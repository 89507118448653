<div bsModal #modal="bs-modal" class="modal fade" role="dialog" (onHidden)="onHidden()" *ngIf="items">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-truncate">
          Choose destination for:
          <ng-template [ngIf]="items.length > 1">
            {{items.length}} items
          </ng-template>
          <ng-template [ngIf]="items.length === 1">
            <i class="icon" [ngClass]="itemIcon"></i> {{items[0].name}}
          </ng-template>
        </h5>
        <button type="button" class="close" (click)="modal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <app-group-browser [startingItem]="group" [config]="browserConfig" (currentGroupChange)="onCurrentGroupChange($event)"></app-group-browser>
      </div>
      <div class="modal-footer" *ngIf="currentGroup">
        <div class="alert alert-danger mb-0 w-100" *ngIf="errorMessage">{{errorMessage}}</div>
        <button class="btn btn-primary" [disabled]="!!errorMessage || currentGroup.id === group.id || isMoving"
          (click)="selectDestination()">Move here</button>
      </div>
    </div>
  </div>
</div>
