<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row" *ngIf="sessionId">
    <div class="col-sm-8">
      <h4 class="page-header">
        <strong>Session {{sessionId}} Details</strong>
      </h4>
    </div>
    <div class="col-sm-4" align="right">
      <button id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()" title="Refresh">
        <i class="icon icon-refresh"></i>
      </button>
    </div>
  </div>

  <div *ngIf="!sessionNotFound && !sessionIdInvalid && (!sessionId || !sessionDetails)">
    <br />
    <i class="icon icon-circle-notch icon-spin"></i> Loading...
  </div>

  <div *ngIf="sessionNotFound === true || sessionIdInvalid === true">
    <br />
    <div class="alert alert-danger">
      <i class="icon icon-2x icon-times-circle text-danger"></i>
      <ng-template [ngIf]="sessionNotFound">
        Session {{sessionId}} no longer exists
      </ng-template>
      <ng-template [ngIf]="sessionIdInvalid">
        Session id {{sessionId}} invalid
      </ng-template>
    </div>
  </div>

  <div *ngIf="sessionDetails">
    <hr />
    <div class="row">
      <div class="col-sm-1 col-12 text-sm-center text-primary" style="display:block">
        <i class='icon icon-user'></i>
      </div>
      <div class="col-sm-2 col-6">
        <strong>User Name</strong>
      </div>
      <div class="col-sm-2 col-6">{{sessionDetails.username}}</div>
      <div class="col-sm-2 col-6">
        <strong>User Id</strong>
      </div>
      <div class="col-sm-2 col-6">{{sessionDetails.userid}}</div>
      <div class="col-sm-2 col-6">
        <strong>Group Id</strong>
      </div>
      <div class="col-sm-1 col-6">{{sessionDetails.gid}}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-1 col-12 text-sm-center text-primary" style="display:block">
        <i class='icon icon-desktop'></i>
      </div>
      <div class="col-sm-2 col-6">
        <strong>Hostname</strong>
      </div>
      <div class="col-sm-2 col-6" style="word-wrap: break-word">{{sessionDetails.hostname}}</div>
      <div class="col-sm-2 col-6">
        <strong>Connected</strong>
      </div>
      <div class="col-sm-2 col-6">{{sessionDetails.connected * 1000 | date:'y-MM-dd HH:mm:ss'}}</div>
      <div class="col-sm-2 col-6">
        <strong>Process ID</strong>
      </div>
      <div class="col-sm-1 col-6">{{sessionDetails.pid}}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-1 col-12 text-sm-center text-primary" style="display:block">
        <i class='icon icon-table'></i>
      </div>
      <div class="col-sm-2 col-6">
        <strong>Total Memory</strong>
      </div>
      <div class="col-sm-2 col-6">{{sessionDetails.mem_total | byte}}</div>
      <div class="col-sm-2 col-6">
        <strong>Used Memory</strong>
      </div>
      <div class="col-sm-2 col-6">{{sessionDetails.mem_used | byte}}</div>
      <div class="col-sm-2 col-6">
        <strong>Open Tables</strong>
      </div>
      <div class="col-sm-1 col-6">{{sessionDetails.nfiles}}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-1 col-12 text-sm-center text-primary" style="display:block">
        <i class='icon icon-document'></i>
      </div>
      <div class="col-sm-2 col-6">
        <strong>Program</strong>
      </div>
      <div class="col-sm-7 col-6" style="word-wrap: break-word">{{sessionDetails.progname}}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-1 col-12 text-sm-center text-primary" style="display:block">
        <i class='icon icon-play'></i>
      </div>
      <div class="col-sm-2 col-6">
        <strong>Current Statement</strong>
      </div>
      <div class="col-sm-7 col-6" style="word-wrap: break-word">{{sessionDetails.current_statement}}</div>
    </div>
    <hr />

    <app-tab-group (eventUpdate)="setSelectedTabIndex($event)">
      <app-tab label="Locks">
        <div class="table-responsive" *ngIf="selectedTabIndex === 0">
          <app-data-table [data]="sessionDetails.locks">
            <app-data-table-column title="Table Name" property="table_name" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Lock Type" property="lock_type" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Lock Duration" property="lock_duration" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-duration="item">
                {{duration | intervalHS}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Row ID" property="rowid" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Index #" property="index_number" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Waiter" property="waiter" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Key Item Locked" property="key_item_locked" [sortable]="true" [searchable]="true">
            </app-data-table-column>
          </app-data-table>
        </div>
      </app-tab>

      <app-tab label="SQL">
        <div class="table-responsive" *ngIf="selectedTabIndex === 1">
          <app-data-table [data]="sessionDetails.sql"
            noDataAlertMessage="No SQL trace statements have been captured for this session.">
            <app-data-table-column title="ID" property="id" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Type" property="type" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Statement" property="statement" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-stmt="item">
                <div style="word-wrap: break-word">{{stmt}}</div>
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </app-tab>

      <app-tab label="Threads">
        <div class="table-responsive" *ngIf="selectedTabIndex === 2">
          <app-data-table [data]="sessionDetails.threads">
            <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Num Scheduled" property="num_scheduled" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Thread ID" property="thread_id" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Thread Priority" property="thread_priority" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Time Slice" property="time_slice" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-timeslice="item">
                {{timeslice | number:'1.5-5'}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Total Time" property="total_time" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-totaltime="item">
                {{totaltime | number:'1.5-5'}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="VPID" property="vpid" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Wait Reason" property="wait_reason" [sortable]="true" [searchable]="true">
            </app-data-table-column>
          </app-data-table>
        </div>
      </app-tab>

      <app-tab label="Memory">
        <div class="table-responsive" *ngIf="selectedTabIndex === 3">
          <app-data-table [data]="sessionDetails.memory">
            <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Used" property="used" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Free" property="free" [sortable]="true"  [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value | byte}}
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </app-tab>

      <app-tab label="Network">
        <div class="table-responsive" *ngIf="selectedTabIndex === 4">
          <app-data-table [data]="sessionDetails.network">
            <app-data-table-column title="Client Name" property="client_name" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Session Start Time" property="session_start_time" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-time="item">
                {{time * 1000 | date:'y-MM-dd HH:mm:ss'}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Conection Duration" property="connect_duration" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-duration="item">
                {{duration | intervalHS}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Average Received" property="average_received" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Average Sent" property="average_sent" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Total Received" property="total_received" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Total Sent" property="total_sent" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Last Received" property="last_read_time" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value * 1000 | date:'y-MM-dd HH:mm:ss'}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Last Sent" property="last_write_time" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-value="item">
                {{value * 1000 | date:'y-MM-dd HH:mm:ss'}}
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </app-tab>

      <app-tab label="Environment">
        <div class="table-responsive " *ngIf="selectedTabIndex === 5">
          <app-data-table [data]="sessionDetails.environment">
            <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Value" property="value" [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column title="Source" property="source" [sortable]="true" [searchable]="true">
            </app-data-table-column>
          </app-data-table>
        </div>
      </app-tab>

      <app-tab label="Profile">
        <app-session-info-profile-stats [sessionProfile]="sessionDetails.profile"></app-session-info-profile-stats>
      </app-tab>
    </app-tab-group>
  </div>
</div>
