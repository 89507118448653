/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, ViewChild, Output, OnInit, Input } from '@angular/core';
import { InformixServer } from '../../informixServer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SchemaService } from '../schema.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { InformixServerStorageService } from '../../storage/informixServerStorage.service';
import { ServerSpace } from '../../storage/serverSpace';
import { InformixDatabase } from '../informix-database';
import { IACValidators } from '../../../../shared/forms/validators';

@Component({
  selector: 'app-create-demo-database-modal',
  templateUrl: 'create-demo-database-modal.html'
})

export class CreateDemoDatabaseModalComponent implements OnInit {

  @Output() actionComplete = new EventEmitter<any>();
  @ViewChild('demoDatabaseModal') demoDatabaseModal: ModalDirective;
  @Input() databases: InformixDatabase[];

  server: InformixServer = null;
  dbSpacesList: ServerSpace[];
  dbspaceNames: any;
  createDemoDatabaseForm: UntypedFormGroup;
  transactionLogging = true;
  timeSeries = true;
  databaseNames = [];
  isDbspacesLoaded = false;

  constructor(private translate: TranslateService,
    private notificationsService: NotificationsService,
    private schemaService: SchemaService,
    private fb: UntypedFormBuilder,
    private serverStorageService: InformixServerStorageService) { }

  ngOnInit() {
    this.createDemoDatabaseForm = this.fb.group({
      selectedDbspace: new UntypedFormControl(null, [Validators.required]),
      databaseName: new UntypedFormControl(null, [this.databaseNameCheck(), Validators.required, IACValidators.informixIdentifierValidator])
    });
  }

  show(server: InformixServer) {
    this.server = server;
    this.getDbspaces();
    this.databases.forEach(element => {
      this.databaseNames.push(element.name);
    });
    this.dbspaceNames = [];
    this.transactionLogging = true;
    this.timeSeries = true;
    this.demoDatabaseModal.show();
  }

  getDbspaces() {
    this.serverStorageService.getServerSpaces(this.server.id)
      .subscribe((data: ServerSpace[]) => {
        this.dbSpacesList = data;
        if (this.dbSpacesList) {
          this.dbSpacesList.forEach(dbspace => {
            this.dbspaceNames.push(dbspace.name);
          });
          this.createDemoDatabaseForm.get('selectedDbspace').setValue(this.dbspaceNames[0]);
          this.isDbspacesLoaded = true;
        }
      }, err => {
        this.translate.get('schemaManager.createDatabase.errorMsg.getDbspacesError', { suffix: err.error ? err.error.err : err })
          .subscribe((text: string) => {
            this.notificationsService.pushErrorNotification(text);
          });
      });
  }

  databaseNameCheck(): ValidatorFn {
    return (): { customError: string } | null => {
      let error: string;
      this.translate.get('schemaManager.createDatabase.duplicateDatabase').subscribe((text: string) => {
        error = text;
      });
      const errors = { customError: error };
      if (this.databaseNames && this.createDemoDatabaseForm && this.createDemoDatabaseForm.get('databaseName')) {
        if (this.databaseNames.indexOf(this.createDemoDatabaseForm.get('databaseName').value) === -1) {
          return null;
        } else {
          return errors;
        }
      } else {
        return null;
      }
    };
  }

  createDemoDatabase() {
    const dataObj = {
      name: this.createDemoDatabaseForm.value.databaseName,
      dbspace: this.createDemoDatabaseForm.value.selectedDbspace,
      isDemoDatabase: true,
      isTransactionLogging: this.transactionLogging,
      isTimeSeries: this.timeSeries
    };
    this.schemaService.createDatabase(this.server, dataObj).subscribe(res => {

      this.notificationsService.pushGenericNotification(res.return_code, res.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
          this.actionComplete.emit();
        }
      });
    }, err => {
      this.translate.get('schemaManager.createDatabase.errorMsg.createDatabaseError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
    });
    this.closeModal();
  }

  closeModal() {
    this.demoDatabaseModal.hide();
    this.isDbspacesLoaded = false;
    this.createDemoDatabaseForm.get('databaseName').reset();
  }
}
