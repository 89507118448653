/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { CanComponentDeactivate } from '../../shared/canDeactivateGuard.service';
import { AlertingProfileComponent } from '../alerting/alerting-profile.component';
import { GroupBreadcrumb } from './groupBreadcrumb';
import { InformixServerGroup } from './informixServerGroup';

@Component( {
  selector: 'app-group-alerting',
  template: `
  <app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
  <div class="container-fluid page-container" *ngIf="group">
    <app-alerting-profile [owner]="group"></app-alerting-profile>
  <div>
  `
} )
export class GroupAlertingComponent implements OnInit, CanComponentDeactivate {

  group: InformixServerGroup = null;
  breadcrumb: BreadcrumbElement[] = null;

  @ViewChild(AlertingProfileComponent) alertingProfileComponent: AlertingProfileComponent;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadGroup(data.group);
    });
  }

  canDeactivate() {
    return this.alertingProfileComponent ? this.alertingProfileComponent.canDeactivate() : true;
  }

  private loadGroup(group: InformixServerGroup) {
    this.group = group;
    this.breadcrumb = GroupBreadcrumb.build(this.group, [{name: 'Alerting'}]);
  }
}
