/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { take, switchMap, retryWhen, repeat, } from 'rxjs/operators';
import { UserService } from '../../shared/user/user.service';

@Injectable()
export class SocketService {

  private subject: WebSocketSubject<any>;
  public messages$: Subject<unknown> = new Subject<unknown>();
  private url: string;
  private reconnectionDelay = 1000;
  private attemptNr = 0;
  private attemptsToReconnect = 3;
  private token;
  public isConnected: Boolean = false;
  public onConnect: Subject<any>;
  constructor(private userService: UserService) {}


  getWebsocketUrl() {
      const user = this.userService.getCurrentUserDirty();
      if (user && user.csrfToken) {
       this.token =  user.csrfToken;
      }
      if(!this.token){
        this.userService.logOut();
      }
      let loc = window.location;
      if (loc.protocol === 'https:') {
          this.url = 'wss:';
      } else {
        this.url = 'ws:';
      }
      this.url += '//' + loc.host + '/ws/hqsocket?X-Token=' + this.token;
      return this.url;
  }

  connect(): Observable<any> {
    this.onConnect = new Subject<any>();
    this.subject = webSocket({
      url: this.getWebsocketUrl(),
      binaryType: 'arraybuffer',
      deserializer: (message: MessageEvent<BufferSource>) =>{
        try {
          const decoder = new TextDecoder('utf-8');
          return JSON.parse(decoder.decode(message.data));
        }catch(e) {
          console.error('Error while deserializing web socket message: ', e);
          return null;
        }
      },
      serializer: msg => {
        try {
          const str = JSON.stringify(msg);
          const bytes = new TextEncoder().encode(str);
          const binaryData = new Blob([bytes], {
              type: 'application/json;charset=utf-8'
          });
          return binaryData;
        }catch(e) {
          console.error('Error while serializing web socket message: ', e);
          return null;
        }
      },
      openObserver: {
        next: () => {
            this.isConnected = true;
            this.onConnect.next();
        }
      },
      closeObserver: {
        next: (error) => {
            this.isConnected = false;
            this.onConnect.error(error);
        }
      }
    });

    /** on socket connection error -  try to reconnect socket until given number of times */
    this.subject.pipe(
      retryWhen((errs) => errs.pipe(
        this.reconnect,
        repeat(),
        take(this.attemptsToReconnect) // stop reconnection on number of attempts
      ))).subscribe(this.messages$);

      return this.onConnect;
  }

  /** send data over WebSocket connection. */
  send(data: any) {
    if(this.isConnected) {
      this.subject.next(data);
    }
  }

  /** close the WebSocket connection. */
  disconnect() {
    if(this.isConnected) {
      this.onConnect.complete();
      this.subject.complete();
    }
  }

 /** Subscribe to the receive method to receive data from server. */
  receive(): Observable<any> {
    return this.subject.asObservable();
  }

 /** reconnect the WebSocket on error or socket close. */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  reconnect = switchMap(() => {
    this.attemptNr = this.attemptNr + 1;
    console.log(`Connection down (${this.url}), will attempt ${this.attemptNr} reconnection in ${this.reconnectionDelay}ms`);
      return timer(this.reconnectionDelay);
  });

}
