<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    {{ 'schemaManager.dbAggregate' | translate }}
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbAggregates; else tableDataLoading" [data]="dbAggregates" [rowsPerPage]="20"
      [searchLabel]="'schemaManager.dbAggregateTab.searchLabel' | translate" sortBy="name"
      [noDataAlertMessage]="'schemaManager.dbAggregateTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'schemaManager.dbAggregateTab.aggregateName' | translate" property="name"
        [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbAggregateTab.initializationUDR' | translate" property="init_func"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbAggregateTab.iteratorUDR' | translate" property="iter_func"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbAggregateTab.combineUDR' | translate" property="combine_func"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbAggregateTab.finalUDR' | translate" property="final_func"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbAggregateTab.handlesNulls' | translate" property="handlesnulls">
        <ng-template appTableCell let-item="item">
          <i class="icon" [ngClass]="item ? 'icon icon-check text-success' : 'icon icon-times text-danger'"></i>
        </ng-template>
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>
