/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subscription, Observable } from 'rxjs';

import { CustomSystemReportsService } from './custom-system-reports.service';
import { InformixServer } from '../../servers/informixServer';
import { InformixServerService } from '../../servers/informixServer.service';
import { InformixDatabase } from '../../servers/schema/informix-database';
import { InformixSQLSession } from '../../servers/schema/informix-sql-session';
import { Query } from '../../servers/schema/query';
import { QueryResultPage } from '../../servers/schema/query-results';
import { SchemaService } from '../../servers/schema/schema.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { CustomSystemReport, systemReportCategories } from './system-report';

@Component({
  selector: 'app-create-custom-report',
  templateUrl: './create-custom-report.html',
})
export class CreateCustomReportComponent implements OnInit, OnDestroy {

  isEditMode = false;
  initializingEditMode = false;
  reportToEdit: CustomSystemReport;

  selectServerModal: BsModalRef = null;

  server: InformixServer = null;
  session: InformixSQLSession = null;
  sessionSub: Subscription = null;
  sessionError: string = null;

  selectedDatabase = 'sysmaster';
  databases: string[] = null;
  databasesLoadError: string = null;

  queryFormControl = new UntypedFormControl('');
  query: Query = null;

  querySub: Subscription = null;
  queryError: string = null;
  queryResult: QueryResultPage = null;

  primaryKeyTypeOptions: string[] = null;

  columnsFormGroup: UntypedFormGroup = null;
  columnsForms: UntypedFormGroup[] = null;
  filterColumnIndex = -1;
  numericColumnUnits: any[] = [
    { label: 'Bytes', value: 'bytes' },
    { label: 'Unix time', value: 'timestamp' }
  ];

  reportTabVisited = false;
  reportFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[a-z0-9]+(_[a-z0-9]+)*$/)]),
    name: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(''),
    category: new UntypedFormControl('misc')
  });

  reportCategories = systemReportCategories;

  reportJson: any = null;
  createReportSub: Subscription = null;

  reportIdValidationErrors: any = {
    pattern: 'ID can only contain lowercase letters, digits, and single underscores. '
      + 'Cannot start or end with an underscore. (e.g. my_custom_report)'
  };

  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  @ViewChild('selectServerModal') selectServerModalTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serverService: InformixServerService,
    private schemaService: SchemaService,
    private reportService: CustomSystemReportsService,
    private modalService: BsModalService,
    private notifications: NotificationsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams.server) {
      const serverId = parseInt(this.route.snapshot.queryParams.server, 10);
      if (!isNaN(serverId) && serverId > 0) {
        this.serverService.getServer(serverId.toString()).then(server => {
          this.selectServer(server);
        }, () => {
          // Not important if this fails.
          this.router.navigate([], { queryParams: { server: null }, queryParamsHandling: 'merge' });
        });
      }
    }

    if (this.route.snapshot.queryParams.id) {
      this.isEditMode = true;
      this.getReportToEdit(this.route.snapshot.queryParams.id);
    }
  }

  ngOnDestroy() {
    if (this.sessionSub) {
      this.sessionSub.unsubscribe();
    }

    if (this.querySub) {
      this.querySub.unsubscribe();
    }

    if (this.createReportSub) {
      this.createReportSub.unsubscribe();
    }
  }

  getReportToEdit(reportId: string) {
    this.reportService.getReport(reportId).then(report => {
      this.reportToEdit = report;
      this.initializeEditMode(report);
    }, err => {
      this.translate.get('systemSettings.customReports.cannotEditReport',
        { suffix: ((err.error && err.error.err) ? err.error.err : 'An unknown error occurred') })
        .subscribe((text: string) => {
          this.notifications.pushErrorNotification(text);
        });
    });
  }

  initializeEditMode(report: CustomSystemReport) {
    this.initializingEditMode = true;
    this.reportFormGroup.controls.id.setValue(report.id);
    this.reportFormGroup.controls.id.disable();
    this.reportFormGroup.controls.name.setValue(report.name);
    this.reportFormGroup.controls.description.setValue(report.description);
    this.reportFormGroup.controls.category.setValue(report.category);
    this.selectedDatabase = report.database;
    this.queryFormControl.setValue(report.sql);

    this.filterColumnIndex = -1;
    this.columnsForms = [];
    const columnsFormGroup = new UntypedFormGroup({});
    report.columns.forEach(col => {
      const columnForm = new UntypedFormGroup({
        id: new UntypedFormControl(col.id),
        name: new UntypedFormControl(col.name, Validators.required),
        unit: new UntypedFormControl(col.unit)
      });

      this.columnsForms.push(columnForm);
      columnsFormGroup.addControl(col.id, columnForm);
    });
    this.columnsFormGroup = columnsFormGroup;
  }

  showSelectServerModal() {
    if (this.selectServerModalTemplate) {
      this.selectServerModal = this.modalService.show(this.selectServerModalTemplate);
    }
  }

  selectServer(server: InformixServer) {
    if (this.selectServerModal) {
      this.selectServerModal.hide();
      this.selectServerModal = null;
    }

    if (server !== this.server) {
      this.server = server;
      this.router.navigate([], { queryParams: { server: server.id }, queryParamsHandling: 'merge' });
      this.clearQueryResult();
      this.loadDatabases();
    }

    this.sessionError = null;
  }

  private loadDatabases() {
    this.databases = null;
    this.databasesLoadError = null;
    this.schemaService.getDatabaseNames(this.server).subscribe(databases => {
      this.databases = databases;
      const index = this.databases.indexOf(this.selectedDatabase);
      if (index < 0 && this.databases.length > 0) {
        this.selectDatabase(this.databases[0]);
      } else {
        this.selectDatabase(this.selectedDatabase);
      }
    }, err => {
      if(err.error && err.error.err) {
        this.databasesLoadError = err.error.err;
      }else {
        this.translate.get('database.errorLoadingDatabases').subscribe((text: string) => {
          this.databasesLoadError = text;
        });
      }
      console.error(err);
    });
  }

  private selectDatabase(dbname: string) {
    this.selectedDatabase = dbname;
    this.clearQueryResult();
    const database = new InformixDatabase(this.server, { name: this.selectedDatabase });
    this.sessionSub = this.schemaService.createSession(database, 'monitor').subscribe(session => {
      this.session = session;

      if (this.initializingEditMode) {
        // When initializing edit mode, automaticallly run query once we have a sql session
        this.initializingEditMode = false;
        this.onRunClick();
      }
    }, err => {
      this.translate.get('database.failedToConnectToDatabase',
      { suffix: ((err.error && err.error.err) ? err.error.err : 'An unknown error occurred') })
      .subscribe((text: string) => {
        this.sessionError = text;
      });
      console.error(err);
    }).add(() => {
      this.sessionSub = null;
    });
  }

  onQueryKeyDown(event: any) {
    if (event.ctrlKey && event.keyCode === 13) { // Ctrl+Enter
      this.onRunClick();
    } else {
      this.clearQueryResult();
    }
  }

  onRunClick() {
    if (this.querySub) {
      return;
    }

    this.clearQueryResult();
    let queryString: string = this.queryFormControl.value.trim();
    if (!queryString) {
      this.translate.get('systemSettings.customReports.enterSqlQuery').subscribe((text: string) => {
        this.queryError = text;
      });
      return;
    }

    queryString = queryString.replace(/\n+|(\r\n)+/g, ' ').trim();
    this.runQuery(queryString);
  }

  private runQuery(sql: string) {
    if (!this.session) {
      return;
    }

    this.clearQueryResult();
    this.query = new Query(this.schemaService, this.session, sql);

    this.querySub = this.query.next().subscribe(result => {
      const results = result.output[0];
      if(!results.isSuccess) {
        this.queryError = results.err;
      } else if (typeof result === 'string' || (results.rows && results.rows.length < 1)) {
        this.translate.get('systemSettings.customReports.sqlQueryNoDataError').subscribe((text: string) => {
          this.queryError = text;
        });
      } else {
        this.queryResult = results;
        this.createColumnsFormGroup();
      }
    }, err => {
      this.queryError = (err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred.';
      this.query = null;
    }).add(() => {
      this.querySub = null;
    });
  }

  private clearQueryResult() {
    this.query = null;
    this.queryResult = null;
    this.queryError = null;
  }

  goToNextTab() {
    for (let i = 1; i < this.tabset.tabs.length; i++) {
      if (this.tabset.tabs[i - 1].active && !this.tabset.tabs[i].disabled) {
        this.tabset.tabs[i].active = true;
        break;
      }
    }
  }

  createColumnsFormGroup() {
    if (!this.queryResult) {
      return;
    }

    this.filterColumnIndex = -1;
    this.columnsForms = [];
    const originalColumnsFormGroup = this.columnsFormGroup;
    const columnsFormGroup = new UntypedFormGroup({});
    this.queryResult.columnNames.forEach(columnName => {
      let columnForm: UntypedFormGroup;
      if (originalColumnsFormGroup && originalColumnsFormGroup.contains(columnName)) {
        columnForm = originalColumnsFormGroup.get(columnName) as UntypedFormGroup;
      } else {
        columnForm = new UntypedFormGroup({
          id: new UntypedFormControl(columnName),
          name: new UntypedFormControl(columnName, Validators.required),
          unit: new UntypedFormControl(null)
        });

      }
      this.columnsForms.push(columnForm);
      columnsFormGroup.addControl(columnName, columnForm);
    });

    if (this.columnsFormGroup) {
      columnsFormGroup.patchValue(this.columnsFormGroup.value);
    }
    this.columnsFormGroup = columnsFormGroup;
  }

  isPreviewDisabled() {
    return !this.query || !this.queryResult || !this.columnsFormGroup || this.columnsForms.length < 1 || this.columnsFormGroup.invalid
      || this.reportFormGroup.invalid;
  }

  onPreviewTabSelect(event: any) {
    if (!(event instanceof TabDirective) || this.isPreviewDisabled()) {
      return;
    }

    this.reportJson = this.buildReportJson();
  }

  private buildReportJson() {
    if (this.isPreviewDisabled()) {
      return null;
    }

    const reportJson: any = {
      id: (this.isEditMode) ? this.reportToEdit.id : this.reportFormGroup.value.id.trim(),
      name: this.reportFormGroup.value.name.trim(),
      description: this.reportFormGroup.value.description.trim(),
      meta: {
        database: this.selectedDatabase,
        sql: this.query.query,
        testServerId: this.server.id,
        columns: [],
        category: this.reportFormGroup.value.category
      }
    };

    this.columnsForms.forEach(columnForm => {
      const f = columnForm.value;
      const columnJson: any = {
        id: f.id,
        name: f.name
      };
      if (f.unit) {
        columnJson.unit = f.unit;
      }
      reportJson.meta.columns.push(columnJson);
    });

    return reportJson;
  }

  isColumnNumeric(columnName: string): boolean {
    if (!this.queryResult) {
      return;
    }

    const columnIndex = this.queryResult.columnNames.indexOf(columnName);
    if (columnIndex < 0) {
      return false;
    }

    return !this.queryResult.rows.find(
      row => (typeof this.getNumberOrString(row.values[columnIndex].value)) !== 'number');
  }

  private getNumberOrString(value: any): number | string {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      const numberValue = parseInt(value, 10);
      if (!isNaN(numberValue)) {
        return numberValue;
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  createReport() {
    const reportJson = this.reportJson || this.buildReportJson();

    if (!reportJson) {
      return;
    }

    let actionObservable: Observable<CustomSystemReport>;
    if (this.isEditMode) {
      actionObservable = this.reportService.updateReport(this.reportToEdit.id, reportJson);
    } else {
      actionObservable = this.reportService.createReport(reportJson);
    }
    this.createReportSub = actionObservable.subscribe(() => {
      this.translate.get('systemSettings.customReports.reportSaved').subscribe((text: string) => {
        this.notifications.pushSuccessNotification(text);
      });

      let routerCommands: any[] = ['..'];
      if (this.server && this.route.snapshot.queryParams.source === 'system-reports') {
        routerCommands = ['/dashboard', 'servers', this.server.id, 'system-reports'];
      }
      this.router.navigate(routerCommands, { relativeTo: this.route });
    }, err => {
      this.translate.get('systemSettings.customReports.reportSaveFailed',
        { suffix: err.error ? err.error.err : 'An unknown error occurred' })
        .subscribe((text: string) => {
          this.notifications.pushErrorNotification(text);
        });
      console.error(err);
    }).add(() => this.createReportSub = null);
  }
}
