/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, OnDestroy, QueryList, ViewChildren, Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ParameterService } from './parameter-service';
import { InformixServer } from '../../servers/informixServer';
import { Parameter } from './parameter-model';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss'],
  providers: [ParameterService]
})
export class ParameterComponent implements OnInit, AfterViewInit, OnDestroy {
  task: any;
  server: InformixServer = null;
  paramDetailsForm: UntypedFormGroup;
  showSave: Boolean = true;
  disableSave: boolean;
  params: UntypedFormArray;
  newParams: UntypedFormArray;
  @Output() saveParamDone: EventEmitter<any> = new EventEmitter();
  constructor(private parameterService: ParameterService, private formBuilder: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService, private notificationsService: NotificationsService) {
  }
  parameters: Array<Parameter>;
  @ViewChild('addParamModal') addParamModal: ModalDirective;

  @ViewChildren('paramName') rows: QueryList<any>;
  private sub1: Subscription = new Subscription();

  ngOnInit() {
    this.paramDetailsForm = this.formBuilder.group({
      params: this.formBuilder.array([]),
      newParams: this.formBuilder.array([])
    });
  }

  ngAfterViewInit() {
    this.sub1 = this.rows.changes.subscribe(resp => {
      if (this.rows.length > 0) {
        this.rows.last.nativeElement.focus();
      }
    });
   }

   // memory leak avoidance
   ngOnDestroy() {
     this.sub1.unsubscribe();
   }

  // To show add param modal with prameters details for a task
  openAddParamModal(server: InformixServer, task: any) {
    this.server = server;
    this.task = task;
    this.parameterService.getParametersForTask(this.server, task.tk_id).subscribe((res: Array<Parameter>) => {
      if (res) {
        res.map(e => {
          this.params = this.paramDetailsForm.get('params') as UntypedFormArray;
          this.params.push(this.createParam(e.id, e.name, e.value, e.value_type, e.description));
          this.showSave = false;
        });
      }
      if (res.length === 0) {
        this.disableSave = true;
      }
    }, (err) =>
        this.notificationsService.pushErrorNotification('Cannot get parameters for task'));
    this.addParamModal.show();
  }

  // Confirmation dialog box when deleting parameter details
  confirmDelete(index: number, taskId: number, parameterId: number) {
    this.confirmationDialogService.show('Delete Parameter',
      'continue?', () =>
        this.deleteParameter(index, taskId, parameterId));
  }

  // Create form for already existing parameters
  createParam(id: number, name: string, value: string, value_type: string, description: string): UntypedFormGroup {
    return this.formBuilder.group({
      id,
      show: false,
      parameterName: new UntypedFormControl(name),
      prevName: name,
      prevValue: value,
      prevValueType: value_type,
      isChangedValue: false,
      isChangedDescription: false,
      prevDescription: description,
      name: new UntypedFormControl({ value: name, disabled: true }, [Validators.required]),
      value: new UntypedFormControl({ value, disabled: true }, [this.valueChanged('value'), Validators.required]),
      value_type: new UntypedFormControl({ value: value_type, disabled: true }, [Validators.required]),
      description: new UntypedFormControl({ value: description, disabled: true }, [this.valueChanged('description'), Validators.required]),
      current_state: 0
    });
  }

  // Adds new parameter in form
  addNewParamDetails() {
    this.disableSave = false;
    if (this.paramDetailsForm.invalid) {
      return;
    }
    this.showSave = true;
    this.newParams = this.paramDetailsForm.get('newParams') as UntypedFormArray;
    this.newParams.push(this.createNewParam());
    this.editParameters();
    if (this.params != null && this.params !== undefined) {
      for (let i = 0; i < this.params.length; i++) {
        this.params.at(i).get('show').setValue(false);
      }
    }
  }

  // Deletes newly added parameter
  deleteParamForm(index: number, formArray: string) {
    if ((this.params == null || this.params === undefined) && this.newParams.value.length === 1) {
      this.disableSave = true;
    }
    const control = <UntypedFormArray>this.paramDetailsForm.controls[formArray];
    control.removeAt(index);
  }

  // Creats formgroup for new parameter
  createNewParam(): UntypedFormGroup {
    return this.formBuilder.group({
      task_name: this.task.tk_name,
      name: new UntypedFormControl(null, [Validators.required]),
      value: new UntypedFormControl(null, [Validators.required]),
      value_type: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(null, [Validators.required]),
      current_state: 1
    });
  }

  // Detect changes in parameter details while editing
  valueChanged(controlType: string): ValidatorFn {
    return (control: AbstractControl): null => {
      if (controlType === 'value' && control.value !== control.parent.value.prevValue) {
        control.parent.get('isChangedValue').setValue(true);
      } else {
        control.parent.get('isChangedValue').setValue(false);
      }
      if (controlType === 'description' && control.value !== control.parent.value.prevDescription) {
        control.parent.get('isChangedDescription').setValue(true);
      } else {
        control.parent.get('isChangedDescription').setValue(false);
      }
      return null;
    };
  }

  // Deletes already existing parameters
  deleteParameter(index: number, taskId: number, parameterId: number) {
    this.parameterService.deleteParameterForTask(this.server, taskId, parameterId).subscribe((res) => {
      if (res) {
        this.deleteParamForm(index, 'params');
        this.notificationsService.pushSuccessNotification('Parameter deleted successfully.');
      }
    }, (err) =>
        this.notificationsService.pushErrorNotification(err.error ? err.error.err : err));
  }

  // Enable fields when clicking on edit button
  editParameters() {
    this.showSave = true;
    if (this.params != null && this.params !== undefined) {
      for (let i = 0; i < this.params.length; i++) {
        if (this.params.at(i) != null && this.params.at(i) !== undefined) {
          this.params.at(0).get('show').setValue(true);
          this.params.at(i).get('description').enable();
          this.params.at(i).get('value').enable();
        }
      }
    }
  }

  // Saves/updates parameter details
  saveParameters() {
    this.parameters = new Array<Parameter>();
    if (this.params != null && this.params !== undefined) {
      for (let i = 0; i < this.params.length; i++) {
        const param = new Parameter();
        if (this.params.at(i).get('isChangedValue').value || this.params.at(i).get('isChangedDescription').value) {
          param.current_state = 2;
        } else {
          param.current_state = 0;
        }
        param.description = this.params.at(i).get('description').value;
        param.name = this.params.at(i).get('name').value;
        param.task_name = this.task.tk_name;
        param.value = this.params.at(i).get('value').value;
        param.value_type = this.params.at(i).get('value_type').value;
        this.parameters.push(param);
      }
    }
    if (this.newParams != null && this.newParams !== undefined) {
      for (let i = 0; i < this.newParams.length; i++) {
        const param = new Parameter();
        param.current_state = 1;
        param.description = this.newParams.at(i).get('description').value;
        param.name = this.newParams.at(i).get('name').value;
        param.task_name = this.task.tk_name;
        param.value = this.newParams.at(i).get('value').value;
        param.value_type = this.newParams.at(i).get('value_type').value;
        this.parameters.push(param);
      }
    }
    this.parameterService.updateParametersForTask(this.server, this.task.tk_id, this.parameters).subscribe((res) => {
      if (res) {
        this.notificationsService.pushSuccessNotification('Parameter details saved successfully.');
        this.saveParamDone.emit(true);
      }
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
    this.closeParamModal();
  }

  // Clears form array
  clearFormArray(formArray: UntypedFormArray) {
    if (formArray != null && formArray !== undefined) {
      formArray.controls.splice(0);
    }
  }

  // Closing Parameter Details Modal and setting flags to initial values
  closeParamModal() {
    this.disableSave = false;
    this.showSave = true;
    this.clearFormArray(this.newParams);
    this.clearFormArray(this.params);
    this.paramDetailsForm.reset();
    this.paramDetailsForm.clearValidators();
    this.addParamModal.hide();
  }
}
