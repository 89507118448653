/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
export class SensorType {
  id: string;
  name: string;
  description: string;
  meta: {
    chart: any;
    default: any;
    metrics: { [key: string]: SensorMetric };
  };
  fromPackage: boolean;

  metrics: SensorMetric[] = [];

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.meta = json.meta;
    this.fromPackage = json.fromPackage;

    for (const key in this.meta.metrics) {
      if (this.meta.metrics.hasOwnProperty(key)) {
        const metric = this.meta.metrics[key];
        metric.id = key;
        this.metrics.push(metric);
      }
    }
  }

  getMetric(id: string): SensorMetric {
    return this.meta.metrics[id];
  }

  hasPrimaryKey() {
    return !!this.meta.default.primaryKey;
  }
}

export interface SensorMetric {
  id: string;
  name: string;
  dataType?: string;
  unit: string;
  showChart: boolean;
}

export class Sensor {

  public name: string;
  public type: SensorType;
  public runInterval: number;
  public dataRetentionInterval: number;
  public disabled: boolean;

  constructor(sensor: any) {
    if (sensor.type) {
      this.type = sensor.type instanceof SensorType ? sensor.type : new SensorType(sensor.type);
      this.name = this.type.name;
    }
    this.runInterval = sensor.runInterval;
    this.dataRetentionInterval = sensor.dataRetentionInterval;
    this.disabled = sensor.disabled || false;
  }

  public equals(sensor: Sensor) {
    return this.runInterval === sensor.runInterval
      && this.dataRetentionInterval === sensor.dataRetentionInterval
      && this.disabled === sensor.disabled;
  }
}
