/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { CanComponentDeactivate } from '../../shared/canDeactivateGuard.service';
import { GroupBreadcrumb } from '../groups/groupBreadcrumb';
import { InformixServerGroupService } from '../groups/informixServerGroup.service';
import { MonitoringProfileComponent } from '../monitoring/monitoringProfile.component';
import { InformixServer } from './informixServer';
import { ServerBreadcrumb } from './serverBreadcrumb';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-server-monitoring',
  templateUrl: 'serverMonitoring.html'
})
export class ServerMonitoringComponent implements OnInit, CanComponentDeactivate {

  breadcrumb: BreadcrumbElement[] = null;
  server: InformixServer = null;
  serverType = environment.serverType;

  @ViewChild(MonitoringProfileComponent) monitoringProfileComponent: MonitoringProfileComponent;

  constructor(
    private groupService: InformixServerGroupService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  canDeactivate() {
    return this.monitoringProfileComponent ? this.monitoringProfileComponent.canDeactivate() : true;
  }

  loadServer(server: InformixServer) {
    this.server = server;

    this.groupService.getGroup(this.server.parentGroupId).then(group => {
      this.breadcrumb = GroupBreadcrumb.build(group, ServerBreadcrumb.build(this.server, [{ name: 'Monitoring' }]));
    }).catch(err => {
      console.error(`Unable to get ${this.serverType} server group`, err);
    });
  }
}
