<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <h4><strong>{{ 'systemReports.locksPerTable' | translate }}</strong>
  </h4>
  <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData" [searchLabel]="'systemReports.searchLocksPerTable' | translate"
    [sortDescending]="true"  sortBy="lockcnt" [rowsPerPage]="20">
    <app-data-table-column [title]="'database.database' | translate" property="dbsname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'table.tableName' | translate" property="tabname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.activeLocks' | translate" property="lockcnt" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <div *ngIf="item != 0">
          <a [routerLink]="['../lock-list']" [queryParams]="{ lockedObject: row.dbsname + ':' + row.tabname}">
            {{item}}
          </a>
        </div>
        <div *ngIf="item == 0">
          {{item}}
        </div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockRequest' | translate" property="lockreq" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockWait' | translate" property="lockwaits" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.deadLock' | translate" property="deadlocks" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockTimeout' | translate" property="locktimeouts" [sortable]="true" [searchable]="true">
    </app-data-table-column>
  </app-data-table>
</div>
