/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { InformixTreeItem } from '../informixTreeItem';
import { AlertingCriteria, AlertOperation, AlertTrigger } from './alerting-profile';


@Component({
  selector: 'app-alert-config',
  templateUrl: 'alert-config.html'
})
export class AlertConfigComponent implements OnChanges {

  private alertOperation: AlertOperation = null;

  @Input() alert: AlertingCriteria;
  @Input() owner: InformixTreeItem;

  @Output() eventUpdate = new EventEmitter<any>();

  formGroup: UntypedFormGroup;
  isOperationValid = false;
  alertTriggerType: string = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.alert && this.alert) {
      this.init();
    }
  }

  private init() {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.alert.name, Validators.required),
      runInterval: new UntypedFormControl(this.alert.trigger.interval, this.validateRunInterval.bind(this))
    });

    this.formGroup.valueChanges.pipe(debounceTime(50)).subscribe(() => {
      this.emitUpdate();
    });
  }

  onUpdate(event: any) {
    this.isOperationValid = event.valid;
    this.alertOperation = event.operation;
    this.alertTriggerType = event.trigger;
    this.emitUpdate();
  }

  private emitUpdate() {
    this.eventUpdate.emit({
      alert: this.getAlert(),
      valid: this.isValid()
    });
  }

  getAlert(): AlertingCriteria {
    const trigger: AlertTrigger = this.alertTriggerType ? { type: this.alertTriggerType } : null;
    if (this.alertTriggerType === 'interval') {
      trigger.interval = this.formGroup.value.runInterval;
    }

    return {
      id: this.alert.id,
      name: this.formGroup.value.name.trim(),
      trigger,
      condition: this.alertOperation,
      disabled: this.alert.disabled
    };
  }

  private validateRunInterval(c: AbstractControl) {
    if (this.alertTriggerType === 'interval' && isNaN(c.value)) {
      return { required: true };
    } else {
      return null;
    }
  }

  public isValid() {
    return this.formGroup && this.formGroup.valid && this.isOperationValid;
  }
}
