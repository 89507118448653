<div class="form-group">
  <div class="row">
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{table.typeName}} name:</label>
      <div class="font-weight-bold mb-2">
        {{table.name}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'common.owner' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.owner}}
      </div>
    </div>
    <div class="col-3 py-3" *ngIf="table.type === 'V'">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.viewDefinition' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{tableDetails?.view_sql}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.lastModified' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.lastModified | date: 'longDate'}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.firstExtent' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.firstExtentSize | byte}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.nextExtent' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.nextExtentSize | byte}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.lockLevel' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.lockLevelName}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.pageSize' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.pageSize | byte}}
      </div>
    </div>
    <div class="col-3 py-3">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.statisticsLevel' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.statisticsLevelName || '-'}}
      </div>
    </div>
    <div class="col-3 py-3" *ngIf="table.statisticsLevel">
      <label class="m-0 color-light-gray-70">{{'schemaManager.tableInfo.statisticsLastUpdated' | translate}}:</label>
      <div class="font-weight-bold mb-2">
        {{table.lastStatisticsUpdate ? (table.lastStatisticsUpdate | date: 'medium') : 'Never'}}
      </div>
    </div>
  </div>
</div>
