/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { AbstractQuery } from '../../abstract-query';
import { InformixSQLSession } from '../../informix-sql-session';
import { Query } from '../../query';
import { SchemaService } from '../../schema.service';
import { ExplainQuery } from './explain-query';
import { HelpQuery } from './help-query';
import { OnStatQuery } from './onstat-query';

@Injectable()
export class SuperSQLService {

  public getSuperQuery(restdb: SchemaService, session: InformixSQLSession, query: string, isMultiLine: Boolean): AbstractQuery {
    const queryWithoutTrailingSemi = this.trimTrailingSemicolon(query);
    const queryParts = queryWithoutTrailingSemi.replace(/[ ]{2,}/g, ' ').split(' ');
    if (isMultiLine) {
      return new Query(restdb, session, query);
    } else {
      if (queryParts[0] === 'onstat') {
        return new OnStatQuery(restdb, session, query, queryParts.slice(1).join(' '));
      } else if (queryParts[0] === 'explain') {
        return new ExplainQuery(restdb, session, query);
      } else if (queryParts[0] === 'help') {
        return new HelpQuery(restdb, session, query);
      } else {
        return new Query(restdb, session, query);
      }
    }
  }

  private trimTrailingSemicolon(query: string): string {
    let result = query;

    if (query.endsWith(';')) {
      result = query.substring(0, query.length - 1);
    }

    return result;
  }
}
