<div class="container-fluid page-container" *ngIf="configs">
  <h3>Alerting Configuration</h3>
  <tabset>
    <tab heading="Email">
      <app-alert-config-container [isEmail]="true" [config]="configs.email" (eventSave)="saveConfig('email', $event)" (eventTest)="testConfig('email', $event)">
        <app-email-config #config></app-email-config>
        <app-user-email-settings #test test-form [settings]="testSettings"></app-user-email-settings>
      </app-alert-config-container>
    </tab>
    <tab heading="Twilio">
      <app-alert-config-container [config]="configs.twilio" (eventSave)="saveConfig('twilio', $event)" (eventTest)="testConfig('twilio', $event)">
        <app-twilio-config #config></app-twilio-config>
        <app-user-twilio-settings #test test-form [settings]="testSettings"></app-user-twilio-settings>
      </app-alert-config-container>
    </tab>
    <tab heading="Pager Duty">
      <app-alert-config-container [config]="configs.pagerduty" (eventSave)="saveConfig('pagerduty', $event)"
        (eventTest)="testConfig('pagerduty', $event)">
        <app-pager-duty-config #config></app-pager-duty-config>
      </app-alert-config-container>
    </tab>
    <tab heading="Run Script">
      <app-alert-config-container [config]="configs.alertscript" (eventSave)="saveConfig('alertscript', $event)"
        (eventTest)="testConfig('alertscript', $event)">
        <app-run-script-config #config></app-run-script-config>
      </app-alert-config-container>
    </tab>
  </tabset>
</div>
