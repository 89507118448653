/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appFocusOnCreate]'
})
export class FocusOnCreateDirective implements OnInit {
  @Input() focusAndSelect = false;

  constructor(private elem: ElementRef) { }

  ngOnInit() {
    this.elem.nativeElement.focus();
    if (this.focusAndSelect && this.elem.nativeElement instanceof HTMLInputElement) {
      this.elem.nativeElement.select();
    }
  }
}
