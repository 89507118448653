/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';

import { ServerResolver } from '../../server-resolver.service';
import { BackupComponent } from './backup.component';

export const BackupRoutes: Route[] = [
  {
    path: 'backups',
    children: [
      {
        path: '',
        component: BackupComponent,
        resolve: {
          server: ServerResolver
        }
      }
    ]
  }
];
