/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InformixServer } from '../informixServer';
import { InternalUserInfo, PrivilegeType } from './privileges.model';
import { PrivilegesService } from './privileges.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { IACValidators } from '../../../shared/forms/validators';
import { ErrorMessageHandlers } from '../../../shared/forms/extendedFormControl.directive';
import { InformixServerOnconfigService } from '../configuration/informixServerOnconfig.service';
import { ServerOnconfigParameter } from '../configuration/serverOnconfigParameter';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-internal-users-privileges',
  templateUrl: './internal-users-privileges.html',
  styleUrls: ['./internal-users-privileges.scss']
})
export class InternalUsersPrivilegesComponent implements OnInit {
  @Input() server: InformixServer = null;
  @ViewChild('internalUserModal') internalUserModal: ModalDirective;
  selectedUserMapping = 'No privileges';
  notSupportedServer = false;
  notSupportedServerMsg = '';
  isEdit = false;
  isDefaultUser: boolean = null;
  prevUserMapping = 'No privileges';
  isDefaultUserExists = false;
  internalUserForm: UntypedFormGroup;
  internalUsersInfo: Array<InternalUserInfo>;
  currentInternalUser: InternalUserInfo;
  userName: UntypedFormControl;
  authenticationType: UntypedFormControl;
  accountStatus: UntypedFormControl;
  mapUserBy: UntypedFormControl;
  osUserName: UntypedFormControl;
  gid: UntypedFormControl;
  osUserID: UntypedFormControl;
  groupID: UntypedFormControl;
  homeDirectory: UntypedFormControl;
  password: UntypedFormControl;
  confirmPassword: UntypedFormControl;
  showPassword: Boolean = false;
  showConfirmPassword: Boolean = false;
  serverType = environment.serverType;
  internalUserPrivileges: Array<PrivilegeType> = [{
    typeName: 'DBSA',
    typeDesc: 'Database server administrator',
    type: 'S',
    checked: false
  }, {
    typeName: 'DBSSO',
    typeDesc: 'Database system security officer',
    type: 'O',
    checked: false
  }, {
    typeName: 'AAO',
    typeDesc: 'Audit analysis officer',
    type: 'A',
    checked: false
  }, {
    typeName: 'bargroup',
    typeDesc: 'bargroup',
    type: 'B',
    checked: false
  }];

  userMappings: Array<{ mappingType: string; mappingName: string; confirmationMessage?: string }> =
    [{
      mappingType: 'OFF', mappingName: 'No privileges', confirmationMessage: 'select this option? ' +
        `Only users with operating system accounts on the ${this.serverType} host computer will be ` +
        'able to connect to the database server. Mapped users cannot connect to the database server.'
    }, {
      mappingType: 'BASIC', mappingName: 'Basic', confirmationMessage: 'select this option? ' +
        'This allows mapped users to have basic privileges on the database server, but they cannot perform administrator operations.'
    }, {
      mappingType: 'ADMIN', mappingName: 'Administrative',
      confirmationMessage: 'select this option? This allows mapped users to have administrator privileges on the database server.'
    }];

  isDataLoading: Boolean = false;
  apiError: string;
  constructor(
    private privilegesService: PrivilegesService,
    private onconfigService: InformixServerOnconfigService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private formBuilder: UntypedFormBuilder
  ) { }

  userNameErrorHandlers: ErrorMessageHandlers = {
    duplicateName: () => 'This user name already exists.',
    specialCharacter: () => 'Special characters are not allowed.',
    passwordMismatch: () => 'Passwords must match and be at least six characters.',
  };

  ngOnInit() {
    this.userName = new UntypedFormControl(null,
        [Validators.required, IACValidators.noSpecialCharacterValidator(), this.userNameValidator()]);
    this.authenticationType = new UntypedFormControl('os');
    this.accountStatus = new UntypedFormControl('unlock');
    this.mapUserBy = new UntypedFormControl('byUserName');
    this.osUserName = new UntypedFormControl(null, [Validators.required, IACValidators.noSpecialCharacterValidator()]);
    this.gid = new UntypedFormControl(null);
    this.osUserID = new UntypedFormControl(null, [Validators.required]);
    this.groupID = new UntypedFormControl(null, [Validators.required]);
    this.homeDirectory = new UntypedFormControl(null);
    this.password = new UntypedFormControl(null);
    this.confirmPassword = new UntypedFormControl(null);
    this.internalUserForm = this.formBuilder.group({
      userName: this.userName,
      authenticationType: this.authenticationType,
      accountStatus: this.accountStatus,
      mapUserBy: this.mapUserBy,
      osUserName: this.osUserName,
      gid: this.gid,
      osUserID: this.osUserID,
      groupID: this.groupID,
      homeDirectory: this.homeDirectory,
      password: this.password,
      confirmPassword: this.confirmPassword
    });
    this.getInternalUsersInfo();

    this.authenticationType.valueChanges.subscribe(value => {
      if (value === 'os') {
        this.accountStatus.disable();
        this.controlDisable(this.password);
        this.controlDisable(this.confirmPassword);
      } else {
        this.accountStatus.enable();
        this.password.enable();
        this.confirmPassword.enable();
      }
    });

    this.mapUserBy.valueChanges.subscribe(value => {
      if (value === 'inherited') {
        this.internalUserPrivileges.map(privilege => privilege.checked = false);
        this.controlDisable(this.osUserID);
        this.controlDisable(this.gid);
        this.controlDisable(this.osUserName);
        this.controlDisable(this.groupID);
        this.controlDisable(this.homeDirectory);
      } else if (value === 'byUserName') {
        this.osUserName.enable();
        this.gid.enable();
        this.controlDisable(this.osUserID);
        this.controlDisable(this.groupID);
        this.homeDirectory.enable();
      } else {
        this.osUserID.enable();
        this.groupID.enable();
        this.controlDisable(this.osUserName);
        this.controlDisable(this.gid);
        this.homeDirectory.enable();
      }
    });

    this.internalUserForm.valueChanges.subscribe(() => {
      if (this.authenticationType.value === 'database') {
        if (!this.isEdit || (this.isEdit && this.currentInternalUser &&
          this.currentInternalUser.authenticationType.toLowerCase() === 'os')) {
          this.password.setValidators([Validators.required, IACValidators.confirmPasswordValidator(this.confirmPassword.value)]);
          this.confirmPassword.setValidators([Validators.required, IACValidators.confirmPasswordValidator(this.password.value)]);
        } else if (this.password.value) {
          this.password.setValidators([IACValidators.confirmPasswordValidator(this.confirmPassword.value)]);
          this.confirmPassword.setValidators([Validators.required, IACValidators.confirmPasswordValidator(this.password.value)]);
        } else {
          this.password.setValidators(null);
          this.confirmPassword.setValidators(null);
        }
      }
      this.password.updateValueAndValidity({ emitEvent: false });
      this.confirmPassword.updateValueAndValidity({ emitEvent: false });
      if (this.internalUserForm.valid) {
        this.password.setErrors(null);
        this.confirmPassword.setErrors(null);
      }
    });

    this.internalUserForm.patchValue({
      authenticationType: 'os',
      mapUserBy: 'byUserName'
    });
  }

  userNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.internalUsersInfo && this.internalUsersInfo.some(user => user.userName === control.value)) {
        isValid = false;
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

  controlDisable(control: UntypedFormControl) {
    control.setValue(null);
    control.disable();
  }

  getInternalUsersInfo() {
    this.isDataLoading = true;
    this.privilegesService.getInternalUsersInfo(this.server)
      .subscribe((internalUsersInfo: InternalUserInfo[]) => {
        this.internalUsersInfo = internalUsersInfo;
        if (this.internalUsersInfo.filter(user => user.userName.toLowerCase() === 'public').length === 1) {
          this.isDefaultUserExists = true;
          this.isDefaultUser = null;
        } else {
          this.isDefaultUserExists = false;
        }
        this.getInternalUserMapping();
        this.notSupportedServer = false;
        this.isDataLoading = false;
      }, err => {
        this.isDataLoading = false;
        if (err.status === 501) {
          this.notSupportedServer = true;
        }
        if (err.error && err.error.err) {
          this.apiError = err.error.err;
        }
      });
  }

  getInternalUserMapping() {
    this.onconfigService.getOnconfig(this.server.id, 'USERMAPPING')
      .subscribe((onconfigParameter: ServerOnconfigParameter) => {
        this.selectedUserMapping = this.userMappings.filter(userMapping => userMapping.mappingType ===
          onconfigParameter.effective.toUpperCase())[0].mappingName;
        this.prevUserMapping = this.selectedUserMapping;
      }, err => {
        this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
      });
  }

  modifyUserMapping() {
    const userMapping = this.userMappings.filter(mapping => mapping.mappingName ===
      this.selectedUserMapping)[0];
    this.confirmationDialogService.show(userMapping.confirmationMessage,
      () => this.onModifyUserMappingConfirm(userMapping), () => this.setPrevUserMapping());
  }

  setPrevUserMapping() {
    this.selectedUserMapping = this.prevUserMapping;
  }

  onModifyUserMappingConfirm(userMapping: { mappingType: string; mappingName: string; confirmationMessage?: string }) {
    this.onconfigService.updateOnconfig(this.server.id, 'USERMAPPING', userMapping.mappingType).then(result => {
      this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
          this.prevUserMapping = userMapping.mappingName;
          this.selectedUserMapping = this.prevUserMapping;
        }
        if (type.isError) {
          this.setPrevUserMapping();
        }
      });
    }, err => {
      this.setPrevUserMapping();
      this.notificationsService.pushErrorNotification('Could not set User Mapping :'
        + (err.error ? err.error.err : err));
    });
  }

  saveInternalUser() {
    const internalUser = new InternalUserInfo();
    internalUser.userName = this.userName.value;
    if (this.authenticationType.value === 'os') {
      internalUser.lockType = null;
    } else {
      internalUser.lockType = this.accountStatus.value;
    }
    internalUser.osUserName = this.osUserName.value;
    if (this.gid.value) {
      internalUser.gid = this.gid.value;
    } else {
      internalUser.gid = this.groupID.value;
    }
    internalUser.uid = this.osUserID.value;
    internalUser.homeDirectory = this.homeDirectory.value;
    internalUser.password = this.password.value;
    internalUser.privilegeTypes = [];
    internalUser.isDefault = this.isDefaultUser;
    if (this.mapUserBy.value === 'inherited') {
      internalUser.mapUserBy = this.mapUserBy.value;
    }
    this.internalUserPrivileges.filter(privilege => privilege.checked === true).forEach(type => {
      internalUser.privilegeTypes.push(type.typeName.toLowerCase());
    });
    internalUser.previousPrivilegeTypes = [];
    if (this.currentInternalUser && this.currentInternalUser.privilegeTypes) {
      this.currentInternalUser.privilegeTypes.forEach(privilegeType => {
        internalUser.previousPrivilegeTypes.push(this.internalUserPrivileges.filter((userPrivilege) =>
          userPrivilege.type === privilegeType)[0].typeName.toLowerCase());
      });
    }
    if (this.isEdit) {
      this.modifyUserService(internalUser, false);
    } else {
      this.privilegesService.addInternalUser(this.server, internalUser).subscribe(() => {
        this.getInternalUsersInfo();
        this.notificationsService.pushSuccessNotification('The internal user ' + internalUser.userName + ' was added');
      },
        err => this.notificationsService.pushErrorNotification('The internal user ' +
          internalUser.userName + ' was not added. ' + (err.error ? err.error.err : err)));
    }
    this.internalUserModal.hide();
  }

  modifyUserService(internalUser: InternalUserInfo, isToggleLock: boolean) {
    let successMessage: string;
    let errorMessage: string;
    if (isToggleLock) {
      successMessage = 'The account access for the internal user ' + internalUser.userName + ' was changed.';
      errorMessage = 'The account access for the internal user ' + internalUser.userName + ' was not changed.';
    } else {
      successMessage = 'The internal user ' + internalUser.userName + ' was modified';
      errorMessage = 'The internal user ' + internalUser.userName + ' was not modified. ';
    }
    this.privilegesService.modifyInternalUser(this.server, internalUser).subscribe(() => {
      this.getInternalUsersInfo();
      this.notificationsService.pushSuccessNotification(successMessage);
    },
      err => this.notificationsService.pushErrorNotification(errorMessage + (err.error ? err.error.err : err)));
  }

  deleteInternalUser(userName: string) {
    this.confirmationDialogService.show('delete the internal user ' + userName + '?',
      () => this.onDeleteInternalUserConfirm(userName));
  }

  onDeleteInternalUserConfirm(userName: string) {
    this.privilegesService.deleteInternalUser(this.server, userName)
      .subscribe(() => {
        this.notificationsService.pushSuccessNotification('The internal user ' + userName + ' was deleted.');
        this.getInternalUsersInfo();
      },
        err => {
          this.notificationsService.pushErrorNotification('The internal user ' +
            userName + ' was not deleted. ' + (err.error ? err.error.err : err));
        });
  }

  toggleLock(internalUser: InternalUserInfo) {
    const user = new InternalUserInfo();
    let title = '';
    user.userName = internalUser.userName;
    let message = '';
    if (internalUser && internalUser.isLocked === 1) {
      user.lockType = 'unlock';
      message = 'enable access to the database server for the user ' + user.userName + ' ?';
      title = 'Unlock User';
    } else if (internalUser && internalUser.isLocked === 0) {
      user.lockType = 'lock';
      message = 'disable access to the database server for the user ' + user.userName + ' ?';
      title = 'Lock User';
    }
    this.confirmationDialogService.show(title, message, () => this.modifyUserService(user, true));
  }

  getPrivilegeName(privilegeTypes: string[]) {
    const privileges = [];
    if (privilegeTypes) {
      privilegeTypes.forEach(type => {
        privileges.push(' ' + this.internalUserPrivileges.filter(privilege => privilege.type === type)[0].typeName);
      });
    }
    return privileges;
  }

  defaultUserToggle() {
    if (this.isEdit || this.isDefaultUserExists) {
      this.isDefaultUser = null;
      return;
    } else {
      this.isDefaultUser = !this.isDefaultUser;
    }
    if (this.isDefaultUser) {
      this.authenticationType.setValue('os');
      this.authenticationType.disable();
      this.userName.setValue('public');
      this.userName.disable();
      this.mapUserBy.setValue('byUserName');
    } else {
      this.isDefaultUser = null;
      this.authenticationType.enable();
      this.userName.setValue('');
      this.userName.enable();
    }
  }

  checkForNoPrivileges(): boolean {
    if (this.selectedUserMapping === 'No privileges') {
      this.confirmationDialogService.show('Not Supported',
        'The USERMAPPING feature is disabled. The USERMAPPING configuration parameter must be set to BASIC or ADMIN.',
        true);
      return true;
    } else {
      return false;
    }
  }

  addInternalUser() {
    if (this.checkForNoPrivileges()) {
      return;
    }
    this.internalUserModal.show();
  }

  modifyInternalUser(internalUser: InternalUserInfo) {
    if (this.checkForNoPrivileges()) {
      return;
    }
    this.checkForNoPrivileges();
    this.isDefaultUser = null;
    this.currentInternalUser = internalUser;
    this.isEdit = true;
    if (internalUser.uid !== null && internalUser.uid !== 0) {
      this.osUserID.setValue(internalUser.uid);
      if (internalUser.gid !== 0) {
        this.groupID.setValue(internalUser.gid);
      }
    } else {
      this.osUserName.setValue(internalUser.osUserName);
      if (internalUser.gid !== 0) {
        this.gid.setValue(internalUser.gid);
      }
    }
    this.userName.setValue(internalUser.userName);
    this.userName.disable();
    this.authenticationType.setValue(internalUser.authenticationType.toLowerCase());
    if (internalUser.isLocked === 0) {
      this.accountStatus.setValue('unlock');
    } else if (internalUser.isLocked === 1) {
      this.accountStatus.setValue('lock');
    }
    this.homeDirectory.setValue(internalUser.homeDirectory);
    if (internalUser.privilegeTypes) {
      internalUser.privilegeTypes.forEach(privilegeType => {
        this.internalUserPrivileges.filter((userPrivilege) => userPrivilege.type === privilegeType)
          .map(privilege => privilege.checked = true);
      });
    }
    this.internalUserModal.show();
  }

  onHidden() {
    this.internalUserPrivileges.map(privilege => privilege.checked = false);
    for (const field in this.internalUserForm.controls) {
      if (field) {
        const control = this.internalUserForm.get(field);
        control.enable();
      }
    }
    this.isEdit = false;
    this.currentInternalUser = null;
    this.isDefaultUser = null;
    this.password.setValidators(null);
    this.confirmPassword.setValidators(null);
    this.internalUserForm.reset();
    this.authenticationType.setValue('os');
    this.accountStatus.setValue('unlock');
    this.mapUserBy.setValue('byUserName');
  }

  toggleShow(flag: boolean = false) {
    if(flag) {
      if(this.internalUserForm.controls.password.value === '') {
        this.showPassword = false;
      }
      return;
    }
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPassShow(flag: boolean = false) {
    if(flag) {
      if(this.internalUserForm.controls.confirmPassword.value === '') {
        this.showConfirmPassword = false;
      }
      return;
    }
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
