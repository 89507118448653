<div class="mt-2 position-relative">
  <div class="table-list-container card" [ngStyle]="{'height': isShowResultTab && heading === 'SQL Editor' ? 'auto' : '70vh' }">
  <!-- <div class="table-list-container card"> -->
    <app-table-list [session]="session" [selectedTableName]="route.snapshot.queryParams.table"
      (tableSelected)="setSelectedTable($event)" (createIndexData)="createIndexData.emit($event)"></app-table-list>
  </div>
  <div [ngStyle]="{ 'height': (heading === 'SQL Editor' && isShowResultTab) ? '250px' : '73vh' }">
    <div *ngIf="session; else sessionLoadingTemplate" class="mt-2">
      <div *ngIf="heading === 'Info'" [style.marginLeft.px]="265">
        <ng-container *ngIf="selectedTable; else databaseTemplate">
          <p class="my-2">
            <a [routerLink]="[]" [queryParams]="{ database: session.database.name }">
              {{session.database.name}}
            </a>
            <span class="mx-1">&gt;</span>
            {{selectedTable.name}}
          </p>
          <app-table-info-page *ngIf="selectedTable" [session]="session" [table]="selectedTable"
            (browseTable)="onBrowseTable(selectedTable)" (openCreateIndex)="createIndexData.emit($event)">
          </app-table-info-page>
        </ng-container>
        <ng-template #databaseTemplate>
          <div class="row pt-2 pl-3">
            <label class="font-weight-bold pt-2">
              {{ 'schemaManager.databaseDetails' | translate }} :
            </label>
            <button type="button" class="btn ml-1 pt-1" *ngFor="let tab of tabNames"
              (click)="tabName = tab" [ngClass]="{'btn-primary': tab === tabName, 'btn-secondary': tab !== tabName}">{{tab}}</button>
          </div>
          <div *ngIf="tabName === tabNames[0]">
            <app-database-info *ngIf="!selectedTable" [currentDB]="session.database.name" [server]="server">
            </app-database-info>
          </div>
          <div *ngIf="tabName === tabNames[1]">
            <app-database-procedure *ngIf="!selectedTable" [session]="session" [currentDB]="session.database.name" [server]="server">
            </app-database-procedure>
          </div>
          <div *ngIf="tabName === tabNames[2]">
            <app-database-sequence *ngIf="!selectedTable" [session]="session" [server]="server">
            </app-database-sequence>
          </div>
          <div *ngIf="tabName === tabNames[3]">
            <app-database-user *ngIf="!selectedTable" [session]="session" [server]="server"></app-database-user>
          </div>
          <div *ngIf="tabName === tabNames[4]">
            <app-schema-privileges *ngIf="!selectedTable" [currentDB]="session.database.name" [server]="server">
            </app-schema-privileges>
          </div>
          <div *ngIf="tabName === tabNames[5]">
            <app-database-datablade *ngIf="!selectedTable" [session]="session" [server]="server">
            </app-database-datablade>
          </div>
          <div *ngIf="tabName === tabNames[6]">
            <app-database-aggregates *ngIf="!selectedTable" [session]="session" [server]="server">
            </app-database-aggregates>
          </div>
          <div *ngIf="tabName === tabNames[7]">
            <app-database-casts *ngIf="!selectedTable" [session]="session" [server]="server"></app-database-casts>
          </div>
          <div *ngIf="tabName === tabNames[8]">
            <app-database-operator *ngIf="!selectedTable" [session]="session" [server]="server">
            </app-database-operator>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="session; else sessionLoadingTemplate" class="mt-2">
      <div *ngIf="heading === 'SQL Editor'">
        <app-sql-console [session]="session" [inputQuery]="inputQuery" (showResultEvent)="isShowResultTab = $event"></app-sql-console>
      </div>
    </div>

    <ng-template #sessionLoadingTemplate>
      <div class="mt-2 text-center">
        <i class="icon icon-circle-notch icon-spin icon-2x"></i>
      </div>
    </ng-template>
  </div>
</div>
