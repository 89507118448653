/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { User } from '../../../shared/user/user';
import { userProvider } from '../../../shared/user/user.provider';
import { UsersService } from './users.service';

@Component({
  selector: 'app-system-users',
  templateUrl: 'users.html',
  styleUrls: ['./users.scss'],
  providers: [userProvider]
})
export class UsersComponent implements OnInit, AfterViewInit {

  adminUserCount = 0;

  users: User[] = null;
  expandedUser: User = null;
  passwordControl: UntypedFormControl = null;

  @ViewChild('confirmModal') confirmModal: ModalDirective;
  confirmModalMessage: string = null;
  confirmModalResolveFn: (result: boolean) => void = null;

  constructor(
    private usersService: UsersService,
    private notifications: NotificationsService,
    private currentUser: User,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit() {
    this.usersService.getUsers().then(users => {
      this.users = users;
      this.adminUserCount = 0;
      this.calculateAdminUserCount();
    }).catch(err => {
      this.notifications.pushErrorNotification('There was a problem getting the list of users');
      console.error(err);
    });

    this.usersService.eventNewUser.subscribe((user: User) => {
      if (user.isSuperAdmin()) {
        this.adminUserCount++;
      }
      this.users = this.users.concat(user);
    });
  }

  ngAfterViewInit() {
    this.confirmModal.onHide.subscribe(() => {
      this.resolveConfirmModal(false);
    });
  }

  private calculateAdminUserCount() {
    this.adminUserCount = 0;
    this.users.forEach(user => {
      if (user.isSuperAdmin() && !user.locked) {
        this.adminUserCount++;
      }
    });
  }

  updateLockStatus(user: User, $event: any) {
    $event.target.checked = !$event.target.checked;
    let lockStatus = null;
    if (user.locked) {
      lockStatus = 'unlock';
    } else {
      lockStatus = 'lock';
    }
    this.confirmationDialogService.show(lockStatus + ' the ' + user.name + ' account?', () => {
      this.usersService.updateUserLockStatus(user).then(() => {
        this.notifications.pushSuccessNotification('The account status for user ' + user.name + ' was ' + lockStatus + 'ed');
        this.ngOnInit();
      }).catch((err: HttpErrorResponse) => {
        this.notifications.pushErrorNotification('There was an error updating user lock status');
        console.error(err);
      });
    });
  }

  resetUserPassword(user: User) {
    if (this.expandedUser === user) {
      this.expandedUser = null;
      this.passwordControl = null;
    } else {
      this.expandedUser = user;
      this.passwordControl = new UntypedFormControl(null, Validators.required);
    }
  }

  userRowExpanded(user: User): boolean {
    return this.expandedUser === user;
  }

  updateUserPassword(user: User) {
    if (!this.passwordControl || this.passwordControl.invalid) {
      return;
    }

    user.password = this.passwordControl.value;
    this.usersService.updateUserPassword(user).then(() => {
      this.notifications.pushSuccessNotification('User password reset');
      this.expandedUser = null;
    }).catch((err: HttpErrorResponse) => {
      this.notifications.pushErrorNotification('There was an error reseting the user\'s passsword');
      console.error(err);
    });
  }

  removeUser(user: User) {
    this.showConfirmModal('Are you sure you want to remove the user: ' + user.name).then(result => {
      if (result) {
        this.usersService.removeUser(user).then(() => {
          const index = this.users.indexOf(user);
          this.users = this.users.slice(0, index).concat(this.users.slice(index + 1));
          if (user.isSuperAdmin() && !user.locked) {
            this.adminUserCount--;
          }
          this.notifications.pushSuccessNotification('User removed');
        }).catch((err: HttpErrorResponse) => {
          this.notifications.pushErrorNotification('There was an error removing user');
        });
      }
    });
  }

  private showConfirmModal(message: string): Promise<boolean> {
    this.confirmModalMessage = message;
    return new Promise<boolean>(resolve => {
      this.confirmModalResolveFn = resolve;
      this.confirmModal.show();
    });
  }

  resolveConfirmModal(result: boolean) {
    if (this.confirmModalResolveFn) {
      this.confirmModalResolveFn(result);
      this.confirmModalResolveFn = null;
      this.confirmModal.hide();
    }
  }

  isLastAdmin(user: User) {
    return this.adminUserCount === 1 && user.isSuperAdmin() && !user.locked;
  }

  isCurrentUser(user: User) {
    return this.currentUser.id === user.id;
  }
}
