/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';
import { serverStatusList, ClusterInfo } from '../servers/high-availability/high-availability.model';

import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerTypes } from '../../shared/hdr-permission/hdr-permission.model';


@Component({
  selector: 'app-dashboard-server-availability',
  templateUrl: 'dashboard-server-availability.html'
})
export class DashboardServerAvailabilityComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() isWorkOffline: Boolean;
  @Input() serverInfo: InformixServerStat;

  hasHA: boolean = null;
  haType: string;
  clusterInfo: ClusterInfo = null;
  cmList: any[] = null;
  cmLoading = false;
  serverStatusList = serverStatusList;
  dataLoading = false;
  private haSub: Subscription = null;
  private cmSub: Subscription = null;

  constructor(
    private serverService: InformixServerService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverInfo) {
      this.handleServerInfoUpdated();
    }
  }

  private handleServerInfoUpdated() {
    if (this.server.serverType.serverType === ServerTypes.STANDARD) {
      this.hasHA = false;
    } else {
      this.getHAInfo();
    }
    if (!this.isWorkOffline) {
      this.getConnectionManagers();
    }
  }

  private getHAInfo() {
    this.dataLoading = true;
    this.haSub = this.serverService.getServerHighAvailabilityStatus(this.server.id, false).subscribe(clusterInfo => {
      this.dataLoading = false;
      this.hasHA = true;
      this.clusterInfo = clusterInfo;
    }, err => {
      this.dataLoading = false;
      console.error('Could not get high availability info', err);
    });
  }

  private getConnectionManagers() {
    this.skipLoader();
    this.cmSub = this.serverService.getConnectionManagers(this.server.id).subscribe(connectionManagers => {
      /*
       * skip loading connection managers - on isWorkOffline flag if already called status API
       */
      if (!this.isWorkOffline) {
        this.cmLoading = false;
        this.cmList = connectionManagers;
      } else{
        this.cmLoading = false;
      }
      this.cmList = connectionManagers;
      this.skipLoader();
    }, err => {
      this.cmLoading = false;
      console.error('Could not get connection manager info', err);
    });
  }

  // hide status loader when connection manager list is empty - idsdb00111850
  skipLoader() {
    if (!this.cmList) {
      this.cmLoading = true;
    } else {
      this.cmLoading = !(this.cmList && this.cmList.length >= 0);
    }
  }

  ngOnDestroy() {
    if (this.haSub) {
      this.haSub.unsubscribe();
    }
    if (this.cmSub) {
      this.cmSub.unsubscribe();
    }
  }
}
