/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InformixServer } from '../../../informixServer';
import { InformixTable } from '../../informix-table';
import { BasicIndexDetails } from './../create-index.model';
import { SchemaService } from '../../schema.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-query',
  templateUrl: './create-query.component.html',
  styleUrls: ['../create-index.component.scss']
})
export class CreateQueryComponent implements OnInit {
  @Input() server: InformixServer;
  @Input() table: InformixTable;
  @Input() dataObj: BasicIndexDetails;

  @Output() backFromQuery = new EventEmitter<any>();
  @Output() closeCreateIndex = new EventEmitter<any>();

  createIndexQuery: string;
  constraintQuery: string;
  isQueryLoaded = false;
  createIndexError: string = null;
  finalObj: BasicIndexDetails;

  constructor(private schemaService: SchemaService,
    private notificationsService: NotificationsService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.finalObj = JSON.parse(JSON.stringify(this.dataObj));
    this.createIndex('createQuery');
  }

  createIndex(type) {
    this.setData();
    this.finalObj.isExecute = (type === 'executeQuery');
    this.schemaService.createIndex(this.table, this.finalObj).subscribe(res => {
      this.createIndexQuery = res.createIndexQuery ? res.createIndexQuery : null;
      this.constraintQuery = res.constraintQuery ? res.constraintQuery : null;
      if (res.ok) {
        let indexSuccessMsg: string;
        this.translate.get('schemaManager.createIndex.createQuery.indexSuccessMsg').subscribe((text: string) => {
          indexSuccessMsg = text;
        });
        this.notificationsService.pushSuccessNotification(indexSuccessMsg);
        this.closeCreateIndex.emit();
      }
      this.isQueryLoaded = true;
    }, err => {
      let unknownError: string;
      this.translate.get('schemaManager.tableInfo.unknownError')
        .subscribe((text: string) => {
          unknownError = text;
        });
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.createIndexError = err.error.err;
      } else {
        this.createIndexError = unknownError;
      }
      this.isQueryLoaded = true;
    });
  }

  setData() {
    this.finalObj.columnsList.forEach(item => {
      delete item.isChecked;
      delete item.length;
      delete item.type;
      if (this.finalObj.indexType === 'fot' || this.finalObj.indexType === 'xml') {
        delete item.order;
      }
    });
    if (this.finalObj.indexType === 'fot' || this.finalObj.indexType === 'xml' || this.finalObj.indexType === 'cluster') {
      delete this.finalObj.isOnline;
      if (this.finalObj.indexType !== 'cluster') {
        delete this.finalObj.fillFactor;
      }
      if (this.finalObj.indexType === 'fot' && !this.finalObj.subtrees) {
        this.finalObj.subtrees = 2;
      }
      if (!this.finalObj.xmlTags) {
        this.finalObj.xmlTags = { useCustomized: 0 };
      }
      if (!this.finalObj.stopWords) {
        this.finalObj.stopWords = { useCustomized: 0 };
      }
    }
    if (this.finalObj.indexType !== 'unique') {
      delete this.finalObj.constraintName;
      delete this.finalObj.isPrimary;
    } else {
      if (this.finalObj.constraintName === null) {
        delete this.finalObj.constraintName;
      }
      if (this.finalObj.isPrimary === null) {
        delete this.finalObj.isPrimary;
      }
    }
    if (this.finalObj.indexType !== 'xml') {
      delete this.finalObj.xmlTags;
      delete this.finalObj.isXMLPathProcessing;
      delete this.finalObj.isIncludeNameSpaces;
      delete this.finalObj.isIncludeSubtagText;
    }
    if (this.finalObj.indexType === 'xml' || this.finalObj.indexType === 'cluster') {
      delete this.finalObj.isCompressed;
    }
    if (this.finalObj.indexType !== 'fot') {
      delete this.finalObj.subtrees;
    }
    if (this.finalObj.firstExtent === null) {
      delete this.finalObj.firstExtent;
    }
    if (this.finalObj.nextExtent === null) {
      delete this.finalObj.nextExtent;
    }
    delete this.finalObj.exptRows;
    delete this.finalObj.growthRate;
  }
}
