/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { ERNode, ERDomain } from './er-domain';
import { InformixServer } from '../informixServer';

// This class is used to generate a fake ER domain (for testing/presentations).

export class FakeERDomainGenerator {
  nodeIndex = 0;

  generateERNode(): ERNode {
    const index = this.nodeIndex++;
    const json: any = {
      id: index,
      name: 'er_node_' + index,
      isHub: true,
      isLeaf: true,
      serverState: 'A',
      members: [
        {
          dbServerName: 'server_' + index
        }
      ]
    };

    if (Math.random() < 0.2) {
      json.members.push({ dbServerName: 'server_' + index + '_2' });
    }

    if (Math.random() > 0.9) {
      json.serverState = ['A', 'D', 'S', 'Q', 'U'][Math.floor(Math.random() * 5)];
    } else {
      json.serverState = 'A';
    }

    const node = new ERNode(json);

    const profileJson: any = {
      name: 'utm_group_4',
      time: '2019-07-23 03:48:36',
      ableToConnect: 'Y',
      version: '14.10.FC1',
      supportsCommand: 'Y',
      ERState: 'Active',
      ddr: {
        state: 'Running',
        current: '6:3903488',
        snoopy: '6:3899460',
        replay: '6:24',
        pagesFromDDRBLOCK: 19046
      },
      disk: {
        total: 50000,
        metadataFree: 2522,
        userdataFree: 46557
      },
      sendQ: {
        txnInQueue: 0,
        txnSpooled: 0,
        acksPending: 0
      },
      network: {
        state: 'Running',
        numberOfConnections: 1,
        expectedConnections: 1,
        msgSent: 2039,
        msgReceived: 1561,
        throughput: 26.93,
        pendingMsg: 0
      },
      receiveQ: {
        txnInQueue: 0,
        txnInPendingList: 0
      },
      apply: {
        state: 'Running',
        txnProcessed: 4,
        commitRate: 0.0,
        avgActiveApply: 1.25,
        failRate: 0.0,
        totalFailures: 0,
        avgLatency: 0.0,
        maxLatency: 0.0,
        atsFileCount: 0,
        risFileCount: 0
      }
    };

    node.addProfile(profileJson);

    return node;
  }

  generateERDomain(server: InformixServer, rootNodes: number = 1): ERDomain {
    // Define the number of max children at every layer
    const nodeCounts = [3, 3, 3, 3, 2];
    const domain = new ERDomain(server, { nodes: [] });

    const queue: any[] = [];

    for (let i = 0; i < rootNodes; i++) {
      const rootNode = this.generateERNode();
      domain.nodes.push(rootNode);
      queue.push({ level: 0, count: this.randomizeNodeCount(nodeCounts[0]), node: rootNode });
    }

    while (queue.length) {
      const queueItem = queue.shift();
      for (let i = 0; i < queueItem.count; i++) {
        const node = this.generateERNode();
        node.setParent(queueItem.node);
        node.parent.isLeaf = false;
        domain.nodes.push(node);
        if (queueItem.level < nodeCounts.length - 1) {
          queue.push({ level: queueItem.level + 1, count: this.randomizeNodeCount(nodeCounts[queueItem.level + 1]), node });
        } else {
          node.isHub = false;
        }
      }
    }

    domain.origin = domain.nodes[0];

    return domain;
  }

  randomizeNodeCount(maxCount: number) {
    // return maxCount;
    return 1 + Math.floor(Math.random() * maxCount);
  }
}
