/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';

interface SizeUnit {
  bytes: number;
  label: string;
}

/* Note: the VALUE of this component is expressed in KB. */

const SIZE_UNITS: SizeUnit[] = [
  { bytes: 1, label: 'KB' },
  { bytes: 1024, label: 'MB' },
  { bytes: 1024 * 1024, label: 'GB' },
];

@Component({
  selector: 'app-input-size',
  template: `
	<form class="form-inline" [formGroup]="formGroup">
		<input type="number" class="form-control" [ngClass]="formClass" min="0" formControlName="value">
		<select class="form-control ml-2" [ngClass]="formClass" formControlName="unit">
			<option *ngFor="let unit of sizeUnits" [ngValue]="unit">{{unit.label}}</option>
		</select>
	</form>
	`,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SizeInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: SizeInputComponent, multi: true }
  ]
})
export class SizeInputComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() minUnit: number;
  @Input() maxUnit: number;
  @Input() formClass: string;

  private onChangeFunction: any;

  sizeUnits = SIZE_UNITS;
  formGroup: UntypedFormGroup;
  disabled = false;

  ngOnInit() {
    this.sizeUnits = SIZE_UNITS;
    if (this.minUnit) {
      this.sizeUnits = this.sizeUnits.filter((entry) => entry.bytes >= this.minUnit);
    }
    if (this.maxUnit) {
      this.sizeUnits = this.sizeUnits.filter((entry) => entry.bytes <= this.maxUnit);
    }
    this.formGroup = new UntypedFormGroup({
      value: new UntypedFormControl('0', [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      unit: new UntypedFormControl(this.sizeUnits[0], Validators.required)
    });

    this.formGroup.valueChanges.subscribe(() => {
      const controls = this.formGroup.controls;
      let value = parseInt(controls['value'].value, 10);
      if (!isNaN(value)) {
        value *= controls['unit'].value.bytes;
      } else {
        value = null;
      }

      if (this.onChangeFunction) {
        this.onChangeFunction(value);
      }
    });
  }

  writeValue(value: any) {
    if (typeof value === 'number') {
      if (value === 0) {
        this.formGroup.controls['value'].setValue(0);
        this.formGroup.controls['unit'].setValue(this.sizeUnits[0]);
      } else {
        for (let i = this.sizeUnits.length - 1; i >= 0; i--) {
          if (value / this.sizeUnits[i].bytes >= 1) {
            this.formGroup.controls['value'].setValue(value / this.sizeUnits[i].bytes);
            this.formGroup.controls['unit'].setValue(this.sizeUnits[i]);
            break;
          }
        }
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any) {

  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (c.value === null) {
      return { required: true };
    }
    return null;
  }
}
