<h5 class="card-title">Overall Storage Space Usage</h5>
<div *ngIf="dataLoading" style="text-align: center">
  <h4><i class="icon icon-circle-notch icon-spin"></i></h4>
</div>
<div class="row ml-1 mr-1" style="width: 100%;">

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger">
      Unable to retrieve storage usage information. <br>
      Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <ng-template [ngIf]="!dataLoadErrorMessage">
    <canvas #storageSummaryBarChart width="500" height="180"></canvas>
  </ng-template>
</div>
