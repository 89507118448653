/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { GroupBreadcrumb } from '../groups/groupBreadcrumb';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { InformixServerGroupService } from '../groups/informixServerGroup.service';
import { MonitoringService } from '../monitoring/monitoring.service';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { Sensor } from '../monitoring/sensor';
import { TimeSliceService } from '../monitoring/time-slice.service';
import { TimeSlice } from '../monitoring/timeSlices';
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';
import { ServerBreadcrumb } from './serverBreadcrumb';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-server-dashboard',
  templateUrl: 'serverDashboard.html',
  providers: [TimeSliceService]
})
export class ServerDashboardComponent implements OnInit, OnDestroy {

  private group: InformixServerGroup = null;
  private workOffSub: Subscription = null;

  server: InformixServer = null;
  monitoringProfile: MonitoringProfile = null;
  breadcrumb: BreadcrumbElement[] = null;
  serverInfo: InformixServerStat = null;
  isWorkOffline: Boolean;
  showGraphControls = false;
  viewMonitoredData = true;
  isOnline: Boolean = false;

  allSensorsList: string[] = [
    'checkpoint', 'diskio', 'fgwrites', 'seqscans', 'session_stats', 'os_memory', 'os_cpu', 'thread_counts', 'os_disk_usage'
  ];

  constructor(
    private serverService: InformixServerService,
    private groupsService: InformixServerGroupService,
    private monitoringService: MonitoringService,
    private timeSliceService: TimeSliceService,
    private route: ActivatedRoute,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.workOffSub = this.serverService.onConnStatus$.subscribe(value => {
      this.isWorkOffline = value;
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;

    this.groupsService.getGroup(this.server.parentGroupId).then(group => {
      this.group = group;
      this.breadcrumb = GroupBreadcrumb.build(this.group, ServerBreadcrumb.build(this.server));
    }).catch(err => {
      console.error('Error getting group info', err);
    });

    this.getMonitoringProfile();
    this.updateConnectionStatus(this.server.isWorkOffline);
  }

  serverInfoUpdated(serverInfo: InformixServerStat) {
    if(serverInfo != null) {
      this.serverInfo = serverInfo;
    };

    // Refresh the server object every time serverInfo is updated
    // because the server object has the current agent status.
    this.serverService.getServer(this.server.id.toString()).then(server => {
      this.server = server;
    });
  }

  serverTypeUpdated(serverType) {
    if(this.server) {
      this.server.serverType = serverType;
    }
  }

  public getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;

      if (this.server.agent && this.server.agent.online) {
        this.showGraphControls = false;
        this.allSensorsList.forEach(sensorId => {
          const sensor: Sensor = this.monitoringProfile.getSensor(sensorId);
          if (sensor != null && !sensor.disabled) {
            this.showGraphControls = true;
          }
        });
      } else {
        this.showGraphControls = false;
      }
    }).catch(err => {
      console.error('Error getting monitoring profile info', err);
    });
  }

  public getLink(relativePath: string = '') {
    return '/dashboard/servers/' + this.server.id + relativePath;
  }

  public timeSliceChanged(timeSlice: TimeSlice) {
    this.timeSliceService.changeTimeSlice(timeSlice);
  }

  public pauseData() {
    this.timeSliceService.pauseData();
  }

  public resumeData() {
    this.timeSliceService.resumeData();
  }

  isAdminPermission(){
      if (this.server.permissions.admin && this.server.hasAdminPassword) {
        return true;
      }
      return false;
  }
  /*
  * toogle work offline/online
  * update connection status on toogled value
  */
  workOffline(isWorkOffline: Boolean) {
    this.isWorkOffline = isWorkOffline;
    this.updateConnectionStatus(this.isWorkOffline);
    this.serverService.getWorkOffline(this.server, this.isWorkOffline).subscribe(res => {
      const msg = this.isWorkOffline ? 'Work-offline Success' : 'Work-online Success';
      this.notifications.pushSuccessNotification(msg);
    }, err => {
      const msg = `There was a problem saving work- ${this.isWorkOffline ? 'offline' : 'online'}`;
      this.notifications.pushErrorNotification(msg);
      this.isWorkOffline = !this.isWorkOffline;
    });
  }

  updateConnectionStatus(isWorkOffline: Boolean) {
    this.isWorkOffline = isWorkOffline;
    if (isWorkOffline) {
      this.serverInfo = null;
    }
    // update isWorkOffline flag
    this.serverService.setConnectionStatus(isWorkOffline);
  }

  ngOnDestroy(): void {
    if(this.workOffSub) {
      this.workOffSub.unsubscribe();
    }
  }
}
