/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ChartJSUtils } from '../../../../../shared/chartjs.utils';
import { Color } from '../../../../../shared/color';

@Component({
  selector: 'app-color-picker-button',
  templateUrl: './color-picker-button.component.html',
  styleUrls: ['./color-picker-button.component.scss']
})
export class ColorPickerButtonComponent implements OnChanges {

  _color: Color;
  hexColor: string;

  @Input() color: string;

  @Output() colorChanged = new EventEmitter<string>();

  defaultColors = ChartJSUtils.defaultColors;

  @ViewChild('popover') popover: PopoverDirective;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.color && this.color !== this.hexColor) {
      this.hexColor = this.color;
      this._color = new Color(this.hexColor);
    }
  }

  onColorChanged(color: Color) {
    this._color = color;
    this.hexColor = this._color.getCssHex();
  }

  onColorPickerHidden() {
    this.colorChanged.emit(this.hexColor);
  }

  selectColor(hexColor: string) {
    this.onColorChanged(new Color(hexColor));
    if (this.popover) {
      this.popover.hide();
    }
  }
}
