<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-6">
      <h4><strong>{{ 'systemReports.pageTitle' | translate }}</strong>
      </h4>
    </div>
    <div class="col-6 form-inline justify-content-end">
      <label class="mr-2">
        {{ 'dataTable.filterLabel' | translate }}
      </label>
      <select class="form-control" [(ngModel)]="selectedCategory" (ngModelChange)="filterReports($event)">
        <option value="all">{{ 'common.all' | translate }}</option>
        <option *ngFor="let category of reportCategories" [value]="category">
          {{ 'systemReports.' + category | translate }}</option>
      </select>
      <a *ngIf="canCreateCustomReport" routerLink="/dashboard/system-settings/reports/edit"
        class="btn btn-sm btn-primary ml-2" [queryParams]="{ server: server.id, source: 'system-reports' }">
        {{ 'systemReports.createCustomReport' | translate }}
      </a>
    </div>
  </div>
  <div *ngIf="reportList">
    <app-data-table class="table-responsive" [enableFilter]="false" [data]="reportList" [sortDescending]="false"
      [rowsPerPage]="20" [searchLabel]="'systemReports.searchLabel' | translate" sortBy="name"
      [noDataAlertMessage]="'systemReports.noReportsFound' | translate">
      <app-data-table-column [title]="'systemReports.name' | translate" property="name" [sortable]="true"
        [searchable]="true">
        <ng-template appTableCell let-row="row" let-item="item">
          <a *ngIf="row.path" [routerLink]="['/dashboard', 'servers', server.id, 'system-reports', row.path]">
            {{item}}
          </a>
          <a *ngIf="!row.path" [routerLink]="['/dashboard', 'servers', server.id, 'system-reports', 'generic', row.id]">
            {{item}}
          </a>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'common.description' | translate" property="description" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
    </app-data-table>
  </div>
</div>
