/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { GeneralComponent } from './general-autoUpdateStatistics.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutoUpdateStatisticsComponent } from './autoUpdateStatistics.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigurationComponent } from './configuration-autoUpdateStatistics.component';
import { AlertsComponent } from './alerts-autoUpdateStatistics.component';
import { CommandsComponent } from './commands-autoUpdateStatistics.component';
import { AutoUpdateStatisticsService } from './autoUpdateStatistics.service';
import { TaskSchedulerModule } from '../task-scheduler/task-scheduler.module';
import { DbPriorityModalComponent } from './dbPriorityModal.component';
import { NgDragDropModule } from 'ng-drag-drop';

@NgModule({
  imports: [
    SharedModule,
    TooltipModule,
    ReactiveFormsModule,
    TaskSchedulerModule,
    NgDragDropModule.forRoot()
  ],
  declarations: [
    AutoUpdateStatisticsComponent,
    GeneralComponent,
    ConfigurationComponent,
    AlertsComponent,
    CommandsComponent,
    DbPriorityModalComponent
  ],
  providers: [AutoUpdateStatisticsService]
})
export class AutoUpdateStatisticsModule { }
