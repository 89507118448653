/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/

export class JSONTreeElement {
  color = '#000';

  constructor(
    public key: string,
    public value: string,
    public originalValue: any,
    public type: string) { }

  public static buildForwardRef(json: any, offset: number = 0): JSONTreeElement[] {
    return null;
  }
}

export class JSONTreeGroupElement extends JSONTreeElement {
  public static builder: JSONTreeBuilder;

  tree: JSONTreeElement[] = null;
  expanded = false;

  constructor(
    public key: string,
    public value: string,
    public originalValue: any,
    public type: string = 'object'
  ) {
    super(key, value, originalValue, type);
  }

  collapse() {
    this.expanded = false;
  }

  expand() {
    this.expanded = true;
    if (this.expanded && !this.tree) {
      this.tree = JSONTreeElement.buildForwardRef(this.originalValue, this.getOffset());
    }
  }

  expandAll() {
    this.expand();
    this.tree.forEach(item => {
      if (item instanceof JSONTreeGroupElement) {
        item.expandAll();
      }
    });
  }

  protected getOffset() {
    return 0;
  }
}

export class JSONTreeArrayElement extends JSONTreeGroupElement {
  offset = 0;

  constructor(
    public key: string,
    public value: string,
    public originalValue: any[]
  ) {
    super(key, value, originalValue, 'array');
  }

  protected getOffset() {
    return this.offset;
  }
}

export class JSONTreeBuilder {

  public static build(json: any, offset: number = 0): JSONTreeElement[] {
    const tree: JSONTreeElement[] = [];

    if (Array.isArray(json)) {
      if (json.length > 200) {
        let step = 2000;
        while (json.length >= step) {
          step *= 10;
        }
        step /= 20;

        for (let i = 0; i < json.length; i += step) {
          const rightBound = Math.min(i + step - 1, json.length) + offset;
          const key = '[' + (offset + i) + '..' + rightBound + ']';
          const element = JSONTreeBuilder.getTreeElementFromJson(key, json.slice(i, i + step)) as JSONTreeArrayElement;
          element.offset = i + offset;
          tree.push(element);
        }
      } else {
        for (let i = 0; i < json.length; i++) {
          tree.push(JSONTreeBuilder.getTreeElementFromJson((i + offset).toString(), json[i]));
        }
      }

      return tree;
    }

    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        tree.push(JSONTreeBuilder.getTreeElementFromJson(key, json[key]));
      }
    }
    return tree;
  }

  private static getTreeElementFromJson(key: string, jsonValue: any): JSONTreeElement {
    if (typeof jsonValue === 'object' && jsonValue !== null) {
      if (Array.isArray(jsonValue)) {
        return new JSONTreeArrayElement(key, 'Array[' + jsonValue.length + ']', jsonValue);
      } else {
        return new JSONTreeGroupElement(key, '{...}', jsonValue);
      }
    } else {
      let value: string;
      let type: string;
      let color: string = null;

      if (jsonValue === undefined) {
        value = 'undefined';
        type = 'undefined';
        color = '#777';
      } else if (jsonValue === null) {
        value = 'null';
        type = 'null';
        color = '#777';
      } else if (typeof jsonValue === 'boolean') {
        value = jsonValue ? 'true' : 'false';
        type = 'boolean';
        color = '#e8730b';
      } else if (typeof jsonValue === 'number') {
        value = jsonValue.toString();
        type = 'number';
        color = '#0850bf';
      } else if (typeof jsonValue === 'string') {
        value = '"' + jsonValue + '"';
        type = 'string';
        color = '#b10101';
      }

      const element = new JSONTreeElement(key, value, jsonValue, type);
      if (color) {
        element.color = color;
      }
      return element;
    }

  }
}

JSONTreeElement.buildForwardRef = JSONTreeBuilder.build;
