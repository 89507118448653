<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="clearfix">
    <h4 class="d-inline-block"><strong>{{ 'highAvailability.pageTitle' | translate }}</strong></h4>
    <button *ngIf="selectedTabIndex !== 3 && selectedTabIndex !== 1" class="float-right btn btn-sm btn-icon"
      (click)="refreshData()"><i class="icon icon-refresh font-weight-bold"></i></button>
  </div>
  <app-tab-group (eventUpdate)="setSelectedTabIndex($event)">
    <app-tab [label]="'highAvailability.tabLabel.clusterTopology' | translate">
      <app-cluster-topology *ngIf="selectedTabIndex === 0" [server]="server">
      </app-cluster-topology>
    </app-tab>
    <app-tab [label]="'highAvailability.tabLabel.clusterMetrics' | translate">
      <app-cluster-metrics *ngIf="selectedTabIndex === 1" [server]="server"></app-cluster-metrics>
    </app-tab>
    <app-tab [label]="'highAvailability.tabLabel.smxInfo' | translate">
      <app-smx-info *ngIf="selectedTabIndex === 2" [server]="server"></app-smx-info>
    </app-tab>
    <app-tab [label]="'highAvailability.tabLabel.configuration' | translate">
      <app-onconfig *ngIf="selectedTabIndex === 3" [server]="server"
        onconfigClass="mt-4" [showCategories]="false" [parameters]="onconfigParameters"
        [serverWarning]="serverNotInCluster" [filterByOptions]="filterByOptions"
        (filterOptionChange)="filterOptionChange($event)">
      </app-onconfig>
    </app-tab>
  </app-tab-group>
</div>
<ng-template #loading>
  <div *ngIf="dataLoading" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
  </div>
  <div *ngIf="serverError" class="mt-4 alert alert-danger">
    <i class="icon icon-danger text-danger"></i>Unable to retrieve  {{serverError}}
  </div>
</ng-template>
