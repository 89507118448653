/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InformixTreeItem } from '../informixTreeItem';
import { InformixServer } from '../servers/informixServer';
import { AlertingCriteria, AlertingProfile } from './alerting-profile';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AlertingService {

  constructor(private httpClient: HttpClient) { }

  private getUrl(owner: InformixTreeItem): string {
    return owner instanceof InformixServer ? 'informix/' + owner.id : 'informix/groups/' + owner.id;
  }

  public getAlerts(owner: InformixTreeItem): Observable<AlertingProfile> {
    return this.httpClient.get<AlertingProfile>(this.getUrl(owner) + '/alerting')
      .pipe(map(json => new AlertingProfile(json)));
  }

  public updateAlerts(owner: InformixTreeItem, alerts: AlertingCriteria[], inherited: any[]): Promise<AlertingProfile> {
    return this.httpClient.put<AlertingProfile>(this.getUrl(owner) + '/alerting', { alerts, inherited })
      .toPromise<AlertingProfile>()
      .then(json => new AlertingProfile(json));
  }
}
