<ng-container *ngIf="isDataLoad else policiesOptimizationLoadingInfo;">
  <div class="row mt-3">
    <div class="col-12">
      <label class="font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.optimizationLabel' | translate }}</label>
    </div>
  </div>
  <div class="row pl-4 pt-2">
    <div class="col-12">
      <label class="font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.policiesLabel' | translate }}</label>
    </div>
  </div>
  <div class="row pl-4">
    <div class="col-12 pt-1">
      <app-checkbox [checked]="!allDisable" (change)="configurePolicies('tk_enable', $event)">
        {{ 'tablesIndexes.optimizationPolicies.enablePolicies' | translate }}
      </app-checkbox>
    </div>
  </div>
  <form [formGroup]="policiesForm">
    <div class="row pl-5 pt-2">
      <div class="col-6 pl-4">
        <label class="font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.policy' | translate }}</label>
      </div>
      <div class="col-6">
        <label class="font-weight-bold">{{ 'common.values' | translate }}</label>
      </div>
    </div>
    <div class="row pl-5 pt-2">
      <div class="col-6 pl-4">
        <app-checkbox [checked]="taskParameters.isCompress" (change)="configurePolicies('compress', $event)"
          [disabled]="allDisable">
          {{ 'tablesIndexes.optimizationPolicies.policiesList.compress' | translate }}
        </app-checkbox>
      </div>
      <div class="col-6"><input type="number" formControlName="compressTables"
          class="input-style policies-input-medium mr-2 pl-2" min="0" max="2147483647" placement="right" tooltip=""
          appExtendedFormControl>{{ 'tablesIndexes.table.columnTitle.rows' | translate }}</div>
    </div>
    <div class="row pt-2 pl-5">
      <div class="col-6 pl-4">
        <app-checkbox [checked]="taskParameters.isRepack" (change)="configurePolicies('repack', $event)"
          [disabled]="allDisable">
          {{ 'tablesIndexes.optimizationPolicies.policiesList.repack' | translate }}
        </app-checkbox>
      </div>
      <div class="col-6"><input type="number" placement="right" tooltip="" appExtendedFormControl min="1" max="100"
          formControlName="repackTables" class="input-style policies-input-small mr-2 pl-2">%
      </div>
    </div>
    <div class="row pt-2 pl-5">
      <div class="col-6 pl-4">
        <app-checkbox [checked]="taskParameters.isShrink" (change)="configurePolicies('shrink', $event)"
          [disabled]="allDisable">
          {{ 'tablesIndexes.optimizationPolicies.policiesList.shrink' | translate }}
        </app-checkbox>
      </div>
      <div class="col-6"><input placement="right" tooltip="" appExtendedFormControl type="number" min="1" max="100"
          formControlName="shrinkTables" class="input-style policies-input-small mr-2 pl-2">%
      </div>
    </div>
    <div class="row pt-2 pl-5">
      <div class="col-6 pl-4">
        <app-checkbox [checked]="taskParameters.isDefragment" (change)="configurePolicies('defragment', $event)"
          [disabled]="allDisable">{{ 'tablesIndexes.optimizationPolicies.policiesList.defragment' | translate }}
        </app-checkbox>
      </div>
      <div class="col-6"><input placement="right" tooltip="" appExtendedFormControl type="number" min="1"
          max="2147483647" formControlName="defragmentTables" class="input-style policies-input-small mr-2 pl-2">
        {{ 'tablesIndexes.table.columnTitle.extents' | translate }}
      </div>
    </div>
    <div class="row pt-2 pl-5">
      <div class="col-6 pl-4">
        <app-checkbox [checked]="taskParameters.isRemove" [disabled]="allDisable"
          (change)="configurePolicies('remove', $event)">
          {{ 'tablesIndexes.optimizationPolicies.policiesList.remove' | translate }}
        </app-checkbox>
      </div>
    </div>
    <div class="row pt-2 pl-5">
      <div class="col-6 pl-4 pt-3">
        <label class="font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.setTimeLabel' | translate }}</label>
      </div>
    </div>
    <div class="row ml-0 pl-5 pt-2">
      <div class="col-sm-6 col-lg-6 col-xl-3 pl-2">
        <div><label class="pt-2 mb-0">{{ 'inputLabel.startTime' | translate }}</label></div>
        <div class="d-inline-flex form-group align-items-center pl-0">
          <input type="number" placeholder="HH" class="input-style policies-input-small pr-0 mr-1 pl-2" tooltip=""
            appExtendedFormControl formControlName="start_time_hh" min="0" max="23">:
          <input type="number" placeholder="MM" formControlName="start_time_mm"
            class="input-style policies-input-small pr-0 pl-2 ml-1 mr-1" tooltip="" appExtendedFormControl min="0"
            max="59">:
          <input type="number" placeholder="SS" formControlName="start_time_ss"
            class="input-style policies-input-small pr-0 pl-2 ml-1 mr-1" tooltip="" appExtendedFormControl min="0"
            max="59">
        </div>
      </div>
      <div class="col-sm-6 col-lg-6 col-xl-3 pl-2">
        <div>
          <span>{{ 'inputLabel.stopTime' | translate }}</span>
          <app-checkbox class="ml-2" [checked]="neverStopTime" (change)="configurePolicies('neverStopTime', $event)"
            [disabled]="allDisable">
            {{ 'inputLabel.neverLabel' | translate }}
          </app-checkbox>
        </div>
        <div class="col-sm-6 col-lg-3 col-xl-3 d-inline-flex form-group align-items-center col-3 pl-0">
          <input type="number" placeholder="HH" formControlName="stop_time_hh"
            class="input-style policies-input-small pr-0 mr-1 pl-2" tooltip="" appExtendedFormControl min="0" max="23">:
          <input type="number" placeholder="MM" class="input-style policies-input-small pr-0 pl-2 ml-1 mr-1" tooltip=""
            appExtendedFormControl min="0" max="59" formControlName="stop_time_mm">
          <input type="number" placeholder="SS" class="input-style policies-input-small pr-0 pl-2 ml-1 mr-1" tooltip=""
            appExtendedFormControl min="0" max="59" formControlName="stop_time_ss">
        </div>
      </div>
    </div>
    <div class="row ml-0 pl-5 pt-2">
      <div class="align-items-center col-3 pl-2">
        <label class="mb-0">{{ 'tablesIndexes.optimizationPolicies.inputLabel.repeatLabel' | translate }}</label><br>
        <div class="d-inline-flex align-items-center pl-0">
          <label class="pr-4">{{ 'inputLabel.days' | translate }}</label>
          <label class="pl-3">{{ 'inputLabel.hours' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="row ml-0 pl-5">
      <div class="d-inline-flex form-group align-items-center col-3 pl-2">
        <input type="number" class="input-style policies-input-small pr-0 mr-1 pl-2" tooltip="" appExtendedFormControl
          min="0" max="99" formControlName="repeat_days">:
        <input type="number" class="input-style policies-input-small pr-0 pl-2 ml-1 mr-1" tooltip=""
          appExtendedFormControl formControlName="repeat_hours" min="0" max="23">
      </div>
    </div>
    <div class="row pl-5 pt-2">
      <div class="col-12 pl-4">
        <label>{{ 'tablesIndexes.optimizationPolicies.inputLabel.selectedDaysLabel' | translate }}</label>
      </div>
    </div>
    <div class="row ml-0 pb-3 pl-5 pt-1">
      <div class="btn-group-toggle pl-2" data-toggle="buttons">
        <label class="btn btn-primary" *ngFor="let day of selectedTaskDays; let i=index"
          [ngClass]="{ 'disabled-text': !selectedTaskDays[i].checked }">
          <input type="checkbox" [disabled]="allDisable"
            (change)="selectedTaskDays[i].checked=$event.target.checked" [checked]="selectedTaskDays[i].checked">
          {{day.tk_day}}
        </label>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-12">
        <app-check-server-admin-permissions [server]="server" placement="top">
          <button type="button" class="btn btn-primary" [disabled]="!policiesForm.valid" (click)="updatePolicies()"
            appPermissionDisable>{{ 'button.updatePolicies' | translate }}</button>
        </app-check-server-admin-permissions>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #policiesOptimizationLoadingInfo>
  <div class="d-flex justify-content-center" *ngIf="isLoading else errorMessage;">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-4"></i>
  </div>
  <ng-template #errorMessage>
    <div class="alert alert-danger mt-2">
      Unable to retrieve server optimization policies information. <br>
      Error: {{apiError}} 
    </div>
  </ng-template>
</ng-template>
