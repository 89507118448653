/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartJSUtils } from '../../shared/chartjs.utils';
import { AbstractDashboardComponent } from '../monitoring/abstract-dashboard-component';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';


@Component({
  selector: 'app-dashboard-server-storage-performance',
  templateUrl: 'dashboard-server-storage-performance.html'
})
export class DashboardServerStoragePerformanceComponent extends AbstractDashboardComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() serverInfo: InformixServerStat;
  @Input() monitoringProfile: MonitoringProfile;
  @Input() viewMonitoredData: boolean;

  storagePerfInfo: any = null;
  readCachePercentage: number = null;
  writeCachePercentage: number = null;
  checkpointSensorRunning = false;
  diskioSensorRunning = false;
  fgWritesSensorRunning = false;
  seqScansSensorRunning = false;

  checkpointChartColor = ChartJSUtils.getDefaultColor(2);
  fgWritesChartColor = ChartJSUtils.getDefaultColor(4);
  seqScansChartColor = ChartJSUtils.getDefaultColor(7);

  @ViewChild('cacheHitRateSensorGraph') cacheHitRateSensorGraph: AbstractDashboardComponent;
  @ViewChild('checkpointSensorGraph') checkpointSensorGraph: AbstractDashboardComponent;
  @ViewChild('fgWritesSensorGraph') fgWritesSensorGraph: AbstractDashboardComponent;
  @ViewChild('seqScansSensorGraph') seqScansSensorGraph: AbstractDashboardComponent;

  private storagePerfSub: Subscription = null;

  constructor(
    private informixServerService: InformixServerService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server || changes.monitoringProfile) {
      this.checkIfSensorsAreRunning();
    }
    if (changes.serverInfo || changes.viewMonitoredData) {
      this.getStoragePerformanceData();
    }
  }

  private checkIfSensorsAreRunning() {
    this.checkpointSensorRunning = this.isSensorRunning('checkpoint');
    this.diskioSensorRunning = this.isSensorRunning('diskio');
    this.fgWritesSensorRunning = this.isSensorRunning('fgwrites');
    this.seqScansSensorRunning = this.isSensorRunning('seqscans');
  }

  private isSensorRunning(sensorId: string): boolean {
    if (!this.server || !this.server.agent || !this.server.agent.online || !this.server.agent.isConfigured || !this.monitoringProfile) {
      return false;
    }
    const sensor = this.monitoringProfile.getSensor(sensorId);
    return sensor != null && !sensor.disabled;
  }

  private getStoragePerformanceData() {
    if (!this.serverInfo) {
      return;
    }

    this.storagePerfSub = this.informixServerService.getDashboardStoragePerformance(this.server.id).subscribe(info => {
      this.storagePerfInfo = info;
      this.readCachePercentage = this.computeCachePercentage(info.pf_bufreads, info.pf_dskreads);
      this.writeCachePercentage = this.computeCachePercentage(info.pf_bufwrites, info.pf_dskwrites);
    }, err => {
      console.error('Could not get storage performance dashboard info', err);
    });
  }

  private computeCachePercentage(x: number, y: number): number {
    if (x === 0 || x < y) {
      return 0.0;
    } else {
      const percentage = (x - y) * 100.0 / x;
      return Math.round(percentage * Math.pow(10, 2)) / Math.pow(10, 2);
    }
  }

  ngOnDestroy() {
    if (this.storagePerfSub) {
      this.storagePerfSub.unsubscribe();
    }
  }
}
