/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
const DEFAULT_CACHE_TIMEOUT = 1000;

export interface CacheableResource {
  getId(): string;
}

interface CachedResource {
  resource: CacheableResource;
  expire: number;
}

export class ResourceCache<T extends CacheableResource> {
  private cache: { [key: string]: CachedResource } = {};
  private promiseCache: { [key: string]: Promise<T> } = {};

  constructor(
    private cacheTimeout: number = DEFAULT_CACHE_TIMEOUT
  ) {

  }

  public get(id: string): Promise<T> {
    const now = (new Date()).getTime();
    const resource = this.cache[id];
    if (resource && resource.expire > now) {
      return Promise.resolve(resource.resource as T);
    }

    const promise = this.promiseCache[id];
    if (promise) {
      return promise;
    }

    return null;
  }

  public put(resource: T) {
    const expire = (new Date()).getTime() + this.cacheTimeout;
    this.cache[resource.getId()] = { resource, expire };
  }

  public putPromise(id: string, promise: Promise<T>) {
    this.promiseCache[id] = promise;
    promise.then(resource => {
      this.put(resource);
    }).catch(err => {
      console.error(err);
    }).then(() => {
      delete this.promiseCache[id];
    });
  }
}
