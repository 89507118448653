/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timeSince' })
export class TimeSincePipe implements PipeTransform {
  transform(value: number, timestamp: number): string {
    if (!timestamp) {
      return moment(value).fromNow();
    } else {
      return moment(value).from(timestamp);
    }
  }
}
