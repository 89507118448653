/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export class BuildInfo {
  public version: string;
  public buildDescription: string;
  public buildDate: string;
  public buildEngineHostName: string;
}

@Injectable()
export class BuildInfoService {

  private buildInfo: BuildInfo = null;

  constructor(private httpClient: HttpClient) { }

  private requestBuildInfo(): Promise<BuildInfo> {
    return this.httpClient.get<BuildInfo>('').toPromise<BuildInfo>()
      .then(buildInfo => {
        this.buildInfo = buildInfo;
        return buildInfo;
      });
  }

  public getBuildInfo(): Promise<BuildInfo> {
    if (this.buildInfo) {
      return Promise.resolve(this.buildInfo);
    } else {
      return this.requestBuildInfo();
    }
  }
}
