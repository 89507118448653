/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
export interface SystemReport {
  id: string;
  path: string;
  category?: string;
  name?: string;
  description?: string;
}

export class CustomSystemReport implements SystemReport {
  id: string;
  name?: string;
  description?: string;
  database: string;
  sql: string;
  testServerId?: number;
  category?: string;
  columns: ReportColumn[];
  path = null;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.database = json.meta.database;
    this.sql = json.meta.sql;
    this.testServerId = json.meta.testServerId;
    this.category = json.meta.category;
    this.columns = json.meta.columns;
  }
}

export interface ReportColumn {
  id: string;
  name: string;
  unit?: string;
}

export const systemReportCategories: string[] = ['locks', 'performance', 'sql', 'storage', 'tables', 'users', 'misc'];
