/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AdminAPIDataTableComponent } from './admin-api-data-table.component';
import { AdminAPILogComponent } from './admin-api-log.component';
import { InformixServerLogsService } from './informixServerLogs.service';
import { LogWindowComponent } from './log-window.component';
import { MonitoringModule } from '../../monitoring/monitoring.module';
import { InformixLogComponent } from './informix-log.component';
import { SharedModule } from '../../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SystemLogsComponent } from '../../system-logs/system-logs.component';

@NgModule({
  imports: [
    SharedModule,
    MonitoringModule,
    AlertModule,
    CommonModule,
    BsDropdownModule,
    RouterModule,
    TooltipModule,
    ReactiveFormsModule
  ],
  declarations: [
    AdminAPIDataTableComponent,
    AdminAPILogComponent,
    InformixLogComponent,
    LogWindowComponent,
    SystemLogsComponent
  ],
  exports: [
    AdminAPIDataTableComponent,
    LogWindowComponent
  ],
  providers: [InformixServerLogsService],
})
export class LogsModule { }
