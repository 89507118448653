/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SecondsToIntervalPipe } from '../../../../shared/pipes/secondsToInterval.pipe';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { JSONUtils } from '../../../../shared/json-utils';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { GroupBreadcrumb } from '../../../groups/groupBreadcrumb';
import { InformixServerGroup } from '../../../groups/informixServerGroup';
import { InformixServerGroupService } from '../../../groups/informixServerGroup.service';
import { InformixServer } from '../../../servers/informixServer';
import { InformixServerService } from '../../../servers/informixServer.service';
import { ServerBreadcrumb } from '../../../servers/serverBreadcrumb';
import { Dashboard } from '../dashboard';
import { DashboardBasePanel } from '../dashboard-base-panel';
import { DashboardGrid } from '../dashboard-grid';
import { DashboardHttpService } from '../dashboard-http.service';
import { DashboardPanel } from '../dashboard-panel';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewChecked, OnDestroy {

  private originServer: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;

  group: InformixServerGroup = null;
  servers: InformixServer[] = null;
  dashboard: Dashboard = null;
  grid: DashboardGrid = null;
  gridCssStyleSub: Subscription = null;

  isEditing = false;
  isFullscreen = false;
  nameFormControl: UntypedFormControl = null;
  editingPanel: DashboardPanel = null;
  duplicatingPanel: DashboardPanel = null;
  isCreatingNewPanel = false;
  editingPanelConfig: any = null;
  duplicate = false;

  private selectServersModal: BsModalRef = null;
  selectedServers: InformixServer[] = null;

  private saveDashboardTimeout: number = null;

  @ViewChild('dashboardContainer') dashboardContainer: ElementRef;
  @ViewChild('serverListPopover') serverListPopover: PopoverDirective;
  @ViewChild('selectServersModal') selectServersModalTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private groupService: InformixServerGroupService,
    private serverService: InformixServerService,
    private dashboardHttpService: DashboardHttpService,
    private dashboardService: DashboardService,
    private modalService: BsModalService,
    private notifications: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(() => {
      if (this.group) {
        this.setGroup(this.group);
      }
    });

    this.route.data.subscribe(data => {
      if (data.group) {
        this.setGroup(data.group);
      } else if (data.server) {
        this.originServer = data.server;
        this.setServers([data.server]);
        this.getGroupForServer(data.server);
      }
    });
  }

  ngAfterViewChecked() {
    if (this.grid && this.dashboardContainer && !this.grid.renderWidth) {
      this.updateGridRenderWidth();
    }
  }

  ngOnDestroy() {
    if (this.saveDashboardTimeout) {
      window.clearTimeout(this.saveDashboardTimeout);
    }
    if (this.gridCssStyleSub) {
      this.gridCssStyleSub.unsubscribe();
    }
  }

  private getGroupForServer(server: InformixServer) {
    this.groupService.getGroup(server.parentGroupId).then(group => {
      this.setGroup(group);
    }).catch(err => {
      this.notifications.pushErrorNotification('There was a problem loading the dashboard');
      console.error(err);
    });
  }

  private setGroup(group: InformixServerGroup) {
    this.group = group;
    this.isEditing = this.group.permissions.admin;
    if (this.route.snapshot.params.dashboardId === 'new') {
      if (!this.group.permissions.admin) {
        return this.navigateToDashboardList();
      }

      this.dashboard = new Dashboard(this.group);
      this.buildBreadcrumb();
      this.createDashboardGrid();
      if (!this.servers) {
        this.setServers([]);
      }
    } else {
      const dashboardId = parseInt(this.route.snapshot.params.dashboardId, 10);
      if (isNaN(dashboardId)) {
        this.navigateToDashboardList();
      } else if (!this.dashboard || dashboardId !== this.dashboard.id) {
        this.loadDashboard(this.route.snapshot.params.dashboardId);
      }
    }
  }

  private navigateToDashboardList() {
    this.router.navigate(['..'], { relativeTo: this.route, replaceUrl: true });
  }

  private buildBreadcrumb() {
    if (!this.group || !this.dashboard) {
      return;
    }

    // HACK: So weird... groups and servers routes are set up and behave differently
    const dashboardsLink = this.originServer ? 'dashboards' : '..';

    this.breadcrumb = [
      { name: 'Dashboards', link: dashboardsLink },
      { name: this.dashboard.config.name }
    ];
    if (this.originServer) {
      this.breadcrumb = ServerBreadcrumb.build(this.originServer, this.breadcrumb);
    }
    this.breadcrumb = GroupBreadcrumb.build(this.group, this.breadcrumb);
  }

  private loadDashboard(dashboardId: number) {
    if (!this.group || !dashboardId) {
      return;
    }

    this.dashboard = null;
    this.dashboardHttpService.getDashboard(this.group, dashboardId).subscribe(dashboard => {
      this.dashboard = dashboard;
      this.buildBreadcrumb();
      this.createDashboardGrid();
      if (this.dashboard.config.timeSlice !== null)  {
        this.dashboardService.setSelectedIntervalOption(new SecondsToIntervalPipe().transform(this.dashboard.config.timeSlice));
      } else {
        this.dashboardService.setSelectedIntervalOption(new SecondsToIntervalPipe().transform(14400));
      }
      if (!this.servers) {
        if (this.dashboard.config.defaultServerIds.length) {
          this.serverService.getServers(this.dashboard.config.defaultServerIds).then(servers => {
            this.setServers(servers);
          }, err => {
            this.notifications.pushErrorNotification('There was a problem loading the dashboard');
            console.error(err);
          });
        } else {
          this.setServers([]);
        }
      }
    }, (err: HttpErrorResponse) => {
      if (err.status === 404) {
        this.navigateToDashboardList();
      } else {
        this.notifications.pushErrorNotification('There was a problem loading the dashboard');
        console.error(err);
      }
    });
  }

  private createDashboardGrid() {
    this.grid = new DashboardGrid();

    if (this.gridCssStyleSub) {
      this.gridCssStyleSub.unsubscribe();
    }
    this.gridCssStyleSub = this.grid.cssStyleChanged.pipe(debounceTime(0)).subscribe(() => this.updateGridRenderWidth());

    this.dashboard.config.panels.forEach(panelConfig => {
      this.grid.addPanel(new DashboardPanel(panelConfig));
    });

    if (this.grid.panels.length < 1) {
      this.grid.calculatePotentialPanels();
    }
  }

  private updateGridRenderWidth() {
    if (this.grid && this.dashboardContainer && !this.editingPanel) {
      const rect = (this.dashboardContainer.nativeElement as HTMLElement).getBoundingClientRect();
      this.grid.renderX = rect.left;
      this.grid.renderY = rect.top;
      this.grid.setRenderWidth(rect.width);
    }
  }

  private hidePotentialPanels() {
    if (this.grid && this.grid.panels.length > 0) {
      this.grid.clearPotentialPanels();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.updateGridRenderWidth();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    if (this.grid) {
      this.grid.onMouseMove(event);
      if (this.grid.activePanel) {
        this.hidePotentialPanels();
      }
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    if (this.grid) {
      const shouldSave = !!this.grid.activePanel;
      this.grid.onMouseUp();

      if (shouldSave) {
        this.scheduleSaveDashboard();
      }
    }
  }

  @HostListener('document:click')
  onClick() {
    this.hidePotentialPanels();
  }

  onServerListPopoverShown() {
    this.selectServersModal = null;
    this.selectedServers = this.servers ? this.servers.slice() : [];
  }

  onServerListPopoverHidden() {
    if (!this.selectServersModal) {
      this.applySelectedServers();
    }
  }

  shiftSelectedServerUp(event: any, index: number) {
    const server = this.selectedServers[index - 1];
    this.selectedServers[index - 1] = this.selectedServers[index];
    this.selectedServers[index] = server;
    event.stopPropagation();
  }

  shiftSelectedServerDown(event: any, index: number) {
    const server = this.selectedServers[index + 1];
    this.selectedServers[index + 1] = this.selectedServers[index];
    this.selectedServers[index] = server;
    event.stopPropagation();
  }

  removeSelectedServer(event: any, index: number) {
    this.selectedServers.splice(index, 1);
    event.stopPropagation();
  }

  openSelectServersModal() {
    if (this.selectServersModalTemplate) {
      this.selectServersModal = this.modalService.show(this.selectServersModalTemplate);
      if (this.serverListPopover) {
        this.serverListPopover.hide();
      }
    }
  }

  applySelectedServers() {
    if (this.selectedServers) {
      if (!this.areServerListsSame(this.servers, this.selectedServers)) {
        this.setServers(this.selectedServers);
        this.selectedServers = null;
      }

      if (this.selectServersModal) {
        this.selectServersModal.hide();
      }
    }
  }

  private areServerListsSame(a: InformixServer[], b: InformixServer[]): boolean {
    if ((!a || !b) && a !== b) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i].id !== b[i].id) {
        return false;
      }
    }

    return true;
  }

  setServers(servers: InformixServer[]) {
    this.servers = servers;
    this.dashboardService.setServers(this.servers);
  }

  saveDefaultServerList() {
    if (this.selectedServers) {
      this.dashboard.config.defaultServerIds = this.selectedServers.map(server => server.id);
      this.scheduleSaveDashboard();
      this.notifications.pushSuccessNotification('Default server list saved');
    }
  }

  editDashboardName() {
    if (this.isEditing) {
      this.nameFormControl = new UntypedFormControl(this.dashboard.config.name);
    }
  }

  saveName() {
    if (!this.isEditing) {
      return;
    }

    const newName = this.nameFormControl.value.trim();
    if (newName) {
      this.dashboard.config.name = this.nameFormControl.value.trim();
      this.scheduleSaveDashboard();
      this.buildBreadcrumb();
    }
    this.closeDashboardNameEdit();
  }

  closeDashboardNameEdit() {
    this.nameFormControl = null;
  }

  startAddingPanel(source?: DashboardPanel) {
    this.duplicatingPanel = source;
    if (!this.duplicatingPanel) {
      this.duplicate = false;
    }
    if (this.grid.panels.length > 0) {
      window.setTimeout(() => {
        this.grid.calculatePotentialPanels();
        if (this.duplicatingPanel) {
          this.onPotentialPanelClick(this.grid.potentialPanels[0]);
        }
      }, 0);
    } else {
      if (!this.grid.potentialPanels) {
        this.grid.calculatePotentialPanels();
      }
      this.onPotentialPanelClick(this.grid.potentialPanels[0]);
    }
  }

  onPotentialPanelClick(source: DashboardBasePanel) {
    if (!this.isEditing) {
      return;
    }

    if (this.duplicatingPanel) {
      const panel = new DashboardPanel(JSONUtils.deepClone(this.duplicatingPanel.toJson()));
      panel.x = source.x;
      panel.y = source.y;
      panel.width = source.width;
      panel.height = source.height;
      this.grid.addPanel(panel);
      this.scheduleSaveDashboard();
      this.duplicatingPanel = null;
    } else {
      const panel = new DashboardPanel({
        x: source.x,
        y: source.y,
        width: source.width,
        height: source.height,
        title: 'New Panel',
        type: 'sensor-chartjs',
        config: null
      });

      this.editingPanel = panel;
      this.isCreatingNewPanel = true;
    }
  }

  stopEditingPanel(applyChanges: boolean) {
    if (this.editingPanelConfig && applyChanges) {
      this.editingPanel.contentConfig = this.editingPanelConfig;
      if (this.isCreatingNewPanel) {
        this.grid.addPanel(this.editingPanel);
      }
      this.scheduleSaveDashboard();
    }
    this.editingPanel = null;
    this.editingPanelConfig = null;
  }

  onPanelChanged() {
    this.scheduleSaveDashboard();
  }

  onPanelEdit(panel: DashboardPanel) {
    this.editingPanel = panel;
    this.isCreatingNewPanel = false;
  }

  onPanelDuplicate(panel: DashboardPanel) {
    this.startAddingPanel(panel);
    this.duplicate = true;
  }

  onPanelDelete(panel: DashboardPanel) {
    this.confirmationDialogService.show('delete the panel ' + panel.title + '?', () => {
      this.grid.removePanel(panel);
      if (this.grid.panels.length < 1) {
        this.grid.calculatePotentialPanels();
      }
      this.scheduleSaveDashboard();
    });
  }

  private scheduleSaveDashboard() {
    if (this.saveDashboardTimeout) {
      window.clearTimeout(this.saveDashboardTimeout);
    }

    this.saveDashboardTimeout = window.setTimeout(() => this.saveDashboard(), 500);
  }

  saveTimeslice() {
    if (this.group.permissions.admin) {
    this.scheduleSaveDashboard();
    }
  }

  private saveDashboard() {
    const dashboardJson: any = {
      config: {
        name: this.dashboard.config.name,
        panels: this.grid.panels.map(panel => panel.toJson()),
        defaultServerIds: this.dashboard.config.defaultServerIds,
        new: false,
        timeSlice: this.dashboardService.selectedIntervaloption.seconds
      }
    };

    if (typeof this.dashboard.id === 'number') {
      this.dashboardHttpService.updateDashboard(this.dashboard, dashboardJson).subscribe(dashboard => {
        this.dashboard = dashboard;
      }, err => {
        this.notifications.pushErrorNotification('There was a problem saving the dashboard');
        console.error(err);
      });
    } else {
      this.dashboardHttpService.createDashboard(this.group, { newDashboard: [dashboardJson] }).subscribe(dashboard => {
        this.dashboard = dashboard[0];
        this.router.navigate(['../', this.dashboard.id], { relativeTo: this.route, replaceUrl: true });
      }, err => {
        this.notifications.pushErrorNotification('There was a problem saving the dashboard');
        console.error(err);
      });
    }
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    window.setTimeout(() => this.updateGridRenderWidth(), 0);
  }
}
