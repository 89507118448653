<div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
  <button id="action-button" [disabled]="disabled" type="button" class="btn btn-primary dropdown-toggle" dropdownToggle>
    {{label}} <span class="caret"></span>
  </button>
  <ul class="dropdown-menu dropdown-menu-right" [ngStyle]="{'max-height': maxHeight, 'overflow': 'auto', 'min-width': minWidth}" role="menu" aria-labelledby="action-button" *dropdownMenu (click)="$event.stopPropagation()">
    <li *ngIf="showSearch" role="menuitem">
      <label class="dropdown-item no-hover w-100">
        <input type="text" class="form-control" [formControl]="searchControl" placeholder="Search for servers...">
      </label>
    </li>
    <li *ngIf="showSearch" class="divider dropdown-divider"></li>
    <li *ngIf="showSelectAll && _data.length > 0" role="menuitem">
      <label class="dropdown-item w-100 cursor-pointer" (click)="doSelectAll()">
        <i class="icon" [ngClass]="selectAll ? 'icon-check-on' : 'icon-check-off'"></i>
        Select All...
      </label>
    </li>
    <li *ngIf="showSelectAll && _data.length > 0" class="divider dropdown-divider"></li>
    <li *ngIf="_data.length === 0" role="menuitem">
      <label class="dropdown-item w-100">
        No data available
      </label>
    </li>
    <li role="menuitem" *ngFor="let item of _data">
      <label class="dropdown-item w-100 cursor-pointer" (click)="onSelect(item)">
        <i class="icon" [ngClass]="item.selected ? 'icon-check-on' : 'icon-check-off'"></i>
        {{item.label}}
      </label>
    </li>
  </ul>
</div>
