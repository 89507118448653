<app-data-table [data]="tableDetails.privileges" sortBy="grantee"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.privileges' | translate">
  <app-data-table-column [title]="'common.user' | translate" property="grantee" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'common.privileges' | translate">
    <ng-template appTableCell let-tablePrivilegeInfo="row">
      <div class="privilege-text rounded-circle text-center d-inline-block align-middle"
        *ngFor="let privilege of tablePrivilegeInfo.tabauthlist" [ngClass]="getColor(privilege)"
        [tooltip]="getTooltip(privilege)">
        {{privilege}}
      </div>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.grantor' | translate" property="grantor"
    [sortable]="true" [searchable]="true"></app-data-table-column>
</app-data-table>
