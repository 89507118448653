/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { InformixServer } from '../../informixServer';
import { InformixTableDetails, InformixTableColumn } from '../informix-table';
import { IndexedColumns } from './create-index.model';
import { DataTableRow } from '../../../../shared/data-table/data-table.component';
import { SchemaService } from '../schema.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { IACValidators } from '../../../../shared/forms/validators';

@Component({
  selector: 'app-create-index',
  templateUrl: './create-index.component.html',
  styleUrls: ['./create-index.component.scss']
})

export class CreateIndexComponent implements OnInit {

  @Input() server: InformixServer;
  @Input() createIndexData: any;
  @Output() closeCreateIndex = new EventEmitter<any>();

  createIndexForm: UntypedFormGroup;
  indexName: UntypedFormControl;
  indexType: UntypedFormControl;
  constraintName: UntypedFormControl;
  constraintType: UntypedFormControl;
  fillFactor: UntypedFormControl;

  tableDetailsLoadError: string = null;
  isConstraint = false;
  indexedColumnsList: IndexedColumns[] = [];
  columnsEnableSelect = true;
  indexedColumnsSelect = false;
  tableColumnsList = [];
  disableRightChevron = true;
  disableLeftChevron = true;
  isIndexLoaded = false;
  tableDetails: InformixTableDetails;
  isDuplicateIndex: boolean;
  selectedIndexedColumns: any;
  isCreateQuery = false;
  isIndexOptions = false;
  dataObj: any = {};
  isPrimaryDisabled = false;
  schemeErrorMsg: string;
  isCheckingDuplicateIndex = false;
  isReloadTable = true;
  textByte = ['text', 'byte'];

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private schemaService: SchemaService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.indexName = new UntypedFormControl(null, [this.duplicateIndexConstraintCheck('index'), Validators.required,
     IACValidators.informixIdentifierValidator]);
    this.indexType = new UntypedFormControl('unique', [Validators.required]);
    this.constraintName = new UntypedFormControl();
    this.constraintType = new UntypedFormControl('primary');
    this.fillFactor = new UntypedFormControl(90, [Validators.min(1), Validators.max(100), Validators.required]);
    this.createIndexForm = this.fb.group({
      indexName: this.indexName,
      indexType: this.indexType,
      constraintName: this.constraintName,
      constraintType: this.constraintType,
      fillFactor: this.fillFactor
    });
    this.indexedColumnsList.push({ id: null, indexedColumnName: null, isChecked: false, order: null, hash: null });
    this.getTableDetails();
  }

  getTableDetails() {
    this.schemaService.getTableDetails(this.createIndexData).subscribe(tableDetails => {
      this.isIndexLoaded = true;
      this.tableDetails = tableDetails;
      this.tableColumnsList = [...this.tableDetails.columns];
      if (this.tableDetails.constraints && this.tableDetails.constraints.length > 0) {
        if (this.tableDetails.constraints.findIndex(item => item.type === 'P') >= 0) {
          this.isPrimaryDisabled = true;
          this.constraintType.setValue('unique');
        }
      }
    }, err => {
      let unknownError: string;
      this.translate.get('schemaManager.tableInfo.errorLoadingTableDetails')
        .subscribe((text: string) => {
          this.tableDetailsLoadError = text;
        });
      this.translate.get('schemaManager.tableInfo.unknownError')
        .subscribe((text: string) => {
          unknownError = text;
        });
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.tableDetailsLoadError += err.error.err;
      } else {
        this.tableDetailsLoadError += unknownError;
      }
    });
  }

  duplicateIndexConstraintCheck(type: string): ValidatorFn {
    return (): { customError: string } | null => {
      if (type === 'index') {
        let error: string;
        this.translate.get('schemaManager.createIndex.duplicateIndexName').subscribe((text: string) => {
          error = text + this.createIndexData.name;
        });
        const errors = { customError: error };
        if (this.createIndexForm && this.indexName) {
          this.indexName.setErrors({ customError: null });
          if (this.tableDetails && this.tableDetails.indexes && this.tableDetails.indexes.length > 0) {
            if (this.tableDetails.indexes.findIndex(item => item.name === this.indexName.value) === -1) {
              return null;
            } else {
              return errors;
            }
          } else {
            return null;
          }
        }
      } else {
        let error: string;
        this.translate.get('schemaManager.createIndex.duplicateConstraintName').subscribe((text: string) => {
          error = text;
        });
        const errors = { customError: error };
        if (this.tableDetails && this.tableDetails.constraints && this.tableDetails.constraints.length > 0) {
          if (this.tableDetails.constraints.findIndex(item => item.name === this.constraintName.value) === -1) {
            return null;
          } else {
            return errors;
          }
        } else {
          return null;
        }
      }
    };
  }

  addRowsIndexedColumn(rows: any) {
    let id: number;
    if (this.indexedColumnsList[0].id === null) {
      this.indexedColumnsList.pop();
    }
    for (let i = 0; i < rows.length; i++) {
      id = this.indexedColumnsList.length > 0 ? this.indexedColumnsList[this.indexedColumnsList.length - 1].id + 1 : 1;
      this.indexedColumnsList.push({
        id,
        indexedColumnName: rows[i].name,
        isChecked: false,
        order: 'ASC',
        hash: false,
        type: rows[i].type
      });
      this.tableColumnsList = this.tableColumnsList.filter(item => item.name !== rows[i].name);
    }
    this.indexedColumnsList = [...this.indexedColumnsList];
    this.indexedColumnsSelect = true;
    if (this.tableColumnsList.length === 0) {
      this.disableRightChevron = true;
      this.tableColumnsList.push({ name: null, type: null, isChecked: false });
      this.columnsEnableSelect = false;
    } else {
      this.disableRightChevron = !this.tableColumnsList.some(item => item.isChecked === true);
    }
    this.compareArrays();
  }

  compareArrays() {
    const indexedColumnsArr = [];
    let existingIndexArr = [];
    this.isDuplicateIndex = false;
    this.schemeErrorMsg = null;
    this.indexedColumnsList.forEach(element => {
      indexedColumnsArr.push(element.indexedColumnName);
      if (element.type && this.textByte.some(el => element.type.toLowerCase().indexOf(el) !== -1)) {
        this.translate.get('schemaManager.createIndex.byteTextError').subscribe((text: string) => {
          this.schemeErrorMsg = text;
        });
        this.isDuplicateIndex = true;
      }
    });
    if (this.tableDetails.indexes && !this.schemeErrorMsg) {
      for (let i = 0; i < this.tableDetails.indexes.length; i++) {
        existingIndexArr = this.tableDetails.indexes[i].columnNames.split(', ');
        this.isDuplicateIndex = (indexedColumnsArr.length === existingIndexArr.length) &&
          indexedColumnsArr.every((ele, index) => ele === existingIndexArr[index]);
        if (this.isDuplicateIndex) {
          this.translate.get('schemaManager.createIndex.sameColumnsError').subscribe((text: string) => {
            this.schemeErrorMsg = text;
          });
          break;
        }
      }
    }
    if (this.indexType.value === 'fot' && !this.schemeErrorMsg && this.indexedColumnsList[0].id !== null) {
      this.validateHash('addColumns');
    }
    this.indexedColumnsList = [...this.indexedColumnsList];
  }

  deleteRowsIndexedColumn(rows: any) {
    this.disableLeftChevron = true;
    const filteredRow = [];
    if (this.tableColumnsList[0].name === null) {
      this.tableColumnsList.pop();
    }
    for (let i = 0; i < rows.length; i++) {
      filteredRow.push(this.tableDetails.columns.filter(item => item.name === rows[i].indexedColumnName)[0]);
      this.tableColumnsList.push({
        name: filteredRow[i].name,
        type: filteredRow[i].type,
        isChecked: false
      });
      this.indexedColumnsList = this.indexedColumnsList.filter(item => item.indexedColumnName !== rows[i].indexedColumnName);
    }
    this.tableColumnsList = [...this.tableColumnsList];
    this.columnsEnableSelect = true;
    this.resetIDs();
    if (this.indexedColumnsList.length === 0) {
      this.indexedColumnsList.push({ id: null, indexedColumnName: null, isChecked: false, order: null, hash: null });
      this.indexedColumnsSelect = false;
      this.disableLeftChevron = true;
    } else {
      this.disableLeftChevron = !this.indexedColumnsList.some(item => item.isChecked === true);
    }
    this.compareArrays();
  }

  onTableColumnsSelected(event: DataTableRow) {
    const table = event.data as InformixTableColumn;
    table.isChecked = event.selected;
    this.disableRightChevron = !this.tableColumnsList.some(element => element.isChecked === true);
  }

  onIndexedColumnsSelected(event: DataTableRow) {
    const table = event.data as IndexedColumns;
    table.isChecked = event.selected;
    this.disableLeftChevron = !this.indexedColumnsList.some(element => element.isChecked === true);
    if (table.isChecked) {
      this.selectedIndexedColumns = table;
    }
  }

  columnsDataMap(tableColumns: any): DataTableRow {
    return {
      data: tableColumns,
      selected: tableColumns.isChecked,
      disabled: false,
      expanded: false
    };
  }

  indexedDataMap(indexedColumns: any): DataTableRow {
    return {
      data: indexedColumns,
      selected: indexedColumns.isChecked,
      disabled: false,
      expanded: false
    };
  }

  moveRows(type: string) {
    const fromIndex: number = this.selectedIndexedColumns.id - 1;
    const toIndex: number = (type === 'upward') ? fromIndex - 1 : fromIndex + 1;
    this.updateIndexedColumns(fromIndex, toIndex);
    this.compareArrays();
  }

  updateIndexedColumns(fromIndex: number, toIndex: number) {
    const element = this.indexedColumnsList[fromIndex];
    this.indexedColumnsList.splice(fromIndex, 1);
    this.indexedColumnsList.splice(toIndex, 0, element);
    this.indexedColumnsList = [...this.indexedColumnsList];
    this.resetIDs();
  }

  resetIDs() {
    let id = 1;
    this.indexedColumnsList.forEach(item => {
      item.id = id++;
    });
  }

  modifyRows(type: string, data?: any, column?: any) {
    let selectedObjs = [];
    if (type === 'all') {
      selectedObjs = this.getSelectedRows(column);
    } else if (type === 'clearIndexedColumns') {
      selectedObjs = this.indexedColumnsList;
    } else {
      selectedObjs.push(data);
    }
    if (column === 'tableColumns') {
      this.addRowsIndexedColumn(selectedObjs);
    } else {
      this.deleteRowsIndexedColumn(selectedObjs);
    }
  }

  getSelectedRows(type: string): any[] {
    const rowsSelected = [];
    const tableList = (type === 'tableColumns') ? this.tableColumnsList : this.indexedColumnsList;
    tableList.forEach(table => {
      if (table.isChecked) {
        rowsSelected.push(table);
      }
    });
    return rowsSelected;
  }

  viewQuery() {
    this.isCheckingDuplicateIndex = true;
    this.getData();
    this.checkIndexName();
  }

  checkIndexName() {
    this.schemaService.indexDuplicateCheck(this.createIndexData, this.indexName.value).subscribe(response => {
      if (response) {
        let indexError: string;
        this.translate.get('schemaManager.createIndex.duplicateIndexName').subscribe((text: string) => {
          indexError = text + response.tabname;
        });
        this.indexName.setErrors({ customError: indexError });
      } else {
        this.isDuplicateIndex = false;
        this.isCreateQuery = true;
      }
      this.isCheckingDuplicateIndex = false;
    }, err => {
      this.isCheckingDuplicateIndex = false;
      this.isCreateQuery = true;
    });
  }

  getData() {
    this.dataObj.indexName = this.indexName.value;
    this.dataObj.columnsList = this.indexedColumnsList;
    this.dataObj.indexType = this.indexType.value;
    this.dataObj.fillFactor = this.fillFactor.value;
    this.dataObj.isExecute = false;
    if (this.isConstraint) {
      this.dataObj.constraintName = this.constraintName.value;
      this.dataObj.isPrimary = this.constraintType.value === 'primary';
    } else {
      this.dataObj.constraintName = null;
      this.dataObj.isPrimary = null;
    }
  }

  goToAdvanceOptions() {
    this.getData();
    this.indexedColumnsList.forEach(element => {
      const col = this.tableDetails.columns.filter(o1 => o1.name === element.indexedColumnName);
      element.length = col[0].length;
    });
    this.isIndexOptions = true;
  }

  toggleConstraint() {
    this.isConstraint = !this.isConstraint;
    if (this.isConstraint) {
      this.constraintName.setValue(null);
      this.constraintName.setValidators([Validators.required, this.duplicateIndexConstraintCheck('constraint'),
      IACValidators.informixIdentifierValidator]);
    } else {
      this.constraintName.setValue(' ');
    }
  }

  changeIndexType() {
    this.isReloadTable = false;
    setTimeout(() => this.isReloadTable = true, 0);
    if (this.indexType.value !== 'unique' && this.isConstraint) {
      this.toggleConstraint();
    }
    if (this.indexType.value === 'fot' || this.indexType.value === 'xml' || this.indexType.value === 'bts') {
      this.fillFactor.setValue(90);
    }
    if (this.indexedColumnsList[0].id) {
      this.modifyRows('clearIndexedColumns');
    }
    this.dataObj.storageScheme = null;
  }

  backFromIndexOptions(event: any) {
    setTimeout(() => this.isIndexOptions = false);
    this.dataObj = { ...event };
  }

  validateHash(type: string) {
    let isHashSelected = false;
    let hashErrorMsg: string;
    this.translate.get('schemaManager.createIndex.hashErrorMsg').subscribe((text: string) => {
       hashErrorMsg = text;
    });
    this.indexedColumnsList.filter(item => {
      if (item.hash) {
        isHashSelected = true;
      }
    });
    if (type === 'addColumns' || (type === 'selectHash' && (!this.schemeErrorMsg || this.schemeErrorMsg === hashErrorMsg))) {
      if (!isHashSelected) {
        this.schemeErrorMsg = hashErrorMsg;
        this.isDuplicateIndex = true;
      } else {
        this.isDuplicateIndex = false;
        this.schemeErrorMsg = null;
      }
    }
  }
}
