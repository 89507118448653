<app-data-table [data]="tableDetails.references" sortBy="constrname"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.references' | translate">
  <app-data-table-column [title]="'common.name' | translate" property="constrname" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'table.indexName' | translate" property="idxname" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.collation' | translate"
    property="collation" [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.referencedPrimaryKey' | translate"
    property="primaryKeys" [searchable]="true">
    <ng-template appTableCell let-value="item">
      {{value === null ? '-' : value}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.referenceTable' | translate"
    property="primarytab" [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.deleteRule' | translate" property="delrule"
    [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.status' | translate" property="state">
    <ng-template appTableCell let-state="item">
      <app-status-icon [status]="state === 'enabled'"></app-status-icon>
    </ng-template>
  </app-data-table-column>
</app-data-table>
