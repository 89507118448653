/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import { InformixServerGroup } from './informixServerGroup';
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { GroupBreadcrumb } from './groupBreadcrumb';

@Component({
  selector: 'app-group-permissions',
  template: `
  <app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
  <div class="container-fluid page-container" *ngIf="group">
    <app-user-permissions [child]="group"> </app-user-permissions>
  </div>
  `
})
export class GroupPermissionsComponent implements OnInit {

  group: InformixServerGroup = null;
  breadcrumb: BreadcrumbElement[] = [];

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadGroup(data.group);
    });
  }

  private loadGroup(group: InformixServerGroup) {
    this.group = group;
    this.breadcrumb = GroupBreadcrumb.build(this.group, [{ name: 'Permissions'}]);
  }
}
