/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/

import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { UntypedFormControl, FormControlDirective, FormControlName, ValidatorFn } from '@angular/forms';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

import { IACValidators } from './validators';

export interface ErrorMessageHandlers {
  [key: string]: string | ((error?: any) => string);
}

const defaultErrorHandlers: ErrorMessageHandlers = {
  required: 'This field is required',
  minlength: error => 'Must be at least ' + error.requiredLength + ' characters long',
  maxlength: error => 'Must be at most ' + error.requiredLength + ' characters long',
  pattern: error => 'Value must match pattern: ' + error.requiredPattern,
  min: error => 'Cannot be smaller than ' + (typeof error === 'object' ? error.min : error),
  max: error => 'Cannot be bigger than ' + (typeof error === 'object' ? error.max : error),
  customError: error => error
};

@Directive({
  selector: '[appExtendedFormControl]'
})
export class ExtendedFormControlDirective implements OnInit, OnChanges {

  @Input() min: string;
  @Input() max: string;
  @Input() hidden = false;
  @Input() useFormControlName = false;

  @Input() customErrors: ErrorMessageHandlers = null;

  private formControl: UntypedFormControl = null;
  private errorMessageHandlers: ErrorMessageHandlers;

  private useRealTimeErrors = false;

  constructor(
    @Optional() private formControlDirective: FormControlDirective,
    @Optional() private formControlName: FormControlName,
    @Optional() private tooltip: TooltipDirective,
    private elemenRef: ElementRef
  ) {
    if (this.tooltip) {
      this.tooltip.triggers = '';
    }
  }

  ngOnInit() {
    this.errorMessageHandlers = {};
    Object.assign(this.errorMessageHandlers, defaultErrorHandlers, this.customErrors || {});

    if (this.formControlDirective) {
      this.formControl = this.formControlDirective.control;
    }

    if ((!this.formControl || this.useFormControlName) && this.formControlName) {
      this.formControl = this.formControlName.control;
    }

    if (this.formControl) {
      const validators: ValidatorFn[] = this.formControl.validator ? [this.formControl.validator] : [];
      const min = parseInt(this.min, 10);
      if (!isNaN(min)) {
        validators.push(IACValidators.minNumberValidator(min));
      }
      const max = parseInt(this.max, 10);
      if (!isNaN(max)) {
        validators.push(IACValidators.maxNumberValidator(max));
      }
      this.formControl.setValidators(validators);

      const updateCallbackFn = () => this.updateTooltip();
      this.formControl.statusChanges.subscribe(updateCallbackFn);
      this.formControl.registerOnDisabledChange(updateCallbackFn);

      if (this.formControl.value !== null) {
        this.useRealTimeErrors = true;
        this.updateTooltip();
      }

      if (this.hidden) {
        this.onHiddenChange();
      }
    } else {
      console.warn('Form directive must be attached to a FormControl!');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hidden'] && this.formControl) {
      this.onHiddenChange();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    this.useRealTimeErrors = true;
    this.updateTooltip();
  }

  private onHiddenChange() {
    this.elemenRef.nativeElement.hidden = this.hidden;
    if (this.hidden) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  private updateTooltip() {
    if (!this.tooltip || !this.formControl) {
      return;
    }

    if (this.formControl.untouched && this.formControl.pristine && this.formControl.value === null) {
      this.tooltip.hide();
      this.useRealTimeErrors = false;
    }

    if (!this.useRealTimeErrors) {
      return;
    }

    if (this.formControl.valid || !this.formControl.enabled) {
      this.tooltip.tooltip = '';
      this.tooltip.hide();
    } else if (this.formControl.enabled) {
      // HACK: Setting the tooltip to an empty string will reset the underlying ngx-bootstrap component
      // Without this, the error message would not update
      this.tooltip.tooltip = '';

      this.tooltip.tooltip = this.getErrorMessage(this.formControl.errors);
      this.tooltip.show();
    }
  }

  private getErrorMessage(errors: { [key: string]: any }): string {
    let errorMessage = '';

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const handler = this.errorMessageHandlers[key];
        if (handler) {
          if (errorMessage.length > 0) {
            errorMessage += '\n';
          }
          if (typeof handler === 'string') {
            errorMessage += handler;
          } else {
            errorMessage += handler(errors[key]);
          }
        }
      }
    }

    if (!errorMessage) {
      errorMessage = 'Invalid';
    }
    return errorMessage;
  }
}
