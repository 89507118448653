<div class="card card-body" *ngIf="serverInfo || isSessionSensorRunning">
  <h4 class="card-title"><a [routerLink]="['sessions']">Sessions</a></h4>

  <div *ngIf="sessionInfo && (!viewMonitoredData || !isSessionSensorRunning)" class="row">
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Total Sessions</div>
        <div class="card-body">
          <h4>{{sessionInfo.total_sessions}}</h4>
        </div>
      </div>
    </div>
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Largest Session Memory</div>
        <div class="card-body">
          <h4>{{sessionInfo.max_session_memory | byte }}</h4>
        </div>
      </div>
    </div>
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Average Session Memory</div>
        <div class="card-body text-center">
          <h4>{{sessionInfo.average_session_memory | byte }}</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="viewMonitoredData && isSessionSensorRunning">
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Total Sessions</div>
        <div class="card-body">
          <app-sensor-chart #sessionCountSensorGraph [server]="server"
            [config]="{sensorId: 'session_stats', metricList: ['session_count'], colors: [sessionCountChartColor], showControls: false, showTitle:false}" >
          </app-sensor-chart>
        </div>
      </div>
    </div>
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Session Memory</div>
        <div class="card-body">
          <app-sensor-chart #sessionMemorySensorGraph [server]="server"
            [config]="{sensorId: 'session_stats', metricList: ['max_session_memory', 'average_session_memory'], showControls: false, showTitle: false}">
          </app-sensor-chart>
        </div>
      </div>
    </div>
  </div>

</div>
