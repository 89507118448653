/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../..//shared/shared.module';
import { IncidentsComponent } from './incidents.component';
import { IncidentsService } from './incidents.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  declarations: [
    IncidentsComponent
  ],
  exports: [
    IncidentsComponent
  ],
  providers: [
    IncidentsService
  ]
})
export class IncidentsModule { }
