/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AbstractSystemReport } from './abstract-system-report';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemReportsService } from './system-reports.service';

@Component({
  selector: 'app-session-activity-report',
  templateUrl: './session-activity-report.html'
})

export class SessionActivityReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId = 'session_activity';

  constructor(
    private route: ActivatedRoute,
    systemReportsService: SystemReportsService,
    notificationsService: NotificationsService,
    translate: TranslateService
  ) {
    super(systemReportsService, notificationsService, translate);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getSystemReport(this.reportId);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get('systemReports.sessionActivity').subscribe((text: string) => {
      this.setBreadcrumb(text);
    });
  }
}
