<form *ngIf="formGroup" [formGroup]="formGroup">
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Account SID</label>
    <div class="col-sm-6">
      <input type="text" class="form-control" formControlName="sid" tooltip="" appExtendedFormControl>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Auth Token</label>
    <div class="col-sm-6">
      <input type="text" class="form-control" formControlName="authToken" tooltip="" appExtendedFormControl>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">From Phone Number</label>
    <div class="col-sm-6">
      <input type="text" class="form-control" formControlName="fromPhone" tooltip="" appExtendedFormControl>
    </div>
  </div>
</form>
