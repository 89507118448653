/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { AdminAPILogComponent } from './admin-api-log.component';
import { ServerResolver } from '../server-resolver.service';
import { InformixLogComponent } from './informix-log.component';
import { Route } from '@angular/router';

export const LogsRoutes: Route[] = [
  {
    path: 'logs',
    children: [
      {
        path: 'onlinelog',
        component: InformixLogComponent,
        data: {
          title: 'Online Log',
          sensorId: 'online_log',
          logRotation: 'Online Log Rotation',
          logType: 'Online'
        },
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: 'onbarlog',
        component: InformixLogComponent,
        data: {
          title: 'ON-Bar Activity Log',
          sensorId: null,
          logRotation: 'ON-Bar Activity Log Rotation',
          logType: 'Onbar'
        },
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: 'adminapi',
        component: AdminAPILogComponent,
        resolve: {
          server: ServerResolver
        }
      }
    ]
  }
];
