/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { ServerResolver } from '../server-resolver.service';
import { SystemInfoDashboardComponent } from './system-info-dashboard.component';
import { Route } from '@angular/router';

export const SystemRoutes: Route[] = [
  {
    path: 'system',
    children: [
      {
        path: '',
        component: SystemInfoDashboardComponent,
        resolve: {
          server: ServerResolver
        }
      }
    ]
  }
];
