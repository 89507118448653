/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved.
 *******************************************************************************/

import { Route } from '@angular/router';
import { ServerResolver } from '../server-resolver.service';
import { ConnectionManagerComponent } from './connection-manager.component';
import { ConnectionUnitComponent } from './connection-unit.component';

export const ConnectionManagerRoutes: Route[] = [
  {
    path: 'cm',
    component: ConnectionManagerComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'cm/cmUnit',
    component: ConnectionUnitComponent,
    resolve: {
      server: ServerResolver
    }
  }
];
