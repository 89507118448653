/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { DataTableRow } from '../../../../shared/data-table/data-table.component';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { EnterpriseReplicationService } from '../enterprise-replication.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ReplicateActions, Participant, ErReplicate } from '../replicate';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-replicates',
  templateUrl: './replicates.component.html',
  styleUrls: ['./replicates.component.scss']
})
export class ReplicatesComponent implements OnInit {

  @ViewChild('startReplicateModal') startReplicateModal: ModalDirective;
  @ViewChild('confirmReplicateModal') confirmReplicateModal: ModalDirective;
  @ViewChild('checkReplicateModal', { static: false }) checkReplicateModal: TemplateRef<any>;
  @ViewChild('syncReplicateModal', { static: false }) syncReplicateModal: TemplateRef<any>;
  searchFormControl: FormControl = new FormControl();
  modalRef: BsModalRef;
  erReplicate: ErReplicate = new ErReplicate();
  breadcrumb: BreadcrumbElement[] = [];
  moitorProgressCheck: Boolean;
  moitorProgressSync: Boolean;
  showIncosistentValues: Boolean;
  registerInconsitentRow: Boolean;
  isPerformSyncOp: Boolean = false;
  indexSelected: Boolean = false;
  isAllParticipants: Boolean = false;
  isLoading: Boolean = false;
  isDomainLoading: Boolean = false;
  isParticipantLoader: Boolean = false;
  isActionInProgress: Boolean = false;
  firstPage: Boolean = true;
  server: InformixServer = null;
  formGroup: FormGroup;
  actions = ReplicateActions;
  dbServerNames = [];
  replicates;
  replicatesData;
  participants;
  selectedAction;
  replicate;
  apiError: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.setServer(data.server);
    });
    this.isLoading = true;
    this.getReplicates();

    this.formGroup = this.fb.group({
      extraTargetRows: new FormControl(null),
      queueSize: new FormControl(null),
      serverName: new FormControl(null, Validators.required),
      isPerformSyncOp: new FormControl(false)
    });

    this.searchFormControl.valueChanges.pipe(debounceTime(200)).subscribe(() => this.filterReplicates());
  }

  filterReplicates() {
    if (!this.replicates) {
      return;
    }
    const searchString = (this.searchFormControl.value || '').trim();
    if (searchString) {
      this.replicates = this.replicatesData.filter(v => v.replicateName.indexOf(searchString) > -1);
    } else {
      this.replicates = this.replicatesData;
    }
  }

  setServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(server, [
      { name: 'Replication' },
      { name: 'Replicates' }
    ]);
  }

  /*
  * API call - get All replicate replicate list
  * Enable / Disable actions to be performed on replicate
  */
  getReplicates() {
    const domainNotExistsMsg = 'Define an ER domain first to access Replicate';
    this.isLoading = true;
    this.erService.getDomain(this.server).subscribe(domain => {
      this.dbServerNames = domain.jsonNodes;
      if (this.dbServerNames && this.dbServerNames.length === 0) {
        this.isLoading = false;
        this.notificationsService.pushErrorNotification(domainNotExistsMsg);
        this.router.navigate(['dashboard', 'servers', this.server.id, 'enterprise-replication']);
        return;
      }
      this.erService.getReplicates(this.server.id).subscribe(res => {
        this.isLoading = false;
        this.replicates = res.map(replicate => {
          const activeParticipants = replicate.participants.filter(participant => participant.status === 'Active');
          return {
            ...replicate,
            activeParticipants,
            actions: this.actions
          };
        });
        this.replicatesData = this.replicates;
        this.filterReplicates();
      }, err => {
        this.isLoading = false;
        if (err instanceof HttpErrorResponse && err.error && err.error.err) {
          this.apiError = err.error.err;
        }
      });
    }, err => {
      this.isLoading = false;
      let errorMessage = '';
      if (err.status !== 404) {
        if (err instanceof HttpErrorResponse && err.error && err.error.err) {
          errorMessage += err.error.err;
        }
        this.apiError = errorMessage;
      } else if (err instanceof HttpErrorResponse && err.error && err.error.err) {
        this.notificationsService.pushInfoNotification(domainNotExistsMsg);
        this.router.navigate(['dashboard', 'servers', this.server.id, 'enterprise-replication']);
      }
    });

  }

  /*
   * perform actions on replicate on selected action
   */
  onActionChange(selectedAction, replicate) {
    this.firstPage = true;
    this.selectedAction = selectedAction;
    this.replicate = replicate;
    switch (selectedAction) {
      case 'participants':
        this.editReplicate(selectedAction);
        break;
      case 'modify':
        this.editReplicate(selectedAction);
        break;
      case 'delete':
        this.confirmReplicateModal.show();
        break;
      case 'check_replicate':
        this.updateServerNames();
        this.modalRef = this.bsModalService.show(this.checkReplicateModal, {
          backdrop: true,
          class: 'align-items-center d-flex h-100 modal-lg my-0 w-100'
        });
        break;
      case 'sync_replicate':
        this.updateServerNames();
        this.modalRef = this.bsModalService.show(this.syncReplicateModal, {
          backdrop: true,
          class: 'align-items-center d-flex h-100 modal-lg my-0 w-100'
        });
        break;
      case 'check_or_sync_tasks':
        this.router.navigate(['dashboard/servers/' + this.server.id + '/replicate/check-sync'],
        { queryParams: { replicate: this.replicate.replicateName } });
        break;
      default:
        break;
    }
  }

  updateServerNames() {
    // filter server names with those are available in replicate participants
    if (this.replicate &&
      (this.dbServerNames.length > 0 && this.replicate.participants.length > 0)) {
      this.dbServerNames = this.dbServerNames.filter(
        (server) => this.replicate.participants.some(
          (part) => server.name === part.groupName)
      );
    }
  }

  /*
   * Resets replicate form
   */
  doResetForm() {
    this.firstPage = true;
    this.formGroup.reset();
  }

  /*
   * show paricipants
   * @param selectedAction - name of selcted action replicate
   * @param replicate
   */
  showParticipants(selectedAction, replicate) {
    this.replicate = replicate;
    this.selectedAction = selectedAction;
    this.doResetForm();
    this.updateServerNames();
    this.participants = this.replicate.participants.map(item => ({
      ...item,
      modifiedTime: this.replicate.modifiedTime,
      isChecked: false,
      isDisabled: (this.selectedAction === 'start')  ? (item.partStatus === 'Inactive' ? false : true) :
      (item.partStatus === 'Active' ? false : true)
    }));
    this.isParticipantLoader = false;
    this.isAllParticipants = false;
    this.startReplicateModal.show();
  }

  editReplicate(action) {
    if (this.replicate.replicateId) {
      this.router.navigate(['/dashboard', 'servers', this.server.id, 'replicate', 'edit'],
        { queryParams: { replicateId: this.replicate.replicateId, action } });
    } else {
      this.notificationsService.pushErrorNotification('Participant details are not availble, cannot perform modify action.');
    }
  }

  /*
   * Start Replication on selected replicate
   * Prepares replicate data to send over request body
   */
  startReplication() {
    const groupNames = this.participantGroupNames();
    if (this.formGroup.invalid) {
      if(this.isAllParticipants || groupNames.length !== 0){
        this.firstPage = false;
      }
      return;
    }
    this.isParticipantLoader = true;
    const form = this.formGroup.value;
    const requestData = {
      isAllParticipant: this.isAllParticipants,
      participantGroupName: groupNames,
      isForeGround: form.isPerformSyncOp,
      queueSize: parseInt(form.queueSize, 10),
      isSynchronizeData: form.serverName !== '' ? true : false,
      syncDataSource: form.serverName,
      replicateName: this.replicate.replicateName,
      extraTargetRows: form.extraTargetRows,
    };
    this.erService.updateReplicate(this.server.id, this.selectedAction, requestData).subscribe((res: any) => {
      if (requestData.isSynchronizeData && res.result_message !== 'OK') {
        this.notificationsService.pushInfoNotification(res.result_message);
      } else {
        this.notificationsService.pushInfoNotification('Replicate Started Successfully');
      }
      this.isParticipantLoader = false;
      this.startReplicateModal.hide();
      this.getReplicates();
    }, err => {
      this.isParticipantLoader = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  /*
   * Stop Replication on selected replicate
   * Prepares replicate data to send over request body
   */
  stopReplication() {
    const groupNames = this.participantGroupNames();
    if (this.isAllParticipants || groupNames.length !== 0) {
      const requestData = {
        isAllParticipant: this.isAllParticipants,
        participantGroupName: groupNames,
        replicateName: this.replicate.replicateName
      };
      this.isParticipantLoader = true;
      this.erService.updateReplicate(this.server.id, this.selectedAction, requestData).subscribe((res: any) => {
        this.isLoading = false;
        this.notificationsService.pushSuccessNotification('Replicate Stopped Successfully');
        this.startReplicateModal.hide();
        this.getReplicates();
      }, err => {
        this.isParticipantLoader = false;
        this.notificationsService.pushErrorNotification(err);
      }
      );
    } else {
      this.notificationsService.pushWarnNotification('Please select participants to stop replication!!');
    }
  }

  /*
   * Suspend and Resume selected replicate
   */
  updateReplicate(selectedAction, replicate) {
    this.selectedAction = selectedAction;
    this.replicate = replicate;
    const requestData = {
      replicateName: this.replicate.replicateName
    };
    this.erService.updateReplicate(this.server.id, this.selectedAction, requestData).subscribe((res: any) => {
      this.notificationsService.pushInfoNotification(res.result_message);
      this.getReplicates();
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  /*
  * Delete selected replicate
  */
  removeReplicate() {
    this.confirmReplicateModal.hide();
    const requestData = {
      replicateName: this.replicate.replicateName
    };
    this.isActionInProgress = true;
    this.erService.removeReplicate(this.server.id, requestData).subscribe((res: any) => {
      if (res.result_message === 'OK') {
        this.isActionInProgress = false;
        this.notificationsService.pushSuccessNotification('Replicate Deleted Successfully');
        this.getReplicates();
      }
    }, err => {
      this.isActionInProgress = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  /*
  * Create array of group_names for selected participant for replication
  */
  participantGroupNames() {
    let groupNames = [];
    this.participants.forEach(item => {
      if (item.isChecked) {
        groupNames.push(item.groupName);
      }
    });
    return groupNames;
  }

  getFrequency(replicate) {
    const frequency = replicate.frequency;
    let freq = '';
    if (frequency.frequencyType && frequency.frequencyType === 'immediately') {
      freq = 'Immediately';
    }
    if (frequency.repeat) {
      const repeatFreq = this.erReplicate.frequencyRepeats.find(repeat => repeat.value === frequency.repeat);
      freq += repeatFreq.name;
    }
    if (frequency.dayOfMonth && frequency.dayOfMonth !== 'L') {
      freq += ' ' + frequency.dayOfMonth;
    }
    if (frequency.day) {
      freq += ' ' + frequency.day;
    }
    if (frequency.hours) {
      freq += ' ' + frequency.hours;
    }
    if (frequency.interval) {
      freq += ' ' + frequency.interval;
    }
    return freq;
  }

  checkValidAction(action, replicate) {
    switch (action.name) {
      case 'Start':
        if (replicate.participants && replicate.participants.length !== 0) {
          const inactiveItems = replicate.participants.filter(item => item.partStatus === 'Inactive');
          return inactiveItems.length > 0 ? true : false;
        } else {
          return false;
        }
      case 'Stop':
        // if replicate status === "Active" then Stop action will be visible
        return (replicate.status === 'Active') ? true : false;
      case 'Suspend':
        return (replicate.status === 'Active') ? true : false;
      case 'Resume':
        return (replicate.status === 'Server suspended') ? true : false;
      default:
        break;
    }
  }

  /*
  * Sort checked participants data
  */
  onIndexSelected(event: DataTableRow) {
    const table = event.data as Participant;
    table.isChecked = event.selected;
    this.participants.forEach(participant => {
      if (participant.groupName === event.data.groupName) {
        return participant.isChecked = event.selected;
      }
    });

    this.isAllParticipants = this.participants.every(participant => participant.isChecked);
  }

  columnsDataMap(data: any): DataTableRow {
    return {
      data,
      selected: data.isChecked,
      disabled: data.isDisabled,
      expanded: false
    };
  }

  toggle() {
    const groupNames = this.participantGroupNames();
    if(this.isAllParticipants || groupNames.length !== 0){
      this.firstPage = !this.firstPage;
    } else {
      this.notificationsService.pushWarnNotification('Please select participants to start replication!!');
    }
  }

  closeModal() {
    this.modalRef.hide();
  }
}
