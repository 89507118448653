/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { TranslateService } from '@ngx-translate/core';
import { InformixServerService } from '../informixServer.service';
import { ClusterTopologyComponent } from './cluster-topology.component';
import { SMXInfoComponent } from './smx-info.component';

@Component({
  selector: 'app-high-availability',
  templateUrl: 'high-availability.html'
})
export class HighAvailabilityComponent implements OnInit {

  server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;
  networkString: string;
  allString: string;
  selectedTabIndex = 0;
  onconfigParameters: Array<string>;
  serverNotInCluster: string;
  serverError: string;
  dataLoading = false;
  filterByOptions = [];
  allPrimaryParams = [];
  parameters = {
    allSecondaryParams: ['HA_ALIAS', 'OFF_RECVRY_THREADS', 'UPDATABLE_SECONDARY', 'TEMPTAB_NOLOG',
      'LTAPEBLK', 'SEC_DR_BUFS', 'SEC_LOGREC_MAXBUFS', 'SEC_APPLY_POLLTIME', 'CLUSTER_TXN_SCOPE', 'FAILOVER_TX_TIMEOUT'],
    rssParams: ['DELAY_APPLY', 'STOP_APPLY', 'RSS_NONBLOCKING_CKPT', 'LOG_STAGING_DIR'],
    hdrParams: ['DRAUTO', 'DRINTERVAL', 'DRTIMEOUT', 'LOG_STAGING_DIR'],
    sdsParams: ['SDS_ENABLE', 'SDS_TIMEOUT', 'SDS_TEMPDBS', 'SDS_PAGING', 'SDS_ALTERNATE'],
    allSecondaryParamsAtPrimary: ['HA_ALIAS', 'LOG_INDEX_BUILDS', 'FAILOVER_CALLBACK', 'ENABLE_SNAPSHOT_COPY', 'FAILOVER_TX_TIMEOUT'],
    connectionManagerParam: ['HA_FOC_ORDER'],
    hdrParamsAtPrimary: ['DRAUTO', 'DRINTERVAL', 'HDR_TXN_SCOPE', 'DRTIMEOUT', 'DRLOSTFOUND', 'DRIDXAUTO', 'SEC_DR_BUFS'],
    rssParamAtPrimary: ['RSS_FLOW_CONTROL'],
    sdsParamsAtPrimary: ['SDS_TIMEOUT', 'SDS_LOGCHECK', 'SDS_ALTERNATE', 'SDS_FLOW_CONTROL'],
    networkParams: ['SMX_NUMPIPES', 'SMX_COMPRESS', 'SMX_PING_INTERVAL', 'SMX_PING_RETRY', 'ENCRYPT_HDR', 'ENCRYPT_SMX']
  };

  @ViewChild(ClusterTopologyComponent) clusterTopologyComponent: ClusterTopologyComponent;
  @ViewChild(SMXInfoComponent) smxInfoComponent: SMXInfoComponent;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService, private informixServerService: InformixServerService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get(['breadcrumb.replication.title', 'breadcrumb.replication.highAvailability'])
      .subscribe(breadcrumbStrings => {
        this.breadcrumb = ServerBreadcrumb.build(this.server,
          [{ name: breadcrumbStrings['breadcrumb.replication.title'] },
          { name: breadcrumbStrings['breadcrumb.replication.highAvailability'] }]);
      });
  }

  setSelectedTabIndex(index: number) {
    this.selectedTabIndex = index;
    if (this.selectedTabIndex === 3) {
      this.translate.get('onconfig.filter.network').subscribe((text: string) => {
        this.networkString = text;
      });
      this.translate.get('onconfig.filter.all').subscribe((text: string) => {
        this.allString = text;
      });
      this.filterByOptions = [{ name: this.allString, value: 'all' }, { name: 'SDS', value: 'sdsParamsAtPrimary' },
      { name: 'HDR', value: 'hdrParamsAtPrimary' }, { name: 'RSS', value: 'rssParamAtPrimary' },
      { name: this.networkString, value: 'networkParams' }, { name: 'Connection Manager', value: 'connectionManagerParam' }];
      this.dataLoading = true;
      this.onconfigParameters = [];
      this.allPrimaryParams = [...this.parameters.allSecondaryParamsAtPrimary, ...this.parameters.connectionManagerParam,
      ...this.parameters.networkParams, ...this.parameters.hdrParamsAtPrimary, ...this.parameters.sdsParamsAtPrimary,
      ...this.parameters.rssParamAtPrimary];
      this.informixServerService.getServerHighAvailabilityStatus(this.server.id, false).subscribe(clusterInfo => {
        this.dataLoading = false;
        // using includes() as haName can be postfixed with thread listeners.
        // example: servername-4. It is defect in IDS version lower than XC8.
        const serverInfo = clusterInfo.clusterNodeList.filter(server => clusterInfo.haName.includes(server.name)
        || server.id === this.server.id)[0];
        if(!serverInfo) {
          this.serverNotInCluster = `Could not display ha: Server '${clusterInfo.haName}' is not present in the cluster.`;
          return;
        }
        if (serverInfo.type !== 'PRIMARY') {
          this.filterByOptions = [];
          this.onconfigParameters.push(...this.parameters.allSecondaryParams);
          if (serverInfo.type === 'RSS') {
            this.onconfigParameters.push(...this.parameters.rssParams);
          } else if (serverInfo.type === 'HDR') {
            this.onconfigParameters.push(...this.parameters.hdrParams);
          } else if (serverInfo.type === 'SDS') {
            this.onconfigParameters.push(...this.parameters.sdsParams);
          }
        } else {
          this.onconfigParameters = this.allPrimaryParams;
        }
      }, err => {
        this.dataLoading = false;
        if (err.status === 404) {
          this.onconfigParameters = this.allPrimaryParams;
          this.serverNotInCluster = err.error ? err.error.err : err;
        } else {
          this.serverError = err.error ? err.error.err : err;
        }
      });
    }
  }

  filterOptionChange(event: string) {
    if (event === 'all') {
      this.onconfigParameters = this.allPrimaryParams;
    } else {
      this.onconfigParameters = this.parameters[event];
    }
  }

  refreshData() {
    if (this.selectedTabIndex === 0) {
      this.clusterTopologyComponent.refreshData();
    } else if (this.selectedTabIndex === 2) {
      this.smxInfoComponent.refreshData();
    }
  }
}
