/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
export interface TimeSlice {
  name: string;
  value: number;
}

const timeSlices: TimeSlice[] = [
  { name: '5 minutes', value: 300000 },
  { name: '1 hour', value: 3600000 },
  { name: '4 hours', value: 14400000 },
  { name: '1 day', value: 24 * 3600000 },
  { name: '7 days', value: 7 * 24 * 3600000 },
  { name: '30 days', value: 30 * 24 * 3600000 }
];

export function getTimeSlices(): TimeSlice[] {
  return timeSlices;
}

export function getTimeSlice(timeSliceName: string): TimeSlice {
  return timeSlices.filter(timeSlice => timeSlice.name === timeSliceName)[0];
}
