/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../../servers/informixServer';
import { HttpClient } from '@angular/common/http';
import { TaskResponse, RunHistoryArray } from './task-scheduler.model';

@Injectable()
export class TaskSchedulerService {

  constructor(private httpClient: HttpClient) { }

  public getAllTasks(server: InformixServer): Observable<Array<TaskResponse>> {
    const url = 'informix/' + server.id + '/scheduler/tasks';
    return this.httpClient.get<Array<TaskResponse>>(url);
  }

  public deleteTask(server: InformixServer, rowId: number): Promise<any> {
    return this.httpClient.delete<any>('informix/' + server.id + '/scheduler/tasks/' + rowId).toPromise<any>();
  }

  public getAllGroups(server: InformixServer): Observable<any> {
    const url = 'informix/' + server.id + '/scheduler/groups';
    return this.httpClient.get<any>(url);
  }

  public createNewTask(server: InformixServer, param: any): Observable<any> {
    const url = 'informix/' + server.id + '/scheduler/tasks';
    return this.httpClient.post<any>(url, param);
  }

  public updateTask(server: InformixServer, param: any, rowId: any): Observable<any> {
    const url = 'informix/' + server.id + '/scheduler/tasks/' + rowId;
    return this.httpClient.put<any>(url, param);
  }

  public getTaskByID(server: InformixServer, rowId: number): Observable<any> {
    const url = 'informix/' + server.id + '/scheduler/tasks/' + rowId;
    return this.httpClient.get<any>(url);
  }

  public executeTask(server: InformixServer, rowId: number, options: any): Observable<any> {
    const url = 'informix/' + server.id + '/scheduler/tasks/' + rowId + '/runs';
    return this.httpClient.post<any>(url, options);
  }

  public getRunHistory(server: InformixServer): Observable<RunHistoryArray> {
    const url = 'informix/' + server.id + '/scheduler/tasks/runs/history';
    return this.httpClient.get<RunHistoryArray>(url);
  }
}
