/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';
import { UserSettingsComponent } from './user-settings.component';
import { AlertSettingsComponent } from './alerts/alertSettings.component';
import { ChangePasswordComponent } from './changePassword.component';
import { UserPreferencesComponent } from '../system-settings/user-preferences/user-preferences.component';

export const UserSettingsRoutes: Route[] = [
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'alerts'
      },
      {
        path: 'alerts',
        component: AlertSettingsComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'user-preferences',
        component: UserPreferencesComponent
      }
    ]
  }
];
