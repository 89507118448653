/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { participant } from '../../replicate';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { EnterpriseReplicationService } from '../../enterprise-replication.service';
import { InformixServer } from '../../../informixServer';
import { DataTableRow } from '../../../../../shared/data-table/data-table.component';

@Component({
  selector: 'app-participant-modal',
  templateUrl: './participant-modal.component.html',
  styleUrls: ['./participant-modal.component.scss']
})
export class ParticipantModalComponent implements OnInit {

  @ViewChild('participantModal') participantModal: ModalDirective;
  @Output() addPart = new EventEmitter<any>();
  @Output() editPart = new EventEmitter<any>();
  @Input() server: InformixServer = null;
  @Input() groupNames = [];
  @Input() isMasterServer: boolean;
  @Input() masterServer: string;
  @Input() participants = [];
  addParticipantForm: FormGroup;
  isCreateMissingTable: Boolean = false;
  firstPage: Boolean = true;
  isAllColumns: Boolean = false;
  formSubmit: Boolean = false;
  isLoading: Boolean = false;
  isColumnDataLoading: Boolean = false;
  ownerExists: Boolean = false;
  participantDetails: participant;
  currIndex: any;
  isEdit = false;
  databases = [];
  tableNames = [];
  columnData = [];

  constructor(private fb: FormBuilder,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.addParticipantForm = this.fb.group({
      query: new FormControl(null),
      owner: new FormControl('informix', Validators.required),
      databaseName: new FormControl(null, Validators.required),
      tabName: new FormControl(null, Validators.required),
      groupName: new FormControl(null, Validators.required),
      participantType: new FormControl(null, Validators.required),
      ownerName: new FormControl(null, Validators.required),
    });
  }

  /*
  * API call - Get all database list
  */
  onServerChange(event, value?: string) {
    if (!value && event.target.value === '') {
      return;
    }
    this.databases = [];
    this.isLoading = true;
    const groupName = value ? value : event.target.value;
    this.erService.getDataBaseList(this.server.id, groupName).subscribe(res => {
      this.databases = res;
      this.isLoading = false;
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  /*
   * API call - Get table list by passing database_name and group_name
   */
  onDbChange(event, value?: string) {
    if (!value && event.target.value === '') {
      return;
    }
    this.tableNames = [];
    this.isLoading = true;
    const database = value ? value : event.target.value;
    const participantForm = this.addParticipantForm.value;
    if (event && participantForm.tabName !== '') {
      this.addParticipantForm.controls.tabName.reset();
    }
    this.erService.getTableList(this.server.id, database, participantForm.groupName).subscribe(res => {
      this.tableNames = res.filter(item => item.id > 99 && item.type === 'T');
      if (event && this.isEdit && this.isMasterServer) {
        this.addParticipantForm.controls.query.reset();
      }
      this.isLoading = false;
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  /*
   * API call - Get cloumn list by passing databasename, table_name and group_name
   */
  onTableChange(event) {
    if (event.target.value === '' || !this.isMasterServer) {
      return;
    }
    this.isLoading = true;
    this.isColumnDataLoading = true;
    this.columnData = [];
    if (this.isEdit) {
      this.addParticipantForm.controls.query.reset();
    }
    const participantForm = this.addParticipantForm.value;
    const tableName = event.target.value;
    this.erService.getColumnList(this.server.id, participantForm.databaseName, tableName, participantForm.groupName).subscribe(res => {
      this.columnData = res.columns;
      this.isLoading = false;
      this.isColumnDataLoading = false;
    }, err => {
      this.isColumnDataLoading = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  autoCreateTable() {
    this.isCreateMissingTable = !this.isCreateMissingTable;
   this.doResetAutoCreateTable();
  }

  doResetAutoCreateTable() {
    if (this.isCreateMissingTable) {
      this.addParticipantForm.controls.tabName.reset();
      this.addParticipantForm.controls.tabName.disable();
    } else {
      this.addParticipantForm.controls.tabName.enable();
    }
  }

  /*
   * Open participant modal to perform Add and Edit actions
   */
  openParticipantModal(index: any, isMasterServer, obj?: any,) {
    this.isMasterServer = isMasterServer;
    this.doResetForm();
    this.currIndex = index;
    // if obj is not null then it opens participant modal in edit mode
    if (obj) {
      this.isEdit = true;
      this.participantDetails = obj;
      const found = this.groupNames.find(group => group.name === this.participantDetails.groupName);
      if (!found) {
        this.groupNames.push({ name: this.participantDetails.groupName });
      }
      this.setParticipantDetails();
      setTimeout(() => {
        this.onServerChange(null, this.participantDetails.groupName);
        this.onDbChange(null, this.participantDetails.databaseName);
      });
    } else {
      this.isEdit = false;
      this.participantDetails = null;
    }
    this.firstPage = this.isMasterServer;
    this.participantModal.show();
  }

  /**
   * On Edit participant patch participant details to the form
   */
  setParticipantDetails() {
    if (this.participantDetails) {
      let owner = this.participantDetails.owner.toLowerCase();
      if (owner !== 'informix') {
        owner = 'table-owner';
      }
      if (this.isMasterServer) {
        this.firstPage = true;
        this.addParticipantForm.patchValue({
          groupName: this.participantDetails.groupName,
          databaseName: this.participantDetails.databaseName,
          tabName: this.participantDetails.tabName,
          isMasterServer: this.isMasterServer,
          query: this.participantDetails.selectStmt,
          participantType: this.participantDetails.participantType,
          owner
        });

        if (this.participantDetails.optional !== undefined) {
          this.columnData = this.participantDetails.optional.columnData,
            this.isAllColumns = this.participantDetails.optional.isAllColsSelected;
          this.columnData.forEach(row => {
            this.columnsDataMap(row);
          });
        }

      } else {
        this.addParticipantForm.patchValue({
          groupName: this.participantDetails.groupName,
          databaseName: this.participantDetails.databaseName,
          tabName: this.participantDetails.tabName,
          isMasterServer: this.isMasterServer,
          isCreateMissingTable: this.participantDetails.isCreateMissingTable,
          query: this.participantDetails.selectStmt,
          participantType: this.participantDetails.participantType,
          owner
        });
        this.isCreateMissingTable = this.participantDetails.isCreateMissingTable;
        this.doResetAutoCreateTable();
      }
     this.addParticipantForm.controls.ownerName.setValue(owner);
    }
  }

  /*
   * prepare participant data
   */
  getParticipantData() {
    const participantForm = this.addParticipantForm.value;
    const query = this.getSqlStatement(participantForm, this.columnData, this.isCreateMissingTable, this.isMasterServer);
    let participantData = {
      groupName: participantForm.groupName,
      databaseName: participantForm.databaseName,
      owner: participantForm.ownerName,
      isMasterServer: this.isMasterServer,
      isCreateMissingTable: this.isCreateMissingTable,
      participantType: participantForm.participantType,
      tabName: participantForm.tabName,
      selectStmt: query
    };
    if (this.isMasterServer) {
      participantData['optional'] = {
        isAllColsSelected: this.isAllColumns,
        columnData: this.columnData
      };
      this.firstPage = false;
    }
    // Enable or disable ERKEY Shadow columns
    if (this.columnData && this.columnData.length > 2) {
      const columnNames = this.columnData.map(item => {
        if(item.name && item.isChecked) {
          return item.name;
        }
      });
      const names = ['ifx_erkey_1', 'ifx_erkey_2', 'ifx_erkey_3'];
      let allNamesFound = names.every(name => columnNames.includes(name));
      participantData['isErKeyDisable'] = !allNamesFound;
    } else {
      if (this.isCreateMissingTable) {
        const participantDetails = this.participants && this.participants.filter(partDetails => partDetails.isMasterServer === true);
        if ((this.participants && this.participants.length > 0) && (participantDetails && participantDetails.length !== 0)) {
          participantData['isErKeyDisable'] = participantDetails[0].isErKeyDisable;
          participantData['tabName'] = participantDetails[0].tabName;
        }
      } else {
        participantData['isErKeyDisable'] = true;
      }
    }
    return participantData;
  }

  /*
   * create sql query
   * add columns and table names to the query
   */

  getSqlStatement(participantDetails, columnData, isCreateMissingTable, isMasterServer) {
    let query: string;
    const isColChecked = columnData && columnData.some(column => column.isChecked);
    if (!this.isAllColumns && isColChecked) {
      const checkedColumnNames = columnData.filter(column => column.isChecked);
      const names = checkedColumnNames.map((item) => item['name']);
      query = 'select ' + names.toString() + ' ';
    } else {
      query = 'select * ';
    }

    /* If user selects Auto create missing table
     * then create query using master participant table name
    */
    if (isCreateMissingTable) {
      /* isCreateMissingTable=true then
      * filter master-server participant and append table-name of master participant in the SQL query
      */
      const partDetails = this.participants && this.participants.filter(partDtls => partDtls.isMasterServer === true);
      if ((this.participants && this.participants.length > 0) && (partDetails && partDetails.length !== 0)) {
        query += 'from ' + partDetails[0].tabName;
      }
    } else {
      query += 'from ' + participantDetails.tabName;
    }

    if (participantDetails.query && isMasterServer) {
      if (query !== participantDetails.query) {
        query += ' ' + 'where ' + participantDetails.query;
      }
    }
    return query;
  }

  /*
    * check owner exist for selected table name
   */
  doesOwnerExist(isChange: Boolean = false) {
    const participantForm = this.addParticipantForm.value;
    if (isChange && !participantForm.owner) {
      return;
    }
    if (participantForm.owner === 'table-owner') {
      let tableDetails = this.tableNames.filter(table => table.name === participantForm.tabName);
      if (tableDetails.length > 0) {
        this.addParticipantForm.controls.ownerName.setValue(tableDetails[0].owner);
      } else {
        if (!participantForm.tabName && participantForm.tabName !== '') {
          const msg = this.isCreateMissingTable ? 'Please add server database table name' : 'Please select server database table';
          this.notificationsService.pushWarnNotification(msg);
          this.addParticipantForm.controls.owner.reset();
        } else {
          this.ownerExists = true;
          this.addParticipantForm.controls.ownerName.reset();
        }
      }
    } else {
      this.addParticipantForm.controls.ownerName.setValue(participantForm.owner);
    }
  }

  /**
   * check participant form validations
   */
  isFormValid(): Boolean {
    if (this.addParticipantForm.invalid) {
      return true;
    }
    return false;
  }

  /*
   * add participants to the new replication
   * emit data to the NewReplicate component on Add and Edit actions
   */
  addParticipant(isCloseModal: boolean) {
    this.formSubmit = true;
    if (this.isFormValid()) {
      return;
    }
    const participantData = this.getParticipantData();
    if (!this.participantDetails) {
      // emit data to the NewReplicateComponent on Add participant action
      this.addPart.emit({ obj: participantData });
    } else {
      // emit data to the NewReplicateComponent on Edit participant action
      this.editPart.emit({ obj: participantData, index: this.currIndex });

      /*
      * On Edit master participant
      * check any participant have isCreateMissingTable=true from the list of participants
      * then update the query [selectStmt] and table name for that participant as well
      */
      if (this.isEdit && this.participants && this.participants.length > 1 && this.isMasterServer) {
        this.participants.forEach((partDetails, index) => {
          if (partDetails.isCreateMissingTable) {
            // update participant
            const participantDetails = partDetails;
            const query = this.getSqlStatement(partDetails, [], partDetails.isCreateMissingTable, partDetails.isMasterServer);
            participantDetails['selectStmt'] = query;
            const masterParticipant = this.participants?.find(partDtls => partDtls.isMasterServer === true);
            if (masterParticipant) {
              // assign updated table name to the participant
              participantDetails['tabName'] = masterParticipant.tabName;
            }
            this.editPart.emit({ obj: participantDetails, index });
          }
        });
      }
    }
    if (isCloseModal || this.isEdit) {
      if (this.isEdit) {
        this.isEdit = false;
      }
      this.participantModal.hide();
    }
    this.doResetForm();
  }

  /*
  * Sort checked columns data form the table
  */
  onIndexSelected(event: DataTableRow) {
    if (this.isEdit) {
      this.addParticipantForm.controls.query.reset();
    }
    const table = event.data;
    table.isChecked = event.selected;
    this.columnData.forEach(col => {
      if (col.name === event.data.columnName) {
        return col.isChecked = event.selected;
      }
    });
    this.isAllColumns = this.columnData.every(column => column.isChecked);
  }

  columnsDataMap(data: any): DataTableRow {
    return {
      data,
      selected: data.isChecked,
      disabled: false,
      expanded: false
    };
  }

  /*
   * Resets participant form
   */
  doResetForm() {
    this.addParticipantForm.reset();
    this.addParticipantForm.controls.tabName.enable();
    this.isCreateMissingTable = false;
    this.ownerExists = false;
    this.columnData = [];
    this.databases = [];
    this.tableNames = [];
  }
}
