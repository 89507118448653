/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { getSystemInfoDashboardConfig } from './system-info-dashboard';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { InformixServerSystemService } from './informixServerSystem.service';
import { ServerBreadcrumb } from './../serverBreadcrumb';

@Component({
  selector: 'app-system-info-dashboard',
  templateUrl: 'system-info-dashboard.html'
})
export class SystemInfoDashboardComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private sessionsBreadCrumb: BreadcrumbElement = {
    name: 'System Resources',
    link: 'system',
  };

  server: InformixServer = null;
  systemInfoDashboard;

  constructor(
    private route: ActivatedRoute,
    private systemService: InformixServerSystemService
    ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.sessionsBreadCrumb]);

    // Try to tailor the system info dashboard the specific OS
    if (this.server.hasMonitorPassword) {
      this.systemService.getServerSystemInfo(this.server.id).then( osInfo => {
        this.systemInfoDashboard = getSystemInfoDashboardConfig(osInfo.os_name);
      } ).catch(() => {
        this.systemInfoDashboard = getSystemInfoDashboardConfig();
      });
    } else {
      this.systemInfoDashboard = getSystemInfoDashboardConfig();
    }
  }

}
