/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onconfig-page',
  templateUrl: './onconfig-page.component.html'
})
export class OnconfigPageComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  server: InformixServer = null;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get(['breadcrumb.configuration.title'])
      .subscribe(breadcrumbString => {
        this.breadcrumb = ServerBreadcrumb.build(this.server,
          [{ name: breadcrumbString['breadcrumb.configuration.title'] }]);
      });
  }
}
