/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2022, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { distinctUntilChanged, map, pairwise, finalize } from 'rxjs/operators';
import { GroupBrowserConfig } from '../../../../shared/group-browser/group-browser.component';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { InformixServer } from '../../informixServer';
import { InformixServerService } from '../../informixServer.service';
import { SchemaService } from '../../schema/schema.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-agent-info',
  templateUrl: 'agent-info.component.html',
  styleUrls: ['agent-info.component.scss']
})
export class AgentInfoComponent implements OnInit {

  @Input() server: InformixServer;
  @ViewChildren('conValue') conValue: QueryList<ElementRef>;
  @ViewChildren('repoConValue') repoConValue: QueryList<ElementRef>;
  @ViewChildren('nameInputCon') nameInputCon: QueryList<ElementRef>;
  @ViewChildren('nameInputRepoCon') nameInputRepoCon: QueryList<ElementRef>;
  @ViewChild('connectionPropertiesWrapper') connectionPropertiesWrapper: ElementRef;
  @ViewChild('repoServerConnectionPropertiesWrapper') repoServerConnectionPropertiesWrapper: ElementRef;

  browserConfig: GroupBrowserConfig;

  repositoryServer: InformixServer = null;
  selectRepositoryModal: BsModalRef;

  selectedDatabase: string = null;
  databases: string[] = null;
  databasesLoadError: string = null;
  agentShowHide = false;
  connectionPropertiesForms: UntypedFormArray;
  remoteConnectionPropertiesForms: UntypedFormArray;
  private connectionPropertyBlacklist = ['user', 'password'];
  connectionPropertiesChanged = false;
  remoteConnectionPropertiesChanged = false;
  formGroup: UntypedFormGroup = null;
  agentConnectionProps: any;
  databaseBlacklist = new Set(['sysmaster', 'sysadmin', 'sysutils', 'sysuser', 'syscdr', 'sysha']);
  expandAgentPropsHelpText: Boolean = false;
  existingConnectionPropsCheckBox: Boolean = true;
  repoDBServerConnectionPropsCheckBox: Boolean = true;
  initialFormValues: string;
  isDifferentRepoServer: Boolean = false;
  helperFullText: string;
  helperShortText: string;
  loadingDatabases: Boolean = false;
  repoConPropsDisplay: Boolean = false;
  repositoryServerName: string;
  getDatabases$: any;
  isReadOnly: Boolean = false;
  isFirstTime: Boolean = false;
  remoteRepositoryServer: any = null;
  productNameNoSpace = environment.productNameNoSpace;
  constructor(
    private modalService: BsModalService,
    private serverService: InformixServerService,
    private sqlService: SchemaService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.browserConfig = {
      serverMapper: server => ({
          item: server,
          hidden: !server.permissions.read || !server.hasAdminPassword || (server.serverType && server.serverType.isReadOnly)
        })
    };

    this.initForms();
    if (this.server.agent.config) {
      this.selectedDatabase = this.server.agent.config.database;
      if (this.server.agent.config.agentConnectionProperties) {
        this.setAgentConnectionProps();
        // Method to decide whether check use existing sysmaster connection props
        this.checkMarkSysmasterServerProps();
      }
        if (this.server.id === this.server.agent.config.repositoryServerId) {
          // Defining repository server for showing name of already configured repository servername in API.
          this.remoteRepositoryServer = this.server;
          this.setRepositoryServer(this.server);
        } else {
          this.loadRepositoryServer(this.server.agent.config.repositoryServerId);
        }
       this.loadConnectionProperties();
    } else {
      // for first time agent configuration.
      this.isFirstTime = true;
      // Initializing config variable for further decision to enable/disable save button.
      this.server.agent.config = {
        agentConnectionProperties: {
          isConnectionPropertiesSame: true,
          isConnectionPropertiesSameForRepo: true
        },
        repositoryServerId : 0,
        database: 'blank'
      };
    }
    this.shouldReadOnly();
    setTimeout(() => {
      this.setVariableState();
      this.setHelperText();
    });
  }

  /**
   * Method to initialize all form groups and form arrays.
   */
  initForms() {
    this.connectionPropertiesForms = new UntypedFormArray([]);
    this.remoteConnectionPropertiesForms = new UntypedFormArray([]);
    this.formGroup = new UntypedFormGroup({
      connectionProperties: this.connectionPropertiesForms,
      remoteConnectionProperties: this.remoteConnectionPropertiesForms
    });
  }
  agentPropertiesChange() {
    this.existingConnectionPropsCheckBox = !this.existingConnectionPropsCheckBox;
    this.loadConnectionProperties();
  }
  loadConnectionProperties() {

    // Clearing connection properties before populating to prevent invalid form validation case.
    this.clearConnectionPropsFormArray();

    if (this.connectionPropertiesForms) {
      this.connectionPropertiesForms.controls = [];
    }

    /*
     * If use existing connection properties checkbox is unchecked.
     */
    if (!this.existingConnectionPropsCheckBox) {

        const sysMasterConnectionProperties: { [key: string]: string } = this.getSysmasterServerConnectionProperties();

        if (sysMasterConnectionProperties) {
          for (const name in sysMasterConnectionProperties) {
            if (sysMasterConnectionProperties.hasOwnProperty(name)) {
              this.addConnectionProperty(false, name, sysMasterConnectionProperties[name]);
            }
          }
        }
        setTimeout(() =>  this.matchPasswordVal(), 0);
    }
  }

  loadRepositoryServerConnectionProperties() {

    // Clearing connection properties before populating to prevent invalid form validation case.
    this.clearConnectionPropsFormArray(true);

    if (this.remoteConnectionPropertiesForms) {
      this.remoteConnectionPropertiesForms.controls = [];
    }

    /*
     * If use existing connection properties checkbox is unchecked.
     */
    if (!this.repoDBServerConnectionPropsCheckBox) {

        const repoDatabaseServerProperties: { [key: string]: string } = this.getRepoDBServerConnectionProperties();

        if (this.server.id !== this.repositoryServer.id || this.isReadOnly) {
          if (repoDatabaseServerProperties) {
            for (const name in repoDatabaseServerProperties) {
              if (repoDatabaseServerProperties.hasOwnProperty(name)) {
                  this.addConnectionProperty(true, name, repoDatabaseServerProperties[name]);
              }
            }
          }
        }

        setTimeout(() =>  this.matchPasswordVal(true), 0);
    }
  }


  /**
   * Method to add new connection property form element into respective form array.
   *
   * @param isRepositoryDB flag to differentiate between sysmaster & repository database server.
   * @param name name of form field.
   * @param value value of form field.
   */
  public addConnectionProperty(isRepositoryDB?: Boolean, name: string = null, value: string = null) {
    // true if new empty block is being added
    const isBlank = (!name && !value);
    // Adding new connection property only if new empty block is being added & connection property form is valid.
    if (isBlank && !this.shouldAdd(isRepositoryDB)) {
      // Focusing last name input field and return.
      this.focusLastInput(isRepositoryDB);
      return;
    }
    const nameControl = new UntypedFormControl(name,
                                          [
                                            Validators.required,
                                            (control) => this.validateConnectionPropertyName(control as UntypedFormControl, isRepositoryDB)
                                          ]
                                        );

    nameControl.valueChanges.pipe(map(v => (typeof v === 'string' ? v.trim() : v)),
      distinctUntilChanged(), pairwise()).subscribe((_value) => {
      if (_value[0]) {
        const conflictingControls = this.connectionPropertiesForms.controls.map(
          (v: UntypedFormGroup) => v.controls.name).filter(v => v !== nameControl && v.value === _value[0]);
        if (conflictingControls.length > 0) {
          window.setTimeout(() => conflictingControls.forEach(v => v.updateValueAndValidity()), 0);
        }
      }
      window.setTimeout(() => (nameControl.parent as UntypedFormGroup).controls.value.updateValueAndValidity(), 0);
    });

    const valueControl = new UntypedFormControl(value, [Validators.required, this.validateConnectionPropertyValue.bind(this)]);
    const showPasswordControl = new UntypedFormControl(false);
    const hideShowIconControl = new UntypedFormControl(false);

    valueControl.valueChanges.pipe(distinctUntilChanged()).subscribe((_value) => {
      window.setTimeout(() => (valueControl.parent as UntypedFormGroup).controls.value.updateValueAndValidity(), 0);
    });

    let connectionPropertiesForms: UntypedFormArray = this.formGroup.controls.connectionProperties as UntypedFormArray;
    if (isRepositoryDB) {
        connectionPropertiesForms = this.formGroup.controls.remoteConnectionProperties as UntypedFormArray;
    }
    connectionPropertiesForms.push(new UntypedFormGroup({
      name: nameControl,
      value: valueControl,
      showPasswordIcon: showPasswordControl,
      hideShowIcon: hideShowIconControl
    }));
    /**
     * Adding following functions call into setTimeout anonymous asynchronous function into event loop.
     * which makes sure following functions call will be executed after all the synchronous code is done executing.
     */
    setTimeout(() => {
      // Scroll to bottom of connection properties container after new row is added by the user.
      this.scrollToBottom((isRepositoryDB) ? this.repoServerConnectionPropertiesWrapper : this.connectionPropertiesWrapper);

      // Focusing name field of last element after adding new element into connection properties form array.
      // Only focusing newly added blank field as name & value will be always empty in this case.
      if (isBlank) {
        this.focusLastInput(isRepositoryDB);
      }
    }, 0);
  }

  /**
   * Method to change type of value field if name field contains "password" word in it.
   *
   * @param isRepositoryDB flag to differentiate between sysmaster & repository database connection properties
   */
  matchPasswordVal(isRepositoryDB?: Boolean, index?: number) {
    let nameInputElement = this.nameInputCon;
    let valueInputElement = this.conValue;
    let connectionPropertiesForms = this.connectionPropertiesForms;
    let remoteConnectionPropertiesForms = this.remoteConnectionPropertiesForms;
    // Switching values for repo database server connection properties.
    if (isRepositoryDB) {
      nameInputElement = this.nameInputRepoCon;
      valueInputElement = this.repoConValue;
    }

    // If index is present means function called from keyup event handler. Prevents further action.
    if (index > -1) {
      const element = nameInputElement.toArray()[index];
      setType(element, index);
      return;
    }

    // Since both name & value input fields on same index in form array.
    if (nameInputElement) {
      nameInputElement.forEach(setType);
    }

    function setType(element, i) {
      if (element.nativeElement.value.match(/password/i)) {
        valueInputElement.toArray()[i].nativeElement.type = 'password';
        if (isRepositoryDB) {
          remoteConnectionPropertiesForms.at(i).patchValue({ showPasswordIcon: true });
          remoteConnectionPropertiesForms.at(i).patchValue({ hideShowIcon: false });
        } else {
          connectionPropertiesForms.at(i).patchValue({ showPasswordIcon: true });
          connectionPropertiesForms.at(i).patchValue({ hideShowIcon: false });
        }
      } else {
        valueInputElement.toArray()[i].nativeElement.type = 'text';
        if (isRepositoryDB) {
          remoteConnectionPropertiesForms.at(i).patchValue({ showPasswordIcon: false });
        } else {
          connectionPropertiesForms.at(i).patchValue({ showPasswordIcon: false });
        }
      }
    }
  }

  public removeConnectionProperty(index: number) {
    this.connectionPropertiesForms.removeAt(index);
    setTimeout(() => {
      this.focusLastInput();
    }, 0);
  }
  public removeRemoteConnectionProperty(index: number) {
    this.remoteConnectionPropertiesForms.removeAt(index);
    setTimeout(() => {
      this.focusLastInput(true);
    }, 0);
  }
  private validateConnectionPropertyName(c: AbstractControl, isRepositoryDB?: Boolean) {
    const value = c.value ? (c.value as string).trim().toLowerCase() : null;
    if (value) {
      if (this.connectionPropertyBlacklist.indexOf(value) > -1) {
        return { customError: 'You cannot set \'' + value.toLocaleUpperCase() + '\' here' };
      }

      let connectionPropertiesForms = this.connectionPropertiesForms;
      if (isRepositoryDB) {
        connectionPropertiesForms = this.remoteConnectionPropertiesForms;
      }

      for (let i = 0; i < connectionPropertiesForms.controls.length; i++) {
        const control = connectionPropertiesForms.controls[i] as UntypedFormGroup;
        if (c.parent !== control && control.controls.name.valid && control.value && control.value.name
          && (control.value.name as string).trim().toLowerCase() === value) {
          return { customError: 'The property \'' + value.toLocaleUpperCase() + '\' is already set' };
        }
      }
    } else if (c.parent) {
      const parentControl = c.parent as UntypedFormGroup;
      const propValue = parentControl.controls.value.value ? (parentControl.controls.value.value as string).trim() : '';
      if (propValue) {
        return { required: true };
      }
    }

    return null;
  }
  private validateConnectionPropertyValue(c: AbstractControl) {
    if (!c.parent) {
      return null;
    }

    const value = c.value ? (c.value as string).trim() : '';
    if (!value) {
      const parentControl = c.parent as UntypedFormGroup;
      const nameValue = parentControl.controls.name.value ? (parentControl.controls.name.value as string).trim() : '';
      if (nameValue) {
        return { required: true };
      }
    }

    return null;
  }


  private loadRepositoryServer(id: number) {
    this.serverService.getServer(id.toString()).then(server => {
      // Storing for first time agent configuration sncenrio
      this.remoteRepositoryServer = server;
      this.setRepositoryServer(server);
    }).catch(err => {
      this.notifications.pushErrorNotification('There was a problem getting repository server info');
      console.error(err);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.selectRepositoryModal = this.modalService.show(template);
  }

  setRepositoryServer(server: InformixServer) {
    if (this.selectRepositoryModal) {
      this.selectRepositoryModal.hide();
    }

    // local helper to get already configured database name
    const getRemoteDBName = (bypass?: boolean) => this.server.agent.config &&
             (bypass || this.server.agent.config.repositoryServerId === this.repositoryServer.id) &&
             this.server.agent.config.database;

    // If same repository server selected, then do nothing.
    if (this.repositoryServer && this.repositoryServer.id === server.id) {
      return;
    } else {
        this.repositoryServer = server;
    }
    // reseting variables
    this.databases = null;
    this.databasesLoadError = null;
    this.loadingDatabases = true;
    this.isReadOnly = false;
    this.selectedDatabase = null;

    this.checkMarkRepoDBServerProps();
    this.loadRepositoryServerConnectionProperties();
    this.isRepoServerSame(this.repositoryServer && this.repositoryServer.id);
    this.setHelperText();
    this.setVariableState();
    this.shouldReadOnly();

    // Canceling previous API subscriber before initializing next one.
    if (this.getDatabases$ && this.getDatabases$.unsubscribe) {
        this.getDatabases$.unsubscribe();
      }

    this.getDatabases$ = this.sqlService.getDatabaseNames(server)
    .pipe(
      finalize(() => {
        this.loadingDatabases = false;
        this.shouldReadOnly();
        this.setVariableState();
        if (this.isReadOnly) {
          this.selectedDatabase = getRemoteDBName(true);
          this.checkMarkSysmasterServerProps();
          this.loadConnectionProperties();
          this.checkMarkRepoDBServerProps();
          this.loadRepositoryServerConnectionProperties();
        }
        this.setHelperText();
        setTimeout(() => {
          this.preserveInitialFormValues(this.formGroup);
          this.matchPasswordVal();
          this.matchPasswordVal(true);
        });
      })
    ).subscribe(databases => {
      this.databases = databases.filter(db => !this.databaseBlacklist.has(db));
      this.selectedDatabase = getRemoteDBName();
      const index = this.databases.indexOf(this.selectedDatabase);
      if (index < 0) {
        this.selectedDatabase = this.databases.length ? this.databases[0] : null;
      }
    }, err => {
      if(err.error && err.error.err) {
        this.databasesLoadError = err.error.err;
      }else {
        this.databasesLoadError = 'There was a problem retrieving the repository\'s list of databases';
      }
      console.error(err);
    });
  }

  /**
   * Method to decide whether elements should be readonly based on deifferent scenarios.
   */
  shouldReadOnly() {
      if (this.databasesLoadError || (this.databases && !this.databases.length) || !this.databases) {
         this.isReadOnly = (this.loadingDatabases || this.isFirstTime || !this.agentConnectionProps ||
                            (
                              this.agentConnectionProps.isConnectionPropertiesSame === false ||
                              this.agentConnectionProps.isConnectionPropertiesSameForRepo === false
                            )
                            );
        } else {
          this.isReadOnly = false;
        }
  }

  isAgentInfoChanged() {
    if (this.server.agent.config) {
      const config = this.server.agent.config;
      // Detecting changes for selection of repository server.
      if (!this.repositoryServer || this.repositoryServer.id !== config.repositoryServerId) {
        return true;
      }
      // Detecting changes for database name.
      if (this.selectedDatabase !== config.database) {
        return true;
      }
      // Detecting changes for checkboxes values of connection properties.
      if (
        config.agentConnectionProperties &&
        (
          (
            config.agentConnectionProperties.isConnectionPropertiesSame !== undefined &&
            config.agentConnectionProperties.isConnectionPropertiesSame !== this.existingConnectionPropsCheckBox
          ) ||
          (
            config.agentConnectionProperties.isConnectionPropertiesSameForRepo !== undefined &&
            config.agentConnectionProperties.isConnectionPropertiesSameForRepo !== this.repoDBServerConnectionPropsCheckBox
          )
        )
        ) {
          return true;
      }

      // Detecting changes for connection properties form with form form array.
      if (this.isFormValueChanged(this.formGroup)) {
        return true;
      }

      return false;
    }

    return !!this.repositoryServer;
  }

  canSave() {
    return !this.loadingDatabases && this.isFormValid() && this.isAgentInfoChanged() && this.repositoryServer && !this.isReadOnly;
  }

  /**
   * Validates connection properties form.
   *
   * @returns is connection properties form valid
   */
  isFormValid(): Boolean {
    // validation for empty connection properties form array only if checkbox of respective server is un-checked.
    // validation for form fields validators.
    if (
      (!this.existingConnectionPropsCheckBox && !this.connectionPropertiesForms.length) ||
      (!this.repoDBServerConnectionPropsCheckBox && !this.remoteConnectionPropertiesForms.length) ||
      (this.connectionPropertiesForms.invalid || this.remoteConnectionPropertiesForms.invalid)
      ) {
      return false;
    }

    return true;
  }

  onSave() {
    if (!this.canSave()) {
      return;
    }
    const reqData = this.getRequestData();
    this.serverService.updateAgentConfig(this.server, reqData).subscribe(agent => {
      this.notifications.pushSuccessNotification('Agent updated');
      this.server.agent = agent;
      this.setAgentConnectionProps();
      this.preserveInitialFormValues(this.formGroup);
    }, err => {
      this.notifications.pushErrorNotification('There was a problem saving agent configuration');
      console.error(err);
    });
  }
  private getRequestData(): any {
    const v = this.formGroup.value;
    const config: any = {
      repositoryServerId: this.repositoryServer.id,
      database: this.selectedDatabase,
      isConnectionPropertiesSame: this.existingConnectionPropsCheckBox,
    };

    // Sending "isConnectionPropertiesSameForRepo" only if selected repository server is different than sysmaster database.
    if (this.isDifferentRepoServer) {
 config.isConnectionPropertiesSameForRepo = this.repoDBServerConnectionPropsCheckBox;
}
    const connectionProperties = {};
    const remoteConnectionProperties = {};

    if (!config.isConnectionPropertiesSame) {
      // Do not pass "connectionPropertiesSysmaster" empty object if connection properties are not present.
      if (v.connectionProperties.length) {
          config.connectionPropertiesSysmaster = {};
      }
      v.connectionProperties.forEach(prop => connectionProperties[prop.name] = prop.value);
      config.connectionPropertiesSysmaster = connectionProperties;
    }

    // Pass "connectionPropertiesRepository" only if sysmaster and repo server are different.
    if (!config.isConnectionPropertiesSameForRepo && this.server.id !== this.repositoryServer.id) {

      // Do not pass "connectionPropertiesRepository" empty object if connection properties are not present.
      if (v.remoteConnectionProperties.length) {
        config.connectionPropertiesRepository = {};
      }
      v.remoteConnectionProperties.forEach(prop => remoteConnectionProperties[prop.name] = prop.value);
      config.connectionPropertiesRepository = remoteConnectionProperties;
    }
    return config;
  }



  /**
   * Onchange handler for repo db connection properties checkbox.
   */
   repoServerConnectionPropsChange() {
    this.repoDBServerConnectionPropsCheckBox = !this.repoDBServerConnectionPropsCheckBox;
    // Refreshing connection properties repository database server to hide/show based on state of checkbox.
    this.loadRepositoryServerConnectionProperties();
  }

  /**
   * Method to decide whether check use existing connection props for repo db server checkbox.
   */
  checkMarkRepoDBServerProps() {
    // First reset checkbox as checked.
    this.repoDBServerConnectionPropsCheckBox = true;
    // If agent connection properties present in configuration in API.
    if (this.server.agent.config && this.server.agent.config.agentConnectionProperties) {
      const isConnectionPropertiesSameForRepo = this.server.agent.config.agentConnectionProperties.isConnectionPropertiesSameForRepo;
      // check if isConnectionPropertiesSameForRepo is not present and selected repo server is same as in config.
      if (isConnectionPropertiesSameForRepo !== undefined &&
          (this.server.agent.config.repositoryServerId === (this.repositoryServer && this.repositoryServer.id) || this.isReadOnly)) {
            this.repoDBServerConnectionPropsCheckBox = isConnectionPropertiesSameForRepo;
          }
    }
  }

   /**
    * Method to decide whether check use existing connection props sysmaster server.
    */
    checkMarkSysmasterServerProps() {
      // First reset checkbox as checked.
      this.existingConnectionPropsCheckBox = true;
      // If agent connection properties present in configuration in API.
      if (this.server.agent.config && this.server.agent.config.agentConnectionProperties) {
        const isConnectionPropertiesSame = this.server.agent.config.agentConnectionProperties.isConnectionPropertiesSame;
        // check if isConnectionPropertiesSame is not present and selected repo server is same as in config.
        if (isConnectionPropertiesSame !== undefined) {
              this.existingConnectionPropsCheckBox = isConnectionPropertiesSame;
        }
      }
    }

  /**
   * Method to define/set agent connection properties used as a global variable.
   */
  setAgentConnectionProps() {
    this.agentConnectionProps = this.server.agent.config && this.server.agent.config.agentConnectionProperties;
    if (this.agentConnectionProps.isConnectionPropertiesSameForRepo === undefined) {
      this.agentConnectionProps.isConnectionPropertiesSameForRepo = true;
    }
  }


  /**
   * Method that decide & get connection properties of repository database server base on creteria.
   *
   * @returns connection properties to be shown for repository database server.
   */
  getRepoDBServerConnectionProperties(): { [key: string]: string } {
    /*
      ** For repository database server connection properties. **
        Showing connection properties of currently selected repository database server on right side.

      Selected repository server and repository server in configuration is same.
        Prepopulating connection properties from "connectionPropertiesRepositoryDatabase" configuration in API.
        if it is not present then using connection properties of currently selected repository database server.

      Selected repository server and repository server in configuration is not same
        Prepopulating connection properties of currently selected repository database server.
    */

    let repoDatabaseServerProperties: { [key: string]: string } = this.repositoryServer && this.repositoryServer.connectionProperties;

    if (
      this.agentConnectionProps &&
      this.agentConnectionProps.connectionPropertiesRepositoryDatabase &&
      (this.server.agent.config.repositoryServerId === this.repositoryServer.id || this.isReadOnly)
      ) {
        repoDatabaseServerProperties = this.agentConnectionProps.connectionPropertiesRepositoryDatabase;
      }

    return repoDatabaseServerProperties;
  }

  /**
   * Method that decide & get connection properties of sysmaster database server base on creteria.
   *
   * @returns connection properties to be shown for sysmaster database server.
   */
  getSysmasterServerConnectionProperties(): { [key: string]: string } {
      /*
        ** For Systmaster server connection properties. **
          Showing connection properties of currently selected sysmaster on left side.

        Selected repository server and repository server in configuration  is same.
          Prepopulating connection properties from "connectionPropertiesSysmasterDatabase" configuration in API.
          if it is not present then using connection properties of currently selected sysmaster server.

        Selected repository server and repository server in configuration is not same
          Prepopulating connection properties of currently selected sysmaster server.
      */

      let sysMasterConnectionProperties: { [key: string]: string } = this.server && this.server.connectionProperties;

      if (
        this.agentConnectionProps &&
        this.agentConnectionProps.connectionPropertiesSysmasterDatabase
        ) {
          sysMasterConnectionProperties = this.agentConnectionProps.connectionPropertiesSysmasterDatabase;
        }

      return sysMasterConnectionProperties;
  }

  /**
   * Helper function to scroll to down of given HTML element container.
   *
   * @param element HTML element reference variable
   */
  scrollToBottom(element: ElementRef): void {
    if (element) {
      element.nativeElement.scrollTop = element.nativeElement.scrollHeight;
    }
  }

  /**
   * Method to clear all elements from form array.
   *
   * @param isRepositoryDB flag to differentiate between sysmaster & repository database connection properties
   */
   clearConnectionPropsFormArray(isRepositoryDB?: Boolean) {
    let connectionPropertiesForms: UntypedFormArray = this.formGroup.controls.connectionProperties as UntypedFormArray;
    if (isRepositoryDB) {
        connectionPropertiesForms = this.formGroup.controls.remoteConnectionProperties as UntypedFormArray;
      }
    connectionPropertiesForms.clear();
  }

  /**
   * Method that store initial values of the given form as a string of JSON format into global variable for later comparison.
   *
   * @param formGroup main form
   */
   preserveInitialFormValues(formGroup: UntypedFormGroup) {
    this.initialFormValues = JSON.stringify(formGroup.value);
   }

   /**
    * Method that check for changes for values on given form.,
    *
    * @param formGroup main form
    * @returns is form value changed for given form
    */
   isFormValueChanged(formGroup: UntypedFormGroup): Boolean {
    if (this.initialFormValues !== JSON.stringify(formGroup.value)) {
      return true;
    }
    return false;
   }

   /**
    * Method that define global global variable to check if selected repository server is same as sysmaster server.
    */
   isRepoServerSame(repositoryServerId) {
     this.isDifferentRepoServer = this.server && this.server.id !== repositoryServerId;
   }

  /**
   * Method to focus name input field of last form array.
   *
   * @param isRepositoryDB flag to differentiate between sysmaster & repository database server.
   */
  focusLastInput(isRepositoryDB?: Boolean) {
    let result: Boolean;
    if (isRepositoryDB) {
      // Handled undefined exception while focusing input field without using if condition.
      result = this.nameInputRepoCon && this.nameInputRepoCon.last && this.nameInputRepoCon.last.nativeElement.focus();
    } else {
      // Handled undefined exception while focusing input field without using if condition.
      result = this.nameInputCon && this.nameInputCon.last && this.nameInputCon.last.nativeElement.focus();
    }
    return result;
  }

  /**
   * Preventing user from adding multiple blank connection properties with invalid form validation.
   *
   * @param isRepositoryDB flag to differentiate between sysmaster & repository database server.
   * @returns validation of form
   */
  shouldAdd(isRepositoryDB?: Boolean): Boolean {
    const connectionPropsForm: UntypedFormArray = (isRepositoryDB) ? this.remoteConnectionPropertiesForms : this.connectionPropertiesForms;
    return connectionPropsForm.valid;
  }

  /**
   * Method to set full and short helper text for connection properties.
   */
  setHelperText() {
    // Updating repository server name to show already configured repo server name instead selected one in readOnly mode.
    if (this.repositoryServer) {
      this.repositoryServerName =
          (this.isReadOnly && this.remoteRepositoryServer) ? this.remoteRepositoryServer.name : this.repositoryServer.name;
    }
    const dbName = this.selectedDatabase;
    if (this.isReadOnly) {
      this.isRepoServerSame(this.remoteRepositoryServer && this.remoteRepositoryServer.id);
    }

    // Showing first helper text for first time user only who having deficulties to connect with repo database.
    if (!this.repositoryServer || (this.isFirstTime && this.isReadOnly)) {
      this.helperFullText = `By default ${this.productNameNoSpace} agent connects with sysmaster database using existing server \
connection properties. To modify agent connection properties, repository database must be configured first. `;
      this.helperShortText = `By default ${this.productNameNoSpace} agent connects with sysmaster database using existing server \
connection properties. `;
    } else if (!this.isDifferentRepoServer) {
      this.helperFullText = `${this.productNameNoSpace} agent connects with sysmaster ${(dbName) ? `and repository database \
(<strong>${dbName}</strong>)` : ``} on same server (<strong>${this.server.alias}</strong>). \
By default Agent uses existing server connection properties. `;
      this.helperShortText = `${this.productNameNoSpace} agent connects with sysmaster ${(dbName) ? `and repository database \
(<strong>${dbName}</strong>)` : ``} on same server (<strong>${this.server.alias}</strong>). `;
    } else {
      this.helperFullText = `${this.productNameNoSpace} agent connects with sysmaster database on "<strong>${this.server.alias}</strong>" \
${(dbName) ? `and repository database (<strong>${dbName}</strong>) on "<strong>${this.repositoryServerName}</strong>"` : ``}. \
By default Agent uses respective server connection properties. `;
      this.helperShortText = `${this.productNameNoSpace} agent connects with sysmaster database on "<strong>${this.server.alias}</strong>" \
${(dbName) ? `and repository database (<strong>${dbName}</strong>) on "<strong>${this.repositoryServerName}</strong>"` : ``} `;
    }
  }

  /**
   * Method to set disable state of systmaster connection props checkbox.
   * and display of repository connection props section.
   */
  setVariableState() {
    this.repoConPropsDisplay = (this.repositoryServer && this.server) &&
      ((this.server.id !== this.repositoryServer.id && !this.isReadOnly ) ||
      (this.isReadOnly && this.remoteRepositoryServer && this.remoteRepositoryServer.id !== this.server.id));
  }

  togglePassShow(index, flag: boolean = false) {
    const control = this.connectionPropertiesForms.controls[index] as UntypedFormGroup;
    if (flag) {
      if (control.controls.value.value === '') {
        this.connectionPropertiesForms.at(index).patchValue({ hideShowIcon: false });
      }
      return;
    }
    if (control.controls.hideShowIcon.value) {
      this.connectionPropertiesForms.at(index).patchValue({ hideShowIcon: false });
    } else {
      this.connectionPropertiesForms.at(index).patchValue({ hideShowIcon: true });
    }
  }

  toggleRemotePassShow(index, flag: boolean = false) {
    const control = this.remoteConnectionPropertiesForms.controls[index] as UntypedFormGroup;
    if (flag) {
      if (control.controls.value.value === '') {
        this.remoteConnectionPropertiesForms.at(index).patchValue({ hideShowIcon: false });
      }
      return;
    }
    if (control.controls.hideShowIcon.value) {
      this.remoteConnectionPropertiesForms.at(index).patchValue({ hideShowIcon: false });
    } else {
      this.remoteConnectionPropertiesForms.at(index).patchValue({ hideShowIcon: true });
    }
  }
}
