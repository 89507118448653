/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { TranslateService } from '@ngx-translate/core';
import { TaskStatusComponent } from './task-status.component';

@Component({
  selector: 'app-tables-indexes-page',
  templateUrl: 'tables-indexes-page.html'
})
export class TablesIndexesPageComponent implements OnInit {
  server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;
  selectedTabIndex = 0;
  @ViewChild(TaskStatusComponent) taskStatus: TaskStatusComponent;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get(['breadcrumb.storage.title', 'breadcrumb.storage.tablesIndexes'])
      .subscribe(breadcrumbStrings => {
        this.breadcrumb = ServerBreadcrumb.build(this.server,
          [
            { name: breadcrumbStrings['breadcrumb.storage.title'], link: 'storage' },
            { name: breadcrumbStrings['breadcrumb.storage.tablesIndexes'] }
          ]);
      });
  }

  setSelectedTabIndex(index: number) {
    this.selectedTabIndex = index;
  }

  refreshData() {
    this.taskStatus.refreshData();
  }

  doCleanup() {
    this.taskStatus.confirmCleanup();
  }

}
