/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
export class PasswordPolicy {
  public minLength: number;
  public requireLowerCase: boolean;
  public requireUpperCase: boolean;
  public requireNumber: boolean;
  public requireCharacterFromSet: boolean;
  public specialCharacterSet: string[];
  public policyDescription: string;

  constructor(json: any) {
    this.minLength = json.minLength;
    this.requireLowerCase = json.requireLowerCase;
    this.requireUpperCase = json.requireUpperCase;
    this.requireNumber = json.requireNumber;
    this.requireCharacterFromSet = json.requireCharacterFromSet;
    this.specialCharacterSet = json.specialCharacterSet;
    this.policyDescription = json.policyDescription;
  }

  public isPasswordValid(password: string): boolean {
    if (password === null) {
      return false;
    }
    return this.validateLength(password) && this.validateLowerCase(password) && this.validateUpperCase(password)
      && this.validateNumber(password) && this.validateSpecialCharacter(password);
  }

  private validateLength(password: string): boolean {
    return password.length >= this.minLength;
  }

  private validateLowerCase(password: string): boolean {
    if (!this.requireLowerCase) {
      return true;
    }

    return password.toUpperCase() !== password;
  }

  private validateUpperCase(password: string): boolean {
    if (!this.requireUpperCase) {
      return true;
    }

    return password.toLowerCase() !== password;
  }

  private validateNumber(password: string): boolean {
    if (!this.requireNumber) {
      return true;
    }

    return (/[0-9]/.test(password));
  }

  private validateSpecialCharacter(password): boolean {
    if (!this.requireCharacterFromSet) {
      return true;
    }

    for (let i = 0; i < password.length; i++) {
      const char = password.charAt(i);
      if (this.isDefinedSpecialCharacter(char)) {
        return true;
      }
    }
    return false;
  }

  private isDefinedSpecialCharacter(c): boolean {
    for (let i = 0; i < this.specialCharacterSet.length; i++) {
      const specialC = this.specialCharacterSet[i];
      if (c === specialC) {
        return true;
      }
    }
    return false;
  }
}
