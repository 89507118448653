/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { SystemReport } from '../../system-settings/custom-system-reports/system-report';

export const staticSystemReportList: SystemReport[] =
  [
    {
      id: 'lockList',
      path: 'lock-list',
      category: 'locks',
    },
    {
      id: 'locksPerSession',
      path: 'locks-per-session',
      category: 'locks',
    },
    {
      id: 'locksPerTable',
      path: 'locks-per-table',
      category: 'locks'
    },
    {
      id: 'locksWithWaiters',
      path: 'locks-with-waiters',
      category: 'locks'
    },
    {
      id: 'sessionActivity',
      path: 'session-activity',
      category: 'users',
    },
    {
      id: 'slowestSQLStatements',
      path: 'slowest-sql',
      category: 'sql'
    },
    {
      id: 'sqlWithHighestEstimatedCost',
      path: 'sql-highest-estimated-cost',
      category: 'sql'
    },
    {
      id: 'sqlWithMostBufferActivity',
      path: 'sql-most-buffer-activity',
      category: 'sql'
    },
    {
      id: 'sqlWithMostIOTime',
      path: 'sql-most-iotime',
      category: 'sql'
    },
    {
      id: 'sqlWithMostMemory',
      path: 'sql-most-memory',
      category: 'sql'
    },
    {
      id: 'tableBufferPoolActivity',
      path: 'table-buffer-pool-activity',
      category: 'tables'
    },
    {
      id: 'tableExtents',
      path: 'table-extents',
      category: 'tables'
    },
    {
      id: 'waitingSessions',
      path: 'waiting-sessions',
      category: 'users'
    },
    {
      id: 'updateStatistics',
      path: 'update-statistics',
      category: 'tables'
    }
  ];
