/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { GroupBreadcrumb } from './groupBreadcrumb';
import { InformixServerGroup } from './informixServerGroup';

@Component({
  selector: 'app-group-incidents',
  templateUrl: 'groupIncidents.html'
})
export class GroupIncidentsComponent implements OnInit {

  group: InformixServerGroup = null;
  breadcrumb: BreadcrumbElement[] = null;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadGroup(data.group);
    });
  }

  private loadGroup(group: InformixServerGroup) {
    this.group = group;
    this.breadcrumb = GroupBreadcrumb.build(this.group, [{ name: 'Incidents' }]);
  }
}
