/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { InformixSQLSession } from '../informix-sql-session';
import { InformixTable } from '../informix-table';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { InformixServer } from '../../informixServer';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnChanges {

  @Input() session: InformixSQLSession;
  @Input() server: InformixServer;
  @Output() createIndexData = new EventEmitter<any>();
  @Input() heading: string;
  inputQuery: string = null;
  selectedTable: InformixTable = null;
  tabName: string;
  isSqlEditor: Boolean = false;
  tabNames = ['Information', 'Stored Procedures & Functions', 'Sequences', 'User-Defined Types', 'Privileges',
    'DataBlade Modules', 'Aggregates', 'Casts', 'Operator Classes'];
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  isShowResultTab: Boolean = false;

  constructor(private router: Router, public route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges) {
    this.tabName = this.tabNames[0];
    if (changes.session) {
      this.selectedTable = null;
      this.inputQuery = null;
    }
  }

  setSelectedTable(table: InformixTable) {
    this.selectedTable = table;
    const tableName: string = this.selectedTable ? this.selectedTable.name : null;
    this.router.navigate([], { queryParams: { table: tableName }, queryParamsHandling: 'merge', replaceUrl: true });
    // this.selectInfoTab();
  }

  onBrowseTable(table: InformixTable) {
    this.inputQuery = null;
    this.runSQL('SELECT * FROM ' + table.name);
  }

  private runSQL(sql: string) {
    setTimeout(() => this.inputQuery = sql, 0);
    // this.selectSQLTab();
  }

}
