/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../informixServer';
import { HttpClient } from '@angular/common/http';
import { AlertInfo } from './alertInfo.model';
import { CommandInfo } from './commandInfo.model';
import { AusTask } from './configInfo.model';
import { GeneralInfo } from './generalInfo.model';

@Injectable()
export class AutoUpdateStatisticsService {

  constructor(private httpClient: HttpClient) { }

  public getAlert(server: InformixServer): Observable<AlertInfo[]> {
    const url = 'informix/' + server.id + '/aus/alerts';
    return this.httpClient.get<AlertInfo[]>(url);
  }

  public getCommand(server: InformixServer, commandType: string): Observable<CommandInfo[]> {
    const url = 'informix/' + server.id + '/aus/commands?commandState=' + commandType;
    return this.httpClient.get<CommandInfo[]>(url);
  }

  public getConfig(server: InformixServer): Observable<AusTask[]> {
    const url = 'informix/' + server.id + '/aus/configuration';
    return this.httpClient.get<AusTask[]>(url);
  }

  public updateThread(server: InformixServer, count: string): Observable<any> {
    const url = 'informix/' + server.id + '/aus/jobs/refresh/threads';
    return this.httpClient.put<any>(url, { threadCount: Number(count) });
  }

  public getDatabasePriority(server: InformixServer): Observable<any> {
    const url = 'informix/' + server.id + '/aus/configuration/databases';
    return this.httpClient.get<any>(url);
  }

  public saveDatabasePriority(server: InformixServer, priorityObj: any): Observable<any> {
    const url = 'informix/' + server.id + '/aus/configuration/databases';
    return this.httpClient.put<any>(url, priorityObj);
  }

  public getAllStatistics(server: InformixServer, includeStats: Boolean): Observable<GeneralInfo[]> {
    const url = 'informix/' + server.id + '/aus?includeStats=' + includeStats;
    return this.httpClient.get<GeneralInfo[]>(url);
  }

  public executeEvaluationTask(server: InformixServer): Observable<any> {
    const url = 'informix/' + server.id + '/aus/jobs/evaluation';
    return this.httpClient.post<any>(url, { server });
  }

  public executeCleanUp(server: InformixServer): Observable<any> {
    const url = 'informix/' + server.id + '/aus/jobs/cleanup';
    return this.httpClient.post<any>(url, { server });
  }
}
