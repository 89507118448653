/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-data-source-toggle',
  template: `
<label class="btn-container">
  <button class= "btn font-weight-bold" [ngClass]="{'btn-style':!viewMonitoredData}" (click)="handleStateChange(false)">Server Data</button>
  <button class= "btn font-weight-bold" [ngClass]="{'btn-style': viewMonitoredData}" (click)="handleStateChange(true)">Agent Data</button>
</label>
  `
})
export class DataSourceToggleComponent {

  @Input() viewMonitoredData = true;
  @Input() useLongTooltips = false;
  @Output() viewMonitoredDataStateChange = new EventEmitter<boolean>();

  liveDataTooltip = 'View live data';
  liveDataTooltipLong = 'View live data directly from the database server';
  monitoredDataTooltip = 'View monitored data';
  monitoredDataTooltipLong = 'When available, view monitored data collected by the agent';

  handleStateChange(viewMonitoredData: boolean) {
    this.viewMonitoredData = viewMonitoredData;
    this.viewMonitoredDataStateChange.emit(this.viewMonitoredData);
  }
}
