/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { InformixServer } from '../informixServer';
import { SchemaService } from '../schema/schema.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { PrivilegesService } from './privileges.service';
import { DatabasePrivilegeInfo } from './privileges.model';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { IACValidators } from '../../../shared/forms/validators';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-database-privileges',
  templateUrl: 'database-privileges.html'
})
export class DatabasePrivilegesComponent implements OnInit {

  @Input() server: InformixServer = null;
  userList: DatabasePrivilegeInfo[] = null;
  formGroup: UntypedFormGroup;

  databases: string[];
  privilegeTypes = ['CONNECT', 'RESOURCE', 'DBA'];
  selectedDB = '';
  privilegeInfo: DatabasePrivilegeInfo = new DatabasePrivilegeInfo();
  priviligesHDR: HDRPermission;
  apiError: string;
  constructor(
    private restdb: SchemaService,
    private notificationsService: NotificationsService,
    private privilegesService: PrivilegesService,
    private confirmationDialogService: ConfirmationDialogService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private hdrPermissionService: HDRPermissionService
  ) { }

  ngOnInit() {
    this.getDatabaseList();
    this.formGroup = this.formBuilder.group({
      userName: new UntypedFormControl(null, [IACValidators.noWhitespaceValidator(), Validators.required]),
      selectedPrivilegeType: new UntypedFormControl(null, [Validators.required])
    });
    this.priviligesHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  grant() {
    if (!this.formGroup.valid || (this.priviligesHDR && !this.priviligesHDR.isAllow())) {
      return;
    }
    const userName = this.formGroup.value.userName.trim();
    this.privilegeInfo.userName = userName;
    this.privilegeInfo.privilegeType = this.formGroup.value.selectedPrivilegeType;
    this.privilegeInfo.defaultRole = null;

    this.privilegesService.grantDatabasePrivilege(this.server, this.selectedDB, this.privilegeInfo)
      .subscribe(response => {
        this.getUsers();
        this.translate.get('privileges.successMsg.grantSuccess', { userName }).subscribe((text: string) => {
          this.notificationsService.pushSuccessNotification(text);
        });
        this.formGroup.reset();
      }, err => {
        this.translate.get('privileges.errorMsg.grantError', { suffix: err.error ? err.error.err : err }).subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
      });
  }

  changeDatabase(event: string) {
    this.selectedDB = event;
    this.formGroup.reset();
    this.getUsers();
  }

  toggleModifyVisible(databasePrivilegeInfo: DatabasePrivilegeInfo) {
    if (this.priviligesHDR && !this.priviligesHDR.isAllow()) {
      return;
    }
    databasePrivilegeInfo.selectedModifyType = databasePrivilegeInfo.privilegeType;
    databasePrivilegeInfo.isModifyVisible = !databasePrivilegeInfo.isModifyVisible;
  }

  save(databasePrivilegeInfo: DatabasePrivilegeInfo) {
    if (databasePrivilegeInfo.selectedModifyType !== databasePrivilegeInfo.privilegeType) {
      this.privilegeInfo.userName = databasePrivilegeInfo.userName;
      this.privilegeInfo.privilegeType = databasePrivilegeInfo.selectedModifyType;
      this.privilegeInfo.defaultRole = null;
      this.privilegesService.grantDatabasePrivilege(this.server, this.selectedDB, this.privilegeInfo)
        .subscribe(response => {
          this.getUsers();
          this.translate.get('privileges.successMsg.modifySuccess', { userName: databasePrivilegeInfo.userName })
            .subscribe((text: string) => {
              this.notificationsService.pushSuccessNotification(text);
            });
        }, err => {
          this.translate.get('privileges.errorMsg.modifyError', { suffix: err.error ? err.error.err : err }).subscribe((text: string) => {
            this.notificationsService.pushErrorNotification(text);
          });
        });
    }
    this.toggleModifyVisible(databasePrivilegeInfo);
  }

  revoke(databasePrivilegeInfo: DatabasePrivilegeInfo) {
    if (this.priviligesHDR && !this.priviligesHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('Revoke Privilege for user ' + databasePrivilegeInfo.userName + '?',
      () => this.onRevokeConfirmed(databasePrivilegeInfo));
  }

  onRevokeConfirmed(databasePrivilegeInfo: DatabasePrivilegeInfo) {
    this.privilegeInfo.userName = databasePrivilegeInfo.userName;
    this.privilegeInfo.privilegeType = databasePrivilegeInfo.privilegeType;
    this.privilegeInfo.defaultRole = null;
    this.privilegesService.revokeDatabasePrivilege(this.server, this.selectedDB, this.privilegeInfo)
      .subscribe(response => {
        this.getUsers();
        this.translate.get('privileges.successMsg.revokeSuccess', { userName: databasePrivilegeInfo.userName })
          .subscribe((text: string) => {
            this.notificationsService.pushSuccessNotification(text);
          });
      }, err => {
        this.translate.get('privileges.errorMsg.revokeError', { suffix: err.error ? err.error.err : err }).subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
      });
  }

  private getDatabaseList() {
    this.restdb.getDatabaseNames(this.server).subscribe(databases => {
      this.databases = databases;
      if (this.databases.indexOf(this.selectedDB) === -1) {
        this.selectedDB = this.databases[0];
        this.getUsers();
      }
    }, err => {
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
      // this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  private getUsers() {
    this.privilegesService.getUsers(this.server, this.selectedDB).subscribe((data: DatabasePrivilegeInfo[]) => {
      data.forEach((element: DatabasePrivilegeInfo) => {
        element.isModifyVisible = true;
        switch (element.privilegeType) {
          case 'R':
            element.privilegeType = 'RESOURCE';
            break;
          case 'C':
            element.privilegeType = 'CONNECT';
            break;
          case 'D':
            element.privilegeType = 'DBA';
            break;
        }
      });
      this.userList = data;
    }, err => {
      this.translate.get('privileges.databasePrivileges.getUsersError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
    });
  }
}
