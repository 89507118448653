/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
  <section class="main-container full">
    <div class="errorPage">
      <i class="icon icon-question-circle icon-3x text-danger"></i><br>
      Couldn't find the group you were looking for :(
    </div>
  </section>
  `
})
export class NotFoundComponent {}
