/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { BackupModule } from './backup/backup.module';
import { SharedModule } from '../../../shared/shared.module';
import { MonitoringModule } from '../../monitoring/monitoring.module';
import { AddChunkComponent } from './add-chunk.component';
import { AddPoolEntryComponent } from './add-pool-entry.component';
import { CreateSpaceComponent } from './create-space.component';
import { DeletePoolEntryComponent } from './delete-pool-entry.component';
import { DropChunkComponent } from './drop-chunk.component';
import { DropSpaceComponent } from './drop-space.component';
import { ExpandSpaceComponent } from './expand-space.component';
import { ExtendChunkComponent } from './extend-chunk.component';
import { InformixServerOnconfigService } from '../configuration/informixServerOnconfig.service';
import { InformixServerRecoveryLogsService } from './informixServerRecoveryLogs.service';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ModifyChunkComponent } from './modify-chunk.component';
import { ModifyPoolEntryComponent } from './modify-pool-entry.component';
import { ModifySpaceComponent } from './modify-space.component';
import { RecoveryLogsComponent } from './recovery-logs.component';
import { SizeInputPercentOrKBComponent } from './sizeInputPercentOrKB.component';
import { SpacesListComponent } from './spaces-list.component';
import { StorageDashboardSummaryStatsComponent } from './storage-dashboard-summary-stats.component';
import { StorageDashboardSummaryUsageComponent } from './storage-dashboard-summary-usage.component';
import { StorageDashboardComponent } from './storage-dashboard.component';
import { StorageExtendSizePipe } from './storage-extend-size.pipe';
import { StoragePoolEntryFormComponent } from './storage-pool-entry-form.component';
import { StoragePoolListComponent } from './storage-pool-list.component';
import { StoragePoolPriorityPipe } from './storage-pool-priority.pipe';
import { StoragePoolSpaceRemainingPipe } from './storage-pool-space-remaining.pipe';
import { TablesIndexesModule } from './tables-indexes/tables-indexes-page.module';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        BackupModule,
        MonitoringModule,
        RouterModule,
        BsDropdownModule,
        ProgressbarModule,
        ReactiveFormsModule,
        TooltipModule,
        TablesIndexesModule,
        PopoverModule,
    ],
    exports: [],
    declarations: [
        RecoveryLogsComponent,
        SpacesListComponent,
        StorageDashboardComponent,
        StorageDashboardSummaryStatsComponent,
        StorageDashboardSummaryUsageComponent,
        StoragePoolListComponent,
        StorageExtendSizePipe,
        StoragePoolPriorityPipe,
        StoragePoolSpaceRemainingPipe,
        AddChunkComponent,
        AddPoolEntryComponent,
        CreateSpaceComponent,
        DeletePoolEntryComponent,
        DropChunkComponent,
        DropSpaceComponent,
        ExpandSpaceComponent,
        ExtendChunkComponent,
        ModifyChunkComponent,
        ModifyPoolEntryComponent,
        ModifySpaceComponent,
        SizeInputPercentOrKBComponent,
        StoragePoolEntryFormComponent
    ],
    providers: [
        InformixServerRecoveryLogsService,
        InformixServerStorageService,
        InformixServerOnconfigService
    ]
})
export class StorageModule { }
