<div *ngIf="(isSbspace && sbspaceNames.length > 0) || dbSpacesList; else dBspaceLoading">
  <form [formGroup]="storageSchemeForm">
    <div class="row mt-3">
      <div class="col-12">
        <div class="d-inline-block"><label class="font-weight-bold mt-2">
            {{ 'schemaManager.createIndex.storageScheme.title' | translate }}</label><br />
        </div>
        <div class="d-inline-block ml-2"
          [title]="'schemaManager.createIndex.storageScheme.schemeTypeChangeDesc' | translate">
          <select class="form-control" formControlName="schemeType" (change)="schemeTypeChange()">
            <option value="singledbspace">
              {{ 'schemaManager.createIndex.storageScheme.schemeType.singleDbspace' | translate }}</option>
            <option value="dateRangeFragmentation" *ngIf="isDateRangePresent && !isSbspace">
              {{ 'schemaManager.createIndex.storageScheme.schemeType.dateRangeFragmentation' | translate }}</option>
            <option value="rangeFragmentation" *ngIf="isRangePresent && !isSbspace">
              {{ 'schemaManager.createIndex.storageScheme.schemeType.rangeFragmentation' | translate }}</option>
            <option value="listFragmentation" *ngIf="!isSbspace">
              {{ 'schemaManager.createIndex.storageScheme.schemeType.listFragmentation' | translate }}</option>
            <option value="expressionBasedFragmentation">
              {{ 'schemaManager.createIndex.storageScheme.schemeType.expressionBasedFragmentation' | translate }}
            </option>
          </select>
        </div>
        <div class="d-inline-block ml-2 alert alert-warning" *ngIf="schemeErrorMsg">
          <i class="icon icon-warning text-warning pr-1"></i>{{schemeErrorMsg}}
        </div>
      </div>
    </div>
    <div class="row mt-2"
      *ngIf="schemeType.value === 'dateRangeFragmentation' || schemeType.value === 'rangeFragmentation' || schemeType.value === 'listFragmentation'">
      <div class="col-12 col-md-3 d-inline-flex">
        <div><label class="mt-2">{{ 'schemaManager.createIndex.storageScheme.fragmentKey' | translate }}</label></div>
        <div class="ml-2" [title]="'schemaManager.createIndex.storageScheme.selectColumn' | translate">
          <select class="form-control" formControlName="fragmentKey" (click)="changeFragment()">
            <option *ngFor="let column of fragmentColumns" [ngValue]="column.indexedColumnName">
              {{column.indexedColumnName}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-3 d-inline-flex" *ngIf="schemeType.value !== 'listFragmentation'"
        [title]="'schemaManager.createIndex.storageScheme.specifyInterval' | translate">
        <div><label class="mt-2">{{ 'schemaManager.createIndex.storageScheme.interval' | translate }}</label></div>
        <div class="ml-2">
          <input class="form-control" type="number" formControlName="interval" tooltip="" appExtendedFormControl>
        </div>
        <div class="ml-2" *ngIf="schemeType.value === 'dateRangeFragmentation'">
          <select class="form-control" formControlName="timePeriod" (change)="onChangeTimeperiod()">
            <option value="year">{{ 'time.year' | translate }}</option>
            <option value="month">{{ 'time.month' | translate }}</option>
            <option value="day">{{ 'time.day' | translate }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 d-inline-flex" *ngIf="schemeType.value === 'rangeFragmentation'">
        <div><label class="mt-2 mr-2">{{ 'schemaManager.createIndex.storageScheme.startValue' | translate }}</label>
        </div>
        <div class="ml-2" [title]="'schemaManager.createIndex.storageScheme.specifyStartValue' | translate">
          <input class="form-control" type="number" formControlName="startValue" tooltip="" appExtendedFormControl>
        </div>
      </div>
      <div class="col-12 col-md-4 d-inline-flex" *ngIf="schemeType.value === 'dateRangeFragmentation'">
        <div><label class="mt-2 mr-2">{{ 'schemaManager.createIndex.storageScheme.startDate' | translate }}</label>
        </div>
        <input type="text" class="form-control w-auto pl-2" bsDatepicker formControlName="startDate"
          #startDatePicker="bsDatepicker" [bsConfig]="bsConfig" [bsValue]="bsValue"
          (bsValueChange)="onValueChange($event)"
          [title]="'schemaManager.createIndex.storageScheme.enterDate' | translate" />
        <div class=" pb-1">
          <span class="input-group-text rounded-0" (click)="startDatePicker.toggle()"
            [attr.aria-expanded]="startDatePicker.isOpen"><i class="icon icon-calendar icon-padding"></i>
          </span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="(schemeType.value === 'dateRangeFragmentation' || schemeType.value === 'rangeFragmentation') && isCreateTable">
      <div class="row align-items-center pt-3">
        <div class="col-auto font-weight-bold pr-3">
          <app-checkbox formControlName="enableRollingWindow"
            [title]="'schemaManager.createIndex.storageScheme.enableRollingWindow' | translate">
            {{'schemaManager.createIndex.storageScheme.rollingWindowOption' | translate}}</app-checkbox>
        </div>
        <div class="col-auto d-inline-block mb-0 alert alert-warning"
          *ngIf="enableRollingWindow.value && !maxFragments.value && !maxTotalSizeForRollingWindow.value">
          <i
            class="icon icon-warning text-warning pr-1"></i>{{'schemaManager.createIndex.storageScheme.specifyFragmentsOrMaxSize'
          | translate}}
        </div>
      </div>
      <div class="font-weight-bold pt-3" [ngClass]="{'disabled-text' : enableRollingWindow.value === false}">
        {{'schemaManager.createIndex.storageScheme.thresholds' | translate}}
      </div>
      <div class="row pt-1" [ngClass]="{'disabled-text' : enableRollingWindow.value === false}">
        <div class="col-auto">
          <label>{{'schemaManager.createIndex.storageScheme.maxFragments' | translate}}</label>
          <input class="form-control" type="number" tooltip="" formControlName="maxFragments" min="1"
            [title]="'schemaManager.createIndex.storageScheme.maxFragmentsDesc' | translate" appExtendedFormControl>
        </div>
        <div class="col-auto">
          <label>{{'schemaManager.createIndex.storageScheme.maxTotalSize' | translate}}</label>
          <input class="form-control" type="number" tooltip="" formControlName="maxTotalSizeForRollingWindow" min="1"
            [title]="'schemaManager.createIndex.storageScheme.maxTotalSizeDesc' | translate" appExtendedFormControl>
        </div>
        <div class="col-auto">
          <label>{{'schemaManager.createIndex.storageScheme.sizeUnit' | translate}}</label>
          <select class="form-control" formControlName="maxTotalSizeUnit">
            <option value="kb">KB</option>
            <option value="mb">MB</option>
            <option value="gb">GB</option>
            <option value="tb">TB</option>
          </select>
        </div>
      </div>
      <div class="row align-items-center pt-3" [ngClass]="{'disabled-text' : enableRollingWindow.value === false}">
        <div class="font-weight-bold col-auto">{{'schemaManager.createIndex.storageScheme.policyForOldestFragment' |
          translate}}</div>
        <app-radio-group class="col-auto" formControlName="policyForOldestFragment">
          <app-radio value="tableStorage" value="detach"
            [title]="'schemaManager.createIndex.storageScheme.detachPolicyDesc' | translate">
            {{'schemaManager.createIndex.storageScheme.detach' | translate}} </app-radio>
          <app-radio class="ml-0 ml-sm-2" value="discard"
            [title]="'schemaManager.createIndex.storageScheme.discardPolicyDesc' | translate">
            {{'schemaManager.createIndex.storageScheme.discard' | translate}}</app-radio>
        </app-radio-group>
      </div>
      <div class="row align-items-center py-3" [ngClass]="{'disabled-text' : enableRollingWindow.value === false}">
        <div class="font-weight-bold px-3">{{'schemaManager.createIndex.storageScheme.fragmentsEligibleForRemoval' |
          translate}}</div>
        <app-radio-group class="col-auto pl-2" formControlName="fragmentsForRemoval">
          <app-radio value="tableStorage" value="first"
            [title]="maxTotalSizeForRollingWindow.value > 0 ? 
            ('schemaManager.createIndex.storageScheme.intervalFirstDesc' | translate) : 
            (enableRollingWindow.value ? ('schemaManager.createIndex.storageScheme.fragmentsEligibleDesc' | translate) : '')">
            {{'schemaManager.createIndex.storageScheme.intervalFirst' | translate}} </app-radio>
          <app-radio class="ml-0 ml-sm-2" value="only"
            [title]="maxTotalSizeForRollingWindow.value > 0 ?
             ('schemaManager.createIndex.storageScheme.intervalOnlyDesc' | translate) : 
             (enableRollingWindow.value ? ('schemaManager.createIndex.storageScheme.fragmentsEligibleDesc' | translate) : '')">
            {{'schemaManager.createIndex.storageScheme.intervalOnly' | translate}} </app-radio>
          <app-radio class="ml-0 ml-sm-2" value="any"
            [title]="maxTotalSizeForRollingWindow.value > 0 ?
            ('schemaManager.createIndex.storageScheme.anyFragmentDesc' | translate) : 
            (enableRollingWindow.value ? ('schemaManager.createIndex.storageScheme.fragmentsEligibleDesc' | translate) : '') ">
            {{'schemaManager.createIndex.storageScheme.any' | translate}}</app-radio>
        </app-radio-group>
      </div>
    </ng-container>
    <div class="row">
      <div class="d-flex justify-content-end col-11"
        *ngIf="(isSbspace && sbspaceNames.length > 0 && schemeType.value === 'expressionBasedFragmentation') || (!isSbspace && schemeType.value === 'expressionBasedFragmentation')">
        <button type="button" class="btn btn-primary" (click)="addExpressions('add',null)">
          {{ 'button.add' | translate }}</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12" *ngIf="schemeType.value !== 'expressionBasedFragmentation'">
        <app-data-table *ngIf="isReloadTable" [data]="isSbspace ? sbSpacesList : dbSpacesList"
          (rowSelected)="onRowSelect($event)" [enableSingleSelect]="enableSingleSelect" [dataMapFn]="columnsDataMap"
          [enableSelect]="enableMultiSelect" [minHeight]="300">
          <app-data-table-column [title]="isSbspace ? 'Sbspace' : 'common.dbspace' | translate" property="name"
            [sortable]="true">
          </app-data-table-column>
          <app-data-table-column [title]="'common.values' | translate" property="columnValues" *ngIf="isValues">
            <ng-template appTableCell let-row="row">
              <button type="button" class="btn btn-icon btn-sm" [disabled]="!row.isChecked" (click)="editValues(row)">
                <i class="icon icon-edit"></i> &nbsp; {{row.columnValues}}
              </button>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createDatabase.table.columnTitle.pageSize' | translate"
            property="page_size" [sortable]="true">
            <ng-template appTableCell let-item="item">{{item | byte}}</ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createDatabase.table.columnTitle.freeSpace' | translate"
            property="free_size" [sortable]="true">
            <ng-template appTableCell let-item="item">{{item | byte}}</ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createIndex.storageScheme.nullPartition' | translate"
            property="nullPartition" *ngIf="isNullPartition">
            <ng-template appTableCell let-row="row">
              <i class="icon-2x icon-radio-off color-gray-30" *ngIf="!row.nullPartition"
                (click)="togglePartition('select' ,row, 'nullPartition')"></i>
              <i class="icon-2x icon-radio-on color-blue" *ngIf="row.nullPartition"
                (click)="togglePartition('deselect' ,row, 'nullPartition')"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createIndex.storageScheme.remainderPartition' | translate"
            property="remainderPartition" *ngIf="isRemainderPartition">
            <ng-template appTableCell let-row="row">
              <i class="icon-2x icon-radio-off color-gray-30" *ngIf="!row.remainderPartition"
                (click)="togglePartition('select' ,row, 'remainderPartition')"></i>
              <i class="icon-2x icon-radio-on color-blue" *ngIf="row.remainderPartition"
                (click)="togglePartition('deselect' ,row, 'remainderPartition')"></i>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
      <div class="col-11" *ngIf="schemeType.value === 'expressionBasedFragmentation'">
        <app-data-table *ngIf="expressionList" [data]="expressionList" [enableSingleSelect]="true"
          (rowSelected)="onExpressionRowSelect($event)" [dataMapFn]="expressionDataMap"  [minHeight]="300">
          <app-data-table-column [title]="'common.name' | translate" property="name"></app-data-table-column>
          <app-data-table-column [title]="isSbspace ? 'Sbspace' : 'common.dbspace' | translate" property="dbSpace">
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.expression' | translate"
            property="expression"></app-data-table-column>
          <app-data-table-column class="text-right">
            <ng-template appTableCell let-row="row">
              <button class="btn btn-sm btn-icon" (click)="addExpressions('edit', row)"
                [tooltip]="'tooltip.edit' | translate"><i class="icon icon-edit"></i>
              </button>
              <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.delete' | translate"
                (click)="deleteExpression(row)"><i class="icon icon-trash"></i>
              </button>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
      <div class="col-1 my-auto text-center chevron-container"
        *ngIf="schemeType.value === 'expressionBasedFragmentation' && expressionList.length > 0">
        <button type="button" class="btn btn-sm btn-icon mt-5" (click)="moveRows('upward')"
          [disabled]="disableUpwardChevron"><i class="icon icon-arrow-up cursor-pointer font-weight-bold"
            [title]="'button.moveUp' | translate"></i>
        </button><br />
        <button type="button" class="btn btn-sm btn-icon mt-1" (click)="moveRows('downward')"
          [disabled]="disableDownwardChevron"><i class="icon icon-arrow-down cursor-pointer font-weight-bold"
            [title]="'button.moveDown' | translate"></i>
        </button>
      </div>
    </div>
  </form>
  <app-add-values-modal #addValuesModal (actionComplete)="refreshValues($event)" *ngIf="isValues">
  </app-add-values-modal>
  <app-add-expression-modal #addExpressionsModal (actionComplete)="refreshExpression($event)" *ngIf="isExpression">
  </app-add-expression-modal>
</div>
<ng-template #dBspaceLoading>
  <div class="d-flex justify-content-center" *ngIf="isDBspaceLoading">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
  </div>
</ng-template>
