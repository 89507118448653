<ng-container *ngIf="tableData && tableData.length else ausError;">
  <div class="row mt-2">
    <div class="col-xs-4 col-lg-6">
      <div class="row ml-0 mt-3">
        <label class="font-weight-bold">
          Statistics Summary
        </label>
        <small class="ml-2 mt-1">Last evaluation completed at
          {{(last_evaluation == null)? "" : last_evaluation | date:'y-MM-dd HH:mm:ss'}}</small>
      </div>
    </div>
    <div class="col-xs-8 col-lg-6 text-right">
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button type="button" class="btn btn-secondary" appPermissionDisable (click)="runExecuteEvaluationTask()">Evaluate
          Now</button>
      </app-check-server-admin-permissions>
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button type="button" class="btn btn-secondary" appPermissionDisable (click)="runExecuteCleanUp()">Clean
          Up</button>
      </app-check-server-admin-permissions>
    </div>
  
    <div class="card-body row col-12 ml-3 mr-3 mt-0 p-0 mb-5" style="height: 250px">
      <div class="card col-6 p-0">
        <div class="mt-2">
          <div class="ml-2">
            <i class="icon icon-circle ml-4 mr-4 color-aqua-50"></i><label class="pieChartLabel">Tables Missing
              Statistics</label><br>
            <label class="pieChartValue color-gray-80">{{pieData.tables_missing_stats}}</label>
          </div>
          <hr class="m-0">
          <div class="ml-2 mt-2">
            <i class="icon icon-circle ml-4 mr-4 color-gold-50"></i><label class="pieChartLabel">Large Tables Needing
              Statistics Refreshed</label><br>
            <label class="pieChartValue color-gray-80">{{pieData.large_tables_needing_refresh}}</label>
          </div>
          <hr class="m-0">
          <div class="ml-2 mt-2">
            <i class="icon icon-circle ml-4 mr-4 color-violet-50"></i><label class="pieChartLabel">Small Tables Needing
              Statistics Refreshed</label><br>
            <label class="pieChartValue color-gray-80">{{pieData.small_tables_needing_refresh}}</label>
          </div>
          <hr class="m-0">
          <div class="ml-2 mt-2">
            <i class="icon icon-circle ml-4 mr-4 color-ruby-50"></i><label class="pieChartLabel">Tables With Refreshed
              Statistics</label><br>
            <label class="pieChartValue color-gray-80">{{pieData.tables_completed}}</label>
          </div>
        </div>
      </div>
      <div class="col-6 card">
        <div class="mt-5" style="height: 200px">
          <canvas height="400" #statsSummary> </canvas>
        </div>
      </div>
    </div>
  </div>
  <div class="ml-0 mt-3">
    <label class="font-weight-bold mb-0">
      Auto Update Statistics by Database
    </label>
    <small *ngIf="ausEnabled" class="ml-2 mt-1">Auto Update Statistics Refresh will run at
      {{(next_execution == null)? "" : next_execution | date:'y-MM-dd HH:mm:ss'}}</small>
    <small *ngIf="!ausEnabled" class="ml-2 mt-1">
      <i class="icon icon-warning text-warning"></i>
      Auto Update Statistics is disabled
    </small>
  </div>
  <div class="card-block">
    <div class="card-body p-0 pt-2">
      <div class="table-responsive">
        <app-data-table [data]="tableData" *ngIf="tableData; else tableDataLoading" [sortDescending]="true"
          [rowsPerPage]="20">
          <app-data-table-column title="Last Time Checked" property="eval_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Database" property="database" [sortable]="true" [searchable]="false">
          </app-data-table-column>
          <app-data-table-column title="Tables Missing Statistics" property="tables_missing_stats" [sortable]="true"
            [searchable]="false">
          </app-data-table-column>
          <app-data-table-column title="Large Tables Needing Statistics Refreshed" property="large_tables_needing_refresh"
            [sortable]="true" [searchable]="false">
          </app-data-table-column>
          <app-data-table-column title="Small Tables Needing Statistics Refreshed" property="small_tables_needing_refresh"
            [sortable]="true" [searchable]="false">
          </app-data-table-column>
          <app-data-table-column title="Tables With Refreshed Statistics" property="tables_completed" [sortable]="true"
            [searchable]="false">
          </app-data-table-column>
        </app-data-table>
        <ng-template #tableDataLoading>
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #ausError>
  <div *ngIf="ausApiError" class="mt-2 alert alert-danger">
    <i class="icon icon-danger text-danger"></i> Unable to retrieve auto update statistics information. <br> Error: {{ausApiError}}
  </div>
</ng-template>
