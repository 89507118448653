/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AbstractSystemReport } from './abstract-system-report';
import { BytePipe } from '../../../shared/pipes/byte.pipe';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemReportsService } from './system-reports.service';
import { SystemReport } from '../../system-settings/custom-system-reports/system-report';
import { CustomSystemReportsService } from '../../system-settings/custom-system-reports/custom-system-reports.service';

@Component({
  selector: 'app-generic-report',
  templateUrl: './generic-report.html'
})

export class GenericReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId: string = null;
  isCatalog: Boolean = false;
  reportMeta: SystemReport = null;
  reportCols: any;
  bytePipe = new BytePipe();
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };
  filteredReportData: any;
  filteringReportData: any;
  constructor(
    private route: ActivatedRoute,
    private customReportsService: CustomSystemReportsService,
    private datePipe: DatePipe,
    systemReportsService: SystemReportsService,
    notificationsService: NotificationsService,
    translate: TranslateService
  ) {
    super(systemReportsService, notificationsService, translate);

    this.route.params.subscribe(params => {
      if (params.reportId) {
        this.reportId = params.reportId;
      } else {
        this.notificationsService.pushErrorNotification('Report id is required');
      }
    });
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.server = data.server;
      if (this.reportId) {
        this.getSystemReportMeta();
        this.getSystemReport(this.reportId, this.isCatalog);
      }
    });
  }

  updateReport() {
    this.reportData = this.systemReportsService.updateReport(this.isCatalog, this.reportsData);
  }
  private getSystemReportMeta() {
    this.customReportsService.getReport(this.reportId).then((res: any) => {
      this.reportMeta = res;
      this.setBreadcrumb(this.reportMeta.name);
    }, err => {
      this.notificationsService.pushErrorNotification(this.notificationsService.sanitizeMessage(err.error ? err.error.err : err));
    });
  }
  getDateUnit(colsData: any) {
    const filterDateCol = colsData['columns'].filter(col => col.unit === 'timestamp' || col.unit === 'date');
    if (filterDateCol.length > 0) {
      return true;
    }
  }
  getDataText(col: any, data: any) {
    if (col.unit === 'bytes') {
      return this.bytePipe.transform(data);
    } else if (col.unit === 'timestamp') {
      // convert unix/epoch timestamp to a human readable date
      return this.datePipe.transform(data * 1000, 'y-MM-dd HH:mm:ss');
    } else if (data && data.$date) {
      if (col.unit === 'date') {
        return this.datePipe.transform(data.$date, 'y-MM-dd HH:mm:ss');
      } else {
        return this.datePipe.transform(data.$date, 'y-MM-dd HH:mm:ss');
      }
    } else {
      if (data instanceof Object) {
        return JSON.stringify(data);
      } else {
        return data;
      }
    }
  }
  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.filterReportData();
  }
  filterReportData() {
    this.filteredReportData = [];
    this.filteringReportData = this.actualReportData;
    this.reportCols = this.reportMeta['columns'];
    if (this.timeStamps.fromTimeStamp !== null || this.timeStamps.toTimeStamp !== null) {
      if (this.timeStamps.fromTimeStamp !== null && this.timeStamps.toTimeStamp !== null) {
        this.reportCols.forEach(col => {
          if (col.unit === 'timestamp' || col.unit === 'date') {
            this.filteredReportData = this.filteringReportData.filter(item => {
              if (item[col.id] && item[col.id].$date) {
                return item[col.id].$date > this.timeStamps.fromTimeStamp &&
                  item[col.id].$date < this.timeStamps.toTimeStamp;
              }
            });
          }
        });
      } else if (this.timeStamps.fromTimeStamp !== null) {
        this.reportCols.forEach(col => {
          if (col.unit === 'timestamp' || col.unit === 'date') {
            this.filteredReportData = this.filteringReportData.filter(item => {
              if (item[col.id] && item[col.id].$date) {
                return item[col.id].$date > this.timeStamps.fromTimeStamp;
              }
            });
          }
        });
      } else if (this.timeStamps.toTimeStamp !== null) {
        this.reportCols.forEach(col => {
          if (col.unit === 'timestamp' || col.unit === 'date') {
            this.filteredReportData = this.filteringReportData.filter(item => {
              if (item[col.id] && item[col.id].$date) {
                return item[col.id].$date < this.timeStamps.toTimeStamp;
              }
            });
          }
        });
      }
      this.reportData = this.filteredReportData;
      this.reportsData = this.filteredReportData;
    } else {
      this.getSystemReport(this.reportId, this.isCatalog);
    }
  }
  includeCatalog(isCatalog: boolean) {
    this.isCatalog = isCatalog;
    this.updateReport();
  }
}
