/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
export interface AlertConfig {
  enabled: boolean;
  [key: string]: any;
}

export abstract class AbstractAlertConfigComponent {

  public abstract getType(): string;
  public abstract setConfig(config: AlertConfig): void;
  public abstract getConfig(): AlertConfig;
  public abstract isValid(): boolean;
  public abstract isModified(): boolean;
}
