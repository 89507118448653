/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataTableRow } from '../../../../shared/data-table/data-table.component';
import { SchemaService } from '../schema.service';
import { InformixServer } from '../../informixServer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { Dblocale } from './create-database.model';
import { InformixServerStorageService } from './../../storage/informixServerStorage.service';
import { ServerSpace } from './../../storage/serverSpace';
import { InformixDatabase } from '../informix-database';
import { IACValidators } from '../../../../shared/forms/validators';

@Component({
  selector: 'app-create-database',
  templateUrl: './create-database.component.html'
})
export class CreateDatabaseComponent implements OnInit {
  createDatabaseForm: UntypedFormGroup;
  databaseName: UntypedFormControl;
  loggingMode: UntypedFormControl;
  characterCase: UntypedFormControl;
  dbspaceValue: any;
  langValue: any;
  dbSpacesList: ServerSpace[];
  dbLocaleList: Dblocale[];
  rowSelected = false;
  databaseNames = [];

  @Input() server: InformixServer;
  @Input() databases: InformixDatabase[];
  @Output() actionComplete = new EventEmitter<any>();

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private schemaService: SchemaService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private serverStorageService: InformixServerStorageService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.createDatabaseForm = this.fb.group({
      databaseName: new UntypedFormControl(null,
          [this.databaseNameCheck(), Validators.required, IACValidators.informixIdentifierValidator]),
      loggingMode: new UntypedFormControl('unbuffered', [Validators.required]),
      characterCase: new UntypedFormControl(true, [Validators.required])
    });
    this.databases.forEach(element => {
      this.databaseNames.push(element.name);
    });
    this.getDbspaces();
    this.getDblocales();
  }

  private loadServer(server: InformixServer) {
    this.server = server;
  }

  getDbspaces() {
    this.serverStorageService.getServerSpaces(this.server.id)
      .subscribe((data: ServerSpace[]) => {
        this.dbSpacesList = data.filter(value => value.type === 'dbspace');
      }, err => {
        this.translate.get('schemaManager.createDatabase.errorMsg.getDbspacesError', { suffix: err.error ? err.error.err : err })
          .subscribe((text: string) => {
            this.notificationsService.pushErrorNotification(text);
          });
      });
  }

  getDblocales() {
    this.schemaService.getDblocales(this.server)
      .subscribe((data: Dblocale[]) => {
        this.dbLocaleList = data;
      }, err => {
        this.translate.get('schemaManager.createDatabase.errorMsg.getLangError', { suffix: err.error ? err.error.err : err })
          .subscribe((text: string) => {
            this.notificationsService.pushErrorNotification(text);
          });
      });
  }

  onRowSelect(event: DataTableRow, type: string) {
      if (type === 'dbspace') {
        event.selected ? this.rowSelected = true : this.rowSelected = false;
        this.dbspaceValue = event.data;
      } else {
        event.selected ? this.langValue = event.data : this.langValue = null;
      }
  }

  createDatabase() {
    const dataObj = {
      name: this.createDatabaseForm.get('databaseName').value,
      dbspace: this.dbspaceValue.name,
      locale: this.langValue ? this.langValue.name : null,
      loggingMode: this.createDatabaseForm.get('loggingMode').value,
      isCharacterCase: this.createDatabaseForm.get('characterCase').value,
      isDemoDatabase: false
    };
    this.schemaService.createDatabase(this.server, dataObj).subscribe(res => {
      if ((res.return_code === 0) || (!res.return_code && res.result_message === '0')) {
        this.actionComplete.emit();
        this.translate.get('schemaManager.createDatabase.successMsg.createDatabaseSuccess', { name: dataObj.name })
          .subscribe((text: string) => {
            this.notificationsService.pushSuccessNotification(text);
          });
      } else {
        const errorMessage = res.result_message ? res.result_message : ('Error: ' + res);
        this.notificationsService.pushErrorNotification(errorMessage);
      }
    }, err => {
      this.actionComplete.emit();
      this.translate.get('schemaManager.createDatabase.errorMsg.createDatabaseError',
        { suffix: err.error ? err.error.err : err }).subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
    });
  }

  databaseNameCheck(): ValidatorFn {
    return (): { customError: string } | null => {
      let error: string;
      this.translate.get('schemaManager.createDatabase.duplicateDatabase').subscribe((text: string) => {
        error = text;
      });
      const errors = { customError: error };
      if (this.databaseNames && this.createDatabaseForm && this.createDatabaseForm.get('databaseName')) {
        if (this.databaseNames.indexOf(this.createDatabaseForm.get('databaseName').value) === -1) {
          return null;
        } else {
          return errors;
        }
      } else {
        return null;
      }
    };
  }
}
