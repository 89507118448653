/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

export interface HSVColor {
  h: number;
  s: number;
  v: number;
}

export class Color {
  private rgb: RGBColor = null;
  private hsv: HSVColor = null;

  constructor(rgbString: string);
  constructor(rgb: RGBColor);
  constructor(hsv: HSVColor);
  constructor(source: string | RGBColor | HSVColor) {
    if (typeof source === 'string') {
      const color = source;
      let r = 0;
      let g = 0;
      let b = 0;
      if (color.startsWith('#')) {
        let hexColor = color.substr(1);
        if (hexColor.length === 3) {
          hexColor = hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
        }
        if (hexColor.length === 6) {
          r = parseInt(hexColor.substr(0, 2), 16) || 0;
          g = parseInt(hexColor.substr(2, 2), 16) || 0;
          b = parseInt(hexColor.substr(4, 2), 16) || 0;
        }
      }
      this.setRGB({ r, g, b });
    } else if ('h' in source) {
      this.setHSV(source);
    } else {
      this.setRGB(source);
    }
  }

  getHSV(): HSVColor {
    if (this.hsv) {
      return this.hsv;
    }

    const r = this.rgb.r / 255;
    const g = this.rgb.g / 255;
    const b = this.rgb.b / 255;
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;

    let h = 0;
    if (delta > 0) {
      switch (max) {
        case r: h = (g - b) / delta; break;
        case g: h = ((b - r) / delta) + 2; break;
        case b: h = ((r - g) / delta) + 4; break;
      }
      h = Math.round(h * 60);
      if (h < 0) {
        h = 360 + (h % 360);
      } else if (h > 360) {
        h %= 360;
      }
    }

    const s = max > 0 ? (delta / max) : 0;

    return { h, s, v: max };
  }

  setHSV(hsv: HSVColor) {
    this.hsv = {
      h: this.clamp(hsv.h || 0, 0, 360),
      s: this.clamp(hsv.s || 0, 0, 1),
      v: this.clamp(hsv.v || 0, 0, 1)
    };
    this.rgb = null;
  }

  getRGB(): RGBColor {
    if (this.rgb) {
      return this.rgb;
    }

    let r = 0;
    let g = 0;
    let b = 0;

    const hi = Math.floor(this.hsv.h / 60) % 6;
    const f = this.hsv.h / 60 - Math.floor(this.hsv.h / 60);
    const p = this.hsv.v * (1 - this.hsv.s);
    const q = this.hsv.v * (1 - f * this.hsv.s);
    const t = this.hsv.v * (1 - (1 - f) * this.hsv.s);

    switch (hi) {
      case 0: r = this.hsv.v; g = t; b = p; break;
      case 1: r = q; g = this.hsv.v; b = p; break;
      case 2: r = p; g = this.hsv.v; b = t; break;
      case 3: r = p; g = q; b = this.hsv.v; break;
      case 4: r = t; g = p; b = this.hsv.v; break;
      case 5: r = this.hsv.v; g = p; b = q; break;
    }

    r = Math.round(r * 255);
    g = Math.round(g * 255);
    b = Math.round(b * 255);

    return { r, g, b };
  }

  setRGB(rgb: RGBColor) {
    this.rgb = {
      r: this.clamp(rgb.r || 0, 0, 255),
      g: this.clamp(rgb.g || 0, 0, 255),
      b: this.clamp(rgb.b || 0, 0, 255)
    };
    this.hsv = null;
  }

  getHex(): string {
    const { r, g, b } = this.getRGB();
    return this.toPaddedHex(r) + this.toPaddedHex(g) + this.toPaddedHex(b);
  }

  getCssHex(): string {
    return '#' + this.getHex();
  }

  getCssRgba(alpha: number): string {
    const { r, g, b } = this.getRGB();
    return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
  }

  private clamp(value: number, min: number, max: number): number {
    return value < min ? min : (value > max ? max : value);
  }

  private toPaddedHex(value: number): string {
    return value < 16 ? '0' + value.toString(16) : value.toString(16);
  }
}
