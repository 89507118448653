/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { SharedModule } from '../../../shared/shared.module';
import { CheckpointsModule } from './checkpoints/checkpoints.module';
import { MonitoringModule } from '../../monitoring/monitoring.module';
import { SessionsModule } from './sessions/sessions.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ThreadsComponent } from './threads/threads.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { VirtualProcessorsComponent } from './vps/virtual-processors.component';

@NgModule({
  imports: [
    CheckpointsModule,
    SessionsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MonitoringModule,
    RouterModule,
    BsDropdownModule,
    ProgressbarModule,
    TooltipModule
  ],
  exports: [],
  declarations: [
    ThreadsComponent,
    VirtualProcessorsComponent
  ],
  providers: [
  ],
})
export class PerformanceModule { }
