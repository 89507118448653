<div class="text-center mt-3 mb-4">
    <ng-container *ngFor="let step of totalSteps; let index = index;">
        <div class="d-inline-block position-relative rounded-circle font-weight-bold stepper"
            [ngClass]="{'bg-green-30 text-white': index < currentCount, 'bg-light-gray-50': index >= currentCount}">
            <div class="position-absolute stepper-text-position">
                <i class="icon icon-check" *ngIf="index < currentCount - 1; else showIndex"></i>
                <ng-template #showIndex>
                    {{index + 1}}
                </ng-template>
            </div>
        </div>
        <div *ngIf="index < totalCount - 1" class="stepper-line col-1 p-0 d-inline-block"></div>
    </ng-container>
</div>
