/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer, InformixServerStatusCode } from '../../informixServer';
import { InformixServerService } from '../../informixServer.service';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { EnterpriseReplicationService } from '../enterprise-replication.service';
import { ERDomain, ERNode } from '../er-domain';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ERDomainClass, SqlhostsGroupsServersInternal } from '../enterprise-replication-types';
import { ErDomainComponent } from '../er-domain/er-domain.component';
import { CanComponentDeactivate } from '../../../../shared/canDeactivateGuard.service';
import { switchMap } from 'rxjs/operators';
import { ServerTypes } from '../../../../shared/hdr-permission/hdr-permission.model';

@Component({
  selector: 'app-enterprise-replication',
  templateUrl: './enterprise-replication.component.html',
  styleUrls: ['./enterprise-replication.component.scss']
})
export class EnterpriseReplicationComponent implements OnInit, CanComponentDeactivate {

  breadcrumb: BreadcrumbElement[] = [];
  server: InformixServer = null;

  isLoading = false;
  domain$: Observable<ERDomain> = null;
  domainAlertMessage: string = null;
  insideLoading: Boolean = false;

  selectedNode: ERNode = null;
  addedServers: SqlhostsGroupsServersInternal[] = null;
  @ViewChild('prerequistiesModal', { static: false }) prerequistiesModal: TemplateRef<any>;
  modalRef: BsModalRef;
  newERDomain: Boolean = false;
  showSqlhostsGroupsModal: Boolean = false;
  erDomainClass: ERDomainClass;
  @ViewChild(ErDomainComponent) erDomainComponent: ErDomainComponent;
  constructor(
    private route: ActivatedRoute,
    private serverService: InformixServerService,
    private erService: EnterpriseReplicationService,
    private bsModalService: BsModalService,
  ) { }

  canDeactivate() {
    return this.erDomainComponent ? this.erDomainComponent.canDeactivate() : true;
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.setServer(data.server);
    });
  }

  setServer(server: InformixServer) {
    this.server = server;

    this.breadcrumb = ServerBreadcrumb.build(server, [
      { name: 'Replication' },
      { name: 'ER Domain' }
    ]);

    this.domainAlertMessage = null;
    this.isLoading = true;

    this.serverService.getServerStatus(server.id).pipe(
      switchMap((serverStatus: any) => {
        if (!(server.serverType.serverType === ServerTypes.PRIMARY || server.serverType.serverType === ServerTypes.STANDARD)) {
          return throwError({error: { err : 'Enterprise Replication information not available on secondary servers.'}});
        }else if (serverStatus.err) {
          return throwError({error: { err : serverStatus.err}});
        }
        return of(serverStatus);
      })
    ).subscribe(
      serverStatus => this.getDomain(server),
      error => {
        this.isLoading = this.insideLoading = false;
        this.domainAlertMessage = error.error.err;
      });
  }


  getDomain(server, isSync: boolean = false) {

    if(!isSync) {
      this.erDomainClass = null;
      this.newERDomain = false;
    }
    this.insideLoading = true;
    this.erService.getDomain(server).subscribe(domain => {
      this.isLoading = this.insideLoading = false;
      this.erDomainClass = { domain, selectedNode: domain.origin };

      /* If nodes are not present. Don't need to get profile*/
      if(domain && domain.nodes && !domain.nodes.length) {
        return;
      }
      this.erService.getDomainProfile(domain).subscribe(profile => {
        /* set profile in ER Domain component and add it in the domain */
        this.erDomainComponent.setProfile(profile);
      }, errorResp => {
        /* set profile error message for all the nodes */
        this.erDomainComponent.setProfile(null, errorResp.error.err);
      });
    }, err => {
      // Loader for showing loader UI in the domain tree
      this.isLoading = this.insideLoading = false;
      this.erDomainClass = null; // Removing existing domain if any error occured
      if (err.status !== 404) {
        if (typeof err === 'string') {
          this.domainAlertMessage = err;
        }else if (err instanceof HttpErrorResponse && err.error.err) {
          this.domainAlertMessage = err.error.err;
        }else {
          this.domainAlertMessage = 'An unknown error occurred.';
        }
      }else {
        this.newERDomain = true;
      }
    });
  }

  /**
   * show modal to select sqlhosts groups and servers.
   */
  showPrerequistiesModal() {
    this.modalRef = this.bsModalService.show(this.prerequistiesModal, { backdrop: true,
      class: 'prerequisties-modal align-items-center d-flex h-100 modal-lg my-0 w-100' });
  }

  /**
   * Hide the group selection modal
   */
   hideModal() {
    this.modalRef.hide();
  }

  /**
   * method to fetch domain from server to sync with local
   */
  reSyncDomain() {
    this.getDomain(this.server, true);
  }
}
