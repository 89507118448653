/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerSpace } from './serverSpace';

@Component({
  selector: 'app-modify-space',
  templateUrl: 'modify-space.html'
})
export class ModifySpaceComponent implements OnInit {

  @Input() server: InformixServer = null;

  _selectedSpace: ServerSpace = null;
  isButtonEnabled = true;

  formGroup: UntypedFormGroup;
  expandableControl: UntypedFormControl;
  createSizeControl: UntypedFormControl;
  extendSizeControl: UntypedFormControl;

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) { }

  ngOnInit() {
    this.expandableControl = new UntypedFormControl(true, [Validators.required]);
    this.createSizeControl = new UntypedFormControl(10, [this.expandSizeCheck('create'), Validators.required]);
    this.extendSizeControl = new UntypedFormControl(10000, [this.expandSizeCheck('extend'), Validators.required]);
    this.formGroup = new UntypedFormGroup({
      expandable: this.expandableControl,
      createSize: this.createSizeControl,
      extendSize: this.extendSizeControl
    });
    this.expandableControl.valueChanges.subscribe(value => {
      if (value) {
        this.createSizeControl.enable();
        this.extendSizeControl.enable();
      } else {
        this.createSizeControl.disable();
        this.extendSizeControl.disable();
      }
    });
  }

  @Input()
  set selectedSpace(selectedSpace: ServerSpace) {
    this._selectedSpace = selectedSpace;
    this.initializeForm();
    this.resetForm();
  }

  public resetForm(): void {
    if (!this.formGroup) {
      return;
    }
    if (this._selectedSpace) {
      if (this._selectedSpace.expandable === 1) {
        this.formGroup.controls['createSize'].enable();
        if (this._selectedSpace.type === 'sbspace' || this._selectedSpace.type === 'blobspace') {
          this.formGroup.controls['extendSize'].setValue(0);
          this.formGroup.controls['extendSize'].disable();
        } else {
          this.formGroup.controls['extendSize'].setValue(this._selectedSpace.extend_size);
          this.formGroup.controls['extendSize'].enable();
        }
      } else {
        this.isButtonEnabled = true;
        this.formGroup.controls['createSize'].disable();
        this.formGroup.controls['extendSize'].disable();
        this.formGroup.controls['createSize'].setValue(0);
        this.formGroup.controls['extendSize'].setValue(0);
      }
    }
  }

  public doModifySpace() {
    const expandable: boolean = this.formGroup.value['expandable'];
    let createSize: number = this.formGroup.value['createSize'];
    let extendSize: number = this.formGroup.value['extendSize'];
    if (!expandable) {
      createSize = 0;
      extendSize = 0;
    } else if (this._selectedSpace.type === 'sbspace' || this._selectedSpace.type === 'blobspace') {
      // extend size not supported on sbspaces and blobspace, so must be set to zero
      extendSize = 0;
    }
    this.informixServerStorageService.modifySpace(this.server.id, this._selectedSpace.name, createSize, extendSize)
      .then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

  public doClose() {
    this.close.emit();
    this.resetForm();
  }

  private initializeForm(): void {
    if (this.formGroup && this._selectedSpace) {
      this.formGroup.controls['expandable'].setValue(this._selectedSpace.expandable === 1);
      this.formGroup.controls['createSize'].setValue(this._selectedSpace.create_size);
    }
  }

  expandSizeCheck(controlType: string): ValidatorFn {
    return (control: AbstractControl): { customError: string } | null => {
      if (!this.formGroup) {
        return null;
      }
      const error = 'Atleast one of the size must be greater than zero for expandable space.';
      const errors = { customError: error };
      setTimeout(() => {
        if (controlType === 'create') {
          this.isButtonEnabled = this.expandableControl.value ? control.value > 0 || this.extendSizeControl.value > 0 : true;
          if (!this.isButtonEnabled && this.formGroup.valid) {
            this.createSizeControl.setErrors(errors);
          }
          if (this.isButtonEnabled && (this.extendSizeControl.errors && this.extendSizeControl.errors.customError &&
            this.extendSizeControl.errors.customError === error)) {
            this.extendSizeControl.setErrors(null);
          }
        }
        if (controlType === 'extend') {
          this.isButtonEnabled = this.expandableControl.value ? (control.value > 0 || this.createSizeControl.value > 0) : true;
          if (!this.isButtonEnabled && this.formGroup.valid) {
            this.extendSizeControl.setErrors(errors);
          }
          if (this.isButtonEnabled && (this.createSizeControl.errors && this.createSizeControl.errors.customError &&
            this.createSizeControl.errors.customError === error)) {
            this.createSizeControl.setErrors(null);
          }
        }
      }, 0);
    };
  }
}
