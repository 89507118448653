<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <h4><strong>{{ 'systemReports.waitingSessions' | translate }}</strong>
  </h4>
  <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
    <app-data-table-column [title]="'systemReports.waitingSession' | translate" property="waitUserDisplay" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', row.wait_sid]">{{item}}</a>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.resourceOwner' | translate" property="ownerUserDisplay" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <div *ngIf="row.owner_sid != 0">
          <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', row.owner_sid]">{{item}}</a>
        </div>
        <div *ngIf="row.owner_sid == 0">{{item}}
        </div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.resourceType' | translate" property="owner_lock_type"
      [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waitObject' | translate" property="wtable" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waitTime' | translate" property="wrowid" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waitInformation' | translate" property="lock_wait_time"
      [sortable]="true" [searchable]="true">
    </app-data-table-column>
  </app-data-table>
</div>
