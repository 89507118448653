/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { InformixSensorService } from '../../monitoring/informixSensor.service';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { MonitoringProfile } from '../../monitoring/monitoringProfile';
import { Sensor } from '../../monitoring/sensor';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { InformixServerLogsService } from './informixServerLogs.service';
import { LogWindowComponent } from './log-window.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { LogResponse, RotationOptions } from './log.model';
import { HttpErrorResponse } from '@angular/common/http';

interface logDataType {
  type: string;
  line: string;
};

@Component({
  selector: 'app-informix-log',
  templateUrl: 'informix-log.html',
  styleUrls: ['./log-window.scss']
})

export class InformixLogComponent implements OnInit, OnDestroy {

  breadcrumb: BreadcrumbElement[] = null;
  private logsBreadCrumb: BreadcrumbElement = { name: 'Logs' };
  private logFileBreadCrumb: BreadcrumbElement = { name: '' };
  rotationOptions: Array<RotationOptions> = [
    { name: 'Enabled', value: true },
    { name: 'Disabled', value: false }
  ];

  server: InformixServer = null;
  monitoringProfile: MonitoringProfile;
  sensorId: string = null;
  logSensor: Sensor = null;
  sensorOldestTimestamp: number = null;
  sensorDataFirstTimestamp: number = null;
  sensorDataLastTimestamp: number = null;
  sensorIsGettingHistoricalData = false;
  sensorLogData: logDataType[] = [];
  sensorLiveTimeout: number = null;
  title: string = null;
  logRotation: string = null;
  logType: string;
  viewLiveData = true;
  logData: logDataType[] = [];
  dataLoadErrorMessage: string = null;
  minLogOffset: number = null;
  maxLogOffset: number = null;
  private liveDataPollTimeout: number = null;
  private sensorMetaDataTimeout: number = null;
  private logSub: Subscription;
  isEdit = false;
  logRotationForm: UntypedFormGroup;
  selectedLogType: String = 'All';
  logTypes = [
    {name: 'All'},
    {name: 'Error'},
    {name: 'Warning'},
    {name: 'Assert Failure'}
  ];

  @ViewChild(LogWindowComponent) logWindow: LogWindowComponent;

  constructor(
    private logsService: InformixServerLogsService,
    private sensorService: InformixSensorService,
    private monitoringService: MonitoringService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) { }

  get formControls() {
    return this.logRotationForm.controls;
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.title = data.title;
      this.logRotation = data.logRotation;
      this.logType = data.logType;
      this.sensorId = data.sensorId;
      this.loadServer(data.server);
      if (this.server.hasMonitorPassword) {
        this.getLiveData();
      } else if (this.server.agent != null) {
        this.viewLiveData = false;
      }
      this.getLogRotationInfo();
    });
  }

  initLogRotationForm() {
    this.logRotationForm = this.fb.group({
      rotationFrequency: new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(99)]),
      maxFiles: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      rotationEnabled: new UntypedFormControl(null, [(Validators.required)])
    });
    this.cancelEdit();
  }

  ngOnDestroy() {
    if (this.logSub) {
      this.logSub.unsubscribe();
    }
    if (this.sensorLiveTimeout) {
      window.clearTimeout(this.sensorLiveTimeout);
    }
    if (this.liveDataPollTimeout) {
      window.clearTimeout(this.liveDataPollTimeout);
    }
    if (this.sensorMetaDataTimeout) {
      window.clearTimeout(this.sensorMetaDataTimeout);
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.getMonitoringProfile();
    this.logFileBreadCrumb.name = this.title;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.logsBreadCrumb, this.logFileBreadCrumb]);
  }

  private getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;
      this.logSensor = profile.getSensor(this.sensorId);
      if (this.logSensor && !this.viewLiveData) {
        this.getDataFromSensor();
      }
    }).catch(err => {
      console.error('Error getting monitoring profile info', err);
    });
  }

  onLogWindowTopReached() {
    if (this.viewLiveData) {
      if (this.logSub || !this.minLogOffset || this.minLogOffset < 1) {
        return;
      }
      this.getLiveData(true);
    } else {
      this.getSensorHistoricalData();
    }
  }

  /**
   * Get type of the line. ( Warning, Error, Or Assert Failed)
   *
   * @param line string
   * @returns string
   */
  private getLineType(line: string): string {
    if (line.toLowerCase().indexOf('error') > -1 || line.toLowerCase().indexOf('oserr') > -1 ||
      line.toLowerCase().indexOf('iserrono') > -1 || line.toLowerCase().indexOf('err') > -1) {
      return 'error';
    } else if (line.toLowerCase().indexOf('warning') > -1) {
      return 'warning';
    } else if (line.toLowerCase().indexOf('assert failed') > -1) {
      return 'assert-failure';
    } else {
      return 'info';
    }
  }

  private getLiveData(prependMessages?: boolean) {
    if (this.logSub) {
      this.logSub.unsubscribe();
    }
    if (!this.server.hasMonitorPassword) {
      return;
    }
    let request: Observable<any[]>;
    if (this.minLogOffset === null) {
      request = this.logsService.getLog(this.server, this.logType);
    } else {
      const fromOffset = Math.max(this.minLogOffset - 10000, 0);
      request = this.logsService.getLog(this.server, this.logType, fromOffset, this.minLogOffset);
    }
    this.logSub = request.subscribe(data => {
      this.dataLoadErrorMessage = null;
      if (data.length > 0) {
        const _data = [...data];
        if (prependMessages) {
          _data.reverse().forEach(elem => {
            this.logData.unshift({ line: elem.message, type: this.getLineType(elem.message) });
          });
        } else {
          _data.forEach(elem => {
            this.logData.push({ line: elem.message, type: this.getLineType(elem.message) });
          });
        }
        if (this.logWindow) {
          this.logWindow.onDataAddedToTop();
        }
        this.minLogOffset = data[0].offset;
        if (this.maxLogOffset === null) {
          this.maxLogOffset = data[data.length - 1].offset;
        }
        if (!this.liveDataPollTimeout) {
          this.pollLiveData();
        }
      }
    }, (err: HttpErrorResponse) => {
      console.error(err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    }, () => {
      this.logSub = null;
    });
  }

  private pollLiveData() {
    if (this.liveDataPollTimeout) {
      window.clearTimeout(this.liveDataPollTimeout);
    }
    if (this.maxLogOffset === null) {
      return;
    }

    this.liveDataPollTimeout = window.setTimeout(() => {
      this.logsService.getLog(this.server, this.logType, this.maxLogOffset).subscribe(data => {
        if (data.length > 0) {
          data.forEach(elem => {
            this.logData.push({line: elem.message, type: this.getLineType(elem.message)});
          });
          if (this.logWindow) {
            this.logWindow.onDataAddedToBottom();
          }
          this.maxLogOffset = data[data.length - 1].offset;
        }
        this.pollLiveData();
      }, err => {
        console.error(err);
      });
    }, 5000);
  }

  private getSensorHistoricalData() {
    if (this.sensorIsGettingHistoricalData || this.sensorDataFirstTimestamp === null
      || this.sensorDataFirstTimestamp <= this.sensorOldestTimestamp) {
      return;
    }
    this.sensorIsGettingHistoricalData = true;
    this.sensorService.getSensorDataWithLimit(this.server.id, this.sensorId, 10, this.sensorDataFirstTimestamp).then(data => {
      if (data.length < 1) {
        return;
      }
      this.sensorDataFirstTimestamp = data[data.length - 1].timestamp;
      const splitData = this.parseSensorData(data);
      for (let i = splitData.length -1; i >= 0; i--) {
        // Geeting lines as string in an array elements. Spliting it further to extract individual lines.
        const lines = splitData[i].split('\n');
        lines.forEach(line =>  this.sensorLogData.unshift({line, type: this.getLineType(line)}));
       ;
      }
      if (this.logWindow) {
        this.logWindow.onDataAddedToTop();
      }
    }).catch(err => {
      console.error(err);
    }).then(() => {
      this.sensorIsGettingHistoricalData = false;
    });
  }

  changeDataSource(viewLive: boolean) {
    if (this.viewLiveData === viewLive) {
      return;
    }

    if (this.logSub) {
      this.logSub.unsubscribe();
    }
    if (this.sensorLiveTimeout) {
      window.clearTimeout(this.sensorLiveTimeout);
    }
    if (this.liveDataPollTimeout) {
      window.clearTimeout(this.liveDataPollTimeout);
    }

    this.viewLiveData = viewLive;
    if (this.viewLiveData) {
      this.logData = [];
      this.minLogOffset = null;
      this.maxLogOffset = null;
      this.getLiveData();
    } else {
      this.sensorLogData = [];
      this.sensorIsGettingHistoricalData = false;
      this.sensorOldestTimestamp = this.sensorDataFirstTimestamp = this.sensorDataLastTimestamp = null;
      this.getDataFromSensor();
    }
  }

  private getDataFromSensor() {
    if (this.sensorMetaDataTimeout) {
      window.clearTimeout(this.sensorMetaDataTimeout);
    }
    if (this.logSensor == null || this.viewLiveData) {
      return;
    }
    this.sensorService.getSensorDataMetaData(this.server.id, this.sensorId).then(meta => {
      this.sensorOldestTimestamp = meta.oldest_timestamp;
      this.sensorDataFirstTimestamp = this.sensorDataLastTimestamp = meta.most_recent_timestamp;
      this.sensorDataFirstTimestamp++;
      this.getSensorHistoricalData();
      if (this.logSensor && !this.logSensor.disabled) {
        this.pollSensorLiveData();
      }
    }).catch(err => {
      console.error('cannot get sensor meta data, retrying in 5 seconds', err);
      this.sensorMetaDataTimeout = window.setTimeout(this.getDataFromSensor.bind(this), 5000);
    });
  }

  private pollSensorLiveData() {
    if (this.sensorLiveTimeout) {
      window.clearTimeout(this.sensorLiveTimeout);
    }

    this.sensorService.getSensorData(this.server.id, this.logSensor.type.id, this.sensorDataLastTimestamp).then(data => {
      if (data.length < 1) {
        return;
      }
      this.sensorDataLastTimestamp = data[0].timestamp;
      const splitData = this.parseSensorData(data);
      for (let i = 0; i < splitData.length; i++) {
        // Geeting lines as string in an array elements. Spliting it further to extract individual lines.
        const lines = splitData[i].split('\n');
        lines.forEach(line =>  this.sensorLogData.push({line, type: this.getLineType(line)}));
      }
      if (this.logWindow) {
        this.logWindow.onDataAddedToBottom();
      }
    }).catch(err => {
      console.error(err);
    }).then(() => {
      this.sensorLiveTimeout = window.setTimeout(this.pollSensorLiveData.bind(this), this.logSensor.runInterval * 1000);
    });
  }

  private parseSensorData(data: any[]): string[] {
    data.sort((a, b) => {
      if (a.timestamp === b.timestamp) {
        return a.data.sequence - b.data.sequence;
      }
      return a.timestamp - b.timestamp;
    });

    const splitData: string[] = [];
    let currentSplit = '';
    data.forEach(dataPoint => {
      if (dataPoint.data.gap) {
        splitData.push(currentSplit);
        currentSplit = '';
      } else {
        currentSplit += dataPoint.data.message + '\n';
      }
    });
    splitData.push(currentSplit);
    return splitData;
  }

  saveLogRotationConfiguration() {
    if (this.logRotationForm.valid) {
      const formControls = this.logRotationForm.controls;
      let saveLogData: Observable<any>;
      if (this.logType === 'Online') {
        saveLogData = this.logsService.saveOnlineLogRotation(this.server, formControls.rotationEnabled.value,
          formControls.rotationFrequency.value,
          formControls.maxFiles.value);
      } else {
        saveLogData = this.logsService.saveOnbarLogRotation(this.server, formControls.rotationEnabled.value,
          formControls.rotationFrequency.value,
          formControls.maxFiles.value);
      }
      saveLogData.subscribe((res) => {
        this.notificationsService.pushSuccessNotification('Log rotation configuration saved.');
        this.getLogRotationInfo();
      },
        error => {
          this.notificationsService.pushErrorNotification(error.error.err);
        });
      this.isEdit = false;
    }
  }

  cancelEdit() {
    this.isEdit = false;
  }

  private getLogRotationInfo() {
    let getData: Observable<any>;
    if (this.logType === 'Online') {
      getData = this.logsService.getOnlineLogRotation(this.server);
    } else {
      getData = this.logsService.getOnbarLogRotation(this.server);
    }

    getData.subscribe((response: LogResponse) => {
      if (!this.logRotationForm) {
        this.initLogRotationForm();
      }
      this.logRotationForm.setValue(response);
    });
  }
}
