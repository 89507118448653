/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { InformixSensorService } from '../monitoring/informixSensor.service';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { InformixServer, InformixServerStatus, InformixServerStatusCode } from './informixServer';
import { InformixServerService } from './informixServer.service';
import { InformixServerStorageService } from './storage/informixServerStorage.service';
import { DBSPACE_PERCENT_FREE_THRESHOLD } from './storage/serverSpace';
import { environment } from './../../../environments/environment';

interface StatusBadge {
  class: string;
  message: string;
}

@Component({
  selector: 'app-dashboard-server-status',
  templateUrl: 'dashboard-server-status.html',
  styleUrls: ['dashboard-server-status.scss']
})
export class DashboardServerStatusComponent implements OnInit, OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() monitoringProfile: MonitoringProfile;
  @Output() eventLoaded = new EventEmitter<any>();
  @Output() eventServerType = new EventEmitter<any>();
  @Output() eventServerStatus = new EventEmitter<Boolean>();


  private pollIntervalHandle: number;
  private pollInterval = 10000; // TODO: make this a configurable setting

  private serverTypeInterval = 1000 * 60 * 60; // 1 hour interval
  private serverTypePollHandle: number;


  agentStatusBadge: StatusBadge;

  serverStatus: InformixServerStatus = null;

  spacePercentFreeThreshold = DBSPACE_PERCENT_FREE_THRESHOLD;
  numSpacesBelowFreeThreshold: number = null;
  oldestLevel0Backup: number = null;
  numSpacesNotBackedUp: number = null;
  numAFFiles: number = null;
  ausEnabled: boolean = null;
  serverType = environment.serverType;
  productName = environment.productName;

  private serverInfoSub: Subscription = null;
  private storageSpacesSub: Subscription = null;
  private storageBackupSub: Subscription = null;
  private logsSub: Subscription = null;
  private ausSub: Subscription = null;
  private workOffSub: Subscription = null;
  isWorkOffline: Boolean;
  statusError: string = null;
  statusLoader: Boolean = true;
  serverStatusCode = InformixServerStatusCode;
  private serverServiceOnConnStatusSub: Subscription = null;
  constructor(
    private serverService: InformixServerService,
    private storageService: InformixServerStorageService,
    private sensorService: InformixSensorService
  ) { }

  ngOnInit() {
    // update isWorkOffline status
    this.workOffSub = this.serverService.onConnStatus$.subscribe(isWorkOffline => {
      if (isWorkOffline) {
        this.isWorkOffline = true;
        this.serverStatus = null;
        this.stopPolling();
      } else {
        this.isWorkOffline = false;
        this.startPolling();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server && this.server) {
      this.updateAgentStatus();
    }

    if (changes.isWorkOffline) {
      this.getServerStatus();
    }
  }

  private updateAgentStatus() {
    this.agentStatusBadge = {
      class: 'badge-danger',
      message: 'Offline'
    };

    const agent = this.server.agent;
    if (agent.online) {
      if (agent.isConfigured) {
        this.agentStatusBadge.class = 'badge-success';
        this.agentStatusBadge.message = 'Online';
      } else {
        this.agentStatusBadge.message = 'Not configured';
      }
    }
  }

  private getServerStatus() {
    if (this.isWorkOffline) {
      this.serverStatus = null;
      return;
    }
    if (!this.server.hasMonitorPassword) {
      return;
    }
    this.serverInfoSub = this.serverService.getServerStatus(this.server.id).subscribe((info: any) => {
      this.statusLoader = false;

      const serverStats = info.serverStats;

      this.statusError = null;
      this.serverStatus = this.server.getStatusByCode(info.code);
      this.serverStatus.state = this.server.getStateByCode(info.state);
      const condition = (this.serverStatus.code === InformixServerStatusCode.ONLINE ||
                         this.serverStatus.code === InformixServerStatusCode.SINGLE_USER) &&
                         !this.serverStatus.state;
      this.eventServerStatus.emit(condition);
      this.eventLoaded.emit(null); // Emitting for updating agent status
      // Not pooling if server is not online and in blocked state
      if (condition) {
        if(serverStats) {
          this.eventLoaded.emit(serverStats);
        }
        this.getStorageSpacesInfo();
        this.getStorageBackupInfo();
        this.getAFFileInfo();
        this.getAUSStatus();
        this.getServerType();
      }
    }, err => {
      this.serverStatus = null;
      this.statusError = 'Something went wrong.';
      if(err.error.err) {
        this.statusError = err.error.err;
      }
      this.eventServerStatus.emit(false);
      this.statusLoader = false;
    });

    if(this.pollIntervalHandle) {
      window.clearInterval(this.pollIntervalHandle);
    }
    this.pollIntervalHandle = window.setInterval(() => this.getServerStatus(), this.pollInterval);
  }

  private getStorageSpacesInfo() {
    this.storageSpacesSub = this.storageService.getServerSpaces(this.server.id).subscribe(spaces => {
      this.numSpacesBelowFreeThreshold = 0;

      spaces.forEach(space => {
        if (space.percent_used > 100 - this.spacePercentFreeThreshold) {
          this.numSpacesBelowFreeThreshold++;
        }
      });
    }, err => {
      console.error('Could not get storage spaces info', err);
    });
  }

  private getStorageBackupInfo() {
    this.storageBackupSub = this.storageService.getBackupInfo(this.server.id).subscribe(backupInfo => {
      this.oldestLevel0Backup = backupInfo.oldest_level0;
      this.numSpacesNotBackedUp = backupInfo.spaces_not_backed_up;
    }, err => {
      console.error('Could not get storage backup info', err);
    });
  }

  private getAFFileInfo() {
    const sensorId = 'af_files';
    if (!this.monitoringProfile || this.monitoringProfile.getSensor(sensorId) == null) {
      return;
    }
    this.sensorService.getSensorInfo(this.server.id, sensorId).then(sensor => {
      this.sensorService.getLatestSensorDataPoint(this.server.id, sensorId).then(sensorData => {
        if (sensorData.length === 1) {
          this.numAFFiles = sensorData[0].data.count;
        }
      }).catch(err => { });
    }).catch(err => { });
  }

  private getAUSStatus() {
    this.ausSub = this.serverService.getServerAutoUpdateStatsInfo(this.server.id).subscribe(data => {
      this.ausEnabled = data.enabled;
    }, err => {
      console.error('Could not get auto update stats info', err);
    });
  }

  private getServerType() {
    // if serverTypePollHandle is already set, then return since we are already polling
    if(this.serverTypePollHandle) {
      return;
    }

    this.serverService.getServerType(this.server.id).subscribe(serverType => {
      this.eventServerType.emit(serverType);
    });
    this.serverTypePollHandle = window.setInterval(() => this.getServerType(), this.serverTypeInterval);
  }

  ngOnDestroy() {
    this.stopPolling();
    if(this.workOffSub) {
      this.workOffSub.unsubscribe();
    }
  }

  unsubscribeAll() {
    if (this.serverInfoSub) {
      this.serverInfoSub.unsubscribe();
    }
    if (this.storageSpacesSub) {
      this.storageSpacesSub.unsubscribe();
    }
    if (this.storageBackupSub) {
      this.storageBackupSub.unsubscribe();
    }
    if (this.logsSub) {
      this.logsSub.unsubscribe();
    }
    if (this.ausSub) {
      this.ausSub.unsubscribe();
    }
    if(this.serverServiceOnConnStatusSub) {
      this.serverServiceOnConnStatusSub.unsubscribe();
    }
  }

  startPolling() {
    this.getServerStatus();
  }

  stopPolling() {
    if (this.pollIntervalHandle) {
      window.clearInterval(this.pollIntervalHandle);
    }
    if (this.serverTypePollHandle) {
      window.clearInterval(this.serverTypePollHandle);
    }
    this.unsubscribeAll();
  }
}
