/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2020. All Rights Reserved
 *******************************************************************************/
export class DatabasePrivilegeInfo {
  userName: string;
  privilegeType: string;
  defaultRole: string;
  isModifyVisible?: boolean;
  selectedModifyType?: string;
}

export class PrivilegeType {
  typeName: string;
  typeDesc?: string;
  type: string;
  checked?: boolean;
  class?: string;
}

export class TablePrivilegeInfo {
  tableName: string;
  grantee: string;
  tabAuth?: string[];
  privilegeTypes?: any;
  grantor?: string;
}

export class SQLAdminUserInfo {
  userName: string;
  removedPrivileges?: string[];
  privilegeTypes: string[];
  lastModified: string;
}

export class InternalUserInfo {
  userName: string;
  authenticationType: string;
  isLocked: number;
  osUserName: string;
  uid: number;
  gid: number;
  homeDirectory: string;
  privilegeTypes: string[];
  previousPrivilegeTypes?: string[];
  password?: string;
  isDefault?: boolean;
  lockType?: string;
  mapUserBy?: string;
}
