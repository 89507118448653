/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AppSettings } from './app.settings';
import { User } from './shared/user/user';
import { UserService } from './shared/user/user.service';

const DEFAULT_IDLE_INACTIVE = 3;
const DEFAULT_KEEPALIVE_INTERVAL = 10;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})

export class AppComponent implements OnInit {
  timeOutSub$: any;
  constructor(
    private titleService: Title,
    private idle: Idle,
    private userService: UserService,
    private keepalive: Keepalive,
  ) {
    this.titleService.setTitle(AppSettings.PRODUCT_NAME);
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.userService.onLogin$.subscribe((user: User) => {
      if (user) {
        const SET_TIMEOUT_PERIOD = user.rest_session_timeout_seconds;
        this.setIdleConfig(SET_TIMEOUT_PERIOD);
      }
    });

    this.userService.onLogout$.subscribe(isLogout => {
      if (isLogout) {
        this.idle.stop(); this.unSubscribeTimeOutSub();
      }
    });
  }

  setIdleConfig(timePeriod) {
    this.idle.setIdle(DEFAULT_IDLE_INACTIVE); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(timePeriod - DEFAULT_IDLE_INACTIVE); // how long can they be idle before considered timed out, in seconds
    // provide sources that will "interrupt" aka provide events indicating the user is active
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // do something when the user has timed out
    this.timeOutSub$ = this.idle.onTimeout.subscribe(() => {
      this.userService.timeOutCurrentUser();
      this.userService.logOut(false);
      this.unSubscribeTimeOutSub();
    });

    // set keepalive parameters, omit if not using keepalive
    this.keepalive.interval(DEFAULT_KEEPALIVE_INTERVAL); // will ping at this interval while not idle, in seconds

    this.reset();
  }

  // Unsubscribing existing timeout subscriber to avoid multiple time logout call. /
  unSubscribeTimeOutSub() {
    if (this.timeOutSub$ && this.timeOutSub$.unsubscribe) {
      this.timeOutSub$.unsubscribe();
    }
  }
}
