<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-12">
      <h4><strong>Memory</strong>
        <app-data-source-toggle class="ml-2" [viewMonitoredData]="!viewLiveData"
          (viewMonitoredDataStateChange)="toggleGraph($event)">
        </app-data-source-toggle>
        <br>
      </h4>
      <div class="pb-1" *ngIf="!apiError">
        <small *ngIf="viewLiveData">You are currently viewing live data.</small>
        <small *ngIf="!viewLiveData">You are currently viewing monitored data from the sensor.</small>
      </div>
    </div>
  </div>

  <ng-template [ngIf]="apiError">
    <div class="alert alert-danger">
      Unable to retrieve memory information. <br> Error: {{apiError}}
    </div>
  </ng-template>

  <ng-template [ngIf]="isDataLoad">
    <form [formGroup]="memoryManagerForm">
      <div class="row equal-div">
        <div class="col-xl-2 col-lg-12 col-md-12 col-12">
          <div class="pb-2 font-weight-bold">
            Database Shared Memory Overview
          </div>
          <div class="card card-body card-style">
            <div>
              <label class="font-weight-bold">Allocated Memory</label>
              <p>{{ memoryObj?.total_mem | byte}}</p>
            </div>
            <div>
              <label class="font-weight-bold">Used Memory</label>
              <p>{{ memoryObj?.used_mem | byte}}</p>
            </div>
            <div>
              <label>
                <a class="font-weight-bold" [routerLink]="['/dashboard/servers/'+ server.id + '/sessions']">Number of
                  Sessions</a>
              </label>
              <p>{{memoryObj?.session_count}}</p>
            </div>
            <div>
              <label class="font-weight-bold">Largest Session Size</label>
              <p>{{ memoryObj?.max_ses_mem | byte}}</p>
            </div>
            <div>
              <label class="font-weight-bold">Average Session Size</label>
              <p>{{ memoryObj?.avg_ses_mem | byte}}</p>
            </div>
          </div>
        </div>

        <div class="col-xl-10 col-lg-12 col-md-12 col-12">
          <div class="pb-2 font-weight-bold">
            Memory Usage
          </div>
          <div class="card card-body card-style">
            <div *ngIf="viewLiveData">
              <canvas #memoryBarChart width="500" height="180"></canvas>
            </div>
            <div class="mx-auto pt-4" *ngIf="viewLiveData">
              <div class="float-left"> <span class="dot bg-gold"></span>&nbsp; Used</div>
              <div class="float-left pl-5"><span class="dot bg-lime"></span>&nbsp; Free</div>
            </div>
            <div *ngIf="!viewLiveData && server">
              <app-dashboard [server]="server" [dashboard]="memoryDashboard">
              </app-dashboard>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-6">
          <div class="pb-2 font-weight-bold">Low Memory Manager</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card card-body card-style">
            <div class="row">
              <div class="col-6 pb-3">
                <div class="pb-2 font-weight-bold">Status</div>
                <span> Off </span>
                <label class="switch" [ngClass]="{'disabled-toggle' : isEditVisible}">
                  <input type="checkbox" [disabled]="isEditVisible" (change)="toggleChecks($event.target.checked)"
                    [checked]="isToggleChecked">
                  <span class="slider round"></span>
                </label>
                <span class="pl-2 ml-1">On</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-6">
                <div class="form-group">
                  <label class="font-weight-bold">Start Threshold</label>
                  <p><input type="number" placement="right" tooltip="" appExtendedFormControl
                      formControlName="lmm_start_threshold" min="5" id="btnSubmit" (change)="this.btnCheck()"
                      class="memory-input input-style pl-1">&nbsp;MB</p>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Stop Threshold</label>
                  <p><input #stopThresholdInput type="number" id="lmm_stop_threshold" placement="right" tooltip=""
                      appExtendedFormControl formControlName="lmm_stop_threshold" (change)="this.btnCheck()"
                      class="memory-input input-style pl-1">&nbsp;MB</p>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Idle Session Threshold</label>
                  <p><input type="number" placement="right" tooltip="" appExtendedFormControl
                      formControlName="lmm_idle_time" min="1" (change)="this.btnCheck()"
                      class="memory-input input-style pl-1">&nbsp;Seconds</p>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-6">
                <div class="form-group">
                  <label class="font-weight-bold">Sessions Terminated</label>
                  <p class="pt-1">{{ memoryObj?.lmm_total_killed}}</p>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Last Termination</label>
                  <p class="pt-1">{{ memoryObj?.lmm_kill_last_time}}</p>

                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Last Reduction</label>
                  <p class="pt-1">{{ memoryObj?.lmm_reduce_last_time}}</p>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">Last Restoration</label>
                  <p class="pt-1">{{ memoryObj?.lmm_restore_last_time}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" align="right">
                <button type="button" *ngIf="!isEditVisible" class="btn btn-secondary btn-fixed-width mr-2"
                  (click)="resetFields()">
                  Cancel</button>
                  <app-check-hdr-permissions [server]="server" [hdrPermission]="memoryManagerHDR" placement="top">
                    <button type="button" *ngIf="!isEditVisible" class="btn btn-primary btn-fixed-width" [disabled]="isBtnDisable"
                      (click)="applyLowMemory()" appHDRPermission [hdrPermission]="memoryManagerHDR">
                      Save</button>
                  </app-check-hdr-permissions>
                <app-check-server-admin-permissions [server]="server" placement="top">
                  <app-check-hdr-permissions [server]="server" [hdrPermission]="memoryManagerHDR" placement="top">
                    <button type="button" *ngIf="isEditVisible" class="btn btn-primary btn-fixed-width"
                      (click)="editMemoryBox()" appHDRPermission [hdrPermission]="memoryManagerHDR" appPermissionDisable>
                      Edit</button>
                  </app-check-hdr-permissions>
                </app-check-server-admin-permissions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</div>
