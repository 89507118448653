<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <h4><strong>{{ 'systemReports.sessionActivity' | translate }}</strong>
  </h4>
  <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
    <app-data-table-column [title]="'common.user' | translate" property="user" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.clientProgram' | translate" property="progname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.sid' | translate" property="sid" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', item]">{{item}}</a>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.pid' | translate" property="pid" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.cpuTime' | translate" property="cpu_time" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.ioWaitTime' | translate" property="iowaittime" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.rowsProcessed' | translate" property="isreads" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.rowsInserted' | translate" property="iswrites" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.rowsUpdated' | translate" property="isrewrites" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.rowsDeleted' | translate" property="isdeletes" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.commits' | translate" property="iscommits" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.rollbacks' | translate" property="isrollbacks" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.sequentialScans' | translate" property="seqscans" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.pageReads' | translate" property="pagereads" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.pageWrites' | translate" property="pagewrites" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.maxSortSpace' | translate" property="max_sortdiskspace"
      [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waitingFor' | translate" property="waitingfor" [sortable]="true" [searchable]="true">
    </app-data-table-column>
  </app-data-table>
</div>
