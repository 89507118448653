/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playground-forms',
  templateUrl: 'forms.html'
})
export class PlaygroundFormsComponent implements OnInit {

  formGroup: UntypedFormGroup;
  hideControl = false;

  dropdownData: any[] = [
    {label: 'Item 1', value: 'item1'},
    {label: 'Item 2', value: 'item2'},
    {label: 'Item 3', value: 'item3', selected: true},
    {label: 'Item with a long name', value: 'item_longname'}
  ];

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      good: new UntypedFormControl('keep me filled', [Validators.required]),
      test: new UntypedFormControl('', [Validators.required])
    });
  }

  handleDropdownValueChange(event: any) {
    console.log(event);
  }

}
