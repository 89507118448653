/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';
import { AuthGuard } from './authGuard.service';
import { DashboardComponent } from './dashboard.component';
import { GroupsRoutes } from './groups/groups.routes';
import { PlaygroundRoutes } from './playground/playground.routes';
import { ServersRoutes } from './servers/servers.routes';
import { SystemSettingsRoutes } from './system-settings/system-settings.routes';
import { UserSettingsRoutes } from './user-settings/user-settings.routes';

export const DashboardRoutes: Route[] = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'groups',
        pathMatch: 'full'
      },
      ...GroupsRoutes,
      ...ServersRoutes,
      ...UserSettingsRoutes,
      ...SystemSettingsRoutes,
      ...PlaygroundRoutes
    ]
  }
];
