/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2021. All Rights Reserved.
 *******************************************************************************/
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { InformixServer } from './informixServer';
import { ServerBreadcrumb } from './serverBreadcrumb';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-server-incidents',
  template: `
  <app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
  <app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
  <div class="container-fluid page-container" *ngIf="server">
    <app-incidents *ngIf="server" [owner]="server"> </app-incidents>
  </div>
  `
})
export class ServerIncidentsComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  server: InformixServer = null;

  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [{ name: 'Incidents' }]);
  }
}
