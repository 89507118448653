/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { InformixServer } from '../informixServer';
import { InformixServerOnconfigService } from './informixServerOnconfig.service';
import { OnconfigInfo, OnconfigParamCategory } from './onconfig-info';
import { ServerOnconfigParameter } from './serverOnconfigParameter';
import { onconfigData } from './onconfig-data';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-onconfig',
  templateUrl: 'onconfig.html'
})
export class OnconfigComponent implements OnInit, OnChanges, OnDestroy {

  @Input() server: InformixServer = null;
  @Input() parameters: string[] = null;
  @Input() title = '';
  @Input() showCategories = true;
  @Input() onconfigClass = '';
  @Input() serverWarning = null;
  @Input() filterByOptions = [];
  @Output() filterOptionChange = new EventEmitter<string>();
  dataLoading = false;
  dataLoadErrorMessage: string = null;

  configuration: ServerOnconfigParameter[] = null;
  filteredConfiguration: ServerOnconfigParameter[] = null;
  categories: OnconfigParamCategory[] = OnconfigInfo.getCategories();
  selectedCategory: OnconfigParamCategory = null;
  selectedFilterValue = 'all';
  searchValue = '';

  showHelp = true;
  showDynamicOnly = false;
  editingParameter: ServerOnconfigParameter = null;

  private onConfigSub: Subscription;
  onconfigHDR: HDRPermission;
  constructor(private route: ActivatedRoute,
    private onconfigService: InformixServerOnconfigService,
    private notificationsService: NotificationsService,
    private hdrPermissionService: HDRPermissionService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server && this.server) {
      this.refreshData();
    }
    if (changes.parameters) {
      this.applyFilter();
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      this.searchValue = param.filterOption;
    });
    this.selectedCategory = this.categories[0];
    this.onconfigHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  ngOnDestroy() {
    if (this.onConfigSub) {
      this.onConfigSub.unsubscribe();
    }
  }

  refreshData() {
    if (this.onConfigSub) {
      this.onConfigSub.unsubscribe();
    }
    this.dataLoading = true;
    this.onConfigSub = this.onconfigService.getServerConfiguration(this.server.id)
      .subscribe(config => {
        this.dataLoading = false;
        this.configuration = config;
        this.configuration.forEach(onconfig => {
          onconfig.info = onconfigData[onconfig.name];
          if (!onconfig.info) {
            onconfig.info = { type: 'STRING' };
          }
        });
        this.applyFilter();
      }, (err: HttpErrorResponse) => {
        this.dataLoading = false;
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      });
  }

  toggleShowDynamicOnly() {
    this.showDynamicOnly = !this.showDynamicOnly;
    this.applyFilter();
  }

  onFilterOptionChange(option: string) {
    this.filterOptionChange.emit(option);
  }

  applyFilter() {
    if (this.configuration) {
      this.filteredConfiguration = this.configuration;
      if (this.parameters) {
        this.filteredConfiguration = this.filteredConfiguration.filter(onconfig => this.parameters.indexOf(onconfig.name) > -1);
      }
      if (this.showDynamicOnly) {
        this.filteredConfiguration = this.filteredConfiguration.filter(onconfig => onconfig.dynamic === 1);
      }
      this.filteredConfiguration = this.filteredConfiguration.filter(onconfig => this.selectedCategory.hasParam(onconfig.name));
    }
  }

  editOnconfigParam(onconfig: ServerOnconfigParameter) {
    this.editingParameter = onconfig;
  }

  cancelEditingParam() {
    this.editingParameter = null;
  }

  saveParam(onconfig: ServerOnconfigParameter, value: any) {
    this.editingParameter = null;
    this.onconfigService.updateOnconfig(this.server.id, onconfig.name, value).then(result => {
      this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
          this.refreshData();
        }
      });
    }).catch((err: HttpErrorResponse) => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : 'An error occurred');
    });
  }

  onCategoryChange(newCategory: OnconfigParamCategory) {
    this.selectedCategory = newCategory;
    this.applyFilter();
  }
}
