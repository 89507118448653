<app-data-table [data]="tableDetails.columns"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.columns' | translate">
  <app-data-table-column [title]="'common.name' | translate" property="name" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.dataType' | translate" property="type"
    [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.constraints' | translate"
    [sortable]="true">
    <ng-template appTableCell let-column="row">
      <ng-container *ngIf="column.constraints.length < 1; else constraintListTemplate">
        -
      </ng-container>
      <ng-template #constraintListTemplate>
        <ng-container *ngFor="let constraint of column.constraints">
          <div *ngIf="constraint.getDisplayName(column.id) as displayName">
            {{constraint.name}}: {{displayName}}
          </div>
        </ng-container>
      </ng-template>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.visible' | translate" property="hidden">
    <ng-template appTableCell let-hidden="item">
      <app-status-icon [status]="!hidden"></app-status-icon>
    </ng-template>
  </app-data-table-column>
</app-data-table>
