/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';

import { CheckpointsRoutes } from './checkpoints/checkpoints.routes';
import { ServerResolver } from '../server-resolver.service';
import { SessionsRoutes } from './sessions/sessions.routes';
import { ThreadsComponent } from './threads/threads.component';
import { VirtualProcessorsComponent } from './vps/virtual-processors.component';

export const PerformanceRoutes: Route[] = [
  {
    path: 'performance/threads',
    component: ThreadsComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'performance/vps',
    component: VirtualProcessorsComponent,
    resolve: {
      server: ServerResolver
    }
  },

  ...CheckpointsRoutes,
  ...SessionsRoutes

];
