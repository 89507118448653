/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Observable } from 'rxjs';
import { AbstractQuery } from '../../abstract-query';
import { InformixSQLSession } from '../../informix-sql-session';
import { Query } from '../../query';
import { QueryResultPages } from '../../query-results';
import { SchemaService } from '../../schema.service';

export class OnStatQuery extends AbstractQuery {

  private onstatArgs: string;
  private queryObj: Query = null;

  constructor(restdb: SchemaService, session: InformixSQLSession, query: string, onstatArgs: string) {
    super(restdb, session, query);

    // Remove the '-r <interval>' option from any onstat
    const result = /-r( +([0-9]+)| |)/.exec(onstatArgs);
    if (result) {
      onstatArgs = onstatArgs.replace(result[0], '');
    }

    this.onstatArgs = onstatArgs.trim();
    this.query = 'onstat ' + this.onstatArgs;

    this.queryObj = new Query(restdb, session,
      'select sysadmin:task(\'onstat\',\'' + this.onstatArgs + '\') as onstat from systables where tabid = 1');
  }

  public hasNext(): boolean {
    return false;
  }

  public next(): Observable<QueryResultPages> {
    return this.queryObj.next();
  }

  public setParams(params: any[]): void {
    // Empty implementation
  }

  public setHasNext(hasMore: boolean, resultSetId: number): void {
    // Empty implementation
  }
}
