/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved
 *******************************************************************************/
import { UsersService } from './users.service';
import { User } from '../../../shared/user/user';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private userService: UsersService, private router: Router, private location: Location) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): User | Promise<User> {
    let userId: number = null;
    for (let i = route.pathFromRoot.length - 1; i >= 0; i--) {
      if (route.pathFromRoot[i].params.userId) {
        userId = parseInt(route.pathFromRoot[i].params.userId, 10);
        break;
      }
    }

    if (userId === null || isNaN(userId)) {
      return null;
    }

    return new Promise<User>(resolve => {
      this.userService.getUser(userId).then(group => {
        resolve(group);
      }).catch((err: HttpErrorResponse) => {
        resolve(null);

        this.router.navigate(['dashboard', 'system-settings', 'users'], { skipLocationChange: true }).then(() => {
          this.location.replaceState(state.url);
        });
      });
    });
  }

}
