/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
export abstract class TreeViewNode {

  public parent: TreeViewNode = null;
  public children: TreeViewNode[] = [];

  public addChild(child: TreeViewNode) {
    if (child.parent) {
      if (child.parent === this) {
        return;
      }
      child.parent.removeChild(child);
    }

    child.parent = this;
    this.children.push(child);
  }

  public unshiftChild(child: TreeViewNode) {
    if (child.parent) {
      if (child.parent === this) {
        return;
      }
      child.parent.removeChild(child);
    }

    child.parent = this;
    this.children.unshift(child);
  }

  public removeChild(child: TreeViewNode) {
    const index = this.children.indexOf(child);
    if (index > -1) {
      this.children.splice(index, 1);
      child.parent = null;
    }
  }
}
