<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title pull-left">
            Sync replicate
            <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
        </h5>

        <button type="button" class="close" (click)="hideModal()">
            <i class="icon icon-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formGroup">
            <ng-container *ngIf="firstPage; else SyncNextPage">
                <div class="row mt-2">
                    <div class="col">
                        <div class="alert alert-danger mx-2" *ngIf="error">
                            <button type="button" class="close" (click)="error = null">
                                <i class="icon icon-times"></i>
                            </button>
                            {{error}}
                        </div>
                        <div class="font-weight-bold mb-2"><span class="text-danger">* </span>Task Name </div>
                        <div class="input-group">
                            <input type="text" formControlName="taskName" class="form-control" placeholder=""
                                title="Task Name" (change)="checkJobNameExist()" />
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <div class="input-group">
                            <label class="font-weight-bold"><span class="text-danger">* </span>Extra target rows</label>
                            <app-radio-group class="justify-content-start ml-2" formControlName="extraTargetRows">
                                <app-radio value="delete">Delete</app-radio>
                                <app-radio class="ml-3" value="keep">Keep</app-radio>
                                <app-radio class="ml-3" value="merge">Merge</app-radio>
                            </app-radio-group>
                        </div>
                    </div>
                </div>

                <div class="font-weight-bold mb-2 mt-3"><span class="text-danger">* </span>Master Server</div>
                <div class="row mt-3">
                    <div class="col">
                        <select class="form-control" formControlName="serverName" *ngIf="!isLoading">
                            <option *ngIf="serverNames.length !== 0" value="" selected>-- Select server name --</option>
                            <option *ngFor="let server of serverNames" value="{{server.name}}">{{server.name}}
                            </option>
                        </select>
                        <select class="form-control" formControlName="serverName" *ngIf="isLoading">
                            <option value="">Loading...</option>
                        </select>
                    </div>
                </div>

                <div class="font-weight-bold mb-2 mt-3"><span class="text-danger">* </span>Target Server</div>
                <div class="row mt-3 font-weight-bold">
                    <div class="col d-flex">
                        <div class="flex-1">
                            <select class="form-control" formControlName="targetServerName" *ngIf="!isLoading">
                                <option *ngIf="serverData.serverList.length !== 0" value="" selected>-- Select server
                                    name --
                                </option>
                                <option *ngFor="let server of serverData.serverList" value="{{server.name}}">
                                    {{server.name}}
                                </option>
                            </select>
                            <select class="form-control" formControlName="serverName" *ngIf="isLoading">
                                <option value="">Loading...</option>
                            </select>
                        </div>
                        <button class="btn btn-secondary m-0 ml-1" [disabled]="isAllTargetServers"
                            (click)="addTargetServers()">Add</button>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <app-checkbox [checked]="isAllTargetServers" (change)="allTargetServers()">
                            All target servers
                        </app-checkbox>
                    </div>
                </div>

                <div class="row m-0 mb-5" *ngIf="!isLoading && serverData.targetServers?.length && !isAllTargetServers">
                    <div class="col d-flex p-0">
                        <table class="table mt-4 mb-0">
                            <tbody>
                                <tr *ngFor="let server of  serverData.targetServers; let i = index;">
                                    <td> {{server?.name}}</td>
                                    <td class="text-right"><i class="icon icon-times"
                                            style="color: gray;font-size: 16px;" (click)="removeTargetServer(i);"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>

            <ng-template #SyncNextPage>
                <div class="row mt-3">
                    <div class="col">
                        <app-checkbox [checked]="isPreventCheckTimeSeries"
                            (change)="isPreventCheckTimeSeries = !isPreventCheckTimeSeries;">
                            Prevent the checking of time series data.
                        </app-checkbox>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <app-checkbox [checked]="isAvoidTimeseriesElements"
                            (change)="isAvoidTimeseriesElements = !isAvoidTimeseriesElements;">
                            Avoid checking time series elements that are marked as hidden
                        </app-checkbox>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <div class="input-group">
                            <label class="font-weight-bold">Fire trigger</label>
                            <app-radio-group class="justify-content-start ml-2" formControlName="isFireTriggers" title="Specifies how to handle triggers at the target servers while data is synchronizing">
                                <app-radio value="off">Off</app-radio>
                                <app-radio class="ml-3" value="on">On</app-radio>
                                <app-radio class="ml-3" value="follow">Follow</app-radio>
                            </app-radio-group>
                        </div>
                    </div>
                </div>

                <div class="font-weight-bold mb-4 mt-3">Size of the send queue (Kilobytes & Megabytes)</div>
                <div class="row mt-2">
                    <div class="col-10">
                        <input type="text" class="form-control" title="Replicate name"
                            placeholder="Size in either Kilobytes (50K) or Megabytes (50M)"
                            formControlName="sendQueueSize">
                    </div>
                    <div class="col-2">
                        <select class="form-control form-control-sm" (change)="onSendQueueFormat($event)">
                            <option value="K">Kilobytes</option>
                            <option value="M">Megabytes</option>
                        </select>
                    </div>
                </div>

            </ng-template>
        </form>
    </div>
    <div class="modal-footer">
        <div class="float-left">
            <button type="button" class="btn btn-secondary" (click)="hideModal()">Close</button>
        </div>
        <div class="float-right">
            <button type="button" class="btn btn-secondary mr-3" (click)="firstPage = !firstPage;">{{ firstPage
                ? 'More
                options' : 'Back' }}</button>
            <button type="button" class="btn btn-primary justify-content-end" (click)="save()"
                [disabled]="isLoading || formGroup.invalid">Save</button>
        </div>
    </div>
</div>
