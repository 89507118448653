<div bsModal #addColumnModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}"
  (onShown)="onModalShown()" (onHidden)="onModalClose()">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{isEdit? ('schemaManager.createTable.title.editColumn' | translate) :
          ('schemaManager.createTable.title.addColumns' | translate)}}</h5>
        <button type="button" class="close" (click)="closeModal()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body pt-0">
        <form [formGroup]="createColumnForm">
          <div class="row align-items-end">
            <div class="col-auto pt-3">
              <label class="font-weight-bold"><span class="text-danger">*
                </span>{{'schemaManager.createTable.label.columnName' | translate}}</label>
              <input #columnNameInput class="form-control"
                [placeholder]="'schemaManager.createTable.placeholder.columnName' | translate" tooltip=""
                formControlName="tableColumnName" appExtendedFormControl [customErrors]="columnNameErrorHandlers">
            </div>
            <div class="col-auto pt-3">
              <label class="font-weight-bold">{{'schemaManager.createTable.label.selectDataType' | translate}}</label>
              <select class="form-control" formControlName="columnDataType">
                <option [value]="option.value" *ngFor="let option of colDataTypes">{{option.name}}</option>
              </select>
            </div>
            <div class="col-auto pt-3"
              *ngIf="columnDataType.value === 'set' || columnDataType.value === 'multiset' || columnDataType.value === 'list'">
              <label class="font-weight-bold">{{columnDataType.value | titlecase}}
                {{'schemaManager.createTable.label.dataType' | translate}}</label>
              <select class="form-control" formControlName="setDataType"
                [title]="'schemaManager.createTable.tooltips.dataType' | translate"
                (change)="currentDataType = setDataType.value">
                <option [value]="option.value" *ngFor="let option of setDataTypes">{{option.name}}</option>
              </select>
            </div>
            <div class="col-auto" *ngIf="tableType === 'standard' || tableType === 'raw'">
              <app-checkbox class="pt-3" formControlName="nullValues">
                <span class="font-weight-bold">{{'schemaManager.createTable.label.allowNulls' | translate}}</span>
              </app-checkbox>
            </div>
          </div>

          <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{currentDataType: currentDataType}">
          </ng-template>
          <ng-template #templateRef>
            <div class="row">
              <div class="col-auto pt-3"
                *ngIf="(optionsWithNumVal.indexOf(currentDataType) > -1 || optionsWithStrValue.indexOf(currentDataType) > -1) &&
                columnDataType.value !== 'list' && columnDataType.value !== 'set' && columnDataType.value !== 'multiset'">
                <label class="font-weight-bold">{{'schemaManager.createTable.label.defaultValue' | translate}}</label>
                <input class="form-control" type="text" list="defaultVal" formControlName="defaultValue" tooltip=""
                  appExtendedFormControl [customErrors]="charTypesErrorHandlers"
                  [title]="'schemaManager.createTable.tooltips.defaultValueDesc' | translate">
                <datalist id="defaultVal">
                  <option *ngFor="let op of defaultValOptions" [ngValue]="op">{{op}}</option>
                </datalist>
              </div>
              <div class="col-auto pt-3" *ngIf="optionsWithSelect.indexOf(currentDataType) > -1 && columnDataType.value !== 'list' 
                && columnDataType.value !== 'set' && columnDataType.value !== 'multiset'">
                <label class="font-weight-bold">{{'schemaManager.createTable.label.defaultValue' | translate}}</label>
                <select class="form-control" formControlName="defaultValue"
                  [title]="'schemaManager.createTable.tooltips.defaultValueDesc' | translate">
                  <option *ngFor="let op of defaultValOptions" [ngValue]="op">{{op}}</option>
                </select>
              </div>
              <div class="col-auto pt-3"
                [hidden]="!(currentDataType === 'char' || currentDataType === 'varchar' || currentDataType === 'nchar' || currentDataType === 'nvarchar' || currentDataType === 'lvarchar')">
                <label class="font-weight-bold">{{'schemaManager.createTable.columnAttributes.lengthInBytes' |
                  translate}}</label>
                <input class="form-control w-50 pl-2 pr-1" type="number" tooltip="" formControlName="length"
                  [title]="'schemaManager.createTable.tooltips.lengthDesc' | translate" appExtendedFormControl>
              </div>
              <div class="col-auto pt-3" [hidden]="!(currentDataType === 'serial' || currentDataType === 'bigSerial')">
                <label class="font-weight-bold">{{'schemaManager.createTable.columnAttributes.startValue' |
                  translate}}</label>
                <input class="form-control w-75 pl-2 pr-1" type="number" tooltip="" formControlName="startValue"
                  appExtendedFormControl>
              </div>
              <div class="col-auto pt-3" *ngIf="currentDataType === 'namedRow'">
                <label class="font-weight-bold">{{'schemaManager.createTable.label.namedRow' | translate}}</label>
                <select class="form-control" formControlName="namedRowType"
                  [title]="'schemaManager.createTable.tooltips.namedRow' | translate">
                  <option *ngFor="let op of namedRowTypes | keyvalue" [ngValue]="op.key">{{op.key}}</option>
                </select>
              </div>
              <div class="col-auto pt-3" *ngIf="currentDataType === 'dateTime' || currentDataType === 'interval'">
                <label class="font-weight-bold">{{'schemaManager.createTable.columnAttributes.largestTimeUnit' |
                  translate}}</label>
                <select class="form-control" formControlName="largestTimeUnit">
                  <option *ngFor="let unit of largestUnit" [ngValue]="unit">{{unit}}</option>
                </select>
              </div>
              <div class="col-auto pt-3" *ngIf="currentDataType === 'dateTime' || currentDataType === 'interval'">
                <label class="font-weight-bold">{{'schemaManager.createTable.columnAttributes.smallestTimeUnit' |
                  translate}}</label>
                <select class="form-control" formControlName="smallestTimeUnit">
                  <option *ngFor="let unit of smallestUnit" [ngValue]="unit">{{unit}}</option>
                </select>
              </div>
              <div class="pt-3 pl-3" [hidden]="!(currentDataType === 'money' || currentDataType === 'decimal' || 
                (currentDataType === 'interval' && largestTimeUnit.value !== 'FRACTION'))"
                [ngClass]="{'col-lg-2 col-md-3 col-sm-3 col-5': currentDataType === 'money' || currentDataType === 'decimal'}">
                <label class="font-weight-bold ">{{'schemaManager.createTable.label.precisionDigit' |
                  translate}}</label><br>
                <input class="form-control number-input-width mr-2 pl-2 pr-1 d-inline-block" type="number" tooltip=""
                  [title]="currentDataType === 'money' || currentDataType === 'decimal' ? ('schemaManager.createTable.tooltips.precisionAndScaleDesc' | translate) : 
                  ('schemaManager.createTable.tooltips.precisionDesc' | translate)" formControlName="precision"
                  appExtendedFormControl><span class="font-weight-bold"
                  *ngIf="currentDataType === 'money' || currentDataType === 'decimal'">(1-32)</span>
              </div>
              <div class="pt-3 pl-3"
                [ngClass]="{'col-lg-3 col-md-4 col-sm-4 col-12': currentDataType === 'money' || currentDataType === 'decimal'}"
                [hidden]="!(currentDataType === 'money' || currentDataType === 'decimal'
               || ((currentDataType === 'interval'  || currentDataType === 'dateTime') && smallestTimeUnit.value === 'FRACTION'))">
                <label class="font-weight-bold">{{'schemaManager.createTable.columnAttributes.scale' |
                  translate}}</label><br>
                <input class="form-control number-input-width mr-2 pl-2 pr-1 d-inline-block" type="number" tooltip=""
                  [title]="currentDataType === 'money' || currentDataType === 'decimal' ? ('schemaManager.createTable.tooltips.precisionAndScaleDesc' | translate) : 
                ('schemaManager.createTable.tooltips.scaleDesc' | translate)" formControlName="scale"
                  appExtendedFormControl><span class="font-weight-bold"
                  *ngIf="currentDataType === 'money' || currentDataType === 'decimal'">(1-{{'schemaManager.createTable.label.precisionDigit'
                  | translate}})</span>
              </div>
            </div>
            <div *ngIf="objectKeys(namedRowTypes).length < 1 && currentDataType === 'namedRow'"
              class="mt-3 mb-0 w-50 alert alert-warning">
              <label></label><i class="icon icon-warning text-warning"></i>
              {{'schemaManager.createTable.errorMsg.noNamedRowFound' | translate}}
            </div>
            <div class="row align-items-center" *ngIf="currentDataType === 'byte' || currentDataType === 'text'">
              <div class="col-auto pt-3 pr-0"><label
                  class="mb-0 font-weight-bold">{{'schemaManager.createTable.columnAttributes.storeColumnIn' |
                  translate}}:</label></div>
              <div class="col-auto pt-3">
                <app-radio-group formControlName="storeColumnIn"
                  [title]="'schemaManager.createTable.tooltips.storeIn' | translate">
                  <app-radio value="table" class="pr-2"> {{'schemaManager.createTable.label.table' | translate}}
                  </app-radio>
                  <app-radio value="blobspace" class="pr-2" [disabled]="requiredSpaces?.length < 1">
                    {{'schemaManager.createTable.label.blobspace' | translate}}
                  </app-radio>
                </app-radio-group>
              </div>
              <div class="col-auto pt-3 pl-0"><select class="form-control" formControlName="selectedSpace">
                  <option *ngFor="let space of requiredSpaces" [value]="space.name">{{space.name}}</option>
                </select></div>
            </div>
            <div class="row" *ngIf="currentDataType === 'blob' || currentDataType === 'clob'">
              <div class="col-lg-3 col-6 col-md-4 col-sm-4 pt-3">
                <label class="font-weight-bold">{{'schemaManager.createTable.label.storeColumnIn' | translate}}:</label>
                <div class="border p-2" [title]="'schemaManager.createTable.tooltips.storeColumnIn' | translate">
                  <div *ngFor="let space of requiredSpaces" class="store-column-height overflow-auto">
                    <app-checkbox (change)="onSpaceSelect($event, space)" [checked]="space.selected">
                      <span>{{space.name}}</span>
                    </app-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-3 col-4 pt-3">
                <label class="font-weight-bold" tooltip="">
                  {{'schemaManager.createTable.columnAttributes.extentSize' | translate}}
                </label>
                <input type="number" class="form-control pl-2 pr-1" formControlName="extentSize" appExtendedFormControl
                  [title]="'schemaManager.createTable.tooltips.extentSizeDesc' | translate">
              </div>
              <div class="col-lg-4 col-sm-5 col-md-5 col-12 pt-3 mt-4">
                <app-checkbox formControlName="turnOnLogging"
                  [title]="'schemaManager.createTable.tooltips.logging' | translate">
                  <span class="font-weight-bold">{{'schemaManager.createTable.label.logging' | translate}}</span>
                </app-checkbox><br>
                <app-checkbox formControlName="recordAccessTime"
                  [title]="'schemaManager.createTable.tooltips.accessTime' | translate">
                  <span class="font-weight-bold">{{'schemaManager.createTable.label.accessTime' | translate}}</span>
                </app-checkbox><br>
                <app-checkbox formControlName="highDataIntegrity"
                  [title]="'schemaManager.createTable.tooltips.highIntegrity' | translate">
                  <span class="font-weight-bold">{{'schemaManager.createTable.label.highIntegrity' | translate}}</span>
                </app-checkbox>
              </div>
            </div>
            <ng-container *ngIf="currentDataType === 'interval' && columnDataType.value !== 'list' 
            && columnDataType.value !== 'set' && columnDataType.value !== 'multiset'">
              <div class="pt-3">
                <label class="mb-0 font-weight-bold">{{'schemaManager.createTable.label.defaultValue' |
                  translate}}</label>
              </div>
              <div class="pt-2">
                <app-checkbox formControlName="nullAsDefault" *ngIf="tableType === 'standard' || tableType === 'raw'">
                  <span class="font-weight-bold">{{'schemaManager.createTable.label.nullAsDefault' | translate}}</span>
                </app-checkbox>
              </div>
              <div class="pt-2">
                <app-checkbox formControlName="noDefaultValue">
                  <span class="font-weight-bold">{{'schemaManager.createTable.label.noDefaultValue' | translate}}</span>
                </app-checkbox>
              </div>
              <div class="row pt-2">
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('YEAR')">
                  <input class="form-control" *ngIf="createColumnForm.get('year')" type="number" tooltip=""
                    formControlName="year" appExtendedFormControl>
                  <div class="pt-1">{{'time.yearLabel' | translate}}</div>
                </div>
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('MONTH')">
                  <input class="form-control" type="number" tooltip="" formControlName="month" appExtendedFormControl>
                  <div class="pt-1">{{'time.monthLabel' | translate}}</div>
                </div>
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('DAY')">
                  <input class="form-control" type="number" tooltip="" formControlName="day" appExtendedFormControl>
                  <div class="pt-1">{{'time.dayLabel' | translate}}</div>
                </div>
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('HOUR')">
                  <input class="form-control" type="number" tooltip="" formControlName="hour" appExtendedFormControl>
                  <div class="pt-1">{{'time.hourLabel' | translate}}</div>
                </div>
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('MINUTE')">
                  <input class="form-control" type="number" tooltip="" formControlName="minute" appExtendedFormControl>
                  <div class="pt-1">{{'time.minuteLabel' | translate}}</div>
                </div>
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('SECOND')">
                  <input class="form-control" type="number" tooltip="" formControlName="second" appExtendedFormControl>
                  <div class="pt-1">{{'time.secondLabel' | translate}}</div>
                </div>
                <div class="col-2 col-sm-2" [hidden]="!intervals.includes('FRACTION')">
                  <input class="form-control" type="number" tooltip="" formControlName="fraction"
                    appExtendedFormControl>
                  <div class="pt-1">{{'time.fractionLabel' | translate}}</div>
                </div>
              </div>
            </ng-container>
          </ng-template>

          <div class="row pt-3" *ngIf="(tableType === 'standard' || tableType === 'raw') && columnDataType.value !== 'blob'
          && columnDataType.value !== 'clob' && columnDataType.value !== 'byte' && columnDataType.value !== 'text'">
            <app-checkbox class="pl-3" formControlName="columnConstraint">
              <span class="font-weight-bold">{{'schemaManager.createTable.label.columnCheckConstraint' |
                translate}}</span>
            </app-checkbox>
          </div>
          <div class="m-1 pt-1" *ngIf="columnConstraint.value">
            <textarea class="col-12 form-control" formControlName="constraintText" autocomplete="off" autocorrect="off"
              autocapitalize="off" spellcheck="false"
              [placeholder]="'schemaManager.createTable.placeholder.checkConstraint' | translate">
            </textarea>
          </div>
          <div class="pt-3 px-0 col-3" *ngIf="tableType === 'externalfixed'"><label
              class="font-weight-bold">{{'schemaManager.createTable.label.externalCharLength' | translate}}</label>
            <input class="form-control w-50 pl-2 pr-1" type="number" tooltip="" min="1" max="32767"
              formControlName="externalCharLength" appExtendedFormControl>
          </div>
          <div class="row pt-3">
            <div class="col-12 text-right">
              <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">
                {{isEdit ? ('button.cancel' | translate) : ('button.close' | translate)}}</button>
              <button type="button" class="btn btn-primary" (click)="addColumns()"
                [disabled]="!createColumnForm.valid || (objectKeys(namedRowTypes).length < 1 && currentDataType === 'namedRow')">
                {{isEdit ? ('button.save' | translate) : ('button.add' | translate)}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>