/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AbstractSystemReport } from './abstract-system-report';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SchemaService } from '../schema/schema.service';
import { SystemReportsService } from './system-reports.service';

@Component({
  selector: 'app-table-buffer-pool-activity-report',
  templateUrl: './table-buffer-pool-activity-report.html'
})

export class TableBufferPoolActivityReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId = 'table_buffer_pool_activity';

  databases: string[] = null;
  selectedDatabase = null;
  isCatalog: Boolean = false;
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    systemReportsService: SystemReportsService,
    notificationsService: NotificationsService,
    translate: TranslateService,
    private schemaService: SchemaService,
  ) {
    super(systemReportsService, notificationsService, translate);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getDatabases();
    });
    this.reportData = null;
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get('systemReports.tableBufferPoolActivity').subscribe((text: string) => {
      this.setBreadcrumb(text);
    });
  }

  getDatabases() {
    this.schemaService.getDatabases(this.server).subscribe((res: any) => {
      this.databases = [];
      res.forEach(element => {
        this.databases.push(element.name);
      });
      this.databases.push('System Objects');
      this.databases.push('All');
      this.selectedDatabase = this.databases[0];

      // commented this line - not to show default reports, let user select database from dropdown
      // this.getSystemDatabaseReport(this.reportId, this.selectedDatabase);
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  changeDatabase(event) {
    if(event.target.value === '') {
      return;
    }
    if (event.target.value === 'All') {
      this.selectedDatabase = null;
    } else if (event.target.value === 'System Objects') {
      this.selectedDatabase = 'system_object';
    } else {
      this.selectedDatabase = event.target.value;
    }
    this.reportData = null;
    this.isLoading = true;
    this.getSystemDatabaseReport(this.reportId, this.selectedDatabase, this.isCatalog);
  }

  updateReport() {
    if (!this.isCatalog && this.reportsData) {
     this.reportData = this.systemReportsService.updateReport(this.isCatalog, this.reportsData, true);
    } else {
      this.reportData = this.reportsData;
    }
  }

  includeCatalog(isCatalog: Boolean) {
    this.isCatalog = isCatalog;
    this.updateReport();
  }
}
