<div *ngIf="missingSensorList.length > 0 || disabledSensorList.length > 0" class="dropdown" container="body" placement="bottom right" dropdown>
  <button class="btn btn-secondary btn-sm dropdown-toggle border-0" type="button" id="sensorRequiredDropDownMenu" dropdownToggle>
    <i class="icon icon-warning text-warning" tooltip="Sensor information missing" placement="left"></i>
  </button>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" style="min-width: 25rem" aria-labelledby="sensorRequiredDropDownMenu">
    <li class="dropdown-item-plain" style="white-space: normal">
        <div class="mt-1 mb-1">
            <h5>
              <i class="icon icon-warning text-warning mr-1"></i>
              <strong>Sensor information missing</strong>
              <button type="button" class="close">
                <i class="icon icon-times"></i>
              </button>
            </h5>
            <div *ngIf="missingSensorList.length > 0 || disabledSensorList.length > 0">
              <p>
                This page uses the following sensors which are either not defined or disabled in the server's monitoring profile.
              </p>
              <ul>
                <li *ngFor="let sensor of missingSensorList">{{sensor?.name}}</li>
              </ul>
              <ul>
                <li *ngFor="let sensor of disabledSensorList">{{sensor?.name}} (disabled)</li>
              </ul>
              <p></p>

              <p>Click to enable all sensors now or go to the <a [routerLink]="['/dashboard/servers', server.id, 'monitoring']">Monitoring</a> page to make changes to the monitoring configuration.</p>
              <app-check-permissions [permissions]="server.permissions.admin" placement="top">
                <button type="button" class="btn btn-primary" title="Enable the sensor now" appPermissionDisable (click)="doEnableSensors()">Enable sensors now</button>
              </app-check-permissions>
            </div>
          </div>
    </li>
  </ul>
</div>
