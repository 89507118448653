/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { InformixServer } from '../informixServer';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { InformixDatabase } from '../schema/informix-database';
import { map } from 'rxjs/operators';

@Injectable()
export class SqltraceService {
  constructor(private httpClient: HttpClient) { }
  getDatabases(server: InformixServer): Observable<InformixDatabase[]> {
    return this.httpClient.get(server.getDatabasesUrl()).pipe(
      map((response: any[]) => response.map(v => new InformixDatabase(server, v))));
  }
  public getSqltraceSummary(server: InformixServer): Observable<any> {
    return this.httpClient.get<any>('informix/' + server.id + '/sqltrace');
  }

  public getSqltraceInfo(server: InformixServer): Observable<any> {
    return this.httpClient.get<any>('informix/' + server.id + '/sqltrace/info');
  }

  public setSqltracingState(server: InformixServer, state: string): Promise<any> {
    return this.httpClient.put<any>('informix/' + server.id + '/sqltrace/config', { state }).toPromise<any>();
  }
  public getQueryTreeIdByStatement(server: InformixServer, data: any) {
    const url = 'informix/' + server.id + '/sqltrace/execution-plan';
    return this.httpClient.post<any>(url, data);
  }
  public getQueryTreeIdByStatementSubscribe(server: InformixServer, data: any): Observable<any> {
    const url = 'informix/' + server.id + '/sqltrace/execution-plan';
    return this.httpClient.post<any>(url, data);
  }
  public enableSqltracing(server: InformixServer, mode: string, level: string, nTraces: number, traceSize: number,
    users: string[], databases: string[], clearBuffer: boolean): Promise<any> {
    return this.httpClient.put<any>('informix/' + server.id + '/sqltrace/config', {
      state: 'On',
      trace_mode: mode,
      trace_level: level,
      ntraces: nTraces,
      trace_size: traceSize,
      users,
      databases,
      clear_buffer: clearBuffer
    }).toPromise<any>();
  }

  public getStatementsByType(server: InformixServer): Promise<any[]> {
    return this.httpClient.get<any[]>('informix/' + server.id + '/sqltrace/stmttypes').toPromise<any[]>();
  }

  public getStatements(server: InformixServer, stmttype?: string, fromTimestamp?: number, toTimestamp?: number): Observable<any[]> {
    let url = 'informix/' + server.id + '/sqltrace/statements';
    if (stmttype || fromTimestamp || toTimestamp) {
      url += '?';
      if (stmttype) {
        url += 'type=' + stmttype;
      }
      if (stmttype && (fromTimestamp || toTimestamp)) {
        url += '&';
      }
      if (fromTimestamp) {
        url += 'fromTimestamp=' + fromTimestamp / 1000;
      }
      if (fromTimestamp && toTimestamp) {
        url += '&';
      }
      if (toTimestamp) {
        url += 'toTimestamp=' + toTimestamp / 1000;
      }
    }
    return this.httpClient.get<any[]>(url);
  }
  public getStatementExecutions(server: InformixServer, sqlid: string, fromTimestamp?: number, toTimestamp?: number): Observable<any[]> {
    let url = 'informix/' + server.id + '/sqltrace/statements/' + sqlid + '/executions';
    if (fromTimestamp || toTimestamp) {
      url += '?';
      if (fromTimestamp) {
        url += 'fromTimestamp=' + fromTimestamp / 1000;
      }
      if (fromTimestamp && toTimestamp) {
        url += '&';
      }
      if (toTimestamp) {
        url += 'toTimestamp=' + toTimestamp / 1000;
      }
    }
    return this.httpClient.get<any[]>(url);
  }

  public getStatementProfile(server: InformixServer, sqlid: string): Promise<any> {
    return this.httpClient.get<any>('informix/' + server.id + '/sqltrace/statements/' + sqlid).toPromise<any>();
  }

  public getStatementIterators(server: InformixServer, sqlid: string): Promise<any> {
    const url = 'informix/' + server.id + '/sqltrace/statements/' + sqlid + '/iterators';
    return this.httpClient.get<any>(url).toPromise<any>();
  }

  public getTransactions(server: InformixServer): Promise<any[]> {
    return this.httpClient.get<any[]>('informix/' + server.id + '/sqltrace/transactions').toPromise<any[]>();
  }

  public getTransactionInfo(server: InformixServer, sqlid: number): Promise<any> {
    const url = 'informix/' + server.id + '/sqltrace/statements/' + sqlid + '/transaction';
    return this.httpClient.get<any>(url).toPromise<any>();
  }
}
