/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../informixServer';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SystemReportsService {

  databaseList = ['sysmaster', 'sysadmin', 'sysutils', 'sysuser', 'syscdr', 'sysha'];
  constructor(private httpClient: HttpClient) { }

  public runSystemReport(server: InformixServer, id: string, db?: any, fromTimestamp?: any, toTimestamp?: any): Observable<any> {
    let url = 'informix/' + server.id + '/reports?id=' + id;
    if (db) {
      url += '&database=' + db;
    }
    if (fromTimestamp) {
      url += '&fromTimestamp=' + fromTimestamp;
    }
    if (toTimestamp) {
      url += '&toTimestamp=' + toTimestamp;
    }
    return this.httpClient.get<any>(url);
  }

// include or exclude system catalogs from system-reports
  updateReport(isCatalog: Boolean, reportsData, isBufferPoolReport: boolean = false) {
    if(isBufferPoolReport) {
      return reportsData.filter(report => report.tabid >= 99);
    }
    const reports = [...reportsData];
    const filteredReports = reports.filter(report =>
      (report.tabid >= 99 || report.tabid === null) && this.doesNotContainAnySystemDbName(report.name)
    );
    if(isCatalog) {
      const systemReports = reports.filter(report => (report.tabid !== null));
      return reportsData = [...filteredReports, ...systemReports];
    }
    reportsData = filteredReports;
    return reportsData;
  }

  // exclude table names which include system database names
  doesNotContainAnySystemDbName(tableName) {
    return !this.databaseList.some(dbName => tableName.includes(dbName + '.'));
  }
}
