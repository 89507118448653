/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { InformixServerOnconfigService } from '../configuration/informixServerOnconfig.service';
import { ServerOnconfigParameter } from '../configuration/serverOnconfigParameter';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerStoragePoolEntry } from './serverStoragePoolEntry';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';

@Component({
  selector: 'app-storage-pool-list',
  templateUrl: 'storage-pool-list.html'
})
export class StoragePoolListComponent implements OnInit, OnDestroy {

  @Input() maximized = true;
  @ViewChild('storagePoolModal') storagePoolModal: ModalDirective;

  dataLoading = false;
  dataLoadErrorMessage: string = null;

  server: InformixServer = null;
  entries: ServerStoragePoolEntry[] = null;
  filteredEntries: ServerStoragePoolEntry[] = null;
  selectedEntry: ServerStoragePoolEntry;
  filter = 'all';

  spaceRemaining = 0;
  numExpandableEntries = 0;

  isEditingConfiguration = false;
  onconfig_sp_autoexpand: ServerOnconfigParameter;
  onconfig_sp_threshold: ServerOnconfigParameter;
  onconfig_sp_waittime: ServerOnconfigParameter;
  spAutoExpand: boolean;
  spThreshold: number;
  spThresholdAsString: string;
  spThresholdUnit: string;
  spThresholdOptions: string[] = ['Off', '%', 'KB'];
  spWaitTime: number;

  saveConfigButtonEnabled = true;

  storageBreadCrumb: BreadcrumbElement = { name: 'Storage', link: 'storage' };
  poolBreadCrumb: BreadcrumbElement = { name: 'Pool', link: () => this.goToPoolList() };

  breadcrumb: BreadcrumbElement[] = null;

  action = '';
  title = '';
  poolEntryHDR: HDRPermission;
  private storagePoolRequestSub: Subscription;

  constructor(
    private storageService: InformixServerStorageService,
    private onconfigService: InformixServerOnconfigService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private hdrPermissionService: HDRPermissionService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    // deleteEntryHDR
    this.poolEntryHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  ngOnDestroy() {
    if (this.storagePoolRequestSub) {
      this.storagePoolRequestSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.setBreadcrumb();
    if (this.server.hasMonitorPassword) {
      this.dataLoading = true;
      this.refreshData();
    }
  }

  private setBreadcrumb(element?: BreadcrumbElement) {
    if (this.maximized) {
      const breadcrumbs: BreadcrumbElement[] = [this.storageBreadCrumb, this.poolBreadCrumb];
      if (element) {
        breadcrumbs.push(element);
      }
      this.breadcrumb = ServerBreadcrumb.build(this.server, breadcrumbs);
    }
  }

  private getStoragePoolConfiguration() {
    this.storageService.getStoragePoolConfiguration(this.server.id).then(config => {
      config.forEach(onconfig => this.setOnconfigParameter(onconfig));
    }).catch(err => {
      console.error('Could not get server storage pool configuration', err);
    });
  }

  private setOnconfigParameter(onconfig: ServerOnconfigParameter) {
    if (onconfig.name === 'SP_AUTOEXPAND') {
      this.onconfig_sp_autoexpand = onconfig;
      this.spAutoExpand = onconfig.effective === '1';
    } else if (onconfig.name === 'SP_THRESHOLD') {
      this.onconfig_sp_threshold = onconfig;
      this.spThreshold = parseInt(onconfig.effective, 10);
      if (this.spThreshold === 0) {
        this.spThresholdAsString = 'Off';
        this.spThresholdUnit = 'Off';
      } else if (this.spThreshold <= 50) {
        this.spThresholdAsString = this.spThreshold + '%';
        this.spThresholdUnit = '%';
      } else {
        this.spThresholdAsString = this.spThreshold + ' KB';
        this.spThresholdUnit = 'KB';
      }
    } else if (onconfig.name === 'SP_WAITTIME') {
      this.onconfig_sp_waittime = onconfig;
      this.spWaitTime = parseInt(onconfig.effective, 10);
    }
  }

  public refreshData() {
    this.getStoragePoolInfo();
  }

  private getStoragePoolInfo() {
    if (this.storagePoolRequestSub) {
      this.storagePoolRequestSub.unsubscribe();
    }
    this.storagePoolRequestSub = this.storageService.getStoragePoolInfo(this.server.id).subscribe(entries => {
      this.dataLoadErrorMessage = null;
      this.dataLoading = false;
      this.entries = entries.sort((a, b) => (a.status.toLowerCase() < b.status.toLowerCase()) ? -1 :
        (a.status.toLowerCase() > b.status.toLowerCase()) ? 1 :
          (a.priority < b.priority) ? -1 : (a.priority > b.priority) ? 1 : 0);
      this.updateStoragePoolSummaryInfo();
      this.applyFilter();
      if (this.maximized) {
        this.getStoragePoolConfiguration();
      }
    }, (err: HttpErrorResponse) => {
      this.dataLoading = false;
      console.error('Could not get storage pool info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private updateStoragePoolSummaryInfo() {
    this.spaceRemaining = 0;
    this.numExpandableEntries = 0;
    this.entries.forEach(entry => this.addToSummaryInfo(entry));
  }

  private addToSummaryInfo(entry: ServerStoragePoolEntry) {
    this.spaceRemaining += ((entry.space_remaining === -1) ? 0 : entry.space_remaining);
    this.numExpandableEntries += ((entry.space_remaining === -1) ? 1 : 0);
  }

  private applyFilter() {
    if (this.filter.toLowerCase() === 'all') {
      this.filteredEntries = this.entries;
    } else {
      this.filteredEntries = this.entries.filter((entry) => entry.status.toLowerCase() === this.filter);
    }
  }

  public setFilter(value: string) {
    this.filter = value;
    this.applyFilter();
  }

  public goToPoolList() {
    if (this.storagePoolModal.show) {
      this.storagePoolModal.hide();
    }
    this.action = '';
    this.selectedEntry = null;
  }

  public addEntryClicked() {
    if (this.poolEntryHDR && !this.poolEntryHDR.isAllow()) {
      return;
    }
    this.storagePoolModal.show();
    this.action = 'add';
    this.title = 'Add Storage Pool Entry';
  }

  public modifyEntryClicked(entry: ServerStoragePoolEntry) {
    if (this.poolEntryHDR && !this.poolEntryHDR.isAllow()) {
      return;
    }
    this.storagePoolModal.show();
    this.action = 'modify';
    this.selectedEntry = entry;
    this.title = 'Modify Storage Pool Entry';
  }

  public deleteEntryClicked(entry: ServerStoragePoolEntry) {
    if (this.poolEntryHDR && !this.poolEntryHDR.isAllow()) {
      return;
    }
    this.storagePoolModal.show();
    this.action = 'delete';
    this.selectedEntry = entry;
    this.title = 'Delete Storage Pool Entry';
  }

  public doEditConfiguration() {
    this.isEditingConfiguration = true;
    this.validateEditPoolConfigForm();
  }

  public doCancelEditConfiguration() {
    this.setOnconfigParameter(this.onconfig_sp_autoexpand);
    this.setOnconfigParameter(this.onconfig_sp_threshold);
    this.setOnconfigParameter(this.onconfig_sp_waittime);
    this.isEditingConfiguration = false;
  }

  public handleSpThresholdUnitChange() {
    if (this.spThresholdUnit === 'Off') {
      this.spThreshold = 0;
    }
    this.validateEditPoolConfigForm();
  }

  public validateEditPoolConfigForm() {
    let valid = true;
    const spAutoExpandUpdated = this.spAutoExpand !== (this.onconfig_sp_autoexpand.effective === '1');
    const spThresholdUpdated = this.spThreshold !== parseInt(this.onconfig_sp_threshold.effective, 10);
    const spWaitTimeUpdated = this.spWaitTime !== parseInt(this.onconfig_sp_waittime.effective, 10);
    if (!spAutoExpandUpdated && !spThresholdUpdated && !spWaitTimeUpdated) {
      // disable save button if no changes were made
      valid = false;
    }

    if (this.spThresholdUnit === '%') {
      if (this.spThreshold < 1 || this.spThreshold > 50) {
        valid = false;
      }
    } else if (this.spThresholdUnit === 'KB') {
      if (this.spThreshold < 1000) {
        valid = false;
      }
    }

    if (this.spWaitTime < 0) {
      valid = false;
    }

    this.saveConfigButtonEnabled = valid;
  }

  public doUpdateConfiguration() {
    const spAutoExpandUpdated = this.spAutoExpand !== (this.onconfig_sp_autoexpand.effective === '1');
    const spThresholdUpdated = this.spThreshold !== parseInt(this.onconfig_sp_threshold.effective, 10);
    const spWaitTimeUpdated = this.spWaitTime !== parseInt(this.onconfig_sp_waittime.effective, 10);
    if (!spAutoExpandUpdated && !spThresholdUpdated && !spWaitTimeUpdated) {
      console.log('nothing to update. no config parameters have been changed');
      return;
    }

    const updateInfo: any = {};
    if (spAutoExpandUpdated) {
      updateInfo.SP_AUTOEXPAND = ((this.spAutoExpand) ? '1' : '0');
    }
    if (spThresholdUpdated) {
      updateInfo.SP_THRESHOLD = this.spThreshold;
    }
    if (spWaitTimeUpdated) {
      updateInfo.SP_WAITTIME = this.spWaitTime;
    }
    this.onconfigService.updateServerConfiguration(this.server.id, updateInfo).then(result => {
      this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
          this.getStoragePoolConfiguration();
        }
      });
    }).catch(err => {
      this.handleActionError(err);
    });
    this.isEditingConfiguration = false;
  }

  public handleActionSuccess(message: string) {
    this.notificationsService.pushSuccessNotification(message);
    this.refreshData();
  }

  public handleActionError(err: any) {
    let message = err;
    if (typeof err !== 'string') {
      message = err.error ? err.error.err : err;
    }
    this.notificationsService.pushErrorNotification(message);
  }
}
