<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    {{ 'schemaManager.dbUdt' | translate }}
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbUser; else tableDataLoading" class="table-responsive" [data]="dbUser" [rowsPerPage]="20"
      [searchLabel]="'schemaManager.dbUdtTab.searchLabel' | translate" sortBy="typename"
      [noDataAlertMessage]="'schemaManager.dbUdtTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'schemaManager.dbUdtTab.udtName' | translate" property="typename"
        [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbUdtTab.udtMode' | translate" property="mode" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.description' | translate" property="desc"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbUdtTab.length' | translate" property="length" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbUdtTab.maxLength' | translate" property="maxlength"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbUdtTab.byValue' | translate" property="byvalue">
        <ng-template appTableCell let-item="item">
          <i class="icon" [ngClass]="item ? 'icon icon-check text-success' : 'icon icon-times text-danger'"></i>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbUdtTab.cannotHash' | translate" property="cannothash">
        <ng-template appTableCell let-item="item">
          <i class="icon" [ngClass]="item ? 'icon icon-check text-success' : 'icon icon-times text-danger'"></i>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbUdtTab.align' | translate" property="align" [sortable]="true">
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>
