<app-data-table [data]="tableDetails.fragments" sortBy="indexname"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.fragments' | translate">
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.data' | translate" property="fragtype"
    [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'common.type' | translate" property="type" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'table.indexName' | translate" property="indexname" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'table.partNum' | translate" property="partn" [sortable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.expression' | translate"
    property="exprtext" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'common.dbspace' | translate" property="dbspace" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'table.partition' | translate" property="partition" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.rows' | translate"	
    property="nrows" [sortable]="true"></app-data-table-column>
</app-data-table>
