/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotificationsService } from '../../../../../../shared/notifications/notifications.service';
import { InformixServer } from '../../../../../../dashboard/servers/informixServer';
import { EnterpriseReplicationService } from '../../../enterprise-replication.service';
import { ERNode } from '../../../er-domain';
import { ERAdvancedOptionsComponent } from '../er-advanced-options/er-advanced-options.component';
import { ProfileStatus } from '../../../enterprise-replication-types';

export enum PANEL {
    ADVANCED = 'Advanced',
    INFO = 'INFO'
}

@Component({
    selector: 'app-er-info-sidebar',
    templateUrl: './er-info-sidebar.component.html',
    styleUrls: ['../er-sidebars.component.scss']
})

export class ERNodeInfoComponent implements OnChanges {
    showSelectGroupsModal: boolean;
    groupHierarchyStr: string = null;
    advancedOptions: any;

    @Input() open: boolean;
    @Input() server: InformixServer;
    @Output() openChange = new EventEmitter<boolean>();
    @Input() selectedNode: ERNode;
    @Input() nodes: ERNode[];

    @Input() clear: Boolean = false;
    @Output() clearChange = new EventEmitter<boolean>();
    @Output() deletedNode = new EventEmitter<ERNode>();
    @ViewChild('confirmationModal') confirmationModal: TemplateRef<any>;
    @ViewChild(ERAdvancedOptionsComponent) advancedOptionsComp: ERAdvancedOptionsComponent;
    modalRef: BsModalRef;
    isDeleting: boolean;
    activePanel: string = PANEL.INFO;
    PANELS = PANEL;
    APICalls: any = {
        fetchOptions: {
            $subsriber: null,
            pending: null
        },
        putOptions: {
            $subsriber: null,
            pending: null
        },
    };

    changes: any = {
        selectedNode : null
    };
    $deleteNode: any;

    profileStatus = ProfileStatus;
    fieldNotFoundMsg: string;
    constructor(
        private bsModalService: BsModalService,
        private notificationsService: NotificationsService,
        private erService: EnterpriseReplicationService,
        ) {
            this.fieldNotFoundMsg = 'This field not found in the output of \'cdr profile xml\' command.';
        }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.open && changes.open.currentValue) {
            this.fetchAdvancedOptions();
        }

        if(changes.selectedNode && changes.selectedNode.currentValue) {
            this.changes.selectedNode = changes.selectedNode;
        }
    }

    close() {
        this.openChange.emit(false);
    }

    showConfirmation() {
        this.modalRef = this.bsModalService.show(this.confirmationModal,
            { backdrop: true, ignoreBackdropClick: true, class: 'modal-dialog-centered' });
            this.close();
    }
    hideModal() {
        if (this.modalRef) {
            this.modalRef.hide();
          }
    }

    deleteNode() {
        const deleteServerGroupName = this.selectedNode.name;
        let connectServerGroupName = null;

        if(this.selectedNode.parent) {
            connectServerGroupName = this.selectedNode.parent.name;
        }else if(this.selectedNode.siblings && this.selectedNode.siblings.length){
            connectServerGroupName = this.selectedNode.siblings[0].name;
        }else {
            connectServerGroupName = deleteServerGroupName;
        }

        this.isDeleting = true;
        this.$deleteNode = this.erService.deleteNode(this.server.id, { node : { deleteServerGroupName, connectServerGroupName}})
            .subscribe(config => {
                this.isDeleting = false;
                this.notificationsService.pushSuccessNotification('Node Deleted Successfully!', 6000);
                this.hideModal();
                this.deletedNode.emit(this.selectedNode);
            }, ({error}) => {
                if(error.err) {
                    this.notificationsService.pushErrorNotification(error.err);
                }
                this.isDeleting = false;
                this.hideModal();
            });
    }

    fetchAdvancedOptions() {

        if(this.APICalls.fetchOptions.$subsriber && this.APICalls.fetchOptions.$subsriber.unsubscribe) {
            this.APICalls.fetchOptions.$subsriber.unsubscribe();
        }
        this.APICalls.fetchOptions.pending = true;

        this.APICalls.fetchOptions.$subsriber = this.erService.fetchAdvancedOptions(this.server.id, this.selectedNode.name)
        .subscribe((advancedOptions: any) => {
            this.APICalls.fetchOptions.pending = false;
            this.advancedOptions = this.putExtraInfo(advancedOptions);
        }, (err) => {
            this.APICalls.fetchOptions.pending = false;
        });
    }

    /**
     * Method to put QOD related extra properties.
     *
     * @param options
     * @param responseData
     * @returns
     */
    putExtraInfo(options, responseData?) {
        let masterNodeId = options.masterNodeId;
        let masterNodeGroup = '';
        if(responseData) {
            if(responseData.qodDefine) {
                options.masterNodeId = this.selectedNode.id;
            }
            options.qodState = (responseData.qodDefine)?true:responseData.qodState;
            if(responseData.qodGroupName) {
                masterNodeGroup = responseData.qodGroupName;
            }else {
                masterNodeId = this.selectedNode.id;
            }
        }

        const masterNode = this.nodes.find(node => node.name === masterNodeGroup || node.id === masterNodeId);
        if(masterNode) {
            options.isSameMaster = this.selectedNode.id === options.masterNodeId;
            options.masterNodeName = masterNode?masterNode.name:'unknown';
        }else{
            options.masterNodeId = null;
        }
        return options;
    }

    modifyAdvancedOptions() {

        this.cancelExistingSave();

        const payload = this.advancedOptionsComp.getSelections();

        this.APICalls.putOptions.pending = true;

        this.APICalls.putOptions.$subsriber = this.erService.modifyAdvancedOptions(this.server.id,this.selectedNode.name, payload)
        .subscribe(response => {
            this.notificationsService.pushSuccessNotification('Advanced Options Modified Successfully', 3000);
            this.advancedOptions = this.putExtraInfo(this.advancedOptions, payload);
            this.activePanel = this.PANELS.INFO;
            this.APICalls.putOptions.pending = false;
        }, (err) => {
            let errMsg = 'Modify Options Error: ';

            if(err.error.qod === false) {
                errMsg = 'Quality of data (QOD) monitoring Error: ';
            }

            errMsg += err.error.err;

            this.notificationsService.pushErrorNotification(errMsg);
            this.APICalls.putOptions.pending = false;
        });
    }

    cancelDelete() {
        if(this.$deleteNode && this.$deleteNode.unsubscribe) {
            this.$deleteNode.unsubscribe();
            this.isDeleting = false;
        }
    }


    cancelExistingSave() {
        if(this.APICalls.putOptions.$subsriber && this.APICalls.putOptions.$subsriber.unsubscribe) {
            this.APICalls.putOptions.$subsriber.unsubscribe();
            this.APICalls.putOptions.pending = false;
        }
    }

    isSaveValid() {
        let isValid = true;
        if((this.advancedOptionsComp && this.advancedOptionsComp.advancedOptionsForm.controls.ats.value === 3 &&
            this.advancedOptionsComp.advancedOptionsForm.controls.atsFilePath.value === '')
        || (this.advancedOptionsComp && this.advancedOptionsComp.advancedOptionsForm.controls.ris.value === 3 &&
            this.advancedOptionsComp.advancedOptionsForm.controls.risFilePath.value === '')) {
            isValid = false;
        }
        return isValid;
    }
}


