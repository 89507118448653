/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playground-data-table',
  template: `
  <section class="main-container" style="margin-left: 0">
    <app-data-table [data]="data" *ngIf="data" [enableSelect]="true" [rowsPerPage]="10" [rowsPerPageOptions]="[2,5,10,33]">
      <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
      </app-data-table-column>
    </app-data-table>
  </section>
  `
})
export class PlaygroundDataTableComponent implements OnInit {

  data: any[] = [];

  ngOnInit() {
    for (let i = 0; i < 1000; i++) {
      this.data.push({
        name: 'Row #' + i
      });
    }
  }
}
