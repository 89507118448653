/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { InformixServer } from '../../../informixServer';
import { BasicIndexDetails, StorageScheme, ExpressionList } from './../create-index.model';
import { ServerSpace } from '../../../storage/serverSpace';
import { DataTableRow } from '../../../../../shared/data-table/data-table.component';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InformixServerStorageService } from '../../../storage/informixServerStorage.service';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/models';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { AddValuesModalComponent } from '../add-values-modal/add-values-modal.component';
import { AddExpressionModalComponent } from '../add-expression-modal/add-expression-modal.component';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-storage-scheme',
  templateUrl: './storage-scheme.component.html',
  styleUrls: ['../create-index.component.scss']
})
export class StorageSchemeComponent implements OnInit, OnChanges {
  @Input() server: InformixServer;
  @Input() dataObj: BasicIndexDetails;
  @Input() isBackToIndex: boolean;
  @Input() createQueryClicked: boolean;
  @Input() isCompress: boolean;
  @Input() isCreateTable = false;

  @Output() isSchemeValid = new EventEmitter<boolean>();
  @Output() schemeDataObj = new EventEmitter<any>();

  @ViewChild('addValuesModal') addValuesModal: AddValuesModalComponent;
  @ViewChild('addExpressionsModal') addExpressionsModal: AddExpressionModalComponent;

  storageSchemeForm: UntypedFormGroup;
  schemeType: UntypedFormControl;
  fragmentKey: UntypedFormControl;
  interval: UntypedFormControl;
  timePeriod: UntypedFormControl;
  startDate: UntypedFormControl;
  startValue: UntypedFormControl;
  enableRollingWindow: UntypedFormControl;
  maxFragments: UntypedFormControl;
  maxTotalSizeForRollingWindow: UntypedFormControl;
  maxTotalSizeUnit: UntypedFormControl;
  policyForOldestFragment: UntypedFormControl;
  fragmentsForRemoval: UntypedFormControl;
  dbSpacesList: ServerSpace[];
  sbSpacesList: ServerSpace[];
  expressionList: ExpressionList[] = [];
  rowSelected = false;
  schemeErrorMsg: string;
  isDateRangePresent = false;
  bsValue: Date;
  minMode: BsDatepickerViewMode = 'year';
  dateInputFormat = 'YYYY-MM-DD';
  bsConfig: Partial<BsDatepickerConfig>;
  enableSingleSelect = true;
  enableMultiSelect = false;
  isNullPartition = false;
  isRemainderPartition = false;
  isValues = false;
  isExpression = false;
  isReloadTable = true;
  dbspaceNames = [];
  sbspaceNames = [];
  storageScheme: StorageScheme;
  isRangePresent = false;
  fragmentColumns: any;
  isNumeric: boolean;
  selectedExpressionRow: ExpressionList;
  disableUpwardChevron = true;
  disableDownwardChevron = true;
  iseditDeleteExpression = false;
  isDBspaceLoading = true;
  isSbspace = false;
  dateTypes = ['datetime', 'date'];
  numericTypes = ['smallint', 'integer', 'float', 'smallfloat', 'decimal', 'serial', 'money', 'bigint', 'bigserial'];

  constructor(
    private fb: UntypedFormBuilder,
    private serverStorageService: InformixServerStorageService,
    private notificationsService: NotificationsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const currentDate = new Date();
    setTimeout(() => this.bsValue = new Date(currentDate.getFullYear(), 0, 1));
    this.schemeType = new UntypedFormControl('singledbspace');
    this.fragmentKey = new UntypedFormControl();
    this.interval = new UntypedFormControl(1, [Validators.min(1), Validators.max(2147483647), Validators.required]);
    this.timePeriod = new UntypedFormControl('year');
    this.startDate = new UntypedFormControl(null);
    this.startValue = new UntypedFormControl(1, [Validators.min(1), Validators.max(2147483647), Validators.required]);
    this.enableRollingWindow = new UntypedFormControl(false);
    this.maxFragments = new UntypedFormControl({ value: null, disabled: true }, [Validators.min(1)]);
    this.maxTotalSizeForRollingWindow = new UntypedFormControl({ value: null, disabled: true }, [Validators.min(1)]);
    this.maxTotalSizeUnit = new UntypedFormControl({ value: 'gb', disabled: true });
    this.policyForOldestFragment = new UntypedFormControl({ value: 'detach', disabled: true });
    this.fragmentsForRemoval = new UntypedFormControl({ value: 'first', disabled: true });
    this.storageSchemeForm = this.fb.group({
      schemeType: this.schemeType,
      fragmentKey: this.fragmentKey,
      interval: this.interval,
      timePeriod: this.timePeriod,
      startDate: this.startDate,
      startValue: this.startValue,
      enableRollingWindow: this.enableRollingWindow,
      maxFragments: this.maxFragments,
      maxTotalSizeForRollingWindow: this.maxTotalSizeForRollingWindow,
      maxTotalSizeUnit: this.maxTotalSizeUnit,
      policyForOldestFragment: this.policyForOldestFragment,
      fragmentsForRemoval: this.fragmentsForRemoval
    });
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode,
      dateInputFormat: this.dateInputFormat
    });
    this.isDateRangePresent = (this.dataObj.columnsList.filter
      (item => this.dateTypes.some(el => item.type.toLowerCase().indexOf(el) !== -1)).length > 0);
    this.isRangePresent = (this.dataObj.columnsList.filter
      (item => this.numericTypes.some(el => item.type.toLowerCase().indexOf(el) !== -1)).length > 0);
    this.interval.valueChanges.subscribe(() => {
      this.validateScheme();
    });
    this.startValue.valueChanges.subscribe(() => {
      this.validateScheme();
    });
    if (this.dataObj.indexType === 'xml' || this.dataObj.indexType === 'bts') {
      this.isSbspace = true;
    }
    this.getDbspaces();

    this.maxTotalSizeForRollingWindow.valueChanges.subscribe(value => {
      if (value > 0) {
        this.fragmentsForRemoval.enable();
      } else {
        this.fragmentsForRemoval.disable();
      }
      this.validateScheme();
    });
    this.maxFragments.valueChanges.subscribe(() => {
      this.validateScheme();
    });
    this.enableRollingWindow.valueChanges.subscribe(value => {
      if (value) {
        this.maxFragments.enable();
        this.maxTotalSizeForRollingWindow.enable();
        this.maxTotalSizeUnit.enable();
        this.policyForOldestFragment.enable();
        this.fragmentsForRemoval.enable();
        if (this.maxTotalSizeForRollingWindow.value > 0) {
          this.fragmentsForRemoval.enable();
        } else {
          this.fragmentsForRemoval.disable();
        }
      } else {
        this.maxFragments.disable();
        this.maxTotalSizeForRollingWindow.disable();
        this.maxTotalSizeUnit.disable();
        this.policyForOldestFragment.disable();
        this.fragmentsForRemoval.disable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isBackToIndex'] || changes['createQueryClicked']) {
      let type = '';
      type = changes['isBackToIndex'] ? 'isBackToIndex' : 'createQueryClicked';
      if (changes[type].currentValue === true) {
        if (!changes[type].firstChange) {
          this.setDataObj();
          const data = {
            pageType: changes['isBackToIndex'] ? 'createIndex' : 'viewQuery',
            storageScheme: this.storageScheme
          };
          this.schemeDataObj.emit(data);
        }
      }
    }
    if (changes['isCompress'] && this.dbSpacesList) {
      this.validateScheme();
    }
  }

  changeFragment() {
    const fragmentColumnForValues = this.fragmentColumns.filter(x => x.indexedColumnName === this.fragmentKey.value);
    this.isNumeric = false;
    if (this.numericTypes.some(el => fragmentColumnForValues[0].type.toLowerCase().indexOf(el) !== -1)) {
      this.isNumeric = true;
    }
    if (this.isNumeric) {
      this.dbSpacesList.forEach(element => {
        if (element.columnValues) {
          element.columnValues = element.columnValues.map(i => parseFloat(i));
          element.columnValues = element.columnValues.filter(j => !isNaN(j));
          if (element.columnValues.length === 0) {
            element.columnValues = null;
            element.isChecked = false;
          }
        }
      });
      this.dbSpacesList = [...this.dbSpacesList];
    }
  }

  setFragment(isScheme: boolean) {
    if (this.schemeType.value === 'dateRangeFragmentation') {
      this.fragmentColumns = this.dataObj.columnsList.filter(item => this.dateTypes.some(el => item.type.toLowerCase().indexOf(el) !== -1));
    } else if (this.schemeType.value === 'rangeFragmentation') {
      this.fragmentColumns = this.dataObj.columnsList.filter(item => this.numericTypes.some(el => item.type.toLowerCase().
        indexOf(el) !== -1));
    } else {
      this.fragmentColumns = this.dataObj.columnsList;
    }
    if (isScheme && this.fragmentColumns.some(column => column.indexedColumnName === this.dataObj.storageScheme.fragmentKey)) {
      this.fragmentKey.setValue(this.dataObj.storageScheme.fragmentKey);
    } else {
      this.fragmentKey.setValue(this.fragmentColumns[0].indexedColumnName);
    }
  }

  schemeTypeChange() {
    this.dbSpacesList.forEach(element => {
      element.isChecked = false;
    });
    this.changeTableType('schemeChange');
    this.dbSpacesList = [...this.dbSpacesList];
    this.validateScheme();
  }

  changeTableType(type: string) {
    this.isReloadTable = false;
    setTimeout(() => this.isReloadTable = true, 0);
    this.isValues = false;
    this.isNullPartition = false;
    this.isRemainderPartition = false;
    this.enableSingleSelect = true;
    this.enableMultiSelect = false;
    if (this.schemeType.value !== 'singledbspace') {
      if (this.schemeType.value === 'expressionBasedFragmentation') {
        this.isExpression = true;
      } else {
        this.isNullPartition = true;
        this.enableMultiSelect = true;
        this.enableSingleSelect = false;
        this.setFragment(type === 'initial');
        if (this.schemeType.value === 'rangeFragmentation' && type !== 'initial') {
          this.startValue.setValue(1);
        }
        if ((this.schemeType.value === 'rangeFragmentation' || this.schemeType.value === 'dateRangeFragmentation') && type !== 'initial') {
          this.interval.setValue(1);
        }
        if (this.schemeType.value === 'listFragmentation') {
          this.isValues = true;
          this.isRemainderPartition = true;
          if (type !== 'initial') {
            this.dbSpacesList.forEach(element => {
              element.columnValues = null;
            });
          }
        }
      }
    }
  }

  onValueChange(value: any): void {
    if (this.timePeriod.value === 'year') {
      this.bsConfig.minMode = 'year';
      if (!value || value.getTime() !== value.getTime()) {
        const currentDate = new Date();
        this.bsValue = new Date(currentDate.getFullYear(), 0, 1);
      } else {
        this.bsValue = new Date(value.getFullYear(), 0, 1);
      }
    } else if (this.timePeriod.value === 'month') {
      this.bsConfig.minMode = 'month';
      if (!value || value.getTime() !== value.getTime()) {
        const currentDate = new Date();
        this.bsValue = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      } else {
        this.bsValue = new Date(value.getFullYear(), value.getMonth(), 1);
      }
    } else {
      this.bsConfig.minMode = 'day';
      if (!value || value.getTime() !== value.getTime()) {
        const currentDate = new Date();
        this.bsValue = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      } else {
        this.bsValue = new Date(value.getFullYear(), value.getMonth(), value.getDate());
      }
    }
  }

  onChangeTimeperiod() {
    this.onValueChange(this.startDate.value);
  }

  onRowSelect(event: DataTableRow) {
    const table = event.data as ServerSpace;
    if (this.schemeType.value !== 'singledbspace') {
      if (!event.selected && this.schemeType.value === 'listFragmentation' && table.columnValues) {
        table.columnValues = null;
      }
    } else {
      this.dbSpacesList.forEach(element => {
        element.isChecked = false;
      });
    }
    table.isChecked = event.selected;
    this.dbSpacesList = [...this.dbSpacesList];
    this.validateScheme();
  }

  setDataObj() {
    if ((!this.isSbspace && this.dbSpacesList) || (this.isSbspace && this.sbSpacesList && this.sbSpacesList.length > 0)) {
      const checkedDbspaces = [];
      if (this.schemeErrorMsg) {
        this.storageScheme = null;
      } else {
        if (this.schemeType.value === 'singledbspace') {
          const spaceType = this.isSbspace ? this.sbSpacesList : this.dbSpacesList;
          spaceType.forEach(element => {
            if (element.isChecked === true) {
              checkedDbspaces.push({ dbSpaceName: element.name, dbSpaceSelected: true });
            }
          });
        } else {
          if (this.schemeType.value === 'expressionBasedFragmentation') {
            this.expressionList.forEach(el => {
              checkedDbspaces.push({
                dbSpaceName: el.dbSpace,
                fragmentName: el.name,
                expression: el.expression
              });
            });
          } else {
            this.dbSpacesList.forEach(element => {
              if (element.isChecked || element.nullPartition || element.remainderPartition) {
                checkedDbspaces.push({
                  dbSpaceName: element.name,
                  dbSpaceSelected: element.isChecked
                });
              }
            });
          }
        }
        this.storageScheme = {
          storageSchemeName: this.schemeType.value,
          dbSpaces: checkedDbspaces
        };
        if (this.schemeType.value !== 'singledbspace' && this.schemeType.value !== 'expressionBasedFragmentation') {
          this.storageScheme.fragmentKey = this.fragmentKey.value;
          if (this.schemeType.value !== 'listFragmentation') {
            this.storageScheme.interval = this.interval.value;
          }
          if (this.schemeType.value === 'dateRangeFragmentation') {
            this.storageScheme.timePeriod = this.timePeriod.value;
            this.storageScheme.startDate = new Date(this.bsValue).getTime();
          }
          if (this.schemeType.value === 'rangeFragmentation') {
            this.storageScheme.startValue = this.startValue.value;
          }
          if ((this.schemeType.value === 'dateRangeFragmentation' || this.schemeType.value === 'rangeFragmentation')
            && this.enableRollingWindow.value) {
            this.storageScheme.enableRollingWindow = this.enableRollingWindow.value;
            this.storageScheme.isDetachPolicy = this.policyForOldestFragment.value === 'detach' ? true : false;
            if (this.maxTotalSizeForRollingWindow.value > 0) {
              this.storageScheme.maxTotalSizeForRollingWindow = this.maxTotalSizeForRollingWindow.value;
              this.storageScheme.maxTotalSizeForRollingWindowUnit = this.maxTotalSizeUnit.value;
              if (this.fragmentsForRemoval.value === 'first') {
                this.storageScheme.fragmentRemovalIntervalFirst = true;
                this.storageScheme.fragmentRemovalIntervalOnly = false;
                this.storageScheme.fragmentRemovalAny = false;
              } else if (this.fragmentsForRemoval.value === 'only') {
                this.storageScheme.fragmentRemovalIntervalOnly = true;
                this.storageScheme.fragmentRemovalIntervalFirst = false;
                this.storageScheme.fragmentRemovalAny = false;
              } else {
                this.storageScheme.fragmentRemovalIntervalOnly = false;
                this.storageScheme.fragmentRemovalIntervalFirst = false;
                this.storageScheme.fragmentRemovalAny = true;
              }
            }
            if (this.maxFragments.value > 0) {
              this.storageScheme.maxFragments = this.maxFragments.value;
            }
          }
          const fragmentColumnForValues = this.fragmentColumns.filter(x => x.indexedColumnName === this.fragmentKey.value);
          this.isNumeric = false;
          if (this.numericTypes.some(el => fragmentColumnForValues[0].type.toLowerCase().indexOf(el) !== -1)) {
            this.isNumeric = true;
          }
          this.storageScheme.dbSpaces.forEach(item => {
            this.dbSpacesList.forEach(element => {
              if (item.dbSpaceName === element.name) {
                item.nullPartition = element.nullPartition;
                if (this.schemeType.value === 'listFragmentation') {
                  item.remainderPartition = element.remainderPartition;
                  if (element.columnValues) {
                    this.isNumeric ? element.columnValues = element.columnValues.map(i => parseFloat(i)) :
                      element.columnValues = element.columnValues = element.columnValues.map(i => i.toString());
                  }
                  item.listFragmentationValues = element.columnValues;
                }
              }
            });
          });
        }
      }
      this.dbSpacesList = [...this.dbSpacesList];
    } else {
      this.storageScheme = this.dataObj.storageScheme;
      this.isSchemeValid.emit(true);
    }
  }

  columnsDataMap(data: any): DataTableRow {
    return {
      data,
      selected: data.isChecked,
      disabled: false,
      expanded: false
    };
  }

  getDbspaces() {
    this.serverStorageService.getServerSpaces(this.server.id)
      .subscribe((data: ServerSpace[]) => {
        if (data) {
          this.isDBspaceLoading = false;
          this.dbSpacesList = data.filter(value => value.type === 'dbspace');
          this.sbSpacesList = data.filter(value => value.type === 'sbspace');
          this.dbSpacesList.forEach(element => {
            element.isChecked = false;
            this.dbspaceNames.push(element.name);
          });
          if (this.sbSpacesList && this.sbSpacesList.length > 0) {
            this.sbSpacesList.forEach(element => {
              element.isChecked = false;
              this.sbspaceNames.push(element.name);
            });
          }
          setTimeout(() => this.setIntialData());
        }
      }, err => {
        this.isDBspaceLoading = false;
        this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
      });
  }

  setIntialData() {
    if (this.dataObj.storageScheme) {
      if ((this.dataObj.storageScheme.storageSchemeName === 'dateRangeFragmentation' && this.isDateRangePresent) ||
        (this.dataObj.storageScheme.storageSchemeName === 'rangeFragmentation' && this.isRangePresent) ||
        this.dataObj.storageScheme.storageSchemeName === 'singledbspace' ||
        this.dataObj.storageScheme.storageSchemeName === 'listFragmentation' ||
        this.dataObj.storageScheme.storageSchemeName === 'expressionBasedFragmentation') {
        this.schemeType.setValue(this.dataObj.storageScheme.storageSchemeName);
        if (this.dataObj.storageScheme.storageSchemeName === 'dateRangeFragmentation' || this.schemeType.value === 'rangeFragmentation') {
          this.interval.setValue(this.dataObj.storageScheme.interval);
          if (this.dataObj.storageScheme.storageSchemeName === 'dateRangeFragmentation') {
            this.startDate.setValue(new Date(this.dataObj.storageScheme.startDate));
            this.timePeriod.setValue(this.dataObj.storageScheme.timePeriod);
          }
          if (this.dataObj.storageScheme.storageSchemeName === 'rangeFragmentation') {
            this.startValue.setValue(this.dataObj.storageScheme.startValue);
          }
          if (this.dataObj.storageScheme.enableRollingWindow) {
            this.enableRollingWindow.setValue(this.dataObj.storageScheme.enableRollingWindow);
            this.maxTotalSizeUnit.setValue(this.dataObj.storageScheme.maxTotalSizeForRollingWindowUnit);
            this.policyForOldestFragment.setValue(this.dataObj.storageScheme.isDetachPolicy === true ? 'detach' : 'discard');
            if (this.dataObj.storageScheme.maxTotalSizeForRollingWindow) {
              this.maxTotalSizeForRollingWindow.setValue(this.dataObj.storageScheme.maxTotalSizeForRollingWindow);
              if (this.dataObj.storageScheme.fragmentRemovalIntervalFirst === true) {
                this.fragmentsForRemoval.setValue('first');
              } else if (this.dataObj.storageScheme.fragmentRemovalIntervalOnly === true) {
                this.fragmentsForRemoval.setValue('only');
              } else {
                this.fragmentsForRemoval.setValue('any');
              }
            }
            if (this.dataObj.storageScheme.maxFragments) {
              this.maxFragments.setValue(this.dataObj.storageScheme.maxFragments);
            }
          }
        }
        if (this.dataObj.storageScheme.storageSchemeName === 'expressionBasedFragmentation') {
          let id = 0;
          for (let i = 0; i < this.dataObj.storageScheme.dbSpaces.length; i++) {
            this.expressionList.push({
              id: id++,
              name: this.dataObj.storageScheme.dbSpaces[i].fragmentName,
              dbSpace: this.dataObj.storageScheme.dbSpaces[i].dbSpaceName,
              expression: this.dataObj.storageScheme.dbSpaces[i].expression,
              isChecked: false
            });
          }
          this.expressionList = [...this.expressionList];
        } else {
          const spaceType = this.isSbspace ? this.sbSpacesList : this.dbSpacesList;
          spaceType.forEach(element => {
            this.dataObj.storageScheme.dbSpaces.forEach(item => {
              if (element.name === item.dbSpaceName) {
                element.isChecked = item.dbSpaceSelected;
                element.nullPartition = item.nullPartition;
                element.remainderPartition = item.remainderPartition;
                element.columnValues = item.listFragmentationValues;
              }
            });
          });
        }
      } else {
        this.dataObj.storageScheme = null;
      }
    }
    this.dbSpacesList = [...this.dbSpacesList];
    this.changeTableType('initial');
    this.validateScheme();
  }

  validateScheme() {
    let isCompressRange = false;
    if (this.isCompress && (this.schemeType.value === 'dateRangeFragmentation' || this.schemeType.value === 'rangeFragmentation')) {
      this.translate.get('schemaManager.createIndex.storageScheme.compressedError').subscribe((text: string) => {
        this.schemeErrorMsg = text;
      });
      isCompressRange = true;
      this.rowSelected = false;
    }
    if ((!this.isSbspace && this.dbSpacesList && !isCompressRange) ||
      (this.isSbspace && this.sbSpacesList && this.sbSpacesList.length > 0)) {
      if (this.schemeType.value === 'expressionBasedFragmentation') {
        if (this.expressionList.length < 2) {
          this.translate.get('schemaManager.createIndex.storageScheme.addTwoExpressions').subscribe((text: string) => {
            this.schemeErrorMsg = text;
          });
          this.rowSelected = false;
        } else {
          this.schemeErrorMsg = null;
          this.rowSelected = true;
        }
      } else {
        if ((!this.isSbspace && !this.dbSpacesList.some(el => el.isChecked === true) ||
          (this.isSbspace && this.sbSpacesList && this.sbSpacesList.length > 0 && !this.sbSpacesList.some(el => el.isChecked === true)))) {
          this.rowSelected = false;
          let selectOneDbspace: string;
          let selectAtleastOneDbspace: string;
          this.translate.get('schemaManager.createIndex.storageScheme.selectOneDbspace').subscribe((text: string) => {
            selectOneDbspace = text;
          });
          this.translate.get('schemaManager.createIndex.storageScheme.selectAtleastOneDbspace').subscribe((text: string) => {
            selectAtleastOneDbspace = text;
          });
          this.schemeType.value === 'singledbspace' ? (this.schemeErrorMsg = selectOneDbspace) :
            (this.schemeErrorMsg = selectAtleastOneDbspace);
        } else {
          this.rowSelected = true;
          this.schemeErrorMsg = null;
          this.dbSpacesList.filter(item => item.isChecked === true).forEach(data => {
            if (!data.columnValues && this.schemeType.value === 'listFragmentation') {
              this.translate.get('schemaManager.createIndex.storageScheme.valuesMustBeDefined').subscribe((text: string) => {
                this.schemeErrorMsg = text;
              });
              this.rowSelected = false;
            }
          });
          if ((!this.interval.valid && (this.schemeType.value === 'rangeFragmentation' || this.schemeType.value === 'dateRangeFragmentation'
          )) || (!this.startValue.valid && this.schemeType.value === 'rangeFragmentation') || (this.enableRollingWindow.value &&
            (!this.maxFragments.value && !this.maxTotalSizeForRollingWindow.value) || (this.maxFragments.invalid ||
              this.maxTotalSizeForRollingWindow.invalid) && (this.schemeType.value === 'rangeFragmentation' ||
                this.schemeType.value === 'dateRangeFragmentation'))) {
            this.rowSelected = false;
          }
        }
      }
    }
    this.isSchemeValid.emit(this.rowSelected);
  }

  togglePartition(type: string, row: ServerSpace, partitionType: string) {
    if (partitionType === 'nullPartition') {
      this.dbSpacesList.forEach(item => item.nullPartition = false);
      row.nullPartition = (type === 'select');
    } else {
      this.dbSpacesList.forEach(item => item.remainderPartition = false);
      row.remainderPartition = (type === 'select');
    }
  }

  editValues(row: ServerSpace) {
    const fragmentColumnForValues = this.fragmentColumns.filter(x => x.indexedColumnName === this.fragmentKey.value);
    this.addValuesModal.show(row, this.dbSpacesList, fragmentColumnForValues[0].type);
  }

  refreshValues(valueObj) {
    const objIndex = this.dbSpacesList.findIndex((obj => obj.name === valueObj.data.name));
    this.dbSpacesList[objIndex].columnValues = JSON.parse(JSON.stringify(valueObj.values));
    this.validateScheme();
  }

  addExpressions(type: string, row) {
    const spaceObj = { spaces: [], type: '' };
    if (this.isSbspace) {
      spaceObj.spaces = this.sbspaceNames;
      spaceObj.type = 'Sbspace';
    } else {
      spaceObj.spaces = this.dbspaceNames;
      spaceObj.type = 'Dbspace';
    }
    this.addExpressionsModal.show(spaceObj, row, type, this.expressionList);
  }

  refreshExpression(obj) {
    let id = 0;
    if (obj.id || obj.id === 0) {
      const rowToUpdate = this.expressionList.find(v => v.id === obj.id);
      rowToUpdate.name = obj.name;
      rowToUpdate.dbSpace = obj.dbSpace;
      rowToUpdate.expression = obj.expression;
      rowToUpdate.isChecked = false;
      this.selectedExpressionRow = null;
    } else {
      this.expressionList.push({ id: null, name: obj.name, dbSpace: obj.dbSpace, expression: obj.expression, isChecked: null });
      this.expressionList.forEach(item => {
        item.id = id++; item.isChecked = false;
      });
    }
    this.disableDownwardChevron = true;
    this.disableUpwardChevron = true;
    this.expressionList = [...this.expressionList];
    this.validateScheme();
  }

  moveRows(type: string) {
    const fromIndex: number = this.selectedExpressionRow.id;
    const toIndex: number = type === 'upward' ? fromIndex - 1 : fromIndex + 1;
    this.updateExpressionColumns(fromIndex, toIndex);
  }

  onExpressionRowSelect(event: DataTableRow) {
    this.expressionList.forEach(item => item.isChecked = false);
    this.expressionList = [...this.expressionList];
    if (event.selected) {
      this.iseditDeleteExpression = true;
      event.data.isChecked = true;
      this.selectedExpressionRow = event.data;
      if (event.data.id === 0) {
        this.disableUpwardChevron = true;
        this.disableDownwardChevron = (this.expressionList.length === 1);
      } else if (event.data.id === this.expressionList.length - 1) {
        this.disableDownwardChevron = true;
        this.disableUpwardChevron = false;
      } else {
        this.disableUpwardChevron = false;
        this.disableDownwardChevron = false;
      }
    } else {
      this.selectedExpressionRow = null;
      this.disableUpwardChevron = true;
      this.disableDownwardChevron = true;
      this.iseditDeleteExpression = false;
    }
  }

  updateExpressionColumns(fromIndex: number, toIndex: number) {
    const element = this.expressionList[fromIndex];
    this.expressionList.splice(fromIndex, 1);
    this.expressionList.splice(toIndex, 0, element);
    this.expressionList = [...this.expressionList];
    if (toIndex === this.expressionList.length - 1) {
      this.disableDownwardChevron = true;
      this.disableUpwardChevron = false;
    } else if (fromIndex === 1) {
      this.disableUpwardChevron = true;
      this.disableDownwardChevron = false;
    } else {
      this.disableUpwardChevron = false;
      this.disableDownwardChevron = false;
    }
    this.resetIDs();
  }

  resetIDs() {
    let id = 0;
    this.expressionList.forEach(item => {
      item.id = id++;
    });
  }

  expressionDataMap(tablesIndexes: any): DataTableRow {
    return {
      data: tablesIndexes,
      selected: tablesIndexes.isChecked,
      disabled: false,
      expanded: false
    };
  }

  deleteExpression(row) {
    this.expressionList = this.expressionList.filter(item => item.id !== row.id);
    this.selectedExpressionRow = null;
    this.resetIDs();
    this.disableDownwardChevron = true;
    this.disableUpwardChevron = true;
    this.validateScheme();
  }
}
