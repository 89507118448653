<div class="container-fluid page-container" *ngIf="server">
  <div *ngIf="!viewTableAdvOpt">
    <div class="row">
      <div class="col-6">
        <h5 class="font-weight-bold">{{ 'schemaManager.createTable.title.createTable' | translate }}</h5>
      </div>
      <div class="col-6">
        <button type="button" class="close" (click)="actionComplete.emit()">
          <i class="icon icon-times font-weight-bold"></i></button>
      </div>
    </div>
    <form *ngIf="createTableForm" [formGroup]="createTableForm">
      <div class="row">
        <div class="col-auto pt-2">
          <label class="font-weight-bold"><span class="text-danger">*
            </span>{{ 'schemaManager.createTable.label.tableName' | translate }}</label>
          <input class="form-control" type="text" [customErrors]="tableNameErrorHandlers"
            [placeholder]="'schemaManager.createTable.placeholder.tableName' | translate" tooltip=""
            formControlName="tableName" appExtendedFormControl appFocusOnCreate>
        </div>
        <div class="col-auto pt-2">
          <label class="font-weight-bold"><span class="text-danger">*
            </span>{{ 'schemaManager.createTable.label.tableOwner' | translate }}</label>
          <input class="form-control" type="text" [title]="'schemaManager.createTable.tooltips.tableOwner' | translate"
            formControlName="tableOwner" appExtendedFormControl tooltip="">
        </div>
        <div class="col-auto pt-2">
          <label class="font-weight-bold">{{ 'schemaManager.createTable.label.tableType' | translate }}</label>
          <select class="form-control mr-3" formControlName="tableType"
            [title]="'schemaManager.createTable.tooltips.tableType' | translate">
            <option ngValue="standard">{{ 'schemaManager.createTable.tableTypes.standard' | translate }}</option>
            <option ngValue="raw">{{ 'schemaManager.createTable.tableTypes.raw' | translate }}</option>
            <option ngValue="externalfixed">{{ 'schemaManager.createTable.tableTypes.externalFixed' | translate }}
            </option>
            <option ngValue="externaldelimited">
              {{ 'schemaManager.createTable.tableTypes.externalDelimited' | translate}}</option>
            <option ngValue="externalinformix">{{ 'schemaManager.createTable.tableTypes.externalInformix' | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="row pt-3" *ngIf="tableType.value === 'externaldelimited' || tableType.value === 'externalinformix'">
        <app-checkbox class="pl-3" formControlName="isCreateTableFlag"
          [title]="'schemaManager.createTable.tooltips.tableSameAs' | translate">
          <span class="font-weight-bold">{{'schemaManager.createTable.label.tableSameAs' | translate}}</span>
        </app-checkbox>
        <div class="col-auto">
          <select class="form-control form-control-sm mr-3" formControlName="tableNameSameAs">
            <option *ngFor="let table of tableList" [ngValue]="table.name">{{table.name}}</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="!isCreateTableFlag.value">
        <div class="row pt-2">
          <div class="col-12">
            <label class="font-weight-bold pt-2 mb-0">{{ 'schemaManager.createTable.label.tableColumn' | translate
              }}</label>
            <button type="button" class="float-right btn btn-primary" (click)="addColumnsModal()">
              {{'schemaManager.createTable.button.addColumns' | translate}} </button>
          </div>
        </div>
        <div class="card-block pt-2">
          <div class="card-body p-0">
            <div class="table-responsive">
              <app-data-table [data]="tableData" *ngIf="tableData; else tableDataLoading" [minHeight]="400"
                [noDataAlertMessage]="'schemaManager.createTable.errorMsg.noColumnsDefined' | translate"
                [enablePagination]="false">
                <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.tableColumns' | translate"
                  property="tableColumnName"> </app-data-table-column>
                <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.dataType' | translate">
                  <ng-template appTableCell let-row="row">
                    {{row.columnDataType | uppercase}}
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.attributes' | translate">
                  <ng-template appTableCell let-row="row">
                    <ng-container *ngIf="!row.setDataType else listType">
                      <div *ngIf="row.dataTypeAttributes?.length">
                        {{'schemaManager.createTable.columnAttributes.length' | translate}}:
                        {{row.dataTypeAttributes.length}} (bytes)</div>
                      <div *ngIf="row.dataTypeAttributes?.largestTimeUnit">
                        {{'schemaManager.createTable.columnAttributes.largestTimeUnit' | translate}}:
                        {{row.dataTypeAttributes.largestTimeUnit}}</div>
                      <div *ngIf="row.dataTypeAttributes?.smallestTimeUnit">
                        {{'schemaManager.createTable.columnAttributes.smallestTimeUnit' | translate}}:
                        {{row.dataTypeAttributes.smallestTimeUnit}}</div>
                      <div *ngIf="row.dataTypeAttributes?.precisionDigit">
                        {{'schemaManager.createTable.columnAttributes.precisionDigit' | translate}}:
                        {{row.dataTypeAttributes.precisionDigit}}</div>
                      <div *ngIf="row.dataTypeAttributes?.scale">
                        {{'schemaManager.createTable.columnAttributes.scale' | translate}}:
                        {{row.dataTypeAttributes.scale}}</div>
                      <div *ngIf="row.dataTypeAttributes?.startValue">
                        {{'schemaManager.createTable.columnAttributes.startValue' | translate}}:
                        {{row.dataTypeAttributes.startValue}} </div>
                      <div *ngIf="row.dataTypeAttributes?.storeColumnIn">
                        {{'schemaManager.createTable.columnAttributes.storeColumnIn' | translate}}:
                        {{row.dataTypeAttributes.storeColumnIn}}</div>
                      <ng-container
                        *ngIf="(row.columnDataType === 'blob' || row.columnDataType === 'clob') && row.dataTypeAttributes?.storeColumnIn else defaultMsg">
                        <div>
                          {{'schemaManager.createTable.columnAttributes.accessTime' | translate}}:
                          {{row.dataTypeAttributes.recordAccessTime ? 'On': 'Off'}}</div>
                        <div>
                          {{'schemaManager.createTable.columnAttributes.highIntegrity' | translate}}:
                          {{row.dataTypeAttributes.highDataIntegrity ? 'On' : 'Off'}}</div>
                        <div>
                          {{'schemaManager.createTable.columnAttributes.logging' | translate}}:
                          {{row.dataTypeAttributes.turnOnLogging ? 'On': 'Off'}}</div>
                        <div>
                          {{'schemaManager.createTable.columnAttributes.extentSize' | translate}}:
                          {{row.dataTypeAttributes.extentSize ? row.dataTypeAttributes.extentSize : 'Determined by
                          system'}} </div>
                      </ng-container>
                      <ng-template #defaultMsg>
                        <div *ngIf="row.columnDataType === 'blob' || row.columnDataType === 'clob'">
                          {{'schemaManager.createTable.defaultStoreColumnIn' | translate}}</div>
                      </ng-template>
                    </ng-container>
                    <ng-template #listType>
                      {{row.columnDataType | titlecase }}
                      {{'schemaManager.createTable.columnAttributes.dataType' | translate}}:
                      {{row.setDataType | uppercase}}
                    </ng-template>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column
                  [title]="'schemaManager.createTable.table.columnTitle.checkConstraint' | translate">
                  <ng-template appTableCell let-row="row"> {{row.columnConstraint ? 'Yes' : 'No'}} </ng-template>
                </app-data-table-column>
                <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.allowNulls' | translate">
                  <ng-template appTableCell let-row="row"> {{row.defaultNull ? 'Yes' : 'No'}} </ng-template>
                </app-data-table-column>
                <app-data-table-column [title]="'schemaManager.createTable.label.defaultValue' | translate">
                  <ng-template appTableCell let-row="row">
                    <ng-container
                      *ngIf="row.columnDataType === 'interval' && !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault else defaultValue">
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultYearValue)">
                        {{'time.yearLabel' | translate}} : {{row.dataTypeAttributes.defaultYearValue}}</div>
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultMonthValue)">
                        {{'time.monthLabel' | translate}} : {{row.dataTypeAttributes.defaultMonthValue}}</div>
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultDayValue)">
                        {{'time.dayLabel' | translate}} : {{row.dataTypeAttributes.defaultDayValue}}</div>
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultHourValue)">
                        {{'time.hourLabel' | translate}} : {{row.dataTypeAttributes.defaultHourValue}}</div>
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultMinuteValue)">
                        {{'time.minuteLabel' | translate}} : {{row.dataTypeAttributes.defaultMinuteValue}}</div>
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultSecondValue)">
                        {{'time.secondLabel' | translate}} : {{row.dataTypeAttributes.defaultSecondValue}}</div>
                      <div *ngIf="isNumber(row.dataTypeAttributes.defaultFractionValue)">
                        {{'time.fractionLabel' | translate}} : {{row.dataTypeAttributes.defaultFractionValue}}
                      </div>
                      <div *ngIf="row.dataTypeAttributes.nullAsDefault">
                        NULL</div>
                    </ng-container>
                    <ng-template #defaultValue>{{row.defaultValue}}</ng-template>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column class="text-right">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.edit' | translate" (click)="openEdit(row)">
                      <i class="icon icon-edit"></i> </button>
                    <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.delete' | translate"
                      (click)="confirmDelete(row)"> <i class="icon icon-trash"></i> </button>
                  </ng-template>
                </app-data-table-column>
              </app-data-table>
              <ng-template #tableDataLoading>
                <i class="icon icon-2x icon-circle-notch icon-spin"></i>
              </ng-template>
            </div>
          </div>
        </div>
        <ng-container *ngIf="tableType.value === 'standard' || tableType.value === 'raw'">
          <div class="row">
            <app-checkbox class="pt-3 pl-3" formControlName="tableConstraint"
              [title]="'schemaManager.createTable.tooltips.checkConstraint' | translate">
              <span class="font-weight-bold">{{'schemaManager.createTable.label.checkConstraint' | translate}}</span>
            </app-checkbox>
          </div>
          <div class="pl-1 pt-2" *ngIf="createTableForm.controls['tableConstraint'].value">
            <textarea class="col-12 form-control" formControlName="constraintText" autocomplete="off" autocorrect="off"
              autocapitalize="off" spellcheck="false"
              [placeholder]="'schemaManager.createTable.placeholder.checkConstraint' | translate">
          </textarea>
          </div>
        </ng-container>
      </ng-container>
    </form>
    <div class="row pt-3">
      <div class="col-12 text-left">
        <button type="button" class="mr-2 btn btn-secondary" (click)="actionComplete.emit()">
          {{'button.cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="next()"
          [disabled]="isCreateTableFlag.value ? !createTableForm.valid : (!createTableForm.valid || tableData.length < 1)">
          {{'button.next' | translate}} </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="viewTableAdvOpt">
    <app-add-constraint *ngIf="tableType.value === 'standard' || tableType.value === 'raw'" [dataObj]="dataObj"
      (advTableObj)="getAdvTableObj($event)" [session]="session" (backToCreateTable)="backToCreateTable($event)">
    </app-add-constraint>
    <app-external-options
      *ngIf="tableType.value === 'externalfixed' || tableType.value === 'externaldelimited' || tableType.value === 'externalinformix'"
      [dataObj]="dataObj" (advTableObj)="getAdvTableObj($event)" [session]="session"
      (backToCreateTable)="backToCreateTable($event)">
    </app-external-options>
  </ng-container>
  <app-column-modal #addColumnModal (editCol)="editCol($event)" (addCol)="addCol($event)" [columnNames]="columnNames"
    [session]="session">
  </app-column-modal>
</div>