<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row mb-2">
    <div class="col-11">
      <h4 class="page-header">
        <strong>Logs</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="dataLoading"></i>
      </h4>
    </div>
    <div class="col-1 text-right">
      <button *ngIf="server.hasMonitorPassword" id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()">
        <i class="icon icon-refresh" title="Refresh"></i>
      </button>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger">
      Unable to retrieve log information.
      <br> Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div class="row">
  <div class="col-xs-12 col-lg-6 col-xl-6 mb-6">
      <div *ngIf="plogInfo" class="card card-body pb-2 mb-3">
        <h5 class="card-title">
          <div class="row">
            <div class="col-6">
              Physical Log
            </div>
            <div class="col-6 text-right">
              <app-check-server-admin-permissions [server]="server" placement="top">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="logHDR" placement="top">
                  <button type="button" class="btn btn-link p-0 m-0" (click)="openMovePLogForm()" [disabled]="isMovingPLog" appHDRPermission [hdrPermission]="logHDR"  appPermissionDisable>Move Log</button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
            </div>
          </div>
        </h5>
        <div class="card-body pt-1 px-0">

          <div *ngIf="isMovingPLog" class="card mb-2">
            <div class="card-header">
              <h5>
                Move Physical Log
              </h5>
            </div>
            <div class="card-body">
              <form [formGroup]="movePLogFormGroup" class="mb-2">
                <div class="row form-inline">
                  <div class="col-2">Dbspace:</div>
                  <div class="col-10">
                    <select class="form-control form-control-sm mr-3" formControlName="dbspace">
                      <option *ngFor="let space of dbspaces" [ngValue]="space.name">{{space.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-1 form-inline">
                  <div class="col-2">Size:</div>
                  <div class="col-10">
                    <app-input-size formClass="form-control-sm" formControlName="size" min="1" tooltip="" appExtendedFormControl></app-input-size>
                  </div>
                </div>
              </form>
              <button type="button" class="btn btn-primary" [disabled]="!movePLogFormGroup.valid" (click)="movePhysicalLog()">
                <i class="icon icon-arrow-right"></i> Move
              </button>
              <button type="button" class="btn btn-secondary" (click)="isMovingPLog = false">
                <i class="icon icon-times"></i> Cancel
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div>Location</div>
              <div><strong>{{plogInfo.location}}</strong></div>
            </div>
            <div class="col">
              <div>Dbspace</div> 
              <div><strong>{{plogInfo.dbspace}}</strong></div>
            </div>
            <div class="col">
              <div>Size</div>
              <div><strong>{{plogInfo.total_size | byte}}</strong></div>
            </div>
            <div class="col">
              <div>Used</div> 
              <div><strong>{{plogInfo.used_size | byte}}</strong></div>
            </div>
            <div class="col">
              <div>Used %</div>
              <div><strong>{{(plogInfo.used_size / plogInfo.total_size * 100).toFixed(2)}}%</strong></div>
            </div>
            <div class="col">
              <div>Start Offset</div>
              <div><strong>{{plogInfo.start_offset | byte}}</strong></div>
            </div>
            <div class="col">
              <div>Buffer Size</div>
              <div><strong>{{plogInfo.buffer_size | byte}}</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="plogInfo" class="col-xs-12 col-lg-3 col-xl-3 mb-3">
      <div class="card card-body h-100">
        <h5 class="card-title">
          Physical Log
        </h5>
        <div class="mt-1">
          <div>
            <h4 class="page-header" [ngStyle]="{display: 'inline-block'}">
              <strong>{{plogInfo.used_size | byte}}/{{plogInfo.total_size | byte}}</strong>
            </h4> Used
          </div>
          <div class="progress chart bg-success" data-toggle="popover" [popover]="physicalLogUsage" 
              containerClass="tab-index-popover popover-arrow-hidden popover-color" placement="bottom" triggers="hover">
            <div class="progress-bar backcolor-used-space " 
             [ngStyle]="{width: (plogInfo.used_size / plogInfo.total_size) * 100 + '%'}">
            </div>
            <ng-template #physicalLogUsage>
              <div class="popover-body btn-group-toggle" data-toggle="buttons">
                <i class="icon icon-circle color-used-space"></i>
                <label class="pl-2">Used: {{plogInfo.used_size | byte}}</label><br>
                <i class="icon icon-circle text-success"></i>
                <label class="pl-2">Free: {{(plogInfo.total_size - plogInfo.used_size) | byte}}</label>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="plogInfo" class="col-xs-12 col-lg-3 col-xl-3 mb-3">
      <div class="card card-body h-100">
        <h5 class="card-title">
          Logical Log
        </h5>
        <div class="mt-1">
          <div>
            <h4 class="page-header" [ngStyle]="{display: 'inline-block'}">
              <strong>{{llogInfo?.logicalLogBackedUp | byte}}/{{llogInfo?.totalLogSize | byte}}</strong>
            </h4> Backed Up
          </div>
          <div class="progress chart bg-success" data-toggle="popover" [popover]="logicalLogUsage" 
              containerClass="tab-index-popover popover-arrow-hidden popover-color" placement="bottom" triggers="hover">
            <!-- Backed Up Log progress bar -->
            <div class="progress-bar bg-primary"
            [ngStyle]="{width: (llogInfo?.logicalLogBackedUp / llogInfo?.totalLogSize) * 100 + '%'}">
            </div>
            <!-- Used Log progress bar -->
            <div class="progress-bar backcolor-used-space "
            [ngStyle]="{width: (llogInfo?.logicalLogUsed / llogInfo?.totalLogSize) * 100 + '%'}">
            </div>
          </div>
          <ng-template #logicalLogUsage>
            <div class="popover-body btn-group-toggle" data-toggle="buttons">
              <i class="icon icon-circle text-primary"></i>
              <label class="pl-2">Backed Up: {{llogInfo?.logicalLogBackedUp | byte}}</label><br>
              <i class="icon icon-circle color-used-space"></i>
              <label class="pl-2">Used: {{(llogInfo?.logicalLogUsed) | byte}}</label><br>
              <i class="icon icon-circle text-success"></i>
              <label class="pl-2">Free: {{(llogInfo?.totalLogSize - (llogInfo?.logicalLogUsed + llogInfo?.logicalLogBackedUp)) | byte}}</label>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <ng-template [ngIf]="llogs">
    <div class="card card-body">
      <div class="card-title row">
        <div class="col-xs-4 col-lg-6">
          <h5 class="llog-heading">
            Logical Logs
          </h5>
          <button type="button" class="btn btn-primary" (click)="navigateToCurrentLlogPage()" [disabled]="isAddingLLog">Current Log</button>
        </div>
        <div class="col-xs-8 col-lg-6" align="right">
          <app-check-server-admin-permissions [server]="server" placement="top">
            <app-check-hdr-permissions [server]="server" [hdrPermission]="logHDR" placement="top">
              <button type="button" class="btn btn-primary" (click)="openAddLLogForm()" [disabled]="isAddingLLog" appHDRPermission [hdrPermission]="logHDR" appPermissionDisable>Add Log</button>
            </app-check-hdr-permissions>
          </app-check-server-admin-permissions>
          <app-check-server-admin-permissions [server]="server" placement="top">
            <app-check-hdr-permissions [server]="server" [hdrPermission]="logHDR" placement="top">
              <button type="button" class="btn btn-primary" (click)="confirmSwitchLogicalLog()" appHDRPermission [hdrPermission]="logHDR" appPermissionDisable>Switch Log</button>
            </app-check-hdr-permissions>
          </app-check-server-admin-permissions>
          <div *ngIf="isAddingLLog" class="card mt-2 mb-2">
            <div class="card-header">
              <h5>
                Add Logical Logs
              </h5>
            </div>
            <div class="card-body">
              <form [formGroup]="addLLogFormGroup" class="mb-2">
                <div class="row">
                  <div class="col-2">Dbspace:</div>
                  <div class="col-10">
                    <select class="form-control form-control-sm mr-3" formControlName="dbspace">
                      <option *ngFor="let space of dbspaces" [ngValue]="space.name">{{space.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-2">Size:</div>
                  <div class="col-10">
                    <app-input-size formClass="form-control-sm" formControlName="size" min="1" tooltip="" appExtendedFormControl></app-input-size>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-2">Count:</div>
                  <div class="col-10">
                    <input type="number" class="form-control form-control-sm" min="0" formControlName="count" appExtendedFormControl>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-2">Add After Current?:</div>
                  <div class="col-10 text-left">
                    <app-checkbox formControlName="afterCurrent">
                    </app-checkbox>
                  </div>
                </div>
              </form>
              <button type="button" class="btn btn-primary" [disabled]="!addLLogFormGroup.valid" (click)="addLogicalLog()">
                <i class="icon icon-plus"></i> Add
              </button>
              <button type="button" class="btn btn-secondary" (click)="isAddingLLog = false">
                <i class="icon icon-times"></i> Cancel
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive">
          <app-data-table [data]="llogs" sortBy="unique_id" sortDescending="true" [rowsPerPage]="rowsPerPage" [defaultPage]="defaultPage" (pageChange)="onPageChange($event)" (rowsPerPageChangeEvent)="rowsPerPageChange($event)" noDataAlertMessage="Logical logs are not available." noDataAlertType="warning">
            <app-data-table-column title="Number" property="number" [sortable]="true">
              <ng-template appTableCell let-item="item" let-row="row" >
              {{ item }} <span class="badge-pill badge-primary" *ngIf="row.status.indexOf('CURRENT') > -1">Current</span>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Unique ID" property="uniqueid" [sortable]="true">
            </app-data-table-column>
            <app-data-table-column title="Size" property="total_size" [sortable]="true">
              <ng-template appTableCell let-item="item">
                {{item | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="% Used" property="percent_used" [sortable]="true">
              <ng-template appTableCell let-item="item" let-row="row">
                <div class="progress bg-success" data-toggle="popover" [popover]="usage"
                  containerClass="tab-index-popover popover-arrow-hidden popover-color" placement="auto" triggers="hover">
                  <div class="progress-bar"
                    [ngClass]="( row.status.indexOf('BACKED_UP') > -1 ? 'bg-primary' : 'backcolor-used-space' )"
                    [ngStyle]="{width: item + '%'}">
                  </div>
                  <ng-template #usage>
                    <div class="popover-body btn-group-toggle" data-toggle="buttons">
                      <div *ngIf="row.status.indexOf('BACKED_UP') > -1 && item > 0; else usedProgress;">
                        <i class="icon icon-circle text-primary"></i>
                        <label class="pl-2">Backed Up: {{ item + '%' }} </label>
                      </div>
                      <ng-template #usedProgress>
                        <div>
                          <i class="icon icon-circle color-used-space"></i>
                          <label class="pl-2">Used: {{ item + '%' }}</label>
                        </div>
                      </ng-template>
                      <i class="icon icon-circle text-success"></i>
                      <label class="pl-2">Free: {{ (100 - item) + '%' }}</label>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Location" property="location" [sortable]="true">
            </app-data-table-column>
            <app-data-table-column title="Last Filled" property="filltime" [sortable]="true">
              <ng-template appTableCell let-item="item">
                {{(item === 'NOT_FULL') ? "Not Full" : item}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Notes" property="status">
              <ng-template appTableCell let-item="item">
                {{getLogicalLogStatusString(item)}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Backed Up" property="status">
            <ng-template appTableCell let-backupedUp="item">
              <app-status-icon [status]="backupedUp.indexOf('BACKED_UP') > -1"></app-status-icon>
            </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Fill Rate" property="pps">
            </app-data-table-column>
            <app-data-table-column class="text-right">
              <ng-template appTableCell let-row="row">
                <app-check-server-admin-permissions [server]="server">
                  <app-check-hdr-permissions [server]="server" [hdrPermission]="logHDR" placement="top">
                    <button class="btn btn-sm btn-icon" (click)="confirmDropLogicalLog(row)" tooltip="Drop" appHDRPermission [hdrPermission]="logHDR" appPermissionDisable>
                      <i class="icon icon-trash"></i>
                    </button>
                  </app-check-hdr-permissions>
                </app-check-server-admin-permissions>
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </div>
    </div>
  </ng-template>
</div>
