/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { InformixTreeItem } from '../informixTreeItem';
import { InformixServer } from '../servers/informixServer';
import { InformixServerService } from '../servers/informixServer.service';
import { InformixServerGroup } from './informixServerGroup';
import { InformixServerGroupService } from './informixServerGroup.service';
import { GroupBrowserConfig, BrowserListItem } from '../../shared/group-browser/group-browser.component';


@Component({
  selector: 'app-move-items',
  templateUrl: 'move-items.html'
})
export class MoveItemsComponent implements OnChanges {

  @Input() group: InformixServerGroup;
  @Input() items: InformixTreeItem[];

  @Output() eventClosed = new EventEmitter<any>();
  @Output() eventDone = new EventEmitter<number>();

  itemIcon: string;

  private movingGroups: InformixServerGroup[];
  private movingServers: InformixServer[];

  browserConfig: GroupBrowserConfig = null;

  currentGroup: InformixServerGroup = null;
  movingGroupsIdMap: { [key: number]: boolean };
  isMoving = false;

  errorMessage: string = null;

  @ViewChild('modal') modalComponent: ModalDirective;

  constructor(
    private groupService: InformixServerGroupService,
    private serverService: InformixServerService,
    private notifications: NotificationsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items) {
      this.init();
    }
  }

  init() {
    this.browserConfig = {
      groupMapper: this.mapBrowserGroup.bind(this),
      showServers: false
    };

    if (this.items.length === 1) {
      this.itemIcon = this.items[0] instanceof InformixServer ? 'icon-database' : 'icon-folder';
    }

    this.movingGroups = [];
    this.movingServers = [];
    this.movingGroupsIdMap = {};
    this.items.forEach(item => {
      if (item instanceof InformixServerGroup) {
        this.movingGroups.push(item);
        this.movingGroupsIdMap[item.id] = true;
      } else if (item instanceof InformixServer) {
        this.movingServers.push(item);
      }
    });

    setTimeout(() => {
      if (this.modalComponent) {
        this.modalComponent.show();
      }
    }, 0);
  }

  onHidden() {
    this.eventClosed.emit(null);
  }

  mapBrowserGroup(group: InformixServerGroup): BrowserListItem {
    return {
      item: group,
      hidden: !group.permissions.read || this.movingGroupsIdMap[group.id] === true
    };
  }

  onCurrentGroupChange(group: InformixServerGroup) {
    this.currentGroup = group;
    if (!group.permissions.admin) {
      this.errorMessage = 'You do not have the permission to move to this group';
    } else {
      this.errorMessage = null;
    }
  }

  selectDestination() {
    this.isMoving = true;

    const groups = this.movingGroups;
    const servers = this.movingServers;

    const promises: Promise<any>[] = [];
    if (groups.length > 0) {
      promises.push(this.groupService.moveGroups(groups, this.currentGroup.id).catch((err: HttpErrorResponse) => {
        let errorMsg = err.error && err.error.err ? err.error.err : err.status + ' ' + err.statusText;
        if (err.error && err.error.id) {
          const group = groups.find(v => v.id === err.error.id);
          if (group) {
            errorMsg = 'Could not move the group ' + group.name + '. ' + errorMsg;
          }
        }
        this.notifications.pushErrorNotification(errorMsg);
        return Promise.reject(err);
      }));
    }
    if (servers.length > 0) {
      promises.push(this.serverService.moveServers(servers, this.currentGroup.id).catch((err: HttpErrorResponse) => {
        let errorMsg = err.error && err.error.err ? err.error.err : err.status + ' ' + err.statusText;
        if (err.error && err.error.id) {
          const server = servers.find(v => v.id === err.error.id);
          if (server) {
            errorMsg = 'Could not move the server ' + server.name + '. ' + errorMsg;
          }
        }
        this.notifications.pushErrorNotification(errorMsg);
        return Promise.reject(err);
      }));
    }

    const groupId = this.group.id;
    Promise.all(promises).then(() => {
      this.notifications.pushSuccessNotification('Item(s) moved successfully');
    }).catch(err => {
      console.error(err);
    }).then(() => {
      this.isMoving = false;
      this.eventDone.emit(groupId);
    });

    this.modalComponent.hide();
  }
}
