<tabset>
  <tab [heading]="'schemaManager.tableInfo.tableStatistics' | translate">
    <div [formGroup]="autoUpdateStatisticsForm">
      <div class="row">
        <div class="col-xl-2">
          <label class="font-weight-bold pt-3">{{'schemaManager.tableInfo.statisticsLevel' | translate}}:</label>
          <select class="form-control" formControlName="selectedStatisticsLevel"
            (change)="checkStatisticsLevel($event.target.value)">
            <option value="A">{{'schemaManager.tableInfo.auto' | translate}}</option>
            <option value="T">{{'table.table' | translate}}</option>
            <option value="F" *ngIf="tableDetails?.statistics?.is_fragmented === true">{{'table.fragment' | translate}}
            </option>
          </select>
        </div>
        <div class="col-xl-10">
          <div class="col-12 pl-0">
            <label class="font-weight-bold pt-3">{{'schemaManager.tableInfo.minChangeThreshold' | translate}}:</label>
            <br />
          </div>
          <app-radio-group class="row" formControlName="minChangeThreshold" (change)="changeThreshold()">
            <div class="col-xl-4">
              <app-radio value="systemSetting" class="pt-1">{{'schemaManager.tableInfo.useSystemSetting' | translate}}:
                {{sysSetting}}%</app-radio>
            </div>
            <div class="col-xl-8">
              <div class="row align-items-center">
                <div class="col-auto">
                  <app-radio value="thresholdValue">
                    {{'schemaManager.tableInfo.setThreshold' | translate}}:
                  </app-radio>
                </div>
                <div class="col d-flex">
                  <input name="threshold" type="number" min="0" max="100" tooltip="" appExtendedFormControl
                    formControlName="threshold">
                  <span class="percent-text">&nbsp;%</span>
                  <button type="button" class="btn btn-primary ml-3"
                    [disabled]="!autoUpdateStatisticsForm?.valid || disableSaveThresholdBtn && !isStatisticsLevelChanged"
                    (click)="saveStatisticsInfo()">{{'button.save' | translate}}</button>
                </div>
              </div>
            </div>
          </app-radio-group>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 justify-content-end form-inline mt-3">
        <label class="pr-2 pl-1 font-weight-bold">{{ 'dataTable.filterLabel' | translate }}</label>
        <select class="form-control" [ngModel]="tableFilter" (ngModelChange)="changeTableFilter($event)">
          <option ngValue="All">{{ 'common.all' | translate }}</option>
          <option ngValue="Column">{{'schemaManager.tableInfo.columnsOnly' | translate}}</option>
          <option ngValue="Index">{{'schemaManager.tableInfo.indexesOnly' | translate}}</option>
        </select>
      </div>
    </div>
    <div class="row d-block px-3 pt-3">
      <ng-container *ngIf="tableDetails.statistics.tableStatistics else noDataTemplate">
        <app-data-table [data]="filteredTableStatistics" sortBy="name"
          [searchLabel]="'schemaManager.tableInfo.table.searchLabel.tableStatistics' | translate">
          <app-data-table-column [title]="'common.name' | translate" property="name" [sortable]="true"
            [searchable]="true"></app-data-table-column>
          <app-data-table-column [title]="'common.type' | translate" property="type" [sortable]="true"
            [searchable]="true"></app-data-table-column>
          <app-data-table-column [title]="'common.mode' | translate" property="mode" [sortable]="true"
            [searchable]="true"></app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.change' | translate"
            property="change" [sortable]="true">
            <ng-template appTableCell let-item="item">
              <span [ngClass]="{'color-red' : (item > tableDetails.statistics.statChange)}">
                {{(item < 0)? "--" : item}}</span>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.udiChange' | translate"
            property="udi_counter" [sortable]="true"></app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.buildDate' | translate"
            property="build_date" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.buildDuration' | translate"
            property="build_duration" [sortable]="true">
            <ng-template appTableCell let-frequency="item">
              {{frequency | intervalDS}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.sampleSize' | translate"
            property="sample" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{(item <= 0)? "--" : item}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.resolution' | translate"
            property="resolution" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{(item <= 0)? "--" : item}}
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </ng-container>
    </div>
  </tab>
  <tab [heading]="'schemaManager.tableInfo.fragmentsStatistics' | translate"
    [disabled]="!tableDetails?.statistics?.is_fragmented">
    <app-data-table [data]="tableDetails.statistics.fragmentStatistics" sortBy="fragname" class="mt-3"
      [searchLabel]="'schemaManager.tableInfo.table.searchLabel.fragmentStatistics' | translate">
      <app-data-table-column [title]="'table.fragment' | translate" property="fragname" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.name' | translate" property="name" [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.type' | translate" property="fragtype" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.mode' | translate" property="mode" [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.change' | translate" property="change"
        [sortable]="true">
        <ng-template appTableCell let-item="item">
          <span [ngClass]="{'color-red' : (item > tableDetails.statistics.statChange)}">
            {{(item < 0)? "--" : item}}</span>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.udiChange' | translate"
        property="udi_counter" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.buildDate' | translate"
        property="build_date" [sortable]="true">
        <ng-template appTableCell let-item="item">
          {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.buildDuration' | translate"
        property="build_duration" [sortable]="true">
        <ng-template appTableCell let-frequency="item">
          {{frequency | intervalDS}}
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.sampleSize' | translate"
        property="sample" [sortable]="true">
        <ng-template appTableCell let-item="item">
          {{(item < 0)? "--" : item}}
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.resolution' | translate"
        property="resolution" [sortable]="true">
        <ng-template appTableCell let-item="item">
          {{(item < 0)? "--" : item}}
        </ng-template>
      </app-data-table-column>
    </app-data-table>
  </tab>
</tabset>
<ng-template #noDataTemplate>
  <div class="alert alert-info mb-0">
    {{'dataTable.noDataAvailable' | translate}}.
  </div>
</ng-template>
