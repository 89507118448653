<div class="row align-items-center">
  <div class="col-10">
    <h5 class="font-weight-bold mb-0">{{'schemaManager.createTable.title.advOptions' | translate}}</h5>
  </div>
  <div class="col-2">
    <button type="button" class="close">
      <i class="icon icon-times font-weight-bold" (click)="back('cancel')"></i></button>
  </div>
</div>

<form [formGroup]="advTableForm">
  <div class="row align-items-center pt-3">
    <label class="font-weight-bold pr-3 col-auto mb-0">{{'schemaManager.createTable.label.lockMode' |
      translate}}</label>
    <app-radio-group formControlName="lockMode" class="d-flex align-items-center col-auto">
      <app-radio value="page" class="pr-4"> {{'schemaManager.createTable.label.page' | translate}} </app-radio>
      <app-radio value="row">{{'schemaManager.createTable.label.row' | translate}}</app-radio>
    </app-radio-group>
  </div>
  <div class="font-weight-bold pt-3 mb-0">{{'schemaManager.createTable.tableOptions' | translate}}</div>
  <div class="row pl-3">
    <app-checkbox class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pt-3" formControlName="isVercols"
      [title]="'schemaManager.createTable.tooltips.vercols' | translate">
      {{'schemaManager.createTable.label.vercols' | translate}}
    </app-checkbox>
    <app-checkbox class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pt-3" formControlName="isErKey"
      [title]="'schemaManager.createTable.tooltips.erkey' | translate">
      {{'schemaManager.createTable.label.erkey' | translate}}
    </app-checkbox>
  </div>
  <div class="row pl-3">
    <app-checkbox class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pt-3" formControlName="isCrcols"
      [title]="'schemaManager.createTable.tooltips.crcols' | translate">
      {{'schemaManager.createTable.label.crcols' | translate}}
    </app-checkbox>
    <app-checkbox class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pt-3" formControlName="isRowLevelAudit"
      [title]="'schemaManager.createTable.tooltips.audit' | translate">
      {{'schemaManager.createTable.label.audit' | translate}}
    </app-checkbox>
  </div>
  <div class="row pl-3">
    <app-checkbox class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pt-3" formControlName="isReplcheck"
      [title]="'schemaManager.createTable.tooltips.replcheck' | translate">
      {{'schemaManager.createTable.label.replcheck' | translate}}
    </app-checkbox>
    <app-checkbox class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pt-3" formControlName="isCompressed"
      [title]="'schemaManager.createTable.tooltips.compressed' | translate">
      {{'schemaManager.createTable.label.compressed' | translate}}
    </app-checkbox>
  </div>

  <div class="font-weight-bold pt-3">{{'schemaManager.createTable.label.aus' | translate}}</div>
  <div class="row align-items-center">
    <div class="col-auto pt-2">{{'schemaManager.createTable.label.specifyThreshold' | translate}}:</div>
    <div class="col-auto pt-2">
      <app-radio-group formControlName="autoUpdate" class="d-flex align-items-center">
        <app-radio value="system" class="pr-4"
          [title]="'schemaManager.createTable.tooltips.defaultThreshold' | translate">
          {{'schemaManager.createTable.label.useSystemSetting' | translate}}
        </app-radio>
        <app-radio value="threshold" [title]="'schemaManager.createTable.tooltips.setThreshold' | translate">
          {{'schemaManager.createTable.label.setTreshold' | translate}}</app-radio>
        <div *ngIf="autoUpdate.value === 'threshold'" class="pl-3 d-flex align-items-center">
          <input class="form-control" type="number" formControlName="threshold" tooltip="" appExtendedFormControl>
          <span class="pl-2">%</span>
        </div>
      </app-radio-group>
    </div>
  </div>

  <div class="font-weight-bold pt-3">{{ 'schemaManager.createIndex.indexOptions.enterExtentSize' | translate }}</div>
  <div class="font-weight-bold pt-2">{{ 'schemaManager.createIndex.indexOptions.extentSizeEstimator' | translate }}
  </div>
  <div class="row align-items-end">
    <div class="col-auto pt-2">
      <label>{{ 'schemaManager.createIndex.indexOptions.numberOfRows' | translate }}</label>
      <input class="form-control" type="number" formControlName="exptRows" tooltip="" appExtendedFormControl>
    </div>
    <div class="col-auto pt-2">
      <label>{{ 'schemaManager.createIndex.indexOptions.growthRate' | translate }}</label>
      <select class="form-control" formControlName="growthRate"
        [title]="'schemaManager.createIndex.indexOptions.growthRateDesc' | translate">
        <option value="static">{{ 'schemaManager.createIndex.indexOptions.static' | translate }}</option>
        <option value="medium">{{ 'schemaManager.createIndex.indexOptions.medium' | translate }}</option>
        <option value="aggressive">{{ 'schemaManager.createIndex.indexOptions.aggressive' | translate }}</option>
      </select>
    </div>
    <div class="col-auto pt-2">
      <button type="button" class="btn btn-primary" (click)="doEstimate()" [disabled]="!exptRows.value">{{
        'schemaManager.createIndex.indexOptions.estimate' | translate }}</button>
    </div>
  </div>
  <div class="font-weight-bold pt-3">{{ 'schemaManager.createIndex.indexOptions.extentSize' | translate }}</div>
  <div class="row">
    <div class="col-auto pt-2">
      <label>{{ 'schemaManager.createIndex.indexOptions.firstExtent' | translate }}</label>
      <input class="form-control" type="number" formControlName="firstExtent" tooltip="" appExtendedFormControl
        [title]="'schemaManager.createIndex.indexOptions.extentDesc' | translate">
    </div>
    <div class="col-auto pt-2">
      <label>{{ 'schemaManager.createIndex.indexOptions.nextExtent' | translate }}</label>
      <input class="form-control" type="number" formControlName="nextExtent" tooltip="" appExtendedFormControl
        [title]="'schemaManager.createIndex.indexOptions.extentDesc' | translate">
    </div>
  </div>
  <div class="row pt-3 align-items-center">
    <div class="font-weight-bold col-auto"> {{ 'schemaManager.createIndex.indexOptions.indexStorageOptions' | translate
      }}</div>
    <app-radio-group formControlName="storageScheme" class="col-auto">
      <app-radio value="tableStorage">
        {{ 'schemaManager.createIndex.indexOptions.followTableStorageScheme' | translate }}</app-radio>
      <app-radio class="ml-0 ml-sm-2" value="specifyStorage">{{
        'schemaManager.createIndex.indexOptions.specifyStorageScheme' | translate }}</app-radio>
    </app-radio-group>
  </div>
  <div *ngIf="storageScheme.value === 'specifyStorage'">
    <app-storage-scheme [server]="server" [dataObj]="dataObj" (isSchemeValid)="isSchemeValid = $event"
      [isBackToIndex]="isBackToIndex" [isCreateTable]="isCreateTable" [createQueryClicked]="createQueryClicked"
      (schemeDataObj)="getSchemeData($event)">
    </app-storage-scheme>
  </div>
</form>

<!-- Footer Buttons -->
<div class="row mt-3">
  <div class="col-12">
    <button type="button" class="btn btn-secondary mr-2" (click)="back('back')"> {{'button.back' | translate}}</button>
    <button type="button" class="btn btn-primary mr-2"
      [disabled]="!advTableForm.valid || (storageScheme.value === 'specifyStorage' && !isSchemeValid)"
      (click)="viewQuery()">{{'schemaManager.createTable.button.viewQueryAndCreate' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="back('cancel')"> {{'button.cancel' | translate}}</button>
  </div>
</div>
