<div *ngIf="sensorsList">
  <div class="alert alert-warning" *ngIf="server && (!server.agent || !server.agent.isMonitoring)">
    The monitoring agent is not running{{ (server.agent.isMonitoring || isWorkOffline) ? ' or You are working in offline mode.' : '.'}}
    You can configure sensors but they will not be deployed until an agent is connected.
    <a class="btn btn-sm btn-secondary" [routerLink]="['../setup']" [queryParams]="{ tab: 'agent' }">
      Configure agent
    </a>
  </div>

  <div class="row mb-1">
    <div class="col">
      <h4>Sensors</h4>
    </div>
    <div [hidden]="!(selectedSensors.length > 0)" class="col-auto">
      <span class="font-weight-bold color-light-gray-70 pr-2">{{selectedSensors.length}} of
        {{sensorsList.sensors.length}} sensor(s) selected</span>
      <app-check-permissions [permissions]="owner.permissions.admin">
        <div tooltip="Edit" placement="top"><button placement="bottom" class="mt-0 mb-0 btn btn-sm btn-icon"
            [popover]="modifySensors" #sensorPopover="bs-popover" [outsideClick]="true" containerClass="mw-none popover-color"
            popoverTitle="Configure Sensors" appPermissionDisable>
            <i class="icon icon-edit"></i>
          </button></div>
      </app-check-permissions>
      <app-check-permissions [permissions]="owner.permissions.admin">
        <button class="btn btn-sm btn-icon" (click)="enableMultipleSensors()" tooltip="Enable" appPermissionDisable>
          <i class="icon icon-check"></i>
        </button>
        <button class="btn btn-sm btn-icon" (click)="disableMultipleSensors()" tooltip="Disable" appPermissionDisable>
          <i class="icon icon-ban"></i>
        </button>
      </app-check-permissions>
      <app-check-permissions [permissions]="owner.permissions.admin">
        <button class="btn btn-sm btn-icon" (click)="removeMultipleSensors()" tooltip="Remove" appPermissionDisable><i
            class="icon icon-trash"></i></button>
      </app-check-permissions>
    </div>
    <div class="col col-auto">
      <app-check-permissions [permissions]="owner.permissions.admin">
        <button class="btn btn-sm btn-primary" (click)="openAddSensorsDialog()" appPermissionDisable>
          <i class="icon icon-plus"></i> Add Sensors
        </button>
      </app-check-permissions>
    </div>
    <ng-template #modifySensors>
      <div class="popover-body btn-group-toggle" data-toggle="buttons">
        <app-edit-sensor [sensor]="selectedSensors[0]" [isEditAll]="true" (save)="getUpdatedValue($event)">
        </app-edit-sensor>
      </div>
    </ng-template>
  </div>

  <app-data-table #sensorDataTable [data]="sensorsList.sensors" sortBy="name" class="table-fixed"
    [enablePagination]="false" noDataAlertMessage="No sensors defined." [enableSelect]="true"
    (setData)="sensorsData($event)" (updateSelectedRows)="getSelectedSensors($event)" [minHeight]="dynamicHeight">
    <app-data-table-expand [expandCondition]="editSensorIsOpen.bind(this)">
      <ng-template appTableExpand let-sensor="row">
        <div class="inline-edit">
          <app-edit-sensor [sensor]="sensor" (save)="editSave($event)"> </app-edit-sensor>
        </div>
      </ng-template>
    </app-data-table-expand>

    <app-data-table-column title="Name" property="name" [sortable]="true">
      <ng-template appTableCell let-name="item" let-sensor="row">
        {{name}}
        <span *ngIf="sensor.isOverriding" class="badge badge-info">Override</span>
        <span *ngIf="sensor.isModified" class="badge badge-warning">Modified</span>
        <span *ngIf="sensor.isNew" class="badge badge-success">New</span>
        <span *ngIf="sensor.disabled" class="badge badge-danger">Disabled</span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Run Interval" property="runInterval">
      <ng-template appTableCell let-interval="item">
        {{interval | relativeTime}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Data Retention Interval" property="dataRetentionInterval">
      <ng-template appTableCell let-interval="item">
        {{((interval <= 0)? 'Indefinite' : interval + ' day' + ((interval> 1)? 's': ''))}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-sensor="row">
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-icon" style="margin-left: 10px" (click)="editSensor(sensor)" tooltip="Edit"
            appPermissionDisable>
            <i class="icon icon-edit"></i>
          </button>
          <button class="btn btn-sm btn-icon" [disabled]="!sensor.isModified" (click)="undoChanges(sensor)"
            tooltip="Undo edits">
            <i class="icon icon-reset"></i>
          </button>
        </app-check-permissions>
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-icon" *ngIf="sensor.disabled" (click)="enableSensor(sensor)" tooltip="Enable"
            appPermissionDisable>
            <i class="icon icon-check"></i>
          </button>
          <button class="btn btn-sm btn-icon" *ngIf="!sensor.disabled" (click)="disableSensor(sensor)" tooltip="Disable"
            appPermissionDisable>
            <i class="icon icon-ban"></i>
          </button>
        </app-check-permissions>
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-icon" (click)="removeSensor(sensor)" tooltip="Remove" appPermissionDisable><i
              class="icon icon-trash"></i></button>
        </app-check-permissions>
      </ng-template>
    </app-data-table-column>
  </app-data-table>

</div>
<ng-template [ngIf]="inheritedSensorsList">
  <hr>
  <div class="row align-items-center my-1">
    <div class="pr-0 col-md-7 col-sm-7 col-auto">
      <h4 style="display: inline-block">Inherited Sensors</h4>
      <a style="margin-left: 10px" [routerLink]="['/dashboard/groups', owner.parentGroupId, 'monitoring']"
        *ngIf="owner.parentGroupId !== null">(View
        parent profile)</a>
    </div>
    <div class="align-items-center col-md-5 col-sm-5 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end
      justify-content-sm-end justify-content-start">
      <div [hidden]="!(selectedInheritedSensors.length > 0)">
        <span class="font-weight-bold color-light-gray-70 pr-2">{{selectedInheritedSensors.length}} of
          {{inheritedSensorsList.sensors?.length}} sensor(s) selected</span>
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-icon" tooltip="Override" (click)="overrideMultipleSensors()" placement="top"
            appPermissionDisable>
            <i class="icon icon-arrow-up"></i>
          </button>
        </app-check-permissions>
      </div>
    </div>
  </div>
  <app-data-table #inheritedSensorTable [data]="inheritedSensorsList.sensors" class="table-fixed" sortBy="name"
    [enablePagination]="false" noDataAlertMessage="Parent group has no sensors defined." [enableSelect]="true"
    (setData)="inheritedSensorsData($event)" (updateSelectedRows)="getSelectedInheritedSensors($event)" [minHeight]="dynamicHeight">
    <app-data-table-column title="Name" property="name" [sortable]="true">
      <ng-template appTableCell let-name="item" let-sensor="row">
        {{name}}
        <span *ngIf="sensor.isOverridden" class="badge badge-danger">Overridden</span>
        <span *ngIf="sensor.disabled" class="badge badge-danger">Disabled</span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Run Interval" property="runInterval">
      <ng-template appTableCell let-interval="item">
        {{interval | relativeTime}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Data Retention Interval" property="dataRetentionInterval">
      <ng-template appTableCell let-interval="item">
        {{((interval <= 0)? 'Indefinite' : interval + ' day' + ((interval> 1)? 's': ''))}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-sensor="row">
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-icon" *ngIf="!sensor.isOverridden" tooltip="Override and Disable"
            placement="top" (click)="overrideSensor(sensor, true)" appPermissionDisable><i
              class="icon icon-ban"></i></button>
        </app-check-permissions>
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-icon" *ngIf="!sensor.isOverridden" tooltip="Override" placement="top"
            (click)="overrideSensor(sensor)" appPermissionDisable>
            <i class="icon icon-arrow-up"></i>
          </button>
        </app-check-permissions>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</ng-template>

<ng-template [ngIf]="(sensorsList && sensorsList.sensors.length > 0) || changeCount > 0">
  <div class="mt-4">
    <button class="btn btn-primary" [disabled]="changeCount < 1" (click)="saveChanges()">
      <i class="icon icon-check"></i> Save Changes
    </button>
    <button class="btn btn-secondary ml-2" [disabled]="changeCount < 1" (click)="discardChanges()">
      <i class="icon icon-times"></i> Discard Changes
    </button>
  </div>
</ng-template>

<div bsModal #addSensorsModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Add Sensors</h5>
        <button type="button" class="close" (click)="addSensorsModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <a routerLink="/dashboard/system-settings/sensors/edit" class="btn btn-sm btn-primary"
          [queryParams]="{ server: owner.id, source: 'monitoring' }" *ngIf="canCreateSensorType()">
          Create Custom Sensor
        </a>
        <ng-container *ngIf="sensorTypes && sensorTypes.length > 0; else noSensors">
          <app-data-table #addSensorsTable [data]="sensorTypes" [enableSelect]="true" sortBy="name" [minHeight]="450">
            <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-item="item" let-type="row">
                {{item}}
                <span class="badge badge-info"
                  *ngIf="parentProfile && parentProfile.getSensor(type.id) !== null">Override</span>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Description" property="description" [sortable]="false" [searchable]="true">
              <ng-template appTableCell let-item="item" let-type="row">
                {{item}}
              </ng-template>
            </app-data-table-column>
          </app-data-table>
          <button type="button" class="btn btn-primary" [disabled]="addSensorsTable.selectedCount < 1"
            (click)="addSensors(addSensorsTable.getSelectedRows())">
            Add Sensors
          </button>
          <button type="button" class="btn btn-secondary" (click)="addSensorsModal.hide()">Cancel</button>
        </ng-container>
        <ng-template #noSensors>
          <div class="alert alert-danger">
            No more sensors are available.
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div bsModal #discardChangesModal="bs-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
        <button type="button" class="close" (click)="discardChangesModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="p-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
          Discard unsaved changes?
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="discardChangesModal.hide()">No</button>
        <button type="button" class="btn btn-primary" (click)="resolveDiscardChanges(true)">Yes</button>
      </div>
    </div>
  </div>
</div>
