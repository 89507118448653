<ng-container *ngIf="forceConstant; else selectOperand">
    <ng-template #operandContainer> </ng-template>
</ng-container>
<ng-template #selectOperand>
  <select class="form-control form-control-sm" [ngModel]="operandType" (ngModelChange)="setOperandType($event)">
    <option [ngValue]="null" *ngIf="!operandType">Select a value...</option>
    <option *ngFor="let op of alertOperandTypesFiltered" [ngValue]="op">{{op.description}}</option>
  </select>
  <div class="ml-4 mt-2">
    <ng-template #operandContainer> </ng-template>
  </div>
</ng-template>