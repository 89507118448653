<div class="container-fluid page-container">
  <h4>
    <strong>Create Sensor</strong>
  </h4>

  <button type="button" class="btn btn-primary mt-2" (click)="createSensorType()"
    [disabled]="isPreviewDisabled() || createSensorSub">
    Save Sensor
  </button>

  <div class="alert alert-danger mt-2" *ngIf="createSensorError">
    {{createSensorError}}
  </div>

  <tabset>
    <tab heading="SQL">
      <div class="mt-2">

        <p *ngIf="!queryResult">
          An {{productNameNoSpace}} SQL Sensor is based on a single SQL statement that the agent runs periodically against
          the <strong>sysmaster</strong> database. The returned data is stored in the repository database.
          To begin, enter a SQL statement that returns at least one row of data.
        </p>

        <div class="row align-items-center">
          <div class="col-auto">
            Selected server: {{server ? server.alias : 'None'}}
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-secondary" (click)="showSelectServerModal()">
              Select...
            </button>
          </div>
        </div>

        <textarea class="form-control text-monospace" rows="5" [formControl]="queryFormControl" autocomplete="off"
          autocorrect="off" autocapitalize="off" spellcheck="false" (keydown)="onQueryKeyDown($event)">
        </textarea>

        <button type="button" class="btn btn-primary mt-2" (click)="onRunClick()" [disabled]="!session || !!querySub">
          Run
        </button>

        <div class="mt-2 text-center" *ngIf="sessionSub || querySub">
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </div>

        <div class="mt-2" *ngIf="queryResult" [formGroup]="formGroup">
          <h5>Data configuration</h5>
          <div class="alert alert-warning"
            *ngIf="!formGroup.value.primaryKey && getDisplayedQueryResult().rows.length > 1">
            Looks like the query returned more than one row. Unless you transpose the data or specify a primary key,
            only the first row will be stored.
          </div>

          <div class="form-group">
            Transpose
            <i class="icon icon-question-circle ml-1"
              tooltip="Transpose (or pivot) will transform rows into columns based on the selected column.">
            </i>
            <div class="form-inline">
              <select class="form-control" formControlName="transposeName">
                <option [ngValue]="null">Disabled</option>
                <option *ngFor="let columnName of queryResult.columnNames" [ngValue]="columnName">
                  {{columnName}}
                </option>
              </select>
            </div>
            <small class="form-text d-block" *ngIf="queryResult.columnNames.length !== 2">
              Transpose requires a query which returns exactly 2 columns.
            </small>
          </div>

          <div class="form-group">
            Primary Key
            <i class="icon icon-question-circle ml-1" tooltip="If a query returns multiple rows describing multiple objects (e.g. dbspaces), the primary key will be used to uniquely identify each object.
              Null and duplicate values will be ignored.">
            </i>
            <div class="form-inline">
              <select class="form-control" formControlName="primaryKey">
                <option [ngValue]="null">None</option>
                <option *ngFor="let columnName of getDisplayedQueryResult().columnNames" [ngValue]="columnName">
                  {{columnName}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group" *ngIf="primaryKeyTypeOptions">
            Primary Key type
            <i class="icon icon-question-circle ml-1" tooltip="The data type of the primary key.">
            </i>
            <div class="form-inline">
              <select class="form-control" formControlName="primaryKeyType">
                <option *ngFor="let type of primaryKeyTypeOptions" [ngValue]="type">
                  {{type}}
                </option>
              </select>
            </div>
          </div>

          <button class="btn btn-primary" (click)="goToNextTab()">
            Next <i class="icon icon-chevron-right"></i>
          </button>

          <h5 class="mt-2">Data preview</h5>
          <div style="overflow-x: auto">
            <app-query-result-table [resultPage]="getDisplayedQueryResult()"></app-query-result-table>
          </div>
        </div>
        <div class="alert alert-danger mt-2" *ngIf="sessionError || queryError">
          {{sessionError || queryError}}
        </div>
      </div>
    </tab>
    <tab [disabled]="!queryResult">
      <ng-template tabHeading>
        Metrics
        <i *ngIf="metricsFormGroup && metricsFormGroup.invalid" class="icon icon-exclamation-circle color-red"></i>
      </ng-template>

      <div class="form-inline mt-2">
        <select class="form-control" [(ngModel)]="filterMetricIndex">
          <option [ngValue]="-1">Show All</option>
          <option *ngFor="let metricForm of metricForms; let i = index" [ngValue]="i">
            {{metricForm.value.name}}
          </option>
        </select>
      </div>
      <div class="mt-2" *ngFor="let metricForm of metricForms; let isFirst = first; let isLast = last; let i = index"
        [formGroup]="metricForm" [hidden]="filterMetricIndex > -1 && filterMetricIndex !== i">
        <h5>{{metricForm.value.id}}</h5>
        <div style="width: 400px">
          <div class="form-group">
            Name
            <i class="icon icon-question-circle ml-1" tooltip="The display name of this metric"
              *ngIf="isFirst || filterMetricIndex > -1">
            </i>
            <input type="text" class="form-control" formControlName="name" tooltip="" appExtendedFormControl>
          </div>
          <div class="form-group">
            Unit
            <i class="icon icon-question-circle ml-1" *ngIf="isFirst || filterMetricIndex > -1"
              tooltip="The unit determines how this metric will be displayed by default">
            </i>
            <select class="form-control" formControlName="unit">
              <option [ngValue]="null">None</option>
              <option *ngFor="let unit of metricUnits" [ngValue]="unit">
                {{unit}}
              </option>
            </select>
          </div>
          <div class="form-group">
            Default value
            <i class="icon icon-question-circle ml-1" *ngIf="isFirst || filterMetricIndex > -1"
              tooltip="The default value of this metric if the query returns a null or invalid value. If blank, an invalid value will be ignored">
            </i>
            <input type="text" class="form-control" formControlName="default">
          </div>
          <div class="form-group">
            <app-checkbox formControlName="delta">
              Calculate delta
              <i class="icon icon-question-circle ml-1" *ngIf="isFirst || filterMetricIndex > -1"
                tooltip="If enabled, the sensor will calculate and store the difference per second between the latest reading and the previous reading">
              </i>
            </app-checkbox>
          </div>
        </div>
        <hr *ngIf="!isLast">
      </div>
      <button class="btn btn-primary mt-2" (click)="goToNextTab()">
        Next <i class="icon icon-chevron-right"></i>
      </button>
    </tab>
    <tab [disabled]="!queryResult" (selectTab)="sensorTabVisited = true">
      <ng-template tabHeading>
        Sensor
        <i *ngIf="sensorTabVisited && sensorFormGroup.invalid" class="icon icon-exclamation-circle color-red"></i>
      </ng-template>

      <div class="mt-2" style="width: 400px" [formGroup]="sensorFormGroup">
        <div class="form-group">
          ID
          <i class="icon icon-question-circle ml-1"
            tooltip="The ID to uniquely identify this sensor. Can only use lowercase characters, digits, and single underscores. (e.g.: my_custom_sensor)">
          </i>
          <input type="text" class="form-control" formControlName="id" tooltip="" appExtendedFormControl
            [customErrors]="sensorIdValidationErrors" maxlength="100">
        </div>
        <div class="form-group">
          Name
          <i class="icon icon-question-circle ml-1" tooltip="The display name of this sensor">
          </i>
          <input type="text" class="form-control" formControlName="name" tooltip="" appExtendedFormControl>
        </div>
        <div class="form-group">
          Description
          <textarea class="form-control" rows="5" formControlName="description"></textarea>
        </div>
        <div class="form-group">
          Default run interval<br>
          <app-input-time class="d-inline-block" formControlName="runInterval" min="1" tooltip="" placement="right"
            appExtendedFormControl>
          </app-input-time>
        </div>
        <div class="form-group">
          Default data retention interval
          <div class="form-inline">
            <input type="number" class="form-control form-control-sm mr-1" min="1" style="width: 50px" tooltip=""
              formControlName="dataRetentionInterval" appExtendedFormControl> day(s)
          </div>
        </div>
      </div>

      <button class="btn btn-primary" (click)="goToNextTab()" [disabled]="isPreviewDisabled()">
        Next <i class="icon icon-chevron-right"></i>
      </button>
    </tab>
    <tab heading="Preview" [disabled]="isPreviewDisabled()" (selectTab)="onPreviewTabSelect($event)">
      <h5 class="mt-2">Sensor JSON Preview</h5>
      <app-json-explorer class="d-block text-monospace" *ngIf="sensorJson" [json]="sensorJson" [inline]="false"
        [expand]="true" [showExpandToggle]="false"></app-json-explorer>
    </tab>
  </tabset>
</div>

<ng-template #selectServerModal>
  <div class="modal-header">
    <h5 class="modal-title">Select a server</h5>
  </div>
  <div class="modal-body p-0">
    <app-group-browser [startingItem]="server" (serverSelect)="selectServer($event)">
    </app-group-browser>
  </div>
</ng-template>
