/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EnterpriseReplicationService } from '../../enterprise-replication.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { InformixServer } from '../../../informixServer';

interface Server {
  name: string;
}

class ServerData {
  targetServers: Array<Server> = [];
  serverList: Array<Server>;
  serverName: string;

  constructor(isAddServer, serverName, serverData) {
    this.serverName = serverName;
    this.serverList = serverData.serverList;
    this.targetServers = serverData.targetServers;

    if (isAddServer) {
      this.addServerName();
    } else {
      this.removeServerName();
    }
  }

  addServerName() {
    if (this.serverName) {
      this.targetServers.push({ name: this.serverName });
    }
    this.serverList = this.serverList.filter(server => server.name !== this.serverName);
    setTimeout(() => {
      this.targetServers = this.targetServers.slice();
      this.serverList = this.serverList.slice();
    });
  }

  removeServerName() {
    if (this.serverName) {
      this.targetServers = this.targetServers.filter(server => server.name !== this.serverName);
      this.serverList.push({ name: this.serverName });
      setTimeout(() => {
        this.targetServers = this.targetServers.slice();
        this.serverList = this.serverList.slice();
      });
    }
  }
}

@Component({
  selector: 'app-sync-replicate-modal',
  templateUrl: './sync-replicate-modal.component.html'
})
export class SyncReplicateModalComponent implements OnInit {
  @ViewChild('syncReplicateModal') syncReplicateModal: ModalDirective;
  @Output() closeModal = new EventEmitter<any>();
  @Input() serverNames = [];
  @Input() replicate;

  formGroup: FormGroup;
  server: InformixServer = null;
  serverData: ServerData;
  firstPage: Boolean = true;
  isPreventCheckTimeSeries: Boolean = false;
  isAvoidTimeseriesElements: Boolean = false;
  isAllTargetServers: Boolean = false;
  isLoading: Boolean = false;
  isProceed: Boolean = false;
  sendQueueFormat = 'K';
  error: string = null;
  tasks = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.server = data.server;
    });

    this.formGroup = this.fb.group({
      taskName: new FormControl(null, Validators.required),
      extraTargetRows: new FormControl('delete', Validators.required),
      serverName: new FormControl(null, Validators.required),
      targetServerName: new FormControl(null),
      isFireTriggers: new FormControl('off'),
      sendQueueSize: new FormControl(null)
    });
    this.getJobsData();
  }

  getJobsData() {
    this.isLoading = true;
    this.erService.getJobs(this.server.id).subscribe(res => {
      this.tasks = res;
      this.isLoading = false;
      this.setCheckJobDetails();
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  setCheckJobDetails() {
    this.serverData = new ServerData(true, null, { serverList: this.serverNames, targetServers: [] });
    this.replicate = this.replicate;
    this.formGroup.controls.taskName.setValue('HQ_' + this.replicate.replicateName);
    this.checkJobNameExist();
  }

  checkJobNameExist() {
    const form = this.formGroup.value;
    if (form.taskName) {
      let taskDetails = this.tasks.find(task => task.name === form.taskName);
      if (taskDetails) {
        this.error = `This task name already exists. If you use this task name, 
        the new task will replace the existing task and its results.`;
        return true;
      } else {
        this.error = null;
        return false;
      }
    }
  }

  hideModal() {
    this.closeModal.emit();
  }

  addTargetServers() {
    const form = this.formGroup.value;
    if (form.targetServerName !== null) {
      this.serverData = new ServerData(true, form.targetServerName, this.serverData);
      this.error = null;
    }
  }

  removeTargetServer(index: number) {
    let serverName = this.serverData.targetServers[index].name;
    if (serverName) {
      this.serverData = new ServerData(false, serverName, this.serverData);
    }
  }

  checkValidations() {
    if (this.formGroup.invalid) {
      if (this.formGroup.invalid && this.formGroup.controls.taskName.invalid) {
        this.error = 'Task Name should not be empty';
      }

      if (this.formGroup.invalid && this.formGroup.controls.serverName.invalid) {
        this.error = 'Server Name should not be empty, Please select server name.';
      }
      return true;
    }

    if (this.isAllTargetServers) {
      if (this.serverNames && this.serverNames.length === 0) {
        this.error = 'Target Server should not be empty, Please select and add target server name.';
        return true;
      }
    } else {
      if (this.serverData.targetServers && this.serverData.targetServers.length === 0) {
        this.error = 'Target Server should not be empty, Please select and add target server name.';
        return true;
      }
    }
    // allow duplicate jobname to override on double save
    if (this.checkJobNameExist() && !this.isProceed) {
      this.isProceed = true;
      return true;
    }
    return false;
  }

  onSendQueueFormat(event) {
    this.sendQueueFormat = event.target.value;
  }

  getFormData() {
    const form = this.formGroup.value;
    const requestBody = {
      action: 'sync',
      replicateName: this.replicate.replicateName,
      jobName: form.taskName,
      object: 'replicate',
      extraTargetRows: form.extraTargetRows,
      masterDataSource: form.serverName,
      fireTrigger: form.isFireTriggers,
      isExcludeTimeSeries: this.isPreventCheckTimeSeries,
      isIgnoreHiddenTSElements: this.isAvoidTimeseriesElements,
      isExecute: true
    };

    if (form.sendQueueSize !== null) {
      requestBody['sizeSendQueue'] = form.sendQueueSize + this.sendQueueFormat;
    }

    if (this.isAllTargetServers) {
      if (this.serverNames && this.serverNames.length) {
        // let targetServers = this.serverNames.map(server => server.name);
        // requestBody['targetServersList'] = targetServers;
        requestBody['targetServersList'] = [];
      } else {
        return;
      }
    } else {
      if (this.serverData.targetServers && this.serverData.targetServers.length) {
        requestBody['targetServersList'] = this.serverData.targetServers.map(server => server.name);
      } else {
        return;
      }
    }
    return requestBody;
  }

  save() {
    if (this.checkValidations()) {
      return;
    }
    this.isLoading = true;
    const requestData = this.getFormData();
    this.erService.checkOrSyncReplicate(this.server.id, requestData).subscribe((res: any) => {
      this.isLoading = false;
      const msg = 'Replicate sync job created successfully';
      this.notificationsService.pushSuccessNotification(msg);
      this.hideModal();
      const queryParams = { replicate: this.replicate.replicateName };
      this.router.navigate(['dashboard/servers/' + this.server.id + '/replicate/check-sync'], { queryParams });
    }, err => {
      this.isLoading = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  allTargetServers() {
    this.isAllTargetServers = !this.isAllTargetServers;
    if (this.isAllTargetServers) {
      this.error = null;
    }
  }
}
