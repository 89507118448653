<div bsModal #openDbPriorityModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Database Priority Rank</h5>
        <small class="ml-2 mt-2">Drag the databases to the desired columns.</small>
        <button type="button" class="close" (click)="closeModal()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="w-25 mr-1 card card-body p-0" droppable id="highPriorityDrop" (onDrop)="dropEvent($event)">
            <div class="pt-2 border-high" droppable="false">
              <div class="card-title pl-2">High Priority</div>
              <hr class="m-0">
            </div>
            <ul droppable id="highPriorityDrop" (onDrop)="dropEvent($event)" class="list-group p-2">
              <ng-container *ngFor="let item of highItems">
                <div class="db m-1" droppable>
                  <li droppable draggable="true" id="highPriority" (onDragEnd)="dragEvent($event)" [dragData]="item"
                    class="list-group-item bg-blue-10 p-1">
                    {{item}}</li>
                </div>
              </ng-container>
            </ul>
          </div>
          <div class="w-25 mr-1 card card-body p-0" droppable id="medPriorityDrop" (onDrop)="dropEvent($event)">
            <div class="pt-2 border-medium" droppable="false">
              <div class="card-title pl-2">Medium Priority</div>
              <hr class="m-0">
            </div>
            <ul droppable id="medPriorityDrop" (onDrop)="dropEvent($event)" class="list-group p-2">
              <ng-container *ngFor="let item of mediumItems">
                <div class="db m-1">
                  <li droppable (onDragEnd)="dragEvent($event)" id="medPriority" [dragData]="item" draggable="true"
                    class="list-group-item bg-blue-10 p-1">{{item}}</li>
                </div>
              </ng-container>
            </ul>
          </div>
          <div class="w-25 mr-1 card card-body p-0" droppable id="lowPriorityDrop" (onDrop)="dropEvent($event)">
            <div class="pt-2 border-low" droppable="false">
              <div droppable class="card-title pl-2">Low Priority</div>
              <hr class="m-0">
            </div>
            <ul droppable id="lowPriorityDrop" (onDrop)="dropEvent($event)" class="list-group p-2">
              <ng-container *ngFor="let item of lowItems">
                <div class="db m-1">
                  <li droppable (onDragEnd)="dragEvent($event)" [dragData]="item" draggable="true" id="lowPriority"
                    class="list-group-item bg-blue-10 p-1">
                    {{item}}
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
          <div class="w-25 card card-body p-0" droppable id="disabledPriorityDrop" (onDrop)="dropEvent($event)">
            <div class="pt-2 border-disable" droppable="false">
              <div droppable class="card-title pl-2">Disabled</div>
              <hr class="m-0">
            </div>
            <ul droppable id="disabledPriorityDrop" (onDrop)="dropEvent($event)" class="list-group p-2">
              <ng-container *ngFor="let item of disabledItems">
                <div class="db m-1">
                  <li droppable (onDragEnd)="dragEvent($event)" [dragData]="item" draggable="true" id="disabledPriority"
                    class="list-group-item bg-blue-10 p-1">{{item}}</li>
                </div>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="text-right mt-2">
          <app-check-server-admin-permissions [server]="server">
            <button type="button" class="btn btn-secondary" (click)="closeModal()" appPermissionDisable>
              Cancel</button>
          </app-check-server-admin-permissions>
          <app-check-server-admin-permissions [server]="server">
            <button type="button" class="btn btn-primary btn-save-edit" (click)="saveDatabasePriority()"
              appPermissionDisable>
              Save</button>
          </app-check-server-admin-permissions>
        </div>
      </div>
    </div>
  </div>
</div>