/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { Dashboard } from '../../../dashboard/monitoring/dashboard';

export const memoryDashboard: Dashboard = {
  title: '',
  rows: [
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'memory',
            title: 'Database Shared Memory',
            metricList: ['allocated_mem', 'used_mem']
          }
        },
        {
          component: 'chart',
          config: {
            sensorId: 'os_memory',
            title: 'OS Memory',
            metricList: ['mem_total', 'mem_used']
          }
        }
      ]
    }
  ]
};
