<div *ngIf="server">
  <form role="form" [formGroup]="formGroup" class="pl-4">
    <div class="form-group row">
      <label class="col-form-label font-weight-bold col-sm-2">Path</label>
      <div class="col-sm-6">
        <input class="form-control" formControlName="path" tooltip="" appExtendedFormControl appFocusOnCreate>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label font-weight-bold col-sm-2">Priority</label>
      <div class="col-sm-2">
        <select formControlName="priority" class="form-control">
          <option *ngFor="let priority of priorityOptions" value="{{priority.value}}">{{priority.label}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label font-weight-bold col-sm-2">Offset</label>
      <div class="col-sm-10 form-inline">
        <app-input-size formControlName="offset" min="0" tooltip="" appExtendedFormControl></app-input-size>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label font-weight-bold col-sm-2">Size</label>
      <app-radio-group class="col-10" formControlName="sizeRadio">
        <div>
          <app-radio value="extendable">Extendable</app-radio>
        </div>
        <div class="form-inline mt-2">
          <app-radio value="fixedBySize">Fixed by size</app-radio>
          <app-input-size class="ml-2" [hidden]="formGroup.value.sizeRadio !== 'fixedBySize'"
            formControlName="fixedBySize" [min]="STORAGE_POOL_ENTRY_SIZE_MINIMUM" title="Specify the size of the space"
            tooltip="" appExtendedFormControl [customErrors]="sizeErrorHandlers">
          </app-input-size>
        </div>
        <div class="form-inline mt-2">
          <app-radio value="fixedByEndOffset">Fixed by ending offset</app-radio>
          <app-input-size class="ml-2" [hidden]="formGroup.value.sizeRadio !== 'fixedByEndOffset'"
            formControlName="fixedByEndOffset" title="Specify the end of the space" tooltip="" appExtendedFormControl
            [customErrors]="sizeErrorHandlers">
          </app-input-size>
        </div>
      </app-radio-group>
    </div>
    <div class="form-group row">
      <label class="col-form-label font-weight-bold col-sm-2">Chunk Size</label>
      <div class="col-sm-10 form-inline">
        <app-input-size formControlName="chunkSize" min="{{STORAGE_POOL_ENTRY_SIZE_MINIMUM}}" tooltip=""
          appExtendedFormControl [customErrors]="sizeErrorHandlers"></app-input-size>
      </div>
    </div>
    <div class="form-group row d-flex pt-2 flex-row-reverse">
      <div class="col-sm-3 d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submitForm()"
          [disabled]="!formGroup.valid">{{(entryToModify)? 'Modify' : 'Add'}}</button>
      </div>
    </div>
  </form>
</div>
