/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../../../shared/shared.module';
import { ColorPickerButtonComponent } from './color-picker-button/color-picker-button.component';
import { SensorChartjsPluginConfigComponent } from './sensor-chartjs-plugin-config/sensor-chartjs-plugin-config.component';
import { SensorChartjsPluginComponent } from './sensor-chartjs-plugin/sensor-chartjs-plugin.component';
import { SensorChartjsService } from './sensor-chartjs.service';
import { AxisConfigComponent } from './axis-config/axis-config.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        PopoverModule,
        TooltipModule,
        TabsModule
    ],
    declarations: [
        SensorChartjsPluginComponent,
        SensorChartjsPluginConfigComponent,
        ColorPickerButtonComponent,
        AxisConfigComponent
    ],
    exports: [
        SensorChartjsPluginComponent,
        SensorChartjsPluginConfigComponent
    ],
    providers: [
        SensorChartjsService
    ]
})
export class SensorChartjsPluginModule { }
