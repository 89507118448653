<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col">
      <h4>
        <strong>Statement Profile</strong>
      </h4>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Data is not available. {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div class="row mt-2" *ngIf="stmtInfo">
    <div class="col-xs-12 col-sm-6 mb-2">

      <div class="card card-body">
        <h5 class="card-title">
          <strong>Statement Info</strong>
        </h5>
        <div class="row mb-2">
          <div class="col text-lg-right">
            Session ID:
          </div>
          <div class="col">
            {{stmtInfo.sql_sid}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-lg-right">
            User ID:
          </div>
          <div class="col">
            {{stmtInfo.sql_uid}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-lg-right">
            Statement Type:
          </div>
          <div class="col">
            {{stmtInfo.sql_stmtname}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-lg-right">
            PDQ:
          </div>
          <div class="col">
            {{stmtInfo.sql_pdq}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-lg-right">
            Statement Completion Time:
          </div>
          <div class="col">
            {{stmtInfo.sql_finishtime * 1000 | date:'y-MM-dd HH:mm:ss'}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-lg-right">
            Response Time:
          </div>
          <div class="col">
            {{stmtInfo.sql_runtime}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-lg-right">
            Database:
          </div>
          <div class="col">
            {{stmtInfo.sql_database}}
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-12 col-sm-6">
      <div class="card card-body">
        <h5 class="card-title">
          <strong>SQL</strong>
        </h5>
        <div class="row">
          <div class="col">
            {{sql}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-2" *ngIf="stmtInfo">
    <div class="col">

      <div class="card card-body">
        <h5 class="card-title">
          <strong>Statistics</strong>
        </h5>
        <div class="row">

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">Reads</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    Page
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_pgreads}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Buffer
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_bfreads}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Cache
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_rdcache}} %
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">Writes</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    Page
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_pgwrites}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Buffer
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_bfwrites}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Cache
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_wrcache}} %
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">I/O</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    IO Waits
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_numiowaits}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Wait Time
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_totaliowaits}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Avg Wait
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_avgiowaits}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Rows Per Second
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_rowspersec}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">Executions</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    Total
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_executions}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Total Time
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_totaltime}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Avg
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_avgtime}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Max
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_maxtime}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">Optimizer</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    Estimated Cost
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_estcost}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Estimated Rows
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_estrows}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Actual Rows
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_actualrows}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Num Iterators
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_numiterators}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">SQL Info</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    SQL Error
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_sqlerror}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    ISAM Error
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_isamerror}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Isolation Level
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_isollevel}} {{isoLevelText}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    SQL Memory
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_sqlmemory | byte}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">Locks</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    Requests
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_lockreq}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Waits
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_lockwaits}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Wait Time
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_lockwttime}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <div class="card">
              <div class="card-header">Misc</div>
              <div class="card-body">
                <div class="row">
                  <div class="col text-right">
                    Disk Sorts
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_sortdisk}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Memory Sorts
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_sortmem}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    Log Space
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_logspace | byte}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    # of Tables
                  </div>
                  <div class="col">
                    {{stmtInfo.sql_numtables}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="sqltraceIteratorData">
    <div class="col-12 mb-2">
      <div class="card card-body">
        <h5 class="card-title">
          <strong>Query Tree</strong>
        </h5>
        <app-sqltrace-query-tree [sqltraceIterators]="sqltraceIteratorData"></app-sqltrace-query-tree>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div *ngIf="stmtInfo && stmtInfo.hvars" class="col-12 mb-2">
      <div class="card card-body">
        <h5 class="card-title">
          <strong>Host Variables</strong>
        </h5>
        <div class="table-responsive">
          <app-data-table [data]="stmtInfo.hvars" noDataAlertMessage="Host variables are not available."
            [minHeight]="'auto'">
            <app-data-table-column title="Position" property="sql_hvar_id">
            </app-data-table-column>
            <app-data-table-column title="Type" property="sql_hvar_type">
            </app-data-table-column>
            <app-data-table-column title="Value" property="sql_hvar_data">
            </app-data-table-column>
          </app-data-table>
        </div>
      </div>
    </div>
  </div>
