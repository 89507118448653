/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ExpressionList } from '../create-index.model';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-expression-modal',
  templateUrl: './add-expression-modal.component.html',
  styleUrls: ['../create-index.component.scss']
})

export class AddExpressionModalComponent implements OnInit {
  addExpressionsForm: UntypedFormGroup;
  name: UntypedFormControl;
  dbspace: UntypedFormControl;
  expression: UntypedFormControl;

  dbspaceNames: any[];
  expressionObj: ExpressionList;
  expressionList: ExpressionList[];
  type: string;
  isDuplicateName = false;
  spaceType: string;

  @Output() actionComplete = new EventEmitter<any>();

  @ViewChild('addExpressionsModal') addExpressionsModal: ModalDirective;

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService) { }

  ngOnInit() {
    this.name = new UntypedFormControl(null, [this.duplicateFragmentCheck(), Validators.required]);
    this.dbspace = new UntypedFormControl(null, [Validators.required]);
    this.expression = new UntypedFormControl(null, [Validators.required]);
    this.addExpressionsForm = this.fb.group({
      name: this.name,
      dbspace: this.dbspace,
      expression: this.expression
    });
  }

  show(spaceObj, row: any, type: string, expressionList: ExpressionList[]) {
    this.isDuplicateName = false;
    this.dbspaceNames = spaceObj.spaces;
    this.expressionObj = row;
    this.type = type;
    this.expressionList = expressionList;
    this.spaceType = spaceObj.type;
    this.setData();
    this.addExpressionsModal.show();
  }

  duplicateFragmentCheck(): ValidatorFn {
    return (): { customError: string } | null => {
      let error: string;
      this.translate.get('schemaManager.createIndex.expressionModal.duplicateFragment').subscribe((text: string) => {
        error = text;
      });
      const errors = { customError: error };
      if (this.addExpressionsForm && this.name) {
        this.name.setErrors({ customError: null });
        this.isDuplicateName = false;
        if (this.expressionList && this.expressionList.length > 0) {
          const filteredExpressionList = this.expressionList.filter(item => item.name === this.name.value);
          if (filteredExpressionList.length > 0) {
            if (this.type === 'edit' && this.expressionObj.name === this.name.value) {
              this.isDuplicateName = false;
              return null;
            } else {
              this.isDuplicateName = true;
              return errors;
            }
          } else {
            this.isDuplicateName = false;
            return null;
          }
        } else {
          return null;
        }
      }
    };
  }

  setData() {
    if (this.type === 'edit') {
      this.name.setValue(this.expressionObj.name);
      this.dbspace.setValue(this.expressionObj.dbSpace);
      this.expression.setValue(this.expressionObj.expression);
    } else {
      this.dbspace.setValue(this.dbspaceNames[0]);
    }
  }

  addValues(type: string) {
    if (type === 'save') {
      this.actionComplete.emit({
        id: this.expressionObj.id, name: this.name.value, dbSpace: this.dbspace.value, expression: this.expression.value
      });
    } else {
      this.actionComplete.emit({ name: this.name.value, dbSpace: this.dbspace.value, expression: this.expression.value });
    }
    this.hideModal();
  }

  addAnother() {
    this.actionComplete.emit({ name: this.name.value, dbSpace: this.dbspace.value, expression: this.expression.value });
    this.addExpressionsForm.reset();
    this.dbspace.setValue(this.dbspaceNames[0]);
  }

  hideModal() {
    this.addExpressionsModal.hide();
    this.addExpressionsForm.reset();
  }
}
