/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/

export class QueryResultValue {
  err: string;
  value: any = null;

  isJson = false;

  constructor(json: any) {
    if (json.err) {
      this.err = json.err;
    } else {
      const value = json.value;
      if (value === undefined || value === null) {
        this.value = null;
      } else {
        const dataType = typeof value;
        switch (dataType) {
          case 'number':
            this.value = value.toString();
            break;
          case 'string':
            this.value = value;
            break;
          case 'object':
            if (value.hasOwnProperty('$date') && typeof value.$date === 'number') {
              this.value = (new Date(value.$date)).toLocaleString();
            } else {
              this.value = value;
              this.isJson = true;
            }
            break;
          default:
            this.err = 'Unsupported type';
            break;
        }
      }
    }
  }
}

export class QueryResultRow {
  values: QueryResultValue[] = [];

  constructor(json?: any) {
    if (typeof json === 'object') {
      for (const key in json) {
        if (json.hasOwnProperty(key)) {
          this.values.push(new QueryResultValue(json[key]));
        }
      }
    }
  }
}

export class QueryResultPage {
  columnNames: string[] = [];
  rows: QueryResultRow[] = [];

  constructor(json?: any) {
    if (Array.isArray(json) && json.length > 0) {
      for (const key in json[0]) {
        if (json[0].hasOwnProperty(key)) {
          this.columnNames.push(key);
        }
      }

      this.rows = json.map(v => new QueryResultRow(v));
    }
  }
}

export type QueryResult = string | QueryResultPage;

export class QueryResultPages {
  output = [];
  constructor(resultData: any[]) {
    if (Array.isArray(resultData) && resultData.length > 0) {
      this.output = resultData.map(result => {
        let resultPage;
        if (result && result['data']) {
          const queryResult = new QueryResultPage(result['data']);
          resultPage = {
            ...queryResult,
            runTime: result['runTime'],
            isSuccess: result['isSuccess'],
            query: result['query'],
            resultSetId: result['resultSetId'],
            hasMore: result['hasMore']
          };
        } else {
          if (result.isSuccess) {
            const response = this.getQueryResponse(result);
            resultPage = {
              runTime: result['runTime'],
              isSuccess: result['isSuccess'],
              query: result['query'],
              n: result['n'],
              hasMore: result['hasMore'],
              response
            };
          } else {
            resultPage = {
              isSuccess: result['isSuccess'],
              query: result['query'],
              err: result['err']
            };
          }
          if(result.hasOwnProperty('isExecute')){
            resultPage['isExecute'] = result['isExecute'];
          }
        }
        return resultPage;
      });
    }
  }

  public getQueryResponse(json: any): QueryResult {
    let pQuery = json.query;
    if (/^create/i.test(pQuery)) {
      pQuery = pQuery.substring(pQuery.indexOf(' ')).trim();
      if (/^table/i.test(pQuery)) {
        return 'Table created.';
      } else if (/^database/i.test(pQuery)) {
        return 'Database created.';
      }
    } else if (/^drop/i.test(pQuery)) {
      pQuery = pQuery.substring(pQuery.indexOf(' ')).trim();
      if (/^table/i.test(pQuery)) {
        return 'Table dropped.';
      } else if (/^database/i.test(pQuery)) {
        return 'Database dropped.';
      }
    } else if (/^update/i.test(pQuery) || /^insert/i.test(pQuery) || /^delete/i.test(pQuery)) {
      if (json && typeof json.n === 'number') {
        return 'Rows affected: ' + json.n;
      } else {
        return json;
      }
    }

    if (!json.data) {
      return 'Query successful.';
    }
  }
}

