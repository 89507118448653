<div class="card card-body">
  <div class="row pb-2">
    <div class="col">
      <h4 class="card-title font-weight-bold">SQL Statements By Type</h4>
    </div>
    <div *ngIf="stmtsByType" class="col text-right">
      <button type="button" class="btn btn-primary btn-sm m-1" [routerLink]="['statements']">Drill down on statements</button>
      <button type="button" class="btn btn-primary btn-sm m-1" [routerLink]="['transactions']">Drill down on transactions</button>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Data is not available. {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div class="row">
    <div class="col-md-12 col-lg-4">
      <div style="height: 400px">
        <canvas height="400" #stmtsByTypeChart> </canvas>
      </div>
    </div>

    <div class="col-md-12 col-lg-8">
      <div class="table-responsive" *ngIf="stmtsByType">
        <app-data-table [data]="stmtsByType" noDataAlertMessage="No statements available." noDataAlertType="warning">
          <app-data-table-column title="SQL Statement" property="stmt_type" [sortable]="true">
            <ng-template appTableCell let-type="item">
              <a [routerLink]="['statements']" [queryParams]="{stmttype: type}">{{type}}</a>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Count" property="count" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Max Run Time" property="max_runtime" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Avg Run Time" property="avg_runtime" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Avg Memory" property="avg_mem" [sortable]="true">
            <ng-template appTableCell let-mem="item">
              {{mem | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Rows Processed" property="rows_processed" [sortable]="true">
          </app-data-table-column>
        </app-data-table>
      </div>
    </div>
  </div>

</div>
