/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

// Convert interval data into day to second interval string
@Pipe({ name: 'intervalDS' })
export class IntervalDSPipe implements PipeTransform {
  transform(interval: any): string {
    if (interval === null || interval === undefined) {
      return interval;
    }
    let seconds = 0;
    if (typeof (interval) === 'number') {
      seconds = interval;
    } else if (interval.IntervalDF && (interval.IntervalDF.seconds || interval.IntervalDF.seconds === 0)) {
      seconds = interval.IntervalDF.seconds;
    } else {
      throw new Error('unexpected data for intervalDF: ' + interval);
    }

    let intervalStr = '';
    if (seconds < 0) {
      intervalStr += '-';
      seconds *= -1;
    }
    const days = Math.floor(seconds / 86400);
    seconds = seconds % 86400;
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    if (days < 10) {
      intervalStr += ' ';
    }
    intervalStr += days;
    intervalStr += ' ';
    if (hours < 10) {
      intervalStr += '0';
    }
    intervalStr += hours;
    intervalStr += ':';
    if (minutes < 10) {
      intervalStr += '0';
    }
    intervalStr += minutes;
    intervalStr += ':';
    if (seconds < 10) {
      intervalStr += '0';
    }
    intervalStr += seconds;

    if (interval.IntervalDF) {
      if (interval.IntervalDF.nanoseconds) {
        intervalStr += ':';
        if (interval.IntervalDF.nanoseconds < 10) {
          intervalStr += '0';
        }
        intervalStr += Math.floor(interval.IntervalDF.nanoseconds / 10000);
      }
    }

    return intervalStr;
  }
}
