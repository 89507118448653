/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../../servers/informixServer';
import { HttpClient } from '@angular/common/http';
import { Parameter } from './parameter-model';

@Injectable()
export class ParameterService {

  constructor(private httpClient: HttpClient) { }

  public getParametersForTask(server: InformixServer, taskId: Number): Observable<Parameter[]> {
    const url = 'informix/' + server.id + '/scheduler/tasks/' + taskId + '/parameters';
    return this.httpClient.get<Parameter[]>(url);
  }

  public updateParametersForTask(server: InformixServer, taskId: Number, params: any): Observable<Parameter[]> {
    const url = 'informix/' + server.id + '/scheduler/tasks/' + taskId + '/parameters';
    return this.httpClient.put<Parameter[]>(url, params);
  }

  public deleteParameterForTask(server: InformixServer, taskId: number, parameterId: number): Observable<Parameter[]> {
    return this.httpClient.delete<Parameter[]>('informix/' + server.id + '/scheduler/tasks/' + taskId + '/parameters/' + parameterId);
  }
}
