/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerOnconfigParameter } from '../configuration/serverOnconfigParameter';
import { ServerChunk } from './serverChunk';
import { ServerSpace } from './serverSpace';
import { ServerStoragePoolEntry } from './serverStoragePoolEntry';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InformixServerStorageService {

  constructor(private httpClient: HttpClient) { }

  public addStoragePoolEntry(serverId: number, path: string, size: number, offset: number,
    chunk_size: number, priority: number): Promise<any> {
    return this.httpClient.post<any>('informix/' + serverId + '/storage/pool', {
      path,
      size,
      offset,
      chunk_size,
      priority
    }).toPromise<any>();
  }

  public createChunk(serverId: number, space: string, createFromPool: boolean, path: string,
    size: string, offset: string): Promise<any> {
    return this.httpClient.post<any>('informix/' + serverId + '/storage/chunks', {
      space_name: space,
      create_from_storage_pool: createFromPool,
      path,
      size,
      offset
    }).toPromise<any>();
  }

  public createSpace(serverId: number, name: string, type: string, path: string, createFromStoragePool: boolean,
    size: string, pageSize: string, offset: string, createSize: number, extendSize: number): Promise<any> {
    return this.httpClient.post<any>('informix/' + serverId + '/storage/spaces/', {
      name,
      type,
      path,
      create_from_storage_pool: createFromStoragePool,
      size,
      page_size: pageSize,
      offset,
      create_size: createSize,
      extend_size: extendSize
    }).toPromise<any>();
  }

  public deleteStoragePoolEntry(serverId: number, entry_id: number): Promise<any> {
    return this.httpClient.delete<any>('informix/' + serverId + '/storage/pool/' + entry_id).toPromise<any>();
  }

  public dropChunk(serverId: number, chunkNum: number, dropToPool: boolean): Promise<any> {
    const url = 'informix/' + serverId + '/storage/chunks/' + chunkNum + '?dropToStoragePool=' + dropToPool;
    return this.httpClient.delete<any>(url).toPromise<any>();
  }

  public dropSpace(serverId: number, name: string, dropToPool: boolean): Promise<any> {
    const url = 'informix/' + serverId + '/storage/spaces/' + name + '?dropToStoragePool=' + dropToPool;
    return this.httpClient.delete<any>(url).toPromise<any>();
  }

  public expandSpace(serverId: number, spaceName: string, expandSize: string): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/storage/spaces/' + spaceName, {
      action: 'expand',
      expand_size: expandSize
    }).toPromise<any>();
  }

  public extendChunk(serverId: number, chunkNum: number, extendSize: string): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/storage/chunks/' + chunkNum, {
      action: 'extend',
      extend_size: extendSize
    }).toPromise<any>();
  }

  public getServerChunks(serverId: number): Observable<ServerChunk[]> {
    return this.httpClient.get<ServerChunk[]>('informix/' + serverId + '/storage/chunks');
  }

  public getServerSpaces(serverId: number): Observable<ServerSpace[]> {
    return this.httpClient.get<ServerSpace[]>('informix/' + serverId + '/storage/spaces');
  }

  public getServerDspaces(serverId: number): Observable<ServerSpace[]> {
    return this.httpClient.get<ServerSpace[]>('informix/' + serverId + '/storage/spaces/dbspaces');
  }

  public getStoragePoolConfiguration(serverId: number): Promise<ServerOnconfigParameter[]> {
    const url = 'informix/' + serverId + '/onconfig?filter=SP_AUTOEXPAND,SP_THRESHOLD,SP_WAITTIME';
    return this.httpClient.get<ServerOnconfigParameter[]>(url).toPromise<ServerOnconfigParameter[]>();
  }

  public getStoragePoolInfo(serverId: number, statusFilter?: string): Observable<ServerStoragePoolEntry[]> {
    let url = 'informix/' + serverId + '/storage/pool';
    if (statusFilter) {
      url += '?status=' + statusFilter;
    }
    return this.httpClient.get<ServerStoragePoolEntry[]>(url);
  }

  public getStorageSummaryInfo(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/storage');
  }

  public getBackupInfo(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/backups');
  }

  public modifyChunk(serverId: number, chunkNum: number, extendable: number): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/storage/chunks/' + chunkNum, {
      action: 'modify',
      extendable
    }).toPromise<any>();
  }

  public modifySpace(serverId: number, name: string, createSize: number, extendSize: number): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/storage/spaces/' + name, {
      action: 'modify',
      create_size: createSize,
      extend_size: extendSize
    }).toPromise<any>();
  }

  public modifyStoragePoolEntry(serverId: number, entry_id: number, size: number, chunk_size: number, priority: number): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/storage/pool/' + entry_id, {
      size,
      chunk_size,
      priority
    }).toPromise<any>();
  }
}
