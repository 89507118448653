/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/

import { InformixServer } from '../servers/informixServer';
import { InformixSensorService } from './informixSensor.service';
import { Sensor } from './sensor';
import { SensorDataSource } from './sensor-data-source';

export interface SensorDataBatchOptions {
  id: string;
  data?: {
    from: number;
    to?: number;
    dsMax?: number;
    primaryKeys?: string;
  };
  meta?: boolean;
}

const unitConverters: { [key: string]: ((v: any) => any) } = {
  percentage: v => v * 100
};

class PromiseWrapper {
  public promise: Promise<any>;
  public resolve: Function;
  public reject: Function;

  constructor() {
    this.promise = new Promise<any>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

class SensorDataBatchPromise {

  private dataPromise: PromiseWrapper = null;
  private metaPromise: PromiseWrapper = null;

  private metricConverters: { [key: string]: ((v: any) => any) } = {};
  private hasConverters = false;

  private fromTimestamp: number = null;
  private toTimestamp: number = null;
  private downsampleMax: number = null;
  private primaryKeys: string[] = null;

  constructor(public sensor: Sensor) {
    if (this.sensor.type.meta.metrics) {
      const metrics = this.sensor.type.meta.metrics;
      for (const id in metrics) {
        if (!metrics[id].unit) {
          continue;
        }
        const converter = unitConverters[metrics[id].unit];
        if (!converter) {
          continue;
        }
        this.metricConverters[id] = converter;
        this.hasConverters = true;
      }
    }
  }

  public getBatchOptions(): SensorDataBatchOptions {
    const options: SensorDataBatchOptions = {
      id: this.sensor.type.id
    };

    if (this.dataPromise) {
      options.data = {
        from: this.fromTimestamp
      };

      if (this.toTimestamp) {
        options.data.to = this.toTimestamp;
      }

      if (this.primaryKeys) {
        options.data.primaryKeys = this.primaryKeys.join(',');
      }

      if (this.downsampleMax) {
        options.data.dsMax = this.downsampleMax;
      }
    }

    if (this.metaPromise) {
      options.meta = true;
    }

    return options;
  }

  public getData(fromTimestamp: number, toTimestamp: number, downsampleMax: number, primaryKeys: string[]): Promise<any[]> {
    if (!this.dataPromise) {
      this.dataPromise = new PromiseWrapper();
      this.fromTimestamp = fromTimestamp;
      this.toTimestamp = toTimestamp;
      this.downsampleMax = downsampleMax;
      this.primaryKeys = primaryKeys ? primaryKeys.slice() : null;
      return this.dataPromise.promise;
    }

    if (this.downsampleMax !== downsampleMax) {
      return null;
    }

    // Refuse to merge if the time slices do not overlap
    if ((toTimestamp !== null && toTimestamp < this.fromTimestamp) || (this.toTimestamp !== null && fromTimestamp > this.toTimestamp)) {
      return null;
    }

    if (fromTimestamp < this.fromTimestamp) {
      this.fromTimestamp = fromTimestamp;
    }

    if (this.toTimestamp !== null && (toTimestamp === null || toTimestamp > this.toTimestamp)) {
      this.toTimestamp = toTimestamp;
    }

    if (this.primaryKeys) {
      if (!primaryKeys) {
        this.primaryKeys = null;
      } else {
        primaryKeys.forEach(key => {
          if (this.primaryKeys.indexOf(key) < 0) {
            this.primaryKeys.push(key);
          }
        });
      }
    }

    return this.dataPromise.promise;
  }

  public getMeta(): Promise<any> {
    if (!this.metaPromise) {
      this.metaPromise = new PromiseWrapper();
    }

    return this.metaPromise.promise;
  }

  private processData(data: any[]): any[] {
    if (data.length < 1) {
      return null;
    }

    const processedData = [];
    let dataPoint = {
      timestamp: data[0].timestamp,
      data: {}
    };

    // Process each data point
    for (let i = 0; i < data.length; i++) {
      if (data[i].timestamp !== dataPoint.timestamp) {
        processedData.push(dataPoint);
        dataPoint = {
          timestamp: data[i].timestamp,
          data: {}
        };
      }

      const pkey = data[i].pkey || '_';
      const metrics = data[i].data;
      if (this.hasConverters) {
        for (const metricId in metrics) {
          if (metrics.hasOwnProperty(metricId)) {
            const converter = this.metricConverters[metricId];
            if (converter) {
              metrics[metricId] = converter(metrics[metricId]);
            }
          }
        }
      }

      dataPoint.data[pkey] = metrics;
    }

    processedData.push(dataPoint);
    return processedData;
  }

  public resolve(result: any) {
    if (result.err) {
      if (this.dataPromise) {
        this.dataPromise.reject(result.err);
      }
      if (this.metaPromise) {
        this.metaPromise.reject(result.err);
      }
      return;
    }

    if (result.data && this.dataPromise) {
      if (result.data.err) {
        this.dataPromise.reject(result.data.err);
      } else {
        this.dataPromise.resolve(this.processData(result.data));
      }
    }

    if (result.meta && this.metaPromise) {
      if (result.meta.err) {
        this.metaPromise.reject(result.meta.err);
      } else {
        this.metaPromise.resolve(result.meta);
      }
    }
  }

  public reject(err: any) {
    if (this.dataPromise) {
      this.dataPromise.reject(err);
    }

    if (this.metaPromise) {
      this.metaPromise.reject(err);
    }
  }
}

export class ServerSensorManager {

  private dataSources: { [key: string]: SensorDataSource } = {};

  private batchDebounce: number = null;
  private batchPromises: SensorDataBatchPromise[] = [];

  constructor(
    private service: InformixSensorService,
    private server: InformixServer
  ) { }

  public getDataSource(sensor: Sensor) {
    let dataSource = this.dataSources[sensor.type.id];
    if (!dataSource) {
      dataSource = new SensorDataSource(this, sensor);
      this.dataSources[sensor.type.id] = dataSource;
    } else {
      dataSource.setSensor(sensor);
    }

    return dataSource;
  }

  public getData(sensor: Sensor, fromTimestamp: number, toTimestamp: number, downsampleMax: number, primaryKeys: string[]): Promise<any> {
    if (this.batchDebounce) {
      window.clearTimeout(this.batchDebounce);
    }
    this.batchDebounce = window.setTimeout(this.getBatch.bind(this), 100);
    for (let i = 0; i < this.batchPromises.length; i++) {
      const batchPromise = this.batchPromises[i];
      if (batchPromise.sensor.type.id === sensor.type.id) {
        const promise = batchPromise.getData(fromTimestamp, toTimestamp, downsampleMax, primaryKeys);
        if (promise) {
          return promise;
        }
      }
    }

    const newBatchPromise = new SensorDataBatchPromise(sensor);
    this.batchPromises.push(newBatchPromise);
    return newBatchPromise.getData(fromTimestamp, toTimestamp, downsampleMax, primaryKeys);
  }

  public getMeta(sensor: Sensor): Promise<any> {
    if (this.batchDebounce) {
      window.clearTimeout(this.batchDebounce);
    }
    this.batchDebounce = window.setTimeout(this.getBatch.bind(this), 100);

    for (let i = 0; i < this.batchPromises.length; i++) {
      const batchPromise = this.batchPromises[i];
      if (batchPromise.sensor.type.id === sensor.type.id) {
        return batchPromise.getMeta();
      }
    }

    const newBatchPromise = new SensorDataBatchPromise(sensor);
    this.batchPromises.push(newBatchPromise);
    return newBatchPromise.getMeta();
  }

  private getBatch() {
    const batchPromises = this.batchPromises;
    this.batchPromises = [];
    const batchOptions = batchPromises.map(promise => promise.getBatchOptions());

    this.service.getSensorDataBatch(this.server, batchOptions).then(results => {
      results.forEach((result, index) => {
        batchPromises[index].resolve(result);
      });
    }).catch(err => {
      batchPromises.forEach(promise => promise.reject(err));
    });
  }
}
