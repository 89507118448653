<div *ngFor="let item of tree; let i = index" [ngClass]="{'json-tree-inline': inline}">
	<div class="json-tree-row">
		<div class="json-tree-expand" *ngIf="!inline">
			<button class="btn btn-sm btn-secondary" *ngIf="item.type === 'object' || item.type === 'array'" (click)="toggleExpand(item)">
				<i class="icon" [ngClass]="item.expanded ? 'icon-minus' : 'icon-plus'"></i>
			</button>
		</div>
		<div class="json-tree-key-value" *ngIf="!inline || i < 4">
			<span class="json-tree-key">{{item.key}}</span>:
			<span *ngIf="!item.expanded || inline" [ngStyle]="{color: item.color}">{{item.value}}</span><span *ngIf="inline && i < tree.length - 1">,&nbsp;</span>
			<span *ngIf="inline && i == 3 && tree.length > 4">...</span>
			<ng-template [ngIf]="item.expanded && !inline">
				{{item.type === 'object' ? '{' : '['}}
				<app-json-tree [tree]="item.tree"> </app-json-tree>
				{{item.type === 'object' ? '}' : ']'}}
			</ng-template>
		</div>
	</div>
</div>
