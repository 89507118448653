/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HighAvailabilityComponent } from './high-availability.component';
import { ClusterTopologyComponent } from './cluster-topology.component';
import { ClusterMetricsComponent } from './cluster-metrics.component';
import { SMXInfoComponent } from './smx-info.component';
import { OnconfigModule } from '../configuration/onconfig.module';
import { SharedModule } from '../../../shared/shared.module';
import { HighAvailabilityService } from './high-availability.service';
import { MonitoringModule } from '../../monitoring/monitoring.module';

@NgModule({
  imports: [
    TooltipModule,
    MonitoringModule,
    SharedModule,
    OnconfigModule
  ],
  declarations: [
    HighAvailabilityComponent,
    ClusterTopologyComponent,
    ClusterMetricsComponent,
    SMXInfoComponent
  ],
  providers: [HighAvailabilityService]
})
export class HighAvailabilityModule { }
