/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/

import { Component, ContentChildren, EventEmitter, forwardRef, Input, Output, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioComponent } from '../radio/radio.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioGroupComponent), multi: true }
  ]
})
export class RadioGroupComponent implements ControlValueAccessor {

  @Input() disabled = false;

  @Output() change = new EventEmitter<any>();

  private value: any = null;
  private _radioComponents: QueryList<RadioComponent>;

  @ContentChildren(RadioComponent, { descendants: true })
  get radioComponents(): QueryList<RadioComponent> {
    return this._radioComponents;
  }

  set radioComponents(radioComponents: QueryList<RadioComponent>) {
    setTimeout(() => {
      this._radioComponents = radioComponents;
      this._radioComponents.forEach(c => c.group = this);
      this.updateRadioComponents();
    }, 0);
  }

  private onChange: any = () => { };

  writeValue(value: any): void {
    this.value = value;
    this.updateRadioComponents();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateRadioComponents() {
    if (!this.radioComponents) {
      return;
    }

    this.radioComponents.forEach(component => {
      component.selected = this.value === component.value;
    });
  }

  updateValue(value: any) {
    this.value = value;
    this.updateRadioComponents();
    this.onChange(value);
    this.change.emit(value);
  }
}
