/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ConstraintInfo, ReferenceKey, ReferenceKeys } from '../create-table.model';
import { InformixSQLSession } from '../../informix-sql-session';
import { SchemaService } from '../../schema.service';
import { IACValidators } from '../../../../../shared/forms/validators';
import { ErrorMessageHandlers } from '../../../../../shared/forms/extendedFormControl.directive';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-foreign-key-modal',
  templateUrl: './foreign-key-modal.component.html'
})
export class ForeignKeyModalComponent implements OnInit {

  @ViewChild('addForeignKeyModal') addForeignKeyModal: ModalDirective;
  @Input() foreignKeys;
  @Output() foreignKeyObj = new EventEmitter<any>();
  session;

  referenceKeys: ReferenceKey[] = [];

  isEdit = false;
  selectedConstraint: ConstraintInfo;
  columns: { name: any; type: any }[] = [];

  tableColumnList: { name: any; type: any }[] = [];

  foreignKeyName: UntypedFormControl;
  isDisableIndex: UntypedFormControl;
  tableName: string;
  referenceKey = {};
  keyDetails;
  isDisable = true;
  isDuplicateTableCol = false;
  isLoading = false;
  currentConstraint: ConstraintInfo;
  keyNameErrorHandlers: ErrorMessageHandlers = {
    duplicateName: error => 'Key name already exists.',
    whitespace: error => 'Input field cannot be empty'
  };
  constructor(
    private schemaService: SchemaService, private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.foreignKeyName = new UntypedFormControl(null, [Validators.required, IACValidators.noWhitespaceValidator(),
    this.duplicateKeyNameValidator()]);
    this.isDisableIndex = new UntypedFormControl(false);
    this.referenceKeys = [];
  }

  openModal(session: InformixSQLSession, owner: string, columnList, keyDetails?: { row: any; index: number }) {
    this.session = session;
    this.tableColumnList = columnList;
    this.isLoading = true;
    this.schemaService.getReferenceKeys(this.session).subscribe((res: ReferenceKeys) => {
      this.isLoading = false;
      this.referenceKeys = res.tabInfo ? res.tabInfo : [];
      if (keyDetails && keyDetails.row) {
        this.keyDetails = keyDetails;
        this.isEdit = true;
        this.foreignKeyName.setValue(keyDetails.row.foreignKeyName);
        this.isDisableIndex.setValue(keyDetails.row.disableIndex);
        this.tableName = keyDetails.row.referencedTableName;
        this.referenceKeys.filter(key => key.tableName === this.tableName)[0].isExpand = true;
        const constraints = this.referenceKeys.filter(key => key.tableName === this.tableName)[0].constInfo;
        this.referenceKey = keyDetails.row.foreignKeyReferences;
        constraints.forEach(constraint => {
          if (JSON.stringify(Object.keys(this.referenceKey)) === JSON.stringify(Object.keys(constraint.columnName))) {
            this.onConstraintSelect(this.tableName, constraint);
          }
        });
        setTimeout(() => {
          this.referenceKeys = this.referenceKeys.slice();
        }, 0);
      } else {
        this.isEdit = false;
      }
    }, err => {
      this.isLoading = false;
      this.notificationsService.pushErrorNotification(err);
    });
    this.addForeignKeyModal.show();
  }

  tableExpanded(row: ReferenceKey): boolean {
    return row.isExpand;
  }

  onConstraintSelect(row: any, constraint: ConstraintInfo) {
    this.isDuplicateTableCol = false;
    this.columns = [];
    this.tableName = row.tableOwner + '.' + row.tableName;
    if (!this.isEdit) {
      this.referenceKey = {};
    }
    this.currentConstraint = constraint;
    Object.keys(constraint.columnName).forEach(key => {
      const col: { name: any; type: any } = { name: key, type: constraint.columnName[key] };
      if (!this.isEdit) {
        this.referenceKey[col.name] = null;
      }
      this.columns.push(col);
    });
    setTimeout(() => {
      this.columns = this.columns.slice();
    }, 0);
  }

  checkIfTypeExist(type) {
    return this.tableColumnList.some(col => col.type === type);
  }

  setColumn(referencedColumn, event) {
    this.referenceKey[referencedColumn] = event.target.value;
    const values = Object.values(this.referenceKey);
    this.isDuplicateTableCol = values.filter((item, index) => values.indexOf(item) !== index).length > 0;
    if (values.indexOf(null) > -1 || this.isDuplicateTableCol) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }

  addKey() {
    const obj = {};
    if (this.isEdit) {
      obj['id'] = this.keyDetails.index;
    }
    obj['foreignKeyName'] = this.foreignKeyName.value.trim();
    obj['disableIndex'] = this.isDisableIndex.value;
    obj['referencedTableName'] = this.tableName;
    obj['foreignKeyReferences'] = this.referenceKey;
    this.foreignKeyObj.emit(obj);
    this.foreignKeyName.setValue(null);
    this.isDisableIndex.setValue(false);
    this.referenceKey = [];
    this.columns = [];
    this.tableName = '';
    this.referenceKeys.map(key => key.isExpand = false);
    if (this.isEdit) {
      this.addForeignKeyModal.hide();
    }
  }

  closeModal() {
    this.addForeignKeyModal.hide();
  }

  duplicateKeyNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.foreignKeys.length > 0 && this.foreignKeys.map(key => key.foreignKeyName).indexOf(control.value) > -1) {
        if (this.isEdit && control.value === this.keyDetails.row.foreignKeyName) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

}
