<div *ngIf="clusterSMXInfo?.primaryNotPresent" class="mt-4 mb-0 alert alert-warning">
  <i class="icon icon-warning text-warning"></i> {{'dataMissing' | translate}} {{clusterSMXInfo.primaryNotPresent}} <a
    [routerLink]="['/dashboard/groups', server.parentGroupId, 'add-server']"> {{'link.clickHere' | translate}}</a>
  {{'toAdd' | translate}}
</div>
<div *ngIf="clusterSMXInfo?.exceptionMessage" class="mt-4 mb-0 alert alert-warning">
  <i class="icon icon-warning text-warning"></i> {{'dataMissing' | translate}} {{clusterSMXInfo.exceptionMessage}}
</div>
<app-data-table *ngIf="clusterSMXInfo?.smxInfoList else smxInfoLoading" [data]="clusterSMXInfo.smxInfoList"
  [rowsPerPage]="10" [searchLabel]="'dataTable.searchLabel' | translate">
  <app-data-table-column [title]="'dataTable.columnTitle.name' | translate" [sortable]="true" [searchable]="true"
    property="name">
  </app-data-table-column>
  <app-data-table-column [title]="'dataTable.columnTitle.type' | translate" property="type"
    [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.numberOfNetworkPipes' | translate"
    property="numberOfNetworkPipes" [sortable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.replicationOnly' | translate"
    [sortable]="true" property="replicationOnly">
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.encryptionStatus' | translate"
    [sortable]="true" property="encryptionStatus">
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.compressionStatus' | translate"
    property="compressionStatus" [sortable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.bytesSent' | translate"
    property="bytesSent" [sortable]="true">
    <ng-template appTableCell let-bytesSent="item">
      {{bytesSent | byte}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.bytesReceived' | translate"
    property="bytesReceived" [sortable]="true">
    <ng-template appTableCell let-bytesReceived="item">
      {{bytesReceived | byte}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.smxInfo.table.columnTitle.retriesForWriteCall' | translate"
    property="retriesForWriteCall" [sortable]="true">
  </app-data-table-column>
</app-data-table>
<ng-template #smxInfoLoading>
  <div *ngIf="dataLoading else serverErrorTmpl" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
  </div>
  <ng-template #serverErrorTmpl>
    <div *ngIf="serverNotInCluster" class="mt-4 alert alert-warning">
      <i class="icon icon-warning text-warning"></i> {{serverNotInCluster}}
    </div>
    <div *ngIf="serverError" class="mt-2 alert alert-danger">
      <i class="icon icon-danger text-danger"></i> Unable to retrieve smx information. <br> Error: {{serverError}}
    </div>
  </ng-template>
</ng-template>
