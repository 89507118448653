/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022, 2023. All Rights Reserved.
 *******************************************************************************/
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../../servers/informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ServerBreadcrumb } from '../../servers/serverBreadcrumb';
import { SystemReportsService } from './system-reports.service';
import { TranslateService } from '@ngx-translate/core';

export abstract class AbstractSystemReport {

  server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;
  reportData = null;
  reportsData = null;
  actualReportData = null;

  constructor(
    protected systemReportsService: SystemReportsService,
    protected notificationsService: NotificationsService,
    protected translate: TranslateService
  ) {
  }

  setBreadcrumb(reportName: string) {
    this.translate.get('systemReports.pageTitle').subscribe((text: string) => {
      const systemReportsBreadcrumb: BreadcrumbElement = { name: text, link: 'system-reports' };
      const breadcrumbs: BreadcrumbElement[] = [systemReportsBreadcrumb];
      const reportBreadcrumb: BreadcrumbElement = { name: reportName };
      breadcrumbs.push(reportBreadcrumb);
      this.breadcrumb = ServerBreadcrumb.build(this.server, breadcrumbs);
    });
  }

  getSystemReport(id: string, isCatalog?: Boolean) {
    this.reportData = null;
    this.reportsData = null;
    this.systemReportsService.runSystemReport(this.server, id).subscribe((res: any) => {
      this.actualReportData = this.reportData = res;
      this.reportsData = this.reportData;
      if(!isCatalog && id === 'table_activity' && this.reportData) {
        this.reportData = this.systemReportsService.updateReport(isCatalog, this.reportsData);
      }
      if (id === 'lock_per_session') {
        this.reportData = this.reportData.filter(item => item['userDisplay'] = `${item.user} @ ${item.hostname}`);
      }
      if (id === 'waiting_session') {
        this.reportData = this.reportData.filter(item => {
          item['ownerUserDisplay'] = `${item.owner_user} (${item.owner_sid})`;
          item['waitUserDisplay'] = `${item.wait_user} (${item.wait_sid})`;
          return item;
        });
      }
      if (id === 'lock_with_waiter') {
        this.reportData = this.reportData.filter(item => {
          item['ownerUserDisplay'] = `${item.owner_user} @ ${item.owner_host}`;
          item['waitUserDisplay'] = `${item.wait_user} @ ${item.wait_host}`;
          return item;
        });
      }
    }, err => {
      this.reportsData = [];
      this.reportData = [];
      this.notificationsService.pushErrorNotification(this.notificationsService.sanitizeMessage(err.error ? err.error.err : err));
    });
  }

  getSystemDatabaseReport(id: string, db: any, isCatalog?: Boolean) {
    this.reportData = null;
    this.systemReportsService.runSystemReport(this.server, id, db).subscribe((res: any) => {
      this.actualReportData = this.reportData = res;
      this.reportsData = this.reportData;
      // exclude system catalogs from system-reports
      if(!isCatalog && id === 'table_buffer_pool_activity' && this.reportData) {
        this.reportData = this.systemReportsService.updateReport(isCatalog, this.reportsData, true);
      }
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }
}
