/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DatabasePrivilegesComponent } from './database-privileges.component';
import { PrivilegesService } from './privileges.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PrivilegesComponent } from './privileges.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TablePrivilegesComponent } from './table-privileges.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SqlAdminPrivilegesComponent } from './sql-admin-privileges.component';
import { InternalUsersPrivilegesComponent } from './internal-users-privileges.component';
import { InformixServerOnconfigService } from '../configuration/informixServerOnconfig.service';

@NgModule({
  imports: [
    SharedModule,
    TooltipModule,
    PopoverModule,
    ReactiveFormsModule
  ],
  declarations: [
    PrivilegesComponent,
    DatabasePrivilegesComponent,
    TablePrivilegesComponent,
    SqlAdminPrivilegesComponent,
    InternalUsersPrivilegesComponent
  ],
  providers: [
    PrivilegesService,
    InformixServerOnconfigService
  ]
})
export class PrivilegesModule { }
