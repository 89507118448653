/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Chart from 'chart.js';
import { ChartJSUtils } from '../../../shared/chartjs.utils';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { InformixServer } from '../informixServer';
import { AutoUpdateStatisticsService } from './autoUpdateStatistics.service';
import { GeneralInfo } from './generalInfo.model';

@Component({
  selector: 'app-general-auto-update-statistics',
  templateUrl: 'general-autoUpdateStatistics.html',
  styleUrls: ['./autoUpdateStatistics.component.scss'],
})
export class GeneralComponent implements OnInit {

  @Input() server: InformixServer = null;
  tableData: GeneralInfo[] = [];
  graphData: any;
  pieChartLabels = [];
  pieData = {
    tables_missing_stats: '',
    large_tables_needing_refresh: '',
    small_tables_needing_refresh: '',
    tables_completed: ''
  };
  pieLabels = {
    tables_missing_stats: 'Tables Missing Statistics',
    large_tables_needing_refresh: 'Large Tables Needing Statistics Refreshed',
    small_tables_needing_refresh: 'Small Tables Needing Statistics Refreshed',
    tables_completed: 'Table With Refreshed Statistics'
  };
  ausEnabled = true;
  next_execution: number;
  last_evaluation: number;

  @ViewChild('statsSummary', { static: true }) statsSummaryCanvas: ElementRef;
  private statsSummary: any;

  private chartData: Number[];
  private chartLabels: string[];
  private chartColors: string[];
  private chartLabelColorMap: { [key: string]: string } = {
    tables_missing_stats: ChartJSUtils.getDefaultColor(12),
    large_tables_needing_refresh: ChartJSUtils.getDefaultColor(5),
    small_tables_needing_refresh: ChartJSUtils.getDefaultColor(4),
    tables_completed: ChartJSUtils.getDefaultColor(2)
  };
  ausApiError: string;

  constructor(
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private autoUpdateStatisticsService: AutoUpdateStatisticsService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getAllStatistics();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.tableData = null;
  }

  getAllStatistics() {
    this.autoUpdateStatisticsService.getAllStatistics(this.server, true).subscribe((res: GeneralInfo[]) => {
      this.statisticsData(res);
    }, err => {
      if(err.error && err.error.err) {
        this.ausApiError = err.error.err;
      }
    });
  }

  runExecuteEvaluationTask() {
    this.autoUpdateStatisticsService.executeEvaluationTask(this.server).subscribe(() => {
      this.notificationsService.pushSuccessNotification('The evaluation job has been submitted.');
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
    this.getAllStatistics();
  }

  runExecuteCleanUp() {
    this.autoUpdateStatisticsService.executeCleanUp(this.server).subscribe(() => {
      this.notificationsService.pushSuccessNotification('Clean up completed.');
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
    this.getAllStatistics();
  }

  private statisticsData(data) {
    if (data.length < 1) {
      return;
    }
    this.tableData = data.statistics_by_database;
    this.ausEnabled = data.enabled;
    this.next_execution = data.next_execution ? data.next_execution.$date : null;
    this.last_evaluation = data.last_evaluation ? data.last_evaluation.$date : null;
    this.graphData = data.statistics_total_summary;
    this.graphDataFn(this.graphData);
  }

  private graphDataFn(data) {
    this.chartLabels = [];
    this.chartData = [];
    this.chartColors = [];

    const keys = Object.keys(data);
    keys.forEach(key => {
      this.chartLabels.push(key);
      this.chartData.push(data[key]);
      this.chartColors.push(this.getColor(key));
    });
    const pieDataKey = Object.keys(this.pieData);
    pieDataKey.forEach(key => {
      this.chartLabels.forEach(label => {
        if (key === label) {
          this.pieData[key] = data[key];
        }
      });
    });
    const pieLabelKey = Object.keys(this.pieLabels);
    pieLabelKey.forEach(key => {
      this.chartLabels.forEach(label => {
        if (key === label) {
          this.pieChartLabels.push(this.pieLabels[key]);
        }
      });
    });
    if (!this.statsSummary) {
      this.createChart();
    }

    if(this.statsSummary) {
      this.statsSummary.data.datasets[0].data = this.chartData;
      this.statsSummary.data.datasets[0].backgroundColor = this.chartColors;
      this.statsSummary.data.labels = this.pieChartLabels;
      this.statsSummary.update();
    }
  }

  private getColor(statsData: string): string {
    let color: string = this.chartLabelColorMap[statsData];
    if (!color) {
      color = ChartJSUtils.getRandomColor();
      this.chartLabelColorMap[statsData] = color;
    }
    return color;
  }

  private createChart() {
    if(this.statsSummaryCanvas) {
      this.statsSummary = new Chart(this.statsSummaryCanvas.nativeElement, {
        type: 'pie',
        data: {
          labels: this.chartLabels,
          datasets: [
            {
              data: [], // this.chartData,
              backgroundColor: [] // this.chartColors
            }
          ]
        },
        options: {
          animation: {
            duration: 0
          },
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index'
          },
          legend: {
            display: false,
          }
        }
      });
    }
  }
}
