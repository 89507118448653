/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/

import { InformixServer } from '../informixServer';
import { ProfileStatus, SqlhostsGroupInternal } from './enterprise-replication-types';

interface ERNodeServerState {
  name: string;
  color: string;
}

type ERNodeServerStatus = ERNodeServerState;

const serverStateMap: { [key: string]: ERNodeServerState } = {
  A: { name: 'Active', color: 'green-30' },
  D: { name: 'Deleted', color: 'red' },
  S: { name: 'Suspended', color: 'red' },
  Q: { name: 'Quiescent', color: 'yellow-30' },
  U: { name: 'Disabled', color: 'red' }
};

const serverStatusMap: { [key: string]: ERNodeServerStatus } = {
  C: { name: 'Connected', color: 'green-30' },
  X: { name: 'Disconnect', color: 'red' },
  L: { name: 'Local', color: 'red' },
  D: { name: 'Disconnected (will be retried)', color: 'red' },
  E: { name: 'Error during connection', color: 'red' },
  F: { name: 'Failed', color: 'red' },
  K: { name: 'Connecting', color: 'red' },
  // L: { name: 'The connection is to the local server', color: 'red' },
  R: { name: 'Reconnecting', color: 'red' },
  T: { name: 'Idle Timeout', color: 'red' },
  // X: { name: 'Connection closed by user command and unavailable until reset by user', color: 'red' },

};

export class ERNodeMember {
  dbServerName: string;

  constructor(json: any) {
    this.dbServerName = json.dbServerName;
  }
}

export class ERNode {
  id: number;
  rootId: number;
  name: string;
  isHub: boolean;
  isLeaf: boolean;
  serverState: ERNodeServerState;
  serverStatus: ERNodeServerStatus;
  members: ERNodeMember[];

  domain: ERDomain = null;
  parent: ERNode = null;
  children: ERNode[] = [];
  siblings: ERNode[] = [];

  memberNames: string;
  group: SqlhostsGroupInternal;

  get typeName(): string {
    if (!this.parent) {
      if (this.domain && this.domain.origin && this.domain.origin.isLeaf) {
        return 'Parent';
      } else {
        return 'Root';
      }
    } else {
      return this.isLeaf ? 'Leaf' : 'Nonroot';
    }
  }

  profile: any = {};

  diskAvailable: number;

  constructor(json: any) {
    this.id = json.id;
    this.rootId = json.rootId;
    this.name = json.name;
    this.isHub = json.isHub;
    this.isLeaf = json.isLeaf;
    this.serverState = json.state;
    this.members = json.members.map(v => new ERNodeMember(v));

    this.serverState = serverStateMap[json.state] || { name: 'Unknown', color: 'light-gray-50' };
    this.serverStatus = serverStatusMap[json.status] || { name: 'Unknown', color: 'light-gray-50' };
    this.memberNames = this.members.map(member => member.dbServerName).join(', ');
    this.group = json.group;
    this.setProfileStatus(ProfileStatus.LOADING);
  }

  setParent(parent: ERNode) {
    this.parent = parent;
    if (this.parent) {
      this.parent.children.push(this);
    }
  }

  addProfile(json: any) {
    this.profile = {
      version: (json.version)?json.version:null,
      ddr: {
        state: (json.ddr)?json.ddr.state:null,
        current: (json.ddr)?json.ddr.current:null,
        replay: (json.ddr)?json.ddr.replay:null,
        snoopy: (json.ddr)?json.ddr.snoopy:null,
        pagesUntilBlock: (json.ddr)?json.ddr.pagesFromDDRBLOCK:null,
      },
      sendQ: {
        acksPending: (json.sendQ)?json.sendQ.acksPending:null,
        txnInQueue:(json.sendQ)?json.sendQ.txnInQueue:null,
        txnSpooled:(json.sendQ)?json.sendQ.txnSpooled:null
      },
      disk: {
        total: (json.disk)?json.disk.total:null,
        metadataFree:(json.disk)?json.disk.metadataFree:null,
        userdataFree:(json.disk)?json.disk.userdataFree:null
      },
      receiveQ: {
        txnInPendingList: (json.receiveQ)?json.receiveQ.txnInPendingList:null,
        txnInQueue:(json.receiveQ)?json.receiveQ.txnInQueue:null,
      },
      network: {
        state: (json.network)?json.network.state:null,
        numberOfConnections: (json.network)?json.network.numberOfConnections:null,
        expectedConnections: (json.network)?json.network.expectedConnections:null,
        msgSent: (json.network)?json.network.msgSent:null,
        msgReceived: (json.network)?json.network.msgReceived:null,
        throughput: (json.network)?json.network.throughput:null,
        pendingMsg: (json.network)?json.network.pendingMsg:null,
      },
      apply: {
        state: (json.apply)?json.apply.state:null,
        txnProcessed: (json.apply)?json.apply.txnProcessed:null,
        commitRate: (json.apply)?json.apply.commitRate:null,
        avgActiveApply: (json.apply)?json.apply.avgActiveApply:null,
        failRate: (json.apply)?json.apply.failRate:null,
        totalFailures: (json.apply)?json.apply.totalFailures:null,
        avgLatency: (json.apply)?json.apply.avgLatency:null,
        maxLatency: (json.apply)?json.apply.maxLatency:null,
        atsFileCount: (json.apply)?json.apply.atsFileCount:null,
        risFileCount: (json.apply)?json.apply.risFileCount:null,
      },
      ableToConnect: json.ableToConnect
    };

    if(json.ableToConnect === 'N') {
      this.setProfileStatus(ProfileStatus.UNABLE_TO_CONNET);
    }else{
      this.setProfileStatus(ProfileStatus.AVAILALBE);
    }
  }

  setSiblings(nodes: ERNode[]) {
    this.siblings = nodes;
  }

  setProfileStatus(status: ProfileStatus, error?: string) {
    this.profile.status = status;
    this.profile.error = error;
  }
}

export class ERDomain {
  origin: ERNode;
  nodes: ERNode[];
  jsonNodes = null;
  isDraft: Boolean = false;
  constructor(public server: InformixServer, json: any, isDraft?: Boolean) {
    const nodeMap = new Map<number, ERNode>();
    const nodeParentIds = [];
    this.nodes = [];
    this.jsonNodes = [...json.nodes];
    json.nodes.forEach((nodeJson: any) => {
      const node = new ERNode(nodeJson);
      node.domain = this;
      this.nodes.push(node);
      nodeParentIds.push(nodeJson.rootId);
      nodeMap.set(node.id, node);

      if (node.name === json.name) {
        this.origin = node;
      }
    });
    this.nodes.forEach((node, i) => {
      node.setParent(nodeMap.get(nodeParentIds[i]) || null);
      node.setSiblings(this.getSiblings(node));
    });
    this.isDraft = isDraft;
  }

  addProfile(json: any[], error?: string) {
    const nodeMap = new Map<string, ERNode>();
    this.nodes.forEach(node => {
      nodeMap.set(node.name, node);
      if(error) {
        /*  set profile error message*/
        node.setProfileStatus(ProfileStatus.ERROR, error);
      }else {
        /* Initially set profile status as not available */
        node.setProfileStatus(ProfileStatus.NOT_AVAILABLE);
      }
    });

    if(json && Array.isArray(json)) {
      json.forEach(profileJson => {
        const node = nodeMap.get(profileJson.name);
        if (node) {
          node.addProfile(profileJson);
        }
      });
    }
  }

  getProfileUrl() {
    return this.server.getERUrl() + '/domain/profile';
  }

  getSiblings(nodeJson) {
    return this.nodes.filter(node => node.rootId === nodeJson.rootId && node.id !== nodeJson.id);
  }
}
