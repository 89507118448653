/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023. All Rights Reserved.
 *******************************************************************************/

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SqlhostsServer, SqlhostsGroupValidationStatus } from '../../../enterprise-replication-types';

@Component({
    selector: 'app-er-group-list-sidebar',
    templateUrl: './er-group-list-sidebar.component.html',
    styleUrls: ['../er-sidebars.component.scss']
})
export class ERDomainLeftPanelComponent {

    @Input() groups: SqlhostsServer[] = [];
    @Input() show: Boolean;
    @Input() selectedGroup: SqlhostsServer = null;

    @Output() groupSelected = new EventEmitter<SqlhostsServer>();
    @Output() sqlHostsModalShow = new EventEmitter<Boolean>(false);
    @Output() groupDeleted = new EventEmitter<SqlhostsServer>();

    sqlhostsGroupValidationStatus = SqlhostsGroupValidationStatus;

    constructor() { }

    /**
     * Selects the currently selected group and emits it to parent er-domain component.
     *
     * @param index
     * @param group
     */
    selectGroup(group: SqlhostsServer) {
        this.selectedGroup = group;
        this.groupSelected.emit(this.selectedGroup);
    }

    /**
     * Showing sqlhosts modal to add server in the list
     *
     * @param event
     */
    showSqlHostsModal(event: Event) {
        event.stopPropagation();
        this.sqlHostsModalShow.emit(true);
    }

    /**
     * Emiting delete event to delete server from the list
     *
     * @param group
     * @param index
     */
    deleteGroup(group, index) {
        this.groupDeleted.emit(group);
    }

    /**
     * Method to select next group in the list
     *
     * @param index
     */
    selectNextGroup(index?: number) {
        let nextGroupIndex = (index !== undefined?index:
                                this.groups.findIndex(group => group.groupName === this.selectedGroup.groupName)) + 1;
        if(nextGroupIndex >= this.groups.length) {
            nextGroupIndex = 0;
        }
        const nextGroupSelection = this.groups[nextGroupIndex];
        if (nextGroupSelection) {
            if(nextGroupSelection.validation && nextGroupSelection.validation.status === SqlhostsGroupValidationStatus.SUCCESS){
                this.selectGroup(nextGroupSelection);
            }else{
                this.selectNextGroup(nextGroupIndex);
            }
        }
    }
}
