<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    {{ 'schemaManager.dbDatablade' | translate }}
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbDatablades; else tableDataLoading" [data]="dbDatablades" [rowsPerPage]="20"
      [searchLabel]="'schemaManager.dbDatabladeTab.searchLabel' | translate" sortBy="bld_id"
      [noDataAlertMessage]="'schemaManager.dbDatabladeTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'schemaManager.dbDatabladeTab.dataBladeModule' | translate" property="bld_id"
        [sortable]="true" [searchable]="true">
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>