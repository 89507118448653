<ng-container *ngIf="session && (filteredTables || loadError); else tablesLoadingTemplate">
  <ng-container *ngIf="!loadError">
    <div class="border-gray-20 d-flex align-items-center px-1 table-operations">
      <div class="border-gray-20 flex-1 py-2 px-1 inner-addon left-addon">
        <i class="icon icon-search"></i>
        <input type="text" class="form-control" placeholder="Search..." [formControl]="searchFormControl">
      </div>
      <div class="p-1">
        <button class="btn btn-icon" [popover]="filterTemplate" container="body" placement="bottom"
          [outsideClick]="true" containerClass="popover-color">
          <i class="icon icon-filter"></i>
        </button>
        <ng-template #filterTemplate>
          <div [formGroup]="filtersFormGroup">
            <app-checkbox *ngFor="let filter of tableFilters" class="d-block" [formControlName]="filter.id">
              {{filter.name}}
              <ng-container *ngIf="filter.count > 0">({{filter.count}})</ng-container>
            </app-checkbox>
          </div>
        </ng-template>
        <button class="btn btn-sm btn-icon" (click)="loadTables()">
          <i class="icon icon-refresh"></i>
        </button>
      </div>
    </div>
    <div class="list-group list-group-flush flex-1 overflow-auto">
      <button type="button" class="list-group-item list-group-item-action table-list"
        [ngClass]="{'table-active': table === selectedTable}" *ngFor="let table of filteredTables"
        (click)="selectTable(table)">
        <div class="row" tooltip="{{table.typeName}} : {{table.name}}" placement="bottom">
          <div class="col-8 text-left align-items-center d-flex">
            <span class="text-truncate"> {{table.name}}</span>
          </div>
          <div class="col-4 text-right" *ngIf="session?.database?.server?.permissions?.sql">
            <button type="button" class="btn btn-sm btn-icon" *ngIf="!showDropdown"
              (mouseenter)="this.showDropdown = true">
              <i class="icon icon-menu-overflow-horizontal"></i>
            </button>
            <span dropdown container="body" placement="bottom" *ngIf="showDropdown"
              (isOpenChange)="dropdownStateChange($event)" (mouseleave)="onClosingDropdown()">
              <button type="button" class="btn btn-sm btn-icon" dropdownToggle>
                <i class="icon icon-menu-overflow-horizontal"></i>
              </button>
              <div *dropdownMenu class="dropdown-menu rounded-0 shadow">
                <button *ngIf="table.type!=='E' && table.type!=='V'" class="dropdown-item" type="button"
                  (click)="openCreateIndex(table)">
                  {{ 'schemaManager.createIndex.pageTitle' | translate }}
                </button>
                <button *ngIf="!table.isSystemTable" class="dropdown-item" type="button" (click)="dropTable(table.name)">
                  {{'schemaManager.dropTable' | translate}}
                </button>
              </div>
            </span>
          </div>
        </div>
      </button>
    </div>
  </ng-container>
  <div class="alert alert-danger m-1" *ngIf="loadError">
    {{loadError}}
  </div>
</ng-container>
<ng-template #tablesLoadingTemplate>
  <div class="mt-2 text-center">
    <i class="icon icon-circle-notch icon-spin icon-2x"></i>
  </div>
</ng-template>
