/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  message: string;
  callback: boolean | Function;
  closeCallback: Function;
  showOk = false;

  constructor(private modalRef: BsModalRef) {
  }

  ngOnInit() {
    if (typeof this.callback === 'boolean') {
      this.showOk = true;
    }
  }

  confirm() {
    if (typeof this.callback === 'function') {
      this.callback();
    }
    this.modalRef.hide();
  }

  close() {
    if (typeof this.closeCallback === 'function') {
      this.closeCallback();
    }
    this.modalRef.hide();
  }
}
