<app-hdr-banner-component *ngIf="server" [serverType]="server.serverType"></app-hdr-banner-component>
<ng-template [ngIf]="group && breadcrumb">
  <app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
  <div class="container-fluid page-container">
    <ng-container *ngIf="dashboards">
      <div class="row mb-1">
        <div class="col">
          <h4>Available Dashboards</h4>
        </div>
        <div class="col col-auto">
          <span class="font-weight-bold color-light-gray-70 pr-2"
            [hidden]="!(selectedDashboards.length > 0)">{{selectedDashboards.length}} of
            {{dashboardTableItems.length}} dashboard(s) selected</span>
          <app-check-permissions [permissions]="group.permissions.admin">
            <button class="btn btn-sm btn-icon" [disabled]="selectedDashboards.length === 0"
              (click)="removeSelectedDashboards()" tooltip="Remove Selected" appPermissionDisable>
              <i class="icon icon-trash"></i>
            </button>
          </app-check-permissions>
          <app-check-permissions [permissions]="group.permissions.admin">
            <a [href]="downloadJsonHref" download="dashboards.json" (click)="exportJson()"
              class="btn btn-sm text-primary border border-primary" appPermissionDisable
              [disabled]="selectedDashboards.length === 0">
              Export
            </a>
          </app-check-permissions>
          <app-check-permissions [permissions]="group.permissions.admin">
            <label for="fileUpload" class="btn btn-sm btn-secondary" appPermissionDisable>Import</label>
            <input #file type="file" id="fileUpload" class="d-none" (change)="importJSON(file)" accept=".json" />
          </app-check-permissions>
          <app-check-permissions [permissions]="group.permissions.admin">
            <a routerLink="new" class="btn btn-sm btn-primary" appPermissionDisable>
              <i class="icon icon-plus"></i> Dashboard
            </a>
          </app-check-permissions>
        </div>
      </div>

      <app-data-table [data]="dashboardTableItems" sortBy="name" noDataAlertMessage="No dashboards available"
        [enableSelect]="true" (updateSelectedRows)="getSelectedRows($event)">
        <app-data-table-column title="Name" property="name" [sortable]="true">
          <ng-template appTableCell let-row="row">
            <a [routerLink]="[row.dashboard.id]" class="mr-1 position-relative">
              {{row.name}}
            </a> <span class="mt-n2 badge-pill badge-primary"
              *ngIf="row.dashboard.config.new === true">New</span>
            <ng-template [ngIf]="row.isInherited">
              (
              <a [routerLink]="['/dashboard/groups', row.dashboard.group.id, 'dashboards']">
                {{row.dashboard.group.name}}
              </a>
              )
            </ng-template>
          </ng-template>
        </app-data-table-column>
        <app-data-table-column class="text-right">
          <ng-template appTableCell let-dashboard="row.dashboard">
            <app-check-permissions [permissions]="dashboard.group.permissions.admin">
              <button class="btn btn-sm btn-icon" (click)="removeDashboard(dashboard)" tooltip="Remove"
                appPermissionDisable>
                <i class="icon icon-trash"></i>
              </button>
            </app-check-permissions>
          </ng-template>
        </app-data-table-column>
      </app-data-table>
    </ng-container>
  </div>
</ng-template>
