<div class="container-fluid page-container">
  <h4>
    <ng-container *ngIf="isEditMode; else createTitle">
      <strong>{{'systemSettings.customReports.editReport' | translate}}</strong>
    </ng-container>
    <ng-template #createTitle>
      <strong>{{'systemSettings.customReports.createReport' | translate}}</strong>
    </ng-template>
  </h4>

  <button type="button" class="btn btn-primary mt-2" (click)="createReport()"
    [disabled]="isPreviewDisabled() || createReportSub">
    {{'systemSettings.customReports.saveReport' | translate}}
  </button>
  <button *ngIf="isEditMode" type="button" class="btn btn-secondary mt-2 ml-2" routerLink="..">
    {{'button.cancel' | translate}}
  </button>

  <tabset>
    <tab [heading]="'common.sql' | translate">
      <div class="mt-2">

        <p>
          {{'systemSettings.customReports.customReportDescription' | translate}}
        </p>

        <div class="row align-items-center">
          <div class="col-sm-4 col-md-2 col-xl-1 col-form-label">
            {{'server.selectedServer' | translate}}
          </div>
          <div class="col-auto">
            {{server ? server.alias : 'common.none' | translate}}
            <button type="button" class="btn btn-secondary" (click)="showSelectServerModal()">
              {{'systemSettings.select___' | translate}}
            </button>
          </div>
        </div>
        <div class="row align-items-center" *ngIf="server">
          <div class="col-sm-4 col-md-2 col-xl-1 col-form-label">
            {{'database.selectedDatabase' | translate}}
          </div>
          <div class="col-auto">
            <ng-container *ngIf="databases; else loadingDatabases">
              <select class="form-control" *ngIf="databases.length; else noDatabaseAvailable"
                [(ngModel)]="selectedDatabase" (ngModelChange)="selectDatabase($event)">
                <option *ngFor="let database of databases">{{database}}</option>
              </select>
              <ng-template #noDatabaseAvailable>
                {{'database.noDatabaseAvailable' | translate}}
              </ng-template>
            </ng-container>
            <ng-template #loadingDatabases>
              <div class="alert alert-danger mt-2" *ngIf="databasesLoadError; else loadingSpinner">
                {{databasesLoadError}}
              </div>
              <ng-template #loadingSpinner>
                <i class="icon icon-circle-notch icon-spin"></i>
              </ng-template>
            </ng-template>
          </div>
        </div>

        <textarea class="form-control text-monospace" rows="5" [formControl]="queryFormControl" autocomplete="off"
          autocorrect="off" autocapitalize="off" spellcheck="false" (keydown)="onQueryKeyDown($event)">
        </textarea>

        <button type="button" class="btn btn-primary mt-2" (click)="onRunClick()" [disabled]="!session || !!querySub">
          {{'common.run' | translate}}
        </button>

        <div class="mt-2 text-center" *ngIf="sessionSub || querySub">
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </div>

        <div class="mt-2" *ngIf="queryResult">

          <button class="btn btn-primary" (click)="goToNextTab()">
            {{ 'button.next' | translate }} <i class="icon icon-chevron-right"></i>
          </button>

          <h5 class="mt-2">{{'systemSettings.customReports.reportPreview' | translate}}</h5>
          <div style="overflow-x: auto">
            <app-query-result-table [resultPage]="queryResult"></app-query-result-table>
          </div>
        </div>
        <div class="alert alert-danger mt-2" *ngIf="sessionError || queryError">
          {{sessionError || queryError}}
        </div>
      </div>
    </tab>
    <tab [disabled]="!queryResult">
      <ng-template tabHeading>
        {{'table.columns' | translate}}
        <i *ngIf="columnsFormGroup && columnsFormGroup.invalid" class="icon icon-exclamation-circle color-red"></i>
      </ng-template>

      <div class="form-inline mt-2">
        <select class="form-control" [(ngModel)]="filterColumnIndex">
          <option [ngValue]="-1">{{'button.showAll' | translate}}</option>
          <option *ngFor="let columnForm of columnsForms; let i = index" [ngValue]="i">
            {{columnForm.value.name}}
          </option>
        </select>
      </div>
      <div class="mt-2" *ngFor="let columnForm of columnsForms; let isFirst = first; let isLast = last; let i = index"
        [formGroup]="columnForm" [hidden]="filterColumnIndex > -1 && filterColumnIndex !== i">
        <h5>{{columnForm.value.id}}</h5>
        <div style="width: 400px">
          <div class="form-group">
            {{'common.name' | translate}}
            <i class="icon icon-question-circle ml-1"
              [tooltip]="'systemSettings.customReports.colNameTooltip' | translate"
              *ngIf="isFirst || filterMetricIndex > -1">
            </i>
            <input type="text" class="form-control" formControlName="name" tooltip="" appExtendedFormControl>
          </div>
          <div class="form-group">
            {{'systemSettings.customReports.unit' | translate}}
            <i class="icon icon-question-circle ml-1" *ngIf="isFirst || filterColumnIndex > -1"
              [tooltip]="'systemSettings.customReports.unitTooltip' | translate">
            </i>
            <select class="form-control" formControlName="unit">
              <option [ngValue]="null">{{'common.none' | translate}}</option>
              <ng-template [ngIf]="isColumnNumeric(columnForm.value.id)">
                <option *ngFor="let unit of numericColumnUnits" [ngValue]="unit.value">
                  {{unit.label}}
                </option>
              </ng-template>
            </select>
          </div>
        </div>
        <hr *ngIf="!isLast">
      </div>
      <button class="btn btn-primary mt-2" (click)="goToNextTab()">
        {{ 'button.next' | translate }} <i class="icon icon-chevron-right"></i>
      </button>
    </tab>
    <tab [disabled]="!queryResult" (selectTab)="reportTabVisited = true">
      <ng-template tabHeading>
        {{'systemSettings.customReports.report' | translate}}
        <i *ngIf="reportTabVisited && reportFormGroup.invalid" class="icon icon-exclamation-circle color-red"></i>
      </ng-template>

      <div class="mt-2" style="width: 400px" [formGroup]="reportFormGroup">
        <div class="form-group">
          {{'common.id' | translate}}
          <i class="icon icon-question-circle ml-1"
            [tooltip]="'systemSettings.customReports.reportIdTooltip' | translate">
          </i>
          <input type="text" class="form-control" formControlName="id" tooltip="" appExtendedFormControl
            [customErrors]="reportIdValidationErrors" maxlength="100">
        </div>
        <div class="form-group">
          {{'common.name' | translate}}
          <i class="icon icon-question-circle ml-1" tooltip="The display name of this report">
          </i>
          <input type="text" class="form-control" formControlName="name" tooltip="" appExtendedFormControl>
        </div>
        <div class="form-group">
          {{'common.description' | translate}}
          <textarea class="form-control" rows="5" formControlName="description"></textarea>
        </div>
        <div class="form-group">
          {{'common.category' | translate}}
          <select class="form-control" formControlName="category">
            <option *ngFor="let category of reportCategories" [value]="category">{{ 'systemReports.' + category | translate }}</option>
          </select>
        </div>
      </div>

      <button class="btn btn-primary" (click)="goToNextTab()" [disabled]="isPreviewDisabled()">
        {{ 'button.next' | translate }} <i class="icon icon-chevron-right"></i>
      </button>
    </tab>
    <tab [heading]="'systemSettings.customReports.preview' | translate" [disabled]="isPreviewDisabled()"
      (selectTab)="onPreviewTabSelect($event)">
      <h5 class="mt-2">{{'systemSettings.customReports.reportJSONPreview' | translate}}</h5>
      <app-json-explorer class="d-block text-monospace" *ngIf="reportJson" [json]="reportJson" [inline]="false"
        [expand]="true" [showExpandToggle]="false"></app-json-explorer>
    </tab>
  </tabset>
</div>

<ng-template #selectServerModal>
  <div class="modal-header">
    <h5 class="modal-title">{{'server.selectAServer' | translate}}</h5>
  </div>
  <div class="modal-body p-0">
    <app-group-browser [startingItem]="server" (serverSelect)="selectServer($event)">
    </app-group-browser>
  </div>
</ng-template>
