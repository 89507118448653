/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { ConstantEnum } from './constant-operands';

export const serverStatusEnum: ConstantEnum = {
  name: 'Informix status',
  type: 'server-status',
  elements: [
    {
      name: 'Offline',
      value: -1
    },
    {
      name: 'Online',
      value: 5
    },
    {
      name: 'Initialization',
      value: 0
    },
    {
      name: 'Quiescent',
      value: 1
    },
    {
      name: 'Recovery',
      value: 2
    },
    {
      name: 'Backup',
      value: 3
    },
    {
      name: 'Shutdown',
      value: 4
    },
    {
      name: 'Abort',
      value: 6
    },
    {
      name: 'Single User',
      value: 7
    },
    {
      name: 'Unknown',
      value: -999
    }
  ]
};
