<div class="card card-body" *ngIf="serverInfo || isThreadsSensorRunning">
  <h4 class="card-title"><a [routerLink]="['performance', 'threads']">Threads</a></h4>

  <div *ngIf="!viewMonitoredData || !isThreadsSensorRunning" class="row">
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Total Threads</div>
        <div class="card-body text-center">
          <h4>{{totalThreads}}</h4>
        </div>
      </div>
    </div>
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Ready Threads</div>
        <div class="card-body text-center">
          <h4>{{readyThreads}}</h4>
        </div>
       </div>
    </div>
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Waiting Threads</div>
        <div class="card-body text-center">
          <h4>{{waitingThreads}}</h4>
        </div>
      </div>
    </div>
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Waiting on Mutex</div>
        <div class="card-body text-center">
          <h4>{{waitingThreadsMutex}}</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="viewMonitoredData && isThreadsSensorRunning">
    <div style="width: 100%; height: 300px">
      <canvas #threadsChartContainer></canvas>
    </div>
  </div>

</div>
