/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { getTimeSlices, TimeSlice } from '../../monitoring/timeSlices';
import { UserSettingsService } from '../../user-settings/user-settings.service';
import { UserService } from '../../../shared/user/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-preferences',
  templateUrl: 'user-preferences.html'
})
export class UserPreferencesComponent implements OnInit {

  isBtnDisable = true;
  serverError: string;
  dataLoading = false;
  timeSlices = getTimeSlices();
  previousTimeSlice = '';
  previousHistorySettings = '';
  historySetting = '';
  prevHistoryValue = null;
  days: number = null;
  entries: number = null;
  payload = {};
  userPreferenceForm: FormGroup;
  MaxDaysLimit = 30;
  MaxEntriesLimit = 1000;
  @Output() timeSliceChanged = new EventEmitter<TimeSlice>();

  constructor(private notifications: NotificationsService,
    private userSettingsService: UserSettingsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userPreferenceForm = new FormGroup({
      selectedTimeSlice: new FormControl('', Validators.required),
      historySettingType: new FormControl('', Validators.required),
      days: new FormControl(null, this.daysValidator),
      entries: new FormControl(null, this.entriesValidator)
    });

    this.getConfigData();
  }

   // Custom validator function for query history days
   daysValidator(control: FormControl) {
    if (control.value && control.value > 30) {
      const errors = { customError: 'Invalid Days, Maximum 30 days allowed' };
      control.setErrors(errors);
      return errors;
    } else if(control.value < 1){
        const errors = { customError: 'Invalid Days, Minimum 1 day required' };
        control.setErrors(errors);
        return errors;
    }
    return null;
  }

  // Custom validator function for query history entries
  entriesValidator(control: FormControl) {
    if (control.value && control.value > 1000) {
      const errors = { customError: 'Invalid entries, Maximum 1000 entires allowed' };
      control.setErrors(errors);
      return errors;
    } else if(control.value < 1){
      const errors = { customError: 'Invalid entries, Minimum 1 entry required' };
      control.setErrors(errors);
      return errors;
    }
    return null;
  }

  changeSelectedTimeSlice(event) {
    this.userPreferenceForm.controls.selectedTimeSlice.setValue(event.target.value);
  }

  // toggle(Enable, Disable) query history delete options(Days, Entries)
  changeQueryHistory() {
    if (this.userPreferenceForm.controls.historySettingType.value === 'day') {
      this.userPreferenceForm.get('days').enable();
      this.userPreferenceForm.get('entries').disable();
      if(this.userPreferenceForm.controls.days.value === null) {
        this.userPreferenceForm.controls.days.setValue(this.MaxDaysLimit);
      }
    } else {
      this.userPreferenceForm.get('days').disable();
      this.userPreferenceForm.get('entries').enable();
      if(this.userPreferenceForm.controls.entries.value === null) {
        this.userPreferenceForm.controls.entries.setValue(this.MaxEntriesLimit);
      }
    }
  }

 /**
  * update query history setting configurations
  */
  getConfigData() {
    this.payload = {};
    this.dataLoading = true;
    this.userService.requestCurrentUser().then(user => {
      this.previousTimeSlice = user.settings.timeSliceName;
      this.userPreferenceForm.controls.selectedTimeSlice.setValue(user.settings.timeSliceName);
       if (user.settings.statementHistoryConfiguration.hasOwnProperty('maxDays')) {
        this.previousHistorySettings = 'day';
        this.prevHistoryValue = user.settings.statementHistoryConfiguration.maxDays;
        this.userPreferenceForm.controls.historySettingType.setValue(this.previousHistorySettings);
        this.userPreferenceForm.controls.days.setValue(this.prevHistoryValue);
        this.userPreferenceForm.get('entries').disable();
      }
      if (user.settings.statementHistoryConfiguration.hasOwnProperty('maxCount')) {
        this.previousHistorySettings = 'entry';
        this.entries = this.prevHistoryValue = user.settings.statementHistoryConfiguration.maxCount;
        this.userPreferenceForm.controls.historySettingType.setValue(this.previousHistorySettings);
        this.userPreferenceForm.controls.entries.setValue(this.prevHistoryValue);
        this.userPreferenceForm.get('days').disable();
      }

      this.dataLoading = false;
    }).catch(err => {
      this.serverError = err.error ? err.error.err : err;
      this.notifications.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
    });
  }

  /*
  prepare the payload by adding configuration fields that have changed
  */
  preparePayload() {
    const form = this.userPreferenceForm?.controls;
    if (form.historySettingType.value === 'day') {
      this.payload['statementHistoryConfiguration'] = { maxDays: form.days.value };
    } else if (form.historySettingType.value === 'entry') {
      this.payload['statementHistoryConfiguration'] = { maxCount: form.entries.value };
    }

    if (form.selectedTimeSlice.value !== this.previousTimeSlice) {
      this.payload['timeSliceName'] = form.selectedTimeSlice.value;
    }
  }

  saveConfig = async () => {
    this.preparePayload();
    this.userSettingsService.changeUserPreferences(this.payload)
      .subscribe(response => {
        if (this.payload.hasOwnProperty('statementHistoryConfiguration') &&
          this.previousTimeSlice !== this.userPreferenceForm.controls.selectedTimeSlice.value) {
          this.notifications.pushSuccessNotification('User preferences successfully updated');
        } else if (this.payload.hasOwnProperty('statementHistoryConfiguration')) {
          this.notifications.pushSuccessNotification('Query history settings successfully updated');
        } else {
        this.notifications.pushSuccessNotification('Default dashboard time slice preference has been successfully updated to '
          + this.userPreferenceForm.controls.selectedTimeSlice.value);
        }
        this.getConfigData();
        this.isBtnDisable = true;
      }, err => {
        this.notifications.pushErrorNotification('The default dashboard time slice could not be saved. ' + err.error ? err.error.err : err);
      });
  };
}
