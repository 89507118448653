/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { AppSettings } from '../app.settings';


/**
 *	This class represents the lazy loaded DashboardComponent.
 */

@Component({
  selector: 'app-dashboard-cmp',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})

export class DashboardComponent {

  constructor(
    private titleService: Title
  ) {
    this.titleService.setTitle(AppSettings.PRODUCT_NAME);
  }
}
