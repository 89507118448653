/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractSystemReport } from './abstract-system-report';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemReportsService } from './system-reports.service';

@Component({
  selector: 'app-lock-list-report',
  templateUrl: './lock-list-report.html'
})

export class LockListReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId = 'lock_list';
  lockListData: any[] = null;
  sid: number = null;
  lockedObject: string = null;
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };
  filteredReportData: any;
  filteringReportData: any;
  constructor(
    private route: ActivatedRoute,
    systemReportsService: SystemReportsService,
    translate: TranslateService,
    notificationsService: NotificationsService,
  ) {
    super(systemReportsService, notificationsService, translate);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getSystemReport(this.reportId);
    });

    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.sid) {
        this.sid = parseInt(queryParams.sid, 10);
      }
      if (queryParams.lockedObject) {
        this.lockedObject = queryParams.lockedObject;
      }
      this.applyFilters();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get('systemReports.lockList').subscribe((text: string) => {
      this.setBreadcrumb(text);
    });
  }

  getSystemReport(id: string) {
    this.lockListData = null;
    this.systemReportsService.runSystemReport(this.server, id).subscribe((res: any) => {
      this.reportData = res;
      this.lockListData = res;
      this.applyFilters();
    }, err => {
      this.lockListData = [];
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }
  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.filterReportData();
  }
  private applyFilters() {
    if (!this.lockListData || this.lockListData.length === 0) {
      return;
    }
    if (this.sid) {
      const listData = [];
      this.lockListData.forEach(element => {
        if (this.sid === element.owner_sid) {
          listData.push(element);
        }
      });
      this.lockListData = listData;
    }
    if (this.lockedObject) {
      const listData = [];
      this.lockListData.forEach(element => {
        if (this.lockedObject === element.locked_object) {
          listData.push(element);
        }
      });
      this.lockListData = listData;
    }
  }
  filterReportData() {
    this.filteredReportData = [];
    this.filteringReportData = this.reportData;
    if (this.timeStamps.fromTimeStamp !== null || this.timeStamps.toTimeStamp !== null) {
      if (this.timeStamps.fromTimeStamp !== null && this.timeStamps.toTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.lk_start_time && item.lk_start_time.$date) {
            return item.lk_start_time.$date > this.timeStamps.fromTimeStamp &&
              item.lk_start_time.$date < this.timeStamps.toTimeStamp;
          }
        });
      } else if (this.timeStamps.fromTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.lk_start_time && item.lk_start_time.$date) {
            return item.lk_start_time.$date > this.timeStamps.fromTimeStamp;
          }
        });
      } else if (this.timeStamps.toTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.lk_start_time && item.lk_start_time.$date) {
            return item.lk_start_time.$date < this.timeStamps.toTimeStamp;
          }
        });
      }
      this.lockListData = this.filteredReportData;
    } else {
      this.getSystemReport(this.reportId);
    }
  }
}
