/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { SidebarElement } from '../../shared/sidebar/sidebar';
import { Component } from '@angular/core';

@Component({
  selector: 'app-user-settings',
  templateUrl: 'user-settings.html'
})
export class UserSettingsComponent {

  sidebar: SidebarElement[] = [
    {
      label: 'Alerting Configuration',
      link: 'alerts',
      workOffline: true
    },
    {
      label: 'Change Password',
      link: 'change-password',
      workOffline: true
    },
    {
      label: 'User Preferences',
      link: 'user-preferences',
      workOffline: true
    }
  ];
}
