/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { AbstractAlertOperand } from '../abstract-alert-operand';


@Component({
  selector: 'app-constant-number-operand',
  template: `
  <div class="form-group form-inline">
    <ng-container *ngIf="!unit || unit === 'percentage'">
      <input type="number" class="form-control form-control-sm" [formControl]="valueControl">
      <div class="ml-2" *ngIf="unit === 'percentage'">%</div>
    </ng-container>
    <ng-container *ngIf="unit === 'bytes'">
      <app-input-size [formControl]="valueControl" formClass="form-control-sm"></app-input-size>
    </ng-container>
  </div>
  `
})
export class ConstantNumberOperandComponent extends AbstractAlertOperand implements OnInit {
  valueControl: UntypedFormControl;
  unit: string = null;

  ngOnInit() {
    super.ngOnInit();

    this.valueControl = new UntypedFormControl(null, Validators.required);

    this.valueControl.valueChanges.pipe(delay(0)).subscribe(() => {
      this.emitUpdate();
    });

    if (this.operand && !isNaN(this.operand.value)) {
      let value = this.operand.value;
      if (this.unit === 'percentage') {
        value *= 100;
      } else if (this.unit === 'bytes') {
        value /= 1024;
      }

      this.valueControl.setValue(value);
    }
  }

  public isValid(): boolean {
    return this.valueControl.valid;
  }

  public getValue(): any {
    let value = parseFloat(this.valueControl.value);
    if (this.unit === 'percentage') {
      value /= 100;
    } else if (this.unit === 'bytes') {
      value *= 1024;
    }

    return {
      value
    };
  }
}
