/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2022, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges, TemplateRef, ViewChild, Inject, OnDestroy } from '@angular/core';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { EnterpriseReplicationService } from '../enterprise-replication.service';
import { ERDomain, ERNode } from '../er-domain';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ERDomainClass, SqlhostsGroupInternal, ERDomainModes, ERDomainStatus, ERDomainUserSelection, ERDomainState, SidebarsType, ERNodeOperationStatus, SqlhostsGroupValidationStatus, ERDomainOperation, PreSelectedGroups } from '../enterprise-replication-types';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { SocketService } from '../../socket.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ERDomainLeftPanelComponent } from './sidebars/er-group-list/er-group-list-sidebar.component';
import { environment } from '../../../../../environments/environment';

const LOCAL_NODE_ID_STARTS_WITH = 90000;
@Component({
  selector: 'app-er-domain',
  templateUrl: './er-domain.component.html',
  styleUrls: ['./er-domain.component.scss']
})

export class ErDomainComponent implements OnInit, OnChanges, OnDestroy {

  // ViewChilds
  @ViewChild('defineDomainModal') defineDomainModal: ModalDirective;
  @ViewChild('cancelDomainModal') cancelDomainModal: ModalDirective;
  @ViewChild('loadDraftModal') loadDraftModal: ModalDirective;
  @ViewChild(ERDomainLeftPanelComponent) erDomainLeftPanelComponent: ERDomainLeftPanelComponent;

  groups: SqlhostsGroupInternal[] = [];

  @Input() server: InformixServer;
  @Input() erDomainClass: ERDomainClass;
  @Input() insideLoading: boolean;
  isDraft: Boolean;

  // Outputs
  @Output() nodeDefined = new EventEmitter();
  @Output() nodeDeleted = new EventEmitter<ERNode>();
  @Output() reSyncDomain = new EventEmitter();
  @Output() loadDraft = new EventEmitter();

  // Member Variables
  breadcrumb: BreadcrumbElement[] = [];

  sidebars: SidebarsType = {
    groupList: false,
    defineNode: false,
    erInfo: false
  };

  erDomainModes = ERDomainModes;
  erDomainStatus = ERDomainStatus;
  erDomainOperation = ERDomainOperation;
  erDomainUserSelection = ERDomainUserSelection;
  erNodeOperationStatus = ERNodeOperationStatus;
  sqlhostsGroupValidationStatus = SqlhostsGroupValidationStatus;
  domainState: ERDomainState = null;

  profile: any;


  selectedGroup: SqlhostsGroupInternal;
  showSqlhostsGroupsModal: Boolean = false;

  preSelectedGroups: PreSelectedGroups= {};
  startDomainLoading: Boolean = false;
  domainStatusText: String = '';
  isGroupListEmpty: Boolean = false;
  productName: string = environment.productNameNoSpace;
  resolveCancelDomainChanges: (value: boolean) => void = null;
  constructor(
    private erService: EnterpriseReplicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private socketService: SocketService,
    private notificationsService: NotificationsService,
    ) {
      this.initDomainState();
    }

  ngOnInit() {
    this.findCurrentGroup();
    this.socketService.connect().subscribe(() => {
      this.subscribeToSocketReceive();
    }, error => {
    });
  }

  ngOnDestroy(): void {
    this.socketService.disconnect();
  }

  /*
   * Add domain nodes in the preSelectedGroups list to disable selection in the sqlhosts list
   * Change mode of the ER Domain If group list is empty
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.erDomainClass) {
      this.setDomainStatus();
    }
    if(changes.groups) {
      this.setDomainMode();
    }
  }

  findCurrentGroup() {
    if(!(this.erDomainClass && this.erDomainClass.domain)){
        return;
    };

    const currentGropup = this.erDomainClass.domain.jsonNodes.find(_node => _node.status === 'L');
    if(currentGropup) {
      this.domainState.currentGroup = { id: currentGropup.id, node: currentGropup };
    }
  }

  isDraftValid() {
    this.isDraft = this.erService.isDraftAvaiable(this.server.id);
    if(this.isDraft) {
      const draftData = this.erService.getDraft(this.server.id);
      if(draftData.domainState.status !== this.domainState.status) {
        this.confirmationDialogService.show('Message', 'Your saved draft is no longer invalid and deleted. As domain is changed. ', false);
        this.deleteDomainDraft();
      }
    }
  }

  setDomainMode() {
    if(this.groups && this.groups.length) {
      this.domainState.mode = ERDomainModes.ADD;
    }else{
      this.domainState.mode = ERDomainModes.VIEW;
    }
  }

  /**
   * Method to check and set status of the er domain
   */
  setDomainStatus() {
    if(this.erDomainClass && this.erDomainClass.domain) {
      if(this.erDomainClass.domain.nodes.length) {
        this.domainState.status = ERDomainStatus.MODIFY;
        this.domainStatusText = 'modification';
      }else{
        this.domainState.status = ERDomainStatus.EMPTY;
      }
      // hiding prerequiesties screen on existing or empty domain
      this.domainState.showPrerequiesties = false;
    }else{
      this.domainState.status = ERDomainStatus.CREATE;
      // Show prerequiesties screen at time of new domain creation
      this.domainState.showPrerequiesties = true;
      this.domainStatusText = 'creation';
    }
    // Check for domain draft
    this.isDraftValid();
    this.preSelectGroupsFromDomain();
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (this.isLocalChanges()) {
      return new Promise<boolean>(resolve => {
        this.confirmationDialogService.show('discard unsaved changes?', () => {
          resolve(true);
        });
        this.resolveCancelDomainChanges = resolve;
      });
    } else {
      return true;
    }
  }

  /**
   *
   * Event Listener for group selection from group list in the left side panel
   * Open right side panel once group is selected by the user
   *
   * @param group
   */
  onGroupSelected(group: SqlhostsGroupInternal) {
    if(group){
      this.selectedGroup = group;
      this.openSideBar('defineNode');
      this.selectERNodeInDomain(group.groupName);
    }else {
      this.selectedGroup = null;
      this.closeSideBar('defineNode');
    }
  }

  /**
   *
   * Highlight the selected node in the ER domain tree
   *
   * @param sqlGroupName
   */
  selectERNodeInDomain(sqlGroupName: string) {
    if (this.erDomainClass && this.erDomainClass.domain) {
      const node = this.erDomainClass.domain.nodes.find(erNode => erNode.name === sqlGroupName);
      this.erDomainClass.selectedNode = node;
    }
  }

  /**
   *
   * Event Listener will be called once ER node is deleted from the domain.
   * Will emit event on enterprise-replication page to fetch ER domain and refresh the domain tree
   *
   * @param node
   *
   */
  deleteERNode(node: ERNode) {
    this.nodeDeleted.emit(node);
  }

  /**
   *
   * Event Listener: Once groups are selected from sqlhosts list from the modal
   * Sorting the list by current group to show it first in the list
   * Validating each newly added group from the list
   * Switching to add mode on the page
   *
   * @param groups
   * @returns
   */
  onGroupsAdded(groups) {
    if(!this.socketService.isConnected) {
      this.notificationsService.pushErrorNotification(`${this.productName} failed to establish a connection to the Websocket.`);
      return;
    }
    if(groups && !groups.length) {
      return;
    }

    this.groups = [...this.groups, ...groups];


    this.selectCurrentGroup();
    this.sortByCurrntGroup();


    const nodesTobeValidated: any = [];

    this.groups = this.groups.map((group, index) => {
      if(!this.preSelectedGroups[group.groupName] || group.isCurrentGroup) {
        nodesTobeValidated.push({groupName: group.groupName});
      }
      this.preSelectedGroups[group.groupName] = true;
      return group;
    });

    this.validateGroups(nodesTobeValidated);

    this.domainState.mode = ERDomainModes.ADD;
    this.domainState.operation = ERDomainOperation.START;
    this.domainState.showPrerequiesties = false;
  }

  /**
   *
   * Automatically Selecting the current group if available otherwise selecting first one from the list
   * Opening both side panels after selection
   */
  selectCurrentGroup() {
    if(this.domainState.status === ERDomainStatus.CREATE) {
      this.groups.forEach(group => {
        if (group.isCurrentGroup) {
          this.domainState.currentGroup = group;
          this.onGroupSelected(group);
        }
      });
    }else{
      this.onGroupSelected(this.groups[0]);
    }
    this.openSideBar('defineNode');
    this.openSideBar('groupList');
  }

  /**
   *
   * Sorting the group list to show current group first in the list
   *
   * @returns
   */
  sortByCurrntGroup(): void {
    if (this.groups && !this.groups.length) {
      return;
    }
    let currentGroup: {index?: number; group?: SqlhostsGroupInternal} = null;
    for(let i = 0; i < this.groups.length; i++) {
      const _group = this.groups[i];
      if (_group.isCurrentGroup ) {
        currentGroup = {
          index: i,
          group: _group
        };
      }
    }
    if(currentGroup) {
      this.groups.splice(currentGroup.index, 1);
      this.groups.unshift(currentGroup.group);
    }
  }

  /**
   *
   * Event listener: To delete group from the list and from the domain
   *
   * @param group
   * @param force
   */
  onGroupDeleted(group: SqlhostsGroupInternal, force: boolean = false) { // from the list

    if(this.deleteNodeFromDomain(group, force)) {
        // /* Not deleting current group from the list */
        // if(this.domainState.currentGroup.name === group.name) {
        //   return;
        // }
        this.groups = this.groups.filter((_group, index) => {
          const groupMatched = _group.groupName === group.groupName;
          const isCurrentGroup = this.domainState.status === ERDomainStatus.CREATE
                                  && this.domainState.currentGroup.groupName === group.groupName;
          if(groupMatched){
            // Nulling the configuration, onConfigParams, and node element to prevent abnormal behaviour in the list
            _group.configuration = null;
            // _group.onConfigParams = null;
            _group.node = null;

            if(!isCurrentGroup || force) {
              return false;
            }
          }

          return true;

          });
        this.preSelectedGroups[group.groupName] = null;
    }
    // Selecting next group from the group list.
    if(!force) {
      this.erDomainLeftPanelComponent.selectNextGroup();
    }
  }

  /**
   * Preselecting the groups which are already available in the domain.
   */
  preSelectGroupsFromDomain() {
    this.preSelectedGroups = {};
    if (this.erDomainClass && this.erDomainClass.domain && this.erDomainClass.domain.nodes) {
      this.erDomainClass.domain.nodes.forEach(node => {
        this.preSelectedGroups[node.name] = true;
      });
    }
  }

  /**
   * Event Listener: from er define component to re-validate group
   *
   * @param group
   */
  onGroupValidate(group) {
    this.validateGroups([{groupName: group.groupName}]);
  }


  validateGroups(nodes: any[]) {
    if(!(nodes && nodes.length)) {
      return;
    }else if(!this.socketService.isConnected) {
      this.notificationsService.pushErrorNotification(`${this.productName} can't establish connection to Websocket.`);
    }

    nodes.forEach(node => this.updateValidationStatus(node.groupName, SqlhostsGroupValidationStatus.PROGRESS));

    this.socketService.send({
      event: 'ER_VALIDATE_NODE',
      data: {
        serverId: this.server.id,
        nodes
      }
    });
  }

  subscribeToSocketReceive() {
    this.socketService.receive().subscribe((message) => {
      if(!message) {
        return;
      }
      try {
        // console.log('Message: ', message.data);
        switch(message.event) {
          case 'ER_VALIDATE_NODE':
            this.onGroupValidateHandler(message.data);
            break;
          case 'ER_CREATE_NODE_COMMAND':
            this.onServerCommandCreatedHandler(message.data);
            break;
          case 'ER_DEFINE_NODE':
            this.onNodeDefinedHandler(message.data);
            break;
        }
      } catch(e) {
        console.error('Error in the web socket receive subsriber : ', e);
      }
    }, error => {
      console.error('ERror Occured : ', error);
    });
  }

  onGroupValidateHandler(message) {
    if(message.status === 'SUCCESS') {
      this.updateValidationStatus(message.groupName, SqlhostsGroupValidationStatus.SUCCESS, null,
        { spaces: message.data.spaces, onConfigParams: message.data.onConfigParams});
      }else if(message.status === 'FAILED'){
      this.updateValidationStatus(message.groupName, SqlhostsGroupValidationStatus.ERROR, message.error);
    }
  }

  onServerCommandCreatedHandler(message) {

    if(message.nodes && message.nodes.length) {

      message.nodes.forEach(_node => {
        const commands = _node.data?_node.data:{};
        const index = this.domainState.nodeList.findIndex(node => node.groupName === _node.groupName);
        this.domainState.nodeList[index].command = {};

        if(commands.defineServerCommand) {
          this.domainState.nodeList[index].command.defineServerCommand = {
            command: commands.defineServerCommand,
            success: true
          };
        }
        if(commands.sbSpaceCommand) {
          this.domainState.nodeList[index].command.sbSpaceCommand = {
            command: commands.sbSpaceCommand,
            success: true
          };
        };
        if(commands.dbSpaceCommand) {
            this.domainState.nodeList[index].command.dbSpaceCommand = {
              command: commands.dbSpaceCommand,
              success: true
            };
        };
      });
    }
  }

  onNodeDefinedHandler(message) {

    /**
     * Checks whether all the events are finished and has failure
     */
    const isAllCompleted = () => {
      let isAllOperationFinished = true;

      this.domainState.hasFailure = false;
      this.domainState.nodeList.forEach(node => {
        if(node && node.operation && node.operation.status === ERNodeOperationStatus.PROGRESS) {
          isAllOperationFinished = false;
        }
        if(node && node.operation && node.operation.status === ERNodeOperationStatus.ERROR) {
          this.domainState.hasFailure = true;
        }
      });

      if(isAllOperationFinished) {
        this.constructFinalMsg(this.domainState.hasFailure);
        this.domainState.operation = ERDomainOperation.COMPLETE;
        if(!this.domainState.hasFailure) {
          console.log('Mode: ', this.domainState.mode);
          this.changeViewMode(ERDomainModes.VIEW);
          console.log('Mode: ', this.domainState.mode);
          // this.domainState.status = ERDomainStatus.START;
          this.groups = [];
          this.deleteDomainDraft();
          this.reSyncDomain.emit();
        }
      }
    };


    const foundIndex = this.domainState.nodeList.findIndex(node => node.groupName === message.groupName);

    if(message.status === 'FAILED') {
      this.domainState.nodeList[foundIndex].operation.status = ERNodeOperationStatus.ERROR;
      this.domainState.nodeList[foundIndex].operation.errorMsg = message.error;
      this.groups = this.groups.map(group => {
        if(group.groupName === this.domainState.nodeList[foundIndex].groupName) {
          group.validation.errorMsg = message.error;
        }
        return group;
      });

      const commands = message.data?message.data:{};

      let commandError = false;
      if(commands.sbSpaceCommand) {
        this.domainState.nodeList[foundIndex].command.sbSpaceCommand = commands.sbSpaceCommand;
        commandError = true;

        if(commands.sbSpaceCommand.success) {
          this.updateSpaceState(message.groupName,foundIndex, this.domainState.nodeList[foundIndex].payload.sbSpace.join(','));
        }
      };

      if(commands.dbSpaceCommand) {
        this.domainState.nodeList[foundIndex].command.dbSpaceCommand = commands.dbSpaceCommand;
        commandError = true;

        if(commands.dbSpaceCommand.success) {
          this.updateSpaceState(message.groupName, foundIndex, null, this.domainState.nodeList[foundIndex].payload.dbSpace);
        }
      };

      if(commands.defineServerCommand) {
        this.domainState.nodeList[foundIndex].command.defineServerCommand = commands.defineServerCommand;
      }

      if(!commandError) {
        this.domainState.nodeList[foundIndex].command.defineServerCommand = {
          ...this.domainState.nodeList[foundIndex].command.defineServerCommand, success: false, err: message.error
        };
      }
      this.domainState.stats.failed += 1;
      isAllCompleted();
      return;
    }

    this.domainState.nodeList[foundIndex].operation.status = ERNodeOperationStatus.SUCCESS;

    // Removing the group from the group list as it is defined on the group
    this.groups = this.groups.filter(_group => _group.groupName !== this.domainState.nodeList[foundIndex].groupName);

    // Nulling group in the domain node
    this.erDomainClass.domain.nodes[this.domainState.nodeList[foundIndex].domainNodeIndex].group = null;

    // if any node successfully defined so it is not new ER domain anymore

    this.domainState.status = ERDomainStatus.MODIFY;

    this.domainState.stats.defined += 1;
    this.domainState.hasChanged = true;
    isAllCompleted();
  }

  updateValidationStatus(groupName: string, status: SqlhostsGroupValidationStatus, errorMsg?: string, extra?: any) {
    this.groups = this.groups.map((group: SqlhostsGroupInternal, index) => {
      if(group.groupName === groupName) {
        group.validation.status = status;
        group.validation.errorMsg = errorMsg;
        group = {...group, ...extra};
      }
      if(group.groupName === this.selectedGroup.groupName) {
        this.selectedGroup = {...group, id : Date.now()};
      }
      return group;
    });
  }

  private openSideBar(sidebar: string) {
    this.sidebars[sidebar] = true;
  }

  private closeSideBar(sidebar: string) {
    this.sidebars[sidebar] = false;
  }

  private toggleSideBar(sidebar: string) {
    this.sidebars[sidebar] = !this.sidebars[sidebar];
  }

  displayHelp() {
    this.domainState.showHelpScreen = true;
    this.closeSideBar('defineNode');
    this.closeSideBar('addServer');
    this.closeSideBar('erInfo');
  }

  displayNodeInfo(node) {
    if(this.domainState.mode === ERDomainModes.ADD) {
      if(node.group) {
        this.onGroupSelected(node.group);
        this.openSideBar('defineNode');
      }
      return;
    }
    this.openSideBar('erInfo');
  }

  toggleFullscreen() {
    this.domainState.fullScreen = !this.domainState.fullScreen;
  }

  OnSaveNode({configuration, goNext}) {

    const node = {
      id: this.domainState.localNodeID++,
      name: this.selectedGroup.groupName,
      rootId: (configuration.nodeType !== 'root')?configuration.syncServer.id:0,
      isHub: false,
      isLeaf: configuration.nodeType === 'leaf',
      serverState: 'A',
      state: 'L',
      members: this.selectedGroup.members.map(member => ({dbServerName: member.serverName})),
      group: this.selectedGroup,
  };

  let nodesToBeAdded: any = [];
  if(this.erDomainClass && this.erDomainClass.domain) {
    let nodesAlreadyDefined = [];
    if(this.domainState.stats.failed) {
      nodesAlreadyDefined = this.erDomainClass.domain.nodes.map(_node => {
        if(!_node.group) {
          return _node.name;
        }
        return null;
      });
    }
    nodesToBeAdded = this.erDomainClass.domain.jsonNodes.map(jsonNode => {
      if(nodesAlreadyDefined.includes(jsonNode.name)) {
        jsonNode.group = null;
      }
      return jsonNode;
    });
  }

    this.groups = this.groups.map( group => {
      if(group.groupName === this.selectedGroup.groupName){
        group.configuration = configuration;
        node.group = group;
        if(group.node) { // Modify Existing node
          nodesToBeAdded = nodesToBeAdded.map(_node => {
            if(_node.id === group.node.id) {
              node.id = group.node.id;
              _node = node;
            }
            return _node;
          });
        }else{ // Add new node locally in the existing domain
          nodesToBeAdded.push(node);
        }
        group.node = node;
      }
      return group;
    });


    const domain = new ERDomain(this.server, {
      name: 'local_grp_domain',
      nodes: nodesToBeAdded
    });

    if(this.profile) {
      domain.addProfile(this.profile);
    }

    this.erDomainClass = {
      domain,
      selectedNode: null
    };


    if(goNext) {
      this.erDomainLeftPanelComponent.selectNextGroup();
    }
  }

  setProfile(profile: any, error?: string) {
    this.erDomainClass.domain.addProfile(profile, error);
    this.profile = profile;
  }


  /**
   * Method to delete ER node from the ER domain locally
   *
   * @param group
   * @param force
   * @returns true if node is successfully deleted
   */
  deleteNodeFromDomain(group, force?: boolean): boolean {

    if(group.configuration || group.node) {

      const nodeToBeDeleted = this.erDomainClass.domain.nodes.find(node => node.id === group.node.id);

      // if(nodeToBeDeleted && nodeToBeDeleted.children && nodeToBeDeleted.children.length && !force) {
      //   this.confirmationDialogService.show('Message',
      //   `${nodeToBeDeleted.name} node has children, It can not be deleted first delete its children!`, false);
      //   return false;
      // }else {
        const syncNode = this.erDomainClass.domain.nodes.find(node => node.id !== nodeToBeDeleted.id &&
            node.group && node.group.configuration && node.group.configuration.syncServer &&
            node.group.configuration.syncServer.name === nodeToBeDeleted.name);

        if(syncNode && !force) {
          this.confirmationDialogService.show('Message',
        `${nodeToBeDeleted.name} can not be deleted. Other group(s) are synced with it `, false);
        return false;
        }
      // }
      const filteredNodes = this.erDomainClass.domain.jsonNodes.filter(node => node.id !== group.node.id);

      const domain = new ERDomain(this.server, {
        name: 'local_grp_domain',
        nodes: filteredNodes
      });

      if(this.profile) {
        domain.addProfile(this.profile);
      }

      this.erDomainClass = {
        domain,
        selectedNode: null
      };
      return true;
    }else {
      return true;
    }

  }

  /**
   * Event Listener from er-domain-graph comp to delete er node from the domain and group list
   *
   * @param node
   */
  deleteNode(node) {
    if(this.deleteNodeFromDomain(node)) {
      this.groups = this.groups.map(group => {
        if(group.parent === node.node.name) {
          group.configuration = null;
          group.node = null;
          group.validation.errorMsg = null;
        }
        return group;
      });
    }
  }

  showConfirmationModal() {
    if (this.defineDomainModal && this.isERDefineValid()) {
      this.domainState.nodeList = this.prepareDomainNodeList();
      this.showCommands();
      this.isGroupListEmpty = this.groups.filter(group => !group.configuration).length === 0;
      this.defineDomainModal.show();
    }
  }

  prepareDomainNodeList() {
    const nodeList = [];
    this.erDomainClass.domain.nodes.forEach((_node: any, i) => {
      if(_node.group) {
        const node = {
          payload: this.getPayload(_node.group),
          domainNodeIndex: i,
          groupName: _node.group.groupName,
          name: _node.group.name,
          typeName: _node.typeName
        };
        nodeList.push(node);
        return;
      }
    });
    return nodeList;
  }

  /**
   * Updating existing sbspace & dbspace if command is successfully executed on the group but group define command failed.
   * to sync with group state to prevent any API error while setting same space again.
   */
  updateSpaceState(groupName: string, index: number, sbSpace?: string, dbSpace?: string ) {
    this.groups = this.groups.map(group => {
      if(group.groupName === groupName) {
        if(sbSpace) {
          group.onConfigParams[0].effective = sbSpace;
          group.configuration.existingSbSpace = sbSpace;
          this.domainState.nodeList[index].payload.existingSbSpace = sbSpace;
        }
        if(dbSpace) {
          group.onConfigParams[1].effective = dbSpace;
          group.configuration.existingDbSpace = dbSpace;
          this.domainState.nodeList[index].payload.existingDbSpace = dbSpace;
        }
      }
      return group;
    });
  }

  processedDomain(onlyFailed: boolean = false){
    this.domainState.operation = ERDomainOperation.PROGRESS;
    this.domainState.userSelection = ERDomainUserSelection.PROCESSED;

    const nodesToDefined = [];
    this.domainState.nodeList.forEach((node, index) => {

      // Processed only failed nodes to retry
      if(onlyFailed && node.operation && node.operation.status === ERNodeOperationStatus.ERROR) {

        this.domainState.nodeList[index].operation = {
          status: ERNodeOperationStatus.PROGRESS
        };
        nodesToDefined.push(node.payload);

      }else if(!onlyFailed) { // processeding all the nodes

        this.domainState.nodeList[index].operation = {
          status: ERNodeOperationStatus.PROGRESS
        };
        nodesToDefined.push(node.payload);
      }

    });

    this.defineNodes(nodesToDefined);
  }

  defineNodes(nodes) {

    this.domainState.stats = {
      total: nodes.length,
      defined: 0,
      failed: 0
    };

    this.socketService.send({
      event: 'ER_DEFINE_NODE',
      data: {
        serverId: this.server.id,
        nodes
      }
    });
  }

  createCommands(nodes) {

    this.socketService.send({
      event: 'ER_CREATE_NODE_COMMAND',
      data: {
        serverId: this.server.id,
        nodes
      }
    });
  }

  getPayload(group) {
    const payload = {...group.configuration};
    // Deleting syncServer unwanted property from payload
    delete payload.syncServer;
    return payload;
  };


changeViewMode(mode: ERDomainModes) {
  this.domainState.mode = mode;
  if(mode === ERDomainModes.VIEW) {
    this.closeSideBar('defineNode');
    this.closeSideBar('groupList');
  }
}
/**
 * Closing the modal
 */
  closeModal(){
    if(this.defineDomainModal) {
      this.defineDomainModal.hide();
    }
    setTimeout(() => {
      this.domainState.userSelection = ERDomainUserSelection.NONE;
      this.domainState.hasFailure = false;
      this.domainState.operation = ERDomainOperation.START;
      this.domainState.statusMsg = null;
    },500);
  }

  /**
   * Canceling the ER domain creating and modification
   */
 cancelERDomain() {
  this.groups.forEach(group => this.onGroupDeleted(group, true));

  if(this.erDomainClass && this.erDomainClass.domain && !this.erDomainClass.domain.nodes.length) {
    this.erDomainClass = null;
  }

  this.changeViewMode(ERDomainModes.VIEW);

  if(this.domainState.hasChanged) {
    this.reSyncDomain.emit();
  }

  this.resetDomainState();
  this.setDomainStatus();

  if(this.cancelDomainModal) {
    this.cancelDomainModal.hide();
    if (this.resolveCancelDomainChanges) {
      this.resolveCancelDomainChanges(false);
      this.resolveCancelDomainChanges = null;
    }
  }

 }

 /**
  * Check if ER domain define should be processed
  *
  * @returns
  */
 isERDefineValid() {
    if(this.erDomainClass && this.erDomainClass.domain && this.erDomainClass.domain.nodes) {
      const nodesToBeAdded = this.erDomainClass.domain.nodes.filter(node => node.group);
      if(!(nodesToBeAdded && nodesToBeAdded.length)) {
        this.confirmationDialogService.show('Message', 'Please add nodes in the domain!', false);
        return false;
      }

      if(this.domainState.status === ERDomainStatus.CREATE) {
        const currentGroup = nodesToBeAdded.find(node => this.domainState.currentGroup &&
            node.group.groupName === this.domainState.currentGroup.groupName);
        if(!currentGroup) {
          this.confirmationDialogService.show('Message', this.domainState.currentGroup.groupName + ' must be part of ER domain', false);
          return false;
        }
      }
      return true;
    }
 }

 showCommands(){
    const nodes = [];
    this.domainState.nodeList.forEach(node => {
      nodes.push(node.payload);
    });
    this.createCommands(nodes);
    return;
  }

  constructFinalMsg(hasFailure: Boolean = false) {
    let msg = ``;
    if(this.domainState.stats.defined) { // If one of node is successed show successfull message
      msg = `Domain ${ this.domainStatusText } was successful. `;
    }
    this.domainState.statusMsg = msg;
  }

  initDomainState() {
    this.domainState = {
      status: ERDomainStatus.CREATE,
      operation: ERDomainOperation.START,
      mode: ERDomainModes.VIEW,
      hasFailure: false,
      userSelection: ERDomainUserSelection.NONE,
      fullScreen: false,
      localNodeID: LOCAL_NODE_ID_STARTS_WITH,
      showHelpScreen: false,
      stats: {
        total: 0,
        defined: 0,
        failed: 0
      },
      statusMsg: '',
      hasChanged: false
    };
  }

  resetDomainState() {
    this.domainState = {
      ...this.domainState,
      status: ERDomainStatus.CREATE,
      operation: ERDomainOperation.START,
      mode: ERDomainModes.VIEW,
      hasFailure: false,
      userSelection: ERDomainUserSelection.NONE,
      showHelpScreen: false,
      stats: {
        total: 0,
        defined: 0,
        failed: 0
      },
      statusMsg: '',
      hasChanged: false
    };
  }

  showCancelDomainModal() {
    if(!this.isLocalChanges()) {
      this.cancelERDomain();
      return;
    }
    if(this.cancelDomainModal) {
      this.cancelDomainModal.show();
    }
  }

  saveAsDraft() {
    this.cancelDomainModal.hide();
    this.erService.saveDraft(this.server.id,
      {
        nodes: this.erDomainClass.domain.jsonNodes.map(jsonNode => ({
            id: jsonNode.id,
            isHub: jsonNode.isHub,
            isLeaf: jsonNode.isLeaf,
            members: jsonNode.members,
            name: jsonNode.name,
            serverState: jsonNode.serverState,
            state: jsonNode.state
          })),
        groups: this.groups.map(group => {
          let new_group = {...group};
          if(group.node) {
            new_group.node = { name: group.node.name };
          }
          return JSON.parse(JSON.stringify(new_group));
        }),
        domainState: this.domainState,
        profile: this.profile, sidebars: this.sidebars,
        selectedGroup: { groupName: this.selectedGroup.groupName },
        preSelectedGroups: this.preSelectedGroups
      });
    this.isDraft = this.erService.isDraftAvaiable(this.server.id);
    this.cancelERDomain();

    if(this.isDraft) {
      this.domainState.showPrerequiesties = true;
    }
  }

  loadFromDraft() {
    const draftData = this.erService.getDraft(this.server.id);
    if(!draftData) {
      return;
    }

    const domain = new ERDomain(this.server, {nodes:draftData.nodes}, true);
    this.erDomainClass = { domain, selectedNode: domain.origin };

    setTimeout(() => {
      this.setProfile(draftData.profile);
      this.sidebars = draftData.sidebars;
      this.groups = draftData.groups.map(group => {
        const _group = {...group};
        if(group.node) {
          _group.node = domain.nodes.find(node => node.name === group.node.name);
          _group.node.group = _group;
        }
        return _group;
      }
      );
      this.domainState = draftData.domainState;
      this.selectedGroup = this.groups.find(group => group.groupName === draftData.selectedGroup.groupName);
      this.preSelectedGroups = draftData.preSelectedGroups;
    });
  }

  showDraftConfirmationModal() {
    if(this.loadDraftModal) {
      this.loadDraftModal.show();
    }
  }


  startDefineDomain(start) {
    if(start && this.isDraft) {
      this.showDraftConfirmationModal();
      return;
    }
    this.showSqlhostsGroupsModal = start;
  }

  startFreshDomain() {
    if(this.loadDraftModal) {
      this.loadDraftModal.hide();
    }
    this.deleteDomainDraft();
    this.showSqlhostsGroupsModal = true;
  }

  discardDomainDraft() {
    this.confirmationDialogService.show('Message', 'Are you sure you want to discard the draft?', () => {
      this.cancelERDomain();
      this.deleteDomainDraft();
    });
  }
  deleteDomainDraft() {
    this.erService.clearDraft(this.server.id);
    this.isDraft = this.erService.isDraftAvaiable(this.server.id);
  }

  isLocalChanges() {
    let isLocalChange = false;
    if(this.erDomainClass && this.erDomainClass.domain && this.erDomainClass.domain.nodes) {
      this.erDomainClass.domain.nodes.forEach((node: ERNode) => {
        if(node && node.group) {
          isLocalChange = true;
        }
      });
    }
    return isLocalChange;
  }

  startERDomain() {
    this.startDomainLoading = true;
    this.erService.startER(this.server.id, { isStart: true }).subscribe((resp: any) => {
      this.domainState.status = ERDomainStatus.MODIFY;
      this.startDomainLoading = false;
      if(resp.return_code === 0) {
        console.log('Enterprise Replication started ');
        this.reSyncDomain.emit();
      }else {
        const errorMessage = resp.result_message ? resp.result_message : ('Error: ' + resp);
        this.notificationsService.pushErrorNotification(errorMessage);
      }
    },error => {
      this.startDomainLoading = false;
      this.notificationsService.pushErrorNotification(error.error.err);
      console.error('Error in while starting enterprise replication');
    });
  }
}
