/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerSpace } from './serverSpace';

@Component({
  selector: 'app-drop-space',
  templateUrl: 'drop-space.html'
})
export class DropSpaceComponent {

  @Input() server: InformixServer = null;
  @Input() selectedSpace: ServerSpace = null;

  dropOption = 'drop';

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  public doClose() {
    this.close.emit();
  }

  public doDropSpace() {
    this.informixServerStorageService.dropSpace(this.server.id, this.selectedSpace.name, this.dropOption === 'dropToPool').then(result => {
      if (result.return_code === null) {
        this.error.emit('Error: ' + result);
      } else if (result.return_code === 0) {
        this.success.emit(result.result_message);
      } else {
        this.error.emit(result.result_message);
      }
    }).catch(err => {
      if (err.status === 404) {
        this.error.emit('Space no longer exists.');
      } else {
        this.error.emit(err);
      }
    });
    this.doClose();
  }
}
