/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { InformixServerGroup } from './groups/informixServerGroup';

interface PermissionsJson {
  read: boolean;
  admin: boolean;
  sql: boolean;
}

export const enum InformixServerPermission {
  READ = 1,
  ADMIN = 2,
  SQL = 4
}

export interface InformixServerPermissionData {
  name: string;
  value: InformixServerPermission;
  inherited: InformixServerPermission[];
  jsonKey: string;
}

export const informixServerPermissions: InformixServerPermissionData[] = [
  { name: 'Read', value: InformixServerPermission.READ, inherited: [], jsonKey: 'read' },
  { name: 'SQL', value: InformixServerPermission.SQL, inherited: [InformixServerPermission.READ], jsonKey: 'sql' },
  {
    name: 'Administer',
    value: InformixServerPermission.ADMIN,
    inherited: [InformixServerPermission.READ, InformixServerPermission.SQL],
    jsonKey: 'admin'
  }
];

export abstract class InformixTreeItem {
  public permissions: PermissionsJson;
  public parent: InformixServerGroup = null;
  aggregate: any;
  unreadIncidents: any;

  constructor(
    public id: number,
    public parentGroupId: number,
    public name: string,
    permissionsByte: number
  ) {
    this.permissions = {
      read: false,
      admin: false,
      sql: false
    };

    informixServerPermissions.forEach(permission => {
      if ((permissionsByte & permission.value) === permission.value) {
        this.permissions[permission.jsonKey] = true;
      }
    });
  }
}
