/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
export class Parameter {
  id: number;
  task_name: string;
  name: string;
  value: string;
  value_type: string;
  description: string;
  current_state: number;
}
