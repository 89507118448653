<form [formGroup]="formGroup" *ngIf="alert" style="max-width: 400px">
  <div class="form-group">
    <label>Name</label>
    <input type="text" class="form-control form-control-sm" formControlName="name" tooltip="" appExtendedFormControl>
  </div>
  <hr>
  <div class="form-group">
    <label>Alert me when</label>
    <app-alert-operation [owner]="owner" [operation]="alert.condition" (eventUpdate)="onUpdate($event)"> </app-alert-operation>
  </div>
  <ng-container *ngIf="alertTriggerType === 'interval' && isOperationValid">
    <hr>
    <div class="form-group form-inline">
      Check every
      <app-input-time class="ml-2" formControlName="runInterval" tooltip="" placement="right" appExtendedFormControl>
      </app-input-time>
    </div>
  </ng-container>
</form>
