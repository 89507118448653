/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023. All Rights Reserved.
 *******************************************************************************/

import { ERDomain, ERNode } from './er-domain';
import { ServerSpace } from '../storage/serverSpace';
import { ServerOnconfigParameter } from '../configuration/serverOnconfigParameter';

export enum InformixServerStatus {
    ONLINE = 'Online',
    OFFLINE = 'Offline',
    QUISCENT = 'Quiescent'
}


export interface SqlhostsServer {
    isAddedInHq: boolean;
    isCurrentServer: boolean;
    groupName: string;
    error?: string;
    validation?: any;
    index?: number;
}

export interface SqlhostsGroups {
    groupName: string;
    servers: SqlhostsServer[];
}

export interface SqlhostsGroupInternal {
    id: number;
    parent?: string;
    groupName?: string;
    visibility?: boolean;
    isChild?: boolean;
    disabled?: boolean;
    checked?: boolean;
    validation?: SqlhostsGroupValidation;
    spaces?: ServerSpace[];
    onConfigParams?: ServerOnconfigParameter[];
    configuration?: NodeConfiguration;
    node?: any;
    members?: any;
    isCurrentGroup?: boolean;
}
export interface AdvancedOptions {
    ats: 1 | 2 | 3;
    atsFilePath?: string;
    ris: 1 | 2 | 3;
    risFilePath?: string;
    fileFormat: 1 | 2 | 3;
    idleTimeOut: boolean;
    mode: string;
    qodGroupName: string;
    qodState: boolean;
    timeOut: number;
    isSameMaster?: boolean;
    masterNodeName?: string;
    dbSpace?: string;
}

export interface AdvancedOptionsMap {
    nodeType: {[key: string]: string };
    ats: {[key: string | number]: string };
    ris: {[key: string | number]: string };
    fileFormat: {[key: string | number]: string };
    idleTimeOut: {[key: string | number]: string };
}

export interface NodeConfiguration {
    groupName: string;
    isFirstServerInDomain: boolean;
    nodeType: string;
    optional: AdvancedOptions;
    sbSpace: string[];
    syncServer: {
        id: number;
        name: string;
    };
    existingSbSpace: string;
    existingDbSpace: string;
    syncServerGroupName?: string;
}
export interface SqlhostsServerInternal {
    name: string;
    index?: number;
    id: number;
    parent?: string;
    groupName?: string;
    visibility?: boolean;
    isChild?: boolean;
    disabled?: boolean;
    checked?: boolean;
    isCurrentServer?: boolean;
    validation?: SqlhostsGroupValidation;
    spaces?: ServerSpace[];
    onConfigParams?: ServerOnconfigParameter[];
    configuration?: NodeConfiguration;
    node?: any;
}

// tslint:disable-next-line: no-empty-interface
export interface SqlhostsGroupsServersInternal extends SqlhostsGroupInternal, SqlhostsServerInternal { }

export interface SqlhostsAddedServers {
    [name: string]: boolean | null | undefined;
}

export interface ServerStatus {
    serverId: number;
    hasAccess: boolean;
    status?: string;
    err?: string;
}

export interface ERDomainClass {
    domain: ERDomain;
    selectedNode: ERNode;
}

export enum ERDomainModes {
    ADD,
    VIEW
}
export enum ERDomainOperation{
    PROGRESS,
    COMPLETE,
    START,
}

export enum ERDomainStatus {
    EMPTY,
    CREATE,
    MODIFY
}
export enum ERNodeOperationStatus {
    PROGRESS,
    SUCCESS,
    ERROR
}

export enum SqlhostsGroupValidationStatus {
    PROGRESS,
    SUCCESS,
    ERROR,
    NONE
}

export interface ERNodeOperation {
    status: ERNodeOperationStatus | SqlhostsGroupValidationStatus;
    $sub?: any;
    errorMsg?: string;
};

// tslint:disable-next-line: no-empty-interface
export type SqlhostsGroupValidation = ERNodeOperation;

export enum ERDomainView {
    REVIEW,
    PROCESSED,
}
export enum ERDomainUserSelection {
    REVIEW,
    PROCESSED,
    REVIEW_PROCESSED,
    NONE
}

export interface commandObj {
    command: string;
    success: boolean;
    err?: string;
}
export interface NodeList {
    payload: any;
    domainNodeIndex: number;
    groupName: string;
    name: string;
    typeName: string;
    dependentNodes: Array<number>;
    operation?: ERNodeOperation;
    command?: {
        sbSpaceCommand?: commandObj;
        dbSpaceCommand?: commandObj;
        defineServerCommand?: commandObj;
    };
}
export interface ERDomainState {
    status: ERDomainStatus;
    operation: ERDomainOperation;
    mode: ERDomainModes;
    hasFailure: Boolean;
    userSelection: ERDomainUserSelection;
    nodeList?: NodeList[];
    fullScreen?: Boolean;
    localNodeID?: number;
    showHelpScreen?: boolean;
    showPrerequiesties?: Boolean;
    currentGroup?: SqlhostsGroupInternal;
    stats: {
        total: number;
        defined: number;
        failed: number;
      };
    statusMsg: string;
    hasChanged?: Boolean;
}

export interface PreSelectedGroups {
    [key: string]: boolean | null;
}

export interface SidebarsType {
    groupList: boolean;
    defineNode: boolean;
    erInfo: boolean;
}

export enum ProfileStatus {
    LOADING,
    NOT_AVAILABLE,
    AVAILALBE,
    ERROR,
    UNABLE_TO_CONNET
}
