/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { InformixServerLogsService } from './informixServerLogs.service';
import { ServerBreadcrumb } from '../serverBreadcrumb';


@Component({
  selector: 'app-admin-api-log',
  templateUrl: 'admin-api-log.html'
})
export class AdminAPILogComponent implements OnInit, OnDestroy {

  dataLoading = false;
  dataLoadErrorMessage: string = null;

  server: InformixServer = null;
  adminAPICommandHistory: any[] = null;
  filteredAdminAPICommandHistory: any[] = null;

  statusFilter = 'all';
  timeFilter = '7';

  breadcrumb: BreadcrumbElement[] = null;
  private logsBreadCrumb: BreadcrumbElement = { name: 'Logs' };
  private adminapiLogBreadCrumb: BreadcrumbElement = { name: 'Admin API Log' };

  private adminApiRequestSub: Subscription;

  constructor(
    private logsService: InformixServerLogsService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  ngOnDestroy() {
    if (this.adminApiRequestSub) {
      this.adminApiRequestSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.setBreadcrumb();
    if (this.server.hasMonitorPassword) {
      this.dataLoading = true;
      this.refreshData();
    }
  }

  private setBreadcrumb(action?: string) {
    const breadcrumbs: BreadcrumbElement[] = [this.logsBreadCrumb, this.adminapiLogBreadCrumb];
    this.breadcrumb = ServerBreadcrumb.build(this.server, breadcrumbs);
  }

  refreshData() {
    if (this.adminApiRequestSub) {
      this.adminApiRequestSub.unsubscribe();
    }

    let since = null;
    if (parseInt(this.timeFilter, 10) > 0) {
      // time filter is specified in days
      since = Date.now() - (parseInt(this.timeFilter, 10) * 24 * 60 * 60 * 1000);
    }
    this.dataLoading = true;
    this.adminApiRequestSub = this.logsService.getAdminAPILog(this.server, since).subscribe(data => {
      this.dataLoading = false;
      this.adminAPICommandHistory = data;
      this.adminAPICommandHistory.forEach(entry => {
        entry.cmd_exec_time = entry.cmd_exec_time.$date;
      });
      this.applyFilter();
    }, err => {
      this.dataLoading = false;
      console.error('Could not get admin api log', err);
      this.dataLoadErrorMessage = (err.error.err) ? err.error.err : err;
    });
  }

  private applyFilter() {
    if (!this.adminAPICommandHistory) {
      return;
    }
    this.filteredAdminAPICommandHistory = this.adminAPICommandHistory.filter(cmd => {
      if (this.statusFilter === 'error') {
        return cmd.cmd_ret_status < 0;
      } else if (this.statusFilter === 'success') {
        return cmd.cmd_ret_status >= 0;
      } else {
        return true;
      }
    });
  }

  changeStatusFilter(filter) {
    this.statusFilter = filter;
    this.applyFilter();
  }

  changeTimeFilter(filter) {
    this.timeFilter = filter;
    this.refreshData();
  }

}
