/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../informixServer';
import { HttpClient } from '@angular/common/http';
import { LogResponse } from './log.model';

@Injectable()
export class InformixServerLogsService {

  constructor(private httpClient: HttpClient) { }

  public getAdminAPILog(server: InformixServer, from: number): Observable<any[]> {
    let url = 'informix/' + server.id + '/logs/adminapi';
    if (typeof from === 'number') {
      url += '?from=' + from;
    }
    return this.httpClient.get<any[]>(url);
  }

  public getLog(server: InformixServer, logType: string, fromOffset?: number, toOffset?: number): Observable<any[]> {
    let url: string = null;
    if (logType === 'Online') {
      url = 'informix/' + server.id + '/logs/onlinelog';
    } else {
      url = 'informix/' + server.id + '/logs/baractlog';
    }
    if (typeof fromOffset === 'number') {
      url += '?offset=' + fromOffset;
      if (typeof toOffset === 'number') {
        url += '&offset2=' + toOffset;
      }
    }
    return this.httpClient.get<any[]>(url);
  }


  public getSystemLogs(logFile: String, fromOffset?: number, toOffset?: number): Observable<any[]> {
    let url: string = null;
      url = 'logs';
    let payload: any = {};

    if(typeof logFile === 'string') {
      payload.logFile = logFile;
    }
    if (typeof fromOffset === 'number') {
      payload.fromOffset = fromOffset;
    }
    if (typeof toOffset === 'number') {
      payload.toOffset = toOffset;
    }
    return this.httpClient.post<any[]>(url, payload);
  }

  public getSystemLogFiles(): Observable<any[]> {
    return this.httpClient.get<any[]>('logs/logfiles');
  }

  public getOnlineLogRotation(server: InformixServer): Observable<LogResponse> {
    const url = 'informix/' + server.id + '/logs/onlinelog/config';
    return this.httpClient.get<LogResponse>(url);
  }

  public saveOnlineLogRotation(server: InformixServer, rotationEnabled?: boolean, rotationFrequency?: number, maxFiles?: number) {
    const url = 'informix/' + server.id + '/logs/onlinelog/config';
    const onlineJson = { rotationEnabled, maxFiles, rotationFrequency };
    return this.httpClient.put<LogResponse>(url, onlineJson);
  }

  public getOnbarLogRotation(server: InformixServer): Observable<LogResponse> {
    const url = 'informix/' + server.id + '/logs/baractlog/config';
    return this.httpClient.get<LogResponse>(url);
  }

  public saveOnbarLogRotation(server: InformixServer, rotationEnabled?: boolean, rotationFrequency?: number, maxFiles?: number) {
    const url = 'informix/' + server.id + '/logs/baractlog/config';
    const onlineJson = { rotationEnabled, maxFiles, rotationFrequency };
    return this.httpClient.put<LogResponse>(url, onlineJson);
  }
}
