/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Subject } from 'rxjs';

export abstract class AbstractDashboardPluginConfig {
  configChanged = new Subject<any>();

  abstract setConfig(config: any);
}
