/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';
import { IntervalOption } from '../../dashboard/monitoring/dashboard/dashboard-time-control/dashboard-time-control.component';

// Convert seconds to IntervalOption
@Pipe({ name: 'secondsToInterval' })
export class SecondsToIntervalPipe implements PipeTransform {
  transform(seconds: number): IntervalOption {
    const interval: IntervalOption = { seconds: 14400, name: 'last 4 hours' };

    switch (seconds) {
      case 300:
        interval.name = 'last 5 minutes';
        interval.seconds = seconds;
          return(interval);

      case 3600:
        interval.name = 'last hour';
        interval.seconds = seconds;
          return(interval);

      case 24 * 3600:
        interval.name = 'last day';
        interval.seconds = seconds;
          return(interval);

      case 7 * 24 * 3600:
        interval.name = 'last week';
        interval.seconds = seconds;
          return(interval);

      case 30 * 24 * 3600:
        interval.name = 'last month';
        interval.seconds = seconds;
          return(interval);

      case 365 * 24 * 3600:
        interval.name = 'last year';
        interval.seconds = seconds;
          return(interval);

      case 737841 * 24 * 3600:
        interval.name = 'all time';
        interval.seconds = seconds;
          return(interval);

      default:
          return(interval);
    }
  }
}
