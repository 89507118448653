/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(
    private modalService: BsModalService
  ) { }

  show(message: string, callback: Function);
  show(message: string, callback: Function, closeCallback: Function);
  show(title: string, message: string, callback: boolean | Function);
  show(a: string, b: string | Function, c?: boolean | Function) {
    let title = a;
    let message = b;
    let callback = c;
    let closeCallback;
    if (typeof b === 'function') {
      title = 'Confirm';
      message = a;
      callback = b;
      closeCallback = c;
    }

    message = 'Are you sure you want to ' + message;
    if (typeof callback === 'boolean') {
      message = b;
    }

    const initialState: any = {
      title,
      message,
      callback,
      closeCallback
    };

    return this.modalService.show(ConfirmationDialogComponent, { initialState, class: 'modal-dialog-centered' });
  }
}
