/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Type } from '@angular/core';

import { AbstractAlertOperand } from './abstract-alert-operand';
import { constantOperandClasses } from './const/constant-operand-classes';
import { LatestMetricOperandComponent } from './latest-metric-operand.component';
import { ServerStatusOperandComponent } from './server-status-operand.component';

export interface AlertOperandType {
  component: Type<AbstractAlertOperand>;
  type: string;
  description: string;
  outputType?: string;
  triggers: string[];
}

export const alertOperandComponents: Type<AbstractAlertOperand>[] = [
  LatestMetricOperandComponent,
  ServerStatusOperandComponent,
  ... constantOperandClasses
];

export const alertOperandTypes: AlertOperandType[] = [
  {
    component: LatestMetricOperandComponent,
    type: 'metric',
    description: 'Latest value of a metric',
    triggers: ['event']
  },
  {
    component: ServerStatusOperandComponent,
    type: 'server-status',
    description: 'Current status of Informix',
    outputType: 'server-status',
    triggers: ['event']
  },
  // TODO: Create a base value-less alert operand component
  {
    component: ServerStatusOperandComponent,
    type: 'agent-status',
    description: 'Agent status',
    outputType: 'agent-status',
    triggers: ['event']
  }
];
