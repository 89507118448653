/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { TreeViewNode } from '../../../shared/tree-view/tree-view-node';

export class SqltraceIteratorTreeNode extends TreeViewNode {
  public sql_itr_id: number;
  public sql_itr_left: number;
  public sql_itr_right: number;
  public sql_itr_cost: number;
  public sql_itr_estrows: number;
  public sql_itr_numrows: number;
  public sql_itr_type: number;
  public sql_itr_info: string;
  public sql_itr_time: number;
  public sql_itr_partnum: number;
  public sql_itr_table: string;

  constructor(
    sql_itr_id: number,
    sql_itr_left: number,
    sql_itr_right: number,
    sql_itr_cost: number,
    sql_itr_estrows: number,
    sql_itr_numrows: number,
    sql_itr_type: number,
    sql_itr_info: string,
    sql_itr_time: number,
    sql_itr_partnum: number,
    sql_itr_table: string
  ) {
    super();

    this.sql_itr_id = sql_itr_id;
    this.sql_itr_left = sql_itr_left;
    this.sql_itr_right = sql_itr_right;
    this.sql_itr_cost = sql_itr_cost;
    this.sql_itr_estrows = sql_itr_estrows;
    this.sql_itr_numrows = sql_itr_numrows;
    this.sql_itr_type = sql_itr_type;
    this.sql_itr_info = sql_itr_info;
    this.sql_itr_time = sql_itr_time;
    this.sql_itr_partnum = sql_itr_partnum;
    this.sql_itr_table = sql_itr_table;
    if ((this.sql_itr_table == null || this.sql_itr_table === undefined) && this.sql_itr_partnum > 0 && this.sql_itr_partnum < 1048576) {
      // pseudo table, so show partnum as table name
      this.sql_itr_table = this.sql_itr_partnum.toString(16).toUpperCase();
      while (this.sql_itr_table.length < 8) {
        this.sql_itr_table = '0' + this.sql_itr_table;
      }
      this.sql_itr_table = '0x' + this.sql_itr_table;
    }
  }
}
