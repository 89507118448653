<div class="container-fluid page-container">
  <h3>Data Cleanup Configuration</h3>
  <p>Configure schedule and settings for enforcing the data retention policies for sensors across all monitored database
    servers.</p>
  <form [formGroup]="formGroup">
    <div class="form-group">
      <h5>Schedule &nbsp;&nbsp;
        <small class="text-muted">Configure when data cleanup activities are run</small>
      </h5>
      <div class="form-group row">
        <div class="col-xs-4 col-sm-2 col-form-label text-sm-center">Run cleanup on</div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="monday">Monday</app-checkbox>
        </div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="tuesday">Tuesday</app-checkbox>
        </div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="wednesday">Wednesday</app-checkbox>
        </div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="thursday">Thursday</app-checkbox>
        </div>
        <div class="col-sm-2 col-xl-6 hidden-xs-down"></div>
        <div class="col-sm-2 hidden-xs-down"></div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="friday">Friday</app-checkbox>
        </div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="saturday">Saturday</app-checkbox>
        </div>
        <div class="form-check form-inline col-xs-4 col-sm-2 col-xl-1">
          <app-checkbox formControlName="sunday">Sunday</app-checkbox>
        </div>
      </div>
      <div class="form-group row form-inline">
        <label class="col-xs-12 col-xs-4 col-sm-2 col-form-label">Start time</label>
        <input type="text" class="form-control" formControlName="startTime" tooltip=""
          [customErrors]="startTimeErrorHandlers" appExtendedFormControl>
      </div>
    </div>
    <h5>Transaction Size &nbsp;&nbsp;
      <small class="text-muted">Configure the number of expired entries that can be deleted per transaction</small>
    </h5>
    <div class="form-group row form-inline">
      <label class="col-xs-12 col-xs-4 col-sm-2 col-form-label">Rows Per Transaction</label>
      <input type="number" class="form-control" formControlName="rowsPerTxn" min="1" tooltip="" appExtendedFormControl>
    </div>
  </form>

  <button class="btn btn-primary mt-2" [disabled]="!formGroup.valid" (click)="saveConfig()">Save</button>
</div>
