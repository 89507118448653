/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component } from '@angular/core';

import { TreeViewNode } from '../../shared/tree-view/tree-view-node';

@Component({
  selector: 'app-playground-tree-view',
  template: `
  <section class="main-container" style="margin-left: 0">
    <app-tree-view [node]="testRootNode" style="display: block; overflow-x: auto">
      <ng-template nodeTemplate2 let-node="node">
        <div style="text-align: center">{{node.value}}</div>
      </ng-template>

      <ng-template nodeTemplate let-node="node">
        <div class="card" style="width: 200px; margin: 0 auto">
          <div class="card-header">{{node.values[0]}}</div>
          <div class="card-body">
            <p *ngFor="let value of node.values">{{value}}</p>
          </div>
        </div>
      </ng-template>
    </app-tree-view>
  </section>
  `
})
export class PlaygroundTreeViewComponent {

  testRootNode: MyTreeNode = new MyTreeNode('Root!', [
    new MyTreeNode('Child 1', [
      new MyTreeNode('Child 1 - 1'),
      new MyTreeNode(['Child 1 - 2', 'Extra stuff on another line!'])
    ]),
    new MyTreeNode('Child 2', [
      new MyTreeNode('Child 2 - 1', [
        new MyTreeNode(['Child 2 - 1 - 1', 'More!', 'EVEN MORE!!!!!', 'HAHAHAH'], [
          new MyTreeNode('Child 2 - 1 - 1 - 1')
        ]),
        new MyTreeNode('Child 2 - 1 - 2', [
          new MyTreeNode('Child 2 - 1 - 2 - 1'),
          new MyTreeNode('Child 2 - 1 - 2 - 2'),
          new MyTreeNode('Child 2 - 1 - 2 - 3'),
          new MyTreeNode('Child 2 - 1 - 2 - 4')
        ])
      ])
    ])
  ]);
}

class MyTreeNode extends TreeViewNode {
  public values: string[];

  constructor(
    value: string | string[],
    children: TreeViewNode[] = []
  ) {
    super();

    this.values = typeof value == 'string' ? [value] : value;
    children.forEach(child => this.addChild(child));
  }
}
