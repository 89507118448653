/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'app-log-window',
  template: `
  <ng-content></ng-content>
  <div [hidden]="!newDataAvailable" class="overlay-notification" (click)="scrollToBottom()">
    New data available...
  </div>
  `,
  styleUrls: ['log-window.scss']
})
export class LogWindowComponent implements AfterViewInit, AfterViewChecked {

  newDataAvailable = false;

  private scrollLock = true;
  private topReached = false;
  @Output() eventTopReached = new EventEmitter<any>();

  private contentDiv: HTMLDivElement;
  private contentDivScrollHeight: number = null;
  private scrollWithHeight = false;

  constructor(
    private contentRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.contentDiv = this.contentRef.nativeElement;
  }

  ngAfterViewChecked() {
    if (this.contentDiv) {
      if (this.scrollLock) {
        this.contentDiv.scrollTop = this.contentDiv.scrollHeight;
      } else if (this.contentDivScrollHeight !== null && this.scrollWithHeight
        && this.contentDiv.scrollHeight > this.contentDivScrollHeight) {
        this.contentDiv.scrollTop += (this.contentDiv.scrollHeight - this.contentDivScrollHeight);
        this.scrollWithHeight = false;
      }

      this.contentDivScrollHeight = this.contentDiv.scrollHeight;

      if (this.contentDiv.scrollTop === 0 && !this.topReached) {
        this.topReached = true;
        this.eventTopReached.emit(null);
      }
    }
  }

  public onDataAddedToTop() {
    this.topReached = false;
    this.scrollWithHeight = true;
  }

  public onDataAddedToBottom() {
    if (!this.scrollLock) {
      this.newDataAvailable = true;
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    if (this.contentDiv) {
      this.scrollLock = this.contentDiv.scrollTop + this.contentDiv.clientHeight >= this.contentDiv.scrollHeight;
      if (this.newDataAvailable && this.scrollLock) {
        this.newDataAvailable = false;
      }

      const topReached = this.contentDiv.scrollTop === 0;
      // Only emit the event when the topReached flag switches from false to true
      if (!this.topReached && topReached) {
        this.eventTopReached.emit(null);
      }
      this.topReached = topReached;
    }
  }

  scrollToBottom() {
    if (this.contentDiv) {
      this.contentDiv.scrollTop = this.contentDiv.scrollHeight;
      this.scrollLock = true;
    }
  }
}
