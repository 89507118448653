/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as Chart from 'chart.js';

import { ChartJSUtils } from '../../../shared/chartjs.utils';
import { InformixServer } from '../informixServer';
import { SqltraceService } from './sqltrace.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sqltrace-stmt-types',
  templateUrl: 'sqltrace-stmt-types.html'
})
export class SqltraceStatementTypesComponent implements OnInit, OnDestroy {

  @Input() server: InformixServer;

  stmtsByType: any[] = null;

  dataLoadErrorMessage: string = null;

  @ViewChild('stmtsByTypeChart') stmtsByTypeChartCanvas: ElementRef;
  stmtsByTypeChart: any;

  private chartData: number[];
  private chartLabels: string[];
  private chartColors: string[];
  private chartLabelColorMap: { [key: string]: string } = {
    SELECT: ChartJSUtils.getDefaultColor(0),
    INSERT: ChartJSUtils.getDefaultColor(1),
    UPDATE: ChartJSUtils.getDefaultColor(5),
    DELETE: ChartJSUtils.getDefaultColor(3),
    DATABASE: ChartJSUtils.getDefaultColor(7),
    'CLOSE DATABASE': ChartJSUtils.getDefaultColor(2),
    'EXEC PROCEDURE': ChartJSUtils.getDefaultColor(4),
    'CREATE TABLE': ChartJSUtils.getDefaultColor(6)
  };

  private sqltraceDataPollTimeout: number = null;
  private pollInterval = 10000; // TODO: make this a configurable setting

  constructor(
    private sqltraceService: SqltraceService
  ) { }

  ngOnInit() {
    this.refreshData();
  }

  ngOnDestroy() {
    if (this.sqltraceDataPollTimeout) {
      window.clearTimeout(this.sqltraceDataPollTimeout);
    }
  }

  private refreshData() {
    this.sqltraceService.getStatementsByType(this.server).then(data => {
      this.stmtsByType = data;
      this.graphData(data);

      this.sqltraceDataPollTimeout = window.setTimeout(() => {
        this.refreshData();
      }, this.pollInterval);
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace statements by type', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private graphData(data: any[]) {
    this.chartLabels = [];
    this.chartData = [];
    this.chartColors = [];
    data.forEach(element => {
      this.chartLabels.push(element.stmt_type);
      this.chartData.push(element.count);
      this.chartColors.push(this.getColor(element.stmt_type));
    });

    if (!this.stmtsByTypeChart) {
      this.createChart();
    }

    this.stmtsByTypeChart.data.datasets[0].data = this.chartData;
    this.stmtsByTypeChart.data.datasets[0].backgroundColor = this.chartColors;
    this.stmtsByTypeChart.data.labels = this.chartLabels;
    this.stmtsByTypeChart.update();
  }

  private getColor(stmt_type: string): string {
    let color: string = this.chartLabelColorMap[stmt_type];
    if (!color) {
      color = ChartJSUtils.getRandomColor();
      this.chartLabelColorMap[stmt_type] = color;
    }
    return color;
  }

  private createChart() {
    if(!this.stmtsByTypeChartCanvas) {
      return;
    }
    this.stmtsByTypeChart = new Chart(this.stmtsByTypeChartCanvas.nativeElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: this.chartData,
            backgroundColor: this.chartColors
          }
        ],
        labels: this.chartLabels
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index'
        },
        legend: {
          position: 'right'
        }
      }
    });
  }
}
