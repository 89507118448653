/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from './../serverBreadcrumb';
import { SqltraceService } from './sqltrace.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sqltrace-txns',
  templateUrl: 'sqltrace-txns.html'
})
export class SqltraceTransactionsComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private sqltraceBreadCrumb: BreadcrumbElement = {
    name: 'SQL Tracing',
    link: 'sqltrace'
  };
  private txnsBreadCrumb: BreadcrumbElement = {
    name: 'Transactions',
  };

  server: InformixServer = null;

  txnData: any[] = null;

  dataLoadErrorMessage: string = null;

  constructor(
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getTransactions();
    });
  }

  refreshData() {
    this.getTransactions();
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.sqltraceBreadCrumb, this.txnsBreadCrumb]);
  }

  private getTransactions() {
    this.sqltraceService.getTransactions(this.server).then(txns => {
      this.txnData = txns;
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace transactions', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }
}
