
<div *ngIf="clusterInfo?.exceptionMessage" class="mt-4 mb-0 alert alert-warning">
  <i class="icon icon-warning text-warning"></i> {{'dataMissing' | translate}} {{clusterInfo.exceptionMessage}}
</div>
<div class="row d-flex mb-4" *ngIf="!serverNotInCluster && clusterInfo?.clusterNodeList else serverInfoLoading">
  <div class="col-xl-6 col-lg-6 col-12 mt-4 d-flex">
    <div class="card card-body card-style pl-2 pb-0">
      <div class="text-nowrap overflow-auto">
        <div class="float-left">
          <div class="text-center d-inline-block pt-1 server-width" [tooltip]="primaryServerInfo?.name">
            <ng-template [ngTemplateOutlet]="serverButton" [ngTemplateOutletContext]="{server: primaryServerInfo}">
            </ng-template>
          </div>
          <div class="d-inline-block line-width border-top color-light-gray-70 pb-3 pt-2 pl-3 align-bottom border-gray-20"
            *ngIf="hdrServerInfo">
          </div>
          <div class="line-width border-left border-bottom ml-5 color-light-gray-70 pt-5 pb-4"
            *ngIf="sdsServersInfo.length  > 0 || rssServersInfo.length > 0"></div>
          <div class="line-width border-left border-bottom mb-3 ml-5 color-light-gray-70 pt-5 pb-5"
            *ngIf="sdsServersInfo.length > 0 && rssServersInfo.length > 0"></div>
        </div>
        <div class="server-width text-truncate text-center mb-4" [tooltip]="hdrServerInfo?.name"
          [ngClass]="!hdrServerInfo ? 'pt-5 pb-3': 'pt-1'">
          <ng-container *ngIf="hdrServerInfo">
            <ng-template [ngTemplateOutlet]="serverButton" [ngTemplateOutletContext]="{server: hdrServerInfo}">
            </ng-template>
          </ng-container>
        </div>
        <ng-container *ngIf="sdsServersInfo.length > 0">
          <div *ngFor="let server of sdsServersInfo" class="d-inline-block text-center pt-1 mb-4 mt-2 server-width"
            [tooltip]="server?.name">
            <ng-template [ngTemplateOutlet]="serverButton" [ngTemplateOutletContext]="{server: server}"></ng-template>
          </div><br>
        </ng-container>
        <ng-container *ngIf="rssServersInfo.length > 0">
          <div *ngFor="let server of rssServersInfo" class="d-inline-block text-center pt-1 mt-2 mb-4 pb-1 server-width"
            [tooltip]="server?.name">
            <ng-template [ngTemplateOutlet]="serverButton" [ngTemplateOutletContext]="{server: server}"></ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-12 d-flex mt-4">
    <div class="card card-body card-style">
      <h5 *ngIf="currentServer?.type === 'PRIMARY'">
        <strong>{{'highAvailability.clusterTopology.clusterStatusTitle' | translate}}</strong></h5>
      <h5 *ngIf="currentServer?.type === 'HDR SECONDARY'">
        <strong>{{'highAvailability.clusterTopology.hdrStatusTitle' | translate}}</strong></h5>
      <h5 *ngIf="currentServer?.type === 'SDS'">
        <strong>{{'highAvailability.clusterTopology.sdsStatusTitle' | translate}}</strong></h5>
      <h5 *ngIf="currentServer?.type === 'RSS'">
        <strong>{{'highAvailability.clusterTopology.rssStatusTitle' | translate}}</strong></h5>
      <div *ngIf="clusterStatusInfo else clusterStatusInfoLoading">
        <ng-template [ngIf]="currentServer?.type === 'PRIMARY'">
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.connectionManagerLabel' | translate}}
              :
            </div>
            <div class="col-6">{{clusterStatusInfo?.activeConnectionManagers}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">
              {{'highAvailability.clusterTopology.failoverArbitrationLabel' | translate}}
              :
            </div>
            <div class="col-6">{{clusterStatusInfo?.failoverArbitration}}</div>
          </div>
        </ng-template>
        <div class="row mt-3" *ngIf="currentServer?.type === 'RSS' || currentServer?.type === 'SDS'">
          <div class="col-6 text-right p-0">Last log page received(log id,page)
            :
          </div>
          <div class="col-6">{{clusterStatusInfo?.lastLogPageReceived}}</div>
        </div>
        <div class="row mt-3" *ngIf="currentServer?.type === 'HDR SECONDARY'">
          <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.lastLogPageAppliedLabel' | translate}} :
          </div>
          <div class="col-6">{{clusterStatusInfo?.lastLogPageApplied}}</div>
        </div>
        <ng-template [ngIf]="currentServer?.type === 'SDS'">
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.nextLogPageReadLabel' | translate}} :
            </div>
            <div class="col-6">{{clusterStatusInfo?.nextLogPageRead}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.lastLSNAckedLabel' | translate}} :</div>
            <div class="col-6">{{clusterStatusInfo?.lastLSNAcked}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.currentPagingFileSizeLabel' | translate}} :</div>
            <div class="col-6">{{clusterStatusInfo?.currentPagingFileSize}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.oldPagingFileSizeLabel' | translate}} :</div>
            <div class="col-6">{{clusterStatusInfo?.oldPagingFileSize}}</div>
          </div>
        </ng-template>
        <ng-template [ngIf]="currentServer?.type === 'RSS' || currentServer?.type === 'SDS'">
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.sequenceNoLastBufferReceivedLabel' | translate}} :</div>
            <div class="col-6">{{clusterStatusInfo?.sequenceNoLastBufferReceived}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.sequenceNoLastBufferAckedLabel' | translate}} : </div>
            <div class="col-6">{{clusterStatusInfo?.sequenceNoLastBufferAcked}}</div>
          </div>
        </ng-template>
        <ng-template [ngIf]="currentServer?.type === 'RSS'">
          <div class="row mt-3">
            <div class="col-6 text-right p-0">
              {{'highAvailability.clusterTopology.delayApplyStatisticsLabel' | translate}}
              :</div>
            <div class="col-6">{{clusterStatusInfo?.delayApplyStatistics}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">
              {{'highAvailability.clusterTopology.stopApplyStatisticsLabel' | translate}}
              :
            </div>
            <div class="col-6">{{clusterStatusInfo?.stopApplyStatistics}}</div>
          </div>
        </ng-template>
        <div class="row mt-3" *ngIf="currentServer?.type === 'HDR SECONDARY' && clusterStatusInfo?.backlog">
          <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.backlog' | translate}} :</div>
          <div class="col-6">{{clusterStatusInfo?.backlog}}</div>
        </div>
        <div class="row mt-3" *ngIf="currentServer?.type === 'HDR SECONDARY' && clusterStatusInfo?.catchupBacklog">
          <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.catchupBacklog' | translate}} :</div>
          <div class="col-6">{{clusterStatusInfo?.catchupBacklog}}</div>
        </div>
        <ng-template [ngIf]="currentServer?.type !== 'PRIMARY'">
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.transactionLatencyLabel' | translate}}
              :
            </div>
            <div class="col-6">{{clusterStatusInfo?.transactionLatency}}</div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right p-0">{{'highAvailability.clusterTopology.applyRateLabel' | translate}}
              :
            </div>
            <div class="col-6">{{clusterStatusInfo?.applyRate}}</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- Show warning message if primary is not present. -->
<div *ngIf="clusterInfo?.primaryNotPresent" class="mb-0 alert alert-warning">
  <i class="icon icon-warning text-warning"></i> {{'dataMissing' | translate}} {{clusterInfo.primaryNotPresent}}<a
    [routerLink]="['/dashboard/groups', server.parentGroupId, 'add-server']"> {{'link.clickHere' | translate}}</a>
  {{'toAdd' | translate}}
</div>

<app-data-table *ngIf="clusterInfo?.clusterNodeList" [data]="clusterInfo.clusterNodeList" [rowsPerPage]="10"
  [searchLabel]="'dataTable.searchLabel' | translate" [minHeight]="200" >
  <app-data-table-column [title]="'highAvailability.clusterTopology.table.columnTitle.server' | translate"
    property="name" [sortable]="true" [searchable]="true">
    <ng-template appTableCell let-serverInfo="row">
      <a (click)="redirect(serverInfo)" href="javascript:void(0)">{{serverInfo.name}}</a>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'dataTable.columnTitle.type' | translate" property="type" [sortable]="true"
    [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'dataTable.columnTitle.replicationStatus' | translate" [sortable]="true"
    property="status">
    <ng-template appTableCell let-serverStatus="item">
      <div
        [ngClass]="serverStatusList[serverStatus?.toUpperCase()] ? ('text-' + serverStatusList[serverStatus?.toUpperCase()]) : 'text-danger'">
        {{serverStatus}}</div>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.clusterTopology.table.columnTitle.connectionStatus' | translate"
    property="connectionStatus" [sortable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.clusterTopology.table.columnTitle.updatable' | translate"
    property="updatable">
    <ng-template appTableCell let-updatable="item">
      <app-status-icon [status]="updatable === 'Y'" *ngIf="updatable" class="pl-4"></app-status-icon>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.clusterTopology.table.columnTitle.workload' | translate"
    property="workload" [sortable]="true">
    <ng-template appTableCell let-workload="item">
      {{workload | number: '1.2-2'}} {{workload ? '%' : ''}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.clusterTopology.table.columnTitle.lagtime' | translate"
    property="lagtime" [sortable]="true">
    <ng-template appTableCell let-lagtime="item">
      {{lagtime | number: '1.5-5'}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'highAvailability.clusterTopology.table.columnTitle.approxLogBacklog' | translate">
    <ng-template appTableCell let-serverInfo="row">
      <div *ngIf="serverInfo.type === 'PRIMARY' else secondaryServerLogBacklog">
        {{serverInfo.approxLogBacklog || serverInfo.approxLogBacklog === '0' ? serverInfo.approxLogBacklog : '-'}}
      </div>
      <ng-template #secondaryServerLogBacklog>{{serverInfo.approxLogBacklog}}</ng-template>
    </ng-template>
  </app-data-table-column>
</app-data-table>
<ng-template #serverButton let-server="server">
  <button class="position-relative bg-white border-0 p-0 w-100" (click)="redirect(server)"
    [ngClass]="{'color-blue': server?.selected, 'color-black' : !server?.selected}">
    <div class="position-absolute rounded-circle server-status"
      [ngClass]="serverStatusList[server?.status?.toUpperCase()] ? ('bg-' + serverStatusList[server?.status?.toUpperCase()]) : 'bg-danger'">
    </div><i class="icon icon-2x" [ngClass]="{'color-blue': server?.selected, 'color-light-gray-70': !server?.selected,
      'icon-primary-server': server?.type === 'PRIMARY', 'icon-hdr-server': server?.type === 'HDR SECONDARY',
      'icon-sds-server': server?.type === 'SDS', 'icon-rss-server': server?.type === 'RSS'}"></i>
    <div class="text-truncate">{{server?.name}}</div>
    {{server?.type}}
  </button>
</ng-template>
<ng-template #serverInfoLoading>
  <div class="d-flex justify-content-center" *ngIf="dataLoading else serverError;">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
  </div>
  <ng-template #serverError>
    <div *ngIf="serverNotInCluster" class="mt-4 alert alert-warning">
      <i class="icon icon-warning text-warning"></i> {{serverNotInCluster}}
    </div>
    <div *ngIf="clusterServerError" class="mt-2 alert alert-danger">
      <i class="icon icon-danger text-danger"></i> Unable to retrieve cluster topology. <br> Error: {{clusterServerError}}
    </div>
  </ng-template>
</ng-template>
<ng-template #clusterStatusInfoLoading>
  <div *ngIf="!currentServer?.id && !currentServer?.idException" class="alert alert-warning">
    <i class="icon icon-warning text-warning"></i> <span class="pl-1"
      [innerHTML]="'highAvailability.serverNotFound' | translate: currentServer"></span> <a
      [routerLink]="['/dashboard/groups', server.parentGroupId, 'add-server']"> {{'link.clickHere' | translate}}</a>
    {{'toAdd' | translate}}
  </div>
  <div *ngIf="currentServer?.idException" class="alert alert-warning">
    <i class="icon icon-warning text-warning"></i> {{currentServer?.idException}}
  </div>
  <div *ngIf="clusterStatusServerError" class="alert alert-danger">
    <i class="icon icon-danger text-danger"></i> {{clusterStatusServerError}}
  </div>
  <ng-template *ngIf="clusterStatusLoading then loading"></ng-template>
</ng-template>
