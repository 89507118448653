/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterContentChecked, Component, ContentChild, Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HDRPermission } from '../hdr-permission/hdr-permission';

@Directive({
  selector: '[appPermissionDisable]'
})
export class PermissionDisableDirective {

  private isAnchor = false;

  @Input() disabled: boolean;
  @Input() permissionDisabled: boolean;
  @Input() hdrPermission: HDRPermission;

  constructor(elementRef: ElementRef) {
    this.isAnchor = elementRef.nativeElement instanceof HTMLAnchorElement;
  }

  isDisabled() {
    // Also checking for HDR permission.
    return this.disabled || this.permissionDisabled || (this.hdrPermission && !this.hdrPermission.isAllow());
  }

  @HostBinding('attr.disabled')
  get attrDisabled() {
    return !this.isAnchor && this.isDisabled() ? true : null;
  }

  @HostBinding('class.disabled')
  get classDisabled() {
    return this.isAnchor && this.isDisabled();
  }
}

@Component({
  selector: 'app-check-permissions',
  template: `
  <div style="display: inline-block" [tooltip]="tooltipMsg" [placement]="placement" container="body">
    <ng-content></ng-content>
  </div>
  `
})
export class CheckPermissionsComponent implements OnChanges, AfterContentChecked {

  @Input() permissions: boolean;
  @Input() placement = 'left';

  noPermissionsMessage = 'You do not have access to this';

  @ContentChild(PermissionDisableDirective) targetContent: PermissionDisableDirective;
  private target: PermissionDisableDirective = null;

  tooltipMsg: string = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.permissions) {
      this.update();
    }
  }

  ngAfterContentChecked() {
    if (this.targetContent !== this.target) {
      this.target = this.targetContent;
      this.update();
    }
  }

  private update() {
    this.updateTooltip();
    this.updateTarget();
  }

  private updateTooltip() {
    this.tooltipMsg = this.permissions ? null : this.noPermissionsMessage;
  }

  private updateTarget() {
    if (this.target) {
      this.target.permissionDisabled = !this.permissions;
    }
  }
}
