<div class="row">
  <div class="col-6">
    <h5>
      <strong>{{ 'schemaManager.createIndex.createQuery.viewSQL' | translate }}
        <span class="h6">{{ 'schemaManager.createIndex.createQuery.reviewSQL' | translate }}</span></strong>
      <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="!isQueryLoaded"></i>
    </h5>
  </div>
  <div class="col-6">
    <button type="button" class="float-right btn btn-sm btn-icon">
      <i class="icon icon-times font-weight-bold" (click)="closeCreateIndex.emit()"></i></button>
  </div>
</div>
<div class="row bottomBorder topBorder queryContainer">
  <div class="col-12 py-2">
    <div>
      <div class="pt-3 pb-3">{{createIndexQuery}}</div>
      <div *ngIf="constraintQuery">{{constraintQuery}}</div>
    </div>
    <div class="alert alert-danger mt-2" *ngIf="createIndexError">
      {{ 'schemaManager.createIndex.createQuery.queryFailed' | translate }} : {{createIndexError}}
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <button type="button" class="btn btn-secondary mr-2" (click)="backFromQuery.emit()">
      {{ 'button.back' | translate }}</button>
    <button type="button" class="btn btn-primary mr-2" [disabled]="createIndexError || !isQueryLoaded"
      (click)="createIndex('executeQuery')">{{ 'button.create' | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="closeCreateIndex.emit()">
      {{ 'button.cancel' | translate }}</button>
  </div>
</div>
