/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
export class JSONUtils {

  public static deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== typeof obj2) {
      return false;
    }
    if (Array.isArray(obj1)) {
      if (!Array.isArray(obj2)) {
        return false;
      }
      if (obj1.length !== obj2.length) {
        return false;
      }
      for (let i = 0; i < obj1.length; i++) {
        if (!JSONUtils.deepEqual(obj1[i], obj2[i])) {
          return false;
        }
      }

      return true;
    } else if (typeof obj1 === 'object') {
      if (obj1 === null || obj2 === null) {
        return false;
      }
      if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }
      for (const key in obj1) {
        if (!JSONUtils.deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  public static deepClone(obj: any): any {
    if (obj && typeof obj === 'object') {
      if (Array.isArray(obj)) {
        const clone = [];
        for (let i = 0; i < obj.length; i++) {
          clone.push(JSONUtils.deepClone(obj[i]));
        }
        return clone;
      } else {
        const clone = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            clone[key] = JSONUtils.deepClone(obj[key]);
          }
        }
        return clone;
      }
    } else {
      return obj;
    }
  }
}
