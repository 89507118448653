/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Injectable } from '@angular/core';
@Injectable({
providedIn: 'root'
})
export class LocalStorageService {
constructor() { }

// Set value in the local storage
setItem(key: string, value: any) {
    localStorage.setItem(key, value);
}

// Get value from local storage
getItem(key: string) {
    return localStorage.getItem(key);
}

// Remove value from local storage
removeItem(key: string) {
    localStorage.removeItem(key);
}

// Clear the local storage
clear() {
    return localStorage.clear();
}
}
