/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SharedModule } from '../../../shared/shared.module';
import { SchemaModule } from '../../servers/schema/schema.module';
import { CreateCustomReportComponent } from './create-custom-report.component';
import { CustomReportListComponent } from './custom-report-list.component';
import { CustomSystemReportsService } from './custom-system-reports.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TooltipModule,
    TabsModule,
    SharedModule,
    SchemaModule
  ],
  exports: [],
  declarations: [
    CustomReportListComponent,
    CreateCustomReportComponent
  ],
  providers: [
    CustomSystemReportsService
  ],
})
export class CustomSystemReportsModule { }
