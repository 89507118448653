/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../../informixServer';
import { HttpClient } from '@angular/common/http';
import { TablesIndexesList } from './tables-indexes-page.model';
import { OptimizationPoliciesList } from './tables-indexes-page.model';
import { TaskStatusList } from './tables-indexes-page.model';
import { map } from 'rxjs/operators';

@Injectable()
export class TablesIndexesService {

  constructor(private httpClient: HttpClient) { }

  public getTablesIndexes(server: InformixServer, db: string, isCatalog: boolean, dbSpace?: number): Observable<TablesIndexesList[]> {
    let url = 'informix/' + server.id + '/storage/databases/' + db;
    if (dbSpace !== 0) {
      url += '?dbsnum=' + dbSpace + '&include_catalogs=' + isCatalog;
    } else {
      url += '?include_catalogs=' + isCatalog;
    }
    return this.httpClient.get<TablesIndexesList[]>(url).pipe(map(response => response.map(res => new TablesIndexesList(res))));
  }

  public getOptimizationPolicies(server: InformixServer): Observable<OptimizationPoliciesList> {
    const url = 'informix/' + server.id + '/storage/policies';
    return this.httpClient.get<OptimizationPoliciesList>(url);
  }
  public updateOptimizationPolicies(server: InformixServer, dataObj: any): Observable<{ ok: boolean }> {
    const url = 'informix/' + server.id + '/storage/policies';
    return this.httpClient.put<{ ok: boolean }>(url, dataObj);
  }
  public getTaskStatus(server: InformixServer): Observable<TaskStatusList[]> {
    const url = 'informix/' + server.id + '/storage/jobs';
    return this.httpClient.get<TaskStatusList[]>(url);
  }
  public updateInfo(server: InformixServer, db: string, objectList: TablesIndexesList[],
    isCatalog: boolean): Observable<TablesIndexesList[]> {
    const url = 'informix/' + server.id + '/storage/databases/' + db;
    const data = {
      action: 'estimate',
      include_catalogs: isCatalog,
      objects: objectList
    };
    return this.httpClient.put<TablesIndexesList[]>(url, data).pipe(map(response => response.map(res => new TablesIndexesList(res))));
  }
  public optimizeSpace(server: InformixServer, db: string, optimizeAction: any): Observable<any> {
    const url = 'informix/' + server.id + '/storage/databases/' + db;
    return this.httpClient.put<any>(url, optimizeAction);
  }

  public cleanupJobs(server: InformixServer): Observable<any> {
    const url = 'informix/' + server.id + '/storage/jobs/cleanup';
    return this.httpClient.delete<any>(url);
  }
}
