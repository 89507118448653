/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { EventEmitter, Injectable } from '@angular/core';
import { TimeSlice } from './timeSlices';

@Injectable()
export class TimeSliceService {

  private isPaused = false;

  private timeSliceChangedEventEmitter = new EventEmitter<TimeSlice>();
  private pauseEventEmitter = new EventEmitter<any>();
  private resumeEventEmitter = new EventEmitter<any>();

  constructor() { }

  public changeTimeSlice(timeSlice: TimeSlice) {
    this.timeSliceChangedEventEmitter.emit(timeSlice);
  }

  public pauseData(): void {
    if (this.isPaused) {
      return null;
    }
    this.isPaused = true;
    this.pauseEventEmitter.emit(null);
  }

  public resumeData(): void {
    if (!this.isPaused) {
      return null;
    }
    this.isPaused = false;
    this.resumeEventEmitter.emit(null);
  }

  public isDataPaused(): boolean {
    return this.isPaused;
  }

  public getTimeSliceChangedEventEmitter(): EventEmitter<TimeSlice> {
    return this.timeSliceChangedEventEmitter;
  }

  public getPauseEventEmitter(): EventEmitter<any> {
    return this.pauseEventEmitter;
  }

  public getResumeEventEmitter(): EventEmitter<any> {
    return this.resumeEventEmitter;
  }
}
