/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { MonitoringService } from '../../monitoring.service';
import { MonitoringProfile } from '../../monitoringProfile';
import { DashboardService } from '../dashboard.service';

@Injectable()
export class SensorChartjsService {

  private monitoringProfiles: Promise<MonitoringProfile[]> = null;

  constructor(
    private dashboardService: DashboardService,
    private monitoringService: MonitoringService,
    private notifications: NotificationsService
  ) {
    this.dashboardService.serversChanged.subscribe(() => {
      this.monitoringProfiles = null;
    });
  }

  getMonitoringProfiles(): Promise<MonitoringProfile[]> {
    if (!this.monitoringProfiles) {
      this.monitoringProfiles = Promise.all(
        this.dashboardService.getServers().map(server => this.monitoringService.getEffectiveMonitoringProfile(server))).catch(err => {
          console.error(err);
          this.notifications.pushErrorNotification('Failed to load monitoring data');
          return null;
        }).then(profiles => {
          this.monitoringProfiles = Promise.resolve(profiles);
          return profiles;
        });
    }
    return this.monitoringProfiles;
  }
}
