/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Directive, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractDashboardPluginConfig } from '../abstract-dashboard-plugin-config';
import { DashboardBuiltinPlugins } from '../dashboard-builtin-plugins';
import { DashboardPanel } from '../dashboard-panel';

@Directive({
  selector: '[appDashboardPanelConfigContent]'
})
export class DashboardPanelConfigContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-dashboard-panel-config',
  templateUrl: './dashboard-panel-config.component.html',
  styleUrls: ['./dashboard-panel-config.component.scss']
})
export class DashboardPanelConfigComponent implements AfterViewInit, OnDestroy {

  private componentRef: ComponentRef<AbstractDashboardPluginConfig> = null;

  @Input() panel: DashboardPanel;

  @Output() configChanged = new EventEmitter<any>();

  @ViewChild(DashboardPanelConfigContentDirective) panelContentDirective: DashboardPanelConfigContentDirective;

  config: any = null;

  private configChangedSub: Subscription = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngAfterViewInit() {
    window.setTimeout(() => this.createComponent(), 0);
  }

  ngOnDestroy() {
    if (this.configChangedSub) {
      this.configChangedSub.unsubscribe();
    }
  }

  private createComponent() {
    const plugin = DashboardBuiltinPlugins.getPlugin(this.panel.contentType);
    if (!plugin) {
      return console.error('Dashboard plugin not recognized: ' + this.panel.contentType);
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(plugin.configComponent);
    const viewContainerRef = this.panelContentDirective.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    const c = this.componentRef.instance;
    c.setConfig(this.panel.contentConfig);

    this.configChangedSub = c.configChanged.subscribe(config => {
      this.config = config;
      this.configChanged.emit(this.config);
    });
  }
}
