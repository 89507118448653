/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerSession } from './serverSession';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InformixServerSessionsService {

  constructor(private httpClient: HttpClient) { }

  public getServerSessions(serverId: number): Observable<ServerSession[]> {
    return this.httpClient.get<ServerSession[]>('informix/' + serverId + '/sessions/');
  }

  public getServerSessionInfo(serverId: number, sessionId: number): Promise<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/sessions/' + sessionId).toPromise<any>();
  }

  public killServerSession(serverId: number, sessionId: number): Promise<any> {
    return this.httpClient.delete<any>('informix/' + serverId + '/sessions/' + sessionId).toPromise<any>();
  }
}
