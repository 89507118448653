/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privileges',
  templateUrl: 'privileges.html'
})
export class PrivilegesComponent implements OnInit {
  server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;
  selectedTabIndex = 0;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get(['breadcrumb.serverAdministration.title', 'common.privileges'])
      .subscribe(breadcrumbStrings => {
        this.breadcrumb = ServerBreadcrumb.build(this.server,
          [{ name: breadcrumbStrings['breadcrumb.serverAdministration.title'] },
          { name: breadcrumbStrings['common.privileges'] }]);
      });
  }

  setSelectedTabIndex(index: number) {
    this.selectedTabIndex = index;
  }
}
