/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { EventEmitter, Injectable } from '@angular/core';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { InformixServer } from '../servers/informixServer';
import { Sensor } from './sensor';
import { ServerSensorManager, SensorDataBatchOptions } from './server-sensor-manager';
import { SensorDataSource } from './sensor-data-source';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InformixSensorService {

  private serverManagers: { [key: number]: ServerSensorManager } = {};

  public eventSensorUpdate = new EventEmitter<Sensor>();

  constructor(private notifications: NotificationsService, private httpClient: HttpClient) { }

  public getSensorInfo(serverId: number, sensorId: string): Promise<Sensor> {
    return this.httpClient.get<Sensor>('informix/' + serverId + '/monitoring/sensors/' + sensorId).toPromise<Sensor>();
  }

  public getSensorDataWithLimit(serverId: number, sensorId: string, limit = 1, fromTimestamp?: number): Promise<any[]> {
    let url = 'informix/' + serverId + '/monitoring/sensors/' + sensorId + '/data/latest?n=' + limit;
    if (fromTimestamp) {
      url += '&fromTimestamp=' + fromTimestamp;
    }

    return this.httpClient.get<any[]>(url).toPromise<any[]>();
  }

  public getSensorData(serverId: number, sensorId: string, fromTimestamp?: number, toTimestamp?: number,
    primaryKeys?: string | string[]): Promise<any[]> {
    let url = 'informix/' + serverId + '/monitoring/sensors/' + sensorId + '/data';
    if (fromTimestamp || toTimestamp) {
      url += '?';
      if (fromTimestamp) {
        url += 'from=' + fromTimestamp;
      }
      if (fromTimestamp && toTimestamp) {
        url += '&';
      }
      if (toTimestamp) {
        url += 'to=' + toTimestamp;
      }
    }
    if (primaryKeys) {
      if (fromTimestamp || toTimestamp) {
        url += '&';
      }
      url += 'primaryKeys=' + primaryKeys.toString();
    }

    return this.httpClient.get<any[]>(url).toPromise<any[]>();
  }

  public getLatestSensorDataPoint(serverId: number, sensorId: string): Promise<any[]> {
    const url = 'informix/' + serverId + '/monitoring/sensors/' + sensorId + '/data/latest';
    return this.httpClient.get<any[]>(url).toPromise<any[]>();
  }

  public getSensorDataBatch(server: InformixServer, options: SensorDataBatchOptions[]): Promise<any[]> {
    return this.httpClient.post<any[]>('informix/' + server.id + '/monitoring/batch', options).toPromise<any[]>();
  }

  public getSensorDataMetaData(serverId: number, sensorId: string): Promise<any> {
    const url = 'informix/' + serverId + '/monitoring/sensors/' + sensorId + '/data/meta';
    return this.httpClient.get<any>(url).toPromise<any>();
  }

  public addSensorToMonitoringProfile(serverId: number, sensorType: string, runInterval?: number, disabled?: boolean) {
    const sensorJson: any = { type: sensorType };
    if (runInterval) {
      sensorJson.runInterval = runInterval;
    }
    if (disabled) {
      sensorJson.disabled = true;
    }

    this.httpClient.post<any>('informix/' + serverId + '/monitoring/', sensorJson)
      .toPromise<any>().then(json => {
        const sensor = new Sensor(json);
        this.notifications.pushSuccessNotification('The ' + sensor.name + ' sensor has been enabled');
        this.eventSensorUpdate.emit(sensor);
      }).catch(err => {
        this.notifications.pushErrorNotification('There was a problem enabling the ' + sensorType + ' sensor');
        console.log(err);
      });
  }

  public addSensorsToMonitoringProfile(serverId: number, sensorsToAdd: string[]) {
    const sensorsJson: any = [];
    sensorsToAdd.forEach(sensorType => {
      sensorsJson.push({ type: sensorType });
    });

    this.httpClient.post<any>('informix/' + serverId + '/monitoring/', sensorsJson)
      .toPromise<any>().then(json => {
        this.notifications.pushSuccessNotification('The following sensors have been enabled: ' + sensorsToAdd.join(', '));
        const sensorJsonList: any[] = json;
        sensorJsonList.forEach(sensorJson => {
          const sensor = new Sensor(sensorJson);
          this.eventSensorUpdate.emit(sensor);
        });
      }).catch(err => {
        this.notifications.pushErrorNotification('There was a problem enabling the following sensors: ' + sensorsToAdd.join(', '));
        console.log(err);
      });
  }

  public updateSensorInMonitoringProfile(serverId: number, sensors: Sensor | Sensor[]) {
    let sensorList: Sensor[];
    if (sensors instanceof Sensor) {
      sensorList = [sensors];
    } else {
      sensorList = sensors;
    }

    sensorList.forEach(sensor => {
      const sensorJson: any = {
        type: sensor.type.id,
        runInterval: sensor.runInterval,
        disabled: sensor.disabled
      };

      this.httpClient.put<any>('informix/' + serverId + '/monitoring/sensors/' + sensor.type.id, sensorJson)
        .toPromise<any>().then(() => {
          this.notifications.pushSuccessNotification('The ' + sensor.name + ' sensor has been enabled');
          this.eventSensorUpdate.emit(sensor);
        }).catch(err => {
          this.addSensorToMonitoringProfile(serverId, sensor.type.id);
          console.log(err);
        });
    });
  }

  public getSensorDataSource(server: InformixServer, sensor: Sensor): SensorDataSource {
    let serverManager = this.serverManagers[server.id];
    if (!serverManager) {
      serverManager = new ServerSensorManager(this, server);
      this.serverManagers[server.id] = serverManager;
    }

    return serverManager.getDataSource(sensor);
  }
}
