/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { Component, Input } from '@angular/core';
import { QueryResultPage } from '../query-results';

@Component({
  selector: 'app-query-result-table',
  templateUrl: './query-result-table.component.html',
  styleUrls: ['./query-result-table.component.scss']
})
export class QueryResultTableComponent {
  @Input() resultPage: QueryResultPage = null;
}
