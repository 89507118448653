<div *ngIf="server && config">
  <div class="row">
    <div *ngIf="sensor && showTitle" class="col col-12">
      <h5>{{chartTitle}}</h5>
    </div>
  </div>
  <div *ngIf="warningMessage">
    <div class="row mt-5 mb-5">
      <div class="col col-12 text-center">
        <div style="display: flex; justify-content: center; align-items: center">
          <div class="mr-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
          <div style="font-weight: bold">
            {{warningMessage}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!warningMessage">
  <app-sensor-chart-graph [server]="server" [sensor]="sensor" [config]="config">
  </app-sensor-chart-graph>
  </div>
</div>
