<ng-template [ngIf]="node">
  <div class="tree-row">
    <div class="tree-column">
      <div style="display: flex" *ngIf="node.parent">
        <div class="tree-line" [ngClass]="{'tree-line-h': !isOnlyChild && !isFirstChild, 'tree-line-ne': !isOnlyChild && isLastChild, 'tree-line-v': !isFirstChild || isOnlyChild}"> </div>
        <div class="tree-line tree-line-h" *ngIf="!isOnlyChild && !isLastChild" [ngClass]="{'tree-line-nw': isFirstChild}"></div>
      </div>

      <div class="node-container">
        <ng-container *ngIf="nodeTemplateRef; else noNodeTemplate">
          <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{node: node}"> </ng-template>
        </ng-container>
        <ng-template #noNodeTemplate>
          No template available
        </ng-template>
      </div>

      <div *ngIf="node.children.length > 0" class="tree-line tree-line-v"> </div>
    </div>
  </div>

  <ng-container *ngIf="node.children.length > 0">
    <div class="tree-row">
      <div class="tree-column" *ngFor="let childNode of node.children; let i = index">
        <app-tree-view [node]="childNode" [nodeTemplateRef]="nodeTemplateRef" [childIndex]="i"> </app-tree-view>
      </div>
    </div>
  </ng-container>
</ng-template>