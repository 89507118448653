/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2022, 2023. All Rights Reserved.
 *******************************************************************************/

import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import guideConfig from '../../../../shared/guide-modal/guide-config';

@Component({
  selector: 'app-er-prerequiesties',
  templateUrl: './prerequiesties.component.html',
  styleUrls: ['./prerequiesties.component.scss']
})
export class ERPrerequiestiesComponent {
  @Output() openDefineNodeSidebar = new EventEmitter();
  @Input() helpOpened: boolean;
  @Input() isDraft: Boolean;
  @Input() showSqlhostsGroupsModal: boolean;
  @Output() showSqlhostsGroupsModalChange = new EventEmitter<boolean>();
  @Output() helpOpenedChange = new EventEmitter<boolean>();
  selectedGuideId: number;
  showGuideModal: Boolean = false;
  guideConfig = guideConfig;
  constructor() { }

  startERDomain() {
    this.openDefineNodeSidebar.emit();
  }
  backToERDomain() {
    this.helpOpened = false;
    this.helpOpenedChange.emit(false);
  }
 openSqlhostsgroupsModal() {
    this.showSqlhostsGroupsModalChange.emit(true);
  }

  showGuide(guideId: number) {
    this.selectedGuideId = guideId;
    this.showGuideModal = true;
  }
}
