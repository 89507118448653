/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataTableRow } from '../../../../shared/data-table/data-table.component';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { SensorType } from '../../../monitoring/sensor';

@Component({
  selector: 'app-sensor-types',
  templateUrl: './sensor-types.component.html',
  styleUrls: ['./sensor-types.component.scss']
})
export class SensorTypesComponent implements OnInit {

  sensorTypes: SensorType[] = null;

  constructor(
    private monitoringService: MonitoringService,
    private confirmationDialog: ConfirmationDialogService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.monitoringService.getSensorTypes().then(sensorTypes => {
      this.sensorTypes = sensorTypes.filter(v => !v.fromPackage);
    }, err => {
      console.error(err);
    });
  }

  sensorTypeDataMap(sensorType: SensorType): DataTableRow {
    return {
      data: sensorType,
      selected: false,
      disabled: sensorType.fromPackage
    };
  }

  deleteSensorType(sensorType: SensorType) {
    this.confirmationDialog.show('delete the sensor: ' + sensorType.name + '?', () => {
      this.monitoringService.deleteSensorType(sensorType).subscribe(() => {
        this.notifications.pushSuccessNotification('Sensor deleted: ' + sensorType.name);
        const index = this.sensorTypes.indexOf(sensorType);
        if (index > -1) {
          this.sensorTypes.splice(index, 1);
          this.sensorTypes = this.sensorTypes.slice();
        }
      }, err => {
        const errorMessage = (err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred';
        this.notifications.pushErrorNotification('Could not delete sensor. ' + errorMessage);
      });
    });
  }
}
