/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { CustomSystemReport } from './system-report';
import { CustomSystemReportsService } from './custom-system-reports.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-custom-report-list',
  templateUrl: './custom-report-list.html',
})
export class CustomReportListComponent implements OnInit {

  reportList: CustomSystemReport[] = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportService: CustomSystemReportsService,
    private confirmationDialog: ConfirmationDialogService,
    private notifications: NotificationsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.reportService.getReports(false).then(reports => {
      this.reportList = reports;
    }, err => {
      console.error(err);
    });
  }

  editReport(report: CustomSystemReport) {
    this.router.navigate(['edit'], {relativeTo: this.route, queryParams: {id: report.id, server: report.testServerId}});
  }

  deleteReport(report: CustomSystemReport) {
    this.confirmationDialog.show('delete the report: ' + report.name + '?', () => {
      this.reportService.deleteReport(report).then(() => {
        this.translate.get('systemSettings.customReports.reportDeleted', { name: report.name })
          .subscribe((text: string) => {
            this.notifications.pushSuccessNotification(text);
          });
        const index = this.reportList.indexOf(report);
        if (index > -1) {
          this.reportList.splice(index, 1);
          this.reportList = this.reportList.slice();
        }
      }, err => {
        this.translate.get('systemSettings.customReports.cannotDeleteReport',
          { suffix: ((err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred') })
          .subscribe((text: string) => {
            this.notifications.pushErrorNotification(text);
          });
      });
    });
  }
}
