/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, ViewChild, Output } from '@angular/core';
import { InformixServer } from '../../informixServer';
import { TranslateService } from '@ngx-translate/core';
import { TablesIndexesList } from './tables-indexes-page.model';
import { TablesIndexesService } from './tables-indexes-page.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-optimize-space-modal',
  templateUrl: 'optimize-space-modal.html',
  styleUrls: ['tables-indexes-page.scss']
})
export class OptimizeSpaceModalComponent {

  server: InformixServer = null;
  database: string = null;
  objects: any[] = null;
  containsBlobData = false;
  isCatalog = false;
  isCompress = false;
  isCompressBlobData = false;
  isUncompress = false;
  isRepack = false;
  isShrink = false;
  isDefragment = false;
  isRemoveIPA = false;
  isIndexDisable = false;
  disableApplyBtn = true;

  @Output() actionComplete = new EventEmitter<any[]>();
  @Output() actionStart = new EventEmitter<any[]>();
  @ViewChild('optimizeModal') optimizeModal: ModalDirective;

  constructor(private translate: TranslateService,
    private tablesIndexesService: TablesIndexesService,
    private notificationsService: NotificationsService) { }

  show(server: InformixServer, database: string, objects: any[], isCatalog: any) {
    this.server = server;
    this.database = database;
    this.objects = objects;
    this.containsBlobData = false;
    this.isCompress = false;
    this.isCompressBlobData = false;
    this.isUncompress = false;
    this.isDefragment = false;
    this.isRemoveIPA = false;
    this.isShrink = false;
    this.isRepack = false;
    this.disableApplyBtn = true;
    this.isIndexDisable = false;
    this.isCatalog = isCatalog;
    this.objects.forEach(row => {
      this.optimizeModalValidations(row);
    });
    this.optimizeModal.show();
  }

  getType(row: any) {
    if (row.type === 'I') {
      if (row.partnum) {
        return 'indexFragment';
      } else {
        return 'index';
      }
    } else {
      if (row.partnum) {
        return 'tableFragment';
      } else {
        return 'table';
      }
    }
  }

  optimizeModalValidations(row: TablesIndexesList) {
    if (row.type === 'I') {
      this.isIndexDisable = true;
    }
    if (row.has_blob_column) {
      this.containsBlobData = true;
    }
  }

  applyOptimization() {
    this.disableApplyBtn = true;
    this.objects[0].running = true;
    this.actionStart.emit();
    const dataObj = {
      action: 'optimize',
      include_catalogs: this.isCatalog,
      compress: this.isUncompress ? false : this.isCompress,
      uncompress: (this.isCompress || this.isRepack) ? false : this.isUncompress,
      repack: this.isUncompress ? false : this.isRepack,
      shrink: this.isShrink,
      defragment: this.isDefragment,
      removeIPA: this.isRemoveIPA,
      compress_blob: this.isUncompress ? false : this.isCompressBlobData,
      objects: this.objects
    };
    this.tablesIndexesService.optimizeSpace(this.server, this.database, dataObj).subscribe(res => {
      this.translate.get('tablesIndexes.successMsg.optimizeSpaces')
        .subscribe((text: string) => {
          this.notificationsService.pushSuccessNotification(text);
        });
      this.actionComplete.emit(this.objects);
    }, err => {
      this.translate.get('tablesIndexes.errorMsg.optimizeSpacesError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
    });
    this.optimizeModal.hide();
  }
  closeoptimizeModal() {
    this.objects[0].running = false;
    this.optimizeModal.hide();
  }

  optimizePopUpValidations(type: string, checked: boolean) {
    if (type === 'compress') {
      this.isCompress = checked;
      if (this.containsBlobData) {
        this.isCompressBlobData = checked;
      }
    } else if (type === 'compress_blobs') {
      this.isCompressBlobData = checked;
      if (checked === true) {
        this.isCompress = true;
      }
    } else if (type === 'uncompress') {
      this.isUncompress = checked;
    } else if (type === 'repack') {
      this.isRepack = checked;
    } else if (type === 'shrink') {
      this.isShrink = checked;
    } else if (type === 'defrag') {
      this.isDefragment = checked;
    } else if (type === 'remove') {
      this.isRemoveIPA = checked;
    }
    if (this.isCompress === false && this.isUncompress === false && this.isRepack === false &&
      this.isShrink === false && this.isDefragment === false && this.isRemoveIPA === false) {
      this.disableApplyBtn = true;
    } else {
      this.disableApplyBtn = false;
    }
  }

}
