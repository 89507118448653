<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <h4 class="mb-2 d-inline-block"><strong>{{'connectionManager.pageTitle' | translate}}</strong></h4>
  <app-data-table *ngIf="cmInfoList else loading" [data]="cmInfoList"
    [noDataAlertMessage]="'connectionManager.noDataAlertMessage' | translate"
    [searchLabel]="'connectionManager.searchLabel.connectionManager' | translate">
    <app-data-table-expand [expandCondition]="cmExpanded.bind(this)">
      <ng-template appTableExpand let-cm="row">
        <app-data-table *ngIf="cm?.cmUnitList" [data]="cm.cmUnitList">
          <app-data-table-column [title]="'connectionManager.table.columnTitle.cmUnitName' | translate">
            <ng-template appTableCell let-cmUnit="row">
              <a [routerLink]="['../cm/cmUnit']" [queryParams]="{cmName: cm.cmName, cmUnitName: cmUnit.cmUnitName}">
                {{cmUnit.cmUnitName}}</a>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'dataTable.columnTitle.type' | translate">
            <ng-template appTableCell let-cmUnit="row">
              <span class="text-capitalize">{{cmUnit.type}}</span>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'connectionManager.foc' | translate">
            <ng-template appTableCell let-cmUnit="row">
              {{cmUnit.type === 'CLUSTER' ? cmUnit.foc : 'NA'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'connectionManager.table.columnTitle.servers' | translate">
            <ng-template appTableCell let-cmUnit="row">
              {{cmUnit.servers?.join(', ')}}
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </ng-template>
    </app-data-table-expand>
    <app-data-table-column width="30px">
      <ng-template appTableCell let-cm="row">
        <i class="icon font-weight-bold cursor-pointer"
          [ngClass]="cm.isExpand ? 'icon-chevron-down' : 'icon-chevron-right'" (click)="cm.isExpand = !cm.isExpand"></i>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'dataTable.columnTitle.name' | translate" [sortable]="true" [searchable]="true"
      property="cmName">
      <ng-template appTableCell let-cm="row">
        {{cm.cmName}}<span class="badge-pill badge-primary small p-1 ml-1"
          *ngIf="cm.cmArbitrator === 1">{{'connectionManager.activeArbitrator' | translate}}</span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'connectionManager.table.columnTitle.cmUnits' | translate" property="cmUnitList"
      [searchable]="true">
      <ng-template appTableCell let-cm="row">
        {{getConnectionUnitNames(cm.cmUnitList)}}
      </ng-template>
    </app-data-table-column>
  </app-data-table>
  <ng-template #loading>
    <div *ngIf="dataLoading" class="d-flex justify-content-center">
      <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>
    <div *ngIf="cmError" class="mt-2 alert alert-danger">
      <i class="icon icon-danger text-danger"></i> Unable to retrieve connection manager information. <br> Error: {{cmError}}
    </div>
  </ng-template>
</div>
