/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved
 *******************************************************************************/
export class Fragment {
  owner: string;
  isChecked: boolean;
  nptotal: number;
  is_fragment: number;
  type: string;
  used_size: number;
  npused: number;
  nrows: number;
  nextents: number;
  compressed: string;
  auto_compressed: string;
  uncompressed: string;
  partnum: string;
  dbspace: string;
  tabname: string;
  estimate: any;
  constructor(fragment: any) {
    this.owner = fragment.owner;
    this.isChecked = false;
    this.nptotal = fragment.nptotal;
    this.is_fragment = fragment.is_fragment;
    this.type = fragment.type;
    this.used_size = fragment.used_size;
    this.npused = fragment.npused;
    this.nrows = fragment.nrows;
    this.nextents = fragment.nextents;
    this.compressed = fragment.compressed;
    this.auto_compressed = fragment.auto_compressed;
    this.uncompressed = fragment.uncompressed;
    this.partnum = fragment.partnum;
    this.dbspace = fragment.dbspace;
    this.tabname = fragment.tabname;
    this.estimate = fragment.estimate;
  }
}

export class TablesIndexesList {
  owner: string;
  tabname: string;
  type: string;
  databaseName: string;
  dbSpaceName: string;
  nrows: number;
  nptotal: number;
  npused: number;
  extents: number;
  compressed: string;
  is_fragment: number;
  used_size: number;
  free: number;
  partly_used: number;
  mostly_used: number;
  very_full: number;
  auto_compressed: string;
  uncompressed: string;
  has_blob_column: boolean;
  fragments: Array<Fragment>;
  estimate: any;
  running: boolean;
  isChecked: boolean;
  constructor(tableData: any) {
    this.owner = tableData.owner;
    this.databaseName = tableData.dbsname ? tableData.dbsname : '_';
    this.dbSpaceName = tableData.name ? tableData.name : '_';
    this.tabname = tableData.tabname;
    this.type = tableData.type;
    this.nrows = tableData.nrows;
    this.nptotal = tableData.nptotal;
    this.npused = tableData.npused;
    this.extents = tableData.extents;
    this.compressed = tableData.compressed;
    this.is_fragment = tableData.is_fragment;
    this.used_size = tableData.used_size;
    this.free = tableData.free;
    this.partly_used = tableData.partly_used;
    this.mostly_used = tableData.mostly_used;
    this.very_full = tableData.very_full;
    this.auto_compressed = tableData.auto_compressed;
    this.uncompressed = tableData.uncompressed;
    this.has_blob_column = tableData.has_blob_column;
    this.fragments = tableData.fragments.map(frag => new Fragment(frag));
    this.estimate = tableData.estimate;
    this.running = tableData.running;
    this.isChecked = false;
  }
}

export interface OptimizationPoliciesList {
  task_info: {
    tk_start_time: any;
    tk_stop_time: any;
    tk_frequency: any;
    tk_monday: boolean;
    tk_tuesday: boolean;
    tk_wednesday: boolean;
    tk_thursday: boolean;
    tk_friday: boolean;
    tk_saturday: boolean;
    tk_sunday: boolean;
    tk_enable: boolean;
  };
  task_parameters: Array<TaskParameters>;
}

export interface TaskParameters {
  name: string;
  value: string;
}

export interface TaskStatusList {
  command: string;
  end: any;
  msg: string;
  start: any;
}
