/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from '../../shared/shared.module';
import { SchemaModule } from '../servers/schema/schema.module';
import { CustomSystemReportsModule } from './custom-system-reports/custom-system-reports.module';
import { UserSettingsModule } from '../user-settings/user-settings.module';
import { AlertConfigContainerComponent } from './alerts/alertConfigContainer.component';
import { AlertScriptConfigComponent } from './alerts/alertScript.component';
import { EmailConfigComponent } from './alerts/emailConfig.component';
import { PagerDutyConfigComponent } from './alerts/pagerDutyConfig.component';
import { SystemAlertsComponent } from './alerts/systemAlerts.component';
import { TwilioConfigComponent } from './alerts/twilioConfig.component';
import { SystemDataCleanupConfigComponent } from './data-cleanup/system-data-cleanup-config.component';
import { CreateSensorComponent } from './sensor-types/create-sensor/create-sensor.component';
import { SensorTypesComponent } from './sensor-types/sensor-types/sensor-types.component';
import { SystemServerConfigComponent } from './server/system-server-config.component';
import { SystemConfigService } from './system-config.service';
import { SystemSettingsComponent } from './system-settings.component';
import { UserResolver } from './users/user-resolver.service';
import { UserFormComponent } from './users/userForm.component';
import { UsersComponent } from './users/users.component';
import { UsersService } from './users/users.service';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { UserService } from '../../shared/user/user.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TooltipModule,
    TabsModule,

    SharedModule,
    CustomSystemReportsModule,
    UserSettingsModule,
    SchemaModule,
    BsDatepickerModule
  ],
  exports: [],
  declarations: [
    UserPreferencesComponent,
    SystemSettingsComponent,
    SystemAlertsComponent,
    SystemDataCleanupConfigComponent,
    SystemServerConfigComponent,
    EmailConfigComponent,
    TwilioConfigComponent,
    PagerDutyConfigComponent,
    AlertScriptConfigComponent,
    AlertConfigContainerComponent,
    UsersComponent,
    UserFormComponent,
    SensorTypesComponent,
    CreateSensorComponent,
  ],
  providers: [
    SystemConfigService,
    UsersService,
    UserService,
    UserResolver
  ],
})
export class SystemSettingsModule { }
