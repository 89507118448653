/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DropdownMultiSelectComponent } from './dropdown-multi-select.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RadioComponent } from './radio/radio.component';

@NgModule({
  imports: [
    BsDropdownModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    DropdownMultiSelectComponent,
    CheckboxComponent,
    RadioGroupComponent,
    RadioComponent
  ],
  exports: [
    DropdownMultiSelectComponent,
    CheckboxComponent,
    RadioGroupComponent,
    RadioComponent
  ]
})
export class SharedFormsModule { }
