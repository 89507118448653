/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { onconfigData, OnconfigParamInfo, onconfigCategories } from './onconfig-data';

export class OnconfigParamCategory {
  protected paramMap: { [key: string]: OnconfigParamInfo } = null;

  constructor(public id: number, public description: string, params: string[]) {
    this.buildParamMap(params);
  }

  protected buildParamMap(params: string[]): void {
    this.paramMap = {};
    params.forEach(param => {
      const paramInfo = onconfigData[param];
      if (paramInfo) {
        this.paramMap[param] = paramInfo;
      } else {
        console.warn('Categorized onconfig param not found: ' + param);
      }
    });
  }

  public hasParam(param: string): boolean {
    return !!this.paramMap[param];
  }
}

export class OnconfigParamAllCategory extends OnconfigParamCategory {

  protected buildParamMap(params: string[]): void { }

  public hasParam(param: string): boolean {
    return true;
  }
}

export class OnconfigInfo {

  private static categories: OnconfigParamCategory[] = null;

  private static buildCategories(): OnconfigParamCategory[] {
    const categorizedParams: { [key: string]: boolean } = {};
    const categories: OnconfigParamCategory[] = [];

    onconfigCategories.forEach(jsonCategory => {
      jsonCategory.params.forEach(param => {
        categorizedParams[param] = true;
      });
      const category = new OnconfigParamCategory(categories.length, jsonCategory.description, jsonCategory.params);
      categories.push(category);
    });

    categories.sort((a, b) => a.description.localeCompare(b.description));

    categories.unshift(new OnconfigParamAllCategory(categories.length, 'All', null));

    const otherParams: string[] = [];
    for (const param in onconfigData) {
      if (!categorizedParams[param]) {
        otherParams.push(param);
      }
    }

    if (otherParams.length > 0) {
      categories.push(new OnconfigParamCategory(categories.length, 'Other', otherParams));
    }

    return categories;
  }

  public static getCategories(): OnconfigParamCategory[] {
    if (!OnconfigInfo.categories) {
      OnconfigInfo.categories = OnconfigInfo.buildCategories();
    }

    return OnconfigInfo.categories;
  }
}
