/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { Route } from '@angular/router';
import { PermissionGuard } from '../../../shared/user/permissionGuard.service';
import { CreateCustomReportComponent } from './create-custom-report.component';
import { CustomReportListComponent } from './/custom-report-list.component';

export const CustomSystemReportsRoutes: Route[] = [
  {
    path: 'reports',
    canActivate: [PermissionGuard],
    canActivateChild: [PermissionGuard],
    children: [
      {
        path: '',
        component: CustomReportListComponent,
        pathMatch: 'full'
      },
      {
        path: 'edit',
        component: CreateCustomReportComponent
      }
    ]
  }
];
