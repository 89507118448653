/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { User } from '../../../shared/user/user';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsersService {

  public eventNewUser = new EventEmitter<User>();

  constructor(private httpClient: HttpClient) { }

  public getUser(id: number): Promise<User> {
    const url = 'users/' + id;
    return this.httpClient.get<User>(url)
      .toPromise<User>()
      .then((response) => new User(response));
  }

  public getUsers(): Promise<User[]> {
    return this.httpClient.get<User[]>('users').toPromise<User[]>()
      .then(response => (response as any[]).map(json => new User(json)));
  }

  public updateUserLockStatus(user: User) {
    return this.httpClient.put<any>('users/' + user.id, { locked: !user.locked }).toPromise<any>();
  }

  public updateUser(user: User) {
    return this.httpClient.put<any>('users/' + user.id,
      { permissions: user.permissions, access_permissions: user.accessPermissions, roleExpiry: user.roleExpiry }).toPromise<any>();
  }

  public updateUserPassword(user: User) {
    return this.httpClient.put<any>('users/' + user.id + '/password', { password: user.password }).toPromise<any>();
  }

  public createUser(user: User) {
    return this.httpClient.post<any>('users', {
      name: user.name,
      password: user.password,
      permissions: user.permissions,
      access_permissions: user.accessPermissions,
      roleExpiry: user.roleExpiry
    }).toPromise<any>()
      .then(result => {
        user.id = result.id;
        user.password = null;
        this.eventNewUser.emit(user);
      });
  }

  public removeUser(user: User) {
    return this.httpClient.delete<any>('users/' + user.id).toPromise<any>();
  }
}
