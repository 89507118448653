/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { AbstractControl, ValidatorFn } from '@angular/forms';

export class IACValidators {
  public static minNumberValidator(min: number): ValidatorFn {
    return (c: AbstractControl): any => {
      const value = parseInt(c.value, 10);
      if (!isNaN(value) && value < min) {
        return { min };
      }
      return null;
    };
  }

  public static maxNumberValidator(max: number): ValidatorFn {
    return (c: AbstractControl): any => {
      const value = parseInt(c.value, 10);
      if (!isNaN(value) && value > max) {
        return { max };
      }
      return null;
    };
  }

  public static informixIdentifierValidator(c: AbstractControl): any {
    const value: string = c.value;
    if (value == null || value === '') {
      return null;
    }
    if (value.length > 128) {
      return { maxlength: { requiredLength: 128 } };
    }
    const IFX_IDENTIFIER_REGEX = /^[_A-Za-z][_A-Za-z0-9$]*$/;
      if (IFX_IDENTIFIER_REGEX.test(value)) {
        return null;
      } else {
        return { pattern: { requiredPattern: IFX_IDENTIFIER_REGEX } };
      }
  }

  public static noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (control.value) {
        isValid = !((control.value).trim().length === 0);
      } else {
        isValid = true;
      }
      return isValid ? null : { whitespace: true };
    };
  }

  public static noSpecialCharacterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const specialCharacterRegex = /^[\w]*$/;
      if (specialCharacterRegex.test(control.value)) {
        return null;
      } else {
        return { specialCharacter: true };
      }
    };
  }

  public static confirmPasswordValidator(password: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && password) {
        if (control.value && password === control.value && control.value.length >= 6) {
          return null;
        } else {
          return { passwordMismatch: true };
        }
      } else {
        return null;
      }
    };
  }
}
