<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-6">
      <h4>
        <label class="server-label pr-3 font-weight-bold text-truncate align-top">{{server.alias}}</label>
        <app-data-source-toggle [useLongTooltips]="true" (viewMonitoredDataStateChange)="viewMonitoredData = $event">
        </app-data-source-toggle>
      </h4>
    </div>

    <div class="col-6 d-flex justify-content-end">
      <div class="col-sm-auto">
        <!-- added && condition to only display on Agent Data tab -->
        <app-time-slice *ngIf="showGraphControls && viewMonitoredData" (timeSliceChanged)="timeSliceChanged($event)"
          (dataPaused)="pauseData()" (dataResumed)="resumeData()"></app-time-slice>
      </div>
      <div class="col-sm-auto mr-2 ml-2">
        <app-sensors-required-control [server]="server" [monitoringProfile]="monitoringProfile"
          [sensorList]="allSensorsList" (monitoringProfileUpdated)="getMonitoringProfile()">
        </app-sensors-required-control>
      </div>
      <div class="col-sm-auto">
        <div class="pt-2">
          <span class="pr-2">Work Online</span>
            <label class="toggle-button" [tooltip]="isAdminPermission() ? '': 'You do not have access to this'" >
              <input type="checkbox" (change)="workOffline($event.target.checked)" [checked]="isWorkOffline" [disabled]="!isAdminPermission()">
              <span class="toggle-slider round"></span>
            </label>
          <span class="pl-2 ml-1">Work Offline</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6">
      <div class="row">
        <div class="col-xl-12">
          <app-dashboard-server-status [server]="server" [monitoringProfile]="monitoringProfile"
            (eventLoaded)="serverInfoUpdated($event)" (eventServerType)="serverTypeUpdated($event)" (eventServerStatus)="isOnline = $event"></app-dashboard-server-status>
        </div>
      </div>


      <div *ngIf="serverInfo && isOnline" class="row mt-2">
        <div class="col-xl-12">
          <app-dashboard-server-availability [server]="server" [serverInfo]="serverInfo" [isWorkOffline]="isWorkOffline">
          </app-dashboard-server-availability>
        </div>
      </div>

      <div class="row mt-2" *ngIf="isOnline">
        <div class="col-xl-12">
          <app-dashboard-server-threads *ngIf="!isWorkOffline" #threadsDashboardComponent [server]="server" [serverInfo]="serverInfo"
            [monitoringProfile]="monitoringProfile" [viewMonitoredData]="viewMonitoredData">
          </app-dashboard-server-threads>
        </div>
      </div>

      <div class="row mt-2" *ngIf="isOnline">
        <div class="col-xl-12">
          <app-dashboard-server-host *ngIf="!isWorkOffline" #hostDashboardComponent [server]="server" [serverInfo]="serverInfo"
            [monitoringProfile]="monitoringProfile" [viewMonitoredData]="viewMonitoredData"></app-dashboard-server-host>
        </div>
      </div>

      <div *ngIf="serverInfo && isOnline" class="row mt-2 mb-2">
        <div class="col-xl-12">
          <app-dashboard-server-info *ngIf="!isWorkOffline" [server]="server" [serverInfo]="serverInfo"></app-dashboard-server-info>
        </div>
      </div>
    </div>

    <div class="col-xl-6">

      <div class="row hidden-lg-down">
        <div class="col-xl-12">
          <div class="card card-body">
            <h4 class="card-title"><a [routerLink]="['incidents']">Incidents</a></h4>
              <app-incidents [owner]="server" [isWidget]="true" [defaultHeight]="300"> </app-incidents>
          </div>
        </div>
      </div>

      <div class="row mt-2" *ngIf="isOnline">
        <div class="col-xl-12">
          <app-dashboard-server-storage-performance *ngIf="!isWorkOffline" #storagePerfDashboardComponent [server]="server"
            [serverInfo]="serverInfo" [monitoringProfile]="monitoringProfile" [viewMonitoredData]="viewMonitoredData">
          </app-dashboard-server-storage-performance>
        </div>
      </div>

      <div class="row mt-2" *ngIf="isOnline">
        <div class="col-xl-12">
          <app-dashboard-server-sessions *ngIf="!isWorkOffline" #sessionsDashboardComponent [server]="server" [serverInfo]="serverInfo"
            [monitoringProfile]="monitoringProfile" [viewMonitoredData]="viewMonitoredData">
          </app-dashboard-server-sessions>
        </div>
      </div>

      <div class="row mt-2" *ngIf="isOnline">
        <div class="col-xl-12">
          <app-dashboard-server-device *ngIf="!isWorkOffline" #deviceDashboardComponent [server]="server"
            [monitoringProfile]="monitoringProfile" [viewMonitoredData]="viewMonitoredData">
          </app-dashboard-server-device>
        </div>
      </div>

    </div>
  </div>

</div>
