/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../informixServer';
import { ClusterStatusInfo } from './high-availability.model';
import { HttpClient } from '@angular/common/http';
import { ClusterSMXInfo } from './high-availability.model';
import { map } from 'rxjs/operators';

@Injectable()
export class HighAvailabilityService {

  constructor(private httpClient: HttpClient) { }

  public getSMXInfo(server: InformixServer): Observable<ClusterSMXInfo> {
    const url = 'informix/' + server.id + '/ha/smx';
    return this.httpClient.get<ClusterSMXInfo>(url).pipe(map(response => new ClusterSMXInfo(response)));
  }

  public getServerStatusInfo(serverId: number, name: string, type: string): Observable<ClusterStatusInfo> {
    const url = 'informix/' + serverId + '/ha/statusInfo?name=' + name + '&type=' + type;
    return this.httpClient.get<ClusterStatusInfo>(url).pipe(map(response => new ClusterStatusInfo(response)));
  }
}
