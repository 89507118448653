
<ng-container *ngIf="scheduleData && scheduleData.length else ausConfigError">
  <div class="row mt-3">
    <div class="col-xs-4 col-lg-6">
      <div class="row ml-0">
        <label class="font-weight-bold">
          Auto Update Statistics Schedule
        </label>
      </div>
    </div>
  </div>
  <div class="card-block">
    <div class="card-body p-0 pt-1">
      <div class="table-responsive">
        <app-data-table [data]="scheduleData" *ngIf="scheduleData; else tableDataLoading" [sortDescending]="true"
          [rowsPerPage]="20" [minHeight]="dynamicHeight">
          <app-data-table-column title="Name" property="tk_name" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-row="row" let-item="item">
              <a href="javascript:void(0)" (click)="addTaskSensorModal.openAddTaskModal(false, '', row)">{{item}}</a>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Start Time" property="tk_start_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Stop Time" property="tk_stop_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Run Frequency" property="tk_frequency" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-frequency="item">
              {{frequency | intervalDS}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="M" property="tk_monday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="T" property="tk_tuesday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="W" property="tk_wednesday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="T" property="tk_thursday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="F" property="tk_friday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="S" property="tk_saturday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="S" property="tk_sunday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Enable" property="tk_enable" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
        <ng-template #tableDataLoading>
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-xs-4 col-lg-6">
      <div class="row ml-0">
        <label class="font-weight-bold pt-2">
          Auto Update Statistics Configuration
        </label>
      </div>
    </div>
    <div class="col-xs-8 col-lg-6 text-right">
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button type="button" class="btn btn-secondary" (click)="openDbPriorityModal.openDbModal()"
          appPermissionDisable>Configure Database Priority</button>
      </app-check-server-admin-permissions>
    </div>
  </div>
  <div class="card-block">
    <div class="card-body p-0 pt-2">
      <app-data-table [data]="configData" *ngIf="configData; else tableDataLoading" [sortDescending]="false"
        [rowsPerPage]="20" [minHeight]="dynamicHeight">
        <app-data-table-column title="Name" property="name" [sortable]="false" [searchable]="false">
        </app-data-table-column>
        <app-data-table-column title="Description" property="description" [sortable]="false" [searchable]="false">
        </app-data-table-column>
        <app-data-table-column title="Value" property="value" [sortable]="false" [searchable]="false" width="500px">
          <ng-template appTableCell let-item="item" let-row="row">
            <form>
              <div class="row container p-0" [formGroup]="paramForm">
                <div *ngIf="!row.isEdit" class="pt-2 pl-3">
                  {{row.name === 'AUS_AUTO_RULES' ? (item == 1 ? 'On' : 'Off') : item}}
                </div>
                <div *ngIf="row.isEdit" class="col-3 pt-1">
                  <input appExtendedFormControl *ngIf="row.name !== 'AUS_AUTO_RULES' && row.name !== 'AUS_CHANGE'"
                    [formControlName]="row.name" class="form-control form-control-sm pr-0 mr-1" tooltip="" type="number"
                    [value]="row.value" min="1" max="">
                  <input appExtendedFormControl *ngIf="row.name === 'AUS_CHANGE'" [formControlName]="row.name"
                    class="form-control form-control-sm pr-0 mr-1" tooltip="" type="number" [value]="row.value" min="1"
                    max="100">
                  <select *ngIf="row.name === 'AUS_AUTO_RULES'" [formControlName]="row.name" [value]="row.value"
                    class="form-control form-control-sm pt-1 pb-1">
                    <option value="1">On</option>
                    <option value="0">Off</option>
                  </select>
                </div>
                <div class="pl-1 pt-2">{{valueLabelObj[row.name]}}</div>
                <div *ngIf="!row.isEdit">
                  <app-check-server-admin-permissions [server]="server">
                    <button *ngIf="!row.isEdit" class="btn btn-sm btn-icon" (click)="row.isEdit=true" tooltip="Edit"
                      appPermissionDisable>
                      <i class="icon icon-edit"></i>
                    </button>
                  </app-check-server-admin-permissions>
                </div>
                <div *ngIf="row.isEdit">
                  <app-check-server-admin-permissions [server]="server">
                    <button type="button" class="btn btn-icon btn-sm pb-2" tooltip="Save" (click)="save(row)">
                      <i class="icon icon-check"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-icon pb-2" (click)="row.isEdit=false" tooltip="Cancel"><i
                        class="icon icon-times"></i></button>
                  </app-check-server-admin-permissions>
                </div>
              </div>
            </form>
          </ng-template>
        </app-data-table-column>
      </app-data-table>
      <ng-template #tableDataLoading>
        <i class="icon icon-2x icon-circle-notch icon-spin"></i>
      </ng-template>
    </div>
  </div>
</ng-container>


<ng-template #ausConfigError>
  <div *ngIf="ausConfigApiError" class="mt-2 alert alert-danger">
    <i class="icon icon-danger text-danger"></i> Unable to retrieve auto update statistics information. <br> Error: {{ausConfigApiError}}
  </div>
</ng-template>


<app-task-modal #addTaskSensorModal [server]="server" (saveDone)="saveDoneEvent($event)">
</app-task-modal>
<app-db-priority-modal #openDbPriorityModal></app-db-priority-modal>