<div bsModal #addExpressionsModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{ 'schemaManager.createIndex.expressionModal.title' | translate }}</h5>
        <div>
          <button type="button" class="close" (click)="hideModal()"><i class="icon icon-times"></i></button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="addExpressionsForm">
          <div class="row">
            <div class="col-12 col-sm-4">
              <div>
                <label class="mt-2 mr-2">{{ 'schemaManager.createIndex.expressionModal.fragmentName' | translate }}
                </label>
              </div>
              <div>
                <input class="form-control" type="text" formControlName="name" tooltip="" appExtendedFormControl>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div><label class="mt-2 mr-2">{{spaceType}}</label></div>
              <div>
                <select class="form-control" formControlName="dbspace">
                  <option *ngFor="let dbspace of dbspaceNames" [ngValue]="dbspace">{{dbspace}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div>
                <label class="mt-2 mr-2">{{ 'schemaManager.createIndex.expressionModal.title' | translate }}
                </label>
              </div>
              <div>
                <textarea class="form-control" rows="4" formControlName="expression"
                  [placeholder]="'schemaManager.createIndex.expressionModal.enterExpression' | translate" tooltip=""
                  appExtendedFormControl></textarea>
              </div>
            </div>
          </div>
        </form>
        <div align="right" class="mt-3">
          <div class="pr-0">
            <button type="button" class="btn btn-secondary btn-fixed-width mr-2" (click)="hideModal()">
              {{ 'button.cancel' | translate }}</button>
            <button type="button" class="btn btn-primary mr-2" [disabled]="!addExpressionsForm.valid || isDuplicateName"
              (click)="addAnother()" *ngIf="type === 'add'">
              {{ 'schemaManager.createIndex.expressionModal.addAnother' | translate }}</button>
            <button type="button" class="btn btn-primary btn-fixed-width"
              [disabled]="!addExpressionsForm.valid || isDuplicateName" (click)="addValues('add')"
              *ngIf="type === 'add'">{{ 'button.add' | translate }}</button>
            <button type="button" class="btn btn-primary btn-fixed-width"
              [disabled]="!addExpressionsForm.valid || isDuplicateName" (click)="addValues('save')"
              *ngIf="type === 'edit'">{{ 'button.save' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
