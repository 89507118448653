<div *ngIf="server && sensor">
  <label class="font-weight-bold mt-4">
    Backup History Timeline
  </label>

  <div class="card-body">
    <ng-template [ngIf]="dataLoadErrorMessage">
      <div class="alert alert-warning">
        Server backup history is not available. <br> {{dataLoadErrorMessage}}
      </div>
    </ng-template>

    <app-time-slice [showPlayPause]="false" (timeSliceChanged)="getHistoryData($event)">
    </app-time-slice>
    <ng-template [ngIf]="!hasHistoryData">
      <div class="alert alert-warning mt-3">
        No backup history data available.
      </div>
    </ng-template>
    <div *ngIf="hasHistoryData" style="height: 120px">
      <canvas height="120" #backupHistoryChart> </canvas>
    </div>
  </div>
</div>
