/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
export class MemoryResponse {
  total_mem: Number;
  used_mem: Number;
  lmm_start_threshold: Number;
  lmm_stop_threshold: Number;
  lmm_idle_time: Number;
  lmm_total_killed: any;
  lmm_kill_last_time: any;
  lmm_reduce_last_time: any;
  lmm_restore_last_time: any;
  lmm_enabled: Number;
  os_mem_free: Number;
  os_mem_total: Number;
  os_mem_used: Number;
  session_count: Number;
  max_ses_mem: Number;
  avg_ses_mem: Number;
}
