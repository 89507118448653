<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-8">
      <h4><strong>{{ 'systemReports.updateStatistics' | translate }}</strong>
        <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-3 mb-2 d-flex">
      <label class="pr-2 col-form-label">{{ 'database.database' | translate }}</label>
      <select class="form-control" (change)="changeDatabase($event)">
        <option *ngFor="let database of databases" value="{{database}}">{{database}}
        </option>
      </select>
    </div>
    <div class="col-6">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
    <div class="col-3 mb-2 form-inline justify-content-end align-self-start">
      <label class="mr-2 col-form-label">{{ 'dataTable.filterLabel' | translate }}</label>
      <select class="form-control" [ngModel]="modeFilter" (ngModelChange)="changeModeFilter($event)">
        <option ngValue="all">{{ 'common.all' | translate }}</option>
        <option ngValue="L">{{ 'systemReports.low' | translate }}</option>
        <option ngValue="M">{{ 'systemReports.med' | translate }}</option>
        <option ngValue="H">{{ 'systemReports.high' | translate }}</option>
      </select>
    </div>
  </div>
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" [rowsPerPage]="20" [searchLabel]="'systemReports.searchUpdateStatisticsReport' | translate">
    <app-data-table-column [title]="'table.tableName' | translate" property="tabname" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'table.columnName' | translate" property="colname" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'common.mode' | translate" property="mode" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        <ng-template [ngIf]="item === 'L'">
          {{ 'systemReports.low' | translate }}
        </ng-template>
        <ng-template [ngIf]="item === 'M'">
          {{ 'systemReports.med' | translate }}
        </ng-template>
        <ng-template [ngIf]="item === 'H'">
          {{ 'systemReports.high' | translate }}
        </ng-template>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.constructedDate' | translate" property="build_date" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.resolutionValue' | translate" property="resolution" [sortable]="true"
      [searchable]="true"></app-data-table-column>
    <app-data-table-column [title]="'systemReports.confidenceLevel' | translate" property="confidence" [sortable]="true"
      [searchable]="true"></app-data-table-column>
  </app-data-table>
</div>
