/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnInit } from '@angular/core';
import { JSONTreeElement, JSONTreeGroupElement } from './json-tree-element';

@Component({
  selector: 'app-json-tree',
  templateUrl: 'json-tree.html',
  styleUrls: ['json-explorer.component.scss']
})
export class JSONTreeComponent implements OnInit {

  @Input() tree: JSONTreeElement[];
  @Input() inline = false;

  ngOnInit() {

  }

  toggleExpand(item: JSONTreeGroupElement) {
    if (item.expanded) {
      item.collapse();
    } else {
      item.expand();
    }
  }
}
