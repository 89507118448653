/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { InformixServerSessionsService } from './informixServerSessions.service';

@Component({
  selector: 'app-session-info',
  templateUrl: 'session-info.html'
})
export class SessionInfoComponent {

  server: InformixServer = null;
  sessionId: string = null;
  sessionDetails: any = null;

  breadcrumb: BreadcrumbElement[] = null;
  perfBreadCrumb: BreadcrumbElement = { name: 'Performance' };
  sessionsBreadCrumb: BreadcrumbElement = { name: 'Sessions', link: 'sessions' };

  loadingData = false;
  sessionNotFound = false;
  sessionIdInvalid = false;
  selectedTabIndex = 0;
  constructor(private sessionsService: InformixServerSessionsService,
    private route: ActivatedRoute) {
    this.route.data.subscribe(data => {
      this.setServer(data.server);

      this.route.params.subscribe(params => {
        if (params.sid) {
          this.setSessionId(params.sid);
        } else {
          console.error('missing session id');
        }
      });
    });
  }
  setSelectedTabIndex(index: number) {
    this.selectedTabIndex = index;
  }
  private setServer(server: InformixServer): void {
    this.server = server;
  }

  public setSessionId(sessionId): void {
    this.sessionId = sessionId;
    const sesBreadCrumb: BreadcrumbElement = {
      name: sessionId.toString()
    };
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.perfBreadCrumb, this.sessionsBreadCrumb, sesBreadCrumb]);

    if (isNaN(Number(sessionId))) {
      this.sessionIdInvalid = true;
      return;
    }

    this.refreshData();
  }

  private refreshData() {
    this.loadingData = true;
    this.sessionsService.getServerSessionInfo(this.server.id, Number(this.sessionId)).then(details => {
      this.sessionDetails = details;
      this.loadingData = false;
    }).catch((err: HttpErrorResponse) => {
      if (err.status === 404) {
        this.sessionNotFound = true;
        this.sessionDetails = null;
      }
      this.loadingData = false;
      console.error('Could not get session info', err);
    });
  }
}
