/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { staticSystemReportList } from './system-report-list';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { SystemReport, CustomSystemReport, systemReportCategories } from '../../system-settings/custom-system-reports/system-report';
import { CustomSystemReportsService } from '../../system-settings/custom-system-reports/custom-system-reports.service';
import { InformixServer } from '../../servers/informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ServerBreadcrumb } from '../../servers/serverBreadcrumb';
import { SystemReportsService } from './system-reports.service';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'app-system-reports',
  templateUrl: './system-reports.component.html',
  providers: [SystemReportsService]
})

export class SystemReportsComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  systemReportsBreadcrumb: BreadcrumbElement;
  server: InformixServer = null;

  reportCategories = systemReportCategories;
  selectedCategory = 'all';

  reportList: SystemReport[] = null;
  allReports: SystemReport[] = null;
  staticSystemReportList = staticSystemReportList;
  dynamicSystemReportList: CustomSystemReport[] = null;

  canCreateCustomReport = false;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private customSystemReportsService: CustomSystemReportsService,
    private notificationsService: NotificationsService,
    private userService: UserService
  ) {
    this.canCreateCustomReport = this.userService.getCurrentUserDirty().isSuperAdmin();
  }

  ngOnInit() {
    this.translate.get('systemReports.pageTitle').subscribe((text: string) => {
      this.systemReportsBreadcrumb = { name: text };
    });
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.addReportLabels();
    this.getDynamicReportList();
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    setTimeout(() => this.setBreadcrumb());
  }

  private setBreadcrumb() {
    const breadcrumbs: BreadcrumbElement[] = [this.systemReportsBreadcrumb];
    this.breadcrumb = ServerBreadcrumb.build(this.server, breadcrumbs);
  }

  // Adds the report names and labels from the translation service to the static report list.
  private addReportLabels() {
    this.translate.stream('systemReports').subscribe(
      (messages) => {
        this.staticSystemReportList.forEach((report: SystemReport) => {
          report.name = messages[report.id];
          report.description = messages[report.id + 'Desc'];
        });
      }
    );
  }

  private getDynamicReportList() {
    this.customSystemReportsService.getReports().then(res => {
      this.dynamicSystemReportList = res;
      this.allReports = this.staticSystemReportList.concat(this.dynamicSystemReportList);
      this.reportList = this.allReports;
    }).catch (err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  filterReports(type: string) {
    if (type === 'all') {
      this.reportList = this.allReports;
    } else {
      this.reportList = [];
      this.allReports.forEach(element => {
        if (element.category === type) {
          this.reportList.push(element);
        }
      });
    }
  }

}
