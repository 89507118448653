/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BreadcrumbElement } from '../../../../../shared/breadcrumb.component';
import { ServerBreadcrumb } from '../../../serverBreadcrumb';
import { InformixServer } from '../../../informixServer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnterpriseReplicationService } from '../../enterprise-replication.service';
import { ErReplicate, participant } from '../../replicate';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { ParticipantModalComponent } from '../participant-modal/participant-modal.component';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-new-replicate',
  templateUrl: './new-replicate.component.html',
  styleUrls: ['./new-replicate.component.scss']
})
export class NewReplicateComponent implements OnInit {

  @ViewChild(ParticipantModalComponent) participantModalComponent: ParticipantModalComponent;
  @ViewChild('defineReplicateModal') defineReplicateModal: ModalDirective;
  @ViewChild('replicateErrorModal') replicateErrorModal: ModalDirective;

  breadcrumb: BreadcrumbElement[] = [];
  server: InformixServer = null;
  isLoading: Boolean = false;
  isDomainLoading: Boolean = false;
  isConfirm: Boolean = true;
  isVerificationRequired: Boolean = true;
  isMasterServer: Boolean = false;
  isActiveReplicate: Boolean = false;
  showNextPage: Boolean = false;
  showHour: Boolean = false;
  showDayOfMonth: Boolean = false;
  showInterval: Boolean = false;
  showRepeat: Boolean = false;
  showDay: Boolean = false;
  isStoreProcedure: Boolean = false;
  isAddStoreProcedure: Boolean = false;
  isOptimize: Boolean = false;
  isPerformSyncOp: Boolean = false;
  formSubmit: Boolean = false;
  editingParticipantDeatils: Boolean = false;
  isAddEditParticipant: Boolean = false;
  isVerificationFailed: Boolean = false;
  isCloseModal = false;
  isEdit: Boolean = false;
  formGroup: FormGroup;
  erReplicate: ErReplicate = new ErReplicate();
  participantDetails;
  verificationList;
  replicateErrorList;
  groupNames = [];
  participants = [];
  allGroupNames = [];
  selectedGroupNames = [];
  newParticipants = [];
  deletedParticipants = [];
  replicate: any;
  replicateId: any;
  masterServer: string;
  action = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit(): void {
    // on edit replicate get replicateId from URL
    this.getReplicateId();

    // set informix server
    this.route.data.subscribe(data => {
      this.setServer(data.server);
    });

    this.formGroup = this.fb.group({
      replicateName: new FormControl(null, Validators.required),
      conflictResolutionRule: new FormControl('ignore', Validators.required),
      conflictResolutionScope: new FormControl('transaction', Validators.required),
      frequencyType: new FormControl('immediately', Validators.required),
      dbServer: new FormControl(null),
      queueSize: new FormControl(null),
      isIncludeLargeObj: new FormControl(null),
      interval: new FormControl(null, this.timeFormatValidator),
      frequencyRepeat: new FormControl(null),
      day: new FormControl(null),
      dayOfMonth: new FormControl(null, this.dayFormatValidator),
      time: new FormControl(null, this.timeFormatValidator),
      isReplicateFullRows: new FormControl(true),
      isAddErkey: new FormControl(null),
      isFireTriggers: new FormControl(null),
      isUTF8: new FormControl(null),
      isRetainDeletedRows: new FormControl(null),
      isActiveAbortedTransaction: new FormControl(true),
      isActivateRowInfoSpooling: new FormControl(true),
      isReplicateSerially: new FormControl(null),
      targetRows: new FormControl(null),
      transferFloatingPoint: new FormControl('floatieee'),
      storeProcedures: new FormControl(null)
    });

    this.isConfirm = true;
    this.isMasterServer = true;
    this.getDomain();
  }

  // Custom validator function for time format (10:30 or 10)
  timeFormatValidator(control: FormControl) {
    const timeRegex = /^\d{1,2}(:\d{1,2})?$/;
    if (control.value && !timeRegex.test(control.value)) {
      const errors = { customError: 'Invalid time format, It should be in format hh:mm or mm' };
      control.setErrors(errors);
      return errors;
    }
    return null;
  }

  dayFormatValidator(control: FormControl) {
    const timeRegex = /^(3[0-1]|[12][0-9]|[1-9])$/;
    if (control.value && !timeRegex.test(control.value)) {
      const errors = { customError: 'Invalid day format, It should be in between 1 to 31' };
      control.setErrors(errors);
      return errors;
    }
  }

  getReplicateId() {
    // Get replicateId from query-param
    this.route.queryParamMap.subscribe(queryParam => {
      if (queryParam.has('replicateId')) {
        this.isEdit = true;
        this.replicateId = queryParam.get('replicateId').toString();
      }
      if (queryParam.has('action')) {
        this.action = queryParam.get('action');
        if (this.action === 'modify') {
          this.showNextPage = true;
        }
      }
    });
  }

  setServer(server: InformixServer) {
    this.server = server;
    const breadCrumb = [{ name: 'Replication' },
    { name: 'New Replicate' }];

    if (this.action === 'modify') {
      breadCrumb[1] = { name: 'Modify Replicate' };
    } else if (this.action === 'participants') {
      if (this.isAddEditParticipant) {
        breadCrumb[1] = { name: 'Edit Replicate' };
      } else {
        breadCrumb[1] = { name: 'View Participants' };
      }
    }
    this.breadcrumb = ServerBreadcrumb.build(server, breadCrumb);
  }

  /*
   * Get Domain list
   */
  getDomain() {
    this.isDomainLoading = true;
    this.isLoading = true;
    this.erService.getDomain(this.server).subscribe(domain => {
      this.groupNames = domain.jsonNodes;
      this.allGroupNames = this.groupNames;
      if (this.isEdit) {
        this.getReplicate();
      } else {
        this.isDomainLoading = false;
      }
    }, err => {
      let domainAlertMessage = '';
      if (err.status !== 404) {
        domainAlertMessage = 'Failed to load the Enterprise Replication domain. ';
        if (typeof err === 'string') {
          domainAlertMessage += err;
        } else if (err instanceof HttpErrorResponse && err.error.err) {
          domainAlertMessage += err.error.err;
        } else {
          domainAlertMessage += 'An unknown error occurred.';
        }
      } else if (err instanceof HttpErrorResponse && err.error.err) {
        domainAlertMessage += err.error.err;
      }
      this.notificationsService.pushErrorNotification(domainAlertMessage);
    });
  }

  /*
   * On Edit/Modify replicate
   * Get all replicates from API and find replicate with replicateId
   */
  getReplicate() {
    this.erService.getReplicates(this.server.id).subscribe((res: Array<any>) => {
      const replicateDetails = res.filter(replicate => replicate.replicateId === this.replicateId);
      this.replicate = replicateDetails[0];
      this.setReplicateDetails(this.replicate);
      this.isDomainLoading = false;
      if (this.replicate && this.replicate.replicateName && this.action === 'modify') {
        this.erService.getReplicate(this.server.id, this.replicate.replicateName).subscribe((replicateData: any) => {
          const optionalData = replicateData;
          this.replicate['queueSize'] = optionalData?.queueSize;
          this.replicate['conflictResolutionScope'] = optionalData?.conflictResolutionScope;
          this.replicate['optional'] = optionalData?.optional;
          this.setReplicateOptionalDetails(this.replicate);
        }, err => {
          this.isLoading = false;
        });
      }
    }, err => {
      this.isLoading = false;
    });
  }

  // On Edit OR Modify Replicate - set replicate details
  setReplicateDetails(replicate) {
    const participantDetails = replicate.participants.map((partDetails: participant) => {
      // change property name from partMode to participantType
      if (replicate.masterServer === partDetails.groupName) {
        return {
          groupName: partDetails.groupName,
          databaseName: partDetails.databaseName,
          owner: partDetails.owner,
          tabName: partDetails.tabName,
          selectStmt: partDetails.selectStmt,
          status: partDetails.status,
          participantType: partDetails.partMode,
          isMasterServer: true
        };
      } else {
        return {
          groupName: partDetails.groupName,
          databaseName: partDetails.databaseName,
          owner: partDetails.owner,
          tabName: partDetails.tabName,
          selectStmt: partDetails.selectStmt,
          status: partDetails.status,
          participantType: partDetails.partMode,
          isMasterServer: false
        };
      }
    }
    );

    this.replicate = replicate;
    this.replicate.participants = participantDetails;
    this.masterServer = replicate.masterServer;
    this.setConflictRule(this.replicate);
    this.formGroup.patchValue(this.replicate);

    if (this.action === 'modify') {
      this.setFrequency(replicate.frequency);
    }
    this.participants = replicate.participants;
    if (this.participants && this.participants.length > 0) {
      this.isMasterServer = false;
      this.participants.forEach(partDetails => {
        this.selectedGroupNames.push(partDetails.groupName);
      });
    }

    this.isLoading = false;
    this.updateServer();
  }

  setReplicateOptionalDetails(replicate) {
    if (replicate && replicate.optional) {
      this.formGroup.patchValue(replicate.optional);
      this.formGroup.controls.isAddErkey.disable();
    }
  }

  /**
   * Set frequency details on modify replicate
   */
  setFrequency(frequency) {
    this.formGroup.controls.frequencyType.setValue(frequency.frequencyType);
    this.frequencyChange({ target: { value: frequency.frequencyType } });
    if (frequency && frequency.repeat) {
      this.formGroup.controls.frequencyRepeat.setValue(frequency.repeat);
      this.repeatChanage({ target: { value: frequency.repeat } });
    }
    if (frequency && frequency.dayOfMonth) {
      this.formGroup.controls.dayOfMonth.setValue(frequency.dayOfMonth);
    }
    if (frequency && frequency.hours) {
      this.formGroup.controls.time.setValue(frequency.hours);
    }
    if (frequency && frequency.interval) {
      this.formGroup.controls.interval.setValue(frequency.interval);
    }
    if (frequency && frequency.day) {
      const dayOfWeek = frequency.day.toLowerCase();
      const day = this.erReplicate.days.find(d => d.value.includes(dayOfWeek));
      this.formGroup.controls.day.setValue(day.value);
    }
  }


  /**
   * Set conflict resolution rule on modify replicate
   */
  setConflictRule(replicate) {
    /**
     * conflict resolution cannot change from ignore option to a non-ignore option
     * conflict resolution cannot change from non-ignore option to ignore option.
     * Filter Conflict rules on condition and set to the form
     */
    if (replicate.resolutionRule === 'Ignore') {
      const conflictResolutionRules = this.erReplicate.conflictRules.filter(rule => rule.value === 'ignore');
      this.erReplicate.conflictRules = conflictResolutionRules;
    } else {
      const conflictResolutionRules = this.erReplicate.conflictRules.filter(rule => rule.value !== 'ignore');
      this.erReplicate.conflictRules = conflictResolutionRules;
    }

    if (replicate.storeProcedurePath) {
      this.isAddStoreProcedure = true;
      this.formGroup.controls.storeProcedures.setValue(replicate.storeProcedurePath);
    }

    if (replicate.resolutionRule === 'Timestamp with SPL routine') {
      this.formGroup.controls.conflictResolutionRule.setValue('Time_stamp_with_SPL_routine');
    } else if (replicate.resolutionRule === 'SPL routine') {
      this.formGroup.controls.conflictResolutionRule.setValue('SPL_routine');
    } else {
      const conflictRule =  this.erReplicate.conflictRules.find(rule=> rule.name === replicate.resolutionRule);
      this.formGroup.controls.conflictResolutionRule.setValue(conflictRule.value);
    }
  }

  defineReplicate() {
    this.defineReplicateModal.show();
  }

  /*
   * Prepares replicate data to send over request body
   */
  getReplicateData() {
    const formGroup = this.formGroup.value;
    let requestData;

    if (this.action === 'participants') {
      requestData = {
        replicateName: formGroup.replicateName
      };
      if (this.deletedParticipants && this.deletedParticipants.length > 0) {
        requestData['deleteParticipants'] = this.deletedParticipants;
      }
      if (this.newParticipants && this.newParticipants.length > 0) {
        // remove isErKeyDisable from the payload
        this.newParticipants.forEach(partDetails => delete partDetails.isErKeyDisable);
        requestData['addParticipants'] = this.newParticipants;
      }

    } else if (this.action === 'modify') {
      requestData = {
        replicateName: formGroup.replicateName,
        conflictResolutionRule: formGroup.conflictResolutionRule,
        conflictResolutionScope: formGroup.conflictResolutionScope,
        isOptimize: this.isOptimize,
      };
      if (formGroup.conflictResolutionRule === 'SPL_routine') {
        requestData['conflictResolutionRule'] = formGroup.storeProcedures;
      } else if (formGroup.conflictResolutionRule === 'Time_stamp_with_SPL_routine') {
        requestData['conflictResolutionRule'] = 'timestamp,' + formGroup.storeProcedures;
      }
      const frequencyDetails = this.getFrequency();
      requestData['frequency'] = frequencyDetails;
      const optionalData = this.getOptionalData();
      requestData['optional'] = optionalData;
    } else {
      // remove isErKeyDisable from the payload
      this.participants.forEach(partDetails => delete partDetails.isErKeyDisable);
      // Deep copy the original participants array using JSON to remove unwanted data from participants array
      const participantsCopy = JSON.parse(JSON.stringify(this.participants));
      requestData = {
        replicateName: formGroup.replicateName,
        isVerificationRequired: this.isVerificationRequired,
        participants: participantsCopy,
        conflictResolutionRule: formGroup.conflictResolutionRule,
        conflictResolutionScope: formGroup.conflictResolutionScope,
        isOptimize: this.isOptimize,
        startReplication: {},
        isExecute: true
      };

      if (formGroup.conflictResolutionRule === 'SPL_routine') {
        requestData['conflictResolutionRule'] = formGroup.storeProcedures;
      } else if (formGroup.conflictResolutionRule === 'Time_stamp_with_SPL_routine') {
        requestData['conflictResolutionRule'] = 'timestamp,' + formGroup.storeProcedures;
      }

      const frequencyDetails = this.getFrequency();
      requestData['frequency'] = frequencyDetails;
      const optionalData = this.getOptionalData();
      requestData['optional'] = optionalData;

      if (this.isActiveReplicate) {
        requestData.startReplication['isAllParticipant'] = true;
        requestData.startReplication['isForeGround'] = true;
        requestData.startReplication['queueSize'] = parseInt(formGroup.queueSize, 10);
        requestData.startReplication['isSynchronizeData'] = this.isPerformSyncOp;
        requestData.startReplication['syncDataSource'] = formGroup.dbServer;
        requestData.startReplication['extraTargetRows'] = formGroup.targetRows;
      }

      // if(formGroup.isReplicationKey){
      //   requestData['columnName'] = formGroup.columnName;
      // }

      if (requestData.participants && requestData.participants.length) {
        delete requestData.participants[0].optional;
      }
    }
    return requestData;
  }

  /*
   * Prepares frequency data to send over request body
   */
  getFrequency() {
    const formGroup = this.formGroup.value;
    const frequency = {};
    frequency['frequencyType'] = formGroup.frequencyType;
    if (formGroup.frequencyType === 'every') {
      frequency['interval'] = formGroup.interval;
    } else if (formGroup.frequencyType === 'time_of_day') {
      frequency['repeat'] = formGroup.frequencyRepeat;
      if (formGroup.frequencyRepeat === 'daily' || formGroup.frequencyRepeat === 'last_day_of_month') {
        frequency['hours'] = formGroup.time;
      } else if (formGroup.frequencyRepeat === 'weekly') {
        frequency['day'] = formGroup.day;
        frequency['hours'] = formGroup.time;
      } else if (formGroup.frequencyRepeat === 'monthly') {
        frequency['dayOfMonth'] = parseInt(formGroup.dayOfMonth, 10);
        frequency['hours'] = formGroup.time;
      }
    }
    return frequency;
  }

  /*
   * Prepares optional data to send over request body
   */
  getOptionalData() {
    const formGroup = this.formGroup.value;
    let optionalData;
    if (this.action === 'modify') {
      optionalData = {
        isIncludeLargeObj: formGroup.isIncludeLargeObj,
        isActiveAbortedTransaction: formGroup.isActiveAbortedTransaction,
        isFireTriggers: formGroup.isFireTriggers,
        isReplicateFullRows: formGroup.isReplicateFullRows,
        isRetainDeletedRows: formGroup.isRetainDeletedRows,
        isActivateRowInfoSpooling: formGroup.isActivateRowInfoSpooling,
        isReplicateSerially: formGroup.isReplicateSerially,
        isUTF8: formGroup.isUTF8,
      };
    } else {
      optionalData = {
        isIncludeLargeObj: formGroup.isIncludeLargeObj,
        isAddErkey: formGroup.isAddErkey,
        isActiveAbortedTransaction: formGroup.isActiveAbortedTransaction,
        isFireTriggers: formGroup.isFireTriggers,
        transferFloatingPoint: formGroup.transferFloatingPoint,
        isReplicateFullRows: formGroup.isReplicateFullRows,
        isRetainDeletedRows: formGroup.isRetainDeletedRows,
        isActivateRowInfoSpooling: formGroup.isActivateRowInfoSpooling,
        isReplicateSerially: formGroup.isReplicateSerially,
        isUTF8: formGroup.isUTF8,
        // isReplicationKey: formGroup.isReplicationKey,
        // isDetectReplicationKey: formGroup.isDetectReplicationKey,
      };
    }
    return optionalData;
  }

  /*
   * API call
   * Create new replicate, Modify replicate, and Edit replicate
   */
  proceed() {
    this.formSubmit = true;
    const requestData = this.getReplicateData();
    if (!this.isEdit) {
      this.defineReplicateModal.hide();
      if (this.formGroup.invalid) {
        return;
      }
      this.isLoading = true;
      this.erService.createReplicate(this.server.id, requestData).subscribe((res: any) => {
        this.isConfirm = false;
        this.isLoading = false;
        if (res.createReplicateResponse) {
          if (res.createReplicateResponse.status === 'SUCCESS') {
            // show verification list on success
            const strArray = res.createReplicateResponse.result_message.split(/\n/g);
            this.verificationList = strArray.filter((el) => el.trim() !== '');
          } else {
            this.isVerificationFailed = true;
            // show verification list on error
            const strArray = res.createReplicateResponse.err.split(/\n/g);
            this.verificationList = strArray.filter((el) => el.trim() !== '');
            // this.notificationsService.pushSuccessNotification(res.createReplicateResponse.err);
          }
          this.defineReplicateModal.show();
        }
        if (res.startReplicateResponse) {
          if (res.startReplicateResponse.status === 'SUCCESS') {
            this.notificationsService.pushSuccessNotification('Replication started');
          } else {
            this.notificationsService.pushErrorNotification(res.startReplicateResponse.err);
          }
        }
      }, err => {
        this.isLoading = false;
        this.replicateErrorList = err.error.err.split(/\n/g).filter((el) => el.trim() !== '');
        this.replicateErrorModal.show();
      });
    } else {
      let url = 'edit';
      if (this.action === 'participants') {
        if ((requestData.deleteParticipants && requestData.deleteParticipants.length > 0) ||
          (requestData.addParticipants && requestData.addParticipants.length > 0)) {
          this.isLoading = true;
          this.erService.updateReplicate(this.server.id, url, requestData).subscribe((res: any) => {
            this.isLoading = false;
            if (res.deleteParticipantResponse) {
              if (res.deleteParticipantResponse.status === 'SUCCESS') {
                setTimeout(() => {
                  this.notificationsService.pushSuccessNotification('Replicate updated successfully');
                });
              } else {
                this.notificationsService.pushErrorNotification(res.deleteParticipantResponse.err);
              }
              this.close();
            }
            if (res.addParticipantResponse) {
              if (res.addParticipantResponse.status === 'SUCCESS') {
                // show verification list on success
                this.isConfirm = false;
                const strArray = res.addParticipantResponse.result_message.split(/\n/g);
                this.verificationList = strArray.filter((el) => el !== '');
                this.defineReplicateModal.show();
              } else {
                this.notificationsService.pushErrorNotification(res.addParticipantResponse.err);
              }
            }
          }, err => {
            this.isLoading = false;
            this.notificationsService.pushErrorNotification(err);
          });
        }
      } else {
        url = 'modify';
        this.defineReplicateModal.hide();
        this.isLoading = true;
        this.erService.updateReplicate(this.server.id, url, requestData).subscribe((res: any) => {
          this.isLoading = false;
          if (res.result_message === 'OK') {
            setTimeout(() => {
              this.notificationsService.pushSuccessNotification('Replicate modified successfully');
              this.router.navigate(['dashboard/servers/' + this.server.id + '/replicate']);
            });
          }
        }, err => {
          this.isLoading = false;
          this.notificationsService.pushErrorNotification(err);
        });
      }
    }
  }

  /*
   * Adding participants details to the list
   * this function receives data from participantModalComponent
   */
  addParticipant(eve) {
    const participantData = eve.obj;
    // set masterServer when participant list is empty
    if (this.participants && this.participants.length === 0) {
      participantData['isMasterServer'] = true;
    } else {
      participantData['isMasterServer'] = false;
    }
    this.participants.push(participantData);
    this.newParticipants.push(participantData);
    this.selectedGroupNames.push(participantData.groupName);
    this.isMasterServer = false;
    const isErKeyDisable = this.participants.every(partDetails => partDetails.isErKeyDisable);
    if (isErKeyDisable) {
      this.formGroup.controls.isAddErkey.disable();
    }

    if (this.action === 'partcipants') {
      this.masterServer = this.replicate.masterServer;
    } else {
      this.masterServer = this.participants[0].groupName;
    }
    this.updateServer();
  }

  /*
   * Edit participants details from the list
   * this function receives data from participantModalComponent
   */
  editParticipant(eve) {
    this.participants.map((row, index) => {
      if (index === eve.index) {
        row.groupName = eve.obj.groupName;
        row.databaseName = eve.obj.databaseName;
        row.owner = eve.obj.owner;
        row.isMasterServer = eve.obj.isMasterServer;
        row.tabName = eve.obj.tabName;
        row.participantType = eve.obj.participantType;
        row.isCreateMissingTable = eve.obj.isCreateMissingTable;
        row.selectStmt = eve.obj.selectStmt;
        return row;
      }
    });
    // setTimeout function helps to render updated participant list
    setTimeout(() => {
      this.participants = this.participants.slice();
    }, 0);
  }

  /*
   * Opens participant modal
   */
  openCreateParticipantModal(index, row?: any) {
    let isMasterServer: Boolean;
    if (row) {
      isMasterServer = row.isMasterServer;
    } else {
      isMasterServer = this.isMasterServer;
      this.updateServer();
    }

    setTimeout(() => {
      this.participantModalComponent.openParticipantModal(index, isMasterServer, row);
    });
  }


  /*
   * Updates server group names
   * maintain array of selected group names
   */
  updateServer(isDeleted?: Boolean) {
    if (this.groupNames && this.groupNames.length > 0) {
      this.groupNames = this.groupNames.filter(group => {
        if (this.selectedGroupNames.indexOf(group?.name) === -1) {
          return group;
        }
      });
    }

    // Set isMasterServer flag to true on delete master-participant[last participant]
    if (isDeleted) {
      if (this.participants && this.participants.length === 0) {
        this.isMasterServer = true;
      }
    }
    setTimeout(() => {
      this.participants = this.participants.slice();
      this.groupNames = this.groupNames.slice();
    });
  }

  /*
  * Open participant modal - add participant
  */
  addParticipantsModal() {
    if (this.groupNames && this.groupNames.length) {
      const lastIndex = this.participants.length;
      this.openCreateParticipantModal(lastIndex);
    } else {
      this.notificationsService.pushWarnNotification('All Groups are added, No new group available!!');
    }
  }

  /*
   * Open participant modal- Edit participant
   */
  openEdit(row) {
    const index = this.participants.indexOf(row);
    this.openCreateParticipantModal(index, row);
  }

  confirmDelete(row) {
    let msg = `delete <b>${row.groupName}</b> participant`;
    this.confirmationDialogService.show('Delete Participant', msg, () => this.deleteParticipant(row));
  }

  /*
   * Delete participant details from the list
   */
  deleteParticipant(row) {

    if (this.participants && this.participants.length > 1 && this.masterServer === row.groupName) {
      this.notificationsService.pushErrorNotification('Master participant cannot be deleted before deleting other participants');
      return;
    }

    // remove group_name from selected participant list
    const index = this.participants.indexOf(row);
    const participantDetails = this.participants.splice(index, 1);
    const selectedIndex = this.selectedGroupNames.indexOf(participantDetails[0].groupName);
    this.selectedGroupNames.splice(selectedIndex, 1);
    const groupDetails = this.allGroupNames.find(group => group.name === participantDetails[0].groupName);
    this.groupNames.push(groupDetails);

    // If Delete newly added participant before save - return
    if (this.newParticipants && this.newParticipants.length > 0) {
      const NewParticipant = this.newParticipants.filter(partDetails => partDetails.groupName === row.groupName);
      if (NewParticipant && NewParticipant.length > 0) {
        this.newParticipants = this.newParticipants.filter(partDetails => partDetails.groupName !== row.groupName);
        this.updateServer(true);
        return;
      }
    }

    this.deletedParticipants.push(participantDetails[0]);
    this.updateServer(true);
  }

  /*
   * Add/Delete participants - API call to update participant changes
   */
  save() {
    this.isEdit = true;
    this.proceed();
  }

  /*
   * Allows to Edit unsaved participant details
   */
  isEditable(participantDetails) {
    const index = this.newParticipants.indexOf(participantDetails);
    if (index >= 0) {
      return true;
    }
    return false;
  }

  close() {
    if(this.action === 'participants'){
      this.redirect();
      return;
    }

    if (!this.isCloseModal) {
      this.isConfirm = true;
      this.isCloseModal = true;
      this.defineReplicateModal.show();
    } else {
      this.redirect();
    }
  }

  next() {
    this.showNextPage = !this.showNextPage;
    // set null sync-data-value if any change in participants add or remove on selected sync-data-value
    if (this.showNextPage && this.formGroup.controls.dbServer.value) {
      if (this.selectedGroupNames.indexOf(this.formGroup.controls.dbServer.value) === -1) {
        this.formGroup.controls.dbServer.setValue(null);
        this.notificationsService.pushWarnNotification('Please re-select the Sync data source value');
      }
    }
  }

  toggleSync() {
    this.isPerformSyncOp = !this.isPerformSyncOp;
  }

  /*
   * On change conflict_resolution_rule
   */
  resolutionRule(event) {
    // show-hide optional value retain deleted rows
    if (event.target.value === 'deletewins') {
      this.formGroup.controls.isRetainDeletedRows.disable();
    } else {
      this.formGroup.controls.isRetainDeletedRows.enable();
    }

    if (event.target.value === 'SPL_routine' || event.target.value === 'Time_stamp_with_SPL_routine') {
      this.isAddStoreProcedure = true;
    } else {
      this.isAddStoreProcedure = false;
    }
  }

  storeProcedures() {
    this.isOptimize = !this.isOptimize;
  }

  colNameVerification() {
    this.isVerificationRequired = !this.isVerificationRequired;
  }

  activateReplicate() {
    this.isActiveReplicate = !this.isActiveReplicate;
  }

  /*
   * On change frequency - hide-show specific frequency textbox
   */
  frequencyChange(event) {
    this.formGroup?.controls?.frequencyRepeat.reset();
    switch (event.target.value) {
      case 'immediately':
        this.showInterval = false;
        this.showRepeat = false;
        this.showHour = false;
        this.showDayOfMonth = false;
        this.showDay = false;
        break;
      case 'every':
        this.showInterval = true;
        this.showRepeat = false;
        this.showHour = false;
        this.showDayOfMonth = false;
        this.showDay = false;
        this.formGroup?.controls.interval.reset();
        break;
      case 'time_of_day':
        this.showInterval = false;
        this.showRepeat = true;
        break;
    }
  }

  /*
   * On change frquency_repeat - hide-show specific frequency_repeat textbox
   */
  repeatChanage(event) {
    this.showInterval = false;
    const form = this.formGroup?.controls;
    form.time.reset();
    switch (event.target.value) {
      case 'daily':
        this.showHour = true;
        this.showDayOfMonth = false;
        this.showDay = false;
        break;
      case 'weekly':
        this.showDay = true;
        this.showHour = true;
        this.showDayOfMonth = false;
        form.day.reset();
        break;
      case 'monthly':
        this.showDayOfMonth = true;
        this.showHour = true;
        this.showDay = false;
        form.dayOfMonth.reset();
        break;
      case 'last_day_of_month':
        this.showHour = true;
        this.showDayOfMonth = false;
        this.showDay = false;
        break;
    }
  }

  isErKeyDisable() {
    return this.participants.every(partDetails => partDetails.isErKeyDisable);
  }

  closeModal(isRedirect: Boolean) {
    this.defineReplicateModal.hide();
    if (isRedirect) {
      this.redirect();
    } else {
      this.isCloseModal = false;
    }
  }

  redirect() {
    this.router.navigate(['dashboard/servers/' + this.server.id + '/replicate']);
  }
}
