/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AbstractSettingsComponent } from './abstractSettings.component';

@Component({
  selector: 'app-user-twilio-settings',
  template: `
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Phone Number</label>
    <div class="col-sm-6">
      <input type="text" class="form-control form-control-sm" [formControl]="phoneNumberControl" tooltip="" placement="right"
        appExtendedFormControl>
    </div>
  </div>
  `
})
export class UserTwilioSettingsComponent extends AbstractSettingsComponent implements OnChanges {

  phoneNumberControl: UntypedFormControl;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.settings) {
      this.settings = { phoneNumber: null };
    }

    if (!this.phoneNumberControl) {
      this.phoneNumberControl = new UntypedFormControl(null, [Validators.required]);

      this.phoneNumberControl.valueChanges.subscribe(() => {
        this.eventChange.emit({
          valid: this.phoneNumberControl.valid,
          modified: this.phoneNumberControl.value !== this.settings.phoneNumber
        });
      });
    }

    this.phoneNumberControl.setValue(this.settings.phoneNumber);
  }

  public getSettings(): any {
    return { phoneNumber: this.phoneNumberControl.value };
  }

  public requiresSettings(): boolean {
    return true;
  }

  public isValid(): boolean {
    return this.phoneNumberControl && this.phoneNumberControl.valid;
  }
}
