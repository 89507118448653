/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018. All Rights Reserved.
 *******************************************************************************/
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainInterceptor } from './main-interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true }
];
