/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Subject } from 'rxjs';
import { DashboardBasePanel } from './dashboard-base-panel';
import { DashboardItem } from './dashboard-item';
import { DashboardPanel } from './dashboard-panel';

export const GRID_HORIZONTAL_CHUNKS = 24;
export const GRID_HEIGHT_STEP = 25;
export const GRID_PANEL_PADDING = 10;

const POTENTIAL_PANEL_MIN_WIDTH = 6;
const POTENTIAL_PANEL_MIN_HEIGHT = 6;
const LAST_PANEL_HEIGHT = 8;

class DashboardFlow {
  private panels: DashboardBasePanel[];

  constructor(panels: DashboardPanel[], activePanel: DashboardPanel = null) {
    this.panels = panels.filter(v => v !== activePanel);
    this.sortPanels();
  }

  private sortPanels() {
    this.panels.sort((a, b) => {
      if (a.y !== b.y) {
        return a.y - b.y;
      } else {
        return a.x - b.x;
      }
    });
  }

  tryPanelPosition(newPanel: DashboardBasePanel) {
    this.shiftPanelsUp();
    this.sortPanels();

    let shadowIndex = -1;
    for (let i = 0; i < this.panels.length; i++) {
      const panel = this.panels[i];
      if (newPanel.overlaps(panel)) {
        this.shiftPanelsDown(i, newPanel.y + newPanel.height - panel.y);
        this.sortPanels();
        shadowIndex = i;
        break;
      } else if (panel.y > newPanel.y || (panel.y === newPanel.y && panel.x > newPanel.x)) {
        shadowIndex = i;
        break;
      }
    }

    if (shadowIndex > -1) {
      this.panels.splice(shadowIndex, 0, newPanel);
    } else {
      shadowIndex = this.panels.length;
      this.panels.push(newPanel);
    }

    this.shiftPanelsUp();
    this.panels.splice(shadowIndex, 1);
    this.sortPanels();
  }

  private shiftPanelsDown(startIndex: number, amount: number) {
    for (let i = startIndex; i < this.panels.length; i++) {
      this.panels[i].y += amount;
    }
  }

  shiftPanelsUp() {
    this.panels.forEach((panel, index) => {
      if (panel.y > 0) {
        panel.y = this.findTopBound(panel, index - 1);
      }
    });
  }

  private findTopBound(panel: DashboardBasePanel, startIndex: number): number {
    let topBound = 0;
    for (let i = startIndex; i >= 0; i--) {
      if (panel.x + panel.width <= this.panels[i].x) {
        continue;
      }
      if (panel.x >= this.panels[i].x + this.panels[i].width) {
        continue;
      }
      if (this.panels[i].y + this.panels[i].height > topBound) {
        topBound = this.panels[i].y + this.panels[i].height;
      }
    }
    return topBound;
  }
}

class DashboardGridGapCalculator {

  private grid: boolean[][];
  private emptyCellCount: number;

  constructor(private panels: DashboardBasePanel[], private gridHeight: number) {
    this.emptyCellCount = this.gridHeight * GRID_HORIZONTAL_CHUNKS;
  }

  calculateGapPanels(): DashboardBasePanel[] {
    const gapPanels: DashboardBasePanel[] = [];
    const emptyCellTreshold = POTENTIAL_PANEL_MIN_WIDTH * POTENTIAL_PANEL_MIN_HEIGHT;

    this.grid = [];
    for (let x = 0; x < GRID_HORIZONTAL_CHUNKS; x++) {
      const gridColumn: boolean[] = [];
      for (let y = 0; y < this.gridHeight; y++) {
        gridColumn.push(false);
      }
      this.grid.push(gridColumn);
    }

    this.panels.forEach(panel => this.fillGrid(panel));
    if (this.emptyCellCount < emptyCellTreshold) {
      return gapPanels;
    }

    for (let x = 0; x <= GRID_HORIZONTAL_CHUNKS - POTENTIAL_PANEL_MIN_WIDTH; x++) {
      for (let y = 0; y <= this.gridHeight - POTENTIAL_PANEL_MIN_HEIGHT; y++) {
        if (!this.grid[x][y]) {
          const gapPanel = this.tryGap(x, y);
          if (gapPanel) {
            gapPanels.push(gapPanel);
            this.fillGrid(gapPanel);
            if (this.emptyCellCount < emptyCellTreshold) {
              return gapPanels;
            }
          }
        }
      }
    }

    return gapPanels;
  }

  private fillGrid(panel: DashboardBasePanel) {
    for (let x = 0; x < panel.width; x++) {
      for (let y = 0; y < panel.height; y++) {
        this.grid[panel.x + x][panel.y + y] = true;
        this.emptyCellCount--;
      }
    }
  }

  private tryGap(startX: number, startY: number): DashboardBasePanel {
    let currentHeight = 0;
    let x = startX;
    for (; x < GRID_HORIZONTAL_CHUNKS; x++) {
      let y = startY;
      for (; y < this.gridHeight; y++) {
        if (this.grid[x][y]) {
          break;
        }
      }

      const height = y - startY;
      if (height < POTENTIAL_PANEL_MIN_HEIGHT) {
        break;
      } else if (!currentHeight || height < currentHeight) {
        currentHeight = height;
      }
    }

    const width = x - startX;
    if (width >= POTENTIAL_PANEL_MIN_WIDTH) {
      return new DashboardBasePanel(startX, startY, width, currentHeight);
    }

    return null;
  }
}

export class DashboardGrid implements DashboardItem {
  panels: DashboardPanel[] = [];
  activePanel: DashboardPanel = null;
  shadowPanel: DashboardBasePanel = null;
  potentialPanels: DashboardBasePanel[] = null;
  height = 0;
  minHeight = 0;

  renderX = 0;
  renderY = 0;
  renderWidth = 0;
  renderHeight = 0;

  cssStyle: any = {};
  cssStyleChanged = new Subject<any>();

  private dashboardFlow: DashboardFlow;

  addPanel(panel: DashboardPanel) {
    panel.setParent(this);
    this.updatePanel(panel);
    const bottomY = panel.y + panel.height;
    if (bottomY > this.height) {
      this.height = bottomY;
      this.renderHeight = this.height * GRID_HEIGHT_STEP + GRID_PANEL_PADDING;
      this.updateCssStyle();
    }
    this.panels.push(panel);
    this.potentialPanels = null;
  }

  removePanel(panel: DashboardPanel) {
    const index = this.panels.indexOf(panel);
    if (index > -1) {
      if (this.activePanel === panel) {
        this.activePanel = null;
      }
      this.panels.splice(index, 1);

      const dashboardFlow = new DashboardFlow(this.panels);
      dashboardFlow.shiftPanelsUp();
      this.potentialPanels = null;
      this.calculateRenderHeight();
      this.updateAllPanels();
    }
  }

  private calculateRenderHeight() {
    const maxHeight = this.panels.reduce((prev, current) => {
      const height = current.y + current.height;
      return height > prev ? height : prev;
    }, 0);
    this.height = Math.max(maxHeight, this.minHeight);
    this.renderHeight = this.height * GRID_HEIGHT_STEP + GRID_PANEL_PADDING;
    this.updateCssStyle();
  }

  private updateCssStyle() {
    let renderHeight = this.renderHeight;
    if (!this.activePanel && this.potentialPanels) {
      renderHeight += LAST_PANEL_HEIGHT * GRID_HEIGHT_STEP;
    }

    this.cssStyle = {
      height: renderHeight + 'px'
    };

    this.cssStyleChanged.next();
  }

  startMoving(panel: DashboardPanel, mouseX: number, mouseY: number) {
    this.activePanel = panel;
    this.activePanel.startMoving(mouseX, mouseY);
    this.dashboardFlow = new DashboardFlow(this.panels, this.activePanel);
    this.shadowPanel = panel.clone();
    this.calculateRenderHeight();
    this.minHeight = this.height;
    this.updatePanel(this.shadowPanel);
  }

  startResizing(panel: DashboardPanel, mouseX: number, mouseY: number) {
    this.activePanel = panel;
    this.activePanel.startResizing(mouseX, mouseY);
    this.dashboardFlow = new DashboardFlow(this.panels, this.activePanel);
    this.shadowPanel = panel.clone();
    this.calculateRenderHeight();
    this.minHeight = this.height;
    this.updatePanel(this.shadowPanel);
  }

  onMouseMove(event: any) {
    if (this.activePanel) {
      const prevX = this.activePanel.x;
      const prevY = this.activePanel.y;
      const prevWidth = this.activePanel.width;
      const prevHeight = this.activePanel.height;
      this.activePanel.onMouseMove(event.clientX, event.clientY);
      if (prevX !== this.activePanel.x || prevY !== this.activePanel.y
        || prevWidth !== this.activePanel.width || prevHeight !== this.activePanel.height) {
        this.shadowPanel.x = this.activePanel.x;
        this.shadowPanel.y = this.activePanel.y;
        this.shadowPanel.width = this.activePanel.width;
        this.shadowPanel.height = this.activePanel.height;
        this.dashboardFlow.tryPanelPosition(this.shadowPanel);
        this.calculateRenderHeight();
        this.updatePanel(this.shadowPanel);
        this.updateAllPanels();
      }
    }
  }

  onMouseUp() {
    if (this.activePanel) {
      const panel = this.activePanel;
      this.activePanel = null;

      panel.isMoving = false;
      panel.isResizing = false;
      panel.x = this.shadowPanel.x;
      panel.y = this.shadowPanel.y;
      panel.width = this.shadowPanel.width;
      panel.height = this.shadowPanel.height;
      this.updatePanel(panel);

      this.shadowPanel = null;
      this.potentialPanels = null;
      this.minHeight = 0;
      this.calculateRenderHeight();
    }
  }

  setRenderWidth(width: number) {
    if (this.renderWidth === width) {
      return;
    }

    this.renderWidth = width;
    this.updateAllPanels();
  }

  private updateAllPanels() {
    this.panels.forEach(panel => this.updatePanel(panel));
    if (this.potentialPanels) {
      this.potentialPanels.forEach(panel => this.updatePanel(panel));
    }
  }

  private updatePanel(panel: DashboardBasePanel) {
    if (panel === this.activePanel) {
      return;
    }

    const unitWidth = this.getUnitWidth();
    panel.renderX = Math.floor(unitWidth * panel.x);
    panel.renderY = panel.y * GRID_HEIGHT_STEP + GRID_PANEL_PADDING;
    panel.renderWidth = Math.floor(unitWidth * panel.width - GRID_PANEL_PADDING);
    panel.renderHeight = panel.height * GRID_HEIGHT_STEP - GRID_PANEL_PADDING;
    panel.updateCssStyle();
  }

  private getUnitWidth() {
    return (this.renderWidth + GRID_PANEL_PADDING) / GRID_HORIZONTAL_CHUNKS;
  }

  calculatePotentialPanels() {
    const gapCalculator = new DashboardGridGapCalculator(this.panels, this.height);
    this.potentialPanels = gapCalculator.calculateGapPanels();
    const lastPanel = new DashboardBasePanel(0, this.height, GRID_HORIZONTAL_CHUNKS, LAST_PANEL_HEIGHT);
    lastPanel.setParent(this);
    this.potentialPanels.push(lastPanel);
    this.potentialPanels.forEach(panel => this.updatePanel(panel));
    this.updateCssStyle();
  }

  clearPotentialPanels() {
    if (this.potentialPanels) {
      this.potentialPanels = null;
      this.calculateRenderHeight();
    }
  }
}
