/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
export class Levenshtein {

  private static calculateLevenshteinLastRow(s1: string, s2: string, search = false): number[] {
    let v0 = new Array<number>(s1.length + 1);
    let v1 = new Array<number>(s1.length + 1);

    for (let i = 0; i <= s1.length; i++) {
      v0[i] = search ? 0 : i;
    }

    for (let j = 0; j < s2.length; j++) {
      v1[0] = j + 1;

      for (let i = 0; i < s1.length; i++) {
        const subCost = s1.charCodeAt(i) === s2.charCodeAt(j) ? 0 : 1;
        v1[i + 1] = Math.min(v1[i] + 1, v0[i + 1] + 1, v0[i] + subCost);
      }

      const vt = v0;
      v0 = v1;
      v1 = vt;
    }

    return v0;
  }

  public static getDistance(s1: string, s2: string): number {
    if (!s1) {
      return s2.length;
    }
    if (!s2) {
      return s1.length;
    }

    return Levenshtein.calculateLevenshteinLastRow(s1, s2)[s1.length];
  }

  public static search(needle: string, haystack: string): number {
    if (!needle || !haystack) {
      return -1;
    }

    const row = Levenshtein.calculateLevenshteinLastRow(haystack, needle, true);
    let min = row[0];
    for (let i = 1; i < row.length; i++) {
      if (row[i] < min) {
        min = row[i];
      }
    }

    return min;
  }
}
