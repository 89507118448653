<!-- Page Heading -->
<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <h4><strong>Task Scheduler</strong></h4>
  <app-tab-group (eventUpdate)="setSelectedTabIndex($event)">
    <app-tab label="Scheduler Details">
      <app-task-scheduler *ngIf="selectedTabIndex === 0"></app-task-scheduler>
    </app-tab>
    <app-tab label="Run History">
      <app-task-scheduler-run-history *ngIf="selectedTabIndex === 1"></app-task-scheduler-run-history>
    </app-tab>
  </app-tab-group>
</div>
