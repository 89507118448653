/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractAlertConfigComponent } from './alertConfig';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-run-script-config',
  templateUrl: 'alertScript.html'
})
export class AlertScriptConfigComponent extends AbstractAlertConfigComponent implements OnInit {

  private config: any;
  formGroup: UntypedFormGroup = null;

  ngOnInit() {
  }

  public getType() {
    return 'alertscript';
  }

  public setConfig(config: any) {
    if (!config) {
      config = {};
    }
    this.config = config;

    if (!this.formGroup) {
      this.formGroup = new UntypedFormGroup({
        path: new UntypedFormControl(config.path, [Validators.required])
      });
    }
  }

  public getConfig() {
    return this.formGroup.value;
  }

  public isValid(): boolean {
    return this.formGroup && this.formGroup.valid;
  }

  public isModified(): boolean {
    const form = this.formGroup.value;
    return this.config.path !== form.path;
  }
}
