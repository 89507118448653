
<ng-container *ngIf="tableData && tableData.length else ausCommandsError;">
  <div class="row pt-2">
    <div class="col-8">
      <label class="font-weight-bold pt-1">
        Update Statistics Commands
      </label>
    </div>
    <div class="col-4 text-right">
      <label class="pr-2">Commands</label>
      <select class="form-control-sm" (change)="getCommandsData($event)">
        <option *ngFor="let commandType of commandTypes" value="{{commandType.command}}">{{commandType.name}}</option>
      </select>
    </div>
  </div>
  <div class="card-block">
    <div class="card-body p-0 pt-1">
      <div class="table-responsive">
        <app-data-table  noDataAlertMessage="No commands available." [data]="tableData" *ngIf="tableData; else tableDataLoading" [sortDescending]="true"
          [rowsPerPage]="20">
          <app-data-table-column title="Command" property="command" [sortable]="true" [searchable]="true">
          </app-data-table-column>
        </app-data-table>
        <ng-template #tableDataLoading>
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #ausCommandsError>
  <div *ngIf="ausCommandsApiError" class="mt-2 alert alert-danger">
    <i class="icon icon-danger text-danger"></i> Unable to retrieve auto update statistics information. <br> Error: {{ausCommandsApiError}}
  </div>
</ng-template>