<div style="float: right" class="mt-2">
  <app-check-server-admin-permissions [server]="server">
      <app-check-hdr-permissions [server]="server" [hdrPermission]="backupHDR" placement="top">
           <button *ngIf="!isEditingConfig" type="button" class="btn btn-primary btn-sm mr-1" title="Edit Configuration"
             (click)="doEditConfiguration()" appHDRPermission [hdrPermission]="backupHDR" appPermissionDisable>
             <i class="icon icon-edit"></i>
           </button>
      </app-check-hdr-permissions>
  </app-check-server-admin-permissions>
  <app-check-server-admin-permissions [server]="server">
      <app-check-hdr-permissions [server]="server" [hdrPermission]="backupHDR" placement="top">
           <button *ngIf="isEditingConfig" type="button" class="btn btn-primary btn-sm mr-1" title="Save Configuration"
             [disabled]="!selectedBackupType || !editConfigurationFormGroup.valid" (click)="saveConfiguration()"
             appHDRPermission [hdrPermission]="backupHDR" appPermissionDisable>
             <i class="icon icon-save"></i>
           </button>
      </app-check-hdr-permissions>
  </app-check-server-admin-permissions>
  <app-check-server-admin-permissions [server]="server">
      <app-check-hdr-permissions [server]="server" [hdrPermission]="backupHDR" placement="top">
           <button *ngIf="isEditingConfig" type="button" class="btn btn-primary btn-sm mr-1" appHDRPermission [hdrPermission]="backupHDR" title="Undo Edits" (click)="cancelEditConfiguration()"
             appPermissionDisable>
             <i class="icon icon-reset"></i>
           </button>
      </app-check-hdr-permissions>
  </app-check-server-admin-permissions>
  <app-check-server-admin-permissions [server]="server" *ngIf="_backupTasks.backupsConfigured">
      <app-check-hdr-permissions [server]="server" [hdrPermission]="backupHDR" placement="top">
           <button type="button" class="btn btn-primary btn-sm" title="Delete Configuration" (click)="confirmDeleteConfiguration()"
             [disabled]="isEditingConfig" appHDRPermission [hdrPermission]="backupHDR" appPermissionDisable>
             <i class="icon icon-trash"></i>
           </button>
      </app-check-hdr-permissions>

  </app-check-server-admin-permissions>
</div>

<div *ngIf="_backupTasks" class="mt-3">
  <ng-template [ngIf]="!isEditingConfig">
    <div *ngIf="_backupTasks.backupsConfigured">
      <div *ngIf="_backupTasks.backupsEnabled">
        <i class="icon icon-2x icon-check-circle text-success" title="Backups tasks configured"></i>
        Backup tasks configured.
      </div>
      <div *ngIf="!_backupTasks.backupsEnabled">
        <i class="icon icon-2x icon-warning text-warning" title="Backups tasks configured but not enabled"></i>
        Backup tasks configured but not enabled.
      </div>
    </div>
    <div *ngIf="!_backupTasks.backupsConfigured">
      <i class="icon icon-2x icon-warning text-warning" title="Backups tasks not configured"></i>
      Backup tasks not configured.
    </div>

    <div class="mt-3" *ngIf="_backupTasks.backupsConfigured">
      <div class="row">
        <div class="col-sm-4 col-xl-2">
          Type:
        </div>
        <div class="col-sm-8 col-xl-10">
          <strong>{{_backupTasks.backupType}}</strong>
        </div>
      </div>
      <ng-template [ngIf]="_backupTasks.backupType === 'ontape'">
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Device Type:
          </div>
          <div class="col-sm-8 col-xl-10">
            {{_backupTasks.backupParams.ONTAPE_DEVICE_TYPE}}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Path (TAPEDEV):
          </div>
          <div class="col-sm-8 col-xl-10">
            {{_backupTasks.backupParams.ONTAPE_TAPEDEV}}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Block Size (TAPEBLK):
          </div>
          <div class="col-sm-8 col-xl-10">
            {{_backupTasks.backupParams.ONTAPE_TAPEBLK}} KB
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="_backupTasks.backupType === 'onbar'">
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Parallel Backup Streams:
          </div>
          <div class="col-sm-8 col-xl-10">
            {{(_backupTasks.backupOnconfigs.BAR_MAX_BACKUP == 0)? 'Maximum number of processes allowed on system' :
            _backupTasks.backupOnconfigs.BAR_MAX_BACKUP}}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Buffers per Stream:
          </div>
          <div class="col-sm-8 col-xl-10">
            {{_backupTasks.backupOnconfigs.BAR_NB_XPORT_COUNT}}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Buffer Size:
          </div>
          <div class="col-sm-8 col-xl-10">
            {{_backupTasks.backupOnconfigs.BAR_XFER_BUF_SIZE}} pages
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xl-2">
            Backup Generations to Retain:
          </div>
          <div class="col-sm-8 col-xl-10">
            {{(_backupTasks.backupParams.BACKUP_GENR_RETAIN == -1)? 'All' :
            _backupTasks.backupParams.BACKUP_GENR_RETAIN}}
          </div>
        </div>
      </ng-template>
      <div class="mt-2 row">
        <div class="col-sm-4 col-xl-2">
          Schedule:
        </div>
      </div>
      <div class="row">
        <div class="col-12 table-responsive">
          <app-data-table *ngIf="backupLevelTasks" [data]="backupLevelTasks" sortBy="level" noDataAlertMessage="Backup schedule is not available."
            noDataAlertType="warning">
            <app-data-table-column title="Level" property="level">
            </app-data-table-column>
            <app-data-table-column title="Start Time" property="tk_start_time">
            </app-data-table-column>
            <app-data-table-column title="Monday" property="tk_monday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Tuesday" property="tk_tuesday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Wednesday" property="tk_wednesday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Thursday" property="tk_thursday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Friday" property="tk_friday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Saturday" property="tk_saturday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Sunday" property="tk_sunday">
              <ng-template appTableCell let-item="item">
                <i *ngIf="item" class="icon icon-check text-success"></i>
                <i *ngIf="!item" class="icon icon-times text-muted"></i>
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="mt-3" *ngIf="isEditingConfig">
    <div class="row">
      <div class="col-12 mb-2">
        <strong>Backup Utility</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-2">
        Specify the utility to use to backup the storage spaces:
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 d-inline-flex">
        <div class="input-group input-group-sm mr-2 mb-2" style="width: auto">
          <span class="input-group-btn">
            <button type="button" class="btn btn-sm" [ngClass]="selectedBackupType === 'onbar' ? 'btn-success' : 'btn-secondary'"
              (click)="setBackupType('onbar')">
              <i class="icon icon-check"></i>
            </button>
          </span>
          <span class="input-group-btn">
            <button type="button" class="btn btn-sm btn-secondary" (click)="setBackupType('onbar')">
              <strong>ON-Bar</strong>
            </button>
          </span>
        </div>
        <div>ON-Bar backs up storage spaces by using a storage manager that tracks backups and storage media.
          Storage Manager: <strong>{{serverType}} Primary Storage Manager</strong></div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="input-group input-group-sm d-inline-flex mr-2 mb-2" style="width: auto">
          <span class="input-group-btn">
            <button type="button" class="btn btn-sm" [ngClass]="selectedBackupType === 'ontape' ? 'btn-success' : 'btn-secondary'"
              (click)="setBackupType('ontape')">
              <i class="icon icon-check"></i>
            </button>
          </span>
          <span class="input-group-btn">
            <button type="button" class="btn btn-sm btn-secondary" (click)="setBackupType('ontape')">
              <strong>Ontape</strong>
            </button>
          </span>
        </div>
        Ontape backs up storage spaces without using any storage manager.
      </div>
    </div>
    <div *ngIf="selectedBackupType" class="row">
      <div class="col-12 mt-3 mb-3">
        <strong>{{(selectedBackupType === 'ontape')? 'Ontape' : 'ON-Bar'}} Configuration</strong>
        <div *ngIf="selectedBackupType === 'onbar'" class="input-group input-group-sm d-inline-flex ml-2" style="width: auto">
          <span class="input-group-btn">
            <button type="button" class="btn btn-sm" [ngClass]="!showOnbarAdvancedConfig ? 'btn-primary' : 'btn-secondary'"
              (click)="showOnbarAdvancedConfig = false">
              Basic
            </button>
          </span>
          <span class="input-group-btn">
            <button type="button" class="btn btn-sm" [ngClass]="showOnbarAdvancedConfig ? 'btn-primary' : 'btn-secondary'"
              (click)="showOnbarAdvancedConfig = true">
              Advanced
            </button>
          </span>
        </div>
      </div>
    </div>
    <ng-template [ngIf]="selectedBackupType === 'ontape' && editConfigurationFormGroup">
      <form role="form" [formGroup]="editConfigurationFormGroup" class="mb-2">
        <div class="row mb-2">
          <div class="col-sm-4 col-xl-2">
            Device Type:
          </div>
          <div class="col-sm-8 col-xl-10">
            <app-radio-group formControlName="deviceType">
              <app-radio value="tape">Tape</app-radio>
              <app-radio class="ml-3" value="file">Disk file</app-radio>
              <app-radio class="ml-3" value="directory">Disk directory</app-radio>
            </app-radio-group>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 col-xl-2">
            Path (TAPEDEV):
          </div>
          <div class="col-sm-8 col-xl-10">
            <input type="text" class="form-control form-control-sm" formControlName="path" tooltip=""
              appExtendedFormControl>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 col-xl-2">
            Block Size (TAPEBLK):
          </div>
          <div class="col-sm-8 col-xl-10">
            <input type="number" class="form-control form-control-sm" min="16" max="1024" formControlName="blockSize"
              tooltip="" appExtendedFormControl>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 col-xl-2">
            Device Size (TAPESIZE):
          </div>
          <div class="col-sm-8 col-xl-10">
            Unlimited
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template [ngIf]="selectedBackupType === 'onbar' && editConfigurationFormGroup">
      <form role="form" [formGroup]="editConfigurationFormGroup" class="mb-2">
        <div *ngIf="!showOnbarAdvancedConfig" class="row mb-2 form-inline">
          <div class="col-sm-4 col-xl-2">
            Backup Speed:
          </div>
          <div class="col-sm-8 col-xl-10">
            Slow
            <mv-slider class="ml-3 mr-3" [(value)]="onbarBackupSpeedValue" [min]="onbarBackupSpeedMin" [max]="onbarBackupSpeedMax"
              (change)="updateOnbarBackupSlider($event)"></mv-slider>
            Fast
          </div>
        </div>
        <ng-template [ngIf]="showOnbarAdvancedConfig">
          <div class="row mb-2 form-inline">
            <div class="col-sm-4 col-xl-2">
              Parallel Backup Streams:
            </div>
            <div class="d-flex align-items-center col-sm-8 col-xl-10">
              <input type="number" class="form-control form-control-sm" min="0" max="1000" formControlName="parallelBackupStreams"
                tooltip="" appExtendedFormControl>
              <app-checkbox class="ml-2" formControlName="parallelBackupStreamsUseSystem">
                Use maximum number of processes allowed on the system
              </app-checkbox>
            </div>
          </div>
          <div class="row mb-2 form-inline">
            <div class="col-sm-4 col-xl-2">
              Buffers per Stream:
            </div>
            <div class="col-sm-8 col-xl-10">
              <input type="number" class="form-control form-control-sm" min="1" max="1000" formControlName="numBuffers"
                tooltip="" appExtendedFormControl>
            </div>
          </div>
          <div class="row mb-2 form-inline">
            <div class="col-sm-4 col-xl-2">
              Buffer Size:
            </div>
            <div class="col-sm-8 col-xl-10">
              <input type="number" class="form-control form-control-sm" min="1" max="1000" formControlName="bufferSize"
                tooltip="" appExtendedFormControl> pages
            </div>
          </div>
        </ng-template>
        <div class="row mb-2 form-inline">
          <div class="col-sm-4 col-xl-2">
            Estimated Memory Used:
          </div>
          <div class="col-sm-8 col-xl-10">
            {{(editConfigurationFormGroup.valid && !editConfigurationFormGroup.value.parallelBackupStreamsUseSystem)?
            editConfigurationFormGroup.value.parallelBackupStreams * editConfigurationFormGroup.value.bufferSize *
            editConfigurationFormGroup.value.numBuffers + ' KB' : '--'}}
          </div>
        </div>
        <div class="row mb-2 form-inline">
          <div class="col-sm-4 col-xl-2">
            Backup Generations to Retain:
          </div>
          <div class="d-flex align-items-center col-sm-8 col-xl-10">
            <input type="number" class="form-control form-control-sm" min="1" max="15" formControlName="backupGenerationRetain"
              tooltip="" appExtendedFormControl>
            <app-checkbox class="ml-2" formControlName="backupGenerationKeepAll">
              Keep all backup generations
            </app-checkbox>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template [ngIf]="selectedBackupType">
      <div class="row">
        <div class="col-12 mt-3 mb-3">
          <strong>Backup Schedule</strong>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form role="form" [formGroup]="editConfigurationFormGroup" class="mb-2">
            <div class="table-reponsive" formArrayName="startTimes">
              <app-data-table [data]="configuredScheduleData" sortBy="level">
                <app-data-table-column title="Level" property="level">
                </app-data-table-column>
                <app-data-table-column title="Start Time" property="startTime">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <input type="text" class="form-control" tooltip="" [customErrors]="startTimeErrorHandlers" [value]="item"
                      [formControlName]="row.level" appExtendedFormControl>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Monday" property="monday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'monday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Monday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Tuesday" property="tuesday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'tuesday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Tuesday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Wednesday" property="wednesday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'wednesday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Wednesday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Thursday" property="thursday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'thursday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Thursday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Friday" property="friday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'friday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Friday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Saturday" property="saturday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'saturday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Saturday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Sunday" property="sunday">
                  <ng-template appTableCell let-row="row" let-item="item">
                    <button type="button" class="btn btn-sm" [ngClass]="item ? 'btn-success' : 'btn-secondary'" (click)="toggleScheduleDay(row.level, 'sunday')">
                      <i class="icon" [ngClass]="item? 'icon-check' : 'icon-times'" [tooltip]="(item? 'Enabled' : 'Disabled') + ' on Sunday'"></i>
                    </button>
                  </ng-template>
                </app-data-table-column>
              </app-data-table>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-check-server-admin-permissions [server]="server">
            <button type="button" class="btn btn-primary btn-sm" (click)="saveConfiguration()" [disabled]="!selectedBackupType || !editConfigurationFormGroup.valid"
              appPermissionDisable>
              Save
            </button>
          </app-check-server-admin-permissions>
          <button type="button" class="btn btn-primary btn-sm ml-1" (click)="cancelEditConfiguration()">
            Cancel
          </button>
        </div>
      </div>
    </ng-template>

  </div>
</div>
