/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright HCL Technologies Ltd. 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { InformixServerLogsService } from '../servers/logs/informixServerLogs.service';
import { LogWindowComponent } from '../servers/logs/log-window.component';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

interface logDataType {
  type: string;
  line: string;
  offset?: string;
};

@Component({
  selector: 'app-system-logs',
  templateUrl: 'system-logs.html',
  styleUrls: ['../servers/logs/log-window.scss']
})

export class SystemLogsComponent implements OnInit, OnDestroy {
  logRotation: string = null;
  logData: logDataType[] = [];
  dataLoadErrorMessage: string = null;
  fromOffset: number = null;
  toOffset: number = null;
  filenames: String[] = [];
  selectedLogFile: String;
  private liveDataPollInterval: number = null;
  private logSub: Subscription;
  productName = environment.productNameNoSpace;
  loadingMessages: boolean;
  logTypes = [
    {name: 'All'},
    {name: 'Error'},
    {name: 'Warning'}
  ];

  @ViewChild(LogWindowComponent) logWindow: LogWindowComponent;

  constructor(private logsService: InformixServerLogsService) { }

  ngOnInit() {
    this.logsService.getSystemLogFiles().subscribe(files => {
      if(files.length) {
        this.selectedLogFile = files[0];
        this.filenames = files;
      }
      this.getLiveData(this.selectedLogFile);
    }, error => {
      console.error('Can not get log file names', error);
    });
  }

  ngOnDestroy() {
    if (this.logSub) {
      this.logSub.unsubscribe();
    }
    if (this.liveDataPollInterval) {
      window.clearTimeout(this.liveDataPollInterval);
    }
  }

  onLogFileChanged(logFile) {
    if (this.logSub) {
      this.logSub.unsubscribe();
    }
    if (this.liveDataPollInterval) {
      window.clearTimeout(this.liveDataPollInterval);
      this.liveDataPollInterval = null;
    }
    this.logData = []; this.fromOffset = null; this.toOffset = null;
    this.getLiveData(this.selectedLogFile);
  }

  onLogWindowTopReached() {
      if (this.logSub || !this.fromOffset || this.fromOffset < 1) {
        return;
      }
      this.getLiveData(this.selectedLogFile, true);
  }

  /**
   * Get type of the line. ( Warning, Error, Or Assert Failed)
   *
   * @param line string
   * @returns string
   */
  private getLineType(line: string): string {
    if (line.toLowerCase().indexOf('error') > -1) {
      return 'error';
    } else if (line.toLowerCase().indexOf('warn') > -1) {
      return 'warning';
    } else {
      return 'info';
    }
  }

  private getLiveData(selectedLogFile: String, prependMessages?: boolean, isTailer?: boolean) {
    if (this.logSub) {
      this.logSub.unsubscribe();
    }
    let request: Observable<any[]>;
    const fromOffset = isTailer? null :this.fromOffset;
    let toOffset = null;
    if(isTailer && this.logData.length) {
      const lastMessage: any = this.logData[this.logData.length - 1];
      toOffset = lastMessage.offset + lastMessage.line.length;
    }
    this.loadingMessages = !isTailer;
    request = this.logsService.getSystemLogs(selectedLogFile, fromOffset, toOffset);
    this.logSub = request.subscribe(data => {
      this.dataLoadErrorMessage = null;
      this.loadingMessages = false;
      if (data.length > 0) {
        if(!isTailer) {
          this.fromOffset = data[0].offset;
          if(data[0].offset === data[0].message.length) { // Means start of the file
            this.fromOffset = -1;
          }
        }
        if(prependMessages) {
          data.reverse().forEach(elem => {
            this.logData.unshift({line: elem.message, type: this.getLineType(elem.message), offset: elem.offset});
          });
        }else {
          data.forEach(elem => {
            this.logData.push({line: elem.message, type: this.getLineType(elem.message), offset: elem.offset});
          });
        }
        if (this.logWindow) {
          this.logWindow.onDataAddedToTop();
        }
        if (!this.liveDataPollInterval) {
          this.liveDataPollInterval = window.setInterval(() => {
            this.getLiveData(this.selectedLogFile, false, true);
          }, 3000);
        }
      }

      if(!this.logData.length) {
        this.dataLoadErrorMessage = 'Log file is empty.';
      }
    }, (err: HttpErrorResponse) => {
      this.loadingMessages = false;
      console.error(err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    }, () => {
      this.logSub = null;
    });
  }
}
