/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../../servers/informixServer';
import { ServerBreadcrumb } from '../../servers/serverBreadcrumb';
import { MemoryManagementService } from './memory-manager.service';
import { MemoryResponse } from './memory-manager.model';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { DatePipe } from '@angular/common';
import * as Chart from 'chart.js';
import { ChartJSUtils } from './../../../shared/chartjs.utils';
import { BytePipe } from './../../../shared/pipes/byte.pipe';
import { memoryDashboard } from './memory-manager-dashboard';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-memory-manager',
  templateUrl: './memory-manager.component.html',
  styleUrls: ['./memory-manager.component.scss']
})
export class MemoryManagementComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private memoryManagementBreadCrumbs: BreadcrumbElement[] = [
    { name: 'System Resources' },
    { name: 'Memory' }
  ];
  server: InformixServer = null;
  memoryManagerForm: UntypedFormGroup;
  isBtnDisable = true;
  isDataLoad = true;
  isToggleChecked = false;
  initialToggleState: boolean;
  isEditVisible = true;
  private chartUsedData: number[];
  private chartFreeData: number[];
  private chartTotalData: number[];
  private chart: Chart;
  memoryObj: MemoryResponse;
  summaryInfo: any = {};
  lmm_start_threshold_control: any;
  lmm_stop_threshold_control: any;
  lmm_idle_time_control: any;
  viewLiveData = true;
  private _memoryBarChartElement: ElementRef = null;

  @ViewChild('stopThresholdInput') stopThresholdInput: ElementRef;
  apiError: any;
  @ViewChild('memoryBarChart')
  get memoryBarChartElement(): ElementRef {
    return this._memoryBarChartElement;
  }
  set memoryBarChartElement(elementRef: ElementRef) {
    this._memoryBarChartElement = elementRef;
    if (elementRef) {
      this.updateBarChart();
    }
  }

  memoryDashboard = memoryDashboard;
  memoryManagerHDR: HDRPermission;
  constructor(
    private route: ActivatedRoute,
    private memoryManageService: MemoryManagementService,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    private datePipe: DatePipe,
    private hdrPermissionService: HDRPermissionService
  ) { }

  get formControls() {
    return this.memoryManagerForm.controls;
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.memoryManagerForm = this.fb.group({
      lmm_start_threshold: new UntypedFormControl({ value: null, disabled: true },
        [this.startstopCheck('lmm_start_threshold'), Validators.min(5)]),
      lmm_stop_threshold: new UntypedFormControl({ value: null, disabled: true },
        [this.startstopCheck('lmm_stop_threshold')]),
      lmm_idle_time: new UntypedFormControl({ value: null, disabled: true }, [Validators.min(1)]),
    });
    this.lmm_start_threshold_control = this.memoryManagerForm.get('lmm_start_threshold');
    this.lmm_stop_threshold_control = this.memoryManagerForm.get('lmm_stop_threshold');
    this.lmm_idle_time_control = this.memoryManagerForm.get('lmm_idle_time');
    this.getMemoryData();
    this.memoryManagerHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  getMemoryData() {
    this.memoryManageService.getMemoryData(this.server).subscribe((res: any) => {
      this.memoryObj = new MemoryResponse();
      this.memoryObj.total_mem = res.total_mem;
      this.memoryObj.used_mem = res.used_mem;
      this.memoryObj.lmm_start_threshold = res.lmm_start_threshold ? res.lmm_start_threshold / 1048576 : 5;
      this.memoryObj.lmm_stop_threshold = res.lmm_stop_threshold ? res.lmm_stop_threshold / 1048576 : 10;
      this.memoryObj.lmm_idle_time = res.lmm_idle_time ? res.lmm_idle_time : 300;
      this.memoryObj.lmm_total_killed = res.lmm_total_killed !== undefined ? (res.lmm_total_killed !== null ?
        res.lmm_total_killed : 'N/A') : 'N/A';
      this.memoryObj.lmm_kill_last_time = res.lmm_kill_last_time ?
        this.datePipe.transform(res.lmm_kill_last_time.$date, 'y-MM-dd HH:mm:ss') : 'N/A';
      this.memoryObj.lmm_reduce_last_time = res.lmm_reduce_last_time ?
        this.datePipe.transform(res.lmm_reduce_last_time.$date, 'y-MM-dd HH:mm:ss') : 'N/A';
      this.memoryObj.lmm_restore_last_time = res.lmm_restore_last_time ?
        this.datePipe.transform(res.lmm_restore_last_time.$date, 'y-MM-dd HH:mm:ss') : 'N/A';
      this.memoryObj.lmm_enabled = res.lmm_enabled;
      this.memoryObj.os_mem_free = res.os_mem_free;
      this.memoryObj.os_mem_total = res.os_mem_total;
      this.memoryObj.os_mem_used = res.os_mem_used;
      this.memoryObj.session_count = res.session_count;
      this.memoryObj.max_ses_mem = res.max_ses_mem;
      this.memoryObj.avg_ses_mem = res.avg_ses_mem;

      this.memoryManagerForm.setValue({
        lmm_start_threshold: this.memoryObj.lmm_start_threshold,
        lmm_stop_threshold: this.memoryObj.lmm_stop_threshold,
        lmm_idle_time: this.memoryObj.lmm_idle_time
      });
      this.initialToggleState = this.memoryObj.lmm_enabled === 1 ? true : false;
      this.summaryInfo.dbmemory_used = res.used_mem;
      this.summaryInfo.osmemory_used = res.os_mem_used;
      this.summaryInfo.dbmemory_free = res.total_mem - res.used_mem;
      this.summaryInfo.osmemory_free = res.os_mem_free;

      this.lmm_start_threshold_control.disable();
      this.lmm_stop_threshold_control.disable();
      this.lmm_idle_time_control.disable();

      this.isToggleChecked = this.initialToggleState;
      this.btnCheck();
      this.getMemoryOverview();
    }, err => {
      this.isDataLoad = false;

      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
    });
  }

  toggleGraph(viewAgentData: boolean) {
    this.viewLiveData = !viewAgentData;
    if (!this.viewLiveData && this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  private getMemoryOverview() {
    this.chartUsedData = [
      this.summaryInfo.dbmemory_used,
      this.summaryInfo.osmemory_used
    ];
    this.chartFreeData = [
      this.summaryInfo.dbmemory_free,
      this.summaryInfo.osmemory_free
    ];
    this.chartTotalData = [this.summaryInfo.dbmemory_used + this.summaryInfo.dbmemory_free,
    this.summaryInfo.osmemory_used + this.summaryInfo.osmemory_free];
    this.updateBarChart();
  }

  private updateBarChart() {
    if (!this.viewLiveData || !this.chartTotalData || !this.memoryBarChartElement) {
      return;
    }
    if (this.chart) {
      this.chart.update();
      return;
    }
    const bytePipe = new BytePipe();
    this.chart = new Chart(this.memoryBarChartElement.nativeElement, {
      type: 'horizontalBar',
      data: {
        labels: ['Database Shared Memory', 'OS Memory'],
        datasets: [
          {
            label: 'Used',
            data: this.chartUsedData,
            backgroundColor: '#ffbc01'
          },
          {
            label: 'Free',
            data: this.chartFreeData,
            backgroundColor: '#c8dd54'
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              beforeBuildTicks: ChartJSUtils.byteAxisBeforeBuildTicks,
              stacked: true,
              ticks: {
                maxRotation: 0,
                callback: (value: any) => bytePipe.transform(value),
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              barThickness: 35,
              stacked: true,
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              ticks: {
                padding: 10
              }
            }
          ]
        },
        legend: {
          display: false,
          position: 'top'
        },
        tooltips: {
          mode: 'nearest',
          callbacks: {
            title: (tooltips: any, data: any) => {
              const tooltip = tooltips[0];
              return data.labels[tooltip.index] + ' ' + data.datasets[tooltip.datasetIndex].label;
            },
            label: (tooltip: any, data: any) => {
              const value: number = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              return bytePipe.transform(value) + ' (' + ((value / this.chartTotalData[tooltip.index]) * 100).toFixed(2) + '%)';
            }
          }
        }
      }
    });
  }

  toggleChecks(value: any) {
    if (value === true) {
      this.isToggleChecked = true;
      this.lmm_start_threshold_control.enable();
      this.lmm_stop_threshold_control.enable();
      this.lmm_idle_time_control.enable();
      this.stopThresholdInput.nativeElement.focus();
      this.stopThresholdInput.nativeElement.blur();
    } else if (value === false) {
      this.isToggleChecked = false;
      this.lmm_start_threshold_control.disable();
      this.lmm_stop_threshold_control.disable();
      this.lmm_idle_time_control.disable();
    }
    if (this.initialToggleState === value) {
      this.isBtnDisable = true;
      this.btnCheck();
    } else {
      this.isBtnDisable = false;
    }
  }

  btnCheck() {
    const difference = this.lmm_stop_threshold_control.value - this.lmm_start_threshold_control.value;
    if (this.lmm_start_threshold_control.value === this.memoryObj.lmm_start_threshold
      && this.lmm_stop_threshold_control.value === this.memoryObj.lmm_stop_threshold
      && this.lmm_idle_time_control.value === this.memoryObj.lmm_idle_time
      && this.isToggleChecked === this.initialToggleState) {
      this.isBtnDisable = true;
    } else if (this.lmm_start_threshold_control.value < 5 ||
      this.lmm_stop_threshold_control.value < 5 || this.lmm_idle_time_control.value < 1) {
      this.isBtnDisable = true;
    } else if (difference < 5) {
      this.isBtnDisable = true;
    } else {
      this.isBtnDisable = false;
    }
  }

  startstopCheck(controlType: string): ValidatorFn {
    return (): { customError: string } | null => {
      const errors = { customError: 'The stop threshold must be at least 5 MB greater than the start threshold.' };
      if ((this.lmm_stop_threshold_control.value - this.lmm_start_threshold_control.value)
        < 5) {
        if (controlType === 'lmm_start_threshold') {
          this.lmm_stop_threshold_control.setErrors(errors);
          return null;
        } else {
          return errors;
        }
      } else {
        this.lmm_stop_threshold_control.setErrors(null);
        return null;
      }
    };
  }

  resetFields() {
    this.memoryManagerForm.setValue({
      lmm_start_threshold: this.memoryObj.lmm_start_threshold,
      lmm_stop_threshold: this.memoryObj.lmm_stop_threshold,
      lmm_idle_time: this.memoryObj.lmm_idle_time
    });
    this.isToggleChecked = this.initialToggleState;
    this.toggleChecks(this.isToggleChecked);
    this.lmm_start_threshold_control.disable();
    this.lmm_stop_threshold_control.disable();
    this.lmm_idle_time_control.disable();
    this.isEditVisible = true;
  }

  applyLowMemory() {
    if (this.memoryManagerHDR && !this.memoryManagerHDR.isAllow()) {
      return;
    }
    this.isBtnDisable = true;
    const valuetosend = this.isToggleChecked === true ? 1 : 0;
    const dataObj = {
      lmm_enable: valuetosend,
      lmm_restart: this.initialToggleState === true ? 1 : 0,
      lmm_start_threshold: this.lmm_start_threshold_control.value + 'M',
      lmm_stop_threshold: this.lmm_stop_threshold_control.value + 'M',
      lmm_idle_time: this.lmm_idle_time_control.value
    };
    this.memoryManageService.updateLowMemoryData(this.server, dataObj).subscribe((res: any) => {
      this.notificationsService.pushGenericNotification(res.return_code, res.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
          this.lmm_start_threshold_control.disable();
          this.lmm_stop_threshold_control.disable();
          this.lmm_idle_time_control.disable();
          this.isEditVisible = true;
          this.getMemoryData();
        }
      });
    }, err => {
      this.notificationsService.pushErrorNotification('Failed to Update Low Memory Manager: ' + (err.error.err));
    });
  }

  editMemoryBox() {
    if (this.memoryManagerHDR && !this.memoryManagerHDR.isAllow()) {
      return;
    }
    this.isEditVisible = false;
    if (this.isToggleChecked === true) {
      this.lmm_start_threshold_control.enable();
      this.lmm_stop_threshold_control.enable();
      this.lmm_idle_time_control.enable();
      this.stopThresholdInput.nativeElement.focus();
      this.stopThresholdInput.nativeElement.blur();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, this.memoryManagementBreadCrumbs);
    if (server.agent != null) {
      this.toggleGraph(server.agent.online);
    }
  }
}
