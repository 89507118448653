/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { AnimationEvent } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { notificationsAnimations } from './notifications.animations';
import { Notification, NotificationsService, TYPE_ERROR, TYPE_INFO, TYPE_SUCESS, TYPE_WARN } from './notifications.service';

const DEFAULT_LIFESPAN = 2000;

interface NotificationUI extends Notification {
  timeoutHandle: number;
  messageSafe: SafeHtml;
}

@Component({
  selector: 'app-notifications-overlay',
  templateUrl: 'notifications.html',
  styleUrls: ['notifications.scss'],
  animations: notificationsAnimations
})
export class NotificationsComponent implements OnInit, OnDestroy {

  private notificationSubscription: Subscription = null;
  private cleanUpTimeout: number = null;

  showMainContainer = false;

  notifications: NotificationUI[] = [];
  pendingNotifications: NotificationUI[] = [];
  maxNotifications = 5;

  constructor(private service: NotificationsService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.notificationSubscription = this.service.getEventNotifyEmitter().subscribe((notification: NotificationUI) => {
      if (notification.lifespan === null || notification.lifespan === undefined) {
        notification.lifespan = DEFAULT_LIFESPAN;
      }
      notification.messageSafe = this.sanitizer.bypassSecurityTrustHtml(notification.message);

      if (this.notifications.length >= this.maxNotifications) {
        this.pendingNotifications.push(notification);
      } else {
        this.addNotification(notification);
      }
    });
  }

  ngOnDestroy() {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }

    if (this.cleanUpTimeout) {
      window.clearTimeout(this.cleanUpTimeout);
    }

    this.notifications.forEach(notif => {
      if (notif.timeoutHandle) {
        window.clearTimeout(notif.timeoutHandle);
      }
    });
  }

  addNotification(notification: NotificationUI) {
    this.showMainContainer = true;
    if (notification.lifespan > 0) {
      notification.timeoutHandle = window.setTimeout(() => this.removeNotification(notification), notification.lifespan);
    }
    this.notifications.unshift(notification);
  }

  removeNotification(notif: NotificationUI) {
    const index = this.notifications.indexOf(notif);
    if (index > -1) {
      this.notifications.splice(index, 1);

      if (this.pendingNotifications.length > 0) {
        this.addNotification(this.pendingNotifications.shift());
      }
    }
  }

  onMainAnimationDone(event: AnimationEvent) {
    if (event.toState === 'void' && this.notifications.length < 1) {
      this.showMainContainer = false;
    }
  }

  getIconClasses(notif: NotificationUI) {
    switch (notif.type) {
      case TYPE_ERROR:
        return 'icon-notification-error';
      case TYPE_INFO:
        return 'icon-info-circle';
      case TYPE_SUCESS:
        return 'icon-check-circle';
      case TYPE_WARN:
        return 'icon-warning';
    }
  }
}
