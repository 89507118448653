/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2021. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from './../serverBreadcrumb';
import { SqltraceService } from './sqltrace.service';

@Component({
  selector: 'app-sqltrace',
  templateUrl: 'sqltrace.html'
})
export class SqltraceComponent implements OnInit, OnDestroy {

  breadcrumb: BreadcrumbElement[] = null;
  private sqltraceBreadCrumb: BreadcrumbElement = {
    name: 'SQL Tracing',
  };

  server: InformixServer = null;
  sqltracingOn = false;
  sqltraceInfo: any;
  sqltraceConfig: any;

  dataLoadErrorMessage: string = null;

  private sqltraceDataPollTimeout: number = null;
  private pollInterval = 10000; // TODO: make this a configurable setting

  constructor(
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getSqlTraceConfiguration();
    });
  }

  ngOnDestroy() {
    if (this.sqltraceDataPollTimeout) {
      window.clearTimeout(this.sqltraceDataPollTimeout);
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.sqltraceBreadCrumb]);
  }

  private getSqlTraceConfiguration() {
    this.sqltraceService.getSqltraceSummary(this.server).subscribe(sqltrace => {
      this.sqltraceInfo = sqltrace.info;
      this.sqltraceConfig = sqltrace.config;
      this.sqltracingOn = this.sqltraceConfig.state === 'On' || this.sqltraceConfig.state === 'Suspended';
      this.setPollInterval();
      this.dataLoadErrorMessage = null;
    }, err => {
      console.error('Error getting sqltrace summary info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private setPollInterval() {
    if (this.sqltracingOn) {
      if (this.sqltraceDataPollTimeout) {
        window.clearTimeout(this.sqltraceDataPollTimeout);
      }
      this.sqltraceDataPollTimeout = window.setTimeout(() => {
        this.getSqlTraceInfo();
      }, this.pollInterval);
    }
  }

  private getSqlTraceInfo() {
    if (!this.sqltracingOn) {
      return;
    }
    this.sqltraceService.getSqltraceInfo(this.server).subscribe(info => {
      this.sqltraceInfo = info;
      this.setPollInterval();
      this.dataLoadErrorMessage = null;
    }, err => {
      console.error('Error getting sqltrace info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  handleActionSuccess(message: string) {
    this.getSqlTraceConfiguration();
  }

  handleActionError(error: any) {
    // this.notificationsService.pushErrorNotification(error);
  }
}
