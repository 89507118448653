/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { storageDashboard } from './storage-dashboard';

@Component({
  selector: 'app-storage-dashboard',
  templateUrl: 'storage-dashboard.html'
})
export class StorageDashboardComponent implements OnInit {

  server: InformixServer = null;

  storageDashboard = storageDashboard;

  breadcrumb: BreadcrumbElement[] = null;

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [{name: 'Storage'}]);
  }

}
