/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-axis-config',
  templateUrl: './axis-config.component.html',
  styleUrls: ['./axis-config.component.scss']
})
export class AxisConfigComponent implements OnInit, OnChanges {

  @Input() config: any;
  @Input() chartType: string;

  @Output() configChanged = new EventEmitter<any>();

  formGroup: UntypedFormGroup;

  axisTypes: string[] = ['number', 'percent', 'bytes'];

  constructor() {
    this.formGroup = new UntypedFormGroup({
      type: new UntypedFormControl('number'),
      min: new UntypedFormControl(null, { updateOn: 'blur' }),
      max: new UntypedFormControl(null, { updateOn: 'blur' }),
      label: new UntypedFormControl(null, { updateOn: 'blur' })
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && this.config) {
      this.formGroup.patchValue(this.config, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(form => {
      const axisConfig: any = {
        type: form.type
      };
      if (typeof form.min === 'number') {
        axisConfig.min = form.min;
      }
      if (typeof form.max === 'number' && (axisConfig.min === undefined || form.max > axisConfig.min)) {
        axisConfig.max = form.max;
      }
      const label = (form.label || '').trim();
      if (label) {
        axisConfig.label = label;
      }

      setTimeout(() => {
        this.configChanged.emit(axisConfig);
      }, 0);
    });
  }

}
