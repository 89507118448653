/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved
 *******************************************************************************/

import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['stepper.scss']
})
export class StepperComponent implements OnChanges {

  @Input() totalCount: number;
  @Input() currentCount: number;
  totalSteps = [];

  ngOnChanges() {
    this.totalSteps = new Array(this.totalCount);
  }
}
