<form *ngIf="formGroup" [formGroup]="formGroup">
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Host</label>
    <div class="col-sm-6">
      <input type="text" class="form-control" formControlName="host" tooltip="" appExtendedFormControl>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Port</label>
    <div class="col-sm-6">
      <input type="number" min="1" max="65535" class="form-control" formControlName="port" tooltip=""
        appExtendedFormControl>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Security</label>
    <div class="col-sm-6">
      <select class="form-control" formControlName="security">
        <option value="none">NONE</option>
        <option value="ssl">SSL</option>
        <option value="tls">TLS</option>
      </select>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">User</label>
    <div class="col-sm-6">
      <input type="text" class="form-control" formControlName="user">
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Password</label>
    <div class="col-sm-6">
      <div class="input-group password-hide-show inner-addon right-addon">
        <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="password" (keyup)="toggleShow(true)">
        <i class="icon icon-eye" aria-hidden="true" (click)="toggleShow()"
          *ngIf="showPassword && (formGroup.value.password !== '' || (formGroup.controls['password'].dirty && formGroup.value.password !== ''))"></i>
        <i class="icon icon-eye-blocked" aria-hidden="true" (click)="toggleShow()"
          *ngIf="!showPassword && (formGroup.value.password !== '' || (formGroup.controls['password'].dirty && formGroup.value.password !== ''))"></i>
      </div>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">From Email</label>
    <div class="col-sm-6">
      <input type="text" class="form-control" formControlName="from">
    </div>
  </div>
</form>
