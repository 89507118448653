<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" [ngClass]="{'showLoader': isLoading && formSubmit}">
    <form [formGroup]="formGroup">
        <div class="row mb-2" *ngIf="action === null || action === 'modify'">
            <div class="col-6">
                <h4>
                    <strong>{{ action === 'modify' ? formGroup.controls.replicateName.value : 'New Replicate' }}</strong>
                    <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading && isDomainLoading"></i>
                </h4>
            </div>
            <div class="col-6 form-inline justify-content-end">
                <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
                    cancel
                </button>
                <button type="button" *ngIf="action !== 'modify'" class="btn btn-sm ml-2"
                    [ngClass]="showNextPage ? 'btn-secondary' : 'btn-primary'" (click)="next()"
                    [disabled]="(!formGroup.controls.replicateName.valid || participants.length === 0)">
                    {{ !showNextPage ? 'Next' : 'Back' }}
                </button>
                <button id="define-button" type="button" *ngIf="showNextPage" class="btn btn-sm btn-primary ml-2"
                    (click)="defineReplicate()" [disabled]="(isLoading && formSubmit) || isDomainLoading">
                    {{action === 'modify' ?  'Modify Replicate' : 'Define Replicate'}}
                </button>
            </div>
        </div>
        <ng-container *ngIf="!showNextPage; else replicateNextPage">
            <div class="row m-0 font-weight-bold" *ngIf="action !== 'participants'">
                <label title="replicate name"> <span class="text-danger">* </span> Replicate name</label>
            </div>
            <div class="row" *ngIf="action !== 'participants'">
                <div class="col-6">
                    <input type="text" class="form-control" title="Replicate name" formControlName="replicateName"
                        [readonly]="isEdit">
                </div>

            </div>
            <!-- participants -->
            <div class="row pt-3">
                <div class="col-6 mb-4" *ngIf="action === 'participants'">
                    <h4>
                        <strong> Participants ( {{ formGroup.controls.replicateName.value }} )
                            <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading || isDomainLoading"></i>
                        </strong>
                    </h4>
                </div>
                <div class="col-6 font-weight-bold mt-2" *ngIf="action !== 'participants'">
                    Participants
                </div>
                <div class="col-6 justify-content-end form-inline mb-2">
                    <button id="button" *ngIf="action === 'participants'" type="button" class="btn btn-secondary"
                        (click)="close()">
                        {{ (action !== 'participants' || isAddEditParticipant) ? 'Cancel' : 'Back' }}
                    </button>
                    <button id="button ml-2" *ngIf="action !== 'participants' || isAddEditParticipant === true"
                        type="button" class="btn"
                        [ngClass]="action === null || action === 'participants' ?  'btn-primary' : 'btn-secondary'"
                        (click)="addParticipantsModal()" [disabled]="isDomainLoading">
                        Add participants
                    </button>
                    <button id="button" *ngIf="isAddEditParticipant === false && action !== null" type="button"
                        class="btn btn-primary" (click)="isAddEditParticipant = true; setServer(server);"
                        [disabled]="isDomainLoading">
                        Add/Delete Participants
                    </button>
                    <button id="button" *ngIf="isAddEditParticipant === true && action === 'participants'" type="button"
                        class="btn btn-primary" (click)="save()"
                        [disabled]="(deletedParticipants.length == 0 && newParticipants.length == 0) || (isLoading && formSubmit)">
                        Save Changes
                    </button>
                </div>
            </div>
            <div>
                <app-data-table *ngIf="participants && participants.length" class="table-responsive"
                    [enableFilter]="false" [data]="participants" [sortDescending]="true" [enableSearch]="true"
                    [rowsPerPage]="20">
                    <app-data-table-column title="Participant Server" property="groupName">
                        <ng-template appTableCell let-participant="row">
                            {{participant.groupName}} <span *ngIf="masterServer === participant.groupName" class="master-server">Master</span>
                        </ng-template>
                    </app-data-table-column>
                    <app-data-table-column title="Participant Type" property="participantType">
                    </app-data-table-column>
                    <app-data-table-column title="Privilege" property="owner">
                    </app-data-table-column>
                    <app-data-table-column title="Database" property="databaseName">
                    </app-data-table-column>
                    <app-data-table-column title="Table" property="tabName">
                    </app-data-table-column>
                    <app-data-table-column title="Modifier" property="selectStmt">
                    </app-data-table-column>
                    <app-data-table-column title="" property="">
                        <ng-template appTableCell let-participant="row">
                            <div class="justify-content-end form-inline"
                                *ngIf="action === null || isAddEditParticipant === true">
                                <button id="refresh-button" type="button" class="btn btn-sm btn-icon" tooltip="edit"
                                    (click)="openEdit(participant)"
                                    [ngClass]="isEditable(participant) ? 'd-block' : 'd-none'">
                                    <i class="icon icon-edit" title="edit"></i>
                                </button>
                                <button id="refresh-button" type="button" class="btn btn-sm btn-icon" tooltip="delete"
                                    (click)="confirmDelete(participant)">
                                    <i class="icon icon-trash" title="delete"></i>
                                </button>
                            </div>
                        </ng-template>
                    </app-data-table-column>
                </app-data-table>
            </div>
        </ng-container>

        <ng-template #replicateNextPage>
            <ng-container *ngIf="action !== 'modify' || !isDomainLoading">
                <!-- Dropdowns -->
                <div class="row">
                    <div class="col-3 mb-2">
                        <label class="font-weight-bold col-form-label pr-2">Conflict resolution rule </label>
                        <select class="form-control" formControlName="conflictResolutionRule"
                            (change)="resolutionRule($event)"  title="Specifies the rule that is used for conflict resolution(--conflict)">
                            <option *ngFor="let rule of erReplicate.conflictRules" value="{{rule.value}}">{{rule.name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-3 mb-2" *ngIf="isAddStoreProcedure">
                        <app-checkbox class="mb-2" [checked]="isOptimize" (change)="storeProcedures($event)">
                            Optimize the store procedure
                        </app-checkbox>
                        <input type="text" class="form-control" title="Store Procedures"
                            formControlName="storeProcedures" placeholder="path ex. dir/file">
                    </div>

                    <div class="col-3 mb-2">
                        <label class="font-weight-bold col-form-label pr-2"> Conflict resolution scope</label>
                        <select class="form-control db-dropdown" formControlName="conflictResolutionScope" title="Specifies the scope of Enterprise Replication conflict resolution(--scope)">
                            <option *ngFor="let scope of erReplicate.conflictScope" value="{{scope.value}}">
                                {{scope.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 mb-2">
                        <label class="font-weight-bold col-form-label pr-2"> Frequency</label>
                        <select class="form-control db-dropdown" formControlName="frequencyType"
                            (change)="frequencyChange($event)" title="Specifies the interval between replications or the time of day when replication occurs">
                            <option *ngFor="let freq of erReplicate.frequencyTypes" value="{{freq.value}}">{{freq.name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-3 mb-2" *ngIf="showInterval">
                        <label class="font-weight-bold col-form-label pr-2"> <span class="text-danger">* </span>
                            Interval</label>
                        <input type="text" class="form-control" title="hours" formControlName="interval" tooltip="Enter number of minutes or number of hours and minutes" placeholder="60 or 30:10 (mm or hh:mm)" appExtendedFormControl>
                    </div>
                    <div class="col-2 mb-2" *ngIf="showRepeat">
                        <label class="font-weight-bold col-form-label pr-2"> <span class="text-danger">*
                            </span>Repeat</label>
                        <select class="form-control db-dropdown" formControlName="frequencyRepeat"
                            (change)="repeatChanage($event)">
                            <option *ngFor="let repeat of erReplicate.frequencyRepeats" value="{{repeat.value}}">
                                {{repeat.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 mb-2" *ngIf="showDay">
                        <label class="font-weight-bold col-form-label pr-2"><span class="text-danger">* </span>
                            Day</label>
                        <select class="form-control db-dropdown" formControlName="day">
                            <option *ngFor="let d of erReplicate.days" value="{{d.value}}">{{d.name}}</option>
                        </select>
                    </div>

                    <div class="col-2 mb-2" *ngIf="showDayOfMonth">
                        <label class="font-weight-bold col-form-label pr-2"><span class="text-danger">* </span>
                            Day</label>
                        <input type="text" class="form-control" title="dayOfMonth" formControlName="dayOfMonth" tooltip="" appExtendedFormControl>
                    </div>

                    <div class="col-2 mb-2" *ngIf="showHour">
                        <label class="font-weight-bold col-form-label pr-2"><span class="text-danger">* </span>
                            Time</label>
                        <input type="text" class="form-control" title="time" formControlName="time" tooltip="" placeholder="HH:mm" appExtendedFormControl>
                    </div>
                </div>

                <!-- Special Options -->
                <div class="font-weight-bold mt-3">Special Options</div>
                <div class="row">
                    <div class="col-3 mt-3">
                        <label class="mb-0 pb-2">Include Unchanged large objects:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isIncludeLargeObj" title="Specifies whether columns that contain unchanged large objects are included in replicated rows(--alwaysRepLOBS)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                    <div class="col-3 mt-3">
                        <label class="mb-0 pb-2">Replicate full rows:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isReplicateFullRows" title="Specifies whether to replicate full rows or only the changed columns(--fullrow)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 mt-3">
                        <label class="mb-0 pb-2">Adds the ERKEY shadow columns:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isAddErkey" title="Adds the ERKEY shadow columns, ifx_erkey_1, ifx_erkey_2, and ifx_erkey_3, to the participant definition(--erkey)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                    <div class="col-3 mt-3">
                        <label class="mb-0 pb-2">Retain deleted rows on the other nodes:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isRetainDeletedRows" title="Specifies whether to retain deleted rows on other nodes(--ignoredel)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 pr-0 mt-3">
                        <label class="mb-0 pb-2">Activates aborted transaction:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isActiveAbortedTransaction" title="Activates aborted transaction spooling for replicate transactions that fail to be applied to the target database(--ats)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                    <div class="col-3 pr-0 mt-3">
                        <label class="mb-0 pb-2">Activates row-information spooling:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isActivateRowInfoSpooling" title="Activates row-information spooling for replicate row data that fails conflict resolution or encounters replication order problems (--ris)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 pr-0 mt-3">
                        <label class="mb-0 pb-2">File triggers at the destination:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isFireTriggers" title="Specifies that the rows that the replicate inserts fire triggers at the destination (--firetrigger).">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                    <div class="col-3 pr-0 mt-3">
                        <label class="mb-0 pb-2">Replicate are applied serially:</label><br />
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group class="justify-content-end" formControlName="isReplicateSerially" title="Specifies that replicated transactions for the replicate are applied serially instead of in parallel (--serial)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>
                </div>

                <div class="row">
                    <ng-container *ngIf="action !== 'modify'">
                        <div class="col-3 pr-0 mt-3">
                            Transfer replicated floating-point:
                        </div>
                        <div class="col-2 mt-3">
                            <app-radio-group class="justify-content-end" formControlName="transferFloatingPoint" title="Transfers replicated floating-point numbers in either 32-bit (for SMALLFLOAT) or 64-bit (for FLOAT) IEEE floating-point format.">
                                <app-radio value="floatieee">Floatieee</app-radio>
                                <app-radio class="ml-3" value="floatcanon">Floatcanon</app-radio>
                            </app-radio-group>
                        </div>
                    </ng-container>
                    <div class="col-3 pr-0 mt-3">
                        UTF8:
                    </div>
                    <div class="col-2 mt-3">
                        <app-radio-group formControlName="isUTF8" title="Specifies whether to enable conversion to and from UTF-8 (Unicode) when you replicate data between servers that use different code sets(--UTF8)">
                            <app-radio [value]="false">No</app-radio>
                            <app-radio class="ml-3" [value]="true">yes</app-radio>
                        </app-radio-group>
                    </div>

                    <!-- <div class="col-3 pr-0 mt-3">
                                Replication key from unique index/constraint: 
                            </div>
                            <div class="col-2 mt-3">
                                <app-radio-group formControlName="isReplicationKey">
                                    <app-radio [value]="false">No</app-radio>
                                    <app-radio class="ml-3" [value]="true">yes</app-radio>
                                </app-radio-group>
                            </div>
        
                            <div class="col-3 pr-0 mt-3">
                                Detect replication key automatically: 
                            </div>
                            <div class="col-2 mt-3">
                                <app-radio-group formControlName="isDetectReplicationKey">
                                    <app-radio [value]="false">No</app-radio>
                                    <app-radio class="ml-3" [value]="true">yes</app-radio>
                                </app-radio-group>
                            </div>
        
                            <div class="col-3 mb-2" *ngIf="formGroup.controls.isReplicationKey.value">
                                <select class="form-control db-dropdown" formControlName="columnName">
                                    <option value="" selected disabled>Select Column</option>
                                    <option *ngFor="let col of columnNames" value="{{col.name}}">{{col.name}}
                                    </option>
                                </select>
                            </div> -->
                </div>

                <div class="col-12 pt-5 mb-2 pl-0">
                    <app-checkbox [checked]="isVerificationRequired" (change)="colNameVerification()">
                        Verify column names along with data types on master replicate.
                    </app-checkbox>
                </div>
                <div class="col-12 mb-4 pl-0" *ngIf="!isEdit">
                    <app-checkbox [checked]="isActiveReplicate" (change)="activateReplicate($event)" title="Start replicate once replicate defined">
                        Activate replicate after creation
                    </app-checkbox>
                </div>

                <div class="row mt-2" *ngIf="isActiveReplicate">
                    <div class="col-5">
                        <div class="input-group">
                            <label class="font-weight-bold">Extra target rows</label>
                            <app-radio-group class="justify-content-start ml-2" formControlName="targetRows" title="Specifies how to handle rows found on the target servers that are not present on the data source server from which the data is being copied">
                                <app-radio value="delete">Delete</app-radio>
                                <app-radio class="ml-3" value="keep">Keep</app-radio>
                                <app-radio class="ml-3" value="merge">Merge</app-radio>
                            </app-radio-group>
                        </div>
                    </div>

                    <div class="col-5">
                        <app-checkbox [checked]="isPerformSyncOp" (change)="toggleSync()">
                            Performed synchronization operation as a foreground process.
                        </app-checkbox>
                    </div>
                </div>

                <div class="row mt-2 mb-4" *ngIf="isActiveReplicate">
                    <div class="col-5">
                        <label class="font-weight-bold col-form-label pr-2">Size of the send queue</label>
                        <input type="text" class="form-control" title="Replicate name" formControlName="queueSize" title="Increases the size of the send queue during synchronization to the number of kilobytes or megabytes specified by the size element.">
                    </div>
                    <div class="col-5">
                        <label class="font-weight-bold col-form-label pr-2">Sync data source</label>
                        <select class="form-control" formControlName="dbServer" (change)="($event)" title="Specifies the name of the database server to use as the reference copy of the data.">
                            <option>Select database server</option>
                            <option *ngFor="let part of participants" value="{{part.groupName}}">{{part.groupName}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </form>
</div>

<!-- action loader -->
<div *ngIf="formSubmit && isLoading" class="align-items-center d-flex flex-column vh-100 position-absolute w-100 loading-wrapper">
    <div class="backdrop h-100 position-absolute w-100" [ngClass]="{'active': true}"></div>
    <div class="alert alert-info">
        <i class="icon icon-circle-notch icon-spin"></i> <span class="pl-2">Please wait. {{ action === 'participants' ? 'Participants' : 'Replicate'}} is being {{ isEdit ? 'updated..' : 'created..'}} </span>
    </div>
</div>
<!-- Define Replicate modal -->
<div bsModal #defineReplicateModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <ng-container *ngIf="isConfirm; else definedReplicate">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left">{{isEdit ?  'Modify Replicate' : 'Define new replicate' }}</h5>
                    <button type="button" class="close" (click)="closeModal(false)">
                        <i class="icon icon-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex align-items-center">
                        <div class="p-2">
                            <i class="icon icon-2x icon-warning text-warning"></i>
                        </div>
                        Are you sure you want to {{isCloseModal ? 'cancel the process?' : (isEdit
                        ? 'modify replicate?' : 'create replicate?') }}
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="float-left">
                        <button type="button" class="btn btn-secondary"
                            (click)="closeModal(false)">Close</button>
                    </div>
                    <div class="float-right">
                        <button type="button" class="btn btn-primary ml-3"
                            (click)="isCloseModal ? close() : proceed()">Proceed</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #definedReplicate>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left">{{action === 'modify' ?  'Modify Replicate' : 'Define new replicate' }}</h5>
                    <button type="button" class="close" (click)="closeModal(true)">
                        <i class="icon icon-times"></i>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row mb-3" *ngFor="let verification of verificationList">
                        <div class="col">
                            <i class="icon icon-check-circle icon-info-circle text-success mr-3"></i>
                            {{verification}}
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="float-left">
                        <button type="button" class="btn btn-secondary" (click)="closeModal(true)">Close</button>
                    </div>
                    <div class="float-right" *ngIf="isVerificationFailed">
                        <button type="button" class="btn btn-primary ml-3">Let's fix the error</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<!-- Replicate Error modal -->
<div bsModal #replicateErrorModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">Error</h5>
                <button type="button" class="close" (click)="replicateErrorModal.hide()">
                    <i class="icon icon-times"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="row mb-3" *ngFor="let replicateError of replicateErrorList">
                    <div class="col">
                        <i class="icon icon icon-times-circle text-danger mr-3"></i>
                        {{replicateError}}
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="float-left">
                    <button type="button" class="btn btn-secondary" (click)="replicateErrorModal.hide()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-participant-modal #participantModal (addPart)="addParticipant($event)" (editPart)="editParticipant($event)"
    [server]="server" [groupNames]="groupNames" [isMasterServer]="isMasterServer" [masterServer]="masterServer"
    [participants]="participants"></app-participant-modal>
