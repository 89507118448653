/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { AlertMessenger, AlertProfile } from './alert';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AlertSettingsService {

  constructor(private httpClient: HttpClient) { }

  public getAlertMessengers(): Promise<any> {
    return this.httpClient.get<any>('users/alerts/messengers').toPromise<any>();
  }

  public createAlertMessenger(settings: AlertMessenger): Promise<any> {
    return this.httpClient.post<any>('users/alerts/messengers', settings).toPromise<any>();
  }

  public updateAlertMessenger(id: number, settings: AlertMessenger): Promise<any> {
    return this.httpClient.put<any>('users/alerts/messengers/' + id, settings).toPromise<any>();
  }

  public getAlertProfiles(): Promise<any> {
    return this.httpClient.get<any>('users/alerts/alertingprofiles').toPromise<any>();
  }

  public createAlertProfile(profile: AlertProfile) {
    const body: any = profile;
    delete body.id;

    return this.httpClient.post<any>('users/alerts/alertingprofiles', body).toPromise<any>();
  }

  public updateAlertProfile(id: number, profile: AlertProfile): Promise<any> {
    return this.httpClient.put<any>('users/alerts/alertingprofiles/' + id, profile).toPromise<any>();
  }

  public setDefaultAlertProfile(id: number): Promise<any> {
    return this.httpClient.put<any>('users/alerts/alertingprofiles/default', { id }).toPromise<any>();
  }

  public removeDefaultAlertProfile(): Promise<any> {
    return this.httpClient.delete<any>('users/alerts/alertingprofiles/default').toPromise<any>();
  }
}
