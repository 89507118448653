/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { AbstractAlertOperand } from './abstract-alert-operand';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-status-operand',
  template: ''
})
export class ServerStatusOperandComponent extends AbstractAlertOperand implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }

  public isValid(): boolean {
    return true;
  }

  public getValue(): any {
    return {};
  }
}
