/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from './../serverBreadcrumb';
import { SqltraceService } from './sqltrace.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sqltrace-stmt-executions',
  templateUrl: 'sqltrace-stmt-executions.html'
})
export class SqltraceStatementExecutionsComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private sqltraceBreadCrumb: BreadcrumbElement = {
    name: 'SQL Tracing',
    link: 'sqltrace'
  };
  private stmtsBreadCrumb: BreadcrumbElement = {
    name: 'Statements',
    link: 'sqltrace/statements'
  };
  private stmtListBreadCrumb: BreadcrumbElement = {
    name: 'Executions',
  };
  server: InformixServer = null;
  sqlid: string = null;
  sql: string = null;
  statementExecutions: any[] = null;
  dataLoadErrorMessage: string = null;
  isFilterDisable = true;
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };

  constructor(
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.route.params.subscribe(params => {
      if (params.sqlid) {
        this.sqlid = params.sqlid;
        this.getStatementProfile();
      } else {
        console.error('missing sqlid');
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params.fromTimeStamp && params.fromTimeStamp !== 'null') {
        this.timeStamps.fromTimeStamp = Math.floor(params.fromTimeStamp);
      }
      if (params.toTimeStamp && params.toTimeStamp !== 'null') {
        this.timeStamps.toTimeStamp = Math.floor(params.toTimeStamp);
      }
      if (this.sqlid) {
        this.getStatementExecutions();
      }
    });
  }

  refreshData() {
    this.getStatementExecutions();
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.sqltraceBreadCrumb, this.stmtsBreadCrumb, this.stmtListBreadCrumb]);
  }

  private getStatementProfile() {
    this.sqltraceService.getStatementProfile(this.server, this.sqlid).then(profile => {
      this.sql = profile.sql_statement;
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace statement profile info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private getStatementExecutions() {
    this.sqltraceService.getStatementExecutions(this.server, this.sqlid,
      this.timeStamps.fromTimeStamp, this.timeStamps.toTimeStamp).subscribe(data => {
        this.statementExecutions = data;
      }, err => {
        console.error('Error getting sqltrace statement execution list', err);
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      });
  }

  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.router.navigate([], {
      queryParams: {
        fromTimeStamp: this.timeStamps.fromTimeStamp,
        toTimeStamp: this.timeStamps.toTimeStamp
      }, replaceUrl: true
    });
  }
}
