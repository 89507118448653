/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SharedFormsModule } from '../forms/shared-forms.module';
import { DataTableColumnComponent, TableCellDirective } from './data-table-column.component';
import { DataTableExpandComponent, TableExpandDirective } from './data-table-expand.directive';
import { DataTableComponent } from './data-table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    SharedFormsModule
  ],
  declarations: [
    DataTableComponent,
    DataTableColumnComponent,
    DataTableExpandComponent,
    TableCellDirective,
    TableExpandDirective
  ],
  exports: [
    DataTableComponent,
    DataTableColumnComponent,
    DataTableExpandComponent,
    TableCellDirective,
    TableExpandDirective
  ]
})
export class DataTableModule { }
