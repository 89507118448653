/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformixServer } from '../informixServer';
import { AutoUpdateStatisticsService } from './autoUpdateStatistics.service';
import { AlertInfo } from './alertInfo.model';
import { NotificationsService } from '../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-alerts-auto-update-statistics',
  templateUrl: 'alerts-autoUpdateStatistics.html',
  styleUrls: ['./autoUpdateStatistics.component.scss'],
})
export class AlertsComponent implements OnInit {

  @Input() server: InformixServer = null;
  tableData: AlertInfo[] = null;
  ausAlertsApiError: string;
  constructor(
    private route: ActivatedRoute,
    private autoUpdateStatisticsService: AutoUpdateStatisticsService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getAlert();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
  }

  getAlert() {
    this.autoUpdateStatisticsService.getAlert(this.server).subscribe((res: AlertInfo[]) => {
      this.tableData = res;
    }, err => {
      if(err.error && err.error.err){
        this.ausAlertsApiError = err.error.err;
      }
    });
  }
}
