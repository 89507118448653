<div *ngIf="sensor">
  <h6 class="mb-3" *ngIf="!isEditAll">Configure {{sensor.name}} Sensor
  </h6>
  <form [formGroup]="formGroup">
    <div class="container-fluid">
      <div class="form-group form-inline row no-gutters">
        <label>Run Interval:</label>
        <app-input-time class="d-inline-block ml-1" formControlName="runInterval" min="1" tooltip="" placement="right"
          appExtendedFormControl></app-input-time>
      </div>
      <div class="form-group form-inline row no-gutters">
        <label>Data Retention Interval:</label>
        <input type="number" class="form-control form-control-sm mx-1" min="1" style="width:50px" tooltip=""
          formControlName="dataRetentionInterval" appExtendedFormControl>day(s)
        <app-checkbox class="ml-2" formControlName="dataRetentionIndefinite">
          Indefinite
        </app-checkbox>
      </div>
    </div>
  </form>
</div>
