/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2021. All Rights Reserved.
 *******************************************************************************/
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { GroupBreadcrumb } from '../groups/groupBreadcrumb';
import { InformixServer } from './informixServer';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { InformixServerGroupService } from '../groups/informixServerGroup.service';
import { ServerBreadcrumb } from './serverBreadcrumb';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-server',
  templateUrl: 'edit-server.component.html'
})

export class EditServerComponent implements OnInit {
  server: InformixServer = null;
  serverGroup: InformixServerGroup = null;
  breadcrumb: BreadcrumbElement[];

  showAgentInfo = false;
  isAgentTabActive: Boolean = false;
  constructor(
    private groupService: InformixServerGroupService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.server = this.route.snapshot.data.server;

    this.route.queryParamMap.subscribe(queryParam => {
      this.isAgentTabActive = queryParam.has('tab');
    });

    this.groupService.getGroup(this.server.parentGroupId).then(group => {
      this.serverGroup = group;
      this.buildBreadcrumb(this.server);
    }).catch(err => {
      console.error('Error getting group info', err);
    });
  }

  private buildBreadcrumb(server: InformixServer) {
    this.breadcrumb = GroupBreadcrumb.build(this.serverGroup,
      ServerBreadcrumb.build(server, [{ name: 'Setup' }]));
  }

  onAgentTabSelect() {
    this.showAgentInfo = true;
    this.router.navigate([], { queryParams: { tab: 'agent' }, replaceUrl: true });
  }

  onAgentTabDeselect() {
    this.showAgentInfo = false;
    this.router.navigate([], { queryParams: { tab: null }, replaceUrl: true });
  }

  onServerUpdate(server: InformixServer) {
    this.server = server;
    this.buildBreadcrumb(server);
  }
}
