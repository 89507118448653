/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { BuildInfo, BuildInfoService } from '../../shared/buildInfo.service';
import { User } from '../user/user';
import { UserService } from '../user/user.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { InformixServerService } from '../../dashboard//servers/informixServer.service';
import { UsersService } from '../../dashboard/system-settings/users/users.service';
import { UserSettingsService } from '../../dashboard/user-settings/user-settings.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-top-nav',
  templateUrl: 'topnav.html',
})

export class TopNavComponent implements OnInit {

  appBuildInfo: BuildInfo = null;
  user: User = null;
  showAgentsList: Boolean = false;
  loader: Boolean = false;
  shutdowBtnLoader: Boolean = false;
  isShuttingDown: Boolean = false;
  @ViewChild('shutdownServerModal', { static: false }) shutdownServerModal: ModalDirective;

  isDarkTheme = false;
  productType = environment.productType;
  productNameNoSpace = environment.productNameNoSpace;
  topnavDiv = environment.topnavDiv;
  topnavLinks = environment.topnavLinks;
  topnavText = environment.topnavText;
  themeLogo: string = null;
  constructor(private buildInfoService: BuildInfoService,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private getUsersService: UsersService,
    private notificationsService: NotificationsService,
    private serverService: InformixServerService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.userService.getCurrentUser().then(user => {
      this.user = user;
      if (this.user.settings.theme === 'theme-dark') {
        this.isDarkTheme = true;
      } else {
        this.isDarkTheme = false;
      }
      this.setThemeLogo(this.isDarkTheme);
      this.setTheme(this.isDarkTheme);
      const changePassword = '. <a href=' + 'dashboard/user-settings/change-password' +
        '>Click here</a>' + ' to change your password.';
      const expiryInfo = this.userService.getDaysToExpire();
      if (expiryInfo.daysToExpire >= -2) {
        const days = (expiryInfo.daysToExpire === 1) ? 'day' : 'days';
        if (expiryInfo.daysToExpire <= 15 && expiryInfo.daysToExpire > 0) {
          this.notificationsService.pushWarnNotification('Your password will expire in '
            + expiryInfo.daysToExpire + ' ' + days + changePassword);
        } else if (expiryInfo.daysToExpire === 0) {
          this.notificationsService.pushWarnNotification('Your password will expire today' + changePassword);
        }
        if (expiryInfo.daysToExpire !== -1 && expiryInfo.passwordExpired === 'passwordExpired') {
          this.notificationsService.pushSuccessNotification('Your password has been updated successfully!', 5000);
        }
      }
    }).catch(err => {
      console.error('Could not get user info', err);
    });
    this.buildInfoService.getBuildInfo().then(buildInfo => {
      this.appBuildInfo = buildInfo;
    }).catch(err => {
      console.error('Could not get application build info', err);
    });
  }
  agentViewChnage($event: string) {
    this.showAgentsList = ($event === 'hide') ? false : true;
  }
  shutdownModal() {
    this.shutdowBtnLoader = true;
    // calling this API to check the session expiry
    this.getUsersService.getUser(this.user.id).then(users => {
      this.shutdowBtnLoader = false;
      if (this.shutdownServerModal) {
        this.shutdownServerModal.hide();
      }
      this.shutdownServerModal.show();
    }).catch(err => {
      this.shutdowBtnLoader = false;
      if (this.shutdownServerModal) {
        this.shutdownServerModal.hide();
      }
    });
  }

  setThemeLogo(isDarkTheme) {
    let logoName = environment.logo;
    if(environment.productId === 2 && isDarkTheme) {
      logoName += isDarkTheme?'-theme-dark':'';
    }
    this.themeLogo = logoName + environment.logoExtention;
  }
  /**
   * This is shutdownServer Method to call shutdown HQ server API
   * if the response is success we are considering as successfully shutdown monitor server, and Reloading the window
   * if the response is ERROR with status is Zero we are considering as successfully shutdown monitor server, and Reloading the window.
   * if the response is ERROR with status is Non-Zero we are considering as Failure in shutdown monitor server,
   * and showing Error Notification.
   */
  shutdownServer(): void {
    this.loader = true;
    this.serverService.shutdownHQserver().subscribe(res => {
      this.setSuccess();
    }, err => {
      if (err.status !== 0) {
        this.isShuttingDown = false;
        this.loader = false;
        this.notificationsService.pushErrorNotification(`There was a problem shutting down the ${this.productNameNoSpace} Server`);
        if (this.shutdownServerModal) {
          this.shutdownServerModal.hide();
        }
        console.error(err);
      } else {
        this.setSuccess();
      }
    });
  }
  setSuccess() {
    this.loader = false;
    this.notificationsService.pushSuccessNotification(this.productNameNoSpace + ' Server Shutdown successfully!', 5000);
    if (this.shutdownServerModal) {
      this.shutdownServerModal.hide();
    }
    this.reloadWindow();
  }
  reloadWindow() {
    window.open('dashboard', '_self');
    window.location.reload();
  }

  toggleTheme(isDarkTheme: boolean) {
    this.userSettingsService.changeTheme(isDarkTheme ? 'theme-dark' : 'theme-light')
      .subscribe(() => {
        this.isDarkTheme = isDarkTheme;
        this.setThemeLogo(this.isDarkTheme);
        this.setTheme(this.isDarkTheme);
        const theme = isDarkTheme ? 'dark.' : 'light.';
      }, err => {
        this.notificationsService.pushErrorNotification('Theme could not be saved. ' + err.error ? err.error.err : err);
      });
  }

  logOut() {
    this.userService.logOut();
  }

  setTheme(isThemeDark: boolean) {
    if (isThemeDark) {
      this.renderer.removeClass(document.body, 'theme-light');
      this.renderer.addClass(document.body, 'theme-dark');
    } else {
      this.renderer.removeClass(document.body, 'theme-dark');
      this.renderer.addClass(document.body, 'theme-light');
    }
  }
}
