/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { InformixServer } from '../servers/informixServer';
import { InformixSensorService } from './informixSensor.service';
import { MonitoringService } from './monitoring.service';
import { MonitoringProfile } from './monitoringProfile';
import { Sensor, SensorType } from './sensor';


@Component({
  selector: 'app-sensors-required-control',
  templateUrl: 'sensors-required.html'
})
export class SensorsRequiredComponent implements OnInit, OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() monitoringProfile: MonitoringProfile;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('sensorList') requiredSensorList: string[];

  missingSensorList: SensorType[] = [];
  disabledSensorList: Sensor[] = [];

  private sensorTypes: SensorType[];

  private sensorUpdateSub: Subscription = null;

  @Output() monitoringProfileUpdated = new EventEmitter<any>();

  constructor(
    private monitoringService: MonitoringService,
    private sensorService: InformixSensorService
  ) {
    this.getSensorTypes();
  }

  private getSensorTypes() {
    this.monitoringService.getSensorTypes().then(types => {
      this.sensorTypes = types;
      this.determineMissingSensors();
    }).catch(err => {
      console.error('Could not get sensor types', err);
    });
  }

  ngOnInit() {
    this.sensorUpdateSub = this.sensorService.eventSensorUpdate.subscribe((sensor: Sensor) => {
      this.monitoringProfileUpdated.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.determineMissingSensors();
  }

  private determineMissingSensors() {
    if (!this.server || !this.monitoringProfile || !this.requiredSensorList || !this.sensorTypes) {
      return;
    }
    this.missingSensorList = [];
    this.disabledSensorList = [];
    this.requiredSensorList.forEach(requiredSensorId => {
      const sensor = this.monitoringProfile.getSensor(requiredSensorId);
      if (sensor == null) {
        this.missingSensorList.push(this.sensorTypes.find(type => type.id === requiredSensorId));
      } else if (sensor.disabled) {
        this.disabledSensorList.push(sensor);
      }
    });
  }

  doEnableSensors() {
    this.addSensorsToMonitoringProfile();
    this.enableSensorsInMonitoringProfile();
    this.monitoringProfileUpdated.emit();
  }

  private addSensorsToMonitoringProfile() {
    if (this.missingSensorList.length === 0) {
      return;
    }
    const missingSensorTypes: string[] = [];
    this.missingSensorList.forEach(sensorType => {
      missingSensorTypes.push(sensorType.id);
    });
    this.sensorService.addSensorsToMonitoringProfile(this.server.id, missingSensorTypes);
  }

  private enableSensorsInMonitoringProfile() {
    if (this.disabledSensorList.length === 0) {
      return;
    }
    this.disabledSensorList.forEach(sensor => {
      sensor.disabled = false;
    });
    this.sensorService.updateSensorInMonitoringProfile(this.server.id, this.disabledSensorList);
  }

  ngOnDestroy() {
    if (this.sensorUpdateSub) {
      this.sensorUpdateSub.unsubscribe();
    }
  }
}
