<div bsModal #addForeignKeyModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{isEdit ? ('schemaManager.createTable.title.editForeignKey' | translate) :
          ('schemaManager.createTable.title.addForeignKey' | translate)}}</h5>
        <button type="button" class="close" (click)="closeModal()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <label class="font-weight-bold"><span class="text-danger">*
              </span>{{'schemaManager.createTable.label.foreignKeyName' | translate}}</label>
            <input class="form-control" type="text"
              [placeholder]="'schemaManager.createTable.placeholder.foreignKeyName' | translate" tooltip=""
              [formControl]="foreignKeyName" appExtendedFormControl [customErrors]="keyNameErrorHandlers">
          </div>
          <div class="col-8 text-danger pt-4" *ngIf="isDuplicateTableCol">
            {{'schemaManager.createTable.errorMsg.duplicateTableCol' | translate}}
          </div>
        </div>
        <div class="text-danger pt-1" *ngIf="referenceKeys && referenceKeys.length < 1">
          {{'schemaManager.createTable.errorMsg.noReferenceKeyFound' | translate}} </div>
        <div class="row">
          <div class="col-lg-4 col-12 pt-3">
            <div class="row py-2">
              <div class="col-6 pr-0">
                <div class="d-inline-block rounded-circle p-1 bg-violet-60 mr-2"></div>
                {{'schemaManager.createTable.label.primaryKey' | translate}}
              </div>
              <div class="col-6">
                <div class="d-inline-block rounded-circle p-1 bg-light-blue-30 mr-2"></div>
                {{'schemaManager.createTable.label.uniqueKey' | translate}}
              </div>
            </div>
            <app-data-table *ngIf="!isLoading" [data]="referenceKeys" [enablePagination]="false" [enableSearch]="false"
              [minHeight]="200"
              [noDataAlertMessage]="'schemaManager.createTable.errorMsg.foreignKeyNoReferenceMsg' | translate">
              <app-data-table-expand [expandCondition]="tableExpanded.bind(this)">
                <ng-template appTableExpand let-row="row">
                  <ul class="list-group">
                    <li *ngFor="let constraint of row.constInfo" class="cursor-pointer p-2"
                      [ngClass]="{'bg-light-gray-30': currentConstraint === constraint}"
                      (click)="onConstraintSelect(row, constraint)">
                      <div class="d-inline-block rounded-circle p-1 mr-1 ml-4"
                        [ngClass]="constraint.constraintType === 'P' ? 'bg-violet-60':'bg-light-blue-30'"></div>
                      {{constraint.constraintName}}
                    </li>
                  </ul>
                </ng-template>
              </app-data-table-expand>
              <app-data-table-column width="30px">
                <ng-template appTableCell let-row="row">
                  <i class="icon font-weight-bold cursor-pointer"
                    [ngClass]="row.isExpand ? 'icon-chevron-down' : 'icon-chevron-right'"
                    (click)="row.isExpand = !row.isExpand"></i>
                </ng-template>
              </app-data-table-column>
              <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.selectKey' | translate">
                <ng-template appTableCell let-row="row">{{row.tableOwner + '.' + row.tableName}}</ng-template>
              </app-data-table-column>
            </app-data-table>
            <div class="d-flex align-items-center overlay mt-3" *ngIf="isLoading">
              <div class="flex-grow-1 text-center">
                <i class="icon icon-circle-notch icon-spin"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-12 pt-3">
            <div class="row py-2">
              <div class="col-12"> {{'schemaManager.createTable.label.mapReferencedCols' | translate}}</div>
            </div>
            <app-data-table [data]="columns" [minHeight]="200"
              [noDataAlertMessage]="'schemaManager.createTable.errorMsg.foreignKeySelectConstraintMsg' | translate">
              <app-data-table-column
                [title]="'schemaManager.createTable.table.columnTitle.referencedColumn' | translate">
                <ng-template appTableCell let-row="row">
                  <span [tooltip]="row.type">{{row.name}}</span>
                </ng-template>
              </app-data-table-column>
              <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.tableColumn' | translate">
                <ng-template appTableCell let-row="row">
                  <ng-container *ngIf="checkIfTypeExist(row.type) else notableCol">
                    <select class="form-control mr-3" (change)="setColumn(row.name, $event)">
                      <option disabled value="" [selected]="!isEdit">
                        {{'schemaManager.createTable.label.selectColumn' | translate}} </option>
                      <ng-container *ngFor="let column of tableColumnList">
                        <option [value]="column.name" *ngIf="column.type === row.type"
                          [selected]="referenceKey[row.name] === column.name">{{column.name}}</option>
                      </ng-container>
                    </select>
                  </ng-container>
                  <ng-template #notableCol>
                    <span class="text-danger"> {{'schemaManager.createTable.errorMsg.dataTypesDoNotMatch' |
                      translate}}</span>
                  </ng-template>
                </ng-template>
              </app-data-table-column>
            </app-data-table>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <app-checkbox [formControl]="isDisableIndex">
              {{'schemaManager.createTable.label.disableIndex' | translate}} </app-checkbox>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12 text-right">
            <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">
              {{isEdit ? ('button.cancel'| translate) : ('button.close' |translate)}}</button>
            <button type="button" class="btn btn-primary" (click)="addKey()"
              [disabled]="isDisable || !foreignKeyName.valid">{{isEdit ? ('button.save'| translate) : ('button.add'
              |translate)}} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
