/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AbstractSystemReport } from './abstract-system-report';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SchemaService } from '../schema/schema.service';
import { SystemReportsService } from './system-reports.service';

@Component({
  selector: 'app-update-statistics-report',
  templateUrl: './update-statistics-report.html'
})

export class UpdateStatisticsReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId = 'update_stats';

  databases: string[] = null;
  selectedDatabase = null;
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };
  filteredReportData: any;
  filteringReportData: any;
  modeFilter: any = 'all';
  constructor(
    private route: ActivatedRoute,
    systemReportsService: SystemReportsService,
    notificationsService: NotificationsService,
    translate: TranslateService,
    private schemaService: SchemaService
  ) {
    super(systemReportsService, notificationsService, translate);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getDatabases();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get('systemReports.updateStatistics').subscribe((text: string) => {
      this.setBreadcrumb(text);
    });
  }

  getDatabases() {
    this.databases = [];
    this.schemaService.getDatabases(this.server).subscribe((res: any) => {
      res.forEach(element => {
        this.databases.push(element.name);
      });
      if (this.databases.length > 0) {
        this.selectedDatabase = this.databases[0];
        this.getSystemDatabaseReport(this.reportId, this.selectedDatabase);
      }
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }
  changeModeFilter(filterData: any) {
    this.reportData = this.actualReportData;
    if (this.reportData && filterData !== 'all') {
      this.reportData = this.reportData.filter(item => item.mode === filterData);
    }
  }

  changeDatabase(event) {
    this.selectedDatabase = event.target.value;
    this.reportData = null;
    this.getSystemDatabaseReport(this.reportId, this.selectedDatabase);
  }
  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.filterReportData();
  }
  filterReportData() {
    this.filteredReportData = [];
    this.filteringReportData = this.actualReportData;
    if (this.timeStamps.fromTimeStamp !== null || this.timeStamps.toTimeStamp !== null) {
      if (this.timeStamps.fromTimeStamp !== null && this.timeStamps.toTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.build_date && item.build_date.$date) {
            return item.build_date.$date > this.timeStamps.fromTimeStamp &&
              item.build_date.$date < this.timeStamps.toTimeStamp;
          }
        });
      } else if (this.timeStamps.fromTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.build_date && item.build_date.$date) {
            return item.build_date.$date > this.timeStamps.fromTimeStamp;
          }
        });
      } else if (this.timeStamps.toTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.build_date && item.build_date.$date) {
            return item.build_date.$date < this.timeStamps.toTimeStamp;
          }
        });
      }
      this.reportData = this.filteredReportData;
    } else {
      this.reportData = this.actualReportData;
    }
  }
}
