<form [formGroup]="formGroup">
  <div class="form-group form-inline my-2">
    <label class="mr-2">
      <strong class="mr-2">Type</strong>
      <select class="form-control mt-1 mb-1" formControlName="type">
        <option *ngFor="let type of axisTypes" [ngValue]="type">
          {{type}}
        </option>
      </select>
    </label>
    <label class="mr-2" *ngIf="chartType !== 'pie' && chartType !== 'table'">
      <strong class="mr-2">Min</strong>
      <input type="number" class="form-control mt-1 mb-1" style="width: 100px" formControlName="min" placeholder="auto">
    </label>
    <label class="mr-2" *ngIf="chartType !== 'pie' && chartType !== 'table'">
      <strong class="mr-2">Max</strong>
      <input type="number" class="form-control mt-1 mb-1" style="width: 100px" formControlName="max" placeholder="auto">
    </label>
    <label class="mr-2" *ngIf="chartType !== 'pie' && chartType !== 'table'">
      <strong class="mr-2">Label</strong>
      <input type="text" class="form-control mt-1 mb-1" formControlName="label" placeholder="No label">
    </label>
  </div>
</form>
