/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023. All Rights Reserved.
 *******************************************************************************/

 import { Component, EventEmitter, Input, OnChanges, OnDestroy,
          OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
 import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
 import guideConfig, { GuideConfiguration } from './guide-config';


 @Component({
   selector: 'app-guide-modal',
   templateUrl: './guide-modal.component.html',
   styleUrls: ['./guide-modal.component.scss']
 })
 export class guideModalComponent implements OnInit, OnChanges, OnDestroy {
   @ViewChild('guideModal', { static: false }) guideModal: TemplateRef<any>;
   @Input() guideId: number;
   @Input() show: Boolean;

   @Output() showChange = new EventEmitter<Boolean>();

   modalRef: BsModalRef;
   currentGuide: any;
   currentStep: number;
   guideConfiguration: GuideConfiguration[] = [...guideConfig];
   constructor(private bsModalService: BsModalService) {
   }

   ngOnInit() {
   }

   ngOnDestroy() {
    this.hideModal();
   }


   ngOnChanges() {
    /**
     * Open guide modal when show is true
     */
     if (this.show) {
        this.getGuideObject(this.guideId);
        setTimeout(() => {
          this.showModal();
        });
     }

   }

   /**
    * Method to show the groups list modal
    */
   showModal(): void {
     this.modalRef = this.bsModalService.show(this.guideModal, { backdrop: true, ignoreBackdropClick: true,
     class: 'modal-dialog-centered modal-lg'});
   }

   /**
    * Method to hide the groups list modal
    */
   hideModal(): void {
    if(this.modalRef) {
      this.modalRef.hide();
    }
     this.showChange.emit(false);
   }

   /**
    * Method to get guide ojject by id
    *
    * @param guideId
    */
   getGuideObject(guideId: number): void {
    this.currentStep = 0;
    const guideObject: GuideConfiguration = this.guideConfiguration.find(guide => guide.id === guideId);
    if(guideConfig && guideObject.steps.length) {
      let stepNumber = 0;
      let totalSteps = 0;
      this.currentGuide = {...guideObject, steps: guideObject.steps.map(step => {
        if(step.image) {
          stepNumber += 1;
          totalSteps += 1;
        }else{
          step.headingOnly = true;
        }
        step.number = stepNumber;
        return step;
      }), totalSteps};
    }else{
      this.setNoSteps();
    }
   }

   /**
    * Method to set default no steps avaialable steps.
    */
   setNoSteps(): void {
    this.guideConfiguration.push(
      {
          id: 0,
          title: null,
          steps : [
              {
                  text: 'No steps avialable for given guide Id.',
              }
            ]
      });
      this.getGuideObject(0);
   }

   /**
    * Go to next step
    */
   nextStep(): void {
    this.currentStep = this.currentStep + 1;
   }

   /**
    * Go to previous step
    */
   prevStep(): void {
    this.currentStep = this.currentStep - 1;
   }
 }
