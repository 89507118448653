/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformixServer } from '../informixServer';
import { AutoUpdateStatisticsService } from './autoUpdateStatistics.service';
import { AusTask, AusParameter } from './configInfo.model';
import { TaskSchedulerService } from '../task-scheduler/task-scheduler.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ParameterService } from '../task-scheduler/parameter-service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataTableComponent } from '../../../shared/data-table/data-table.component';

@Component({
  selector: 'app-configuration-auto-update-statistics',
  templateUrl: 'configuration-autoUpdateStatistics.html',
  styleUrls: ['./autoUpdateStatistics.component.scss'],
  providers: [TaskSchedulerService, ParameterService]
})
export class ConfigurationComponent implements OnInit {

  @Input() server: InformixServer = null;
  parameterForm: UntypedFormGroup;
  scheduleData: AusTask[];
  configData: AusParameter[];
  threadCount: Number;
  valueLabelObj = {
    AUS_AGE: 'days',
    AUS_CHANGE: '%',
    AUS_AUTO_RULES: '',
    AUS_SMALL_TABLES: 'rows',
    AUS_PDQ: 'priority',
    Thread: 'threads'
  };

  @ViewChild('addTaskSensorModal') addTaskSensorModal: ModalDirective;
  @ViewChild('openDbPriorityModal') openDbPriorityModal: ModalDirective;
  @ViewChildren(DataTableComponent) myDataTable: QueryList<DataTableComponent>;
  paramForm: UntypedFormGroup;
  dynamicHeight: any;
  ausConfigApiError: string;
  constructor(
    private route: ActivatedRoute,
    private autoUpdateStatisticsService: AutoUpdateStatisticsService,
    private parameterService: ParameterService,
    private notificationsService: NotificationsService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getConfig();
      if (!this.paramForm) {
        this.createForm();
      }
    });
  }
  get formControls() {
 return this.paramForm.controls;
}

  createForm() {
    this.paramForm = this.fb.group({
      AUS_AGE: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      AUS_CHANGE: new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(100)]),
      AUS_AUTO_RULES: new UntypedFormControl(null),
      AUS_SMALL_TABLES: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      AUS_PDQ: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      Thread: new UntypedFormControl(null, [Validators.required, Validators.min(1)])
    });
  }
  private loadServer(server: InformixServer) {
    this.server = server;
  }

  getConfig() {
    this.autoUpdateStatisticsService.getConfig(this.server).subscribe((res: AusTask[]) => {
      this.scheduleTabData(res);
      setTimeout(() => {
        if (this.myDataTable.length === 2 && this.myDataTable.first.dataTableRef) {
          const topOffSet = this.myDataTable.first.dataTableRef.nativeElement.getBoundingClientRect().top;
          this.dynamicHeight = ((window.innerHeight - topOffSet) / 2) - 90;
        }
      });
    }, err => {
      if(err.error && err.error.err) {
        this.ausConfigApiError = err.error.err;
      }
    });
  }

  saveDoneEvent(event: any) {
    if (event === true || event.saveDone === true) {
      this.getConfig();
    }
  }

  save(row: any) {
    const paramFormControl = this.paramForm.controls[row.name];
    if (paramFormControl.valid) {
      row.isEdit = true;
      row.value = paramFormControl.value;
      if (row.name !== 'Thread') {
        this.scheduleData.forEach(element => {
          element.parameters.forEach(param => {
            if (param.name === row.name) {
              param.current_state = 2;
              this.parameterService.updateParametersForTask(this.server, element.tk_id, element.parameters).subscribe((res) => {
                if (res) {
                  this.notificationsService.pushSuccessNotification('Configuration parameter saved successfully.');
                  row.isEdit = false;
                }
              }, err => {
                this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
                row.isEdit = false;
              });
            }
          });
        });
      } else {
        this.autoUpdateStatisticsService.updateThread(this.server, row.value).subscribe((res) => {
          if (res) {
            this.notificationsService.pushSuccessNotification('Thread count updated successfully.');
            row.isEdit = false;
          }
        }, err => {
          this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
          row.isEdit = false;
        });
      }
    }
  }

  private scheduleTabData(data: any) {
    if (data.length < 1) {
      return;
    }
    this.scheduleData = data.tasks;
    this.configData = [];
    data.tasks.forEach(element => {
      element.parameters.forEach(parameter => {
        parameter.current_state = 0;
        this.configData.push(parameter);
      });
    });
    this.threadCount = data.total_ausr_threads;
    const threadObj = {
      current_state: 0,
      id: null,
      name: 'Thread',
      task_name: null,
      value: this.threadCount,
      value_type: null,
      description: 'The Auto Update Statistics Refresh task runs with this number of threads.'
    };
    this.configData.push(threadObj);
  }
}
