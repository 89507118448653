/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { AlertOperandType } from '../alert-operand-types';
import { agentStatusEnum } from './agent-status-enum';
import { ConstantNumberOperandComponent } from './constant-number-operand.component';
import { OnlineLogRegexOperandComponent } from './online-log-regex-operand.component';
import { serverStatusEnum } from './server-status-enum';
import { haStatusEnum } from './ha-status-enum';

export interface ConstantEnumElem {
  name: string;
  value: any;
}

export interface ConstantEnum {
  name: string;
  type: string;
  elements: ConstantEnumElem[];
}

export class ConstantOperands {
  private static enumTypeMap: { [key: string]: ConstantEnum } = null;

  public static getTypes(): AlertOperandType[] {
    const types: AlertOperandType[] = [
      {
        type: 'const',
        component: ConstantNumberOperandComponent,
        description: 'Number',
        outputType: 'number',
        triggers: null
      },
      {
        type: 'online-log-regex',
        component: OnlineLogRegexOperandComponent,
        description: 'Online Log Regex',
        outputType: 'online-log-regex',
        triggers: null
      }
    ];

    return types;
  }

  public static getEnums(): ConstantEnum[] {
    return [
      serverStatusEnum,
      agentStatusEnum,
      haStatusEnum
    ];
  }

  public static getEnumTypeMap() {
    if (!ConstantOperands.enumTypeMap) {
      ConstantOperands.enumTypeMap = {};
      ConstantOperands.getEnums().forEach(e => {
        ConstantOperands.enumTypeMap[e.type] = e;
        ConstantOperands.enumTypeMap[e.type + '[]'] = e;
      });
    }

    return ConstantOperands.enumTypeMap;
  }

  public static getEnumForType(type: string) {
    return ConstantOperands.getEnumTypeMap()[type];
  }
}
