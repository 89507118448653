/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import * as Chart from 'chart.js';
import { Subject } from 'rxjs';

const DOUBLE_CLICK_INTERVAL = 500;

export interface ZoomRequest {
  start: number;
  end: number;
}

export class ChartjsZoomPlugin {
  private chart: Chart = null;
  private hoverX: number = null;
  private windowHoverOffset = 0;
  private selectStartX: number = null;
  private clickX: number = null;
  private clickTimestamp: number = null;

  private windowMouseMoveListener: any;
  private windowMouseUpListener: any;

  public readonly zoomRequests = new Subject<ZoomRequest>();

  beforeInit(chart) {
    this.chart = chart;
    chart.config.options.events.push('mousedown');
  }

  afterDatasetsDraw(chart) {
    const ctx = chart.chart.ctx as CanvasRenderingContext2D;
    if (!ctx) {
      return;
    }

    if (this.hoverX !== null) {
      ctx.save();
      ctx.beginPath();
      ctx.rect(chart.chartArea.left, chart.chartArea.top, chart.chartArea.right - chart.chartArea.left,
        chart.chartArea.bottom - chart.chartArea.top);
      ctx.clip();
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
      if (this.selectStartX !== null) {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
        const startX = Math.min(this.selectStartX, this.hoverX);
        const width = Math.abs(this.hoverX - this.selectStartX);
        ctx.fillRect(startX, chart.chartArea.top, width, chart.chartArea.bottom - chart.chartArea.top);

        ctx.beginPath();
        ctx.moveTo(this.selectStartX, chart.chartArea.top);
        ctx.lineTo(this.selectStartX, chart.chartArea.bottom);
        ctx.stroke();
      }

      ctx.beginPath();
      ctx.moveTo(this.hoverX, chart.chartArea.top);
      ctx.lineTo(this.hoverX, chart.chartArea.bottom);
      ctx.stroke();

      ctx.restore();
    }
  }

  beforeEvent(chart, event) {
    if (event.type === 'mousedown' && event.native.button === 0) {
      this.selectStartX = this.clampX(event.x);
      this.windowHoverOffset = event.native.clientX - event.x;
      this.addWindowMouseEventListeners();
    } else if (event.type === 'mousemove' && this.selectStartX === null) {
      this.hoverX = this.clampX(event.x);
      chart.render();
    } else if (event.type === 'mouseout') {
      this.hoverX = null;
      chart.render();
    } else if (event.type === 'click' && event.native.button === 0) {
      const now = new Date().getTime();
      if (this.clickTimestamp === null || now - this.clickTimestamp > DOUBLE_CLICK_INTERVAL) {
        this.clickX = this.clampX(event.x);
        this.clickTimestamp = now;
      } else {
        const clickTimestamp = this.getTimestampFromX(this.clickX);
        const scale = (this.chart as any).scales.time;
        const timestampDelta = scale.lastTick.valueOf() - scale.firstTick.valueOf();
        this.zoomRequests.next({
          start: clickTimestamp - timestampDelta,
          end: clickTimestamp + timestampDelta
        });
        this.clickX = this.clickTimestamp = null;
      }
    }
  }

  destroy() {
    this.removeWindowMouseEventListeners();
    this.chart = null;
  }

  private clampX(x: number): number {
    const area = this.chart.chartArea;
    return x < area.left ? area.left : (x > area.right ? area.right : x);
  }

  private stopSelecting() {
    this.selectStartX = null;
    this.hoverX = null;
    this.removeWindowMouseEventListeners();
    this.chart.render();
  }

  isSelecting(): boolean {
    return this.selectStartX !== null;
  }

  private addWindowMouseEventListeners() {
    if (!this.windowMouseMoveListener) {
      this.windowMouseMoveListener = this.onWindowMouseMove.bind(this);
      window.addEventListener('mousemove', this.windowMouseMoveListener);
    }
    if (!this.windowMouseUpListener) {
      this.windowMouseUpListener = this.onWindowMouseUp.bind(this);
      window.addEventListener('mouseup', this.windowMouseUpListener);
    }
  }

  private removeWindowMouseEventListeners() {
    if (this.windowMouseMoveListener) {
      window.removeEventListener('mousemove', this.windowMouseMoveListener);
      this.windowMouseMoveListener = null;
    }
    if (this.windowMouseUpListener) {
      window.removeEventListener('mouseup', this.windowMouseUpListener);
      this.windowMouseUpListener = null;
    }
  }

  private onWindowMouseMove(event: any) {
    if (!(event.buttons & 1)) {
      return this.stopSelecting();
    }

    this.hoverX = this.clampX(event.clientX - this.windowHoverOffset);
    this.chart.render();
  }

  private onWindowMouseUp(event: any) {
    if (!(event.buttons & 1)) {
      if (this.selectStartX !== null && this.hoverX !== null) {
        const start = Math.min(this.selectStartX, this.hoverX);
        const end = Math.max(this.selectStartX, this.hoverX);
        this.zoomRequests.next({
          start: this.getTimestampFromX(start),
          end: this.getTimestampFromX(end)
        });
      }

      this.stopSelecting();
    }
  }

  private getTimestampFromX(x: number): number {
    const scale = (this.chart as any).scales.time;
    return scale.getValueForPixel(x).valueOf();
  }
}
