/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/

import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { RadioGroupComponent } from '../radio-group/radio-group.component';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {

  private _disabled = false;

  @Input() value: any;

  group: RadioGroupComponent = null;
  selected = false;

  @Input()
  set checked(checked: boolean) {
    setTimeout(() => {
      this.selected = checked;
    }, 0);
  }

  @Input()
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this._disabled || (this.group && this.group.disabled);
  }

  set disabled(disabled: boolean) {
    setTimeout(() => {
      this._disabled = disabled;
    }, 0);
  }

  @HostListener('click')
  onClick() {
    if (!this.disabled && this.group) {
      this.group.updateValue(this.value);
    }
  }
}
