/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InformixServerRecoveryLogsService {

  constructor(private httpClient: HttpClient) { }

  public getRecoveryLogsInfo(serverId: number): Observable<any> {
    const url = 'informix/' + serverId + '/rlogs';
    return this.httpClient.get<any>(url);
  }

  public movePhysicalLog(serverId: number, dbspace: string, size: number): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/rlogs/plog',
      {
        dbspace,
        size
      }).toPromise<any>();
  }

  public addLogicalLog(serverId: number, dbspace: string, size: number, count: number, afterCurrent: boolean): Promise<any> {
    return this.httpClient.post<any>('informix/' + serverId + '/rlogs/llog/',
      {
        dbspace,
        size,
        count,
        afterCurrent
      }).toPromise<any>();
  }

  public dropLogicalLog(serverId: number, logicalLogId: number): Promise<any> {
    return this.httpClient.delete<any>('informix/' + serverId + '/rlogs/llog/' + logicalLogId).toPromise<any>();
  }

  public switchLogicalLog(serverId: number): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/rlogs/llog/', {}).toPromise<any>();
  }
}
