/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2024. All Rights Reserved.
 *******************************************************************************/

 import { Component, EventEmitter, Input, OnChanges, OnDestroy,
          OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
 import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
 import { InformixServer } from '../../informixServer';
import { PreSelectedGroups, SqlhostsGroupInternal } from '../enterprise-replication-types';
 import { EnterpriseReplicationService } from '../enterprise-replication.service';

 @Component({
   selector: 'app-sqlhosts-groups-modal',
   template: `
     <ng-template #serverListModal>
         <div class="modal-header">
             <h5 class="modal-title pull-left">Select groups for ER domain</h5>
             <button type="button" [disabled]="loader" class="close" (click)="hideModal()">
                 <i class="icon icon-times"></i>
             </button>
         </div>
         <div class="modal-body px-3 py-2">
             <div class="loader-container d-flex align-items-center" style="min-height: 450px;" *ngIf="isLoading">
               <div style="width: 100%;" class="d-flex justify-content-center">
                 <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
               </div>
             </div>
             <div *ngIf="!isLoading && errorResponse" [style.minHeight]="'60vh'">
              <div class="alert alert-danger mt-2 mb-0 w-100">
                    {{ errorResponse }}
                </div>
             </div>
             <ng-container *ngIf="!isLoading && filterGroupList && filterGroupList.length">
              <div class="alert alert-info mt-2">
                  <i class="icon icon-info icon-info-circle text-info pr-1"></i>
                    Below groups are belong to <strong> {{ server.alias }} </strong> sqlhosts file.
              </div>
              <div>
                <app-data-table #groupstDataTable [data]="filterGroupList"
                    [rowsPerPage]="10" [enablePagination]="false"  [minHeight]="465"
                        noDataAlertMessage="No servers found." [enableSelect]="true" isChecked="checked"
                        (updateSelectedRows)="onChecked($event)">
                    <app-data-table-column [title]="'Name'" property="groupName" [searchable]="true">
                        <ng-template appTableCell let-row="row">
                            <div class="one-line d-flex align-items-center">
                                <div class="flex-1">{{row.groupName}}</div>
                                <div class="mr-2 cursor-pointer"><i class="icon icon-info icon-info-circle"
                                [style.fontSize.rem]="1" [popover]="membersInfo" placement="left" triggers="hover"
                                containerClass="er-graph-popover popover-arrow-hidden popover-color"></i></div>

                                <ng-template #membersInfo>
                                  <div class="popover-body btn-group-toggle p-0">
                                      <div><strong class="bold">Group Member(s):</strong></div>
                                      <div>
                                        <ul class="list-unstyled m-0 p-0">
                                          <li *ngFor="let member of row.members">{{member?.serverName}}</li>
                                        </ul>
                                      </div>
                                  </div>
                                </ng-template>
                            </div>
                        </ng-template>
                    </app-data-table-column>
                  </app-data-table>
                </div>
             </ng-container>
         </div>
         <div class="modal-footer">
             <button type="button" class="btn btn-secondary btn-fixed-width mr-1"
                 (click)="hideModal()">
                 Close
             </button>
             <button type="button" class="btn btn-primary mr-1" [disabled]="isLoading" *ngIf="!errorResponse"
                (click)="hideModal(); addServers(); ">
                 Add
             </button>
         </div>
     </ng-template>
     `,
 })
 export class SqlhostsGroupsModalComponent implements OnInit, OnChanges, OnDestroy {
   @ViewChild('serverListModal', { static: false }) serverListModal: TemplateRef<any>;
   @Input() server: InformixServer;
   @Input() preSelectedGroups: PreSelectedGroups;
   @Input() includeCurrentGroup: boolean;
   @Input() show: Boolean;

   @Output() showChange = new EventEmitter<Boolean>();
   @Output() groupAdded = new EventEmitter<SqlhostsGroupInternal[]>();

   modalRef: BsModalRef;
   filterGroupList: any = null;
   isLoading: Boolean = true;
   errorResponse: string;
   selectedRows: SqlhostsGroupInternal[];
   private currentGroup: SqlhostsGroupInternal = null;
   constructor(
     private bsModalService: BsModalService,
     private erService: EnterpriseReplicationService,
   ) {

   }

   ngOnInit() {
   }

   ngOnDestroy() {
    this.hideModal();
   }

   /**
    * Method to fetch sqlhosts group list
    */
   getSqlhostsGroups(): void {
    this.isLoading = true;
    this.errorResponse = null;
    this.filterGroupList = null;
    this.erService.getSqlhostsGroups(this.server.id).subscribe(response => {
      this.isLoading = false;
      this.filterGroupList = this.erService.mapInternalSqlhostsGroups(response.groups);
      this.disablePreSelectedGroups();
    }, err => {
      let errMsg = err.message;
      if(err.error.err) {
        errMsg = err.error.err;
      }
      this.errorResponse = errMsg;
      this.isLoading = false;
    });
   }

   ngOnChanges(changes: SimpleChanges) {
     if (this.show) {
       this.showModal();
     }
   }

   /**
    * Method to show the groups list modal
    */
   showModal(): void {
     this.modalRef = this.bsModalService.show(this.serverListModal, { backdrop: true, ignoreBackdropClick: true });
     this.disablePreSelectedGroups();
     this.getSqlhostsGroups();
   }

   /**
    * Method to hide the groups list modal
    */
   hideModal(): void {
    if(this.modalRef) {
      this.modalRef.hide();
    }
     this.showChange.emit(false);
   }

   /**
    * Event handler when user selects groups from the data table
    *
    * @param selectedRows SqlhostsGroupInternal[]
    */
   onChecked(selectedRows: SqlhostsGroupInternal[]) {
    this.selectedRows = selectedRows.filter(server => !server.disabled);
    if(this.includeCurrentGroup) {
      this.selectedRows.push(this.currentGroup);
    }
   }

   /**
    * Method to be called when Add button is clicked
    * Emits selected groups
    */
   addServers() {
     this.groupAdded.emit(this.selectedRows);
     this.showChange.emit(false);
   }

   /**
    * Method to disable groups that are already added/selected.
    *
    * @returns void
    */
   disablePreSelectedGroups(): void {
     if (!this.filterGroupList || !this.filterGroupList.length) {
       return;
     }
    //  Disable group of the current dbserver
     this.currentGroup = this.filterGroupList.find(group => group.isCurrentGroup);
     if(this.currentGroup && this.currentGroup.groupName) {
      this.preSelectedGroups[this.currentGroup.groupName] = true;
     }

     this.filterGroupList = this.filterGroupList.map(group => {
       // Pre selecting already added groups in the groups list (left panel).
       if (this.preSelectedGroups[group.groupName]) {
          group.disabled = true;
       } else {
          group.disabled = false;
       }
       return group;
     });

   }
 }
