/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { ChartJSUtils } from '../../../../shared/chartjs.utils';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { MonitoringProfile } from '../../../monitoring/monitoringProfile';
import { InformixServer } from '../../informixServer';
import { InformixServerService } from '../../informixServer.service';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { HttpErrorResponse } from '@angular/common/http';
import { HDRPermissionService } from '../../../../shared/hdr-permission/hdr-permission.service';
import { HDRPermission } from '../../../../shared/hdr-permission/hdr-permission';

@Component({
  selector: 'app-virtual-processors',
  templateUrl: 'virtual-processors.html'
})
export class VirtualProcessorsComponent implements OnInit, OnDestroy {

  @Input() viewLiveData = true;

  breadcrumb: BreadcrumbElement[] = null;
  performanceBreadCrumb: BreadcrumbElement = { name: 'Performance' };
  vpsBreadCrumb: BreadcrumbElement = { name: 'Virtual Processors' };

  server: InformixServer = null;
  monitoringProfile: MonitoringProfile = null;
  vps: any[] = null;

  selectedVPClass: string = null;
  classVps: any[] = null;

  vpChart: Chart;
  chartData: number[] = [];
  chartColors: string[] = [];
  chartLabels: string[] = [];

  isAddingVP = false;
  isDroppingVP = false;
  isDisableDropVPs = false;
  vpFormGroup: UntypedFormGroup;
  vpClass: UntypedFormControl;
  vpCount: UntypedFormControl;
  actionClassOptions: string[] = null;
  addVPClasses = ['adt', 'aio', 'bts', 'cpu', 'dwavp', 'encrypt', 'idsxmlvp', 'jvp', 'lio', 'msc', 'pio', 'soc', 'str'];
  dropVPClasses = ['bts', 'cpu', 'encrypt', 'idsxmlvp', 'jvp'];

  dataLoadErrorMessage: string = null;

  private refreshVPs = new Subject<any>();
  private vpsSub: Subscription = null;
  private refreshVPsByClass = new Subject<any>();
  private vpsByClassSub: Subscription = null;
  vpHDR: HDRPermission;
  @ViewChild('vpChartCanvas') vpChartCanvas: ElementRef;

  constructor(
    private informixServerService: InformixServerService,
    private monitoringService: MonitoringService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private hdrPermissionService: HDRPermissionService) { }

  ngOnInit() {
    this.vpsSub = this.refreshVPs.pipe(
      switchMap(() => this.informixServerService.getServerVPs(this.server.id)))
      .subscribe(vps => {
        this.vps = vps;
        this.isDisableDropVPs = true;
        this.vps.forEach((vp) => {
          if (this.dropVPClasses.indexOf(vp.class) > 0 && ((vp.class === 'cpu' && vp.count > 1) || vp.class !== 'cpu')) {
            this.isDisableDropVPs = false;
          }
        });
        this.vps.sort((a, b) => a.class.localeCompare(b.class));
        setTimeout(() => this.updateVPChart(), 0);
        this.dataLoadErrorMessage = null;
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      });

    this.vpsByClassSub = this.refreshVPsByClass.pipe(
      switchMap(() => this.informixServerService.getServerClassVPs(this.server.id, this.selectedVPClass)))
      .subscribe(vps => {
        this.classVps = vps;
        this.classVps.sort((a, b) => a.class.localeCompare(b.class));
        this.dataLoadErrorMessage = null;
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      });

    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.route.queryParams.subscribe(queryParams => {
      this.classVps = [];
      if (queryParams.class) {
        this.selectedVPClass = queryParams.class;
        this.refreshVPsByClass.next(null);
      } else {
        this.selectedVPClass = null;
      }
    });
    this.vpClass = new UntypedFormControl('', [Validators.required]);
    this.vpCount = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
    this.vpFormGroup = new UntypedFormGroup({
      vpClass: this.vpClass,
      vpCount: this.vpCount
    });
    this.vpFormGroup.valueChanges.subscribe(() => {
      if (this.isDroppingVP) {
        if (this.vpClass.value === 'cpu') {
          this.vpCount.setValidators([Validators.required, Validators.min(1),
          Validators.max(this.vps.filter(vp => vp.class === this.vpClass.value)[0].count - 1)]);
        } else {
          this.vpCount.setValidators([Validators.required, Validators.min(1),
          Validators.max(this.vps.filter(vp => vp.class === this.vpClass.value)[0].count)]);
        }
      } else {
        this.vpCount.setValidators([Validators.required, Validators.min(1)]);
      }
      this.vpCount.updateValueAndValidity({ emitEvent: false });
    });
    this.vpHDR = this.hdrPermissionService.getByPermissionId('p3');
  }

  ngOnDestroy() {
    if (this.vpsSub) {
      this.vpsSub.unsubscribe();
    }
    if (this.vpsByClassSub) {
      this.vpsByClassSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.performanceBreadCrumb, this.vpsBreadCrumb]);
    this.getMonitoringProfile();
    if (this.server.hasMonitorPassword) {
      this.refreshData();
    }
  }

  private refreshData(refreshAll = false) {
    if (this.selectedVPClass) {
      this.refreshVPsByClass.next(null);
    }
    if (refreshAll || !this.selectedVPClass) {
      this.refreshVPs.next(null);
    }
  }

  public handleDataSourceToggle(viewMonitoredData) {
    this.viewLiveData = !viewMonitoredData;
    if (this.viewLiveData) {
      setTimeout(() => this.updateVPChart(), 0);
    } else if (this.vpChart) {
      this.vpChart.destroy();
      this.vpChart = null;
    }
  }

  public getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;
    }).catch(err => {
      console.error('Error getting monitoring profile info', err);
    });
  }

  private setChartData() {
    // reset chart data arrays
    while (this.chartData.length > 0) {
      this.chartData.pop();
    }
    while (this.chartColors.length > 0) {
      this.chartColors.pop();
    }
    while (this.chartLabels.length > 0) {
      this.chartLabels.pop();
    }

    if (!this.vps) {
      return;
    }

    // set chart data array with current vp data
    let i = 0;
    this.vps.forEach(vp => {
      this.chartData.push(vp.totalcpu);
      this.chartLabels.push(vp.class);
      this.chartColors.push(ChartJSUtils.getDefaultColor(i));
      i++;
    });
  }

  private updateVPChart() {
    this.setChartData();

    // If chart has already been created it, update it.
    if (this.vpChart) {
      this.vpChart.update();
      return;
    }

    // Otherwise we need to create it
    this.vpChart = new Chart(this.vpChartCanvas.nativeElement, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: this.chartData,
            backgroundColor: this.chartColors
          }
        ],
        labels: this.chartLabels
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index'
        },
        legend: {
          position: 'bottom'
        }
      }
    });
  }

  public removeClassFilter() {
    this.selectedVPClass = null;
    this.router.navigate([]);
  }

  public openAddVPForm() {
    if (this.vpHDR && !this.vpHDR.isAllow()) {
      return;
    }
    this.isAddingVP = true;
    this.isDroppingVP = false;
    this.actionClassOptions = [];
    this.addVPClasses.forEach((vpclass) => {
      this.actionClassOptions.push(vpclass);
    });
    if (this.vps) {
      this.vps.forEach((vp) => {
        if (this.addVPClasses.indexOf(vp.class) < 0 && vp.class !== 'adm' && vp.class !== 'opt') {
          this.actionClassOptions.push(vp.class);
        }
      });
    }
    this.actionClassOptions.sort((a, b) => a.localeCompare(b));
    if (this.selectedVPClass) {
      this.vpClass.setValue(this.selectedVPClass);
    } else {
      this.vpClass.setValue(this.actionClassOptions[0]);
    }
    this.vpCount.setValue(1);
  }

  public openDropVPForm() {
    if (this.vpHDR && !this.vpHDR.isAllow()) {
      return;
    }
    this.isAddingVP = false;
    this.isDroppingVP = true;
    this.actionClassOptions = [];
    if (this.vps) {
      this.vps.forEach((vp) => {
        if (this.dropVPClasses.indexOf(vp.class) >= 0) {
          if ((vp.class === 'cpu' && vp.count > 1) || vp.class !== 'cpu') {
            this.actionClassOptions.push(vp.class);
          }
        }
      });
    }
    if (this.selectedVPClass && this.actionClassOptions.indexOf(this.selectedVPClass) >= 0) {
      this.vpClass.setValue(this.selectedVPClass);
    } else {
      this.vpClass.setValue(this.actionClassOptions[0]);
    }
    this.vpCount.setValue(1);
  }

  public doVPAction() {
    if (!this.vpFormGroup.valid) {
      return;
    }
    if (this.isAddingVP) {
      this.informixServerService.addServerVPs(this.server.id, this.vpClass.value, this.vpCount.value).then(result => {
        this.handleActionResult(result);
      }).catch(err => {
        this.notificationsService.pushErrorNotification(err);
      });
      this.isAddingVP = false;
    } else if (this.isDroppingVP) {
      this.informixServerService.dropServerVPs(this.server.id, this.vpClass.value, this.vpCount.value).then(result => {
        this.handleActionResult(result);
      }).catch(err => {
        this.notificationsService.pushErrorNotification(err);
      });
      this.isDroppingVP = false;
    }
  }

  private handleActionResult(result) {
    this.notificationsService.pushGenericNotification(result.return_code, result.result_message);
    this.refreshData(true);
  }

}
