<div class="{{loginPage}}">
  <div class="d-flex justify-content-center">
    <div>
      <img src="assets/img/logo_big.png" width="501" height="105" class="mt-5 mb-5">
      <div class="input-panel" [ngStyle]="{ 'width': isFirstSignIn ? '450px' : null}">
        <form [formGroup]="formGroup">
          <div class="form-content">
            <div class="alert alert-danger mx-2 text-aligner" [hidden]="!logInError" [ngStyle]="{'max-width': isFirstSignIn ? '365px': '300px'}">
              <button type="button" class="close" (click)="logInError = null">
                <i class="icon icon-times"></i>
              </button>
              {{logInError}}
            </div>
            <div class="alert alert-info text-aligner" *ngIf="isFirstSignIn && isChangePassword" style="max-width: 365px;">
              <i class="icon icon-info-circle text-info"></i> Please update your password since this is your first sign-in.
            </div>
            <div class="form-group" *ngIf="!isFirstSignIn">
              <input type="text" class="input-underline input-lg" placeholder="Username" formControlName="username" appFocusOnCreate>
            </div>
            <div class="form-group" *ngIf="!isFirstSignIn">
              <div class="input-group password-hide-show inner-addon right-addon justify-content-center">
                <input [type]="showPassword ? 'text' : 'password'" class="input-underline input-lg"
                  [placeholder]="passwordExpired ? 'Old Password' : 'Password'" formControlName="password" (keyup)="toggleShow(true)">
                <i class="icon icon-eye" aria-hidden="true" (click)="toggleShow()"
                  *ngIf="showPassword && (formGroup.controls['password'].dirty && formGroup.value.password !== '')"></i>
                <i class="icon icon-eye-blocked" aria-hidden="true" (click)="toggleShow()"
                  *ngIf="!showPassword && (formGroup.controls['password'].dirty && formGroup.value.password !== '')"></i>
              </div>
            </div>
            <div class="form-group" *ngIf="passwordExpired || isFirstSignIn">
              <div class="input-group password-hide-show inner-addon right-addon justify-content-center">
                <input [type]="showNewPassword ? 'text' : 'password'" class="input-underline input-lg" [ngStyle]="{ 'width': isFirstSignIn ? '365px' : null}" placeholder="Enter New Password"
                  tooltip="" appExtendedFormControl formControlName="newPassword" (keyup)="toggleNewPassShow(true)">
                <i class="icon icon-eye" aria-hidden="true" (click)="toggleNewPassShow()"
                  *ngIf="showNewPassword && (formGroup.controls['newPassword'].dirty && formGroup.value.newPassword !== '')"></i>
                <i class="icon icon-eye-blocked" aria-hidden="true" (click)="toggleNewPassShow()"
                  *ngIf="!showNewPassword && (formGroup.controls['newPassword'].dirty && formGroup.value.newPassword !== '')"></i>
              </div>
            </div>
            <div class="form-group" *ngIf="passwordExpired || isFirstSignIn">
              <div class="input-group password-hide-show inner-addon right-addon justify-content-center">
                <input [type]="showConfirmPassword ? 'text' : 'password'" class="input-underline input-lg" [ngStyle]="{ 'width': isFirstSignIn ? '365px' : null}"
                  placeholder="Confirm New Password" tooltip="" appExtendedFormControl formControlName="confirmPassword"
                  (keyup)="toggleConfirmPassShow(true)">
                <i class="icon icon-eye" aria-hidden="true" (click)="toggleConfirmPassShow()"
                  *ngIf="showConfirmPassword && (formGroup.controls['confirmPassword'].dirty && formGroup.value.confirmPassword !== '')"></i>
                <i class="icon icon-eye-blocked" aria-hidden="true" (click)="toggleConfirmPassShow()"
                  *ngIf="!showConfirmPassword && (formGroup.controls['confirmPassword'].dirty && formGroup.value.confirmPassword !== '')"></i>
              </div>
            </div>
            <div class="form-group" *ngIf="isFirstSignIn">
              <div class="input-group justify-content-center">
              <span class="password-info" style="padding: 10px 15px; max-width: 365px; margin: auto; text-align: left;"> {{passwordPolicy?.policyDescription}} </span>
            </div>
            </div>
            <div>
              <button type="submit" class="btn login-btn" *ngIf="!isFirstSignIn" [disabled]="!formGroup.valid || isLoggingIn"
                (click)="logIn()"> Login </button>
              <button type="submit" class="btn login-btn" *ngIf="isFirstSignIn" style="width: '150px'; margin-top: 20px;" (click)="changePassword()"
                [disabled]="(formGroup.value.newPassword === '' || formGroup.value.newPassword === null) || (formGroup.value.confirmPassword === '' || formGroup.value.confirmPassword === null)"> Change Password
              </button>
            </div>

          </div>
          <label *ngIf="!logInError" class="copyright mt-5">{{copyright}}</label>
          <label *ngIf="logInError" class="copyright mt-5">{{copyright}}</label>
        </form>
      </div>
    </div>
  </div>
</div>

