/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SharedModule } from '../../shared/shared.module';
import { PlaygroundDataTableComponent } from './data-table.component';
import { PlaygroundFormsComponent } from './forms.component';
import { PlaygroundNotificationsComponent } from './notifications.component';
import { PlaygroundTreeViewComponent } from './tree-view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TooltipModule
  ],
  declarations: [
    PlaygroundFormsComponent,
    PlaygroundNotificationsComponent,
    PlaygroundDataTableComponent,
    PlaygroundTreeViewComponent
  ],
  providers: []
})
export class PlaygroundModule {}
