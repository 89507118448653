<div class="container-fluid page-container">
  <div class="row align-items-center">
    <div class="col">
      <h3>{{'systemSettings.customReports.reportManagement' | translate}}</h3>
    </div>
    <div class="col-auto">
      <a routerLink="edit" class="btn btn-sm btn-primary">
        {{'systemSettings.customReports.createReport' | translate}}
      </a>
    </div>
  </div>

  <p>
    {{'systemSettings.customReports.reportManagementDescription' | translate}}
  </p>

  <app-data-table *ngIf="reportList" [data]="reportList" [dataMapFn]="reportDataMap" sortBy="name"
    [noDataAlertMessage]="'systemSettings.customReports.noCustomReports' | translate">
    <app-data-table-column [title]="'common.name' | translate" property="name" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'common.description' | translate" property="description" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-report="row">
        <button class="btn btn-sm btn-icon" (click)="editReport(report)">
          <i class="icon icon-edit"></i>
        </button>
        <button class="btn btn-sm btn-icon" (click)="deleteReport(report)">
          <i class="icon icon-trash"></i>
        </button>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
