<app-data-table [data]="tableDetails.triggers" sortBy="trigname"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.triggers' | translate">
  <app-data-table-column [title]="'common.name' | translate" property="trigname" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.event' | translate" property="event"
    [sortable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.action' | translate" property="action"
    [sortable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.enabled' | translate" property="status">
    <ng-template appTableCell let-status="item">
      <app-status-icon [status]="status === 'E'"></app-status-icon>
    </ng-template>
  </app-data-table-column>
</app-data-table>
