/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { InformixServer } from './informixServer';

export class ServerBreadcrumb {

  public static build(server: InformixServer, extraElements: BreadcrumbElement[] = []): BreadcrumbElement[] {
    const breadcrumbElements: BreadcrumbElement[] = [];

    breadcrumbElements.push({
      name: server.alias,
      link: extraElements.length < 1 ? null : '/dashboard/servers/' + server.id,
    });

    extraElements.forEach(elem => {
      let link = elem.link;
      if (link) {
        if (typeof link !== 'function') {
          link = '/dashboard/servers/' + server.id + '/' + elem.link;
        }
      }
      breadcrumbElements.push({
        name: elem.name,
        link,
      });
    });

    return breadcrumbElements;
  }
}
