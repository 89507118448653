/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractAlertConfigComponent } from './alertConfig';

@Component({
  selector: 'app-email-config',
  templateUrl: `emailConfig.html`
})
export class EmailConfigComponent extends AbstractAlertConfigComponent {

  private config: any;
  formGroup: UntypedFormGroup = null;
  showPassword: Boolean = false;

  public getType() {
    return 'email';
  }

  public setConfig(config: any) {
    if (!config) {
      config = {};
    }
    this.config = config;

    if (!this.formGroup) {
      this.formGroup = new UntypedFormGroup({
        host: new UntypedFormControl(config.host, [Validators.required]),
        port: new UntypedFormControl(config.port, [Validators.required]),
        security: new UntypedFormControl(config.security ? config.security : 'ssl', [Validators.required]),
        user: new UntypedFormControl(config.user),
        password: new UntypedFormControl(config.password),
        from: new UntypedFormControl(config.from)
      });
    }
  }

  public getConfig() {
    return this.formGroup.value;
  }

  public isValid(): boolean {
    return this.formGroup && this.formGroup.valid;
  }

  public isModified(): boolean {
    const form = this.formGroup.value;
    return (this.config.host !== form.host
      || this.config.port !== form.port
      || this.config.user !== form.user
      || this.config.security !== form.security
      || this.config.password !== form.password
      || this.config.from !== form.from);
  }

  toggleShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.password.value === '') {
        this.showPassword = false;
      }
      return;
    }
    this.showPassword = !this.showPassword;
  }
}
