/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Color } from '../color';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnChanges {

  private isValueMouseDown = false;
  private isHueMouseDown = false;

  private valueContainerPos = { x: 0, y: 0 };
  value = { x: 0, y: 0 };

  private hueContainerY = 0;
  hue = 0;

  valueBgColor = 'rgb(255, 0, 0)';

  rgbFormGroup: UntypedFormGroup;
  hexFormControl: UntypedFormControl;

  @Input() color = new Color('#FFF');
  @Output() colorChanged = new EventEmitter<Color>();

  constructor() { }

  ngOnInit() {
    this.hexFormControl = new UntypedFormControl(this.color.getHex(), [Validators.required, Validators.pattern(/^([0-F]{6}|[0-F]{3})$/i)]);

    const { r, g, b } = this.color.getRGB();
    this.rgbFormGroup = new UntypedFormGroup({
      r: new UntypedFormControl(r, Validators.required),
      g: new UntypedFormControl(g, Validators.required),
      b: new UntypedFormControl(b, Validators.required)
    });

    this.hexFormControl.valueChanges.subscribe(value => {
      if (this.hexFormControl.valid) {
        this.color = new Color('#' + value);
        this.updateValueAndHuePickers();
        this.updateRgbFormGroup();
        this.colorChanged.emit(this.color);
      }
    });

    this.rgbFormGroup.valueChanges.subscribe(() => {
      if (this.rgbFormGroup.valid) {
        this.color = new Color(this.rgbFormGroup.value);
        this.updateValueAndHuePickers();
        this.updateHexFormControl();
        this.colorChanged.emit(this.color);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.color && this.color) {
      this.updateValueAndHuePickers();
      this.updateHexFormControl();
      this.updateRgbFormGroup();
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isValueMouseDown) {
      this.updateValue(event.clientX - this.valueContainerPos.x, event.clientY - this.valueContainerPos.y);
      event.preventDefault();
    } else if (this.isHueMouseDown) {
      this.updateHue(event.clientY - this.hueContainerY);
      event.preventDefault();
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isValueMouseDown = false;
    this.isHueMouseDown = false;
  }

  colorValueMouseDown(event: MouseEvent) {
    const targetRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    if (event.button === 0) {
      this.valueContainerPos.x = targetRect.left;
      this.valueContainerPos.y = targetRect.top;
      this.isValueMouseDown = true;
      this.updateValue(event.clientX - this.valueContainerPos.x, event.clientY - this.valueContainerPos.y);
    }
  }

  colorHueMouseDown(event: MouseEvent) {
    const targetRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    if (event.button === 0) {
      this.hueContainerY = targetRect.top;
      this.isHueMouseDown = true;
      this.updateHue(event.clientY - this.hueContainerY);
    }
  }

  private clamp(value: number, min: number, max: number): number {
    return (value < min ? min : (value > max ? max : value));
  }

  private updateValue(x: number, y: number) {
    this.value.x = this.clamp(x * 2, 0, 255);
    this.value.y = this.clamp(y * 2, 0, 255);
    this.updateColor();
    this.colorChanged.emit(this.color);
  }

  private updateHue(y: number) {
    this.hue = this.clamp(y / 128 * 360, 0, 360);
    this.updateBackgroundColor();
    this.updateColor();
    this.colorChanged.emit(this.color);
  }

  private updateBackgroundColor() {
    const bgColor = new Color({ h: this.hue, s: 1, v: 1 }).getRGB();
    this.valueBgColor = 'rgb(' + bgColor.r + ',' + bgColor.g + ',' + bgColor.b + ')';
  }

  private updateColor() {
    const s = this.value.x / 255;
    const v = 1.0 - this.value.y / 255;
    this.color = new Color({ h: this.hue, s, v });
    this.updateHexFormControl();
    this.updateRgbFormGroup();
  }

  private updateValueAndHuePickers() {
    const { h, s, v } = this.color.getHSV();
    this.hue = h;
    this.value = {
      x: this.clamp(Math.round(s * 255), 0, 255),
      y: 255 - this.clamp(Math.round(v * 255), 0, 255)
    };
    this.updateBackgroundColor();
  }

  private updateHexFormControl() {
    if (this.hexFormControl) {
      this.hexFormControl.setValue(this.color.getHex(), { emitEvent: false });
    }
  }

  private updateRgbFormGroup() {
    if (this.rgbFormGroup) {
      this.rgbFormGroup.setValue(this.color.getRGB(), { emitEvent: false });
    }
  }
}
