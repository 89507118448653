/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { AbstractDashboardComponent } from '../../monitoring/abstract-dashboard-component';
import { InformixServerSystemService } from './informixServerSystem.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-os-info',
  templateUrl: 'os-info.html'
})
export class OSInfoComponent extends AbstractDashboardComponent implements OnInit {

  osInfo: Promise<any>;

  dataLoadErrorMessage: string = null;

  constructor(private systemService: InformixServerSystemService) {
    super();
  }

  ngOnInit() {
    if (this.server.hasMonitorPassword) {
      this.osInfo = this.systemService.getServerSystemInfo(this.server.id).catch((err: HttpErrorResponse) => {
        this.dataLoadErrorMessage =  err.error ? err.error.err : 'An error occurred';
      });
    }
  }
}
