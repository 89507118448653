/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { SharedModule } from '../../../../shared/shared.module';
import { MonitoringModule } from '../../../monitoring/monitoring.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { InformixServerSessionsService } from './informixServerSessions.service';
import { SessionInfoComponent } from './session-info.component';
import { SessionInfoProfileStatsComponent } from './session-info-profile-stats.component';
import { SessionsListComponent } from './sessions-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    MonitoringModule,
    RouterModule,
    BsDropdownModule,
    ProgressbarModule,
    TooltipModule
  ],
  exports: [],
  declarations: [
    SessionInfoComponent,
    SessionInfoProfileStatsComponent,
    SessionsListComponent
  ],
  providers: [
    InformixServerSessionsService
  ],
})
export class SessionsModule { }
