/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-admin-api-data-table',
  templateUrl: 'admin-api-data-table.html'
})
export class AdminAPIDataTableComponent {

  @Input() adminAPICommands: any[];
  @Input() showSearch = true;
}
