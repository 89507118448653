<div *ngIf="rawData.length > 0" class="data-table"
  [ngStyle]="{'min-height': multiselect?.dropdown.isOpen ? '250px' : 'auto' }">
  <fieldset class="mb-2 mt-2" *ngIf="useSearch">
    <div class="input-group inner-addon left-addon">
      <i class="icon icon-search"></i>
      <input type="text" class="form-control search-input" [placeholder]="searchLabel" [formControl]="searchControl">
      <button class="close input-clear" [hidden]="!searchControl.value" (click)="searchControl.setValue('')"
        [ngClass]="{'btn-margin-right': enableFilter}">
        <i class="icon icon-times"></i>
      </button>
      <div class="input-group-append">
        <app-dropdown-multi-select #multiselect *ngIf="enableFilter" maxHeight="200px" minWidth="14rem"
          [data]="columnsTitle" label="Columns" (valueChanged)="filterColumns($event)"></app-dropdown-multi-select>
      </div>
    </div>
  </fieldset>
  <div *ngIf="searchStatusMsg" class="alert alert-warning mt-2 pl-1">
    <i class="icon icon-warning text-warning mr-2 pl-1"></i>To search either of keywords use <span class="p-1">" , "</span> as operator or to search all of keywords use <span class="p-1">" + "</span> operator . Combination of both operators <span class="p-1">" , "</span> and <span class="p-1">" + "</span> is not allowed.
    <button class="close input-clear" (click)="searchStatusMsg = !searchStatusMsg"
      [ngClass]="{'btn-margin-right': enableFilter}">
      <i class="icon icon-times"></i>
    </button>
  </div>
  <div class="table-border table-responsive mb-3" #dataTableRef [style.height]="dataTableMinHeight" [ngClass]="{'sticky-table': enableStickyHeader}">
      <table class="table mb-0">
        <thead>
          <tr>
            <th *ngIf="enableExpand" [ngClass]="{'sticky-header': enableStickyHeader}" style="width: 30px;"></th>
            <th *ngIf="enableSelect" [ngClass]="{'sticky-header': enableStickyHeader}" style="width: 40px;">
              <app-checkbox (click)="onSelectAll()" *ngIf="!enableSingleSelect"
                [checked]="searchedData.length > 0 && searchedData.length === (selectedCount + disableCount) && disableCount !== searchedData.length">
              </app-checkbox>
            </th>
            <th *ngFor="let column of filteredColumns" (click)="onColumnHeaderClick(column)"
              [ngClass]="[(enableStickyHeader ? 'sticky-header' : ''), (column.headerClass ? column.headerClass : '')]">
              {{column.title}}
              <ng-template [ngIf]="column.sortable">
                <i class="icon" [ngClass]="getSortIconClass(column)"></i>&nbsp;
              </ng-template>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="searchedData.length > 0; else noRecordsFound">
            <ng-template ngFor let-row [ngForOf]="pagedData" let-i="index">
                <tr [ngClass]="{'table-active': row.selected}">
                  <td *ngIf="enableExpand">
                    <i *ngIf="row.expanded !== null" class="icon font-weight-bold"
                      [ngClass]="{'icon-chevron-down': row.expanded === true, 'icon-chevron-right': row.expanded === false}"
                      (click)="onRowExpand(row)"></i>
                  </td>
                  <td *ngIf="enableSelect && !enableSingleSelect">
                    <app-checkbox [checked]="row.selected" [disabled]="row.disabled" (click)="onRowSelect($event, row, i)">
                    </app-checkbox>
                  </td>
                  <td *ngIf="enableSelect && enableSingleSelect">
                    <app-radio [checked]="row.selected" [disabled]="row.disabled" (click)="onSingleSelect(row)">
                    </app-radio>
                  </td>
                  <td *ngFor="let column of filteredColumns" [class.cursor-pointer]="enableSingleSelect"
                    [ngClass]="column.class" [ngStyle]="{width: column.width}" (click)="onSingleSelect(row)">
                    <ng-template [ngIf]="column.cellTemplate" [ngTemplateOutlet]="column.cellTemplate"
                      [ngTemplateOutletContext]="{item: row.data[column.property], row: row.data}"></ng-template>
                    <ng-template [ngIf]="!column.cellTemplate">{{row.data[column.property]}}</ng-template>
                  </td>
                </tr>
                <tr *ngIf="isRowExpanded(row)">
                  <td [colSpan]="getColumnCount()" [ngClass]="{'p-0': noTable != null}">
                    <ng-template [ngTemplateOutlet]="expandDirective.expandTemplate"
                      [ngTemplateOutletContext]="{row: row.data, dataTableRow: row}"></ng-template>
                  </td>
                </tr>
            </ng-template>
          </ng-container>
          <ng-template #noRecordsFound>
            <tr>
              <td [attr.colspan]="enableSelect ? filteredColumns.length + 1 : filteredColumns.length" class="p-0">
                <div class="alert alert-info mb-0">
                  <i class="icon icon-info-circle text-info"></i>
                  No Records Found.
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
  </div>
  <div class="row no-gutters mt-3" *ngIf="usePagination && searchedData.length > 10"
    [ngClass]="{'fixed-pagination': enableFixedPagination}">
    <div class="col">
      <pagination class="pagination-sm" [totalItems]="searchedData.length" [ngModel]="currentPage"
        [itemsPerPage]="rowsPerPage" [boundaryLinks]="enablePaginationBoundaryLinks" [maxSize]="maxPagingButtons"
        [rotate]="false" (pageChanged)="onPageChange($event)">
      </pagination>
    </div>
    <div class="col col-sm-12 col-md-auto" *ngIf="rowsPerPageOptions">
      Rows per page:
      <select class="form-control form-control-sm" style="display: inline-block; width: initial" [ngModel]="rowsPerPage"
        (ngModelChange)="rowsPerPageChange($event)">
        <option *ngFor="let option of rowsPerPageOptions" [ngValue]="option">{{option}}</option>
        <option [ngValue]="searchedData.length">All</option>
      </select>
    </div>
  </div>
</div>
<div class="alert alert-{{noDataAlertType}}" *ngIf="rawData.length < 1">
  <i class="icon icon-{{noDataAlertType}} icon-{{noDataAlertType}}-circle text-{{noDataAlertType}}"></i>
  {{noDataAlertMessage}}
</div>
