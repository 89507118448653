<app-data-table [data]="adminAPICommands" sortBy="cmd_exec_time" sortDescending="true" [rowsPerPage]="20"
  searchLabel="Search command or result message ( Use ' , ' OR ' + ' operator for multiple keyword search )." noDataAlertMessage="No Admin API command logs available.">
  <app-data-table-column title="User" property="cmd_user" [sortable]="true">
    <ng-template appTableCell let-row="row">
      {{row.cmd_user}} @ {{row.cmd_hostname}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column title="Time" property="cmd_exec_time" [sortable]="true">
    <ng-template appTableCell let-item="item">
      {{item | date:'y-MM-dd HH:mm:ss'}}
    </ng-template>
  </app-data-table-column>
  <app-data-table-column title="Command Executed" property="cmd_executed" [sortable]="true" [searchable]="showSearch">
  </app-data-table-column>
  <app-data-table-column title="Result" property="cmd_ret_status" [sortable]="true">
    <ng-template appTableCell let-item="item">
      <i class="icon icon-times text-danger" *ngIf="item < 0"></i>
      <i class="icon icon-check text-success" *ngIf="item >= 0"></i>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column title="Result Message" property="cmd_ret_msg" [sortable]="true" [searchable]="showSearch">
  </app-data-table-column>
</app-data-table>
