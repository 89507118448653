/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/

/**
 * This component file will display the Stored-Procedures present in database selected by the user in Schema Manager.
 */
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InformixSQLSession } from '../informix-sql-session';
import { SchemaService } from '../schema.service';
import { InformixServer } from '../../informixServer';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import sqlFormatter from '@sqltools/formatter';

@Component({
  selector: 'app-database-procedure',
  templateUrl: './database-procedure.component.html',
  styleUrls: ['../database/database.component.scss']
})
export class DatabaseProcedureComponent implements OnInit {

  @Input() session: InformixSQLSession;
  @Input() server: InformixServer = null;
  @Input() currentDB: string;
  systemDatabases = new Set(['sysmaster', 'sysadmin', 'sysutils', 'sysuser', 'syscdr', 'sysha']);
  dbProcedure: any;
  filteredDbProcedure: any;
  dbProcedureId: any;
  currentProcName = '';
  tableFilter = 'all';
  disableFilter: Boolean = false;
  @ViewChild('procedureModal') procedureModal: ModalDirective;
  dataLoading = false;
  serverError: string;

  constructor(private schemaService: SchemaService, private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.getDbProcedure('procedures');
  }

  /**
   * This function will call the Procedure API and store the response in dbProcedure.
   */
  private getDbProcedure(tabName: string): void {
    this.dataLoading = true;
    this.schemaService.getDatabaseTabData(this.session, this.server, tabName)
      .subscribe(res => {
        if (this.systemDatabases.has(this.currentDB)) {
          this.tableFilter = 'system';
          this.disableFilter = true;
        }
        this.dbProcedure = res;
        this.filteredDbProcedure = res;
        this.dataLoading = false;
      },
        err => {
          this.dataLoading = false;
          this.serverError = err.error ? err.error.err : err;
        });
  }
  /**
   *
   * @param filter parameter is to know that selected filter type: all/user defined/system defined
   */
  changeTableFilter(filter: string) {
    this.tableFilter = filter;
    if (this.tableFilter !== 'all') {
      this.filteredDbProcedure = this.dbProcedure.filter(item => {
        const filterValue = this.tableFilter === 'system' ? item.modeabbr.toLowerCase() : item.modeabbr.toUpperCase();
        return item.modeabbr === filterValue;
      });
    } else {
      this.filteredDbProcedure = this.dbProcedure;
    }
  }

  openProDetails(id: number, name: string) {
    this.schemaService.getProcedureByID(this.server, this.session.database.name, this.session.id, 'procedures', id)
      .subscribe(res => {
        res.sql_alter = this.formatSQL(res.sql_alter);
        res.sql_create = this.formatSQL(res.sql_create);
        this.dbProcedureId = res;
      },
        err => this.notificationsService.pushErrorNotification(err.error ? err.error.err : err));
    this.currentProcName = name;
    this.procedureModal.show();
  }

  closeModal() {
    this.procedureModal.hide();
  }

  /**
   * Format the SQL
   *
   * @param sqlStr Sql string
   * @returns
   */
  formatSQL(sqlStr: string): string {
    return sqlFormatter.format(sqlStr, { language: 'sql', linesBetweenQueries: 2, reservedWordCase: 'upper'});
  }
}
