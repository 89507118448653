/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { SchemaService } from '../schema.service';
import { InformixServer } from '../../informixServer';
import { ColumnModalComponent } from './column-modal.component';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { InformixSQLSession } from '../informix-sql-session';
import { pairwise, startWith } from 'rxjs/operators';
import { IACValidators } from '../../../../shared/forms/validators';
import { ErrorMessageHandlers } from '../../../../shared/forms/extendedFormControl.directive';
import { Table } from './create-table.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-table',
  templateUrl: './create-table.component.html',
  styleUrls: ['./create-table.component.scss']
})
export class CreateTableComponent implements OnInit {
  tableData: any;
  isCreateQuery = false;
  viewTableAdvOpt = false;
  dataObj: Table;
  tableAdvObj: any;
  tableList = [];
  allTables = [];
  createTableForm: UntypedFormGroup;
  tableType: UntypedFormControl;
  tableName: UntypedFormControl;
  tableOwner: UntypedFormControl;
  isCreateTableFlag: UntypedFormControl;
  tableNameSameAs: UntypedFormControl;
  tableConstraint: UntypedFormControl;
  constraintText: UntypedFormControl;
  columnNames = [];
  tableNameErrorHandlers: ErrorMessageHandlers = {};

  @Input() session: InformixSQLSession;
  @Input() server: InformixServer;
  @Input() database: any;
  @Output() actionComplete = new EventEmitter<any>();
  @ViewChild(ColumnModalComponent) columnModalComponent: ColumnModalComponent;

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private schemaService: SchemaService,
    private confirmationDialogService: ConfirmationDialogService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.translate.get('schemaManager.createTable.errorMsg.duplicateTableNameError').subscribe((text: string) => {
      this.tableNameErrorHandlers.duplicateName = error => text;
    });
    this.translate.get('schemaManager.createTable.errorMsg.whitespaceError').subscribe((text: string) => {
      this.tableNameErrorHandlers.whitespace = error => text;
    });

    this.route.data.subscribe(data => {
      this.server = data.server;
      this.tableData = [];
    });
    this.tableType = new UntypedFormControl('standard', [Validators.required]);
    this.tableName = new UntypedFormControl(null, [Validators.required, this.duplicateTableNameValidator(),
    IACValidators.noWhitespaceValidator(), IACValidators.informixIdentifierValidator]);
    this.tableOwner = new UntypedFormControl('informix', [Validators.required]);
    this.isCreateTableFlag = new UntypedFormControl(false);
    this.tableNameSameAs = new UntypedFormControl(null);
    this.tableConstraint = new UntypedFormControl(false);
    this.constraintText = new UntypedFormControl(null);
    this.createTableForm = this.fb.group({
      tableName: this.tableName,
      tableOwner: this.tableOwner,
      tableType: this.tableType,
      isCreateTableFlag: this.isCreateTableFlag,
      tableNameSameAs: this.tableNameSameAs,
      tableColumnsList: this.fb.array([]),
      primaryKey: new UntypedFormControl(null),
      tableConstraint: this.tableConstraint,
      constraintText: this.constraintText
    });

    this.schemaService.getTables(this.session).subscribe(res => {
      this.allTables = res;
    });

    this.tableType.valueChanges.pipe(startWith('standard'), pairwise()).subscribe(([prev, next]) => {
      this.tableAdvObj = null;
      if (next === 'standard' || next === 'raw') {
        this.tableData.forEach(col => {
          if (col['externalCharacterLength']) {
            delete col['externalCharacterLength'];
          }
        });
      }
      if (next === 'externalfixed') {
        this.tableData.forEach(col => {
          col['externalCharacterLength'] = 32;
        });
      }
      if ((next === 'externalfixed' || next === 'externalinformix') && this.tableData.filter(col => col.columnDataType === 'blob' ||
        col.columnDataType === 'clob' || col.columnDataType === 'text' || col.columnDataType === 'byte').length > 0) {
        const msg = `continue? This action changes the table type to a type that does not support columns with the data types
       BLOB, CLOB, TEXT, and BYTE. Changing the table type also deletes the BLOB, CLOB, TEXT, and BYTE columns.`;
        this.confirmationDialogService.show(msg, () => {
          this.updateCol(next);
        }, () => {
          this.tableType.setValue(prev);
        });
      }
      this.isCreateTableFlag.setValue(false);
      if (next === 'externaldelimited' || next === 'externalinformix') {
        this.tableList = this.allTables.filter(table => table.type !== 'E' && table.id >= 100);
        this.tableNameSameAs.setValue(this.tableList[0].name);
      }
    });

    this.isCreateTableFlag.valueChanges.subscribe(value => {
      if (value) {
        this.tableNameSameAs.enable();
      } else {
        this.tableNameSameAs.disable();
      }
    });
  }

  duplicateTableNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.allTables && this.allTables.map(table => table.name).indexOf(control.value) > -1) {
        isValid = false;
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

  updateCol(type: string) {
    if (type === 'externalfixed' || type === 'externalinformix') {
      this.tableData = this.tableData.filter(col => col.columnDataType !== 'blob' && col.columnDataType !== 'clob' &&
        col.columnDataType !== 'text' && col.columnDataType !== 'byte');
    }
    setTimeout(() => {
      this.tableData = this.tableData.slice();
      this.columnNames = this.tableData.map(col => col.tableColumnName);
    }, 0);
  }

  backToCreateTable(eve) {
    if (eve === 'cancel') {
      this.actionComplete.emit();
    } else {
      this.viewTableAdvOpt = false;
    }
  }

  getAdvTableObj(e) {
    this.tableAdvObj = e;
  }

  openEdit(row) {
    const index = this.tableData.indexOf(row);
    this.openCreateColumnModal(index, row);
  }

  confirmDelete(row) {
    let msg = null;
    if (this.dataObj && ((this.dataObj['primaryKey'] && this.dataObj['primaryKey'].primaryKeyColumnName
      .indexOf(row.tableColumnName) > -1) || (this.dataObj['uniqueKey'] && this.dataObj['uniqueKey'].filter(key => key.uniquekeyColumnName
        .indexOf(row.tableColumnName) > -1).length > 0) || (this.dataObj['foreignKey'] && this.dataObj['foreignKey']
          .filter(key => Object.values(key.foreignKeyReferences).indexOf(row.tableColumnName) > -1).length > 0))) {
      msg = 'This action deletes a column that is part of one or more primary, foreign or unique key constraints.' +
        ' Deleting the column also deletes the primary, foreign, and unique key constraints that the column is part of.';
    }
    if (this.dataObj && this.dataObj['storageScheme'] && this.dataObj['storageScheme'].fragmentKey &&
      this.dataObj['storageScheme'].fragmentKey === row.tableColumnName) {

      msg = msg ? ('This action deletes a column that is part of one or more primary, \
                    foreign or unique key constraints and used as a fragment key.' +
        ' Deleting the column also deletes the fragmentation strategy and the primary, foreign, and \
        unique key constraints that the column is part of.') :
        (' This action deletes a column that is used as a fragment key. Deleting the column also deletes the ' +
          'fragmentation strategy that the column is part of.');
    }
    this.confirmationDialogService.show('Delete Column', msg ? ('continue? ' + msg) : 'continue?', () => this.deleteColumn(row));
  }

  deleteColumn(row) {
    if (this.dataObj && this.dataObj['storageScheme'] && this.dataObj['storageScheme'].fragmentKey &&
      this.dataObj['storageScheme'].fragmentKey === row.tableColumnName) {
      delete this.tableAdvObj['storageScheme'];
    }
    if (this.dataObj && this.dataObj['primaryKey'] && this.dataObj['primaryKey'].primaryKeyColumnName.indexOf(row.tableColumnName) > -1) {
      delete this.tableAdvObj['primaryKey'];
    }
    if (this.dataObj && this.dataObj['uniqueKey'] && this.dataObj['uniqueKey'].filter(key => key.uniquekeyColumnName
      .indexOf(row.tableColumnName) > -1).length > 0) {
      this.dataObj['uniqueKey'].forEach((key, i) => {
        if (key.uniquekeyColumnName.indexOf(row.tableColumnName) > -1) {
          this.tableAdvObj['uniqueKey'].splice(i, 1);
        }
      });
      if (this.tableAdvObj['uniqueKey'].length === 0) {
        delete this.tableAdvObj['uniqueKey'];
      }
    }
    if (this.dataObj && this.dataObj['foreignKey'] && this.dataObj['foreignKey'].filter(key => Object.values(key.foreignKeyReferences)
      .indexOf(row.tableColumnName) > -1).length > 0) {
      this.dataObj['foreignKey'].forEach((key, i) => {
        if (Object.values(key.foreignKeyReferences).indexOf(row.tableColumnName) > -1) {
          this.tableAdvObj['foreignKey'].splice(i, 1);
        }
      });
      if (this.tableAdvObj['foreignKey'].length === 0) {
        delete this.tableAdvObj['foreignKey'];
      }
    }
    const index = this.tableData.map(obj => obj.tableColumnName).indexOf(row.tableColumnName);
    this.tableData.splice(index, 1);
    setTimeout(() => {
      this.tableData = this.tableData.slice();
      this.columnNames = this.tableData.map(col => col.tableColumnName);
    }, 0);
  }

  addColumnsModal() {
    const lastIndex = this.tableData.length;
    this.openCreateColumnModal(lastIndex);
  }

  openCreateColumnModal(index, row?: any) {
    this.columnModalComponent.openColumnModal(index, this.tableType.value, this.tableAdvObj, row);
  }

  viewCreateQuery() {
    this.isCreateQuery = true;
    this.viewTableAdvOpt = false;
  }

  next() {
    this.dataObj = this.generateFinalObject();
    this.viewTableAdvOpt = true;
    this.isCreateQuery = false;
  }

  addCol(eve) {
    const attributes = {
      length: eve.length ? eve.length : null,
      precisionDigit: eve.precisionDigit ? eve.precisionDigit : null,
      scale: eve.scale ? eve.scale : null,
      storeColumnIn: eve.storeColumnIn ? eve.storeColumnIn : null,
      extentSize: eve.extentSize ? eve.extentSize : null,
      turnOnLogging: eve.turnOnLogging ? eve.turnOnLogging : null,
      recordAccessTime: eve.recordAccessTime ? eve.recordAccessTime : null,
      highDataIntegrity: eve.highDataIntegrity ? eve.highDataIntegrity : null,
      startValue: eve.startValue ? eve.startValue : null,
      largestTimeUnit: eve.largestTimeUnit ? eve.largestTimeUnit : null,
      smallestTimeUnit: eve.smallestTimeUnit ? eve.smallestTimeUnit : null,
      namedRowType: eve.namedRowType,
      namedRowDataLength: eve.namedRowDataLength,
      defaultYearValue: eve.defaultYearValue,
      defaultMonthValue: eve.defaultMonthValue,
      defaultDayValue: eve.defaultDayValue,
      defaultHourValue: eve.defaultHourValue,
      defaultMinuteValue: eve.defaultMinuteValue,
      defaultSecondValue: eve.defaultSecondValue,
      defaultFractionValue: eve.defaultFractionValue,
      noDefaultValue: eve.noDefaultValue,
      nullAsDefault: eve.nullAsDefault
    };
    const obj = {
      tableColumnName: eve.tableColumnName,
      columnDataType: eve.columnDataType,
      dataTypeAttributes: attributes,
      columnConstraint: eve.columnConstraint,
      constraintText: eve.constraintText,
      defaultNull: eve.nullValues,
      defaultValue: eve.defaultValue,
      externalCharacterLength: eve.externalCharacterLength,
      setDataType: eve.setDataType
    };
    this.tableData.push(obj);
    setTimeout(() => {
      this.tableData = this.tableData.slice();
      this.columnNames = this.tableData.map(col => col.tableColumnName);
    }, 0);
  }

  editCol(eve) {
    this.tableData.map((row, index) => {
      if (index === eve.index) {
        row.tableColumnName = eve.obj.tableColumnName;
        row.columnDataType = eve.obj.columnDataType;
        row.constraintText = eve.obj.constraintText;
        row.columnConstraint = eve.obj.columnConstraint;
        row.defaultNull = eve.obj.nullValues;
        row.defaultValue = eve.obj.defaultValue;
        row.externalCharacterLength = eve.obj.externalCharacterLength;
        row.setDataType = eve.obj.setDataType;
        const dataTypeAttributes = {
          length: eve.obj.length ? eve.obj.length : null,
          precisionDigit: eve.obj.precisionDigit ? eve.obj.precisionDigit : null,
          scale: eve.obj.scale ? eve.obj.scale : null,
          storeColumnIn: eve.obj.storeColumnIn ? eve.obj.storeColumnIn : null,
          extentSize: eve.obj.extentSize ? eve.obj.extentSize : null,
          turnOnLogging: eve.obj.turnOnLogging ? eve.obj.turnOnLogging : null,
          recordAccessTime: eve.obj.recordAccessTime ? eve.obj.recordAccessTime : null,
          highDataIntegrity: eve.obj.highDataIntegrity ? eve.obj.highDataIntegrity : null,
          startValue: eve.obj.startValue ? eve.obj.startValue : null,
          largestTimeUnit: eve.obj.largestTimeUnit ? eve.obj.largestTimeUnit : null,
          smallestTimeUnit: eve.obj.smallestTimeUnit ? eve.obj.smallestTimeUnit : null,
          namedRowType: eve.obj.namedRowType,
          namedRowDataLength: eve.obj.namedRowDataLength,
          defaultYearValue: eve.obj.defaultYearValue,
          defaultMonthValue: eve.obj.defaultMonthValue,
          defaultDayValue: eve.obj.defaultDayValue,
          defaultHourValue: eve.obj.defaultHourValue,
          defaultMinuteValue: eve.obj.defaultMinuteValue,
          defaultSecondValue: eve.obj.defaultSecondValue,
          defaultFractionValue: eve.obj.defaultFractionValue,
          noDefaultValue: eve.obj.noDefaultValue,
          nullAsDefault: eve.obj.nullAsDefault
        };
        row.dataTypeAttributes = dataTypeAttributes;
        return row;
      }
    });
    setTimeout(() => {
      this.tableData = this.tableData.slice();
      this.columnNames = this.tableData.map(col => col.tableColumnName);
    }, 0);
  }

  generateFinalObject() {
    const finalObj = {
      tableName: this.tableName.value.trim(),
      tableOwner: this.tableOwner.value,
      tableType: this.tableType.value,
      isExecute: false,
      isNewLine: false
    };

    if (this.tableType.value === 'standard' || this.tableType.value === 'raw') {
      finalObj['tableColumnsList'] = this.tableColumnObj();
      if (this.tableConstraint.value) {
        finalObj['tableConstraint'] = this.constraintText.value;
      }
    } else if (this.tableType.value === 'externalfixed') {
      finalObj['tableColumnsList'] = this.tableColumnObj();
    } else if (this.tableType.value === 'externaldelimited' || this.tableType.value === 'externalinformix') {
      finalObj['isCreateTableFlag'] = this.isCreateTableFlag.value;
      if (!this.isCreateTableFlag.value) {
        finalObj['tableColumnsList'] = this.tableColumnObj();
      } else {
        finalObj['tableNameSameAs'] = this.tableNameSameAs.value;
      }
    }
    if (this.tableAdvObj) {
      for (const key in this.tableAdvObj) {
        if (this.tableAdvObj.hasOwnProperty(key)) {
          finalObj[key] = this.tableAdvObj[key];
        }
      }
    }
    return finalObj;
  }

  tableColumnObj() {
    const array = [];

    this.tableData.forEach(row => {
      const column = {
        tableColumnName: row.tableColumnName,
        columnDataType: row.columnDataType
      };

      if (row.defaultNull !== null) {
        column['defaultNull'] = row.defaultNull;
      }

      if (row.constraintText) {
        column['columnConstraint'] = row.constraintText;
      }

      if (this.tableType.value === 'externalfixed') {
        column['externalCharacterLength'] = row.externalCharacterLength;
      }
      switch (row.columnDataType.toUpperCase()) {
        case 'BIGINT':
          column['defaultValue'] = row.defaultValue;
          break;
        case 'SMALLINT':
        case 'INTEGER':
        case 'FLOAT':
        case 'SMALLFLOAT':
          if (row.defaultValue === 'NULL') {
            column['defaultValue'] = row.defaultValue;
          } else if (row.defaultValue) {
            column['defaultValue'] = Number(row.defaultValue);
          }
          break;
        case 'BIGSERIAL':
        case 'SERIAL':
          column['dataTypeAttributes'] = {
            startValue: row.dataTypeAttributes.startValue
          };
          break;

        case 'CLOB':
        case 'BLOB':
          if (row.dataTypeAttributes.storeColumnIn) {
            column['dataTypeAttributes'] = {
              storeColumnIn: row.dataTypeAttributes.storeColumnIn,
              extentSize: row.dataTypeAttributes.extentSize,
              turnOnLogging: row.dataTypeAttributes.turnOnLogging,
              recordAccessTime: row.dataTypeAttributes.recordAccessTime,
              highDataIntegrity: row.dataTypeAttributes.highDataIntegrity
            };
          }
          break;
        case 'BOOLEAN':
          if (row.defaultValue === 'NULL') {
            column['defaultValue'] = null;
          } else {
            column['defaultValue'] = row.defaultValue === 'TRUE' ? true : false;
          }
          break;
        case 'BYTE':
        case 'TEXT':
          column['dataTypeAttributes'] = {
            storeColumnIn: row.dataTypeAttributes.storeColumnIn
          };
          break;
        case 'BSON':
        case 'JSON':
        case 'LONGLVARCHAR':
          if (row.defaultValue) {
            column['defaultValue'] = row.defaultValue;
          }
          break;
        case 'CHAR':
        case 'LVARCHAR':
        case 'NCHAR':
        case 'NVARCHAR':
        case 'VARCHAR':
          column['dataTypeAttributes'] = {
            length: row.dataTypeAttributes.length
          };
          if (row.defaultValue) {
            column['defaultValue'] = row.defaultValue;
          }
          break;
        case 'DATE':
          if (row.defaultValue === 'TODAY') {
            column['defaultValue'] = row.defaultValue;
          } else if (row.defaultValue === 'No default value') {
            column['defaultValue'] = 'NODEFAULT';
          } else if (row.defaultValue === 'NULL') {
            column['defaultValue'] = 'null';
          }
          break;
        case 'DATETIME':
          if (row.defaultValue === 'CURRENT') {
            column['defaultValue'] = row.defaultValue;
          } else if (row.defaultValue === 'No default value') {
            column['defaultValue'] = 'NODEFAULT';
          } else if (row.defaultValue === 'NULL') {
            column['defaultValue'] = 'null';
          }
          column['dataTypeAttributes'] = {
            largestTimeUnit: row.dataTypeAttributes.largestTimeUnit,
            smallestTimeUnit: row.dataTypeAttributes.smallestTimeUnit,
            scale: row.dataTypeAttributes.scale
          };
          break;
        case 'DECIMAL':
        case 'MONEY':
          if (row.defaultValue) {
            column['defaultValue'] = row.defaultValue;
          }
          column['dataTypeAttributes'] = {
            precisionDigit: row.dataTypeAttributes.precisionDigit,
            scale: row.dataTypeAttributes.scale
          };
          break;

        case 'INTERVAL':
          column['dataTypeAttributes'] = {
            largestTimeUnit: row.dataTypeAttributes.largestTimeUnit,
            smallestTimeUnit: row.dataTypeAttributes.smallestTimeUnit,
            precisionDigit: row.dataTypeAttributes.precisionDigit,
            scale: row.dataTypeAttributes.scale,
            defaultYearValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultYearValue : null,
            defaultMonthValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultMonthValue : null,
            defaultDayValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultDayValue : null,
            defaultHourValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultHourValue : null,
            defaultMinuteValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultMinuteValue : null,
            defaultSecondValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultSecondValue : null,
            defaultFractionValue: !row.dataTypeAttributes.noDefaultValue && !row.dataTypeAttributes.nullAsDefault ?
              row.dataTypeAttributes.defaultFractionValue : null,
            noDefaultValue: row.dataTypeAttributes.noDefaultValue,
            nullAsDefault: row.dataTypeAttributes.nullAsDefault
          };
          break;
        case 'LIST':
        case 'MULTISET':
        case 'SET':
          const dataTypeAttributes = {};
          if (row.setDataType === 'varchar' || row.setDataType === 'nvarchar' || row.setDataType === 'nchar' ||
            row.setDataType === 'lvarchar' || row.setDataType === 'char') {
            dataTypeAttributes['length'] = row.dataTypeAttributes.length;
          } else if (row.setDataType === 'money' || row.setDataType === 'decimal') {
            dataTypeAttributes['precisionDigit'] = row.dataTypeAttributes.precisionDigit;
            dataTypeAttributes['scale'] = row.dataTypeAttributes.scale;
          } else if (row.setDataType === 'interval' || row.setDataType === 'dateTime') {
            dataTypeAttributes['largestTimeUnit'] = row.dataTypeAttributes.largestTimeUnit;
            dataTypeAttributes['smallestTimeUnit'] = row.dataTypeAttributes.smallestTimeUnit;
            if (row.dataTypeAttributes.precisionDigit) {
              dataTypeAttributes['precisionDigit'] = row.dataTypeAttributes.precisionDigit;
            }
            if (row.dataTypeAttributes.scale) {
              dataTypeAttributes['scale'] = row.dataTypeAttributes.scale;
            }
          } else if ((row.setDataType === 'blob' || row.setDataType === 'clob') && row.dataTypeAttributes.storeColumnIn) {
            dataTypeAttributes['storeColumnIn'] = row.dataTypeAttributes.storeColumnIn;
            dataTypeAttributes['extentSize'] = row.dataTypeAttributes.extentSize;
            dataTypeAttributes['turnOnLogging'] = row.dataTypeAttributes.turnOnLogging;
            dataTypeAttributes['recordAccessTime'] = row.dataTypeAttributes.recordAccessTime;
            dataTypeAttributes['highDataIntegrity'] = row.dataTypeAttributes.highDataIntegrity;
          }
          column['setDataType'] = {
            columnDataType: row.setDataType,
            defaultNull: false,
            dataTypeAttributes
          };
          break;
        case 'NAMEDROW':
          if (row.defaultValue) {
            column['defaultValue'] = row.defaultValue;
          }
          column['dataTypeAttributes'] = {
            namedRowType: row.dataTypeAttributes.namedRowType,
            namedRowDataLength: row.dataTypeAttributes.namedRowDataLength,
          };
          break;
      }
      array.push(column);
    });
    return array;
  }

  isNumber(val): boolean {
    return typeof val === 'number';
  }
}
