/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021. All Rights Reserved.
 *******************************************************************************/
import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { HDRPermission } from './hdr-permission';

@Directive({
    selector: '[appHDRPermission]'
})
export class HDRPermissionDirective {

    private isAnchorEl = false;

    @Input() disabled: boolean;
    @Input() hdrPermission: HDRPermission;

    constructor(elementRef: ElementRef) {
        this.isAnchorEl = elementRef.nativeElement instanceof HTMLAnchorElement;
    }

    isDisabled() {
        return this.disabled || (this.hdrPermission && !this.hdrPermission.isAllow());
    }

    @HostBinding('attr.disabled')
    get attrDisabled() {
        return !this.isAnchorEl && this.isDisabled() ? true : null;
    }

    @HostBinding('class.disabled')
    get classDisabled() {
        return this.isAnchorEl && this.isDisabled();
    }
 }
