<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row mb-2">
    <div class="col">
      <h4 class="page-header">
        <strong>Backups</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="dataLoading"></i>
      </h4>
    </div>
    <div class="col-auto pr-0">
      <app-sensors-required-control [server]="server" [monitoringProfile]="monitoringProfile" [sensorList]="['backups']" (monitoringProfileUpdated)="getMonitoringProfile()"></app-sensors-required-control>
    </div>
    <div class="col-auto">
      <button *ngIf="server.hasMonitorPassword" id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData(true)">
        <i class="icon icon-refresh" title="Refresh"></i>
      </button>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger">
      Unable to retrieve server backup information. <br> Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>


  <app-tab-group (eventUpdate)="setSelectedTabIndex($event)" *ngIf="backupInfo">

    <app-tab label="Status">
      <app-backup-status *ngIf="selectedTabIndex === 0" [server]="server" [sensor]="backupSensor" [backupInfo]="backupInfo" [backupTasks]="backupTasksInfo" (backupInitiated)="refreshData(false)">
      </app-backup-status>
    </app-tab>

    <app-tab label="Configuration">
      <app-backup-config *ngIf="selectedTabIndex === 1" [server]="server" [(backupTasks)]="backupTasksInfo">
      </app-backup-config>
    </app-tab>

    <app-tab label="Logs">
      <app-backup-logs #backupLogs *ngIf="selectedTabIndex === 2" [server]="server">
      </app-backup-logs>
    </app-tab>

  </app-tab-group>

</div>
