/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { CanComponentDeactivate } from '../../shared/canDeactivateGuard.service';
import { MonitoringProfileComponent } from '../monitoring/monitoringProfile.component';
import { GroupBreadcrumb } from './groupBreadcrumb';
import { InformixServerGroup } from './informixServerGroup';

@Component( {
  selector: 'app-group-monitoring',
  templateUrl: 'groupMonitoring.html'
} )
export class GroupMonitoringComponent implements OnInit, CanComponentDeactivate {

  group: InformixServerGroup = null;
  breadcrumb: BreadcrumbElement[] = null;

  @ViewChild(MonitoringProfileComponent) monitoringProfileComponent: MonitoringProfileComponent;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadGroup(data.group);
    });
  }

  canDeactivate() {
    return this.monitoringProfileComponent ? this.monitoringProfileComponent.canDeactivate() : true;
  }

  private loadGroup(group: InformixServerGroup) {
    this.group = group;
    this.breadcrumb = GroupBreadcrumb.build(this.group, [{name: 'Monitoring'}]);
  }
}
