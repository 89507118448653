<div class="form">
  <div class="form-inline">
    Alert frequency:&nbsp;&nbsp;
    <div class="text-muted">Configure how often you are notified of alert violation incidents for the same alerting
      condition.</div>
  </div>
  <form [formGroup]="formGroup" class="ml-5">
    <app-radio-group formControlName="alwaysAlert">
      <div class="my-1">
        <app-radio [value]="true">Always alert me</app-radio>
      </div>
      <div class="form-inline my-1">
        <app-radio [value]="false">Alert me every</app-radio>
        <app-input-time class="ml-2" formControlName="timeout" min="0" [timeUnits]="timeoutTimeUnits" tooltip="" placement="right"
          appExtendedFormControl>
        </app-input-time>
      </div>
    </app-radio-group>
  </form>
</div>
