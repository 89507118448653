<table *ngIf="resultPage" class="table table-striped table-hover text-monospace">
  <thead>
    <tr>
      <th *ngFor="let columnName of resultPage.columnNames">
        {{columnName}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of resultPage.rows">
      <td *ngFor="let col of row.values">
        <span class="color-light-gray-60" *ngIf="col.err">{{col.err}}</span>
        <ng-container *ngIf="!col.err">
          <span class="color-light-gray-60" *ngIf="col.value === null">NULL</span>
          <ng-container *ngIf="col.value !== null">
            <div style="white-space: pre-wrap" *ngIf="!col.isJson">{{col.value}}</div>
            <app-json-explorer class="d-block json-column" *ngIf="col.isJson" [json]="col.value"></app-json-explorer>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
