/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Type } from '@angular/core';
import { AbstractDashboardPluginConfig } from './abstract-dashboard-plugin-config';
import { Subject } from 'rxjs';

export interface DashboardPlugin {
  id: string;
  module: Type<any>;
  component: Type<AbstractDashboardPlugin>;
  configComponent: Type<AbstractDashboardPluginConfig>;
}

export abstract class AbstractDashboardPlugin {
  private errorMessages: string[] = [];
  private warningMessages: string[] = [];

  errorsChanged = new Subject<string[]>();
  warningsChanged = new Subject<string[]>();

  abstract setConfig(config: any);

  protected setErrors(messages: string[]) {
    this.errorMessages = messages;
    this.errorsChanged.next(this.errorMessages.slice());
  }

  protected pushError(message: string) {
    this.errorMessages.push(message);
    this.errorsChanged.next(this.errorMessages.slice());
  }

  protected clearErrors() {
    this.errorMessages = [];
    this.errorsChanged.next([]);
  }

  protected setWarnings(messages: string[]) {
    this.warningMessages = messages;
    this.warningsChanged.next(this.warningMessages.slice());
  }

  protected pushWarning(message: string) {
    this.warningMessages.push(message);
    setTimeout(() => {
      this.warningsChanged.next(this.warningMessages.slice());
    }, 0);
  }

  protected clearWarnings() {
    this.warningMessages = [];
    this.warningsChanged.next([]);
  }
}
