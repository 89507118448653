<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <h4><strong>Auto Update Statistics</strong></h4>
  <app-tab-group (eventUpdate)="setSelectedTabIndex($event)">
    <app-tab label="Overview">
      <app-general-auto-update-statistics *ngIf="selectedTabIndex === 0" [server]="server">
      </app-general-auto-update-statistics>
    </app-tab>
    <app-tab label="Configuration">
      <app-configuration-auto-update-statistics *ngIf="selectedTabIndex === 1" [server]="server">
      </app-configuration-auto-update-statistics>
    </app-tab>
    <app-tab label="Alerts">
      <app-alerts-auto-update-statistics *ngIf="selectedTabIndex === 2" [server]="server">
      </app-alerts-auto-update-statistics>
    </app-tab>
    <app-tab label="Commands">
      <app-commands-auto-update-statistics *ngIf="selectedTabIndex === 3" [server]="server">
      </app-commands-auto-update-statistics>
    </app-tab>
  </app-tab-group>
</div>