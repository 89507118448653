<ng-template [ngIf]="currentGroup">
  <div class="d-flex align-items-center">
    <button class="btn btn-secondary mr-2" (click)="navigateBack()" [disabled]="!currentGroup.parent">
      <i class="icon icon-chevron-left"></i>
    </button>
    <div class="flex-1 text-truncate">
      <i class="icon icon-folder-open mr-1"></i> {{currentGroup.name}}
    </div>
  </div>
  <div class="list-group">
    <ng-container *ngIf="childGroups">
      <ng-container *ngFor="let item of childGroups">
        <button type="button" *ngIf="!item.hidden" class="list-group-item list-group-item-action text-truncate" (click)="navigateToGroup(item.item)" >
          <i class="icon icon-folder mr-1"></i> {{item.item.name}}
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="childServers">
      <ng-container *ngFor="let item of childServers">
        <button type="button" *ngIf="!item.hidden"
          class="list-group-item list-group-item-action d-flex align-items-center text-truncate"
          [ngClass]="{'text-primary': isSelected(item.item)}" (click)="!item.item.isWorkOffline && onServerClick(item.item)">
          <div class="flex-grow-1" [tooltip]="(item.item.isWorkOffline) ? 'You are working in offline mode' : null">
            <i class="icon icon-database mr-1"></i> {{item.item.name}}
          </div>
          <div *ngIf="isSelected(item.item)">
            <i class="icon icon-check"></i>
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
