<button type="button" class="btn color-button mb-2 ml-sm-2" tabindex="-1" [ngStyle]="{'background-color': hexColor}"
  [popover]="popoverTemplate" [popoverContext]="{ color: _color }" placement="right" [outsideClick]="true"
  container="body" (onHidden)="onColorPickerHidden()" #popover="bs-popover" containerClass="popover-color">
  &nbsp;
</button>

<ng-template #popoverTemplate let-color="color">
  <div class="d-flex flex-column">
    <div class="mb-1 text-center">
      <button type="button" class="btn color-button color-button-sm m-1" *ngFor="let color of defaultColors"
        [ngStyle]="{'background-color': color}" (click)="selectColor(color)">
        &nbsp;
      </button>
    </div>
    <div class="d-flex">
      <app-color-picker [color]="color" (colorChanged)="onColorChanged($event)"></app-color-picker>
    </div>
  </div>
</ng-template>
