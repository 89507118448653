/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { ChartJSUtils } from '../../shared/chartjs.utils';
import { AbstractDashboardComponent } from '../monitoring/abstract-dashboard-component';
import { InformixSensorService } from '../monitoring/informixSensor.service';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { TimeSliceService } from '../monitoring/time-slice.service';
import { getTimeSlice, TimeSlice } from '../monitoring/timeSlices';
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';
import {
  IFX_THREAD_STATE_COND_WAIT,
  IFX_THREAD_STATE_JOIN_WAIT,
  IFX_THREAD_STATE_MEMSYNC_WAIT,
  IFX_THREAD_STATE_MUTEX_WAIT,
  IFX_THREAD_STATE_READY
} from './performance/threads/serverThread';
import { SensorDataSource } from '../monitoring/sensor-data-source';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { UserService } from '../../shared/user/user.service';
import { User } from '../../shared/user/user';

@Component({
  selector: 'app-dashboard-server-threads',
  templateUrl: 'dashboard-server-threads.html'
})
export class DashboardServerThreadsComponent extends AbstractDashboardComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() serverInfo: InformixServerStat;
  @Input() monitoringProfile: MonitoringProfile;
  @Input() viewMonitoredData: boolean;

  @ViewChild('threadsChartContainer') threadsChartCanvas: ElementRef;
  private threadsChart: any;

  totalThreads: number = null;
  readyThreads: number = null;
  waitingThreads: number = null;
  waitingThreadsMutex: number = null;

  private threadCountsSensorId = 'thread_counts';
  private sensorDataSource: SensorDataSource = null;
  isThreadsSensorRunning = false;
  private threadSensorRunInterval: number;
  private oldestSensorGraphTimestamp: number = null;
  private mostRecentSensorGraphTimestamp: number = null;

  private threadsLiveDataSub: Subscription = null;
  private threadsSensorDataSub: Subscription = null;
  private timeSliceChangedSubscription: Subscription = null;
  private pauseDataSubscription: Subscription = null;
  private resumeDataSubscription: Subscription = null;

  readyThreadsChartColor = ChartJSUtils.getDefaultColor(6);
  waitThreadsChartColor = ChartJSUtils.getDefaultColor(3);
  waitMutexThreadsChartColor = ChartJSUtils.getDefaultColor(4);

  selectedTimeSlice: TimeSlice;
  isPaused = true;

  constructor(
    private serverService: InformixServerService,
    private sensorService: InformixSensorService,
    private timeSliceService: TimeSliceService,
    private notifications: NotificationsService,
    private userService: UserService
  ) {
    super();

    this.timeSliceChangedSubscription = this.timeSliceService.getTimeSliceChangedEventEmitter().subscribe((timeSlice: TimeSlice) => {
      this.setTimeSlice(timeSlice);
    });
    this.pauseDataSubscription = this.timeSliceService.getPauseEventEmitter().subscribe(() => {
      this.pauseData();
    });
    this.resumeDataSubscription = this.timeSliceService.getResumeEventEmitter().subscribe(() => {
      this.resumeData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.userService.getCurrentUser().then((user: User) => {
      this.selectedTimeSlice = getTimeSlice(user.settings.timeSliceName);
      if (changes.server || changes.monitoringProfile || changes.viewMonitoredData || this.serverInfo) {
        this.refreshData();
      }
    }).catch(err => {
      this.notifications.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
    });
  }

  private refreshData() {
    this.checkThreadsSensor();
    if (this.viewMonitoredData && this.isThreadsSensorRunning) {
      if (!this.threadsChart) {
        this.getHistoricalData();
        this.resumeData();
      }
    } else if (this.serverInfo) {
      if (this.threadsChart) {
        this.pauseData();
        this.threadsChart = null;
        this.oldestSensorGraphTimestamp = null;
        this.mostRecentSensorGraphTimestamp = null;
      }
      this.getThreadInfo();
    }
  }

  private checkThreadsSensor(): boolean {
    if (!this.server || !this.server.agent || !this.server.agent.online || !this.server.agent.isConfigured || !this.monitoringProfile) {
      return false;
    }
    const sensor = this.monitoringProfile.getSensor(this.threadCountsSensorId);
    if (sensor != null && !sensor.disabled) {
      this.isThreadsSensorRunning = true;
      this.threadSensorRunInterval = sensor.runInterval;
      this.sensorDataSource = this.sensorService.getSensorDataSource(this.server, sensor);
    }
    return this.isThreadsSensorRunning;
  }

  private getThreadInfo() {
    this.threadsLiveDataSub = this.serverService.getServerThreads(this.server.id).subscribe(threads => {
      this.totalThreads = 0;
      this.readyThreads = 0;
      this.waitingThreads = 0;
      this.waitingThreadsMutex = 0;
      threads.forEach(thread => {
        this.totalThreads++;
        if (thread.state === IFX_THREAD_STATE_READY) {
          this.readyThreads++;
        } else if (thread.state === IFX_THREAD_STATE_MUTEX_WAIT) {
          this.waitingThreads++;
          this.waitingThreadsMutex++;
        } else if (thread.state === IFX_THREAD_STATE_COND_WAIT || thread.state === IFX_THREAD_STATE_JOIN_WAIT
          || thread.state === IFX_THREAD_STATE_MEMSYNC_WAIT) {
          this.waitingThreads++;
        }
      });
    }, err => {
      console.error('Could not get thread info', err);
    });
  }

  private createThreadChart() {
    const chartConfig = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Ready',
            data: [],
            borderColor: this.readyThreadsChartColor,
            backgroundColor: this.readyThreadsChartColor,
            fill: false,
            lineTension: 0
          },
          {
            label: 'Waiting',
            data: [],
            borderColor: this.waitThreadsChartColor,
            backgroundColor: this.waitThreadsChartColor,
            fill: false,
            lineTension: 0
          },
          {
            label: 'Waiting on Mutexes',
            data: [],
            borderColor: this.waitMutexThreadsChartColor,
            backgroundColor: this.waitMutexThreadsChartColor,
            fill: false,
            lineTension: 0
          },
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                source: 'labels',
                autoSkipPadding: 10
              }
            }
          ],
          yAxes: [
            {
              type: 'linear',
              distribution: 'series',
              ticks: {
                min: 0,
                autoSkipPadding: 10,
                autoSkip: true,
                maxRotation: 0
              }
            }
          ]
        },
        tooltips: {
          mode: 'index'
        }
      }
    };
    this.threadsChart = new Chart(this.threadsChartCanvas.nativeElement, chartConfig);
  }

  setTimeSlice(slice: TimeSlice) {
    if (!this.isThreadsSensorRunning) {
      return;
    }
    if (slice === this.selectedTimeSlice) {
      return;
    }

    this.selectedTimeSlice = slice;
    if (!this.threadsChart || new Date().getTime() - slice.value < this.oldestSensorGraphTimestamp) {
      this.getHistoricalData();
    } else {
      this.trimData();
    }
  }

  private trimData() {
    const timestamp = new Date().getTime() - this.selectedTimeSlice.value;

    const timestamps = this.threadsChart.data.labels as number[];
    const index = timestamps.findIndex(v => v > timestamp);
    if (index > 0) {
      this.threadsChart.data.labels.splice(0, index);
      this.threadsChart.data.datasets[0].data.splice(0, index);
      this.threadsChart.data.datasets[1].data.splice(0, index);

      this.oldestSensorGraphTimestamp = timestamp;

      this.threadsChart.update();
    }
  }

  pauseData() {
    if (!this.isThreadsSensorRunning) {
      return;
    }
    if (this.isPaused) {
      return;
    }

    this.isPaused = true;
    if (this.threadsSensorDataSub) {
      this.threadsSensorDataSub.unsubscribe();
    }
  }

  resumeData() {
    if (!this.isThreadsSensorRunning || !this.isPaused) {
      return;
    }

    this.isPaused = false;
    this.threadsSensorDataSub = this.sensorDataSource.getLiveData(null, this.mostRecentSensorGraphTimestamp).subscribe(data => {
      if (data && data.length > 0) {
        this.addData(this.convertSensorData(data));
      }
    });
  }

  private getHistoricalData(): Promise<any> {
    const fromTimestamp = new Date().getTime() - this.selectedTimeSlice.value;

    if (this.sensorDataSource) {
      return this.sensorDataSource.getData(fromTimestamp, this.oldestSensorGraphTimestamp).then(data => {
        if (data && data.length > 0) {
          this.addData(this.convertSensorData(data));
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }

  private addData(data: any[]) {
    if (data.length < 1) {
      return;
    }

    if (!this.threadsChart) {
      this.createThreadChart();
    }

    let isHistorical = false;

    if (!this.oldestSensorGraphTimestamp && !this.mostRecentSensorGraphTimestamp) {
      isHistorical = true;
      this.mostRecentSensorGraphTimestamp = data[0].timestamp;
    } else if (this.oldestSensorGraphTimestamp && data[0].timestamp < this.oldestSensorGraphTimestamp) {
      isHistorical = true;
    } else if (this.mostRecentSensorGraphTimestamp && data[data.length - 1].timestamp > this.mostRecentSensorGraphTimestamp) {
      data.reverse();
    } else {
      return;
    }

    if (isHistorical) {
      data.forEach(v => {
        if (this.oldestSensorGraphTimestamp && this.oldestSensorGraphTimestamp - v.timestamp > 2000 * this.threadSensorRunInterval) {
          this.addGapDataPoint(this.oldestSensorGraphTimestamp - 1);
        }
        this.threadsChart.data.labels.unshift(new Date(v.timestamp));
        this.threadsChart.data.datasets[0].data.unshift(v.ready);
        this.threadsChart.data.datasets[1].data.unshift(v.all_waits);
        this.threadsChart.data.datasets[2].data.unshift(v.mutex_wait);
        this.oldestSensorGraphTimestamp = v.timestamp;
      });
    } else {
      data.forEach(v => {
        if (this.mostRecentSensorGraphTimestamp && v.timestamp - this.mostRecentSensorGraphTimestamp
          > 2000 * this.threadSensorRunInterval) {
          this.addGapDataPoint(this.mostRecentSensorGraphTimestamp + 1);
        }
        this.threadsChart.data.labels.push(new Date(v.timestamp));
        this.threadsChart.data.datasets[0].data.push(v.ready);
        this.threadsChart.data.datasets[1].data.push(v.all_waits);
        this.threadsChart.data.datasets[2].data.push(v.mutex_wait);
        this.mostRecentSensorGraphTimestamp = v.timestamp;
      });
    }

    this.threadsChart.update();
  }

  private addGapDataPoint(timestamp: number) {
    if (timestamp < this.oldestSensorGraphTimestamp) {
      this.threadsChart.data.labels.unshift(new Date(timestamp));
      this.threadsChart.data.datasets[0].data.unshift(null);
      this.threadsChart.data.datasets[1].data.unshift(null);
      this.threadsChart.data.datasets[2].data.unshift(null);
      this.oldestSensorGraphTimestamp = timestamp;
    } else {
      this.threadsChart.data.labels.push(new Date(timestamp));
      this.threadsChart.data.datasets[0].data.push(null);
      this.threadsChart.data.datasets[1].data.push(null);
      this.threadsChart.data.datasets[2].data.push(null);
      this.mostRecentSensorGraphTimestamp = timestamp;
    }
  }

  private convertSensorData(data: any[]): any[] {
    data.forEach(v => {
      v.ready = (v.data._.ready) ? v.data._.ready : 0;
      const mutex_wait = (v.data._.mutex_wait) ? v.data._.mutex_wait : 0;
      v.mutex_wait = mutex_wait;
      const join_wait = (v.data._.join_wait) ? v.data._.join_wait : 0;
      const cond_wait = (v.data._.cond_wait) ? v.data._.cond_wait : 0;
      const memsync_wait = (v.data._.memsync_wait) ? v.data._.memsync_wait : 0;
      v.all_waits = mutex_wait + join_wait + cond_wait + memsync_wait;
    });
    return data;
  }

  ngOnDestroy() {
    if (this.threadsLiveDataSub) {
      this.threadsLiveDataSub.unsubscribe();
    }
    if (this.threadsSensorDataSub) {
      this.threadsSensorDataSub.unsubscribe();
    }
    if (this.timeSliceChangedSubscription) {
      this.timeSliceChangedSubscription.unsubscribe();
    }
    if (this.pauseDataSubscription) {
      this.pauseDataSubscription.unsubscribe();
    }
    if (this.resumeDataSubscription) {
      this.resumeDataSubscription.unsubscribe();
    }
  }
}
