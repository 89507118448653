/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InformixTableDetails, InformixTable, InformixIndex } from '../../informix-table';
import { DataTableRow } from '../../../../../shared/data-table/data-table.component';
import { SchemaService } from '../../schema.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';

@Component({
  selector: 'app-table-indexes-info',
  templateUrl: './table-indexes-info.component.html'
})
export class TableIndexesInfoComponent implements OnInit {

  @Input() tableDetails: InformixTableDetails;
  @Input() table: InformixTable;
  @Output() openCreateIndex = new EventEmitter<any>();
  @Output() refreshIndexes = new EventEmitter();

  indexSelected = false;

  constructor(private schemaService: SchemaService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    if (this.tableDetails.indexes && this.tableDetails.indexes.length > 0 && this.tableDetails.constraints
      && this.tableDetails.constraints.length > 0) {
      this.tableDetails.constraints.forEach(element => {
        if (element.index) {
          this.tableDetails.indexes.forEach(idx => {
            if (idx.name === element.index.name) {
              idx.isDisabled = true;
            }
          });
        }
      });
    }
  }

  onIndexSelected(event: DataTableRow) {
    const table = event.data as InformixIndex;
    table.isChecked = event.selected;
    this.indexSelected = false;
    this.tableDetails.indexes.forEach(index => {
      if (index.isChecked) {
        this.indexSelected = true;
      }
    });
  }

  indexesDataMap(data: any): DataTableRow {
    return {
      data,
      selected: data.isChecked,
      disabled: data.isDisabled,
      expanded: false
    };
  }

  enableDisableIndex(type: string, row: InformixIndex) {
    this.confirmationDialogService.show(type + ' index ' + row.name + '?',
      () => this.enableDisableIndexConfirm(type, row));
  }

  enableDisableIndexConfirm(type: string, row: InformixIndex) {
    this.schemaService.enableDisableIndex(this.table, type, row).subscribe(res => {
      let action: string;
      type === 'enable' ? action = 'enabled' : action = 'disabled';
      this.refreshIndexes.emit();
      this.notificationsService.pushSuccessNotification('The selected indexes were ' + action + ' successfully.');
    }, err => {
      this.refreshIndexes.emit();
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  deleteIndexes(row: InformixIndex) {
    this.confirmationDialogService.show('drop all the selected indexes?',
      () => this.deleteIndexesConfirm(row));
  }

  deleteIndexesConfirm(row: InformixIndex) {
    const indexesToDelete = [];
    if (row) {
      indexesToDelete.push(row.name);
    } else {
      this.tableDetails.indexes.filter(item => {
        if (item.isChecked) {
          indexesToDelete.push(item.name);
        }
      });
    }
    this.schemaService.deleteIndexes(this.table, indexesToDelete).subscribe(res => {
      this.refreshIndexes.emit();
      this.notificationsService.pushSuccessNotification('The selected indexes were dropped successfully.');
    }, err => {
      this.refreshIndexes.emit();
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }
}
