/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved
 *******************************************************************************/
import { ServerResolver } from '../server-resolver.service';
import { Route } from '@angular/router';
import { PrivilegesComponent } from './privileges.component';

export const PrivilegesRoutes: Route[] = [
  {
    path: 'privileges',
    component: PrivilegesComponent,
    resolve: {
      server: ServerResolver
    }
  }
];
