/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { ServerResolver } from '../server-resolver.service';
import { Route } from '@angular/router';
import { SystemReportsComponent } from './system-reports.component';
import { GenericReportComponent } from './generic-report.component';
import { LockListReportComponent } from './lock-list-report.component';
import { LocksPerSessionReportComponent } from './lock-session-report.component';
import { LocksPerTableReportComponent } from './lock-table-report.component';
import { LocksWithWaitersReportComponent } from './lock-waiter-report.component';
import { SessionActivityReportComponent } from './session-activity-report.component';
import { SqltraceReportComponent } from './sqltrace-report.component';
import { TableBufferPoolActivityReportComponent } from './table-buffer-pool-activity-report.component';
import { TableExtentsReportComponent } from './table-extents-report.component';
import { WaitingSessionsReportComponent } from './waiting-sessions-report.component';
import { UpdateStatisticsReportComponent } from './update-statistics-report.component';

export const SystemReportsRoutes: Route[] = [
  {
    path: 'system-reports',
    component: SystemReportsComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/generic/:reportId',
    component: GenericReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/lock-list',
    component: LockListReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/locks-per-session',
    component: LocksPerSessionReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/locks-per-table',
    component: LocksPerTableReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/locks-with-waiters',
    component: LocksWithWaitersReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/session-activity',
    component: SessionActivityReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/slowest-sql',
    component: SqltraceReportComponent,
    data: {
      reportId: 'slowest_sql'
    },
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/sql-highest-estimated-cost',
    component: SqltraceReportComponent,
    data: {
      reportId: 'sql_highest_estimated_cost'
    },
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/sql-most-buffer-activity',
    component: SqltraceReportComponent,
    data: {
      reportId: 'sql_most_buffer_activity'
    },
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/sql-most-iotime',
    component: SqltraceReportComponent,
    data: {
      reportId: 'sql_most_iotime'
    },
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/sql-most-memory',
    component: SqltraceReportComponent,
    data: {
      reportId: 'sql_most_memory'
    },
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/table-buffer-pool-activity',
    component: TableBufferPoolActivityReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/table-extents',
    component: TableExtentsReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/waiting-sessions',
    component: WaitingSessionsReportComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'system-reports/update-statistics',
    component: UpdateStatisticsReportComponent,
    resolve: {
      server: ServerResolver
    }
  }
];
