<div *ngIf="server">
  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger mt-2">
      Unable to retrieve task run history information.
      <br>Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div *ngIf="!dataLoadErrorMessage" class="card-block">
    <div class="card-body p-0 mt-2">
      <div class="table-responsive">
        <app-data-table [data]="tableData" *ngIf="tableData; else tableDataLoading" [sortDescending]="true"
          [rowsPerPage]="20" searchLabel="Search ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
          <app-data-table-column title="Name" property="tk_name" [sortable]="true" [searchable]="true">
            <ng-template appTableCell let-row="row" let-item="item">
              <a href="javascript:void(0)" (click)="addTaskSensorModal.openAddTaskModal(false, '', row)">{{item}}</a>
            </ng-template>
            {{item}}
          </app-data-table-column>
          <app-data-table-column title="Number of Executions" property="tk_total_executions" [sortable]="true"
            [searchable]="false">
          </app-data-table-column>
          <app-data-table-column title="Average Time (s)" property="tk_average_time" [sortable]="true"
            [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Total Time (s)" property="tk_total_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item | number:'2.0-3'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Last Run Time" property="tk_run_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Last Execution Status" property="tk_run_retcode" [sortable]="true"
            [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item >=0" class="icon icon-check text-success"></i>
              <i *ngIf="item <0" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column class="text-right">
            <ng-template appTableCell let-row="row">
              <app-check-server-admin-permissions [server]="server">
                <button class="btn btn-sm btn-icon" tooltip="Run" (click)="confirmRun($event)" appPermissionDisable>
                  <i class="icon icon-play"></i>
                </button>
              </app-check-server-admin-permissions>
              <div class="col-xs-8 col-lg-6" align="right">
                <ul #dropdownRun class="dropdown-menu dropdown-menu-right py-0"
                  aria-labelledby="RunDropDownMenu">
                  <li class="li-background" (click)="openRunModal($event,row.tk_id,'Synchronous')">Synchronous</li>
                  <li class="li-background" (click)="openRunModal($event,row.tk_id,'Asynchronous')">Asynchronous</li>
                </ul>
              </div>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
        <ng-template #tableDataLoading>
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <app-task-modal #addTaskSensorModal [server]="server"></app-task-modal>
  <app-parameter #addParamModal></app-parameter>
