/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { AlertOperand } from '../../alerting-profile';
import { AbstractAlertOperand } from '../abstract-alert-operand';
import { AlertOperandType } from '../alert-operand-types';
import { ConstantEnum, ConstantEnumElem, ConstantOperands } from './constant-operands';


interface EnumElemUI extends ConstantEnumElem {
  isSelected: boolean;
}

@Component({
  selector: 'app-constant-enum-operand',
  template: `
  <ng-template [ngIf]="options">
    <button type="button" class="btn btn-sm m-1" *ngFor="let option of options" (click)="onOptionClick(option)"
      [ngClass]="option.isSelected ? 'btn-success' : 'btn-secondary'">
      {{option.name}}
    </button>
  </ng-template>
  `
})
export class ConstantEnumOperandComponent extends AbstractAlertOperand implements OnInit {
  private constEnum: ConstantEnum = null;
  selectedCount = 0;

  options: EnumElemUI[] = null;
  isOutputArray = false;

  ngOnInit() {
    super.ngOnInit();
  }

  public setType(type: AlertOperandType) {
    super.setType(type);
    this.constEnum = ConstantOperands.getEnumForType(type.outputType);
    this.options = null;
    this.selectedCount = 0;
    if (this.constEnum) {
      this.options = this.constEnum.elements.map(elem => ({
        name: elem.name,
        value: elem.value,
        isSelected: false
      }));
    }
    this.isOutputArray = type.outputType.endsWith('[]');
  }

  public setOperand(operand: AlertOperand) {
    super.setOperand(operand);
    if (this.operand && this.operand.value != null) {
      if (Array.isArray(this.operand.value) && this.isOutputArray) {
        (this.operand.value as any[]).forEach(value => {
          const option = this.options.find(opt => opt.value === value);
          if (option) {
            option.isSelected = true;
            this.selectedCount++;
          }
        });
      } else if (!this.isOutputArray) {
        const option = this.options.find(opt => opt.value === this.operand.value);
        if (option) {
          this.deselectAll();
          option.isSelected = true;
        } else {
          this.options[0].isSelected = true;
        }

        this.selectedCount = 1;
      }
    }
  }

  public isValid(): boolean {
    return this.selectedCount > 0;
  }

  public getValue(): any {
    let value: any | any[] = null;
    if (this.isOutputArray) {
      value = this.options.filter(v => v.isSelected).map(v => v.value);
    } else {
      const selectedOption = this.options.find(v => v.isSelected);
      if (selectedOption) {
        value = selectedOption.value;
      }
    }

    return { value };
  }

  selectAll() {
    this.options.forEach(option => option.isSelected = true);
    this.selectedCount = this.options.length;
  }

  deselectAll() {
    this.options.forEach(option => option.isSelected = false);
    this.selectedCount = 0;
  }

  onOptionClick(option: EnumElemUI) {
    if (!this.isOutputArray && !option.isSelected) {
      this.deselectAll();
      option.isSelected = true;
      this.selectedCount = 1;
    } else if (this.isOutputArray) {
      option.isSelected = !option.isSelected;
      this.selectedCount += option.isSelected ? 1 : -1;
    } else {
      return;
    }

    this.emitUpdate();
  }
}
