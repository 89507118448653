<div *ngIf="selectedChunk" class="container-fluid">
  <form>
    <div class="form-group">
      <div class="col-sm-12">
        <label class="col-form-label">
          <ng-container *ngIf="selectedChunk.extendable === 1; else makeExtendable">
            Chunk {{selectedChunk.chknum}} ({{selectedChunk.space_name}}) will no longer be extendable.
          </ng-container>
          <ng-template #makeExtendable>
            Chunk {{selectedChunk.chknum}} ({{selectedChunk.space_name}}) will become extendable.
          </ng-template>
          Do you want to continue?
        </label>
      </div>
    </div>
    <div class="form-group row d-flex flex-row-reverse">
      <div class="col-sm-3 d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="doModifyChunk()">Yes</button>
      </div>
    </div>
  </form>
</div>
