/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AutoUpdateStatisticsService } from './autoUpdateStatistics.service';
import { InformixServer } from '../informixServer';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-db-priority-modal',
  templateUrl: 'dbPriorityModal.html',
  styleUrls: ['dbPriorityModal.component.scss'],
})

export class DbPriorityModalComponent implements OnInit {

  @ViewChild('openDbPriorityModal') openDbPriorityModal: ModalDirective;
  @Input() server: InformixServer = null;
  highItems: any[];
  mediumItems: any[];
  lowItems: any[];
  disabledItems: any[];
  dragData = null;

  constructor(private autoUpdateStatisticsService: AutoUpdateStatisticsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getDatabasePriority();
    });
  }
  private loadServer(server: InformixServer) {
    this.server = server;
  }

  getDatabasePriority() {
    this.autoUpdateStatisticsService.getDatabasePriority(this.server).subscribe(res => {
      this.highItems = res.database_priority_high;
      this.lowItems = res.database_priority_low;
      this.mediumItems = res.database_priority_medium;
      this.disabledItems = res.database_priority_disable;
    });
  }

  saveDatabasePriority() {
    const priorityObj = {
      database_priority_high: this.highItems,
      database_priority_low: this.lowItems,
      database_priority_medium: this.mediumItems,
      database_priority_disable: this.disabledItems
    };
    this.autoUpdateStatisticsService.saveDatabasePriority(this.server, priorityObj).subscribe(res => {
      if (res) {
        this.notificationsService.pushSuccessNotification('Database priority saved.');
        this.openDbPriorityModal.hide();
      }
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }
  dropEvent(e: any) {
    this.dragData = e.dragData;
    const priorityIdObj = {
      highPriorityDrop: this.highItems,
      medPriorityDrop: this.mediumItems,
      lowPriorityDrop: this.lowItems,
      disabledPriorityDrop: this.disabledItems
    };
    const objKeys = Object.keys(priorityIdObj);
    objKeys.forEach(element => {
      if (e.nativeEvent.target.id === element) {
        priorityIdObj[element].push(this.dragData);
      }
    });
  }

  dragEvent(e: any) {
    const priorityIdObj = {
      highPriority: this.highItems,
      medPriority: this.mediumItems,
      lowPriority: this.lowItems,
      disabledPriority: this.disabledItems
    };
    const objKeys = Object.keys(priorityIdObj);
    objKeys.forEach(element => {
      if (e.target.id === element) {
        const index = priorityIdObj[element].indexOf(e.target.innerText);
        if (this.dragData) {
          priorityIdObj[element].splice(index, 1);
          this.dragData = null;
        }
      }
    });
  }
  openDbModal() {
    this.openDbPriorityModal.show();
    this.getDatabasePriority();
  }
  closeModal() {
    this.openDbPriorityModal.hide();
  }
}
