/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { ServerAlertingComponent } from './server-alerting.component';
import { Route } from '@angular/router';
import { CanDeactivateGuard } from '../../shared/canDeactivateGuard.service';
import { EditServerComponent } from './edit-server.component';
import { ErrorForbiddenComponent } from './forbidden.component';
import { LogsRoutes } from './logs/logs.routes';
import { NotFoundComponent } from './not-found.component';
import { OnconfigRoutes } from './configuration/onconfig.routes';
import { PerformanceRoutes } from './performance/performance.routes';
import { ServerResolver } from './server-resolver.service';
import { ServerDashboardComponent } from './serverDashboard.component';
import { ServerMonitoringComponent } from './serverMonitoring.component';
import { ServerPermissionsComponent } from './serverPermissions.component';
import { ServersComponent } from './servers.component';
import { SqltraceRoutes } from './sqltrace/sqltrace.routes';
import { StorageRoutes } from './storage/storage.routes';
import { SystemRoutes } from './system/system.routes';
import { PrivilegesRoutes } from './privileges/privileges.routes';
import { AutoUpdateStatisticsRoutes } from './autoUpdateStatistics/autoUpdateStatistics.routes';
import { dashboardRoutes } from '../monitoring/dashboard/dashboard.routes';
import { SchedulerRoutes } from './task-scheduler/scheduler.routes';
import { MemoryManagementComponent } from './memory-manager/memory-manager.component';
import { ServerIncidentsComponent } from './serverIncidents.component';
import { SystemReportsRoutes } from './system-reports/system-reports.routes';
import { HighAvailabilityRoutes } from './high-availability/high-availability.routes';
import { ConnectionManagerRoutes } from './connection-manager/connection-manager.routes';
import { SchemaManagerComponent } from './schema/schema-manager/schema-manager.component';
import { enterpriseReplicationRoutes } from './enterprise-replication/enterprise-replication.routes';
import { replicatesRoutes } from './enterprise-replication/replicates/replicates.routes';

export const ServersRoutes: Route[] = [
  {
    path: 'servers/not-found',
    component: NotFoundComponent
  },
  {
    path: 'servers/forbidden',
    component: ErrorForbiddenComponent
  },
  {
    path: 'servers/:id',
    component: ServersComponent,
    resolve: {
      server: ServerResolver
    },
    children: [
      {
        path: '',
        component: ServerDashboardComponent,
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: 'monitoring',
        component: ServerMonitoringComponent,
        resolve: {
          server: ServerResolver
        },
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'alerting',
        component: ServerAlertingComponent,
        resolve: {
          server: ServerResolver
        },
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'incidents',
        resolve: {
          server: ServerResolver
        },
        component: ServerIncidentsComponent
      },
      {
        path: 'setup',
        resolve: {
          server: ServerResolver
        },
        component: EditServerComponent
      },
      {
        path: 'permissions',
        resolve: {
          server: ServerResolver
        },
        component: ServerPermissionsComponent
      },
      {
        path: 'schema',
        component: SchemaManagerComponent,
        resolve: {
          server: ServerResolver
        },
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'memory',
        resolve: {
          server: ServerResolver
        },
        component: MemoryManagementComponent
      },

      ...dashboardRoutes,
      ...LogsRoutes,
      ...OnconfigRoutes,
      ...PerformanceRoutes,
      ...SqltraceRoutes,
      ...StorageRoutes,
      ...SystemRoutes,
      ...PrivilegesRoutes,
      ...HighAvailabilityRoutes,
      ...AutoUpdateStatisticsRoutes,
      ...SchedulerRoutes,
      ...enterpriseReplicationRoutes,
      ...ConnectionManagerRoutes,
      ...SystemReportsRoutes,
      ...replicatesRoutes

      // TODO: Other Server ID-based routes should be in here, like OnconfigRoutes
      // This way, the ':id' path parameter can be removed from all child routes
    ]
  }
];
