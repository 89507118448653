<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
    <div class="row mb-2">
        <div class="col-6">
            <h4>
                <strong>Replicate task details</strong>
                <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
            </h4>
        </div>
        <div class="col-6 form-inline justify-content-end">
            <a class="btn btn-sm btn-secondary ml-2" (click)="back()">
                Back to task list
            </a>
            <button id="refresh-button" type="button" class="btn btn-sm btn-primary ml-2" (click)="getTaskId();">
                <i class="icon icon-refresh" title="Refresh"></i>
            </button>
        </div>
    </div>

    <div class="task-details mt-2" *ngIf="taskDetails">
        <div class="row mt-4">
            <div class="col-3 d-flex">
                <div class="font-weight-bold">Task Name</div>
                <div class="ml-3">{{taskDetails.name}}</div>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Start Time:</label>
                <label class="ml-3">{{taskDetails.time_started?.$date | date:'short'}}</label>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Data last updated:</label>
                <label class="ml-3">{{taskDetails.current_time?.$date | date:'short'}}</label>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Task staus:</label>
                <div class="ml-3 font-weight-bold"> 
                  <a href="javascript:;" (click)="getErrorDetails()" *ngIf="taskDetails.cmdNumber && status === 'Failed'">
                    <span [ngClass]="'color-' + color">{{status}}</span></a>
                    <span [ngClass]="'color-' + color" *ngIf="!taskDetails.cmdNumber && status === 'Failed'" tooltip="No reason found in database">{{status}}</span>
                    <span [ngClass]="'color-' + color" *ngIf="status !== 'Failed'">{{status}}</span>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Task type:</label>
                <div class="ml-3"> {{taskDetails.type === null ? 'null' : (taskDetails.type === 'C' ? 'Check' : 'Sync')}}</div>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">End Time:</label>
                <label class="ml-3">{{taskDetails.time_completed?.$date  | date:'short'}}</label>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Rows on master:</label>
                <div class="ml-3"> {{taskDetails.numrows}}</div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Replicate name:</label>
                <div class="ml-3">{{taskDetails.replicate}}</div>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Duration:</label>
                <div class="ml-3">{{taskDetails.IntervalDF?.seconds}} {{taskDetails.IntervalDF?.nanoseconds && ':'}} {{taskDetails.IntervalDF?.nanoseconds}}</div>
            </div>
            <div class="col-3 d-flex">
                <label class="font-weight-bold">Rows processed:</label>
                <div class="ml-3">{{taskDetails.rows_processed}}</div>
            </div>
        </div>


        <!-- Table -->
        <div class="alert alert-danger mt-4" [hidden]="!taskError">
            <i class="icon icon-times-circle text-danger pr-1"></i> <span [innerHTML]="taskError"></span>
        </div>
        
        <div class="font-weight-bold mt-4" [hidden]="taskError">Statistics
            <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isStatLoading"></i>
        </div>
        <div class="mt-2" *ngIf="taskError === null">
            <app-data-table *ngIf="participants" class="table-responsive" [enableFilter]="false"
                [data]="participants" [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
                <app-data-table-column title="Node" property="group">
                    <ng-template appTableCell let-participant="row">
                        {{participant.group}} <span *ngIf="participant.group === master" class="master-server">Master</span>
                    </ng-template>
                </app-data-table-column>
                <app-data-table-column title="Table" property="tableName">
                </app-data-table-column>
                <app-data-table-column title="Rows" property="rowCount">
                </app-data-table-column>
                <app-data-table-column title="Extra" property="extraRows">
                </app-data-table-column>
                <app-data-table-column title="Missing" property="missingRows">
                </app-data-table-column>
                <app-data-table-column title="Missmatch" property="mismatchedRows">
                </app-data-table-column>
                <app-data-table-column title="Processed" property="rowsProcessed">
                </app-data-table-column>
            </app-data-table>
        </div>

        <!-- logs -->
        <!-- <div class="mt-4">
            <div class="border h-100 p-2">
                <div class="mt-2">Validations failed for the following rows:</div>
                <div class="mt-2">row missing on grp_2</div>
                <div class="">Key:C1:424</div>
            </div>
        </div> -->
    </div>

    <div bsModal #errorModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
        <div class="modal-dialog modal-lg" style="margin-top: 20%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left color-red">Failed Reason</h5>
                    <button type="button" class="close" (click)="errorModal.hide()">
                        <i class="icon icon-times"></i>
                    </button>
                </div>
    
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col">
                            {{taskErrorReason}}
                        </div>
                    </div>
                </div>
    
                <div class="modal-footer">
                    <div class="float-left">
                        <button type="button" class="btn btn-secondary" (click)="errorModal.hide()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
