<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title pull-left">
            Check replicate
            <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
        </h5>

        <button type="button" class="close" (click)="hideModal()">
            <i class="icon icon-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formGroup">
            <ng-container *ngIf="firstPage; else checkReplicateNextPage">
                <div class="row mt-2">
                    <div class="col">
                        <div class="alert alert-danger mx-2" *ngIf="error">
                            <button type="button" class="close" (click)="error = null">
                                <i class="icon icon-times"></i>
                            </button>
                            {{error}}
                        </div>
                        <div class="font-weight-bold mb-2"><span class="text-danger">* </span>Task Name </div>
                        <div class="input-group">
                            <input type="text" formControlName="taskName" class="form-control" placeholder=""
                                title="Task Name" (change)="checkJobNameExist()" />
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <app-checkbox [checked]="isRepair" (change)="isRepair = !isRepair" title="Specifies that rows that are found to be inconsistent are repaired.">
                            Repair inconsistent row
                        </app-checkbox>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="isRepair">
                    <div class="col">
                        <app-checkbox [checked]="isRepairRowsOnTimestamp" (change)="repairRowsOnTimeStamp()">
                            Repair inconsistent rows based on the latest timestamp
                        </app-checkbox>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <div class="input-group">
                            <label class="font-weight-bold">Extra target rows</label>
                            <app-radio-group class="justify-content-start ml-2" formControlName="extraTargetRows">
                                <app-radio value="delete">Delete</app-radio>
                                <app-radio class="ml-3" value="keep">Keep</app-radio>
                                <app-radio class="ml-3" value="merge">Merge</app-radio>
                            </app-radio-group>
                        </div>
                    </div>
                </div>


                <div class="row mt-2">
                    <div class="col">
                        <app-checkbox [checked]="isDelWinConflictResolution"
                            (change)="isDelWinConflictResolution = !isDelWinConflictResolution">
                            Use the delete wins conflict resolution rule
                        </app-checkbox>
                    </div>
                </div>

                <div class="font-weight-bold mb-2 mt-2"><span class="text-danger">* </span>Master Server</div>
                <div class="row mt-2">
                    <div class="col">
                        <select class="form-control" formControlName="serverName" *ngIf="!isLoading">
                            <option *ngIf="serverNames.length !== 0" value="">-- Select server name --</option>
                            <option *ngFor="let server of serverNames" value="{{server.name}}">{{server.name}}
                            </option>
                        </select>
                        <select class="form-control" formControlName="serverName" *ngIf="isLoading">
                            <option value="">Loading...</option>
                        </select>
                    </div>
                </div>

                <div class="font-weight-bold mb-2 mt-2"><span class="text-danger">* </span>Target Server</div>
                <div class="row mt-2 font-weight-bold">
                    <div class="col d-flex">
                        <div class="flex-1">
                            <select class="form-control" formControlName="targetServerName" *ngIf="!isLoading">
                                <option *ngIf="serverData.serverList.length !== 0" value="">-- Select server name --
                                </option>
                                <option *ngFor="let server of serverData.serverList" value="{{server.name}}">
                                    {{server.name}}
                                </option>
                            </select>
                            <select class="form-control" formControlName="serverName" *ngIf="isLoading">
                                <option value="">Loading...</option>
                            </select>
                        </div>
                        <button class="btn btn-secondary m-0 ml-1" (click)="addTargetServers()"
                            [disabled]="isAllTargetServers">Add</button>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <app-checkbox [checked]="isAllTargetServers" (change)="allTargetServers()">
                            All target servers
                        </app-checkbox>
                    </div>
                </div>

                <div class="row m-0 mb-5" *ngIf="!isLoading && serverData.targetServers?.length && !isAllTargetServers">
                    <div class="col d-flex p-0">
                        <table class="table mt-2 mb-0">
                            <tbody>
                                <tr *ngFor="let server of serverData.targetServers; let i = index;">
                                    <td> {{server?.name}}</td>
                                    <td class="text-right"><i class="icon icon-times"
                                            style="color: gray;font-size: 16px;" (click)="removeTargetServer(i);"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>

            <ng-template #checkReplicateNextPage>
                <div class="row mt-2">
                    <div class="col">
                        <div class="input-group">
                            <label class="font-weight-bold">Fire trigger</label>
                            <app-radio-group class="justify-content-start ml-2" formControlName="isFireTriggers" title="Specifies how to handle triggers at the target servers while data is synchronizing">
                                <app-radio value="off">Off</app-radio>
                                <app-radio class="ml-3" value="on">On</app-radio>
                                <app-radio class="ml-3" value="follow">Follow</app-radio>
                            </app-radio-group>
                        </div>
                    </div>
                </div>

                <div class="font-weight-bold mb-2 mt-2">In progress(seconds)</div>
                <div class="row mt-2">
                    <div class="col">
                        <input type="text" formControlName="inProgress" class="form-control" placeholder="20"
                            title="Specifies to spend more than the default time to recheck inconsistent rows that might be in the process of being applied on target servers." />
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <app-checkbox [checked]="isSkipLob" (change)="isSkipLob = !isSkipLob">
                            Do not check large objects
                        </app-checkbox>
                    </div>
                </div>

                <div class="font-weight-bold mb-2 mt-2">Specifies the time from which to check updated rows
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <select class="form-control form-control-sm" formControlName="timeFormat" title="Specifies the time from which to check updated rows. The replicate must be using the time stamp or delete wins conflict resolution rule(--since).">
                            <option value="M" selected>Minutes</option>
                            <option value="H">Hours</option>
                            <option value="D">Days</option>
                            <option value="W">Weeks</option>
                            <option value="date_time">Date and Time</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <input type="text" *ngIf="formGroup.controls.timeFormat.value !== 'date_time'"
                            formControlName="since" class="form-control" title="since" tooltip="Enter the number" appExtendedFormControl />
                        <div class="input-group" *ngIf="formGroup.controls.timeFormat.value === 'date_time'">
                            <input type="text" class="form-control w-auto pl-2" placeholder="YYYY-MM-DD hh:mm:ss"
                                bsDatepicker formControlName="since" #fromDatePicker="bsDatepicker"
                                [bsConfig]="{withTimepicker: true, rangeInputFormat : 'YYYY-MM-DD, H:mm:ss', dateInputFormat: 'YYYY-MM-DD, H:mm:ss'}"
                                [minDate]="tomorrowsDate" />
                            <div class="input-group-append">
                                <span class="input-group-text rounded-0" (click)="fromDatePicker.toggle()"
                                    [attr.aria-expanded]="fromDatePicker.isOpen">
                                    <i class="icon icon-calendar icon-padding"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col">
                        <div class="font-weight-bold mb-2">Enter WHERE Clause(SQL statement) </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">WHERE</span>
                            </div>
                            <input type="text" class="form-control" formControlName="query"
                                placeholder="'Country=Germany' AND City='Berlin'" title="Where Clause" />
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <app-checkbox [checked]="isPreventCheckTimeSeries"
                            (change)="isPreventCheckTimeSeries = !isPreventCheckTimeSeries">
                            Prevent the checking of time series data.
                        </app-checkbox>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <app-checkbox [checked]="isAvoidTimeseriesElements"
                            (change)="isAvoidTimeseriesElements = !isAvoidTimeseriesElements">
                            Avoid checking time series elements that are marked as hidden
                        </app-checkbox>
                    </div>
                </div>

                <div class="row mt-2 mb-5">
                    <div class="col">
                        <div class="font-weight-bold mb-2">Name of an existing checksum function </div>
                        <div class="input-group">
                            <input type="text" formControlName="nameChecksumFunction" class="form-control"
                                title="Specifies the name of an existing checksum function to use during consistency checking" />
                        </div>
                    </div>
                </div>
            </ng-template>
        </form>
    </div>
    <div class="modal-footer">
        <div class="float-left">
            <button type="button" class="btn btn-secondary" (click)="hideModal()">Close</button>
        </div>
        <div class="float-right">
            <button type="button" class="btn btn-secondary mr-3" (click)="firstPage = !firstPage">{{ firstPage ?
                'More
                options' : 'Back' }}</button>
            <button type="button" class="btn btn-primary justify-content-end" (click)="save()"
                [disabled]="isLoading || formGroup.invalid">Save</button>
        </div>
    </div>
</div>
