/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Routes } from '@angular/router';

import { DashboardRoutes } from './dashboard/dashboard.routes';
import { LoginRoutes } from './login/login.routes';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  ...LoginRoutes,
  ...DashboardRoutes,
  { path: '**', redirectTo: 'login' }
];
