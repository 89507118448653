/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Sensor } from './sensor';

export class SensorList<T extends Sensor> {
  public sensors: T[] = [];
  private sensorMap: { [key: string]: T } = {};

  constructor(sensors: T[] = null) {
    if (sensors && sensors.length > 0) {
      this.addSensors(sensors);
    }
  }

  public addSensors(sensors: T[]) {
    this.sensors = this.sensors.concat(sensors);
    sensors.forEach(sensor => {
      this.sensorMap[sensor.type.id] = sensor;
    });
  }

  public addSensor(sensor: T) {
    this.addSensors([sensor]);
  }

  public getSensor(id: string) {
    return this.sensorMap[id] || null;
  }

  public removeSensor(idOrSensor: string | T) {
    const id = typeof idOrSensor === 'string' ? idOrSensor : idOrSensor.type.id;
    if (this.sensorMap[id]) {
      delete this.sensorMap[id];
      const index = this.sensors.findIndex(v => v.type.id === id);
      if (index > -1) {
        this.sensors = this.sensors.slice(0, index).concat(this.sensors.slice(index + 1));
      }
    }
  }
}

export class MonitoringProfile extends SensorList<Sensor> {

  constructor(profile: any = null) {
    if (profile) {
      super((profile.sensors as any[]).map(sensor => new Sensor(sensor)));
    } else {
      super();
    }
  }
}
