/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { InformixTreeItem } from '../informixTreeItem';
import { MonitoringProfile } from './monitoringProfile';
import { SensorType } from './sensor';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface MonitoringProfileResponse {
  own: MonitoringProfile;
  inherited?: MonitoringProfile;
}

@Injectable()
export class MonitoringService {

  constructor(private httpClient: HttpClient) { }

  private getPath(item: InformixTreeItem): string {
    return 'informix/' + (item instanceof InformixServerGroup ? 'groups/' : '') + item.id;
  }

  public getMonitoringProfile(item: InformixTreeItem): Promise<MonitoringProfileResponse> {
    return this.httpClient.get<MonitoringProfileResponse>(this.getPath(item) + '/monitoring')
      .toPromise<MonitoringProfileResponse>()
      .then(response => {
        const profiles: MonitoringProfileResponse = {
          own: new MonitoringProfile(response.own)
        };
        if (response.inherited) {
          profiles.inherited = new MonitoringProfile(response.inherited);
        }
        return profiles;
      });
  }

  public getEffectiveMonitoringProfile(item: InformixTreeItem): Promise<MonitoringProfile> {
    return this.httpClient.get<MonitoringProfile>(this.getPath(item) + '/monitoring/effective')
      .toPromise<MonitoringProfile>()
      .then(json => new MonitoringProfile(json));
  }

  public updateMonitoringProfile(item: InformixTreeItem, profile: MonitoringProfile) {
    const sensors = profile.sensors.map(sensor => ({
      type: sensor.type.id,
      runInterval: sensor.runInterval,
      dataRetentionInterval: sensor.dataRetentionInterval,
      disabled: sensor.disabled
    }));

    return this.httpClient.put<MonitoringProfile>(this.getPath(item) + '/monitoring', { sensors })
      .toPromise<MonitoringProfile>();
  }

  public getSensorTypes(): Promise<SensorType[]> {
    return this.httpClient.get<SensorType[]>('sensor_types').pipe(
      map(response => response.map(v => new SensorType(v)))).toPromise<SensorType[]>();
  }

  createSensorType(json: any): Observable<SensorType> {
    return this.httpClient.post('sensor_types', json).pipe(map(response => new SensorType(response)));
  }

  deleteSensorType(sensorType: SensorType): Observable<any> {
    return this.httpClient.delete('sensor_types/' + sensorType.id);
  }
}
