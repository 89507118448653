<ng-template [ngIf]="sqltraceConfig">
  <div class="card card-body">
    <form role="form" [formGroup]="formGroup">
      <div class="row">
        <h4 class="col card-title font-weight-bold">
          Configuration
        </h4>
        <div class="col col-auto">
          <app-check-server-admin-permissions [server]="server" *ngIf="!isEditingConfig">
            <app-check-hdr-permissions [server]="server" [hdrPermission]="sqlTracingHDR" placement="top">
              <button type="button" class="btn btn-primary btn-sm" title="Edit Configuration" (click)="doEditConfiguration()"
                appHDRPermission [hdrPermission]="sqlTracingHDR" appPermissionDisable>
                <i class="icon icon-edit"></i>
              </button>
            </app-check-hdr-permissions>
          </app-check-server-admin-permissions>

          <app-check-hdr-permissions [server]="server" [hdrPermission]="sqlTracingHDR" placement="top">
            <button *ngIf="isEditingConfig" type="button" class="btn btn-primary btn-sm mr-1" title="Save" (click)="doUpdateConfiguration()"
              [disabled]="!formGroup.valid" appHDRPermission [hdrPermission]="sqlTracingHDR">
              <i class="icon icon-save"></i>
            </button>
          </app-check-hdr-permissions>

          <app-check-hdr-permissions [server]="server" [hdrPermission]="sqlTracingHDR" placement="top">
              <button *ngIf="isEditingConfig" type="button" class="btn btn-primary btn-sm" title="Undo" (click)="doCancelEditConfiguration()" appHDRPermission [hdrPermission]="sqlTracingHDR">
                <i class="icon icon-reset"></i>
              </button>
          </app-check-hdr-permissions>

        </div>
      </div>

      <div class="row">
        <div class="col-4 text-xl-right">
          Status:
        </div>
        <div class="col">
          <span *ngIf="!isEditingConfig && sqltraceConfig.state === 'On'" class="badge badge-success"
            style="padding: 0.5em 0.5em; font-size: 100%">Enabled</span>
          <span *ngIf="!isEditingConfig && sqltraceConfig.state === 'Off'" class="badge badge-danger"
            style="padding: 0.5em 0.5em; font-size: 100%">Disabled</span>
          <span *ngIf="!isEditingConfig && sqltraceConfig.state === 'Suspended'" class="badge badge-primary"
            style="padding: 0.5em 0.5em; font-size: 100%">Suspended</span>
          <span *ngIf="isEditingConfig">
            <button *ngIf="sqltraceConfig.state !== 'Suspended'" type="button" class="btn btn-sm m-1"
              (click)="setNewState('On')"
              [ngClass]="formGroup.value.state === 'On' ? 'btn-success' : 'btn-secondary'">Enable</button>
            <button *ngIf="sqltraceConfig.state !== 'Off'" type="button" class="btn btn-sm m-1"
              (click)="setNewState('Suspend')"
              [ngClass]="formGroup.value.state === 'Suspend' ? 'btn-primary' : 'btn-secondary'">Suspend</button>
            <button *ngIf="sqltraceConfig.state === 'Suspended'" type="button" class="btn btn-sm m-1"
              (click)="setNewState('Resume')"
              [ngClass]="formGroup.value.state === 'Resume' ? 'btn-success' : 'btn-secondary'">Resume</button>
            <button type="button" class="btn btn-sm m-1" (click)="setNewState('Off')"
              [ngClass]="formGroup.value.state === 'Off' ? 'btn-danger' : 'btn-secondary'">Disable</button>
          </span>
        </div>
      </div>
      <ng-template [ngIf]="sqltraceConfig.state !== 'Off' || isEditingConfig">
        <div class="row mt-1">
          <div class="col-4 text-xl-right">
            Mode:
          </div>
          <div class="col">
            <ng-template [ngIf]="!isEditingConfig">
              {{sqltraceConfig.mode}}
            </ng-template>
            <ng-template [ngIf]="isEditingConfig">
              <select class="form-control form-control-sm" formControlName="mode" (change)="handleModeChanged()">
                <option *ngFor="let option of modeOptions" [ngValue]="option.value">{{option.name}}</option>
              </select>
            </ng-template>
          </div>
        </div>
        <div
          *ngIf="(!isEditingConfig && sqltraceConfig.mode === 'User') || (isEditingConfig && formGroup.controls.mode.value === 'user')"
          class="row mt-1">
          <div class="col-4 text-xl-right">
            Traced users:
          </div>
          <div class="col">
            <ng-template [ngIf]="!isEditingConfig">
              {{sqltraceConfig.tracedUsers.join(', ')}}
            </ng-template>
            <ng-template [ngIf]="isEditingConfig">
              <div formArrayName="tracedUsers">
                <div class="form-inline" *ngFor="let user of tracedUsersFormArray.controls; let i = index">
                  <input type="text" class="form-control form-control-sm flex-grow-1" [formControlName]="i" tooltip=""
                    appExtendedFormControl>
                  <button class="btn btn-sm btn-danger" (click)="removeUser(i)">
                    <i class="icon icon-times"></i>
                  </button>
                </div>
                <button type="button" class="btn btn-sm btn-primary" (click)="addNewUser()"
                  [disabled]="formGroup.value.state !== 'On'">
                  <i class="icon icon-plus"></i> Add User
                </button>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 text-xl-right">
            Level:
          </div>
          <div class="col">
            <ng-template [ngIf]="!isEditingConfig">
              {{sqltraceConfig.level}}
            </ng-template>
            <ng-template [ngIf]="isEditingConfig">
              <select class="form-control form-control-sm" formControlName="level">
                <option *ngFor="let option of levelOptions" [ngValue]="option.value">{{option.name}}</option>
              </select>
            </ng-template>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 text-xl-right">
            Number of traces:
          </div>
          <div class="col">
            <ng-template [ngIf]="!isEditingConfig">
              {{sqltraceConfig.ntraces}}
            </ng-template>
            <ng-template [ngIf]="isEditingConfig">
              <input type="number" class="form-control form-control-sm" formControlName="nTraces" tooltip=""
                appExtendedFormControl [min]="500">
            </ng-template>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 text-xl-right">
            Trace Size:
          </div>
          <div class="col">
            <ng-template [ngIf]="!isEditingConfig">
              {{sqltraceConfig.tracesize | byte}}
            </ng-template>
            <ng-template [ngIf]="isEditingConfig">
              <app-input-size formClass="form-control-sm" formControlName="traceSize" min="1" maxUnit="1" tooltip=""
                appExtendedFormControl></app-input-size>
            </ng-template>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 text-xl-right">
            Traced databases:
          </div>
          <div class="col">
            <ng-template [ngIf]="!isEditingConfig">
              {{(sqltraceConfig.databases.untracedDatabases.length == 0)? 'All' :
              sqltraceConfig.databases.tracedDatabases.join(', ')}}
            </ng-template>
            <ng-template [ngIf]="isEditingConfig">
              <app-checkbox class="ml-0" formControlName="allDatabases" tooltip="Trace all databases">
                All databases
              </app-checkbox>
              <div class="row mt-1" *ngIf="isEditingConfig && formGroup.controls.allDatabases.value == false">
                <div class="col">
                  <ng-select [multiple]="true" [searchable]="false" (change)="onDatabaseSelect($event)" [(ngModel)]="selectedDatabases"
                    [items]="databases" bindLabel="name" bindValue="name" [placeholder]="'Select Databases'" [ngModelOptions]="{standalone: true}">
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2">
                            <span class="ng-value-label">{{item.name}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{items.length - 2}} more...</span>
                        </div>
                      </ng-template>
                    </ng-select>

                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="row mt-1" *ngIf="isEditingConfig && sqltraceConfig.state === 'On'">
          <div class="col-4 text-xl-right">
            Clear trace buffer:
          </div>
          <div class="col">
            <app-checkbox class="ml-0" formControlName="clearBuffer"
              tooltip="Clear out existing SQL trace data from the trace buffer.">
            </app-checkbox>
          </div>
        </div>
      </ng-template>
    </form>
  </div>
</ng-template>
