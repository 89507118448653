<div class="row">
  <div class="col-11 px-0 mb-1">
    <app-checkbox [(ngModel)]="useAnyServer" (change)="addAnyServer()" class="font-weight-bold">
      {{'connectionManager.label.anyServer' | translate}} </app-checkbox>
    <button class="btn btn-sm btn-primary float-right" (click)="openServerGroupPopup()" [disabled]="useAnyServer">
      {{'button.group' | translate}}
    </button>
  </div>
  <div class="col-5 px-0" [ngClass]="{'disabled-text': useAnyServer}">
    <div class="border overflow-auto server-table-height">
      <table class="table border-0">
        <thead>
          <tr>
            <th> {{'connectionManager.availableServers' | translate}} </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="availableServers?.length > 0 else loading">
            <tr *ngFor="let server of availableServers">
              <td class="p-0 outline-none border-0"><button (click)="selectedServerToAdd = server"
                  class="p-2 border-0 w-100 text-left" [disabled]="useAnyServer"
                  [ngClass]="selectedServerToAdd === server ? 'bg-light-gray-30' : 'bg-white'">{{server}}</button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-1 px-0 pt-5 mt-5 text-center server-table-height">
    <button class="btn btn-sm btn-icon" (click)="addSelectedServer()" [disabled]="!selectedServerToAdd || useAnyServer">
      <i class="icon font-weight-bold icon-chevron-right"></i></button> <br>
    <button class="btn btn-sm btn-icon" (click)="removeSelectedServer()"
      [disabled]="!selectedServerToRemove || useAnyServer"> <i
        class="icon font-weight-bold icon-chevron-left"></i></button>
  </div>
  <div class="col-5 px-0" [ngClass]="{'disabled-text': useAnyServer}">
    <div class="border overflow-auto server-table-height">
      <table class="table border-0">
        <thead>
          <tr>
            <th> {{'connectionManager.serversInSLA' | translate}} </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let server of selectedServers; let i=index">
            <td class="p-0 outline-none border-0">
              <div class="btn-group w-100" role="group">
                <button
                  (click)="!(server.includes('(') && server.includes(')')) ? selectedServerToRemove = server : selectedServerToRemove = ''"
                  class="p-2 border-0 w-100 text-left text-monospace" [disabled]="useAnyServer"
                  [ngClass]="selectedServerToRemove === server ? 'bg-light-gray-30' : 'bg-white'">
                  {{(server.includes('(') && server.includes(')')) ? server.split('(')[0]: server}}</button>
                <button class="btn btn-sm btn-icon" [disabled]="useAnyServer"
                  *ngIf="server.includes('(') && server.includes(')')"> <i class="icon font-weight-bold icon-edit"
                    (click)="editGroup(server)"></i></button><button class="btn btn-sm btn-icon"
                  [disabled]="useAnyServer" *ngIf="server.includes('(') && server.includes(')')"
                  (click)="removeGroup(server)">
                  <i class="icon font-weight-bold icon-trash"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-spin mt-5"></i>
  </div>
</ng-template>

<div bsModal #groupModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-11">
          <h5 class="modal-title pull-left">{{'connectionManager.createGroup' | translate}}</h5>
        </div>
        <div>
          <button type="button" class="close" (click)="closeGroupPopup()">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body p-4">
        <h6>{{isEditGroup ? groupName : 'Group' + groupId}}</h6>
        <div class="font-weight-bold pb-2">{{'connectionManager.selectFromAvailableServers' | translate}}</div>
        <div class="overflow-auto server-table-height"
          *ngIf="allAvailableServers?.length > 0 && showTable else loading">
          <app-data-table [enablePagination]="false" [data]="allAvailableServers" [enableSelect]="true"
            (updateSelectedRows)="getSelectedServers($event)" isChecked="checked"
            [searchLabel]="'dataTable.searchLabel' | translate">
            <app-data-table-column [title]="'connectionManager.table.columnTitle.servers' | translate"
              [searchable]="true" property="server">
            </app-data-table-column>
          </app-data-table>
        </div>
        <div class="pr-0 pt-3 float-right">
          <button type="button" class="btn btn-secondary btn-fixed-width"
            (click)="closeGroupPopup()">{{'button.cancel' | translate}}</button>
          <button type="button" class="btn btn-primary" (click)="addServersInSLA()"
            [disabled]="groupServers?.length < 1">
            {{isEditGroup ? ('connectionManager.button.updateGroup' | translate): ('connectionManager.button.addSLAGroup' | translate)}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
