/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';

import { PlaygroundDataTableComponent } from './data-table.component';
import { PlaygroundFormsComponent } from './forms.component';
import { PlaygroundNotificationsComponent } from './notifications.component';
import { PlaygroundTreeViewComponent } from './tree-view.component';

export const PlaygroundRoutes: Route[] = [
  {
    path: 'playground',
    children: [
      {
        path: 'forms',
        component: PlaygroundFormsComponent
      },
      {
        path: 'notifications',
        component: PlaygroundNotificationsComponent
      },
      {
        path: 'data-table',
        component: PlaygroundDataTableComponent
      },
      {
        path: 'tree-view',
        component: PlaygroundTreeViewComponent
      }
    ]
  }
];
