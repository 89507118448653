
<ng-container *ngIf="tableData && tableData.length else ausAlertsError;">
  <div class="mt-3">
    <label class="font-weight-bold">
      Auto Update Statistics Alerts
    </label>
  </div>
  <div class="card-block">
    <div class="card-body p-0">
      <div class="table-responsive">
        <app-data-table [data]="tableData" *ngIf="tableData; else tableDataLoading" [sortDescending]="true"
          [rowsPerPage]="20">
          <app-data-table-column title="Time" property="alert_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Type" property="alert_type" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item === 'INFO'" class="icon icon-info-circle color-light-gray-70"></i>
              <i *ngIf="item === 'WARNING'" class="icon icon-warning color-light-gray-70"></i>
              <i *ngIf="item === 'ERROR'" class="icon icon-notification-error color-light-gray-70"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Color" property="alert_color" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item === 'yellow'" class="icon icon-circle color-gold-60"></i>
              <i *ngIf="item === 'red'" class="icon icon-circle text-danger"></i>
              <i *ngIf="item === 'green'" class="icon icon-circle text-success"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Message" property="alert_message" [sortable]="true" [searchable]="false">
          </app-data-table-column>
        </app-data-table>
        <ng-template #tableDataLoading>
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #ausAlertsError>
  <div *ngIf="ausAlertsApiError" class="mt-2 alert alert-danger">
    <i class="icon icon-danger text-danger"></i> Unable to retrieve auto update statistics information. <br> Error: {{ausAlertsApiError}}
  </div>
</ng-template>