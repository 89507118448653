/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/

import { InformixDatabase } from './informix-database';

export type InformixSQLSessionType = 'monitor' | 'admin' | 'other';

export class InformixSQLSession {
  database: InformixDatabase;
  id: number;
  type: InformixSQLSessionType;
  username: string;
  password: string;
  isAutoCommit: Boolean;
  isContinueOnFailure: Boolean;
  isBatchMode: Boolean;

  constructor(database: InformixDatabase, json: any, type: InformixSQLSessionType, username: string, password: string) {
    this.database = database;
    this.update(json);

    this.type = type;
    this.username = this.username || username;
    this.password = password;
  }

  update(json: any) {
    this.id = json.id;
    this.username = json.username;
    this.isAutoCommit = json.isAutoCommit;
    this.isContinueOnFailure = json.isContinueOnFailure;
    this.isBatchMode = json.isSetBatchMode;
  }

  getUrl(): string {
    return this.database.getSQLSessionsUrl() + '/' + this.id;
  }

  getTablesUrl(): string {
    return this.getUrl() + '/tables';
  }

  getSQLUrl(): string {
    return this.getUrl() + '/sql';
  }
}
