/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
export const IFX_THREAD_STATE_RUNNING = 0;
export const IFX_THREAD_STATE_READY = 1;
export const IFX_THREAD_STATE_MUTEX_WAIT = 2;
export const IFX_THREAD_STATE_JOIN_WAIT = 3;
export const IFX_THREAD_STATE_COND_WAIT = 4;
export const IFX_THREAD_STATE_DETACH_TERMINATED = 5;
export const IFX_THREAD_STATE_TERMINATED = 6;
export const IFX_THREAD_STATE_SLEEPING = 7;
export const IFX_THREAD_STATE_MEMSYNC_WAIT = 8;

export interface ServerThread {
  tid: number;
  name: string;
  address: string;
  state: number;
  statedesc: string;
  statedetail: string;
  priority: number;
  vpclass: number;
  last_run_time: number;
  cpu_time: number;
  num_sched: number;
}
