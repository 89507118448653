/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InformixServer } from '../../servers/informixServer';
import { TaskSchedulerService } from './task-scheduler.service';
import { RunHistoryArray } from './task-scheduler.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from './../../../shared/modal/confirmation-dialog.component';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';

@Component({
  selector: 'app-task-scheduler-run-history',
  templateUrl: './task-scheduler-run-history.component.html',
  styleUrls: ['./task-scheduler.component.scss'],
  providers: [TaskSchedulerService]
})
export class TaskSchedulerRunHistoryComponent implements OnInit {

  server: InformixServer = null;
  tableData: any[];
  currentTaskId: number;
  rowId: number;
  dataLoadErrorMessage: string = null;

  @ViewChild('addTaskSensorModal') addTaskSensorModal: ModalDirective;
  @ViewChild(ConfirmationDialogComponent) confirmationDialog: ConfirmationDialogComponent;
  @ViewChild('dropdownRun') dropdownRun: Element;

  constructor(
    private route: ActivatedRoute,
    private taskSchedulerService: TaskSchedulerService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getRunHistory();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.tableData = null;
  }

  private getRunHistory() {
    this.taskSchedulerService.getRunHistory(this.server).subscribe((res: RunHistoryArray) => {
      this.addData(res);
      this.dataLoadErrorMessage = null;
    }, (err: HttpErrorResponse) => {
      console.error(err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private addData(data) {
    if (data.length < 1) {
      return;
    }
    const tableData = data.map(v => ({
      tk_id: v.tk_id,
      tk_name: v.tk_name,
      tk_total_executions: v.tk_total_executions,
      tk_average_time: v.tk_average_time,
      tk_total_time: v.tk_total_time,
      tk_run_time: v.tk_run_time,
      tk_run_retcode: v.tk_run_retcode
    }));
    this.tableData = tableData;
  }

  confirmRun(event: any) {
    event.stopPropagation();
    this.dropdownRun['nativeElement'].style.display = 'block';
  }

  openRunModal(event: any, id: any, type: string) {
    event.stopPropagation();
    this.rowId = id;
    this.confirmationDialogService.show('Run Task',
      'run the task?', () => this.executeTask(type));
  }

  executeTask(type: any) {
    let options = {};
    if (type === 'Asynchronous') {
      options = { async: true };
    } else {
      options = { async: false };
    }
    this.taskSchedulerService.executeTask(this.server, this.rowId, options).subscribe(result => {
      this.notificationsService.pushSuccessNotification('Task submitted');
    }, err => {
      this.notificationsService.pushErrorNotification('Failed to run task: ' + (err.error.err));
    });
    this.dropdownRun['nativeElement'].style.display = 'none';
  }
}
