/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartjsData, ChartjsDataSeries, ChartjsYAxis, ZoomEvent } from '../../../../../shared/chartjs/chartjs/chartjs.component';
import { InformixServer } from '../../../../servers/informixServer';
import { InformixSensorService } from '../../../informixSensor.service';
import { MonitoringProfile } from '../../../monitoringProfile';
import { SensorMetric } from '../../../sensor';
import { SensorDataSource } from '../../../sensor-data-source';
import { AbstractDashboardPlugin } from '../../abstract-dashboard-plugin';
import { DashboardTimeInterval } from '../../dashboard-time-interval';
import { DashboardService } from '../../dashboard.service';
import { SensorChartjsPluginConfig } from '../sensor-chartjs-plugin-config';
import { SensorChartjsService } from '../sensor-chartjs.service';
import { TranslateService } from '@ngx-translate/core';
import { SchemaService } from '../../../../servers/schema/schema.service';
import { InformixSQLSession } from '../../../../servers/schema/informix-sql-session';
import { Query } from '../../../../servers/schema/query';
import { QueryResultPage, QueryResultValue } from '../../../../servers/schema/query-results';
import { InformixDatabase } from '../../../../servers/schema/informix-database';
import { HttpErrorResponse } from '@angular/common/http';
import { ChartJSUtils } from '../../../../../shared/chartjs.utils';

interface ServerContext {
  server: InformixServer;
  monitoringProfile: MonitoringProfile;
  dataSources?: ChartDataSource[];
  rawData?: any[][];
  missingSensors?: string[];
  disabledSensors?: string[];
  dataLoadWarning?: string;
}

interface ChartDataSource {
  sensorDataSource: SensorDataSource;
  metric?: string;
  primaryKeys?: string[];
}

@Component({
  selector: 'app-sensor-chartjs-plugin',
  templateUrl: './sensor-chartjs-plugin.component.html',
  styleUrls: ['./sensor-chartjs-plugin.component.scss']
})
export class SensorChartjsPluginComponent extends AbstractDashboardPlugin implements OnInit, AfterViewInit, OnDestroy {

  config: SensorChartjsPluginConfig = null;

  isLoading = true;
  shouldReloadData = false;
  statusMessage: string = null;

  serverContexts: ServerContext[] = null;
  monitoringProfiles: MonitoringProfile[] = null;
  sensorTypes = [];

  chartData: ChartjsData = null;
  chartYAxes: ChartjsYAxis[] = null;

  timeInterval: DashboardTimeInterval;
  timeIntervalSub: Subscription;

  session: InformixSQLSession = null;
  sessionSub: Subscription = null;
  sessionError: string = null;

  query: Query = null;
  querySub: Subscription = null;
  queryError: string = null;
  queryResult: QueryResultPage = null;
  timeStampIndex: number = null;

  private pollTimeoutHandle: number;

  constructor(
    private dashboardService: DashboardService,
    private pluginService: SensorChartjsService,
    private sensorService: InformixSensorService,
    private hostElementRef: ElementRef,
    private translate: TranslateService,
    private schemaService: SchemaService
  ) {
    super();
  }

  ngOnInit() {
    this.timeInterval = this.dashboardService.getTimeInterval();
    this.timeIntervalSub = this.dashboardService.timeIntervalChanged.subscribe(timeInterval => {
      if (this.config.dataSources.length < 1 && this.config.isCustomQuery === false) {
        this.timeIntervalSub.unsubscribe();
      } else {
        this.timeInterval = timeInterval;
        if (this.config.isCustomQuery) {
          this.loadCustomQuery();
        } else {
          this.loadData();
        }
      }
    });

    if (this.config.isCustomQuery === false) {
      this.getMonitoringProfiles();
    }
  }

  ngAfterViewInit() {
    this.loadData();
  }

  ngOnDestroy() {
    if (this.timeIntervalSub) {
      this.timeIntervalSub.unsubscribe();
    }
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }

    if (this.sessionSub) {
      this.sessionSub.unsubscribe();
    }

    if (this.querySub) {
      this.querySub.unsubscribe();
    }
  }

  setConfig(config: any) {
    const prevConfig = this.config;
    this.config = new SensorChartjsPluginConfig(config);

    if (this.config.isCustomQuery) {
      this.loadCustomQuery();
      return;
    }
    this.getMonitoringProfiles();
    let shouldReloadData = false;
    let shouldRebuildData = false;

    const prevServerIndex = prevConfig ? prevConfig.serverIndex : null;
    if (this.config.serverIndex !== prevServerIndex) {
      if (this.serverContexts && prevServerIndex === null) {
        this.setSingleServerContext(this.config.serverIndex);
        shouldRebuildData = true;
      } else {
        this.buildServerContexts();
        shouldReloadData = true;
      }
    }

    const dataSources = config ? config.dataSources : null;
    if (dataSources && dataSources.length) {
      this.sensorTypes = Array.from(new Set(dataSources.map(dataSource => dataSource.sensorType)));
      const prevDataSources = prevConfig ? prevConfig.dataSources : [];
      if (dataSources.length !== prevDataSources.length) {
        shouldReloadData = true;
      } else {
        for (let i = 0; i < dataSources.length; i++) {
          if (dataSources[i].sensorType !== prevDataSources[i].sensorType ||
            dataSources[i].primaryKey !== prevDataSources[i].primaryKey) {
            shouldReloadData = true;
            break;
          }
          if (dataSources[i].metric !== prevDataSources[i].metric) {
            shouldRebuildData = true;
          }
        }
      }
      if (shouldReloadData) {
        this.buildDataSources();
        this.loadData();
      } else if (shouldRebuildData) {
        this.buildDataSources();
        this.buildChartData();
      } else {
        this.buildChartData();
      }
    } else {
      this.buildDataSources();
      this.chartData = null;
    }
  }

  getMonitoringProfiles() {
    this.pluginService.getMonitoringProfiles().then(profiles => {
      if (profiles) {
        this.isLoading = false;
        this.monitoringProfiles = profiles;
        this.buildServerContexts();
        this.buildDataSources();
        this.loadData();
      }
    });
  }

  private buildServerContexts() {
    if (!this.monitoringProfiles) {
      return;
    }

    this.serverContexts = this.dashboardService.getServers().map((server, i) => ({
        server,
        monitoringProfile: this.monitoringProfiles[i],
        dataSources: []
      }));

    if (this.config && this.config.serverIndex !== null) {
      this.setSingleServerContext(this.config.serverIndex);
    }
  }

  private setSingleServerContext(index: number) {
    const context = this.serverContexts[index];
    if (context) {
      this.serverContexts = [context];
    } else {
      this.statusMessage = 'Server #' + (index + 1) + ' not selected yet.';
      this.serverContexts = null;
    }
  }

  private buildDataSources() {
    if (!this.serverContexts) {
      return;
    }
    if (this.config.isCustomQuery) {
      return;
    }
    if (!this.config || !this.config.dataSources || !this.config.dataSources.length) {
      this.statusMessage = 'No data sources configured yet.';
      return;
    }

    this.statusMessage = null;

    this.serverContexts.forEach(context => {
      const server = context.server;
      const missingSensors = new Set<string>();
      const disabledSensors = new Set<string>();

      context.dataSources = [];
      this.config.dataSources.forEach(dataSource => {
        const sensor = context.monitoringProfile.getSensor(dataSource.sensorType);
        if (!sensor) {
          missingSensors.add(dataSource.sensorType);
        } else if (sensor.disabled) {
          disabledSensors.add(dataSource.sensorType);
        } else {
          context.dataSources.push({
            sensorDataSource: this.sensorService.getSensorDataSource(server, sensor),
            metric: dataSource.metric,
            primaryKeys: dataSource.primaryKey ? [dataSource.primaryKey] : null
          });
        }
      });

      context.missingSensors = Array.from(missingSensors.values());
      context.disabledSensors = Array.from(disabledSensors.values());
    });

    if (!this.serverContexts.reduce((prev, current) => prev + current.dataSources.length, 0)) {
      this.statusMessage = 'No data available.';
    }

    this.buildWarningsAndErrors();
  }

  private loadData() {
    if (!this.serverContexts) {
      return;
    }

    if (this.isLoading) {
      this.shouldReloadData = true;
      return;
    }

    const promises = this.serverContexts.map(context => {
      context.dataLoadWarning = null;
      const dataPromises = context.dataSources.map(dataSource => this.getDataSource(dataSource, context));

      if (dataPromises.length < 1) {
        return null;
      }

      return Promise.all(dataPromises).then(results => {
        context.rawData = results;
        context.rawData.forEach(result => {
          if (result) {
            result.reverse();
          }
        });
      });
    }).filter(v => !!v);

    if (promises.length < 1) {
      return;
    }

    this.isLoading = true;
    this.statusMessage = null;
    Promise.all(promises).then(() => {
      this.isLoading = false;
      if (this.shouldReloadData) {
        this.shouldReloadData = false;
        this.loadData();
      } else {
        this.buildChartData();
        this.buildWarningsAndErrors();
      }
    });
  }

  private getDataSource(dataSource, context) {
    const downsampleMax = Math.floor(this.hostElementRef.nativeElement.clientWidth * 0.5);
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }
    this.pollTimeoutHandle = window.setTimeout(() => {
      if (this.dashboardService.getSelectedIntervalOption()) {
        this.dashboardService.setTimeInterval(DashboardTimeInterval
          .relativeFromNow(this.dashboardService.getSelectedIntervalOption().seconds * -1000));
      }
    }, 30000);
    return dataSource.sensorDataSource.getData(
      this.timeInterval.getStartTimestamp(), this.timeInterval.getEndTimestamp(), downsampleMax, dataSource.primaryKeys).catch(err => {
        context.dataLoadWarning = (err.error && err.error.err) ? err.error.err : err;
        return null;
      });
  }

  private buildChartData() {
    if (!this.serverContexts || this.isLoading) {
      return;
    }

    this.chartData = {
      dataSeries: []
    };

    let maxYAxisIndex = 0;
    let hasSharedTimestamps = !this.isMultiserver();

    this.serverContexts.forEach((context, serverIndex) => {
      if (!context.rawData) {
        return;
      }

      if (!this.isMultiserver()) {
        hasSharedTimestamps = hasSharedTimestamps && this.canShareTimestamps(context.dataSources, context.rawData);
      }

      context.rawData.forEach((result, i) => {
        if (!result) {
          return;
        }

        const dataSource = context.dataSources[i];
        const sensorType = dataSource.sensorDataSource.getSensor().type;
        const metrics: SensorMetric[] = dataSource.metric ? [sensorType.getMetric(dataSource.metric)] : sensorType.metrics;
        let primaryKeys: string[] = ['_'];
        if (sensorType.hasPrimaryKey()) {
          primaryKeys = dataSource.primaryKeys || this.getPrimaryKeys(result);
          primaryKeys.sort((a, b) => a.localeCompare(b));
        }

        primaryKeys.forEach(primaryKey => {
          const primaryKeyDataMap = new Map<string, ChartjsDataSeries>();
          metrics.forEach(metric => {
            if (this.chartData.dataSeries.length >= 20) {
              return;
            }
            const seriesConfig = this.config.getSeriesConfig(
              sensorType, metric, sensorType.hasPrimaryKey() ? primaryKey : null, this.isMultiserver() ? serverIndex : null);

            const metricData: ChartjsDataSeries = {
              label: seriesConfig.getLabel(context.server),
              color: seriesConfig.color,
              values: result.map(dataPoint => {
                const value = this.getDataPointValue(dataPoint, primaryKey, metric);
                return hasSharedTimestamps ? value : { x: new Date(dataPoint.timestamp), y: value };
              })
            };
            if (typeof seriesConfig.yAxis === 'number') {
              metricData.yAxis = seriesConfig.yAxis;
              maxYAxisIndex = Math.max(maxYAxisIndex, metricData.yAxis);
            }

            primaryKeyDataMap.set(metric.id, metricData);
            this.chartData.dataSeries.push(metricData);
          });
        });
      });
    });

    if (this.chartData.dataSeries.length < 1) {
      this.chartData = null;
      this.statusMessage = 'No data available.';
    } else {
      if (this.config.yAxes) {
        this.chartYAxes = this.config.yAxes.slice(0, Math.min(this.config.yAxes.length, maxYAxisIndex + 1));
      }
      if (hasSharedTimestamps) {
        this.serverContexts.find(context => {
          if (!context.rawData) {
            return false;
          }

          const data = context.rawData.find(v => !!v);
          if (data) {
            this.chartData.timestamps = data.map(dataPoint => new Date(dataPoint.timestamp));
            return true;
          } else {
            return false;
          }
        });
      }
      this.buildWarningsAndErrors();
    }
    if (this.chartData && this.chartData.timestamps && this.chartData.timestamps.length > 0) {
      this.timeStampIndex = 0;
    } else {
      this.timeStampIndex = -1;
    }
  }

  private canShareTimestamps(dataSources: ChartDataSource[], rawData: any[][]) {
    let prevSensorType: string = null;
    for (let i = 0; i < dataSources.length; i++) {
      if (rawData[i]) {
        const sensorType = dataSources[i].sensorDataSource.getSensor().type.id;
        if (prevSensorType && sensorType !== prevSensorType) {
          return false;
        }
        prevSensorType = sensorType;
      }
    }

    return true;
  }

  private getPrimaryKeys(data: any[]): string[] {
    const primaryKeySet = new Set<string>();

    data.forEach(dataPoint => {
      Object.keys(dataPoint.data).forEach(key => primaryKeySet.add(key));
    });

    return Array.from(primaryKeySet.values());
  }

  private getDataPointValue(dataPoint: any, primaryKey: string, metric: SensorMetric): number {
    const metricValues = dataPoint.data[primaryKey];
    if (!metricValues) {
      return NaN;
    }

    const value = metricValues[metric.id];
    return typeof value === 'number' ? value : NaN;
  }

  private isMultiserver(): boolean {
    return this.serverContexts && this.serverContexts.length > 1;
  }

  private buildWarningsAndErrors() {
    if (!this.serverContexts) {
      return;
    }

    this.clearWarnings();
    this.clearErrors();

    if (this.chartData && this.chartData.dataSeries && this.chartData.dataSeries.length >= 20) {
      this.pushWarning('Too many data series. Showing the first 20 only.');
    }
    this.serverContexts.forEach(context => {
      const prefix = context.server.name + ' - ';
      if (context.dataLoadWarning) {
        this.pushWarning(prefix + context.dataLoadWarning);
      } else if (context.missingSensors && context.missingSensors.length) {
        this.pushWarning(prefix + 'Missing sensors: ' + context.missingSensors.join(', '));
      } else if (context.disabledSensors && context.disabledSensors.length) {
        this.pushWarning(prefix + 'Disabled sensors: ' + context.disabledSensors.join(', '));
      }
    });
  }

  onZoom(zoomEvent: ZoomEvent) {
    this.dashboardService.setSelectedIntervalOption(null);
    this.dashboardService.setTimeInterval(new DashboardTimeInterval(zoomEvent.start, zoomEvent.end));
  }

  onWarning(warning: string) {
    if (warning) {
      this.pushWarning(warning);
    }
  }

  loadCustomQuery() {
    this.chartData = null;
    this.isLoading = !this.config.queryString ? false : true;
    this.statusMessage = !this.config.queryString ? 'Run a query to see graph.' : null;
    this.clearWarnings();
    this.clearErrors();
    this.clearQueryResult();
    this.createSession();
    this.runQuery();
  }

  createSession() {
    const database = new InformixDatabase(this.dashboardService.getServers()[this.config.serverIndex ?
      this.config.serverIndex : 0], { name: this.config.databaseName });
    if (this.session) {
      if (this.session.database.name === database.name) {
        return;
      }
      this.schemaService.closeSession(this.session).subscribe(null);
      this.session = null;
      if (this.sessionSub) {
        this.sessionSub.unsubscribe();
        this.sessionSub = null;
      }
    }

    if (this.sessionSub) {
      return;
    }
    if (!this.config.queryString) {
      return;
    }
    this.sessionSub = this.schemaService.createSession(database, 'monitor').subscribe(session => {
      this.session = session;
      this.runQuery();
    }, err => {
      this.isLoading = false;
      this.translate.get('database.failedToConnectToDatabase',
        { suffix: ((err.error && err.error.err) ? err.error.err : 'An unknown error occurred') })
        .subscribe((text: string) => {
          this.sessionError = text;
          this.statusMessage = this.sessionError;
        });
      console.error(err);
    });
  }

  private runQuery() {
    if (this.querySub) {
      return;
    }
    if (!this.session || !this.config.queryString) {
      return;
    }
    this.query = new Query(this.schemaService, this.session, this.config.queryString);
    this.querySub = this.query.next().subscribe(result => {
      this.isLoading = false;
      const results = result.output[0];
      if(!results.isSuccess) {
        this.queryError = results.err;
      } else if (typeof result === 'string' || (results.rows && !results.rows.length)) {
        this.translate.get('systemSettings.customReports.sqlQueryNoDataError').subscribe((text: string) => {
          this.queryError = text;
          this.statusMessage = this.queryError;
        });
      } else {
        this.queryResult = results;
        if (this.queryResult && this.queryResult.columnNames && this.queryResult.columnNames.length > 0 &&
          this.queryResult.rows && this.queryResult.rows.length > 0) {
          this.chartData = {
            dataSeries: [],
            timestamps: []
          };
          const colNames = [];
          let index = null;
          this.timeStampIndex = this.queryResult.columnNames.indexOf('timestamp');
          this.queryResult.rows.forEach((row) => {
            if (this.timeStampIndex > -1) {
              const timeStamp = row.values[this.timeStampIndex].value.toString();
              if (this.isNumber(timeStamp)) {
                row.values[this.timeStampIndex].value = new Date(Number(timeStamp));
              } else {
                row.values[this.timeStampIndex].value = new Date(timeStamp.replace('[{"a-zA-Z$:}]'));
              }
            }
            row.values.map((value, i) => {
              if (value.isJson) {
                index = i;
                for (const key in value.value) {
                  if (value.value.hasOwnProperty(key)) {
                    row.values.push(new QueryResultValue({ value: value.value[key] }));
                    colNames.push(key);
                  }
                }
                row.values.splice(index, 1);
              }
            });
          });
          if (index) {
            this.queryResult.columnNames.splice(index, 1);
          }
          colNames.forEach(col => {
            if (this.queryResult.columnNames.indexOf(col) === -1) {
              this.queryResult.columnNames.push(col);
            }
          });
          if (this.timeStampIndex > -1) {
            this.queryResult.rows.forEach(row => {
              this.chartData.timestamps.push(row.values[this.timeStampIndex].value);
            });
            this.queryResult.columnNames.forEach((col, i) => {
              if (col !== 'timestamp') {
                this.createDataSeries(col, i);
              }
            });
            this.dashboardService.setCustomQueryColumns(this.queryResult.columnNames.filter(col => col !== 'timestamp'));
          } else {
            if (this.config.chartType === 'pie' || this.config.chartType === 'table') {
              this.queryResult.columnNames.forEach((col, i) => {
                this.createDataSeries(col, i);
              });
              this.dashboardService.setCustomQueryColumns(this.queryResult.columnNames);
            } else {
              this.chartData = null;
              this.statusMessage = 'For visualizing in line/bar graph, Run that query which have \'timestamp\' column.';
            }
          }
          this.chartYAxes = this.config.yAxes.length > 0 ? this.config.yAxes.slice(0, Math.min(this.config.yAxes.length, 1)) :
            [{ type: 'number' }];
        } else {
          this.statusMessage = 'No data available.';
        }
      }
    }, err => {
      this.isLoading = false;
      this.queryError = (err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred.';
      this.query = null;
      if (err.status === 403) {
        this.statusMessage = 'You do not have permissions to view this information.';
      } else {
        this.statusMessage = (err.error && err.error.err) ? err.error.err : err;
      }
    }).add(() => {
      this.querySub = null;
    });
  }

  createDataSeries(col, index) {
    const data: ChartjsDataSeries = {
      label: this.config.dataSeries ? this.config.getSeriesConfig(col).label : col,
      color: this.config.dataSeries ? this.config.getSeriesConfig(col).color : ChartJSUtils.getRandomColor(),
      values: this.queryResult.rows.map(row => row.values[index].value),
      yAxis: 0
    };
    this.chartData.dataSeries.push(data);
  }

  private clearQueryResult() {
    this.query = null;
    this.queryResult = null;
    this.queryError = null;
  }

  isNumber(value) {
    if (value && (/^-?(\d*\.)?\d+$/.test(value.toString()))) {
      return typeof Number(value) === 'number' && !(value !== value);
    } else {
      return false;
    }
  }
}
