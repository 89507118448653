/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SchemaService } from '../../schema.service';
import { InformixServer } from '../../../informixServer';
import { StorageScheme } from '../../create-index/create-index.model';
import { Table } from '../create-table.model';

@Component({
  selector: 'app-table-adv-options',
  templateUrl: './table-adv-options.component.html',
  styleUrls: ['../create-table.component.scss']
})
export class TableAdvOptionsComponent implements OnInit, OnChanges {

  @Output() backToConstraint = new EventEmitter<string>();
  @Output() advTableObj = new EventEmitter<any>();
  @Input() queryError;
  @Input() session;
  @Input() dataObj: Table;
  advTableForm: UntypedFormGroup;

  exptRows: UntypedFormControl;
  growthRate: UntypedFormControl;
  storageScheme: UntypedFormControl;
  isSchemeValid: boolean;
  threshold: UntypedFormControl;
  autoUpdate: UntypedFormControl;
  lockMode: UntypedFormControl;
  firstExtent: UntypedFormControl;
  nextExtent: UntypedFormControl;
  isVercols: UntypedFormControl;
  isCrcols: UntypedFormControl;
  isReplcheck: UntypedFormControl;
  isErKey: UntypedFormControl;
  isRowLevelAudit: UntypedFormControl;
  isCompressed: UntypedFormControl;
  schemeDataObj: StorageScheme;
  createQueryClicked = false;
  isBackToIndex = false;
  isCreateTable = false;
  adtRows = false;
  server: InformixServer;

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private schemaService: SchemaService
  ) { }

  ngOnInit() {
    this.threshold = new UntypedFormControl(this.dataObj['thresholdValue'] ? this.dataObj['thresholdValue'] : null,
      [Validators.min(0), Validators.max(100)]);
    this.autoUpdate = new UntypedFormControl(this.threshold.value ? 'threshold' : 'system');
    this.lockMode = new UntypedFormControl(this.dataObj['isPageMode'] ? 'page' : 'row', [Validators.required]);
    this.isVercols = new UntypedFormControl(this.dataObj['isVercols'] ? true : false);
    this.isCrcols = new UntypedFormControl(this.dataObj['isCrcols'] ? true : false);
    this.isReplcheck = new UntypedFormControl(this.dataObj['isReplcheck'] ? true : false);
    this.isErKey = new UntypedFormControl(this.dataObj['isErKey'] ? true : false);
    this.isRowLevelAudit = new UntypedFormControl({ value: this.adtRows && this.dataObj['isRowLevelAudit'] ? true : false,
                                                    disabled: true });
    this.isCompressed = new UntypedFormControl(this.dataObj['isCompressed'] ? true : false);
    this.firstExtent = new UntypedFormControl(this.dataObj['firstExtent'] ? this.dataObj['firstExtent'] : null,
      [Validators.min(8), Validators.max(33554430)]);
    this.nextExtent = new UntypedFormControl(this.dataObj['nextExtent'] ? this.dataObj['nextExtent'] : null,
      [Validators.min(8), Validators.max(33554430)]);
    this.storageScheme = new UntypedFormControl('tableStorage');
    this.exptRows = new UntypedFormControl(null, [Validators.min(0)]);
    this.growthRate = new UntypedFormControl('static');
    this.advTableForm = this.fb.group({
      lockMode: this.lockMode,
      autoUpdate: this.autoUpdate,
      threshold: this.threshold,
      exptRows: this.exptRows,
      growthRate: this.growthRate,
      isVercols: this.isVercols,
      isCrcols: this.isCrcols,
      isReplcheck: this.isReplcheck,
      isErKey: this.isErKey,
      isRowLevelAudit: this.isRowLevelAudit,
      isCompressed: this.isCompressed,
      firstExtent: this.firstExtent,
      nextExtent: this.nextExtent,
      storageScheme: this.storageScheme
    });

    this.route.data.subscribe(data => {
      this.server = data.server;
      this.schemaService.getDatabaseInfo(this.server, this.session.database.name).subscribe(res => {
        this.adtRows = res.adtRows;
        if (this.adtRows) {
          this.isRowLevelAudit.enable();
        } else {
          this.isRowLevelAudit.disable();
        }
      });
    });

    this.storageScheme.valueChanges.subscribe(value => {
      if (value === 'specifyStorage') {
        this.createQueryClicked = false;
        this.isCreateTable = true;
        if (this.dataObj) {
          this.dataObj['columnsList'] = JSON.parse(JSON.stringify(this.dataObj['tableColumnsList']));
          this.dataObj['storageScheme'] = this.schemeDataObj;
          this.dataObj['columnsList'].forEach(col => {
            col['indexedColumnName'] = col.tableColumnName;
            col['type'] = col.columnDataType;
          });
        }
      }
    });

    this.autoUpdate.valueChanges.subscribe(value => {
      if (value === 'threshold') {
        this.threshold.setValue(10);
      } else {
        this.threshold.setValue(null);
      }
    });

    if (this.schemeDataObj) {
      this.storageScheme.setValue('specifyStorage');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['queryError'] && changes['queryError'].currentValue === true) {
      this.createQueryClicked = false;
    }
    if (changes['dataObj'] && this.dataObj['storageScheme']) {
      this.schemeDataObj = this.dataObj['storageScheme'];
    }
  }

  getSchemeData(schemeDataObj) {
    if (schemeDataObj.storageScheme) {
      this.schemeDataObj = { ...schemeDataObj.storageScheme };
      if (!this.isSchemeValid) {
        this.schemeDataObj = null;
      }
    } else {
      this.schemeDataObj = null;
    }
  }

  createAdvTableObj() {
    const obj = {
      isVercols: this.isVercols.value,
      isCrcols: this.isCrcols.value,
      isReplcheck: this.isReplcheck.value,
      isErKey: this.isErKey.value,
      isCompressed: this.isCompressed.value,
      isPageMode: this.lockMode.value === 'page' ? true : false
    };
    if (this.storageScheme.value === 'specifyStorage') {
      obj['storageScheme'] = this.schemeDataObj ? this.schemeDataObj : null;
    } else if (this.dataObj && this.dataObj['storageScheme']) {
      delete this.dataObj['storageScheme'];
    }
    if (this.adtRows) {
      obj['isRowLevelAudit'] = this.isRowLevelAudit.value;
    } else if (this.dataObj && this.dataObj['isRowLevelAudit']) {
      delete this.dataObj['isRowLevelAudit'];
    }
    if (this.autoUpdate.value === 'threshold') {
      obj['thresholdValue'] = this.threshold.value;
    } else if (this.dataObj && this.dataObj['thresholdValue']) {
      delete this.dataObj['thresholdValue'];
    }
    if (this.firstExtent.value) {
      obj['firstExtent'] = this.firstExtent.value;
    } else if (this.dataObj && this.dataObj['firstExtent']) {
      delete this.dataObj['firstExtent'];
    }
    if (this.nextExtent.value) {
      obj['nextExtent'] = this.nextExtent.value;
    } else if (this.dataObj && this.dataObj['nextExtent']) {
      delete this.dataObj['nextExtent'];
    }
    this.advTableObj.emit(obj);
  }

  viewQuery() {
    if (this.storageScheme.value === 'specifyStorage') {
      this.createQueryClicked = true;
    }
    setTimeout(() => {
      this.createAdvTableObj();
      this.backToConstraint.emit('viewQuery');
    });
  }

  back(type: string) {
    this.isBackToIndex = true;
    if (type === 'back') {
      setTimeout(() => {
        this.createAdvTableObj();
        this.backToConstraint.emit('back');
      }, 0);
    } else if (type === 'cancel') {
      this.backToConstraint.emit('cancel');
    }
  }

  doEstimate() {
    const rowSize = this.getRowSize();
    const nrows = Number(this.exptRows.value);
    const nextExtent = this.growthRateFunc(nrows, rowSize, this.growthRate.value, 1);
    const firstExtent = this.growthRateFunc(nrows, rowSize, this.growthRate.value, 0);
    this.advTableForm.controls['firstExtent'].setValue(firstExtent);
    this.advTableForm.controls['nextExtent'].setValue(nextExtent);
  }

  getRowSize() {
    let rowSize = 0;
    this.dataObj.tableColumnsList.forEach(column => {
      rowSize += this.getStorageSize(column);
    });
    return rowSize;
  }

  growthRateFunc(nrows: number, rowSize: number, growth, type: number) {
    let gr = 1; // growth rate.
    const min = 16; // minimum extent size : 16kb appears to be the default.
    let ngrowth = 1; // the growth of the next extent.
    let ret; // The return value.
    switch (growth) {
      case 'static':
        gr = 1;
        ngrowth = 0;
        break;
      case 'medium':
        ngrowth = 4;
        if (nrows >= 100000) {
          gr = 1.25;
        } else {
          gr = 1.125;
        }
        break;
      case 'aggressive':
        ngrowth = 2;
        if (nrows >= 100000) {
          gr = 1.5;
        } else {
          gr = 1.25;
        }
        break;
    }
    if (type === 0) {
      ret = Math.max(Math.ceil(Math.max((rowSize * (nrows * gr)) / 1010, min)), min);
    } else {
      if (ngrowth === 0) {
        ret = min;
      } else {
        ret = Math.max(Math.ceil(Math.max((rowSize * (nrows * gr)) / 1010, min) / ngrowth), min);
      }
    }
    if (ret % 2 !== 0) {
      ret++;
    }
    if (ret > 33554430) {
      ret = 33554430;
    }
    return Number(ret);
  }

  getStorageSize(column) {
    let size;
    switch (column.columnDataType.toUpperCase()) {
      case 'BIGINT':
      case 'BIGSERIAL':
      case 'FLOAT':
        size = 8;
        break;
      case 'BLOB':
      case 'CLOB':
        size = 76;
        break;
      case 'BOOLEAN':
      case 'SMALLINT':
        size = 2;
        break;
      case 'BSON':
      case 'JSON':
      case 'LONGLVARCHAR':
        size = 0;
        break;
      case 'BYTE':
      case 'TEXT':
        size = 56;
        break;
      case 'CHAR':
      case 'NCHAR':
        size = column.dataTypeAttributes.length;
        break;
      case 'DATE':
      case 'INTEGER':
      case 'SERIAL':
      case 'SMALLFLOAT':
        size = 4;
        break;
      case 'DATETIME':
      case 'INTERVAL':
        size = this.getDateTimeAndIntervalSize(column);
        break;
      case 'DECIMAL':
      case 'MONEY':
        size = (column.dataTypeAttributes.precisionDigit * 256) + column.dataTypeAttributes.scale;
        break;
      case 'LVARCHAR':
        size = column.dataTypeAttributes.length + 3;
        break;
      case 'NVARCHAR':
      case 'VARCHAR':
        size = column.dataTypeAttributes.length + 1;
        break;
      case 'NAMEDROW':
        size = column.dataTypeAttributes.namedRowDataLength;
        break;
      case 'LIST':
      case 'MULTISET':
      case 'SET':
        size = this.getSetStorageSize(column);
        break;
    }
    return size;
  }

  getDateTimeAndIntervalSize(column: any): number {
    // (total number of digits for all fields)/2 + 1
    // All fields of a DATETIME/INTERVAL column are two-digit numbers except for the year and fraction fields.
    // The year field is stored as four digits.
    // For Interval, if the field is the largest qualifier, the digits it requires will be equal to the precision value.
    // The fraction field requires n digits where 1 ≤ n ≤ 5, rounded up to an even number.
    const precisionDigit = column.dataTypeAttributes.precisionDigit;
    const largestTimeUnit = column.dataTypeAttributes.largestTimeUnit;
    const scale = column.dataTypeAttributes.scale;
    let arr = [];
    if (column.columnDataType === 'dateTime') {
      arr = [{ time: 'YEAR', digits: 4 },
      { time: 'MONTH', digits: 2 },
      { time: 'DAY', digits: 2 },
      { time: 'HOUR', digits: 2 },
      { time: 'MINUTE', digits: 2 },
      { time: 'SECOND', digits: 2 },
      { time: 'FRACTION', digits: (scale % 2 === 0) ? scale : scale + 1 }];
    } else {
      arr = [{ time: 'YEAR', digits: largestTimeUnit === 'YEAR' ? precisionDigit : 4 },
      { time: 'MONTH', digits: largestTimeUnit === 'MONTH' ? precisionDigit : 2 },
      { time: 'DAY', digits: largestTimeUnit === 'DAY' ? precisionDigit : 2 },
      { time: 'HOUR', digits: largestTimeUnit === 'HOUR' ? precisionDigit : 2 },
      { time: 'MINUTE', digits: largestTimeUnit === 'MINUTE' ? precisionDigit : 2 },
      { time: 'SECOND', digits: largestTimeUnit === 'SECOND' ? precisionDigit : 2 },
      { time: 'FRACTION', digits: (scale % 2 === 0) ? scale : scale + 1 }];
    }

    let start = 0;
    let end = 0;
    arr.forEach((item, index) => {
      if (item.time === largestTimeUnit) {
        start = index;
      }
      if (item.time === column.dataTypeAttributes.smallestTimeUnit) {
        end = index;
      }
    });

    let total_digits = 0;
    for (let i = start; i <= end; i++) {
      total_digits = total_digits + arr[i].digits;
    }

    return Math.round(total_digits / 2) + 1;
  }

  getSetStorageSize(column) {
    const INTSIZE = 2;
    const LONGSIZE = 4;
    const SIZTBLOB = ((11 * LONGSIZE) + (6 * INTSIZE));
    const COLL_ALIGN = 8;
    const COLL_FLAGHASH_SIZE = 8;
    const COLL_HEADERSIZE = (8 + COLL_FLAGHASH_SIZE);
    const COLLHDRSIZE = (SIZTBLOB + COLL_HEADERSIZE);
    // Size varies with IDS bitness. In IDS 11.50.FC5, it's 120 bytes
    const IFX_COLLECTION_T_SIZE = 120;
    const COLLECTION_SIZE = 2048;
    const SLOT_T_SIZE = 4;
    const IFX_PAGE_T_SIZE = 24;
    let elementSize = 1;
    elementSize = this.getStorageSize(column.setDataType);

    // Initial size is 20 elements plus header overhead
    let dataLength = COLLHDRSIZE + (elementSize * 20);
    // Adjust so there'll be enough space should the collection data spill over into a tuple blob
    dataLength = Math.max(dataLength, (IFX_COLLECTION_T_SIZE + COLL_FLAGHASH_SIZE + COLL_ALIGN));

    const maxLength = (COLLECTION_SIZE - IFX_PAGE_T_SIZE - 4) - SLOT_T_SIZE;
    return Math.min(dataLength, maxLength);
  }
}
