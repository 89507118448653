/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractSettingsComponent } from '../../user-settings/alerts/abstractSettings.component';
import { AbstractAlertConfigComponent, AlertConfig } from './alertConfig';
import { alertConfigHelp } from './alertConfigHelp';

@Component({
  selector: 'app-alert-config-container',
  templateUrl: 'alertConfigContainer.html',
  styles: [
    '.form-inline .badge {font-size: 110%}'
  ]
})
export class AlertConfigContainerComponent implements OnChanges, AfterContentInit {

  @Input() config: AlertConfig = null;
  @Input() isEmail?: Boolean = false;
  enableAlert = false;

  descriptionSafe: SafeHtml;

  testSetting: any = [];

  @Output() eventSave = new EventEmitter<AlertConfig>();
  @Output() eventTest = new EventEmitter<AlertConfig>();

  @ContentChild('config', { static: true }) configComponent: AbstractAlertConfigComponent;
  @ContentChild('test', { static: true }) testSettingsComponent: AbstractSettingsComponent;

  constructor(private sanitizer: DomSanitizer) { }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['config'] && this.configComponent) {
      this.configComponent.setConfig(this.config);
      if (this.config && this.config.hasOwnProperty('enabled')) {
        this.enableAlert = this.config.enabled;
      } else {
          this.enableAlert = false;
      }
    }
  }

  ngAfterContentInit() {
    this.configComponent.setConfig(this.config);
    const descriptionUnsafe = alertConfigHelp[this.configComponent.getType()];
    if (descriptionUnsafe) {
      this.descriptionSafe = this.sanitizer.bypassSecurityTrustHtml(alertConfigHelp[this.configComponent.getType()]);
    }
  }

  saveConfig() {
    const newConfig: AlertConfig = this.configComponent.getConfig();
    newConfig.enabled = this.enableAlert;
    this.eventSave.emit(newConfig);
  }

  testConfig() {
    this.eventTest.emit((this.testSettingsComponent) ? this.testSettingsComponent.getSettings() : {});
  }

  isValid(): boolean {
    if (this.configComponent) {
      return this.configComponent.isValid();
    }
    return false;
  }

  isModified(): boolean {
    if (this.config && this.enableAlert !== this.config.enabled) {
      return true;
    }
    if (this.configComponent && this.configComponent.isModified()) {
      return true;
    }
    return false;
  }

  isTestValid(): boolean {
    if (this.testSettingsComponent) {
      return this.testSettingsComponent.isValid();
    } else {
      return true;
    }
  }
}
