<div class="db-border mt-2 p-2">
  <div class="row ml-2 mr-2">
    <div class="col-12 col-lg-2 col-xl-3 form-inline mt-2 p-0 font-weight-bold tab-title">
      {{ 'schemaManager.dbStore' | translate }}
    </div>
    <div class="col-12 col-lg-12 col-xl-9 mt-2 p-0 justify-content-end form-inline">
      <label class="pr-2 pl-1 font-weight-bold">{{ 'dataTable.filterLabel' | translate }}</label>
      <select class="form-control" [ngModel]="tableFilter" (ngModelChange)="changeTableFilter($event)" [disabled]="disableFilter">
        <option ngValue="all">All</option>
        <option ngValue="system">System Defined</option>
        <option ngValue="user">User Defined</option>
      </select>
    </div>
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbProcedure; else tableDataLoading" class="table-responsive" [data]="filteredDbProcedure"
      [rowsPerPage]="20" [searchLabel]="'schemaManager.dbStoreTab.searchLabel' | translate" sortBy="procname"
      [noDataAlertMessage]="'schemaManager.dbStoreTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'schemaManager.dbStoreTab.procedure' | translate" property="procname"
        [sortable]="true" [searchable]="true">
        <ng-template appTableCell let-row="row" let-item="item">
          <a href="javascript:void(0)" (click)="openProDetails(row.procid, item)">{{item}}</a>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.type' | translate" property="type" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.mode' | translate" property="mode" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.language' | translate" property="lang" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbStoreTab.argument' | translate" property="numargs"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbStoreTab.parameter' | translate" property="paramtypes"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbStoreTab.returnSize' | translate" property="retsize"
        [sortable]="true">
        <ng-template appTableCell let-size="item">
          {{size | byte}}
        </ng-template>
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>
<div bsModal #procedureModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content modal-margin">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Details for {{currentProcName}}</h5>
        <button type="button" class="close" (click)="closeModal()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="py-2 px-3 procedure-container" *ngIf="dbProcedureId?.sql_alter">
        <pre>{{dbProcedureId ? dbProcedureId.sql_alter : ''}}</pre>
      </div>
      <div class="py-2 px-3 procedure-container" *ngIf="dbProcedureId?.sql_create">
        <pre>{{dbProcedureId ? dbProcedureId.sql_create : ''}}</pre>
      </div>
      <button type="button" class="btn btn-secondary modal-button col-1 my-2" (click)="closeModal()">
        OK</button>
    </div>
  </div>
</div>
