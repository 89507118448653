/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023. All Rights Reserved.
 *******************************************************************************/

interface ReplicateAction {
    name: string;
    value: string;
    isValid: boolean;
}

interface Status {
    name: string;
    color: string;
  }

export interface participant {
    groupName: string;
    databaseName: string;
    owner: string;
    tabName: string;
    selectStmt: string;
    participantType?: string;
    isMasterServer: boolean;
    isCreateMissingTable?: boolean;
    optional?: any;
    partMode?: string;
    status?: string;
}

export const ReplicateActionMap: { [key: string]: ReplicateAction } = {
    EDIT: { name: 'Edit', value: 'edit', isValid: true },
    START: { name: 'Start', value: 'start', isValid: true },
    STOP: { name: 'Stop', value: 'stop', isValid: true },
    SUSPEND: { name: 'Suspend', value: 'suspend', isValid: true },
    RESUME: { name: 'Resume', value: 'resume', isValid: true },
    CHECK: { name: 'Check replicate', value: 'check_replicate', isValid: true },
    SYNC: { name: 'Sync replicate', value: 'sync_replicate', isValid: true },
    CHECK_SYNC: { name: 'Check or Sync Tasks', value: 'check_or_sync_tasks', isValid: true },
    VIEW: { name: 'View participants', value: 'view_participants', isValid: true },
    DELETE: { name: 'Delete', value: 'delete', isValid: true }
};

export const statusMap: { [key: string]: Status } = {
    A: { name: 'Aborted', color: 'red' },
    D: { name: 'Defined', color: 'green-30' },
    R: { name: 'Running', color: 'green-30' },
    C: { name: 'Completed', color: 'green-30' },
    F: { name: 'Completed, but inconsistent', color: 'green-30' },
    W: { name: 'Pending complete', color: 'green-30' },
    Failed: { name: 'Failed', color: 'red' },
};

type ArrayObject = Array<{ name: string; value: string }>;

export const ReplicateActions = [
    { name: 'Participants', value: 'participants', isValid: true },
    { name: 'Modify Replicate', value: 'modify', isValid: true },
    { name: 'Check replicate', value: 'check_replicate', isValid: true },
    { name: 'Sync replicate', value: 'sync_replicate', isValid: true },
    { name: 'Check or Sync Tasks', value: 'check_or_sync_tasks', isValid: true },
    { name: 'Delete', value: 'delete', isValid: true }
];

const ConflictRules: ArrayObject = [
    { name: 'Ignore', value: 'ignore' },
    { name: 'Timestamp', value: 'timestamp' },
    { name: 'SPL routine', value: 'SPL_routine' },
    { name: 'Timestamp with SPL routine', value: 'Time_stamp_with_SPL_routine' },
    { name: 'Delete wins', value: 'deletewins' },
    { name: 'Always apply', value: 'always' },
];

const ConflictScope: ArrayObject = [
    { name: 'Row scope', value: 'row' },
    { name: 'Transaction scope', value: 'transaction' }
];

const frequencyTypes: ArrayObject = [
    { name: 'Immediately', value: 'immediately' },
    { name: 'Every', value: 'every' },
    { name: 'Time of day', value: 'time_of_day' }
];

const FrequencyRepeats: ArrayObject = [
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Last Day of Month', value: 'last_day_of_month' }
];

const Days: ArrayObject = [
    { name: 'Sunday', value: 'sunday' },
    { name: 'Monday', value: 'monday' },
    { name: 'Tuesday', value: 'tuesday' },
    { name: 'Wednesday', value: 'wednesday' },
    { name: 'Thursday', value: 'thursday' },
    { name: 'Friday', value: 'friday' },
    { name: 'Saturday', value: 'saturday' }
];

export const timeFormats: ArrayObject = [
    { name: 'Minutes', value: 'minutes' },
];

export class ErReplicate {
    conflictRules = [];
    replicateActions = [];
    conflictScope = [];
    frequencyTypes = [];
    frequencyRepeats = [];
    days = [];

    constructor() {
        this.conflictRules = ConflictRules;
        this.replicateActions = ReplicateActions;
        this.conflictScope = ConflictScope;
        this.frequencyTypes = frequencyTypes;
        this.frequencyRepeats = FrequencyRepeats;
        this.days = Days;
    }
}

export class Participant {

    groupName: string;
    databaseName: string;
    owner: string;
    participantType: string;
    isMasterServer: Boolean;
    tabName: string;
    selectStmt: string;
    isCreateMissingTable: Boolean;
    isChecked: Boolean;

    constructor(json: any) {
        this.groupName = json.groupName;
        this.databaseName = json.databaseName;
        this.owner = json.owner;
        this.participantType = json.participantType;
        this.isMasterServer = json.isMasterServer;
        this.tabName = json.tabName;
        this.selectStmt = json.selectStmt;
        this.isCreateMissingTable = json.isCreateMissingTable;
        this.isChecked = json.isChecked;
    }
}
