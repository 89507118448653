/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { EnterpriseReplicationComponent } from './enterprise-replication/enterprise-replication.component';
import { ErDomainGraphComponent } from './er-domain-graph/er-domain-graph.component';
import { ErDomainComponent } from './er-domain/er-domain.component';
import { SelectHQGroupsModalComponent } from './er-domain/select-hq-groups-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ERDefineNodeComponent } from './er-domain/sidebars/er-define/er-define.component';
import { ERNodeInfoComponent } from './er-domain/sidebars/er-info/er-info-sidebar.component';
import { ERPrerequiestiesComponent } from './prerequiesties/prerequiesties.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ERAdvancedOptionsComponent } from './er-domain/sidebars/er-advanced-options/er-advanced-options.component';
import { SqlhostsGroupsModalComponent } from './enterprise-replication/sqlhosts-groups-modal.component';
import { NewReplicateComponent } from './replicates/new-replicate/new-replicate.component';
import { CheckSyncTaskComponent } from './replicates/check-sync-task/check-sync-task.component';
import { TaskDetailsComponent } from './replicates/task-details/task-details.component';
import { ParticipantModalComponent } from './replicates/participant-modal/participant-modal.component';
import { ERDomainLeftPanelComponent } from './er-domain/sidebars/er-group-list/er-group-list-sidebar.component';
import { CheckReplicateModalComponent } from './replicates/check-replicate-modal/check-replicate-modal.component';
import { SyncReplicateModalComponent } from './replicates/sync-replicate-modal/sync-replicate-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { guideModalComponent } from '../../../shared/guide-modal/guide-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    BsDatepickerModule,
  ],
  declarations: [
    EnterpriseReplicationComponent,
    ErDomainGraphComponent,
    ErDomainComponent,
    SelectHQGroupsModalComponent,
    ERDefineNodeComponent,
    ERNodeInfoComponent,
    ERPrerequiestiesComponent,
    ERAdvancedOptionsComponent,
    SqlhostsGroupsModalComponent,
    ERDomainLeftPanelComponent,
    NewReplicateComponent,
    TaskDetailsComponent,
    CheckSyncTaskComponent,
    ParticipantModalComponent,
    CheckReplicateModalComponent,
    SyncReplicateModalComponent,
    guideModalComponent
  ],
  exports: [ CheckReplicateModalComponent,
    SyncReplicateModalComponent
  ]
})
export class EnterpriseReplicationModule { }
