<ng-container *ngIf="!server.agent.online; else agentOnline">
  <div class="row">
    <div class="col col-lg-8">
      <div class="alert alert-danger">
        The agent is offline.
      </div>
    </div>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="deployAgent()">
    <div class="form-group font-weight-bold">
      <app-checkbox formControlName="overwrite">
        Overwrite existing agent
      </app-checkbox>
    </div>
    <div class="row" *ngIf="formGroup.controls.overwrite.value">
      <div class="col col-lg-8">
        <div class="alert alert-warning">
          Agent JAR and any other configuration files will be overwritten!
        </div>
      </div>
    </div>
    <div [hidden]="!showForm">
      <div class="form-group">
        <label class="col-sm-4 col-lg-2 col-form-label font-weight-bold">Username</label>
        <div class="col-sm-8 col-lg-6">
          <input class="form-control" type="text" formControlName="username" tooltip="" appExtendedFormControl>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-4 col-lg-2 font-weight-bold">
          <app-radio-group formControlName="authType">
            <app-radio value="password">
              Password
            </app-radio>
            <app-radio class="ml-4" value="identity">
              Identity File
            </app-radio>
          </app-radio-group>
        </div>
        <div class="col-sm-8 col-lg-6" [ngSwitch]="formGroup.value.authType">
          <div class="input-group password-hide-show inner-addon right-addon">
            <input *ngSwitchCase="'password'" class="form-control" [type]="showPassword ? 'text' : 'password'"
              formControlName="password" tooltip="" (keyup)="toggleShow(true)" appExtendedFormControl>
            <i class="icon icon-eye" (click)="toggleShow()"
              *ngIf="showPassword && (formGroup.controls['password'].dirty && formGroup.value.password !== '')"></i>
            <i class="icon icon-eye-blocked" (click)="toggleShow()"
              *ngIf="!showPassword && ( formGroup.controls['password'].dirty && formGroup.value.password !== '')"></i>
          </div>
          <input *ngSwitchCase="'identity'" class="form-control" type="text" formControlName="identityFile" tooltip=""
            appExtendedFormControl placeholder="Example: ~/.ssh/id_rsa">
        </div>
      </div>
      <div class="form-group" *ngIf="formGroup.value.authType === 'identity'">
        <label class="col-sm-4 col-lg-2 col-form-label font-weight-bold">Passphrase</label>
        <div class="col-sm-8 col-lg-6">
          <input class="form-control" type="text" formControlName="passphrase">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-4 col-lg-2 col-form-label font-weight-bold">Remote directory</label>
        <div class="col-sm-8 col-lg-6">
          <input class="form-control" type="text" formControlName="remoteDir" tooltip="" appExtendedFormControl
            placeholder="Example: /opt/informixhq-agent">
        </div>
      </div>
      <ng-container *ngIf="sslEnabled">
        <h5>
          SSL Configuration
        </h5>
        <div class="row" *ngIf="!formGroup.controls.keystoreFile.value">
          <div class="col col-lg-8">
            <div class="alert alert-info">
              If a keystore is not configured, {{productNameNoSpace}} will try to generate one with the required certificate.
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-sm-4 col-lg-2 col-form-label">Keystore file</label>
          <div class="col-sm-8 col-lg-6">
            <input class="form-control" type="text" formControlName="keystoreFile" placeholder="Example: keystore.jks">
            <small class="form-text text-muted">
              This path can be relative to the remote directory configured above
            </small>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-sm-4 col-lg-2 col-form-label">Keystore password</label>
          <div class="col-sm-8 col-lg-6">
            <input class="form-control" type="password" formControlName="keystorePassword" tooltip=""
              appExtendedFormControl>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-sm-4 col-lg-2 col-form-label">Keystore type</label>
          <div class="col-sm-8 col-lg-6">
            <input class="form-control" type="text" formControlName="keystoreType" placeholder="Default: JKS">
          </div>
        </div>
      </ng-container>
    </div>
    <div class="d-flex align-items-center">
      <button type="submit" class="btn btn-primary" [disabled]="!canDeploy()">
        Deploy agent
      </button>
      <button type="button" class="btn btn-secondary ml-1" *ngIf="!showForm && !deployStatus" (click)="showForm = true">
        <i class="icon icon-manage"></i>
      </button>
      <ng-container *ngIf="deployStatus">
        <div class="ml-2">
          <i class="icon icon-circle-notch icon-spin"></i>
        </div>
        <div class="ml-2">
          {{deployStatus}}
        </div>
      </ng-container>
    </div>
  </form>
</ng-container>
<ng-template #agentOnline>
  <div class="row">
    <div class="col col-lg-8">
      <div class="alert alert-success">
        The agent is online.
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <button type="button" class="btn btn-danger" (click)="shutdownAgent()" [disabled]="isShuttingDown">
      Shutdown agent
    </button>
    <div class="ml-2" *ngIf="isShuttingDown">
      <i class="icon icon-circle-notch icon-spin"></i>
    </div>
  </div>
</ng-template>
