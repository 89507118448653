<ng-template [ngIf]="server && monitoringProfile && uiCharts">

  <div class="row justify-content-between">
    <div class="col-sm">
      <h4 class="page-header">
        <strong>{{dashboard.title}}</strong>
      </h4>
    </div>
    <div class="col-sm">
      <div class="row justify-content-end no-gutters">
        <div class="col-auto">
          <app-time-slice [isPaused]="isPaused" (timeSliceChanged)="changeTimeSlice($event)" (dataPaused)="pauseData()" (dataResumed)="resumeData()"> </app-time-slice>
        </div>
        <div class="col-auto">
          <app-sensors-required-control [server]="server" [monitoringProfile]="monitoringProfile" [sensorList]="requiredSensorList" (monitoringProfileUpdated)="getMonitoringProfile()"></app-sensors-required-control>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let row of uiCharts">
    <div *ngFor="let chart of row" class="col mt-2 mb-2" [ngClass]="chart.cssClass" >
      <div class="card card-body">
         <app-dashboard-component [component]="chart.component" [server]="server" [config]="chart.chart.config"
          [monitoringProfile]="monitoringProfile"></app-dashboard-component>
      </div>
    </div>
  </div>
</ng-template>
