/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TopNavComponent } from '../shared/index';
import { NotificationsComponent } from '../shared/notifications/notifications.component';
import { AuthGuard } from './authGuard.service';
import { DashboardComponent } from './dashboard.component';
import { GroupsModule } from './groups/groups.module';
import { PlaygroundModule } from './playground/playground.module';
import { ServersModule } from './servers/servers.module';
import { SystemSettingsModule } from './system-settings/system-settings.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { SharedModule } from '../shared/shared.module';
import { LogsModule } from './servers/logs/logs.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule,
    ModalModule,
    GroupsModule,
    ServersModule,
    UserSettingsModule,
    SystemSettingsModule,
    TooltipModule,
    PlaygroundModule,
    SharedModule,
    LogsModule
  ],
  declarations: [DashboardComponent, TopNavComponent, NotificationsComponent],
  providers: [AuthGuard],
  exports: [DashboardComponent, TopNavComponent]
})

export class DashboardModule { }
