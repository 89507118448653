/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2020. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InformixServer } from '../../servers/informixServer';
import { DashboardTimeInterval } from './dashboard-time-interval';
import { IntervalOption } from './dashboard-time-control/dashboard-time-control.component';

@Injectable()
export class DashboardService {

  private servers: InformixServer[] = null;
  private timeInterval = DashboardTimeInterval.relativeFromNow(-60000);

  serversChanged = new Subject<InformixServer[]>();
  timeIntervalChanged = new Subject<DashboardTimeInterval>();
  selectedIntervaloption: IntervalOption = { seconds: 14400, name: 'last 4 hours' };
  columns: string[] = [];

  constructor() { }

  getServers(): InformixServer[] {
    return this.servers;
  }

  setServers(servers: InformixServer[]) {
    this.servers = servers;
    this.serversChanged.next(servers);
  }

  setSelectedIntervalOption(option) {
    this.selectedIntervaloption = option;
  }

  getSelectedIntervalOption() {
    return this.selectedIntervaloption;
  }

  getTimeInterval(): DashboardTimeInterval {
    return this.timeInterval;
  }

  setTimeInterval(timeInterval: DashboardTimeInterval) {
    this.timeInterval = timeInterval;
    this.timeIntervalChanged.next(timeInterval);
  }

  setCustomQueryColumns(columns) {
    this.columns = columns;
  }

  getCustomQueryColumns() {
    return this.columns;
  }
}
