<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row mb-2 justify-content-between">
    <div class="col-sm">
      <h4>
        <strong>Checkpoints</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
      </h4>
    </div>
    <div *ngIf="selectedTimeSlice && !checkpointApiError" class="col-sm">
      <div class="row justify-content-end no-gutters">
        <div class="col-auto">
          <app-time-slice [timeSlice]="selectedTimeSlice" [isPaused]="isPaused" (timeSliceChanged)="timeSliceChanged($event)" (dataPaused)="pauseData()"
            (dataResumed)="resumeData()"> </app-time-slice>
        </div>
        <div class="col-auto">
          <app-sensors-required-control [server]="server" [monitoringProfile]="monitoringProfile" [sensorList]="['checkpoint']" (monitoringProfileUpdated)="getMonitoringProfile()"></app-sensors-required-control>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && !checkpointApiError else checkpointError">
    <ng-container *ngIf="hasServerData || sensorDataSource; else noData">
      <div class="row">
        <div class="col-12 col-lg-9">
          <div class="card">
            <div class="card-body">
              <div style="height: 300px">
                <canvas height="300" #checkpointsChart [hidden]="checkpointData && checkpointData.length < 1"> </canvas>
                <div class="alert alert-info" *ngIf="checkpointData && checkpointData.length < 1 && !isLoadingData">
                  <i class="icon icon-info-circle text-info"></i> No data available in the last {{selectedTimeSlice.name}}
                </div>
                <i *ngIf="!checkpointData || (isLoadingData && checkpointData.length < 1)" class="icon icon-circle-notch icon-spin"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mt-2 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <ng-container *ngIf="onConfig; else onConfigLoading">
                <form *ngIf="editConfigFormGroup" [formGroup]="editConfigFormGroup">
                  <div style="float: right">
                    <app-check-server-admin-permissions [server]="server" *ngIf="!isEditingConfig">
                      <app-check-hdr-permissions [server]="server" [hdrPermission]="checkpointsHDR">
                          <button type="button" class="btn btn-primary btn-sm" title="Edit Configuration" (click)="doEditConfiguration()" appHDRPermission [hdrPermission]="checkpointsHDR" appPermissionDisable>
                            <i class="icon icon-edit"></i>
                          </button>
                      </app-check-hdr-permissions>
                    </app-check-server-admin-permissions>
                    <button *ngIf="isEditingConfig" type="button" class="btn btn-primary btn-sm" title="Save" (click)="doUpdateConfiguration()"
                      [disabled]="!editConfigFormGroup.valid || !saveConfigButtonEnabled">
                      <i class="icon icon-save"></i>
                    </button>
                    <button *ngIf="isEditingConfig" type="button" class="btn btn-primary btn-sm" title="Undo" (click)="doCancelEditConfiguration()">
                      <i class="icon icon-reset"></i>
                    </button>
                  </div>
                  <div>
                    <strong>Automatic Checkpoints</strong> (AUTO_CKPTS)
                    <br>
                    <label *ngIf="!isEditingConfig">{{onConfig.AUTO_CKPTS == 1 ? 'ON' : 'OFF'}}</label>
                    <select *ngIf="isEditingConfig" class="form-control form-control-sm" formControlName="autoCkpts" (change)="validateEditConfigForm()">
                      <option [ngValue]="1">ON</option>
                      <option [ngValue]="0">OFF</option>
                    </select>
                  </div>
                  <div class="mt-2">
                    <strong>Recovery Time Objective</strong> (RTO_SERVER_RESTART)
                    <br>
                    <label *ngIf="!isEditingConfig">
                      <ng-template [ngIf]="onConfig.RTO_SERVER_RESTART > 0" [ngIfElse]="rtoDisabled">
                        {{onConfig.RTO_SERVER_RESTART | relativeTime}}
                      </ng-template>
                      <ng-template #rtoDisabled>
                        Disabled
                      </ng-template>
                    </label>
                    <div *ngIf="isEditingConfig" class="form-inline">
                      <input *ngIf="isEditingConfig" type="number" class="form-control form-control-sm" style="width: 80px" (change)="validateEditConfigForm()"
                        formControlName="rtoServerRestart" tooltip="" appExtendedFormControl [min]="0" [max]="1800">
                      <label class="ml-1">seconds</label>
                    </div>
                  </div>
                  <div class="mt-2" *ngIf="onConfig.RTO_SERVER_RESTART < 1 || isEditingConfig">
                    <strong>Checkpoint Interval</strong> (CKPTINTVL)
                    <label class="text-muted" *ngIf="isEditingConfig"><em>Applicable only when RTO_SERVER_RESTART is disabled (set to 0 seconds)</em></label>
                    <br>
                    <label *ngIf="!isEditingConfig">{{onConfig.CKPTINTVL | relativeTime}}</label>
                    <div *ngIf="isEditingConfig" class="form-inline">
                      <input *ngIf="isEditingConfig" type="number" class="form-control form-control-sm" style="width: 80px" (change)="validateEditConfigForm()"
                        formControlName="ckptInterval" tooltip="" appExtendedFormControl [min]="0">
                      <label class="ml-1">seconds</label>
                    </div>
                  </div>
                </form>
              </ng-container>
              <ng-template #onConfigLoading>
                <i *ngIf="hasServerData" class="icon icon-circle-notch icon-spin"></i>
                <div class="alert alert-danger" *ngIf="!hasServerData">
                  Server configuration is not available.
                  <br> There was a problem getting the configuration from {{serverType}}.
                </div>
              </ng-template>
            </div>
          </div>
          <div class="mt-4 card">
            <div class="card-body">
              <strong>Run a checkpoint</strong>
              <form *ngIf="runCheckpointFormGroup" [formGroup]="runCheckpointFormGroup">
                <div class="row mt-2">
                  <div class="col form-inline">
                    <label class="mr-1">
                      Type:
                    </label>
                    <select class="form-control form-control-sm mr-3" formControlName="type">
                      <option ngValue="norm">Normal</option>
                      <option ngValue="hard">Sync</option>
                    </select>
                    <app-check-server-admin-permissions [server]="server">
                      <app-check-hdr-permissions [server]="server" [hdrPermission]="checkpointsHDR">
                        <button type="button" class="btn btn-primary btn-sm" (click)="doRunCheckpoint()" appHDRPermission [hdrPermission]="checkpointsHDR" appPermissionDisable>
                          Run Checkpoint
                        </button>
                      </app-check-hdr-permissions>
                    </app-check-server-admin-permissions>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-2" *ngIf="tableData && tableData.length > 0">
        <div class="card-body">
          <app-data-table class="table-responsive" [enableFilter]="true" [data]="tableData" sortBy="timestamp" [sortDescending]="true"
            [rowsPerPage]="20" searchLabel="Search for Interval, Trigger, LSN ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
            <app-data-table-column title="Interval" property="intvl" [sortable]="true" [searchable]="true"> </app-data-table-column>
            <app-data-table-column title="Start Time" property="timestamp" [sortable]="true">
              <ng-template appTableCell let-timestamp="item">
                {{timestamp | date:'y-MM-dd HH:mm:ss'}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Trigger" property="caller" [sortable]="true" [searchable]="true"> </app-data-table-column>
            <app-data-table-column title="LSN" property="lsn" [searchable]="true"> </app-data-table-column>
            <app-data-table-column title="Total Time" property="cp_time" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Crit Time" property="crit_time" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Block Time" property="block_time" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Flush Time" property="flush_time" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Txns Blocked" property="n_crit_waits" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Longest Txn Block Time" property="longest_crit_wait" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Avg Txn Block Time" property="avg_crit_wait" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Dirty Buffers" property="n_dirty_buffs" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Physical Log Pages" property="physused" [sortable]="true"> </app-data-table-column>
            <app-data-table-column title="Logical Log Pages" property="logused" [sortable]="true"> </app-data-table-column>
          </app-data-table>
        </div>
      </div>
    </ng-container>
    <ng-template #noData>
      <div class="alert alert-warning">
        There is no data available. Either supply monitoring credentials or configure the checkpoint sensor.
      </div>
    </ng-template>
  </ng-container>

  <ng-template #checkpointError>
    <div *ngIf="checkpointApiError" class="mt-2 alert alert-danger">
      <i class="icon icon-danger text-danger"></i> Unable to retrieve checkpoints information. <br> Error: {{checkpointApiError}}
    </div>
  </ng-template>
</div>
