<div class="w-100 h-100 d-flex flex-column">
  <div class="flex-grow-1 p-1 overflow-hidden">
    <div class="chart-container">
      <canvas #chartCanvas>
      </canvas>

      <div *ngIf="tooltip" style="position: absolute; width: 0; height: 0" [ngStyle]="tooltip.cssStyle"
        [popover]="chartTooltip" container="body" [isOpen]="tooltipOpen" [placement]="tooltip.placement"
        containerClass="chartjs-popover popover-color">
      </div>
    </div>
  </div>

  <div class="chart-legend p-2" *ngIf="chart">
    <div class="chart-legend-item" *ngFor="let item of legendItems; let i = index"
      [ngClass]="{'chart-legend-item-hidden': item.hidden, 'cursor-pointer': chartType !== 'pie'}" (click)="onLegendItemClick(i)">
      <div class="series-color" [ngStyle]="{'background-color': item.color}">
      </div>
      {{item.label}}
    </div>
  </div>
  <div class="pl-2" *ngIf="chartType === 'pie' && pieTimeStamp">
   <strong>Timestamp: {{pieTimeStamp}}</strong> 
  </div>
</div>

<ng-template #chartTooltip>
  <table>
    <tr>
      <td colspan="3">
        {{tooltip.timestamp | date:'medium'}}
      </td>
    </tr>
    <tr *ngFor="let series of tooltip.dataSeries">
      <td class="series-color">
        <div [ngStyle]="{'background-color': series.color}">
        </div>
      </td>
      <td class="text-truncate" style="max-width: 600px">
        {{series.label}}
      </td>
      <td class="pl-2 text-right">
        {{series.value}}
      </td>
    </tr>
  </table>
</ng-template>
