/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { IACValidators } from '../../../shared/forms/validators';
import { InformixServer } from '../informixServer';
import { ConnectionManagerService } from './connection-manager.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ErrorMessageHandlers } from '../../../shared/forms/extendedFormControl.directive';
import { SLAInfo } from './connection-manager.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sla-form',
  templateUrl: 'sla-form.html',
  styleUrls: ['sla-form.scss']
})
export class SlaFormComponent implements OnInit {

  @Input() server: InformixServer;

  @Input() cmUnitType: string;
  @Input() cmName: string;
  @Input() cmUnitName: string;

  @Input() slaInfoList;
  @Input() slaToEdit: string = null;
  @Output() closeSLA = new EventEmitter<boolean>();

  slaDetailsForm: UntypedFormGroup;
  slaName: UntypedFormControl;
  service: UntypedFormControl;
  connectionMode: UntypedFormControl;
  useAliases: UntypedFormControl;
  workers: UntypedFormControl;
  leftValue: UntypedFormControl;
  rightValue: UntypedFormControl;
  netType: UntypedFormControl;
  host: UntypedFormControl;
  slaPolicy: UntypedFormControl;

  netTypeValues = ['drsocssl', 'drsoctcp', 'onsocssl', 'onsoctcp'];
  servers: string[];
  selectedServers: string[] = [];

  saveInProgress = false;

  title: string;
  duplicateNameErrorHandler: ErrorMessageHandlers = {
    duplicateName: () => 'This SLA name already exists.'
  };

  constructor(private formBuilder: UntypedFormBuilder,
    private connectionManagerService: ConnectionManagerService,
    private notificationService: NotificationsService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.connectionManagerService.getAvailableServers(this.server.id).subscribe(res => {
      this.servers = res.map(server => server.name);
      if (this.cmUnitType === 'CLUSTER') {
        this.servers.unshift('Primary', 'SDS', 'HDR', 'RSS');
      }
    }, err => {
      this.translateService.get('connectionManager.errorMsg.availableServersError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationService.pushErrorNotification(text);
        });
    });
    this.slaName = new UntypedFormControl(null,
        [this.duplicateNameValidator(), IACValidators.noWhitespaceValidator(), Validators.required]);
    this.service = new UntypedFormControl(null, Validators.required);
    this.leftValue = new UntypedFormControl(30);
    this.rightValue = new UntypedFormControl(60);
    this.connectionMode = new UntypedFormControl('redirect');
    this.useAliases = new UntypedFormControl(true);
    this.workers = new UntypedFormControl(4);
    this.slaPolicy = new UntypedFormControl('WORKLOAD');
    this.netType = new UntypedFormControl('onsoctcp');
    this.slaDetailsForm = this.formBuilder.group({
      slaName: this.slaName,
      service: this.service,
      connectionMode: this.connectionMode,
      useAliases: this.useAliases,
      leftValue: this.leftValue,
      rightValue: this.rightValue,
      workers: this.workers,
      netType: this.netType,
      slaPolicy: this.slaPolicy
    });

    if (this.slaToEdit) {
      const slaInfo = this.slaInfoList.filter(sla => sla.slaName === this.slaToEdit)[0];
      this.slaName.setValue(this.slaToEdit);
      this.slaName.disable();
      this.service.setValue(slaInfo.port);
      this.workers.setValue(slaInfo.workers);
      this.useAliases.setValue(slaInfo.useAliases === 'OFF' ? false : true);
      this.connectionMode.setValue(slaInfo.mode === 'proxy' ? 'proxy' : 'redirect');
    }
    this.leftValue.valueChanges.subscribe(value => {
      if (value > this.rightValue.value) {
        this.leftValue.setValue(this.rightValue.value, { emitEvent: false });
      }
    });
    this.rightValue.valueChanges.subscribe(value => {
      if (value < this.leftValue.value) {
        this.rightValue.setValue(this.leftValue.value, { emitEvent: false });

      }
    });
  }

  duplicateNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.slaInfoList && this.slaInfoList.some(sla => sla.slaName === control.value)) {
        isValid = false;
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

  saveSLA() {
    let policy = this.slaPolicy.value;
    if (this.slaPolicy.value === 'WORKLOAD' && (this.cmUnitType === 'GRID' || this.cmUnitType === 'REPLSET')) {
      policy = this.leftValue.value + '*LATENCY+' + (this.rightValue.value - this.leftValue.value) + '*FAILURE+' +
        (100 - this.rightValue.value) + '*WORKLOAD';
    }
    const slaInfo: SLAInfo = {
      cmName: this.cmName,
      cmUnitName: this.cmUnitName,
      type: this.cmUnitType,
      slaName: this.slaName.value,
      dbServers: this.selectedServers.toString().replace(/Group\d+\(/g, '('),
      port: this.service.value,
      policy,
      mode: this.connectionMode.value,
      workers: this.workers.value,
      useAliases: this.useAliases.value ? 'ON' : 'OFF'
    };
    this.saveInProgress = true;
    this.connectionManagerService.updateSLA(this.server.id, slaInfo).subscribe(res => {
      this.saveInProgress = false;
      this.closeSLA.emit(true);
      if (this.slaToEdit) {
        this.translateService.get('connectionManager.successMsg.UpdateSLASuccess').subscribe((text: string) => {
          this.notificationService.pushSuccessNotification(text);
        });
      } else {
        this.translateService.get('connectionManager.successMsg.addSLASuccess').subscribe((text: string) => {
          this.notificationService.pushSuccessNotification(text);
        });
      }
    }, err => {
      this.saveInProgress = false;
      if (this.slaToEdit) {
        this.translateService.get('connectionManager.errorMsg.updateSLAError',
          { suffix: err.error ? err.error.err : err }).subscribe((text: string) => {
            this.notificationService.pushErrorNotification(text);
          });
      } else {
        this.translateService.get('connectionManager.errorMsg.addSLAError',
          { suffix: err.error ? err.error.err : err }).subscribe((text: string) => {
            this.notificationService.pushErrorNotification(text);
          });
      }
    });
  }

  getSelectedServers(event: any) {
    this.selectedServers = event;
  }
}
