/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AbstractAlertOperand } from './abstract-alert-operand';


interface SensorOption {
  id: string;
  name: string;
  metrics: MetricOption[];
}

interface MetricOption {
  id: string;
  name: string;
  dataType: string;
  unit: string;
}

@Component({
  selector: 'app-latest-metric-operand',
  template: `
  <form [formGroup]="formGroup" *ngIf="sensorOptionsFiltered" class="form-group form-inline">
    <ng-container *ngIf="sensorOptionsFiltered.length > 0; else noValidSensors">
      <select class="form-control form-control-sm" formControlName="sensor">
        <option *ngIf="!sensorControl.value" [ngValue]="null">Select a sensor...</option>
        <option *ngFor="let option of sensorOptionsFiltered" [ngValue]="option">{{option.name}}</option>
      </select>
      <select class="form-control form-control-sm ml-2" *ngIf="metricOptions" formControlName="metric">
        <option *ngFor="let option of metricOptions" [ngValue]="option">{{option.name}}</option>
      </select>
    </ng-container>
    <ng-template #noValidSensors>
      <div class="alert alert-warning d-inline-block">
        No valid sensors available.
      </div>
    </ng-template>
  </form>
  `
})
export class LatestMetricOperandComponent extends AbstractAlertOperand implements OnInit {
  private sensorOptions: SensorOption[] = null;
  metricOptions: MetricOption[] = null;
  sensorOptionsFiltered: SensorOption[] = null;

  formGroup: UntypedFormGroup;
  sensorControl: UntypedFormControl;

  constructor(
    private monitoringService: MonitoringService
  ) {
    super();
  }

  ngOnInit() {
    this.monitoringService.getEffectiveMonitoringProfile(this.owner).then(profile => {
      this.sensorControl = new UntypedFormControl(null, Validators.required);

      this.formGroup = new UntypedFormGroup({
        sensor: this.sensorControl,
        metric: new UntypedFormControl(null, Validators.required)
      });

      this.sensorOptions = [];
      profile.sensors.forEach(sensor => {
        const option: SensorOption = {
          id: sensor.type.id,
          name: sensor.name,
          metrics: []
        };

        for (const key in sensor.type.meta.metrics) {
          if (sensor.type.meta.metrics.hasOwnProperty(key)) {
            const metricMeta = sensor.type.meta.metrics[key];
            const metric = {
              id: key,
              name: metricMeta.name,
              dataType: metricMeta.dataType || 'number',
              unit: metricMeta.unit
            };

            option.metrics.push(metric);
          }
        }

        this.sensorOptions.push(option);
      });
      this.sensorOptions.sort((a, b) => a.name.localeCompare(b.name));

      this.filterSensorOptions();

      this.formGroup.valueChanges.pipe(delay(0)).subscribe(value => {
        this.emitUpdate();
      });

      if (this.operand && this.operand.sensor) {
        const sensorOption = this.sensorOptions.find(v => v.id === this.operand.sensor);
        let metricOption: MetricOption = null;
        if (sensorOption) {
          this.metricOptions = sensorOption.metrics;
          metricOption = this.metricOptions.find(v => v.id === this.operand.metric) || this.metricOptions[0];
        }

        this.formGroup.setValue({
          sensor: sensorOption,
          metric: metricOption
        });
      } else {
        this.emitUpdate();
      }

      this.sensorControl.valueChanges.subscribe(value => {
        this.metricOptions = value.metrics;
        this.formGroup.controls.metric.setValue(this.metricOptions[0]);
      });
    }).catch(err => {
      console.error(err);
    });
  }

  private filterSensorOptions() {
    if (!this.sensorOptions) {
      return;
    }

    if (this.dataTypeFilter) {
      this.sensorOptionsFiltered = [];
      this.sensorOptions.forEach(sensor => {
        const metricOptions = sensor.metrics.filter(metric => metric.dataType === this.dataTypeFilter);
        if (metricOptions.length > 0) {
          this.sensorOptionsFiltered.push({
            id: sensor.id,
            name: sensor.name,
            metrics: metricOptions
          });
        }
      });
    } else {
      this.sensorOptionsFiltered = this.sensorOptions;
    }
  }

  public isValid(): boolean {
    return this.formGroup.valid;
  }

  public getValue(): any {
    const v = this.formGroup.value;
    return {
      sensor: v.sensor ? v.sensor.id : null,
      metric: v.metric ? v.metric.id : null
    };
  }

  public setDataTypeFilter(dataType: string) {
    super.setDataTypeFilter(dataType);
    this.filterSensorOptions();
  }

  protected getOutputType() {
    const metric: MetricOption = this.formGroup.value.metric;
    return metric ? metric.dataType : null;
  }

  protected getOutputUnit(): string {
    const metric = this.formGroup.value.metric as MetricOption;
    return metric ? metric.unit : null;
  }
}
