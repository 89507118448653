<div class="er-sidebar right border border-gray-20 bg-white" [ngClass]="{'open': open}" >
      <div class="wrapper d-flex flex-column">
        <div class="header py-2 px-3 border-bottom border-gray-20 d-flex ">
          <div class="flex-1">
            Node Details (<strong>{{ selectedGroup?.groupName }}</strong>)
          </div>
          <span>
            <i class="icon icon-times" (click)="close();"></i>
          </span>   
        </div>
        <div class="contents flex-grow-1 px-3">
          <form class="h-100" role="form" [formGroup]="formGroup">
                <div class="h-100">
                  <div class="wrapper d-flex flex-column h-100">
                    <div class="form-group">
                      <div class="row m-0 font-weight-bold">
                          <div class="alert alert-info mt-2 mb-0 w-100" *ngIf="isFirstServerInDomain && selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.SUCCESS">
                              <i class="icon icon-info icon-info-circle text-info pr-1"></i>  Since <strong class="bold">{{ selectedGroup?.groupName }}</strong> is not a part of any ER domain, This will be root node.
                          </div> 
                          <div class="alert alert-info mt-2 mb-0 w-100" *ngIf="selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.PROGRESS">
                              <i class="icon icon-info icon-info-circle text-info pr-1"></i>  Please wait. Validation is in progress. 
                          </div> 
                          <div class="alert alert-danger mt-2 mb-0 w-100" *ngIf="selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.ERROR">
                              <i class="icon icon-info icon-info-circle text-danger pr-1"></i>  {{ selectedGroup?.validation?.errorMsg }}
                          </div> 
                          <div class="alert alert-danger mt-2 mb-0 w-100" *ngIf="selectedGroup?.validation?.errorMsg && selectedGroup?.validation?.status !== sqlhostsGroupValidationStatus.ERROR">
                               {{ selectedGroup?.validation?.errorMsg }}
                          </div> 
                      </div>
                    </div>
                    <ng-container *ngIf="selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.SUCCESS">
                        <div class="form-group">
                          <div class="row m-0 font-weight-bold">
                           <span class="text-danger mr-1">*</span> 
                           <label title="Defines the server as a root, non-root, or leaf server in an domain">Node Type</label>
                          </div>
                          <div class="row m-0 font-weight-bold">
                            <app-radio-group formControlName="nodeType">
                              <app-radio value="root">
                                Root node
                              </app-radio>
                              <app-radio class="ml-4" [disabled]="isFirstServerInDomain" value="nonroot">
                                Non-root node
                              </app-radio>
                              <app-radio class="ml-4" [disabled]="isFirstServerInDomain" value="leaf">
                                Leaf node
                              </app-radio>
                            </app-radio-group>
                          </div>
                        </div>
                        <div class="form-group" *ngIf="!isFirstServerInDomain">  
                          <ng-container>
                            <div class="row m-0 font-weight-bold">
                            <span class="text-danger mr-1">*</span> 
                            <label [ngClass]="{'text-danger': !formSelections.syncServer}" title="Adds a server to the domain of which the sync_server is a member.">Group for sync</label>
                            </div>
                            <select [(ngModel)]="formSelections.syncServer" [ngModelOptions]="{standalone: true}" class="form-control">
                              <option [ngValue]="null" disabled selected>Select Server</option>
                              <option *ngFor="let server of syncServerList" [ngValue]="server" >{{server?.name}}</option>
                            </select>
                          </ng-container>
                        </div>
                        <div class="form-group">  
                          <ng-container>
                            <div class="row m-0 font-weight-bold">
                              <span class="text-danger mr-1">*</span> 
                              <label [ngClass]="{'text-danger': !formSelections?.sbSpaces?.length}" title="Required sbspaces to store replicate queue.">CDR_QDATA_SBSPACE</label>
                            </div>
                            <div class="row m-0 font-weight-bold">
                              <div class="col d-flex p-0">
                                <div class="flex-1">
                                  <select [(ngModel)]="selectedSBSpace" [ngModelOptions]="{standalone: true}" class="form-control">
                                    <option [ngValue]="null" disabled selected>Select Space</option>
                                    <option *ngFor="let space of sbSpacesList" [ngValue]="space" >{{space?.name}}</option>
                                  </select>
                                </div>
                                <button class="btn btn-secondary m-0 ml-1" (click)="onSBSpaceSelected(selectedSBSpace)" [disabled]="!selectedSBSpace">Add</button>
                              </div>
                            </div>

                            <div class="row m-0 font-weight-bold" *ngIf="formSelections?.sbSpaces?.length">
                              <div class="col d-flex p-0">
                                <table class="table mt-2 mb-0">
                                    <thead>
                                      <tr>
                                        <td>
                                          Name
                                        </td>
                                        <td>
                                          Status
                                        </td>
                                        <td></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sbSpace of formSelections.sbSpaces; let i = index;">
                                          <td> {{ sbSpace?.name }} </td>
                                          <td> {{ sbSpace?.status }} </td>
                                          <td class="text-right"><i class="icon icon-times" style="color: gray;font-size: 16px;" (click)="removeSbSpace(i);"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="form-group">  
                          <ng-container>
                            <div class="row m-0 font-weight-bold">
                             <label title="The dbspace name for the syscdr database">CDR_DBSPACE</label>
                            </div>
                            <div class="row m-0 font-weight-bold">
                              <div class="col d-flex p-0">
                                <div class="flex-1">
                                  <select [(ngModel)]="selectedDBSpace" [ngModelOptions]="{standalone: true}" class="form-control">
                                    <option [ngValue]="null" disabled selected>Select Space</option>
                                    <option *ngFor="let space of dbSpacesList" [ngValue]="space" >{{space?.name}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>

                        <div class="row m-0 font-weight-bold d-block">
                          <div class="col d-flex p-0">
                              <button class="btn btn-link p-0 d-flex m-0 mb-2" (click)="showMoreOptions = !showMoreOptions">
                                <span>{{showMoreOptions?'Hide':'Show'}} more options</span>
                              </button>
                          </div>
                        </div>
                        <div [hidden]="!showMoreOptions">
                          <app-er-advanced-options [selectedNode]="selectedGroup" [selections]="advancedOptions"></app-er-advanced-options>
                        </div>
                    </ng-container>
                  </div>
                </div>
          </form>
        </div>
        
        <div class="footer d-flex justify-content-end px-3 py-2 border-top border-gray-20" >
            <button class="btn btn-secondary" *ngIf="selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.SUCCESS" (click)="save();" [disabled]="!isSaveValid()">Save</button>
            <div class="d-flex flex-grow-1 justify-content-end" *ngIf="selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.SUCCESS && !isSingleGroup">
              <button class="btn btn-secondary" (click)="save(true);" [disabled]="!isSaveValid()">Save & Next</button>
            </div>
            <button class="btn btn-secondary btn-block" *ngIf="selectedGroup?.validation?.status === sqlhostsGroupValidationStatus.ERROR" (click)="triggerValidation();">Re-Validate</button>
        </div>
      </div>
  </div>
