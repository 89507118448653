<div class="card card-body">
  <h4 class="card-title"><a
      [routerLink]="['/dashboard/servers', server.id, 'ha']">{{'highAvailability.pageTitle' | translate}}</a></h4>

  <div *ngIf="clusterInfo?.clusterNodeList" class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th [attr.colspan]="4" class="text-muted border-bottom border-light-gray-20">{{'dashboard.availability.table.heading' | translate}}
          </th>
        </tr>
        <tr>
          <th>{{'dataTable.columnTitle.name' | translate}}</th>
          <th>{{'dataTable.columnTitle.type' | translate}}</th>
          <th>{{'dataTable.columnTitle.replicationStatus' | translate}}</th>
          <th>{{'dashboard.availability.table.columnTitle.lagtime' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let haServer of clusterInfo.clusterNodeList">
          <td>{{haServer.name}} <span class="badge-pill badge-primary"
              *ngIf="haServer.name === clusterInfo.haName">Current</span></td>
          <td>{{(haServer.type === 'HDR')? haServer.type + ' SECONDARY' : haServer.type}} </td>
          <td
            [ngClass]="serverStatusList[haServer?.status?.toUpperCase()] ? ('text-' + serverStatusList[haServer?.status?.toUpperCase()]) : 'text-danger'">
            <strong>{{haServer.status}}</strong></td>
          <td>{{haServer.lagtime ? ((haServer.lagtime | number: '1.5-5') + " " + ('time.seconds' | translate)) : ""}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="hasHA != null && !hasHA" class="alert alert-warning">
    <i class="icon icon-warning text-warning"></i> {{'highAvailability.serverNotInHA' | translate}}
  </div>

  <div *ngIf="dataLoading && !clusterInfo?.clusterNodeList" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-x icon-spin mt-2"></i>
  </div>

  <h4 class="card-title"><a
      [routerLink]="['/dashboard/servers', server.id, 'cm']">{{'connectionManager.pageTitle' | translate}}</a></h4>
  <div *ngIf="cmList && cmList.length > 0" class="border">
    <div class="col-12 px-3 py-2 bg-light-gray-10 border-bottom">
      <div> {{'dashboard.availability.connectionManagers' | translate}} </div>
    </div>
    <div class="row p-1">
      <div class="col-xl-12 col-sm-12">
        <div *ngIf="cmList && cmList.length > 0" class="ml-2 mt-2">
          <span *ngFor="let cm of cmList">
            {{cm.name}}
            <i class="icon icon-star" *ngIf="cm.arbitrator == 1" tooltip="Active arbitrator"></i>
            {{(cm == cmList[cmList.length - 1])? '':','}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isWorkOffline">
    <div *ngIf="cmLoading" class="d-flex justify-content-center">
      <i class="icon icon-circle-notch icon-x icon-spin mt-2"></i>
    </div>
    <div *ngIf="!cmLoading && (!cmList || (cmList && cmList.length === 0))" class="alert alert-info">
      <i class="icon icon-info text-info"></i> {{'connectionManager.noDataAlertMessage' | translate}}
    </div>
  </ng-container>
</div>
