/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, HostListener, AfterContentInit } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-multi-select',
  templateUrl: 'dropdown-multi-select.html',
  styleUrls: ['dropdown-multi-select.scss']
})
export class DropdownMultiSelectComponent implements AfterContentInit {

  @Input() label = 'Select...';
  @Input() showSelectAll = true;
  @Input() showSearch = false;
  @Input() disabled: boolean;
  @Input() maxHeight: string;
  @Input() minWidth: string;

  @Output() valueChanged = new EventEmitter<any>();

  _data: any[] = null;
  searchData: any[] = null;
  lastValuesSelected: any[];
  selectAll = false;
  searchControl: UntypedFormControl;

  @ViewChild(BsDropdownDirective) dropdown: BsDropdownDirective;

  constructor(private _eref: ElementRef) {
  }

  ngAfterContentInit() {
    this.searchControl = new UntypedFormControl('');
    this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.applySearch();
    });
  }

  private applySearch() {
    let searchString = (this.searchControl.value as string).trim();
    if (searchString.length > 0) {
      searchString = searchString.toLowerCase();
      this._data = this.searchData.filter(row => {
          const value = row.label;
          if (typeof value === 'string' && value.toLowerCase().indexOf(searchString) > -1) {
            return true;
          } else if (typeof value === 'number' && value.toString().indexOf(searchString) > -1) {
            return true;
          } else if (value instanceof Array && value.toString().indexOf(searchString) > -1) {
            return true;
          }
        return false;
      });
    } else {
      this._data = this.searchData;
    }
  }

  @Input()
  public set data(data: any[]) {
    this._data = data;
    this.searchData = data;
    this.lastValuesSelected = this._data.filter((item) => item.selected);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      // Close the drop-down menu if the user clicks away from
      // the drop-down multi-select component
      this.dropdown.hide();
    }
  }

  onSelect(item) {
    item.selected = !item.selected;
    this.selectAll = false;
    this.handleOnHiddenEvent();
  }

  doSelectAll() {
    this.selectAll = !this.selectAll;
    for (const index in this._data) {
      if (this._data.hasOwnProperty(index)) {
        this._data[index].selected = this.selectAll;
      }
    }
    this.handleOnHiddenEvent();
  }

  handleOnHiddenEvent() {
    const newSelection: any[] = this._data.filter((item) => item.selected);

    let hasSelectionChanged = false;
    if (this.lastValuesSelected == null || newSelection.length !== this.lastValuesSelected.length) {
      hasSelectionChanged = true;
    } else {
      for (let i = 0; i < newSelection.length; i++) {
        if (this.lastValuesSelected[i] !== newSelection[i]) {
          hasSelectionChanged = true;
        }
      }
    }

    if (hasSelectionChanged) {
      this.lastValuesSelected = newSelection;
      this.valueChanged.emit(newSelection);
    }
  }

}
