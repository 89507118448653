/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { InformixServerSessionsService } from './informixServerSessions.service';
import { ServerSession } from './serverSession';
import { sessionDashboard } from './session-dashboard';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { HDRPermission } from '../../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-sessions-list',
  templateUrl: 'sessions-list.html'
})
export class SessionsListComponent implements OnInit, OnDestroy {

  @Input() viewLiveData = true;

  breadcrumb: BreadcrumbElement[] = null;
  perfBreadCrumb: BreadcrumbElement = { name: 'Performance' };
  sessionsBreadCrumb: BreadcrumbElement = { name: 'Sessions' };

  server: InformixServer = null;
  sessions: ServerSession[] = null;

  dataLoadErrorMessage: string = null;

  sessionDashboard = sessionDashboard;

  private refreshSessions = new Subject<any>();
  private sessionsSub: Subscription = null;
  sessionHDR: HDRPermission;
  constructor(
    private sessionsService: InformixServerSessionsService,
    private route: ActivatedRoute,
    private notifications: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private hdrPermissionService: HDRPermissionService) { }

  ngOnInit() {
    this.sessionsSub = this.refreshSessions.pipe(
      switchMap(() => this.sessionsService.getServerSessions(this.server.id)))
      .subscribe(sessions => {
        this.sessions = sessions;
        this.dataLoadErrorMessage = null;
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      });

    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });

    this.sessionHDR = this.hdrPermissionService.getByPermissionId('p3');
  }

  ngOnDestroy() {
    if (this.sessionsSub) {
      this.sessionsSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.perfBreadCrumb, this.sessionsBreadCrumb]);
    if (this.server.hasMonitorPassword) {
      this.refreshData();
    } else if (server.agent != null) {
      this.viewLiveData = false;
    }
  }

  private refreshData() {
    this.refreshSessions.next(null);
  }

  confirmKillSession(session: ServerSession) {
    if (this.sessionHDR && !this.sessionHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('terminate session ' + session.sid + '?', () => {
      this.sessionsService.killServerSession(this.server.id, session.sid)
        .then(result => {

          this.notifications.pushGenericNotification(result.return_code, result.result_message, (type) => {
            if (type.isSuccess || type.isInfo) {
              this.refreshData();
            }
          });
        }).catch((err: HttpErrorResponse) => {
          let errorMessage = 'There was a problem terminating the session';
          if (err.error && err.error.err) {
            errorMessage += '. ' + err.error.err;
          }
          this.notifications.pushErrorNotification(errorMessage);
        });
    });
  }
}
