/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { TimeSliceComponent } from './time-slice.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { SharedModule } from '../../shared/shared.module';
import { DashboardDynamicComponent } from './dashboard-dynamic.component';
import { DashboardComponent } from './dashboard.component';
import { EditSensorComponent } from './editSensor.component';
import { InformixSensorService } from './informixSensor.service';
import { MonitoringService } from './monitoring.service';
import { MonitoringProfileComponent } from './monitoringProfile.component';
import { SensorChartGraphComponent } from './sensor-chart-graph.component';
import { SensorChartComponent } from './sensor-chart.component';
import { SensorsRequiredComponent } from './sensors-required.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        AlertModule,
        BsDropdownModule,
        ModalModule,
        TooltipModule,
        PopoverModule,
        NgSelectModule
    ],
    declarations: [
        MonitoringProfileComponent,
        EditSensorComponent,
        SensorChartComponent,
        SensorChartGraphComponent,
        SensorsRequiredComponent,
        DashboardComponent,
        DashboardDynamicComponent,
        TimeSliceComponent
    ],
    exports: [
        MonitoringProfileComponent,
        SensorChartComponent,
        SensorChartGraphComponent,
        SensorsRequiredComponent,
        DashboardComponent,
        TimeSliceComponent
    ],
    providers: [
        MonitoringService,
        InformixSensorService
    ]
} )
export class MonitoringModule {}
