/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';

import { CheckPermissionsComponent } from './check-permissions.component';
import { InformixServer } from '../../dashboard/servers/informixServer';

 @Component({
  selector: 'app-check-server-admin-permissions',
  template: `
  <div style="display: inline-block" [tooltip]="tooltipMsg" [placement]="placement" container="body">
    <ng-content></ng-content>
  </div>
  `
})
export class CheckServerAdminPermissionsComponent extends CheckPermissionsComponent implements OnChanges {

  @Input() server: InformixServer;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server) {
      this.permissions = this.server.permissions.admin && this.server.hasAdminPassword;
      if (!this.permissions) {
        this.noPermissionsMessage = (this.server.permissions.admin ? 'No admin credentials provided' : 'You do not have access to this');
      }
    }
  }

}
