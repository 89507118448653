<label class="btn-container mt-3">
  <button class="btn font-weight-bold" [ngClass]="{'btn-style': viewSystemBackups}" (click)="handleStateChange(true)">
    System Backups
  </button>
  <button class="btn font-weight-bold" [ngClass]="{'btn-style': !viewSystemBackups}" (click)="handleStateChange(false)">
    Backups per Space
  </button>
</label>

<div *ngIf="_backupInfo && _backupInfo.backup_in_progress" class="row">
  <div class="col-12">
    <div class="alert alert-info d-flex align-items-center justify-content-center">
      <i class="icon icon-circle-notch icon-spin icon-2x mr-1"></i>
      <strong>Backup in progress...</strong>
    </div>
  </div>
</div>

<div *ngIf="viewSystemBackups">
  <div *ngIf="backupLevels" class="my-4">
    <div class="row align-items-center mb-1">
      <div class="col text-center">
        <strong>Backup Level</strong>
      </div>
      <div class="col text-center">
        <strong>Status</strong>
      </div>
      <div class="col text-center">
        <strong>Time Since Last Backup</strong>
      </div>
      <div class="col text-center">
        <strong>Time Until Next Backup</strong>
      </div>
      <div class="col text-center">
      </div>
    </div>
    <div class="row align-items-center mb-1" *ngFor="let level of backupLevels; let i = index">
      <div class="col text-center">
        Level {{i}}
      </div>
      <div class="col text-center">
        <i *ngIf="level.status === -1" class="icon icon-2x icon-warning text-warning" title="The last backup was not within the number of days specified as the threshold for a backup in the ph_threshold table."></i>
        <i *ngIf="level.status === 1" class="icon icon-2x icon-check-circle text-success" title="Backup up to date"></i>
      </div>
      <div class="col text-center">
        {{(level.lastBackup === 0)? 'Never' : (level.lastBackup * 1000 | timeSince)}}
      </div>
      <div class="col text-center">
        {{(level.nextBackup) ? (level.nextBackup.$date | date:'y-MM-dd HH:mm:ss') : '--'}}
      </div>
      <div class="col text-center">
        <app-check-server-admin-permissions [server]="server">
          <app-check-hdr-permissions [server]="server" [hdrPermission]="backupHDR">
            <button type="button" class="btn btn-primary btn-sm mr-1" (click)="runBackup(i)" [disabled]="!_backupTasks || !_backupTasks.backupsConfigured"
              appHDRPermission [hdrPermission]="backupHDR" appPermissionDisable>
              Run level {{i}} backup
            </button>
          </app-check-hdr-permissions>
        </app-check-server-admin-permissions>
      </div>
    </div>

    <app-backup-history [server]="server" [sensor]="sensor">
    </app-backup-history>
  </div>
</div>

<div *ngIf="!viewSystemBackups && _backupInfo" class="table-responsive mt-2">
  <app-data-table [data]="_backupInfo.per_space" sortBy="name" [rowsPerPage]="20">
    <app-data-table-column title="Space Name" property="name" [sortable]="true">
    </app-data-table-column>
    <app-data-table-column title="Last Level 0" property="level0" [sortable]="true">
      <ng-template appTableCell let-level="item">
        {{(level === 0)? 'Never' : (level * 1000 | timeSince)}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Last Level 1" property="level1" [sortable]="true">
      <ng-template appTableCell let-level="item">
        {{(level === 0)? 'Never' : (level * 1000 | timeSince)}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Last Level 2" property="level2" [sortable]="true">
      <ng-template appTableCell let-level="item">
        {{(level === 0)? 'Never' : (level * 1000 | timeSince)}}
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
