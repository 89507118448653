/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/

/**
 * This component file will display the Information present in database selected by the user in Schema Manager.
 */
import { Component, OnInit, Input } from '@angular/core';
import { SchemaService } from '../schema.service';
import { InformixServer } from '../../informixServer';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-database-info',
  templateUrl: './database-info.component.html',
  styleUrls: ['../database/database.component.scss']
})
export class DatabaseInfoComponent implements OnInit {

  @Input() server: InformixServer = null;
  @Input() currentDB: string;
  databaseInfo: any;

  constructor(private schemaService: SchemaService, private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.getDbInfo(this.currentDB);
  }

  /**
   * This function will call the Database Information API and store the response in databaseInfo.
   */
  private getDbInfo(databaseName: string) {
    this.schemaService.getDatabaseInfo(this.server, databaseName).subscribe(res => {
      this.databaseInfo = res;
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  /**
   * This function will checks the logging mode for respective database.
   */
  getLoggingMode(): string {
    if (this.databaseInfo.ansi) {
      return 'ANSI';
    }
    if (this.databaseInfo.bufferedLogging) {
      return 'Buffered';
    }
    if (this.databaseInfo.logging) {
      return 'Unbuffered';
    }
    return 'Disabled';
  }
}
