<div *ngIf="viewMonitoredData && deviceSensorRunning" class="card card-body">
  <h4 class="card-title"><a [routerLink]="['storage']">Storage Devices</a></h4>
  <div class="row" style="width: 100%;" [ngStyle]="{height: chartHeight + 'px'}">
    <canvas #deviceUsageBarChart width="500"></canvas>
    <div *ngIf="sensorError"
      style="position:absolute; top: 50%; right: 0; left: 0; text-align: center;">
      <i class="icon icon-2x icon-warning text-danger mr-2"> </i> {{sensorError}}
    </div>
  </div>
</div>
