/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../informixServer';
import { HttpClient } from '@angular/common/http';
import { MemoryResponse } from './memory-manager.model';

@Injectable()
export class MemoryManagementService {

  constructor(private httpClient: HttpClient) { }

  public updateLowMemoryData(server: InformixServer, dataObj: any): Observable<MemoryResponse> {
    const url = 'informix/' + server.id + '/memory';
    return this.httpClient.put<any>(url, dataObj);
  }

  public getMemoryData(server: InformixServer): Observable<MemoryResponse> {
    const url = 'informix/' + server.id + '/memory';
    return this.httpClient.get<any>(url);
  }

}
