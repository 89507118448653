/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { JSONUtils } from '../../../shared/json-utils';
import { AlertOperatorType } from './alert-operator-types';

export class AlertOperator {
  private typeIndex = 0;
  private hasGenericTypes: boolean;
  private computedInputs: { types: string[] }[] = null;
  private genericTypeMap: { [key: string]: boolean } = {};

  constructor(public type: AlertOperatorType) {
    this.hasGenericTypes = (this.type.genericTypes && this.type.genericTypes.length > 0);

    if (this.hasGenericTypes) {
      this.type.genericTypes.forEach(genericType => {
        this.genericTypeMap[genericType] = true;
      });
    } else {
      this.computedInputs = this.type.inputs;
    }
  }

  setGenericType(genericType: string, type: string) {
    if (!this.hasGenericTypes || !this.genericTypeMap[genericType]) {
      return;
    }

    this.computedInputs = JSONUtils.deepClone(this.type.inputs);
    this.computedInputs.forEach(input => {
      for (let i = 0; i < input.types.length; i++) {
        input.types[i] = input.types[i].replace(genericType, type);
      }
    });
  }

  setTypeIfValid(type: string): boolean {
    for (let i = 0; i < this.computedInputs[0].types.length; i++) {
      if (type === this.computedInputs[0].types[i]) {
        this.typeIndex = i;
        return true;
      }
    }

    return false;
  }

  getInputType(index: number): string {
    if (index >= this.computedInputs.length) {
      return null;
    }
    return this.typeIndex < this.computedInputs[index].types.length ? this.computedInputs[index].types[this.typeIndex] : null;
  }
}
