  <ng-container>
    <div class="wrapper py-4 border border-gray-20">
       <div class="px-4">
        <!-- <span>Defining replication servers in ER involves several prerequisites and steps that must be completed before you can successfully set up and manage your replication environment. 
          Follow these guidelines to ensure that your replication system is properly configured and ready to use:
        </span> -->
       </div>
       <div class="d-flex py-4">
        <div class="prereq  pl-4 pr-5">
            <div class="pl-4 pb-3">
            <span>Defining replication servers in ER involves several prerequisites and steps that must be completed before you can successfully set up and manage your replication environment. 
              Follow these guidelines to ensure that your replication system is properly configured and ready to use:
            </span>
          </div>
            <div class="pl-4 py-1">
              <h5 class="m-0 pb-3">Prerequisites:</h5>
            </div>
            <div class="pl-4">
                
                <ul class="m-0 p-0">
                  <li class="preq-li pl-4">Understand and design your replication strategy: familiarize yourself with the concepts and strategies outlined in this resource. (For more information, please refer to the database server documentation for Enterprise Replication)</li>
                  <li class="preq-li pl-4">Prepare your replication environment for the server by following these instructions. (For more information, please refer to the database server documentation for Enterprise Replication)</li>
                  <li class="preq-li pl-4">Update the sqlhosts file for the server. If you are adding the server to an existing ER domain, make sure to update the sqlhosts files for the other servers in that ER domain.</li>
                  <li class="preq-li pl-4">Create the Aborted Transaction Spooling (ATS) and Row Information Spooling (RIS) directories for the server (optional).</li>
                </ul>
            </div>

            <div class="pl-4 pt-5" *ngIf="!helpOpened">
              <button class="btn btn-primary" (click)="openSqlhostsgroupsModal()">Define ER Domain {{isDraft && false?'(Draft)':''}}</button>
            </div>
        </div>

        <div class="steps flex-1 pl-4 pr-5" [style.min-width.vw]="44">
            <ul class="p-0">
              <li class="mb-1 cursor-pointer" *ngFor="let guide of guideConfig" (click)="showGuide(guide.id);">
                <div class="bg-light-gray-10 d-flex border-blue-50 guide-list-item">
                  <span class="flex-1 p-3">
                    <h5 class="m-0 text-muted">{{guide?.title}}</h5>
                  </span>
                  <i class="icon icon-2x icon-play-outline text-primary py-2 px-3 d-flex align-items-center"></i>
                </div>
              </li>
            
            </ul>
        </div>
       </div>
    </div>
  </ng-container>


  <app-guide-modal [guideId]="selectedGuideId" [(show)]="showGuideModal"> </app-guide-modal>
