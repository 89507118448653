/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { InformixServer } from '../../informixServer';
import { NotificationsService } from '../../.././../shared/notifications/notifications.service';
import { Sensor } from '../../../monitoring/sensor';
import { HDRPermission } from '../../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-backup-status',
  templateUrl: 'backup-status.html'
})
export class BackupStatusComponent implements OnInit {

  @Input() server: InformixServer;
  @Input() sensor: Sensor;
  @Output() backupInitiated = new EventEmitter();

  viewSystemBackups = true;

  _backupInfo: any = null;
  _backupTasks: any = null;
  backupLevels: any[] = null;
  backupHDR: HDRPermission;
  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private hdrPermissionService: HDRPermissionService
  ) { }

  ngOnInit() {
    this.backupHDR = this.hdrPermissionService.getByPermissionId('p5');
  }

  @Input()
  set backupInfo(backupInfo: any) {
    if (backupInfo === null) {
      this.backupLevels = null;
      return;
    }
    this._backupInfo = backupInfo;
    this.backupLevels = [];
    for (let i = 0; i < 3; i++) {
      const backup: any = {level: i};
      const lastBackup = backupInfo['oldest_level' + i];
      backup.lastBackup = lastBackup;
      if (this._backupTasks === null) {
        backup.nextBackup = null;
      } else {
        const taskInfo = this._backupTasks['level' + i];
        if (taskInfo && taskInfo.tk_enable) {
          backup.nextBackup = taskInfo.tk_next_execution;
        }
      }
      backup.status = this.getBackupStatus(i, lastBackup);
      this.backupLevels.push(backup);
    }
  }

  @Input()
  set backupTasks(backupTasks: any) {
    this._backupTasks = backupTasks;
    if (this.backupLevels == null) {
      return;
    }
    this.backupLevels.forEach(level => {
      const taskInfo = backupTasks['level' + level.level];
      if (taskInfo && taskInfo.tk_enable) {
        level.nextBackup = taskInfo.tk_next_execution;
      }
    });
  }

  /**
   * Compute backup status for a particular backup level
   *
   * 1 = backup for this level up to date
   * 0 = backup for this level not required (either because the previous level backup
   *     is so recent as to not require this level, or because the previous level
   *     backup is so out of date, that that one is required instead)
   * -1 = backup for this level is out of date
   */
  private getBackupStatus(level: number, lastBackup: number) {
    let maxInterval = (level === 0) ? this._backupInfo.max_interval_l0 : this._backupInfo.max_interval_any;
    maxInterval *= 24 * 60 * 60 * 1000; // convert from days to milliseconds
    const now = new Date().getTime();
    if (lastBackup === 0) {
      // if backup for this level has not run...
      if (level === 0) {
        // status is -1, since level 0 backup is always required
        return -1;
      } else if (level === 1) {
        // whether level 1 backup is required depends on how recent the level 0 backup is
        if (this.backupLevels[0].status === -1) {
          return 0;
        } else {
          return ((now - (this.backupLevels[0].lastBackup * 1000)) < maxInterval) ? 0 : -1;
        }
      } else {
        // whether level 2 backup is required depends on how recent the level 0 & 1 backups are
        if (this.backupLevels[0].status === -1 || this.backupLevels[1].status === -1) {
          return 0;
        } else {
          const prevBackup = (this.backupLevels[1].lastBackup === 0) ? this.backupLevels[0].lastBackup : this.backupLevels[1].lastBackup;
          return ((now - (prevBackup * 1000)) < maxInterval) ? 0 : -1;
        }
      }
    } else {
      // if there is a last backup time for this level, compare this to the max interval
      return ((now - (lastBackup * 1000)) < maxInterval) ? 1 : -1;
    }
  }

  public runBackup(level: number) {
    this.http.post<any>('informix/' + this.server.id + '/backups', {level}).subscribe(taskInfo => {
      taskInfo.return_code = 100;
      taskInfo.result_message = 'Backup level ' + level + ' submitted.';
      this.notificationsService.pushGenericNotification(taskInfo.return_code, taskInfo.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
 this.backupInitiated.emit();
}
      });
    }, err => {
      console.log(err);
      this.notificationsService.pushErrorNotification((err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err));
    });
  }

  handleStateChange(viewSystemBackups: boolean) {
    this.viewSystemBackups = viewSystemBackups;
  }

}
