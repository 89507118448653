/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input, OnChanges,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html'
})
export class TabGroupComponent implements OnChanges, AfterViewInit {

  @Input() selectedIndex = 0;
  @Output() eventUpdate: EventEmitter<any> = new EventEmitter();

  @ViewChild('tabViewContainer', { read: ViewContainerRef }) tabViewContainer: ViewContainerRef;

  @ContentChildren(TabComponent) tabComponents: QueryList<TabComponent>;

  private currentTemplate: TemplateRef<any>;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedIndex) {
      this.createTabView();
    }
  }

  ngAfterViewInit() {
    this.createTabView();
  }

  selectTab(index: number) {
    this.selectedIndex = index;
    this.eventUpdate.emit(this.selectedIndex);
    this.createTabView();
  }

  private createTabView() {
    if (this.tabViewContainer && this.tabComponents && this.selectedIndex < this.tabComponents.length) {
      const template = this.tabComponents.toArray()[this.selectedIndex].getTemplate();
      if (template && this.currentTemplate !== template) {
        this.tabViewContainer.clear();
        this.tabViewContainer.createEmbeddedView(template);
      }
    }
  }
}
