/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/

import { Route } from '@angular/router';
import { CanDeactivateGuard } from '../../../shared/canDeactivateGuard.service';
import { ServerResolver } from '../server-resolver.service';
import { EnterpriseReplicationComponent } from './enterprise-replication/enterprise-replication.component';

export const enterpriseReplicationRoutes: Route[] = [
  {
    path: 'enterprise-replication',
    component: EnterpriseReplicationComponent,
    canDeactivate: [CanDeactivateGuard],
    resolve: {
      server: ServerResolver
    }
  }
];
