/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DatePipe } from '@angular/common';
import { SystemReportsComponent } from './system-reports.component';
import { SystemReportsService } from './system-reports.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SqltraceModule } from './../sqltrace/sqltrace.module';
import { CustomSystemReportsModule } from '../../system-settings/custom-system-reports/custom-system-reports.module';
import { GenericReportComponent } from './generic-report.component';
import { LockListReportComponent } from './lock-list-report.component';
import { LocksPerSessionReportComponent } from './lock-session-report.component';
import { LocksPerTableReportComponent } from './lock-table-report.component';
import { LocksWithWaitersReportComponent } from './lock-waiter-report.component';
import { SessionActivityReportComponent } from './session-activity-report.component';
import { SqltraceReportComponent } from './sqltrace-report.component';
import { TableBufferPoolActivityReportComponent } from './table-buffer-pool-activity-report.component';
import { TableExtentsReportComponent } from './table-extents-report.component';
import { WaitingSessionsReportComponent } from './waiting-sessions-report.component';
import { UpdateStatisticsReportComponent } from './update-statistics-report.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TooltipModule,
    SharedModule,
    BsDropdownModule,
    ProgressbarModule,
    SqltraceModule,
    CustomSystemReportsModule
  ],
  declarations: [
    SystemReportsComponent,
    GenericReportComponent,
    LockListReportComponent,
    LocksPerSessionReportComponent,
    LocksPerTableReportComponent,
    LocksWithWaitersReportComponent,
    SessionActivityReportComponent,
    SqltraceReportComponent,
    TableBufferPoolActivityReportComponent,
    TableExtentsReportComponent,
    WaitingSessionsReportComponent,
    UpdateStatisticsReportComponent
  ],
  providers: [
    DatePipe,
    SystemReportsService
  ],
})
export class SystemReportsModule { }
