<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col">
      <h4>
        <strong>SQL Tracing</strong>
        <i *ngIf="server.hasMonitorPassword && !sqltraceConfig && !dataLoadErrorMessage" class="icon icon-circle-notch icon-spin ml-2"></i>
      </h4>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger">
      Unable to retrieve SQL tracing information. <br>
      Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

    <div class="row">
      <div class="col-xs-12 col-md-6 col-xl-5">
        <app-sqltrace-config *ngIf="sqltraceConfig" [server]="server" [config]="sqltraceConfig" (success)="handleActionSuccess($event)" (error)="handleActionError($event)"></app-sqltrace-config>
      </div>
      <div class="col-xs-12 col-md-6 col-xl-7 mt-4 mt-md-0">
        <app-sqltrace-info *ngIf="sqltraceInfo" [sqltraceInfo]="sqltraceInfo"></app-sqltrace-info>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <app-sqltrace-stmt-types *ngIf="sqltracingOn" [server]="server"></app-sqltrace-stmt-types>
      </div>
    </div>

</div>
