/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackupLogsComponent } from './backup-logs.component';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { MonitoringProfile } from '../../../monitoring/monitoringProfile';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { Sensor } from '../../../monitoring/sensor';

@Component({
  selector: 'app-backup',
  templateUrl: 'backup.html'
})
export class BackupComponent implements OnInit, OnDestroy {

  private backupBreadCrumb: BreadcrumbElement = {
    name: 'Backups',
  };

  breadcrumb: BreadcrumbElement[] = null;

  server: InformixServer = null;
  monitoringProfile: MonitoringProfile = null;
  backupSensor: Sensor = null;

  selectedTabIndex = 0;

  backupInfo: any = null;
  backupTasksInfo: any = null;

  dataLoading = false;
  dataLoadErrorMessage: string = null;

  hasMonitoringCredentials: boolean;

  @ViewChild('backupLogs') backupLogsElement: BackupLogsComponent;

  private pollTimeoutHandle: number;
  private backupSub: Subscription;
  private backupTaskSub: Subscription;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private monitoringService: MonitoringService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  ngOnDestroy() {
    if (this.backupSub) {
      this.backupSub.unsubscribe();
    }
    if (this.backupTaskSub) {
      this.backupTaskSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.hasMonitoringCredentials = server.hasMonitorPassword;
    this.getMonitoringProfile();
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.backupBreadCrumb]);
    if (this.hasMonitoringCredentials) {
      this.dataLoading = true;
      this.refreshData();
    }
  }

  public getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;
      this.backupSensor = this.monitoringProfile.getSensor('backups');
    }).catch(err => {
      console.error('Error getting monitoring profile info', err);
    });
  }

  refreshData(includeTasks = true) {
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }

    this.refreshBackupsData();
    if (includeTasks) {
      this.refreshBackupTasksData();
    }

    if (this.backupLogsElement) {
      this.backupLogsElement.refreshData();
    }
  }

  refreshBackupsData() {
    if (this.backupSub) {
      this.backupSub.unsubscribe();
    }

    this.backupSub = this.http.get<any>('informix/' + this.server.id + '/backups').subscribe(backupInfo => {
      this.dataLoading = false;
      this.backupInfo = backupInfo;
      if (backupInfo.backup_in_progress) {
        // If backup currently in progress, we'll poll every 15 seconds to see if backup has completed.
        this.pollTimeoutHandle = window.setTimeout(() => this.refreshData(false), 15000);
      }
    }, err => {
      this.dataLoading = false;
      this.dataLoadErrorMessage = (err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err);
      console.log(err);
    });
  }

  refreshBackupTasksData() {
    if (this.backupTaskSub) {
      this.backupTaskSub.unsubscribe();
    }

    this.backupTaskSub = this.http.get<any>('informix/' + this.server.id + '/backups/tasks').subscribe(backupTasksInfo => {
      this.backupTasksInfo = backupTasksInfo;
    }, err => {
      this.dataLoadErrorMessage = (err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err);
      console.log(err);
    });
  }

  setSelectedTabIndex(index) {
    this.selectedTabIndex = index;
  }

}
