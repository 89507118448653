<div class="container-fluid px-0 position-relative">
  <app-er-prerequiesties *ngIf="domainState.showPrerequiesties" (showSqlhostsGroupsModalChange)="startDefineDomain($event)" (openDefineNodeSidebar)="openSideBar('defineNode');" [(helpOpened)]="domainState.showHelpScreen" [isDraft]="isDraft"> </app-er-prerequiesties>
  <div class="wrapper border border-gray-20  bg-white" [hidden]="domainState.showPrerequiesties" [ngClass]="{'fullscreen': domainState?.fullScreen}">
    <div class="toolbar d-flex align-items-center px-2 py-1 border-bottom border-gray-20">
      <button *ngIf="domainState?.mode === erDomainModes.ADD" type="button" class="py-2 pr-3 pl-2 close mr-3 border-right border-gray-20" (click)="toggleSideBar('groupList');">
        <i class="icon icon-bars" style="font-size: 14px;"></i>
      </button>
      <div class="nodes d-flex align-items-center flex-grow-1 p1">
        <div class="node mr-4"> <i class="icon icon-bare-metal-server root mr-2"></i> Root Node</div>
        <div class="node mr-4"><i class="icon icon-bare-metal-server non-root mr-2"></i>Non-Root Node</div>
        <div class="node mr-4"><i class="icon icon-bare-metal-server leaf mr-2"></i>Leaf Node</div>
      </div>
      <div class="controls d-flex align-items-center">
          <button *ngIf="domainState?.mode === erDomainModes.VIEW" type="button" class="btn btn-sm btn-secondary" (click)="reSyncDomain.emit()"
            [disabled]="insideLoading">
            <i class="icon icon-refresh"></i>
          </button>
          <button type="button" class="btn btn-sm btn-secondary ml-2" (click)="toggleFullscreen()">
            <i *ngIf="!domainState?.fullScreen" class="icon icon-fullScreen"></i>
            <i *ngIf="domainState?.fullScreen" class="icon icon-full-screen-exit"></i>
          </button>
          <button *ngIf="domainState?.mode === erDomainModes.ADD" type="button" class="btn btn-sm btn-secondary ml-2" (click)="showCancelDomainModal()">
              Cancel
          </button>
          <button class="btn btn-sm btn-secondary ml-2" (click)="discardDomainDraft();" *ngIf="domainState?.mode === erDomainModes.ADD && isDraft">Discard Draft</button>
          <button type="button" class="btn btn-sm btn-primary ml-2" *ngIf="domainState?.mode === erDomainModes.ADD" (click)="showConfirmationModal()" [disabled]="!erDomainClass?.domain?.nodes?.length">
              {{domainState?.status === erDomainStatus.MODIFY?'Modify':'Define'}} ER Domain
          </button>
          <div class="d-inline-block" [tooltip]="domainState?.currentGroup?.node?.isLeaf?'Operation is not permitted on leaf node':''" placement="left">
            <button type="button" [disabled]="domainState?.currentGroup?.node?.isLeaf" class="btn btn-sm btn-primary ml-2" *ngIf="domainState?.mode === erDomainModes.VIEW && domainState?.status !== erDomainStatus.EMPTY" (click)="showSqlhostsGroupsModal = true;">
                Add new nodes
            </button>
          </div>
      </div>
    </div>
    <div *ngIf="!erDomainClass?.domain?.nodes?.length" class="add-first-node align-items-center d-flex justify-content-center ng-star-inserted w-100">
        <div class="alert alert-info" *ngIf="domainState?.status === erDomainStatus.CREATE">
            <i class="icon icon-info icon-info-circle text-info pr-1"></i> Add first node to start ER domain creation
        </div>
        <div class="align-items-center d-flex flex-column" *ngIf="domainState?.status === erDomainStatus.EMPTY">
          <div class="alert alert-danger mb-0" >
              <i class="icon icon-info icon-info-circle text-danger pr-1"></i> It seems that the Enterprise Replication processes have been stopped. Do you wish to start?
          </div>
          <app-check-server-admin-permissions [server]="server">
            <button type="button" class="btn btn-primary mt-1" [disabled]="startDomainLoading" (click)="startERDomain()" appPermissionDisable>
                  Yes, Start ER Processes
            </button>
          </app-check-server-admin-permissions>
        </div>
    </div>

    <div *ngIf="insideLoading" class="align-items-center d-flex flex-column h-100 position-absolute w-100 sync-progress-wrapper">
        <div class="backdrop h-100 position-absolute w-100" [ngClass]="{'active': true}"></div>
        <div class="alert alert-info">
            <i class="icon icon-circle-notch icon-spin"></i> <span class="pl-2">Please wait. ER domain is being synchronized..</span>
        </div>
    </div>

    <div *ngIf="startDomainLoading" class="align-items-center d-flex flex-column h-100 position-absolute w-100 sync-progress-wrapper">
        <div class="backdrop h-100 position-absolute w-100" [ngClass]="{'active': true}"></div>
        <div class="alert alert-info">
            <i class="icon icon-circle-notch icon-spin"></i> <span class="pl-2">Please wait. ER domain is being started..</span>
        </div>
    </div>

    <div class="canvas flex-grow-1" >
      <ng-container *ngIf="erDomainClass?.domain && erDomainClass?.domain?.nodes && erDomainClass?.domain?.nodes.length">
        <app-er-domain-graph [domain]="erDomainClass.domain" [(selectedNode)]="erDomainClass.selectedNode" [domainMode]="domainState?.mode" (selectedNodeChange)="displayNodeInfo($event)" 
         (deleteNode)="onGroupDeleted($event.group);"></app-er-domain-graph>  
      </ng-container>
    </div>
    <ng-container>
      <app-sqlhosts-groups-modal *ngIf="server" [server]="server" [preSelectedGroups]="preSelectedGroups" (groupAdded)="onGroupsAdded($event);" [(show)]="showSqlhostsGroupsModal" [includeCurrentGroup]="domainState.showPrerequiesties"> </app-sqlhosts-groups-modal>
      <app-er-define-sidebar [selectedGroup]="selectedGroup" [(open)]="sidebars.defineNode"
        (validateGroup)="onGroupValidate($event)" (saveNode)="OnSaveNode($event)" [nodes]="erDomainClass?.domain?.nodes" [isSingleGroup]="groups.length === 1"> 
      </app-er-define-sidebar>
      <app-er-info-sidebar [server]="server" [selectedNode]="erDomainClass?.selectedNode" [nodes]="erDomainClass?.domain?.nodes" [(open)]="sidebars.erInfo" (deletedNode)="deleteERNode($event);"> </app-er-info-sidebar>
      <app-er-group-list-sidebar  [groups]="groups" [selectedGroup]="selectedGroup" [(show)]="sidebars.groupList" (groupSelected)="onGroupSelected($event);" (sqlHostsModalShow)="showSqlhostsGroupsModal = true;" (groupDeleted)="onGroupDeleted($event)"> </app-er-group-list-sidebar>
    </ng-container>
  </div>

<div bsModal #defineDomainModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <ng-container *ngIf="domainState?.userSelection === erDomainUserSelection.PROCESSED || domainState?.userSelection === erDomainUserSelection.REVIEW; then commandNProccessedContent else confirmationContent"></ng-container>
</div>

<ng-template #confirmationContent>
  <div class="modal-dialog modal-dialog-centered">  
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">{{domainState?.status === erDomainStatus.MODIFY?'Modify':'Create'}} ER Domain</h5>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
          <div>
            <div class="pb-3">
              <i class="icon icon-warning text-warning pr-1"></i> 
              {{ isGroupListEmpty?'':'Please note that there are still some groups in the list that can be added to the domain.'}}
              Are you sure you want to {{domainState?.status === erDomainStatus.MODIFY?'modify':'define'}} ER domain?
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100">
            <div class="flex-1">
              <button type="button"  class="btn btn-secondary mr-1"
                (click)="closeModal();">
                Close
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-primary mr-1" (click)="domainState.userSelection = erDomainUserSelection.REVIEW">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</ng-template>


<ng-template #commandNProccessedContent >
 <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">{{domainState?.operation === erDomainOperation.PROGRESS ?'Sit back and relax, HQ is working on it':'Nodes'}}</h5>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
          <div class="overflow-auto" style="max-height: 70vh;">
            <div class="pb-2" *ngIf="domainState?.operation === erDomainOperation.COMPLETE">
                <div class="alert alert-success mb-0 w-100" *ngIf="domainState?.statusMsg" >
                    <i class="icon icon-success icon-success-circle text-success pr-1"></i> 
                    <span>{{domainState?.statusMsg}}</span>
                </div>
                <div class="alert alert-warning mt-1 mb-0 w-100" *ngIf="domainState?.stats?.failed" >
                    <i class="icon icon-warning icon-warning-circle text-warning pr-1"></i> 
                    <span>Nodes that encountered errors were not added to the domain. Please review and re-validate.</span>
                </div>  
            </div>

            <ul class="list-group">
              <ng-container *ngFor="let node of domainState?.nodeList">
                <li class="list-group-item border-gray-20" *ngIf="node">
                  <div class="d-flex" >
                    <div class="flex-1">
                      <strong> {{node?.groupName}} | {{node?.typeName}} node </strong>
                      <ng-container *ngIf="node?.command">
                        <p class="mb-0 mt-2" *ngIf="node?.command?.sbSpaceCommand">
                          <span>
                            <p class="mb-0">{{node?.command?.sbSpaceCommand?.command}}</p>
                            <p class="text-danger mb-0" *ngIf="node?.operation?.status === erNodeOperationStatus.ERROR && node?.command?.sbSpaceCommand?.err && !node?.command?.sbSpaceCommand?.success">{{node?.command?.sbSpaceCommand?.err}}</p>
                          </span>
                        </p>
                        <p class="mb-0 mt-2" *ngIf="node?.command?.dbSpaceCommand">
                          <span>
                            <p class="mb-0">{{node?.command?.dbSpaceCommand?.command}}</p>
                            <p class="text-danger mb-0" *ngIf="node?.operation?.status === erNodeOperationStatus.ERROR && node?.command?.dbSpaceCommand?.err && !node?.command?.dbSpaceCommand?.success; else mainError;">{{node?.command?.dbSpaceCommand?.err}}</p>
                          </span>
                        </p>
                        <p class="mb-0 mt-2" *ngIf="node?.command?.defineServerCommand">
                          <span>
                            <p class="mb-0">{{node?.command?.defineServerCommand?.command}}</p>
                            <p class="text-danger mb-0" *ngIf="node?.operation?.status === erNodeOperationStatus.ERROR && node?.command?.defineServerCommand?.err && !node?.command?.defineServerCommand?.success">{{node?.command?.defineServerCommand?.err}}</p>
                          </span>
                        </p>
                      </ng-container>
                    </div>
                    <div class="icons d-flex align-items-center" *ngIf="node?.operation">
                        <i *ngIf="node?.operation?.status === erNodeOperationStatus.PROGRESS" class="icon icon-circle-notch icon-spin"></i>
                        <i *ngIf="node?.operation?.status === erNodeOperationStatus.SUCCESS" class="icon icon-check-circle text-success"></i>
                        <i *ngIf="node?.operation?.status === erNodeOperationStatus.ERROR" class="icon icon-info-circle info text-danger"></i>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100">
            <div class="flex-1">
              <button *ngIf="domainState?.operation !== erDomainOperation.PROGRESS" type="button"  class="btn btn-secondary mr-1"
                (click)="closeModal();">
                Close
              </button>
            </div>
            <div>
              <app-check-server-admin-permissions [server]="server">
                <button type="button" *ngIf="domainState?.operation !== erDomainOperation.COMPLETE && !domainState?.hasFailure" class="btn btn-primary mr-1" [disabled]="domainState?.operation === erDomainOperation.PROGRESS" (click)="processedDomain()" appPermissionDisable>
                  Proceed
                </button>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <button type="button" *ngIf="domainState?.hasFailure" class="btn btn-primary mr-1" [disabled]="domainState?.operation === erDomainOperation.PROGRESS" (click)="processedDomain(true)" appPermissionDisable>
                  Proceed all failed nodes
                </button>
              </app-check-server-admin-permissions>
            </div>
          </div>
        </div>
      </div>
    </div>
</ng-template>


<div bsModal #cancelDomainModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">  
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title text-truncate">
                Cancel ER Domain
            </h5>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
            <div class="pb-3">
                <span>
                  <i class="icon icon-warning text-warning pr-1"></i> Are you sure you want to cancel ER domain {{domainState?.status === erDomainStatus.CREATE?'creation':'modification'}}?
                </span>
            </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100">
            <div class="flex-1">
              <button class="btn btn-secondary" (click)="cancelDomainModal.hide();">Close</button>
            </div>
            <div>
              <button class="btn btn-primary" (click)="cancelERDomain(); deleteDomainDraft();">Yes</button>
              <!-- <button class="btn btn-primary"  (click)="saveAsDraft();" *ngIf="domainState.status === erDomainStatus.CREATE">{{(isDraft)?'Update draft':'Save as draft'}}</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
</div>



<div bsModal #loadDraftModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">  
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title text-truncate">
                Draft
            </h5>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
            <div class="pb-3">
                <span>
                  Would you like to edit the existing draft, or delete it and start fresh?
                </span>
            </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100">
            <div class="flex-1">
              <button class="btn btn-secondary" (click)="loadDraftModal.hide();">Close</button>
            </div>
            <div>
              <button class="btn btn-secondary" (click)="startFreshDomain()">Start Fresh</button>
              <button class="btn btn-primary"  (click)="loadDraftModal.hide(); loadFromDraft();">Load Draft</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
