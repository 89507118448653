/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright HCL Technologies Ltd. 2017, 2022, 2024. All Rights Reserved.
 *******************************************************************************/
import { SidebarElement } from '../../shared/sidebar/sidebar';
import { InformixServer } from './informixServer';

export class ServerSidebar {

  public static getSidebar(server: InformixServer): SidebarElement[] {
    return [
      {
        label: server.name,
        link: './',
        linkActiveExact: true,
        workOffline: true,
        children: [
          {
            label: 'Setup',
            link: 'setup',
            hidden: !server.permissions.admin,
            searchKeywords: ['agent'],
            workOffline: true
          },
          {
            label: 'Dashboards',
            link: 'dashboards',
            workOffline: false
          },
          {
            label: 'Monitoring',
            link: 'monitoring',
            searchKeywords: ['sensor'],
            workOffline: true
          },
          {
            label: 'Alerting',
            link: 'alerting',
            workOffline: true
          },
          {
            label: 'Permissions',
            link: 'permissions',
            hidden: !server.permissions.admin,
            searchKeywords: ['user'],
            workOffline: true
          }
        ]
      },
      {
        label: 'Incidents',
        link: 'incidents',
        searchKeywords: ['alert'],
        workOffline: true
      },
      {
        label: 'Configuration',
        link: 'onconfig',
        searchKeywords: ['onconfig'],
        workOffline: false
      },
      {
        label: 'DB Server Log',
        workOffline: false,
        children: [
          {
            label: 'Online Log',
            link: 'logs/onlinelog'
          },
          {
            label: 'ON-Bar Activity Log',
            link: 'logs/onbarlog'
          },
          {
            label: 'Admin API Log',
            link: 'logs/adminapi'
          }
        ]
      },
      {
        label: 'Performance',
        workOffline: false,
        children: [
          {
            label: 'Checkpoints',
            link: 'checkpoints'
          },
          {
            label: 'Sessions',
            link: 'sessions'
          },
          {
            label: 'Threads',
            link: 'performance/threads'
          },
          {
            label: 'Virtual Processors',
            link: 'performance/vps',
            searchKeywords: ['vp']
          }
        ]
      },
      {
        label: 'Replication',
        workOffline: false,
        children: [
          {
            label: 'Connection Manager',
            link: 'cm',
            searchKeywords: ['connection manager', 'sla', 'connection units']
          },
          {
            label: 'High Availability',
            link: 'ha',
            searchKeywords: ['high availability', 'cluster']
          },
          {
            label: 'ER Domain',
            link: 'enterprise-replication',
            searchKeywords: ['enterprise', 'replication', 'domain', 'er']
          },
          {
            label: 'Replicate',
            link: 'replicate',
            searchKeywords: ['replicate']
          }
        ]
      },
      {
        label: 'Schema Manager',
        link: 'schema',
        searchKeywords: ['schema', 'sql', 'table', 'index', 'query', 'editor', 'onstat'],
        workOffline: false
      },
      {
        label: 'DB Server Administration',
        workOffline: false,
        children: [
          {
            label: 'Auto Update Statistics',
            link: 'aus'
          },
          {
            label: 'Privileges',
            link: 'privileges',
            searchKeywords: ['internal user', 'mapped user']
          },
          {
            label: 'Task Scheduler',
            link: 'scheduler'
          },
        ]
      },
      {
        label: 'Storage',
        link: 'storage',
        linkActiveExact: true,
        workOffline: false,
        children: [
          {
            label: 'Spaces',
            link: 'storage/spaces',
            searchKeywords: ['dbspace', 'chunk']
          },
          {
            label: 'Pool',
            link: 'storage/pool'
          },
          {
            label: 'Tables & Indexes',
            link: 'storage/tablesIndexes',
            searchKeywords: ['compress', 'repack', 'shrink', 'defragment', 'space', 'optimize', 'storage policies']
          },
          {
            label: 'Backups',
            link: 'storage/backups',
            searchKeywords: ['onbar', 'ontape']
          },
          {
            label: 'Logs',
            link: 'storage/rlogs',
            searchKeywords: ['physical log', 'logical log']
          }
        ]
      },
      {
        label: 'SQL Tracing',
        link: 'sqltrace',
        searchKeywords: ['sqltrace'],
        workOffline: false
      },
      {
        label: 'System Reports',
        link: 'system-reports',
        workOffline: false
      },
      {
        label: 'System Resources',
        link: 'system',
        searchKeywords: ['os', 'operating system'],
        workOffline: false,
        children: [
          {
            label: 'Memory',
            link: 'memory',
            searchKeywords: ['low memory manager']
          }
        ]
      }
    ];
  }
}
