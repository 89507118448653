<form role="form" [formGroup]="formGroup">
  <div class="ml-4">
    <h6>This action expands the selected space. The server determines whether to extend an existing chunk or to add a
      chunk.</h6>
    <div class="form-group">
      <div *ngIf="_selectedSpace" class="row">
        <div class="col-12 col-lg-4 col-xl-3 mt-2 pr-0">
          <label class="col-form-label"><strong>Current Size:</strong></label>
          <div>{{_selectedSpace.size | byte}}</div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mt-2 pr-0">
          <label class="col-form-label"><strong>Expand the space by:</strong></label>
          <div>{{_selectedSpace.extend_size | storageExtendSize}}
            ( Maximum Size )
          </div>
        </div>
      </div>
      <label class="col-form-label mt-3"><strong>Expand Size</strong></label>
      <div class="form-inline">
        <input formControlName="expandSize" class="form-control col-sm-2" type="number" min="1" tooltip=""
          appExtendedFormControl>
        <select formControlName="expandSizeUnit" class="form-control ml-2" style="width:100" appExtendedFormControl>
          <option *ngFor="let unit of unitOptions">{{unit}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="form-group row d-flex flex-row-reverse">
    <div class="col-sm-3 d-flex justify-content-end">
      <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="doExpandSpace()" [disabled]="!this.formGroup.valid">Apply</button>
    </div>
  </div>
</form>
