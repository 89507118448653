/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { InformixServerType } from '../../dashboard/servers/informixServer';
import { Permission, ServerTypes, SERVER_TYPES_COLORS } from './hdr-permission.model';
export class HDRPermission {

    private server: InformixServerType;
    private permission: Permission;

    constructor(permission: Permission, serverType) {
        this.permission = permission;
        // Set Undefined as server type if for null serverType
        if (!serverType) {
            serverType = { serverType: ServerTypes.NA, isReadOnly: false};
        }
        this.server = serverType;
    }

    /**
     * Check if server has hdr permission to do the operation
     *
     * @returns
     */
    isAllow(): boolean {
        const allowOnServer =  this.permission.allow.find(el => el.serverType === this.server.serverType);
        const allowOnReadOnly = allowOnServer &&
                                (
                                    allowOnServer.allowOnReadOnly && this.server.isReadOnly ||
                                    allowOnServer.allowOnReadOnly === false && this.server.isReadOnly === false ||
                                    (allowOnServer.allowOnReadOnly === true || allowOnServer.allowOnReadOnly === undefined)
                                );
        return allowOnServer && allowOnReadOnly;
    }

    /**
     * Retruns constructed message for different type of the servers.
     *
     * @returns
     */
    getMessage(): string {
        return `This operation is not allowed on \
        ${this.server.serverType}${this.server.isReadOnly && !this.isAllow() ? ' : Read-Only' : ''} server.`;
    }

    isReadOnly() {
        return this.server.isReadOnly;
    }

    isStandardServer() {
        return this.server.serverType === ServerTypes.STANDARD;
    }

    isPrimaryServer() {
        return this.server.serverType === ServerTypes.PRIMARY;
    }

    isSecondaryServer() {
        return this.server.serverType === ServerTypes.SECONDARY;
    }

    isRSSServer() {
        return this.server.serverType === ServerTypes.RSS;
    }

    isSDSServer() {
        return this.server.serverType === ServerTypes.SDS;
    }

    isNAServer() {
        return this.server.serverType === ServerTypes.NA;
    }

    getBackgroundColor(): string {
        return SERVER_TYPES_COLORS[this.server.serverType];
    }

    getServerType(): string {
        return this.server.serverType;
    }

    getPermissionId() {
        return (this.permission) ? this.permission.id : '0';
    }

    fromSecondary() {
        return !(this.server.serverType === ServerTypes.PRIMARY ||
                this.server.serverType === ServerTypes.STANDARD ||
                this.server.serverType === ServerTypes.NA);
    }
}
