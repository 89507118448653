/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';


@Component({
  selector: 'app-dashboard-server-info',
  templateUrl: 'dashboard-server-info.html'
})
export class DashboardServerInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() serverInfo: InformixServerStat;

  serverHostInfo: any = null;
  serverIWAInfo: any = null;
  totalVPs: number = null;
  cpuVPs: number = null;
  serverUptime: string = null;

  private dashboardRequestSub: Subscription = null;
  private liveUptimeInterval: number = null;

  constructor(private serverService: InformixServerService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverInfo) {
      this.handleServerInfoRefreshed();
    }
  }

  private handleServerInfoRefreshed() {
    this.serverUptime = this.formatUptime(this.serverInfo.uptime);
    this.dashboardRequestSub = this.serverService.getDashboardServerInfo(this.server.id).subscribe(info => {
      this.serverHostInfo = info.host;
      this.serverIWAInfo = info.iwa;

      let totalCount = 0;
      let cpuCount = 0;
      const vps: any[] = info.vps;
      vps.forEach(vp => {
        totalCount += vp.count;
        if (vp.class === 'cpu') {
          cpuCount = vp.count;
        }
      });
      this.totalVPs = totalCount;
      this.cpuVPs = cpuCount;
    }, err => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    if (this.dashboardRequestSub) {
      this.dashboardRequestSub.unsubscribe();
    }
    if (this.liveUptimeInterval) {
      window.clearInterval(this.liveUptimeInterval);
    }
  }

  public formatUptime(seconds: number) {
    const units: string[] = ['day', 'hour', 'minute', 'second'];
    const unitValues: number[] = [86400, 3600, 60, 1];
    let formatted = '';
    for (let i = 0; i < units.length; i++) {
      const value = Math.floor(seconds / unitValues[i]);
      if (value > 0) {
        formatted += value + ' ' + units[i];
        if (value > 1) {
          formatted += 's';
        }
        formatted += ' ';
        seconds %= unitValues[i];
      }
    }

    return formatted;
  }
}
