/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { InformixServer } from '../servers/informixServer';
import { InformixTreeItem } from '../informixTreeItem';
export const GROUP_ROOT_ID = 0;

export interface GroupAggregateInfo {
  serverCount: number;
  serverCountTotal: number;
  unreadIncidents: number;
}

export class InformixServerGroup extends InformixTreeItem {
  public name: string;
  public groups: InformixServerGroup[];
  public servers: InformixServer[];
  public ancestors: InformixServerGroup[];
  public isRoot: boolean;
  public aggregate: GroupAggregateInfo;

  private groupMap: Map<number, InformixServerGroup>;
  private serverMap: Map<number, InformixServer>;

  constructor(parent: InformixServerGroup, name: string);
  constructor(json: any);
  constructor(parentIdOrJson: any, name?: string) {
    if (parentIdOrJson instanceof InformixServerGroup) {
      super(null, parentIdOrJson.id, name, 0);
      this.parent = parentIdOrJson;
      this.permissions = this.parent.permissions;
      this.groups = [];
      this.servers = [];
      this.ancestors = [];
      this.aggregate = {
        serverCount: 0,
        serverCountTotal: 0,
        unreadIncidents: 0
      };
    } else {
      const json = parentIdOrJson;
      super(json.id, json.parentGroupId, json.name, json.permissions);
      this.aggregate = json.aggregate;

      if (Array.isArray(json.groups)) {
        this.groups = (json.groups as any[]).map(groupJson => {
          const group = new InformixServerGroup(groupJson);
          group.parent = this;
          return group;
        });
      } else {
        this.groups = [];
      }

      if (Array.isArray(json.servers)) {
        this.servers = (json.servers as any[]).map(serverJson => {
          const server = new InformixServer(serverJson);
          server.parent = this;
          return server;
        });
      } else {
        this.servers = [];
      }

      if (Array.isArray(json.ancestors)) {
        this.ancestors = (json.ancestors as any[]).map(groupJson => new InformixServerGroup(groupJson));
      } else {
        this.ancestors = [];
      }

      if (this.id == null) {
        this.id = GROUP_ROOT_ID;
      }
    }

    this.isRoot = this.id === GROUP_ROOT_ID;
    this.groupMap = null;
    this.serverMap = null;
  }

  getGroup(groupId: number): InformixServerGroup {
    this.buildChildrenMaps();
    return this.groupMap.get(groupId);
  }

  getServer(serverId: number): InformixServer {
    this.buildChildrenMaps();
    return this.serverMap.get(serverId);
  }

  private buildChildrenMaps() {
    if (!this.groupMap || !this.serverMap) {
      this.groupMap = new Map();
      this.serverMap = new Map();
      const groups: InformixServerGroup[] = [this];
      while (groups.length > 0) {
        const group = groups.shift();
        group.groups.forEach(childGroup => {
          this.groupMap.set(childGroup.id, childGroup);
          groups.push(childGroup);
        });
        group.servers.forEach(childServer => this.serverMap.set(childServer.id, childServer));
      }
    }
  }
}
