<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <h4><strong>{{reportTitle}}</strong>
  </h4>
  <div *ngIf="sqltraceEnabled; else sqlTraceOff">
    <div class="row">
      <div class="col-7">
        <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
        </app-date-time-picker>
      </div>
      <div class="col-5 mb-2 form-inline justify-content-end align-self-start">
        <button class="btn btn-primary" (click)="updateReport()">{{ 'systemReports.updateReport' | translate }}</button>
      </div>
    </div>
    <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
    <ng-template [ngIf]="reportData">
      <div *ngIf="reportData.length > 0; else noDataAvailable">
        <div class="card card-body" *ngFor="let sqlStmt of reportData; let index = index">
          <div class="font-weight-bold mb-1">
            #{{index + 1}}
          </div>
          <app-data-table class="table-responsive" [data]="sqlStmt" [enablePagination]="false" [minHeight]="'auto'">
            <app-data-table-column [title]="'sqltrace.sqlStatement' | translate" property="sql_statement">
            </app-data-table-column>
          </app-data-table>
          <ng-template [ngIf]="sqlStmt[0].query_plan && sqlStmt[0].query_plan.length > 0">
            <label class="font-weight-bold text-center mt-2">
              {{ 'sqltrace.queryTree' | translate }}
            </label>
            <app-sqltrace-query-tree [sqltraceIterators]="sqlStmt[0].query_plan"></app-sqltrace-query-tree>
          </ng-template>
          <label class="mt-4 font-weight-bold">
            {{ 'sqltrace.statementStatistics' | translate }}
          </label>
          <app-data-table class="table-responsive" [data]="sqlStmt" [enablePagination]="false" [minHeight]="'auto'">
            <app-data-table-column [title]="'sqltrace.sessionId' | translate" property="sql_sid">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.userId' | translate" property="sql_uid">
            </app-data-table-column>
            <app-data-table-column [title]="'database.database' | translate" property="sql_database">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.statementType' | translate" property="sql_stmtname">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.pdq' | translate" property="sql_pdq"></app-data-table-column>
            <app-data-table-column [title]="'sqltrace.statementCompletionTime' | translate" property="finishtime">
              <ng-template appTableCell let-finishtime="item">
                {{finishtime * 1000 | date:'y-MM-dd HH:mm:ss'}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.responseTime' | translate" property="runtime">
            </app-data-table-column>
          </app-data-table>
          <app-data-table class="table-responsive" [data]="sqlStmt" [enablePagination]="false" [minHeight]="'auto'">
            <app-data-table-column [title]="'systemReports.pageReads' | translate" property="sql_pgreads" #dataTableCol>
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.bufferReads' | translate" property="sql_bfreads">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.readsCache' | translate" property="rdcache">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.dataBufferReads' | translate" property="databufreads">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.indexBufferReads' | translate" property="sql_bfidxreads">
            </app-data-table-column>
            <app-data-table-column [title]="'systemReports.pageWrites' | translate" property="sql_pgwrites">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.bufferWrites' | translate" property="sql_bfwrites">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.writesCache' | translate" property="wrcache">
            </app-data-table-column>
          </app-data-table>
          <app-data-table class="table-responsive" [data]="sqlStmt" [enablePagination]="false" [minHeight]="'auto'">
            <app-data-table-column [title]="'systemReports.lockRequest' | translate" property="sql_lockreq">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.lockWaits' | translate" property="sql_lockwaits">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.lockWaitTimeInSec' | translate" property="lockwait">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.logSpace' | translate" property="logspace">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.diskSorts' | translate" property="sql_sortdisk">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.memorySorts' | translate" property="sql_sorttotal">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.numberOfTables' | translate" property="sql_numtables">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.numberOfIterators' | translate" property="sql_numiterators">
            </app-data-table-column>
          </app-data-table>
          <app-data-table class="table-responsive" [data]="sqlStmt" [enablePagination]="false" [minHeight]="'auto'">
            <app-data-table-column [title]="'sqltrace.totalExecutions' | translate" property="sql_executions">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.totalExecutionsTime' | translate" property="totaltime">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.averageExecutionsTime' | translate" property="avgtime">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.maximumExecutionsTime' | translate" property="maxtime">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.ioWaits' | translate" property="sql_numiowaits">
            </app-data-table-column>
            <app-data-table-column [title]="'systemReports.ioWaitTime' | translate" property="iowait">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.averageIoWaitTime' | translate" property="avgiowaits">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.averageRowsSecond' | translate" property="rowspersec">
            </app-data-table-column>
          </app-data-table>
          <app-data-table class="table-responsive" [data]="sqlStmt" [enablePagination]="false" [minHeight]="'auto'">
            <app-data-table-column [title]="'sqltrace.estimatedCost' | translate" property="sql_estcost">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.estimatedRows' | translate" property="sql_estrows">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.actualRows' | translate" property="sql_actualrows">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.sqlError' | translate" property="sql_sqlerror">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.isamError' | translate" property="sql_isamerror">
            </app-data-table-column>
            <app-data-table-column [title]="'systemReports.isolationLevel' | translate" property="sql_isollevel">
            </app-data-table-column>
            <app-data-table-column [title]="'sqltrace.sqlMemory' | translate" property="sqlmemory">
              <ng-template appTableCell let-item="item">
                {{item | byte}}
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </div>
      <ng-template #noDataAvailable>
        <div class="alert alert-info">
          <i class="icon icon-info-circle text-info"></i>
          {{ 'dataTable.noDataAvailable' | translate }}
        </div>
      </ng-template>
    </ng-template>
  </div>
  <ng-template #sqlTraceOff>
    <div class="alert alert-warning">
      <i class="icon icon-warning text-warning"></i>
      {{ 'sqltrace.sqltraceDisabled' | translate }}
      <a [routerLink]="['/dashboard', 'servers', server.id, 'sqltrace']">
        {{ 'sqltrace.clickHere' | translate }}
      </a>
      {{ 'sqltrace.toEnableSqltrace' | translate }}
    </div>
  </ng-template>
</div>
