/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ServerSpace } from '../../../storage/serverSpace';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-values-modal',
  templateUrl: './add-values-modal.component.html',
  styleUrls: ['../create-index.component.scss']
})
export class AddValuesModalComponent implements OnInit {
  data: ServerSpace;
  columnValues: Array<{ value: any }>;
  addValuesForm: UntypedFormGroup;
  value: UntypedFormControl;
  isSameValue = true;
  dbSpacesList: ServerSpace[];
  type: string;
  numericTypes = ['SMALLINT', 'INTEGER', 'FLOAT', 'SMALLFLOAT', 'DECIMAL', 'SERIAL', 'MONEY', 'BIGINT', 'BIGSERIAL'];

  @Output() actionComplete = new EventEmitter<{ values: any[]; data: ServerSpace }>();

  @ViewChild('addValuesModal') addValuesModal: ModalDirective;

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService) { }

  ngOnInit() {
    this.value = new UntypedFormControl(null, [this.duplicateValueCheck()]);
    this.addValuesForm = this.fb.group({
      value: this.value
    });
  }

  show(data: ServerSpace, dbSpacesList: ServerSpace[], type: string) {
    this.data = data;
    this.dbSpacesList = dbSpacesList;
    this.type = type;
    !data.columnValues ? this.columnValues = [] : this.setValues(data.columnValues);
    this.addValuesModal.show();
  }

  setValues(columnValues) {
    this.columnValues = [];
    columnValues.forEach(element => {
      this.columnValues.push({ value: element });
    });
  }

  addValues() {
    if (this.value.value !== '') {
      this.columnValues.push({ value: this.value.value });
      this.columnValues = [...this.columnValues];
      this.value.setValue('');
    }
    this.isSameValue = true;
  }

  saveValues() {
    let valuesArray = this.columnValues.map(item => item.value);
    if (valuesArray.length === 0) {
      valuesArray = null;
    }
    this.actionComplete.emit({ values: valuesArray, data: this.data });
    this.addValuesModal.hide();
    this.value.reset();
  }

  deleteValues(row) {
    this.columnValues = this.columnValues.filter(i => i.value !== row.value);
  }

  duplicateValueCheck(): ValidatorFn {
    return (): { customError: string } | null => {
      let error: string;
      this.translate.get('schemaManager.createIndex.addValuesModal.numericValueError').subscribe((text: string) => {
        error = text;
      });
      const numericError = { customError: error };
      if (this.addValuesForm && this.value) {
        this.value.setErrors({ customError: null });
        this.isSameValue = false;
        if (!this.value.value) {
          this.isSameValue = true;
        }
        const valueMatcher = /^-?[0-9]\d*(\.\d+)?$/g;
        if (this.value.value && this.value.value.trim()) {
          if (this.numericTypes.some(el => this.type.includes(el))) {
            if (!this.value.value.match(valueMatcher)) {
              this.isSameValue = true;
              return numericError;
            } else {
              return this.typeCheck();
            }
          } else {
            return this.typeCheck();
          }
        } else {
          this.isSameValue = true;
        }
      }
    };
  }

  typeCheck() {
    let error: string;
    this.translate.get('schemaManager.createIndex.addValuesModal.duplicateValue').subscribe((text: string) => {
      error = text;
    });
    const errors = { customError: error };
    const otherColumnValues = [];
    this.dbSpacesList.forEach(element => {
      if (element.columnValues) {
        element.columnValues.forEach(column => {
          otherColumnValues.push(column);
        });
      }
    });
    if (this.columnValues) {
      if (this.columnValues.findIndex(item => item.value.toString() === this.value.value) === -1 &&
        otherColumnValues.findIndex(item => item.toString() === this.value.value) === -1) {
        return null;
      } else {
        this.isSameValue = true;
        return errors;
      }
    } else {
      return null;
    }
  }

  hideModal() {
    this.addValuesModal.hide();
    this.value.reset();
  }
}
