<h5 class="mb-0 font-weight-bold">
  {{slaToEdit ? ('connectionManager.modifySLA' | translate) : ('connectionManager.createSLA' | translate)}}</h5>
<div class="pt-4 px-4">
  <strong>{{'connectionManager.slaHeading' | translate}}</strong>
  <h6>{{'connectionManager.slaDescription' | translate}}</h6>
  <div class="row mt-3">
    <div class="col-xl-8">
      <form [formGroup]="slaDetailsForm">
        <div class="row">
          <div class="form-group font-weight-bold col-6">
            <label>{{'connectionManager.slaName' | translate}}</label>
            <input type="text" class="form-control w-50"
              [placeholder]="'connectionManager.slaNamePlaceholder' | translate" formControlName="slaName" tooltip=""
              appExtendedFormControl [customErrors]="duplicateNameErrorHandler">
          </div>
          <div class="form-group font-weight-bold col-6">
            <label>{{'connectionManager.label.service' | translate}}</label>
            <input type="text" class="form-control w-50"
              [placeholder]="'connectionManager.servicePlaceholder' | translate" formControlName="service" tooltip=""
              appExtendedFormControl>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label><strong>{{'connectionManager.label.workers' | translate}}</strong>
              {{'connectionManager.label.workersDesc' | translate}}</label>
            <input type="number" class="form-control w-25" min="1" formControlName="workers" tooltip=""
              appExtendedFormControl>
          </div>
          <div class="form-group font-weight-bold col-6">
            <label>{{'connectionManager.label.netType' | translate}}</label>
            <select class="form-control w-50" formControlName="netType">
              <option *ngFor="let netType of netTypeValues" [value]="netType">
                {{netType}}
              </option>
            </select>
          </div>
        </div>
        <div class="row pb-2 align-items-center">
          <div class="form-group col-xl-6 col-lg-6 mb-2">
            <div class="font-weight-bold mb-2 pb-1">{{'connectionManager.label.useAliases' | translate}}
            </div>
            <span class="color-gray-30 pr-2">{{'button.off' | translate}}</span>
            <label class="switch">
              <input type="checkbox" formControlName="useAliases">
              <span class="slider round"></span>
            </label>
            <span class="color-gray-30 pl-2 ml-1">{{'button.on' | translate}}</span>
          </div>
          <div class="form-group col-xl-6 col-lg-6 mb-2">
            <label class="font-weight-bold pr-3">
              {{'connectionManager.label.connectionMode' | translate}}</label>
            <app-radio-group formControlName="connectionMode" class="d-flex align-items-center">
              <span>
                <app-radio value="redirect" class="mr-4">
                  {{'connectionManager.label.redirect' | translate}}
                </app-radio>
                <app-radio value="proxy">{{'connectionManager.label.proxy' | translate}}</app-radio>
              </span>
            </app-radio-group>
          </div>
        </div>
        <ng-container *ngIf="cmUnitType === 'CLUSTER' || cmUnitType === 'SERVERSET' else slider">
          <div class="form-group"><label class="font-weight-bold">
              {{'connectionManager.label.slaPolicy' | translate}}</label>
            <app-radio-group formControlName="slaPolicy" class="pl-3 row align-items-center">
              <span>
                <app-radio class="pr-3" value="WORKLOAD">
                  {{'connectionManager.label.workload' | translate}}
                </app-radio>
                <app-radio value="ROUNDROBIN">{{'connectionManager.label.roundRobin' | translate}}
                </app-radio>
              </span>
            </app-radio-group>
          </div>
        </ng-container>
        <ng-template #slider>
          <div class="form-group"><label class="font-weight-bold">
              {{'connectionManager.label.slaPolicy' | translate}}</label>
            <app-radio-group formControlName="slaPolicy" class="pl-3 row align-items-center">
              <span>
                <app-radio value="WORKLOAD" class="col-9 px-0">
                  {{'connectionManager.label.slaPolicybasedOnLFW' | translate}}
                </app-radio>
                <app-radio value="ROUNDROBIN" class="col-3 pl-0">
                  {{'connectionManager.label.roundRobin' | translate}}
                </app-radio>
              </span>
            </app-radio-group>
          </div>
          <div class="col-xl-9 col-lg-9">
            <div slider class="position-relative mt-4 mb-2 pb-4 mt-n2 mr-5" *ngIf="slaPolicy.value === 'WORKLOAD'">
              <div class="row mb-5 mt-4">
                <div class="col-4 text-center">
                  <strong>{{'connectionManager.label.latency' | translate}}:
                    {{leftValue.value}}</strong>
                </div>
                <div class="col-4 text-center">
                  <strong>{{'connectionManager.label.failure' | translate}}:
                    {{rightValue.value - leftValue.value}}</strong>
                </div>
                <div class="col-4 text-center">
                  <strong class="text-capitalize">{{'connectionManager.label.workload' | translate}}:
                    {{100 - rightValue.value}}</strong>
                </div>
              </div>
              <div class="position-absolute">
                <div class="position-absolute slider-bar" [ngStyle]="{'width.%' : leftValue.value}">
                </div>
                <div inverse-right class="position-absolute slider-bar"
                  [ngStyle]="{'width.%' : (100 - rightValue.value)}">
                </div>
                <div class="position-absolute slider-bar" [ngStyle]="{'left.%' : leftValue.value,
                                                            'right.%' : (100 - rightValue.value)}"></div>
                <span class="thumb position-absolute rounded-circle bg-primary"
                  [ngStyle]="{'left.%' : leftValue.value}"></span>
                <span class="thumb position-absolute rounded-circle bg-primary"
                  [ngStyle]="{'left.%' : rightValue.value}"></span>
                <div sign class="position-absolute bg-primary text-white text-center"
                  [ngStyle]="{'left.%' : leftValue.value}">
                  <span id="value">{{leftValue.value}}</span>
                </div>
                <div sign class="position-absolute bg-primary text-white text-center"
                  [ngStyle]="{'left.%' : rightValue.value}">
                  <span id="value">{{rightValue.value}}</span>
                </div>
              </div>
              <input class="w-100 position-absolute" type="range" value="30" min="0" max="100"
                formControlName="leftValue" />

              <input class="w-100" [ngClass]="{'position-absolute' : leftValue.value !== 100}" type="range" value="60"
                min="0" max="100" formControlName="rightValue" />
            </div>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
  <div class="col-xl-12">
    <app-sla-db-servers [availableServers]="servers" (updateSelectedServers)="getSelectedServers($event)">
    </app-sla-db-servers>
  </div>
  <div class="col-11 pb-4 pr-0">
    <div class="float-right pr-0">
      <button type="button" class="btn btn-secondary btn-fixed-width" (click)="closeSLA.emit(false)"
        [disabled]="saveInProgress">{{'button.cancel' | translate}}</button>
      <button type="button" class="btn btn-primary btn-fixed-width" (click)="saveSLA()"
        [disabled]="!slaDetailsForm.valid || selectedServers.length < 1 || saveInProgress">
        {{'button.save' | translate}}
      </button>
      <div *ngIf="saveInProgress" class="ml-2 d-inline-block">
        <i class="icon icon-circle-notch icon-spin mt-4"></i>
      </div>
    </div>
  </div>
</div>
