<ng-template [ngIf]="inline">
  {{ '{' }}
  <app-json-tree [tree]="jsonTree" [inline]="true"> </app-json-tree>
  {{ '}' }}
  <button *ngIf="showExpandToggle" type="button" class="btn btn-sm btn-secondary" (click)="inline = false">
    <i class="icon icon-search"></i>
  </button>
</ng-template>
<div class="d-flex p-1" *ngIf="!inline">
  <div class="flex-1">
    {{ '{' }}
    <app-json-tree [tree]="jsonTree" [inline]="false"> </app-json-tree>
    {{ '}' }}
  </div>
  <div *ngIf="showExpandToggle">
    <button type="button" class="btn btn-sm btn-close" (click)="inline = true">
      <i class="icon icon-times"></i>
    </button>
  </div>
</div>
