<div class="container-fluid page-container">
  <div class="row align-items-center">
    <div class="col">
      <h3>Sensor Management</h3>
    </div>
    <div class="col-auto">
      <a routerLink="edit" class="btn btn-sm btn-primary">
        Create Sensor
      </a>
    </div>
  </div>

  <app-data-table *ngIf="sensorTypes" [data]="sensorTypes" [dataMapFn]="sensorTypeDataMap" sortBy="name"
    noDataAlertMessage="No custom sensors have been created yet.">
    <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Description" property="description" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-sensorType="row">
        <button class="btn btn-sm btn-icon" (click)="deleteSensorType(sensorType)">
          <i class="icon icon-trash"></i>
        </button>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
