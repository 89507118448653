/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { SqltraceIteratorTreeNode } from './sqltrace-iterator-tree-node';

@Component({
  selector: 'app-sqltrace-query-tree',
  templateUrl: 'sqltrace-query-tree.html'
})
export class SqltraceQueryTreeComponent implements OnChanges, AfterViewInit {
  @ViewChild('queryTreeContainer') queryTreeContainer: ElementRef;
  @Input() sqltraceIterators: any[];
  @Input() maxHeight: number;
  iteratorTreeRootNode: SqltraceIteratorTreeNode = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sqltraceIterators) {
      this.convertIteratorDataToTreeNodes(this.sqltraceIterators);
    }
  }
  ngAfterViewInit() {
    this.queryTreeContainer.nativeElement.scrollLeft =
    (this.queryTreeContainer.nativeElement.scrollWidth - this.queryTreeContainer.nativeElement.clientWidth) / 2;
  }

  private convertIteratorDataToTreeNodes(iterators: any[]) {
    let lastNode: SqltraceIteratorTreeNode = null;
    iterators.forEach((it: any) => {
      const treeNode = new SqltraceIteratorTreeNode(it.sql_itr_id, it.sql_itr_left, it.sql_itr_right, it.sql_itr_cost, it.sql_itr_estrows,
        it.sql_itr_numrows, it.sql_itr_type, it.sql_itr_info, it.sql_itr_time, it.sql_itr_partnum, it.sql_itr_table);
      if (this.iteratorTreeRootNode == null) {
        this.iteratorTreeRootNode = treeNode;
      } else {
        if (lastNode && lastNode.sql_itr_left === treeNode.sql_itr_id || lastNode.sql_itr_right === treeNode.sql_itr_id) {
          lastNode.addChild(treeNode);
        } else {
          this.addNodeToTree(this.iteratorTreeRootNode, treeNode);
        }
      }
      lastNode = treeNode;
    });
  }

  private addNodeToTree(rootNode: SqltraceIteratorTreeNode, newNode: SqltraceIteratorTreeNode): boolean {
    if (rootNode.sql_itr_left === newNode.sql_itr_id) {
      rootNode.unshiftChild(newNode);
      return true;
    } else if (rootNode.sql_itr_right === newNode.sql_itr_id) {
      rootNode.addChild(newNode);
      return true;
    }
    for (let i = 0; i < rootNode.children.length; i++) {
      const child: SqltraceIteratorTreeNode = rootNode.children[i] as SqltraceIteratorTreeNode;
      const ret = this.addNodeToTree(child, newNode);
      if (ret) {
        return true;
      }
    }
    return false;
  }
}
