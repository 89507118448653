/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservablePoller } from '../../shared/observable-poller';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { InformixTreeItem } from '../informixTreeItem';

@Injectable()
export class IncidentsService {

  private groupIncidentsPollers = new Map<number, ObservablePoller<any>>();

  private serverIncidentsPollers = new Map<number, ObservablePoller<any>>();

  constructor(private httpClient: HttpClient) { }

  getIncidents(item: InformixTreeItem, afterTimestamp?: number): Observable<any> {
    let url = 'informix/' + (item instanceof InformixServerGroup ? 'groups/' : '') + item.id + '/incidents';
    if (afterTimestamp > 0) {
      url += '?after=' + afterTimestamp;
    }
    return this.httpClient.get(url);
  }

  pollIncidents(item: InformixTreeItem): Observable<any> {
    let poller = item instanceof InformixServerGroup ? this.groupIncidentsPollers.get(item.id) : this.serverIncidentsPollers.get(item.id);
    if (!poller) {
      poller = new ObservablePoller(5000, () => {
        const prev = poller.getPreviousValue() || [];
        let afterTimestamp: number = null;
        if (prev.length) {
          afterTimestamp = prev[0].when_created;
        }
        return this.getIncidents(item, afterTimestamp).pipe(map(newIncidents => newIncidents.length ? newIncidents.concat(prev) : prev));
      }, () => {
        if (item instanceof InformixServerGroup) {
          this.groupIncidentsPollers.delete(item.id);
        } else {
          this.serverIncidentsPollers.delete(item.id);
        }
      });
      if (item instanceof InformixServerGroup) {
        this.groupIncidentsPollers.set(item.id, poller);
      } else {
        this.serverIncidentsPollers.set(item.id, poller);
      }
    }
    return poller.getObservable();
  }

  updateIncidents(item: InformixTreeItem, updateData: any[]): Promise<any> {
    return this.httpClient.put('informix/' + (item instanceof InformixServerGroup ? 'groups/' : '') + item.id
      + '/incidents', { incidents: updateData }).toPromise();
  }

  deleteIncidents(item: InformixTreeItem, ids: any[]): Promise<any> {
    const bodyObjArr = [];
    for (const id of ids) {
      bodyObjArr.push({ id: id.id, server_id: id.server_id });
    }
    const httpOptions = {
      headers: new HttpHeaders(), body: { incidents: bodyObjArr }
    };
    return this.httpClient.delete('informix/' + (item instanceof InformixServerGroup ? 'groups/' : '')
      + item.id + '/incidents', httpOptions)
      .toPromise().then(() => {
        const poller = item instanceof InformixServerGroup ? this.groupIncidentsPollers.get(item.id) :
          this.serverIncidentsPollers.get(item.id);
        if (poller) {
          poller.injectValue(this.getIncidents(item));
        }
      });
  }
}
