/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';

import { InformixServer } from '../informixServer';
import { InformixDatabase } from '../schema/informix-database';
import { SqltraceService } from './sqltrace.service';

@Component({
  selector: 'app-sqltrace-config',
  templateUrl: 'sqltrace-config.html'
})
export class SqltraceConfigComponent implements OnInit {

  @Input() server: InformixServer;
  @Input() database: string;
  @Input()
  set config(sqltraceConfig: any) {
    this.sqltraceConfig = sqltraceConfig;
    this.setupForm();
  }

  sqltraceConfig: any;

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();

  isEditingConfig = false;
  formGroup: UntypedFormGroup = null;
  tracedUsersFormArray: UntypedFormArray = new UntypedFormArray([]);
  tracedDatabasesFormArray: Array<any> = [];
  multiSelectEditState: Boolean = false;

  modeOptions: { name: string; value: any }[] = [
    { name: 'Global', value: 'global' },
    { name: 'User', value: 'user' }
  ];
  levelOptions: { name: string; value: any }[] = [
    { name: 'Low', value: 'low' },
    { name: 'Medium', value: 'med' },
    { name: 'High', value: 'high' }
  ];

  sqlTracingHDR: HDRPermission;

  databases: InformixDatabase[] = null;
  dropdownSettings: any = {};
  selectedDatabases: Array<any> = [];
  constructor(
    private sqltraceService: SqltraceService,
    private hdrPermissionService: HDRPermissionService,
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit() {
    this.setupForm();
    this.sqlTracingHDR = this.hdrPermissionService.getByPermissionId('p3');
    this.sqltraceService.getDatabases(this.server).subscribe(databases => {
      this.databases = databases;
    });
  }
  onDatabaseSelect(databases: any) {
    this.tracedDatabasesFormArray = databases.map(database => database.name);
  }
  private setupForm() {
    this.tracedUsersFormArray = new UntypedFormArray([]);
    this.formGroup = new UntypedFormGroup({
      state: new UntypedFormControl('On', [Validators.required]),
      mode: new UntypedFormControl('global', [Validators.required]),
      level: new UntypedFormControl('low', [Validators.required]),
      nTraces: new UntypedFormControl(500, [Validators.required]),
      traceSize: new UntypedFormControl(2, [Validators.required]),
      tracedUsers: this.tracedUsersFormArray,
      tracedDatabases: new UntypedFormControl(this.tracedDatabasesFormArray),
      allDatabases: new UntypedFormControl(true, [Validators.required]),
      clearBuffer: new UntypedFormControl(false, [Validators.required])
    });
  }

  public doEditConfiguration() {
    if (this.sqlTracingHDR && !this.sqlTracingHDR.isAllow()) {
      return;
    }
    this.tracedDatabasesFormArray = [];
    this.formGroup.controls.state.setValue(this.sqltraceConfig.state);
    // checking the sql trace config state is 'ON' or 'SUSPENDED' setting the values to the form.
    if (this.sqltraceConfig.state === 'On' || this.sqltraceConfig.state === 'Suspended') {
      this.formGroup.controls.mode.setValue(this.sqltraceConfig.mode.toLowerCase());
      this.formGroup.controls.level.setValue(this.sqltraceConfig.level.toLowerCase());
      this.formGroup.controls.nTraces.setValue(this.sqltraceConfig.ntraces);
      this.formGroup.controls.traceSize.setValue(Math.round(this.sqltraceConfig.tracesize / 1024));
    }
    // checking the sql trace config state is 'OFF', selected Databese and untracedDatabases length is zero.
    // making alldatabase seletion false and pushing selectd database to the traced database array.
    if (this.database && this.sqltraceConfig.state === 'Off' && this.sqltraceConfig.databases.untracedDatabases.length === 0) {
      this.formGroup.controls.allDatabases.setValue(false);
      this.tracedDatabasesFormArray.push(this.database);
    } else {
      // setting defaults based on the existing sql tracing configuration.
      this.formGroup.controls.allDatabases.setValue(this.sqltraceConfig.databases.untracedDatabases.length === 0);
      if (this.sqltraceConfig.databases.untracedDatabases.length > 0) {
        const tracedDBs: string[] = this.sqltraceConfig.databases.tracedDatabases;
        tracedDBs.forEach(db => {
          this.tracedDatabasesFormArray.push(db);
        });
        if (this.database) {
          this.formGroup.controls.allDatabases.setValue(false);
          if (this.tracedDatabasesFormArray && this.tracedDatabasesFormArray.indexOf(this.database) === -1) {
            this.tracedDatabasesFormArray.push(this.database);
          }
        }
      }
    }
    this.selectedDatabases = [...this.selectedDatabases, ...this.tracedDatabasesFormArray];
    this.formGroup.controls.tracedDatabases.setValue(this.tracedDatabasesFormArray);
    if (this.sqltraceConfig.mode === 'User') {
      for (let i = this.tracedUsersFormArray.length - 1; i >= 0; i--) {
        this.tracedUsersFormArray.removeAt(i);
      }
      const tracedUsers: string[] = this.sqltraceConfig.tracedUsers;
      tracedUsers.forEach(user => {
        this.tracedUsersFormArray.push(new UntypedFormControl(user, [Validators.required]));
      });
    }
    this.setNewState(this.sqltraceConfig.state);
    this.isEditingConfig = true;
  }

  public doUpdateConfiguration() {
    if (this.sqlTracingHDR && !this.sqlTracingHDR.isAllow()) {
      return;
    }
    if (!this.formGroup.valid) {
      return;
    }
    if (this.formGroup.value.state === 'On') {
      const values = this.formGroup.value;
      if (values.allDatabases) {
        values.tracedDatabases = [];
      } else {
        values.tracedDatabases = this.tracedDatabasesFormArray;
      }
      const mode = values.mode;
      let users = values.tracedUsers;
      if (mode === 'global') {
        users = [];
      }
      this.sqltraceService.enableSqltracing(this.server, mode, values.level, values.nTraces, values.traceSize, users,
        values.tracedDatabases, values.clearBuffer).then(result => {

          this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
            if (type.isSuccess || type.isInfo) {
              this.success.emit(result.result_message);
              this.isEditingConfig = false;
            }
            if (type.isError) {
              this.error.emit('Error: ' + result);
            }
          });
        }).catch(err => {
          this.error.emit(err);
        });
    } else {
      this.sqltraceService.setSqltracingState(this.server, this.formGroup.value.state).then(result => {

        this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
          if (type.isSuccess || type.isInfo) {
            this.success.emit(result.result_message);
            this.isEditingConfig = false;
          }
          if (type.isError) {
              this.error.emit('Error: ' + result);
            }
        });
      }).catch(err => {
        this.error.emit(err);
      });
    }
  }

  public doCancelEditConfiguration() {
    if (this.sqlTracingHDR && !this.sqlTracingHDR.isAllow()) {
      return;
    }
    this.isEditingConfig = false;
  }

  public setNewState(newState: string) {
    if (newState === 'Suspended') {
      newState = 'Suspend';
    }
    this.formGroup.controls.state.setValue(newState);
    if (newState === 'On') {
      this.formGroup.controls.mode.enable();
      this.formGroup.controls.level.enable();
      this.formGroup.controls.nTraces.enable();
      this.formGroup.controls.traceSize.enable();
      this.formGroup.controls.tracedUsers.enable();
      this.formGroup.controls.tracedDatabases.enable();
      this.formGroup.controls.allDatabases.enable();
      this.formGroup.controls.clearBuffer.enable();
      this.multiSelectEditState = false;
    } else {
      this.formGroup.controls.mode.disable();
      this.formGroup.controls.level.disable();
      this.formGroup.controls.nTraces.disable();
      this.formGroup.controls.traceSize.disable();
      this.formGroup.controls.tracedUsers.disable();
      this.formGroup.controls.tracedDatabases.disable();
      this.formGroup.controls.allDatabases.disable();
      this.formGroup.controls.clearBuffer.disable();
      this.multiSelectEditState = true;
    }
  }

  public handleModeChanged() {
    if (this.formGroup.controls.mode.value === 'global') {
      for (let i = this.tracedUsersFormArray.length - 1; i >= 0; i--) {
        this.tracedUsersFormArray.removeAt(i);
      }
    } else {
      if (this.sqltraceConfig.tracedUsers.length === 0) {
        this.addNewUser();
      }
    }
  }

  public addNewUser() {
    this.tracedUsersFormArray.push(new UntypedFormControl(null, [Validators.required]));
  }

  public removeUser(index: number) {
    this.tracedUsersFormArray.removeAt(index);
  }
}
