/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorMessageHandlers } from '../../../shared/forms/extendedFormControl.directive';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerStoragePoolEntry } from './serverStoragePoolEntry';

@Component({
  selector: 'app-storage-pool-entry-form',
  templateUrl: 'storage-pool-entry-form.html'
})
export class StoragePoolEntryFormComponent implements OnInit {

  @Input() server: InformixServer = null;

  _entryToModify: ServerStoragePoolEntry = null;

  STORAGE_POOL_ENTRY_SIZE_MINIMUM = 1000;

  unitOptions: string[] = ['KB', 'MB', 'GB'];
  priorityOptions: any[] = [
    { label: 'High', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'Low', value: 3 }
  ];

  sizeErrorHandlers: ErrorMessageHandlers = {
    min: () => 'Cannot be smaller than 1000 KB',
    minAvailableSize: () =>
      'The available storage space must be at least 1000 KB. The available storage space is the ending offset minus the beginning offset.',
    minChunkSize: () => 'The chunk size cannot be greater than the available storage space.'
  };

  formGroup: UntypedFormGroup = null;

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  ngOnInit() {
    const pathFormControl = new UntypedFormControl(null, [Validators.required]);
    const priorityFormControl = new UntypedFormControl(1, [Validators.required]);
    const offsetFormControl = new UntypedFormControl(0, [Validators.required]);
    const sizeRadioFormControl = new UntypedFormControl('extendable', [Validators.required]);
    const fixedBySizeFormControl = new UntypedFormControl(50 * 1024, [Validators.required]);
    const fixedByEndOffsetFormControl = new UntypedFormControl(50 * 1024, [Validators.required, this.validateFixedByEndOffset.bind(this)]);
    const chunkSizeFormControl = new UntypedFormControl(50 * 1024, [Validators.required, this.validateChunkSize.bind(this)]);

    this.formGroup = new UntypedFormGroup({
      path: pathFormControl,
      priority: priorityFormControl,
      offset: offsetFormControl,
      sizeRadio: sizeRadioFormControl,
      fixedBySize: fixedBySizeFormControl,
      fixedByEndOffset: fixedByEndOffsetFormControl,
      chunkSize: chunkSizeFormControl,
    });

    offsetFormControl.valueChanges.subscribe(value => {
      fixedByEndOffsetFormControl.updateValueAndValidity();
      chunkSizeFormControl.updateValueAndValidity();
    });
    sizeRadioFormControl.valueChanges.subscribe(value => {
      chunkSizeFormControl.updateValueAndValidity();
    });
    fixedBySizeFormControl.valueChanges.subscribe(value => {
      chunkSizeFormControl.updateValueAndValidity();
    });
    fixedByEndOffsetFormControl.valueChanges.subscribe(value => {
      chunkSizeFormControl.updateValueAndValidity();
    });
  }

  get entryToModify(): ServerStoragePoolEntry {
    return this._entryToModify;
  }

  @Input()
  set entryToModify(entryToModify: ServerStoragePoolEntry) {
    if (!entryToModify || !this.formGroup) {
      return;
    }
    this._entryToModify = entryToModify;

    // For editing a storage pool entry, prepopulate with current values.
    const controls = this.formGroup.controls;
    controls['path'].setValue(entryToModify.path);
    controls['priority'].setValue(entryToModify.priority);
    controls['offset'].setValue(this.entryToModify.offset);
    controls['sizeRadio'].setValue((entryToModify.device_size === 0) ? 'extendable' : 'fixedBySize');
    if (entryToModify.device_size > 0) {
      controls['fixedBySize'].setValue(entryToModify.space_remaining);
      controls['fixedByEndOffset'].setValue(entryToModify.device_size);
    }
    controls['chunkSize'].setValue(this.entryToModify.chunk_size);

    // path and offset are not editable.
    controls['path'].disable();
    controls['offset'].disable();
  }

  private reset() {
    this.formGroup.controls['path'].setValue('');
  }

  public doClose() {
    this.close.emit();
    this.reset();
  }

  public submitForm() {
    if (this.entryToModify) {
      this.doModifyEntry();
    } else {
      this.doAddEntry();
    }
  }

  doAddEntry() {
    const values = this.formGroup.value;
    const offsetKB: number = values.offset;
    let sizeKB: number;
    if (values.sizeRadio === 'extendable') {
      sizeKB = 0;
    } else if (values.sizeRadio === 'fixedBySize') {
      sizeKB = values.fixedBySize;
    } else {
      sizeKB = values.fixedByEndOffset - offsetKB;
    }

    this.informixServerStorageService.addStoragePoolEntry(this.server.id, values.path,
      sizeKB, offsetKB, values.chunkSize, values.priority)
      .then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

  doModifyEntry() {
    const values = this.formGroup.value;
    let sizeKB: number;
    if (values.sizeRadio === 'extendable') {
      sizeKB = 0;
    } else if (values.sizeRadio === 'fixedBySize') {
      sizeKB = values.fixedBySize;
    } else {
      sizeKB = values.fixedByEndOffset - this.entryToModify.offset;
    }

    this.informixServerStorageService.modifyStoragePoolEntry(this.server.id, this.entryToModify.entry_id,
      sizeKB, values.chunkSize, values.priority)
      .then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

  validateFixedByEndOffset(control: AbstractControl) {
    if (!this.formGroup) {
      return null;
    }
    const startOffset: number = this.formGroup.controls['offset'].value;
    const endOffset: number = control.value;
    const availableSize: number = endOffset - startOffset;
    if (availableSize < this.STORAGE_POOL_ENTRY_SIZE_MINIMUM) {
      return { minAvailableSize: availableSize };
    }
    return null;
  }

  validateChunkSize(control: AbstractControl) {
    if (!this.formGroup) {
      return null;
    }
    if (this.formGroup.controls['sizeRadio'].value === 'extendable') {
      return null;
    }
    const chunkSize = control.value;
    let availableSize: number;
    if (this.formGroup.controls['sizeRadio'].value === 'fixedBySize') {
      availableSize = this.formGroup.controls['fixedBySize'].value;
    } else {
      availableSize = this.formGroup.controls['fixedByEndOffset'].value - this.formGroup.controls['offset'].value;
    }
    if (chunkSize > availableSize) {
      return { minChunkSize: chunkSize };
    }
    return null;
  }

}
