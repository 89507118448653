<div class="mainContainer" [hidden]="!showMainContainer">
  <div *ngIf="pendingNotifications.length > 0" class="alert alert-info unread">
    {{pendingNotifications.length}} more message(s)
  </div>

  <div *ngFor="let notif of notifications" class="alert p-0" [ngClass]="['alert-' + notif.type]" [@mainAnimation]="true"
    (@mainAnimation.done)="onMainAnimationDone($event)">
    <div class="d-flex">
      <div class="d-flex flex-grow-1">
        <div class="d-flex align-items-center p-2" [ngClass]="['text-' + notif.type]">
          <i class="icon icon-2x" [ngClass]="getIconClasses(notif)"></i>
        </div>
        <div class="d-flex align-items-center flex-grow-1 p-2">
          <div class="message-container" [innerHTML]="notif.messageSafe"></div>
        </div>
      </div>
      <div class="d-flex align-items-start">
        <button type="button" class="close pt-2 pr-2" (click)="removeNotification(notif)">
          <span><i class="icon icon-times"></i></span>
        </button>
      </div>
    </div>
  </div>
</div>
