/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterContentInit, ContentChild, Directive, Input, OnInit, TemplateRef, Component } from '@angular/core';

@Directive({
  selector: '[appTableCell]'
})
export class TableCellDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'app-data-table-column',
  template: ''
})
export class DataTableColumnComponent implements OnInit, AfterContentInit {

  @Input() title: string;
  @Input() property: string;
  @Input() sortProperty: string;
  @Input() sortable = false;
  @Input() searchable = false;
  @Input() class: string;
  @Input() width = 'auto';
  @Input() actionColumn = false;

  public cellTemplate: TemplateRef<any> = null;
  public headerClass: string;

  @ContentChild(TableCellDirective) tableCell: TableCellDirective;

  ngOnInit() {
    if (!this.class) {
      this.class = '';
    }

    this.headerClass = this.class + (this.sortable ? ' sortable' : '');
  }

  ngAfterContentInit() {
    if (this.tableCell) {
      this.cellTemplate = this.tableCell.templateRef;
    }
  }
}
