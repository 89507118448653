/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2021. All Rights Reserved.
 *******************************************************************************/
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../shared/user/user.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private userService: UserService) { }
  canDeactivate(component: CanComponentDeactivate) {
    // component.canDeactivate will work only when user is active
    return new Promise<boolean>((resolve, reject) => {
      this.userService.getCurrentUser().then(user => {
        if (user) {
          resolve(component.canDeactivate ? component.canDeactivate() : true);
        } else {
          resolve(true);
        }
      }).catch(err => {
        resolve(true);
      });
    });
  }
}
