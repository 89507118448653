/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { InformixServerGroup } from './informixServerGroup';
import { InformixServerGroupService } from './informixServerGroup.service';

@Component({
  selector: 'app-group-card',
  templateUrl: 'group-card.html',
  styleUrls: ['group.scss']
})
export class GroupCardComponent implements OnInit {

  @Input() group: InformixServerGroup;
  @Input() isSelected = false;

  isEditing = false;
  ignoreCardClick = false;

  groupNameControl: UntypedFormControl = null;
  isSaving = false;

  @Output() eventEdit = new EventEmitter<any>();
  @Output() eventDelete = new EventEmitter<any>();
  @Output() eventSelect = new EventEmitter<boolean>();
  @Output() eventEnter = new EventEmitter<any>();
  @Output() eventSaved = new EventEmitter<any>();
  @Output() eventDestroyed = new EventEmitter<boolean>();
  @Output() eventMove = new EventEmitter<any>();

  constructor(private groupService: InformixServerGroupService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService) {}

  private isNew() {
    return typeof this.group.id !== 'number';
  }

  ngOnInit() {
    if (this.isNew()) {
      this.onEdit();
    }
  }

  onEdit() {
    this.groupNameControl = new UntypedFormControl(this.group.name, [Validators.required]);
    this.isEditing = true;
    this.eventEdit.emit(null);
  }

  closeEdit() {
    this.isEditing = false;
    this.groupNameControl = null;
    if (this.isNew()) {
      this.eventDelete.emit();
    }
  }

  onDelete() {
    this.confirmationDialogService.show('delete the group ' + this.group.name + '?', () => {
      this.groupService.deleteGroup(this.group.id).then(() => {
        this.eventDelete.emit(null);
      }).catch(err => {
        this.notificationsService.pushErrorNotification(err);
        console.error('Cannot delete group', err);
      });
    });
  }

  onMouseDown(event: MouseEvent) {
    if (!this.isEditing && event.shiftKey) {
      event.preventDefault();
    }
  }

  onClick(event: MouseEvent) {
    if (this.ignoreCardClick) {
      this.ignoreCardClick = false;
      return;
    }

    if (!this.isEditing) {
      if (event.shiftKey || event.ctrlKey) {
        this.eventSelect.emit(event.shiftKey);
      } else {
        this.eventEnter.emit(null);
      }
    }
  }

  onSave() {
    this.ignoreCardClick = true;

    const groupName = this.groupNameControl.value;
    this.isSaving = true;

    let promise: Promise<any>;
    if (this.group.id === null) {
      promise = this.groupService.createGroup(this.group.parentGroupId, groupName);
    } else {
      promise = this.groupService.renameGroup(this.group.id, groupName);
    }

    promise.then(response => {
      if (typeof response.id === 'number') {
        this.group.id = response.id;
      }

      this.group.name = groupName;
      this.isEditing = false;
      this.eventSaved.emit(null);
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err);
      console.error('Unable to create/update group', err);
    }).then(() => {
      this.isSaving = false;
    });
  }

  onMove() {
    this.eventMove.emit(null);
  }
}
