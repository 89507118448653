/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { GROUP_ROOT_ID, InformixServerGroup } from './informixServerGroup';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class InformixServerGroupService {

  constructor(private httpClient: HttpClient) { }

  public getGroup(id: number, nested: number = null, includeChildGroups = true, includeChildServers = true): Promise<InformixServerGroup> {
    let url = 'informix/groups/' + id;
    let queryParams = '';
    if (typeof nested === 'number') {
      queryParams = 'nested=' + (nested === -1 ? 'all' : nested.toString());
    }
    if (!includeChildGroups) {
      if (queryParams.length > 0) {
        queryParams += '&';
      }
      queryParams += 'groups=0';
    }
    if (!includeChildServers) {
      if (queryParams.length > 0) {
        queryParams += '&';
      }
      queryParams += 'servers=0';
    }
    if (queryParams.length > 0) {
      url += '?' + queryParams;
    }

    return this.httpClient.get<InformixServerGroup>(url)
      .toPromise<InformixServerGroup>()
      .then((response) => new InformixServerGroup(response));
  }
  public getAgentsData(): Observable<any> {
    return this.httpClient.get<InformixServerGroup>('informix/groups/0?nested=all').pipe(
      map(response => new InformixServerGroup(response)));
  }
  public getRootGroup(): Promise<InformixServerGroup> {
    return this.getGroup(GROUP_ROOT_ID);
  }

  public createGroup(parentGroupId: number, groupName: string): Promise<any> {
    return this.httpClient.post('informix/groups/' + parentGroupId, { name: groupName }).toPromise<any>();
  }

  public renameGroup(groupId: number, name: string): Promise<any> {
    return this.httpClient.put('informix/groups/' + groupId, { name }).toPromise<any>();
  }

  public deleteGroup(groupId: number): Promise<any> {
    return this.httpClient.delete('informix/groups/' + groupId).toPromise<any>();
  }

  public moveGroups(groups: InformixServerGroup[], destGroupId: number): Promise<any> {
    const body = groups.map(group => ({ id: group.id, groupId: destGroupId }));
    return this.httpClient.put('informix/groups/', body).toPromise<any>();
  }
}
