/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Sensor } from './sensor';

@Component({
  selector: 'app-edit-sensor',
  templateUrl: 'editSensor.html'
})
export class EditSensorComponent implements OnInit {

  @Input() sensor: Sensor;
  @Input() isEditAll = false;
  @Output() save = new EventEmitter<any>();
  formGroup: UntypedFormGroup;

  ngOnInit() {
    if (this.isEditAll) {
      const sensorData = new Sensor({});
      sensorData.dataRetentionInterval = this.sensor.dataRetentionInterval;
      sensorData.runInterval = this.sensor.runInterval;
      this.sensor = sensorData;
    }
    this.formGroup = new UntypedFormGroup({
      runInterval: new UntypedFormControl(this.sensor.runInterval * 1000, [Validators.required]),
      dataRetentionInterval: new UntypedFormControl({
        value: (this.sensor.dataRetentionInterval <= 0) ? 30 : this.sensor.dataRetentionInterval,
        disabled: this.sensor.dataRetentionInterval <= 0
      }),
      dataRetentionIndefinite: new UntypedFormControl(this.sensor.dataRetentionInterval <= 0)
    });
    this.formGroup.controls.dataRetentionIndefinite.valueChanges.subscribe(value => {
      if (value) {
        this.formGroup.controls.dataRetentionInterval.disable();
      } else {
        this.formGroup.controls.dataRetentionInterval.enable();
      }
    });
    this.formGroup.valueChanges.subscribe(() => {
      this.saveEdit();
    });
  }

  saveEdit() {
    if (!this.formGroup.valid) {
      return;
    }
    const values = this.formGroup.value;
    this.sensor.runInterval = Math.floor(values.runInterval / 1000);
    if (values.dataRetentionIndefinite) {
      this.sensor.dataRetentionInterval = 0;
    } else {
      this.sensor.dataRetentionInterval = values.dataRetentionInterval;
    }
    this.save.emit(this.sensor);
  }
}
