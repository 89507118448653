<ul class="nav nav-tabs" *ngIf="tabComponents && tabComponents.length">
  <li role="button" class="nav-item" *ngFor="let tab of tabComponents; index as i">
    <div class="nav-link" style="cursor: pointer" [ngClass]="{active: selectedIndex === i}"
      (click)="selectTab(i)">
      {{tab.label}}
    </div>
  </li>
</ul>

<ng-template #tabViewContainer></ng-template>
