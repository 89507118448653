/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Observable, throwError } from 'rxjs';
import { AbstractQuery } from '../../abstract-query';
import { InformixSQLSession } from '../../informix-sql-session';
import { Query } from '../../query';
import { QueryResultPages } from '../../query-results';
import { SchemaService } from '../../schema.service';

export class ExplainQuery extends AbstractQuery {

  private queryObj: Query = null;
  private explainedQuery: string = null;

  constructor(restdb: SchemaService, session: InformixSQLSession, query: string) {
    super(restdb, session, query);
    const index = query.indexOf(' ');
    if (index > -1) {
      this.explainedQuery = query.substr(index).trim();
    }
    this.queryObj = new Query(restdb, session,
      'select bson_value_lvarchar(data, "explain") as explain from (select bson_explain(?) as data from sysmaster:sysdual)');
    this.queryObj.setParams([this.explainedQuery]);
  }

  public hasNext(): boolean {
    return this.queryObj.hasNext();
  }

  public next(): Observable<QueryResultPages> {
    if (!this.explainedQuery) {
      return throwError('Please provide a query to explain');
    }

    return this.queryObj.next();
  }

  public setParams(params: any[]): void {
    // Empty implementation
  }

  public setHasNext(hasMore: boolean, resultSetId: number): void {
    // Empty implementation
  }
}
