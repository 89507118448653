/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { BehaviorSubject, Observable, Subscriber, Subscription, of } from 'rxjs';
import { multicast, refCount } from 'rxjs/operators';

export class ObservablePoller<T> {
  private subscriber: Subscriber<T>;
  private observable: Observable<T>;
  private singleValueSubscription: Subscription;
  private pollTimeoutHandle: number = null;
  private subject: BehaviorSubject<T>;

  constructor(
    private pollTimeout: number,
    private singleValueGetter: () => Observable<T>,
    private teardown?: () => void) { }

  getObservable(): Observable<T> {
    if (!this.observable) {
      this.subject = new BehaviorSubject(null);
      this.observable = new Observable(subscriber => {
        this.subscriber = subscriber;
        this.getSingleValue();

        return () => {
          this.observable = null;
          this.subscriber = null;
          this.subject = null;
          if (this.singleValueSubscription) {
            this.singleValueSubscription.unsubscribe();
            this.singleValueSubscription = null;
          }
          window.clearTimeout(this.pollTimeoutHandle);
          if (this.teardown) {
            this.teardown();
          }
        };
      }).pipe(
        multicast(this.subject),
        refCount()
      );
    }
    return this.observable;
  }

  private getSingleValue(value$?: Observable<T>) {
    if (!value$) {
      value$ = this.singleValueGetter();
    }
    this.singleValueSubscription = value$.subscribe(value => {
      if (this.observable) {
        this.subscriber.next(value);
        this.pollTimeoutHandle = window.setTimeout(() => this.getSingleValue(), this.pollTimeout);
      }
    }, err => {
      this.subscriber.error(err);
    });
  }

  getPreviousValue(): T {
    if (this.subject) {
      return this.subject.getValue();
    }
  }

  injectValue(value: T | Observable<T>) {
    if (this.subject) {
      if (this.singleValueSubscription) {
        this.singleValueSubscription.unsubscribe();
      }
      window.clearTimeout(this.pollTimeoutHandle);
      if (!(value instanceof Observable)) {
        value = of(value);
      }
      this.getSingleValue(value);
    }
  }
}
