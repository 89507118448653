/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, Input } from '@angular/core';

export interface BreadcrumbElement {
  name: string;
  link?: string | Function;
}

@Component({
  selector: 'app-breadcrumb-component',
  template: `
  <ol class="breadcrumb">
    <li class="breadcrumb-item text-truncate" *ngFor="let elem of elements">
      <a [routerLink]="elem.link" *ngIf="isString(elem.link)" style="cursor: pointer">{{elem.name}}</a>
      <a href="javascript:;" *ngIf="isFunction(elem.link)" (click)="elem.link()" style="cursor: pointer">{{elem.name}}</a>
      <span *ngIf="!elem.link">{{elem.name}}</span>
    </li>
  </ol>
  `
})
export class BreadcrumbComponent {

  @Input() elements: BreadcrumbElement[];

  isFunction(obj: any) {
    return typeof obj === 'function';
  }

  isString(obj: any) {
    return typeof obj === 'string';
  }
}
