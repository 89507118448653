<div class="transparent-overlay" (click)="closeAgentView()"></div>
<div class="agents-list" *ngIf="user && filterAgentList">
    <div class="view-title border-gray-20">
        <h5>Agents Status</h5>
        <button type="button" class="close" (click)="closeAgentView()">
            <i class="icon icon-times"></i>
        </button>
        <button *ngIf="selectedAgentStatus" type="button" class="btn btn-danger shutdown-btn"
            (click)="confirmShutdown()">
            Shutdown {{selectedCount>1?'Agents':'Agent'}}
        </button>
    </div>
    <div>
        <div class="alert alert-info mt-2" *ngIf="!user.isSuperAdmin()">
            <i class="icon icon-info icon-info-circle text-info pr-1"></i> Users with admin privileges can only perform
            agent shutdown for corresponding group/server
        </div>
        <div class="alert alert-info mt-2" *ngIf="selectedCount > 0 && !shutdownErrorResponse">
            <i class="icon icon-info icon-info-circle text-info pr-1"></i> {{selectedCount}} {{selectedCount>1?'agents':'agent'}} selected.
        </div>
        <div class="alert alert-warning mt-2" *ngIf="shutdownErrorResponse">
            <i class="icon icon-warning text-warning pr-1"></i> {{shutdownErrorCount}} out of {{selectedRowsCount}} agents
            having trouble while shutting down.
        </div>
    </div>
    <div class="clearfix">
        <div class="viewfilter" *ngIf="!showSpinner">
            <label class="font-weight-bold">View</label>
            <select class="form-control" [(ngModel)]="selectedView" (ngModelChange)="changeView($event)">
                <option value="all"> All</option>
                <option value="Offline"> Offline</option>
                <option value="Online"> Online</option>
                <option value="Not configured"> Not configured</option>
            </select>
        </div>
        <div class="d-flex justify-content-center" *ngIf="showSpinner">
            <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
        </div>
        <app-data-table *ngIf="!showSpinner" #viewAgentsDataTable [data]="filterAgentList" [enableSelect]="true"
            [rowsPerPage]="10" [enablePagination]="filterAgentList.length > 10" isChecked="isChecked"
            (rowSelected)="onAgentSelected($event)" [minHeight]="465" noDataAlertMessage="No agents found.">
            <app-data-table-column [title]="'Group Name'" property="groupName" [sortable]="true" [searchable]="true">
                <ng-template appTableCell let-row="row">
                    <div class="one-line" [tooltip]="row.groupNameOverflow?row.groupName:''">
                        {{row.groupName}}</div>
                </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'Server name'" property="name" [sortable]="true" [searchable]="true">
                <ng-template appTableCell let-row="row">
                    <div class="one-line" [tooltip]="row.nameOverflow?row.name:''">
                        <a href="javascript:;" (click)="navigateToAgent(row.id)">{{row.name}}</a>
                    </div>
                </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'Agents Status'" property="online" [sortable]="true">
                <ng-template appTableCell let-row="row">
                    <span
                        [ngClass]="{'text-success': row.agent.online, 'text-danger' : !row.agent.online || !row.agent.isConfigured }"
                        [tooltip]="(row.agent.online && !row.agent.isConfigured)?'Agent is running but repository database not configured.':''">{{row.online}}</span>
                    <i class="icon icon-warning text-warning pl-1" [tooltip]="row.err" *ngIf="row.err"></i>
                </ng-template>
            </app-data-table-column>
        </app-data-table>
    </div>
</div>
<ng-template #shutdownAgentModal>
    <div class="modal-header">
        <h5 class="modal-title pull-left">Confirm</h5>
        <button type="button" [disabled]="loader" class="close" (click)="modalRef.hide()">
            <i class="icon icon-times"></i>
        </button>
    </div>
    <div class="modal-body p-3 pt-4 pb-4">
        <div class="pb-3">
            <i class="icon icon-warning text-warning pr-1"></i> Are you sure you want to shutdown the
            {{selectedCount>1?'Agents':'Agent'}}?
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="loader" class="btn btn-secondary btn-fixed-width mr-1"
            (click)="modalRef.hide()">
            No
        </button>
        <button type="button" class="btn btn-primary mr-1" [disabled]="loader" (click)="shutdownAgents()">
            Yes, Shutdown
        </button>
    </div>
</ng-template>
