/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { environment } from '../environments/environment';
export class AppSettings {

  public static PRODUCT_NAME = environment.productType;
  public static COPYRIGHT = '@ Copyright IBM Corp. 2015, 2017. @ Copyright HCL Technologies Ltd. 2017, 2024.';
  public static DEFAULT_LANGUAGE = 'en_US';

}
