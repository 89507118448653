<ng-template [ngIf]="serverWarning">
  <div class="mt-4 alert alert-warning">
    <i class="icon icon-warning text-warning"></i> {{serverWarning}}
  </div>
</ng-template>
<div class="row">
  <div class="col-md-6" *ngIf="title">
    <h4 class="page-header">
      <strong>{{title}}</strong>
    </h4>
  </div>
  <ng-container *ngIf="filteredConfiguration">
    <div class="col-md-6 justify-content-end form-inline">
        <button type="button" class="btn btn-secondary" (click)="showHelp = !showHelp">
          {{showHelp ? ('button.hideDescriptions' | translate) : ('button.showDescriptions' | translate)}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="toggleShowDynamicOnly()">
          {{showDynamicOnly ? ('button.showAll' | translate) : ('button.showDynamicOnly' | translate)}}
        </button>
        <div *ngIf="filterByOptions.length > 0" class="form-inline">
          <label class="font-weight-bold pl-2 pr-2">Filter By</label>
          <select class="form-control select-sm" [ngModel]="selectedFilterValue" (ngModelChange)="onFilterOptionChange($event)">
            <option *ngFor="let option of filterByOptions" [ngValue]="option.value">{{option.name}}</option>
          </select>
      </div>
      <button id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()">
        <i class="icon icon-refresh" title="Refresh"></i>
      </button>
    </div>
    <div *ngIf="showCategories" class="col-12 mt-2">
      <select class="form-control" [ngModel]="selectedCategory" (ngModelChange)="onCategoryChange($event)">
        <option *ngFor="let category of categories" [ngValue]="category">{{category.description}}</option>
      </select>
    </div>
  </ng-container>
</div>

<ng-template [ngIf]="dataLoadErrorMessage">
  <div class="mt-2 alert alert-danger">
    Unable to retrieve server configuration. <br> Error: {{dataLoadErrorMessage}}
  </div>
</ng-template>

<app-data-table class="table-hover" *ngIf="filteredConfiguration else loading" [hidden]="selectedOnconfig"
  [data]="filteredConfiguration" sortBy="name" [sortDescending]="false" [rowsPerPage]="20"
  searchLabel="Search for Parameter ( Use ' , ' OR ' + ' operator for multiple keyword search )." [searchValue]="searchValue">
  <app-data-table-column title="Parameter" property="name" [sortable]="true" [searchable]="true">
    <ng-template appTableCell let-name="item" let-onconfig="row">
      <span [ngClass]="{'font-weight-bold': showHelp}">{{name}}</span>
      <div class="mt-2" *ngIf="onconfig.info" [hidden]="!showHelp">
        {{onconfig.info.description}}
      </div>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column class="text-right" title="Value" property="effective" [sortable]="true">
    <ng-template appTableCell let-value="item" let-onconfig="row">
      <ng-container *ngIf="onconfig === editingParameter; else showValue">
        <form class="d-inline-block form-inline" style="white-space: nowrap"
          (ngSubmit)="saveParam(onconfig, onconfigInput.getValue())">
          <app-onconfig-input [onconfig]="onconfig" #onconfigInput></app-onconfig-input>
          <button type="submit" class="btn btn-sm btn-primary" [disabled]="!onconfigInput.isValid()">
            <i class="icon icon-check"></i>
          </button>
        </form>
      </ng-container>
      <ng-template #showValue>
        {{value}}
      </ng-template>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column class="w-1 text-right">
    <ng-template appTableCell let-onconfig="row">
      <div class="d-inline-block" [tooltip]="'tooltip.cannotModify' | translate" placement="left"
        *ngIf="onconfig.dynamic === 0; else editParam">
        <button type="button" class="btn btn-sm btn-secondary" disabled>
          <i class="icon icon-edit"></i>
        </button>
      </div>

      <ng-template #editParam>
        <ng-container *ngIf="onconfig === editingParameter; else editButton">
          <button type="button" class="btn btn-sm btn-danger" (click)="cancelEditingParam()">
            <i class="icon icon-times"></i>
          </button>
        </ng-container>
        <ng-template #editButton>
          <app-check-server-admin-permissions [server]="server" *ngIf="onconfig.dynamic === 1">
              <button type="button" class="btn btn-sm btn-primary" appPermissionDisable
                (click)="editOnconfigParam(onconfig)">
                <i class="icon icon-edit"></i>
              </button>
          </app-check-server-admin-permissions>
        </ng-template>
      </ng-template>
    </ng-template>
  </app-data-table-column>
</app-data-table>
<ng-template #loading>
  <div *ngIf="dataLoading" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-3"></i>
  </div>
</ng-template>
