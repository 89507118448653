<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-xl-3 col-5">
      <h4>
        <strong>Statement Executions</strong>
      </h4>
    </div>
    <div *ngIf="statementExecutions" class="col-12 col-xl-8 col-lg-10 pr-0">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
    <div *ngIf="statementExecutions" class="col form-group">
      <button id="refresh-button" type="button" class="btn btn-primary float-xl-right float-lg-right float-left"
        (click)="refreshData()" title="Refresh"><i class="icon icon-refresh"></i></button>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Data is not available. {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div *ngIf="sql" class="card card-body mt-2 mb-2">
    <div class="row">
      <div class="col-1">
        <h5><strong>SQL</strong></h5>
      </div>
      <div class="col-11">
        {{sql}}
      </div>
    </div>
  </div>

  <div class="table-responsive" *ngIf="statementExecutions">
    <app-data-table [data]="statementExecutions" [rowsPerPage]="20"
      noDataAlertMessage="No statement executions available." noDataAlertType="warning">
      <app-data-table-column title="SQL ID" property="sql_id" [sortable]="true">
        <ng-template appTableCell let-sqlid="item">
          <span class="mr-1"><a class="btn btn-sm btn-secondary"
              [routerLink]="['/dashboard/servers/', server.id, 'sqltrace', 'statements', sqlid, 'profile']">
              <i class="icon icon-search"></i>
            </a></span>
          {{sqlid}}
        </ng-template>
      </app-data-table-column>
      <app-data-table-column title="Session ID" property="sql_sid" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column title="User ID" property="sql_uid" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column title="Completion Time" property="sql_finishtime" [sortable]="true">
        <ng-template appTableCell let-time="item">
          {{time * 1000 | date:'y-MM-dd HH:mm:ss'}}
        </ng-template>
      </app-data-table-column>
      <app-data-table-column title="Run Time" property="sql_runtime" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column title="Rows/Second" property="sql_rowspersec" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column title="Rows Processed" property="sql_actualrows" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column title="Lock Wait Time" property="sql_lockwttime" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column title="IO Wait Time" property="sql_totaliowaits" [sortable]="true">
      </app-data-table-column>
    </app-data-table>
  </div>

</div>
