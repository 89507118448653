/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { InformixServer } from '../../dashboard/servers/informixServer';
import { CheckServerAdminPermissionsComponent } from '../permissions/check-server-admin-permissions.component';
import { HDRPermission } from './hdr-permission';

  @Component({
   selector: 'app-check-hdr-permissions',
   template: `
   <div style="display: inline-block" [tooltip]="tooltipHDRMsg" [placement]="placement" container="body">
     <ng-content></ng-content>
   </div>
   `
 })
 export class HDRPermissionComponent extends CheckServerAdminPermissionsComponent implements OnChanges {

  @Input() server: InformixServer;
  @Input() hdrPermission: HDRPermission;

  hasHDRPermission: boolean;
  tooltipHDRMsg: string;

  constructor() {
    super();
  }

  setHDRPermission() {
    this.hasHDRPermission = this.permissions && (this.hdrPermission && this.hdrPermission.isAllow());
    this.setToolTipText();
  }

  setToolTipText() {
    if (this.permissions && !this.hasHDRPermission) {
      this.tooltipHDRMsg = this.hdrPermission.getMessage();
      }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hdrPermission) {
      this.permissions = this.server.permissions.admin && this.server.hasAdminPassword;
      this.setHDRPermission();
    }
  }
 }
