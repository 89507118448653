/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-playground-notifications',
  template: `
  <section class="main-container" style="margin-left: 0">
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" name="notifType" [(ngModel)]="notificationType" value="info">
        Info
      </label>
    </div>
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" name="notifType" [(ngModel)]="notificationType" value="success">
        Success
      </label>
    </div>
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" name="notifType" [(ngModel)]="notificationType" value="warning">
        Warning
      </label>
    </div>
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" name="notifType" [(ngModel)]="notificationType" value="danger">
        Danger
      </label>
    </div>
    <div class="form-group">
      <label>Lifespan (ms)</label>
      <input class="form-control" type="number" style="width: 100px" [(ngModel)]="notificationLifespan">
      <span class="text-muted">(blank = default, 0 = infinite)</span>
    </div>
    <button class="btn btn-primary" (click)="createNotification()">Create Notification</button>
  </section>
  `
})
export class PlaygroundNotificationsComponent {

  notificationType = 'info';
  notificationLifespan: number = null;

  constructor(private notificationsService: NotificationsService) { }

  createNotification() {
    this.notificationsService.pushNotification({
      type: this.notificationType,
      message: '<strong>Hello!</strong> ' + (new Date()).toString(),
      lifespan: this.notificationLifespan
    });
  }
}
