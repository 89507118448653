/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { trigger, state, style, animate, transition } from '@angular/animations';

export const notificationsAnimations: any[] = [
  trigger('mainAnimation', [
    state('*', style({
      opacity: 1,
      transform: 'translate(0, 0)'
    })),
    transition('void => *', [
      style({
        opacity: 0,
        transform: 'translateY(-100%)'
      }),
      animate('0.2s ease-in')
    ]),
    transition('* => void', [
      animate('0.2s ease-out', style({
        opacity: 0,
        transform: 'translateX(100%)'
      }))
    ])
  ])
];
