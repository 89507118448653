<ng-container *ngIf="databases; else tableIndexInfoLoading;">
  <div class="row pb-2">
    <div class="col-12 col-lg-2 col-xl-2 mt-3">
      <label class="font-weight-bold">{{ 'storage.selectDbspace' | translate }}</label>
      <select class="form-control" [(ngModel)]="selectedDBspace.name" (ngModelChange)="changeDbspace($event)">
        <option *ngFor="let dbspace of dbspaces" [value]="dbspace.name">
          {{dbspace.name}}</option>
      </select>
    </div>
    <div class="col-12 col-lg-2 col-xl-2 mt-3">
      <label class="font-weight-bold">{{ 'database.selectDatabase' | translate }}</label>
      <select class="form-control" [(ngModel)]="selectedDB" (ngModelChange)="changeDatabase($event)">
        <option *ngFor="let db of databases" [value]="db.name"> {{db.name}}</option>
      </select>
    </div>
    <div class="col-12 col-lg-12 col-xl-2 pt-2 pt-lg-2 pt-xl-5">
      <app-checkbox [checked]="isCatalog" (change)="includeCatalog($event)">
        {{ 'tablesIndexes.systemCatalogLabel' | translate }}
      </app-checkbox>
    </div>
    <div class="col-12 col-lg-12 col-xl-6 pt-lg-2 pt-xl-4 justify-content-end form-inline pt-4 mt-2">
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button class="btn btn-sm btn-icon float-xl-right float-lg-right float-left"
          (click)="optimizeSpacesModal('all')" [tooltip]="'tablesIndexes.optimizeSpace' | translate"
          [disabled]="selectedCount < 2" appPermissionDisable>
          <i class="icon icon-compress"></i>
        </button>
      </app-check-server-admin-permissions>
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button class="btn btn-sm btn-icon float-xl-right float-lg-right float-left"
          [tooltip]="'tablesIndexes.updateInfo' | translate" (click)="refreshDataForSelectedRows()"
          [disabled]="selectedCount < 2" appPermissionDisable>
          <i class="icon icon-refresh"></i></button>
      </app-check-server-admin-permissions>
      <label class="pr-2 pl-1 font-weight-bold">{{ 'dataTable.filterLabel' | translate }}</label>
      <select class="form-control" [ngModel]="tableFilter" (ngModelChange)="changeTableFilter($event)">
        <option ngValue="all">{{ 'common.all' | translate }}</option>
        <option ngValue="tables">{{ 'table.tables' | translate }}</option>
        <option ngValue="indexes">{{ 'table.indexes' | translate }}</option>
        <option ngValue="compressed">{{ 'tablesIndexes.dropdownValues.compressed' | translate }}</option>
        <option ngValue="noncompressed">{{ 'tablesIndexes.dropdownValues.nonCompressed' | translate }}</option>
      </select>
    </div>
  </div>

  <app-data-table *ngIf="filteredTablesIndexesList; else TableIndexLoading" [data]="filteredTablesIndexesList"
    [searchLabel]="'dataTable.searchLabel' | translate" [enableSelect]="true"
    [noDataAlertMessage]="'tablesIndexes.noTablesOrIndexes' | translate" (rowSelected)="onTableSelected($event)"
    [enableExpand]="rowExpandables" [dataMapFn]="tablesIndexesDataMap" #tablesAndIndexesTable>
    <app-data-table-expand>
      <ng-template appTableExpand let-data="row" let-dataTableRow="dataTableRow">
        <div class="pl-3 ml-1">
          <app-data-table #fragmentDataTable *ngIf="data.fragments" [data]="data.fragments" [enableSelect]="true"
            [minHeight]="200" [enablePagination]="false" isChecked="isChecked"
            (updateSelectedRows)="onFragmentsSelected($event, dataTableRow)" (rowSelected)="onFragmentSelected($event)">
            <app-data-table-column [title]="'table.partNum' | translate" property="partnum">
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.partName' | translate" property="dbspace"
              [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.rows' | translate" property="nrows"
              [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.extents' | translate" property="nextents"
              [sortable]="true" [searchable]="true">
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.compressed' | translate">
              <ng-template appTableCell let-data="row">
                <div class="pl-3">
                  <span [tooltip]="'tablesIndexes.iconsTooltip.autoCompress' | translate"
                    *ngIf="data.auto_compressed === 'yes'">
                    <i class="icon icon-clock color-green-30"></i></span>
                  <span [tooltip]="'tablesIndexes.iconsTooltip.compress' | translate" *ngIf="data.compressed === 'yes'">
                    <i class="icon icon-check color-green-30 font-weight-bold"></i></span>
                  <span [tooltip]="'tablesIndexes.iconsTooltip.uncompress' | translate"
                    *ngIf="data.uncompressed === 'yes'">
                    <i class="icon icon-times color-red font-weight-bold"></i></span>
                </div>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.usedSize' | translate" property="used_size"
              [sortable]="true" [searchable]="true">
              <ng-template appTableCell let-item="item">
                {{item | byte}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.spaceUsage' | translate">
              <ng-template appTableCell let-row="row">
                <div class="progress" data-toggle="popover" [popover]="spaceUsage"
                  containerClass="tab-index-popover popover-arrow-hidden popover-color" placement="auto" triggers="hover">
                  <div class="progress-bar backcolor-used-space" [ngStyle]="row.estimate ? {'width.%': (row.npused - ((row.npused * row.estimate.change) / 100)) * 100 / row.nptotal} :
                        {'width.%': row.npused * 100 / row.nptotal}">
                  </div>
                  <div class="progress-bar backcolor-savings" [ngStyle]="row.estimate ? {'width.%': (row.npused * row.estimate.change) / row.nptotal} :
                      {'width.%': 0}">
                  </div>
                  <div class="progress-bar backcolor-free-space"
                    [ngStyle]="{'width.%': (row.nptotal - row.npused) * 100 / row.nptotal}">
                  </div>
                </div>
                <ng-template #spaceUsage>
                  <div class="popover-body btn-group-toggle" data-toggle="buttons">
                    <i class="icon icon-circle color-used-space"></i>
                    <label *ngIf="row.estimate">/</label>
                    <i *ngIf="row.estimate" class="icon icon-circle color-savings"></i>
                    <label class="pl-2">
                      {{ 'tablesIndexes.spaceUsagePopover.usedSpaceLabel' | translate }}:
                      {{ row.npused | number:'1.0-0' }}
                      {{ 'tablesIndexes.spaceUsagePopover.pages' | translate }}
                    </label><br>
                    <ng-template [ngIf]="row.estimate">
                      <i class="icon icon-circle color-savings"></i>
                      <label class="pl-2">
                        {{ 'tablesIndexes.spaceUsagePopover.savingsLabel' | translate }}:
                        {{((row.npused * row.estimate.change) / 100) | number:'1.0-0'}}
                        {{ 'tablesIndexes.spaceUsagePopover.pages' | translate }}
                      </label><br>
                    </ng-template>
                    <i class="icon icon-circle color-free-space"></i>
                    <label class="pl-2">
                      {{ 'tablesIndexes.spaceUsagePopover.freeSpaceLabel' | translate }}:
                      {{row.nptotal - row.npused}}
                      {{ 'tablesIndexes.spaceUsagePopover.pages' | translate }}
                    </label>
                  </div>
                </ng-template>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column class="text-right">
              <ng-template appTableCell let-fragmentRow="row">
                <app-check-server-admin-permissions [server]="server" placement="top">
                  <button class="btn btn-sm btn-icon" [tooltip]="'tablesIndexes.optimizeSpace' | translate"
                    (click)="optimizeSpacesModal('one', fragmentRow)" appPermissionDisable>
                    <i class="icon icon-compress"></i>
                  </button>
                </app-check-server-admin-permissions>
                <app-check-server-admin-permissions [server]="server" placement="top">
                  <button class="btn btn-sm btn-icon" [tooltip]="'tablesIndexes.updateInfo' | translate"
                    (click)="refreshDataForRow(fragmentRow)" appPermissionDisable>
                    <i class="icon icon-refresh"></i>
                  </button>
                </app-check-server-admin-permissions>
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
      </ng-template>
    </app-data-table-expand>
    <app-data-table-column [title]="'dataTable.columnTitle.name' | translate" property="tabname" [sortable]="true"
      [searchable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.dbSpaceName' | translate"
      property="dbSpaceName" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.database' | translate"
      property="databaseName" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'dataTable.columnTitle.type' | translate" property="type" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-row="row">
        {{((row.type === 'I')? 'table.index' : 'table.table') | translate}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.rows' | translate" property="nrows"
      [sortable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.extents' | translate" property="extents"
      [sortable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.spaceUsage' | translate">
      <ng-template appTableCell let-row="row">
        <div class="progress" data-toggle="popover" [popover]="spaceUsage"
          containerClass="tab-index-popover popover-arrow-hidden popover-color" placement="auto" triggers="hover">
          <div class="progress-bar backcolor-used-space" [ngStyle]="(row.estimate) ?
            {'width.%': (row.npused - ((row.npused * row.estimate.change) / 100)) * 100 / row.nptotal} :
            {'width.%': row.npused * 100 / row.nptotal}">
          </div>
          <div class="progress-bar backcolor-savings" [ngStyle]="(row.estimate) ?
            {'width.%': (row.npused * row.estimate.change) / row.nptotal} : {'width.%': 0}">
          </div>
          <div class="progress-bar backcolor-free-space"
            [ngStyle]="{'width.%': (row.nptotal - row.npused) * 100 / row.nptotal}">
          </div>
        </div>
        <ng-template #spaceUsage>
          <div class="popover-body btn-group-toggle" data-toggle="buttons">
            <i class="icon icon-circle color-used-space"></i>
            <label *ngIf="row.estimate">/</label>
            <i *ngIf="row.estimate" class="icon icon-circle color-savings"></i>
            <label class="pl-2">
              {{ 'tablesIndexes.spaceUsagePopover.usedSpaceLabel' | translate }}:
              {{ row.npused | number:'1.0-0' }}
              {{ 'tablesIndexes.spaceUsagePopover.pages' | translate }}
            </label><br>
            <ng-template [ngIf]="row.estimate">
              <i class="icon icon-circle color-savings"></i>
              <label class="pl-2">
                {{ 'tablesIndexes.spaceUsagePopover.savingsLabel' | translate }}:
                {{((row.npused * row.estimate.change) / 100) | number:'1.0-0'}}
                {{ 'tablesIndexes.spaceUsagePopover.pages' | translate }}
              </label><br>
            </ng-template>
            <i class="icon icon-circle color-free-space"></i>
            <label class="pl-2">
              {{ 'tablesIndexes.spaceUsagePopover.freeSpaceLabel' | translate }}:
              {{row.nptotal - row.npused}}
              {{ 'tablesIndexes.spaceUsagePopover.pages' | translate }}
            </label>
          </div>
        </ng-template>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.compressed' | translate">
      <ng-template appTableCell let-data="row">
        <div class="pl-3">
          <ng-container *ngIf="data.running === true; else otherStatus">
            <span [tooltip]="'tablesIndexes.iconsTooltip.running' | translate">
              <i class="icon icon-circle-notch"></i>
            </span>
          </ng-container>
          <ng-template #otherStatus>
            <span [tooltip]="data.auto_compressed === 'some' ? ('tablesIndexes.iconsTooltip.autoCompressSome' | translate) :
          ('tablesIndexes.iconsTooltip.autoCompress' | translate)"
              *ngIf="data.auto_compressed === 'yes' || data.auto_compressed === 'some'">
              <i class="icon icon-clock color-green-30"></i></span>
            <span [tooltip]="data.compressed === 'some' ? ('tablesIndexes.iconsTooltip.compressSome' | translate) :
          ('tablesIndexes.iconsTooltip.compress' | translate)"
              *ngIf="data.compressed === 'yes' || data.compressed === 'some'">
              <i class="icon icon-check color-green-30 font-weight-bold"></i></span>
            <span
              [tooltip]="(data.uncompressed === 'some' || (data.uncompressed === null && data.compressed === 'some'))
           ? ('tablesIndexes.iconsTooltip.uncompressSome' | translate) : ('tablesIndexes.iconsTooltip.uncompress' | translate)"
              *ngIf="data.uncompressed === 'yes' || data.uncompressed === 'some'">
              <i class="icon icon-times color-red font-weight-bold"></i></span>
          </ng-template>
        </div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.usedSize' | translate" property="used_size"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{item | byte}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.pageUsage' | translate">
      <ng-template appTableCell let-row="row">
        <div class="progress" data-toggle="popover" [popover]="pageUsage"
          containerClass="tab-index-popover popover-arrow-hidden popover-color" placement="auto" triggers="hover">
          <div class="progress-bar backcolor-full-pages"
            [ngStyle]="{'width.%': (row.very_full * 100) / (row.very_full +  row.mostly_used + row.partly_used + row.free)}">
          </div>
          <div class="progress-bar backcolor-most-used-pages"
            [ngStyle]="{'width.%': ( row.mostly_used * 100) / ( row.very_full +  row.mostly_used + row.partly_used + row.free)}">
          </div>
          <div class="progress-bar backcolor-partly-used-pages"
            [ngStyle]="{'width.%': ( row.partly_used * 100) / ( row.very_full +  row.mostly_used + row.partly_used + row.free)}">
          </div>
          <div class="progress-bar backcolor-unused-pages"
            [ngStyle]="{'width.%': ( row.free * 100) / ( row.very_full +  row.mostly_used + row.partly_used + row.free)}">
          </div>
        </div>
        <ng-template #pageUsage>
          <div *ngIf="row.very_full != null; else pageUsageNoData" class="popover-body btn-group-toggle"
            data-toggle="buttons">
            <i class="icon icon-circle color-full-pages pr-2"></i>
            <label> {{ 'tablesIndexes.pageUsagePopover.fullPagesLabel' | translate }}: {{row.very_full}}
            </label><br>
            <i class="icon icon-circle color-most-used-pages pr-2"></i>
            <label> {{ 'tablesIndexes.pageUsagePopover.mostUsedPagesLabel' | translate }}:
              {{row.mostly_used}} </label><br>
            <i class="icon icon-circle color-partly-used-pages pr-2"></i>
            <label> {{ 'tablesIndexes.pageUsagePopover.partiallyUsedPagesLabel' | translate }}:
              {{row.partly_used}} </label><br>
            <i class="icon icon-circle color-unused-pages pr-2"></i>
            <label> {{ 'tablesIndexes.pageUsagePopover.unusedPagesLabel' | translate }}: {{row.free}}
            </label>
          </div>
          <ng-template #pageUsageNoData>
            <label class="p-1"> {{ 'dataTable.noDataAvailable' | translate }}</label>
          </ng-template>
        </ng-template>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-row="row">
        <app-check-server-admin-permissions [server]="server" placement="top">
          <button class="btn btn-sm btn-icon" [disabled]="isVNextVersion" [tooltip]="'tablesIndexes.btnLabel.iconOptimizeTitle' | translate"
            (click)="optimizeSpacesModal('one', row)" appPermissionDisable>
            <i class="icon icon-compress"></i>
          </button>
        </app-check-server-admin-permissions>
        <app-check-server-admin-permissions [server]="server" placement="top">
          <button class="btn btn-sm btn-icon" [disabled]="isVNextVersion" [tooltip]="'tablesIndexes.btnLabel.iconUpdateTitle' | translate"
            (click)="refreshDataForRow(row)" appPermissionDisable>
            <i class="icon icon-refresh"></i>
          </button>
        </app-check-server-admin-permissions>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
  <ng-template #TableIndexLoading>
    <div class="d-flex justify-content-center" *ngIf="showSpinner">
      <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>
  </ng-template>
</ng-container>

<ng-template #tableIndexInfoLoading>
  <div class="d-flex justify-content-center" *ngIf="isLoading else errorMessage;">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-4"></i>
  </div>
  <ng-template #errorMessage>
    <div class="alert alert-danger mt-2 mb-0 w-100" *ngIf="apiError">
      <i class="icon icon-danger icon-ganger-circle text-ganger"></i> Unable to retrieve table & indexes information. <br>
      Error: {{apiError}} 
    </div>
  </ng-template>
</ng-template>

<app-optimize-space-modal #optimizeSpaceModal (actionStart)="optimizeStart" (actionComplete)="refreshData($event)"></app-optimize-space-modal>
