/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { getTimeSlice, getTimeSlices, TimeSlice } from './timeSlices';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { UserService } from '../../shared/user/user.service';
import { User } from '../../shared/user/user';

@Component({
  selector: 'app-time-slice',
  templateUrl: 'time-slice.html'
})
export class TimeSliceComponent implements OnInit {

  @Input() disabled = false;
  @Input() showPlayPause = true;

  @Input()
  set timeSlice(slice: TimeSlice) {
    if (slice) {
      this.changeSelectedTimeSlice(slice.name);
    }
  }

  @Input()
  get isPaused(): boolean {
    return this._isPaused;
  }

  set isPaused(value: boolean) {
    if (value) {
      this.pauseData();
    } else {
      this.resumeData();
    }
  }

  constructor(private notifications: NotificationsService, private userService: UserService) { }

  @Output() timeSliceChanged = new EventEmitter<TimeSlice>();
  @Output() dataPaused = new EventEmitter<any>();
  @Output() dataResumed = new EventEmitter<any>();

  timeSlices = getTimeSlices();
  selectedTimeSlice = '';
  _isPaused = false;

  ngOnInit() {
    this.userService.getCurrentUser().then((user: User) => {
      this.selectedTimeSlice = user.settings.timeSliceName;
    }).catch(err => {
      this.notifications.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
    });
  }

  changeSelectedTimeSlice(timeSliceName: string) {
    this.selectedTimeSlice = timeSliceName;
    this.timeSliceChanged.emit(getTimeSlice(timeSliceName));
  }

  pauseData() {
    if (!this._isPaused) {
      this._isPaused = true;
      this.dataPaused.emit(null);
    }
  }

  resumeData() {
    if (this._isPaused) {
      this._isPaused = false;
      this.dataResumed.emit(null);
    }
  }
}
