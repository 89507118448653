/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { SystemConfigService } from '../system-config.service';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-system-alerts',
  templateUrl: 'systemAlerts.html'
})
export class SystemAlertsComponent implements OnInit {

  configs: any = null;

  constructor(private configService: SystemConfigService,
    private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.configService.getConfigs().then(configs => {
      this.configs = configs;
    }).catch(err => {
      console.error('Unable to get system config', err);
    });
  }

  saveConfig(type: string, config: any) {
    this.configService.saveConfig(type, config).then(() => {
      this.configs[type] = config;
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
      console.error('Unable to save system config', err);
    });
  }

  testConfig(type: string, config: any) {
    this.configService.testAlertConfig(type, config).then(() => {
      this.notificationsService.pushSuccessNotification('Test alert sent');
    }).catch(err => {
      console.error('Test alert failed', err);
      this.notificationsService.pushErrorNotification(err);
    });
  }
}
