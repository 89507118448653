/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019, 2023. All Rights Reserved.
 *******************************************************************************/

import { Component } from '@angular/core';

import { SidebarElement } from '../../shared/sidebar/sidebar';

@Component({
  selector: 'app-system-settings-component',
  templateUrl: './system-settings.html'
})
export class SystemSettingsComponent {

  sidebar: SidebarElement[] = [
    {
      label: 'Alerting Configuration',
      link: 'alerts',
      workOffline: true
    },
    {
      label: 'Data Cleanup Configuration',
      link: 'data-cleanup',
      workOffline: true
    },
    {
      label: 'Report Management',
      link: 'reports',
      workOffline: true
    },
    {
      label: 'Sensor Management',
      link: 'sensors',
      workOffline: true
    },
    {
      label: 'Server Configuration',
      link: 'server',
      workOffline: true
    },
    {
      label: 'User Management',
      link: 'users',
      workOffline: true
    }
  ];
}
