/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, share, switchMap } from 'rxjs/operators';
import { InformixSensorService } from '../../../informixSensor.service';
import { MonitoringService } from '../../../monitoring.service';
import { MonitoringProfile } from '../../../monitoringProfile';
import { SensorMetric, SensorType } from '../../../sensor';
import { AbstractDashboardPluginConfig } from '../../abstract-dashboard-plugin-config';
import { DashboardService } from '../../dashboard.service';
import { DataSeriesConfig, SensorChartjsPluginConfig } from '../sensor-chartjs-plugin-config';
import { SensorChartjsService } from '../sensor-chartjs.service';
import { TranslateService } from '@ngx-translate/core';
import { SchemaService } from '../../../../servers/schema/schema.service';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';

@Component({
  selector: 'app-sensor-chartjs-plugin-config',
  templateUrl: './sensor-chartjs-plugin-config.component.html',
  styleUrls: ['./sensor-chartjs-plugin-config.component.scss']
})
export class SensorChartjsPluginConfigComponent extends AbstractDashboardPluginConfig implements OnInit {

  private originalConfig: SensorChartjsPluginConfig = null;
  private config: SensorChartjsPluginConfig = null;

  isLoading = true;
  monitoringProfiles: MonitoringProfile[] = null;
  sensorTypes: SensorType[] = null;
  chartType = 'line';
  dataSourcesFormGroup: UntypedFormGroup;
  dataSeriesFormGroup: UntypedFormGroup;
  selectedSensorTypes: string[] = [];
  isRunClicked = false;

  databases: string[] = null;
  databasesLoadError: string = null;

  private primaryKeysMap = new Map<string, Observable<any>>();

  constructor(
    public dashboardService: DashboardService,
    private pluginService: SensorChartjsService,
    private monitoringService: MonitoringService,
    private sensorService: InformixSensorService,
    private translate: TranslateService,
    private schemaService: SchemaService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();

    this.dataSourcesFormGroup = new UntypedFormGroup({
      server: new UntypedFormControl(null),
      dataSources: new UntypedFormArray([]),
      databaseName: new UntypedFormControl('sysmaster'),
      queryString: new UntypedFormControl('')
    });

    this.dataSourcesFormGroup.valueChanges.pipe(
      debounceTime(10),
      switchMap(() => this.getDataSeriesConfigs())
    ).subscribe(seriesConfigs => {
      this.initDataSeriesForms(seriesConfigs);
      if (!this.config.isCustomQuery) {
        this.emitConfigChanged();
      }
    });
  }

  ngOnInit() {
    this.pluginService.getMonitoringProfiles().then(profiles => {
      if (profiles) {
        this.isLoading = false;
        this.monitoringProfiles = profiles;
        const sensorTypeMap = new Map<string, SensorType>();
        profiles.forEach(profile => {
          profile.sensors.forEach(sensor => sensorTypeMap.set(sensor.type.id, sensor.type));
        });

        this.sensorTypes = Array.from(sensorTypeMap.values());
        this.sensorTypes.sort((a, b) => a.name.localeCompare(b.name));
        this.sensorTypes.forEach(type => type.metrics.sort((a, b) => a.name.localeCompare(b.name)));

        this.initForms();
      }
    });
  }

  emitConfigChanged(): any {
    if (this.config.isCustomQuery) {
      const customQueryConfig: any = {};
      customQueryConfig.yAxes = this.config.yAxes;
      customQueryConfig.chartType = this.chartType;
      if (this.dataSourcesFormGroup.value.server !== null) {
        customQueryConfig.serverIndex = this.dataSourcesFormGroup.value.server;
      }
      this.config.dataSeries = this.dataSeriesFormGroup.value.dataSeries;
      customQueryConfig.dataSeries = this.config.dataSeries;
      customQueryConfig.databaseName = this.dataSourcesFormGroup.value.databaseName;
      customQueryConfig.queryString = this.dataSourcesFormGroup.value.queryString.replace(/\n+|(\r\n)+/g, ' ').trim();
      customQueryConfig.isCustomQuery = this.config.isCustomQuery;
      this.configChanged.next(customQueryConfig);
      return;
    }
    if (!this.dataSeriesFormGroup) {
      return;
    }

    const newConfig: any = {
      dataSources: [],
      dataSeries: this.dataSeriesFormGroup.value.dataSeries
    };

    const configuredSeriesIds = new Set<string>(newConfig.dataSeries.map(series => series.id));

    const dataSourceControls = this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray;
    dataSourceControls.controls.filter(c => c.valid).forEach(c => {
      const sensorType = c.value.sensorType as SensorType;

      this.originalConfig.getSeriesConfigs(sensorType).forEach(series => {
        if (!configuredSeriesIds.has(series.id)) {
          newConfig.dataSeries.push(series);
        }
      });

      const config: any = {
        sensorType: sensorType.id
      };

      if (c.value.metric) {
        config.metric = c.value.metric.id;
      }

      if (c.value.sensorType.hasPrimaryKey() && c.value.primaryKey) {
        config.primaryKey = c.value.primaryKey;
      }

      newConfig.dataSources.push(config);
    });
    this.selectedSensorTypes = Array.from(new Set(newConfig.dataSources.map(dataSource => dataSource.sensorType)));
    newConfig.yAxes = this.config.yAxes;
    newConfig.chartType = this.chartType;
    newConfig.isCustomQuery = false;
    if (this.dataSourcesFormGroup.value.server !== null) {
      newConfig.serverIndex = this.dataSourcesFormGroup.value.server;
    }

    this.configChanged.next(newConfig);
  }

  setConfig(config: any) {
    this.originalConfig = new SensorChartjsPluginConfig(config);
    this.config = new SensorChartjsPluginConfig(config);
  }

  getSeries() {
    if (!this.config.isCustomQuery) {
      return;
    }
    this.getDataSeriesConfigs().subscribe(configs => {
      this.initDataSeriesForms(configs);
    });
  }

  newDataSource(source: UntypedFormGroup = null) {
    this.config.isCustomQuery = false;
    this.emitConfigChanged();
    const formGroup = new UntypedFormGroup({
      sensorType: new UntypedFormControl(null, Validators.required),
      metric: new UntypedFormControl(null),
      primaryKey: new UntypedFormControl(null)
    }, this.dataSourceValidator);

    formGroup.controls.sensorType.valueChanges.subscribe(() => {
      formGroup.patchValue({
        metric: null,
        primaryKey: null
      });
    });

    formGroup.controls.metric.valueChanges.subscribe(value => {
      if (value === null && formGroup.value.primaryKey) {
        formGroup.controls.primaryKey.setValue(null);
      }
    });

    if (source) {
      formGroup.patchValue(source.value);
    }

    (this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray).push(formGroup);

    return formGroup;
  }

  private dataSourceValidator(c: AbstractControl) {
    if (c.value.sensorType && c.value.sensorType.hasPrimaryKey() && !c.value.metric && !c.value.primaryKey) {
      return { primaryKey: true };
    }
    return null;
  }

  removeDataSource(index: number) {
    (this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray).removeAt(index);
    if ((this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray).length < 1) {
      this.config.isCustomQuery = true;
      this.removeAllDataSources();
    }
  }

  removeAllDataSources() {
    while ((this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray).length !== 0) {
      (this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray).removeAt(0);
    }
    this.config.databaseName = '';
    this.config.queryString = '';
    this.dataSourcesFormGroup.controls.databaseName.setValue('');
    this.dataSourcesFormGroup.controls.queryString.setValue('');
    this.config.isCustomQuery = true;
    this.emitConfigChanged();
    this.loadDatabases();
  }

  removeDataSourcesConfirmation() {
    if ((this.dataSourcesFormGroup.controls.dataSources.value[0].sensorType)) {
      this.confirmationDialogService.show('discard changes?', () => {
        this.removeAllDataSources();
      });
    } else {
      this.removeAllDataSources();
    }
  }

  discardChangesConfirmation() {
    if (this.isRunClicked) {
      this.isRunClicked = false;
      this.confirmationDialogService.show('discard changes?', () => {
        this.newDataSource();
      });
    } else {
      this.newDataSource();
    }
  }

  getPrimaryKeys(sensorType: SensorType, requiredKeys?: any[]): Observable<any[]> {
    let observable = this.primaryKeysMap.get(sensorType.id);
    if (!observable) {
      observable = new Observable(observer => {
        const promises: Promise<any>[] = [];
        this.dashboardService.getServers().forEach((server, i) => {
          const sensor = this.monitoringProfiles[i].getSensor(sensorType.id);
          if (sensor) {
            const sensorDataSource = this.sensorService.getSensorDataSource(server, sensor);
            promises.push(sensorDataSource.getMetaData().then(meta => meta.primaryKeys));
          }
        });

        Promise.all(promises).then(primaryKeyLists => {
          const primaryKeySet = new Set<any>();
          primaryKeyLists.forEach(primaryKeyList => primaryKeyList.forEach(primaryKey => primaryKeySet.add(primaryKey)));

          if (requiredKeys) {
            requiredKeys.forEach(primaryKey => primaryKeySet.add(primaryKey));
          }

          const primaryKeys = Array.from(primaryKeySet.values()).sort();
          this.primaryKeysMap.set(sensorType.id, of(primaryKeys));
          observer.next(primaryKeys);
        }, err => {
          console.error(err);
        });
      }).pipe(share());

      this.primaryKeysMap.set(sensorType.id, observable);
    }

    return observable;
  }

  private initForms() {
    if (this.config.serverIndex !== null) {
      this.dataSourcesFormGroup.controls.server.setValue(this.config.serverIndex, { emitEvent: false });
    }

    if (this.config && this.config.isCustomQuery) {
      this.loadDatabases();
      this.dataSourcesFormGroup.controls.databaseName.setValue(this.config.databaseName);
      this.dataSourcesFormGroup.controls.queryString.setValue(this.config.queryString);
      this.chartType = this.config.chartType;
      return;
    }

    if (!this.config || !this.config.dataSources || !this.config.dataSources.length) {
      this.newDataSource();
      return;
    }

    this.isLoading = true;
    let sensorTypesPromise = Promise.resolve(this.sensorTypes);
    this.chartType = this.config.chartType;
    // If we find a data source that references an unknown sensor type...
    if (this.config.dataSources.find(dataSource => !this.sensorTypes.find(type => type.id === dataSource.sensorType))) {
      sensorTypesPromise = this.monitoringService.getSensorTypes().then(sensorTypes => {
        this.config.dataSources.forEach(dataSource => {
          if (!this.sensorTypes.find(type => type.id === dataSource.sensorType)) {
            const sensorType = sensorTypes.find(type => type.id === dataSource.sensorType);
            if (sensorType) {
              this.sensorTypes.push(sensorType);
            }
          }
        });

        return this.sensorTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
    }

    sensorTypesPromise.then(sensorTypes => {
      const requiredKeysMap = new Map<SensorType, any[]>();

      this.config.dataSources.forEach(dataSource => {
        const sensorType = sensorTypes.find(type => type.id === dataSource.sensorType);
        if (!sensorType) {
          return;
        }

        const metric = dataSource.metric ? sensorType.getMetric(dataSource.metric) : null;
        if (dataSource.metric && !metric) {
          return;
        }

        if (sensorType.hasPrimaryKey() && dataSource.primaryKey) {
          let requiredKeys = requiredKeysMap.get(sensorType);
          if (!requiredKeys) {
            requiredKeys = [];
            requiredKeysMap.set(sensorType, requiredKeys);
          }
          requiredKeys.push(dataSource.primaryKey);
        }

        this.newDataSource().patchValue({
          sensorType,
          metric,
          primaryKey: dataSource.primaryKey || null
        });
      });

      const observables: Observable<any[]>[] = [];
      requiredKeysMap.forEach((primaryKeys, sensorType) => {
        observables.push(this.getPrimaryKeys(sensorType, primaryKeys));
      });

      (observables.length ? combineLatest(observables) : of(null)).subscribe(() => {
        this.isLoading = false;
      }, err => {
        console.error(err);
      });
    }, err => {
      console.error(err);
    });
  }

  private getDataSeriesConfigs(): Observable<DataSeriesConfig[][]> {
    if (this.config.isCustomQuery) {
      let seriesConfigs: DataSeriesConfig[] = [];
      const isSame = JSON.stringify(this.config.dataSeries.map((series) => series.id).sort()) ===
        JSON.stringify(this.dashboardService.getCustomQueryColumns().map(value => value).sort());
      if (this.config.dataSeries.length > 0 && isSame) {
        seriesConfigs = this.config.dataSeries;
      } else {
        this.dashboardService.getCustomQueryColumns().forEach(col => seriesConfigs.push(this.config.getSeriesConfig(col)));
      }
      return of([seriesConfigs]);
    }
    const selectedServer = this.dataSourcesFormGroup.value.server as number;
    let servers = this.dashboardService.getServers();
    if (selectedServer !== null) {
      servers = [servers[selectedServer]];
    }

    const isMultiserver = servers.length > 1;
    const dataSourceControls = this.dataSourcesFormGroup.controls.dataSources as UntypedFormArray;
    const seriesConfigs$: Observable<DataSeriesConfig[]>[] = dataSourceControls.controls.filter(c => c.valid).map(c => {
      const sensorType = c.value.sensorType as SensorType;
      const sensorMetric = c.value.metric as SensorMetric;
      if (sensorType.hasPrimaryKey() && !c.value.primaryKey) {
        return this.getPrimaryKeys(sensorType).pipe(map(primaryKeys => {
          const seriesConfigs: DataSeriesConfig[] = [];
          servers.forEach((server, i) => {
            primaryKeys.forEach(primaryKey => {
              seriesConfigs.push(this.config.getSeriesConfig(sensorType, sensorMetric, primaryKey, isMultiserver ? i : null));
              seriesConfigs.map(series => series.label = series.getLabel(server));
            });
          });
          return seriesConfigs;
        }));
      } else {
        const seriesConfigs: DataSeriesConfig[] = [];
        const sensorMetrics = sensorMetric ? [sensorMetric] : sensorType.metrics;
        servers.forEach((server, i) => {
          sensorMetrics.forEach(metric => {
            seriesConfigs.push(this.config.getSeriesConfig(sensorType, metric, c.value.primaryKey, isMultiserver ? i : null));
            seriesConfigs.map(series => series.label = series.getLabel(server));
          });
        });
        return of(seriesConfigs);
      }
    });
    if (this.config.queryString) {
      return of([]);
    }
    return seriesConfigs$.length ? combineLatest(seriesConfigs$) : of([]);
  }

  private initDataSeriesForms(seriesConfigs: DataSeriesConfig[][]) {
    const dataSeriesControls = new UntypedFormArray([]);
    this.dataSeriesFormGroup = new UntypedFormGroup({
      dataSeries: dataSeriesControls
    });

    if (seriesConfigs.length > 20) {
      seriesConfigs = seriesConfigs.slice(0, 20);
    }

    seriesConfigs.forEach(v => v.forEach(seriesConfig => {
      const formGroup = new UntypedFormGroup({
        id: new UntypedFormControl(seriesConfig.id),
        label: new UntypedFormControl(seriesConfig.label, { updateOn: 'blur' }),
        color: new UntypedFormControl(seriesConfig.color),
        yAxis: new UntypedFormControl(seriesConfig.yAxis || 0)
      });

      dataSeriesControls.push(formGroup);
    }));

    this.dataSeriesFormGroup.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.emitConfigChanged();
    });
  }

  onSeriesColorChanged(index: number, color: string) {
    ((this.dataSeriesFormGroup.controls.dataSeries as UntypedFormArray).controls[index] as UntypedFormGroup).controls.color.setValue(color);
  }

  onAxisConfigChanged(id: number, config: any) {
    this.config.yAxes[id] = config;
    this.emitConfigChanged();
  }

  loadDatabases() {
    this.databases = null;
    this.databasesLoadError = null;
    this.schemaService.getDatabaseNames(this.dashboardService.getServers()[this.config.serverIndex ?
      this.config.serverIndex : 0]).subscribe(databases => {
        this.databases = databases;
        const index = this.databases.indexOf(this.dataSourcesFormGroup.value.databaseName);
        if (index < 0 && this.databases.length > 0) {
          this.dataSourcesFormGroup.controls.databaseName.setValue(this.databases[0]);
        }
      }, err => {
        if(err.error && err.error.err) {
          this.databasesLoadError = err.error.err;
        }else {
          this.translate.get('database.errorLoadingDatabases').subscribe((text: string) => {
            this.databasesLoadError = text;
          });
        }
        console.error(err);
      });
  }

  onRunClick() {
    this.emitConfigChanged();
    this.isRunClicked = true;
  }
}
