<app-data-table [data]="tableDetails.constraints" sortBy="name"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.constraints' | translate">
  <app-data-table-column [title]="'common.name' | translate" property="name" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'common.type' | translate" property="displayName" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'table.columns' | translate" property="columnNames" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.locale' | translate" property="collation"
    [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.enabled' | translate" property="enabled">
    <ng-template appTableCell let-enabled="item">
      <app-status-icon [status]="enabled"></app-status-icon>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.filtering' | translate"
    property="filtering">
    <ng-template appTableCell let-filtering="item">
      <app-status-icon [status]="filtering"></app-status-icon>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.withErrors' | translate"
    property="filterErrorsEnabled">
    <ng-template appTableCell let-filterErrorsEnabled="item">
      <app-status-icon [status]="filterErrorsEnabled"></app-status-icon>
    </ng-template>
  </app-data-table-column>
</app-data-table>
