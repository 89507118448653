/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerOnconfigParameter } from './serverOnconfigParameter';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InformixServerOnconfigService {

  constructor(private httpClient: HttpClient) { }

  public getOnconfig(serverId: number, name: string): Observable<ServerOnconfigParameter> {
    return this.httpClient.get<ServerOnconfigParameter>('informix/' + serverId + '/onconfig/' + name);
  }

  public getServerConfiguration(serverId: number, filter?: string[]): Observable<ServerOnconfigParameter[]> {
    let url = 'informix/' + serverId + '/onconfig';
    if (filter) {
      url += '?filter=' + filter.join(',');
    }
    return this.httpClient.get<ServerOnconfigParameter[]>(url);
  }

  public updateOnconfig(serverId: number, name: string, value: string, saveOption: string = 'wf'): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/onconfig/' + name, {
      value,
      option: saveOption
    }).toPromise<any>();
  }

  public updateServerConfiguration(serverId: number, updateInfo: any): Promise<any> {
    return this.httpClient.put<any>('informix/' + serverId + '/onconfig', updateInfo).toPromise<any>();
  }
}
