/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InformixServer } from '../../servers/informixServer';
import { TaskSchedulerService } from './task-scheduler.service';
import { TaskResponse } from './task-scheduler.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from './../../../shared/modal/confirmation-dialog.component';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { ParameterComponent } from './parameter.component';

@Component({
  selector: 'app-task-scheduler',
  templateUrl: './task-scheduler.component.html',
  styleUrls: ['./task-scheduler.component.scss'],
  providers: [TaskSchedulerService]
})
export class TaskSchedulerComponent implements OnInit {

  server: InformixServer = null;
  tableData: TaskResponse[];
  currentTaskId: number;
  rowId: number;
  openParameterModal: Boolean = false;
  saveDone: any;
  dataLoadErrorMessage: string = null;

  @ViewChild('addTaskSensorModal') addTaskSensorModal: ModalDirective;
  @ViewChild(ConfirmationDialogComponent) confirmationDialog: ConfirmationDialogComponent;
  @ViewChild(ParameterComponent) parameterComponent: ParameterComponent;

  constructor(
    private route: ActivatedRoute,
    private taskSchedulerService: TaskSchedulerService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getAllTasks();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.tableData = null;
  }

  private getAllTasks() {
    this.taskSchedulerService.getAllTasks(this.server).subscribe((res: Array<TaskResponse>) => {
      this.addData(res);
      if (this.openParameterModal) {
        this.parameterComponent.openAddParamModal(this.server, res.filter(task => task.tk_id === this.currentTaskId)[0]);
        this.openParameterModal = false;
      }
      this.dataLoadErrorMessage = null;
    }, (err: HttpErrorResponse) => {
      console.error(err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private addData(data) {
    if (data.length < 1) {
      return;
    }
      this.tableData = data.map(v => ({
      tk_id: v.tk_id,
      tk_name: v.tk_name,
      tk_group: v.tk_group,
      tk_start_time: v.tk_start_time,
      tk_frequency: {
        frequency: v.tk_frequency,
        $date: (v.tk_frequency && v.tk_frequency.IntervalDF ? v.tk_frequency.IntervalDF.seconds : null)
      },
      tk_monday: v.tk_monday,
      tk_tuesday: v.tk_tuesday,
      tk_wednesday: v.tk_wednesday,
      tk_thursday: v.tk_thursday,
      tk_friday: v.tk_friday,
      tk_saturday: v.tk_saturday,
      tk_sunday: v.tk_sunday,
      tk_enable: v.tk_enable,
      tk_next_execution: v.tk_next_execution,
      tk_result_table: v.tk_result_table
    }));
  }

  confirmDelete(id: number) {
    this.rowId = id;
    this.confirmationDialogService.show('Delete Task',
      'continue?', () => this.deleteTask());
  }

  deleteTask() {
    this.taskSchedulerService.deleteTask(this.server, this.rowId).then(result => {
      this.notificationsService.pushSuccessNotification('Task deleted');
      this.getAllTasks();
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  openRunModal(event: any, id: any, type: string) {
    event.stopPropagation();
    this.rowId = id;
    const message = 'run the task ' + ((type === 'Asynchronous') ? 'a' : '') + 'synchronously?';
    this.confirmationDialogService.show('Run Task', message,
      () => this.executeTask(type));
  }

  executeTask(type: any) {
    let options = {};
    if (type === 'Asynchronous') {
      options = { async: true };
    } else {
      options = { async: false };
    }
    this.taskSchedulerService.executeTask(this.server, this.rowId, options).subscribe(result => {
      this.notificationsService.pushSuccessNotification('Task submitted');
    }, err => {
      this.notificationsService.pushErrorNotification('Failed to run task: ' + (err.error.err));
    });
  }

  saveDoneEvent(event) {
    this.saveDone = event.saveDone;
    if (this.saveDone === true) {
      this.openParameterModal = event.openParameterModal;
      this.currentTaskId = event.currentTaskId;
      this.getAllTasks();
    }
  }
}
