/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021. All Rights Reserved.
 *******************************************************************************/
import { StorageScheme } from '../create-index/create-index.model';

export interface Table {
  tableName: string;
  tableOwner: string;
  tableType: string;
  tableColumnsList?: Array<TableColumnsList>;
  tableConstraint?: string;
  isCreateTableFlag?: boolean;
  tableNameSameAs?: string;
  isPageMode?: boolean;
  isVercols?: boolean;
  isCrcols?: boolean;
  isReplcheck?: boolean;
  isErKey?: boolean;
  isCompressed?: boolean;
  isRowLevelAudit?: boolean;
  thresholdValue?: number;
  firstExtent?: number;
  nextExtent?: number;
  isExecute: boolean;
  isNewLine: boolean;
  storageScheme?: StorageScheme;
  dateFormat?: string;
  moneyFormat?: string;
  noOfRows?: string;
  maximumErrors?: string;
  rejectFile?: string;
  dataFiles?: Array<DataFile>;
  fieldDelimiter?: string;
  recordDelimiter?: string;
  isEscapeCharacter?: boolean;
  primaryKey?: PrimaryKey;
  uniqueKey?: Array<UniqueKey>;
  foreignKey?: Array<ForeignKey>;
}

export interface TableColumnsList {
  tableColumnName: string;
  columnDataType: string;
  defaultNull?: boolean;
  columnConstraint?: string;
  constraintText?: string;
  externalCharacterLength?: number;
  defaultValue?: any;
  dataTypeAttributes?: DataTypeAttributes;
  setDataType?: any;
}

export interface DataTypeAttributes {
  startValue?: number;
  storeColumnIn?: string;
  extentSize?: number;
  turnOnLogging?: boolean;
  recordAccessTime?: boolean;
  highDataIntegrity?: boolean;
  length?: number;
  largestTimeUnit?: string;
  smallestTimeUnit?: string;
  scale?: number;
  precisionDigit?: number;
  defaultYearValue?: number;
  defaultMonthValue?: number;
  defaultDayValue?: number;
  defaultHourValue?: number;
  defaultMinuteValue?: number;
  defaultSecondValue?: number;
  defaultFractionValue?: number;
  noDefaultValue?: boolean;
  nullAsDefault?: boolean;
  namedRowType?: string;
  namedRowDataLength?: number;
}

export interface PrimaryKey {
  primaryKeyName: string;
  primaryKeyColumnName: Array<string>;
}

export interface UniqueKey {
  uniqueKeyName: string;
  uniquekeyColumnName: Array<string>;
}

export interface ForeignKey {
  foreignKeyName: string;
  referencedTableName: string;
  foreignKeyReferences: any;
  disableIndex: boolean;
}

export interface DataFile {
  id: number;
  dataFileType: string;
  dataFileName: string;
  blobDirectory?: string;
  clobDirectory?: string;
}

export class ConstraintInfo {
  constraintName: string;
  constraintType: string;
  columnName: any;
  constructor(data: any) {
    this.constraintName = data.constraintName;
    this.constraintType = data.constraintType;
    this.columnName = data.columnName;
  }
}

export class ReferenceKey {
  isExpand: boolean;
  tableName: string;
  tableOwner: string;
  constInfo: ConstraintInfo[];
  constructor(data: any) {
    this.isExpand = false;
    this.tableName = data.tableName;
    this.tableOwner = data.tableOwner;
    this.constInfo = data.constInfo.map(constraint => new ConstraintInfo(constraint));
  }
}

export class ReferenceKeys {
  tabInfo: ReferenceKey[];
  constructor(data: any) {
    if (data.tabInfo) {
      this.tabInfo = data.tabInfo.map(key => new ReferenceKey(key));
    }
  }
}
