/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';


import { InformixServer } from '../../informixServer';

@Component({
  selector: 'app-backup-logs',
  templateUrl: 'backup-logs.html'
})
export class BackupLogsComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;

  adminAPICommands: any[] = null;
  barActLog: string = null;

  viewAdminAdminAPILog = true;

  private barActLogSub: Subscription;
  private adminAPISub: Subscription;

  dataLoadErrorMessage: string = null;

  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server) {
      this.refreshData();
    }
  }

  handleLogChange(viewAdminAdminAPILog: boolean) {
    this.viewAdminAdminAPILog = viewAdminAdminAPILog;
    this.refreshData();
  }

  refreshData() {
    if (this.viewAdminAdminAPILog) {
      this.refreshAdminAPIData();
    } else {
      this.refreshBarActivityLogData();
    }
  }

  refreshAdminAPIData() {
    if (this.adminAPISub) {
      this.adminAPISub.unsubscribe();
    }

    this.adminAPISub = this.http.get<any[]>(
      'informix/' + this.server.id + '/logs/adminapi?commandFilter=["ontape","onbar","onsmsync"]').subscribe(commands => {
      this.adminAPICommands = commands;
      this.adminAPICommands.forEach(entry => {
        entry.cmd_exec_time = entry.cmd_exec_time.$date;
      });
    }, err => {
      this.dataLoadErrorMessage = (err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err);
      console.log(err);
    });
  }

  refreshBarActivityLogData() {
    if (this.barActLogSub) {
      this.barActLogSub.unsubscribe();
    }

    this.barActLogSub = this.http.get<any[]>('informix/' + this.server.id + '/logs/baractlog?offset=-4096').subscribe(messages => {
      this.barActLog = '';
      messages.forEach(elem => {
        this.barActLog += elem.message + '\n';
      });
    }, err => {
      this.dataLoadErrorMessage = (err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err);
      console.log(err);
    });
  }

  ngOnDestroy() {
    if (this.barActLogSub) {
      this.barActLogSub.unsubscribe();
    }
    if (this.adminAPISub) {
      this.adminAPISub.unsubscribe();
    }
  }

}
