/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';
import { HighAvailabilityComponent } from './high-availability.component';
import { ServerResolver } from '../server-resolver.service';

export const HighAvailabilityRoutes: Route[] = [
  {
    path: 'ha',
    component: HighAvailabilityComponent,
    resolve: {
      server: ServerResolver
    }
  }
];
