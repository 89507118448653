<section [style.marginTop.px]="50">
  <div class="container-fluid page-container"[style.minHeight.vh]="94">
    <div class="row">
      <div class="col">
        <h4>
          <strong>Monitoring Server Logs</strong>
        </h4>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col align-items-center d-flex">
        <small *ngIf="selectedLogFile">You are currently viewing the {{selectedLogFile}} file directly from the {{productName}} server.</small>
      </div>
      <div class="col">
        <select  class="form-control-sm float-right" [(ngModel)]="selectedLogFile" (ngModelChange)="onLogFileChanged($event)" [style.maxWidth.rem]="25">
          <option *ngFor="let filename of filenames" [value]="filename">{{filename}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="dataLoadErrorMessage" class="alert alert-warning mt-1">
      {{dataLoadErrorMessage}}
    </div>
    <div *ngIf="logData.length" class="card" style="margin-top: 0.5rem !important">
      <app-log-window class="card-body" style="height: 80vh; overflow: auto" (eventTopReached)="onLogWindowTopReached()">
        <div *ngIf="loadingMessages" style="text-align: center">
          <i class="icon icon-circle-notch icon-spin icon-2x ml-2"></i>
        </div>
        <div class="text-monospace" style="white-space: pre">
          <pre class="mb-0 {{ (log?.line?.length)?log?.type:'' }}" *ngFor="let log of logData;"> {{log?.line}} </pre>
        </div>
      </app-log-window>
    </div>
  </div>
</section>
