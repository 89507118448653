/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { GroupAlertingComponent } from './group-alerting.component';
import { GroupResolver } from './group-resolver.service';
import { ErrorForbiddenComponent } from './forbidden.component';
import { NotFoundComponent } from './not-found.component';
import { CanDeactivateGuard } from '../../shared/canDeactivateGuard.service';
import { GroupMonitoringComponent } from './groupMonitoring.component';
import { AddServerComponent } from '../servers/add-server.component';
import { GroupsComponent } from './groups.component';
import { GroupComponent } from './group.component';
import { Route } from '@angular/router';
import { GroupPermissionsComponent } from './groupPermissions.component';
import { dashboardRoutes } from '../monitoring/dashboard/dashboard.routes';
import { GroupIncidentsComponent } from './groupIncidents.component';
import { SystemLogsComponent } from '../system-logs/system-logs.component';
import { PermissionGuard } from '../../shared/user/permissionGuard.service';

export const GroupsRoutes: Route[] = [
  {
    path: 'groups',
    children: [
      {
        path: '',
        redirectTo: '0',
        pathMatch: 'full'
      },
      {
        path: 'not-found',
        component: NotFoundComponent
      },
      {
        path: 'forbidden',
        component: ErrorForbiddenComponent
      },
      {
        path: ':groupId',
        component: GroupsComponent,
        resolve: {
          group: GroupResolver
        },
        children: [
          {
            path: '',
            component: GroupComponent,
            resolve: {
              group: GroupResolver
            },
          },
          {
            path: 'add-server',
            component: AddServerComponent,
            resolve: {
              group: GroupResolver
            }
          },
          {
            path: 'monitoring',
            component: GroupMonitoringComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              group: GroupResolver
            }
          },
          {
            path: 'alerting',
            component: GroupAlertingComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              group: GroupResolver
            }
          },
          {
            path: 'permissions',
            component: GroupPermissionsComponent,
            resolve: {
              group: GroupResolver
            }
          },
          {
            path: 'incidents',
            component: GroupIncidentsComponent,
            resolve: {
              group: GroupResolver
            }
          },
          {
            path: 'logs',
            component: SystemLogsComponent,
            canActivate: [PermissionGuard]
          },
          ...dashboardRoutes
        ]
      }
    ]
  }
];
