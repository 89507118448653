/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { GroupBreadcrumb } from '../../../groups/groupBreadcrumb';
import { InformixServerGroup } from '../../../groups/informixServerGroup';
import { InformixServerGroupService } from '../../../groups/informixServerGroup.service';
import { InformixServer } from '../../../servers/informixServer';
import { ServerBreadcrumb } from '../../../servers/serverBreadcrumb';
import { Dashboard } from '../dashboard';
import { DashboardHttpService } from '../dashboard-http.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

interface DashboardTableItem {
  dashboard: Dashboard;
  name: string;
  isInherited: boolean;
}

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  group: InformixServerGroup = null;
  server: InformixServer = null;

  dashboards: Dashboard[] = null;
  dashboardTableItems: DashboardTableItem[] = null;
  selectedDashboards: DashboardTableItem[] = [];
  downloadJsonHref: SafeUrl;
  constructor(
    private route: ActivatedRoute,
    private groupService: InformixServerGroupService,
    private dashboardService: DashboardHttpService,
    private confirmationDialogService: ConfirmationDialogService,
    private notifications: NotificationsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data.group) {
        this.setGroup(data.group);
      } else if (data.server) {
        this.setServer(data.server);
      }
    });
  }

  private setServer(server: InformixServer) {
    this.server = server;
    this.groupService.getGroup(server.parentGroupId).then(group => {
      this.setGroup(group);
    }).catch(err => {
      this.notifications.pushErrorNotification('There was a problem getting the list of dashboards');
      console.error(err);
    });
  }

  private setGroup(group: InformixServerGroup) {
    this.group = group;
    this.breadcrumb = [{ name: 'Dashboards' }];
    if (this.server) {
      this.breadcrumb = ServerBreadcrumb.build(this.server, this.breadcrumb);
    }
    this.breadcrumb = GroupBreadcrumb.build(this.group, this.breadcrumb);
    this.dashboards = null;
    this.dashboardService.getDashboards(this.group).subscribe(dashboards => {
      this.dashboards = dashboards;
      this.buildTableData();
    }, err => {
      this.notifications.pushErrorNotification('There was a problem getting the list of dashboards');
      console.error(err);
    });
  }

  private buildTableData() {
    this.dashboardTableItems = this.dashboards.map(this.getDashboardTableItem.bind(this));
  }

  private getDashboardTableItem(dashboard: Dashboard): DashboardTableItem {
    return {
      dashboard,
      name: dashboard.config.name,
      isInherited: dashboard.group.id !== this.group.id
    };
  }

  removeDashboard(dashboard: Dashboard) {
    this.confirmationDialogService.show('delete \'' + dashboard.config.name + '\'?', () => {
      this.dashboardService.removeDashboard(dashboard).subscribe(() => {
        const index = this.dashboards.indexOf(dashboard);
        if (index > -1) {
          this.dashboards.splice(index, 1);
        }
        this.buildTableData();
        this.notifications.pushSuccessNotification('Removed dashboard successfully.');
      }, err => {
        this.notifications.pushErrorNotification('There was a problem removing the dashboard.');
        console.error(err);
      });
    });
  }

  removeSelectedDashboards() {
    this.confirmationDialogService.show('delete selected dashboard(s)?', () => {
      const selectedDashboardsIds = [];
      this.selectedDashboards.forEach(item => {
        selectedDashboardsIds.push(item.dashboard.id);
      });
      this.dashboardService.removeSelectedDashboards(this.group.id, selectedDashboardsIds).subscribe(() => {
        this.selectedDashboards.forEach(item => {
          const index = this.dashboards.indexOf(item.dashboard);
          if (index > -1) {
            this.dashboards.splice(index, 1);
          }
        });
        this.buildTableData();
        this.notifications.pushSuccessNotification('Removed selected dashboard(s) successfully.');
      }, err => {
        this.notifications.pushErrorNotification('There was a problem removing the dashboard(s).');
        console.error(err);
      });
    });
  }

  getSelectedRows(event: DashboardTableItem[]) {
    setTimeout(() => this.selectedDashboards = event, 0);
  }

  importJSON(file: any) {
    if (file.files.length === 0) {
      return;
    }
    if (file.files[0].type.match(/json\/*/) == null) {
      this.notifications.pushErrorNotification('Only JSON files are supported.');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const importedJSON = JSON.parse(reader.result.toString())['dashboards'];
      if (importedJSON) {
        importedJSON.forEach(dashboard => {
          dashboard.config['new'] = true;
        });
        this.dashboardService.createDashboard(this.group, { newDashboard: importedJSON }).subscribe(dashboards => {
          this.dashboards.push(...dashboards);
          this.buildTableData();
          file.value = '';
        }, err => {
          this.notifications.pushErrorNotification('There was a problem importing dashboards.');
          file.value = '';
        });
      } else {
        this.notifications.pushErrorNotification('JSON file does not have dashboards.');
      }
    };
    reader.readAsText(file.files[0]);
  }

  exportJson() {
    if (this.selectedDashboards.length === 0) {
      this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl('javascript:void(0)');
    } else {
      const dashboards = [];
      this.selectedDashboards.forEach(item => dashboards.push({ config: item.dashboard.config }));
      const json = JSON.stringify({ dashboards });
      const blob = new Blob([json], { type: 'text/json' });
      this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
      if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
        window.navigator.msSaveBlob(blob, 'dashboards.json');
      }
    }
  }
}
