/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { User } from './user';
import { UserService } from './user.service';

export function userFactory(userService: UserService): User {
  return userService.getCurrentUserDirty();
}

export const userProvider = {
  provide: User,
  useFactory: userFactory,
  deps: [UserService]
};
