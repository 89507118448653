<div class="container-fluid page-container">
  <div class="row">
    <div class="col col-auto">
      <h3>Alerting Configuration</h3>
    </div>
    <div class="col">
      <button class="btn" [ngClass]="{'btn-success': alertingEnabled, 'btn-secondary': !alertingEnabled}" *ngIf="alertProfile"
        (click)="toggleAlertingEnabled()">{{alertingEnabled ? 'Enabled' : 'Disabled'}}</button>
    </div>
  </div>

  <ng-container *ngIf="alertingEnabled && messengers && alertProfile">
    <div class="mt-3 mb-3">
      <h5>
        Alerting Service&nbsp;&nbsp;
        <small class="text-muted">Configure how you are notified when an alerting incident occurs</small>
      </h5>
    </div>
    <div class="ml-5 mt-3">
      <div class="form-inline">
        <label class="mr-2">Alert me using:</label>
        <select class="form-control form-control-sm" [(ngModel)]="alertType">
          <option *ngFor="let type of alertTypes" [ngValue]="type">{{type.name}}</option>
        </select>
      </div>
      <div class="my-3">
        <div class="row" *ngIf="alertType.available">
          <div class="col col-xl-8">
            <app-user-email-settings #settings *ngIf="alertType.id === 'email'" [settings]="messengers.email"
              (eventChange)="onSettingsChange($event)">
            </app-user-email-settings>
            <app-user-twilio-settings #settings *ngIf="alertType.id === 'twilio'" [settings]="messengers.twilio"
              (eventChange)="onSettingsChange($event)"> </app-user-twilio-settings>
          </div>
        </div>
        <ng-template [ngIf]="!alertType.available">
          <alert type="warning">
            {{alertType.name}} alerting is not available.
            <a *ngIf="currentUser && currentUser.isSuperAdmin(); else contactAdminMessage" [routerLink]="['/dashboard/system-settings']">The
              alerting service must be configured at the system level first.</a>
            <ng-template #contactAdminMessage>
              Contact your {{ productNameNoSpace }} adminstrator.
            </ng-template>
          </alert>
        </ng-template>
      </div>
    </div>
    <ng-template [ngIf]="alertType.available">
      <div class="mt-5 mb-3">
        <h5>
          Notification Preferences
        </h5>
      </div>
      <div class="ml-5 my-3">
        <app-alert-preferences-settings #preferences [settings]="alertProfile" (eventChange)="onSettingsChange($event)"></app-alert-preferences-settings>
      </div>
    </ng-template>
    <button class="btn btn-primary" [disabled]="isSaveDisabled()" (click)="saveSettings()">Save</button>
  </ng-container>
</div>
