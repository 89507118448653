<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-4">
      <h4><strong>{{ 'systemReports.locksWithWaiters' | translate }}</strong>
      </h4>
      <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
    </div>
    <div class="col-8">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
  </div>
  
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
    <app-data-table-column [title]="'database.database' | translate" property="dbsname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'table.tableName' | translate" property="tabname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.ownerSessionId' | translate" property="owner_sid" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', item]">{{item}}</a>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'common.owner' | translate" property="ownerUserDisplay" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        {{item}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waiterSessionId' | translate" property="wait_sid" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', item]">{{item}}</a>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waiter' | translate" property="waitUserDisplay" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        {{item}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waitTime' | translate" property="lock_wait" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{item| intervalDS}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockId' | translate" property="lock_id" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockType' | translate" property="lock_type" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.rowId' | translate" property="rowid" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.key' | translate" property="keynum" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockStartTime' | translate" property="lock_establish"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockDuration' | translate" property="lock_duration"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{item| intervalDS}}
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
