/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Levenshtein } from './levenshtein';
import { SidebarElement } from './sidebar';
import { InformixServerService } from '../../dashboard/servers/informixServer.service';


interface SidebarElementUI extends SidebarElement {
  isActive: boolean;
  isExpanded: boolean;
  urlTree?: UrlTree;
  children: SidebarElementUI[];
  searchScore?: number;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.html',
  animations: [
    trigger('slideAnimation', [
      state('*', style({ height: '*', 'padding-top': '*', 'padding-bottom': '*' })),
      transition(':enter', [
        style({ height: 0, 'padding-top': 0, 'padding-bottom': 0 }),
        animate('0.2s ease-in')
      ]),
      transition(':leave', [
        animate('0.2s ease-out', style({ height: 0, 'padding-top': 0, 'padding-bottom': 0 }))
      ])
    ])
  ]
})
export class SidebarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() enableSearch = true;
  @Input() sidebar: SidebarElementUI[];
  isActive = false;

  searchFormControl: UntypedFormControl = new UntypedFormControl();
  isSearching = false;
  isWorkOffline: Boolean;

  private routerSubscription: Subscription;
  private searchSubscription: Subscription;
  private workOffSub: Subscription;

  private ignoreDocumentClickEvent = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serverService: InformixServerService,
  ) { }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.sidebar) {
        this.updateActiveElement();
      }
    });

    this.searchSubscription = this.searchFormControl.valueChanges.pipe(debounceTime(200)).subscribe(value => {
      if (!this.sidebar) {
        return;
      }

      const searchString = (value as string).trim().toLowerCase();
      if (searchString && searchString.length > 1) {
        this.sidebar.forEach(elem => {
          elem.searchScore = this.getSearchScore(searchString, elem.searchKeywords);
          if (elem.children) {
            elem.children.forEach(child => {
              child.searchScore = this.getSearchScore(searchString, child.searchKeywords);
              if (child.searchScore < elem.searchScore) {
                elem.searchScore = child.searchScore;
              }
            });
          }
        });

        this.isSearching = true;
      } else {
        this.isSearching = false;
      }
    });

    // update isWorkOffline flag
    this.workOffSub = this.serverService.onConnStatus$.subscribe(isWorkOffline => {
      if (isWorkOffline !== undefined) {
        this.isWorkOffline = isWorkOffline;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sidebar && this.sidebar) {
      this.sidebar.forEach(elem => {
        this.prepareElement(elem);

        if (elem.children) {
          elem.children.forEach(child => {
            this.prepareElement(child);
          });
        }

        this.updateActiveElement();
      });
    }

    if (changes.enableSearch) {
      this.isSearching = false;
      this.searchFormControl.setValue('');
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.workOffSub) {
      this.workOffSub.unsubscribe();
    }
  }

  private updateActiveElement() {
    this.sidebar.forEach(elem => {
      elem.isActive = elem.urlTree && this.router.isActive(elem.urlTree, elem.linkActiveExact);

      if (elem.children) {
        if (elem.isActive) {
          elem.isExpanded = true;
        }

        elem.children.forEach(child => {
          child.isActive = child.urlTree && this.router.isActive(child.urlTree, child.linkActiveExact);
          if (child.isActive) {
            elem.isExpanded = true;
          }
        });
      }
    });
  }

  private prepareElement(elem: SidebarElementUI) {
    if (elem.link) {
      elem.urlTree = this.router.createUrlTree([elem.link], { relativeTo: this.route });
      elem.linkActiveExact = !!elem.linkActiveExact;
    }

    if (this.enableSearch) {
      const labelKeywords = elem.label.toLowerCase().split(' ');
      if (!elem.searchKeywords) {
        elem.searchKeywords = labelKeywords;
      } else {
        elem.searchKeywords = elem.searchKeywords.concat(labelKeywords);
      }
    }
  }

  onCategoryClick(elem: SidebarElementUI) {
    if (elem.link) {
      this.navigateToElement(elem);
      elem.isExpanded = true;
    } else {
      elem.isExpanded = !elem.isExpanded;
    }
  }

  onSubCategoryClick(elem: SidebarElementUI) {
    if (elem.link) {
      this.navigateToElement(elem);
    }
  }

  private navigateToElement(elem: SidebarElementUI) {
    this.router.navigate([elem.link], { relativeTo: this.route });
  }

  toggleSidebar() {
    this.ignoreDocumentClickEvent = true;
    this.isActive = !this.isActive;
  }

  private getSearchScore(needle: string, haystacks: string[]) {
    let score = Infinity;
    haystacks.forEach(haystack => {
      const newScore = Levenshtein.search(needle, haystack);
      if (newScore < score) {
        score = newScore;
      }
    });
    return score;
  }

  elementTrackBy(index: number, elem: SidebarElementUI) {
    return elem.label;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.ignoreDocumentClickEvent) {
      this.ignoreDocumentClickEvent = false;
      return;
    }

    this.isActive = false;
  }

  onSidebarClick() {
    this.ignoreDocumentClickEvent = true;
  }
}
