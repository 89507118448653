<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
    <div class="row mb-3">
        <div class="align-items-center col-md-8 d-flex">
          <h4 class="mb-0">
            <strong>ER Domain</strong>
            <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
          </h4>
        </div>
        <div class="col-md-4 text-right"  *ngIf="!isLoading"> 
            <!-- <button tooltip="View help" placement="left" type="button" class="btn pt-2 m-0" (click)="showPrerequistiesModal();">
              <i class="icon icon-info-circle text-primary help-icon" ></i>
            </button> -->
            <!-- <button tooltip="View help" placement="left" type="button" class="btn pt-2 m-0" (click)="contextHelpModal.show();">
              <i class="icon icon-info-circle text-primary help-icon" ></i>
            </button> -->
        </div>
    </div>

    <ng-container *ngIf="!isLoading && !domainAlertMessage; else errorMessage;">
      <app-er-domain [server]="server" [insideLoading]="insideLoading" [erDomainClass]="erDomainClass" (nodeDefined)="refreshDomain()" (nodeDeleted)="reSyncDomain()" [(sidebars)]="sidebars" [newERDomain]="newERDomain"
       (reSyncDomain)="reSyncDomain();" [isDraft]="isDraft"> </app-er-domain>
    </ng-container>
    <ng-template #errorMessage>
         <div class="alert alert-danger mt-2 mb-0 w-100" *ngIf="!isLoading">
            Unable to retrieve domain information. <br> Error: {{domainAlertMessage}} 
          </div>
    </ng-template>
</div>

<ng-template #prerequistiesModal>
    <div>
      <button type="button" class="close p-3 position-absolute" (click)="hideModal()">
          <i class="icon icon-times"></i>
      </button>
      <div class="modal-body p-0">
        <app-er-prerequiesties [helpOpened]="true"> </app-er-prerequiesties>
      </div>
    </div>
</ng-template>

<div bsModal #contextHelpModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">  
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title text-truncate">
                Context
            </h5>
            <button type="button" class="close" (click)="contextHelpModal.hide()">
              <i class="icon icon-times"></i>
            </button>
        </div>
        <div class="modal-body p-3 pt-4 pb-4 overflow-auto" [ngStyle]="{'max-height': '70vh'}">
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">Node Type</label>
                </div>
                <div class="row m-0">
                  <span>Defines the server as a root, non-root, or leaf server in an domain</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">Server for sync</label>
                </div>
                <div class="row m-0">
                  <span>Adds a server to the domain of which the sync_server is a member.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">CDR_QDATA_SBSPACE</label>
                </div>
                <div class="row m-0">
                  <span>Required sbspace to store replicate queue.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">CDR_DBSPACE</label>
                </div>
                <div class="row m-0">
                  <span>Voluptua et diam duo dolores et invidunt et stet sed lorem, clita eirmod lorem consetetur.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">ATS Directory</label>
                </div>
                <div class="row m-0">
                  <span>Name of the directory for Aborted Transaction Spooling files.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">RIS Directory</label>
                </div>
                <div class="row m-0">
                  <span>Name of the directory for Row Information Spooling files.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">ATS & RIS File Format</label>
                </div>
                <div class="row m-0">
                  <span>Specifies the format(text, xml, both) of ATS and RIS files.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">Idle timeout</label>
                </div>
                <div class="row m-0">
                  <span>Set the number of minutes after which an inactive connection is closed after timeout minutes.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">Mode</label>
                </div>
                <div class="row m-0">
                  <span>Et dolores takimata justo amet lorem, no diam justo justo eos elitr diam accusam, sit et sadipscing invidunt amet ipsum.</span>
                </div>
            </div>
            <div class="form-group">
                <div class="row m-0 font-weight-bold">
                  <label class="mb-0">Quality of data (QOD) monitoring</label>
                </div>
                <div class="row m-0">
                  <span>Defines a master server for monitoring the quality of data (QOD) for replication servers</span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
              <button class="btn btn-secondary" (click)="contextHelpModal.hide();">Close</button>
        </div>
      </div>
    </div>
</div>

