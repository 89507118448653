<ng-container *ngIf="profile">

  <div class="row mb-1">
    <div class="col">
      <h4>Alerts</h4>
    </div>
    <div class="col col-md-auto">
      <app-check-permissions [permissions]="owner.permissions.admin">
        <button class="btn btn-sm btn-primary" (click)="openAddNewAlert()" appPermissionDisable>
          <i class="icon icon-plus"></i> Add Alert
        </button>
      </app-check-permissions>
    </div>
  </div>

  <div class="card my-2" *ngIf="newAlert">
    <div class="card-body">
      <h4 class="card-title">Create a new alert</h4>
      <p class="card-text mb-2">
        <app-alert-config #newAlertConfig [owner]="owner" [alert]="newAlert"></app-alert-config>
      </p>
      <button type="button" class="btn btn-primary" [disabled]="!newAlertConfig.isValid()" (click)="addNewAlert(newAlertConfig.getAlert())">
        <i class="icon icon-plus"></i> Add
      </button>
      <button type="button" class="btn btn-secondary" (click)="newAlert = null">
        <i class="icon icon-times"></i> Cancel
      </button>
    </div>
  </div>

  <app-data-table [data]="uiAlerts" class="table-fixed" [enablePagination]="false" noDataAlertMessage="No alerts defined." [minHeight]="dynamicHeight">
    <app-data-table-expand [expandCondition]="isEditingAlert.bind(this)">
      <ng-template appTableExpand let-alert="row">
        <div class="inline-edit">
          <h6 class="mb-3"><i class="icon icon-edit"></i> Edit {{alert.name}}</h6>
          <div class="container-fluid">
            <app-alert-config [owner]="owner" [alert]="alert" (eventUpdate)="onEditedAlertUpdate($event)"></app-alert-config>
          </div>
        </div>
      </ng-template>
    </app-data-table-expand>
    <app-data-table-column title="Name" property="name" [sortable]="true">
      <ng-template appTableCell let-alert="row">
        {{alert.name}}
        <span *ngIf="alert.isNew" class="badge badge-success">New</span>
        <span *ngIf="alert.isChanged" class="badge badge-warning">Modified</span>
        <span *ngIf="!alert.isValid" class="badge badge-danger">Invalid</span>
        <span *ngIf="alert.disabled" class="badge badge-danger">Disabled</span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Run..." property="trigger">
      <ng-template appTableCell let-trigger="item">
        <ng-template [ngIf]="trigger.type === 'interval'">
          every {{(trigger.interval / 1000) | relativeTime}}
        </ng-template>
        <ng-template [ngIf]="trigger.type === 'event'">
          on change
        </ng-template>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-alert="row">
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-secondary" (click)="toggleEditAlert(alert)" tooltip="Edit" appPermissionDisable>
            <i class="icon icon-edit"></i>
          </button>
        </app-check-permissions>
        <button class="btn btn-sm btn-secondary" (click)="undoAlertChanges(alert)" tooltip="Undo" [disabled]="!alert.isChanged">
          <i class="icon icon-reset"></i>
        </button>
        <app-check-permissions *ngIf="!alert.isNew" [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-secondary" [hidden]="alert.disabled" (click)="toggleEnabled(alert)" tooltip="Disable"
            appPermissionDisable>
            <i class="icon icon-ban"></i>
          </button>
          <button class="btn btn-sm btn-secondary" [hidden]="!alert.disabled" (click)="toggleEnabled(alert)" tooltip="Enable"
            appPermissionDisable>
            <i class="icon icon-check"></i>
          </button>
        </app-check-permissions>
        <app-check-permissions [permissions]="owner.permissions.admin">
          <button class="btn btn-sm btn-secondary" (click)="removeAlert(alert)" tooltip="Remove" appPermissionDisable>
            <i class="icon icon-trash"></i></button>
        </app-check-permissions>
      </ng-template>
    </app-data-table-column>
  </app-data-table>


  <ng-template [ngIf]="uiInheritedAlerts">
    <hr>

    <div class="row my-1">
      <div class="col">
        <h4>Inherited Alerts</h4>
      </div>
    </div>

    <app-data-table [data]="uiInheritedAlerts" class="table-fixed" [enablePagination]="false" noDataAlertMessage="No inherited alerts defined." [minHeight]="dynamicHeight">
      <app-data-table-column title="Name" property="name" [sortable]="true">
        <ng-template appTableCell let-alert="row">
          {{alert.name}}
          <span *ngIf="alert.disabled" class="badge badge-danger">Disabled</span>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column title="Run..." property="trigger">
        <ng-template appTableCell let-trigger="item">
          <ng-template [ngIf]="trigger.type === 'interval'">
            every {{(trigger.interval / 1000) | relativeTime}}
          </ng-template>
          <ng-template [ngIf]="trigger.type === 'event'">
            on change
          </ng-template>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column class="text-right">
        <ng-template appTableCell let-alert="row">
          <app-check-permissions [permissions]="owner.permissions.admin">
            <button class="btn btn-sm btn-secondary" (click)="cloneAlert(alert)" tooltip="Clone" appPermissionDisable>
              <i class="icon icon-copy"></i>
            </button>
          </app-check-permissions>
          <app-check-permissions [permissions]="owner.permissions.admin">
            <button class="btn btn-sm btn-secondary" [hidden]="alert.disabled" (click)="toggleEnabled(alert)" tooltip="Disable"
              appPermissionDisable>
              <i class="icon icon-ban"></i>
            </button>
            <button class="btn btn-sm btn-secondary" [hidden]="!alert.disabled" (click)="toggleEnabled(alert)"
              tooltip="Enable" appPermissionDisable>
              <i class="icon icon-check"></i>
            </button>
          </app-check-permissions>
        </ng-template>
      </app-data-table-column>
    </app-data-table>
  </ng-template>

  <ng-template [ngIf]="uiAlerts.length > 0 || changeCount > 0">
    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-primary" [disabled]="isSaveButtonDisabled()" (click)="saveProfile()">
          <i class="icon icon-check"></i> Save Changes
        </button>
        <button class="btn btn-secondary ml-2" [disabled]="isSaveButtonDisabled()" (click)="discardChanges()">
          <i class="icon icon-times"></i> Discard Changes
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>

<div bsModal #discardChangesModal="bs-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
        <button type="button" class="close" (click)="discardChangesModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="p-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
          Discard unsaved changes?
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="discardChangesModal.hide()">No</button>
        <button type="button" class="btn btn-primary" (click)="resolveDiscardChanges(true)">Yes</button>
      </div>
    </div>
  </div>
</div>
