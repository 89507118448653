<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
<div class="row">
    <div class="col">
      <h4 class="page-header">
        <strong>Admin API Log</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="dataLoading"></i>
      </h4>
    </div>
    <ng-template [ngIf]="server.hasMonitorPassword">
      <div class="col col-md-auto form-inline" *ngIf="adminAPICommandHistory">
        <label class="mr-3">
          <strong>Filter by:</strong>
        </label>
        <label class="mr-1">Status</label>
        <select id="metricSelect" [ngModel]="statusFilter" (ngModelChange)="changeStatusFilter($event)" class="form-control form-control-sm mr-3">
          <option ngValue="all">All</option>
          <option ngValue="error">Error</option>
          <option ngValue="success">Success</option>
        </select>
        <label class="mr-1">Time</label>
        <select id="metricSelect" [ngModel]="timeFilter" (ngModelChange)="changeTimeFilter($event)" class="form-control form-control-sm">
          <option ngValue="7">Last 7 days</option>
          <option ngValue="30">Last 30 days</option>
          <option ngValue="90">Last 90 days</option>
          <option ngValue="-1">Any</option>
        </select>
      </div>
    </ng-template>
  </div>

  <ng-template [ngIf]="!server.hasMonitorPassword">
    <div class="alert alert-warning">
      Admin API log information is not available. Monitoring credentials have not been supplied.
    </div>
  </ng-template>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger">
      Unable to retrieve Admin API log information. <br> Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <ng-template [ngIf]="filteredAdminAPICommandHistory">
    <div class="table-responsive">
      <app-admin-api-data-table [adminAPICommands]="filteredAdminAPICommandHistory">
      </app-admin-api-data-table>
    </div>

  </ng-template>

</div>
