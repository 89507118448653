<ng-template [ngIf]="valueControl">
  <ng-container *ngIf="valueOptions; else noOptions">
    <select class="form-control form-control-sm" [formControl]="valueControl">
      <option *ngFor="let option of valueOptions" [ngValue]="option.value">{{option.name}}</option>
    </select>
  </ng-container>
  <ng-template #noOptions>
    <input type="text" class="form-control form-control-sm config-input-str" *ngIf="onconfig.type === 'STRING'"
      [formControl]="valueControl" tooltip="" appExtendedFormControl appFocusOnCreate>
    <input type="number" class="form-control form-control-sm config-input-integer" *ngIf="onconfig.type === 'INTEGER'"
      [formControl]="valueControl" tooltip="" appExtendedFormControl appFocusOnCreate [min]="onconfig.info.min" [max]="onconfig.info.max">
  </ng-template>
</ng-template>
