/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { ChartJSUtils } from '../../../../shared/chartjs.utils';
import { InformixServer } from '../../informixServer';
import { Sensor } from '../../../monitoring/sensor';
import { getTimeSlice, TimeSlice } from '../../../monitoring/timeSlices';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { UserService } from '../../../../shared/user/user.service';
import { User } from '../../../../shared/user/user';

@Component({
  selector: 'app-backup-history',
  templateUrl: 'backup-history.html'
})
export class BackupHistoryComponent implements OnChanges {

  @Input() server: InformixServer;
  @Input() sensor: Sensor;

  selectedTimeSlice: string;

  private _backupHistoryChartElement: ElementRef = null;

  @ViewChild('backupHistoryChart')
  get backupHistoryChartElement(): ElementRef {
    return this._backupHistoryChartElement;
  }
  set backupHistoryChartElement(elementRef: ElementRef) {
    this._backupHistoryChartElement = elementRef;
    if (elementRef) {
      this.graphData();
    }
  }

  private backupHistoryChart: any;
  private backupHistoryChartConfiguration: any;

  private hasHistoryData = true;
  private level0Data: any[];
  private level1Data: any[];
  private level2Data: any[];

  dataLoadErrorMessage = null;

  private backupHistorySub: Subscription;

  constructor(private http: HttpClient,
    private userService: UserService,
    private notifications: NotificationsService) { }

  ngOnChanges() {
    this.getHistoryData();
  }

  private getHistoryData(event?: TimeSlice) {
    if (event) {
      this.selectedTimeSlice = event.name;
      this.getBackupHistory();
    } else {
      this.userService.getCurrentUser().then((user: User) => {
        this.selectedTimeSlice = user.settings.timeSliceName;
        this.getBackupHistory();
      }).catch(err => {
        this.notifications.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
      });
    }
  }

  private getBackupHistory() {
    if (this.backupHistorySub) {
      this.backupHistorySub.unsubscribe();
    }

    if (!this.selectedTimeSlice) {
      return;
    }
    const now = new Date().getTime();
    const fromTimestamp = now - getTimeSlice(this.selectedTimeSlice).value;
    this.backupHistorySub = this.http.get<any>('informix/' + this.server.id + '/backups/history?from=' + fromTimestamp).subscribe(data => {
      this.level0Data = this.transformBackupData(data, 'level0');
      this.level1Data = this.transformBackupData(data, 'level1');
      this.level2Data = this.transformBackupData(data, 'level2');
      this.hasHistoryData = (this.level0Data.length > 0 ||
        this.level1Data.length > 0 || this.level2Data.length > 0);
      if (this.hasHistoryData) {
        this.graphData();
      } else {
        this.destroyChart();
      }
    }, err => {
      if (err.status === 404) {
        // sensor table not found
        this.hasHistoryData = false;
      } else {
        this.dataLoadErrorMessage = (err.error && err.error.err) ? err.error.err :
          ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err);
        console.log(err);
      }
    });
  }

  private transformBackupData(data: any[], level: string) {
    const levelData = [];
    data[level].forEach(timestamp => {
      levelData.push({ x: new Date(timestamp * 1000), y: 0 });
    });
    return levelData;
  }

  private graphData() {
    if (!this.hasHistoryData || !this.backupHistoryChartElement) {
      return;
    }

    if (!this.backupHistoryChart) {
      this.createChart();
    }
    this.updateChartDatasets();
    this.backupHistoryChart.update();
  }

  private createChart() {
    if (!this.backupHistoryChartElement) {
      return;
    }
    this.backupHistoryChartConfiguration = {
      type: 'line',
      data: {
        datasets: []
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              gridLines: {
                display: false
              },
              distribution: 'series',
              ticks: {
                source: 'labels',
                maxRotation: 0,
                autoSkipPadding: 10,
                autoSkip: true,
              }
            }
          ],
          yAxes: [
            {
              distribution: 'series',
              type: 'linear',
              display: false,
              gridLines: {
                display: false
              },
              ticks: {
                min: 0,
                max: 1
              }
            }
          ]
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ' Backup';
            }
          }
        }
      }
    };
    this.backupHistoryChart = new Chart(this.backupHistoryChartElement.nativeElement,
      this.backupHistoryChartConfiguration);
  }

  private destroyChart() {
    if (!this.backupHistoryChart) {
      return;
    }

    this.backupHistoryChart.destroy();
    this.backupHistoryChart = null;
  }

  private updateChartDatasets() {
    this.backupHistoryChartConfiguration.data.datasets = [
      this.buildDataset('Level 0', this.level0Data, ChartJSUtils.getDefaultColor(6)),
      this.buildDataset('Level 1', this.level1Data, ChartJSUtils.getDefaultColor(3)),
      this.buildDataset('Level 2', this.level2Data, ChartJSUtils.getDefaultColor(4))
    ];
  }

  private buildDataset(label: string, data: any[], color: string) {
    return {
      label,
      data,
      backgroundColor: color,
      borderColor: color,
      pointRadius: 8,
      showLine: false
    };
  }
}
