/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { ConstantEnum } from './constant-operands';

export const haStatusEnum: ConstantEnum = {
  name: 'High Availability Status',
  type: 'ha-status',
  elements: [
    {
      name: 'Connected',
      value: 1
    },
    {
      name: 'Disconnected',
      value: 0
    },
    {
      name: 'Unknown',
      value: -1
    }
  ]
};
