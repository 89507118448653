<ng-container *ngIf="adminUsersInfo; else userInfoLoading" >
  <div class="row">
    <div class="col-12 mt-3 col-xl-2 col-lg-3">
      <h5>SQL Admin API Privileges</h5>
    </div>
    <div class="col-12 mt-3 col-xl-9 col-lg-7">
      Grant and revoke privileges to run the SQL administration API commands on the database server
    </div>
    <div class="col-12 col-xl-1 col-lg-2 mt-3 d-flex justify-content-start justify-content-xl-end justify-content-lg-end">
      <app-check-server-admin-permissions [server]="server" placement="top">
        <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
          <button type="button" class="btn btn-primary" (click)="priviligesHDR.isAllow() && adminPrivilegesModal.show()"
          appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable>Grant</button>
        </app-check-hdr-permissions>
      </app-check-server-admin-permissions>
    </div>
  </div>
  <app-data-table [data]="adminUsersInfo"
    noDataAlertMessage="No SQL Admin API privileges defined." [rowsPerPage]="10" searchLabel="Search here ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
    <app-data-table-column title="User" [sortable]="true" property="userName" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Privileges" property="privilegeTypes" [searchable]="true">
      <ng-template appTableCell let-adminUserInfo="row">
        <span>
          {{getAdminPrivilegeNames(adminUserInfo.privilegeTypes)}}</span><span>
          <app-check-server-admin-permissions [server]="server" placement="top">
            <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
              <button type="button" class="btn btn-icon btn-sm" tooltip="Modify" appHDRPermission [hdrPermission]="priviligesHDR"
                (click)="modifyAdminPrivilege(adminUserInfo)" appPermissionDisable>
                <i class="icon icon-edit"></i>
              </button>
            </app-check-hdr-permissions>
          </app-check-server-admin-permissions>
        </span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Last Modified" [sortable]="true" property="lastModified">
    </app-data-table-column>
    <app-data-table-column title="Revoke">
      <ng-template appTableCell let-adminUserInfo="row">
        <app-check-server-admin-permissions [server]="server" placement="top">
          <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
            <button type="button" class="btn btn-icon btn-sm" appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable
              (click)="revokePrivileges(adminUserInfo)">
              <i class="icon icon-trash"></i>
            </button>
          </app-check-hdr-permissions>
        </app-check-server-admin-permissions>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</ng-container>



<ng-template #userInfoLoading>
  <div *ngIf="isDataLoading else serverError;" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-4"></i>
  </div>

  <ng-template #serverError>
    <div class="mt-2 alert alert-danger" *ngIf="apiError">
      Unable to retrieve sql admin api information. <br> Error: {{apiError}}
    </div>
  </ng-template>
</ng-template>


<div bsModal #adminPrivilegesModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}"
  (onHidden)="onHidden()">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-11">
          <h5 class="modal-title pull-left" *ngIf="!isEdit">Grant Privileges</h5>
          <h5 class="modal-title pull-left" *ngIf="isEdit">Edit Privileges</h5>
        </div>
        <div>
          <button type="button" class="close" (click)="adminPrivilegesModal.hide()">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="adminPrivilegeForm" class="admin-privilege-form">
          <div class="form-group font-weight-bold">
            <label>User Name</label>
            <input type="text" class="form-control" placeholder="Enter User Name..." formControlName="userName"
              tooltip="" [customErrors]="userNameErrorHandlers" appExtendedFormControl>
          </div>
          <div class="form-group font-weight-bold">
            <label>Specify the Privileges</label>
            <select class="form-control" formControlName="selectedPrivilege">
              <option [disabled]="true" [ngValue]="null">-- Select Privileges --</option>
              <option *ngFor="let privilege of adminPrivileges" [value]="privilege?.typeName">
                {{privilege.typeName}} - {{privilege.typeDesc}}
              </option>
            </select>
          </div>
        </form>
        <app-data-table *ngIf="selectedPrivilegeControl.value === 'Custom'" [enablePagination]="false"
          #customPrivilegesDataTable [data]="customPrivileges" [enableSelect]="true" isChecked="checked"
          (updateSelectedRows)="getSelectedPrivileges($event)">
          <app-data-table-column title="Privileges" property="typeName">
          </app-data-table-column>
          <app-data-table-column title="Descriptions" property="typeDesc">
          </app-data-table-column>
        </app-data-table>
        <div align="right">
          <div class="pr-0 pt-3">
            <button type="button" class="btn btn-secondary btn-fixed-width" (click)="adminPrivilegesModal.hide()">Cancel</button>
            <button type="button" *ngIf="!isEdit" class="btn btn-primary btn-fixed-width" [disabled]="isDisable"
              (click)="addUsers()">
              Add User
            </button>
            <button type="button" *ngIf="isEdit" class="btn btn-primary btn-fixed-width" [disabled]="isDisable"
              (click)="addUsers()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
