/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartJSUtils } from '../../shared/chartjs.utils';
import { AbstractDashboardComponent } from '../monitoring/abstract-dashboard-component';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';


@Component({
  selector: 'app-dashboard-server-sessions',
  templateUrl: 'dashboard-server-sessions.html'
})
export class DashboardServerSessionsComponent extends AbstractDashboardComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() serverInfo: InformixServerStat;
  @Input() monitoringProfile: MonitoringProfile;
  @Input() viewMonitoredData: boolean;

  @ViewChild('sessionCountSensorGraph') sessionCountSensorGraph: AbstractDashboardComponent;
  @ViewChild('sessionMemorySensorGraph') sessionMemorySensorGraph: AbstractDashboardComponent;

  sessionInfo: any = null;
  isSessionSensorRunning = false;

  sessionCountChartColor = ChartJSUtils.getDefaultColor(3);

  private sessionSensorId = 'session_stats';

  private sessionSub: Subscription = null;

  constructor(
    private serverService: InformixServerService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server || changes.monitoringProfile || changes.viewMonitoredData || this.serverInfo) {
      this.refreshData();
    }
  }

  private refreshData() {
    this.checkSessionSensor();
    if (!(this.viewMonitoredData && this.isSessionSensorRunning) && this.serverInfo) {
      this.getSessionInfo();
    }
  }

  private checkSessionSensor(): boolean {
    if (!this.server || !this.server.agent || !this.server.agent.online || !this.server.agent.isConfigured || !this.monitoringProfile) {
      return false;
    }
    const sensor = this.monitoringProfile.getSensor(this.sessionSensorId);
    this.isSessionSensorRunning = sensor != null && !sensor.disabled;
    return this.isSessionSensorRunning;
  }

  private getSessionInfo() {
    this.sessionSub = this.serverService.getServerSessionSummaryInfo(this.server.id).subscribe(info => {
      this.sessionInfo = info;
    }, err => {
      console.error('Could not get session summary info', err);
    });
  }

  ngOnDestroy() {
    if (this.sessionSub) {
      this.sessionSub.unsubscribe();
    }
  }
}
