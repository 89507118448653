/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { InformixServerGroupService } from './informixServerGroup.service';
import { InformixServerGroup } from './informixServerGroup';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GroupResolver implements Resolve<InformixServerGroup> {
  constructor(private groupService: InformixServerGroupService, private router: Router, private location: Location) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): InformixServerGroup | Promise<InformixServerGroup> {
    let groupId: number = null;
    for (let i = route.pathFromRoot.length - 1; i >= 0; i--) {
      if (route.pathFromRoot[i].params.groupId) {
        groupId = parseInt(route.pathFromRoot[i].params.groupId, 10);
        break;
      }
    }

    if (groupId === null) {
      return null;
    }

    return new Promise<InformixServerGroup>(resolve => {
      this.groupService.getGroup(groupId).then(group => {
        resolve(group);
      }).catch((err: HttpErrorResponse) => {
        resolve(null);

        let redirect = 'not-found';
        if (err.status === 403) {
          redirect = 'forbidden';
        }

        this.router.navigate(['dashboard', 'groups', redirect], { skipLocationChange: true }).then(() => {
          this.location.replaceState(state.url);
        });
      });
    });
  }

}
