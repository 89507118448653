<div class="dashboard-panel-header bg-gray-20" (mousedown)="onHeaderMouseDown($event)">
  <div class="bg-warning color-white px-1" *ngIf="warningMessages.length && !errorMessages.length"
    [popover]="warningsPopover" triggers="mouseenter:mouseleave" placement="right" container="body"
    [outsideClick]="true" containerClass="popover-color">
    <i class="icon icon-warning"></i>
  </div>
  <div class="text-truncate flex-grow-1 color-black">
    {{panel.title}}
    <button class="btn btn-sm" *ngIf="!editable" (mousedown)="ignoreHeaderMouseDown = true" [popover]="panelRename"
      placement="bottom" container="body" (onShown)="onTitleEditPopoverShown()" #panelRenamePopover="bs-popover"
      [outsideClick]="true" containerClass="popover-color">
      <i class="icon icon-rename"></i>
    </button>
    <ng-template #panelRename>
      <form class="d-flex" (ngSubmit)="saveTitle()">
        <input class="form-control form-control-sm flex-grow-1" type="text" (keydown)="onTitleKeyDown($event)"
          [formControl]="titleFormControl" appFocusOnCreate [focusAndSelect]="true">
        <button type="submit" class="btn btn-sm btn-primary ml-1">
          <i class="icon icon-check"></i>
        </button>
        <button type="button" class="btn btn-sm btn-danger ml-1" (click)="panelRenamePopover.hide()">
          <i class="icon icon-times"></i>
        </button>
      </form>
    </ng-template>
  </div>
  <div *ngIf="editable" class="btn-group">
    <button class="btn btn-sm border-0 px-2" (click)="editPanel()" tooltip="Edit">
      <i class="icon icon-edit"></i>
    </button>
    <button class="btn btn-sm border-0 px-2" (click)="duplicatePanel()" tooltip="Duplicate">
      <i class="icon icon-copy"></i>
    </button>
    <button class="btn btn-sm border-0 px-2 text-danger" (click)="deletePanel()" tooltip="Delete">
      <i class="icon icon-trash"></i>
    </button>
  </div>
</div>
<div class="dashboard-panel-body" [ngClass]="{'overflow-auto': config?.chartType === 'table'}">
  <ng-container appDashboardPanelContent></ng-container>
</div>
<div *ngIf="resizable" class="dashboard-panel-resize-notch" (mousedown)="onResizeMouseDown($event)">
  <div></div>
</div>

<div class="dashboard-panel-alert-overlay bg-danger color-white p-1" *ngIf="errorMessages.length"
  [popover]="errorsPopover" triggers="mouseenter:mouseleave" placement="right" container="body" [outsideClick]="true" containerClass="popover-color">
  <i class="icon icon-2x icon-warning"></i>
</div>
<ng-template #errorsPopover>
  <p class="mb-0" *ngFor="let message of errorMessages">
    {{message}}
  </p>
</ng-template>

<ng-template #warningsPopover>
  <p class="mb-0" *ngFor="let message of warningMessages">
    {{message}}
  </p>
</ng-template>

<div *ngIf="panel.isActive()" class="dashboard-panel-active-overlay"
  [ngClass]="{ 'dashboard-panel-move': panel.isMoving }"></div>
  