/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2020. All Rights Reserved.
 *******************************************************************************/
export const DBSPACE_PERCENT_FREE_THRESHOLD = 5;

export interface ServerSpace {
  dbsnum: number;
  name: string;
  type: string;
  status: string;
  size: number;
  page_size: number;
  percent_used: number;
  expandable: number;
  create_size: number;
  extend_size: number;
  last_backup: number;
  isChecked?: boolean;
  nullPartition?: boolean;
  columnValues?: any;
  remainderPartition?: boolean;
}
