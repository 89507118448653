/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SharedModule } from '../../../shared/shared.module';
import { InformixServerOnconfigService } from './informixServerOnconfig.service';
import { OnConfigInputComponent } from './onconfig-input.component';
import { OnconfigComponent } from './onconfig.component';
import { OnconfigPageComponent } from './onconfig-page/onconfig-page.component';

@NgModule({
  imports: [
    SharedModule,
    AlertModule,
    CommonModule,
    BsDropdownModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule
  ],
  exports: [OnconfigComponent],
  declarations: [
    OnconfigComponent,
    OnConfigInputComponent,
    OnconfigPageComponent
  ],
  providers: [InformixServerOnconfigService],
})
export class OnconfigModule { }
