/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';
import { SchemaService } from '../../schema.service';
import { DataFile, Table } from '../create-table.model';
import { IACValidators } from '../../../../../shared/forms/validators';
import { ErrorMessageHandlers } from '../../../../../shared/forms/extendedFormControl.directive';
import { InformixSQLSession } from '../../informix-sql-session';

@Component({
  selector: 'app-external-options',
  templateUrl: './external-options.component.html',
  styles: [`.select-editable {position:relative;}
.select-editable select {position:absolute; top:0px; left:-1px; font-size:14px; margin:0;}
.select-editable input {position:absolute; top:0px; left:0px; border: none; width: 85%}
.select-editable select:focus, .select-editable input:focus {outline:none;}`]
})

export class ExternalOptionsComponent implements OnInit {
  @Input() session: InformixSQLSession;
  @Input() dataObj: Table;
  @Output() advTableObj = new EventEmitter<any>();
  @Output() backToCreateTable = new EventEmitter<string>();
  @ViewChild('addFilesModal') addFilesModal: ModalDirective;

  advTableForm: UntypedFormGroup;
  fieldDelimiter: UntypedFormControl;
  recordDelimiter: UntypedFormControl;
  isEscapeChar: UntypedFormControl;
  dateFormat: UntypedFormControl;
  moneyFormat: UntypedFormControl;
  noOfRows: UntypedFormControl;
  maxError: UntypedFormControl;
  rejectFileName: UntypedFormControl;

  addDataFileForm: UntypedFormGroup;
  dataFileType: UntypedFormControl;
  dataFileName: UntypedFormControl;
  blobDirectory: UntypedFormControl;
  clobDirectory: UntypedFormControl;

  dataFiles: DataFile[];
  id = 0;
  editId: number;
  isEdit = false;

  externalObj;

  isViewQuery = false;
  viewQueryObj;
  fileNameErrorHandlers: ErrorMessageHandlers = {
    duplicateName: error => 'Key name already exists.',
    whitespace: error => 'Input field cannot be empty'
  };
  constructor(
    private fb: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService,
    private notificationsService: NotificationsService,
    private schemaService: SchemaService) { }

  ngOnInit() {
    this.fieldDelimiter = new UntypedFormControl(this.dataObj && this.dataObj['fieldDelimiter'] ? this.dataObj['fieldDelimiter'] : null);
    this.recordDelimiter = new UntypedFormControl(this.dataObj && this.dataObj['recordDelimiter'] ? this.dataObj['recordDelimiter'] : null);
    this.isEscapeChar = new UntypedFormControl(this.dataObj && this.dataObj['isEscapeCharacter'] ?
                                               this.dataObj['isEscapeCharacter'] : null);
    this.dateFormat = new UntypedFormControl(this.dataObj && this.dataObj['dateFormat'] ? this.dataObj['dateFormat'] : 'MDY4/');
    this.moneyFormat = new UntypedFormControl(this.dataObj && this.dataObj['moneyFormat'] ? this.dataObj['moneyFormat'] : null);
    this.noOfRows = new UntypedFormControl(this.dataObj && this.dataObj['noOfRows'] ? this.dataObj['noOfRows'] : '50',
      [Validators.pattern(RegExp('\d*[1-9]\d*'))]);
    this.maxError = new UntypedFormControl(this.dataObj && this.dataObj['maximumErrors'] ? this.dataObj['maximumErrors'] : '50',
      [Validators.pattern(RegExp('\d*[1-9]\d*'))]);
    this.rejectFileName = new UntypedFormControl(this.dataObj && this.dataObj['rejectFile'] ? this.dataObj['rejectFile'] : null);
    this.advTableForm = this.fb.group({
      fieldDelimiter: this.fieldDelimiter,
      recordDelimiter: this.recordDelimiter,
      isEscapeChar: this.isEscapeChar,
      dateFormat: this.dateFormat,
      moneyFormat: this.moneyFormat,
      noOfRows: this.noOfRows,
      maxError: this.maxError,
      rejectFileName: this.rejectFileName
    });

    this.dataFileType = new UntypedFormControl('disk');
    this.dataFileName = new UntypedFormControl(null, [Validators.required, IACValidators.noWhitespaceValidator()]);
    this.blobDirectory = new UntypedFormControl(null);
    this.clobDirectory = new UntypedFormControl(null);
    this.addDataFileForm = this.fb.group({
      dataFileType: this.dataFileType,
      dataFileName: this.dataFileName,
      blobDirectory: this.blobDirectory,
      clobDirectory: this.clobDirectory
    });
    this.dataFiles = this.dataObj && this.dataObj['dataFiles'] && this.dataObj['dataFiles'].length > 0 ?
      this.dataObj['dataFiles'].slice() : [];
  }

  openModel() {
    this.isEdit = false;
    this.dataFileType.setValue('disk');
    this.addFilesModal.show();
  }

  addDataFile() {
    if (this.isEdit) {
      this.dataFiles.map(row => {
        if (row.id === this.editId) {
          row.dataFileType = this.dataFileType.value;
          row.dataFileName = this.dataFileName.value;
          row.blobDirectory = this.blobDirectory.value;
          row.clobDirectory = this.clobDirectory.value;
          return row;
        }
      });
      this.closeModal();
    } else {
      this.id += 1;
      const dataFile: DataFile = { id: this.id, dataFileType: this.dataFileType.value, dataFileName: this.dataFileName.value };
      if (this.blobDirectory.value) {
        dataFile['blobDirectory'] = this.blobDirectory.value;
      }
      if (this.clobDirectory.value) {
        dataFile['clobDirectory'] = this.clobDirectory.value;
      }
      this.dataFiles.push(dataFile);
      setTimeout(() => {
        this.dataFiles = this.dataFiles.slice();
      }, 0);
      this.addDataFileForm.reset({ dataFileType: 'disk' });
    }
  }

  editDataFile(row: any) {
    this.isEdit = true;
    this.editId = row.id;
    this.addFilesModal.show();
    this.dataFileType.setValue(row.dataFileType);
    this.dataFileName.setValue(row.dataFileName);
    this.blobDirectory.setValue(row.blobDirectory);
    this.clobDirectory.setValue(row.clobDirectory);
  }

  deleteDataFile(id: number) {
    this.confirmationDialogService.show('Delete Column', 'continue?', () => {
      this.dataFiles = this.dataFiles.filter(row => row.id !== id);
    });
  }

  closeModal() {
    this.addDataFileForm.reset({ dataFileType: 'disk' });
    this.addFilesModal.hide();
  }

  getFinalObj() {
    this.externalObj = {
      dateFormat: this.dateFormat.value,
      moneyFormat: this.moneyFormat.value,
      noOfRows: this.noOfRows.value,
      maximumErrors: this.maxError.value,
      rejectFile: this.rejectFileName.value ? this.rejectFileName.value : '',
      dataFiles: this.dataFiles
    };

    if (this.dataObj['tableType'] === 'externaldelimited') {
      this.externalObj['fieldDelimiter'] = this.fieldDelimiter.value;
      this.externalObj['recordDelimiter'] = this.recordDelimiter.value;
      this.externalObj['isEscapeCharacter'] = this.isEscapeChar.value;
    }

    for (const key in this.externalObj) {
      if (this.externalObj.hasOwnProperty(key)) {
        this.dataObj[key] = this.externalObj[key];
      }
    }
    this.dataObj['isNewLine'] = true;

    if (this.dataObj['columnsList']) {
      delete this.dataObj['columnsList'];
    }
  }

  viewQuery() {
    this.getFinalObj();

    this.schemaService.createTable(this.session, this.dataObj).subscribe((res) => {
      this.viewQueryObj = res;
      this.isViewQuery = true;
    },
      err => {
        this.isViewQuery = false;
        this.notificationsService.pushErrorNotification(err);
      });
  }

  backFromQuery(event: string) {
    if (event === 'cancel') {
      this.backToCreateTable.emit('cancel');
    } else {
      this.isViewQuery = false;
      if (this.dataObj['isExecute']) {
        this.dataObj['isExecute'] = false;
      }
    }
  }

  backToTable(type: string) {
    if (type === 'back') {
      this.getFinalObj();
      this.advTableObj.emit(this.externalObj);
      this.backToCreateTable.emit('back');
    } else {
      this.backToCreateTable.emit('cancel');
    }
  }

  setDateFormat(event) {
    this.dateFormat.setValue(event.target.value);
  }
}
