/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2020. All Rights Reserved.
 *******************************************************************************/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardTimeInterval } from '../dashboard-time-interval';
import { DashboardService } from '../dashboard.service';
import { DashboardComponent } from '../dashboard/dashboard.component';

export interface IntervalOption {
  name: string;
  seconds: number;
}

@Component({
  selector: 'app-dashboard-time-control',
  templateUrl: './dashboard-time-control.component.html',
  styleUrls: ['./dashboard-time-control.component.scss']
})
export class DashboardTimeControlComponent implements OnInit, OnDestroy {

  interval: DashboardTimeInterval = null;
  selectedOption: IntervalOption = null;
  dateFormat = 'short';

  intervalOptions: IntervalOption[] = [
    { seconds: 300, name: 'last 5 minutes' },
    { seconds: 3600, name: 'last hour' },
    { seconds: 14400, name: 'last 4 hours' },
    { seconds: 24 * 3600, name: 'last day' },
    { seconds: 7 * 24 * 3600, name: 'last week' },
    { seconds: 30 * 24 * 3600, name: 'last month' },
    { seconds: 365 * 24 * 3600, name: 'last year' },
    { seconds: 737841 * 24 * 3600, name: 'all time' }
  ];

  private intervalSub: Subscription = null;
  isBtnDisabled = false;
  currentTimeslice: IntervalOption = null;
  previousTimeslice: IntervalOption = null;
  checkPermissions = false;

  constructor(
    private dashboardService: DashboardService,
    private dashboardComponent: DashboardComponent
  ) { }

  ngOnInit() {
    if (this.dashboardComponent.dashboard.id !== undefined && this.dashboardService.getSelectedIntervalOption() !== undefined) {
      this.selectIntervalOption(this.dashboardService.getSelectedIntervalOption());
    } else {
      this.selectIntervalOption(this.intervalOptions[2]);
    }
    this.checkPermissions = this.dashboardComponent.group.permissions.admin;
    if (!this.previousTimeslice && !this.currentTimeslice) {
      this.isBtnDisabled = true;
      this.previousTimeslice = this.dashboardService.getSelectedIntervalOption();
      this.currentTimeslice = this.previousTimeslice;
    }
    this.intervalSub = this.dashboardService.timeIntervalChanged.subscribe(interval => {
      if (this.interval !== interval) {
        this.interval = interval;
        const selectedIntervalOption = this.intervalOptions.filter(intervalOption =>
          intervalOption.seconds === ((interval.getEndTimestamp() - interval.getStartTimestamp()) / 1000))[0];
        this.selectedOption = selectedIntervalOption ? selectedIntervalOption : null;
        this.updateDateFormat();
      }
    });
  }

  ngOnDestroy() {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe();
    }
  }

  selectIntervalOption(option: IntervalOption) {
    if (this.selectedOption === option) {
      return;
    }

    this.selectedOption = option;
    this.dashboardService.setSelectedIntervalOption(option);
    this.setInterval(DashboardTimeInterval.relativeFromNow(option.seconds * -1000));
  }

  buttonDisabled() {
    this.currentTimeslice = this.selectedOption;
    if ((!this.previousTimeslice && this.currentTimeslice) || (this.previousTimeslice.seconds !== this.currentTimeslice.seconds)) {
      this.isBtnDisabled = false;
    } else {
      this.isBtnDisabled = true;
    }
  }

  saveTime() {
    if (!this.previousTimeslice && !this.currentTimeslice) {
      this.currentTimeslice = this.selectedOption;
    }
    this.previousTimeslice = this.currentTimeslice;
    this.dashboardComponent.saveTimeslice();
    this.isBtnDisabled = true;
  }

  shiftBackward() {
    this.selectedOption = null;
    this.setInterval(
      DashboardTimeInterval.relative(this.interval.getStartTimestamp(), this.interval.getStartTimestamp() - this.interval.getEndTimestamp())
    );
  }

  shiftForward() {
    this.selectedOption = null;
    this.setInterval(
      DashboardTimeInterval.relative(this.interval.getEndTimestamp(), this.interval.getEndTimestamp() - this.interval.getStartTimestamp())
    );
  }

  private setInterval(interval: DashboardTimeInterval) {
    this.interval = interval;
    this.updateDateFormat();
    this.dashboardService.setTimeInterval(this.interval);
  }

  private updateDateFormat() {
    this.dateFormat = this.interval.isSameDate() ? 'shortTime' : 'short';
  }
}
