/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';

import { ChartJSUtils } from '../../shared/chartjs.utils';
import { BytePipe } from '../../shared/pipes/byte.pipe';
import { AbstractDashboardComponent } from '../monitoring/abstract-dashboard-component';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { InformixServer, InformixServerStat } from './informixServer';
import { InformixServerService } from './informixServer.service';

@Component({
  selector: 'app-dashboard-server-host',
  templateUrl: 'dashboard-server-host.html'
})
export class DashboardServerHostComponent extends AbstractDashboardComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() serverInfo: InformixServerStat;
  @Input() monitoringProfile: MonitoringProfile;
  @Input() viewMonitoredData: boolean;

  memorySensorRunning = false;
  cpuSensorRunning = false;

  private memoryInfo: any;
  private usedMemory: number[];
  private freeMemory: number[];
  private chart: Chart;

  private requestSub: Subscription = null;

  @ViewChild('osMemoryBarChart') osMemoryBarChartElement: ElementRef;
  @ViewChild('osMemorySensorGraph') osMemorySensorGraph: AbstractDashboardComponent;
  @ViewChild('osCPUSensorGraph') osCPUSensorGraph: AbstractDashboardComponent;

  constructor(private serversService: InformixServerService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server || changes.monitoringProfile) {
      this.checkIfSensorsAreRunning();
    }
    if (changes.serverInfo || changes.viewMonitoredData) {
      if (changes.viewMonitoredData && this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      if (!this.viewMonitoredData || !this.memorySensorRunning) {
        this.getServerMemoryInfo();
      }
    }
  }

  private getServerMemoryInfo() {
    if (!this.serverInfo) {
      return;
    }
    this.requestSub = this.serversService.getServerMemory(this.server.id).subscribe(memoryInfo => {
      this.memoryInfo = memoryInfo;
      this.updateMemoryGraphData();
      this.updateMemoryGraphChart();
    }, err => {
      console.error('Could not get server memory info', err);
    });

  }

  private checkIfSensorsAreRunning() {
    this.memorySensorRunning = this.isSensorRunning('os_memory');
    this.cpuSensorRunning = this.isSensorRunning('os_cpu');
  }

  private isSensorRunning(sensorId: string): boolean {
    if (!this.server || !this.server.agent || !this.server.agent.online || !this.server.agent.isConfigured || !this.monitoringProfile) {
      return false;
    }
    const sensor = this.monitoringProfile.getSensor(sensorId);
    return sensor != null && !sensor.disabled;
  }

  private updateMemoryGraphData() {
    if (!this.usedMemory) {
      this.usedMemory = [0, 0];
    }
    if (!this.freeMemory) {
      this.freeMemory = [0, 0];
    }
    this.usedMemory[0] = this.memoryInfo.used_mem;
    this.usedMemory[1] = this.memoryInfo.os_mem_used;
    this.freeMemory[0] = this.memoryInfo.total_mem - this.memoryInfo.used_mem;
    this.freeMemory[1] = this.memoryInfo.os_mem_free;
  }

  private updateMemoryGraphChart() {
    // If chart has already been created it, update it.
    if (this.chart) {
      this.chart.update();
      return;
    }

    // Otherwise we need to create it
    const labels = ['Database Shared Memory', 'OS Memory'];
    const bytePipe = new BytePipe();

    if(this.osMemoryBarChartElement) {
      this.chart = new Chart(this.osMemoryBarChartElement.nativeElement, {
        type: 'horizontalBar',
        data: {
          labels,
          datasets: [
            {
              label: 'Used',
              data: this.usedMemory,
              backgroundColor: ChartJSUtils.getDefaultColor(0) // '#E4A02A',
            },
            {
              label: 'Free',
              data: this.freeMemory,
              backgroundColor: '#F6F6F6'
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
                beforeBuildTicks: ChartJSUtils.byteAxisBeforeBuildTicks,
                stacked: true,
                ticks: {
                  maxRotation: 0,
                  callback: (value: any) => bytePipe.transform(value),
                  padding: 10
                }
              }
            ],
            yAxes: [
              {
                barThickness: 35,
                stacked: true,
                gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
                ticks: {
                  padding: 10
                }
              }
            ]
          },
          legend: {
            display: false,
            position: 'top'
          },
          tooltips: {
            mode: 'nearest',
            callbacks: {
              title: (tooltips: any, data: any) => {
                const tooltip = tooltips[0];
                return data.labels[tooltip.index] + ' ' + data.datasets[tooltip.datasetIndex].label;
              },
              label: (tooltip: any, data: any) => {
                const value: number = data.datasets[tooltip.datasetIndex].data[tooltip.index];
                return bytePipe.transform(value) + ' (' + ((value / this.memoryInfo.os_mem_total) * 100).toFixed(2) + '%)';
              }
            }
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.requestSub) {
      this.requestSub.unsubscribe();
    }
  }
}
