/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InformixServer } from '../../../informixServer';
import { BreadcrumbElement } from '../../../../../shared/breadcrumb.component';
import { ServerBreadcrumb } from '../../../serverBreadcrumb';
import { EnterpriseReplicationService } from '../../enterprise-replication.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { statusMap } from '../../replicate';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html'
})
export class TaskDetailsComponent implements OnInit {

  @ViewChild('errorModal') errorModal: ModalDirective;

  breadcrumb: BreadcrumbElement[] = [];
  server: InformixServer = null;
  isLoading: Boolean = false;
  isStatLoading: Boolean = false;
  taskError: string = null;
  taskErrorReason: string = null;
  status: String;
  color: String;
  master: String;
  taskDetails;
  participants;
  taskId;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.setServer(data.server);
    });
    this.getTaskId();
  }

  getTaskId(){
     //  Get replcheck_id from query-param
     this.route.queryParamMap.subscribe(queryParam => {
      if (queryParam.has('replcheck_id')) {
        this.taskId =  parseInt(queryParam.get('replcheck_id').toString(), 10);
        this.getJobsData(this.taskId);
      } else if (queryParam.has('taskId')) {
        this.taskId =  parseInt(queryParam.get('taskId').toString(), 10);
        this.getJobsData(false);
      }
    });
  }


  setServer(server: InformixServer) {
    this.server = server;

    this.breadcrumb = ServerBreadcrumb.build(server, [
      { name: 'Replication' },
      { name: 'Replicate task details' }
    ]);
  }

 getJobsData(taskId) {
    let taskDetails;
    let requestBody;
    this.isLoading = true;

    this.erService.getJobs(this.server.id).subscribe(res => {
      if (taskId) {
        taskDetails = res.filter(task => task.replcheck_id === parseInt(this.taskId, 10));
        this.taskDetails = taskDetails[0];
        if (this.taskDetails?.cmdNumber) {
          requestBody = {
            cmdNumber: this.taskDetails.cmdNumber
          };
        } else {
          requestBody = {
            jobName: this.taskDetails.name,
            jobType: this.taskDetails.type.toLowerCase(),
          };
        }
        this.isStatLoading = true;
        this.erService.getJob(this.server.id, requestBody).subscribe(stats => {
          if (stats && stats.cmd_ret_status) {
            this.taskError = stats.cmd_ret_msg;
          } else {
            this.participants = stats.participants;
            this.master = stats.master;
            this.taskError = null;
          }
          this.isStatLoading = false;
        });
      } else {
        taskDetails = res.filter(task => task.jsTask === parseInt(this.taskId, 10));
        this.taskDetails = taskDetails[0];
        this.participants = [];
      }
      this.status = statusMap[this.taskDetails?.status].name;
      this.color = statusMap[this.taskDetails?.status].color;
      this.isLoading = false;
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  back() {
    const queryParams = { replicate: this.taskDetails.replicate };
    this.router.navigate(['/dashboard', 'servers', this.server.id, 'replicate', 'check-sync'], { queryParams });
  }

  getErrorDetails() {
    this.erService.getJobReason(this.server.id, this.taskDetails.cmdNumber).subscribe(res => {
      if(res.result_message) {
        this.taskErrorReason = res.result_message;
      } else {
        this.taskErrorReason = 'No Reason found in database';
      }
      this.errorModal.show();
    });
  }
}
