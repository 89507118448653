/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { InformixServer } from '../informixServer';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { ConnectionUnit, FOCInfo, SLAInfo } from './connection-manager.model';
import { ConnectionManagerService } from './connection-manager.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-connection-unit',
  templateUrl: 'connection-unit.html'
})
export class ConnectionUnitComponent implements OnInit {
  server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;

  cmName: string;
  cmUnitName: string;
  cmUnitInfo: ConnectionUnit;
  cmUnitError: string;

  focPriority: string;
  orderValue: string;
  focDetailsForm: UntypedFormGroup;
  timeout: UntypedFormControl;
  order: UntypedFormControl;

  slaToEdit: string = null;

  dataLoading = false;
  saveInProgress = false;
  showSLAForm = false;
  isEdit = false;

  constructor(private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private connectionManagerService: ConnectionManagerService,
    private confirmationDialogService: ConfirmationDialogService,
    private notificationService: NotificationsService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      this.route.data.subscribe(data => {
        this.loadServer(data.server, param.cmName, param.cmUnitName);
        this.cmName = param.cmName;
        this.cmUnitName = param.cmUnitName;
        this.refreshCMUnitInfo();
      });
    });
  }

  refreshCMUnitInfo() {
    this.dataLoading = true;
    this.cmUnitInfo = null;
    this.connectionManagerService.getConnectionUnitInfo(this.server.id, this.cmName, this.cmUnitName).subscribe(cmUnitInfo => {
      this.dataLoading = false;
      this.cmUnitInfo = cmUnitInfo;
      this.timeout = new UntypedFormControl();
      this.order = new UntypedFormControl();
      if (cmUnitInfo.type === 'CLUSTER') {
        const timeoutValue = this.getFocDetail('TIMEOUT');
        this.orderValue = this.getFocDetail('ORDER') ? this.getFocDetail('ORDER').split('=')[1] : '0';
        this.focPriority = this.getFocDetail('PRIORITY') ? this.getFocDetail('PRIORITY').split('=')[1] : '0';
        this.order.setValue(this.orderValue ? (this.orderValue === 'DISABLED' ? false : true) : null);
        this.timeout.setValue(timeoutValue ? timeoutValue.split('=')[1] : 0);
        this.timeout.setValidators([Validators.required]);
        this.order.disable();
        this.timeout.disable();
      }
      this.focDetailsForm = this.formBuilder.group({
        timeout: this.timeout,
        order: this.order
      });
    }, err => {
      this.dataLoading = false;
      this.cmUnitError = err.error ? err.error.err : err;
    });
  }

  private loadServer(server: InformixServer, cmName: string, cmUnitName: string) {
    this.server = server;
    this.translate.get(['breadcrumb.replication.title', 'connectionManager.pageTitle'])
      .subscribe(breadcrumbStrings => {
        const connectionManagerBreadcrumb: BreadcrumbElement = {
          name: breadcrumbStrings['connectionManager.pageTitle'],
          link: 'cm'
        };
        this.breadcrumb = ServerBreadcrumb.build(this.server,
          [{ name: breadcrumbStrings['breadcrumb.replication.title'] },
            connectionManagerBreadcrumb, { name: cmName }, { name: cmUnitName }]);
      });
  }

  getFocDetail(value: string) {
    return this.cmUnitInfo.foc.split(' ').filter(foc => foc.indexOf(value) !== -1)[0];
  }

  editFOC() {
    this.isEdit = true;
    this.order.enable();
    this.timeout.enable();
  }

  saveFOC() {
    const focInfo: FOCInfo = {
      cmName: this.cmName,
      cmUnitName: this.cmUnitInfo.cmUnitName,
      type: this.cmUnitInfo.type,
      timeout: this.timeout.value,
      order: this.order.value ? 'ENABLED' : 'DISABLED'
    };
    this.saveInProgress = true;
    this.connectionManagerService.updateFOC(this.server.id, focInfo).subscribe(() => {
      this.saveInProgress = false;
      this.cancelEdit();
      this.refresh();
      this.translate.get('connectionManager.successMsg.updateFOCSuccess').subscribe((text: string) => {
        this.notificationService.pushSuccessNotification(text);
      });
    }, err => {
      this.saveInProgress = false;
      this.translate.get('connectionManager.errorMsg.updateFOCError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationService.pushErrorNotification(text);
        });
    });
  }

  cancelEdit() {
    this.isEdit = false;
    this.order.disable();
    this.timeout.disable();
  }

  addSLA() {
    this.slaToEdit = null;
    this.showSLAForm = true;
  }

  deleteSLA(slaInfo: SLAInfo) {
    this.confirmationDialogService.show('delete this SLA?', () => {
      this.connectionManagerService.deleteSLA(this.server.id, slaInfo).subscribe(() => {
        this.cmUnitInfo.slaInfoList = this.cmUnitInfo.slaInfoList.filter(sla => sla.slaName !== slaInfo.slaName);
        this.translate.get('connectionManager.successMsg.deleteSLASuccess', { name: slaInfo.slaName }).subscribe((text: string) => {
          this.notificationService.pushSuccessNotification(text);
        });
      }, err => {
        this.translate.get('connectionManager.errorMsg.deleteSLAError', { suffix: err.error ? err.error.err : err })
          .subscribe((text: string) => {
            this.notificationService.pushErrorNotification(text);
          });
      }
      );
    });
  }

  editSLA(slaName: string) {
    this.slaToEdit = slaName;
    this.showSLAForm = true;
  }

  closeSLA(event: boolean) {
    this.showSLAForm = false;
    if (event === true) {
      this.refresh();
    }
  }

  refresh() {
    this.dataLoading = true;
    this.cmUnitInfo = null;
    setTimeout(() => {
      this.refreshCMUnitInfo();
    }, 5000);
  }
}
