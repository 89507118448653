<ng-container *ngIf="!isAdvOptions && !isViewQuery">
  <h5 class="mb-0">{{'schemaManager.createTable.label.primaryKey' | translate}}</h5>
  <div class="row pt-3">
    <div class="col-auto">
      <label class="font-weight-bold"><span class="text-danger">*
        </span>{{'schemaManager.createTable.label.primaryKeyName' | translate}}</label>
      <input class="form-control" type="text"
        [placeholder]="'schemaManager.createTable.placeholder.primaryKeyName' | translate" tooltip=""
        [(ngModel)]="primaryKeyName">
    </div>
  </div>
  <div class="row col-xl-7 col-lg-8 col-12 pt-3">
    <div class="col-xl-5 col-lg-5 col-md-5 col-10 px-0">
      <app-data-table [data]="allColumns" [enableSelect]="true" [enableSearch]="false" [enablePagination]="false"
        [noDataAlertMessage]="primaryColumns.length > 0 ? ('schemaManager.createTable.errorMsg.noMoreColumnsAvailable' | translate):
         ('schemaManager.createTable.errorMsg.noColumnsAvailable' | translate)" isChecked="checked"
        (updateSelectedRows)="getSelectedColumns($event)" [dataMapFn]="allColsDataMap" [minHeight]="200"
        [title]="'schemaManager.createTable.tooltips.primaryKey' | translate">
        <app-data-table-column property="name"
          [title]="'schemaManager.createTable.table.columnTitle.availableColumns' | translate"></app-data-table-column>
      </app-data-table>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-2 px-0 text-center my-auto"
      *ngIf="allColumns.length > 0 || primaryColumns.length > 0">
      <button class="btn btn-sm btn-icon" [disabled]="isAddDisable" (click)="addPrimaryKey()">
        <i class="icon font-weight-bold icon-chevron-right"></i></button> <br />
      <button class="btn btn-sm btn-icon" [disabled]="isRemoveDisable" (click)="removePrimaryKey()"> <i
          class="icon font-weight-bold icon-chevron-left"></i></button>
    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 col-10 pt-xl-0 pt-lg-0 pt-md-0 pt-3 pl-0"
      *ngIf="allColumns.length > 0 || primaryColumns.length > 0">
      <app-data-table [data]="primaryColumns" [enableSelect]="true" [enableSearch]="false" [enablePagination]="false"
        [noDataAlertMessage]="'schemaManager.createTable.errorMsg.noPrimaryKeyColumnsAdded' | translate"
        isChecked="checked" (updateSelectedRows)="getPrimaryColumns($event)" [dataMapFn]="allPrimaryColsDataMap"
        [minHeight]="200">
        <app-data-table-column property="name"
          [title]="'schemaManager.createTable.table.columnTitle.primaryKeyCols' | translate"></app-data-table-column>
      </app-data-table>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-2 text-center my-auto" *ngIf="primaryColumns.length > 0">
      <button type="button" class="btn btn-sm btn-icon" (click)="moveRows('upward')" [disabled]="isUpwardDisable">
        <i class="icon icon-arrow-up cursor-pointer font-weight-bold" [tooltip]="'button.moveUp' | translate"></i>
      </button><br />
      <button type="button" class="btn btn-sm btn-icon mt-1" (click)="moveRows('downward')"
        [disabled]="isDownwardDisable">
        <i class="icon icon-arrow-down cursor-pointer font-weight-bold" [tooltip]="'button.moveDown' | translate"></i>
      </button>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col-lg-6 col-md-6 col-12">
      <div class="row align-items-center">
        <div class="col-12">
          <h5 class="d-inline-block mb-0 pt-2">{{'schemaManager.createTable.label.foreignKey' | translate}}</h5> <button
            class="float-right btn btn-primary" (click)="addForeignKey()">{{'button.add' | translate}}</button>
        </div>
      </div>
      <div class="pt-2">
        <app-data-table [data]="foreignKeys" *ngIf="foreignKeys" [rowsPerPage]="20" [minHeight]="220"
          [noDataAlertMessage]="'schemaManager.createTable.errorMsg.noForeignKeyDefined' | translate">
          <app-data-table-column [title]="'common.name' | translate" property="foreignKeyName"> </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.referenceTable' | translate"
            property="referencedTableName"> </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.mappedCol' | translate">
            <ng-template appTableCell let-row="row">
              <div *ngFor="let item of row.foreignKeyReferences | keyvalue"> {{item.key + ' -> ' + item.value}}</div>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column class="text-right">
            <ng-template appTableCell let-row="row">
              <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.edit' | translate" (click)="editForeignKey(row)">
                <i class="icon icon-edit"></i> </button>
              <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.delete' | translate"
                (click)="deleteForeignKey(row)"> <i class="icon icon-trash"></i> </button>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-12">
      <div class="row align-items-center">
        <div class="col-12">
          <h5 class="d-inline-block mb-0 pt-2">{{'schemaManager.createTable.label.uniqueKey' | translate}}</h5> <button
            type="button" class="float-right btn btn-primary" (click)="addUniqueKey()">{{'button.add' |
            translate}}</button>
        </div>
      </div>
      <div class="pt-2">
        <app-data-table [data]="uniqueKeys" *ngIf="uniqueKeys" [rowsPerPage]="20" [minHeight]="220"
          [noDataAlertMessage]="'schemaManager.createTable.errorMsg.noUniqueKeyDefined' | translate">
          <app-data-table-column [title]="'common.name' | translate" property="uniqueKeyName"> </app-data-table-column>
          <app-data-table-column [title]="'table.columns' | translate">
            <ng-template appTableCell let-row="row">
              {{row.uniquekeyColumnName.toString()}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column class="text-right">
            <ng-template appTableCell let-row="row">
              <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.edit' | translate" (click)="editUniqueKey(row)"><i
                  class="icon icon-edit"></i></button>
              <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.delete' | translate"
                (click)="deleteUniqueKey(row)"><i class="icon icon-trash"></i></button>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-12 text-left">
      <button type="button" class="mr-2 btn btn-secondary" (click)="backToTable('back')">
        {{'button.back' | translate}}</button>
      <button type="button" class="btn  btn-secondary mr-2" (click)="viewTableAdvOptions()"
        [disabled]="primaryColumns.length > 0 && !(primaryKeyName && primaryKeyName.trim())">
        {{'schemaManager.createTable.button.advTableOptions' | translate}} </button>
      <button type="button" class="btn btn-primary mr-2" (click)="viewQuery()"
        [disabled]="primaryColumns.length > 0 && !(primaryKeyName && primaryKeyName.trim())">
        {{'schemaManager.createTable.button.viewSQL' | translate}}</button>
      <button type="button" class="mr-2 btn btn-secondary" (click)="backToTable('cancel')">
        {{'button.cancel' | translate}}</button>
    </div>
  </div>
</ng-container>

<app-table-adv-options *ngIf="isAdvOptions" (advTableObj)="getAdvTableObj($event)" [dataObj]="dataObj"
  [session]="session" [queryError]="queryError" (backToConstraint)="backToConstraint($event)">
</app-table-adv-options>

<app-create-table-query *ngIf="isViewQuery" (backFromQuery)="backToConstraint($event)" [viewQueryObj]="viewQueryObj"
  [backToAdvOptions]="backToAdvOptions" [dataObj]="dataObj" [session]="session"></app-create-table-query>

<app-unique-modal (uniqueKeyObj)="getUniqueKeyObj($event)" [uniqueKeys]="uniqueKeys"></app-unique-modal>
<app-foreign-key-modal (foreignKeyObj)="getForeignKeyObj($event)" [foreignKeys]="foreignKeys"></app-foreign-key-modal>
