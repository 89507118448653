/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TooltipConfig  } from 'ngx-bootstrap/tooltip';
import { AlertModule  } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './shared/http/http-interceptors';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

export function tooltipConfigFactory() {
  return Object.assign(new TooltipConfig(), { container: 'body' });
}
declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    LoginModule,
    DashboardModule,
    SharedModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    NgxBootstrapSliderModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  declarations: [AppComponent],
  providers: [
    { provide: TooltipConfig, useFactory: tooltipConfigFactory },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
