/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InformixServerType } from '../dashboard/servers/informixServer';
import { HDRPermission } from './hdr-permission/hdr-permission';

export interface BreadcrumbElement {
  name: string;
  link?: string | Function;
}
@Component({
  selector: 'app-hdr-banner-component',
  template: `
  <div class="hdr-banner" *ngIf="showBanner">
    <div>
      <span class="server-type">
        {{hdrPermission.isSecondaryServer()?'HDR ':''}} {{ hdrPermission.getServerType() }}
        <span *ngIf="hdrPermission.fromSecondary()"> : {{ (hdrPermission.isReadOnly())? 'Read-Only': 'Updatable' }}</span>
      </span>
    </div>
  </div>
  `,
  styleUrls: ['hdr-banner.scss']
})
export class HDRBannerComponent implements OnInit, OnChanges {

  @Input() serverType: InformixServerType;
  hdrPermission: HDRPermission;
  showBanner: boolean;
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverType) {
      this.initHDRPermission();
    }
  }

  initHDRPermission() {
    this.hdrPermission = new HDRPermission(null, this.serverType);
    // Show HDR banner only for Primary, SECONDARY, SDS, RSS
    if (this.hdrPermission.isStandardServer() || this.hdrPermission.isNAServer()) {
      this.showBanner = false;
    } else {
      this.showBanner = true;
    }
  }
}
