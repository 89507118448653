<div class="er-sidebar node-info right border border-gray-20 bg-white"  [ngClass]="{'open': open}" *ngIf="!clear">
      <div class="wrapper d-flex flex-column">
        <div class="header py-2 px-3 border-bottom border-gray-20">
        Node Details (<strong> {{ selectedNode?.name }} </strong>)
        </div>
        <div class="contents flex-grow-1 p-3">
          <div [hidden]="activePanel !== PANELS.INFO">
          <ng-template #messageContainer>
            <div class="h-100 d-flex align-items-center justify-content-center flex-column">
              <ng-container *ngIf="selectedNode?.profile?.status === profileStatus.LOADING">
                <i class="icon icon-circle-notch icon-spin"></i>
                <span class="mt-2">Loading Profile... </span>
              </ng-container>
              <ng-container *ngIf="selectedNode?.profile?.status === profileStatus.NOT_AVAILABLE">
                <div class="alert alert-danger mt-2 mb-0 w-100">
                    <i class="icon icon-info icon-info-circle text-danger pr-1"></i> Profile not available. 
                </div>
              </ng-container>
              <ng-container *ngIf="selectedNode?.profile?.status === profileStatus.ERROR">
                <div class="alert alert-danger mt-2 mb-0 w-100">
                    <i class="icon icon-info icon-info-circle text-danger pr-1"></i> {{selectedNode?.profile?.error}}
                </div> 
              </ng-container>
              <ng-container *ngIf="selectedNode?.profile?.status === profileStatus.UNABLE_TO_CONNET">
                <div class="alert alert-danger mt-2 mb-0 w-100">
                    <i class="icon icon-info icon-info-circle text-danger pr-1"></i>A network error has occurred while communicating with <strong>{{selectedNode?.name}}</strong>. Please check the server status and reachability from the client.
                </div>
              </ng-container>
            </div>
          </ng-template>
          <div class="info-list" *ngIf="selectedNode?.profile?.status === profileStatus.AVAILALBE; else messageContainer;">
                  <ul class="list-group">
                    <li class="list-group-item border-gray-20 highlight bg-gray-20">DDR</li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">DDR State</span>
                        <span *ngIf="selectedNode?.profile?.ddr?.state != null;" class="strong align-right highlight">{{selectedNode?.profile?.ddr?.state}}</span>
                      </div>
                    </li>

                    <ng-container *ngIf="selectedNode?.profile?.ddr?.state != null && selectedNode?.profile?.ddr?.state !== 'uninitialized'">
                      <li class="list-group-item border-gray-20">
                        <div class="d-flex">
                          <span class="flex-1">Current</span>
                          <span class="strong align-right highlight">{{selectedNode?.profile?.ddr?.current}}</span>
                        </div>
                      </li>
                      <li class="list-group-item border-gray-20">
                        <div class="d-flex">
                          <span class="flex-1">Snoopy</span>
                          <span class="strong align-right highlight">{{selectedNode?.profile?.ddr?.snoopy}}</span>
                        </div>
                      </li>
                      <li class="list-group-item border-gray-20">
                        <div class="d-flex">
                          <span class="flex-1">Replay</span>
                          <span class="strong align-right highlight">{{selectedNode?.profile?.ddr?.replay}}</span>
                        </div>
                      </li>
                      <li class="list-group-item border-gray-20">
                        <div class="d-flex">
                          <span class="flex-1">Pages from log lag state</span>
                          <span class="strong align-right highlight">{{selectedNode?.profile?.ddr?.pagesUntilBlock}}</span>
                        </div>
                      </li>
                    </ng-container>
      
                    <li class="list-group-item border-gray-20 highlight bg-gray-20">Send Queue</li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Txn In Queue</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.sendQ?.txnInQueue}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Txn Spooled</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.sendQ?.txnSpooled}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Acts Pending</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.sendQ?.acksPending}}</span>
                      </div>
                    </li>

                    <li class="list-group-item border-gray-20 highlight bg-gray-20">Network</li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Network State</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.network?.state}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">ConnectedNodes</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.network?.numberOfConnections}} out of {{selectedNode?.profile?.network?.expectedConnections}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Msg Sent</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.network?.msgSent}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Msg Received</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.network?.msgReceived}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Throughput </span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.network?.throughput}}</span>
                      </div>
                    </li>

                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Pending Messages</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.network?.pendingMsg}}</span>
                      </div>
                    </li>
      
                    <li class="list-group-item border-gray-20 highlight bg-gray-20">Spool Disk Usage</li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Total</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.disk?.total}}</span>
                      </div>
                    </li>

                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Metadata Free</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.disk?.metadataFree}}</span>
                      </div>
                    </li>

                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Userdata Free</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.disk?.userdataFree}}</span>
                      </div>
                    </li>


                    <li class="list-group-item border-gray-20 highlight bg-gray-20">Receive Queue</li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Txn In Queue</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.receiveQ?.txnInQueue}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Txn In Pending List</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.receiveQ?.txnInPendingList}}</span>
                      </div>
                    </li>
      
                   

                    <li class="list-group-item border-gray-20 highlight bg-gray-20">Apply</li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Apply State</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.state}}</span>
                      </div>
                    </li>

                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Txn Processed</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.txnProcessed}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Commit Rate</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.commitRate}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Avg. Active Apply</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.avgActiveApply}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Fail Rate</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.failRate}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Total Failure</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.totalFailures}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Avg Latency</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.avgLatency}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">Max Latency</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.maxLatency}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">ATS File Count</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.atsFileCount}}</span>
                      </div>
                    </li>
                    <li class="list-group-item border-gray-20">
                      <div class="d-flex">
                        <span class="flex-1">RIS File Count</span>
                        <span class="strong align-right highlight">{{selectedNode?.profile?.apply?.risFileCount}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
          </div>
          <div [hidden]="activePanel !== PANELS.ADVANCED">
            <app-er-advanced-options [modifyMode]="true" [selectedNode]="selectedNode" [selections]="advancedOptions" [APICalls]="APICalls"></app-er-advanced-options>
          </div>
        </div>
        <div class="footer d-flex justify-content-end px-3 py-2 border-top border-gray-20">
        <div class="d-flex flex-grow-1" *ngIf="selectedNode?.profile?.status !== profileStatus.UNABLE_TO_CONNET">
          <div [tooltip]="!selectedNode?.children.length?'': selectedNode.name + ' can not be deleted. Other group(s) are synced with it'" *ngIf="activePanel === PANELS.INFO">
              <button class="btn btn-secondary" [disabled]="selectedNode?.children?.length" (click)="showConfirmation()" appPermissionDisable>Delete</button>
          </div>
        </div>
          <div *ngIf="activePanel === PANELS.INFO">
            <button class="btn btn-secondary" (click)="close()">Close</button>
            <app-check-server-admin-permissions [server]="server">
              <button *ngIf="selectedNode?.profile?.status !== profileStatus.UNABLE_TO_CONNET" class="btn btn-primary" (click)="activePanel = PANELS.ADVANCED" appPermissionDisable>More Options</button>
            </app-check-server-admin-permissions>
          </div>
          <div *ngIf="activePanel === PANELS.ADVANCED">
            <button class="btn btn-secondary" (click)="activePanel = PANELS.INFO" *ngIf="!APICalls?.putOptions?.pending">Back</button>
            <button *ngIf="!APICalls?.fetchOptions?.pending" class="btn btn-primary" (click)="modifyAdvancedOptions();" [disabled]="APICalls?.putOptions?.pending || !isSaveValid()">Save</button>
          </div>
        </div>
      </div>
  </div>
  <div class="backdrop right-panel" [ngClass]="{'active': open === true}" (click)="close()"></div>

  <ng-template #confirmationModal>
        <div class="modal-header">
            <h5 class="modal-title text-truncate">
                Delete node
            </h5>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
            <div class="pb-3">
                <span *ngIf="!isDeleting">
                  <i class="icon icon-warning text-warning cursor-pointer pr-1"></i> Are you sure you want to delete {{ selectedNode?.name }} node?
                </span>
                <span *ngIf="isDeleting">
                  <i class="icon icon-circle-notch icon-spin"></i>  <span class="pl-1"> Please wait. HQ is working on it...</span>
                </span>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" *ngIf="!isDeleting" (click)="hideModal()">Close</button>
            <app-check-server-admin-permissions [server]="server">
              <button class="btn btn-primary" [disabled]="isDeleting" (click)="deleteNode();" appPermissionDisable>Proceed</button>
            </app-check-server-admin-permissions>
        </div>
  </ng-template>
