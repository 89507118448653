<div class="modal-header">
  <h4 class="modal-title">
    {{title}}
  </h4>
  <button type="button" class="close" (click)="close()">
    <i class="icon icon-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex align-items-center text-break">
    <div class="p-2">
      <i class="icon icon-2x icon-warning text-warning"></i>
    </div>
    <div [innerHTML]="message">
      <!-- {{message}} -->
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" *ngIf="!showOk" (click)="close()">No</button>
  <button class="btn btn-primary" *ngIf="!showOk" (click)="confirm()">Yes</button>
  <button class="btn btn-primary" *ngIf="showOk" (click)="close()">OK</button>
</div>
