/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { InformixServer } from '../../informixServer';
import { TranslateService } from '@ngx-translate/core';
import { OptimizationPoliciesList } from './tables-indexes-page.model';
import { TablesIndexesService } from './tables-indexes-page.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-optimization-policies',
  templateUrl: 'optimization-policies.html',
  styleUrls: ['tables-indexes-page.scss']
})

export class OptimizationPoliciesComponent implements OnInit {
  @Input() server: InformixServer = null;
  policiesForm: UntypedFormGroup;
  allDisable = false;
  taskParameters = { isCompress: false, isRepack: false, isShrink: false, isDefragment: false, isRemove: false };
  neverStopTime = false;
  isDataLoad = false;
  isLoading = false;
  policiesData: OptimizationPoliciesList;

  compressTables: UntypedFormControl;
  repackTables: UntypedFormControl;
  shrinkTables: UntypedFormControl;
  defragmentTables: UntypedFormControl;
  start_time_hh: UntypedFormControl;
  start_time_mm: UntypedFormControl;
  start_time_ss: UntypedFormControl;
  stop_time_hh: UntypedFormControl;
  stop_time_mm: UntypedFormControl;
  stop_time_ss: UntypedFormControl;
  repeat_days: UntypedFormControl;
  repeat_hours: UntypedFormControl;
  repeat_mins: UntypedFormControl;
  repeat_secs: UntypedFormControl;
  starttimeUpdated: any;
  stoptimeUpdated: any;
  frequencyUpdated: any;
  apiError: string;
  selectedTaskDays: Array<{ tk_day: string; checked: boolean }> = [{ tk_day: 'Monday', checked: true },
  { tk_day: 'Tuesday', checked: true }, { tk_day: 'Wednesday', checked: true },
  { tk_day: 'Thursday', checked: true }, { tk_day: 'Friday', checked: true },
  { tk_day: 'Saturday', checked: true }, { tk_day: 'Sunday', checked: true }];

  constructor(private translate: TranslateService,
    private tablesIndexesService: TablesIndexesService,
    private notificationsService: NotificationsService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe) { }

  get formControls() {
 return this.policiesForm.controls;
}

  ngOnInit() {
    this.compressTables = new UntypedFormControl({ disabled: true });
    this.repackTables = new UntypedFormControl({ disabled: true });
    this.shrinkTables = new UntypedFormControl({ disabled: true });
    this.defragmentTables = new UntypedFormControl({ disabled: true });
    this.start_time_hh = new UntypedFormControl({ disabled: true }, Validators.required);
    this.start_time_mm = new UntypedFormControl({ disabled: true }, Validators.required);
    this.start_time_ss = new UntypedFormControl({ disabled: true }, Validators.required);
    this.stop_time_hh = new UntypedFormControl({ disabled: true });
    this.stop_time_mm = new UntypedFormControl({ disabled: true });
    this.stop_time_ss = new UntypedFormControl({ disabled: true });
    this.repeat_days = new UntypedFormControl({ disabled: true }, Validators.required);
    this.repeat_hours = new UntypedFormControl({ disabled: true }, Validators.required);
    this.repeat_mins = new UntypedFormControl({ disabled: true });
    this.repeat_secs = new UntypedFormControl({ disabled: true });

    this.policiesForm = this.fb.group({
      compressTables: this.compressTables,
      repackTables: this.repackTables,
      shrinkTables: this.shrinkTables,
      defragmentTables: this.defragmentTables,
      start_time_hh: this.start_time_hh,
      start_time_mm: this.start_time_mm,
      start_time_ss: this.start_time_ss,
      stop_time_hh: this.stop_time_hh,
      stop_time_mm: this.stop_time_mm,
      stop_time_ss: this.stop_time_ss,
      repeat_days: this.repeat_days,
      repeat_hours: this.repeat_hours,
      repeat_mins: this.repeat_mins,
      repeat_secs: this.repeat_secs
    });
    this.getOptimizationPolicies();
  }

  private getOptimizationPolicies() {
    this.isLoading = true;
    this.tablesIndexesService.getOptimizationPolicies(this.server).subscribe((data: OptimizationPoliciesList) => {
      this.isLoading = false;
      this.policiesData = data;
      this.neverStopTime = false;
      this.starttimeUpdated = (data.task_info.tk_start_time !== null) ?
        this.datePipe.transform(data.task_info.tk_start_time.$date, 'HH:mm:ss').split(':') : null;
      this.frequencyUpdated = (data.task_info.tk_frequency !== null) ?
        this.transformTime(data.task_info.tk_frequency).split(':') : null;
      if (data.task_info.tk_stop_time === null || data.task_info.tk_stop_time === 'NEVER') {
        this.stoptimeUpdated = null;
        this.stop_time_hh.setValue(0);
        this.stop_time_mm.setValue(0);
        this.stop_time_ss.setValue(0);
        this.stop_time_hh.disable();
        this.stop_time_mm.disable();
        this.stop_time_ss.disable();
      } else {
        this.stoptimeUpdated = this.datePipe.transform(data.task_info.tk_stop_time.$date, 'HH:mm:ss').split(':');
        this.stop_time_hh.setValue(this.stoptimeUpdated[0]);
        this.stop_time_mm.setValue(this.stoptimeUpdated[1]);
        this.stop_time_ss.setValue(this.stoptimeUpdated[2]);
      }

      this.start_time_hh.setValue((this.starttimeUpdated === null) ? 0 : this.starttimeUpdated[0]);
      this.start_time_mm.setValue((this.starttimeUpdated === null) ? 0 : this.starttimeUpdated[1]);
      this.start_time_ss.setValue((this.starttimeUpdated === null) ? 0 : this.starttimeUpdated[2]);
      this.repeat_days.setValue((this.frequencyUpdated === null) ? 0 : this.frequencyUpdated[0]);
      this.repeat_hours.setValue((this.frequencyUpdated === null) ? 0 : this.frequencyUpdated[1]);
      this.repeat_mins.setValue((this.frequencyUpdated === null) ? 0 : this.frequencyUpdated[2]);
      this.repeat_secs.setValue((this.frequencyUpdated === null) ? 0 : this.frequencyUpdated[3]);

      this.policiesData.task_parameters.forEach(parameter => {
        if (parameter.name === 'AUTOCOMPRESS_ENABLED') {
          this.taskParameters.isCompress = (parameter.value === 'F') ? false : true;
          this.taskParameters.isCompress ? this.compressTables.enable() : this.compressTables.disable();
        } else if (parameter.name === 'AUTOREPACK_ENABLED') {
          this.taskParameters.isRepack = (parameter.value === 'F') ? false : true;
          this.taskParameters.isRepack ? this.repackTables.enable() : this.repackTables.disable();
        } else if (parameter.name === 'AUTOSHRINK_ENABLED') {
          this.taskParameters.isShrink = (parameter.value === 'F') ? false : true;
          this.taskParameters.isShrink ? this.shrinkTables.enable() : this.shrinkTables.disable();
        } else if (parameter.name === 'AUTODEFRAG_ENABLED') {
          this.taskParameters.isDefragment = (parameter.value === 'F') ? false : true;
          this.taskParameters.isDefragment ? this.defragmentTables.enable() : this.defragmentTables.disable();
        } else if (parameter.name === 'REMOVE_IPA_ENABLED') {
          this.taskParameters.isRemove = (parameter.value === 'F') ? false : true;
        } else if (parameter.name === 'AUTOCOMPRESS_ROWS') {
          this.compressTables.setValue(parameter.value);
          this.taskParameters.isCompress ?
            this.compressTables.setValidators([Validators.required, Validators.min(0), Validators.max(2147483647)]) :
            this.compressTables.setValidators(null);
        } else if (parameter.name === 'AUTOREPACK_SPACE') {
          this.repackTables.setValue(parameter.value);
          this.taskParameters.isRepack ?
            this.repackTables.setValidators([Validators.required, Validators.min(1), Validators.max(100)]) :
            this.repackTables.setValidators(null);
        } else if (parameter.name === 'AUTOSHRINK_UNUSED') {
          this.shrinkTables.setValue(parameter.value);
          this.taskParameters.isShrink ?
            this.shrinkTables.setValidators([Validators.required, Validators.min(1), Validators.max(2147483647)]) :
            this.shrinkTables.setValidators(null);
        } else if (parameter.name === 'AUTODEFRAG_EXTENTS') {
          this.defragmentTables.setValue(parameter.value);
          this.taskParameters.isDefragment ?
            this.defragmentTables.setValidators([Validators.required, Validators.min(1), Validators.max(2147483647)]) :
            this.defragmentTables.setValidators(null);
        }
      });

      if (this.policiesData.task_info.tk_stop_time === 'NEVER' || this.policiesData.task_info.tk_stop_time === null) {
        this.neverStopTime = true;
        this.stop_time_hh.setValidators(null);
        this.stop_time_mm.setValidators(null);
        this.stop_time_ss.setValidators(null);
      } else {
        this.stop_time_hh.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
        this.stop_time_mm.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
        this.stop_time_ss.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      }

      this.selectedTaskDays.filter(day => day.tk_day.includes('Monday'))[0].checked = this.policiesData.task_info.tk_monday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Tuesday'))[0].checked = this.policiesData.task_info.tk_tuesday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Wednesday'))[0].checked = this.policiesData.task_info.tk_wednesday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Thursday'))[0].checked = this.policiesData.task_info.tk_thursday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Friday'))[0].checked = this.policiesData.task_info.tk_friday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Saturday'))[0].checked = this.policiesData.task_info.tk_saturday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Sunday'))[0].checked = this.policiesData.task_info.tk_sunday;
      this.configureInputPolicies(data.task_info.tk_enable);
      this.apiError = null;
      this.isDataLoad = true;
    }, err => {
      this.isLoading = false;
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
    });
  }

  configureInputPolicies(isEnable: boolean) {
    if (isEnable === false) {
      this.allDisable = true;
      this.start_time_hh.disable();
      this.start_time_mm.disable();
      this.start_time_ss.disable();
      this.stop_time_hh.disable();
      this.stop_time_mm.disable();
      this.stop_time_ss.disable();
      this.repeat_days.disable();
      this.repeat_hours.disable();
      this.compressTables.disable();
      this.repackTables.disable();
      this.shrinkTables.disable();
      this.defragmentTables.disable();
    } else {
      this.allDisable = false;
      this.start_time_hh.enable();
      this.start_time_mm.enable();
      this.start_time_ss.enable();
      this.repeat_days.enable();
      this.repeat_hours.enable();
      if (this.neverStopTime === false) {
        this.stop_time_hh.enable();
        this.stop_time_mm.enable();
        this.stop_time_ss.enable();
      }
      if (this.taskParameters.isCompress) {
        this.compressTables.enable();
      }
      if (this.taskParameters.isRepack) {
        this.repackTables.enable();
      }
      if (this.taskParameters.isShrink) {
        this.shrinkTables.enable();
      }
      if (this.taskParameters.isDefragment) {
        this.defragmentTables.enable();
      }
    }
  }

  configurePolicies(type: string, event: boolean) {
    if (type === 'tk_enable') {
      this.configureInputPolicies(event);
    }
    if (type === 'compress') {
      this.taskParameters.isCompress = event;
      if (event) {
        this.compressTables.enable();
        this.compressTables.setValidators([Validators.required, Validators.min(0), Validators.max(2147483647)]);
      } else {
        this.compressTables.disable();
      }
    } if (type === 'repack') {
      this.taskParameters.isRepack = event;
      if (event) {
        this.repackTables.enable();
        this.repackTables.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
      } else {
        this.repackTables.disable();
      }
    } else if (type === 'shrink') {
      this.taskParameters.isShrink = event;
      if (event) {
        this.shrinkTables.enable();
        this.shrinkTables.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
      } else {
        this.shrinkTables.disable();
      }
    } else if (type === 'defragment') {
      this.taskParameters.isDefragment = event;
      if (event) {
        this.defragmentTables.enable();
        this.defragmentTables.setValidators([Validators.required, Validators.min(1), Validators.max(2147483647)]);
      } else {
        this.defragmentTables.disable();
      }
    } else if (type === 'remove') {
      this.taskParameters.isRemove = event;
    } else if (type === 'neverStopTime') {
      this.neverStopTime = event;
      if (event) {
        this.stop_time_hh.setValidators(null);
        this.stop_time_mm.setValidators(null);
        this.stop_time_ss.setValidators(null);
        this.stop_time_hh.disable();
        this.stop_time_mm.disable();
        this.stop_time_ss.disable();
      } else {
        this.stop_time_hh.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
        this.stop_time_mm.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
        this.stop_time_ss.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
        this.stop_time_hh.enable();
        this.stop_time_mm.enable();
        this.stop_time_ss.enable();
      }
    }
  }

  transformTime(interval: any): string {
    if (interval === null || interval === undefined) {
      return interval;
    }
    let seconds = 0;
    if (typeof (interval) === 'number') {
      seconds = interval;
    } else if (interval.IntervalDF && interval.IntervalDF.seconds) {
      seconds = interval.IntervalDF.seconds;
    } else {
      throw new Error('unexpected data for intervalDF: ' + interval);
    }
    let intervalStr = '';
    if (seconds < 0) {
      intervalStr += '-';
      seconds *= -1;
    }
    const days = Math.floor(seconds / 86400);
    seconds = seconds % 86400;
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    if (days < 10) {
      intervalStr += '0';
    }
    intervalStr += days;
    intervalStr += ':';
    if (hours < 10) {
      intervalStr += '0';
    }
    intervalStr += hours;
    intervalStr += ':';
    if (minutes < 10) {
      intervalStr += '0';
    }
    intervalStr += minutes;
    intervalStr += ':';
    if (seconds < 10) {
      intervalStr += '0';
    }
    intervalStr += seconds;
    return intervalStr;
  }

  updatePolicies() {
    const dataObj = {
      tk_start_time: !this.allDisable ? (this.start_time_hh.value + ':' + this.start_time_mm.value + ':' + this.start_time_ss.value) :
        (this.starttimeUpdated[0] + ':' + this.starttimeUpdated[1] + ':' + this.starttimeUpdated[2]),

      tk_stop_time: !this.allDisable ? (this.neverStopTime ? null : this.stop_time_hh.value + ':' + this.stop_time_mm.value + ':'
        + this.stop_time_ss.value) : (this.stoptimeUpdated !== null ? this.stoptimeUpdated[0] + ':' + this.stoptimeUpdated[1] + ':'
          + this.stoptimeUpdated[2] : null),

      tk_frequency: !this.allDisable ? (this.repeat_days.value + ' ' + this.repeat_hours.value + ':' +
        this.repeat_mins.value + ':' + this.repeat_secs.value) : (this.frequencyUpdated !== null ? this.frequencyUpdated[0] + ' '
          + this.frequencyUpdated[1] + ':' + this.frequencyUpdated[2] + ':' + this.frequencyUpdated[3] : null),

      tk_monday: !this.allDisable ? this.selectedTaskDays[0].checked : this.policiesData.task_info.tk_monday,
      tk_tuesday: !this.allDisable ? this.selectedTaskDays[1].checked : this.policiesData.task_info.tk_tuesday,
      tk_wednesday: !this.allDisable ? this.selectedTaskDays[2].checked : this.policiesData.task_info.tk_wednesday,
      tk_thursday: !this.allDisable ? this.selectedTaskDays[3].checked : this.policiesData.task_info.tk_thursday,
      tk_friday: !this.allDisable ? this.selectedTaskDays[4].checked : this.policiesData.task_info.tk_friday,
      tk_saturday: !this.allDisable ? this.selectedTaskDays[5].checked : this.policiesData.task_info.tk_saturday,
      tk_sunday: !this.allDisable ? this.selectedTaskDays[6].checked : this.policiesData.task_info.tk_sunday,
      tk_enable: !this.allDisable,
      compress: !this.allDisable ? (this.taskParameters.isCompress ? 'T' : 'F') :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTOCOMPRESS_ENABLED')[0].value,
      compress_threshold: (this.taskParameters.isCompress && !this.allDisable) ? this.compressTables.value :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTOCOMPRESS_ROWS')[0].value,
      repack: !this.allDisable ? (this.taskParameters.isRepack ? 'T' : 'F') :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTOREPACK_ENABLED')[0].value,
      removeIPA: !this.allDisable ? (this.taskParameters.isRemove ? 'T' : 'F') :
        this.policiesData.task_parameters.filter(type => type.name === 'REMOVE_IPA_ENABLED')[0].value,
      repack_threshold: (this.taskParameters.isRepack && !this.allDisable) ? this.repackTables.value :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTOREPACK_SPACE')[0].value,
      shrink: !this.allDisable ? (this.taskParameters.isShrink ? 'T' : 'F') :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTOSHRINK_ENABLED')[0].value,
      shrink_threshold: (this.taskParameters.isShrink && !this.allDisable) ? this.shrinkTables.value :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTOSHRINK_UNUSED')[0].value,
      defragment: !this.allDisable ? (this.taskParameters.isDefragment ? 'T' : 'F') :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTODEFRAG_ENABLED')[0].value,
      defragment_threshold: (this.taskParameters.isDefragment && !this.allDisable) ? this.defragmentTables.value :
        this.policiesData.task_parameters.filter(type => type.name === 'AUTODEFRAG_EXTENTS')[0].value
    };
    if (!this.allDisable) {
      if (this.repeat_hours.value < 1 && this.repeat_days.value < 1) {
        dataObj.tk_frequency = null;
      }
    }
    this.apiError = null;
    this.tablesIndexesService.updateOptimizationPolicies(this.server, dataObj).subscribe(res => {
      this.translate.get('tablesIndexes.successMsg.updatePoliciesSuccess')
        .subscribe((text: string) => {
          this.notificationsService.pushSuccessNotification(text);
        });
      this.getOptimizationPolicies();
    }, err => {
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
    });
  }
}
