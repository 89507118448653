/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { TablesIndexesComponent } from './tables-indexes.component';
import { TaskStatusComponent } from './task-status.component';
import { OptimizationPoliciesComponent } from './optimization-policies.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TablesIndexesPageComponent } from './tables-indexes-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TablesIndexesService } from './tables-indexes-page.service';
import { OptimizeSpaceModalComponent } from './optimize-space-modal.component';

@NgModule({
  imports: [
    SharedModule,
    TooltipModule,
    PopoverModule,
    ReactiveFormsModule
  ],
  declarations: [
    TablesIndexesPageComponent,
    TablesIndexesComponent,
    OptimizationPoliciesComponent,
    OptimizeSpaceModalComponent,
    TaskStatusComponent
  ],
  providers: [
    TablesIndexesService
  ]
})
export class TablesIndexesModule { }
