/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];

@Pipe({ name: 'byte' })
export class BytePipe implements PipeTransform {
  transform(value: number, precision: number = 2): string {
    const bytes = Math.abs(value);
    let limit = 1;
    let i = 0;
    for (; i < units.length - 1; i++) {
      if (bytes < limit * 1024) {
        break;
      }
      limit *= 1024;
    }

    let convertedValue = bytes / limit;
    convertedValue = Math.round(convertedValue * Math.pow(10, precision)) / Math.pow(10, precision);
    return convertedValue + ' ' + units[i];
  }
}
