/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved
 *******************************************************************************/
import { Component, Input } from '@angular/core';
import { InformixTableDetails } from '../../informix-table';

@Component({
  selector: 'app-table-fragments-info',
  templateUrl: './table-fragments-info.component.html'
})
export class TableFragmentsInfoComponent {

  @Input() tableDetails: InformixTableDetails;

  constructor() { }

}
