/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Dashboard } from '../../../monitoring/dashboard';

export const sessionDashboard: Dashboard = {
  title: 'Session Statistics',
  rows: [
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'session_stats',
            title: 'Session Count',
            metricList: ['session_count']
          }
        }
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'session_stats',
            title: 'Session Memory',
            metricList: ['max_session_memory', 'average_session_memory']
          }
        }
      ]
    }
  ]
};
