<!-- Page Heading -->
<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <h4><strong>{{ 'privileges.pageTitle' | translate }}</strong></h4>
  <app-tab-group (eventUpdate)="setSelectedTabIndex($event)">
    <app-tab label="{{ 'privileges.tabLabel.database' | translate }}">
      <app-database-privileges *ngIf="selectedTabIndex === 0" [server]="server"></app-database-privileges>
    </app-tab>
    <app-tab label="{{ 'privileges.tabLabel.table' | translate }}">
      <app-table-privileges *ngIf="selectedTabIndex === 1" [server]="server"></app-table-privileges>
    </app-tab>
    <app-tab label="{{ 'privileges.tabLabel.adminAPI' | translate }}">
      <app-sql-admin-privileges *ngIf="selectedTabIndex === 2" [server]="server"></app-sql-admin-privileges>
    </app-tab>
    <app-tab label="{{ 'privileges.tabLabel.internalUsers' | translate }}">
      <app-internal-users-privileges *ngIf="selectedTabIndex === 3" [server]="server"></app-internal-users-privileges>
    </app-tab>
  </app-tab-group>
</div>
