/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved
 *******************************************************************************/
import { ServerResolver } from '../server-resolver.service';
import { Route } from '@angular/router';
import { SchedulerComponent } from './scheduler.component';

export const SchedulerRoutes: Route[] = [
  {
    path: 'scheduler',
    component: SchedulerComponent,
    resolve: {
      server: ServerResolver
    }
  }
];
