/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { InformixTreeItem } from '../informixTreeItem';
import { HttpClient } from '@angular/common/http';

export interface PermissionsListItem {
  permissions: number;
  user: {
    id: number;
    name: string;
  };
}

export type PermissionsList = PermissionsListItem[];

export interface PermissionsListResponse {
  own: PermissionsList;
  inherited?: PermissionsList;
}

@Injectable()
export class PermissionsService {

  constructor(private httpClient: HttpClient) { }

  private getPath(child: InformixTreeItem): string {
    let path = 'informix/';
    if (child instanceof InformixServerGroup) {
      path += 'groups/';
    }
    path += child.id;
    return path;
  }

  public getAllPermissions(child: InformixTreeItem): Promise<PermissionsListResponse> {
    return this.httpClient.get<PermissionsListResponse>(this.getPath(child) + '/permissions')
      .toPromise<PermissionsListResponse>()
      .then(response => {
        const permissions: PermissionsListResponse = {
          own: response.own
        };
        if (response.inherited) {
          permissions.inherited = response.inherited;
        }
        return permissions;
      });
  }

  public updatePermissions(child: InformixTreeItem, userId: number, permissions: number): Promise<any> {
    return this.httpClient.put<any>(this.getPath(child) + '/permissions/' + userId, { permissions }).toPromise<any>();
  }

  public removePermissions(child: InformixTreeItem, userId: number): Promise<any> {
    return this.httpClient.delete<any>(this.getPath(child) + '/permissions/' + userId).toPromise<any>();
  }
}
