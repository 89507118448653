/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { MonitoringModule } from '../../../monitoring/monitoring.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../../shared/shared.module';
import { CheckpointsComponent } from './checkpoints.component';
import { CheckpointsService } from './checkpoints.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    MonitoringModule
  ],
  declarations: [
    CheckpointsComponent
  ],
  providers: [
    CheckpointsService
  ]
})
export class CheckpointsModule { }
