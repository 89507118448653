/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { InformixServer } from '../../informixServer';
import { InformixServerService } from '../../informixServer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-agent-deploy',
  templateUrl: './agent-deploy.component.html'
})
export class AgentDeployComponent implements OnInit, OnDestroy {

  @Input() server: InformixServer;

  formGroup: UntypedFormGroup;
  showForm = false;
  deployStatus: string = null;
  offlinePollTriesLeft = 0;
  isShuttingDown = false;
  productNameNoSpace = environment.productNameNoSpace;
  showPassword: Boolean = false;
  agentPollSubscription: Subscription = null;

  sslEnabled = location.protocol.startsWith('https');

  constructor(
    private serverService: InformixServerService,
    private notifications: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      overwrite: new UntypedFormControl(false),
      username: new UntypedFormControl(null, Validators.required),
      authType: new UntypedFormControl(),
      password: new UntypedFormControl(null, Validators.required),
      identityFile: new UntypedFormControl(null, Validators.required),
      passphrase: new UntypedFormControl(null),
      remoteDir: new UntypedFormControl(null, Validators.required),
      keystoreFile: new UntypedFormControl(null),
      keystorePassword: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      keystoreType: new UntypedFormControl('')
    });

    this.formGroup.controls.authType.valueChanges.subscribe(value => {
      const controls = this.formGroup.controls;
      if (value === 'password') {
        controls.password.enable();
        controls.identityFile.disable();
        controls.passphrase.disable();
      } else {
        controls.password.disable();
        controls.identityFile.enable();
        controls.passphrase.enable();
      }
    });

    this.formGroup.controls.keystoreFile.valueChanges.subscribe(value => {
      const passwordControl = this.formGroup.controls.keystorePassword;
      if (value) {
        passwordControl.enable();
      } else {
        passwordControl.disable();
      }
    });

    this.formGroup.controls.authType.setValue('password');

    if (!this.server.hasAdminPassword) {
      this.showForm = true;
    }

    this.agentPollSubscription = interval(2000).pipe(switchMap(() => this.serverService.getAgent(this.server))).subscribe(agent => {
      this.server.agent = agent;
      if (this.deployStatus) {
        if (agent.online) {
          this.deployStatus = null;
          this.offlinePollTriesLeft = 0;
        } else if (this.offlinePollTriesLeft > 0) {
          this.offlinePollTriesLeft--;
          if (this.offlinePollTriesLeft <= 0) {
            this.deployStatus = null;
            this.notifications.pushErrorNotification('Agent failed to connect in 10 seconds');
          }
        }
      } else if (this.isShuttingDown && !agent.online) {
        this.isShuttingDown = false;
      }
    }, err => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    this.agentPollSubscription.unsubscribe();
  }

  canDeploy() {
    return !this.deployStatus && (!this.showForm || this.formGroup.valid);
  }

  deployAgent() {
    if (!this.canDeploy()) {
      return;
    }

    const form = this.formGroup.value;
    const config: any = {
      overwrite: form.overwrite
    };

    if (this.showForm) {
      config.username = form.username;
      config.remoteDir = form.remoteDir;
      if (form.authType === 'password') {
        config.password = form.password;
      } else {
        config.identityFile = form.identityFile;
        config.passphrase = form.passphrase;
      }

      if (this.sslEnabled && form.keystoreFile && form.keystorePassword) {
        config.ssl = {
          keystoreFile: form.keystoreFile,
          keystorePassword: form.keystorePassword
        };

        const keystoreType = form.keystoreType.trim();
        if (keystoreType) {
          config.ssl.keystoreType = keystoreType;
        }
      }
    }

    this.deployStatus = 'Deploying agent...';
    this.serverService.deployAgent(this.server, config).subscribe(() => {
      this.deployStatus = 'Agent deployed. Waiting for connection...';
      this.offlinePollTriesLeft = 5;
    }, (err: HttpErrorResponse) => {
      console.log(err.error.err);
      this.deployStatus = null;
      this.showForm = true;
      let errorMessage = 'There was a problem deploying the agent';
      if (err.error && err.error.err) {
        errorMessage += ': ' + err.error.err;
      }
      this.notifications.pushErrorNotification(errorMessage);
      console.error(err);
    });
  }

  shutdownAgent() {
    this.confirmationDialogService.show('shut the agent down?', () => {
      this.isShuttingDown = true;
      this.serverService.shutdownAgent(this.server).subscribe(agent => {
        this.server.agent = agent;
        this.isShuttingDown = agent.online;
      }, err => {
        this.isShuttingDown = false;
        this.notifications.pushErrorNotification('There was a problem shutting down the agent');
        console.error(err);
      });
    });
  }

  toggleShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.password.value === '') {
        this.showPassword = false;
      }
      return;
    }
    this.showPassword = !this.showPassword;
  }
}
