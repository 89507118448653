<div bsModal #addParamModal="bs-modal" class="modal fade param-details" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-11">
          <h5 class="modal-title heading-padding-top pull-left">Parameter Details <button type="button" class="btn btn-primary"
              style="float:right" (click)="addNewParamDetails()"><i class="icon icon-plus"></i>Add
              New</button></h5>
        </div>
        <div>
          <button type="button" class="close" (click)="closeParamModal()">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body p-0">
        <form *ngIf="paramDetailsForm" [formGroup]="paramDetailsForm">
          <div class="param-taskname">
            <strong>Task Name : &nbsp;</strong><span>{{task?.tk_name}}</span>
          </div>
        <div class="param-sidebar">
          <div formArrayName="params" *ngFor="let param of paramDetailsForm?.get('params').controls; let i = index;"
            class="param-p2 mt-3">
            <div *ngIf="i>0">
              <hr>
            </div>
            <div class="row">
              <div class="col-3 pt-1">Parameter Name</div>
              <div class="col-7 bg-gray-20 pt-1">{{paramDetailsForm.controls.params.controls[i].controls.parameterName.value}}</div>
              <div class="col-2 px-0">
                <button class="btn-icon bg-gray-20" (click)="confirmDelete(i, task.tk_id, paramDetailsForm.controls.params.controls[i].controls.id.value)"><i
                    class="icon icon-trash"></i></button>
                <button class="btn-icon bg-gray-20" (click)="paramDetailsForm.controls.params.controls[i].controls.show.value=!paramDetailsForm.controls.params.controls[i].controls.show.value;">
                  <i class="icon" [ngClass]="{'icon-chevron-down': !paramDetailsForm.controls.params.controls[i].controls.show.value, 'icon-chevron-up': paramDetailsForm.controls.params.controls[i].controls.show.value}"></i>
                </button>
              </div>
            </div>
            <div [formGroupName]="i" class="param-form-width">
              <div *ngIf="paramDetailsForm.controls.params.controls[i].controls.show.value">
                <div class="form-group">
                  <label>Parameter Name</label>
                  <input type="text" class="form-control param-details-input" formControlName="name"
                    appExtendedFormControl tooltip="" value="{{paramDetailsForm.controls.params.controls[i].controls.name.value}}">
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <input formControlName="description" class="form-control param-details-input" type="text"
                    appExtendedFormControl tooltip="" value="{{paramDetailsForm.controls.params.controls[i].controls.description.value}}">
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>Value</label>
                    <input formControlName="value" class="form-control value-input" type="text" appExtendedFormControl
                      tooltip="" value="{{paramDetailsForm.controls.params.controls[i].controls.value.value}}">
                  </div>
                  <div class="col-6">
                    <label>Value Type</label>
                    <input formControlName="value_type" class="form-control value-input" type="text"
                      appExtendedFormControl tooltip="" value="{{paramDetailsForm.controls.params.controls[i].controls.value_type.value}}">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div formArrayName="newParams" *ngFor="let param of paramDetailsForm?.get('newParams').controls; let i = index;"
            class="param-p2">
            <div *ngIf="!disableSave || i>0">
              <hr>
            </div>
            <div [formGroupName]="i" class="param-form-width">
              <div class="form-group">
                <label>Parameter Name</label>
                <button class="btn-icon" (click)="deleteParamForm(i, 'newParams')"><i class="icon icon-trash"></i></button>
                <input type="text" #paramName class="form-control param-details-input" appExtendedFormControl tooltip=""
                  formControlName="name">
              </div>
              <div class="form-group">
                <label>Description</label>
                <input type="text" class="form-control param-details-input" appExtendedFormControl tooltip=""
                  formControlName="description">
              </div>
              <div class="row">
                <div class="col-6">
                  <label>Value</label>
                  <input type="text" class="form-control value-input" appExtendedFormControl tooltip="" formControlName="value"></div>
                <div class="col-6">
                  <label>Value Type</label>
                  <select class="form-control value-input" tooltip="" appExtendedFormControl formControlName="value_type">
                    <option [ngValue]="null" disabled>Select value type</option>
                    <option>STRING</option>
                    <option>NUMERIC</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="col-12 p-3" align="right">
            <div class="col-8">
              <button type="button" class="btn btn-secondary" (click)="closeParamModal()">
                Cancel</button>
              <button type="button" *ngIf="!showSave" class="btn btn-primary" (click)="editParameters()">
                Edit</button>
              <button type="button" *ngIf="showSave" [disabled]="!paramDetailsForm.valid || disableSave" class="btn btn-primary"
                (click)="saveParameters()">
                Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
