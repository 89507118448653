/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { ChartJSUtils } from '../../../../shared/chartjs.utils';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { InformixSensorService } from '../../../monitoring/informixSensor.service';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { MonitoringProfile } from '../../../monitoring/monitoringProfile';
import { SensorDataSource } from '../../../monitoring/sensor-data-source';
import { TimeSlice, getTimeSlice } from '../../../monitoring/timeSlices';
import { InformixServerOnconfigService } from '../../configuration/informixServerOnconfig.service';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { CheckpointsService } from './checkpoints.service';
import { UserService } from '../../../../shared/user/user.service';
import { User } from '../../../../shared/user/user';
import { HDRPermission } from '../../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../../shared/hdr-permission/hdr-permission.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-checkpoints',
  templateUrl: 'checkpoints.html'
})
export class CheckpointsComponent implements OnInit, OnDestroy {

  isLoading = true;
  isLoadingData = false;

  server: InformixServer;
  monitoringProfile: MonitoringProfile = null;
  breadcrumb: BreadcrumbElement[] = null;

  @ViewChild('checkpointsChart') checkpointsChartCanvas: ElementRef;
  private checkpointsChart: any;

  checkpointData: any[] = null;
  tableData: any[] = null;
  onConfig: any = null;

  isEditingConfig = false;
  editConfigFormGroup: UntypedFormGroup;
  saveConfigButtonEnabled = false;

  runCheckpointFormGroup: UntypedFormGroup;

  sensorDataSource: SensorDataSource = null;
  sensorMostRecentTimestamp: number = null;
  sensorLiveSubscription: Subscription;

  oldestCheckpointTimestamp: number = null;
  newestCheckpointTimestamp: number = null;

  selectedTimeSlice: TimeSlice;
  isPaused = true;

  serverDataSubscription: Subscription;
  onConfigSubscription: Subscription;
  serverDataPollTimeout: number = null;
  serverType = environment.serverType;

  hasServerData = true;
  checkpointsHDR: HDRPermission;
  checkpointApiError: string;
  constructor(
    private route: ActivatedRoute,
    private checkpointsService: CheckpointsService,
    private onconfigService: InformixServerOnconfigService,
    private sensorService: InformixSensorService,
    private monitoringService: MonitoringService,
    private notificationsService: NotificationsService,
    private userService: UserService,
    private hdrPermissionService: HDRPermissionService
  ) {
    this.editConfigFormGroup = new UntypedFormGroup({
      autoCkpts: new UntypedFormControl(null, [Validators.required]),
      rtoServerRestart: new UntypedFormControl(null, [Validators.required]),
      ckptInterval: new UntypedFormControl(null, [Validators.required])
    });
    this.runCheckpointFormGroup = new UntypedFormGroup({
      type: new UntypedFormControl('norm', [Validators.required])
    });
  }

  ngOnInit() {
    this.userService.getCurrentUser().then((user: User) => {
      this.selectedTimeSlice = getTimeSlice(user.settings.timeSliceName);
    }).catch(err => {
      this.notificationsService.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
    });
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.checkpointsHDR = this.hdrPermissionService.getByPermissionId('p1');
  }

  ngOnDestroy() {
    if (this.serverDataSubscription) {
      this.serverDataSubscription.unsubscribe();
    }
    if (this.onConfigSubscription) {
      this.onConfigSubscription.unsubscribe();
    }
    if (this.sensorLiveSubscription) {
      this.sensorLiveSubscription.unsubscribe();
    }
    if (this.serverDataPollTimeout) {
      window.clearTimeout(this.serverDataPollTimeout);
    }
  }

  timeSliceChanged(slice: TimeSlice) {
    if (slice === this.selectedTimeSlice) {
      return;
    }

    this.selectedTimeSlice = slice;
    if (!this.oldestCheckpointTimestamp || new Date().getTime() - slice.value < this.oldestCheckpointTimestamp) {
      this.getHistoricalData();
    } else {
      this.trimData();
    }
  }

  pauseData() {
    if (this.isPaused) {
      return;
    }

    this.isPaused = true;
    if (this.sensorLiveSubscription) {
      this.sensorLiveSubscription.unsubscribe();
    }
    if (this.serverDataPollTimeout) {
      window.clearTimeout(this.serverDataPollTimeout);
    }
  }

  resumeData() {
    if (!this.isPaused) {
      return;
    }

    this.isPaused = false;
    if (this.sensorDataSource && !this.sensorDataSource.getSensor().disabled && this.server.agent && this.server.agent.isMonitoring) {
      this.sensorLiveSubscription = this.sensorDataSource.getLiveData(null, this.newestCheckpointTimestamp).subscribe(data => {
        if (data && data.length > 0) {
          this.sensorMostRecentTimestamp = data[0].timestamp;
          this.addData(this.convertSensorData(data));
        }
      });
    } else {
      this.pollServerCheckpoints(0);
    }
  }

  private loadServer(server: InformixServer) {
    this.isLoading = true;

    this.checkpointData = null;
    this.tableData = null;
    this.onConfig = null;

    if (this.checkpointsChart) {
      this.checkpointsChart.destroy();
      this.checkpointsChart = null;
    }

    this.sensorDataSource = null;
    this.sensorMostRecentTimestamp = null;

    this.oldestCheckpointTimestamp = null;
    this.newestCheckpointTimestamp = null;

    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [
      { name: 'Performance' },
      { name: 'Checkpoints' }
    ]);

    // TODO: Why was this here?
    // this.hasServerData === this.server.hasMonitorPassword;

    if (this.serverDataSubscription) {
      this.serverDataSubscription.unsubscribe();
    }

    this.getMonitoringProfile();

    if (this.server.hasMonitorPassword) {
      this.getOnconfigInfo();
    }
  }

  public getOnconfigInfo() {
    if (this.onConfigSubscription) {
      this.onConfigSubscription.unsubscribe();
    }

    this.onConfigSubscription = this.onconfigService.getServerConfiguration(this.server.id,
      ['AUTO_CKPTS', 'RTO_SERVER_RESTART', 'CKPTINTVL']).subscribe(config => {
        this.onConfig = {};
        config.forEach(v => {
          this.onConfig[v.name] = parseInt(v.effective, 10);
        });
      }, err => {
        console.error(err);
      });
  }

  public getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;
      const sensor = this.monitoringProfile.getSensor('checkpoint');
      if (sensor != null) {
        this.sensorDataSource = this.sensorService.getSensorDataSource(this.server, sensor);
      } else {
        this.sensorDataSource = null;
      }
    }).catch(err => {
      this.sensorDataSource = null;
    }).then(() => {
      if (this.sensorDataSource) {
        return this.sensorDataSource.getMetaData().catch(err => null);
      } else {
        return null;
      }
    }).then(meta => {
      if (meta && meta.most_recent_timestamp) {
        this.sensorMostRecentTimestamp = meta.most_recent_timestamp + 1;
      }

      this.isLoading = false;
      this.getHistoricalData().then(() => {
        this.resumeData();
      });
    });
  }

  private getHistoricalData(): Promise<any> {
    const fromTimestamp = new Date().getTime() - this.selectedTimeSlice.value;

    let promise: Promise<any> = Promise.resolve(null);
    if (this.sensorDataSource != null) {
      let serverFromTimestamp = fromTimestamp;
      if (this.sensorMostRecentTimestamp && this.sensorMostRecentTimestamp > serverFromTimestamp) {
        serverFromTimestamp = this.sensorMostRecentTimestamp;
      }

      if (!this.oldestCheckpointTimestamp || serverFromTimestamp < this.oldestCheckpointTimestamp) {
        promise = this.getServerCheckpoints(serverFromTimestamp, this.oldestCheckpointTimestamp).catch(() => { });
      }
    }

    this.isLoadingData = true;
    return promise.then(() => {
      if (this.sensorDataSource) {
        return this.sensorDataSource.getData(fromTimestamp, this.oldestCheckpointTimestamp).then(data => {
          if (data && data.length > 0) {
            this.sensorMostRecentTimestamp = data[0].timestamp;
            this.addData(this.convertSensorData(data));
          }
        }).catch(err => {
          console.error(err);
        }).then(() => {
          this.isLoadingData = false;
        });
      }
    });
  }

  private getServerCheckpoints(fromTimestamp?: number, toTimestamp?: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.serverDataSubscription) {
        this.serverDataSubscription.unsubscribe();
      }

      this.serverDataSubscription = this.checkpointsService.getCheckpoints(this.server, fromTimestamp, toTimestamp).subscribe(data => {
        this.addData(data);
        resolve(null);
      }, err => {
        this.hasServerData = false;
        if(err.error && err.error.err) {
          this.checkpointApiError = err.error.err;
        }
        console.error(err);
        reject(err);
      });
    });
  }

  private pollServerCheckpoints(timeout: number) {
    if (this.serverDataPollTimeout) {
      window.clearTimeout(this.serverDataPollTimeout);
    }

    this.serverDataPollTimeout = window.setTimeout(() => {
      this.getServerCheckpoints(this.newestCheckpointTimestamp).then(() => {
        this.serverDataPollTimeout = null;
        this.pollServerCheckpoints(5000);
      }).catch(err => { });
    }, timeout);
  }

  private trimData() {
    const timestamp = new Date().getTime() - this.selectedTimeSlice.value;
    if (!this.oldestCheckpointTimestamp || timestamp <= this.oldestCheckpointTimestamp) {
      return;
    }

    const index = this.checkpointData.findIndex(v => v.timestamp > timestamp);

    if (index > 0) {
      this.checkpointData = this.checkpointData.slice(index);
      this.oldestCheckpointTimestamp = timestamp;
    } else if (index < 0) {
      this.checkpointData = [];
      this.oldestCheckpointTimestamp = null;
      this.newestCheckpointTimestamp = null;
    }

    this.updateChartAndTable();
  }

  private addData(data: any[]) {
    if (!this.checkpointData) {
      this.checkpointData = [];
      this.tableData = [];
    }

    if (data.length < 1) {
      return;
    }

    if (!this.checkpointsChart) {
      this.createRunTimesChart();
    }

    let isHistorical = false;

    if (!this.oldestCheckpointTimestamp && !this.newestCheckpointTimestamp) {
      this.oldestCheckpointTimestamp = data[data.length - 1].timestamp;
      this.newestCheckpointTimestamp = data[0].timestamp;
      isHistorical = true;
    } else if (this.oldestCheckpointTimestamp && data[0].timestamp < this.oldestCheckpointTimestamp) {
      isHistorical = true;
      this.oldestCheckpointTimestamp = data[data.length - 1].timestamp;
    } else if (this.newestCheckpointTimestamp && data[data.length - 1].timestamp > this.newestCheckpointTimestamp) {
      this.newestCheckpointTimestamp = data[0].timestamp;
    } else {
      return;
    }

    data.reverse();
    this.checkpointData = isHistorical ? data.concat(this.checkpointData) : this.checkpointData.concat(data);
    this.hasServerData = true;
    this.updateChartAndTable();
  }

  private convertSensorData(data: any[]): any[] {
    return data.map(v => {
      v.data._.timestamp = v.timestamp;
      return v.data._;
    });
  }

  private createRunTimesChart() {
    this.checkpointsChart = new Chart(this.checkpointsChartCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Total Time (seconds)',
            data: [],
            borderColor: ChartJSUtils.getDefaultColor(0),
            backgroundColor: ChartJSUtils.getDefaultColor(0),
            fill: false,
            lineTension: 0
          },
          {
            label: 'Txn Longest Wait (seconds)',
            data: [],
            borderColor: ChartJSUtils.getDefaultColor(1),
            backgroundColor: ChartJSUtils.getDefaultColor(1),
            fill: false,
            lineTension: 0
          },
          {
            label: '# Txns waited',
            data: [],
            borderColor: ChartJSUtils.getDefaultColor(2),
            backgroundColor: ChartJSUtils.getDefaultColor(2),
            fill: false,
            lineTension: 0,
            yAxisID: 'y-axis-2'
          }
        ]
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              type: 'time',
              distribution: 'series',
              ticks: {
                source: 'labels',
                maxRotation: 0,
                autoSkipPadding: 10,
                autoSkip: true,
              }
            }
          ],
          yAxes: [
            {
              type: 'linear',
              distribution: 'series',
              position: 'left',
              id: 'y-axis-1',
              scaleLabel: {
                display: true,
                labelString: 'Seconds'
              },
              ticks: {
                min: 0,
                autoSkipPadding: 10,
                autoSkip: true,
                suggestedMax: 0.1
              }
            },
            {
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              type: 'linear',
              position: 'right',
              id: 'y-axis-2',
              scaleLabel: {
                display: true,
                labelString: '# Txns waited'
              },
              ticks: {
                min: 0,
                suggestedMax: 1,
                padding: 10
              }
            }
          ]
        },
        tooltips: {
          mode: 'index'
        }
      }
    } as any);
  }

  private updateChartAndTable() {
    if (this.checkpointsChart) {
      if (this.checkpointData.length > 0) {
        this.checkpointsChart.data.labels = this.checkpointData.map(v => new Date(v.timestamp));
        this.checkpointsChart.data.datasets[0].data = this.checkpointData.map(v => v.cp_time);
        this.checkpointsChart.data.datasets[1].data = this.checkpointData.map(v => v.longest_crit_wait);
        this.checkpointsChart.data.datasets[2].data = this.checkpointData.map(v => v.n_crit_waits);
        this.checkpointsChart.update();
      } else {
        this.checkpointsChart.destroy();
        this.checkpointsChart = null;
      }
    }

    this.tableData = this.checkpointData.map(v => ({
      intvl: v.intvl,
      timestamp: v.timestamp,
      caller: v.caller,
      lsn: v.ckpt_logid + ':0x' + v.ckpt_logpos.toString(16),
      cp_time: v.cp_time.toFixed(3),
      crit_time: v.crit_time.toFixed(3),
      block_time: v.block_time.toFixed(3),
      flush_time: v.flush_time.toFixed(3),
      n_crit_waits: v.n_crit_waits,
      longest_crit_wait: v.n_crit_waits > 0 ? v.longest_crit_wait.toFixed(3) : '-',
      avg_crit_wait: v.n_crit_waits > 0 ? (v.tot_crit_wait / v.n_crit_waits).toFixed(3) : '-',
      n_dirty_buffs: v.n_dirty_buffs,
      physused: v.physused,
      logused: v.logused
    }));
  }

  public doEditConfiguration() {
    if (this.checkpointsHDR && !this.checkpointsHDR.isAllow()) {
 return;
}
    if (this.onConfig) {
      this.editConfigFormGroup.controls.autoCkpts.setValue(this.onConfig['AUTO_CKPTS']);
      this.editConfigFormGroup.controls.rtoServerRestart.setValue(this.onConfig['RTO_SERVER_RESTART']);
      this.editConfigFormGroup.controls.ckptInterval.setValue(this.onConfig['CKPTINTVL']);
    }
    this.isEditingConfig = true;
  }

  public doUpdateConfiguration() {
    const autoCkptsUpdated: boolean = this.onConfig['AUTO_CKPTS'] !== this.editConfigFormGroup.controls.autoCkpts.value;
    const rtoUpdated: boolean = this.onConfig['RTO_SERVER_RESTART'] !== this.editConfigFormGroup.controls.rtoServerRestart.value;
    const ckptIntervalUpdated: boolean = (this.editConfigFormGroup.controls.rtoServerRestart.value < 1)
      && this.onConfig['CKPTINTVL'] !== this.editConfigFormGroup.controls.ckptInterval.value;
    if (!autoCkptsUpdated && !rtoUpdated && !ckptIntervalUpdated) {
      console.log('nothing to update. no config parameters have been changed');
      return;
    }

    const updateInfo: any = {};
    if (autoCkptsUpdated) {
      updateInfo.AUTO_CKPTS = this.editConfigFormGroup.controls.autoCkpts.value;
    }
    if (rtoUpdated) {
      updateInfo.RTO_SERVER_RESTART = this.editConfigFormGroup.controls.rtoServerRestart.value;
    }
    if (ckptIntervalUpdated) {
      updateInfo.CKPTINTVL = this.editConfigFormGroup.controls.ckptInterval.value;
    }
    this.onconfigService.updateServerConfiguration(this.server.id, updateInfo).then(result => {
      this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
 this.getOnconfigInfo();
}
      });
    }).catch(err => {
      let message: string = err;
      if (typeof err !== 'string') {
        message = err.error ? err.error.err : err;
      }
      this.notificationsService.pushErrorNotification(message);
    });
    this.isEditingConfig = false;
  }

  public doCancelEditConfiguration() {
    this.isEditingConfig = false;
  }

  public validateEditConfigForm() {
    let valid: boolean = this.editConfigFormGroup.valid;
    const autoCkptsUpdated: boolean = this.onConfig['AUTO_CKPTS'] !== this.editConfigFormGroup.controls.autoCkpts.value;
    const rtoUpdated: boolean = this.onConfig['RTO_SERVER_RESTART'] !== this.editConfigFormGroup.controls.rtoServerRestart.value;
    const ckptIntervalUpdated: boolean = (this.editConfigFormGroup.controls.rtoServerRestart.value < 1)
      && this.onConfig['CKPT_INTERVAL'] !== this.editConfigFormGroup.controls.ckptInterval.value;
    if (!autoCkptsUpdated && !rtoUpdated && !ckptIntervalUpdated) {
      // disable save button if no changes were made
      valid = false;
    }
    this.saveConfigButtonEnabled = valid;
  }

  public doRunCheckpoint() {
    if (this.checkpointsHDR && !this.checkpointsHDR.isAllow()) {
 return;
}
    this.checkpointsService.runCheckpoint(this.server, this.runCheckpointFormGroup.controls.type.value).then(result => {
      this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
        if (type.isSuccess || type.isInfo) {
 this.pollServerCheckpoints(0);
}
      });
    }).catch(err => {
      let message: string = err;
      if (typeof err !== 'string') {
        message = err.error ? err.error.err : err;
      }
      this.notificationsService.pushErrorNotification(message);
    });
  }
}
