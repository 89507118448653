<ng-template [ngIf]="operation">
  <div class="form-group">
    <app-alert-operand [owner]="owner" [operand]="operation.a" (eventUpdate)="updateLeftOperand($event)"> </app-alert-operand>
  </div>
  <ng-template [ngIf]="operators">
    <div class="form-group form-inline">
      <select class="form-control form-control-sm" [formControl]="operatorControl">
        <option *ngFor="let op of operators" [ngValue]="op">{{op.type.name}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="rightOperandDataTypeFilter">
      <app-alert-operand [owner]="owner" [operand]="operation.b" [dataTypeFilter]="rightOperandDataTypeFilter" [unit]="leftOperandEvent.unit" [forceConstant]="true"
        (eventUpdate)="updateRightOperand($event)"> </app-alert-operand>
    </div>
  </ng-template>
</ng-template>