<div class="mt-2" *ngIf="taskStatusList; else taskStatusInfoLoading">
  <app-data-table *ngIf="taskStatusList; else TaskStatusLoading" [data]="taskStatusList" [rowsPerPage]="10"
    sortBy="start" [sortDescending]="true">
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.command' | translate" property="command"
      [sortable]="true"></app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.startTime' | translate" property="start"
      [sortable]="true"></app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.endTime' | translate" property="end"
      [sortable]="true"></app-data-table-column>
    <app-data-table-column [title]="'tablesIndexes.table.columnTitle.result' | translate" property="msg"
      [sortable]="true"></app-data-table-column>
  </app-data-table>
</div>

<ng-template #taskStatusInfoLoading>
  <div *ngIf="isLoading else errorMessage;" class="d-flex justify-content-center mt-4">
    <i class="icon icon-circle-notch icon-2x icon-spin"></i>
  </div>
  <ng-template #errorMessage>
    <div class="alert alert-danger mt-2 mb-0 w-100" *ngIf="apiError">
      Unable to retrieve task status information. <br> Error: {{apiError}} 
     </div>
  </ng-template>
</ng-template>


<div bsModal #confirmCleanupModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered">  
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Cleanup</h5>
      </div>
      <div class="modal-body p-3 pt-4 pb-4">
        <div>
          <div class="pb-3" *ngIf="cleanupSpinner">
            <i class="icon icon-circle-notch icon-spin"></i> Please wait, Cleaning up ph_task...
          </div>
          <div class="pb-3" *ngIf="!cleanupSpinner">
            <i class="icon icon-warning text-warning pr-1"></i> This will delete the completed background tasks of compression type from 'ph_task' table.
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex w-100">
          <div class="flex-1">
            <button type="button"  class="btn btn-secondary mr-1"
              (click)="confirmCleanupModal.hide();">
              Close
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-primary mr-1" (click)="cleanupJobs()">
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
