<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="clearfix">
    <h4 class="d-inline-block"><strong>{{ 'breadcrumb.storage.tablesIndexes' | translate }}</strong></h4>
    <div class="float-right">
      <app-check-server-admin-permissions [server]="server" placement="top" *ngIf="selectedTabIndex === 2">
          <button class="btn btn-sm btn-primary" (click)="doCleanup()" appPermissionDisable>Cleanup</button>
      </app-check-server-admin-permissions>
      <button *ngIf="selectedTabIndex === 2" class="btn btn-sm btn-icon" (click)="refreshData()">
        <i class="icon icon-refresh font-weight-bold"></i></button>
    </div>
  </div>
  <app-tab-group (eventUpdate)="setSelectedTabIndex($event)">
    <app-tab [label]="'breadcrumb.storage.tablesIndexes' | translate">
      <app-tables-indexes *ngIf="selectedTabIndex === 0" [server]="server"></app-tables-indexes>
    </app-tab>
    <app-tab [label]="'tablesIndexes.tabLabel.optimizationPolicies' | translate">
      <app-optimization-policies *ngIf="selectedTabIndex === 1" [server]="server"></app-optimization-policies>
    </app-tab>
    <app-tab [label]="'tablesIndexes.tabLabel.taskStatus' | translate">
      <app-task-status *ngIf="selectedTabIndex === 2" [server]="server">
      </app-task-status>
    </app-tab>
  </app-tab-group>
</div>
