<ng-container *ngIf="!isLoading; else loadingTemplate">
  <tabset>
    <tab heading="Data Source">
      <div class="p-2" [formGroup]="dataSourcesFormGroup">
        <ng-container *ngIf="dashboardService.getServers()?.length > 1 || dataSourcesFormGroup.value.server !== null">
          <h5>Server</h5>
          <div class="form-inline mb-2">
            <select class="form-control" formControlName="server">
              <option [ngValue]="null">All Servers</option>
              <option *ngFor="let server of dashboardService.getServers(); index as i" [ngValue]="i">
                #{{i + 1}} - {{server.name}}
              </option>
              <option *ngIf="dataSourcesFormGroup.value.server >= dashboardService.getServers().length"
                [ngValue]="dataSourcesFormGroup.value.server">
                #{{dataSourcesFormGroup.value.server + 1}} - No server selected
              </option>
            </select>
          </div>
        </ng-container>
        <label class="btn-container">
          <button class="btn font-weight-bold" tooltip="Add data source from available sensors."
            [ngClass]="{'btn-style':!config.isCustomQuery}" (click)="discardChangesConfirmation()">Add Data
            Source</button>
          <button class="btn font-weight-bold" tooltip="Run custom SQL query to see graph."
            [ngClass]="{'btn-style': config.isCustomQuery}" (click)="removeDataSourcesConfirmation()">Add Query</button>
        </label>
        <div *ngIf="config?.isCustomQuery">
          <div class="row align-items-center mb-2">
            <div class="col-auto font-weight-bold">
              {{'database.selectDatabase' | translate}}
            </div>
            <div class="col-auto">
              <ng-container *ngIf="databases; else loadingDatabases">
                <select class="form-control" *ngIf="databases.length; else noDatabaseAvailable"
                  formControlName="databaseName">
                  <option *ngFor="let database of databases" [ngValue]="database">{{database}}</option>
                </select>
                <ng-template #noDatabaseAvailable>
                  {{'database.noDatabaseAvailable' | translate}}
                </ng-template>
              </ng-container>
              <ng-template #loadingDatabases>
                <div class="alert alert-danger" *ngIf="databasesLoadError; else loadingSpinner">
                  {{databasesLoadError}}
                </div>
                <ng-template #loadingSpinner>
                  <i class="icon icon-circle-notch icon-spin"></i>
                </ng-template>
              </ng-template>
            </div>
          </div>
          <textarea class="form-control text-monospace mb-2" rows="3" formControlName="queryString" autocomplete="off"
            autocorrect="off" autocapitalize="off" spellcheck="false">
          </textarea>
          <div>
            <button type="button" class="mr-2 btn btn-primary" (click)="onRunClick()"
              [disabled]="!dataSourcesFormGroup.controls.queryString.value?.trim()">
              Run
            </button>
            <button type="button" class="btn btn-primary" (click)="config.isCustomQuery = false; initForms();">
              Cancel
            </button>
          </div>
        </div>
        <div formArrayName="dataSources">
          <div *ngIf="dataSourcesFormGroup.controls.dataSources.controls.length > 0" class="font-weight-bold mb-2">
            Select Available Sensors</div>
          <div *ngFor="let dataSourceFormGroup of dataSourcesFormGroup.controls.dataSources.controls; let i = index">
            <div [formGroup]="dataSourceFormGroup" class="form-inline">
              <select class="form-control mb-2" formControlName="sensorType">
                <option *ngIf="!dataSourceFormGroup.value.sensorType" [ngValue]="null">
                  -- Select Sensor --
                </option>
                <option *ngFor="let type of sensorTypes" [ngValue]="type">
                  {{type.name}}
                </option>
              </select>

              <ng-container *ngIf="dataSourceFormGroup.value.sensorType">
                <select class="form-control mb-2 ml-sm-2" formControlName="metric">
                  <option [ngValue]="null">-- All Metrics --</option>
                  <option *ngFor="let metric of dataSourceFormGroup.value.sensorType.metrics" [ngValue]="metric">
                    {{metric.name}}
                  </option>
                </select>

                <ng-container *ngIf="dataSourceFormGroup.value.sensorType.hasPrimaryKey()">
                  <select class="form-control mb-2 ml-sm-2" formControlName="primaryKey"
                    *ngIf="getPrimaryKeys(dataSourceFormGroup.value.sensorType) | async as primaryKeys; else primaryKeysLoading">
                    <option *ngIf="dataSourceFormGroup.value.metric else selectKeyOption" [ngValue]="null">-- All --
                    </option>
                    <ng-template #selectKeyOption>
                      <option *ngIf="!dataSourceFormGroup.value.primaryKey" [ngValue]="null">
                        -- Select one --
                      </option>
                    </ng-template>
                    <option *ngFor="let primaryKey of primaryKeys" [ngValue]="primaryKey">
                      {{primaryKey}}
                    </option>
                  </select>
                  <ng-template #primaryKeysLoading>
                    <div class="mb-2 ml-sm-2">
                      <i class="icon icon-circle-notch icon-spin"></i>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
              <button class="btn btn-icon btn-sm mb-2" (click)="newDataSource(dataSourceFormGroup)">
                <i class="icon icon-copy"></i>
              </button>
              <button class="btn btn-icon btn-sm mb-2" (click)="removeDataSource(i)">
                <i class="icon icon-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </tab>
    <tab heading="Visualisation">
      <div class="border-bottom outline-none">
        <button class="btn btn-sm btn-icon" [ngClass]="{'text-primary': chartType === 'bar'}" tooltip="Bar"
          (click)="chartType='bar'; emitConfigChanged();"><i class="icon icon-chart-bar"></i></button>
        <button class="btn btn-sm btn-icon" [ngClass]="{'text-primary': chartType === 'pie'}" tooltip="Pie"
          (click)="chartType='pie'; emitConfigChanged();"><i class="icon icon-chart-pie"></i></button>
        <button class="btn btn-sm btn-icon" [ngClass]="{'text-primary': chartType === 'line'}" tooltip="Line"
          (click)="chartType='line'; emitConfigChanged();"><i class="icon icon-chart-line"></i></button>
        <button class="btn btn-sm btn-icon" [ngClass]="{'text-primary': chartType === 'table'}" tooltip="Table"
          (click)="chartType='table'; emitConfigChanged();"><i class="icon icon-chart-table"></i></button>
      </div>
      <ng-container *ngIf="chartType === 'bar' || chartType === 'line'">
        <div class="p-2">
          <div class="row align-items-center">
            <div class="col-1 mt-1 color-gray-10 font-weight-bold">Left Y Axis</div>
            <div class="col-11 pl-0">
              <app-axis-config [config]="config.yAxes[0]" (configChanged)="onAxisConfigChanged(0, $event)"
                [chartType]="chartType">
              </app-axis-config>
            </div>
          </div>
          <div class="row align-items-center" *ngIf="config?.isCustomQuery === false">
            <div class="col-1 mt-1 color-gray-10 font-weight-bold">Right Y Axis</div>
            <div class="col-11 pl-0">
              <app-axis-config [config]="config.yAxes[1]" (configChanged)="onAxisConfigChanged(1, $event)"
                [chartType]="chartType">
              </app-axis-config>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="chartType === 'pie' || chartType === 'table'">
        <app-axis-config [config]="config.yAxes[0]" (configChanged)="onAxisConfigChanged(0, $event)"
          [chartType]="chartType">
        </app-axis-config>
      </ng-container>
    </tab>
    <tab heading="Series" (selectTab)="getSeries()" *ngIf="!((chartType === 'pie' || chartType === 'table') && (selectedSensorTypes.length > 1 ||
      (dashboardService.getServers()?.length > 1 && dataSourcesFormGroup.value.server === null)))">
      <div class="p-2" *ngIf="dataSeriesFormGroup" [formGroup]="dataSeriesFormGroup">
        <div formArrayName="dataSeries">
          <div *ngFor="let formGroup of dataSeriesFormGroup.controls.dataSeries.controls; let i = index">
            <div [formGroup]="formGroup" class="form-inline">
              <app-color-picker-button *ngIf="chartType !== 'table'" [color]="formGroup.value.color"
                (colorChanged)="onSeriesColorChanged(i, $event)">
              </app-color-picker-button>
              <input type="text" class="form-control mb-2 ml-sm-2 flex-grow-1" formControlName="label"
                [placeholder]="formGroup.value.id">

              <select class="form-control mb-2 ml-sm-2" formControlName="yAxis"
                *ngIf="(chartType !== 'pie' && chartType !== 'table') && config.isCustomQuery !== true">
                <option [ngValue]="0">Left Y Axis</option>
                <option [ngValue]="1">Right Y Axis</option>
              </select>
            </div>
          </div>
        </div>
        <div class="alert alert-info" *ngIf="dataSeriesFormGroup.controls.dataSeries.controls.length < 1">
          No data sources have been configured yet.
        </div>
      </div>
    </tab>
  </tabset>
</ng-container>

<ng-template #loadingTemplate>
  <div class="text-center">
    <i class="icon icon-circle-notch icon-spin icon-2x"></i>
  </div>
</ng-template>
