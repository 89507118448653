/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../../../shared/shared.module';
import { MonitoringModule } from '../monitoring.module';
import { DashboardBuiltinPlugins } from './dashboard-builtin-plugins';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardPanelConfigComponent, DashboardPanelConfigContentDirective } from './dashboard-panel-config/dashboard-panel-config.component';
import { DashboardPanelComponent, DashboardPanelContentDirective } from './dashboard-panel/dashboard-panel.component';
import { DashboardTimeControlComponent } from './dashboard-time-control/dashboard-time-control.component';
import { DashboardService } from './dashboard.service';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    MonitoringModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BsDropdownModule,
    TooltipModule,
    PopoverModule,

    ...DashboardBuiltinPlugins.getPluginModules()
  ],
  declarations: [
    DashboardListComponent,
    DashboardComponent,
    DashboardPanelComponent,
    DashboardPanelContentDirective,
    DashboardPanelConfigComponent,
    DashboardPanelConfigContentDirective,
    DashboardTimeControlComponent
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule { }
