<nav class="navbar navbar-expand {{topnavLinks}} bg-dark fixed-top {{topnavDiv}}" *ngIf="user">
  <a class="navbar-brand text-center" [routerLink]="['/dashboard','groups','0']">
    <!-- <img class="ml-3" src="assets/img/logo.png" width="132" height="36" *ngIf="productType=='Informix HQ'"> -->
    <img class="ml-3" [src]="'assets/img/logo/' + themeLogo" width="132" height="36">
  </a>
  <ul class="navbar-nav ml-auto li-list">
    <li *ngIf="user.isSuperAdmin()">
      <span class="nav-link {{topnavText}}">
        <button class="btn btn-danger outline-override"
            tooltip="Click here to shutdown {{productType}} server" [disabled]="shutdowBtnLoader" (click)="shutdownModal()">
            <i class="icon icon-circle-notch icon-spin text-white" *ngIf="shutdowBtnLoader"></i>
            <i class="icon icon-power" *ngIf="!shutdowBtnLoader"></i> Shutdown
        </button>
      </span>
    </li>
    <li class="dropdown nav-item" dropdown>
      <span class="nav-link">
        <a class="nav-link dropdown-toggle" (click)="showAgentsList = !showAgentsList" dropdownToggle>Agents
          <b class="caret"></b>
        </a>
      </span>
      <app-agent-list [style.display]="showAgentsList?'block':'none'" [enableAgentView]="showAgentsList"
        (changeEvent)="agentViewChnage($event)"></app-agent-list>
    </li>
    <li>
      <span class="nav-link">
        <div class="mr-3">
          <span class="pr-2">Light</span>
            <label class="switch">
              <input type="checkbox" (change)="toggleTheme($event.target.checked)" [checked]="isDarkTheme">
              <span class="slider round"></span>
            </label>
          <span class="pl-2 ml-1">Dark</span>
        </div>
        </span>
      </li>
    <li *ngIf="appBuildInfo" class="dropdown nav-item" dropdown>
      <span class="nav-link">
        <a class="nav-link dropdown-toggle" id="infoDropDownMenu" dropdownToggle>
          <i class="icon icon-info-circle"></i>
        </a>
      </span>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="infoDropDownMenu">
        <li class="dropdown-item-plain">
          <div>
            <h5 class="media-heading label-danger">
              <strong>About {{productType}}</strong>
            </h5>
            <p class="text-muted">
              Version: {{appBuildInfo.version}}
              <br /> Build Timestamp: {{appBuildInfo.buildDate}}
            </p>
          </div>
        </li>
      </ul>
    </li>

    <li class="dropdown nav-item" dropdown #userDrop="bs-dropdown" autoClose="disabled">
      <span class="nav-link">
        <a id="single-button" class="nav-link dropdown-toggle" dropdownToggle>
          <i class="icon icon-user"></i> {{user.name}}
          <b class="caret"></b>
        </a>
      </span>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="single-button">
        <li>
          <a [routerLink]="['user-settings']" [routerLinkActive]="['router-link-active']" class="dropdown-item"
            (click)="userDrop.hide()">
            My Settings
          </a>
        </li>
        <li *ngIf="user.isSuperAdmin()">
          <a [routerLink]="['system-settings']" [routerLinkActive]="['router-link-active']" class="dropdown-item"
            (click)="userDrop.hide()">
            System Settings
          </a>
        </li>
        <li class="divider"></li>
        <li>
          <a class="dropdown-item" href="javascript:;" (click)="logOut()">
            Log Out
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
<div bsModal #shutdownServerModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Confirm</h5>
        <button type="button" [disabled]="loader" class="close" (click)="shutdownServerModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body p-3 pt-4 pb-4">
        <div>
          <div class="pb-3">
            <i class="icon icon-warning text-warning pr-1"></i> Are you sure you want to shutdown the {{ productNameNoSpace }} server
            ?
          </div>
          <div class="pb-3 d-flex">
            <div class="pr-1"><i class="icon icon-info icon-info-circle text-info"></i></div>
            <div>To re-start {{ productNameNoSpace }} server, use startup scripts or execute <b>{{ productNameNoSpace }}-server.jar</b><br>
              For more details on starting {{ productNameNoSpace }} server, please <a
                href="https://www.ibm.com/docs/en/informix-servers/14.10?topic=guide-getting-started"
                target="_blank">click here</a></div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button type="button" [disabled]="loader" class="btn btn-secondary btn-fixed-width mr-1"
            (click)="shutdownServerModal.hide()">
            No
          </button>
          <button type="button" class="btn btn-primary mr-1" [disabled]="loader" (click)="shutdownServer()">
            Yes, Shutdown
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
