/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ParameterComponent } from './parameter.component';
import { DatePipe } from '@angular/common';
import { TaskSchedulerComponent } from './task-scheduler.component';
import { TaskSchedulerRunHistoryComponent } from './task-scheduler-run-history.component';
import { SchedulerComponent } from './scheduler.component';
import { TaskModalComponent } from './task-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BsDropdownModule,
    TooltipModule,
    TabsModule,
    SharedModule
  ],
  exports: [TaskModalComponent, ParameterComponent],
  declarations: [
    TaskSchedulerComponent,
    ParameterComponent,
    TaskSchedulerRunHistoryComponent,
    SchedulerComponent,
    TaskModalComponent
  ],
  providers: [
    DatePipe
  ],
})
export class TaskSchedulerModule { }
