<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    {{ 'schemaManager.dbCasts' | translate }}
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbCasts; else tableDataLoading" [data]="dbCasts" [rowsPerPage]="20"
      [searchLabel]="'schemaManager.dbCastsTab.searchLabel' | translate" sortBy="owner"
      [noDataAlertMessage]="'schemaManager.dbCastsTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbCastsTab.castType' | translate" property="cast_type"
        [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbCastsTab.fromType' | translate" property="from_type"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbCastsTab.toType' | translate" property="to_type"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbCastsTab.routineName' | translate" property="routine_name"
        [sortable]="true">
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>
