<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <ng-template [ngIf]="maximized">
    <div class="row">
      <div class="col-md-6">
        <h4 class="page-header">
          <strong>Storage Pool</strong>
          <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="dataLoading"></i>
        </h4>
      </div>
    </div>
    <ng-template [ngIf]="!dataLoadErrorMessage && !dataLoading">
      <div class="row">
        <div class="col-xl-6 mb-2 d-flex">
          <div class="card card-body">
            <h5 class="card-title">Storage Pool Information</h5>
            <div class="row mt-4">
              <div class="col-4 text-right">Space remaining in fixed entries:</div>
              <div class="col-8">{{spaceRemaining * 1024| byte}}</div>
            </div>
            <div class="row mt-2 mb-3">
              <div class="col-4 text-right">Number of extendable entries:</div>
              <div class="col-8">{{numExpandableEntries}}</div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 mb-2 d-flex">
          <div *ngIf="onconfig_sp_autoexpand && onconfig_sp_threshold && onconfig_sp_waittime" class="card card-body">
            <form #editPoolConfigForm="ngForm">
              <h5 class="card-title">
                Automatic Expansion Configuration
                <app-check-server-admin-permissions [server]="server" *ngIf="!isEditingConfiguration">
                  <button type="button" class="btn btn-primary btn-sm" title="Edit Configuration"
                    (click)="doEditConfiguration()" appPermissionDisable>
                    <i class="icon icon-edit"></i>
                  </button>
                </app-check-server-admin-permissions>
                <button *ngIf="isEditingConfiguration" type="button" class="btn btn-primary btn-sm" title="Save"
                  (click)="doUpdateConfiguration()"
                  [disabled]="!editPoolConfigForm.form.valid || !saveConfigButtonEnabled">
                  <i class="icon icon-save"></i>
                </button>
                <button *ngIf="isEditingConfiguration" type="button" class="btn btn-primary btn-sm" title="Undo"
                  (click)="doCancelEditConfiguration()">
                  <i class="icon icon-reset"></i>
                </button>
              </h5>
              <div class="row align-items-center">
                <div class="col-3 text-right">
                  <span
                    tooltip="Configures whether the database server adds storage space automatically when more space is needed.">
                    Automatic Expansion:
                  </span>
                </div>
                <div class="col-9">
                  <i *ngIf="!isEditingConfiguration && onconfig_sp_autoexpand.effective === '1'"
                    class="icon icon-check text-success" tooltip="Enabled"></i>
                  <i *ngIf="!isEditingConfiguration && onconfig_sp_autoexpand.effective === '0'"
                    class="icon icon-times text-danger" tooltip="Disabled"></i>
                  <app-checkbox *ngIf="isEditingConfiguration" [(ngModel)]="spAutoExpand"
                    (change)="validateEditPoolConfigForm()">
                  </app-checkbox>
                </div>
              </div>
              <div class="row mt-1 align-items-center">
                <div class="col-3 text-right">
                  <span
                    tooltip="The minimum amount of free space allowed in a storage space before the space is automatically expanded. Specify the free space as a percentage of the total space or as a specific size in kilobytes.">
                    Free space threshold:&nbsp;
                  </span>
                </div>
                <div class="col-9 form-inline">
                  <span *ngIf="!isEditingConfiguration">{{spThresholdAsString}}</span>
                  <input class="form-control form-control-sm" *ngIf="isEditingConfiguration" name="spThresholdInput"
                    type="number" [(ngModel)]="spThreshold" required
                    [min]="(spThresholdUnit === 'Off') ? 0 : (spThresholdUnit === '%') ? 1 : 1000"
                    [max]="(spThresholdUnit === '%') ? 50 : ''" [disabled]="spThresholdUnit === 'Off'"
                    (change)="validateEditPoolConfigForm()">
                  <select *ngIf="isEditingConfiguration" name="spThresholdUnitSelect"
                    class="form-control form-control-sm ml-2" [(ngModel)]="spThresholdUnit"
                    (change)="handleSpThresholdUnitChange()">
                    <option *ngFor="let option of spThresholdOptions">{{option}}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-1 align-items-center">
                <div class="col-3 text-right">
                  <span
                    tooltip="The time in seconds that a thread waits for a storage space to expand before the database server returns an out-of-space error.">
                    Wait Time:&nbsp;
                  </span>
                </div>
                <div class="col-9 form-inline">
                  <span *ngIf="!isEditingConfiguration">{{onconfig_sp_waittime.effective}}</span>
                  <input class="form-control form-control-sm" *ngIf="isEditingConfiguration" name="spWaitTimeInput"
                    type="number" min="0" [(ngModel)]="spWaitTime" (change)="handleSpThresholdUnitChange()" required>
                  <label>&nbsp;seconds</label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>

    <div class="row mt-2" *ngIf="!dataLoadErrorMessage">
      <div class="col-md-8">
        <h5 *ngIf="maximized === true"><strong>Storage Pool Entries</strong></h5>
      </div>
      <div class="col-md-4" align="right" *ngIf="entries">
        <div class="btn-group" dropdown container="body" placement="bottom right">
          <button id="filter-button" type="button" class="btn btn-secondary dropdown-toggle" dropdownToggle>
            Filter <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="filter-button">
            <li role="menuitem"><a class="dropdown-item" (click)="setFilter('all')">All</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="setFilter('active')">Active</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="setFilter('error')">Error</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="setFilter('full')">Full</a></li>
          </ul>
        </div>
        <app-check-server-admin-permissions *ngIf="maximized" placement="top" [server]="server">
          <app-check-hdr-permissions [server]="server" [hdrPermission]="poolEntryHDR" placement="top">
            <button type="button" class="btn btn-primary" (click)="addEntryClicked()" appHDRPermission [hdrPermission]="poolEntryHDR" appPermissionDisable>Add
              Entry</button>
          </app-check-hdr-permissions>
        </app-check-server-admin-permissions>
        <button id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()"><i
            class="icon icon-refresh" title="Refresh"></i></button>
      </div>
    </div>

    <ng-template [ngIf]="dataLoadErrorMessage">
      <div class="alert alert-danger">
        Unable to retrieve storage pool information. <br>
        Error: {{dataLoadErrorMessage}}
      </div>
    </ng-template>

    <div class="mt-2" *ngIf="filteredEntries">
      <div class="table-responsive">
        <app-data-table [data]="filteredEntries" [rowsPerPage]="20">
          <app-data-table-column title="Path" property="path" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Offset" property="offset" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item * 1024| byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Space Remaining" property="space_remaining" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | storagePoolSpaceRemaining}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Status" property="status" [sortable]="true">
            <ng-template appTableCell let-item="item">
              <i class="icon icon-circle"
                [ngClass]="((item === 'Error') ? 'text-danger' : (item === 'Full') ? 'color-orange' : 'text-success')"
                title="{{item}}"></i>
              {{item}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Priority" property="priority" [sortable]="true">
            <ng-template appTableCell let-item="item">
              <i class="icon" [ngClass]="'icon-star' + ((item === 3) ? '-outline' : (item == 2) ? '-half' : '')"
                title="{{item | storagePoolPriority}}"></i>
              {{item | storagePoolPriority}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Chunk Size" property="chunk_size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item * 1024 | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Last Accessed" property="last_accessed" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column *ngIf="maximized" [sortable]="false">
            <ng-template appTableCell let-row="row">
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="poolEntryHDR" placement="top">
                  <button class="btn btn-sm btn-icon" tooltip="Modify Entry" (click)="modifyEntryClicked(row)"
                   appHDRPermission [hdrPermission]="poolEntryHDR" appPermissionDisable>
                    <i class="icon icon-edit"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="poolEntryHDR" placement="top">
                  <button tooltip="Delete Entry" class="btn btn-sm btn-icon" (click)="deleteEntryClicked(row)"
                   appHDRPermission [hdrPermission]="poolEntryHDR" appPermissionDisable>
                    <i class="icon icon-trash"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
    </div>
  <br />
</div>
<div bsModal #storagePoolModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div *ngIf="action !== ''">
          <h5 class="modal-title pull-left font-weight-bold">{{title}}</h5>
        </div>
        <button type="button" class="close" (click)="goToPoolList()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div [hidden]="action !== 'modify'">
          <app-modify-pool-entry [server]="server" [selectedEntry]="selectedEntry"
            (success)="handleActionSuccess($event)" (error)="handleActionError($event)" (close)="goToPoolList()">
          </app-modify-pool-entry>
        </div>
        <div [hidden]="action !== 'delete'">
          <app-delete-pool-entry [server]="server" [selectedEntry]="selectedEntry"
            (success)="handleActionSuccess($event)" (error)="handleActionError($event)" (close)="goToPoolList()">
          </app-delete-pool-entry>
        </div>
        <div [hidden]="action !== 'add'">
          <app-add-pool-entry [(server)]="server" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="goToPoolList()"></app-add-pool-entry>
        </div>
      </div>
    </div>
  </div>
</div>
