<ng-container *ngIf="databases else serverError">
  <div class="row mt-3">
    <div class="col-12 col-lg-4 col-xl-2 pr-0">
      <label>{{ 'database.selectDatabase' | translate }}</label>
      <select class="form-control" [(ngModel)]="selectedDB" (ngModelChange)="changeDatabase($event)">
        <option [disabled]="selectedDB" value="">-- {{ 'database.selectDatabase' | translate }} --</option>
        <option *ngFor="let db of databases" value="{{db}}">{{db}}</option>
      </select>
    </div>
  </div>
  <h6 class="font-weight-bold mt-3">{{ 'privileges.grantLabel' | translate }}</h6>
  <div class="row" [formGroup]="formGroup">
    <div class="col-12 col-lg-4 col-xl-2 mt-2 pr-0">
      <label>{{ 'privileges.userNameLabel' | translate }}</label>
      <input class="form-control" type="text" [placeholder]="'privileges.userNamePlaceHolder' | translate"
        formControlName="userName" tooltip="" appExtendedFormControl>
    </div>
    <div class="col-12 col-lg-4 col-xl-2 mt-2 pr-0">
      <label>{{ 'privileges.privilegeLabel' | translate }}</label>
      <select class="form-control" formControlName="selectedPrivilegeType">
        <option [disabled]="true" [selected]="true" [ngValue]="null">-- {{ 'privileges.privilegeLabel' | translate }}
          --</option>
        <option *ngFor="let privilegeType of privilegeTypes" value="{{privilegeType}}">{{privilegeType}}</option>
      </select>
    </div>
    <div class="col-12 col-lg-4 col-xl-2 mt-3">
      <div>
        <label></label>
      </div>
      <app-check-server-admin-permissions [server]="server" placement="top">
        <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
          <button type="button" class="btn btn-primary" [disabled]="!formGroup.valid" (click)="grant()"
            appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable>{{ 'button.grant' | translate }}</button>
        </app-check-hdr-permissions>
      </app-check-server-admin-permissions>
    </div>
  </div>
  <br>
  <h5>{{ 'privileges.databasePrivileges.privilegeLabel' | translate }}</h5>
  <app-data-table *ngIf="userList; else userListIsLoading" [data]="userList" [rowsPerPage]="10" [searchLabel]="'dataTable.searchLabel' | translate">
    <app-data-table-column [title]="'dataTable.columnTitle.userName' | translate" property="userName" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'privileges.table.columnTitle.privilege' | translate" property="privilegeType"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-privilegedUser="row">
        <div [hidden]="!privilegedUser.isModifyVisible">
          <span> {{privilegedUser.privilegeType}} </span>
          <span>
            <app-check-server-admin-permissions [server]="server" placement="top">
              <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
                <button type="button" class="btn btn-sm btn-icon" [tooltip]="'tooltip.modify' | translate" (click)="toggleModifyVisible(privilegedUser)"
                  appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable>
                  <i class="icon icon-edit"></i>
                </button>
              </app-check-hdr-permissions>
            </app-check-server-admin-permissions>
          </span>
        </div>
        <div class="row" [hidden]="privilegedUser.isModifyVisible">
          <div class="col-12 col-lg-6 col-xl-3">
            <select class="form-control form-control-sm" [(ngModel)]="privilegedUser.selectedModifyType">
              <option *ngFor="let privilegeType of privilegeTypes">{{privilegeType}}</option>
            </select>
          </div>
          <div class="col-12 col-lg-4">
            <button type="button" class="btn btn-icon btn-sm ml-1" [tooltip]="'button.save' | translate" (click)="save(privilegedUser)">
              <i class="icon icon-check"></i>
            </button>
            <button type="button" class="btn btn-sm btn-icon" (click)="toggleModifyVisible(privilegedUser)" [tooltip]="'button.cancel' | translate">
              <i class="icon icon-times"></i></button>
          </div>
        </div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'privileges.table.columnTitle.defaultRole' | translate" property="defaultRole"
      [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'privileges.table.columnTitle.revoke' | translate">
      <ng-template appTableCell let-privilegedUser="row">
        <div>
          <app-check-server-admin-permissions [server]="server" placement="top">
            <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
              <button type="button" class="btn btn-icon btn-sm" (click)="revoke(privilegedUser)"  appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable>
                <i class="icon icon-trash"></i>
              </button>
            </app-check-hdr-permissions>
          </app-check-server-admin-permissions>
        </div>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
  <ng-template #userListIsLoading>
    <div class="d-flex justify-content-center mt-5">
      <i class="icon icon-circle-notch icon-2x icon-spin"></i>
    </div>
  </ng-template>
</ng-container>

<ng-template #serverError>
  <div class="mt-2 alert alert-danger" *ngIf="apiError">
    Unable to retrieve database privileges information. <br> Error: {{apiError}}
  </div>
</ng-template>
