/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from '../../../shared/shared.module';
import { DatabaseInfoComponent } from './database-info/database-info.component';
import { DatabaseComponent } from './database/database.component';
import { JSONExplorerComponent } from './json-explorer.component';
import { JSONTreeComponent } from './json-tree.component';
import { SchemaManagerComponent } from './schema-manager/schema-manager.component';
import { SchemaService } from './schema.service';
import { SQLConsoleComponent } from './sql-console/sql-console.component';
import { SuperSQLService } from './sql-console/supersql/supersql.service';
import { TableInfoPageComponent } from './table-info-page/table-info-page.component';
import { TableListComponent } from './table-list/table-list.component';
import { QueryResultTableComponent } from './query-result-table/query-result-table.component';
import { CreateIndexComponent } from './create-index/create-index.component';
import { CreateQueryComponent } from './create-index/create-query/create-query.component';
import { IndexOptionsComponent } from './create-index/index-options/index-options.component';
import { TableStatisticsInfoComponent } from './table-info-page/table-statistics-info/table-statistics-info.component';
import { TableIndexesInfoComponent } from './table-info-page/table-indexes-info/table-indexes-info.component';
import { TableColumnsInfoComponent } from './table-info-page/table-columns-info/table-columns-info.component';
import { TableReferencesInfoComponent } from './table-info-page/table-references-info/table-references-info.component';
import { TablePrivilegesInfoComponent } from './table-info-page/table-privileges-info/table-privileges-info.component';
import { TableConstraintsInfoComponent } from './table-info-page/table-constraints-info/table-constraints-info.component';
import { TableFragmentsInfoComponent } from './table-info-page/table-fragments-info/table-fragments-info.component';
import { TableTriggersInfoComponent } from './table-info-page/table-triggers-info/table-triggers-info.component';
import { TableInfoComponent } from './table-info-page/table-info/table-info.component';
import { CreateDatabaseComponent } from './create-database/create-database.component';
import { CreateDemoDatabaseModalComponent } from './create-database/create-demo-database-modal.component';
import { DatabaseProcedureComponent } from './database-procedure/database-procedure.component';
import { DatabaseSequenceComponent } from './database-sequence/database-sequence.component';
import { DatabaseUserComponent } from './database-user/database-user.component';
import { DatabaseDatabladeComponent } from './database-datablade/database-datablade.component';
import { DatabaseAggregatesComponent } from './database-aggregates/database-aggregates.component';
import { DatabaseCastsComponent } from './database-casts/database-casts.component';
import { DatabaseOperatorComponent } from './database-operator/database-operator.component';
import { SchemaPrivilegesComponent } from './schema-privileges/schema-privileges.component';
import { StorageSchemeComponent } from './create-index/storage-scheme/storage-scheme.component';
import { AddValuesModalComponent } from './create-index/add-values-modal/add-values-modal.component';
import { AddExpressionModalComponent } from './create-index/add-expression-modal/add-expression-modal.component';
import { CreateTableComponent } from './create-table/create-table.component';
import { ColumnModalComponent } from './create-table/column-modal.component';
import { AddConstraintComponent } from './create-table/add-constraints/add-constraint.component';
import { ForeignKeyModalComponent } from './create-table/add-constraints/foreign-key-modal.component';
import { UniqueKeyModalComponent } from './create-table/add-constraints/unique-key-modal.component';
import { TableAdvOptionsComponent } from './create-table/advance-options/table-adv-options.component';
import { ExternalOptionsComponent } from './create-table/advance-options/external-options.component';
import { CreateTableQueryComponent } from './create-table/create-table-query/create-table-query.component';
import { SqltraceModule } from '../sqltrace/sqltrace.module';

@NgModule({
  declarations: [
    SQLConsoleComponent,
    JSONExplorerComponent,
    JSONTreeComponent,
    SchemaManagerComponent,
    DatabaseComponent,
    DatabaseInfoComponent,
    TableInfoPageComponent,
    TableListComponent,
    QueryResultTableComponent,
    CreateIndexComponent,
    CreateQueryComponent,
    IndexOptionsComponent,
    TableStatisticsInfoComponent,
    TableIndexesInfoComponent,
    TableColumnsInfoComponent,
    TableReferencesInfoComponent,
    TablePrivilegesInfoComponent,
    TableConstraintsInfoComponent,
    TableFragmentsInfoComponent,
    TableTriggersInfoComponent,
    TableInfoComponent,
    CreateDatabaseComponent,
    CreateDemoDatabaseModalComponent,
    DatabaseProcedureComponent,
    DatabaseSequenceComponent,
    DatabaseUserComponent,
    DatabaseDatabladeComponent,
    DatabaseAggregatesComponent,
    DatabaseCastsComponent,
    DatabaseOperatorComponent,
    SchemaPrivilegesComponent,
    StorageSchemeComponent,
    AddValuesModalComponent,
    AddExpressionModalComponent,
    CreateTableComponent,
    ColumnModalComponent,
    AddConstraintComponent,
    ForeignKeyModalComponent,
    UniqueKeyModalComponent,
    TableAdvOptionsComponent,
    ExternalOptionsComponent,
    CreateTableQueryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PopoverModule,
    TabsModule,
    TooltipModule,
    BsDropdownModule,
    BsDatepickerModule,
    SqltraceModule
  ],
  exports: [
    JSONExplorerComponent,
    QueryResultTableComponent
  ],
  providers: [
    SchemaService,
    SuperSQLService
  ]
})
export class SchemaModule { }
