/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Observable } from 'rxjs';
import { InformixSQLSession } from './informix-sql-session';
import { QueryResultPages } from './query-results';
import { SchemaService } from './schema.service';

export abstract class AbstractQuery {
  runTime: number = null;

  constructor(
    public restdb: SchemaService,
    public session: InformixSQLSession,
    public query: string
  ) { }

  public abstract hasNext(): boolean;
  public abstract next(): Observable<QueryResultPages>;
  public abstract setParams([]: any);
  public abstract setHasNext(hasMore: boolean, resultSetId: number);
}
