/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SidebarElement } from '../../shared/sidebar/sidebar';
import { InformixServerGroup } from './informixServerGroup';
import { UserService } from '../../shared/user/user.service';
import { User } from '../../shared/user/user';

@Component({
  selector: 'app-groups-component',
  templateUrl: 'groups.html',
  styles: ['.overflow-container { overflow-x: visible; }']
})
export class GroupsComponent implements OnInit {

  groupSidebar: SidebarElement[] = null;

  constructor(private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.userService.getCurrentUser().then(user => {
        this.buildSidebar(data.group, user);
      }).catch(err => this.buildSidebar(data.group));
    });
  }

  private buildSidebar(group: InformixServerGroup, user?: User) {
    if (!group) {
      return;
    }

    this.groupSidebar = [
      {
        label: 'Dashboards',
        link: 'dashboards',
        workOffline: true
      },
      {
        label: 'Monitoring',
        link: 'monitoring',
        workOffline: true
      },
      {
        label: 'Alerting',
        link: 'alerting',
        workOffline: true
      },
      {
        label: 'Permissions',
        link: 'permissions',
        hidden: !group.permissions.admin,
        workOffline: true
      },
      {
        label: 'Incidents',
        link: 'incidents',
        searchKeywords: ['alert'],
        workOffline: true
      },
      {
        label: 'Monitoring Server Logs',
        link: 'logs',
        searchKeywords: ['logs'],
        hidden: user && !user.isSuperAdmin(),
        workOffline: true
      }
    ];
  }
}
