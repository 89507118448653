<ng-template #guideModal>
    <div class="step"  [ngClass]="{active: currentStep === i}"  *ngFor="let step of currentGuide.steps; let i = index; let isLast = last; let isFirst = first;">
        <div class="modal-body p-0">
            <div class="progress bg-gray-20 w-100" [style.height.rem]="0.4">
                <div class="progress-bar" [style.width.%]="(step.number/(currentGuide.totalSteps))*100"></div>
            </div> 
            <div class="d-flex px-4 py-5" >
                <div *ngIf="step?.headingOnly; else fullStep;" class="w-100">
                    <div class="content pr-2 heading-only">
                        <h1 align="center" class="w-100"><strong>{{step?.text}}</strong></h1>
                    </div>
                </div>
                <ng-template #fullStep>
                    <div class="content pr-2">
                        <div class="step bg-warning d-inline-block p-2 mb-4">
                            <h4 class="m-0"><strong>Step {{ step?.number }}</strong> </h4>
                        </div>
                        <div class="text">
                            <h5 [innerHTML]="step?.text" [style.fontWeight]="'bolder'"></h5> 
                        </div>
                    </div>
                    <div class="screenshot">
                        <img [src]="step.image" alt="step-{{step?.number}}" [style.width.px]="474" [style.height.px]="354">
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex flex-1">
                <button type="button" class="btn btn-secondary btn-fixed-width mr-1" (click)="hideModal()">
                    Close
                </button>
            </div>
            
            <button type="button" class="btn btn-primary mr-1" (click)="prevStep();" [disabled]="isFirst">
                Previous
            </button>
            <button type="button" class="btn btn-primary mr-1" (click)="nextStep();" [disabled]="isLast">
                Next
            </button>
        </div>
    </div>
</ng-template>
