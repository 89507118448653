/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformixServer } from '../informixServer';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { ConnectionManagerInfo, ConnectionUnit } from './connection-manager.model';
import { ConnectionManagerService } from './connection-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-connection-manager',
  templateUrl: 'connection-manager.html'
})
export class ConnectionManagerComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  server: InformixServer = null;

  cmInfoList: Array<ConnectionManagerInfo>;
  cmError: string;

  dataLoading = false;

  constructor(private route: ActivatedRoute,
    private connectionManagerService: ConnectionManagerService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.dataLoading = true;
      this.connectionManagerService.getConnectionManagerInfo(data.server.id, true).subscribe(cmInfoList => {
        this.dataLoading = false;
        this.cmInfoList = cmInfoList;
      }, err => {
        this.dataLoading = false;
        this.cmError = err.error ? err.error.err : err;
      });
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get(['breadcrumb.replication.title', 'connectionManager.pageTitle'])
      .subscribe(breadcrumbStrings => {
        this.breadcrumb = ServerBreadcrumb.build(this.server,
          [{ name: breadcrumbStrings['breadcrumb.replication.title'] },
          { name: breadcrumbStrings['connectionManager.pageTitle'] }]);
      });
  }

  cmExpanded(cm: ConnectionManagerInfo): boolean {
    return cm.isExpand;
  }

  getConnectionUnitNames(cmUnitList: Array<ConnectionUnit>) {
    const cmUnitNames = [];
    if (cmUnitList) {
      cmUnitList.forEach(cmUnit => {
        cmUnitNames.push(' ' + cmUnit.type.charAt(0).toUpperCase() + cmUnit.type.substr(1).toLowerCase());
      });
    }
    return cmUnitNames;
  }
}
