/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GROUP_ROOT_ID, InformixServerGroup } from '../../dashboard/groups/informixServerGroup';
import { InformixServerGroupService } from '../../dashboard/groups/informixServerGroup.service';
import { InformixTreeItem } from '../../dashboard/informixTreeItem';
import { InformixServer } from '../../dashboard/servers/informixServer';

export interface BrowserListItem {
  item: InformixTreeItem;
  hidden: boolean;
}

export interface GroupBrowserConfig {
  groupMapper?: (group: InformixServerGroup) => BrowserListItem;
  serverMapper?: (server: InformixServer) => BrowserListItem;
  showServers?: boolean;
}

@Component({
  selector: 'app-group-browser',
  templateUrl: 'group-browser.component.html'
})
export class GroupBrowserComponent implements OnInit {

  @Input() startingItem: InformixTreeItem;
  @Input() config: GroupBrowserConfig;
  @Input() maxSelectionCount = 1;
  @Input() selectedServers: InformixServer[] = null;

  @Output() currentGroupChange = new EventEmitter<InformixServerGroup>();
  @Output() serverSelect = new EventEmitter<InformixServer>();
  @Output() serverDeselect = new EventEmitter<InformixServer>();
  @Output() selectionChanged = new EventEmitter<InformixServer[]>();

  rootGroup: InformixServerGroup = null;
  currentGroup: InformixServerGroup = null;

  childGroups: BrowserListItem[] = null;
  childServers: BrowserListItem[] = null;

  private selectedServerIds = new Set<number>();

  constructor(private groupService: InformixServerGroupService) {

  }

  ngOnInit() {
    let showServers = true;
    if (this.config) {
      if (typeof this.config.showServers === 'boolean') {
        showServers = this.config.showServers;
      }
    }

    this.groupService.getGroup(GROUP_ROOT_ID, -1, true, showServers).then(group => {
      this.rootGroup = group;
      if (this.startingItem) {
        const groupId = this.startingItem instanceof InformixServerGroup ? this.startingItem.id : this.startingItem.parentGroupId;
        const startingGroup = this.rootGroup.getGroup(groupId) || this.rootGroup;
        this.navigateToGroup(startingGroup);
      } else {
        this.navigateToGroup(this.rootGroup);
      }

      if (this.selectedServers) {
        this.selectedServers.map(v => this.rootGroup.getServer(v.id)).forEach(server => {
          if (server) {
            this.selectedServerIds.add(server.id);
          }
        });
      }
    });
  }

  navigateBack() {
    this.navigateToGroup(this.currentGroup.parent);
  }

  navigateToGroup(group: InformixServerGroup) {
    this.currentGroup = group;
    this.currentGroup.groups.sort((a, b) => a.name.localeCompare(b.name));

    if (this.config && this.config.groupMapper) {
      this.childGroups = this.currentGroup.groups.map(this.config.groupMapper);
    } else {
      this.childGroups = this.currentGroup.groups.map(child => ({
        item: child,
        hidden: !child.permissions.read
      }));
    }

    this.currentGroupChange.emit(this.currentGroup);

    if (group.servers.length) {
      group.servers.sort((a, b) => a.name.localeCompare(b.name));
      let serverMapper: (server: InformixServer) => BrowserListItem = (server: InformixServer) => ({
          item: server,
          hidden: !server.permissions.read
        });

      if (this.config && this.config.serverMapper) {
        serverMapper = this.config.serverMapper;
      }

      this.childServers = group.servers.map(serverMapper);
    } else {
      this.childServers = [];
    }
  }

  onServerClick(server: InformixServer) {
    if (this.isSelected(server)) {
      this.selectedServerIds.delete(server.id);
      this.serverDeselect.emit(server);
    } else if (this.selectedServerIds.size < this.maxSelectionCount) {
      this.selectedServerIds.add(server.id);
      this.serverSelect.emit(server);
    }

    this.selectionChanged.emit(Array.from(this.selectedServerIds.values()).map(id => this.rootGroup.getServer(id)));
  }

  isSelected(server: InformixServer) {
    return this.selectedServerIds.has(server.id);
  }
}
