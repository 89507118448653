/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { AlertOperandType } from './alert-operand-types';
import { EventEmitter, OnInit, Directive } from '@angular/core';

import { InformixTreeItem } from '../../informixTreeItem';
import { AlertOperand } from '../alerting-profile';

export interface OperandEvent {
  operand: AlertOperand;
  valid: boolean;
  dataType: string;
  unit: string;
  triggers: string[];
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractAlertOperand implements OnInit {
  protected type: AlertOperandType;
  protected operand: AlertOperand;
  protected dataTypeFilter: string = null;
  protected unit: string = null;

  public owner: InformixTreeItem;
  public eventUpdate = new EventEmitter<OperandEvent>();

  ngOnInit() {
    setTimeout(() => this.emitUpdate(), 0);
  }

  public setType(type: AlertOperandType) {
    this.type = type;
  }

  public setOperand(operand: AlertOperand) {
    this.operand = operand;
  }

  public setDataTypeFilter(dataType: string) {
    this.dataTypeFilter = dataType;
  }

  public setUnit(unit: string) {
    this.unit = unit;
  }

  public emitUpdate() {
    const operand = this.getValue();
    operand.type = this.type.type;

    this.eventUpdate.emit({
      operand,
      valid: this.isValid(),
      dataType: this.getOutputType(),
      unit: this.getOutputUnit(),
      triggers: this.type.triggers || null
    });
  }

  protected getOutputType(): string {
    return this.type.outputType;
  }

  protected getOutputUnit(): string {
    return null;
  }

  public abstract isValid(): boolean;
  public abstract getValue(): any;
}
