<div class="container-fluid">
  <div *ngIf="selectedEntry">
    <form>
      <div class="form-group row">
        <div class="col-sm-12">
          <i class="icon icon-warning text-warning" title="Warning"></i>
          <label class="col-form-label">&nbsp; This action deletes the storage pool entry at
            <strong>{{selectedEntry.path}}</strong>. Do you want to continue?</label>
        </div>
      </div>
      <div class="form-group row d-flex pt-2 flex-row-reverse">
        <div class="col-sm-3 d-flex justify-content-end">
          <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="doDeleteEntry()">Delete</button>
        </div>
      </div>
    </form>
  </div>
</div>
