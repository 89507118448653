/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { InformixServer } from '../informixServer';
import { SchemaService } from '../schema/schema.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { PrivilegesService } from './privileges.service';
import { TablePrivilegeInfo, PrivilegeType } from './privileges.model';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { IACValidators } from '../../../shared/forms/validators';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-table-privileges',
  templateUrl: 'table-privileges.html',
  styleUrls: ['table-privileges.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TablePrivilegesComponent implements OnInit {

  @Input() server: InformixServer = null;
  privilegeFormGroup: UntypedFormGroup;
  databases: string[];
  tableNames: string[] = [];
  tablePrivilegeInfos: TablePrivilegeInfo[];
  selectedDB = '';
  privilegeTypes: Array<PrivilegeType> = [{ typeName: 'Select', class: 'bg-teal-50 color-white-primary', type: 'S', checked: false },
  { typeName: 'Update', class: 'bg-ruby-50 color-white-primary', type: 'U', checked: false },
  { typeName: 'Insert', class: 'bg-aqua-60 color-white-primary', type: 'I', checked: false },
  { typeName: 'Delete', class: 'bg-lime-60 color-white-primary', type: 'D', checked: false },
  { typeName: 'Index', class: 'bg-violet-60 color-white-primary', type: 'X', checked: false },
  { typeName: 'Alter', class: 'bg-gold-60 color-white-primary', type: 'A', checked: false },
  { typeName: 'References', class: 'bg-blue-60 color-white-primary', type: 'R', checked: false },
  { typeName: 'Under', class: 'bg-ruby-80 color-white-primary', type: 'N', checked: false }];
  selectedPrivilegeTypes: string[];
  selectedModifyPrivilegeTypes: string[] = [];
  priviligesHDR: HDRPermission;
  apiError: string;
  constructor(
    private restdb: SchemaService,
    private notificationsService: NotificationsService,
    private privilegesService: PrivilegesService,
    private confirmationDialogService: ConfirmationDialogService,
    private formBuilder: UntypedFormBuilder,
    private hdrPermissionService: HDRPermissionService
  ) { }

  ngOnInit() {
    this.getDatabaseList();
    const privilegeTypeControls = this.privilegeTypes.map(privilegeType => new UntypedFormControl(false));
    this.privilegeFormGroup = this.formBuilder.group({
      userName: new UntypedFormControl(null, [IACValidators.noWhitespaceValidator(), Validators.required]),
      selectedtableName: new UntypedFormControl(null, [Validators.required]),
      privilegeTypes: new UntypedFormArray(privilegeTypeControls, this.selectedPrivilegeTypesValidator())
    });
    this.priviligesHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  selectedPrivilegeTypesValidator() {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);
      return totalSelected >= 1 ? null : { required: true };
    };
    return validator;
  }

  grant() {
    if (!this.privilegeFormGroup.valid || (this.priviligesHDR && !this.priviligesHDR.isAllow())) {
      return;
    }
    const userName = this.privilegeFormGroup.value.userName.trim();
    const selectedPrivilegeTypes =
      this.privilegeFormGroup.value.privilegeTypes.map((value, index) => value ? this.privilegeTypes[index].typeName : null)
        .filter(value => value !== null);
    const tablePrivilegeInfo = new TablePrivilegeInfo();
    tablePrivilegeInfo.tableName = this.privilegeFormGroup.value.selectedtableName;
    tablePrivilegeInfo.privilegeTypes = selectedPrivilegeTypes;
    tablePrivilegeInfo.grantee = userName;
    this.privilegesService.grantTablePrivileges(this.server, this.selectedDB, tablePrivilegeInfo)
      .subscribe(response => {
        this.getTablePrivilegeInfo();
        this.notificationsService.pushSuccessNotification('Grant successful for user ' + userName + '.');
        this.privilegeFormGroup.reset();
      }, err => {
        this.notificationsService.pushErrorNotification('Could not grant privilege. ' + (err.error ? err.error.err : err));
      });
  }

  editPrivilege(tabAuth: string[]) {
    if (this.priviligesHDR && !this.priviligesHDR.isAllow()) {
      return;
    }
    this.privilegeTypes.map(privilegeType => privilegeType.checked = false);
    tabAuth.forEach(privilege => {
      this.privilegeTypes.filter(privilegeType => privilegeType.type === privilege)[0].checked = true;
    });
  }

  save(tablePrivilegeInfo: TablePrivilegeInfo) {
    this.privilegeTypes.filter(type => type.checked === true).forEach(privilegeType => {
      this.selectedModifyPrivilegeTypes.push(privilegeType.typeName);
    });
    tablePrivilegeInfo.privilegeTypes = this.selectedModifyPrivilegeTypes;
    if (this.selectedModifyPrivilegeTypes.length > 0) {
      this.privilegesService.modifyTablePrivileges(this.server, this.selectedDB, tablePrivilegeInfo)
        .subscribe(response => {
          this.getTablePrivilegeInfo();
          this.notificationsService.pushSuccessNotification('Privileges saved for user ' + tablePrivilegeInfo.grantee + '.');
          this.selectedModifyPrivilegeTypes = [];
        }, err => {
          this.selectedModifyPrivilegeTypes = [];
          this.notificationsService.pushErrorNotification('Could not modify privilege. ' + (err.error ? err.error.err : err));
        });
    } else {
      this.revoke(tablePrivilegeInfo);
      this.getTablePrivilegeInfo();
    }
  }

  revoke(tablePrivilegeInfo: TablePrivilegeInfo) {
    if (this.priviligesHDR && !this.priviligesHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('Revoke all privileges for user ' + tablePrivilegeInfo.grantee + '?',
      () => this.onRevokeConfirmed(tablePrivilegeInfo));
  }

  onRevokeConfirmed(tablePrivilegeInfo: TablePrivilegeInfo) {
    this.privilegesService.revokeTablePrivileges(this.server, this.selectedDB, tablePrivilegeInfo)
      .subscribe(response => {
        this.getTablePrivilegeInfo();
        this.notificationsService.pushSuccessNotification('Privilege revoked for user ' + tablePrivilegeInfo.grantee + '.');
      }, err => {
        this.notificationsService.pushErrorNotification('Could not revoke privilege. ' + (err.error ? err.error.err : err));
      });
  }

  changeDatabase(event: string) {
    this.selectedDB = event;
    this.privilegeFormGroup.reset();
    this.getTablePrivilegeInfo();
  }

  private getDatabaseList() {
    this.restdb.getDatabaseNames(this.server).subscribe(databases => {
      this.databases = databases;
      if (this.databases.indexOf(this.selectedDB) === -1) {
        this.selectedDB = this.databases[0];
        this.getTablePrivilegeInfo();
      }
    }, err => {
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
      // this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  getColor(type: string) {
    return this.privilegeTypes.filter(privilegeType => privilegeType.type === type)[0].class;
  }

  private getTablePrivilegeInfo() {
    this.tableNames = [];
    this.privilegesService.getTablePrivilegeInfo(this.server, this.selectedDB).subscribe(tablePrivilegeInfos => {
      tablePrivilegeInfos.forEach((tbInfo: TablePrivilegeInfo) => {
        this.tableNames.push(tbInfo.tableName);
      });
      this.tableNames = this.tableNames.filter((tableName, index, tableNames) => index === tableNames.indexOf(tableName));
      this.tablePrivilegeInfos = tablePrivilegeInfos;
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }
}
