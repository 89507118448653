/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { SharedModule } from '../../../shared/shared.module';
import { MonitoringModule } from '../../monitoring/monitoring.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { InformixServerSystemService } from './informixServerSystem.service';
import { OSInfoComponent } from './os-info.component';
import { SystemInfoDashboardComponent } from './system-info-dashboard.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        MonitoringModule,
        RouterModule,
        BsDropdownModule,
        ProgressbarModule,
    ],
    exports: [],
    declarations: [
        OSInfoComponent,
        SystemInfoDashboardComponent
    ],
    providers: [
        InformixServerSystemService
    ]
})
export class SystemModule { }
