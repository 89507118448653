<div bsModal #addUniqueKeyModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{isEdit ? ('schemaManager.createTable.title.editUniqueKey' | translate) :
          ('schemaManager.createTable.title.addUniqueKey' | translate)}}</h5>
        <button type="button" class="close" (click)="closeModal()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <label class="font-weight-bold"><span class="text-danger">*
              </span>{{'schemaManager.createTable.label.uniqueKeyName' | translate}}</label>
            <input class="form-control" type="text"
              [placeholder]="'schemaManager.createTable.placeholder.uniqueKeyName' | translate" tooltip=""
              appExtendedFormControl [customErrors]="keyNameErrorHandlers" [formControl]="uniqueKeyName">
          </div>
          <div class="pt-2 pl-3 text-danger" *ngIf="isSameCols">
            {{'schemaManager.createTable.errorMsg.alreadyUniqueConstraint' | translate}}</div>
          <div class="pt-2 pl-3 text-danger" *ngIf="isSameAsPrimary">
            {{'schemaManager.createTable.errorMsg.sameAsPrimary' | translate}}</div>
        </div>
        <div class="row pt-2 align-items-center">
          <div class="col-lg-10 col-md-10 col-sm-10 col-9">
            <app-data-table [data]="columnList" [enableSelect]="true" [enableSearch]="false"
              [noDataAlertMessage]="'schemaManager.createTable.errorMsg.noColumnsAvailable' | translate"
              [title]="'schemaManager.createTable.tooltips.uniqueKey' | translate" [enablePagination]="false"
              isChecked="checked" (updateSelectedRows)="getSelectedColumns($event)" [dataMapFn]="allColsDataMap"
              [minHeight]="200">
              <app-data-table-column property="name"
                [title]="'schemaManager.createTable.table.columnTitle.availableColumns' | translate">
              </app-data-table-column>
            </app-data-table>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-3">
            <button type="button" class="btn btn-sm btn-icon" (click)="moveRows('upward')" [disabled]="isUpwardDisable">
              <i class="icon icon-arrow-up cursor-pointer font-weight-bold" [tooltip]="'button.moveUp' | translate"></i>
            </button><br />
            <button type="button" class="btn btn-sm btn-icon mt-1" (click)="moveRows('downward')"
              [disabled]="isDownwardDisable">
              <i class=" icon icon-arrow-down cursor-pointer font-weight-bold"
                [tooltip]="'button.moveDown' | translate"></i>
            </button>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12 text-right">
            <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">
              {{isEdit ? ('button.cancel' | translate) : ('button.close' | translate)}}</button>
            <button type="button" class="btn btn-primary" (click)="addKey()"
              [disabled]="selectedColumns.length < 1 || !uniqueKeyName.valid || isSameCols || isSameAsPrimary">
              {{isEdit ? ('button.save' | translate) : ('button.add' | translate)}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
