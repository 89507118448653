/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { PermissionsComponent } from './permissions.component';
import { PermissionsService } from './permissions.service';
import { PermissionsControlComponent } from './permissionsControl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ModalModule,
    TooltipModule
  ],
  declarations: [
    PermissionsComponent,
    PermissionsControlComponent
  ],
  providers: [
    PermissionsService
  ],
  exports: [
    PermissionsComponent
  ]
})
export class PermissionsModule { }
