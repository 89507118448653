View last:
<select [disabled]="disabled" [ngModel]="selectedTimeSlice" (ngModelChange)="changeSelectedTimeSlice($event)" class="form-control form-control-sm mx-2 d-inline-block" style="width: auto">
  <option *ngFor="let timeSlice of timeSlices" [ngValue]="timeSlice.name">{{timeSlice.name}}</option>
</select>
<ng-template [ngIf]="showPlayPause">
  <button class="btn btn-sm btn-secondary" [hidden]="isPaused" [disabled]="disabled" (click)="pauseData()">
    <i class="icon icon-pause"></i>
  </button>
  <button class="btn btn-sm btn-secondary" [hidden]="!isPaused" [disabled]="disabled" (click)="resumeData()">
    <i class="icon icon-play"></i>
  </button>
</ng-template>
