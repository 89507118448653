/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { DataTableRow } from '../../../../../shared/data-table/data-table.component';
import { ErrorMessageHandlers } from '../../../../../shared/forms/extendedFormControl.directive';
import { IACValidators } from '../../../../../shared/forms/validators';

@Component({
  selector: 'app-unique-modal',
  templateUrl: './unique-key-modal.component.html',
  styles: [`.table-height {  max-height: 230px;}`]
})
export class UniqueKeyModalComponent implements OnInit {

  @ViewChild('addUniqueKeyModal', { static: true }) addUniqueKeyModal: ModalDirective;
  @Input() uniqueKeys;
  @Output() uniqueKeyObj = new EventEmitter<any>();
  isEdit = false;

  columnList = [];
  selectedColumns = [];
  primaryColList = [];
  isSameCols = false;
  isSameAsPrimary = false;
  isUpwardDisable = true;
  isDownwardDisable = true;
  keyDetails: { row: any; index: number };

  uniqueKeyName: UntypedFormControl;
  keyNameErrorHandlers: ErrorMessageHandlers = {
    duplicateName: error => 'Key name already exists.',
    whitespace: error => 'Input field cannot be empty'
  };
  constructor() { }

  ngOnInit() {
    this.uniqueKeyName = new UntypedFormControl(null, [Validators.required, IACValidators.noWhitespaceValidator(),
    this.duplicateKeyNameValidator()]);
  }

  openModal(columnList: any, primaryColumns, keyDetails?: { row: any; index: number }) {
    this.isSameCols = false;
    this.isSameAsPrimary = false;
    this.isDownwardDisable = true;
    this.isUpwardDisable = true;
    this.columnList = columnList;
    this.keyDetails = keyDetails;
    this.primaryColList = primaryColumns;
    if (keyDetails && keyDetails.row) {
      this.isEdit = true;
      keyDetails.row.uniquekeyColumnName.forEach(name => {
        const index = this.columnList.map(col => col.name).indexOf(name);
        if (index > -1) {
          this.columnList.splice(index, 1);
        }
      });
      const cols = keyDetails.row.uniquekeyColumnName.map(col => ({ name: col, checked: true }));
      this.columnList.unshift(...cols);
      this.uniqueKeyName.setValue(keyDetails.row.uniqueKeyName);
    } else {
      this.isEdit = false;
    }
    this.addUniqueKeyModal.show();
  }

  closeModal() {
    this.selectedColumns = [];
    this.uniqueKeyName.setValue(null);
    this.uniqueKeyName.clearValidators();
    this.addUniqueKeyModal.hide();
  }

  getSelectedColumns(event) {
    this.selectedColumns = event;
    this.isSameAsPrimary = false;
    this.isUpwardDisable = true;
    this.isDownwardDisable = true;
    this.uniqueKeys.forEach(key => {
      if (event.length > 0 && key.uniquekeyColumnName.toString() === event.map(col => col.name).toString()) {
        setTimeout(() => {
          if (this.isEdit && this.keyDetails.row.uniquekeyColumnName.toString() === event.map(col => col.name).toString()) {
            this.isSameCols = false;
          } else {
            this.isSameCols = true;
          }
        }, 0);
      } else {
        this.isSameCols = false;
      }
    });
    if (event.length > 0 && event.map(col => col.name).toString() === this.primaryColList.map(col => col.name).toString()) {
      this.isSameAsPrimary = true;
    }
    if (this.selectedColumns.length === 1 && this.columnList.length > 1) {
      const index = this.columnList.map(col => col.name).indexOf(this.selectedColumns[0].name);
      if (index === this.columnList.length - 1) {
        this.isDownwardDisable = true;
        this.isUpwardDisable = false;
      } else if (index === 0) {
        this.isUpwardDisable = true;
        this.isDownwardDisable = false;
      } else {
        this.isDownwardDisable = false;
        this.isUpwardDisable = false;
      }
    } else {
      this.isDownwardDisable = true;
      this.isUpwardDisable = true;
    }
  }

  addKey() {
    const uniqueKeyObj = {
      uniqueKeyName: this.uniqueKeyName.value.trim(),
      uniquekeyColumnName: this.selectedColumns.map(col => col.name)
    };
    if (this.isEdit) {
      uniqueKeyObj['id'] = this.keyDetails.index;
    }
    this.uniqueKeyObj.emit(uniqueKeyObj);
    if (this.isEdit) {
      this.closeModal();
    } else {
      this.uniqueKeyName.setValue(null);
      this.columnList.map(col => col.checked = false);
      setTimeout(() => {
        this.columnList = this.columnList.slice();
      }, 0);
    }
  }

  allColsDataMap(column: any): DataTableRow {
    return {
      data: column,
      selected: column.checked,
      disabled: false,
      expanded: false
    };
  }

  duplicateKeyNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.uniqueKeys.length > 0 && this.uniqueKeys.map(key => key.uniqueKeyName).indexOf(control.value) > -1) {
        if (this.isEdit && control.value === this.keyDetails.row.uniqueKeyName) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

  moveRows(type: string) {
    const colName = this.selectedColumns[0].name;
    const fromIndex: number = this.columnList.map(col => col.name).indexOf(colName);
    const toIndex: number = (type === 'upward') ? fromIndex - 1 : fromIndex + 1;
    this.columnList.splice(fromIndex, 1);
    this.columnList.splice(toIndex, 0, this.selectedColumns[0]);
    setTimeout(() => {
      this.columnList.map(col => {
        if (col.name === colName) {
          col.checked = true;
        } else {
          col.checked = false;
        }
      });
      this.columnList = this.columnList.slice();
    }, 0);
  }
}
