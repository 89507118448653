/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-session-info-profile-stats',
  templateUrl: 'session-info-profile-stats.html'
})
export class SessionInfoProfileStatsComponent  {

  @Input() sessionProfile: any;

}
