<div class="container-fluid page-container" *ngIf="server">
  <div *ngIf="!isCreateQuery && !isIndexOptions">
    <div class="row bottomBorder">
      <div class="col-6">
        <h5><strong>{{ 'schemaManager.createIndex.pageTitle' | translate }}</strong>
          <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="!isIndexLoaded && !tableDetailsLoadError"></i>
        </h5>
      </div>
      <div class="col-6">
        <button type="button" class="float-right btn btn-sm btn-icon" (click)="closeCreateIndex.emit()">
          <i class="icon icon-times font-weight-bold"></i></button>
      </div>
    </div>
    <form *ngIf="createIndexForm && isIndexLoaded && !tableDetailsLoadError" [formGroup]="createIndexForm">
      <div class="row pt-3">
        <div class="col-12 col-sm-3">
          <label class="font-weight-bold"><span class="text-danger">* </span>
            {{ 'schemaManager.createIndex.indexName' | translate }}</label>
          <input class="form-control" type="text" formControlName="indexName"
            [placeholder]="'schemaManager.createIndex.indexNamePlaceholder' | translate" tooltip=""
            appExtendedFormControl>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-6 col-sm-3">
          <label class="font-weight-bold">{{ 'schemaManager.createIndex.indexType' | translate }}</label>
          <select class="form-control" formControlName="indexType" (change)="changeIndexType()">
            <option value="unique">{{ 'schemaManager.createIndex.uniqueIndex' | translate }}</option>
            <option value="cluster">{{ 'schemaManager.createIndex.clusterIndex' | translate }}</option>
            <option value="duplicate">{{ 'schemaManager.createIndex.duplicateIndex' | translate }}</option>
            <option value="fot">{{ 'schemaManager.createIndex.treesIndex' | translate }}</option>
            <option value="xml">{{ 'schemaManager.createIndex.xmlIndex' | translate }}</option>
            <option value="bts">{{ 'schemaManager.createIndex.textSearchIndex' | translate }}</option>
          </select>
        </div>
        <div class="col-6 col-sm-3" [title]="'schemaManager.createIndex.fillFactorDesc' | translate"
          *ngIf="indexType.value === 'unique' || indexType.value === 'cluster' || indexType.value === 'duplicate'">
          <label class="font-weight-bold">{{ 'schemaManager.createIndex.fillFactor' | translate }} (%)</label>
          <input type="number" placement="right" tooltip="" appExtendedFormControl formControlName="fillFactor"
            class="form-control w-25">
        </div>
      </div>
      <div class="row pt-3 ml-0" *ngIf="indexType.value === 'unique'">
        <app-checkbox [checked]="isConstraint" (click)="toggleConstraint()">
          {{ 'schemaManager.createIndex.addConstraint' | translate }}
        </app-checkbox>
      </div>
      <div *ngIf="isConstraint" class="py-3">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="font-weight-bold">{{ 'schemaManager.createIndex.constraintName' | translate }}</label>
            <input class="form-control" type="text" formControlName="constraintName"
              [placeholder]="'schemaManager.createIndex.constraintNamePlaceholder' | translate" tooltip=""
              appExtendedFormControl>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12 col-sm-4">
            <app-radio-group formControlName="constraintType">
              <app-radio value="primary" [disabled]="isPrimaryDisabled">
                {{ 'schemaManager.createIndex.addAsPrimaryConstraint' | translate }}</app-radio>
              <app-radio class="ml-2" value="unique">{{ 'schemaManager.createIndex.addAsUniqueConstraint' | translate }}
              </app-radio>
            </app-radio-group>
          </div>
        </div>
      </div>
      <div class="row alert alert-warning ml-0 mt-3" *ngIf="schemeErrorMsg">
        <i class="icon icon-warning text-warning pr-1"></i>{{schemeErrorMsg}}
      </div>
      <div class="row pt-2 no-gutters">
        <div class="col-11 col-sm-5">
          <app-data-table *ngIf="tableColumnsList && tableColumnsList.length > 0; else tableColumnsLoading"
            [data]="tableColumnsList" sortBy="name" [enableSelect]="columnsEnableSelect"
            (rowSelected)="onTableColumnsSelected($event)" [dataMapFn]="columnsDataMap" [minHeight]="380">
            <app-data-table-column [title]="'schemaManager.createIndex.tableColumnsTitle.tableColumn' | translate"
              property="name" [sortable]="true" [searchable]="true"></app-data-table-column>
            <app-data-table-column [title]="'schemaManager.createIndex.tableColumnsTitle.dataType' | translate"
              property="type" [sortable]="true"></app-data-table-column>
            <app-data-table-column>
              <ng-template appTableCell let-row="row">
                <i class="icon icon-chevron-right cursor-pointer font-weight-bold" *ngIf="columnsEnableSelect"
                  (click)="modifyRows('one', row, 'tableColumns')" [title]="'button.add' | translate"></i>
              </ng-template>
            </app-data-table-column>
          </app-data-table>
          <ng-template #tableColumnsLoading>
            <div class="d-flex justify-content-center "><i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
            </div>
          </ng-template>
        </div>
        <div class="col-1 my-auto text-center chevron-container">
          <button type="button" (click)="modifyRows('all', null, 'tableColumns')" class="btn btn-sm btn-icon mt-5"
            [disabled]="disableRightChevron" [title]="'button.add' | translate"><i
              class="icon icon-chevron-right cursor-pointer font-weight-bold"></i>
          </button><br />
          <button type="button" (click)="modifyRows('all', null, 'indexedColumns')" class="btn btn-sm btn-icon mt-1"
            [disabled]="disableLeftChevron" [title]="'button.remove' | translate"><i
              class="icon icon-chevron-left cursor-pointer font-weight-bold"></i>
          </button>
        </div>
        <div class="col-11 col-sm-5">
          <button type="button" class="btn btn-secondary mt-2 mb-2 float-right"
            (click)="modifyRows('clearIndexedColumns')" [disabled]="!indexedColumnsList[0].id">
            {{ 'schemaManager.createIndex.clearAll' | translate }}</button>
          <app-data-table *ngIf="indexedColumnsList && isReloadTable" [data]="indexedColumnsList"
            [enableSelect]="indexedColumnsSelect" (rowSelected)="onIndexedColumnsSelected($event)"
            [dataMapFn]="indexedDataMap" [minHeight]="380">
            <app-data-table-column property="id"></app-data-table-column>
            <app-data-table-column [title]="'schemaManager.createIndex.tableColumnsTitle.indexedColumns' | translate"
              property="indexedColumnName" [sortable]="true"></app-data-table-column>
            <app-data-table-column [title]="'schemaManager.createIndex.tableColumnsTitle.order' | translate"
              property="order"
              *ngIf="indexType.value === 'unique' || indexType.value === 'cluster' || indexType.value === 'duplicate'">
              <ng-template appTableCell let-row="row">
                <i class="icon-2x icon-sort-asc cursor-pointer font-weight-bold" *ngIf="row.order === 'ASC'"
                  (click)="row.order = 'DESC'" [title]="'schemaManager.createIndex.ascOrder' | translate"></i>
                <i class="icon-2x icon-sort-desc cursor-pointer font-weight-bold" *ngIf="row.order === 'DESC'"
                  (click)="row.order = 'ASC'" [title]="'schemaManager.createIndex.descOrder' | translate"></i>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'schemaManager.createIndex.hash' | translate" property="hash"
              *ngIf="indexType.value === 'fot'">
              <ng-template appTableCell let-row="row">
                <app-checkbox [checked]="row.hash" (click)="row.hash = !row.hash; validateHash('selectHash')"
                  *ngIf="(row.id !== null) && row.type && (row.type.toLowerCase().indexOf('lvarchar') === -1) && (row.type.toLowerCase().indexOf('boolean') === -1)">
                </app-checkbox>
              </ng-template>
            </app-data-table-column>
            <app-data-table-column>
              <ng-template appTableCell let-row="row">
                <i class="icon icon-chevron-left cursor-pointer font-weight-bold" *ngIf="indexedColumnsSelect"
                  (click)="modifyRows('one', row, 'indexedColumns')"
                  [title]="'button.remove' | translate"></i>
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
        <div class="col-1 my-auto text-center chevron-container ">
          <button type="button" class="btn btn-sm btn-icon mt-5"
            [disabled]="!indexedColumnsList[0].id || indexedColumnsList[0].isChecked || disableLeftChevron"
            (click)="moveRows('upward')">
            <i class="icon icon-arrow-up cursor-pointer font-weight-bold"
              [title]="'button.moveUp' | translate"></i>
          </button><br />
          <button type="button" class="btn btn-sm btn-icon mt-1"
            [disabled]="!indexedColumnsList[0].id || indexedColumnsList[indexedColumnsList.length-1].isChecked || disableLeftChevron"
            (click)="moveRows('downward')">
            <i class="icon icon-arrow-down cursor-pointer font-weight-bold"
              [title]="'button.moveDown' | translate"></i>
          </button>
        </div>
      </div>
      <div class="row mt-5 topBorder">
        <div class="col-12 pt-3">
          <button type="button" class="btn btn-primary" (click)="viewQuery()"
            [disabled]="!createIndexForm.valid || !indexedColumnsList[0].id || isDuplicateIndex || isCheckingDuplicateIndex">{{ 'schemaManager.createIndex.createQuery.viewSQL' | translate }}</button>
          <i class="icon icon-circle-notch icon-spin ml-1" *ngIf="isCheckingDuplicateIndex"></i>
          <button type="button" class="btn btn-secondary mr-2 ml-2" (click)="goToAdvanceOptions()"
            [disabled]="!createIndexForm.valid || !indexedColumnsList[0].id || isDuplicateIndex">{{ 'schemaManager.createIndex.advanceIndexOptions' | translate }}</button>
          <button type="button" class="btn btn-secondary" (click)="closeCreateIndex.emit()">
            {{'button.cancel' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
  <app-create-query *ngIf="isCreateQuery" [server]="server" [table]="createIndexData" [dataObj]="dataObj"
    (backFromQuery)="isCreateQuery = false" (closeCreateIndex)="closeCreateIndex.emit()"></app-create-query>
  <app-index-options *ngIf="isIndexOptions" [server]="server" [table]="createIndexData" [dataObj]="dataObj"
    (closeCreateIndex)="closeCreateIndex.emit()" (backToCreateIndex)="backFromIndexOptions($event)"></app-index-options>
  <div class="alert alert-danger mt-2" *ngIf="tableDetailsLoadError">{{tableDetailsLoadError}}</div>
</div>
