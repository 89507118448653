<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <ng-container *ngIf="cmUnitInfo else loading">
    <div class="row d-flex mb-3" *ngIf="cmUnitInfo.type === 'CLUSTER' && showSLAForm === false">
      <div class="col-12 mt-4">
        <h5 class="font-weight-bold">{{'connectionManager.foc' | translate}}</h5>
        <div class="card card-body card-style p-3">
          <div class="row">
            <form [formGroup]="focDetailsForm" class="col-6">
              <div class="pb-3">
                <div class="font-weight-bold w-25 d-inline-block">
                  {{'connectionManager.label.failoverTimeout' | translate}}</div><input type="number"
                  class="time-input-width pr-0 pl-2 ml-1" tooltip="" appExtendedFormControl formControlName="timeout"
                  min="0" max="59"> <span class="color-gray-30 pl-2">
                  {{'time.seconds' | translate}} </span>
                <span class="float-right" *ngIf="!isEdit">
                  <app-check-server-admin-permissions [server]="server" placement="top">
                    <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.modify' | translate" appPermissionDisable
                      (click)="editFOC()">
                      <i class="icon icon-edit"></i>
                    </button>
                  </app-check-server-admin-permissions>
                </span>
              </div>
              <div>
                <div class="font-weight-bold w-25 pb-3 d-inline-block">
                  {{'connectionManager.label.focOrder' | translate}}</div>
                <span class="color-gray-30"> {{'button.disable' | translate}} </span>
                <label class="switch">
                  <input type="checkbox" formControlName="order">
                  <span class="slider round"></span>
                </label>
                <span class="color-gray-30 pl-2 ml-1">{{'button.enable' | translate}}</span>
              </div>
              <div class="float-right" *ngIf="isEdit">
                <button class="btn btn-sm btn-primary" (click)="cancelEdit()" [disabled]="saveInProgress">
                  {{'button.cancel' | translate}}
                </button>
                <button class="btn btn-sm btn-primary" (click)="saveFOC()"
                  [disabled]="!focDetailsForm.valid || saveInProgress">
                  {{'button.save' | translate}}
                </button>
                <span *ngIf="saveInProgress" class="ml-2 d-inline-block">
                  <i class="icon icon-circle-notch icon-x icon-spin"></i>
                </span>
              </div>
            </form>
            <div class="col-6 border-left">
              <div class="font-weight-bold pb-2">{{'connectionManager.focInformation' | translate}}</div>
              <div class="d-inline-block pb-2">
                <strong>{{'connectionManager.order' | translate}}</strong>={{orderValue}}</div>
              <div class="pb-2">
                <strong>{{'connectionManager.priority' | translate}}</strong>={{focPriority}}</div>
              <div class="pb-2"><a [routerLink]="['../../onconfig']"
                  [queryParams]="{filterOption: 'HA_FOC_ORDER'}">{{'link.clickHere' | translate}}</a>
                {{'connectionManager.linkToChange' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="float-right mb-2" *ngIf="showSLAForm === false">
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button class="btn btn-primary btn-width" appPermissionDisable (click)="addSLA()">
          {{'connectionManager.addSLA' | translate}}
        </button>
      </app-check-server-admin-permissions>
    </div>
    <app-sla-form *ngIf="showSLAForm === true" (closeSLA)="closeSLA($event)" [cmName]="cmName"
      [cmUnitName]="cmUnitInfo?.cmUnitName" [cmUnitType]="cmUnitInfo.type" [server]="server"
      [slaInfoList]="cmUnitInfo?.slaInfoList" [slaToEdit]="slaToEdit">
    </app-sla-form>
    <ng-container *ngIf="showSLAForm === false">
      <h5 class="d-inline-block mb-0 mt-3 font-weight-bold">{{'connectionManager.slaHeading' | translate}}</h5>
      <app-data-table class="mt-2" *ngIf="cmUnitInfo.slaInfoList" [data]="cmUnitInfo.slaInfoList" [rowsPerPage]="10"
        [searchLabel]="'connectionManager.searchLabel.sla' | translate">
        <app-data-table-column [title]="'connectionManager.slaName' | translate" property="slaName" [sortable]="true"
          [searchable]="true">
        </app-data-table-column>
        <app-data-table-column [title]="'connectionManager.table.columnTitle.servers' | translate" property="dbServers"
          [sortable]="true">
        </app-data-table-column>
        <app-data-table-column [title]="'connectionManager.table.columnTitle.policy' | translate" property="policy"
          [sortable]="true">
        </app-data-table-column>
        <app-data-table-column [title]="'connectionManager.label.workers' | translate" property="workers"
          [sortable]="true">
        </app-data-table-column>
        <app-data-table-column [title]="'connectionManager.label.useAliases' | translate" property="useAliases"
          [sortable]="true">
        </app-data-table-column>
        <app-data-table-column [title]="'connectionManager.table.columnTitle.port' | translate" property="port"
          [sortable]="true">
        </app-data-table-column>
        <app-data-table-column [title]="'connectionManager.table.columnTitle.mode' | translate" property="mode"
          [sortable]="true">
        </app-data-table-column>
        <app-data-table-column>
          <ng-template appTableCell let-row="row">
            <app-check-server-admin-permissions [server]="server" placement="top">
              <button class="btn btn-sm btn-icon" [tooltip]="'connectionManager.modifySLA' | translate"
                appPermissionDisable (click)="editSLA(row.slaName)">
                <i class="icon icon-edit"></i>
              </button>
            </app-check-server-admin-permissions>
          </ng-template>
        </app-data-table-column>
        <app-data-table-column>
          <ng-template appTableCell let-row="row">
            <app-check-server-admin-permissions [server]="server" placement="top">
              <button class="btn btn-sm btn-icon" [tooltip]="'connectionManager.removeSLA' | translate"
                appPermissionDisable (click)="deleteSLA(row)">
                <i class="icon icon-trash"></i>
              </button>
            </app-check-server-admin-permissions>
          </ng-template>
        </app-data-table-column>
      </app-data-table>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div *ngIf="dataLoading" class="d-flex justify-content-center">
      <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>
    <div *ngIf="cmUnitError" class="mt-4 alert alert-danger">
      <i class="icon icon-danger text-danger"></i> {{cmUnitError}}
    </div>
  </ng-template>
</div>
