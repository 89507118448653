<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    <a
      [routerLink]="['/dashboard', 'servers', server.id, 'privileges']">{{ 'common.privileges' | translate }}</a>
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbPrivileges; else tableDataLoading" [data]="dbPrivileges" [rowsPerPage]="20"
      [searchLabel]="'schemaManager.dbPrivilegesTab.searchLabel' | translate" sortBy="userName"
      [noDataAlertMessage]="'schemaManager.dbPrivilegesTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'dataTable.columnTitle.userName' | translate" property="userName"
        [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'privileges.table.columnTitle.privilege' | translate" property="privilegeType"
        [sortable]="true" [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'privileges.table.columnTitle.defaultRole' | translate" property="defaultRole"
        [sortable]="true">
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>
