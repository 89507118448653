/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { ServerResolver } from '../../server-resolver.service';
import { CheckpointsComponent } from './checkpoints.component';
import { Routes } from '@angular/router';

export const CheckpointsRoutes: Routes = [
  {
    path: 'checkpoints',
    resolve: {
      server: ServerResolver
    },
    component: CheckpointsComponent
  }
];
