/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import { debounceTime } from 'rxjs/operators';
import { DataTableColumnComponent } from './data-table-column.component';
import { DataTableExpandComponent } from './data-table-expand.directive';
import { DropdownMultiSelectComponent } from '../forms/dropdown-multi-select.component';
import { JSONUtils } from '../json-utils';

export interface DataTableRow {
  data: any;
  selected: boolean;
  disabled: boolean;
  expanded?: boolean;
}

@Component({
  selector: 'app-data-table',
  templateUrl: 'data-table.html',
  styleUrls: ['data-table.scss']
})
export class DataTableComponent implements OnInit, AfterContentInit, AfterViewInit, OnChanges {
  @ViewChild('dataTableRef') dataTableRef: ElementRef;
  private sortColumn: DataTableColumnComponent;
  useSearch = false;
  private searchColumns: DataTableColumnComponent[] = [];
  private searchControl: UntypedFormControl;

  usePagination = false;
  currentPage = 1;
  currentSearchString = '';

  data: DataTableRow[] = [];
  private searchedData: DataTableRow[] = [];
  pagedData: DataTableRow[] = [];
  private indexedData: { [key: string]: DataTableRow } = {};

  private lastSelectedRow: DataTableRow = null;
  private lastSelectedRowIndex: number = null;
  selectedCount = 0;
  disableCount = 0;
  filteredColumns: Array<DataTableColumnComponent> = new Array<DataTableColumnComponent>();
  columnsTitle: Array<{ label: string; selected: boolean }> = [];
  private ignoreNextPageChangeEvent = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data') rawData: any[] = null;
  @Input() isChecked: string = null;
  @Input() enableFilter = false;
  @Input() sortBy: string = null;
  @Input() sortDescending = false;
  @Input() rowsPerPage = 10;
  @Input() rowsPerPageOptions: number[] = [10, 20, 50, 100];
  @Input() defaultPage = 1;
  @Input() searchLabel = `Search ( Use ' , ' OR ' + ' operator for multiple keyword search ).`;
  @Input() searchValue = '';
  @Input() enableExpand = false;
  @Input() enableSelect = false;
  @Input() enableSingleSelect = false;
  @Input() class: string;
  @Input() enablePagination = true;
  @Input() enableFixedPagination = false;
  @Input() enableStickyHeader = true;
  @Input() enablePaginationBoundaryLinks = true;
  @Input() maxPagingButtons = 10;
  @Input() idProperty: string = null;
  @Input() noDataAlertMessage = 'No data available.';
  @Input() noDataAlertType = 'info';
  @Input() enableSearch = true;
  @Input() minHeight: any;
  searchStatusMsg: Boolean = false;
  dataTableMinHeight: any = 388;

  @Input() dataMapFn: (row: any) => DataTableRow;

  @Output() setData = new EventEmitter<DataTableRow[]>();
  @Output() updateSelectedRows = new EventEmitter<DataTableRow[]>();
  @Output() rowSelected = new EventEmitter<DataTableRow>();
  @Output() pageChange = new EventEmitter<Number>();
  @Output() rowsPerPageChangeEvent = new EventEmitter<Number>();

  @ContentChildren(DataTableColumnComponent) columns: QueryList<DataTableColumnComponent>;
  @ContentChild(DataTableExpandComponent) expandDirective: DataTableExpandComponent;

  @ViewChild('multiselect') multiselect: DropdownMultiSelectComponent;

  constructor() { }

  ngOnInit() {
    if (!this.class) {
      this.class = '';
    }
    this.currentPage = this.defaultPage;
  }

  ngAfterViewInit() {
    this.getDataTableHeight();
  }

  getDataTableHeight() {
    setTimeout(() => {
      if (this.dataTableRef) {
        // calculating data table min-height based on the input of minHeight variable
        if (this.minHeight === 'auto') {
          this.dataTableMinHeight = 'auto';
        } else {
          const topOffSet = this.dataTableRef.nativeElement.getBoundingClientRect().top;
          this.dataTableMinHeight = this.minHeight ? this.minHeight : (window.innerHeight - (topOffSet + 110));
          this.dataTableMinHeight = this.dataTableMinHeight + 'px';
        }
      }
    });
  }

  ngAfterContentInit() {
    if (this.sortBy) {
      this.sortColumn = this.columns.find(column => column.property === this.sortBy);
    }
    this.columns.forEach(column => {
      if (column.searchable) {
        this.searchColumns.push(column);
      }
      if (this.enableFilter && column.actionColumn === false) {
        this.columnsTitle.push({ label: column.title, selected: false });
      }
      this.filteredColumns.push(column);
    });

    if (this.enableSearch && this.searchColumns.length > 0) {
      this.useSearch = true;
      this.searchControl = new UntypedFormControl(this.searchValue ? this.searchValue : '');
      this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe(() => {
        this.applySearch();
      });
    }

    this.applySort();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.usePagination = this.enablePagination ? this.rawData.length > 10 : false;
    if (changes['rawData']) {
      this.selectedCount = 0;
      if (this.idProperty) {
        const oldIndex = this.indexedData;
        this.indexedData = {};
        this.data = [];
        this.rawData.forEach(v => {
          let id: string = null;
          if (typeof v[this.idProperty] === 'string') {
            id = v[this.idProperty];
          } else if (typeof v[this.idProperty] === 'number') {
            id = v[this.idProperty].toString();
          }

          let row: DataTableRow = null;
          if (id !== null) {
            row = oldIndex[id];
            if (row && row.selected) {
              this.selectedCount++;
            }
          }

          if (!row) {
            row = this.convertRawDataRow(v);
          }
          if (id !== null) {
            this.indexedData[id] = row;
          }
          this.data.push(row);
        });
      } else {
        this.data = this.rawData.map(v => this.convertRawDataRow(v));
      }

      if (this.columns) {
        this.applySort();
      }
    }
    if (changes['defaultPage']) {
      this.currentPage = this.defaultPage;
    }

    this.setData.emit(this.data);
    this.updateSelectedRows.emit(this.getSelectedRows());
    this.selectedCount = this.getSelectedRows().length;
    this.getDataTableHeight();
  }

  filterColumns(selectedColumns: any[]) {
    this.filteredColumns = [];
    selectedColumns.forEach(column => {
      if (column.selected === true) {
        this.filteredColumns.push(this.columns.filter(col => col.title === column.label)[0]);
      }
    });
    this.columns.forEach(column => {
      if (column.actionColumn === true) {
        this.filteredColumns.push(this.columns.filter(col => col.title === column.title)[0]);
      }
    });
    if (!selectedColumns.filter(column => column.selected === true).length) {
      this.filteredColumns = [];
      this.columns.forEach(col => this.filteredColumns.push(col));
    }
  }

  getSelectedRows() {
    return this.data.filter(v => v.selected).map(v => v.data);
  }

  deselectSelectedRows() {
    this.data.forEach(row => {
      row.selected = false;
    });
    this.selectedCount = 0;
  }

  removeRow(data: any) {
    const index = this.data.findIndex(v => v.data === data);
    if (index > -1) {
      this.data.splice(index, 1);
      this.applyPagination();
    }
  }

  onRowExpand(row: DataTableRow) {
    row.expanded = !row.expanded;
  }

  public onColumnHeaderClick(column: DataTableColumnComponent) {
    if (!this.data || !column.sortable) {
      return;
    }

    if (this.sortColumn === column) {
      this.sortDescending = !this.sortDescending;
      this.data.reverse();

      this.applySearch();
      this.applySort();
    } else {
      this.sortColumn = column;
      this.sortDescending = false;

      this.applySort();
    }
  }

  public onPageChange(event: PageChangedEvent) {
    if (this.ignoreNextPageChangeEvent) {
      this.ignoreNextPageChangeEvent = false;
      return;
    }

    this.currentPage = event.page;
    this.applyPagination();
    this.pageChange.emit(this.currentPage);
  }

  public onRowSelect(event: MouseEvent, row: DataTableRow, pageIndex: number) {
    if (row.disabled) {
      this.setRowSelected(row, false);
      return;
    }
    const index = this.usePagination ? (this.currentPage - 1) * this.rowsPerPage + pageIndex : pageIndex;
    if (event.shiftKey && this.lastSelectedRow !== null && this.lastSelectedRowIndex !== null && index !== this.lastSelectedRowIndex &&
      this.searchedData[this.lastSelectedRowIndex] === this.lastSelectedRow) {
      const start: number = Math.min(index, this.lastSelectedRowIndex);
      const end: number = Math.max(index, this.lastSelectedRowIndex);
      for (let i = start; i <= end; i++) {
        this.setRowSelected(this.searchedData[i], this.lastSelectedRow.selected);
      }
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.setRowSelected(row, !row.selected);
    }

    this.lastSelectedRow = row;
    this.lastSelectedRowIndex = index;
    this.updateSelectedRows.emit(this.getSelectedRows());
  }

  onSingleSelect(row: DataTableRow) {
    if (this.enableSingleSelect) {
      if (this.lastSelectedRow !== null) {
        if (JSONUtils.deepEqual(row.data, this.lastSelectedRow.data)) {
          this.setRowSelected(row, !row.selected);
        } else {
          this.searchedData.filter(dataRow => {
            if (JSONUtils.deepEqual(dataRow.data, this.lastSelectedRow.data)) {
              this.setRowSelected(dataRow, false);
            }
          });
          this.lastSelectedRow = JSON.parse(JSON.stringify(row));
          this.setRowSelected(row, true);
        }
      } else {
        let isMatch = false;
        this.lastSelectedRow = JSON.parse(JSON.stringify(row));
        this.searchedData.forEach(dataRow => {
          if (dataRow.selected) {
            this.setRowSelected(dataRow, false);
            if (JSONUtils.deepEqual(dataRow.data, row.data)) {
              isMatch = true;
              this.setRowSelected(dataRow, false);
            }
          }
        });
        if (!isMatch) {
          this.setRowSelected(row, true);
        }
      }
      const selectedRows = this.getSelectedRows();
      this.updateSelectedRows.emit(selectedRows.length?selectedRows[0]:null);
    }
  }

  public onSelectAll() {
    if (!this.searchedData || this.searchedData.length < 1) {
      return;
    }
    this.disableCount = this.searchedData.filter(row => row.disabled).length;
    if (this.selectedCount + this.disableCount < this.searchedData.length) {
      this.searchedData.forEach(row => {
        if (!row.disabled) {
          this.setRowSelected(row, true);
        }
      });
    } else {
      this.clearSelection();
    }
    this.updateSelectedRows.emit(this.getSelectedRows());
  }

  private clearSelection() {
    this.searchedData.forEach(row => {
      this.setRowSelected(row, false);
    });
  }

  public setRowSelected(row: DataTableRow, value: boolean) {
    if (row.selected !== value) {
      this.selectedCount += value ? 1 : -1;
      row.selected = value;
      this.rowSelected.emit(row);
    }
  }

  private applySort() {
    if (!this.data) {
      return;
    }

    if (this.sortColumn && this.data.length > 0) {
      let sortFunction: (a: any, b: any) => number = null;
      const sortColName: any = this.sortColumn.sortProperty !== undefined ? this.sortColumn.sortProperty : this.sortColumn.property;
      const firstValue: any = this.getFirstNonNullValue(sortColName);

      if (typeof firstValue === 'string') {
        sortFunction = this.sortString;
      } else if (typeof firstValue === 'number') {
        sortFunction = this.sortNumber;
      } else if (typeof firstValue === typeof true) {
        sortFunction = this.sortBoolean;
      } else if (typeof firstValue === 'object') {
        sortFunction = this.sortDate;
      } else {
        console.warn('Unable to sort. Unsupported data type.');
      }

      if (sortFunction) {
        this.data.sort((a, b) => sortFunction(a.data[this.sortColumn.property], b.data[this.sortColumn.property]));
        if (this.sortDescending) {
          this.data.reverse();
        }
      }
    }

    this.applySearch();
  }

  private getFirstNonNullValue(key: string): any {
    const match = this.data.find(row => {
      const val = row.data[key];
      return (val !== null && val !== undefined);
    });
    if (match === undefined) {
      return null;
    } else {
      return match.data[key];
    }
  }

  private applySearch() {
    this.searchStatusMsg = false;
    if (!this.useSearch) {
      this.searchedData = this.data;
    } else {
      let searchString = (this.searchControl.value as string).trim();
      if (this.currentSearchString !== searchString) {
        this.clearSelection();
        this.currentSearchString = searchString;
      }
      if (searchString.length < 1) {
        this.searchedData = this.data;
      } else if (searchString.indexOf(',') > -1 && searchString.indexOf('+') === -1) {
        // checking if searchString have ',' delimiter.
        this.searchStatusMsg = true;
        this.searchedData = this.data;
        searchString = searchString.toLowerCase();
        // splitting searchString into keywords and removing extra spaces with ',' delimiter.
        const searchArray = searchString.split(',').map(item => item.trim());
        // loop through filtering the data based on keywords.
        searchArray.forEach(item => {
          this.searchedData = this.data.filter(row => {
            for (let i = 0; i < this.searchColumns.length; ++i) {
              const value = row.data[this.searchColumns[i].property];
              const searchExp = new RegExp(searchArray.join('|'), 'gi');
              if (typeof value === 'string' && searchExp.test(value.toLowerCase())) {
                return true;
              } else if (typeof value === 'number' && searchExp.test(value.toString())) {
                return true;
              } else if (value instanceof Array && searchExp.test(value.toString())) {
                return true;
              }
            }
          });
          return false;
        });
      } else if (searchString.indexOf('+') > -1 && searchString.indexOf(',') === -1) {
        // checking if searchString have '+' delimiter and calling searchWithPlusDelimator to perform the filtering data.
        this.searchStatusMsg = true;
        this.searchWithPlusDelimiter(searchString);
      } else if (searchString.indexOf('+') > -1 && searchString.indexOf(',') > -1) {
        // checking searchString has both ',' and '+' delimiter
        this.searchStatusMsg = true;
      } else {
        // default single keyword loop through filtering data  based on keyword.
        searchString = searchString.toLowerCase();
        this.searchedData = this.data.filter(row => {
          for (let i = 0; i < this.searchColumns.length; ++i) {
            const value = row.data[this.searchColumns[i].property];
            if (typeof value === 'string' && value.toLowerCase().indexOf(searchString) > -1) {
              return true;
            } else if (typeof value === 'number' && value.toString().indexOf(searchString) > -1) {
              return true;
            } else if (value instanceof Array && value.toString().indexOf(searchString) > -1) {
              return true;
            }
          }
          return false;
        });
      }
    }

    this.applyPagination();
  }
  searchWithPlusDelimiter(searchString) {
    // splitting searchString into keywords and removing extra spaces with '+' delimiter.
    searchString = searchString.toLowerCase();
    const searchArray = searchString.split('+').map(item => item.trim());
    // loop through filtering the data based on keywords.
    searchArray.forEach(item => {
      this.searchedData = this.searchedData.filter(row => {
        for (let i = 0; i < this.searchColumns.length; ++i) {
          const value = row.data[this.searchColumns[i].property];
          if (typeof value === 'string' && value.toLowerCase().indexOf(item) > -1) {
            return true;
          } else if (typeof value === 'number' && value.toString().indexOf(item) > -1) {
            return true;
          } else if (value instanceof Array && value.toString().indexOf(item) > -1) {
            return true;
          }
        }
      });
      return false;
    });
  }
  private applyPagination() {
    if (this.usePagination && this.searchedData.length > 10) {
      const lastPage = this.searchedData.length > 0 ? Math.ceil(this.searchedData.length / this.rowsPerPage) : 1;
      if (this.currentPage > lastPage) {
        this.currentPage = lastPage;
      }

      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      this.pagedData = this.searchedData.slice(startIndex, startIndex + this.rowsPerPage);
    } else {
      this.pagedData = this.searchedData;
    }
  }

  public getSortIconClass(column: DataTableColumnComponent) {
    return this.sortColumn === column ? (this.sortDescending ? 'icon-sort-desc' : 'icon-sort-asc') : 'icon-sort';
  }

  public getColumnCount(): number {
    if (this.enableFilter) {
      return this.filteredColumns.length + (this.enableSelect ? 1 : 0);
    } else {
      return this.columns.length + (this.enableSelect ? 1 : 0) + (this.enableExpand ? 1 : 0);
    }
  }

  private sortNumber(a: number, b: number) {
    return a - b;
  }

  private sortString(a: string, b: string) {
    if (a === null && b === null) {
      return 0;
    } else if (a === null) {
      return -1;
    } else if (b === null) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  }

  private sortBoolean(a: string, b: string) {
    if (a === null && b === null) {
      return 0;
    } else if (a === null) {
      return -1;
    } else if (b === null) {
      return 1;
    } else {
      return a.toString().localeCompare(b.toString());
    }
  }

  private sortDate(a: any, b: any) {
    if (!a) {
      a = {};
      a.$date = -999999999999;
    }

    if (!b) {
      b = {};
      b.$date = -999999999999;
    }
    return a.$date - b.$date;
  }

  rowsPerPageChange(newValue: number) {
    if (newValue > 0) {
      let currentPage = 1;
      if (this.usePagination) {
        // Calculate the new page the table should land on, trying to keep the same elements in view
        const currentIndex = (this.currentPage - 1) * this.rowsPerPage;
        currentPage = Math.floor(currentIndex / newValue) + 1;
        // HACK: The pagination component will try to set the page itself, based on old/wrong values
        // In that case, ignore the next event the component fires
        const lastPage = this.searchedData.length > 0 ? Math.ceil(this.searchedData.length / newValue) : 1;
        if (this.currentPage > lastPage) {
          this.ignoreNextPageChangeEvent = true;
        }
      }
      this.currentPage = currentPage;
    }
    this.rowsPerPage = newValue;
    this.usePagination = newValue > 0;
    this.rowsPerPageChangeEvent.emit(newValue);
    this.applyPagination();
  }

  isRowExpanded(row: DataTableRow) {
    if (row.expanded) {
      return true;
    }

    return this.expandDirective && this.expandDirective.expandCondition && this.expandDirective.expandCondition(row.data);
  }

  private convertRawDataRow(row: any): DataTableRow {
    if (this.dataMapFn) {
      return this.dataMapFn(row);
    }

    let selected = false;
    if (this.enableSelect && typeof this.isChecked === 'string' && this.isChecked) {
      selected = !!row[this.isChecked];
    }

    return {
      data: row,
      selected,
      disabled: row['disabled'] ? row['disabled'] : false
    };
  }
}
