/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2023. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { ChartJSUtils } from '../../../shared/chartjs.utils';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { BytePipe } from '../../../shared/pipes/byte.pipe';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { InformixServerRecoveryLogsService } from './informixServerRecoveryLogs.service';
import { InformixServerStorageService } from './informixServerStorage.service';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';

@Component({
  selector: 'app-recovery-logs',
  templateUrl: 'recovery-logs.html',
  styleUrls: ['recovery-logs.scss']
})
export class RecoveryLogsComponent implements OnInit, OnDestroy {

  dataLoading = false;
  dataLoadErrorMessage: string = null;

  server: InformixServer = null;
  plogInfo: any = null;
  llogs: any[] = null;

  isMovingPLog = false;
  movePLogFormGroup: UntypedFormGroup;
  isAddingLLog = false;
  addLLogFormGroup: UntypedFormGroup;
  dbspaces: any[] = null;

  breadcrumb: BreadcrumbElement[] = null;
  breadcrumbElements = [
    { name: 'Storage', link: 'storage' },
    { name: 'Logs' }
  ];

  recoveryLogRequestSub: Subscription = null;
  dbspacesRequestSub: Subscription = null;
  logHDR: HDRPermission;
  LOGICAL_LOGS_USAGE_RED_THRESHOLD = 90;
  defaultPage: Number = 1;
  rowsPerPage: Number = 20;
  llogInfo: any;
  _plogs: any;

  @ViewChild('rlogChartCanvas') rlogChartCanvas: ElementRef;

  constructor(
    private rlogService: InformixServerRecoveryLogsService,
    private storageService: InformixServerStorageService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private hdrPermissionService: HDRPermissionService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });

    this.movePLogFormGroup = new UntypedFormGroup({
      dbspace: new UntypedFormControl('', [Validators.required]),
      size: new UntypedFormControl('', [Validators.required])
    });

    this.addLLogFormGroup = new UntypedFormGroup({
      dbspace: new UntypedFormControl('', [Validators.required]),
      size: new UntypedFormControl('', [Validators.required]),
      count: new UntypedFormControl(1, [Validators.required, Validators.min(1)]),
      afterCurrent: new UntypedFormControl(false, [Validators.required])
    });
    this.logHDR = this.hdrPermissionService.getByPermissionId('p1');
  }

  ngOnDestroy() {
    if (this.recoveryLogRequestSub) {
      this.recoveryLogRequestSub.unsubscribe();
    }
    if (this.dbspacesRequestSub) {
      this.dbspacesRequestSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, this.breadcrumbElements);
    if (this.server.hasMonitorPassword) {
      this.dataLoading = true;
      this.refreshData();
      this.getDbspacesList();
    }
  }

  public refreshData() {
    this.getRecoveryLogInfo();
  }

  private getRecoveryLogInfo() {
    this.recoveryLogRequestSub = this.rlogService.getRecoveryLogsInfo(this.server.id).subscribe(data => {
      this.dataLoading = false;
      this.plogInfo = data.plog;
      this.llogs = data.llog;

      // Sum up used/free values for logical logs, then set chart data
      let logicalLogUsed = 0;
      let logicalLogFree = 0;
      let logicalLogBackedUp = 0;
      let totalLogSize = 0;
      this.llogs.forEach((llog, index) => {
        if ((llog.flags & 0x04) > 0) {
          logicalLogBackedUp += llog.total_size;
        } else if ((llog.flags & 0x01) > 0 && (llog.flags & 0x02) > 0) {
          logicalLogUsed += llog.used_size;
          logicalLogFree += llog.total_size - llog.used_size;
        } else if ((llog.flags & 0x01) > 0) {
          logicalLogUsed += llog.total_size;
        } else {
          logicalLogFree += llog.total_size;
        }
        totalLogSize += llog.total_size;
      });
      this.llogInfo = {
        logicalLogUsed,
        logicalLogFree,
        logicalLogBackedUp,
        totalLogSize
      };
    }, (err: HttpErrorResponse) => {
      this.dataLoading = false;
      console.error('Could not get recovery logs info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  /**
   * Calculating page number where current llog is located.
   */
  navigateToCurrentLlogPage(): void {
    this.llogs.forEach((llog, index) => {
      if (llog.status.indexOf('CURRENT') > -1) {
        this.defaultPage = Math.ceil((index + 1) / Number(this.rowsPerPage));
        this.llogs[index].selected = true;
      }
    });
  }

  /**
   * Output event to be called on page change. Reseting default with current page value to call onChange in the datatable
   *
   * @param currentPage current page of datatable
   */
  onPageChange(currentPage: number): void {
    this.defaultPage = currentPage;
  }

  /**
   * Output evgent to set rows per page when it is changed.
   *
   * @param newValue new rows per page
   */
   rowsPerPageChange(newValue: number): void {
    this.rowsPerPage = newValue;
  }

  private getDbspacesList() {
    this.dbspacesRequestSub = this.storageService.getServerDspaces(this.server.id).subscribe(data => {
      this.dbspaces = data;
      this.dbspaces.sort((a, b) => a.name.localeCompare(b.name));
    }, (err: HttpErrorResponse) => {
      console.error('Could not get dbspaces info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  public handleActionSuccess(message: string) {
    this.notificationsService.pushSuccessNotification(message);
    this.refreshData();
  }

  public handleActionError(err: any) {
    let message = err;
    if (typeof err !== 'string') {
      message = err.error ? err.error.err : err;
    }
    this.notificationsService.pushErrorNotification(message);
  }

  public getLogicalLogStatusString(status) {
    switch (status) {
      case 'NOT_FULL':
        return 'Not Full';
      case 'USED':
        return 'Used';
      case 'USED_BACKED_UP':
        return 'Used and Backed up';
      case 'USED_CURRENT':
        return 'Used and Current';
      case 'NEW_ADD':
        return 'Newly Added';
      default:
        return '';
    }
  }

  public openMovePLogForm() {
    if (this.logHDR && !this.logHDR.isAllow()) {
      return;
    }
    if (this.plogInfo) {
      this.movePLogFormGroup.controls.dbspace.setValue(this.plogInfo.dbspace);
      this.movePLogFormGroup.controls.size.setValue(Math.round(this.plogInfo.total_size / 1024));
    }
    this.isMovingPLog = true;
  }

  public openAddLLogForm() {
    if (this.logHDR && !this.logHDR.isAllow()) {
      return;
    }
    if (this.dbspaces && this.dbspaces.length > 0) {
      this.addLLogFormGroup.controls.dbspace.setValue(this.dbspaces[0].name);
    }
    if (this.llogs && this.llogs.length > 0) {
      this.addLLogFormGroup.controls.size.setValue(this.llogs[0].total_size / 1024);
      this.addLLogFormGroup.controls.count.setValue(1);
    }
    this.isAddingLLog = true;
  }

  public confirmDropLogicalLog(llog) {
    if (this.logHDR && !this.logHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('drop logical log ' + llog.number + '?', () => {
      this.dropLogicalLog(llog);
    });
  }

  public confirmSwitchLogicalLog() {
    if (this.logHDR && !this.logHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('switch to the next logical log file?', () => {
      this.switchLogicalLog();
    });
  }

  public addLogicalLog() {
    if (!this.addLLogFormGroup.valid) {
      return;
    }
    this.rlogService.addLogicalLog(this.server.id, this.addLLogFormGroup.value.dbspace, this.addLLogFormGroup.value.size,
      this.addLLogFormGroup.value.count, this.addLLogFormGroup.value.afterCurrent).then(result => {
        this.handleActionResult(result);
      }).catch(err => {
        this.notificationsService.pushErrorNotification(err);
      });
    this.isAddingLLog = false;
  }

  private switchLogicalLog() {
    this.rlogService.switchLogicalLog(this.server.id).then(result => {
      this.handleActionResult(result);
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  private dropLogicalLog(llog) {
    this.rlogService.dropLogicalLog(this.server.id, llog.number).then(result => {
      this.handleActionResult(result);
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  public movePhysicalLog() {
    if (!this.movePLogFormGroup.valid) {
      return;
    }
    this.rlogService.movePhysicalLog(this.server.id, this.movePLogFormGroup.value.dbspace,
      this.movePLogFormGroup.value.size).then(result => {
        this.handleActionResult(result);
      }).catch(err => {
        this.notificationsService.pushErrorNotification(err);
      });
    this.isMovingPLog = false;
  }

  private handleActionResult(result) {
    this.notificationsService.pushGenericNotification(result.return_code, result.result_message);
    this.refreshData();
  }
}
