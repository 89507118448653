/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { InformixServer } from '../informixServer';

export class InformixDatabase {
  server: InformixServer;

  name: string;
  owner: string;
  created: number;
  logging: boolean;
  bufferedLogging: boolean;
  ansi: boolean;
  gls: boolean;
  caseInsensitive: boolean;
  dbspace: string;
  locale: string;

  constructor(server: InformixServer, json: any) {
    this.server = server;
    this.name = json.name;
    this.owner = json.owner;
    this.created = json.created;
    this.logging = json.logging;
    this.bufferedLogging = json.bufferedLogging;
    this.ansi = json.ansi;
    this.gls = json.gls;
    this.caseInsensitive = json.caseInsensitive;
    this.dbspace = json.dbspace;
    this.locale = json.locale;
  }

  getUrl(): string {
    return this.server.getDatabasesUrl() + '/' + this.name;
  }

  getSQLSessionsUrl(): string {
    return this.getUrl() + '/sql-sessions';
  }
}
