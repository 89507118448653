/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DatePipe } from '@angular/common';
import { MemoryManagementComponent } from './memory-manager.component';
import { MonitoringModule } from '../../../dashboard/monitoring/monitoring.module';
import { MemoryManagementService } from './memory-manager.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TooltipModule,
    SharedModule,
    MonitoringModule
  ],
  exports: [],
  declarations: [
    MemoryManagementComponent
  ],
  providers: [
    DatePipe,
    MemoryManagementService
  ],
})
export class MemoryManagementModule { }
