/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { CacheableResource } from '../../../shared/resourceCache';

export class ServerHostInfo implements CacheableResource {

  id: number; // server id
  os_name: string;
  os_release: string;
  os_nodename: string;
  os_version: string;
  os_machine: string;
  os_num_procs: number;
  os_num_olprocs: number;
  os_pagesize: number;
  os_mem_total: number;
  os_mem_free: number;
  os_open_file_lim: number;

  constructor(json: any) {
    this.os_name = json.os_name;
    this.os_release = json.os_release;
    this.os_nodename = json.os_nodename;
    this.os_version = json.os_version;
    this.os_machine = json.os_machine;
    this.os_num_procs = json.os_num_procs;
    this.os_num_olprocs = json.os_num_olprocs;
    this.os_pagesize = json.os_pagesize;
    this.os_mem_total = json.os_mem_total;
    this.os_mem_free = json.os_mem_free;
    this.os_open_file_lim = json.os_open_file_lim;
  }

  public getId(): string {
    return this.id.toString();
  }
}
