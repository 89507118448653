/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sla-db-servers',
  templateUrl: 'sla-db-servers.html',
  styles: [`.server-table-height {
        height: 300px;
    }`]
})
export class SlaDBServersComponent implements OnChanges {

  @ViewChild('groupModal') groupModal: ModalDirective;
  @Input() availableServers: string[];
  @Output() updateSelectedServers = new EventEmitter<string[]>();

  allAvailableServers: any[] = [];
  selectedServerToAdd;
  selectedServerToRemove;
  selectedServers = [];

  groupServers;
  groupName = '';
  groupId = 0;
  isEditGroup = false;

  useAnyServer = false;
  showTable = false;

  ngOnChanges() {
    if (this.availableServers && this.availableServers.length > 0) {
      this.availableServers.forEach(server => {
        const data = { server, checked: false };
        this.allAvailableServers.push(data);
      });
    }
  }

  openServerGroupPopup() {
    this.isEditGroup = false;
    this.groupId = this.groupId + 1;
    this.allAvailableServers.map(value => value.checked = false);
    this.groupModal.show();
    this.showTable = true;
  }

  addSelectedServer() {
    this.selectedServers.push(this.selectedServerToAdd);
    this.availableServers = this.availableServers.filter(server => server !== this.selectedServerToAdd);
    this.selectedServerToAdd = null;
    this.updateSelectedServers.emit(this.selectedServers);
  }

  removeSelectedServer() {
    this.availableServers.push(this.selectedServerToRemove);
    this.selectedServers = this.selectedServers.filter(server => server !== this.selectedServerToRemove);
    this.selectedServerToRemove = null;
    this.updateSelectedServers.emit(this.selectedServers);
  }

  getSelectedServers(groupServers: any[]) {
    this.groupServers = groupServers.map(value => value.server);
  }

  addServersInSLA() {
    if (this.isEditGroup === true) {
      const group = this.selectedServers.find(server => server.includes(this.groupName));
      const index = this.selectedServers.indexOf(group);
      if (index > -1) {
        this.selectedServers[index] = this.groupName + '(' + [...this.groupServers] + ')';
      }
    } else {
      this.selectedServers.push('Group' + this.groupId + '(' + [...this.groupServers] + ')');
    }
    this.updateSelectedServers.emit(this.selectedServers);
    this.groupModal.hide();
    this.showTable = false;
  }

  removeGroup(server: string) {
    const index = this.selectedServers.indexOf(server);
    if (index !== -1) {
      this.selectedServers.splice(index, 1);
      this.updateSelectedServers.emit(this.selectedServers);
    }
  }

  editGroup(servers: string) {
    this.groupName = servers.split('(')[0];
    this.isEditGroup = true;
    const groupServers = servers.split('(')[1].replace(/[()]/g, '').split(',');
    this.allAvailableServers.map(value => {
      if (groupServers.indexOf(value.server) !== -1) {
        value.checked = true;
      } else {
        value.checked = false;
      }
    });
    this.groupModal.show();
    this.showTable = true;
  }

  addAnyServer() {
    if (this.useAnyServer === true) {
      this.updateSelectedServers.emit(['ANY']);
    } else {
      this.updateSelectedServers.emit(this.selectedServers);
    }
  }

  closeGroupPopup() {
    this.groupModal.hide();
    this.groupId = this.isEditGroup ? this.groupId : this.groupId - 1;
    this.showTable = false;
  }
}
