/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {

  constructor(private userService: UserService) { }

  public canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.userService.getCurrentUser().then(user => {
        resolve(user.isSuperAdmin());
      }).catch(err => {
        console.error('Error getting user info', err);
        resolve(false);
      });
    });
  }

  canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
