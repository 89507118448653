/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InformixServer } from '../servers/informixServer';
import { AbstractDashboardComponent } from './abstract-dashboard-component';
import { InformixSensorService } from './informixSensor.service';
import { Sensor } from './sensor';
import { ChartConfig, SensorChartGraphComponent } from './sensor-chart-graph.component';
import { TimeSlice } from './timeSlices';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sensor-chart',
  templateUrl: 'sensor-chart.html'
})
export class SensorChartComponent extends AbstractDashboardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() server: InformixServer;
  @Input() config: ChartConfig;

  showTitle = true;
  chartTitle: string = null;
  sensor: Sensor;

  warningMessage: string = null;

  sensorUpdateSub: Subscription = null;

  constructor(
    private sensorService: InformixSensorService,
    private router: Router
  ) {
    super();
  }

  @ViewChild(SensorChartGraphComponent) chartComponent: SensorChartGraphComponent;

  ngOnInit() {
    if (!this.server || !this.config) {
      return;
    }

    if (this.config.title) {
      this.chartTitle = this.config.title;
    }

    if (this.config.showTitle != null) {
      this.showTitle = this.config.showTitle;
    }

    this.fetchSensor().then(sensor => {
      this.setSensor(sensor);
    });

    this.sensorUpdateSub = this.sensorService.eventSensorUpdate.subscribe((sensor: Sensor) => {
      if (sensor.type.id === this.config.sensorId) {
        this.setSensor(sensor);
      }
    });
  }

  ngOnDestroy() {
    this.sensorUpdateSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.server || changes.config) {
      if (!this.server.agent || !this.server.agent.isMonitoring) {
        this.warningMessage = 'The agent is not running';
      } else {
        this.warningMessage = null;
      }
    }
  }

  private setSensor(sensor: Sensor) {
    this.sensor = sensor;
    if (!this.sensor) {
      this.warningMessage = 'The ' + this.config.sensorId + ' sensor is not configured';
    } else {
      if (this.sensor.disabled) {
        this.warningMessage = 'This sensor is disabled';
      } else if (!this.server.agent || !this.server.agent.isMonitoring) {
        this.warningMessage = 'The agent is not running';
      } else {
        this.warningMessage = null;
      }

      if (!this.chartTitle) {
        this.chartTitle = sensor.type.name;
      }
    }
  }

  private fetchSensor(): Promise<Sensor> {
    if (this.monitoringProfile) {
      const sensor = this.monitoringProfile.getSensor(this.config.sensorId);
      return Promise.resolve(sensor);
    }

    return this.sensorService.getSensorInfo(this.server.id, this.config.sensorId).catch((err: HttpErrorResponse) => {
      if (err.status !== 404) {
        console.error('Error getting sensor', err);
      }
      return null;
    });
  }

  doEnableSensor() {
    if (!this.sensor) {
      // Sensor is not configured, so add it to the monitoring profile.
      this.addSensorToMonitoringProfile();
    } else if (this.sensor.disabled) {
      // Sensor is configured but disabled so just re-enable it.
      this.enableSensorInMonitoringProfile();
    }
  }

  addSensorToMonitoringProfile() {
    this.sensorService.addSensorToMonitoringProfile(this.server.id, this.config.sensorId);
  }

  enableSensorInMonitoringProfile() {
    const updatedSensor = new Sensor(this.sensor);
    updatedSensor.disabled = false;
    this.sensorService.updateSensorInMonitoringProfile(this.server.id, updatedSensor);
  }

  navigateToConfigureMonitoring() {
    this.router.navigate(['dashboard/servers/' + this.server.id + '/monitoring']);
  }

  pauseData() {
    if (this.chartComponent) {
      this.chartComponent.pauseData();
    }
  }

  resumeData() {
    if (this.chartComponent) {
      this.chartComponent.resumeData();
    }
  }

  setTimeSlice(slice: TimeSlice) {
    if (this.chartComponent) {
      this.chartComponent.setTimeSlice(slice);
    }
  }
}
