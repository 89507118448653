/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartJSUtils } from '../../shared/chartjs.utils';
import { BytePipe } from '../../shared/pipes/byte.pipe';
import { AbstractDashboardComponent } from '../monitoring/abstract-dashboard-component';
import { InformixSensorService } from '../monitoring/informixSensor.service';
import { MonitoringProfile } from '../monitoring/monitoringProfile';
import { InformixServer } from './informixServer';

@Component({
  selector: 'app-dashboard-server-device',
  templateUrl: 'dashboard-server-device.html'
})
export class DashboardServerDeviceComponent extends AbstractDashboardComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() monitoringProfile: MonitoringProfile;
  @Input() viewMonitoredData: boolean;

  private deviceSensorId = 'os_disk_usage';
  deviceSensorRunning = false;

  private devicesNames: string[];
  private devicesUsedBytes: number[];
  private devicesFreeBytes: number[];
  private devicesTotalBytes: number[];

  private chart: Chart;
  chartHeight = 125;

  private timeoutHandle: number;
  private retryInterval = 5000;
  private remainingRetries = 5;

  @ViewChild('deviceUsageBarChart') deviceUsageBarChartElement: ElementRef;
  @ViewChild('deviceSensorGraph') deviceSensorGraph: AbstractDashboardComponent;
  sensorError: any;

  constructor(private sensorService: InformixSensorService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server || changes.monitoringProfile) {
      this.checkIfSensorIsRunning();
      if (this.deviceSensorRunning) {
        this.getLatestSensorDataPoint();
      }
    }
  }

  private checkIfSensorIsRunning() {
    if (!this.server || !this.server.agent || !this.server.agent.online || !this.server.agent.isConfigured || !this.monitoringProfile) {
      this.deviceSensorRunning = false;
      return;
    }
    const sensor = this.monitoringProfile.getSensor(this.deviceSensorId);
    this.deviceSensorRunning = sensor != null && !sensor.disabled;
  }

  private getLatestSensorDataPoint() {
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
    }

    this.sensorService.getLatestSensorDataPoint(this.server.id, this.deviceSensorId).then(sensorData => {
      this.updateDeviceUsageBarChart(sensorData);
    }).catch(err => {
      console.log('Unable to get latest disk usage information', err);

      if(err.error && err.error.err) {
        this.sensorError = err.error.err;
      }
      // Retry a few times to get data in case the sensor was just enabled for the first time
      if (this.remainingRetries > 0) {
        this.remainingRetries--;
        this.timeoutHandle = window.setTimeout(() => this.getLatestSensorDataPoint(), this.retryInterval);
      }
    });
  }

  private updateDeviceUsageBarChart(sensorData: any[]) {
    // If chart has already been created it, update it.
    this.chartHeight = 50 + sensorData.length * 50;
    if (this.chart) {
      this.chart.update();
      return;
    }

    // Otherwise we need to create it
    this.devicesNames = [];
    this.devicesUsedBytes = [];
    this.devicesFreeBytes = [];
    this.devicesTotalBytes = [];
    const bytePipe = new BytePipe();

    sensorData.forEach(element => {
      this.devicesNames.push(element.pkey);
      this.devicesUsedBytes.push(element.data.used);
      this.devicesFreeBytes.push(element.data.free);
      this.devicesTotalBytes.push(element.data.size);
    });

    this.chart = new Chart(this.deviceUsageBarChartElement.nativeElement, {
      type: 'horizontalBar',
      data: {
        labels: this.devicesNames,
        datasets: [
          {
            label: 'Used',
            data: this.devicesUsedBytes,
            backgroundColor: ChartJSUtils.getDefaultColor(0)
          },
          {
            label: 'Free',
            data: this.devicesFreeBytes,
            backgroundColor: '#F6F6F6'
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              beforeBuildTicks: ChartJSUtils.byteAxisBeforeBuildTicks,
              stacked: true,
              ticks: {
                maxRotation: 0,
                callback: (value: any) => bytePipe.transform(value),
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              barThickness: 35,
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              stacked: true,
              ticks: {
                padding: 10
              }
            }
          ]
        },
        legend: {
          display: false,
          position: 'top'
        },
        tooltips: {
          mode: 'nearest',
          callbacks: {
            title: (tooltips: any, data: any) => {
              const tooltip = tooltips[0];
              return data.labels[tooltip.index] + ' ' + data.datasets[tooltip.datasetIndex].label;
            },
            label: (tooltip: any, data: any) => {
              const value: number = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              return bytePipe.transform(value) + ' (' + ((value / this.devicesTotalBytes[tooltip.index]) * 100).toFixed(2) + '%)';
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
    }
  }
}
