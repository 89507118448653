/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformixServer } from '../informixServer';
import { AutoUpdateStatisticsService } from './autoUpdateStatistics.service';
import { CommandInfo } from './commandInfo.model';
import { NotificationsService } from '../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-commands-auto-update-statistics',
  templateUrl: 'commands-autoUpdateStatistics.html',
  styleUrls: ['./autoUpdateStatistics.component.scss'],
})
export class CommandsComponent implements OnInit {

  @Input() server: InformixServer = null;
  tableData: CommandInfo[] = null;
  commandTypes = [{
    name: 'Pending',
    command: 'P'
  }, {
    name: 'Completed',
    command: 'C'
  }, {
    name: 'In Progress',
    command: 'I'
  }, {
    name: 'Error',
    command: 'E'
  }];
  ausCommandsApiError: string;

  constructor(
    private route: ActivatedRoute,
    private autoUpdateStatisticsService: AutoUpdateStatisticsService,
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getCommand('P');
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
  }

  getCommand(commandType) {
    this.autoUpdateStatisticsService.getCommand(this.server, commandType).subscribe((res: CommandInfo[]) => {
      this.tableData = res;
    }, err => {
      if(err.error && err.error.err){
        this.ausCommandsApiError = err.error.err;
      }
    });
  }

  getCommandsData(event) {
    const commandType = event.target.value;
    this.getCommand(commandType);
  }
}
