/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { InformixServerService } from './informixServer.service';
import { InformixServer } from './informixServer';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ServerResolver implements Resolve<InformixServer> {
  constructor(private serverService: InformixServerService, private router: Router, private location: Location) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): InformixServer | Promise<InformixServer> {
    let serverId: string = null;
    for (let i = route.pathFromRoot.length - 1; i >= 0; i--) {
      if (route.pathFromRoot[i].params.id) {
        serverId = route.pathFromRoot[i].params.id;
        break;
      }
    }

    if (serverId === null) {
      return null;
    }

    return new Promise<InformixServer>(resolve => {
      this.serverService.getServer(serverId).then(server => {
        resolve(server);
      }).catch((err: HttpErrorResponse) => {
        resolve(null);
        let redirect;
        if(err.status === 404) {
          redirect = 'not-found';
        }else if (err.status === 403) {
          redirect = 'forbidden';
        }
        this.router.navigate(['dashboard', 'servers', redirect], { skipLocationChange: true }).then(() => {
          this.location.replaceState(state.url);
        });
      });
    });
  }


}
