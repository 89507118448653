/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2020. All Rights Reserved.
 *******************************************************************************/
export class ChartJSUtils {

  public static defaultColors: string[] = [
    '#0066b3', '#589d25', '#ee3f64', '#f69643', '#7550a3', '#fac43b',
    '#7fd7de', '#4696d2', '#aaaaaa', '#132730', '#f58ca2', '#a35614',
    '#79cbf2', '#aae4e9', '#f78a8a', '#fac08e', '#c8b9da', '#fcdc89',
    '#bceb99', '#008c98', '#e3dcec', '#717171', '#f8b2c1', '#fbd5b4'
  ];

  public static getDefaultColor(index: number): string {
    if (index < ChartJSUtils.defaultColors.length) {
      return ChartJSUtils.defaultColors[index % ChartJSUtils.defaultColors.length];
    } else {
      return ChartJSUtils.getRandomColor();
    }
  }

  public static getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  public static byteAxisBeforeBuildTicks(axis: any) {
    if (axis.max > 0) {
      const limit = axis.max / Math.floor((axis.isHorizontal() ? axis.width : axis.height) / 100);
      let step = axis.options.ticks.stepSize || 1;
      if (step === Infinity) {
        step = 1;
      }
      while (step < limit) {
        step *= 2;
      }
      while (step > limit) {
        step /= 2;
      }
      axis.options.ticks.stepSize = step;
    }
  }

  public static hideNextToLastxAxisTick(value, index, ticks) {
    if (index <= 2 || index !== ticks.length - 1) {
      return value;
    }
  }
}
