<button class="btn" id="toggle-sidebar" type="button" (click)="toggleSidebar()">
  <i class="icon icon-bars"></i>
</button>
<nav class="sidebar" [ngClass]="{sidebarPushRight: isActive}" (click)="onSidebarClick()">
  <div class="sidebar-search my-3" *ngIf="enableSearch">
    <div class="search-icon">
      <i class="icon icon-search"></i>
    </div>
    <input type="text" class="form-control" [formControl]="searchFormControl">
  </div>

  <ul class="list-group" *ngIf="sidebar">
    <ng-container *ngFor="let item of sidebar; trackBy: elementTrackBy">
      <ng-container *ngIf="!item.hidden && (!isSearching || item.searchScore <= 1)">
        <li class="list-group-item d-flex" [ngClass]="{active: item.isActive}" @slideAnimation [tooltip]="(!item.workOffline && isWorkOffline)  ? 'You are working in offline mode' : null">
          <a class="btn text-truncate" *ngIf="item.link; else noLink" [routerLink]="item.link" [class.disabled]="!item.workOffline && isWorkOffline">
            {{item.label}}
          </a>
          <ng-template #noLink>
            <button class="btn" (click)="onCategoryClick(item)" [disabled]="!item.workOffline && isWorkOffline">
              {{item.label}}
            </button>
          </ng-template>
          <button class="btn" *ngIf="item.children" (click)="item.isExpanded = !item.isExpanded">
            <i *ngIf="!item.isExpanded" class="icon icon-chevron-right"></i>
            <i *ngIf="item.isExpanded" class="icon icon-chevron-down"></i>
          </button>
        </li>
        <ng-container *ngIf="item.children && (item.isExpanded || (isSearching && item.searchScore <= 1))">
          <ng-container *ngFor="let child of item.children; trackBy: elementTrackBy">
            <li class="list-group-item list-group-item-nested d-flex" [ngClass]="{active: child.isActive}"
              *ngIf="!child.hidden && (!isSearching || child.searchScore <= 1)" @slideAnimation [tooltip]="(!child.workOffline && isWorkOffline)  ? 'You are working in offline mode' : null">
              <a class="btn" [routerLink]="child.link" [class.disabled]="!child.workOffline && isWorkOffline">
                <span class="d-inline-block">{{child.label}}</span>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</nav>
