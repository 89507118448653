/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { ChartJSUtils } from '../../chartjs.utils';
import { BytePipe } from '../../pipes/byte.pipe';
import { DecimalPipe } from '../../pipes/decimal.pipe';
import { ChartjsZoomPlugin } from '../chartjs-zoom-plugin';

export interface ChartjsDataSeries {
  label: string;
  values: any[];
  color: string;
  yAxis?: number;
}

export interface ChartjsData {
  timestamps?: Date[];
  dataSeries: ChartjsDataSeries[];
}

export interface ChartjsYAxis {
  type: string;
  min?: number;
  max?: number;
  label?: string;
}

export interface ZoomEvent {
  start: number;
  end: number;
}

interface HTMLTooltipDataSeries {
  color: string;
  label: string;
  value: string;
}

interface HTMLTooltipData {
  cssStyle: any;
  placement: string;
  timestamp: Date;
  dataSeries: HTMLTooltipDataSeries[];
}

interface HTMLLegendItem {
  color: string;
  label: string;
  hidden: boolean;
}

@Component({
  selector: 'app-chartjs',
  templateUrl: './chartjs.component.html',
  styleUrls: ['./chartjs.component.scss']
})
export class ChartjsComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input() data: ChartjsData;
  @Input() startTimestamp: number = null;
  @Input() endTimestamp: number = null;
  @Input() yAxes: ChartjsYAxis[] = null;

  @Input() zoomOnInputOnly = false;

  @Input() chartType: string;
  @Input() isCustomQuery = false;

  @Output() zoom = new EventEmitter<ZoomEvent>();
  @Output() pushWarning = new EventEmitter<string>();

  @ViewChild('chartCanvas') chartCanvas: ElementRef;

  chart: any = null;
  tooltip: HTMLTooltipData = null;
  tooltipOpen = true;
  legendItems: HTMLLegendItem[] = null;
  chartPlugin: ChartjsZoomPlugin = null;

  private zoomRequestsSub: Subscription = null;
  private yAxisValuePrinters = new Map<string, Function>();

  pieDataSum = 0;
  pieTimeStamp: Date = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.yAxes) {
      this.updateYAxes();
    } else if (changes.data || changes.startTimestamp || changes.endTimestamp) {
      this.updateData();
      this.buildLegendItems();
    } else if (changes.chartType) {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.createChart();
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => this.createChart(), 0);
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }

    if (this.zoomRequestsSub) {
      this.zoomRequestsSub.unsubscribe();
    }
  }

  private createChart() {
    if (this.chart || !this.chartCanvas) {
      return;
    }

    this.yAxisValuePrinters.clear();
    const yAxes = this.getYAxes();
    yAxes.forEach(yAxis => {
      if (yAxis.ticks && yAxis.ticks.callback) {
        this.yAxisValuePrinters.set(yAxis.id, yAxis.ticks.callback);
      }
    });

    this.chartPlugin = new ChartjsZoomPlugin();
    const lineAndBarchartConfig: any = {
      type: this.chartType,
      options: {
        animation: {
          duration: 0
        },
        hover: {
          animationDuration: 0,
          intersect: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              id: 'time',
              type: 'time',
              distribution: 'series',
              ticks: {
                source: 'labels',
                maxRotation: 0,
                autoSkipPadding: 10,
                autoSkip: true,
              },
              time: {
                min: null,
                max: null
              }
            }
          ],
          yAxes
        },
        tooltips: {
          enabled: false,
          position: 'nearest',
          intersect: false,
          custom: this.tooltipCallback.bind(this)
        },
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        spanGaps: true
      },
      plugins: [
        this.chartPlugin
      ]
    };

    const pieData = this.getPieValues();
    const pieChartConfig = {
      type: this.chartType,
      data: {
        labels: pieData.pieLabel,
        datasets: [{
          data: pieData.pieValues,
          backgroundColor: pieData.pieColor,
        }]
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          callbacks: {
            label(tooltipItem) {
              let label = pieData.pieLabel[tooltipItem.index] || '';
              let value;
              if (label) {
                label += ': ';
                value = yAxes[0].ticks.callback(pieData.pieValues[tooltipItem.index]);
                label += value;
              }
              return label;
            }
          }
        },
        legend: {
          display: false
        }
      }
    };
    if (this.chartType === 'pie') {
      this.chart = new Chart(this.chartCanvas.nativeElement.getContext('2d'), pieChartConfig);
    } else {
      this.chart = new Chart(this.chartCanvas.nativeElement.getContext('2d'), lineAndBarchartConfig);
    }
    this.updateData();
    this.buildLegendItems();

    this.zoomRequestsSub = this.chartPlugin.zoomRequests.subscribe(request => {
      if (request.end - request.start < 1000) {
        return;
      }

      if (!this.zoomOnInputOnly) {
        this.startTimestamp = request.start;
        this.endTimestamp = request.end;
        this.updateData();
      }

      this.zoom.emit({ start: request.start, end: request.end });
    });
  }

  getPieValues() {
    const pieData = {
      pieLabel: [],
      pieValues: [], pieColor: [], warning: null
    };
    this.data.dataSeries.forEach(data => {
      if (data.values && !(data.values[data.values.length - 1] !== data.values[data.values.length - 1])) {
        pieData.pieLabel.push(data.label);
        pieData.pieValues.push(data.values[data.values.length - 1]);
        pieData.pieColor.push(data.color);
      } else {
        pieData.warning = 'Showing only numeric metrics.';
      }
    });
    if (this.data.timestamps) {
      this.pieTimeStamp = this.data.timestamps[this.data.timestamps.length - 1];
    }
    return pieData;
  }

  private tooltipCallback(tooltip: any) {
    if (tooltip.opacity && !this.chartPlugin.isSelecting()) {
      const timestamp = tooltip.title[0];
      const tooltipDataSeries: HTMLTooltipDataSeries[] = [];

      tooltip.dataPoints.forEach((dataPoint, index) => {
        const dataSet = this.chart.data.datasets[dataPoint.datasetIndex];
        const printer = dataSet ? this.yAxisValuePrinters.get(dataSet.yAxisID) : ((v: any) => v);
        if (this.chart.options.tooltips.mode !== 'x' ||
          Math.floor(dataPoint.xLabel.getTime() / 1000) === Math.floor(timestamp.getTime() / 1000)) {
          tooltipDataSeries.push({
            color: tooltip.labelColors[index].borderColor,
            label: dataSet.label,
            value: printer(dataPoint.yLabel)
          });
        }
      });

      // HACK: If the tooltip placement changes, force ngx-bootstrap to re-create the component
      const placement = tooltip.xAlign === 'center' ? 'top' : (tooltip.xAlign === 'left' ? 'right' : 'left');
      if (this.tooltip && this.tooltip.placement !== placement) {
        this.tooltipOpen = false;
        window.setTimeout(() => {
          this.tooltipOpen = true;
        });
      }

      this.tooltip = {
        cssStyle: {
          left: tooltip.caretX + 'px',
          top: tooltip.caretY + 'px'
        },
        placement,
        timestamp,
        dataSeries: tooltipDataSeries
      };
    } else {
      this.tooltip = null;
    }
  }

  private updateData(): any {
    if (!this.chart || !this.data) {
      return;
    }
    if (this.chartType === 'pie') {
      const pieData = this.getPieValues();
      this.chart.data.labels = pieData.pieLabel;
      this.chart.data.datasets = [{
        data: pieData.pieValues,
        backgroundColor: pieData.pieColor,
      }];
      this.chart.update();
      this.pushWarning.emit(pieData.warning);
      return;
    }
    let chartHoverMode = 'x';
    let startIndex = -1;
    let endIndex = -1;

    if (this.data.timestamps && this.data.timestamps.length) {
      chartHoverMode = 'index';
      startIndex = 1;
      if (this.startTimestamp !== null) {
        while (startIndex < this.data.timestamps.length && this.data.timestamps[startIndex].getTime() <= this.startTimestamp) {
          startIndex++;
        }
      }
      startIndex--;

      endIndex = this.data.timestamps.length - 1;
      if (this.endTimestamp !== null) {
        while (endIndex >= 0 && this.data.timestamps[endIndex].getTime() >= this.endTimestamp) {
          endIndex--;
        }
      }
      if (endIndex < 0) {
        endIndex += 1;
      }
      this.chart.data.labels = this.data.timestamps.slice(startIndex, endIndex);
    }

    let warning = null;
    this.chart.data.datasets = this.data.dataSeries.map((series, index) => {
      const hidden = this.legendItems && this.legendItems[index] && this.legendItems[index].hidden;

      const seriesConfig: any = {
        label: series.label,
        data: startIndex > -1 ? series.values.slice(startIndex, endIndex) : series.values,
        borderColor: series.color,
        backgroundColor: series.color,
        pointRadius: 0,
        pointHoverRadius: 2,
        lineTension: 0,
        borderWidth: 1,
        fill: false,
        hidden
      };

      if (typeof series.yAxis === 'number') {
        seriesConfig.yAxisID = 'yaxis-' + series.yAxis;
      }
      if (seriesConfig.data.some(value => value !== value)) {
        warning = 'Showing only numeric metrics.';
      }
      return seriesConfig;
    });
    this.chart.options.hover.mode = chartHoverMode;
    this.chart.options.tooltips.mode = chartHoverMode;
    this.chart.update();
    this.pushWarning.emit(warning);
  }

  onLegendItemClick(index: number) {
    if (this.chartType === 'pie') {
      return;
    }
    const dataSeriesStatus = this.chart.data.datasets.map((v, i) => {
      const meta = this.chart.getDatasetMeta(i);
      return meta.hidden === null ? !!v.hidden : meta.hidden;
    });

    const allVisible = dataSeriesStatus.reduce((prev, current) => !current && prev, true);
    if (allVisible) {
      for (let i = 0; i < dataSeriesStatus.length; i++) {
        this.chart.getDatasetMeta(i).hidden = (i !== index);
      }
    } else {
      dataSeriesStatus[index] = !dataSeriesStatus[index];
      if (dataSeriesStatus[index] && dataSeriesStatus.reduce((prev, current) => current && prev, true)) {
        for (let i = 0; i < dataSeriesStatus.length; i++) {
          const meta = this.chart.getDatasetMeta(i);
          meta.hidden = false;
        }
      } else {
        const meta = this.chart.getDatasetMeta(index);
        meta.hidden = meta.hidden === null ? dataSeriesStatus[index] : !meta.hidden;
      }
    }
    this.chart.update();
    this.buildLegendItems();
  }

  buildLegendItems() {
    if (this.chart) {
      if (this.chartType === 'pie') {
        this.legendItems = this.data.dataSeries.map(data => ({
            color: data.color,
            label: data.label,
            hidden: false
          }));
      } else {
        this.legendItems = this.chart.data.datasets.map((dataset: any, index) => {
          const meta = this.chart.getDatasetMeta(index);
          return {
            color: dataset.borderColor,
            label: dataset.label,
            hidden: meta.hidden === null ? !!dataset.hidden : meta.hidden
          };
        });
      }
    }
  }

  private updateYAxes() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
    this.createChart();
  }

  private getYAxes(): any[] {
    if (this.yAxes && this.yAxes.length) {
      return this.yAxes.map((axisConfig, index) => {
        const axis = this.buildYAxis(axisConfig);

        axis.id = 'yaxis-' + index;
        axis.stacked = false;
        axis.display = true;
        axis.ticks = axis.ticks || {};
        axis.position = index === 1 ? 'right' : 'left';
        if (axisConfig) {
          if (typeof axisConfig.min === 'number') {
            axis.ticks.min = axisConfig.min;
          }
          if (typeof axisConfig.max === 'number') {
            axis.ticks.max = axisConfig.max;
          }
          const axisLabel = (axisConfig.label || '').trim();
          if (axisLabel) {
            axis.scaleLabel = {
              display: true,
              labelString: axisLabel
            };
          }
        }

        return axis;
      });
    } else {
      return [{ id: 'yaxis-0', stacked: false }];
    }
  }

  // TODO: Makes this library of Y axes easier to extend
  private buildYAxis(config: ChartjsYAxis): any {
    if (!config) {
      return {};
    }

    if (config.type === 'percent') {
      return {
        ticks: {
          callback: v => new DecimalPipe().transform(v) + '%'
        }
      };
    } else if (config.type === 'bytes') {
      const bytePipe = new BytePipe();
      return {
        ticks: {
          callback: v => bytePipe.transform(v)
        },
        beforeBuildTicks: ChartJSUtils.byteAxisBeforeBuildTicks
      };
    } else if (config.type === 'number') {
      return {
        ticks: {
          callback: v => new DecimalPipe().transform(v)
        }
      };
    } else {
      return {};
    }
  }
}
