/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractDashboardComponent } from '../../monitoring/abstract-dashboard-component';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { DBSPACE_PERCENT_FREE_THRESHOLD } from './serverSpace';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-storage-dashboard-summary-stats',
  templateUrl: 'storage-dashboard-summary-stats.html'
})
export class StorageDashboardSummaryStatsComponent extends AbstractDashboardComponent implements OnInit, OnDestroy {

  @Input() server: InformixServer = null;

  dataLoading = true;
  dataLoadErrorMessage: string = null;

  numSpacesBelowFreeThreshold: number = null;
  numSpacesNotBackedUp: number = null;
  oldestLevel0Backup: number = null;
  storageFreeSpaceThreshold = DBSPACE_PERCENT_FREE_THRESHOLD;
  storagePoolTotalEntries: number = null;
  storagePoolActiveEntries: number = null;
  storagePoolErrorEntries: number = null;
  storagePoolFullEntries: number = null;
  storagePoolInfoForbidden = false;

  private storageBackupSub: Subscription;
  private storagePoolSub: Subscription;
  private storageSpacesSub: Subscription;

  constructor(private storageService: InformixServerStorageService) {
    super();
  }

  ngOnInit() {
    if (this.server.hasMonitorPassword) {
      this.refreshData();
    }
  }

  ngOnDestroy() {
    if (this.storageBackupSub) {
      this.storageBackupSub.unsubscribe();
    }
    if (this.storagePoolSub) {
      this.storagePoolSub.unsubscribe();
    }
    if (this.storageSpacesSub) {
      this.storageSpacesSub.unsubscribe();
    }
  }

  private refreshData() {
    this.getStorageSpacesInfo();
    this.getStorageBackupInfo();
    this.getStoragePoolInfo();
  }

  private getStorageSpacesInfo() {
    this.storageSpacesSub = this.storageService.getServerSpaces(this.server.id).subscribe(spaces => {
      this.dataLoading = false;
      let numSpacesBelowFreeThreshold = 0;

      spaces.forEach(space => {
        if (space.percent_used > 100 - this.storageFreeSpaceThreshold) {
          numSpacesBelowFreeThreshold++;
        }
      });
      this.numSpacesBelowFreeThreshold = numSpacesBelowFreeThreshold;
    }, (err: HttpErrorResponse) => {
      this.dataLoading = false;
      console.error('Could not get storage spaces info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private getStorageBackupInfo() {
    this.storageBackupSub = this.storageService.getBackupInfo(this.server.id).subscribe(backupInfo => {
      this.dataLoading = false;
      this.oldestLevel0Backup = backupInfo.oldest_level0;
      this.numSpacesNotBackedUp = backupInfo.spaces_not_backed_up;
    }, (err: HttpErrorResponse) => {
      console.error('Could not get storage backup info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private getStoragePoolInfo() {
    if (this.storagePoolSub) {
      this.storagePoolSub.unsubscribe();
    }
    this.storagePoolSub = this.storageService.getStoragePoolInfo(this.server.id).subscribe(entries => {
      this.storagePoolTotalEntries = 0;
      this.storagePoolActiveEntries = 0;
      this.storagePoolErrorEntries = 0;
      this.storagePoolFullEntries = 0;
      entries.forEach(entry => {
        this.storagePoolTotalEntries++;
        if (entry.status === 'Error') {
          this.storagePoolErrorEntries++;
        } else if (entry.status === 'Full') {
          this.storagePoolFullEntries++;
        } else {
          this.storagePoolActiveEntries++;
        }
      });
    }, (err: HttpErrorResponse) => {
      if (err.status === 403) {
        console.log('You do not have permission to see storage pool information', err);
        this.storagePoolInfoForbidden = true;
      } else {
        console.error('Could not get storage pool info', err);
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      }
    });
  }
}
