/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Type } from '@angular/core';

import { AbstractAlertOperand } from '../abstract-alert-operand';
import { ConstantEnumOperandComponent } from './constant-enum-operand.component';
import { ConstantNumberOperandComponent } from './constant-number-operand.component';
import { OnlineLogRegexOperandComponent } from './online-log-regex-operand.component';

export const constantOperandClasses: Type<AbstractAlertOperand>[] = [
  ConstantNumberOperandComponent,
  ConstantEnumOperandComponent,
  OnlineLogRegexOperandComponent
];
