/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractSettingsComponent } from './abstractSettings.component';
import { TIME_UNITS_ARRAY } from '../../../shared/controls/timeInput.component';

@Component({
  selector: 'app-alert-preferences-settings',
  templateUrl: './alertPreferencesSettings.html'
})
export class AlertPreferencesSettingsComponent extends AbstractSettingsComponent implements OnChanges {

  formGroup: UntypedFormGroup;
  timeoutTimeUnits = TIME_UNITS_ARRAY.slice(0, 4);

  ngOnChanges(changes: SimpleChanges) {
    if (!this.settings) {
      this.settings = {
        instant: true,
        timeout: 0 }
      ;
    }

    if (!this.formGroup) {
      this.formGroup = new UntypedFormGroup({
        timeout: new UntypedFormControl(this.settings.timeout, [Validators.required, Validators.min(0)]),
        alwaysAlert: new UntypedFormControl(this.settings.timeout === 0, [Validators.required])
      });

      this.formGroup.valueChanges.subscribe(() => {
        this.eventChange.emit({
          valid: this.formGroup.valid,
          modified: this.formGroup.value.timeout !== this.settings.timeout
        });
      });

      this.formGroup.controls.alwaysAlert.valueChanges.subscribe((value) => {
        if (value) {
          this.formGroup.controls.timeout.setValue(0);
        }
      });
    }
  }

  public getSettings(): any {
    return {
      instant: true,
      timeout: this.formGroup.value.timeout
    };
  }

  public requiresSettings(): boolean {
    return true;
  }

  public isValid(): boolean {
    return this.formGroup.valid;
  }
}
