/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { UserService } from '../shared/user/user.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CanActivateService implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.userService.getCurrentUser().then(user => {
        if (user && !user.isFirstSignIn) {
          resolve(false);
          this.router.navigate(['/dashboard/groups']);
        } else {
          resolve(true);
        }
      }).catch(err => {
        resolve(true);
      });
    });
  }
}
