/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2020. All Rights Reserved.
 *******************************************************************************/

import { Observer, Observable } from 'rxjs';
import { ServerSensorManager } from './server-sensor-manager';
import { Sensor } from './sensor';

interface SensorDataSourceSubscriber {
  id: number;
  observer: Observer<any>;
  primaryKeys: string[];
}

export class SensorDataSource {

  private pollTimeout: number = null;
  private lastPollTimestamp: number = null;
  private pollPrimaryKeys: string[] = [];
  private pollPrimaryKeysNeedRebuild = false;

  private nextSubscriberId = 1;
  private pollSubscribers: { [key: number]: SensorDataSourceSubscriber } = {};
  private pollSubscriberCount = 0;

  constructor(
    private manager: ServerSensorManager,
    private sensor: Sensor
  ) {

  }

  public setSensor(sensor: Sensor) {
    this.sensor = sensor;
  }

  public getSensor(): Sensor {
    return this.sensor;
  }

  public getData(fromTimestamp: number, toTimestamp: number, downsampleMax?: number, primaryKeys?: string[]): Promise<any[]> {
    return this.manager.getData(this.sensor, fromTimestamp, toTimestamp, downsampleMax, primaryKeys).then(data => {
      if (!data) {
        return null;
      }

      // It's possible to receive more data than asked for
      // In that case, calculate the correct slice of data
      return this.sliceData(data, fromTimestamp, toTimestamp);
    });
  }

  private sliceData(data: any[], fromTimestamp: number, toTimestamp: number): any {
    let sliceStart = 0;
    let sliceEnd = data.length;

    if (toTimestamp < data[0].timestamp) {
      for (let i = 1; i < data.length; i++) {
        if (data[i].timestamp <= toTimestamp) {
          sliceStart = i;
          break;
        }
      }
    }

    if (fromTimestamp > data[data.length - 1].timestamp) {
      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].timestamp >= fromTimestamp) {
          sliceEnd = i + 1;
          break;
        }
      }
    }

    if (sliceStart > 0 || sliceEnd < data.length) {
      return data.slice(sliceStart, sliceEnd);
    } else {
      return data;
    }
  }

  public getLiveData(primaryKeys: string[], fromTimestamp: number): Observable<any> {
    if (fromTimestamp && !this.lastPollTimestamp) {
      this.lastPollTimestamp = fromTimestamp;
    }

    return new Observable((observer: Observer<any>) => {
      const id = this.addObserver(observer, primaryKeys);
      return () => {
        this.removeObserver(id);
      };
    });
  }

  public getMetaData(): Promise<any> {
    return this.manager.getMeta(this.sensor);
  }

  private addObserver(observer: Observer<any>, primaryKeys: string[]): number {
    const subscriber: SensorDataSourceSubscriber = {
      id: this.nextSubscriberId++,
      observer,
      primaryKeys
    };
    this.pollSubscribers[subscriber.id] = subscriber;
    this.mergePrimaryKeys(primaryKeys);
    if (this.pollSubscriberCount === 0) {
      this.startPolling();
    }
    this.pollSubscriberCount++;
    return subscriber.id;
  }

  private mergePrimaryKeys(keys: string[]) {
    if (!this.pollPrimaryKeys) {
      return;
    }
    if (!keys) {
      this.pollPrimaryKeys = null;
      return;
    }

    keys.forEach(key => {
      if (this.pollPrimaryKeys.indexOf(key) < 0) {
        this.pollPrimaryKeys.push(key);
      }
    });
  }

  private removeObserver(id: number) {
    this.pollSubscriberCount--;
    if (this.pollSubscriberCount === 0) {
      this.lastPollTimestamp = null;
      this.pollPrimaryKeys = [];
      this.nextSubscriberId = 1;
      this.stopPolling();
    } else {
      const subscriber = this.pollSubscribers[id];

      // Mark primary key list as requiring a rebuild when someone unsubscribes
      // This doesn't need to happen if the subscriber is trying to remove
      // a finite list of keys from an infinite one (null)
      if (this.pollPrimaryKeys || !subscriber.primaryKeys) {
        this.pollPrimaryKeysNeedRebuild = true;
      }
    }

    delete this.pollSubscribers[id];
  }

  private startPolling() {
    this.stopPolling();

    this.pollTimeout = window.setTimeout(() => {
      let metaDataPromise = Promise.resolve(null);
      if (!this.lastPollTimestamp) {
        metaDataPromise = this.manager.getMeta(this.sensor).then(meta => {
          if (meta && meta.most_recent_timestamp) {
            // Subtract 1ms from the timestamp to make sure the timestamp
            // is included in the next poll for data
            this.lastPollTimestamp = meta.most_recent_timestamp - 1;
          }
        });
      }

      metaDataPromise.then(() => {
        if (!this.lastPollTimestamp) {
          return;
        }

        // Rebuild primary key list, if needed
        if (this.pollPrimaryKeysNeedRebuild) {
          this.pollPrimaryKeys = [];
          for (const id in this.pollSubscribers) {
            if (this.pollSubscribers.hasOwnProperty(id)) {
              this.mergePrimaryKeys(this.pollSubscribers[id].primaryKeys);
              if (this.pollPrimaryKeys === null) {
                break;
              }
            }
          }
          this.pollPrimaryKeysNeedRebuild = false;
        }

        return this.manager.getData(this.sensor, this.lastPollTimestamp, null, null, this.pollPrimaryKeys).then(data => {
          if (data.length > 0) {
            for (const id in this.pollSubscribers) {
              if (this.pollSubscribers.hasOwnProperty(id)) {
                this.pollSubscribers[id].observer.next(data);
              }
            }
            this.lastPollTimestamp = data[0].timestamp;
          }
        });
      }).catch(err => {
        // Ignore
      }).then(() => {
        this.startPolling();
      });
    }, this.sensor.runInterval * 1000);
  }

  private stopPolling() {
    if (this.pollTimeout) {
      window.clearTimeout(this.pollTimeout);
      this.pollTimeout = null;
    }
  }
}
