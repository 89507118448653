/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../informixServer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatabasePrivilegeInfo, TablePrivilegeInfo, SQLAdminUserInfo, InternalUserInfo } from './privileges.model';

@Injectable()
export class PrivilegesService {

  constructor(private httpClient: HttpClient) { }

  public getUsers(server: InformixServer, db: string): Observable<DatabasePrivilegeInfo[]> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/privilegedUsers';
    return this.httpClient.get<DatabasePrivilegeInfo[]>(url);
  }

  public grantDatabasePrivilege(server: InformixServer, db: string,
    privilegeInfo: DatabasePrivilegeInfo): Observable<DatabasePrivilegeInfo> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/privilegedUsers';
    return this.httpClient.post<DatabasePrivilegeInfo>(url, privilegeInfo);
  }

  public revokeDatabasePrivilege(server: InformixServer, db: string,
    privilegeInfo: DatabasePrivilegeInfo): Observable<DatabasePrivilegeInfo> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/privilegedUsers';
    const httpOptions = {
      headers: new HttpHeaders(), body: privilegeInfo
    };
    return this.httpClient.delete<DatabasePrivilegeInfo>(url, httpOptions);
  }

  public getTablePrivilegeInfo(server: InformixServer, db: string): Observable<TablePrivilegeInfo[]> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/tablePrivileges';
    return this.httpClient.get<TablePrivilegeInfo[]>(url);
  }

  public grantTablePrivileges(server: InformixServer, db: string, tablePrivilegeInfo: TablePrivilegeInfo): Observable<TablePrivilegeInfo> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/tablePrivileges';
    return this.httpClient.post<TablePrivilegeInfo>(url, tablePrivilegeInfo);
  }

  public revokeTablePrivileges(server: InformixServer, db: string, tablePrivilegeInfo: TablePrivilegeInfo): Observable<TablePrivilegeInfo> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/tablePrivileges';
    const httpOptions = {
      headers: new HttpHeaders(), body: tablePrivilegeInfo
    };
    return this.httpClient.delete<TablePrivilegeInfo>(url, httpOptions);
  }

  public modifyTablePrivileges(server: InformixServer, db: string, tablePrivilegeInfo: TablePrivilegeInfo): Observable<TablePrivilegeInfo> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/tablePrivileges';
    return this.httpClient.put<TablePrivilegeInfo>(url, tablePrivilegeInfo);
  }

  public getAdminPrivilegeInfo(server: InformixServer): Observable<SQLAdminUserInfo[]> {
    const url = 'informix/' + server.id + '/privileges/' + 'adminPrivileges';
    return this.httpClient.get<SQLAdminUserInfo[]>(url);
  }

  public addAdminPrivilegedUsers(server: InformixServer, adminUserInfo: SQLAdminUserInfo): Observable<SQLAdminUserInfo> {
    const url = 'informix/' + server.id + '/privileges/' + 'adminPrivileges';
    return this.httpClient.post<SQLAdminUserInfo>(url, adminUserInfo);
  }

  public revokeAdminPrivileges(server: InformixServer, adminUserInfo: SQLAdminUserInfo): Observable<SQLAdminUserInfo> {
    const url = 'informix/' + server.id + '/privileges/' + 'adminPrivileges';
    const httpOptions = {
      headers: new HttpHeaders(), body: adminUserInfo
    };
    return this.httpClient.delete<SQLAdminUserInfo>(url, httpOptions);
  }

  public getInternalUsersInfo(server: InformixServer): Observable<InternalUserInfo[]> {
    const url = 'informix/' + server.id + '/privileges/' + 'internalUsers';
    return this.httpClient.get<InternalUserInfo[]>(url);
  }

  public addInternalUser(server: InformixServer, internalUserInfo: InternalUserInfo): Observable<{ ok: boolean }> {
    const url = 'informix/' + server.id + '/privileges/' + 'internalUsers';
    return this.httpClient.post<{ ok: boolean }>(url, internalUserInfo);
  }

  public modifyInternalUser(server: InformixServer, internalUserInfo: InternalUserInfo): Observable<{ ok: boolean }> {
    const url = 'informix/' + server.id + '/privileges/' + 'internalUsers';
    return this.httpClient.put<{ ok: boolean }>(url, internalUserInfo);
  }

  public deleteInternalUser(server: InformixServer, userName: string): Observable<{ ok: boolean }> {
    const url = 'informix/' + server.id + '/privileges/' + 'internalUsers';
    const httpOptions = {
      headers: new HttpHeaders(), body: { userName }
    };
    return this.httpClient.delete<{ ok: boolean }>(url, httpOptions);
  }
}
