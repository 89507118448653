<div class="container-fluid">
  <h4 class="mt-4">Repository Database Configuration</h4>
  <div class="row">
    <div class="form-group col-sm-4 mb-0">
      <label class="col-form-label font-weight-bold"><span class="text-danger">* </span>Select
        Repository Server</label>
      <div class="col-sm-12">
        <ng-container *ngIf="repositoryServer; else noRepositoryServer">
          <i class="icon icon-database"></i>
          {{repositoryServer.name}}
        </ng-container>
        <ng-template #noRepositoryServer>
          No repository selected.
        </ng-template>
        <button type="button" class="btn btn-secondary ml-2" (click)="openModal(selectRepositoryModal)">
          Select...
        </button>
      </div>
    </div>
    <div class="form-group col-sm-8 align-items-center mb-0" *ngIf="repositoryServer">
      <label class="col-form-label font-weight-bold"><span class="text-danger">* </span>Select
        Database</label>
      <div class="col-sm-8 col-lg-6">
        <ng-container *ngIf="databases; else loadingDatabases">
          <select class="form-control" *ngIf="databases.length; else noDatabaseAvailable" [(ngModel)]="selectedDatabase" (ngModelChange)="setHelperText()">
            <option *ngFor="let database of databases">{{database}}</option>
          </select>
          <ng-template #noDatabaseAvailable>
            No database available.
          </ng-template>
        </ng-container>
        <ng-template #loadingDatabases>
          <div class="alert alert-danger mb-0" *ngIf="databasesLoadError; else loadingSpinner">
            {{databasesLoadError}}
          </div>
          <ng-template #loadingSpinner>
            <i class="icon icon-circle-notch icon-spin"></i>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
  <form role="form" [formGroup]="formGroup">
    <div class="mt-2">
      <h4 class="mt-4">Connection Properties</h4>
      <div>
        <div class="row">
          <div class="col-12 text-muted">
            <ng-container *ngIf="expandAgentPropsHelpText; else abbreviatedAgentPropsHelpText">
              <span [innerHTML]="helperFullText"></span>
              <a href="javascript:;" (click)="expandAgentPropsHelpText = false; $event.stopPropagation()">See less</a>
            </ng-container>
            <ng-template #abbreviatedAgentPropsHelpText>
              <span [innerHTML]="helperShortText"></span>
              <a href="javascript:;" (click)="expandAgentPropsHelpText = true; $event.stopPropagation()">See more...</a>
            </ng-template>
          </div>
        </div>
      </div>
      
      <div class="row mt-2">
        <div class="col-sm-6 col-lg-6">
          <b>{{server?.name}}: Connection Properties</b>
          <div class="my-2">
            <app-checkbox [disabled]="isReadOnly" [checked]="existingConnectionPropsCheckBox" (change)="agentPropertiesChange()">
              Use existing connection properties (Uncheck to modify properties)
            </app-checkbox>
          </div>
          <div *ngIf="!existingConnectionPropsCheckBox">
            <div *ngIf="!connectionPropertiesForms?.length">
              <span class="text-danger ml-3">* </span>At least one connection property is mandatory
            </div>
            <div formArrayName="connectionProperties" class="mt-3 ml-lg-3 props-container" #connectionPropertiesWrapper>
              <div class="row form-group" 
                *ngFor="let connectionProperty of connectionPropertiesForms.controls; let i = index;" [formGroupName]="i">
                <div class="col-12 col-lg-3 mb-lg-0 mb-1">
                  <input [readonly]="isReadOnly" type="text" #nameInputCon class="form-control" placeholder="Name" formControlName="name" tooltip="" (keyup)="matchPasswordVal(false,i)"
                    placement="right" appExtendedFormControl>
                </div>
                <div class="col-12 col-lg-5 mb-lg-0 mb-1">
                  <div class="input-group password-hide-show inner-addon right-addon">
                    <input [readonly]="isReadOnly" [type]="connectionProperty.controls['hideShowIcon'].value ? 'text' : 'password' "
                      #conValue class="form-control" placeholder="Value" formControlName="value" tooltip="" placement="right"
                      appExtendedFormControl (keyup)="togglePassShow(i, true)">
                    <i class="icon icon-eye" (click)="togglePassShow(i)"
                      *ngIf="connectionProperty.controls['showPasswordIcon'].value && connectionProperty.controls['hideShowIcon'].value && (connectionProperty.controls['value'].value !== '' || (connectionProperty.controls['value'].dirty && connectionProperty.controls['value'].value !== ''))"></i>
                    <i class="icon icon-eye-blocked" (click)="togglePassShow(i)"
                      *ngIf="connectionProperty.controls['showPasswordIcon'].value && !connectionProperty.controls['hideShowIcon'].value && (connectionProperty.controls['value'].value !== '' || (connectionProperty.controls['value'].dirty && connectionProperty.controls['value'].value !== ''))"></i>
                  </div>
                </div>
                <div class="col-12 col-lg-1 mb-lg-0 mb-1">
                  <button [disabled]="isReadOnly" type="button" class="btn btn-danger" (click)="removeConnectionProperty(i)">
                    <i class="icon icon-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-sm btn-secondary ml-0 ml-lg-3" (click)="addConnectionProperty()" [disabled]="!shouldAdd() || isReadOnly">
              <i class="icon icon-plus"></i>
              Add Connection Property
            </button>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6" *ngIf="repoConPropsDisplay">
          <div class="my-2 my-md-0">
            <b>{{repositoryServerName}}: Repository Database Server Connection Properties</b>
          </div>
          <div class="my-2">
            <app-checkbox [disabled]="isReadOnly" [checked]="repoDBServerConnectionPropsCheckBox" (change)="repoServerConnectionPropsChange()">
              Use existing connection properties (Uncheck to modify properties)
            </app-checkbox>
          </div>
          <div *ngIf="!repoDBServerConnectionPropsCheckBox">
            <div *ngIf="!remoteConnectionPropertiesForms?.length">
              <span class="text-danger ml-3">* </span>At least one connection property is mandatory
            </div>
            <div formArrayName="remoteConnectionProperties" class="mt-3 ml-lg-3 props-container" #repoServerConnectionPropertiesWrapper>
              <div class="row form-group"
                *ngFor="let connectionProperty of remoteConnectionPropertiesForms.controls; let i = index"
                [formGroupName]="i">
                <div class="col-12 col-lg-3 mb-lg-0 mb-1">
                  <input [readonly]="isReadOnly" type="text" #nameInputRepoCon class="form-control" placeholder="Name" formControlName="name" tooltip="" (keyup)="matchPasswordVal(true,i)"
                    placement="right" appExtendedFormControl>
                </div>
                <div class="col-12 col-lg-5 mb-lg-0 mb-1">
                  <div class="input-group password-hide-show inner-addon right-addon">
                    <input [readonly]="isReadOnly" [type]="connectionProperty.controls['hideShowIcon'].value ? 'text' : 'password' "
                      #repoConValue class="form-control" placeholder="Value" formControlName="value" tooltip="" placement="right"
                      appExtendedFormControl (keyup)="toggleRemotePassShow(i, true)">
                    <i class="icon icon-eye" (click)="toggleRemotePassShow(i)"
                      *ngIf="connectionProperty.controls['showPasswordIcon'].value && connectionProperty.controls['hideShowIcon'].value && (connectionProperty.controls['value'].dirty && connectionProperty.controls['value'].value !== '')"></i>
                    <i class="icon icon-eye-blocked" (click)="toggleRemotePassShow(i)"
                      *ngIf="connectionProperty.controls['showPasswordIcon'].value && !connectionProperty.controls['hideShowIcon'].value && (connectionProperty.controls['value'].dirty && connectionProperty.controls['value'].value !== '')"></i>
                  </div>
                </div>
                <div class="col-12 col-lg-1 mb-lg-0 mb-1">
                  <button [disabled]="isReadOnly" type="button" class="btn btn-danger" (click)="removeRemoteConnectionProperty(i)">
                    <i class="icon icon-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-sm btn-secondary ml-0 ml-lg-3" (click)="addConnectionProperty(true)" [disabled]="!shouldAdd(true) || isReadOnly">
              <i class="icon icon-plus"></i>
              Add Connection Property
            </button>
          </div>
        </div>

      </div>
    </div>
  </form>
  <div class="mt-2">
    <button type="button" class="btn btn-primary mt-2" [disabled]="!canSave()" (click)="onSave()">
      Save
    </button>
  </div>

  <h4 class="mt-4">Agent Status</h4>
  <app-agent-deploy [server]="server"></app-agent-deploy>
</div>

<ng-template #selectRepositoryModal>
  <div class="modal-header">
    <h5 class="modal-title">Select repository server</h5>
  </div>
  <div class="modal-body p-0">
    <app-group-browser [startingItem]="repositoryServer || server" [config]="browserConfig"
      (serverSelect)="setRepositoryServer($event)"></app-group-browser>
  </div>
</ng-template>
