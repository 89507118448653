/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorMessageHandlers } from '../../../shared/forms/extendedFormControl.directive';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemConfigService } from '../system-config.service';
import { HttpErrorResponse } from '@angular/common/http';

const DATA_CLEANUP_CONFIG_NAME = 'data_cleanup';

@Component({
  selector: 'app-system-data-cleanup-config',
  templateUrl: 'system-data-cleanup-config.html'
})
export class SystemDataCleanupConfigComponent implements OnInit {

  formGroup: UntypedFormGroup = null;

  config: any;

  startTimeErrorHandlers: ErrorMessageHandlers = {
    pattern: error => 'Must be in the format HH:MM',
  };

  constructor(private configService: SystemConfigService,
    private notifications: NotificationsService) { }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      startTime: new UntypedFormControl('00:00', [Validators.required, Validators.pattern('([0-1][0-9]|2[0-3]):[0-5][0-9]')]),
      monday: new UntypedFormControl(true, [Validators.required]),
      tuesday: new UntypedFormControl(true, [Validators.required]),
      wednesday: new UntypedFormControl(true, [Validators.required]),
      thursday: new UntypedFormControl(true, [Validators.required]),
      friday: new UntypedFormControl(true, [Validators.required]),
      saturday: new UntypedFormControl(true, [Validators.required]),
      sunday: new UntypedFormControl(true, [Validators.required]),
      rowsPerTxn: new UntypedFormControl(1000, [Validators.required])
    });

    this.configService.getConfig(DATA_CLEANUP_CONFIG_NAME).then(config => {
      this.config = config;
      this.formGroup.controls.startTime.setValue(config.schedule.startTime);
      this.formGroup.controls.monday.setValue(config.schedule.monday);
      this.formGroup.controls.tuesday.setValue(config.schedule.tuesday);
      this.formGroup.controls.wednesday.setValue(config.schedule.wednesday);
      this.formGroup.controls.thursday.setValue(config.schedule.thursday);
      this.formGroup.controls.friday.setValue(config.schedule.friday);
      this.formGroup.controls.saturday.setValue(config.schedule.saturday);
      this.formGroup.controls.sunday.setValue(config.schedule.sunday);
      this.formGroup.controls.rowsPerTxn.setValue(config.rowsPerTxn);
    }).catch(err => {
      console.error('Unable to get system config', err);
    });
  }

  saveConfig() {
    const values = this.formGroup.value;
    const newConfig: any = {};
    const schedule: any = {};
    schedule.startTime = values.startTime;
    schedule.monday = values.monday;
    schedule.tuesday = values.tuesday;
    schedule.wednesday = values.wednesday;
    schedule.thursday = values.thursday;
    schedule.friday = values.friday;
    schedule.saturday = values.saturday;
    schedule.sunday = values.sunday;
    newConfig.schedule = schedule;
    newConfig.rowsPerTxn = values.rowsPerTxn;

    this.configService.saveConfig(DATA_CLEANUP_CONFIG_NAME, newConfig).then(() => {
      this.notifications.pushSuccessNotification('Data cleanup configuration saved.');
    }).catch((err: HttpErrorResponse) => {
      console.error('Unable to save data cleanup config', err);
      this.notifications.pushErrorNotification('Data cleanup configuration not saved. ' + err.error ? err.error.err : err);
    });
  }
}
