/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { InformixTable, InformixTableDetails, InformixTableStatistics } from '../../informix-table';
import { SchemaService } from '../../../schema/schema.service';
import { InformixSQLSession } from '../../informix-sql-session';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-statistics-info',
  templateUrl: './table-statistics-info.component.html',
  styleUrls: ['../table-info-page.component.scss']
})
export class TableStatisticsInfoComponent implements OnInit {
  @Input() tableDetails: InformixTableDetails;
  @Input() session: InformixSQLSession;
  @Input() table: InformixTable;
  @Output() refreshData = new EventEmitter<any>();
  autoUpdateStatisticsForm: UntypedFormGroup;
  sysSetting = 10;
  tableFilter = 'All';
  filteredTableStatistics: InformixTableStatistics[];
  tableStatistics: InformixTableStatistics[];
  disableSaveThresholdBtn = true;
  isStatisticsLevelChanged = false;

  constructor(
    private schemaService: SchemaService,
    private fb: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService,
    private notificationsService: NotificationsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.tableFilter = 'All';
    this.autoUpdateStatisticsForm = this.fb.group({
      selectedStatisticsLevel: new UntypedFormControl('A', [Validators.required]),
      threshold: new UntypedFormControl({ value: null, disabled: false }, [this.thresholdCheck(), Validators.required]),
      minChangeThreshold: new UntypedFormControl('systemSetting', [Validators.required])
    });
    this.loadTableDetails();
  }

  private loadTableDetails() {
    if (this.tableDetails.statistics) {
      if (this.autoUpdateStatisticsForm.get('selectedStatisticsLevel')) {
        this.autoUpdateStatisticsForm.get('selectedStatisticsLevel').setValue(this.tableDetails.statistics.statLevel);
      }
      this.filteredTableStatistics = this.tableStatistics = this.tableDetails.statistics.tableStatistics;
      if (Number(this.tableDetails.statistics.statChange) !== null) {
        this.autoUpdateStatisticsForm.controls['minChangeThreshold'].setValue('thresholdValue');
        this.autoUpdateStatisticsForm.controls['threshold'].setValue(Number(this.tableDetails.statistics.statChange));
        this.autoUpdateStatisticsForm.get('threshold').enable();
      }
    }
    this.saveThresholdBtnValidation();
    this.checkStatisticsLevel(this.autoUpdateStatisticsForm.get('selectedStatisticsLevel').value);
  }

  changeThreshold() {
    if (this.autoUpdateStatisticsForm.get('minChangeThreshold').value === 'systemSetting') {
      this.disableSaveThresholdBtn = (this.sysSetting === Number(this.tableDetails.statistics.statChange));
      this.autoUpdateStatisticsForm.get('threshold').disable();
    } else {
      this.autoUpdateStatisticsForm.get('threshold').enable();
    }
  }

  changeTableFilter(filter: string) {
    this.tableFilter = filter;
    this.filteredTableStatistics = this.tableStatistics.filter(filterBy => {
      if (this.tableFilter === 'Column') {
        return filterBy.type === 'Column';
      } else if (this.tableFilter === 'Index') {
        return filterBy.type === 'Index';
      } else {
        return true;
      }
    });
  }

  thresholdCheck(): ValidatorFn {
    return (): null => {
      this.saveThresholdBtnValidation();
      return null;
    };
  }

  saveThresholdBtnValidation() {
    if (this.autoUpdateStatisticsForm && this.tableDetails.statistics) {
      if (this.autoUpdateStatisticsForm.get('threshold').value === Number(this.tableDetails.statistics.statChange)) {
        this.disableSaveThresholdBtn = true;
      } else {
        this.disableSaveThresholdBtn = false;
      }
    }
  }

  saveStatisticsInfo() {
    this.translate.get('schemaManager.tableInfo.saveStatistics')
      .subscribe((text: string) => {
        this.confirmationDialogService.show(text, () => this.saveStatisticsInfoConfirmed());
      });
  }

  saveStatisticsInfoConfirmed() {
    const dataObj = {
      statLevel: this.autoUpdateStatisticsForm.get('selectedStatisticsLevel').value,
      statChange: this.autoUpdateStatisticsForm.get('minChangeThreshold').value === 'systemSetting' ? this.sysSetting :
        this.autoUpdateStatisticsForm.get('threshold').value
    };

    this.schemaService.saveStatisticsData(this.table, dataObj).subscribe(() => {
      this.translate.get('schemaManager.tableInfo.statisticsUpdateSuccessMsg')
        .subscribe((text: string) => {
          this.notificationsService.pushSuccessNotification(text);
        });
      this.refreshData.emit();
    }, err => {
      this.translate.get('schemaManager.tableInfo.statisticsUpdateErrorMsg', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
    });
  }

  checkStatisticsLevel(value: string) {
    value !== this.tableDetails.statistics.statLevel ? this.isStatisticsLevelChanged = true : this.isStatisticsLevelChanged = false;
  }

}
