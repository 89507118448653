<div>
  <form class="h-100" role="form" [formGroup]="advancedOptionsForm">
    <div *ngIf="APICalls?.fetchOptions?.pending && true; else options;" class="h-100 d-flex align-items-center justify-content-center flex-column">
       <i class="icon icon-circle-notch icon-spin"></i>
       <span class="mt-2">Loading Advanced Options... </span>
    </div>
    <ng-template #options>
      <div class="form-group" >
        <div class="row m-0 font-weight-bold d-block">
          <div class="font-weight-bold">
            <label title="Name of the directory for Aborted Transaction Spooling files.">ATS Directory</label>
          </div>
          <app-radio-group formControlName="ats">
            <app-radio class="d-block" [value]="2">
              Generate the ATS files in the temporary directory
            </app-radio>
            <app-radio class="d-block" [value]="1">
              Disable ATS file generation
            </app-radio>
            <app-radio class="d-block" [value]="3">
              Generate the ATS files in this directory
            </app-radio>
          </app-radio-group>
          <div class="font-weight-bold mr-3 mt-2">
            <input formControlName="atsFilePath" type="text" class="form-control" placeholder="Enter Path">
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row m-0 font-weight-bold d-block">
          <div class="font-weight-bold">
            <label title="Name of the directory for Row Information Spooling files.">RIS Directory</label>
          </div>
          <app-radio-group formControlName="ris">
            <app-radio class="d-block" [value]="2">
              Generate the RIS files in the temporary directory
            </app-radio>
            <app-radio class="d-block" [value]="1">
              Disable RIS file generation
            </app-radio>
            <app-radio class="d-block" [value]="3">
              Generate the RIS files in this directory
            </app-radio>
          </app-radio-group>
          <div class="font-weight-bold mr-3 mt-2">
            <input formControlName="risFilePath" type="text" class="form-control" placeholder="Enter Path">
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row m-0 font-weight-bold d-block">
          <div class="font-weight-bold">
            <label title="Specifies the format(text, xml, both) of ATS and RIS files.">ATS & RIS File Format</label>
          </div>
          <app-radio-group formControlName="fileFormat">
            <app-radio class="d-block" [value]="1">
              Text
            </app-radio>
            <app-radio class="d-block" [value]="2">
              XML
            </app-radio>
            <app-radio class="d-block" [value]="3">
              Both
            </app-radio>
          </app-radio-group>
        </div>
      </div>
      <div class="form-group">
        <div class="row m-0 font-weight-bold d-block">
          <div class="font-weight-bold">
            <label title="Set the number of minutes after which an inactive connection is closed after timeout minutes.">Idle timeout (minutes)</label>
          </div>
          <app-radio-group formControlName="idleTimeOut">
            <app-radio class="d-block" [value]="false">
              Off
            </app-radio> 
            <app-radio class="d-block" [value]="true">
              On
            </app-radio>
          </app-radio-group>
          <div class="font-weight-bold mr-3 mt-2">
            <input class="form-control" (input)="handleInput($event?.target)" formControlName="timeOut" type="number" min="1" max="65535">
          </div>
        </div>
      </div>
  
      <ng-container *ngIf="modifyMode">
        <div class="form-group">
            <div class="row m-0 font-weight-bold d-block">
              <div class="font-weight-bold">
                <label for="">Mode</label>
              </div>
            </div>
            <app-radio-group formControlName="mode">    
                <div> 
                  <app-radio [value]="'nochange'">No Change </app-radio>
                </div>
                <div> 
                  <app-radio [value]="'primary'">Primary </app-radio>
                </div>
                <div> 
                  <app-radio [value]="'sendonly'">Send Only </app-radio>
                </div>
                <div> 
                  <app-radio [value]="'readonly'">Read Only </app-radio>
                </div>

            </app-radio-group>
        </div>
        <div class="form-group">
          <div class="row m-0 font-weight-bold d-block">
            <div class="font-weight-bold">
              <label title="Defines a master server for monitoring the quality of data (QOD) for replication servers">Quality of data (QOD) monitoring</label>
            </div>
            <ng-container>    
              <app-radio-group formControlName="qod">  
                <ng-container *ngIf="selectedNode?.typeName === 'Root'">
                  <div *ngIf="!selections?.masterNodeId || !selections?.isSameMaster"> 
                    <app-radio [value]="'define'">Define <strong>{{ selectedNode?.name }}</strong> as master and start QOD monitoring </app-radio>
                  </div>
                </ng-container>  
      
                <div *ngIf="selections?.masterNodeId && selections?.qodState">
                  <app-radio [value]="'stop'">Stop QOD monitoring <strong>{{ (selections?.isSameMaster)?'':selections?.masterNodeName }}</strong> </app-radio>
                </div>
                
                <div *ngIf="selections?.masterNodeId && !selections?.qodState">
                  <app-radio [value]="'start'">Start QOD monitoring <strong>{{ (selections?.isSameMaster)?'':selections?.masterNodeName }}</strong></app-radio>
                </div>
      
                <div class="alert alert-info mt-2 mb-0 w-100">
                  <i class="icon icon-info icon-info-circle text-info pr-1"></i> 
                  <span *ngIf="!selections?.masterNodeId">QOD master not defined</span> 
                  <span *ngIf="selections?.masterNodeId">Currently <strong>{{ selections?.masterNodeName }}</strong> is QOD master and QOD monitoring is {{selections?.qodState?'ON':'OFF'}}.</span>
                </div>
              </app-radio-group>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </form>
</div>
