<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col">
      <h4>
        <strong>Statements</strong>
      </h4>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Data is not available. {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div *ngIf="stmtTypes" class="row">
    <div class="col-xl-3 col-5">
      <div class="form-inline form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="icon icon-glasses"></i>
            </span>
          </div>
          <select class="form-control" [ngModel]="selectedStmtType" (ngModelChange)="onSelectedStmtTypeChange($event)">
            <option [disabled]="selectedStmtType" value="">-- Select Statement Type --</option>
            <option value="ALL">ALL</option>
            <option *ngFor="let type of stmtTypes" [ngValue]="type">{{type}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-8 col-lg-10 pr-0">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
    <div class="col form-group">
      <button id="refresh-button" type="button" class="btn btn-primary float-xl-right float-lg-right float-left"
        (click)="refreshData()" title="Refresh"><i class="icon icon-refresh"></i></button>
    </div>
  </div>

  <app-data-table *ngIf="tracedStatements else tracedStatementsLoading" [data]="tracedStatements" [rowsPerPage]="20"
    searchLabel="Search SQL ( Use ' , ' OR ' + ' operator for multiple keyword search )." noDataAlertMessage="No statements available.">
    <app-data-table-column property="sql_id">
      <ng-template appTableCell let-sqlid="item">
        <div><a class="btn btn-sm btn-secondary" [routerLink]="[sqlid]"
            [queryParams]="{fromTimeStamp: timeStamps.fromTimeStamp, toTimeStamp: timeStamps.toTimeStamp}">
            <i class="icon icon-search"></i>
          </a></div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="SQL Statement" property="sql_statement" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Count" property="count" [sortable]="true">
    </app-data-table-column>
    <app-data-table-column title="Avg Run Time" property="runtime" [sortable]="true">
    </app-data-table-column>
    <app-data-table-column title="Lock Wait Time" property="lockwait" [sortable]="true">
    </app-data-table-column>
    <app-data-table-column title="IO Wait Time" property="iowait" [sortable]="true">
    </app-data-table-column>
  </app-data-table>
  <ng-template #tracedStatementsLoading>
    <div class="d-flex justify-content-center">
      <i class="icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>
  </ng-template>
</div>
