/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { TreeViewNode } from './tree-view-node';

@Directive({
  selector: '[appTreeNodeTemplate]'
})
export class TreeViewNodeTemplateDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'app-tree-view',
  templateUrl: 'tree-view.component.html',
  styleUrls: ['tree-view.component.scss']
})
export class TreeViewComponent implements OnChanges, AfterContentInit {

  @Input() node: TreeViewNode = null;
  @Input() nodeTemplateRef: TemplateRef<any> = null;
  @Input() childIndex: number = null;

  @ContentChild(TreeViewNodeTemplateDirective) nodeTemplate: TreeViewNodeTemplateDirective;

  isOnlyChild = false;
  isFirstChild = false;
  isLastChild = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.node && this.node && this.node.parent && this.childIndex !== null) {
      if (this.node.parent.children.length > 1) {
        this.isOnlyChild = false;
        this.isFirstChild = this.childIndex === 0;
        this.isLastChild = this.childIndex === this.node.parent.children.length - 1;
      } else {
        this.isOnlyChild = this.isFirstChild = this.isLastChild = true;
      }
    }
  }

  ngAfterContentInit() {
    if (!this.nodeTemplateRef && this.nodeTemplate) {
      this.nodeTemplateRef = this.nodeTemplate.templateRef;
    }
  }
}
