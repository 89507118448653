<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    {{ 'schemaManager.dbSequences' | translate }}
  </div>
  <div class="p-2">
    <app-data-table *ngIf="dbSequence; else tableDataLoading" class="table-responsive" [data]="dbSequence"
      [rowsPerPage]="20" [searchLabel]="'schemaManager.dbSeqTab.searchLabel' | translate" sortBy="seqname"
      [noDataAlertMessage]="'schemaManager.dbSeqTab.noDataAlertMessage' | translate">
      <app-data-table-column [title]="'schemaManager.dbSeqTab.seqName' | translate" property="seqname" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.owner' | translate" property="owner" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.cycle' | translate" property="cycle">
        <ng-template appTableCell let-item="item">
          <i class="icon" [ngClass]="item ? 'icon icon-check text-success' : 'icon icon-times text-danger'"></i>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.increment' | translate" property="inc_val"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.cache' | translate" property="cache" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.start' | translate" property="start_val"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.min' | translate" property="min_val" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.max' | translate" property="max_val" [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.restart' | translate" property="restart_val"
        [sortable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'schemaManager.dbSeqTab.grantor' | translate" property="grantor" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.user' | translate" property="grantee" [sortable]="true"
        [searchable]="true">
      </app-data-table-column>
      <app-data-table-column [title]="'common.privileges' | translate" property="tabauth"
        [sortable]="true">
      </app-data-table-column>
    </app-data-table>
    <ng-template #tableDataLoading>
      <div *ngIf="dataLoading" class="d-flex justify-content-center">
        <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
      </div>
      <div *ngIf="serverError" class="mt-4 alert alert-danger">
        <i class="icon icon-danger text-danger"></i> {{serverError}}
      </div>
    </ng-template>
  </div>
</div>
