<div *ngIf="sessionProfile" class="row">
  <div class="col-xs-12 col-sm-6 col-lg-4 mt-2 mb-2">
    <div class="card">
      <div class="card-header">Locks</div>
      <div class="card-body">
        <div class="row">
          <div class="col text-right">
            Locks
          </div>
          <div class="col">
            {{sessionProfile.nlocks}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Lock Requests
          </div>
          <div class="col">
            {{sessionProfile.lock_requests}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Lock Waits
          </div>
          <div class="col">
            {{sessionProfile.lock_waits}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Lock Timeouts
          </div>
          <div class="col">
            {{sessionProfile.lock_timeouts}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Dead Locks
          </div>
          <div class="col">
            {{sessionProfile.deadlocks}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-lg-4 mt-2 mb-2">
    <div class="card">
      <div class="card-header">Rows</div>
      <div class="card-body">
        <div class="row">
          <div class="col text-right">
            Rows Processed
          </div>
          <div class="col">
            {{sessionProfile.rows_processed}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Rows Inserted
          </div>
          <div class="col">
            {{sessionProfile.rows_inserted}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Rows Updated
          </div>
          <div class="col">
            {{sessionProfile.rows_updated}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Rows Deleted
          </div>
          <div class="col">
            {{sessionProfile.rows_deleted}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-lg-4 mt-2 mb-2">
    <div class="card">
      <div class="card-header">I/O Performance</div>
      <div class="card-body">
        <div class="row">
          <div class="col text-right">
            Buffer Reads
          </div>
          <div class="col">
            {{sessionProfile.bufreads}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Buffer Writes
          </div>
          <div class="col">
            {{sessionProfile.bufwrites}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Foreground Reads
          </div>
          <div class="col">
            {{sessionProfile.fg_reads}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Foreground Writes
          </div>
          <div class="col">
            {{sessionProfile.fg_writes}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Sequential Scans
          </div>
          <div class="col">
            {{sessionProfile.seqscans}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-lg-4 mt-2 mb-2">
    <div class="card">
      <div class="card-header">Transactions</div>
      <div class="card-body">
        <div class="row">
          <div class="col text-right">
            Commits
          </div>
          <div class="col">
            {{sessionProfile.commits}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Rollbacks
          </div>
          <div class="col">
            {{sessionProfile.rollbacks}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Long Transactions
          </div>
          <div class="col">
            {{sessionProfile.longtxs}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row">
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-lg-4 mt-2 mb-2">
    <div class="card">
      <div class="card-header">Logs</div>
      <div class="card-body">
        <div class="row">
          <div class="col text-right">
            Log Records
          </div>
          <div class="col">
            {{sessionProfile.log_records}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Log Space
          </div>
          <div class="col">
            {{sessionProfile.log_space | byte}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Max Log Space
          </div>
          <div class="col">
            {{sessionProfile.log_space_max | byte}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-lg-4 mt-2 mb-2">
    <div class="card">
      <div class="card-header">Sorts</div>
      <div class="card-body">
        <div class="row">
          <div class="col text-right">
            Total Sorts
          </div>
          <div class="col">
            {{sessionProfile.total_sorts}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Memory Sorts
          </div>
          <div class="col">
            {{sessionProfile.memory_sorts}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Disk Sorts
          </div>
          <div class="col">
            {{sessionProfile.disk_sorts}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Max Sort
          </div>
          <div class="col">
            {{sessionProfile.max_sort | byte}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
