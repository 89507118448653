/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2021. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformixServer } from './informixServer';
import { InformixServerGroupService } from '../groups/informixServerGroup.service';
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { GroupBreadcrumb } from '../groups/groupBreadcrumb';
import { ServerBreadcrumb } from './serverBreadcrumb';

@Component({
  selector: 'app-server-permissions',
  template: `
  <app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
  <app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
  <div class="container-fluid page-container" *ngIf="server && breadcrumb">
    <app-user-permissions [child]="server"> </app-user-permissions>
  </div>
  `
})
export class ServerPermissionsComponent implements OnInit {

  server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;

  constructor(
    private route: ActivatedRoute,
    private groupService: InformixServerGroupService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;

    this.groupService.getGroup(server.parentGroupId).then(group => {
      this.breadcrumb = GroupBreadcrumb.build(group, ServerBreadcrumb.build(server, [{ name: 'Permissions' }]));
    }).catch(err => {
      console.error(err);
    });
  }
}
