<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"></app-breadcrumb-component>

<div class="container-fluid bg-white" [ngClass]="isFullscreen ? 'dashboard-fullscreen' : 'page-container'">
  <ng-container *ngIf="group && servers && dashboard; else loadingDashboard">
    <div class="row align-items-center" [hidden]="editingPanel">
      <div class="col-6 col-sm-9 col-lg">
        <div *ngIf="!nameFormControl; else editNameForm" class="d-flex align-items-center">
          <h5 class="mb-0 text-truncate">
            {{dashboard.config.name}}
          </h5>
          <button *ngIf="isEditing" class="btn btn-sm btn-icon" (click)="editDashboardName()">
            <i class="icon icon-edit"></i>
          </button>
        </div>

        <ng-template #editNameForm>
          <form class="form-inline" (ngSubmit)="saveName()">
            <input type="text" class="form-control form-control-sm mr-1 flex-grow-1" [formControl]="nameFormControl"
              appFocusOnCreate [focusAndSelect]="true">
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="icon icon-check"></i>
            </button>
            <button type="button" class="btn btn-sm btn-danger" (click)="closeDashboardNameEdit()">
              <i class="icon icon-times"></i>
            </button>
          </form>
        </ng-template>
      </div>

      <div class="col-6 col-sm-3 col-lg-auto text-right" *ngIf="isEditing && servers && servers.length && grid">
        <button type="button" class="btn btn-sm btn-primary" *ngIf="!grid.potentialPanels || grid.panels.length < 1; else chooseLocationMessage"
          (click)="startAddingPanel()">
          Add Panel
        </button>
        <ng-template #chooseLocationMessage>
          Choose location
        </ng-template>
      </div>

      <div class="col-12 col-sm-6 col-lg-3">
        <button type="button" class="btn btn-sm border-gray-20 text-muted dropdown-toggle d-flex align-items-center w-100"
          [popover]="serverListPopoverTemplate" container="body" containerClass="popover-no-padding popover-color" placement="bottom"
          (onShown)="onServerListPopoverShown()" (onHidden)="onServerListPopoverHidden()" [outsideClick]="true"
          #serverListPopover="bs-popover">
          <div class="text-truncate flex-grow-1 text-left mr-1">
            <ng-container *ngIf="servers.length > 1">
              {{servers.length}} servers
            </ng-container>
            <ng-container *ngIf="servers.length === 1">
              <i class="icon icon-database mr-1"></i> {{servers[0].name}}
            </ng-container>
            <ng-container *ngIf="servers.length < 1">
              No servers
            </ng-container>
          </div>
          <span class="caret"></span>
        </button>

        <ng-template #serverListPopoverTemplate>
          <div class="list-group list-group-flush">
            <div class="list-group-item text-center text-muted" *ngIf="selectedServers.length < 1">
              No servers selected
            </div>
            <div class="list-group-item d-flex align-items-center p-0 bg-transparent"
              *ngFor="let server of selectedServers; index as i; first as isFirst; last as isLast">
              <div class="flex-grow-1 text-truncate pl-2">
                <i class="icon icon-database mr-1"></i> {{server.name}}
              </div>
              <div class="text-nowrap">
                <button type="button" class="btn btn-sm btn-icon" [disabled]="isFirst" (click)="shiftSelectedServerUp($event, i)">
                  <i class="icon icon-chevron-up"></i>
                </button>
                <button type="button" class="btn btn-sm btn-icon" [disabled]="isLast" (click)="shiftSelectedServerDown($event, i)">
                  <i class="icon icon-chevron-down"></i>
                </button>
                <button type="button" class="btn btn-sm btn-icon" (click)="removeSelectedServer($event, i)">
                  <i class="icon icon-times"></i>
                </button>
              </div>
            </div>
            <div class="list-group-item bg-transparent popover-btn-group">
              <button class="btn btn-secondary bg-transparent popover-btn flex-grow-1" (click)="openSelectServersModal()" [disabled]="selectedServers.length >= 5">
                Select Servers
              </button>
              <app-check-permissions [permissions]="group.permissions.admin" placement="bottom">
                <button class="btn btn-secondary bg-transparent popover-btn flex-grow-1" (click)="saveDefaultServerList()"
                appPermissionDisable>
                  Save as default
                </button>
              </app-check-permissions>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="col-12 col-sm-6 col-lg-auto text-nowrap text-right">
        <app-dashboard-time-control></app-dashboard-time-control>

        <button type="button" class="btn btn-sm btn-icon ml-4" (click)="toggleFullscreen()">
          <i class="icon" [ngClass]="isFullscreen ? 'icon-minimize' : 'icon-fullScreen'"></i>
        </button>
      </div>
    </div>

    <div class="row" *ngIf="editingPanel">
      <div class="col">
        <h5>
          {{isCreatingNewPanel ? 'New' : 'Edit'}} Panel
        </h5>
      </div>
      <div class="col col-auto">
        <button class="btn btn-sm btn-icon" (click)="stopEditingPanel(true)" tooltip="Save dashboard">
          <i class="icon icon-save"></i>
        </button>
        <button class="btn btn-sm btn-icon" (click)="stopEditingPanel(false)" tooltip="Discard changes">
          <i class="icon icon-times"></i>
        </button>
      </div>
    </div>

    <ng-container *ngIf="servers && servers.length; else selectServersMessage">
      <div *ngIf="!editingPanel">
        <div class="dashboard-container" #dashboardContainer
          [ngClass]="{ 'dashboard-animated': grid && grid.activePanel }" [ngStyle]="grid.cssStyle">
          <ng-container *ngIf="dashboardContainer">
            <div class="dashboard-panel dashboard-panel-shadow" *ngIf="grid.shadowPanel"
              [ngStyle]="grid.shadowPanel.cssStyle">
              <div></div>
            </div>

            <app-dashboard-panel class="dashboard-panel bg-white border-gray-20" *ngFor="let panel of grid.panels" [grid]="grid" [panel]="panel"
              [config]="panel.contentConfig" [editable]="isEditing" [movable]="isEditing" [resizable]="isEditing"
              [ngClass]="{ 'dashboard-panel-active': panel.isActive() }" [ngStyle]="panel.cssStyle"
              (changed)="onPanelChanged()" (delete)="onPanelDelete(panel)" (edit)="onPanelEdit(panel)"
              (duplicate)="onPanelDuplicate(panel)">
            </app-dashboard-panel>

            <ng-container *ngIf="isEditing && !grid.activePanel && grid.potentialPanels">
              <div class="dashboard-panel dashboard-panel-new-placeholder border-gray-20" *ngFor="let panel of grid.potentialPanels"
                [ngStyle]="panel.cssStyle" (click)="!duplicate ? onPotentialPanelClick(panel): null">
                <div class="flex-grow-1 text-center" *ngIf="!duplicate"
                  [ngClass]="isMouseOver ? 'color-gray-10' : 'color-light-gray-50'"
                  (mouseover)="isMouseOver=true" (mouseout)="isMouseOver=false">
                  <i class="icon icon-plus-circle"></i>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <app-dashboard-panel-config *ngIf="editingPanel" [panel]="editingPanel"
        (configChanged)="editingPanelConfig = $event">
      </app-dashboard-panel-config>
    </ng-container>
    <ng-template #selectServersMessage>
      <div class="alert alert-info">
        A dashboard requires at least one Server to work.
        <button class="btn btn-sm btn-primary" (click)="openSelectServersModal()">
          Select Servers
        </button>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingDashboard>
    <div class="text-center">
      <i class="icon icon-circle-notch icon-spin icon-2x"></i>
    </div>
  </ng-template>
</div>

<ng-template #selectServersModal>
  <div class="modal-header">
    <h5 class="modal-title">Select up to 5 servers</h5>
  </div>
  <div class="modal-body p-0">
    <app-group-browser [startingItem]="group" [selectedServers]="selectedServers" [maxSelectionCount]="5"
      (selectionChanged)="selectedServers = $event">
    </app-group-browser>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="applySelectedServers()">
      Apply
    </button>
  </div>
</ng-template>
