/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConnectionManagerInfo, ConnectionUnit, FOCInfo, SLAInfo } from './connection-manager.model';

@Injectable()
export class ConnectionManagerService {
  constructor(private httpClient: HttpClient) { }

  public getConnectionManagerInfo(serverId: number, includeDetailedInfo: boolean): Observable<ConnectionManagerInfo[]> {
    const url = 'informix/' + serverId + '/cmsm?includeDetailedInfo=' + includeDetailedInfo;
    return this.httpClient.get<ConnectionManagerInfo[]>(url).pipe(map(res => res.map(cmInfo => new ConnectionManagerInfo(cmInfo))));
  }

  public getConnectionUnitInfo(serverId: number, cmName: string, cmUnitName: string): Observable<ConnectionUnit> {
    let url = 'informix/' + serverId + '/cmsm/cmUnits';
    url += '?cmName=' + cmName + '&';
    url += 'cmUnitName=' + cmUnitName;
    return this.httpClient.get<ConnectionUnit>(url).pipe(map(res => new ConnectionUnit(res, cmName, cmUnitName)));
  }

  public updateFOC(serverId: number, focInfo: FOCInfo): Observable<{ ok: boolean }> {
    const url = 'informix/' + serverId + '/cmsm/foc';
    return this.httpClient.put<{ ok: boolean }>(url, focInfo);
  }

  public deleteSLA(serverId: number, slaInfo: SLAInfo): Observable<{ ok: boolean }> {
    const url = 'informix/' + serverId + '/cmsm/sla';
    const httpOptions = {
      headers: new HttpHeaders(), body: slaInfo
    };
    return this.httpClient.delete<{ ok: boolean }>(url, httpOptions);
  }

  public updateSLA(serverId: number, slaInfo: SLAInfo) {
    const url = 'informix/' + serverId + '/cmsm/sla';
    return this.httpClient.post<{ ok: boolean }>(url, slaInfo);
  }

  public getAvailableServers(serverId: number) {
    const url = 'informix/' + serverId + '/cmsm/availableServers';
    return this.httpClient.get<any[]>(url);
  }
}
