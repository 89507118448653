<div class="ml-4">
  <h6 class="modal-title">
    Are you sure you want to drop chunk <strong>{{selectedChunk?.chknum}} ({{selectedChunk?.space_name}})</strong>?
  </h6>

  <app-radio-group class="d-block form-group mt-2" [(ngModel)]="dropOption">
    <app-radio class="d-block" value="drop">Drop the chunk</app-radio>
    <app-radio class="d-block mt-2" value="dropToPool">
      Drop the chunk and return the space to the storage pool
    </app-radio>
  </app-radio-group>
</div>

<div class="form-group row d-flex flex-row-reverse">
  <div class="col-sm-3 d-flex justify-content-end">
    <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="doDropChunk()">Yes</button>
  </div>
</div>
