<div bsModal #participantModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">{{isEdit ? 'Edit Participant' : 'Add Participant'}}
                    <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
                </h5>
                <button type="button" class="close" (click)="participantModal.hide()">
                    <i class="icon icon-times"></i>
                </button>
            </div>

            <div class="modal-body">
                <form [formGroup]="addParticipantForm">
                    <ng-container *ngIf="firstPage; else nextpage">
                        <div class="row">
                            <div class="col">
                                <div class="alert alert-info">
                                    <i class="icon icon-info icon-info-circle text-info mr-2"></i>
                                    Since this is the first participant, it will be the master server
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Master server </label>
                                <select class="form-control" formControlName="groupName" (change)="onServerChange($event); $event.stopPropagation();" title="Specifies the database server to use as the reference copy of the data.">
                                    <option *ngIf="groupNames.length ===0" disabled>Loading...</option>
                                    <option *ngIf="groupNames.length !== 0" value="">-- Select group --</option>
                                    <option *ngFor="let group of groupNames" value="{{group.name}}">{{group.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Master server database </label>
                                <select class="form-control" formControlName="databaseName" (change)="onDbChange($event)">
                                    <option *ngIf="databases.length === 0" disabled>Loading...</option>
                                    <option *ngIf="databases.length !== 0" value="">-- Select database --</option>
                                    <option *ngFor="let db of databases" value="{{db.name}}">{{db.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label class="font-weight-bold"><span class="text-danger ">* </span>Master server database table </label>
                                <select class="form-control" formControlName="tabName" (change)="onTableChange($event)">
                                    <option *ngIf="tableNames.length === 0" disabled>Loading...</option>
                                    <option *ngIf="tableNames.length !== 0" value="">-- Select table --</option>
                                    <option *ngFor="let table of tableNames" value="{{table.name}}">{{table.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-3" *ngIf="isColumnDataLoading">
                            <div class="col">
                                <label class="font-weight-bold">Loading Column data... </label>
                            </div>
                        </div>

                        <div class="row mt-3" *ngIf="columnData && columnData.length">
                            <div class="col">
                                <app-data-table *ngIf="columnData && columnData.length" class="table-responsive" [enableFilter]="false"
                                    [data]="columnData" [sortDescending]="true" [enableSearch]="true"
                                    [rowsPerPage]="5" [enableSingleSelect]="false" [enableSelect]="true"
                                    (rowSelected)="onIndexSelected($event)" [dataMapFn]="columnsDataMap" [minHeight]="280">
                                    <app-data-table-column title="Column name" property="name">
                                    </app-data-table-column>
                                    <app-data-table-column title="Datatype" property="type">
                                    </app-data-table-column>
                                </app-data-table>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <div class="font-weight-bold mb-2">Enter WHERE Clause SQL statement </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">WHERE</span>
                                    </div>
                                    <input type="text" class="form-control"
                                        placeholder="'Country=Germany' AND City='Berlin'" formControlName="query" />
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #nextpage>
                        <div class="row mt-2">
                            <div class="col">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Participant server </label>
                                <select class="form-control" formControlName="groupName" (change)="onServerChange($event)" title="Specifies that all servers defined for the replicate are checked.">
                                    <option *ngIf="groupNames.length ===0" disabled>Loading...</option>
                                    <option *ngIf="groupNames.length && groupNames.length !== 0" value="">-- Select group --</option>
                                    <option *ngFor="let group of groupNames" [value]="group.name">{{group.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Participant server database</label>
                                <select class="form-control" (change)="onDbChange($event)" formControlName="databaseName">
                                    <option *ngIf="databases.length === 0" disabled>Loading...</option>
                                    <option *ngIf="databases.length !== 0" value="">-- Select database --</option>
                                    <option *ngFor="let db of databases" [value]="db.name">{{db.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Participant server database table </label>
                                <select class="form-control" (change)="onTableChange($event)" formControlName="tabName">
                                    <option *ngIf="tableNames.length === 0" disabled>Loading...</option>
                                    <option *ngIf="tableNames.length !== 0" value="">-- Select table --</option>
                                    <option *ngFor="let table of tableNames" [value]="table.name">{{table.name}}
                                    </option>
                                </select>
                                <!-- <input type="text" class="form-control" *ngIf="isCreateMissingTable" title="Table name" formControlName="tabName" placeholder="Enter table name" (change)="doesOwnerExist(true)"> -->
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col pt-1">
                                <app-checkbox [checked]="isCreateMissingTable" (change)="autoCreateTable()">
                                    Auto create missing table on on participant server.
                                </app-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="alert alert-info">
                                    <i class="icon icon-info icon-info-circle text-info mr-2"></i>
                                    Table will be created on participant server database.
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <div class="row mt-3">
                        <div class="col">
                            <div class="input-group">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Participant Type</label>
                                <app-radio-group class="justify-content-start ml-2" formControlName="participantType">
                                    <app-radio value="update-anywhere" title="Check if you are creating update-anywhere replicate">Update-anywhere</app-radio>
                                    <app-radio class="ml-3" value="primary" title="Specifies that the participant is a primary participant, which both sends and receives replicated data.">Primary</app-radio>
                                    <app-radio class="ml-3" value="received-only" title="Specifies that the participant is a receive-only target participant, which only receives data from primary participants.">Receive-only</app-radio>
                                    <app-radio class="ml-3" value="send-only" title="Specifies that the participant is a send-only primary participant, which only sends data to target participants.">Send-only</app-radio>
                                </app-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-8">
                            <div class="input-group">
                                <label class="font-weight-bold"><span class="text-danger">* </span>Privileges</label>
                                <app-radio-group class="justify-content-start ml-2" formControlName="owner" (change)="doesOwnerExist()" title="Enables permission checks for table owner that is specified in the participant to be applied to the operation">
                                    <app-radio value="informix">User-informix</app-radio>
                                    <app-radio class="ml-3" value="table-owner">Table Owner</app-radio>
                                </app-radio-group>
                                <input type="text" *ngIf="ownerExists" class="form-control ml-3" title="Owner name" formControlName="ownerName"
                                    placeholder="Enter owner name">
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <div class="float-left">
                    <button type="button" class="btn btn-secondary" (click)="participantModal.hide()">Close</button>
                </div>
                <div class="float-right"> 
                    <button type="button" class="btn btn-secondary ml-3" (click)="addParticipant(true)" [disabled]="!addParticipantForm.valid || isColumnDataLoading">
                        {{(isEdit || groupNames.length == 1) ? 'Save' : 'Add Participant'}}</button>
                    <button type="button" *ngIf="!isEdit && groupNames.length != 1" class="btn btn-primary justify-content-end" (click)="addParticipant(false)" [disabled]="!addParticipantForm.valid || isColumnDataLoading">Save &
                        Next</button>
                </div>
            </div>
        </div>
    </div>
</div>
