<div class="db-border mt-2 p-2">
  <div class="form-inline mt-2 ml-2 font-weight-bold tab-title">
    {{ 'schemaManager.dbInformation' | translate }}
  </div>
  <div class="p-2" *ngIf="databaseInfo">
    <div class="form-group ml-4">
      <div class="row">
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.dbName' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.name}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'common.owner' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.owner}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.createdDate' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{(databaseInfo.created == null)? "" : databaseInfo.created | date:'y-MM-dd'}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'common.dbspace' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.dbspace}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.loggingMode' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{getLoggingMode()}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.locale' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.locale}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.spaceOccupied' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.space_occupied | byte}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.caseSensitive' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.caseInsensitive ? 'No' : 'Yes'}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.gls' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.gls ? 'Enabled' : 'Disabled'}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.loadTasks' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.load_tasks}}
          </div>
        </div>
        <div class="col-3 py-3">
          <label class="m-0 color-light-gray-70">{{ 'schemaManager.dbInfoTab.unloadTasks' | translate }}</label>
          <div class="font-weight-bold mb-2">
            {{databaseInfo.unload_tasks}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
