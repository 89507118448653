/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';

@Component({
  selector: 'app-auto-update-statistics',
  templateUrl: 'autoUpdateStatistics.html'
})
export class AutoUpdateStatisticsComponent implements OnInit {

  @Input() server: InformixServer = null;
  breadcrumb: BreadcrumbElement[] = null;
  selectedTabIndex: Number = 0;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [{ name: 'DB Server Administration' }, { name: 'Auto Update Statistics' }]);
  }

  setSelectedTabIndex(index) {
    this.selectedTabIndex = index;
  }
}
