/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved
 *******************************************************************************/
import { Component, Input } from '@angular/core';
import { InformixTableDetails, InformixTable } from '../../informix-table';

@Component({
  selector: 'app-table-info',
  templateUrl: './table-info.component.html'
})
export class TableInfoComponent {

  @Input() tableDetails: InformixTableDetails;
  @Input() table: InformixTable;

  constructor() { }

}
