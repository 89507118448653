/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorMessageHandlers } from '../../../../shared/forms/extendedFormControl.directive';
import { InformixServer } from '../../informixServer';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { HDRPermissionService } from '../../../../shared/hdr-permission/hdr-permission.service';
import { HDRPermission } from '../../../../shared/hdr-permission/hdr-permission';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-backup-config',
  templateUrl: 'backup-config.html'
})
export class BackupConfigComponent implements OnInit {

  @Input() server: InformixServer;

  _backupTasks: any = null;
  backupLevelTasks: any[] = [];

  isEditingConfig = false;
  showOnbarAdvancedConfig = false;
  onbarBackupSpeedValue = 5;
  onbarBackupSpeedMin = 1;
  onbarBackupSpeedMax = 10;
  editConfigurationFormGroup: UntypedFormGroup;
  configuredScheduleData: any[];
  selectedBackupType: string;
  serverType = environment.serverType;

  startTimeErrorHandlers: ErrorMessageHandlers = {
    pattern: error => 'Must be in the format HH:MM:SS',
  };

  @Output() backupTasksChange = new EventEmitter<any>();

  backupHDR: HDRPermission;
  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private hdrPermissionService: HDRPermissionService
  ) {
  }

  ngOnInit() {
    this.backupHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  @Input()
  public set backupTasks(backupTasks: any) {
    if (!backupTasks) {
      return;
    }
    this._backupTasks = backupTasks;
    if (backupTasks.backupsConfigured) {
      this.backupLevelTasks = [];
      ['level0', 'level1', 'level2'].forEach(level => {
        if (backupTasks[level]) {
          this.backupLevelTasks.push(backupTasks[level]);
        }
      });
    }
  }

  public doEditConfiguration() {
    if (this.backupHDR && !this.backupHDR.isAllow()) {
      return;
    }
    this.isEditingConfig = true;
    if (this._backupTasks.backupsConfigured) {
      this.setBackupType(this._backupTasks.backupType);
    }
    this.configuredScheduleData = [];
    [0, 1, 2].forEach(level => {
      if (this._backupTasks.backupsConfigured && this._backupTasks['level' + level]) {
        const existingTask = this._backupTasks['level' + level];
        this.configuredScheduleData.push({
          level,
          startTime: existingTask.tk_start_time,
          monday: existingTask.tk_monday,
          tuesday: existingTask.tk_tuesday,
          wednesday: existingTask.tk_wednesday,
          thursday: existingTask.tk_thursday,
          friday: existingTask.tk_friday,
          saturday: existingTask.tk_saturday,
          sunday: existingTask.tk_sunday,
        });
      } else {
        this.configuredScheduleData.push({
          level,
          startTime: '00:00:00',
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        });
      }
    });
  }

  public cancelEditConfiguration() {
    if (this.backupHDR && !this.backupHDR.isAllow()) {
      return;
    }
    this.isEditingConfig = false;
    this.selectedBackupType = null;
  }

  public setBackupType(type: string) {
    this.selectedBackupType = type;
    if (type === 'ontape') {
      this.editConfigurationFormGroup = this.getOntapeFormGroup();
    } else {
      this.editConfigurationFormGroup = this.getOnbarFormGroup();
    }
  }

  private getOntapeFormGroup(): UntypedFormGroup {
    const scheduleStartTimeFormArray = this.createScheduleStartTimeFormArray();
    const formGroup = new UntypedFormGroup({
      deviceType: new UntypedFormControl('tape', [Validators.required]),
      path: new UntypedFormControl(null, [Validators.required]),
      blockSize: new UntypedFormControl(16, [Validators.required, Validators.min(16), Validators.max(1024)]),
      startTimes: scheduleStartTimeFormArray
    });
    if (this._backupTasks && this._backupTasks.backupsConfigured && this._backupTasks.backupType === 'ontape') {
      formGroup.controls.deviceType.setValue(this._backupTasks.backupParams.ONTAPE_DEVICE_TYPE);
      formGroup.controls.path.setValue(this._backupTasks.backupParams.ONTAPE_TAPEDEV);
      formGroup.controls.blockSize.setValue(this._backupTasks.backupParams.ONTAPE_TAPEBLK);
      [0, 1, 2].forEach(level => {
        if (this._backupTasks['level' + level]) {
          scheduleStartTimeFormArray.controls[level].setValue(this._backupTasks['level' + level].tk_start_time);
        }
      });
    }
    return formGroup;
  }

  private getOnbarFormGroup(): UntypedFormGroup {
    const scheduleStartTimeFormArray = this.createScheduleStartTimeFormArray();
    const formGroup = new UntypedFormGroup({
      backupGenerationRetain: new UntypedFormControl(3, [Validators.required, Validators.min(1), Validators.max(15)]),
      backupGenerationKeepAll: new UntypedFormControl(false, [Validators.required]),
      parallelBackupStreams: new UntypedFormControl(4, [Validators.required, Validators.min(1), Validators.max(1000)]),
      parallelBackupStreamsUseSystem: new UntypedFormControl(false, [Validators.required]),
      bufferSize: new UntypedFormControl(3, [Validators.required, Validators.min(1), Validators.max(1000)]),
      numBuffers: new UntypedFormControl(3, [Validators.required, Validators.min(1), Validators.max(1000)]),
      startTimes: scheduleStartTimeFormArray
    });
    formGroup.controls.backupGenerationKeepAll.valueChanges.subscribe(value => {
      if (value) {
        formGroup.controls.backupGenerationRetain.disable();
      } else {
        formGroup.controls.backupGenerationRetain.enable();
      }
    });
    formGroup.controls.parallelBackupStreamsUseSystem.valueChanges.subscribe(value => {
      if (value) {
        formGroup.controls.parallelBackupStreams.disable();
      } else {
        formGroup.controls.parallelBackupStreams.enable();
      }
    });
    if (this._backupTasks) {
      this.onbarBackupSpeedMax = (this._backupTasks.numNonCriticalDbspaces > 1000) ? 1000 : this._backupTasks.numNonCriticalDbspaces;
      this.onbarBackupSpeedValue = Math.ceil(this.onbarBackupSpeedMax / 2);
      formGroup.controls.parallelBackupStreams.setValue(this.onbarBackupSpeedValue);
      formGroup.controls.bufferSize.setValue(this._backupTasks.backupOnconfigs.BAR_XFER_BUF_SIZE);
      formGroup.controls.numBuffers.setValue(this._backupTasks.backupOnconfigs.BAR_NB_XPORT_COUNT);
      if (this._backupTasks.backupsConfigured && this._backupTasks.backupType === 'onbar') {
        this.showOnbarAdvancedConfig = true;
        formGroup.controls.backupGenerationRetain.setValue(
          (this._backupTasks.backupParams.BACKUP_GENR_RETAIN > 0) ? this._backupTasks.backupParams.BACKUP_GENR_RETAIN : 3
        );
        formGroup.controls.backupGenerationKeepAll.setValue(this._backupTasks.backupParams.BACKUP_GENR_RETAIN === -1);
        if (this._backupTasks.backupOnconfigs.BAR_MAX_BACKUP > 0) {
          formGroup.controls.parallelBackupStreams.setValue(this._backupTasks.backupOnconfigs.BAR_MAX_BACKUP);
          formGroup.controls.parallelBackupStreamsUseSystem.setValue(false);
          this.onbarBackupSpeedValue =
            (this._backupTasks.backupOnconfigs.BAR_MAX_BACKUP > this.onbarBackupSpeedMax) ? this.onbarBackupSpeedMax :
              this._backupTasks.backupOnconfigs.BAR_MAX_BACKUP;
        } else {
          formGroup.controls.parallelBackupStreamsUseSystem.setValue(true);
        }
        [0, 1, 2].forEach(level => {
          if (this._backupTasks['level' + level]) {
            scheduleStartTimeFormArray.controls[level].setValue(this._backupTasks['level' + level].tk_start_time);
          }
        });
      }
    }
    return formGroup;
  }

  private createScheduleStartTimeFormArray(): UntypedFormArray {
    const scheduleStartTimeFormArray: UntypedFormArray = new UntypedFormArray([]);
    [0, 1, 2].forEach(level => {
      scheduleStartTimeFormArray.push(
        new UntypedFormControl('00:00:00', [Validators.required, Validators.pattern('([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]')])
      );
    });
    return scheduleStartTimeFormArray;
  }

  public updateOnbarBackupSlider(event) {
    this.editConfigurationFormGroup.controls.parallelBackupStreams.setValue(event.newValue);
    this.editConfigurationFormGroup.controls.parallelBackupStreamsUseSystem.setValue(false);
  }

  public toggleScheduleDay(level: number, day: string) {
    this.configuredScheduleData[level][day] = !this.configuredScheduleData[level][day];
  }

  public saveConfiguration() {
    if (!this.editConfigurationFormGroup.valid && (this.backupHDR && !this.backupHDR.isAllow())) {
      return;
    }

    const backupConfig = this.editConfigurationFormGroup.value;
    backupConfig.backupType = this.selectedBackupType;
    if (this.selectedBackupType === 'onbar') {
      // update values if either checkbox is selected
      if (backupConfig.backupGenerationKeepAll) {
        backupConfig.backupGenerationRetain = -1;
      }
      delete backupConfig.backupGenerationKeepAll;
      if (backupConfig.parallelBackupStreamsUseSystem) {
        backupConfig.parallelBackupStreams = 0;
      }
      delete backupConfig.parallelBackupStreamsUseSystem;
      // remove onconfig values if unchanged
      if (backupConfig.parallelBackupStreams === this._backupTasks.backupOnconfigs.BAR_MAX_BACKUP) {
        delete backupConfig.parallelBackupStreams;
      }
      if (backupConfig.numBuffers === this._backupTasks.backupOnconfigs.BAR_NB_XPORT_COUNT) {
        delete backupConfig.numBuffers;
      }
      if (backupConfig.bufferSize === this._backupTasks.backupOnconfigs.BAR_XFER_BUF_SIZE) {
        delete backupConfig.bufferSize;
      }
    }

    const startTimes = this.editConfigurationFormGroup.value.startTimes;
    for (let i = 0; i < startTimes.length; i++) {
      this.configuredScheduleData[i].startTime = startTimes[i];
    }
    backupConfig.schedule = this.configuredScheduleData;

    if (this._backupTasks && this._backupTasks.backupsConfigured) {
      this.updateBackupTasks(backupConfig);
    } else {
      this.insertBackupTasks(backupConfig);
    }
  }

  private insertBackupTasks(backupConfig) {
    this.isEditingConfig = false;
    this.http.post<any>('informix/' + this.server.id + '/backups/tasks', backupConfig).subscribe(taskInfo => {
      this.notificationsService.pushSuccessNotification('Backup configuration saved');
      this.backupTasksChange.emit(taskInfo);
    }, err => {
      console.log(err);
      this.notificationsService.pushErrorNotification((err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err));
    });
  }

  private updateBackupTasks(backupConfig) {
    this.isEditingConfig = false;
    this.http.put<any>('informix/' + this.server.id + '/backups/tasks', backupConfig).subscribe(taskInfo => {
      this.notificationsService.pushSuccessNotification('Backup configuration updated');
      this.backupTasksChange.emit(taskInfo);
    }, err => {
      console.log(err);
      this.notificationsService.pushErrorNotification((err.error && err.error.err) ? err.error.err :
        ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err));
    });
  }

  public confirmDeleteConfiguration() {
    if (this.backupHDR && !this.backupHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('delete this server\'s backup configuration?', () => {
      this.http.delete<any>('informix/' + this.server.id + '/backups/tasks').subscribe(taskInfo => {
        this.notificationsService.pushSuccessNotification('Backup configuration deleted');
        this.isEditingConfig = false;
        this.backupTasksChange.emit(taskInfo);
      }, err => {
        console.log(err);
        this.notificationsService.pushErrorNotification((err.error && err.error.err) ? err.error.err :
          ((err instanceof HttpErrorResponse) ? err.status + ' ' + err.statusText : err));
      });
    });
  }
}
