/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { User } from './user';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../app.settings';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {

  private static DEFAULT_LOGIN_REDIRECT_URL = '/dashboard/groups';

  private userRequestPromise: Promise<User> = null;

  private currentUser: User;
  private loginRedirectUrl: string = null;
  daysToExpire: number;
  passwordExpired: string;

  private userTimedOut = false;

  _onLoginSource = new BehaviorSubject<User>(null);
  onLogin$ = this._onLoginSource.asObservable();

  _onLogoutSource = new BehaviorSubject<Boolean>(false);
  onLogout$ = this._onLogoutSource.asObservable();
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private zone: NgZone
  ) { }

  public requestCurrentUser(user?: string, password?: string, newPassword?: string): Promise<User> {
    if (this.userRequestPromise) {
      return this.userRequestPromise;
    }

    if (user && password) {
      let requestHeaders;
      if (newPassword) {
        // send New-Authorization header on first sign-in
        requestHeaders = {
          Authorization: 'Basic ' + window.btoa(user + ':' + password),
          'New-Authorization': 'BasicNew ' + window.btoa(user + ':' + newPassword)
        };
      } else {
        requestHeaders = { Authorization: 'Basic ' + window.btoa(user + ':' + password) };
      }
      const headers = new HttpHeaders(requestHeaders);
      this.userRequestPromise = this.httpClient.get<User>('users/current', { headers }).toPromise<User>();
    } else {
      this.userRequestPromise = this.httpClient.get<User>('users/current').toPromise<User>();
    }

    this.userRequestPromise.then(newUser => {
      const userDetails = new User(newUser);
      if (!userDetails.isFirstSignIn) {
        // not first sign-in then set current user
        this.currentUser = userDetails;
        this.userTimedOut = false;
        this.setLanguage();
        // Emitting onLogin subscriber for session timer watch().
        this._onLoginSource.next(this.currentUser);
        return this.currentUser;
      } else {
        return userDetails;
      }
    }).catch(err => {
      this.currentUser = null;
      console.log(err);
    }).then(() => {
      this.userRequestPromise = null;
    });
    return this.userRequestPromise;
  }

  public getCurrentUser(): Promise<User> {
    if (this.currentUser === undefined) {
      return this.requestCurrentUser();
    } else {
      return Promise.resolve(this.currentUser);
    }
  }

  public getCurrentUserDirty(): User {
    return this.currentUser;
  }

  public logIn(user: string, password: string, newPassword: string): Promise<User> {
    return this.requestCurrentUser(user, password, newPassword);
  }

  public logOut(clearLoginRedirectUrl: boolean = true) {
    this.httpClient.post('logout', {}).toPromise().catch(err => {
      console.error(err);
    }).then(() => {
      this.currentUser = null;
      if (clearLoginRedirectUrl) {
        this.loginRedirectUrl = null;
      } else {
        this.setLoginRedirectUrl(this.router.routerState.snapshot.url);
      }
      this.zone.run(() => {
        this.router.navigate(['/login']);
      });
      localStorage.clear();
      // Emitting onLogout subscriber for session timer stop().
      this._onLogoutSource.next(true);
    });
  }

  public timeOutCurrentUser() {
    this.currentUser = null;
    this.userTimedOut = true;
    localStorage.clear();
  }

  public hasUserTimedOut() {
    return this.userTimedOut;
  }

  public getLoginRedirectUrl(): string {
    return this.loginRedirectUrl || UserService.DEFAULT_LOGIN_REDIRECT_URL;
  }

  public setLoginRedirectUrl(url: string) {
    this.loginRedirectUrl = url;
  }

  private setLanguage() {
    this.translate.setDefaultLang(AppSettings.DEFAULT_LANGUAGE);
    if (this.translate.currentLang === undefined) {
      this.translate.use(this.currentUser.settings.language);
    }
  }

  setDaysToExpire(daysToExpire: number, passwordExpired: string) {
    this.daysToExpire = daysToExpire;
    this.passwordExpired = passwordExpired;
  }

  getDaysToExpire() {
    const expiryInfo = {
      daysToExpire: this.daysToExpire,
      passwordExpired: this.passwordExpired
    };
    return expiryInfo;
  }

  /**
   * Method to timout the current session from frontend side.
   */
  timoutCurrentSession() {
    this.timeOutCurrentUser();
    this.zone.run(() => {
      this.router.navigate(['/login']);
    });
    this.setLoginRedirectUrl(this.router.routerState.snapshot.url);
  }
}
