/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022, 2023. All Rights Reserved.
 *******************************************************************************/

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { PasswordPolicy } from '../../../shared/user/passwordPolicy';
import { SystemConfigService } from '../../system-settings/system-config.service';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ChangePasswordFormComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: ChangePasswordFormComponent, multi: true }
  ]
})
export class ChangePasswordFormComponent implements OnInit, ControlValueAccessor, Validator {

  showConfirmPassword: Boolean = false;
  showNewPassword: Boolean = false;
  showOldPassword: Boolean = false;
  @Input() requireOldPassword = false;

  formGroup: UntypedFormGroup;
  passwordPolicy: PasswordPolicy = null;
  private onChangeFn: any = () => { };

  constructor(
    private systemConfigService: SystemConfigService,
  ) { }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      new: new UntypedFormControl(null, [Validators.required]),
      newAgain: new UntypedFormControl(null, [Validators.required])
    }, [this.validateNewPassword.bind(this), this.passwordMatchValidator]);

    if (this.requireOldPassword) {
      this.formGroup.addControl('old', new UntypedFormControl(null, [Validators.required]));
    }

    this.formGroup.valueChanges.subscribe(value => {
      this.onChangeFn(this.requireOldPassword ? { old: value.old, new: value.new } : value.new);
    });

    this.systemConfigService.getConfig('passwordPolicy').then(policy => {
      this.passwordPolicy = new PasswordPolicy(policy);
    }).catch(err => {
      console.log('Could not get password policy');
      console.log(err);
    });
  }

  validateNewPassword(formGroup: UntypedFormGroup) {
    const newPassword = formGroup.value.new;
    if (formGroup.value.old === newPassword) {
      const errors = { customError: 'New password must be different from the old one' };
      formGroup.controls.new.setErrors(errors);
      return errors;
    } else if (this.passwordPolicy && !this.passwordPolicy.isPasswordValid(newPassword)) {
      const errors = { customError: 'Password invalid. ' + this.passwordPolicy.policyDescription };
      formGroup.controls.new.setErrors(errors);
      return errors;
    }
    return null;
  }

  passwordMatchValidator(formGroup: UntypedFormGroup) {
    if (formGroup.value.new === formGroup.value.newAgain) {
      formGroup.controls.newAgain.setErrors(null);
      return null;
    } else {
      const errors = { customError: 'Passwords don\'t match' };
      formGroup.controls.newAgain.setErrors(errors);
      return errors;
    }
  }

  writeValue(value: any) {
    if (!value) {
      this.formGroup.reset();
    } else {
      const formValue: any = {
        new: value,
        newAgain: null
      };
      if (this.requireOldPassword) {
        formValue.old = null;
      }
      this.formGroup.setValue(formValue);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  registerOnTouched() {
  }

  validate(c: AbstractControl) {
    return this.formGroup.errors;
  }

  toggleOldPassShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.old.value === '') {
        this.showOldPassword = false;
      }
      return;
    }
    this.showOldPassword = !this.showOldPassword;
  }

  toggleNewPassShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.new.value === '') {
        this.showNewPassword = false;
      }
      return;
    }
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPassShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.newAgain.value === '') {
        this.showConfirmPassword = false;
      }
      return;
    }
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
