<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="group">
  <h4 class="font-weight-bold mb-3 text-truncate">
    {{group.name}}
  </h4>
  <div class="row align-items-center">
    <div class="col-12 col-md-12 col-xl-12 d-flex align-items-center">
      <button class="btn btn-sm btn-icon mr-1" (click)="isGroupExpanded = !isGroupExpanded">
        <i class="icon" [ngClass]="isGroupExpanded ? 'icon-chevron-down' : 'icon-chevron-right'"></i>
      </button>
      <h5 class="flex-1 m-0">Groups ({{searchedGroupData.length}})</h5>
    </div>
  </div>

  <div class="d-flex flex-wrap my-3 align-items-stretch" *ngIf="isGroupExpanded; else groupsServersDivider">
    <div class="col-12 col-md-12 col-xl-12">
      <div class="row align-items-center">
        <div class="col">
          <div class="input-group">
            <input class="form-control" type="text" placeholder="Search groups..." [formControl]="searchGroupControl">
            <button class="close input-clear" [hidden]="!searchGroupControl.value"
              (click)="searchGroupControl.setValue('')">
              <i class="icon icon-times"></i>
            </button>
          </div>
        </div>
        <div class="col-auto">
          <app-check-permissions [permissions]="group.permissions.admin">
            <button type="button" class="btn btn-primary" (click)="addNewGroup()" appPermissionDisable>
              Add Group
            </button>
          </app-check-permissions>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-xl-12">
      <div class="row d-flex align-items-center p-2">
        <app-group-card *ngIf="newGroup" [group]="newGroup" (eventSaved)="onNewGroupSaved()" class="d-block m-2"
          (eventDelete)="onNewGroupDelete()"></app-group-card>
        <app-group-card *ngFor="let group of searchedGroupData; trackBy: groupListTrackBy; let index = index"
          class="d-block m-2" [group]="group" [isSelected]="isGroupSelected(group)" (eventEdit)="onEditGroup(group)"
          (eventSelect)="onSelectGroup($event, group, index)" (eventDelete)="onDeleteGroup(group)"
          (eventMove)="onMoveGroup(group)" (eventEnter)="navigateToGroup(group)" (eventSaved)="onGroupSaved()">
        </app-group-card>
      </div>
    </div>
  </div>
  <ng-template #groupsServersDivider>
    <hr>
  </ng-template>

  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-xl-3 d-flex align-items-center">
      <button class="btn btn-sm btn-icon mr-1" (click)="isServerExpanded = !isServerExpanded">
        <i class="icon" [ngClass]="isServerExpanded ? 'icon-chevron-down' : 'icon-chevron-right'"></i>
      </button>
      <h5 class="flex-1 m-0">Servers ({{searchedServerData.length}})</h5>
    </div>
  </div>
  <div class="row" *ngIf="naServerFound && isServerExpanded">
    <div class="col-12 text-muted pl-4 pt-3">
      <i class="icon icon-info-circle mr-2 text-primary"></i> <span>Note: {{serverType}} server should be online or HQ agent
        should be connected to get correct server type instead of <strong>N/A</strong>.</span>
    </div>
  </div>
  <div class="d-flex flex-wrap my-3 align-items-stretch">
    <ng-container *ngIf="isServerExpanded">
      <div class="col-12 col-md-12 col-xl-12">
        <div class="row align-items-center">
          <div class="col">
            <div class="input-group">
              <input type="text" class="form-control" [formControl]="searchServerControl"
                placeholder="Search servers...">
              <button class="close input-clear" [hidden]="!searchServerControl.value"
                (click)="searchServerControl.setValue('')">
                <i class="icon icon-times"></i>
              </button>
            </div>
          </div>
          <div class="col-auto">
            <app-check-permissions [permissions]="group.permissions.admin">
              <button type="button" class="btn btn-primary" (click)="addNewServer()" appPermissionDisable>
                Add Server
              </button>
            </app-check-permissions>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-12">
        <div class="row d-flex align-items-center p-2">
          <app-server-card *ngFor="let server of searchedServerData; trackBy: serverListTrackBy; let index = index"
            class="d-block m-2" [server]="server" [isSelected]="isServerSelected(server)"
            (eventSelect)="onServerSelect($event, server, index)" (eventMove)="onMoveServer(server)"
            (eventEdit)="editServer(server)" (eventDelete)="deleteServer(server)" (eventEnter)="navigateToServer(server)">
          </app-server-card>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="bottom-fixed-bar bg-white color-white border-gray-20" [ngClass]="{active: isAnythingSelected()}">
  <div class="hint color-black" *ngIf="!isAnythingSelected()">
    <i class="icon icon-idea"></i> Hold Ctrl or Shift when clicking to select multiple items!
  </div>
  <ng-template [ngIf]="isAnythingSelected()">
    <div>
      <button class="btn btn-primary mr-2" (click)="clearSelection()"><i class="icon icon-times"></i></button>
    </div>
    <div class="col">
      <ng-template [ngIf]="selectedGroups.size > 0">
        <span class="mr-2">
          <i class="icon icon-folder"></i> {{selectedGroups.size}}
        </span>
      </ng-template>
      <ng-template [ngIf]="selectedServers.size > 0">
        <i class="icon icon-database"></i> {{selectedServers.size}}
      </ng-template>
    </div>
    <div>
      <div class="action-buttons-collapsed" dropdown container="body" placement="top right" [dropup]="true">
        <button class="btn btn-primary" dropdownToggle><i class="icon icon-menu-overflow-horizontal"></i></button>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" (click)="onMoveAll()"><i class="icon icon-folder"></i> Move all
            to...</button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item text-danger" type="button" (click)="onDeleteAll()"><i
              class="icon icon-trash"></i> Delete all</button>
        </div>
      </div>
      <div class="action-buttons">
        <button class="btn btn-primary" type="button" (click)="onMoveAll()"><i class="icon icon-folder"></i> Move all
          to...</button>
        <button class="btn btn-primary" type="button" (click)="onDeleteAll()"><i class="icon icon-trash"></i> Delete
          all</button>
      </div>
    </div>
  </ng-template>
</div>

<app-move-items [group]="group" [items]="itemsBeingMoved" (eventClosed)="onMoveToClosed()"
  (eventDone)="onMoveToDone($event)"></app-move-items>

<!-- Scroll to top -->
<button class="btn btn-sm btn-primary btn-scroll-to-top m-0 d-sm-none" (click)="scrollToTheTop()"
  *ngIf="windowScrolled">
  <i class="icon icon-arrow-up"></i>
</button>
