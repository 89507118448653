/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';
import { GroupResolver } from '../../groups/group-resolver.service';
import { ServerResolver } from '../../servers/server-resolver.service';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const dashboardRoutes: Route[] = [
  {
    path: 'dashboards',
    component: DashboardListComponent,
    resolve: {
      group: GroupResolver,
      server: ServerResolver,
    }
  },
  {
    path: 'dashboards/:dashboardId',
    component: DashboardComponent,
    resolve: {
      group: GroupResolver,
      server: ServerResolver
    }
  }
];
