/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
export interface InformixServerAgentConfig {
  repositoryServerId: number;
  database: string;
  agentConnectionProperties: any;
}

export class InformixServerAgent {
  online: boolean;
  isMonitoring: boolean;
  isConfigured: boolean;
  config: InformixServerAgentConfig;

  constructor(json: any) {
    this.online = json.online;
    this.isMonitoring = json.isMonitoring;
    this.config = json.config || null;
    this.isConfigured = !!this.config;
  }
}
