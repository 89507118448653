/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from './../serverBreadcrumb';
import { SqltraceService } from './sqltrace.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sqltrace-stmts',
  templateUrl: 'sqltrace-stmts.html'
})
export class SqltraceStatementsComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private sqltraceBreadCrumb: BreadcrumbElement = {
    name: 'SQL Tracing',
    link: 'sqltrace'
  };
  private stmtsBreadCrumb: BreadcrumbElement = {
    name: 'Statements',
  };

  server: InformixServer = null;
  stmtTypes: string[] = null;
  tracedStatements: any[] = null;
  selectedStmtType = 'ALL';
  dataLoadErrorMessage: string = null;
  isFilterDisable = true;
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };
  constructor(
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.route.queryParams.subscribe(params => {
      if (params.stmttype) {
        if (this.stmtTypes && !this.stmtTypes.some(stmt => stmt === params.stmttype.toUpperCase())) {
          this.selectedStmtType = 'ALL';
        } else {
          this.selectedStmtType = params.stmttype.toUpperCase();
        }
      }
      if (params.fromTimeStamp) {
        this.timeStamps.fromTimeStamp = Math.floor(params.fromTimeStamp);
      }
      if (params.toTimeStamp) {
        this.timeStamps.toTimeStamp = Math.floor(params.toTimeStamp);
      }
      this.getStatements();
    });
  }

  refreshData() {
    this.getStatements();
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.sqltraceBreadCrumb, this.stmtsBreadCrumb]);
    this.getStatementTypes();
  }

  private getStatementTypes() {
    this.sqltraceService.getStatementsByType(this.server).then(stmttypes => {
      this.stmtTypes = [];
      stmttypes.forEach(element => {
        this.stmtTypes.push(element.stmt_type);
      });
      if (!this.stmtTypes.some(stmt => stmt === this.selectedStmtType)) {
        this.selectedStmtType = 'ALL';
      }
      this.getStatements();
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace statement types', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private getStatements() {
    if (!this.stmtTypes) {
      return;
    }
    const type = (this.selectedStmtType === 'ALL') ? null : this.selectedStmtType;
    this.sqltraceService.getStatements(this.server, type, this.timeStamps.fromTimeStamp, this.timeStamps.toTimeStamp).subscribe(stmts => {
      this.tracedStatements = stmts;
    }, err => {
      console.error('Error getting sqltrace statements', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  onSelectedStmtTypeChange(stmtType: string) {
    this.selectedStmtType = stmtType;
    this.router.navigate([], { queryParams: { stmttype: this.selectedStmtType }, replaceUrl: true, queryParamsHandling: 'merge' });
  }

  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.router.navigate([], {
      queryParams: {
        fromTimeStamp: this.timeStamps.fromTimeStamp,
        toTimeStamp: this.timeStamps.toTimeStamp
      }, replaceUrl: true, queryParamsHandling: 'merge'
    });
  }
}
