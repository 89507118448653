<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server && !isCreateDatabase && !isCreateTable && !isCreateIndex"
  [hidden]="showQueryHistory">
  <div class="row mb-2">
    <div class="col-2">
      <h4>
        <strong>{{ 'schemaManager.title' | translate }}</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="!databases && !databasesLoadError"></i>
      </h4>
    </div>
    <div class="col">
      <label class="btn-container">
        <button class="btn font-weight-bold" [ngClass]="{'btn-style': heading === 'Info'}"
          (click)="heading = 'Info'">Info</button>
        <button class="btn font-weight-bold" [ngClass]="{'btn-style': heading === 'SQL Editor'}"
          (click)="heading = 'SQL Editor'">SQL Editor</button>
      </label>
    </div>
  </div>
  <ng-container *ngIf="isChangingUser; else userChosen">
    <h5>Select credentials</h5>
    <form [formGroup]="userFormGroup" (ngSubmit)="onChangeUserSubmit()" style="max-width: 400px">
      <div class="alert alert-danger" *ngIf="changeUserError">
        {{changeUserError}}
      </div>
      <app-radio-group formControlName="type">
        <app-radio value="monitor">Monitoring</app-radio>
        <app-radio class="ml-2" value="admin">Admin</app-radio>
        <app-radio class="ml-2" value="other">Other</app-radio>
      </app-radio-group>
      <div class="form-group mt-2">
        <label>Username</label>
        <input type="text" class="form-control" formControlName="username">
      </div>
      <div class="form-group">
        <label>Password</label>
        <div class="input-group password-hide-show inner-addon right-addon">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="password" (keyup)="toggleShow(true); $event.stopPropagation()">
          <i class="icon icon-eye" (click)="toggleShow(); $event.stopPropagation()"
            *ngIf="showPassword && userFormGroup.value.type === 'other' && (userFormGroup.controls['password'].dirty && userFormGroup.value.password !== '')"></i>
          <i class="icon icon-eye-blocked" (click)="toggleShow(); $event.stopPropagation()"
            *ngIf="!showPassword && userFormGroup.value.type === 'other' && (userFormGroup.controls['password'].dirty && userFormGroup.value.password !== '')"></i>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="userFormGroup.invalid">Log In</button>
      <button type="button" class="btn btn-secondary" (click)="cancelChangeUser()">Cancel</button>
    </form>
  </ng-container>
  <ng-template #userChosen>
    <ng-container *ngIf="databases">
      <div class="font-weight-bold">Select Database</div>
      <div class="row align-items-center">
        <div class="col col-auto pr-2">
          <div *ngIf="databases" class="form-inline">
            <select class="form-control db-dropdown" [formControl]="databaseFormControl">
              <option *ngFor="let database of databases" [ngValue]="database">
                {{database.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col col-auto pl-0 pr-1" *ngIf="session?.database?.server?.permissions?.sql">
          <div dropdown container="body" placement="bottom" dropdown #dropdown="bs-dropdown">
              <button type="button" class="btn btn-sm btn-icon" dropdownToggle>
                <i class="icon icon-menu-overflow-horizontal"></i>
              </button>
            <div *dropdownMenu class="dropdown-menu rounded-0 shadow">
              <app-check-hdr-permissions [server]="server" [hdrPermission]="schemaManagerHDR" placement="right">
                <button class="dropdown-item" type="button" appHDRPermission [hdrPermission]="schemaManagerHDR"
                  (click)="schemaManagerHDR.isAllow() && isCreateDatabase = true">
                  Create Database
                </button>
              </app-check-hdr-permissions>
              <app-check-hdr-permissions [server]="server" [hdrPermission]="schemaManagerHDR" placement="right">
                <button class="dropdown-item" type="button" appHDRPermission [hdrPermission]="schemaManagerHDR"
                  (click)="createDemoDatabase()">
                  Create Demo Database
                </button>
              </app-check-hdr-permissions>
              <app-check-hdr-permissions [server]="server" [hdrPermission]="schemaManagerHDR" placement="right">
                <button class="dropdown-item" type="button" appHDRPermission [hdrPermission]="schemaManagerHDR"
                  (click)="dropDatabaseConfirm()">
                  Drop Database
                </button>
              </app-check-hdr-permissions>
                <button class="dropdown-item" type="button" (click)="createTable()">
                  Create Table
                </button>
            </div>
          </div>
        </div>
        <div class="col col-auto px-0">
          <button type="button" class="btn btn-sm btn-icon" (click)="refreshDatabases()" tooltip="Refresh databases"
            placement="bottom">
            <i class="icon icon-refresh"></i>
          </button>
        </div>
        <div class="col col-sm text-right text-nowrap">
          <ng-container *ngIf="!session && !sessionLoading; else currentSessionTemplate">
            Not logged in
          </ng-container>
          <ng-template #currentSessionTemplate>
            Logged in as:
            <i class="icon icon-circle-notch icon-spin" *ngIf="sessionLoading"></i>
            <ng-container *ngIf="session">{{session.username}}</ng-container>
          </ng-template>
          <button class="btn btn-secondary ml-2" (click)="changeUser()" [disabled]="sessionLoading">
            <i class="icon icon-user"></i> Change User
          </button>
        </div>
      </div>
      <app-database *ngIf="!sessionError" [session]="session" [server]="server" [heading]="heading"
        (createIndexData)="getCreateIndexData($event)"></app-database>
      <div class="alert alert-danger mt-2" *ngIf="sessionError">
        {{sessionError}}
      </div>
    </ng-container>
    <div class="alert alert-danger" *ngIf="databasesLoadError">
      Unbale to retrieve schema.<br>
      Error: {{databasesLoadError}}
    </div>
  </ng-template>
</div>

<app-create-index *ngIf="isCreateIndex" [createIndexData]="createIndexData" [server]="server"
  (closeCreateIndex)="closeCreateIndexModal()"></app-create-index>
<app-create-demo-database-modal #createDemoDatabaseModal (actionComplete)="closeCreateDatabaseModal()"
  [databases]="databases">
</app-create-demo-database-modal>
<app-create-database *ngIf="isCreateDatabase" (actionComplete)="closeCreateDatabaseModal()" [databases]="databases">
</app-create-database>
<app-create-table *ngIf="isCreateTable" [database]="selectedDatabase" [session]="session"
  (actionComplete)="closeCreateTable()"></app-create-table>

<div bsModal #confirmationModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Uncommitted Transaction Found</h5>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="p-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
            Save with "Commit" or discard with "Rollback".
        </div>
      </div>
      <div class="modal-footer d-inline-block">
        <div class="float-left" *ngIf="isPageChange">
          <button type="button" class="btn btn-secondary btn-fixed-width" (click)="cancel();">
            Cancel
          </button>
        </div>
        <div class="float-right">
          <button type="button" class="btn btn-secondary btn-fixed-width mr-1" (click)="rollback();">
            Rollback
          </button>
          <button type="button" class="btn btn-primary btn-fixed-width mr-1" (click)="commit()">
            Commit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Query history page -->
<div class="container-fluid page-container" [hidden]="!showQueryHistory">
  <div class="row mb-2">
    <div class="col-6">
      <h4>
        <strong> Query History</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoadingHistory"></i>
      </h4>
    </div>
    <div class="col-6 form-inline justify-content-end">
      <div class="align-items-center float-right">
        <button class="btn btn-secondary mr-2" *ngIf="queryHistory.length" (click)="confirmModal.show()">
          Clear Query History
        </button>
        <button class="btn btn-secondary " (click)="showQueryHistory=!showQueryHistory">
          Back to SQL Editor
        </button>
      </div>
    </div>
  </div>
  <div class="alert alert-info mt-4" *ngIf="queryHistory.length && !isLoadingHistory"> 
      <i class="icon icon-info-circle text-info"></i> <span class="pl-2">Query history will be auto deleted after
        {{historyFormGroup.controls.historySettingType.value === 'day' ? historyFormGroup.controls.days.value + ' days' :
        historyFormGroup.controls.entries.value + ' entries'}}.
        <a href="javascript:;" (click)="queryHistorySettingsModal.show()">Click here</a> to change</span>
  </div>
  <div>
    <app-data-table *ngIf="queryHistory && !isLoadingHistory" class="table-responsive" [enableFilter]="false" [data]="queryHistory"
      [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
      <app-data-table-column title="Batch" property="name" width="500px">
        <ng-template appTableCell let-query="row">
          <a class="p-2 border-0 w-100 text-left text-monospace" href="javascript:;" (click)="showBatchModal(query);" *ngIf="!editbatch && !query.edit"> {{query.name}} </a>
          <input class="form-control-sm border-1" [(ngModel)]="batchName" *ngIf="query.edit">
          <button type="button" class="btn btn-sm btn-icon mr-2" *ngIf="query.edit" (click)="updateSqlHistory(query);" tooltip="Save">
            <i class="icon icon-check" title="Save"></i>
        </button>
          <button type="button button " class="btn btn-sm btn-icon" *ngIf="query.edit" (click)="query.edit = false" tooltip="Close">
            <i class="icon icon-times" title="Close" ></i>
        </button>
          <button id="refresh-button" type="button" class="btn btn-sm btn-icon" tooltip="Edit" *ngIf="!query.edit" (click)="query.edit = true; batchName = query.name;">
            <i class="icon icon-edit" title="Edit"></i>
          </button>
        </ng-template>
      </app-data-table-column>
      <app-data-table-column title="Date and Time" property="date">
        <ng-template appTableCell let-query="row">
          {{query.running_datetime | date: 'yyyy-MM-dd HH:mm:ss'}}
        </ng-template>
      </app-data-table-column>
      <app-data-table-column title="Actions" property="" class="text-right pr-4" width="300px">
        <ng-template appTableCell let-query="row">
          <div class="justify-content-end form-inline">
            <a [href]="downloadJsonHref" download="sql_queries.sql" (click)="exportQuery(query, true)"
            class="btn btn-sm btn-icon"> <i class="icon icon-export-icon color-black" tooltip="Export"></i> </a>
            <button type="button" class="btn btn-sm btn-icon" style="border: none;" (click)="loadQuery(query, true);" >
              <i class="icon icon-load-query-icon color-black" tooltip="Load Statements"></i>
            </button>
            <button id="refresh-button" type="button" class="btn btn-sm btn-icon" (click)="removeQuery(query)">
              <i class="icon icon-delete-history color-black" tooltip="Delete"></i>
            </button>
          </div>
        </ng-template>
      </app-data-table-column>
    </app-data-table>
  </div>
</div>

<div bsModal #queryHistorySettingsModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Auto delete settings</h5>
        <button type="button" class="close" (click)="queryHistorySettingsModal.hide(); isBtnDisable = true;">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mr-0">
          <div class="col">
            <form [formGroup]="historyFormGroup">
              <app-radio-group formControlName="historySettingType" class="justify-content-start ml-2" (change)="changeQueryHistory()">
                <app-radio value="day" class="d-block">Auto delete after {{historyFormGroup.controls.days.value == null ? MaxDaysLimit : historyFormGroup.controls.days.value}} days</app-radio>
                <input type="number" class="form-control m-2" title="Specifies that after reaching the days limit, older records above the limit will be deleted" tooltip="days" placeholder="30" formControlName="days" appExtendedFormControl/>
                <app-radio class="mt-2" value="entry" class="d-block">Auto delete after {{historyFormGroup.controls.entries.value == null ? MaxEntriesLimit :
                  historyFormGroup.controls.entries.value}} entries</app-radio>
                <input type="number" class="form-control m-2" title="Specifies that after reaching the entires limit, older records above the limit will be deleted" tooltip="entries" placeholder="1000" formControlName="entries" appExtendedFormControl/>
              </app-radio-group>
            </form>
          </div>
        </div>

        <div class="modal-footer">
          <div class="float-left">
            <button type="button" class="btn btn-secondary" (click)="queryHistorySettingsModal.hide(); isBtnDisable = true">Close</button>
          </div>
          <div class="float-right">
            <button class="btn btn-primary ml-3" [disabled]="historyFormGroup.invalid" (click)="saveConfig()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Confirm Modal -->
<div bsModal #confirmModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Clear Query History</h5>
        <button type="button" class="close" (click)="confirmModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="p-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
          Are you sure you want to clear all query history?
        </div>
      </div>

      <div class="modal-footer">
        <div class="float-left">
          <button type="button" class="btn btn-secondary" (click)="confirmModal.hide()">No</button>
        </div>
        <div class="float-right">
          <button type="button" class="btn btn-primary ml-2"
            (click)="removeQueryHistory(true); confirmModal.hide();">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- delete -->
<div bsModal #deleteConfirmModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Confirm Deletion</h5>
        <button type="button" class="close" (click)="deleteConfirmModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="p-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
          Are you sure you want to delete this batch from the query history?
        </div>
      </div>

      <div class="modal-footer">
        <div class="float-left">
          <button type="button" class="btn btn-secondary" (click)="deleteConfirmModal.hide()">No</button>
        </div>
        <div class="float-right">
          <button type="button" class="btn btn-primary ml-2"
            (click)="removeQueryHistory(false , selectedQueryHistory); deleteConfirmModal.hide();">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #batchModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title pull-left">{{batchData?.name}}</h5>
              <button type="button" class="close" (click)="batchModal.hide()">
                  <i class="icon icon-times"></i>
              </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row mb-3">
                  <div class="col">
                       <p class="mb-0" *ngFor="let query of batchData?.statement_list">
                        {{query}}<br/>
                       </p>
                  </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
              <div class="float-left">
                  <button type="button" class="btn btn-secondary" (click)="removeQuery(batchData, true)">Delete</button>
              </div>
              <div class="float-right">
                <a [href]="downloadJsonHref" download="sql_queries.sql" (click)="exportQuery(batchData);batchModal.hide();"
                class="btn btn-md btn-primary ml-2"> Export </a>
              </div>
              <div class="float-right">
                <button type="button" class="btn btn-primary ml-2" (click)="loadQuery(batchData);batchModal.hide();">Load Query</button>
            </div>
          </div>
      </div>
  </div>
</div>
</div>
