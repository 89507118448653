/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Type } from '@angular/core';
import { DashboardPlugin } from './abstract-dashboard-plugin';
import { SensorChartjsPluginConfigComponent } from './sensor-chartjs-plugin/sensor-chartjs-plugin-config/sensor-chartjs-plugin-config.component';
import { SensorChartjsPluginModule } from './sensor-chartjs-plugin/sensor-chartjs-plugin.module';
import { SensorChartjsPluginComponent } from './sensor-chartjs-plugin/sensor-chartjs-plugin/sensor-chartjs-plugin.component';

export class DashboardBuiltinPlugins {

  private static plugins: DashboardPlugin[] = [
    {
      id: 'sensor-chartjs',
      module: SensorChartjsPluginModule,
      component: SensorChartjsPluginComponent,
      configComponent: SensorChartjsPluginConfigComponent
    }
  ];

  static getPluginModules(): Type<any>[] {
    return [SensorChartjsPluginModule];
  }

  static getPluginComponents(): Type<any>[] {
    const components: Type<any>[] = [];
    DashboardBuiltinPlugins.plugins.forEach(plugin => {
      components.push(plugin.component);
      components.push(plugin.configComponent);
    });
    return components;
  }

  static getPlugin(id: string): DashboardPlugin {
    return DashboardBuiltinPlugins.plugins.find(plugin => plugin.id === id);
  }
}
