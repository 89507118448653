/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023. All Rights Reserved.
 *******************************************************************************/

import { Route } from '@angular/router';
import { ReplicatesComponent } from './replicates.component';
import { ServerResolver } from '../../server-resolver.service';
import { NewReplicateComponent } from './new-replicate/new-replicate.component';
import { CheckSyncTaskComponent } from './check-sync-task/check-sync-task.component';
import { TaskDetailsComponent } from './task-details/task-details.component';

export const replicatesRoutes: Route[] = [
    {
        path: 'replicate',
        component: ReplicatesComponent,
        resolve: {
            server: ServerResolver
        }
    },
    {
        path: 'replicate/new',
        component: NewReplicateComponent,
        resolve: {
            server: ServerResolver
        }
    },
    {
        path: 'replicate/edit',
        component: NewReplicateComponent,
        resolve: {
            server: ServerResolver
        }
    },
    {
        path: 'replicate/check-sync',
        component: CheckSyncTaskComponent,
        resolve: {
            server: ServerResolver
        }
    },
    {
        path: 'replicate/task-details',
        component: TaskDetailsComponent,
        resolve: {
            server: ServerResolver
        }
    }
];
