/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ServerOnconfigParameter } from './serverOnconfigParameter';

@Component({
  selector: 'app-onconfig-input',
  templateUrl: 'onconfig-input.component.html',
  styles: ['input.config-input-str { width: 300px }', 'input.config-input-integer { width: 120px }']
})
export class OnConfigInputComponent implements OnChanges {
  @Input() onconfig: ServerOnconfigParameter;

  private booleanOptions: { name: string; value: any }[] = [
    { name: '1 (ON)', value: 1 },
    { name: '0 (OFF)', value: 0 }
  ];

  valueOptions: { name: string; value: any }[] = null;

  valueControl: UntypedFormControl = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.onconfig && this.onconfig) {
      const info = this.onconfig.info;

      if (info.type === 'BOOLEAN') {
        this.valueOptions = this.booleanOptions;
      } else if (info.values) {
        this.valueOptions = info.values.map(v => ({ name: v, value: v }));
      } else {
        this.valueOptions = null;
      }

      const value = (info.type === 'INTEGER' || info.type === 'BOOLEAN') ? parseInt(this.onconfig.effective, 10) : this.onconfig.effective;
      this.valueControl = new UntypedFormControl(value, info.type === 'STRING' ? null : Validators.required);
    }
  }

  isValid(): boolean {
    return this.valueControl && this.valueControl.valid;
  }

  getValue(): any {
    return this.valueControl ? this.valueControl.value : null;
  }
}
