/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ERDomainModes } from '../enterprise-replication-types';
import { ERDomain, ERNode } from '../er-domain';

const NODE_RADIUS = 25;
const LAYER_MIN_RADIUS = 100;
const LAYER_MAX_RADIUS = 300;

const GRAPH_LINE_OFFSET = 5;

interface Position {
  x: number;
  y: number;
}

interface AngularPosition {
  radius: number;
  radians: number;
}

interface GraphLine {
  start: Position;
  end: Position;
}

class GraphNode {
  pos: Position;
  children: GraphNode[] = [];

  private lines: GraphLine[] = null;

  cssStyle: any = {};

  constructor(
    public node: ERNode,
    public angularPos: AngularPosition
  ) {
    this.calculatePosition();
  }

  private calculatePosition() {
    this.setPosition({
      x: Math.round(Math.cos(this.angularPos.radians) * this.angularPos.radius),
      y: Math.round(Math.sin(this.angularPos.radians) * this.angularPos.radius)
    });
  }

  setPosition(pos: Position) {
    this.lines = null;
    this.pos = pos;
    this.cssStyle = {
      left: (this.pos.x - NODE_RADIUS) + 'px',
      top: (this.pos.y - NODE_RADIUS) + 'px'
    };
  }

  getLines(): GraphLine[] {
    if (!this.lines) {
      this.calculateLines();
    }
    return this.lines;
  }

  private calculateLines() {
    this.lines = [];

    this.children.forEach(child => {
      const dx = child.pos.x - this.pos.x;
      const dy = child.pos.y - this.pos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const normalizedVector: Position = { x: dx / distance, y: dy / distance };
      const endpointDistance = NODE_RADIUS + GRAPH_LINE_OFFSET;
      const line: GraphLine = {
        start: {
          x: Math.floor(this.pos.x + normalizedVector.x * endpointDistance),
          y: Math.floor(this.pos.y + normalizedVector.y * endpointDistance)
        },
        end: {
          x: Math.floor(child.pos.x - normalizedVector.x * endpointDistance),
          y: Math.floor(child.pos.y - normalizedVector.y * endpointDistance)
        }
      };

      this.lines.push(line);
    });
  }
}

@Component({
  selector: 'app-er-domain-graph',
  templateUrl: './er-domain-graph.component.html',
  styleUrls: ['./er-domain-graph.component.scss']
})
export class ErDomainGraphComponent implements OnChanges {

  @Input() domain: ERDomain = null;
  @Input() selectedNode: ERNode = null;
  @Input() domainMode: ERDomainModes;

  @Output() selectedNodeChange = new EventEmitter<ERNode>();
  @Output() deleteNode = new EventEmitter<GraphNode>();

  domainModes = ERDomainModes;
  graphNodes: GraphNode[] = null;
  graphSize: Position = null;
  graphRootLines: GraphLine[] = null;
  zoom = 1.0;

  readonly GRAPH_HEIGHT = 650;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.domain) {
      this.buildGraph();
    }
  }

  private buildGraph() {
    if (!this.domain) {
      this.graphNodes = null;
      return;
    }

    this.graphNodes = [];
    this.graphRootLines = null;
    let graphRootNodes: GraphNode[] = null;

    let rootNodes = this.domain.nodes.filter(node => !node.parent);
    if (rootNodes.length < 1) {
      rootNodes = this.domain.nodes.filter(node => !node.parent && !node.isHub);
      if (rootNodes.length > 1) {
        const fakeRootNode = new GraphNode(null, { radius: 0, radians: 0 });
        graphRootNodes = this.buildGraphLayer(fakeRootNode, rootNodes);
      }
    } else if (rootNodes.length === 1) {
      const rootNode = new GraphNode(rootNodes[0], { radius: 0, radians: 0 });
      this.graphNodes.push(rootNode);
      this.buildGraphLayer(rootNode, rootNode.node.children);
    } else {
      const fakeRootNode = new GraphNode(null, { radius: 0, radians: 0 });
      graphRootNodes = this.buildGraphLayer(fakeRootNode, rootNodes);
    }

    const graphOffset: Position = { x: 0, y: 0 };
    this.graphSize = { x: 0, y: 0 };
    this.graphNodes.forEach(node => {
      if (node.pos.x < graphOffset.x) {
        graphOffset.x = node.pos.x;
      }
      if (node.pos.y < graphOffset.y) {
        graphOffset.y = node.pos.y;
      }

      if (node.pos.x > this.graphSize.x) {
        this.graphSize.x = node.pos.x;
      }
      if (node.pos.y > this.graphSize.y) {
        this.graphSize.y = node.pos.y;
      }
    });

    this.graphSize.x = this.graphSize.x - graphOffset.x + NODE_RADIUS * 2;
    this.graphSize.y = this.graphSize.y - graphOffset.y + NODE_RADIUS * 2;

    graphOffset.x = NODE_RADIUS - graphOffset.x;
    graphOffset.y = NODE_RADIUS - graphOffset.y;

    if (this.graphSize.y < this.GRAPH_HEIGHT) {
      graphOffset.y += Math.round((this.GRAPH_HEIGHT - this.graphSize.y) / 2);
      this.graphSize.y = this.GRAPH_HEIGHT;
    }

    this.graphNodes.forEach(node => {
      node.setPosition({ x: node.pos.x + graphOffset.x, y: node.pos.y + graphOffset.y });
    });

    if (graphRootNodes) {
      this.graphRootLines = [];

      graphRootNodes.forEach(node => {
        this.graphRootLines.push(this.buildGraphRootLine(node, graphOffset));
      });
    }
  }

  private buildGraphLayer(node: GraphNode, children: ERNode[], availableRadians: number = Math.PI * 2, offsetRadians: number = Math.PI) {
    if (children.length < 1) {
      return;
    }

    const graphNodes: GraphNode[] = [];
    const radius = this.getLayerRadius(node.angularPos.radius, children.length, availableRadians);
    const radiansPerChild = availableRadians / children.length;
    const halfRadiansPerChild = radiansPerChild / 2;
    children.forEach((erNode, i) => {
      const angularPos: AngularPosition = { radius, radians: i * radiansPerChild + halfRadiansPerChild + offsetRadians };
      const childNode = new GraphNode(erNode, angularPos);
      graphNodes.push(childNode);
      this.graphNodes.push(childNode);
      node.children.push(childNode);
      this.buildGraphLayer(childNode, erNode.children, radiansPerChild, offsetRadians + i * radiansPerChild);
    });

    return graphNodes;
  }

  private getLayerRadius(previousLayerRadius: number, nodeCount: number, availableRadians: number): number {
    let radius = nodeCount * 2 * NODE_RADIUS / availableRadians - previousLayerRadius;
    radius = radius < LAYER_MIN_RADIUS ? LAYER_MIN_RADIUS : (radius > LAYER_MAX_RADIUS ? LAYER_MAX_RADIUS : radius);
    return radius + previousLayerRadius;
  }

  private buildGraphRootLine(node: GraphNode, center: Position) {
    const dx = node.pos.x - center.x;
    const dy = node.pos.y - center.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const normalizedVector: Position = { x: dx / distance, y: dy / distance };
    const endpointDistance = NODE_RADIUS + GRAPH_LINE_OFFSET;

    return {
      start: center,
      end: {
        x: Math.floor(node.pos.x - normalizedVector.x * endpointDistance),
        y: Math.floor(node.pos.y - normalizedVector.y * endpointDistance)
      }
    };
  }

  selectNode(node: GraphNode) {
    this.selectedNode = node.node;
    this.selectedNodeChange.emit(this.selectedNode);
  }

  zoomIn() {
    this.zoom = Math.min(3.5, this.zoom + 0.25);
  }

  zoomOut() {
    this.zoom = Math.max(0, this.zoom - 0.25);
  }

  removeNode(node: GraphNode) {
    this.deleteNode.emit(node);
  }
}
