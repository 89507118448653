/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2020. All Rights Reserved.
 *******************************************************************************/
import { InformixServerGroup } from '../../groups/informixServerGroup';

export class DashboardPanelConfig {
  title: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: string;
  config: any;

  constructor(json: any) {
    this.title = json.title;
    this.x = json.x;
    this.y = json.y;
    this.width = json.width;
    this.height = json.height;
    this.type = json.type;
    this.config = json.config;
  }
}

export class DashboardConfig {
  name: string;
  panels: DashboardPanelConfig[] = [];
  defaultServerIds: number[] = [];
  new = false;
  timeSlice: number;

  constructor(json: any) {
    this.name = json.name || 'Unnamed dashboard';
    if (Array.isArray(json.panels)) {
      this.panels = json.panels.map(v => new DashboardPanelConfig(v));
    }
    if (Array.isArray(json.defaultServerIds)) {
      this.defaultServerIds = json.defaultServerIds;
    }
    if (json.new) {
      this.new = json.new;
    }
    if (json.timeSlice) {
      this.timeSlice = json.timeSlice;
    }
  }
}

export class Dashboard {
  id: number;
  group: InformixServerGroup;
  config: DashboardConfig;

  restUrl: string;

  constructor(group: InformixServerGroup);
  constructor(json: any);
  constructor(jsonOrGroup: any) {
    if (jsonOrGroup instanceof InformixServerGroup) {
      this.group = jsonOrGroup;
      this.config = new DashboardConfig({
        name: 'New dashboard'
      });
    } else {
      const json = jsonOrGroup;
      this.id = json.id;
      this.group = new InformixServerGroup(json.group);
      this.config = new DashboardConfig(json.config);

      this.restUrl = 'informix/groups/' + this.group.id + '/dashboards/' + this.id;
    }
  }
}
