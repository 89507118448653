<ng-container *ngIf="databases else serverError;">
  <div class="row mt-3">
    <div class="col-12 col-lg-4 col-xl-2 pr-0">
      <label>Select Database</label>
      <select class="form-control" [(ngModel)]="selectedDB" (ngModelChange)="changeDatabase($event)">
        <option [disabled]="selectedDB" value="">-- Select database --</option>
        <option *ngFor="let db of databases" value="{{db}}">{{db}}</option>
      </select>
    </div>
  </div>
  <h6 class="font-weight-bold mt-3">Grant New Privilege</h6>
  <div class="row" [formGroup]="privilegeFormGroup">
    <div class="col-12 col-lg-4 col-xl-2 mt-2 pr-0">
      <label>User Name for Privileges</label>
      <input class="form-control" type="text" placeholder="Enter User Name..." formControlName="userName" tooltip=""
        appExtendedFormControl>
    </div>
    <div class="col-12 col-lg-4 col-xl-2 mt-2 pr-0">
      <label>Table Name</label>
      <select class="form-control" formControlName="selectedtableName">
        <option [disabled]="true" [ngValue]="null">-- Select Table Name --</option>
        <option *ngFor="let tableName of tableNames">{{tableName}}</option>
      </select>
    </div>
    <div class="col-12 col-xl-7 mt-2">
      <label>Select Privileges from Below</label>
      <div class="btn-group-toggle" data-toggle="buttons">
        <label formArrayName="privilegeTypes" class="btn btn-round" [ngClass]="privilegeFormGroup.value.privilegeTypes[i] ? privilegeTypes[i].class : 'bg-light-gray-40 text-dark'"
          *ngFor="let privilege of privilegeFormGroup?.controls.privilegeTypes.controls; let i = index">
          <input type="checkbox" [formControlName]="i"> {{privilegeTypes[i].typeName}}
        </label>
      </div>
    </div>
    <div class="col-12 col-xl-1 mt-3">
      <div>
        <label></label>
      </div>
      <app-check-server-admin-permissions [server]="server" placement="top">
        <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
          <button type="button" class="btn btn-primary" [disabled]="!privilegeFormGroup?.valid" (click)="grant()"
            appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable>Grant</button>
        </app-check-hdr-permissions>
      </app-check-server-admin-permissions>
    </div>
  </div>
  <br>
  <h5>Table-Level Privileges</h5>
  <app-data-table *ngIf="tablePrivilegeInfos; else tableNamesAreLoading" [data]="tablePrivilegeInfos" [rowsPerPage]="10"
    searchLabel="Search here ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
    <app-data-table-column title="Table Name" property="tableName" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="User" property="grantee" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Grantor" property="grantor" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Privileges">
      <ng-template appTableCell let-tablePrivilegeInfo="row">
        <div class="privilege-text rounded-circle text-center d-inline-block align-middle" *ngFor="let privilege of tablePrivilegeInfo.tabAuth" [ngClass]="getColor(privilege)">
          {{privilege}}
        </div>
        <app-check-server-admin-permissions [server]="server" class="privilege-popover" placement="top">
          <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
            <button type="button" class="btn btn-icon btn-sm" tooltip="Modify" [popover]="modifyPrivileges"
              #modifyPopover="bs-popover" [outsideClick]="true" (click)="editPrivilege(tablePrivilegeInfo.tabAuth)"
              popoverTitle="Select Privileges" appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable containerClass="popover-color">
              <i class="icon icon-edit"></i>
            </button>
          </app-check-hdr-permissions>
        </app-check-server-admin-permissions>
        <ng-template #modifyPrivileges>
          <div class="popover-body btn-group-toggle" data-toggle="buttons">
            <ul>
              <label *ngFor="let modifiedPrivilegeType of privilegeTypes; let j=index" class="btn btn-round" [ngClass]="modifiedPrivilegeType.checked ? modifiedPrivilegeType.class : 'bg-light-gray-40 text-dark'">
                <input type="checkbox" (change)="privilegeTypes[j].checked = !privilegeTypes[j].checked">
                {{modifiedPrivilegeType.typeName}}
              </label></ul>
          </div>
          <div><button type="button" class="popover-btn btn-border-right btn bg-white" (click)="save(tablePrivilegeInfo); modifyPopover.hide();">
              <i class="icon icon-check"></i>
            </button>
            <button type="button" class="popover-btn btn bg-white" (click)="modifyPopover.hide();"><i class="icon icon-times"></i></button>
          </div>
        </ng-template>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Revoke">
      <ng-template appTableCell let-tablePrivilegeInfo="row">
        <div>
          <app-check-server-admin-permissions [server]="server" placement="top">
            <app-check-hdr-permissions [server]="server" [hdrPermission]="priviligesHDR" placement="top">
              <button type="button" class="btn btn-icon btn-sm" (click)="revoke(tablePrivilegeInfo)" appHDRPermission [hdrPermission]="priviligesHDR" appPermissionDisable>
                <i class="icon icon-trash"></i>
              </button>
            </app-check-hdr-permissions>
          </app-check-server-admin-permissions>
        </div>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
  <ng-template #tableNamesAreLoading>
    <div class="d-flex justify-content-center">
      <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>
  </ng-template>
</ng-container>

<ng-template #serverError>
  <div class="mt-2 alert alert-danger" *ngIf="apiError">
    Unable to retrieve table privileges information. <br> Error: {{apiError}}
  </div>
</ng-template>
