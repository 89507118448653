/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, EventEmitter, Output, ViewChild, Input, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ErrorMessageHandlers } from '../../../../shared/forms/extendedFormControl.directive';
import { IACValidators } from '../../../../shared/forms/validators';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { ActivatedRoute } from '@angular/router';
import { SchemaService } from '../schema.service';
import { InformixServerStorageService } from '../../storage/informixServerStorage.service';
import { InformixServer } from '../../informixServer';
import { InformixSQLSession } from '../informix-sql-session';
import { TranslateService } from '@ngx-translate/core';
import { TableColumnsList } from './create-table.model';

@Component({
  selector: 'app-column-modal',
  templateUrl: './column-modal.component.html',
  styles: [`.store-column-height {
    max-height: 150px;
    min-height: 100px;
  }
  .number-input-width {
    width: 50px;
  }`]
})
export class ColumnModalComponent implements OnInit {
  createColumnForm: UntypedFormGroup;
  externalCharLength: UntypedFormControl;
  columnDataType: UntypedFormControl;
  defaultValue: UntypedFormControl;
  startValue: UntypedFormControl;
  length: UntypedFormControl;
  nullValues: UntypedFormControl;
  largestTimeUnit: UntypedFormControl;
  smallestTimeUnit: UntypedFormControl;
  tableColumnName: UntypedFormControl;
  precision: UntypedFormControl;
  scale: UntypedFormControl;
  year: UntypedFormControl;
  month: UntypedFormControl;
  day: UntypedFormControl;
  hour: UntypedFormControl;
  minute: UntypedFormControl;
  second: UntypedFormControl;
  fraction: UntypedFormControl;
  nullAsDefault: UntypedFormControl;
  noDefaultValue: UntypedFormControl;
  columnConstraint: UntypedFormControl;
  constraintText: UntypedFormControl;
  storeColumnIn: UntypedFormControl;
  extentSize: UntypedFormControl;
  turnOnLogging: UntypedFormControl;
  recordAccessTime: UntypedFormControl;
  highDataIntegrity: UntypedFormControl;
  setDataType: UntypedFormControl;
  selectedSpace: UntypedFormControl;
  namedRowType: UntypedFormControl;
  charTypesErrorHandlers: ErrorMessageHandlers = {};
  columnNameErrorHandlers: ErrorMessageHandlers = {};
  currentDefaultValue = null;
  defaultValOptions = ['NULL', 'USER'];
  colDetails: TableColumnsList;
  tableAdvObj;
  selectedVal: any;
  dateTypes = ['datetime', 'date'];
  numericTypes = ['smallint', 'integer', 'float', 'smallfloat', 'decimal', 'serial', 'money', 'bigint', 'bigserial'];
  optionsWithNumVal = ['bigInt', 'decimal', 'float', 'integer', 'money', 'smallFloat', 'smallInt'];
  optionsWithSelect = ['boolean', 'bson', 'date', 'dateTime', 'json', 'longLvarchar'];
  optionsWithStrValue = ['char', 'lvarchar', 'namedRow', 'nchar', 'nvarchar', 'varchar'];
  colDataTypes = [];
  spaces = [];
  requiredSpaces = [];
  currentDataType = 'char';
  intervals = [];
  namedRowTypes = {};
  largestUnit = ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND', 'FRACTION'];
  smallestUnit = [];
  setDataTypes = [];
  dataTypes = [{ name: 'BIGINT', value: 'bigInt', type: 'standard fixed delimited', isSetType: true },
  { name: 'BIGSERIAL', value: 'bigSerial', type: 'standard fixed delimited', isSetType: false },
  { name: 'BLOB', value: 'blob', type: 'standard delimited', isSetType: true },
  { name: 'BOOLEAN', value: 'boolean', type: 'standard fixed delimited', isSetType: true },
  { name: 'BSON', value: 'bson', type: 'standard', isSetType: false },
  { name: 'BYTE', value: 'byte', type: 'standard delimited', isSetType: false },
  { name: 'CHAR', value: 'char', type: 'standard fixed delimited', isSetType: true },
  { name: 'CLOB', value: 'clob', type: 'standard delimited', isSetType: true },
  { name: 'DATE', value: 'date', type: 'standard fixed delimited', isSetType: true },
  { name: 'DATETIME', value: 'dateTime', type: 'standard fixed delimited', isSetType: true },
  { name: 'DECIMAL', value: 'decimal', type: 'standard fixed delimited', isSetType: true },
  { name: 'FLOAT', value: 'float', type: 'standard fixed delimited', isSetType: true },
  { name: 'INTEGER', value: 'integer', type: 'standard fixed delimited', isSetType: true, min: -2147483647, max: 2147483647 },
  { name: 'INTERVAL', value: 'interval', type: 'standard fixed delimited', isSetType: true },
  { name: 'JSON', value: 'json', type: 'standard', isSetType: false },
  { name: 'LIST', value: 'list', type: 'standard fixed delimited', isSetType: false },
  { name: 'LONGLVARCHAR', value: 'longLvarchar', type: 'standard', isSetType: false },
  { name: 'LVARCHAR', value: 'lvarchar', type: 'standard fixed delimited', isSetType: true },
  { name: 'MONEY', value: 'money', type: 'standard fixed delimited', isSetType: true },
  { name: 'MULTISET', value: 'multiset', type: 'standard fixed delimited', isSetType: false },
  { name: 'NAMED ROW', value: 'namedRow', type: 'standard fixed delimited', isSetType: false },
  { name: 'NCHAR', value: 'nchar', type: 'standard fixed delimited', isSetType: true },
  { name: 'NVARCHAR', value: 'nvarchar', type: 'standard fixed delimited', isSetType: true },
  { name: 'SERIAL', value: 'serial', type: 'standard fixed delimited', isSetType: false },
  { name: 'SET', value: 'set', type: 'standard fixed delimited', isSetType: false },
  { name: 'SMALLFLOAT', value: 'smallFloat', type: 'standard fixed delimited', isSetType: true },
  { name: 'SMALLINT', value: 'smallInt', type: 'standard fixed delimited', isSetType: true, min: -32767, max: 32767 },
  { name: 'TEXT', value: 'text', type: 'standard delimited', isSetType: false },
  { name: 'VARCHAR', value: 'varchar', type: 'standard fixed delimited', isSetType: true }];

  informixColDataType = [];
  isEdit = false;
  tableType = '';
  currIndex: any;
  server: InformixServer;
  objectKeys = Object.keys;

  @ViewChild('columnNameInput') columnNameInput: ElementRef;
  @ViewChild('addColumnModal') addColumnModal: ModalDirective;
  @Input() columnNames = [];
  @Input() session: InformixSQLSession;
  @Output() addCol = new EventEmitter<any>();
  @Output() editCol = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    public route: ActivatedRoute,
    private schemaService: SchemaService,
    private storageService: InformixServerStorageService,
    private translate: TranslateService
  ) { }

  initCreateColumnForm() {
    this.externalCharLength = new UntypedFormControl(32, [Validators.min(1), Validators.max(32767)]);
    this.tableColumnName = new UntypedFormControl(null, [this.duplicateColumnNameValidator(), IACValidators.noWhitespaceValidator(),
    Validators.required, IACValidators.informixIdentifierValidator]);
    this.columnDataType = new UntypedFormControl('char');
    this.defaultValue = new UntypedFormControl(null);
    this.length = new UntypedFormControl(32);
    this.nullValues = new UntypedFormControl(true);
    this.startValue = new UntypedFormControl(1);
    this.largestTimeUnit = new UntypedFormControl('YEAR');
    this.smallestTimeUnit = new UntypedFormControl('FRACTION');
    this.precision = new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(32)]);
    this.scale = new UntypedFormControl(null);
    this.year = new UntypedFormControl(null);
    this.month = new UntypedFormControl(null);
    this.day = new UntypedFormControl(null);
    this.hour = new UntypedFormControl(null);
    this.minute = new UntypedFormControl(null);
    this.second = new UntypedFormControl(null);
    this.fraction = new UntypedFormControl(null);
    this.noDefaultValue = new UntypedFormControl(null);
    this.nullAsDefault = new UntypedFormControl(null);
    this.columnConstraint = new UntypedFormControl(null);
    this.constraintText = new UntypedFormControl(null);
    this.storeColumnIn = new UntypedFormControl(null);
    this.extentSize = new UntypedFormControl(null);
    this.turnOnLogging = new UntypedFormControl(null);
    this.recordAccessTime = new UntypedFormControl(null);
    this.highDataIntegrity = new UntypedFormControl(null);
    this.setDataType = new UntypedFormControl('char');
    this.selectedSpace = new UntypedFormControl(null);
    this.namedRowType = new UntypedFormControl(null);
    this.createColumnForm = this.fb.group({
      tableColumnName: this.tableColumnName,
      columnDataType: this.columnDataType,
      length: this.length,
      defaultValue: this.defaultValue,
      nullValues: this.nullValues,
      columnConstraint: this.columnConstraint,
      constraintText: this.constraintText,
      startValue: this.startValue,
      precision: this.precision,
      scale: this.scale,
      storeColumnIn: this.storeColumnIn,
      extentSize: this.extentSize,
      turnOnLogging: this.turnOnLogging,
      recordAccessTime: this.recordAccessTime,
      highDataIntegrity: this.highDataIntegrity,
      externalCharLength: this.externalCharLength,
      largestTimeUnit: this.largestTimeUnit,
      smallestTimeUnit: this.smallestTimeUnit,
      year: this.year,
      month: this.month,
      day: this.day,
      hour: this.hour,
      minute: this.minute,
      second: this.second,
      fraction: this.fraction,
      nullAsDefault: this.nullAsDefault,
      noDefaultValue: this.noDefaultValue,
      setDataType: this.setDataType,
      selectedSpace: this.selectedSpace,
      namedRowType: this.namedRowType
    });
  }

  ngOnInit() {
    this.translate.get('schemaManager.createTable.errorMsg.charTypeLengthError').subscribe((text: string) => {
      this.charTypesErrorHandlers.lengthError = error => text;
    });

    this.translate.get('schemaManager.createTable.errorMsg.duplicateNameError').subscribe((text: string) => {
      this.columnNameErrorHandlers.duplicateName = error => text;
    });

    this.translate.get('schemaManager.createTable.errorMsg.whitespaceError').subscribe((text: string) => {
      this.columnNameErrorHandlers.whitespace = error => text;
    });

    this.route.data.subscribe(data => {
      this.server = data.server;
    });
    this.initCreateColumnForm();
    this.length.valueChanges.subscribe(value => {
      if (value > 0) {
        this.defaultValue.markAsTouched({ onlySelf: true });
        this.defaultValue.updateValueAndValidity();
      }
    });
    this.defaultValue.valueChanges.subscribe(value => {
      if (this.currentDataType === 'bigInt' || this.currentDataType === 'smallInt' || this.currentDataType === 'integer') {
        if (value) {
          if (value.includes('NULL')) {
            this.defaultValue.setValue('NULL', { emitEvent: false });
          } else {
            this.defaultValue.setValue(this.defaultValue.value.replace(/[^0-9-]/g, ''), { emitEvent: false });
          }
          if (value.includes('-')) {
            if ((value.match(/\-/g) || []).length > 1) {
              const index = value.lastIndexOf('-');
              this.defaultValue.setValue(value.substr(0, index) + value.substr(index + value.length), { emitEvent: false });
            }
            if ((value.match(/^\-/g) || []).length === 0) {
              const index = value.lastIndexOf('-');
              this.defaultValue.setValue(value.substr(0, index) + value.substr(index + value.length), { emitEvent: false });
            }
          }
        }
      } else if (this.currentDataType === 'float' || this.currentDataType === 'smallFloat' || this.currentDataType === 'decimal' ||
        this.currentDataType === 'money') {
        if (value) {
          if (value.includes('NULL')) {
            this.defaultValue.setValue('NULL', { emitEvent: false });
          } else {
            if (value.includes('.')) {
              if ((value.match(/\./g) || []).length > 1) {
                const index = value.lastIndexOf('.');
                this.defaultValue.setValue(value.substr(0, index) + value.substr(index + value.length), { emitEvent: false });
              }
            }
            if (value.includes('-')) {
              if ((value.match(/\-/g) || []).length > 1) {
                const index = value.lastIndexOf('-');
                this.defaultValue.setValue(value.substr(0, index) + value.substr(index + value.length), { emitEvent: false });
              }
              if ((value.match(/^\-/g) || []).length === 0) {
                const index = value.lastIndexOf('-');
                this.defaultValue.setValue(value.substr(0, index) + value.substr(index + value.length), { emitEvent: false });
              }
            }
            this.defaultValue.setValue(this.defaultValue.value.replace(/[^0-9.-]/g, ''), { emitEvent: false });
          }
        }
      } else if (this.optionsWithStrValue.indexOf(this.currentDataType) > -1 && this.currentDataType !== 'namedRow') {
        value === 'NULL' ? this.defaultValue.clearValidators() : this.defaultValue.setValidators([this.charTypesValidator()]);
      }
      this.defaultValue.updateValueAndValidity({ emitEvent: false });
    });
    this.scale.valueChanges.subscribe(value => {
      if (value && this.columnDataType.value === 'interval') {
        let max = 0;
        for (let j = 0; j < this.scale.value; j++) {
          max = (max * 10) + 9;
        }
        this.fraction.setValidators([Validators.required, Validators.min(0), Validators.max(max)]);
        this.fraction.updateValueAndValidity();
        if (this.fraction.valid) {
          this.fraction.setErrors(null);
        }
      }
    });
    this.precision.valueChanges.subscribe(value => {
      if (value) {
        if (this.currentDataType === 'decimal' || this.currentDataType === 'money') {
          this.scale.setValidators([Validators.required, Validators.min(1), Validators.max(value)]);
          if (this.scale.value > value) {
            this.scale.setValue(value);
          }
          this.scale.updateValueAndValidity();
        }
        if (!this.noDefaultValue.value && !this.nullAsDefault.value && this.columnDataType.value === 'interval') {
          this.enableIntervals();
        } else {
          this.disableIntervals();
        }
      }
    });
    this.largestTimeUnit.valueChanges.subscribe(value => {
      if (value) {
        if (value === 'YEAR' && this.currentDataType === 'interval') {
          this.smallestUnit = ['YEAR', 'MONTH'];
          this.precision.setValue(4);
        } else if (value === 'MONTH' && this.currentDataType === 'interval') {
          this.smallestUnit = ['MONTH'];
          this.precision.setValue(2);
        } else {
          this.smallestUnit = this.largestUnit.slice(this.largestUnit.indexOf(value), this.largestUnit.length);
          if (this.currentDataType === 'interval') {
            this.precision.setValue(2);
          }
        }
        if (this.currentDataType === 'interval') {
          if (value === 'FRACTION') {
            this.precision.setValue(null);
            this.precision.clearValidators();
          } else {
            this.precision.setValidators([Validators.required, Validators.min(1), Validators.max(9)]);
          }
        }
        this.precision.updateValueAndValidity();
        this.smallestTimeUnit.setValue(this.smallestUnit[this.smallestUnit.length - 1]);
      }
    });
    this.smallestTimeUnit.valueChanges.subscribe(value => {
      if (value) {
        this.getIntervals();
        if (!this.noDefaultValue.value && !this.nullAsDefault.value && this.columnDataType.value === 'interval') {
          this.enableIntervals();
        } else {
          this.disableIntervals();
        }
        if (value !== 'FRACTION') {
          this.scale.setValue(null);
          this.scale.clearValidators();
        } else {
          this.scale.setValue(3);
          this.scale.setValidators([Validators.required, Validators.min(1), Validators.max(5)]);
        }
        this.scale.updateValueAndValidity();
        if (this.currentDataType === 'dateTime') {
          if (this.largestTimeUnit.value === 'YEAR' && this.smallestTimeUnit.value === 'FRACTION') {
            this.defaultValOptions = this.nullValues.value ? ['NULL', 'No default value', 'CURRENT'] : ['No default value', 'CURRENT'];
          } else {
            this.defaultValOptions = this.nullValues.value ? ['NULL', 'No default value'] : ['No default value'];
          }
        }
      }
    });
    this.storeColumnIn.valueChanges.subscribe(value => {
      if (value) {
        if (this.columnDataType.value === 'byte' || this.columnDataType.value === 'text') {
          if (value === 'blobspace') {
            this.requiredSpaces = this.spaces.filter(space => {
              if (space && space.type === 'blobspace') {
                return space;
              }
            });
            this.selectedSpace.setValue(this.requiredSpaces[0].name);
            this.selectedSpace.enable();
          } else {
            this.selectedSpace.setValue(null);
            this.selectedSpace.disable();
          }
        }
      }
    });
    this.nullAsDefault.valueChanges.subscribe(value => {
      if (value) {
        this.noDefaultValue.disable({ emitEvent: false });
        this.disableIntervals();
      } else {
        this.noDefaultValue.enable({ emitEvent: false });
        if (this.noDefaultValue.value === false) {
          this.enableIntervals();
        }
      }
    });
    this.noDefaultValue.valueChanges.subscribe(value => {
      if (value) {
        this.nullAsDefault.disable({ emitEvent: false });
        this.disableIntervals();
      } else {
        if (this.nullValues.value) {
          this.nullAsDefault.enable({ emitEvent: false });
        }
        if (this.nullAsDefault.value === false) {
          this.enableIntervals();
        }
      }
    });
    this.columnDataType.valueChanges.subscribe(value => {
      this.setValueAndValidations(value);
    });
    this.setDataType.valueChanges.subscribe(value => this.setValueAndValidations(value));
    this.nullValues.valueChanges.subscribe(value => {
      this.isNullCheck(value);
      this.updateForm(Object.keys(this.createColumnForm.controls));
    });
  }

  setValueAndValidations(value) {
    if (value) {
      this.removeValidators();
      this.defaultValue.setValue(null, { emitEvent: false });
      this.spaces.map(space => space.selected = false);
      this.tableColumnName.setValidators([this.duplicateColumnNameValidator(), IACValidators.noWhitespaceValidator(),
      Validators.required]);
      if (this.columnDataType.value !== 'list' && this.columnDataType.value !== 'set' && this.columnDataType.value !== 'multiset') {
        this.currentDataType = this.columnDataType.value;
        this.setDataType.setValue(null);
      } else {
        this.currentDataType = this.setDataType.value;
      }
      if (this.tableType === 'externalfixed') {
        this.externalCharLength.setValue(this.colDetails && this.colDetails.externalCharacterLength ?
          this.colDetails.externalCharacterLength : 32);
        this.externalCharLength.setValidators([Validators.required, Validators.min(1), Validators.max(32767)]);
      }
      const isEditMode = this.colDetails && ((this.currentDataType === this.colDetails.columnDataType &&
        (this.columnDataType.value !== 'list' && this.columnDataType.value !== 'set' && this.columnDataType.value !== 'multiset')) ||
        ((this.columnDataType.value === 'list' || this.columnDataType.value === 'set' || this.columnDataType.value === 'multiset') &&
          this.currentDataType === this.colDetails.setDataType));
      switch (value.toUpperCase()) {
        case 'BIGINT':
        case 'INTEGER':
        case 'SMALLINT':
        case 'SMALLFLOAT':
        case 'FLOAT':
        case 'BSON':
        case 'JSON':
        case 'LONGLVARCHAR':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL'];
            this.defaultValue.setValue(this.defaultValOptions[0], { emitEvent: false });
          } else {
            this.defaultValOptions = [];
            this.defaultValue.setValue(null, { emitEvent: false });
          }
          break;
        case 'BIGSERIAL':
        case 'SERIAL':
          this.startValue.setValue(isEditMode && this.colDetails.dataTypeAttributes.startValue ?
            this.colDetails.dataTypeAttributes.startValue : 1);
          if (value === 'bigSerial') {
            this.startValue.setValidators([Validators.required, Validators.min(1), Validators.max(4294967295)]);
          } else {
            this.startValue.setValidators([Validators.required, Validators.min(1), Validators.max(2147483647)]);
          }
          break;
        case 'BLOB':
        case 'CLOB':
          this.extentSize.setValue(null);
          this.turnOnLogging.setValue(null);
          this.recordAccessTime.setValue(null);
          this.highDataIntegrity.setValue(null);
          this.extentSize.disable();
          this.turnOnLogging.disable();
          this.recordAccessTime.disable();
          this.highDataIntegrity.disable();
          this.storageService.getServerSpaces(this.server.id).subscribe(res => {
            this.spaces = res.map(space => ({ type: space.type, name: space.name, selected: false }));
            this.requiredSpaces = this.spaces.filter(space => {
              if (space && space.type === 'sbspace') {
                return space;
              }
            });
            if (isEditMode) {
              const spaces = this.colDetails.dataTypeAttributes.storeColumnIn ?
                this.colDetails.dataTypeAttributes.storeColumnIn.split(',') : null;
              if (spaces && spaces.length > 0) {
                this.requiredSpaces.forEach(space => {
                  if (spaces.indexOf(space.name) > -1) {
                    space.selected = true;
                  } else {
                    space.selected = false;
                  }
                });
              }
              this.extentSize.setValue(this.colDetails.dataTypeAttributes.extentSize);
              this.turnOnLogging.setValue(this.colDetails.dataTypeAttributes.turnOnLogging);
              this.recordAccessTime.setValue(this.colDetails.dataTypeAttributes.recordAccessTime);
              this.highDataIntegrity.setValue(this.colDetails.dataTypeAttributes.highDataIntegrity);
            }
            if (this.requiredSpaces.filter(space => space.selected).length < 1) {
              this.extentSize.setValue(null);
              this.turnOnLogging.setValue(null);
              this.recordAccessTime.setValue(null);
              this.highDataIntegrity.setValue(null);
              this.extentSize.disable();
              this.turnOnLogging.disable();
              this.recordAccessTime.disable();
              this.highDataIntegrity.disable();
            } else {
              this.extentSize.enable();
              this.turnOnLogging.enable();
              this.recordAccessTime.enable();
              this.highDataIntegrity.enable();
            }
          });
          break;
        case 'BOOLEAN':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL', 'TRUE', 'FALSE'];
          } else {
            this.defaultValOptions = ['TRUE', 'FALSE'];
          }
          this.defaultValue.setValue(isEditMode && this.colDetails.defaultValue ? this.colDetails.defaultValue
            : this.defaultValOptions[0], { emitEvent: false });
          break;
        case 'BYTE':
        case 'TEXT':
          this.storeColumnIn.setValue('table');
          this.storageService.getServerSpaces(this.server.id).subscribe(res => {
            this.spaces = res.map(space => ({ type: space.type, name: space.name, selected: false }));
            this.requiredSpaces = this.spaces.filter(space => {
              if (space && space.type === 'blobspace') {
                return space;
              }
            });
            if (isEditMode && this.requiredSpaces.filter(space => space.name ===
              this.colDetails.dataTypeAttributes.storeColumnIn).length > 0) {
              this.storeColumnIn.setValue('blobspace', { emitEvent: false });
              this.selectedSpace.setValue(this.colDetails.dataTypeAttributes.storeColumnIn);
              this.selectedSpace.enable({ emitEvent: false });
            } else {
              this.storeColumnIn.setValue('table');
            }
          });
          break;
        case 'NCHAR':
        case 'CHAR':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL', 'USER'];
            this.defaultValue.setValue(this.defaultValOptions[0], { emitEvent: false });
          } else {
            this.defaultValOptions = ['USER'];
          }
          if (isEditMode && this.colDetails.dataTypeAttributes.length) {
            this.length.setValue(this.colDetails.dataTypeAttributes.length);
          } else {
            this.length.setValue(32);
          }
          this.length.setValidators([Validators.required, Validators.min(1), Validators.max(32767)]);
          this.defaultValue.setValidators([this.charTypesValidator()]);
          break;
        case 'DATE':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL', 'TODAY', 'No default value'];
            this.defaultValue.setValue(isEditMode && this.colDetails.defaultValue ? this.colDetails.defaultValue :
              this.defaultValOptions[0], { emitEvent: false });
          } else {
            this.defaultValOptions = ['TODAY', 'No default value'];
            this.defaultValue.setValue(isEditMode && this.colDetails.defaultValue ? this.colDetails.defaultValue :
              this.defaultValOptions[1], { emitEvent: false });
          }
          break;
        case 'DATETIME':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL', 'No default value', 'CURRENT'];
          } else {
            this.defaultValOptions = ['No default value', 'CURRENT'];
          }
          if (isEditMode && this.colDetails.dataTypeAttributes) {
            this.largestTimeUnit.setValue(this.colDetails.dataTypeAttributes.largestTimeUnit);
            this.smallestTimeUnit.setValue(this.colDetails.dataTypeAttributes.smallestTimeUnit);
            if (this.smallestTimeUnit.value !== 'FRACTION') {
              this.scale.setValue(null);
              this.scale.clearValidators();
            } else {
              this.scale.setValue(this.colDetails.dataTypeAttributes.scale);
              this.scale.setValidators([Validators.required, Validators.min(1), Validators.max(5)]);
            }
          } else {
            this.largestTimeUnit.setValue('YEAR');
          }
          this.defaultValue.setValue(isEditMode && this.colDetails.defaultValue ? this.colDetails.defaultValue :
            this.defaultValOptions[0], { emitEvent: false });
          break;
        case 'DECIMAL':
        case 'MONEY':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL'];
            this.defaultValue.setValue(this.defaultValOptions[0], { emitEvent: false });
          } else {
            this.defaultValOptions = null;
          }
          if (isEditMode && this.colDetails.dataTypeAttributes) {
            this.scale.setValue(this.colDetails.dataTypeAttributes.scale, { emitEvent: false });
            this.precision.setValue(this.colDetails.dataTypeAttributes.precisionDigit);
          } else {
            this.scale.setValue(1, { emitEvent: false });
            this.precision.setValue(16);
          }
          this.precision.setValidators([Validators.required, Validators.min(1), Validators.max(32)]);
          break;
        case 'INTERVAL':
          this.defaultValOptions = null;
          if (isEditMode) {
            if (this.tableType === 'standard' || this.tableType === 'raw') {
              this.nullAsDefault.setValue(this.colDetails.dataTypeAttributes.nullAsDefault);
              this.noDefaultValue.setValue(this.colDetails.dataTypeAttributes.noDefaultValue);
            } else {
              this.noDefaultValue.setValue(this.colDetails.dataTypeAttributes.noDefaultValue);
            }
            if (this.colDetails.dataTypeAttributes) {
              this.largestTimeUnit.setValue(this.colDetails.dataTypeAttributes.largestTimeUnit);
              this.smallestTimeUnit.setValue(this.colDetails.dataTypeAttributes.smallestTimeUnit);
              if (this.largestTimeUnit.value !== 'FRACTION') {
                this.precision.setValue(this.colDetails.dataTypeAttributes.precisionDigit);
                this.precision.setValidators([Validators.required, Validators.min(1), Validators.max(9)]);
              }
              if (this.smallestTimeUnit.value === 'FRACTION') {
                this.scale.setValue(this.colDetails.dataTypeAttributes.scale);
                this.scale.setValidators([Validators.required, Validators.min(1), Validators.max(5)]);
              }
              this.getIntervals();
              if (!this.noDefaultValue.value && !this.nullAsDefault.value && this.columnDataType.value === 'interval') {
                this.enableIntervals();
              } else {
                this.disableIntervals();
              }
            }
          } else {
            if (this.tableType === 'standard' || this.tableType === 'raw') {
              this.nullAsDefault.setValue(true);
              this.noDefaultValue.setValue(false);
            } else {
              this.noDefaultValue.setValue(true);
            }
            this.largestTimeUnit.setValue('YEAR');
          }
          break;
        case 'LIST':
        case 'MULTISET':
        case 'SET':
          this.setDataTypes = this.dataTypes.filter(type => type.isSetType === true);
          this.setDataType.setValue(this.colDetails && this.colDetails.setDataType ? this.colDetails.setDataType : 'char');
          this.currentDataType = this.setDataType.value;
          break;
        case 'LVARCHAR':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL', 'USER', 'DBSERVERNAME', 'SITENAME'];
            this.defaultValue.setValue('NULL', { emitEvent: false });
          } else {
            this.defaultValOptions = ['USER', 'DBSERVERNAME', 'SITENAME'];
          }
          this.length.setValue(isEditMode && this.colDetails.dataTypeAttributes.length ?
            this.colDetails.dataTypeAttributes.length : 512);
          this.length.setValidators([Validators.required, Validators.min(1), Validators.max(32739)]);
          this.defaultValue.setValidators([this.charTypesValidator()]);
          if (this.tableType === 'externalfixed') {
            this.externalCharLength.setValue(isEditMode && this.colDetails.externalCharacterLength ?
              this.colDetails.externalCharacterLength : 512);
          }
          break;
        case 'NAMEDROW':
          this.namedRowType.disable({ emitEvent: false });
          this.schemaService.getNamedRowType(this.session).subscribe(res => {
            this.namedRowTypes = res.namedRowType;
            if (this.objectKeys(this.namedRowTypes).length < 1) {
              this.namedRowType.disable({ emitEvent: false });
            } else {
              if (isEditMode) {
                this.namedRowType.setValue(this.colDetails.dataTypeAttributes.namedRowType, { emitEvent: false });
              } else {
                this.namedRowType.setValue(this.objectKeys(this.namedRowTypes)[0], { emitEvent: false });
              }
              this.namedRowType.enable({ emitEvent: false });
              this.namedRowType.setValidators([Validators.required]);
            }
          });
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL'];
            this.defaultValue.setValue('NULL', { emitEvent: false });
          } else {
            this.defaultValOptions = null;
          }
          break;
        case 'VARCHAR':
        case 'NVARCHAR':
          if (this.nullValues.value) {
            this.defaultValOptions = ['NULL', 'USER', 'DBSERVERNAME', 'SITENAME'];
            this.defaultValue.setValue('NULL', { emitEvent: false });
          } else {
            this.defaultValOptions = ['USER', 'DBSERVERNAME', 'SITENAME'];
          }
          this.length.setValue(isEditMode && this.colDetails.dataTypeAttributes.length ?
            this.colDetails.dataTypeAttributes.length : 255);
          this.length.setValidators([Validators.required, Validators.min(1), Validators.max(255)]);
          this.defaultValue.setValidators([this.charTypesValidator()]);
          if (this.tableType === 'externalfixed') {
            this.externalCharLength.setValue(isEditMode && this.colDetails.externalCharacterLength ?
              this.colDetails.externalCharacterLength : 255);
          }
          break;
      }
      this.defaultValue.setValue(isEditMode && this.colDetails.defaultValue ? this.colDetails.defaultValue : this.defaultValue.value);
      this.isNullCheck(this.nullValues.value);
    }
    this.updateForm(Object.keys(this.createColumnForm.controls));
  }

  getIntervals() {
    this.intervals = [];
    const largestUnit = this.largestUnit.indexOf(this.largestTimeUnit.value);
    const smallestUnit = this.largestUnit.indexOf(this.smallestTimeUnit.value);
    this.intervals = this.largestUnit.slice(largestUnit, smallestUnit + 1);
  }

  disableIntervals() {
    this.clearValues(['year', 'month', 'day', 'hour', 'minute', 'second', 'fraction']);
    this.disableControls(['year', 'month', 'day', 'hour', 'minute', 'second', 'fraction']);
    this.updateForm(['year', 'month', 'day', 'hour', 'minute', 'second', 'fraction']);
  }

  enableIntervals() {
    const controls = ['year', 'month', 'day', 'hour', 'minute', 'second', 'fraction'].filter(interval =>
      this.intervals.indexOf(interval.toUpperCase()) === -1);
    this.clearValues(controls);
    this.updateForm(controls);
    let maxValue = 0;
    for (let i = 0; i < this.precision.value; i++) {
      maxValue = (maxValue * 10) + 9;
    }
    this.intervals.forEach((interval, i) => {
      if (interval === 'YEAR') {
        if (i === 0) {
          if (this.colDetails && this.colDetails.dataTypeAttributes) {
            this.year.setValue(this.colDetails.dataTypeAttributes.defaultYearValue);
          }
          if (!this.year.value) {
            this.year.setValue(0);
          }
          this.year.setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
        }
      } else if (interval === 'MONTH') {
        if (i === 0) {
          this.month.setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
        } else {
          this.month.setValidators([Validators.required, Validators.min(0), Validators.max(11)]);
        }
        if (this.colDetails && this.colDetails.dataTypeAttributes) {
          this.month.setValue(this.colDetails.dataTypeAttributes.defaultMonthValue);
        }
        if (!this.month.value) {
          this.month.setValue(0);
        }
      } else if (interval === 'DAY') {
        if (i === 0) {
          this.day.setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
        }
        if (this.colDetails && this.colDetails.dataTypeAttributes) {
          this.day.setValue(this.colDetails.dataTypeAttributes.defaultDayValue);
        }
        if (!this.day.value) {
          this.day.setValue(0);
        }
      } else if (interval === 'HOUR') {
        if (i === 0) {
          this.hour.setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
        } else {
          this.hour.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
        }
        if (this.colDetails && this.colDetails.dataTypeAttributes) {
          this.hour.setValue(this.colDetails.dataTypeAttributes.defaultHourValue);
        }
        if (!this.hour.value) {
          this.hour.setValue(0);
        }
      } else if (interval === 'MINUTE') {
        if (i === 0) {
          this.minute.setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
        } else {
          this.minute.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
        }
        if (this.colDetails && this.colDetails.dataTypeAttributes) {
          this.minute.setValue(this.colDetails.dataTypeAttributes.defaultMinuteValue);
        }
        if (!this.minute.value) {
          this.minute.setValue(0);
        }
      } else if (interval === 'SECOND') {
        if (i === 0) {
          this.second.setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
        } else {
          this.second.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
        }
        if (this.colDetails && this.colDetails.dataTypeAttributes) {
          this.second.setValue(this.colDetails.dataTypeAttributes.defaultSecondValue);
        }
        if (!this.second.value) {
          this.second.setValue(0);
        }
      } else {
        let max = 0;
        for (let j = 0; j < this.scale.value; j++) {
          max = (max * 10) + 9;
        }
        this.fraction.setValidators([Validators.required, Validators.min(0), Validators.max(max)]);
        if (this.colDetails && this.colDetails.dataTypeAttributes) {
          this.fraction.setValue(this.colDetails.dataTypeAttributes.defaultFractionValue);
        }
        if (!this.fraction.value) {
          this.fraction.setValue(0);
        }
      }
      this.enableControls([interval.toLowerCase()]);
      this.updateForm([interval.toLowerCase()]);
    });
  }

  clearValues(controlList) {
    controlList.forEach(key => {
      this.createColumnForm.get(key).setValue(null, { emitEvent: false });
      this.createColumnForm.get(key).clearValidators();
    });
  }

  disableControls(controlList) {
    controlList.forEach(key => {
      this.createColumnForm.get(key).disable({ emitEvent: false });
    });
  }

  enableControls(controlList) {
    controlList.forEach(key => {
      this.createColumnForm.get(key).enable({ emitEvent: false });
    });
  }

  setColDetails() {
    if (this.colDetails) {
      this.tableColumnName.setValue(this.colDetails.tableColumnName);
      this.columnDataType.setValue(this.colDetails.columnDataType);
      this.defaultValue.setValue(this.colDetails.defaultValue);
      this.nullValues.setValue(this.colDetails.defaultNull);
      this.columnConstraint.setValue(this.colDetails.columnConstraint);
      this.constraintText.setValue(this.colDetails.constraintText);
    }
  }

  openColumnModal(index: any, tableType: string, tableAdvObj, obj?: any) {
    this.currIndex = index;
    this.tableType = tableType;
    this.tableAdvObj = tableAdvObj;
    if (obj) {
      this.isEdit = true;
      this.colDetails = obj;
      this.setColDetails();
    } else {
      if (tableType === 'standard' || tableType === 'raw') {
        this.colDataTypes = this.dataTypes.filter(data => data.type.includes('standard'));
      } else if (tableType === 'externalfixed' || tableType === 'externalinformix') {
        this.colDataTypes = this.dataTypes.filter(data => data.type.includes('fixed'));
      } else {
        this.colDataTypes = this.dataTypes.filter(data => data.type.includes('delimited'));
      }
      this.resetForm();
      this.isEdit = false;
    }

    this.addColumnModal.show();
  }

  addColumns() {
    const colObj = this.getColDetails();
    if (this.createColumnForm.valid) {
      if (!this.colDetails) {
        this.addCol.emit(colObj);
        this.translate.get('schemaManager.createTable.successMsg.columnAdded', { columnName: colObj.tableColumnName })
          .subscribe((text: string) => {
            this.notificationsService.pushSuccessNotification(text);
          });
        this.resetForm();
      } else if (this.colDetails) {
        const isConstraintColumn = this.tableAdvObj && ((this.tableAdvObj['primaryKey'] && this.tableAdvObj['primaryKey']
          .primaryKeyColumnName.indexOf(this.colDetails.tableColumnName) > -1) || (this.tableAdvObj['uniqueKey'] &&
            this.tableAdvObj['uniqueKey'].filter(key => key.uniquekeyColumnName.indexOf(this.colDetails.tableColumnName) > -1)
              .length > 0) || (this.tableAdvObj['foreignKey'] && this.tableAdvObj['foreignKey'].filter(key =>
                Object.values(key.foreignKeyReferences).indexOf(this.colDetails.tableColumnName) > -1).length > 0)) &&
          (colObj.columnDataType === 'blob' || colObj.columnDataType === 'clob' || colObj.columnDataType === 'text' ||
            colObj.columnDataType === 'byte' || colObj.columnDataType === 'set' || colObj.columnDataType === 'multiset' ||
            colObj.columnDataType === 'list' || colObj.columnDataType === 'namedRow' || colObj.length > 6144 ||
            (colObj.nullValues === true && ((this.tableAdvObj['primaryKey'] && this.tableAdvObj['primaryKey'].primaryKeyColumnName
              .indexOf(this.colDetails.tableColumnName) > -1) || (this.tableAdvObj['uniqueKey']) && this.tableAdvObj['uniqueKey']
                .filter(key => key.uniquekeyColumnName.indexOf(this.colDetails.tableColumnName) > -1).length > 0)));
        const isFragmentColumn = this.tableAdvObj && this.tableAdvObj['storageScheme'] && this.tableAdvObj['storageScheme']
          .fragmentKey === this.colDetails.tableColumnName && !(this.dateTypes.indexOf(this.colDetails.columnDataType
            .toLowerCase()) > -1 && this.dateTypes.indexOf(colObj.columnDataType.toLowerCase()) > -1) && !(this.numericTypes
              .indexOf(this.colDetails.columnDataType.toLowerCase()) > -1 && this.numericTypes.indexOf(colObj.columnDataType
                .toLowerCase()) > -1);
        let msg;
        if (isConstraintColumn || isFragmentColumn) {
          if (isConstraintColumn && isFragmentColumn) {
            msg = ' This action changes a column that is used as a fragment key and that is part of one or more primary, foreign, ' +
              'or unique key constraints so that its type is no longer valid for the specified fragmentation strategy and the column ' +
              'cannot be part of the primary, foreign, or unique key constraints. Saving the changes for the column also deletes the ' +
              'fragmentation strategy as well as primary, foreign, and unique key constraints that the column is part of. ' +
              'Column types BLOB, CLOB, TEXT, BYTE, SET, MULTISET, LIST or NAMED ROW, and character type columns that are longer ' +
              'than 6 KB cannot be part of a primary, foreign, or unique key.';
          } else if (isFragmentColumn) {
            msg = 'This action changes a column that is used as a fragment key so that its type is no longer valid for the specified ' +
              'fragmentation strategy. Saving the changes for the column also deletes the \
              fragmentation strategy that the column is part of.';
          } else if (isConstraintColumn) {
            msg = ' This action changes a column that is part of one or more primary, foreign, or unique key constraints so ' +
              'that the column cannot be part of the primary, foreign, or unique key constraints. Column types BLOB, CLOB, TEXT, ' +
              'BYTE, SET, MULTISET, LIST or NAMED ROW, and character type columns that are longer than 6 KB ' +
              'cannot be part of a primary, foreign, or unique key. Saving the changes for the column also deletes the ' +
              'primary, foreign, and unique key constraints that the column is part of.';
          }
          this.confirmationDialogService.show('Save Column',
            msg ? ('continue?' + msg) : 'continue?', () => {
              this.removeConstraint(isConstraintColumn, isFragmentColumn);
              this.editCol.emit({ obj: colObj, index: this.currIndex });
              this.closeModal();
              this.colDetails = null;
              this.translate.get('schemaManager.createTable.successMsg.columnUpdated', { columnName: colObj.tableColumnName })
                .subscribe((text: string) => {
                  this.notificationsService.pushSuccessNotification(text);
                });
            });
        } else {
          if (this.tableAdvObj) {
            this.updateConstraint(colObj);
          }
          this.editCol.emit({ obj: colObj, index: this.currIndex });
          this.closeModal();
          this.colDetails = null;
          this.translate.get('schemaManager.createTable.successMsg.columnUpdated', { columnName: colObj.tableColumnName })
            .subscribe((text: string) => {
              this.notificationsService.pushSuccessNotification(text);
            });
        }
      }
    }
  }

  updateConstraint(colObj) {
    if (this.tableAdvObj['primaryKey']) {
      const index = this.tableAdvObj['primaryKey'].primaryKeyColumnName.indexOf(this.colDetails.tableColumnName);
      if (index > -1) {
        this.tableAdvObj['primaryKey'].primaryKeyColumnName[index] = colObj.tableColumnName;
      }
    }
    if (this.tableAdvObj['uniqueKey'] && this.tableAdvObj['uniqueKey'].filter(key => key.uniquekeyColumnName
      .indexOf(this.colDetails.tableColumnName) > -1).length > 0) {
      this.tableAdvObj['uniqueKey'].forEach((key, i) => {
        const index = key.uniquekeyColumnName.indexOf(this.colDetails.tableColumnName);
        if (index > -1) {
          this.tableAdvObj['uniqueKey'][i].uniquekeyColumnName[index] = colObj.tableColumnName;
        }
      });
    }
    if (this.tableAdvObj['foreignKey'] && this.tableAdvObj['foreignKey'].filter(key => Object.values(key.foreignKeyReferences)
      .indexOf(this.colDetails.tableColumnName) > -1).length > 0) {
      this.tableAdvObj['foreignKey'].forEach((key, i) => {
        const index = Object.values(key.foreignKeyReferences).indexOf(this.colDetails.tableColumnName);
        if (index > -1) {
          this.tableAdvObj['foreignKey'][i].foreignKeyReferences[Object.keys(key.foreignKeyReferences)[index]] = colObj.tableColumnName;
        }
      });
    }
    if (this.tableAdvObj['storageScheme'] && this.tableAdvObj['storageScheme'].fragmentKey === this.colDetails.tableColumnName) {
      this.tableAdvObj['storageScheme'].fragmentKey = colObj.tableColumnName;
    }
  }

  removeConstraint(isConstraintColumn, isFragmentColumn) {
    if (isConstraintColumn) {
      if (this.tableAdvObj['primaryKey'] && this.tableAdvObj['primaryKey'].primaryKeyColumnName
        .indexOf(this.colDetails.tableColumnName) > -1) {
          delete this.tableAdvObj['primaryKey'];
        }
      if (this.tableAdvObj['uniqueKey'] && this.tableAdvObj['uniqueKey'].filter(key => key.uniquekeyColumnName
        .indexOf(this.colDetails.tableColumnName) > -1).length > 0) {
        this.tableAdvObj['uniqueKey'].forEach((key, i) => {
          if (key.uniquekeyColumnName.indexOf(this.colDetails.tableColumnName) > -1) {
           this.tableAdvObj['uniqueKey'].splice(i, 1);
          }
        });
        if (this.tableAdvObj['uniqueKey'].length === 0) {
          delete this.tableAdvObj['uniqueKey'];
        }
      }
      if (this.tableAdvObj['foreignKey'] && this.tableAdvObj['foreignKey'].filter(key => Object.values(key.foreignKeyReferences)
        .indexOf(this.colDetails.tableColumnName) > -1).length > 0) {
        this.tableAdvObj['foreignKey'].forEach((key, i) => {
          if (Object.values(key.foreignKeyReferences).indexOf(this.colDetails.tableColumnName) > -1) {
            this.tableAdvObj['foreignKey'].splice(i, 1);
          }
        });
        if (this.tableAdvObj['foreignKey'].length === 0) {
 delete this.tableAdvObj['foreignKey'];
}
      }
    }
    if (isFragmentColumn) {
      delete this.tableAdvObj['storageScheme'];
    }
  }

  getColDetails() {
    const obj = {
      tableColumnName: this.tableColumnName.value.trim(),
      columnDataType: this.columnDataType.value,
      defaultValue: this.defaultValue.value,
      nullValues: this.nullValues.value,
      columnConstraint: this.columnConstraint.value,
      constraintText: this.constraintText.value,
      precisionDigit: this.precision.value,
      scale: this.scale.value,
      extentSize: this.extentSize.value,
      turnOnLogging: this.turnOnLogging.value,
      recordAccessTime: this.recordAccessTime.value,
      highDataIntegrity: this.highDataIntegrity.value,
      startValue: this.startValue.value,
      length: this.length.value,
      largestTimeUnit: this.largestTimeUnit.value,
      smallestTimeUnit: this.smallestTimeUnit.value,
      namedRowType: this.namedRowType.value,
      namedRowDataLength: this.namedRowType.value ? this.namedRowTypes[this.namedRowType.value] : null,
      externalCharacterLength: this.externalCharLength.value,
      setDataType: this.setDataType.value,
      defaultYearValue: this.year.value,
      defaultMonthValue: this.month.value,
      defaultDayValue: this.day.value,
      defaultHourValue: this.hour.value,
      defaultMinuteValue: this.minute.value,
      defaultSecondValue: this.second.value,
      defaultFractionValue: this.fraction.value,
      noDefaultValue: this.noDefaultValue.value,
      nullAsDefault: this.nullAsDefault.value
    };
    if (this.columnDataType.value === 'byte' || this.columnDataType.value === 'text') {
      obj['storeColumnIn'] = this.storeColumnIn.value === 'table' ? this.storeColumnIn.value : this.selectedSpace.value;
    } else if (this.currentDataType === 'blob' || this.currentDataType === 'clob') {
      const spaceNames = this.requiredSpaces.filter(space => space && space.selected).map(value => value.name);
      obj['storeColumnIn'] = spaceNames.length > 0 ? spaceNames.toString() : null;
    }

    return obj;
  }

  isNullCheck(value) {
    if (value && this.defaultValOptions) {
      const index = this.defaultValOptions.indexOf('NULL');
      if (index === -1) {
        this.defaultValOptions.unshift('NULL');
      }
    } else if (this.defaultValOptions) {
      const index = this.defaultValOptions.indexOf('NULL');
      if (index > -1) {
        this.defaultValOptions.splice(index, 1);
      }
      if (this.defaultValue.value === 'NULL') {
        if (this.optionsWithStrValue.indexOf(this.currentDataType) > -1 || this.optionsWithNumVal.indexOf(this.currentDataType) > -1) {
          this.defaultValue.setValue(null);
        } else {
          this.defaultValue.setValue(this.defaultValOptions[0]);
        }
      }
    }
    if (this.columnDataType.value === 'interval') {
      if (value === false) {
        if (this.noDefaultValue.disabled) {
          this.noDefaultValue.setValue(true);
        }
        this.nullAsDefault.setValue(false);
        this.nullAsDefault.disable({ emitEvent: false });
      } else {
        if (value && !this.noDefaultValue.value) {
          this.nullAsDefault.enable({ emitEvent: false });
        }
      }
    }
    this.defaultValue.updateValueAndValidity();
  }

  closeModal() {
    this.addColumnModal.hide();
  }

  resetForm() {
    if (this.tableType === 'standard' || this.tableType === 'raw') {
      this.createColumnForm.reset({ columnDataType: 'char', length: 32, nullValues: true });
    } else if (this.tableType === 'externalfixed') {
      this.createColumnForm.reset({ columnDataType: 'char', length: 32, externalCharLength: 32 });
    } else {
      this.createColumnForm.reset({ columnDataType: 'char', length: 32 });
    }
    this.currentDataType = this.columnDataType.value;
  }

  removeValidators() {
    for (const key in this.createColumnForm.controls) {
      if (this.createColumnForm.get(key)) {
        const control = this.createColumnForm.get(key);
        if (key !== 'columnDataType' && key !== 'nullValues' && key !== 'columnConstraint' && key !== 'constraintText'
          && key !== 'externalCharLength' && key !== 'setDataType' && key !== 'tableColumnName') {
          control.setValue(null, { emitEvent: false });
        }
        control.clearValidators();
        control.enable({ emitEvent: false });
        control.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  updateForm(formControls) {
    formControls.forEach(key => {
      this.createColumnForm.get(key).updateValueAndValidity({ emitEvent: false });
    });
  }

  onSpaceSelect(event: boolean, space) {
    space.selected = event;
    if (this.requiredSpaces.filter(value => value.selected).length < 1) {
      this.extentSize.setValue(null);
      this.turnOnLogging.setValue(null);
      this.recordAccessTime.setValue(null);
      this.highDataIntegrity.setValue(null);
      this.extentSize.disable({ emitEvent: false });
      this.turnOnLogging.disable({ emitEvent: false });
      this.recordAccessTime.disable({ emitEvent: false });
      this.highDataIntegrity.disable({ emitEvent: false });
    } else {
      this.extentSize.enable({ emitEvent: false });
      this.turnOnLogging.enable({ emitEvent: false });
      this.recordAccessTime.enable({ emitEvent: false });
      this.highDataIntegrity.enable({ emitEvent: false });
    }
    this.extentSize.updateValueAndValidity({ emitEvent: false });
    this.turnOnLogging.updateValueAndValidity({ emitEvent: false });
    this.recordAccessTime.updateValueAndValidity({ emitEvent: false });
    this.highDataIntegrity.updateValueAndValidity({ emitEvent: false });
  }

  charTypesValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.defaultValue.value && this.defaultValue.value.length > this.length.value) {
        isValid = false;
      } else {
        isValid = true;
      }
      return isValid ? null : { lengthError: true };
    };
  }

  duplicateColumnNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.columnNames && this.columnNames.indexOf(control.value) > -1) {
        if (this.colDetails && control.value === this.colDetails.tableColumnName) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

  onModalShown() {
    this.columnNameInput.nativeElement.focus();
  }

  onModalClose() {
    this.removeValidators();
    this.isEdit = false;
    this.colDetails = null;
  }
}
