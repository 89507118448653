/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SystemConfigService {

  constructor(private httpClient: HttpClient) { }

  public getConfigs(): Promise<any> {
    return this.httpClient.get<any>('config').toPromise<any>();
  }

  public getConfig(type: string): Promise<any> {
    return this.httpClient.get<any>('config/' + type).toPromise<any>();
  }

  public saveConfig(type: string, config: any): Promise<any> {
    return this.httpClient.put<any>('config/' + type, config).toPromise<any>();
  }

  public testAlertConfig(type: string, settings: any): Promise<any> {
    return this.httpClient.post<any>('config/' + type + '/test', settings).toPromise<any>();
  }
}
