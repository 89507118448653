/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/

export const serverStatusList = {
  OFF: 'danger', ON: 'success', CONNECTING: 'warning', DRAINING: 'danger',
  FAILED: 'danger', 'FAIL RECOVERY': 'danger', PROMOTION: 'warning',
  UNKNOWN: 'warning', DEFINED: 'warning', ACTIVE: 'success', INACTIVE: 'danger',
  SHUTDOWN: 'danger', 'ALTERNATE COMMUNICATION': 'warning', '????': 'warning'
};

export class ServerInfo {
  name: string;
  id?: number;
  idException?: string;
  type: string;
  status: string;
  connectionStatus?: string;
  updatable?: string;
  workload?: number;
  lagtime?: number;
  approxLogBacklog?: number;
  selected?: boolean;
  constructor(serverInfo: any) {
    this.name = serverInfo.name;
    this.id = serverInfo.id;
    this.idException = serverInfo.idException;
    this.type = serverInfo.type.toUpperCase();
    if (serverInfo.status.toUpperCase() === 'ON') {
      this.status = 'Active';
    } else if (serverInfo.status.toUpperCase() === 'OFF') {
      this.status = 'Inactive';
    } else {
      this.status = serverInfo.status;
    }
    this.connectionStatus = serverInfo.connectionStatus;
    this.updatable = serverInfo.updatable;
    this.workload = serverInfo.workload;
    this.lagtime = serverInfo.lagtime;
    this.approxLogBacklog = serverInfo.approxLogBacklog;
    this.selected = false;
  }
}

export class ClusterInfo {
  haName: string;
  primaryNotPresent?: string;
  exceptionMessage?: string;
  clusterNodeList: Array<ServerInfo>;
  constructor(clusterInfo: any) {
    this.haName = clusterInfo.haName;
    this.primaryNotPresent = clusterInfo.primaryNotPresent;
    this.exceptionMessage = clusterInfo.exceptionMessage;
    if (clusterInfo.clusterNodeList) {
      this.clusterNodeList = clusterInfo.clusterNodeList.map(serverInfo => new ServerInfo(serverInfo));
    }
  }
}

export class SMXInfo {
  name: string;
  type: string;
  numberOfNetworkPipes: number;
  encryptionStatus: string;
  replicationOnly?: boolean;
  compressionStatus: string;
  bytesSent: number;
  bytesReceived: number;
  retriesForWriteCall: number;
  constructor(smxInfo: any) {
    this.name = smxInfo.name;
    this.type = smxInfo.type;
    this.numberOfNetworkPipes = smxInfo.numberOfNetworkPipes;
    this.replicationOnly = smxInfo.replicationOnly;
    this.encryptionStatus = smxInfo.encryptionStatus;
    this.compressionStatus = smxInfo.compressionStatus;
    this.bytesSent = smxInfo.bytesSent;
    this.bytesReceived = smxInfo.bytesReceived;
    this.retriesForWriteCall = smxInfo.retriesForWriteCall;
  }
}

export class ClusterSMXInfo {
  primaryNotPresent?: string;
  exceptionMessage?: string;
  smxInfoList: Array<SMXInfo>;
  constructor(clusterSMXInfo: any) {
    this.primaryNotPresent = clusterSMXInfo.primaryNotPresent;
    this.exceptionMessage = clusterSMXInfo.exceptionMessage;
    if (clusterSMXInfo.smxInfoList) {
      this.smxInfoList = clusterSMXInfo.smxInfoList.map(smxInfo => new SMXInfo(smxInfo));
    }
  }
}

export class ClusterStatusInfo {
  activeConnectionManagers?: string;
  failoverArbitration?: string;
  lastLogPageApplied?: string;
  lastLogPageAcked?: string;
  lastLogPageSent?: string;
  lastLogPageFlushed?: string;
  nextLogPageToSend?: string;
  flowControl?: string;
  logTransmissionStatus?: string;
  delayApplyStatistics?: string;
  stopApplyStatistics?: string;
  transactionLatency?: string;
  sumOfWorkReplay?: number;
  applyRate?: number;
  backlog?: string;
  catchupBacklog?: string;
  lastLogPageReceived?: string;
  sequenceNoLastBufferReceived?: string;
  sequenceNoLastBufferAcked?: string;
  nextLogPageRead?: string;
  lastLSNAcked?: string;
  currentPagingFileSize?: string;
  oldPagingFileSize?: string;

  constructor(clusterStatusInfo: any) {
    this.activeConnectionManagers = clusterStatusInfo.activeConnectionManagers;
    this.failoverArbitration = clusterStatusInfo.failoverArbitration;
    this.lastLogPageApplied = clusterStatusInfo.lastLogPageApplied;
    this.lastLogPageAcked = clusterStatusInfo.lastLogPageAcked;
    this.lastLogPageSent = clusterStatusInfo.lastLogPageSent;
    this.lastLogPageFlushed = clusterStatusInfo.lastLogPageFlushed;
    this.nextLogPageToSend = clusterStatusInfo.nextLogPageToSend;
    this.flowControl = clusterStatusInfo.flowControl;
    this.logTransmissionStatus = clusterStatusInfo.logTransmissionStatus;
    this.delayApplyStatistics = clusterStatusInfo.delayApplyStatistics;
    this.stopApplyStatistics = clusterStatusInfo.stopApplyStatistics;
    this.transactionLatency = clusterStatusInfo.transactionLatency;
    this.sumOfWorkReplay = clusterStatusInfo.sumOfWorkReplay ? clusterStatusInfo.sumOfWorkReplay : 0;
    this.backlog = clusterStatusInfo.backlog;
    this.catchupBacklog = clusterStatusInfo.catchupBacklog;
    this.lastLogPageReceived = clusterStatusInfo.lastLogPageReceived;
    this.sequenceNoLastBufferReceived = clusterStatusInfo.sequenceNoLastBufferReceived;
    this.sequenceNoLastBufferAcked = clusterStatusInfo.sequenceNoLastBufferAcked;
    this.nextLogPageRead = clusterStatusInfo.nextLogPageRead;
    this.lastLSNAcked = clusterStatusInfo.lastLSNAcked;
    this.currentPagingFileSize = clusterStatusInfo.currentPagingFileSize;
    this.oldPagingFileSize = clusterStatusInfo.oldPagingFileSize;
  }
}
