/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { NotificationsService } from '../notifications/notifications.service';

// Handle 1 unauthorized request per second, to avoid running the handler multiple times in case of request bursts
const UNAUTHORIZED_HANDLER_COOLDOWN = 1000;

@Injectable()
export class MainInterceptor implements HttpInterceptor {

  private lastUnauthorizedResponseTimestamp: number = null;

  constructor(
    private userService: UserService,
    private router: Router,
    private notifications: NotificationsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('i18n')) {
      return next.handle(this.createUncachedRequest(req));
    }

    const user = this.userService.getCurrentUserDirty();
    let headers = req.headers;
    if (user && user.csrfToken) {
      headers = headers.set('X-Token', user.csrfToken);
    }

    const apiReq = req.clone({
      url: '/api/' + req.url,
      withCredentials: true,
      headers
    });
    return next.handle(apiReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && !error.url.endsWith('/api/users/current')) {
        const now = new Date().getTime();
        if (!this.lastUnauthorizedResponseTimestamp || now - this.lastUnauthorizedResponseTimestamp >= UNAUTHORIZED_HANDLER_COOLDOWN) {
          this.lastUnauthorizedResponseTimestamp = now;
          if (this.router.routerState.snapshot.url && this.router.routerState.snapshot.url !== '/login') {
            this.userService.setLoginRedirectUrl(this.router.routerState.snapshot.url);
          }
          this.userService.timeOutCurrentUser();

          // Schedule navigation for next tick, in case the router is busy navigating (e.g. in the middle of a route switch)
          setTimeout(() => {
            this.router.navigate(['/login']).catch(() => false).then(result => {
              // If navigation fails for whatever reason, try to notify the user as a fallback
              if (!result) {
                this.notifications.pushErrorNotification('Your session timed out. Please refresh the page and log back in.');
              }
            });
          }, 0);
        }
      }
      throw error;
    }));
  }

  private createUncachedRequest(req: HttpRequest<any>) {
    let headers = req.headers;
    headers = headers.set('Cache-Control', 'no-cache, no-store');
    headers = headers.set('Pragma', 'no-cache');

    return req.clone({ headers });
  }
}
