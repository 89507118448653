/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subscription } from 'rxjs';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { InformixServer } from '../../../servers/informixServer';
import { InformixServerService } from '../../../servers/informixServer.service';
import { InformixDatabase } from '../../../servers/schema/informix-database';
import { InformixSQLSession } from '../../../servers/schema/informix-sql-session';
import { Query } from '../../../servers/schema/query';
import { QueryResultPage, QueryResultRow } from '../../../servers/schema/query-results';
import { SchemaService } from '../../../servers/schema/schema.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-create-sensor',
  templateUrl: './create-sensor.component.html',
  styleUrls: ['./create-sensor.component.scss']
})
export class CreateSensorComponent implements OnInit, OnDestroy {

  selectServerModal: BsModalRef = null;

  server: InformixServer = null;
  session: InformixSQLSession = null;
  sessionSub: Subscription = null;
  sessionError: string = null;
  productNameNoSpace = environment.productNameNoSpace;

  queryFormControl = new UntypedFormControl('');
  query: Query = null;

  formGroup = new UntypedFormGroup({
    transposeName: new UntypedFormControl(null),
    primaryKey: new UntypedFormControl(null),
    primaryKeyType: new UntypedFormControl(null)
  });

  querySub: Subscription = null;
  queryError: string = null;
  queryResult: QueryResultPage = null;
  transposedResult: QueryResultPage = null;

  primaryKeyTypeOptions: string[] = null;

  metricsFormGroup: UntypedFormGroup = null;
  metricForms: UntypedFormGroup[] = null;
  filterMetricIndex = -1;
  metricUnits: string[] = ['bytes', 'percentage'];

  sensorTabVisited = false;
  sensorFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[a-z0-9]+(_[a-z0-9]+)*$/)]),
    name: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(''),
    runInterval: new UntypedFormControl(60000, Validators.required),
    dataRetentionInterval: new UntypedFormControl(30, Validators.required)
  });

  sensorJson: any = null;
  createSensorSub: Subscription = null;
  createSensorError: string = null;

  sensorIdValidationErrors: any = {
    pattern: 'ID can only contain lowercase letters, digits, and single underscores. '
      + 'Cannot start or end with an underscore. (e.g. my_custom_sensor)'
  };

  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  @ViewChild('selectServerModal') selectServerModalTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serverService: InformixServerService,
    private schemaService: SchemaService,
    private monitoringService: MonitoringService,
    private modalService: BsModalService,
    private notifications: NotificationsService,
    private confirmationDialog: ConfirmationDialogService
  ) { }

  ngOnInit() {
    this.formGroup.controls.transposeName.valueChanges.subscribe(value => {
      this.transposedResult = value ? this.transposeResult(value) : null;
      this.formGroup.controls.primaryKey.setValue(null);
    });

    this.formGroup.controls.primaryKey.valueChanges.subscribe(value => {
      if (value) {
        this.primaryKeyTypeOptions = ['string'];
        if (this.isColumnNumeric(value)) {
          this.primaryKeyTypeOptions.unshift('numeric');
        }
        this.formGroup.controls.primaryKeyType.setValue(this.primaryKeyTypeOptions[0]);
      } else {
        this.primaryKeyTypeOptions = null;
        this.formGroup.controls.primaryKeyType.setValue(null);
      }
      this.createMetricsFormGroup();
    });

    if (this.route.snapshot.queryParams.server) {
      const serverId = parseInt(this.route.snapshot.queryParams.server, 10);
      if (!isNaN(serverId) && serverId > 0) {
        this.serverService.getServer(serverId.toString()).then(server => {
          this.selectServer(server);
        }, () => {
          // Not important if this fails.
          this.router.navigate([], { queryParams: { server: null }, queryParamsHandling: 'merge' });
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.sessionSub) {
      this.sessionSub.unsubscribe();
    }

    if (this.querySub) {
      this.querySub.unsubscribe();
    }

    if (this.createSensorSub) {
      this.createSensorSub.unsubscribe();
    }
  }

  showSelectServerModal() {
    if (this.selectServerModalTemplate) {
      this.selectServerModal = this.modalService.show(this.selectServerModalTemplate);
    }
  }

  selectServer(server: InformixServer) {
    if (this.selectServerModal) {
      this.selectServerModal.hide();
      this.selectServerModal = null;
    }

    this.server = server;
    this.router.navigate([], { queryParams: { server: server.id }, queryParamsHandling: 'merge' });

    this.sessionError = null;

    const database = new InformixDatabase(this.server, { name: 'sysmaster' });
    this.sessionSub = this.schemaService.createSession(database, 'monitor').subscribe(session => {
      this.session = session;
      if (this.query) {
        this.runQuery(this.query.query);
      } else {
        this.clearQueryResult();
      }
    }, err => {
      this.clearQueryResult();
      this.sessionError = 'Failed to connect to the server. ';
      this.sessionError += (err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred.';
      console.error(err);
    }).add(() => {
      this.sessionSub = null;
    });
  }

  onQueryKeyDown(event: any) {
    if (event.ctrlKey && event.keyCode === 13) { // Ctrl+Enter
      this.onRunClick();
    }
  }

  onRunClick() {
    if (this.querySub) {
      return;
    }

    this.clearQueryResult();
    let queryString: string = this.queryFormControl.value.trim();
    if (!queryString) {
      this.queryError = 'Please enter a SQL statement';
      return;
    }

    queryString = queryString.replace(/\n+|(\r\n)+/g, ' ').trim();
    this.runQuery(queryString);
  }

  private runQuery(sql: string) {
    this.clearQueryResult();
    this.query = new Query(this.schemaService, this.session, sql);

    this.formGroup.patchValue({
      transposeName: null,
      primaryKey: null
    });

    this.querySub = this.query.next().subscribe(result => {
      const results = result.output[0];
      if(!results.isSuccess) {
        this.queryError = results.err;
      } else if (typeof result === 'string' || (results.rows && results.rows.length < 1)) {
        this.queryError = 'The SQL statement must return at least one row of data';
      } else {
        this.queryResult = results;
        if (this.queryResult.columnNames.length === 2) {
          this.formGroup.controls.transposeName.enable();
        } else {
          this.formGroup.controls.transposeName.disable();
        }
        this.createMetricsFormGroup();
      }
    }, err => {
      this.queryError = (err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred.';
      this.query = null;
    }).add(() => {
      this.querySub = null;
    });
  }

  private clearQueryResult() {
    this.query = null;
    this.queryResult = null;
    this.transposedResult = null;
    this.queryError = null;
  }

  private transposeResult(columnName: string): QueryResultPage {
    const columnIndex = this.queryResult.columnNames.indexOf(columnName);
    if (columnIndex < 0) {
      return null;
    }

    const transposedColumnSet = new Set<string>();
    const uniqueRows: QueryResultRow[] = [];
    this.queryResult.rows.forEach(row => {
      const value = row.values[columnIndex].value;
      if (typeof value === 'number' || typeof value === 'string') {
        const stringValue = value.toString();
        if (!transposedColumnSet.has(stringValue)) {
          transposedColumnSet.add(stringValue);
          uniqueRows.push(row);
        }
      }
    });

    const transposedResult = new QueryResultPage();
    transposedResult.columnNames = Array.from(transposedColumnSet);
    this.queryResult.columnNames.forEach((column, i) => {
      if (i === columnIndex) {
        return;
      }

      const transposedRow = new QueryResultRow();
      uniqueRows.forEach(row => {
        transposedRow.values.push(row.values[i]);
      });
      transposedResult.rows.push(transposedRow);
    });

    return transposedResult;
  }

  getDisplayedQueryResult() {
    return this.transposedResult || this.queryResult;
  }

  goToNextTab() {
    for (let i = 1; i < this.tabset.tabs.length; i++) {
      if (this.tabset.tabs[i - 1].active && !this.tabset.tabs[i].disabled) {
        this.tabset.tabs[i].active = true;
        break;
      }
    }
  }

  createMetricsFormGroup() {
    if (!this.queryResult) {
      return;
    }

    this.filterMetricIndex = -1;
    this.metricForms = [];
    const metricsFormGroup = new UntypedFormGroup({});
    this.getDisplayedQueryResult().columnNames.forEach(columnName => {
      if (columnName === this.formGroup.value.primaryKey) {
        return;
      }

      const metricForm = new UntypedFormGroup({
        id: new UntypedFormControl(columnName),
        name: new UntypedFormControl(columnName, Validators.required),
        unit: new UntypedFormControl(null),
        delta: new UntypedFormControl(false),
        unique: new UntypedFormControl(false),
        default: new UntypedFormControl('')
      });

      this.metricForms.push(metricForm);
      metricsFormGroup.addControl(columnName, metricForm);
    });

    if (this.metricsFormGroup) {
      metricsFormGroup.patchValue(this.metricsFormGroup.value);
    }
    this.metricsFormGroup = metricsFormGroup;
  }

  isPreviewDisabled() {
    return !this.query || !this.queryResult || !this.metricsFormGroup || this.metricForms.length < 1 || this.metricsFormGroup.invalid
      || this.sensorFormGroup.invalid;
  }

  onPreviewTabSelect(event: any) {
    if (!(event instanceof TabDirective) || this.isPreviewDisabled()) {
      return;
    }

    this.sensorJson = this.buildSensorJson();
  }

  private buildSensorJson() {
    if (this.isPreviewDisabled()) {
      return null;
    }

    const sensorJson: any = {
      id: this.sensorFormGroup.value.id.trim(),
      name: this.sensorFormGroup.value.name.trim(),
      description: this.sensorFormGroup.value.description.trim(),
      meta: {
        default: {
          type: 'sql',
          sql: this.query.query,
          sleepBetweenExecution: Math.floor(this.sensorFormGroup.value.runInterval / 1000),
          dataRetentionInterval: this.sensorFormGroup.value.dataRetentionInterval
        },
        metrics: {}
      }
    };

    if (this.formGroup.value.transposeName) {
      const transposeName = this.formGroup.value.transposeName;
      const transposeValue = this.queryResult.columnNames.find(v => v !== transposeName);
      sensorJson.meta.default.collapse = {
        key: transposeName,
        value: transposeValue
      };
    }

    if (this.formGroup.value.primaryKey) {
      sensorJson.meta.default.primaryKey = {
        name: this.formGroup.value.primaryKey,
        type: this.formGroup.value.primaryKeyType === 'string' ? 'string' : 'long'
      };
    }

    let hasDefaults = false;
    const defaults: any = {};
    const deltas: string[] = [];

    this.metricForms.forEach(metricForm => {
      const f = metricForm.value;
      const metricJson: any = {
        name: f.name
      };
      if (f.unit) {
        metricJson.unit = f.unit;
      }
      sensorJson.meta.metrics[f.id] = metricJson;

      if (f.default) {
        const defaultValue = this.getNumberOrString(f.default);
        if (defaultValue !== null) {
          defaults[f.id] = defaultValue;
          hasDefaults = true;
        }
      }

      if (f.delta) {
        deltas.push(f.id);
      }
    });

    if (hasDefaults) {
      sensorJson.meta.default.defaults = defaults;
    }

    if (deltas.length > 0) {
      sensorJson.meta.default.delta = deltas;
      sensorJson.meta.default.deltaAllowNegative = true;
    }

    return sensorJson;
  }

  private isColumnNumeric(columnName: string): boolean {
    const columnIndex = this.getDisplayedQueryResult().columnNames.indexOf(columnName);
    if (columnIndex < 0) {
      return false;
    }

    return !this.getDisplayedQueryResult().rows.find(
      row => (typeof this.getNumberOrString(row.values[columnIndex].value)) === 'string');
  }

  private getNumberOrString(value: any): number | string {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      const numberValue = parseInt(value, 10);
      if (!isNaN(numberValue)) {
        return numberValue;
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  createSensorType() {
    const sensorJson = this.sensorJson || this.buildSensorJson();

    if (!sensorJson) {
      return;
    }

    this.createSensorError = null;

    this.confirmationDialog.show('save this sensor? Once saved, a sensor can no longer be modified.', () => {
      this.createSensorSub = this.monitoringService.createSensorType(sensorJson).subscribe(type => {
        this.notifications.pushSuccessNotification('Sensor saved.');

        let routerCommands: any[] = ['..'];
        if (this.server && this.route.snapshot.queryParams.source === 'monitoring') {
          routerCommands = ['/dashboard', 'servers', this.server.id, 'monitoring'];
        }
        this.router.navigate(routerCommands, { relativeTo: this.route });
      }, err => {
        this.createSensorError = 'Failed to save sensor. ';
        this.createSensorError += (err instanceof HttpErrorResponse && err.error.err) ? err.error.err : 'An unknown error occurred.';
        console.error(err);
      }).add(() => this.createSensorSub = null);
    });
  }
}
