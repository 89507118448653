<div class="row">
  <div class="d-flex justify-content-end col-12">
    <button type="button" class="btn btn-icon btn-sm" *ngIf="table.type!=='E' && table.type!=='V'"
      [disabled]="!indexSelected" (click)="deleteIndexes()" tooltip="Delete">
      <i class="icon icon-trash"></i>
    </button>
    <button type="button" class="btn btn-primary" (click)="openCreateIndex.emit()"
      *ngIf="table.type!=='E' && table.type!=='V'"><i class="icon icon-plus pr-1"></i>Create</button>
  </div>
</div>
<ng-container *ngIf="tableDetails.indexes && tableDetails.indexes.length > 0; else noDataTemplate">
  <app-data-table [data]="tableDetails.indexes" sortBy="name" [enableSelect]="true"
    [searchLabel]="'schemaManager.tableInfo.table.searchLabel.indexes' | translate"
    (rowSelected)="onIndexSelected($event)" [dataMapFn]="indexesDataMap">
    <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true"></app-data-table-column>
    <app-data-table-column title="Type" property="type" [sortable]="true" [searchable]="true"></app-data-table-column>
    <app-data-table-column title="Columns" property="columnNames" [searchable]="true"></app-data-table-column>
    <app-data-table-column title="Levels" property="levels">
      <ng-template appTableCell let-value="item">
        {{value === null ? '-' : value}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Leaves" property="leaves">
      <ng-template appTableCell let-value="item">
        {{value === null ? '-' : value}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Unique keys" property="uniqueKeys">
      <ng-template appTableCell let-value="item">
        {{value === null ? '-' : value}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Clusters" property="clusters">
      <ng-template appTableCell let-value="item">
        {{value === null ? '-' : value}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Filtering" property="filtering">
      <ng-template appTableCell let-filtering="item">
        <app-status-icon [status]="filtering"></app-status-icon>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="With Errors" property="filterErrorsEnabled">
      <ng-template appTableCell let-filterErrorsEnabled="item">
        <app-status-icon [status]="filterErrorsEnabled"></app-status-icon>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column property="enabled">
      <ng-template appTableCell let-row="row" let-enabled="item">
        <button type="button" class="btn btn-icon btn-sm" *ngIf="enabled" tooltip="Index is Enabled. Click to Disable."
          (click)="enableDisableIndex('disable', row)" [disabled]="row.isDisabled">
          <i class="icon icon-check"></i>
        </button>
        <button type="button" class="btn btn-icon btn-sm" *ngIf="!enabled" tooltip="Index is Disabled. Click to Enable."
          (click)="enableDisableIndex('enable', row)" [disabled]="row.isDisabled">
          <i class="icon icon-ban"></i>
        </button>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column>
      <ng-template appTableCell let-row="row">
        <button type="button" class="btn btn-icon btn-sm" (click)="deleteIndexes(row)" tooltip="Delete" [disabled]="row.isDisabled">
          <i class="icon icon-trash"></i>
        </button>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</ng-container>
<ng-template #noDataTemplate>
  <div class="alert alert-info mb-0">
    {{table.name}} has no Indexes.
  </div>
</ng-template>
