/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AbstractSettingsComponent } from './abstractSettings.component';

@Component({
  selector: 'app-user-email-settings',
  template: `
  <div class="row form-group">
    <label class="col-sm-2 col-form-label">Email</label>
    <div class="col-sm-6">
    <input type="text" class="form-control form-control-sm" [formControl]="emailControl" tooltip="" placement="right"
      appExtendedFormControl>
    </div>
  </div>
  `
})
export class UserEmailSettingsComponent extends AbstractSettingsComponent implements OnChanges {

  emailControl: UntypedFormControl;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.settings) {
      this.settings = { email: null };
    }

    if (!this.emailControl) {
      this.emailControl = new UntypedFormControl(null, [Validators.required]);

      this.emailControl.valueChanges.subscribe(() => {
        this.eventChange.emit({
          valid: this.emailControl.valid,
          modified: this.emailControl.value !== this.settings.email
        });
      });
    }

    this.emailControl.setValue(this.settings.email);
  }

  public getSettings(): any {
    return { email: this.emailControl.value };
  }

  public requiresSettings(): boolean {
    return true;
  }

  public isValid(): boolean {
    return this.emailControl && this.emailControl.valid;
  }
}
