<form role="form" [formGroup]="formGroup">
  <h5 *ngIf="_selectedSpace == null" class="mb-4">Add Chunk</h5>
  <div *ngIf="_selectedSpace == null" class="form-group mb-2">
    <label><strong>Select the space to add the chunk to:</strong></label>
    <select formControlName="spaceSelect" class="form-control col-sm-3" appExtendedFormControl>
      <option *ngFor="let space of _spacesList" [ngValue]="space">{{space.name}}</option>
    </select>
  </div>
  <div class="form-group mb-2" [ngClass]="{'ml-4': _selectedSpace != null}">
    <label><strong>Location</strong></label>
    <app-radio-group class="col-10" formControlName="locationOption">
      <div class="row align-items-center">
        <div class="col-auto">
          <app-radio value="path" class="ml-3">
            Specify the path
          </app-radio>
        </div>
        <div class="col">
          <input formControlName="path" class="form-control col-sm-6" type="text" tooltip="" appExtendedFormControl>
        </div>
      </div>

      <app-radio class="mt-2 ml-3" value="pool" [tooltip]="hasStoragePoolEntries ? '' : 'There are no active storage pool entries on the server.'"
        [disabled]="!hasStoragePoolEntries">
        Create the space from the storage pool
      </app-radio>
    </app-radio-group>
  </div>
  <div class="form-group mb-2" [ngClass]="{'ml-4': _selectedSpace != null}">
    <label><strong>Size</strong></label>
    <app-input-size formControlName="size" min="1" tooltip="" appExtendedFormControl></app-input-size>
  </div>
  <div class="form-group mb-2" [ngClass]="{'ml-4': _selectedSpace != null}">
    <label><strong>Offset</strong></label>
    <app-input-size formControlName="offset" min="0" tooltip="" appExtendedFormControl></app-input-size>
  </div>
  <div class="form-group" [ngClass]="{'d-flex flex-row-reverse': _selectedSpace != null}">
    <div class="mt-5" [ngClass]="{'d-flex justify-content-end': _selectedSpace != null}">
      <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="doAddChunk()" [disabled]="!formGroup.valid">Create</button>
    </div>
  </div>
</form>
