<ng-container *ngIf="!isViewQuery">
  <div class="row">
    <div class="col-10">
      <h5> <strong>{{'schemaManager.createTable.title.externalOptions' | translate}} </strong> </h5>
    </div>
    <div class="col-2">
      <button type="button" class="close">
        <i class="icon icon-times font-weight-bold" (click)="backToCreateTable.emit('cancel')"></i></button>
    </div>
  </div>

  <!-- Advance Options Form for External table type -->

  <form [formGroup]="advTableForm">
    <div *ngIf="dataObj['tableType'] === 'externaldelimited'">
      <div class="row">
        <div class="col-auto">
          <label class="font-weight-bold pt-3 mb-0">{{'schemaManager.createTable.label.fileOptions' |
            translate}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-auto pt-2">
          <label>{{'schemaManager.createTable.label.fieldDelimiter' | translate}}</label>
          <input class="form-control" type="text" formControlName="fieldDelimiter" tooltip="" appExtendedFormControl
            [title]="'schemaManager.createTable.tooltips.fieldDelimiterDesc' | translate">
        </div>
        <div class="col-auto pt-2">
          <label>{{'schemaManager.createTable.label.recordDelimiter' | translate}}</label>
          <input class="form-control" type="text" formControlName="recordDelimiter" tooltip="" appExtendedFormControl
            [title]="'schemaManager.createTable.tooltips.recordDelimiterDesc' | translate">
        </div>
      </div>
      <app-checkbox formControlName="isEscapeChar" class="pt-2"
        [title]="'schemaManager.createTable.tooltips.escapeCharDesc' | translate">
        {{'schemaManager.createTable.label.escapeChar' | translate}}
      </app-checkbox>
    </div>
    <div class="row">
      <div class="col-auto pt-3">
        <label class="font-weight-bold mb-0"> {{'schemaManager.createTable.label.format' | translate}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 pt-2">
        <label>{{'schemaManager.createTable.label.dateFormat' | translate}}</label>
        <div class="select-editable">
          <select (change)="setDateFormat($event)" class="form-control w-100">
            <option value="MDY4/">MDY4/</option>
            <option value="DMY4/">DMY4/</option>
            <option value="Y4MD/">Y4MD/</option>
            <option value="Y4MD.">Y4MD.</option>
            <option value="MDY2/">MDY2/</option>
            <option value="DMY2/">DMY2/</option>
            <option value="Y2MD/">Y2MD/</option>
          </select>
          <input type="text" name="format" class="mt-2 pl-2" formControlName="dateFormat"
            [title]="'schemaManager.createTable.tooltips.dateFormatDesc' | translate" />
        </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 pt-2">
        <label>{{'schemaManager.createTable.label.moneyFormat' | translate}}</label>
        <input class="form-control" type="text" formControlName="moneyFormat" tooltip="" appExtendedFormControl
          [title]="'schemaManager.createTable.tooltips.moneyFormatDesc' | translate">
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-auto">
        <label class="font-weight-bold mt-1"> {{'schemaManager.createTable.label.loadOptions' | translate}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-auto pt-2">
        <label>{{'schemaManager.createTable.label.noOfRows' | translate}}</label>
        <input class="form-control" type="text" formControlName="noOfRows" tooltip="" appExtendedFormControl
          oninput="value=value.replace(/[^0-9]/g, '')"
          [title]="'schemaManager.createTable.tooltips.noOfRowsDesc' | translate">
      </div>
      <div class="col-auto pt-2">
        <label>{{'schemaManager.createTable.label.maximumError' | translate}}</label>
        <input class="form-control" type="text" formControlName="maxError" tooltip="" appExtendedFormControl
          oninput="value=value.replace(/[^0-9]/g, '')"
          [title]="'schemaManager.createTable.tooltips.maxErrorDesc' | translate">
      </div>
      <div class="col-auto pt-2">
        <label>{{'schemaManager.createTable.label.rejectFile' | translate}}</label>
        <input class="form-control" type="text" formControlName="rejectFileName" tooltip="" appExtendedFormControl
          [title]="'schemaManager.createTable.tooltips.rejectFileDesc' | translate">
      </div>
    </div>
  </form>

  <!-- Add File Popup -->
  <div class="row align-items-center pt-3">
    <div class="col-6">
      <label class="font-weight-bold mb-0"><span class="text-danger">*</span>
        {{'schemaManager.createTable.label.dataFiles' | translate}}</label>
    </div>
    <div class="col-6 text-right">
      <button type="button" class="btn btn-primary" (click)="openModel()">
        + {{'button.add' | translate}}</button>
    </div>
  </div>

  <div bsModal #addFilesModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">{{isEdit ? ('schemaManager.createTable.title.editFiles' | translate) :
            ('schemaManager.createTable.title.addFiles' | translate)}} </h5>
          <button type="button" class="close" (click)="closeModal()">
            <i class="icon icon-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="addDataFileForm">
            <div>
              <label class="font-weight-bold">{{'common.type' | translate}}</label>
              <div class="row font-weight-bold col-12">
                <app-radio-group formControlName="dataFileType"
                  [title]="'schemaManager.createTable.tooltips.dataFileType' | translate">
                  <app-radio value="disk"> {{'schemaManager.createTable.label.disk' | translate}} </app-radio>
                  <app-radio class="ml-4" value="pipe"> {{'schemaManager.createTable.label.pipe' | translate}}
                  </app-radio>
                </app-radio-group>
              </div>
            </div>
            <div class="mt-2 col-xl-6 col-md-6 col-md-6 col-sm-6 col-12 px-0">
              <label><span class="text-danger">* </span>{{'schemaManager.createTable.table.columnTitle.fileName' |
                translate}}</label>
              <input class="form-control" type="text"
                [title]="'schemaManager.createTable.tooltips.fileNameDesc' | translate"
                [placeholder]="'schemaManager.createTable.placeholder.fileName' | translate" tooltip=""
                formControlName="dataFileName" appExtendedFormControl>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label class="font-weight-bold mt-1"> {{'common.options' | translate}}</label>
              </div>
            </div>
            <div>
              <label>BLOB {{'common.directory' | translate}}</label>
              <input class="form-control" type="text" placeholder="" tooltip="" formControlName="blobDirectory"
                appExtendedFormControl>
            </div>
            <div class="pt-2">
              <label>CLOB {{'common.directory' | translate}}</label>
              <input class="form-control" type="text" placeholder="" tooltip="" formControlName="clobDirectory"
                appExtendedFormControl>
            </div>
          </form>
          <div class="row pt-3">
            <div class="col-12 text-right">
              <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">
                {{'button.cancel' | translate}}</button>
              <button type="button" class="btn btn-primary" (click)="addDataFile()"
                [disabled]="!addDataFileForm.valid">{{isEdit ? ('button.save' | translate) : ('button.add' |
                translate)}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Table -->
  <app-data-table [data]="dataFiles" *ngIf="dataFiles" [rowsPerPage]="20" [minHeight]="200" class="mt-3">
    <app-data-table-column [title]="'common.type' | translate">
      <ng-template appTableCell let-row="row">
        {{row.dataFileType}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'schemaManager.createTable.table.columnTitle.fileName' | translate">
      <ng-template appTableCell let-row="row">
        PATH:{{row.dataFileName + (row.blobDirectory ? (';BLOBDIR:' +
        row.blobDirectory) : '') + (row.clobDirectory ?
        (';CLOBDIR:' + row.clobDirectory) : '')}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-row="row">
        <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.edit' | translate" (click)="editDataFile(row)">
          <i class="icon icon-edit"></i>
        </button>
        <button class="btn btn-sm btn-icon" [tooltip]="'tooltip.delete' | translate" (click)="deleteDataFile(row.id)">
          <i class="icon icon-trash"></i>
        </button>
      </ng-template>
    </app-data-table-column>
  </app-data-table>

  <!-- Footer Buttons -->
  <div class="row mt-3">
    <div class="col-12">
      <button type="button" class="btn btn-secondary mr-2" (click)="backToTable('back')"> {{'button.back' |
        translate}}</button>
      <button type="button" class="btn btn-primary mr-2" (click)="viewQuery()"
        [disabled]="!advTableForm.valid || dataFiles?.length < 1 || !noOfRows.value || !maxError.value">{{'schemaManager.createTable.button.viewQueryAndCreate'
        | translate}}</button>
      <button type="button" class="btn btn-secondary" (click)="backToTable('cancel')"> {{'button.cancel' |
        translate}}</button>
    </div>
  </div>
</ng-container>

<app-create-table-query *ngIf="isViewQuery" (backFromQuery)="backFromQuery($event)" [viewQueryObj]="viewQueryObj"
  [dataObj]="dataObj" [session]="session"></app-create-table-query>