/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

const units: string[] = ['day', 'hour', 'minute', 'second'];
const unitValues: number[] = [86400, 3600, 60, 1];

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {
  transform(seconds: number) {
    let formatted = '';
    for (let i = 0; i < units.length; i++) {
      const value = Math.floor(seconds / unitValues[i]);
      if (value > 0) {
        formatted += value + ' ' + units[i];
        if (value > 1) {
          formatted += 's';
        }
        formatted += ' ';
        seconds %= unitValues[i];
      }
    }

    return formatted;
  }
}
