<div [formGroup]="timeStampForm">
  <div class="d-inline-flex form-group align-items-center mr-3">
    <input type="text" class="form-control date-input pl-2" placeholder="From Date" bsDatepicker formControlName="fromDate"
      #fromDatePicker="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" [maxDate]="toDate.value" />
    <div class="input-group-append mr-2">
      <span class="input-group-text rounded-0" (click)="fromDatePicker.toggle()" [attr.aria-expanded]="fromDatePicker.isOpen">
        <i class="icon icon-calendar icon-padding"></i>
      </span>
    </div>
    <input type="number" placeholder="HH" class="time-input-width form-control mr-1 pl-1 pr-0" tooltip=""
      appExtendedFormControl formControlName="fromTimeHH" min="0" max="23">:
    <input type="number" placeholder="MM" class="time-input-width form-control pr-0 pl-1 ml-1" tooltip=""
      appExtendedFormControl formControlName="fromTimeMM" min="0" max="59">
  </div>
  <div class="d-inline-flex form-group align-items-center">
    <input type="text" class="form-control date-input pl-2" placeholder="To Date" bsDatepicker formControlName="toDate"
      #toDatePicker="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" [minDate]="fromDate.value" />
    <div class="input-group-append mr-2">
      <span class="input-group-text rounded-0" (click)="toDatePicker.toggle()" [attr.aria-expanded]="toDatePicker.isOpen">
        <i class="icon icon-calendar icon-padding"></i>
      </span>
    </div>
    <input type="number" placeholder="HH" class="time-input-width form-control mr-1 pl-1 pr-0" tooltip=""
      appExtendedFormControl formControlName="toTimeHH" min="0" max="23" #toTime>:
    <input type="number" placeholder="MM" class="time-input-width form-control pr-0 pl-1 ml-1 mr-2" tooltip=""
      appExtendedFormControl formControlName="toTimeMM" min="0" max="59">
  </div>
  <button type="button" class="btn btn-primary mt-0" tooltip="Reset Date Range" (click)="reset()" [disabled]="(fromDate.value === null && toDate.value === null)"><i
      class="icon icon-times"></i></button>
</div>
