/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { ServerResolver } from '../server-resolver.service';
import { SqltraceComponent } from './sqltrace.component';
import { SqltraceStatementsComponent } from './sqltrace-stmts.component';
import { SqltraceStatementExecutionsComponent } from './sqltrace-stmt-executions.component';
import { SqltraceStatementProfileComponent } from './sqltrace-stmt-profile.component';
import { SqltraceTransactionInfoComponent } from './sqltrace-txn-info.component';
import { SqltraceTransactionsComponent } from './sqltrace-txns.component';
import { Route } from '@angular/router';

export const SqltraceRoutes: Route[] = [
  {
    path: 'sqltrace',
    component: SqltraceComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'sqltrace/statements',
    component: SqltraceStatementsComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'sqltrace/statements/:sqlid',
    component: SqltraceStatementExecutionsComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'sqltrace/statements/:sqlid/profile',
    component: SqltraceStatementProfileComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'sqltrace/transactions',
    component: SqltraceTransactionsComponent,
    resolve: {
      server: ServerResolver
    }
  },
  {
    path: 'sqltrace/statements/:sqlid/transaction',
    component: SqltraceTransactionInfoComponent,
    resolve: {
      server: ServerResolver
    }
  }

];
