/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { DashboardPanelConfig } from './dashboard';
import { DashboardBasePanel } from './dashboard-base-panel';
import { GRID_HEIGHT_STEP, GRID_HORIZONTAL_CHUNKS, GRID_PANEL_PADDING } from './dashboard-grid';

export class DashboardPanel extends DashboardBasePanel {
  title: string;

  isMoving = false;
  moveOffsetX = 0;
  moveOffsetY = 0;

  isResizing = false;
  resizeOffsetX = 0;
  resizeOffsetY = 0;

  contentType: string;
  contentConfig: any;

  constructor(config: DashboardPanelConfig) {
    super(config.x, config.y, config.width, config.height);
    this.title = config.title;
    this.contentType = config.type;
    this.contentConfig = config.config;
  }

  isActive() {
    return this.isMoving || this.isResizing;
  }

  startMoving(mouseX: number, mouseY: number) {
    this.isMoving = true;
    this.isResizing = false;
    this.moveOffsetX = mouseX - this.renderX - this.parent.renderX;
    this.moveOffsetY = mouseY - this.renderY - this.parent.renderY;
  }

  startResizing(mouseX: number, mouseY: number) {
    this.isMoving = false;
    this.isResizing = true;
    this.resizeOffsetX = mouseX - this.parent.renderX - this.renderX - this.renderWidth;
    this.resizeOffsetY = mouseY - this.parent.renderY - this.renderY - this.renderHeight;
  }

  onMouseMove(mouseX: number, mouseY: number) {
    if (this.isMoving) {
      this.renderX = mouseX - this.parent.renderX - this.moveOffsetX;
      this.renderY = mouseY - this.parent.renderY - this.moveOffsetY;
      this.x = this.snapToGridX(this.renderX);
      if (this.x < 0) {
        this.x = 0;
      } else if (this.x + this.width > GRID_HORIZONTAL_CHUNKS) {
        this.x = GRID_HORIZONTAL_CHUNKS - this.width;
      }
      this.y = this.snapToGridY(this.renderY);
      if (this.y < 0) {
        this.y = 0;
      }
      this.updateCssStyle();
    } else if (this.isResizing) {
      this.renderWidth = mouseX - this.parent.renderX - this.resizeOffsetX - this.renderX;
      this.renderHeight = mouseY - this.parent.renderY - this.resizeOffsetY - this.renderY;
      this.width = this.snapToGridX(this.renderX + this.renderWidth + GRID_PANEL_PADDING) - this.x;
      if (this.width < 1) {
        this.width = 1;
      } else if (this.x + this.width > GRID_HORIZONTAL_CHUNKS) {
        this.width = GRID_HORIZONTAL_CHUNKS - this.x;
      }
      this.height = this.snapToGridY(this.renderY + this.renderHeight + GRID_PANEL_PADDING) - this.y;
      if (this.height < 1) {
        this.height = 1;
      }
      this.updateCssStyle();
    }
  }

  protected getUnitWidth() {
    return (this.parent.renderWidth + GRID_PANEL_PADDING) / GRID_HORIZONTAL_CHUNKS;
  }

  protected snapToGridX(x: number) {
    const unitWidth = this.getUnitWidth();
    return Math.round(x / unitWidth);
  }

  protected snapToGridY(y: number) {
    return Math.round((y - GRID_PANEL_PADDING) / GRID_HEIGHT_STEP);
  }

  toJson(): any {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      title: this.title,
      type: this.contentType,
      config: this.contentConfig
    };
  }
}
