/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { InformixServer } from '../informixServer';
import { SqlhostsGroupInternal, SqlhostsGroups, SqlhostsGroupValidationStatus } from './enterprise-replication-types';
import { ERDomain } from './er-domain';
import { FakeERDomainGenerator } from './fake-er-domain';
import { LocalStorageService } from '../../../shared/storage/local-storage.service';
import { UserService } from '../../../shared/user/user.service';
import { User } from '../../../shared/user/user';
@Injectable({
  providedIn: 'root'
})
export class EnterpriseReplicationService {
  private currentUser: User;
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {
    this.userService.getCurrentUser().then(user => this.currentUser = user);
  }

  getDomain(server: InformixServer) {
    return this.http.get(server.getERUrl() + '/domain')
      .pipe(map(response => new ERDomain(server, response)));
  }

  getDomainWithProfile(server: InformixServer): Observable<ERDomain> {
    const domain$ = this.getDomain(server);
    const profile$ = this.http.get<any[]>(server.getERUrl() + '/domain/profile');
    return combineLatest([domain$, profile$]).pipe(map(([domain, profileJson]) => {
      domain.addProfile(profileJson);
      return domain;
    }));
  }

  getDomainProfile(domain: ERDomain): Observable<any> {
    return this.http.get(domain.getProfileUrl()).pipe(map((response: any[]) => response));
  }

  getFakeDomain(server: InformixServer): Observable<ERDomain> {
    const generator = new FakeERDomainGenerator();
    return of(generator.generateERDomain(server, 5));
  }

  getSqlhostsGroups(serverId: number): any {
    return this.http.get('informix/' + serverId + '/er/sqlhost');
  }
  getHQServers(serverId): any {
    return this.http.get('informix/' + serverId + '/er/available/servers');
  }

  validateServer(serverId: number, serverName: string): any {
    return this.http.get('informix/' + serverId + '/er/available/server', { params: { serverName } });
  }

  defineNode(serverId, body): any {
    return this.http.post('informix/' + serverId + '/er/create/domain', body);
  }

  defineNodeList(serverId, body): any {
    return this.http.post('informix/' + serverId + '/er/create/domain/list', body);
  }

  deleteNode(serverId, body): any {
    return this.http.delete('informix/' + serverId + '/er/domain/server/delete', { body });
  }
  fetchAdvancedOptions(serverId: number, groupName: string) {
    return this.http.get('informix/' + serverId + '/er/domain/server/modify', { params: { group: groupName } });
  }

  modifyAdvancedOptions(serverId: number, groupName: string, body) {
    return this.http.put('informix/' + serverId + '/er/domain/server/modify', body, { params: { group: groupName } });
  }

  startER(serverId: number, body) {
    return this.http.post('informix/' + serverId + '/er/domain/server/startstop', body);
  }

  /**
   * Helper to map response of groups for internal UI purpose.
   *
   * @param groups SqlhostsGroups
   * @returns Returns mapped array of sqlhosts groups
   */
  mapInternalSqlhostsGroups(groups: SqlhostsGroups[]): SqlhostsGroupInternal[] {
    let id = 1;
    let sqlhostsGroups: SqlhostsGroupInternal[] = [];
    if (!groups) {
      return sqlhostsGroups;
    }
    groups.forEach(group => {
      const sqlhostsGroup: SqlhostsGroupInternal = {
        id: id++, groupName: group.groupName, checked: false, disabled: false,
        validation: { status: SqlhostsGroupValidationStatus.NONE }, members: group.servers,
        isCurrentGroup: false
      };


      const isCurrentServerGrp = sqlhostsGroup.members.find(member => member.isCurrentServer);

      if (isCurrentServerGrp) {
        sqlhostsGroup.isCurrentGroup = true;
      }
      sqlhostsGroups.push(sqlhostsGroup);
    });

    return sqlhostsGroups;
  }

  saveDraft(serverId, domainDraft) {
    this.localStorageService.setItem(`ihq_er_domain_draft_${serverId}_usr_${this.currentUser.id}`, JSON.stringify(domainDraft));
  }

  clearDraft(serverId) {
    if(this.isDraftAvaiable(serverId)) {
      this.localStorageService.removeItem(`ihq_er_domain_draft_${serverId}_usr_${this.currentUser.id}`);
    }
  }

  getDraft(serverId) {
    const data = JSON.parse(this.localStorageService.getItem(`ihq_er_domain_draft_${serverId}_usr_${this.currentUser.id}`));
    return data;
  }

  isDraftAvaiable(serverId) {
    return false;
    // return this.localStorageService.getItem(`ihq_er_domain_draft_${serverId}_usr_${this.currentUser.id}`) !== null;
  }

  getReplicates(serverId: number) {
    return this.http.get<any[]>('informix/' + serverId + '/er/replicate');
  }

  getReplicate(serverId: number, replicateName) {
    return this.http.get<any[]>('informix/' + serverId + '/er/replicate/modify', { params: { name: replicateName } });
  }

  updateReplicate(serverId: number, url, body) {
    return this.http.put('informix/' + serverId + '/er/replicate/' + url, body);
  }

  removeReplicate(serverId: number, body) {
    return this.http.delete('informix/' + serverId + '/er/replicate/delete', { body });
  }

  createReplicate(serverId: number, body): any {
    return this.http.post('informix/' + serverId + '/er/replicate/create', body);
  }

  getDataBaseList(serverId: number, groupName: string): any {
    return this.http.get('informix/' + serverId + '/er/replicate/databases', { params: { group: groupName } });
  }

  getTableList(serverId: number, database: string, groupName: string): any {
    return this.http.get('informix/' + serverId + '/er/replicate/databases/' + database + '/tables', { params: { group: groupName } });
  }

  getColumnList(serverId: number, database: string, tableName: string, groupName: string): any {
    return this.http.get('informix/' + serverId + '/er/replicate/databases/' + database + '/tables/' + tableName,
    { params: { group: groupName } });
  }

  checkOrSyncReplicate(serverId: number, body) {
    return this.http.post('informix/' + serverId + '/er/replicate/job/create', body);
  }

  runTask(body) {
    return this.http.post('http://demo8359962.mockable.io/er/replicate/runtask', body, { params: { thirdParty: 'true' } });
  }

  getJobs(serverId: number): any {
    return this.http.get('informix/' + serverId + '/er/replicate/jobs');
  }

  getJob(serverId: number, body): any {
    return this.http.post('informix/' + serverId + '/er/replicate/job',  body);
  }

  deleteTask(serverId: number, body: any) {
    return this.http.delete('informix/' + serverId + '/er/replicate/job', { body });
  }

  getJobReason(serverId: number, cmdNumber: number): any {
    return this.http.get('informix/' + serverId + '/er/replicate/job/failed/reason/' + cmdNumber);
  }
}
