/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { JSONTreeBuilder, JSONTreeElement, JSONTreeGroupElement } from './json-tree-element';

@Component({
  selector: 'app-json-explorer',
  templateUrl: 'json-explorer.html',
  styleUrls: ['json-explorer.component.scss']
})
export class JSONExplorerComponent implements OnChanges {

  @Input() json: any;
  @Input() inline = true;
  @Input() expand = false;
  @Input() showExpandToggle = true;

  jsonTree: JSONTreeElement[] = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['json'] && this.json) {
      this.jsonTree = JSONTreeBuilder.build(this.json);
      if (this.expand) {
        this.jsonTree.forEach(element => {
          if (element instanceof JSONTreeGroupElement) {
            element.expandAll();
          }
        });
      }
    }
  }
}
