/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { AbstractAlertOperand } from '../abstract-alert-operand';


@Component({
  selector: 'app-online-log-regex-operand',
  template: `
  <div class="input-group input-group-sm d-inline-flex mr-2 mb-2" style="width: auto" *ngFor="let regex of predefinedRegex">
    <span class="input-group-btn">
      <button type="button" class="btn btn-sm" [ngClass]="regex.enabled ? 'btn-success' : 'btn-secondary'"
        (click)="togglePredefinedRegex(regex)">
        <i class="icon icon-check"></i>
      </button>
    </span>
    <span class="input-group-btn">
      <button type="button" class="btn btn-sm btn-secondary" (click)="togglePredefinedRegex(regex)">
        {{regex.name}}
      </button>
    </span>
  </div>

  <form [formGroup]="customRegexFormGroup">
    <div formArrayName="regexes">
      <div class="form-group" *ngFor="let regex of customRegex.controls; let i = index">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control form-control-sm" [formControlName]="i" tooltip="" appExtendedFormControl>
          <span class="input-group-btn">
            <button class="btn btn-sm btn-danger" (click)="removeCustomRegex(i)">
              <i class="icon icon-times"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  </form>

  <button type="button" class="btn btn-sm btn-secondary d-block" (click)="addNewCustomRegex()">
    <i class="icon icon-plus"></i> Add Custom String
  </button>
  `
})
export class OnlineLogRegexOperandComponent extends AbstractAlertOperand implements OnInit {

  predefinedRegex: any[] = [
    { name: 'Errors', enabled: false, value: 'error' },
    { name: 'Warnings', enabled: false, value: 'warn' },
    { name: 'Assert Fails', enabled: false, value: 'af' }
  ];

  private selectedPredefined: string[] = [];

  customRegexFormGroup = new UntypedFormGroup({
    regexes: new UntypedFormArray([])
  });

  customRegex: UntypedFormArray = this.customRegexFormGroup.controls.regexes as UntypedFormArray;

  ngOnInit() {
    super.ngOnInit();

    if (this.operand) {
      if (Array.isArray(this.operand.predefined)) {
        this.predefinedRegex.forEach(regex => {
          if (this.operand.predefined.indexOf(regex.value) > -1) {
            regex.enabled = true;
            this.selectedPredefined.push(regex.value);
          }
        });
      }

      if (Array.isArray(this.operand.custom) && this.operand.custom.length > 0) {
        this.operand.custom.forEach((value: any) => {
          if (typeof value === 'string') {
            this.customRegex.push(this.getCustomStringFormControl(value));
          }
        });
      }
    }

    this.customRegexFormGroup.valueChanges.pipe(delay(0)).subscribe(() => {
      this.emitUpdate();
    });
  }

  togglePredefinedRegex(regex: any) {
    regex.enabled = !regex.enabled;
    this.selectedPredefined = this.predefinedRegex.filter(v => v.enabled).map(v => v.value);
    this.emitUpdate();
  }

  private getCustomStringFormControl(value: string = null): UntypedFormControl {
    return new UntypedFormControl(value, Validators.required);
  }

  addNewCustomRegex() {
    this.customRegex.push(this.getCustomStringFormControl());
  }

  removeCustomRegex(index: number) {
    this.customRegex.removeAt(index);
  }

  public isValid(): boolean {
    if (this.customRegex.controls.length > 0) {
      return this.customRegexFormGroup.valid;
    } else {
      return this.selectedPredefined.length > 0;
    }
  }

  public getValue(): any {
    return {
      predefined: this.selectedPredefined,
      custom: this.customRegex.value
    };
  }
}
