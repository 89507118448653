<div bsModal #optimizeModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-11">
          <h5 class="modal-title pull-left">{{ 'tablesIndexes.optimizeSpace' | translate }}
          </h5>
        </div>
        <div>
          <button type="button" class="close" (click)="closeoptimizeModal()">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body p-4">
        <div class="pr-3">
          <label
            class="font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.compressModal.selectedRowsLabel' | translate }}:
            {{objects?.length}}
          </label>
        </div>
        <div *ngIf="objects" class="overflow-auto selected-table-modal">
          <app-data-table [data]="objects" [enablePagination]="false">
            <app-data-table-column [title]="'common.type' | translate">
              <ng-template appTableCell let-data="row">
                {{'table.' + getType(data) | translate}}
              </ng-template>
            </app-data-table-column>
            <app-data-table-column [title]="'tablesIndexes.table.columnTitle.objSelected' | translate">
              <ng-template appTableCell let-data="row">
                {{data.tabname}} {{data.partnum ? '[fragment ' + data.partnum + ']' :''}}
              </ng-template>
            </app-data-table-column>
          </app-data-table>
        </div>
        <div class="col-12 pl-4 font-weight-bold pt-3">
          <app-checkbox [checked]="isCompress" [disabled]="isUncompress"
            (change)="optimizePopUpValidations('compress', $event)">
            {{ 'tablesIndexes.optimizationPolicies.compressModal.compressLabel' | translate }}
          </app-checkbox>
        </div>
        <div class="col-12 pl-5">
          <app-checkbox [disabled]="!containsBlobData || isUncompress" [checked]="isCompressBlobData"
            (change)="optimizePopUpValidations('compress_blobs', $event)">
            {{ 'tablesIndexes.optimizationPolicies.compressModal.compressDesc' | translate }}
          </app-checkbox>
        </div>
        <div class="col-12 pl-4 pt-1 font-weight-bold">
          <app-checkbox [checked]="isUncompress"
            [disabled]="isCompress || isIndexDisable || isRepack"
            (change)="optimizePopUpValidations('uncompress', $event)">
            {{ 'tablesIndexes.optimizationPolicies.compressModal.uncompressLabel' | translate }}
          </app-checkbox>
        </div>
        <div class="col-12 pl-4 pt-4">
          <app-checkbox [checked]="isRepack" [disabled]="isUncompress"
            (change)="optimizePopUpValidations('repack', $event)">
            <span
              class="pr-3 font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.compressModal.repackLabel' | translate }}</span>
            {{ 'tablesIndexes.optimizationPolicies.compressModal.repackDesc' | translate }}
          </app-checkbox>
        </div>
        <div class="col-12 pl-4 pt-4">
          <app-checkbox [checked]="isShrink" (change)="optimizePopUpValidations('shrink', $event)">
            <span
              class="pr-3 font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.compressModal.shrinkLabel' | translate }}</span>
            {{ 'tablesIndexes.optimizationPolicies.compressModal.shrinkDesc' | translate }}
          </app-checkbox>
        </div>
        <div class="col-12 pl-4 pt-4">
          <app-checkbox [checked]="isDefragment" (change)="optimizePopUpValidations('defrag', $event)">
            <span
              class="pr-3 font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.compressModal.defragLabel' | translate }}</span>
            {{ 'tablesIndexes.optimizationPolicies.compressModal.defragDesc' | translate }}
          </app-checkbox>
        </div>
        <div class="col-12 pl-4 pt-4">
          <app-checkbox [checked]="isRemoveIPA" (change)="optimizePopUpValidations('remove', $event)"
            [disabled]="isIndexDisable">
            <span
              class="pr-3 font-weight-bold">{{ 'tablesIndexes.optimizationPolicies.compressModal.removeLabel' | translate }}</span>
            {{ 'tablesIndexes.optimizationPolicies.compressModal.removeDesc' | translate }}
          </app-checkbox>
        </div>
        <div align="right">
          <div class="pt-4">
            <button type="button" class="btn btn-secondary btn-fixed-width" (click)="closeoptimizeModal()">
              {{ 'button.cancel' | translate }}</button>
            <button type="button" class="btn btn-primary btn-fixed-width ml-2" (click)="applyOptimization()"
              [disabled]="disableApplyBtn">{{ 'button.apply' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
