/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InformixServerGroup } from '../../groups/informixServerGroup';
import { Dashboard } from './dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardHttpService {

  constructor(
    private http: HttpClient
  ) { }

  getDashboards(group: InformixServerGroup): Observable<Dashboard[]> {
    return this.http.get('informix/groups/' + group.id + '/dashboards').pipe(
      map(response => response['dashboards'].map(v => new Dashboard(v))));
  }

  getDashboard(group: InformixServerGroup, dashboardId: number): Observable<Dashboard> {
    return this.http.get('informix/groups/' + group.id + '/dashboards/' + dashboardId).pipe(
      map(response => new Dashboard(response)));
  }

  createDashboard(group: InformixServerGroup, dashboardJson: any): Observable<Dashboard[]> {
    return this.http.post('informix/groups/' + group.id + '/dashboards', dashboardJson).pipe(
      map(response => response['newDashboards'].map(v => new Dashboard(v))));
  }

  updateDashboard(dashboard: Dashboard, dashboardJson: any): Observable<Dashboard> {
    return this.http.put('informix/groups/' + dashboard.group.id + '/dashboards/' + dashboard.id, dashboardJson).pipe(
      map(response => new Dashboard(response)));
  }

  removeDashboard(dashboard: Dashboard) {
    return this.http.delete(dashboard.restUrl);
  }

  removeSelectedDashboards(groupId: number, dashboardIds: number[]) {
    const httpOptions = {
      headers: new HttpHeaders(), body: { dashboardIds }
    };
    return this.http.delete('informix/groups/' + groupId + '/dashboards', httpOptions);
  }
}
