/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdvancedOptions, AdvancedOptionsMap, SqlhostsServerInternal } from '../../../enterprise-replication-types';

export enum PANEL {
    ADVANCED = 'Advanced',
    DEFINE = 'Define',
    SPACES = 'Spaces',
    REVIEW = 'REVIEW',
    INFO = 'INFO'
}


@Component({
    selector: 'app-er-advanced-options',
    templateUrl: './er-advanced-options.component.html',
    styleUrls: ['../er-sidebars.component.scss','./er-advanced-options.component.scss']
})

export class ERAdvancedOptionsComponent implements OnInit, OnChanges {

    @Input() selectedNode: SqlhostsServerInternal;
    @Input() modifyMode: false;
    @Input() selections: AdvancedOptions;
    @Input() APICalls: any;
    @Input() open: boolean;

    advancedOptionsForm: FormGroup = null;
    // Mappging Object to map local values with servers payload properties
    formMapping: AdvancedOptionsMap = {
        nodeType: {
            root: 'Root',
            nonroot: 'Non-Root',
            leaf: 'Leaf'
        },
        ats: {
            1: '/dev/null',
            2: '/tmp',
            3: ''
        },
        ris: {
            1: '/dev/null',
            2: '/tmp',
            3: ''
        },
        fileFormat: {
            1: 'Text',
            2: 'Xml',
            3: 'Both'
        },
        idleTimeOut: {
            true: 'On',
            false: 'Off',
        }

    };

    formDefaults: any = {
        ats: 2,
        ris: 2,
        fileFormat: 1,
        idleTimeOut: false,
        timeOut: 0,
        mode: 'nochange',
        qod: null,
        atsFilePath: '',
        risFilePath: ''
    };

    constructor() { }

    ngOnInit() {
        this.initForms();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selections && changes.selections.currentValue) {
            this.setValues();
        }else{
            this.setDefaultFormValues();
        }
    }

    handleInput(timeOutElement: HTMLInputElement): void {
        const inputValue = Math.abs(parseFloat(timeOutElement.value));
        timeOutElement.value = inputValue.toString();
    }

    /**
     * Initilizing the options form
     */
    initForms() {
        this.advancedOptionsForm = new FormGroup({
            ats: new FormControl(null, [Validators.required]),
            ris: new FormControl(null, [Validators.required]),
            fileFormat: new FormControl(null, [Validators.required]),
            idleTimeOut: new FormControl(false, [Validators.required]),
            timeOut: new FormControl(null, [Validators.required]),
            atsFilePath: new FormControl(null, [Validators.required]),
            risFilePath: new FormControl(null, [Validators.required]),
            qod: new FormControl(null),
            mode: new FormControl(null)
        });
        this.setDefaultFormValues();
        this.subscribeToFormValues();

    }

    setDefaultFormValues(){
        if(!this.advancedOptionsForm) {
            return;
        }
        this.advancedOptionsForm.reset();
        const values: any = {
            ats: this.formDefaults.ats,
            ris: this.formDefaults.ris,
            fileFormat: this.formDefaults.fileFormat,
            idleTimeOut: this.formDefaults.idleTimeOut,
            timeOut: this.formDefaults.timeOut,
            mode: this.formDefaults.mode,
            qod: this.formDefaults.qod,
            atsFilePath: this.formDefaults.atsFilePath,
            risFilePath: this.formDefaults.risFilePath,
        };
        this.advancedOptionsForm.patchValue(values);
        this.advancedOptionsForm.controls.atsFilePath.disable();
        this.advancedOptionsForm.controls.risFilePath.disable();
        this.advancedOptionsForm.controls.timeOut.disable();
    }

    setValues() {
        const values: any = {
            ats: this.selections.ats,
            ris: this.selections.ris,
            atsFilePath: this.formDefaults.atsFilePath,
            risFilePath: this.formDefaults.risFilePath,
            fileFormat: this.selections.fileFormat,
            idleTimeOut: this.selections.timeOut > 0,
            timeOut: this.selections.timeOut,
            mode: 'nochange',
            qod: null
        };
        if(this.selections.ats === 3) {
            values.atsFilePath = this.selections.atsFilePath;
        }
        if(this.selections.ris === 3) {
            values.risFilePath = this.selections.risFilePath;
        }
        setTimeout(() => {
            this.advancedOptionsForm.patchValue(values);
        });
    }
    /**
     * Subscribe to ats, ris, and idleTimeout form controls to enable/disable dependent controls
     */
    subscribeToFormValues() {
        this.advancedOptionsForm.controls.ats.valueChanges.subscribe(value => {
            if (value === 3) {
                this.advancedOptionsForm.controls.atsFilePath.enable();
            } else {
                this.advancedOptionsForm.controls.atsFilePath.disable();
            }
        });
        this.advancedOptionsForm.controls.ris.valueChanges.subscribe(value => {
            if (value === 3) {
                this.advancedOptionsForm.controls.risFilePath.enable();
            } else {
                this.advancedOptionsForm.controls.risFilePath.disable();
            }
        });
        this.advancedOptionsForm.controls.idleTimeOut.valueChanges.subscribe(value => {
            if (value) {
                this.advancedOptionsForm.controls.timeOut.enable();
            } else {
                this.advancedOptionsForm.controls.timeOut.disable();
            }
        });
    }

    /**
     * Method to get all the options selections
     *
     * @returns
     */
    getSelections() {
        const selections: any = {
            ats: this.advancedOptionsForm.controls.ats.value,
            ris: this.advancedOptionsForm.controls.ris.value,
            fileFormat: this.advancedOptionsForm.controls.fileFormat.value,
            idleTimeOut: this.advancedOptionsForm.controls.idleTimeOut.value,
            mode: this.advancedOptionsForm.controls.mode.value,
            timeOut: 0,
        };
        if(selections.ats === 3) {
            selections.atsFilePath = this.formMapping.ats['3'] = this.advancedOptionsForm.controls.atsFilePath.value;
        }
        if(selections.ris === 3) {
            selections.risFilePath = this.formMapping.ris['3'] = this.advancedOptionsForm.controls.risFilePath.value;
        }
        if(selections.idleTimeOut === true) {
            selections.timeOut = this.advancedOptionsForm.controls.timeOut.value;
        }

        if(this.modifyMode) {
            selections.groupName = this.selectedNode.name;
            if(this.advancedOptionsForm.controls.qod.value === 'define') {
                selections.qodDefine = true;
            }else if(this.advancedOptionsForm.controls.qod.value === 'start') {
                selections.qodState = true;
            }else if(this.advancedOptionsForm.controls.qod.value === 'stop'){
                selections.qodState = false;
            }

            if((!this.selections.isSameMaster && this.selections.masterNodeName)
                && this.advancedOptionsForm.controls.qod.value !== 'define') {
                selections.qodGroupName = this.selections.masterNodeName;
            }
        }

        return selections;
    };

}
