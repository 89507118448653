<!-- Page Heading -->
<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>

<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col">
      <h4>
        <strong>{{title}}</strong>
        <app-data-source-toggle class="ml-2" *ngIf="sensorId" [viewMonitoredData]="false" (viewMonitoredDataStateChange)="changeDataSource(!$event)"></app-data-source-toggle>
      </h4>
    </div>
  </div>

  <div class="row mb-4" *ngIf="sensorId && !dataLoadErrorMessage">
    <div class="col">
      <small *ngIf="viewLiveData">You are currently viewing the log file directly from the database server.</small>
      <small *ngIf="!viewLiveData">You are currently viewing the log file as monitored data by the sensor.</small>
    </div>
    <div *ngIf="!viewLiveData" class="col text-right">
      <app-sensors-required-control [server]="server" [monitoringProfile]="monitoringProfile" [sensorList]="[sensorId]"
        (monitoringProfileUpdated)="getMonitoringProfile()"></app-sensors-required-control>
    </div>
  </div>

  <div *ngIf="viewLiveData && !server.hasMonitorPassword" class="alert alert-warning">
    Log information is not available. Monitoring credentials have not been supplied.
  </div>

  <div *ngIf="dataLoadErrorMessage" class="alert alert-danger mt-1">
    Unable to retrieve {{title?.toLowerCase()}} information. <br>
    Error: {{dataLoadErrorMessage}}
  </div>

  <ng-template [ngIf]="viewLiveData && server.hasMonitorPassword && !dataLoadErrorMessage">
    <form *ngIf="logRotationForm" role="admin" [formGroup]="logRotationForm">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-6 mt-1">
          <label class="font-weight-bold mr-3">{{title}} Rotation:</label>
          <ng-container *ngIf="!isEdit">
            <label class="pt-1" *ngIf="formControls.rotationEnabled.value">
              Enabled. Rotate every {{ formControls.rotationFrequency.value }} days, keeping a maximum of {{
              formControls.maxFiles.value }} log files.
            </label>
            <label class="pt-1" *ngIf="!formControls.rotationEnabled.value">
              Disabled.
            </label>
            <app-check-server-admin-permissions [server]="server">
              <div *ngIf="!isEdit">
                <button type="button" class="btn btn-primary btn-sm ml-1" title="Edit" (click)="isEdit=!isEdit"
                  appPermissionDisable>
                  <i class="icon icon-edit"></i>
                </button>
              </div>
            </app-check-server-admin-permissions>
          </ng-container>
          <ng-container *ngIf="isEdit">
            <select class="form-control form-control-sm col-lg-2 col-md-2 col-sm-3 d-inline-block" formControlName="rotationEnabled">
              <option *ngFor="let option of rotationOptions" [selected]="formControls.rotationEnabled.value === option.value"
                [ngValue]="option.value">{{option.name}}</option>
            </select>
            <div class="pt-1 d-inline-block">
              <div class="row ml-1">
                <div class="row ml-1" *ngIf="formControls.rotationEnabled.value">
                  <label class="pt-1">Rotate every</label>
                  <input style="width: 80px" type="number" id="rotationFrequency" name="rotationFrequency"
                    formControlName="rotationFrequency" class="ml-2 mb-1 form-control form-control-sm" tooltip=""
                    appExtendedFormControl min="1" max="99">
                  <label class="ml-2 pt-1"> days, keeping a maximum of</label>
                  <input style="width: 80px" type="number" id="maxFiles" name="maxFiles" formControlName="maxFiles" class="ml-2 mb-1 form-control form-control-sm"
                    tooltip="" appExtendedFormControl min="1" max="100">
                  <label class="ml-2 pt-1">log files.</label>
                </div>
    
                <div [ngClass]="{'ml-1': formControls.rotationEnabled.value === false ,'ml-3': formControls.rotationEnabled.value === true}"
                  *ngIf="isEdit">
                  <button [disabled]="!logRotationForm.valid" type="button" class="btn btn-primary btn-sm ml-1" title="Save"
                    (click)="saveLogRotationConfiguration()">
                    <i class="icon icon-check "></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-sm" title="Cancel" (click)="cancelEdit()">
                    <i class="icon icon-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-4">
          <div class="d-flex float-right">
            <span class="log-lagends lagend info"> Info log entry</span>
            <span class="log-lagends lagend warning"> Warning</span>
            <span class="log-lagends lagend error"> Error / Assert Failed</span>
          </div>

        </div>
      </div>
    </form>

    <div *ngIf="logData.length" class="card" style="margin-top: 0.5rem !important">
      <app-log-window class="card-body" style="max-height: 65vh; overflow: auto" (eventTopReached)="onLogWindowTopReached()">
        <div *ngIf="minLogOffset === null" class="alert alert-info">
          No log messages.
        </div>
        <div class="alert alert-info" *ngIf="minLogOffset === 0">
          No more data available.
        </div>
        <div *ngIf="minLogOffset > 0" style="text-align: center">
          <i class="icon icon-circle-notch icon-spin icon-2x ml-2"></i>
        </div>
        <div class="text-monospace" style="white-space: pre">
          <pre class="mb-0 {{ (log?.line?.length)?log?.type:'' }}" *ngFor="let log of logData;"> {{log?.line}} </pre>
        </div>
      </app-log-window>
    </div>

  </ng-template>

  <ng-template [ngIf]="!viewLiveData">
    <div class="alert alert-warning" *ngIf="!logSensor && !dataLoadErrorMessage">
      The sensor for this log file is not configured.
    </div>

    <div class="alert alert-warning" *ngIf="logSensor && logSensor.disabled && !dataLoadErrorMessage">
      The sensor for this log file is disabled.
    </div>

    <ng-container *ngIf="logSensor">
      <div class="row">
        <div class="col-lg-8"></div>
        <div class="col-lg-4 mb-2">
          <div class="d-flex float-right">
            <span class="log-lagends lagend info"> Info log entry</span>
            <span class="log-lagends lagend warning"> Warning</span>
            <span class="log-lagends lagend error"> Error / Assert Failed</span>
          </div>
        </div>
      </div>
      <div class="card">
        <app-log-window class="card-body" style="max-height: 65vh; overflow: auto" (eventTopReached)="onLogWindowTopReached()">
          <div class="alert alert-info" *ngIf="sensorOldestTimestamp === sensorDataFirstTimestamp">
            No more data available.
          </div>
          <div *ngIf="sensorIsGettingHistoricalData" style="text-align: center">
            <i class="icon icon-circle-notch icon-spin icon-2x ml-2"></i>
          </div>
          <div class="text-monospace" style="white-space: pre">
            <pre class="mb-0 {{ (log?.line?.length)?log?.type:'' }}" *ngFor="let log of sensorLogData;"> {{log?.line}} </pre>
          </div>
        </app-log-window>
      </div>
    </ng-container>
  </ng-template>
</div>
