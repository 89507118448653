/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { DashboardItem } from './dashboard-item';

export class DashboardBasePanel implements DashboardItem {
  protected parent: DashboardItem;

  x: number;
  y: number;
  width: number;
  height: number;

  renderX = 0;
  renderY = 0;
  renderWidth = 0;
  renderHeight = 0;

  cssStyle: any = {};

  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  setParent(parent: DashboardItem) {
    this.parent = parent;
  }

  updateCssStyle() {
    this.cssStyle = {
      width: this.renderWidth + 'px',
      height: this.renderHeight + 'px',
      transform: 'translate(' + this.renderX + 'px, ' + this.renderY + 'px)'
    };
  }

  overlaps(panel: DashboardBasePanel) {
    if (this.x + this.width <= panel.x) {
      return false;
    }
    if (this.x >= panel.x + panel.width) {
      return false;
    }
    if (this.y + this.height <= panel.y) {
      return false;
    }
    if (this.y >= panel.y + panel.height) {
      return false;
    }
    return true;
  }

  clone(): DashboardBasePanel {
    const panel = new DashboardBasePanel(this.x, this.y, this.width, this.height);
    panel.setParent(this.parent);
    return panel;
  }
}
