/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';

import { LogsModule } from '../../logs/logs.module';
import { MonitoringModule } from '../../../monitoring/monitoring.module';
import { SharedModule } from '../../../../shared/shared.module';
import { BackupComponent } from './backup.component';
import { BackupConfigComponent } from './backup-config.component';
import { BackupHistoryComponent } from './backup-history.component';
import { BackupLogsComponent } from './backup-logs.component';
import { BackupStatusComponent } from './backup-status.component';

@NgModule({
  imports: [
    SharedModule,
    LogsModule,
    AlertModule,
    CommonModule,
    MonitoringModule,
    BsDropdownModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    NgxBootstrapSliderModule
  ],
  exports: [],
  declarations: [
    BackupComponent,
    BackupConfigComponent,
    BackupHistoryComponent,
    BackupLogsComponent,
    BackupStatusComponent
  ]
})
export class BackupModule { }
