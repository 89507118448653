<div class="card card-body">
  <h4 class="card-title">Status</h4>

  <ng-template [ngIf]="statusLoader && !isWorkOffline">
    <div style="text-align: center">
      <i class="icon icon-circle-notch icon-spin"></i>
    </div>
  </ng-template>

  <ng-template [ngIf]="serverStatus">
    <div>
      <div class="row">
        <div class="col-xs-12 col-sm-4">
          <div class="row">
            <div class="col-6 text-xl-right">
              <a [routerLink]="['setup']">{{serverType}} Server</a>:
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center">
                <span class="badge badge-{{serverStatus?.color}} custom-size mr-1" >{{serverStatus?.description}}</span> 
                <span class="badge custom-size" *ngIf="serverStatus?.state"  placement="right"> <i class="icon icon-warning text-danger mr-1"></i>Blocked:{{serverStatus?.state?.description}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-6 text-xl-right">
              <a [routerLink]="['setup']" [queryParams]="{ tab:'agent' }">{{productName}} Agent</a>:
            </div>
            <div class="col-6">
              <span class="badge custom-size" [ngClass]="agentStatusBadge.class" >
                {{agentStatusBadge.message}}
              </span>
            </div>
          </div>
          <div class="row mt-1" *ngIf="serverStatus?.code == serverStatusCode.ONLINE">
            <div class="col-6 text-xl-right">
              Server type:
            </div>
            <div class="col-6">
              {{ (server?.serverType?.serverType === 'Secondary')?'HDR ':'' }} {{server?.serverType?.serverType}}
            </div>
          </div>
          <div class="row mt-1" *ngIf="numAFFiles != null">
            <div class="col-6 text-xl-right">
              AF files:
            </div>
            <div class="col-6">
              <i class="icon icon-warning text-warning" *ngIf="numAFFiles > 0"></i> {{numAFFiles}}
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-8" *ngIf="serverStatus?.code == serverStatusCode.ONLINE && !serverStatus?.state">
          <div class="row">
            <div class="col-6 text-xl-right">
              <a [routerLink]="['storage/spaces']">Spaces</a>:
            </div>
            <div class="col-6" *ngIf="numSpacesBelowFreeThreshold != null">
              <i class="icon icon-warning text-warning" *ngIf="numSpacesBelowFreeThreshold > 0"></i>
              {{numSpacesBelowFreeThreshold}}
              spaces &lt; {{spacePercentFreeThreshold}}% free
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-6 text-xl-right">
              Last backup:
            </div>
            <div class="col-6" *ngIf="oldestLevel0Backup != null">
              <div *ngIf="oldestLevel0Backup <= 0">
                <i class="icon icon-warning text-warning" *ngIf="oldestLevel0Backup == 0"></i> NEVER
              </div>
              <div *ngIf="oldestLevel0Backup > 0">
                {{oldestLevel0Backup * 1000 | timeSince}} at <br>
                <i class="icon icon-calendar"></i>
                {{(oldestLevel0Backup * 1000) | date:'y-MM-dd HH:mm:ss'}}
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-6 text-xl-right">
              Spaces not backed up:
            </div>
            <div class="col-6" *ngIf="numSpacesNotBackedUp != null">
              <i class="icon icon-warning text-warning" *ngIf="numSpacesNotBackedUp > 0"></i> {{numSpacesNotBackedUp}}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-6 text-xl-right">
              <a [routerLink]="['aus']">Auto Update Statistics</a>:
            </div>
            <div class="col-6">
              <i class="icon icon-check text-success" *ngIf="ausEnabled"></i>
              <i class="icon icon-times-circle text-danger" *ngIf="ausEnabled != null && !ausEnabled"></i>
              <i class="icon icon-question-circle" *ngIf="ausEnabled == null"
                tooltip="You may not have permissions to see this information."></i> {{((ausEnabled) ? 'Enabled' :
              (ausEnabled == null) ? 'Unknown' : 'Disabled')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="alert alert-danger" *ngIf="statusError && !isWorkOffline">
    <i class=" icon icon-times-circle text-danger"></i>
    <label class="color-red ml-1"><strong>Unable to connect</strong></label><br> {{statusError}}
  </div>

  <div class="alert alert-danger" *ngIf="isWorkOffline">
    <i class=" icon icon-times-circle text-danger"></i>
    <label class="color-red ml-1"><strong>Work Offline</strong></label><br> You've selected the work-offline mode. Status data will not be fetched.
  </div>
</div>
