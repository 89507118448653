/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChildren, QueryList, AfterViewInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, pairwise } from 'rxjs/operators';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { InformixServer, InformixServerStatus, informixServerStatusListMap } from './informixServer';
import { InformixServerService } from './informixServer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-server-info-form',
  templateUrl: 'server-info-form.html'
})
export class ServerInfoFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() editServer: InformixServer = null;
  @Input() groupId: number = null;
  @Input() isERNode: Boolean = false;

  @Output() serverUpdate = new EventEmitter<InformixServer>();
  @ViewChildren('conValue') conValue: QueryList<ElementRef>;
  @ViewChildren('nameInputCon') nameInputCon: QueryList<ElementRef>;

  @Input() erServer: any = null;
  serverType = environment.serverType;
  formGroup: UntypedFormGroup = null;
  connectionPropertiesForms: UntypedFormArray;
  private connectionPropertyBlacklist = ['user', 'password'];
  private workOffSub: Subscription = null;

  editingAdminCredentials = false;
  editingMonitorCredentials = true;

  expandMonitorHelpText = false;
  expandAdminHelpText = false;

  connectionPropertiesChanged = false;
  portOrSvcname: UntypedFormControl;
  serviceName: UntypedFormControl;
  port: UntypedFormControl;

  serverStatus: InformixServerStatus = null;
  serverStatusErrorMessage: string = null;
  isServerStatusLoading = false;
  serverStatusList = informixServerStatusListMap;
  iconClass: string = null;
  alertClass: string = null;
  aliasValidationErrors: any = {
    pattern: 'This field is required'
  };
  columnSize: string;
  serverInfoFormSubscriber$ = null;
  showPassword: Boolean = false;
  showAdminPassword: Boolean = false;
  isWorkOffline: Boolean;
  constructor(
    private serversService: InformixServerService,
    private notificationsService: NotificationsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const adminUserControl = new UntypedFormControl(null, [Validators.maxLength(255)]);
    const adminPassControl = new UntypedFormControl(null, [Validators.maxLength(255)]);
    this.portOrSvcname = new UntypedFormControl(this.editServer && this.editServer.serviceName ? 'serviceName' : 'port');
    this.serviceName = new UntypedFormControl(null);
    this.port = new UntypedFormControl(null);

    const monitorUserControl = new UntypedFormControl(null, [Validators.maxLength(255), Validators.required]);
    const monitorPassControl = new UntypedFormControl(null, [Validators.maxLength(255), Validators.required]);

    this.connectionPropertiesForms = new UntypedFormArray([]);
    this.connectionPropertiesForms.valueChanges.subscribe(() => {
      this.connectionPropertiesChanged = true;
    });

    this.formGroup = new UntypedFormGroup({
      alias: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255), Validators.pattern(/[\S]/)]),
      hostname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
      serviceName: this.serviceName,
      port: this.port,
      portOrSvcname: this.portOrSvcname,
      adminUser: adminUserControl,
      adminPassword: adminPassControl,
      monitorUser: monitorUserControl,
      monitorPassword: monitorPassControl,
      connectionProperties: this.connectionPropertiesForms
    });


    /*
     * Adding required validator only if admin user control has value
     */
    this.formGroup.controls.adminUser.valueChanges.subscribe(value => {
      if (value) {
        this.formGroup.controls.adminPassword.addValidators([Validators.maxLength(255), Validators.required]);
      } else {
        this.formGroup.controls.adminPassword.clearValidators();
      }
      this.formGroup.controls.adminPassword.updateValueAndValidity({onlySelf: true});
    });

    if (this.editServer) {
      for (const name in this.editServer.connectionProperties) {
        if (this.editServer.connectionProperties.hasOwnProperty(name)) {
          this.addConnectionProperty(name, this.editServer.connectionProperties[name]);
        }
      }
      this.formGroup.patchValue({
        alias: this.editServer.alias,
        hostname: this.editServer.hostname,
        serviceName: this.editServer.serviceName,
        port: this.editServer.port,
        monitorUser: this.editServer.monitorUser,
      });
      this.connectionPropertiesChanged = false;

      this.setEditingMonitorCredentials(false);
      this.editAdminCredentialsClick(false);
    }

    /**
     * Patching HQ default servername and port number available in ER node in sqlhost.
     */
    if (this.erServer) {
      const port = this.erServer.port;
      this.formGroup.patchValue({
        alias: this.erServer.name,
        port
      });
      // Disabling port field for er server as port is already present and it should be pointed to the same port.
      if (port) {
        this.port.disable();
        this.portOrSvcname.disable();
      }
    }

    this.changePortOrServiceName();

    // update isWorkOffline flag
    this.workOffSub = this.serversService.onConnStatus$.subscribe(isWorkOffline => {
      this.isWorkOffline = isWorkOffline;
    });
    // Dynamic column size for ER node to be displayed into the ER Domain add server panel.
    if (this.isERNode) {
        this.columnSize = 'col-12';
      } else {
        this.columnSize = 'col-sm-6';
      }
    this.serverInfoFormSubscriber$ = this.subscribeToERDomainAddEvent();
  }

  ngOnDestroy() {
    if (this.serverInfoFormSubscriber$ && this.serverInfoFormSubscriber$.unsubscribe) {
      this.serverInfoFormSubscriber$.unsubscribe();
    }
    if(this.workOffSub){
      this.workOffSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.formGroup.controls['connectionProperties'].value.forEach((element, index) => {
      this.matchPasswordVal(element.name, index);
    });
  }

  /**
   * Hanldes edit and undo of admin credentials fields.
   *
   * @param edit boolean
   */
  public editAdminCredentialsClick(edit: boolean) {
    this.editingAdminCredentials = edit;
    if (this.editingAdminCredentials) {
      this.formGroup.get('adminPassword').setValue(null);
      this.formGroup.get('adminUser').enable();
      this.formGroup.get('adminPassword').enable();
    } else {
      this.formGroup.get('adminUser').setValue(this.editServer.adminUser);
      this.formGroup.get('adminPassword').setValue((this.editServer.adminUser)?'top secret':null);
      this.formGroup.get('adminUser').disable();
      this.formGroup.get('adminPassword').disable();
    }
  }

  /**
   * Hanldes edit and undo of monitoring credentials fields.
   *
   * @param edit boolean
   */
  public setEditingMonitorCredentials(edit: boolean) {
    this.editingMonitorCredentials = edit;
    if (this.editingMonitorCredentials) {
      this.formGroup.get('monitorPassword').setValue(null);
      this.formGroup.get('monitorUser').enable();
      this.formGroup.get('monitorPassword').enable();
    } else {
      this.formGroup.get('monitorUser').setValue(this.editServer.monitorUser);
      this.formGroup.get('monitorPassword').setValue('top secret');
      this.formGroup.get('monitorUser').disable();
      this.formGroup.get('monitorPassword').disable();
    }
  }

  /**
   * Method to add new connection property form element into form array.
   *
   * @param name name of form field.
   * @param value value of form field.
   */
  public addConnectionProperty( name: string = null, value: string = null) {
    // true if new empty block is being added
    const isBlank = (!name && !value);
    // Adding new connection property only if new empty block is being added & connection property form is valid.
    // Only focusing newly added blank field as name & value will be always empty in this case.
    if (isBlank && !this.shouldAdd()) {
      // Focusing last name input field and return.
      this.focusLastInput();
      return;
    }


    const nameControl = new UntypedFormControl(name, [ Validators.required, this.validateConnectionPropertyName.bind(this)]);

    nameControl.valueChanges.pipe(map(v => (typeof v === 'string' ? v.trim() : v)),
      distinctUntilChanged(), pairwise()).subscribe(_value => {
      if (_value[0]) {
        const conflictingControls = this.connectionPropertiesForms.controls.map(
          (v: UntypedFormGroup) => v.controls.name).filter(v => v !== nameControl && v.value === _value[0]);
        if (conflictingControls.length > 0) {
          window.setTimeout(() => conflictingControls.forEach(v => v.updateValueAndValidity()), 0);
        }
      }

      window.setTimeout(() => (nameControl.parent as UntypedFormGroup).controls.value.updateValueAndValidity(), 0);
    });


    const valueControl = new UntypedFormControl(value, [Validators.required, this.validateConnectionPropertyValue.bind(this)]);
    const showPasswordControl = new UntypedFormControl(false);
    const hideShowIconControl = new UntypedFormControl(false);

    valueControl.valueChanges.pipe(distinctUntilChanged()).subscribe(_value => {
      window.setTimeout(() => (valueControl.parent as UntypedFormGroup).controls.value.updateValueAndValidity(), 0);
    });

    const connectionPropertiesForms: UntypedFormArray = this.formGroup.controls.connectionProperties as UntypedFormArray;

    connectionPropertiesForms.push(new UntypedFormGroup({
      name: nameControl,
      value: valueControl,
      showPasswordIcon: showPasswordControl,
      hideShowIcon: hideShowIconControl
    }));
    // For new blank, Focusing name field of last element after adding new element into connection properties form array.
     setTimeout(() => {
       if (isBlank) {
          this.focusLastInput();
      }
      }, 0);
  }

  matchPasswordVal(name, index) {
    if (name.match(/password/i)) {
      this.conValue.toArray()[index].nativeElement.type = 'password';
      this.connectionPropertiesForms.at(index).patchValue({showPasswordIcon: true});
      this.connectionPropertiesForms.at(index).patchValue({hideShowIcon: false});
    } else {
      this.conValue.toArray()[index].nativeElement.type = 'text';
      this.connectionPropertiesForms.at(index).patchValue({showPasswordIcon: false});
    }
  }

  getConName(eve, index) {
    this.matchPasswordVal(eve.target.value, index);
  }

  public removeConnectionProperty(index: number) {
    this.connectionPropertiesForms.removeAt(index);
    setTimeout(() => this.focusLastInput(), 0);
  }

  private validateConnectionPropertyName(c: AbstractControl) {
    const value = c.value ? (c.value as string).trim().toLowerCase() : null;
    if (value) {
      if (this.connectionPropertyBlacklist.indexOf(value) > -1) {
        return { customError: 'You cannot set \'' + value.toLocaleUpperCase() + '\' here' };
      }

      for (let i = 0; i < this.connectionPropertiesForms.controls.length; i++) {
        const control = this.connectionPropertiesForms.controls[i] as UntypedFormGroup;
        if (c.parent !== control && control.controls.name.valid && control.value && control.value.name
          && (control.value.name as string).trim().toLowerCase() === value) {
          return { customError: 'The property \'' + value.toLocaleUpperCase() + '\' is already set' };
        }
      }
    } else if (c.parent) {
      const parentControl = c.parent as UntypedFormGroup;
      const propValue = parentControl.controls.value.value ? (parentControl.controls.value.value as string).trim() : '';
      if (propValue) {
        return { required: true };
      }
    }

    return null;
  }

  private validateConnectionPropertyValue(c: AbstractControl) {
    if (!c.parent) {
      return null;
    }

    const value = c.value ? (c.value as string).trim() : '';
    if (!value) {
      const parentControl = c.parent as UntypedFormGroup;
      const nameValue = parentControl.controls.name.value ? (parentControl.controls.name.value as string).trim() : '';
      if (nameValue) {
        return { required: true };
      }
    }

    return null;
  }

  canSubmit() {
    return this.formGroup.valid && this.isServerInfoChanged() && !this.isServerStatusLoading;
  }

  private isServerInfoChanged() {
    if (this.editServer) {
      const form = this.formGroup.value;
      return (this.editingAdminCredentials
        || this.editingMonitorCredentials
        || this.connectionPropertiesChanged
        || form.alias !== this.editServer.name
        || form.hostname !== this.editServer.hostname
        || form.serviceName !== this.editServer.serviceName
        || form.port !== this.editServer.port);
    }

    return true;
  }

  public onSubmit() {
    if (!this.canSubmit()) {
      return;
    }

    const requestData = this.getRequestData();
    this.isServerStatusLoading = true;
    if (this.editServer) {
      this.serversService.editServer(this.editServer.id, requestData).then(updatedServer => {
        this.isServerStatusLoading = false;
        this.editServer.adminUser = updatedServer.adminUser;
        this.editServer.monitorUser = updatedServer.monitorUser;
        this.setEditingMonitorCredentials(false);
        this.editAdminCredentialsClick(false);
        this.connectionPropertiesChanged = false;
        this.notificationsService.pushSuccessNotification('Server updated');
        this.serverUpdate.emit(updatedServer);
      }).catch((err: HttpErrorResponse) => {
        this.isServerStatusLoading = false;
        console.error('Could not edit server', err);
        this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
      });
    } else {
      requestData.groupId = this.groupId;
      this.serversService.createServer(requestData).then(newServer => {
        this.isServerStatusLoading = false;
        this.notificationsService.pushSuccessNotification('Server added');
        // Emiting server add event for ER node instead redirecting to the routing as this page in the panel now.
        if (!this.isERNode) {
          this.router.navigate(['dashboard/servers', newServer.id]);
        } else {
          this.serverUpdate.emit(newServer);
        }
      }).catch((err: HttpErrorResponse) => {
        this.isServerStatusLoading = false;
        console.error('Could not create server', err);
        this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
      });
    }
  }

  changePortOrServiceName() {
    if (this.portOrSvcname.value === 'port') {
      this.serviceName.clearValidators();
      this.port.setValidators([Validators.required, Validators.min(1), Validators.max(65535)]);
    } else if (this.portOrSvcname.value === 'serviceName') {
      this.port.clearValidators();
      this.serviceName.setValidators([Validators.required, Validators.maxLength(255)]);
    }
    this.serviceName.updateValueAndValidity({ emitEvent: false });
    this.port.updateValueAndValidity({ emitEvent: false });
  }

  testConnection() {
    if (!this.formGroup.valid) {
      return;
    }

    if(this.isWorkOffline) {
      return;
    }

    this.isServerStatusLoading = true;
    const requestData = this.getRequestData(true);
    if (this.editServer) {
      requestData.id = this.editServer.id;
    }
    this.serversService.testConnection(requestData).subscribe(response => {
      const informixServerClass = new InformixServer({});
      this.serverStatus = informixServerClass.getStatusByCode(response.code);
      // d
      this.isServerStatusLoading = false;
      this.serverStatusErrorMessage = null;
      if (this.serverStatus) {
        const statusColor =  informixServerClass.getStatusColorByCode(response.code);
        this.alertClass = 'alert alert-' + statusColor + ' mt-4';
        this.iconClass = 'icon icon-check-circle text-' + statusColor;
      }
    }, err => {
      this.isServerStatusLoading = false;
      this.alertClass = null;
      this.iconClass = null;
      this.serverStatusErrorMessage = err.error ? err.error.err : err;
    });
  }

  private getRequestData(isTestConnection: boolean = false): any {
    const controls: any = this.formGroup.controls;
    const requestData: any = {
      alias: controls.alias.value.trim(),
      hostname: controls.hostname.value,
      serviceName: controls.serviceName.value,
      port: controls.port.value
    };

    if (!this.editServer || this.editingAdminCredentials) {
      requestData.adminUser = controls.adminUser.value;
      requestData.adminPassword = controls.adminPassword.value;
    }

    if (!this.editServer || this.editingMonitorCredentials) {
      requestData.monitorUser = controls.monitorUser.value;
      requestData.monitorPassword = controls.monitorPassword.value;
    }

    const connectionProperties = {};
    controls.connectionProperties.value.forEach(prop => connectionProperties[prop.name] = prop.value);
    requestData.connectionProperties = connectionProperties;

    if (this.portOrSvcname.value === 'serviceName') {
      this.formGroup.get('port').reset();
      requestData.port = null;
    } else if (this.portOrSvcname.value === 'port') {
      this.formGroup.get('serviceName').reset();
      requestData.serviceName = null;
    }

    if (this.editServer && !isTestConnection) {
      requestData.groupId = this.editServer.parentGroupId;
    }

    return requestData;
  }


  /**
   * Method to focus name input field of last form array.
   */
   focusLastInput() {
     if (this.nameInputCon) {
      this.nameInputCon.last.nativeElement.focus();
    }
  }

  /**
   * Preventing user from adding multiple blank connection properties with invalid form validation.
   *
   * @returns validation of form
   */
   shouldAdd(): Boolean {
    const connectionPropsForm: UntypedFormArray = this.connectionPropertiesForms;
    return connectionPropsForm.valid;
  }

  /**
   * Subscribing to add button from ER domain add server panel.
   *
   * @returns subscriber to unsubscribe it once component is destroy
   */
  subscribeToERDomainAddEvent() {
    return this.serversService.serverInforForm$.subscribe(value => {
      this.onSubmit();
    });
  }

  toggleShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.monitorPassword.value === '') {
        this.showPassword = false;
      }
      return;
    }
    this.showPassword = !this.showPassword;
  }

  toggleAdminPassShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.adminPassword.value === '') {
        this.showAdminPassword = false;
      }
      return;
    }
    this.showAdminPassword = !this.showAdminPassword;
  }

  togglePassShow(index, flag: boolean = false) {
    const control = this.connectionPropertiesForms.controls[index] as UntypedFormGroup;
    if(flag) {
      if(control.controls.value.value === '') {
        this.connectionPropertiesForms.at(index).patchValue({hideShowIcon: false});
      }
      return;
    }
    if(control.controls.hideShowIcon.value){
      this.connectionPropertiesForms.at(index).patchValue({hideShowIcon: false});
    } else {
      this.connectionPropertiesForms.at(index).patchValue({hideShowIcon: true});
    }
  }
}
