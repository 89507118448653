/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2020. All Rights Reserved.
 *******************************************************************************/
import { ChartJSUtils } from '../../../../shared/chartjs.utils';
import { JSONUtils } from '../../../../shared/json-utils';
import { InformixServer } from '../../../servers/informixServer';
import { SensorMetric, SensorType } from '../../sensor';

const SERVER_NAME_PLACEHOLDER = '%server.name%';

const axisTypeMap: { [key: string]: string } = {
  number: 'number',
  percentage: 'percent',
  bytes: 'bytes'
};

export class DataSeriesConfig {
  id: string;
  color: string;
  label: string;
  yAxis?: number;

  constructor(json: any) {
    this.id = json.id;
    this.color = json.color;
    this.label = (json.label || '').trim();
    this.yAxis = json.yAxis || 0;
  }

  getLabel(server: InformixServer) {
    return this.label ? this.label.replace(SERVER_NAME_PLACEHOLDER, server.name) : this.id;
  }
}

export class SensorChartjsPluginConfig {

  isCustomQuery: boolean;
  dataSources: any[];
  dataSeries: DataSeriesConfig[];
  yAxes: any[];
  serverIndex: number;
  chartType: string;
  databaseName: string;
  queryString: string;

  private dataSeriesMap = new Map<string, DataSeriesConfig>();

  constructor(json: any) {
    json = json ? JSONUtils.deepClone(json) : {};
    this.isCustomQuery = json.isCustomQuery;
    this.dataSources = json.dataSources || [];
    this.dataSeries = (json.dataSeries || []).map(v => new DataSeriesConfig(v));
    this.dataSeries.forEach(series => this.dataSeriesMap.set(series.id, series));
    this.yAxes = json.yAxes || [];
    this.chartType = json.chartType ? json.chartType : 'line';
    this.serverIndex = typeof json.serverIndex === 'number' ? json.serverIndex : null;
    this.databaseName = json.databaseName;
    this.queryString = json.queryString;
  }

  getSeriesConfig(id: string);
  getSeriesConfig(sensorType: SensorType, metric: SensorMetric, primaryKey?: string, index?: number): DataSeriesConfig;
  getSeriesConfig(idOrSensorType: SensorType | string, metric?: SensorMetric, primaryKey?: string, index?: number): DataSeriesConfig {
    const sensorType: SensorType = typeof idOrSensorType === 'string' ? null : idOrSensorType;
    const seriesId: string = sensorType ? this.getSeriesId(sensorType, metric, primaryKey, index) : idOrSensorType as string;
    let config = this.dataSeriesMap.get(seriesId);
    if (!config && sensorType) {
      config = this.generateDefaultSeriesConfig(sensorType, metric, primaryKey, index);
      this.dataSeries.push(config);
      this.dataSeriesMap.set(config.id, config);
    } else if (this.isCustomQuery && !config) {
      config = new DataSeriesConfig({ id: seriesId, color: ChartJSUtils.getRandomColor(), label: seriesId, yAxis: 0 });
      this.dataSeries.push(config);
      this.dataSeriesMap.set(config.id, config);
    }
    return config;
  }

  getSeriesConfigs(sensorType: SensorType) {
    const prefix = sensorType.id + '.';
    return this.dataSeries.filter(series => series.id.startsWith(prefix));
  }

  private getSeriesId(sensorType: SensorType, metric: SensorMetric, primaryKey?: string, index?: number): string {
    let seriesId = sensorType.id + '.' + metric.id;
    if (primaryKey) {
      seriesId += '.' + primaryKey;
    }
    if (typeof index === 'number') {
      seriesId += '.' + index;
    }
    return seriesId;
  }

  private generateDefaultSeriesConfig(sensorType: SensorType, metric: SensorMetric, primaryKey?: string, index?: number): DataSeriesConfig {
    let baseConfig: DataSeriesConfig = null;
    let label = sensorType.name + ' - ' + metric.name;
    if (sensorType.hasPrimaryKey() && primaryKey) {
      label += ' - ' + primaryKey;
    }
    if (typeof index === 'number') {
      baseConfig = this.getSeriesConfig(sensorType, metric, primaryKey);
      if (baseConfig) {
        label = baseConfig.label;
      }
      if (label.indexOf(SERVER_NAME_PLACEHOLDER) < 0) {
        label += ' - ' + SERVER_NAME_PLACEHOLDER;
      }
    }

    this.autoConfigureYAxes(sensorType);
    const axisType = axisTypeMap[metric.unit] || 'number';
    let yAxis = this.yAxes.findIndex(v => v.type === axisType);
    if (yAxis < 0) {
      yAxis = 0;
    }

    const config = new DataSeriesConfig({
      id: this.getSeriesId(sensorType, metric, primaryKey, index),
      label,
      color: ChartJSUtils.getDefaultColor(this.dataSeries.length),
      yAxis
    });

    if (baseConfig) {
      config.yAxis = baseConfig.yAxis;
    }

    return config;
  }

  autoConfigureYAxes(sensorType: SensorType) {
    let minY: number = null;
    let maxY: number = null;
    if (sensorType.meta.chart) {
      if (typeof sensorType.meta.chart.minY === 'number') {
        minY = sensorType.meta.chart.minY;
      }
      if (typeof sensorType.meta.chart.maxY === 'number') {
        maxY = sensorType.meta.chart.maxY;
      }
    }

    for (let i = 0; i < sensorType.metrics.length; i++) {
      if (this.yAxes.length >= 2) {
        return;
      }

      const metric = sensorType.metrics[i];
      const axisType = axisTypeMap[metric.unit] || 'number';
      let axis = this.yAxes.find(v => v.type === axisType);
      if (!axis) {
        axis = { type: axisType };
        this.yAxes.push(axis);
      }

      if (minY !== null && (typeof axis.min !== 'number' || minY < axis.min)) {
        axis.min = minY;
      }
      if (maxY !== null && (typeof axis.max !== 'number' || minY < axis.max)) {
        axis.max = maxY;
      }
    }
  }

  toJson(): any {
    const json: any = {
      dataSources: this.dataSources,
      dataSeries: this.dataSeries,
      yAxes: this.yAxes
    };

    if (this.serverIndex !== null) {
      json.serverIndex = this.serverIndex;
    }
  }
}
