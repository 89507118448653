/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserSettingsService {

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  public changePassword(oldPassword: string, newPassword: string, username?: string): Promise<any> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + oldPassword) });
    return this.httpClient.put<any>('users/current/password', {
      old: oldPassword,
      new: newPassword
    }, { headers }).toPromise<any>();
  }

  public changeLanguage(newLanguage: string) {
    this.translate.use(newLanguage);
    this.httpClient.put<{ language: string }>('users/current/preferences', {
      language: newLanguage,
    }).subscribe({
      error: err => this.notificationsService.pushErrorNotification(err.error ? err.error.err : err)
    });
  }

  public changeUserPreferences(data: object): Observable<any> {
    return this.httpClient.put<any>('users/current/preferences', {...data});
  }

  public changeTheme(theme: string): Observable<any> {
    return this.httpClient.put<{ theme: string }>('users/current/preferences', { theme });
  }

  public changeHistorySetting(value: string): Observable<any>{
    return this.httpClient.put<any>('users/current/preferences', { value });
  }

  public getUserPreferences(): Observable<any> {
    return this.httpClient.get<any>('users/current/preferences');
  }
}
