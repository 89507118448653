/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';

import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { User } from '../../../shared/user/user';
import { UserService } from '../../../shared/user/user.service';
import { SystemConfigService } from '../../system-settings/system-config.service';
import { AbstractSettingsComponent } from './abstractSettings.component';
import { AlertMessenger, AlertProfile } from './alert';
import { AlertSettingsService } from './alertSettings.service';
import {environment} from '../../../../environments/environment';

class AlertType {
  public available = false;

  constructor(
    public id: string,
    public name: string
  ) { }
}

@Component({
  selector: 'app-alert-settings-component',
  templateUrl: './alertSettings.html'
})
export class AlertSettingsComponent implements OnInit {

  alertTypes: AlertType[] = [
    new AlertType('email', 'Email'),
    new AlertType('twilio', 'Twilio')
  ];

  alertType: AlertType = this.alertTypes[0];

  @ViewChild('settings') settingsComponent: AbstractSettingsComponent;
  @ViewChild('preferences') preferencesComponent: AbstractSettingsComponent;

  alertingEnabled = true;
  messengers: { [key: string]: AlertMessenger } = null;
  settingsValid = false;
  settingsModified = false;
  productNameNoSpace = environment.productNameNoSpace;

  private defaultAlertProfileId: number = null;
  alertProfile: AlertProfile = null;

  currentUser: User = null;

  constructor(
    private systemConfigService: SystemConfigService,
    private alertSettingsService: AlertSettingsService,
    private notifications: NotificationsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.alertSettingsService.getAlertMessengers().then((messengers: any[]) => {
      this.messengers = {};
      messengers.forEach(messenger => {
        this.messengers[messenger.type] = messenger;
      });

      this.getSystemAlertConfigs();
      this.getAlertProfiles();
    }).catch(err => {
      console.error('Could not get user alert messengers', err);
    });

    this.userService.getCurrentUser().then(user => {
      this.currentUser = user;
    }).catch(err => {
      console.error(err);
    });
  }

  private getSystemAlertConfigs() {
    this.systemConfigService.getConfigs().then(configs => {
      this.alertTypes.forEach(type => {
        const config = configs[type.id];
        type.available = (config && config.enabled === true);
      });
    }).catch(err => {
      console.error('Could not get system alert configurations', err);
    });
  }

  private getAlertProfiles() {
    this.alertSettingsService.getAlertProfiles().then(profiles => {
      if (profiles.default) {
        const foundProfile = (profiles.alertingprofiles as any[]).find(v => v.id === profiles.default);
        if (foundProfile) {
          this.alertProfile = foundProfile;
          this.defaultAlertProfileId = foundProfile.id;
          this.alertingEnabled = true;
        }
      }

      if (!this.alertProfile) {
        this.alertingEnabled = false;
        if (profiles.alertingprofiles.length > 0) {
          this.alertProfile = profiles.alertingprofiles[0];
          this.defaultAlertProfileId = this.alertProfile.id;
        } else {
          this.alertProfile = {
            id: null,
            name: 'Default Profile',
            instant: true,
            timeout: 60 * 60 * 1000, // 1 hour
            messenger: null
          };
          this.defaultAlertProfileId = null;
        }
      }

      this.alertType = this.alertTypes.find(
        type => this.messengers[type.id] && this.messengers[type.id].id === this.alertProfile.messenger
        ) || this.alertTypes[0];
    }).catch(err => {
      console.error('Error getting user alert profiles', err);
    });
  }

  saveSettings() {
    const settings = this.settingsComponent.getSettings();
    settings.type = this.alertType.id;
    settings.name = this.alertType.name;
    const messenger = this.messengers[this.alertType.id];
    let promise: Promise<any>;
    if (messenger) {
      promise = this.alertSettingsService.updateAlertMessenger(messenger.id, settings);
    } else {
      promise = this.alertSettingsService.createAlertMessenger(settings);
    }

    promise.then(response => {
      settings.id = messenger ? messenger.id : response.id;
      this.messengers[settings.type] = settings;

      let profilePromise: Promise<any>;
      this.alertProfile.messenger = settings.id;
      const preferences = this.preferencesComponent.getSettings();
      for (const key in preferences) {
        if (preferences.hasOwnProperty(key)) {
          this.alertProfile[key] = preferences[key];
        }
      }
      if (this.alertProfile.id) {
        profilePromise = this.alertSettingsService.updateAlertProfile(this.defaultAlertProfileId, this.alertProfile);
      } else {
        profilePromise = this.alertSettingsService.createAlertProfile(this.alertProfile).then(profileResponse => {
          this.alertProfile.id = this.defaultAlertProfileId = profileResponse.id;
          return this.alertSettingsService.setDefaultAlertProfile(this.alertProfile.id);
        });
      }

      return profilePromise.then(() => {
        this.notifications.pushSuccessNotification('Alert settings updated');
      });
    }).catch(err => {
      console.error(err);
      this.notifications.pushErrorNotification('There was a problem updating alert settings');
    });
  }

  onSettingsChange(event: any) {
    if (!this.settingsComponent || !this.preferencesComponent) {
      return;
    }
    this.settingsValid = this.settingsComponent.isValid() && this.preferencesComponent.isValid();
    this.settingsModified = event.modified;
  }

  toggleAlertingEnabled() {
    this.alertingEnabled = !this.alertingEnabled;
    if (!this.defaultAlertProfileId) {
      return;
    }

    let promise: Promise<any>;
    if (this.alertingEnabled) {
      promise = this.alertSettingsService.setDefaultAlertProfile(this.defaultAlertProfileId);
    } else {
      promise = this.alertSettingsService.removeDefaultAlertProfile();
    }

    promise.then(() => {
      this.notifications.pushSuccessNotification('Alerts ' + (this.alertingEnabled ? 'enabled' : 'disabled'));
    }).catch(err => {
      console.error(err);
      this.notifications.pushErrorNotification('There was a problem updating alert settings');
    });
  }

  isSaveDisabled() {
    if (!this.settingsValid) {
      return true;
    }
    if (!this.alertType.available) {
      return true;
    }
    if (!this.messengers[this.alertType.id] || this.messengers[this.alertType.id].id !== this.alertProfile.messenger) {
      return false;
    }
    return !this.settingsModified;
  }
}
