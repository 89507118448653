/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
export class TaskResponse {
    tk_id: Number;
    tk_name: String;
    tk_group: String;
    tk_start_time: String;
    tk_frequency: String;
    tk_monday: Boolean;
    tk_tuesday: Boolean;
    tk_wednesday: Boolean;
    tk_thursday: Boolean;
    tk_friday: Boolean;
    tk_saturday: Boolean;
    tk_sunday: Boolean;
    tk_enable: Boolean;
    tk_next_execution: String;
}

export class TaskDetails {
    tk_id: Number;
    tk_name: String;
    tk_description: String;
    tk_type: String;
    tk_sequence: Number;
    tk_result_table: String;
    tk_create: String;
    tk_dbs: String;
    tk_execute: String;
    tk_delete: Object;
    tk_start_time: Object;
    tk_stop_time: Object;
    tk_frequency: Object;
    tk_next_execution: Object;
    tk_total_executions: Number;
    tk_total_time: Number;
    tk_monday: Boolean;
    tk_tuesday: Boolean;
    tk_wednesday: Boolean;
    tk_thursday: Boolean;
    tk_friday: Boolean;
    tk_saturday: Boolean;
    tk_sunday: Boolean;
    tk_attributes: Number;
    tk_group: String;
    tk_enable: Boolean;
    tk_priority: Number;
}

export class RunHistory {
    tk_name: String;
    tk_total_executions: Number;
    tk_average_time: String;
    tk_total_time: String;
    tk_run_time: String;
    tk_run_retcode: Boolean;
}

export class RunHistoryArray {
    response: Array<RunHistory>;
}
