/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved
 *******************************************************************************/
import { Component, Input } from '@angular/core';
import { InformixTableDetails } from '../../informix-table';

@Component({
  selector: 'app-table-columns-info',
  templateUrl: './table-columns-info.component.html'
})
export class TableColumnsInfoComponent {

  @Input() tableDetails: InformixTableDetails;

  constructor() { }

}
