<div>
  <div class="d-flex pr-2 sql-editor-tab">
    <div class="d-flex">
      <label class="toggle-button">
        <input type="checkbox" (change)="toggleMultiLine()" [checked]="isMultiLine">
        <span class="toggle-slider round"></span>
      </label>
      <span class="align-items-center" style="padding-left: 12px;">Multi Statement Execution</span>
    </div>
  </div>
  <div class="mt-3" [style.marginLeft.px]="265">
    <textarea #sqlEditor class="form-control text-monospace" rows="10" [formControl]="queryFormControl"
      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" (keydown)="onQueryKeyDown($event)">
    </textarea>

    <div class="row mt-3">
      <div class="col-12 float-left">
        <button type="button" class="btn btn-primary" [disabled]="!queryFormControl.value" (click)="onRunClick()"
          *ngIf="!isRunning">
          Run
        </button>
        <button type="button" class="btn btn-primary" (click)="onCancelClick()" *ngIf="isRunning">
          Cancel
        </button>
        <button class="btn btn-secondary ml-2" (click)="showQueryHistory(true)">
          Query History </button>
        <button class="btn btn-secondary ml-2" (click)="importFile()">
          Import
        </button>
        <a [href]="downloadJsonHref" [ngClass]="{'disabled': !queryFormControl.value}" download="sql_queries.sql"
          (click)="exportQueryAs('sql')" [attr.disabled]="!queryFormControl.value ? true : null"
          class="btn btn-md text-primary border border-primary ml-2">
          Export
        </a>
        <div class="d-inline-block pl-2" *ngIf="isMultiLine">
          <div class="d-flex">
            <label class="toggle-button" [tooltip]="isBatchMode ? 'You have enabled batch mode' : ''">
              <input type="checkbox" (change)="toggleContinueOnFailure()" [checked]="isContinueOnFailure" [disabled]="isBatchMode">
              <span class="toggle-slider round"></span>
            </label>
            <span class="align-items-center" style="padding-left: 12px;">Continue on failure</span>
          </div>
        </div>

        <div class="d-inline-block pl-2" *ngIf="isMultiLine">
          <div class="d-flex">
            <label class="toggle-button">
              <input type="checkbox" (change)="toggleAutoCommit()" [checked]="isAutoCommit">
              <span class="toggle-slider round"></span>
            </label>
            <span class="align-items-center" style="padding-left: 12px;">Auto-commit</span>
          </div>
        </div>

        <div class="d-inline-block pl-2" *ngIf="isMultiLine">
          <div class="d-flex">
            <label class="toggle-button">
              <input type="checkbox" (change)="toggleBatchMode()" [checked]="isBatchMode">
              <span class="toggle-slider round"></span>
            </label>
            <span class="align-items-center" style="padding-left: 12px;">Run as Batch Mode</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between mt-4 mb-1" *ngIf="isShowResultTab && isMultiLine">
    <div class="font-weight-bold d-flex align-items-center">Result</div>
    <button class="font-weight-bold btn btn btn-secondary" (click)="clearResults()">Clear</button>
  </div>
  <div class="mt-2 position-relative">
    <div class="mt-2 p-2 position-relative" [ngClass]="{'border border-gray-20': isShowResultTab }"  [ngStyle]="{ 'min-height': isShowResultTab ? '95vh' : 'inherit'}">
      <div class="table-list-container card border-top-0 border-left-0" *ngIf="isShowResultTab && isMultiLine">
        <div class="list-group list-group-flush flex-1 overflow-auto">
          <div class="p-2 border border-gray-20">
            <div class="alert alert-info p-2">
              <i class="icon icon-info icon-info-circle text-info pr-1"></i>
              <span>Choose any SQL statement below to access the
                most up-to-date data.</span>
            </div>
          </div>
        
          <ul class="list-unstyled">
            <li *ngFor="let query of slicedQueries" class="border border-gray-20">
              <div class="p-0 outline-none">
                <button (click)="selectedQuery = query; mapQueryResults(query);"
                  class="color-black border-0 w-100 text-left text-monospace" [style.height.px]="40"
                  [ngClass]="selectedQuery === query ? 'border-right bg-gray-20' : 'bg-white'">
                  <div class="one-line pl-2" [tooltip]="query.query">{{query.query}}</div>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      
      <!-- query result -->
        <div [style.marginLeft.px]="255">
        <div class="mt-2 w-100 d-inline-block">
          <div class="button-container mt-2" *ngIf="!isExcution">
            <div class="align-items-center float-left" *ngIf="resultTable">
              <button class="btn btn-secondary" (click)="cursorPrev()" [disabled]="isLoading || resultCurrentPage < 1">
                <i class="icon icon-chevron-left"></i>
              </button>
              <div class="d-inline-block text-center" [style.width.px]="100">
                {{ resultCurrentPage + 1}} / {{ isAllRecordsFetched() ? resultTablePages.length : '-' }}
              </div>
              <button class="btn btn-secondary" (click)="cursorNext()"
                [disabled]="isLoading || hasNextQueryResult()">
                <i class="icon icon-chevron-right"></i>
              </button>
              <button class="btn btn-secondary" (click)="onRunAgainClick()" [disabled]="isLoading" *ngIf="multiQueryResults.length === 1">
                <i class="icon icon-refresh"></i>
              </button>
            </div>
            <div class="align-items-center float-right" *ngIf="isShowResultTab && !resultMessage && !errorMessage">
              <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
              Run time: {{selectedQueryResult?.runTime < 1000 ? selectedQueryResult?.runTime + 'ms' : (selectedQueryResult?.runTime / 1000).toFixed(3) + 's' }}
                <button class="btn btn-secondary ml-2" (click)="isExcution = !isExcution; onExecutionPlanTabSelect()" *ngIf="!isMultiLine">
                  Execution Plan
                  </button>
            </div>
          </div>
          <div class="mt-2 p-2" *ngIf="isExcution">
            <div *ngIf="!showSqlTraceState" class="alert alert-warning mt-2">
              <i class="icon icon-warning text-warning"></i> To view query execution plan enable SQL tracing. Currently
              SQL tracing is disable for <strong>{{database}}</strong> database. <a href="javascript:;"
                (click)="showSqlTraceConfigState = !showSqlTraceConfigState; $event.stopPropagation()">Click here</a> to
              enable SQL tracing & configure.
            </div>
            <div *ngIf="showSqlTraceState" class="alert alert-info mt-2">
              SQL tracing is currently enabled. To disable SQL tracing please
              <a href="javascript:;"
                (click)="showSqlTraceConfigState = !showSqlTraceConfigState; $event.stopPropagation()">click here</a>.
            </div>
            <div *ngIf="showSqlTraceConfigState">
              <div class="col-6 p-0">
                <app-sqltrace-config [server]="server" [config]="sqltraceConfig" (success)="handleActionSuccess($event)"
                  (error)="handleActionError($event)" [database]="database"></app-sqltrace-config>
              </div>
            </div>
            <div class="mt-2 text-center" *ngIf="fetchingTreeStatus">
              <i class="icon icon-circle-notch icon-spin icon-2x"></i>
            </div>
            <div class="mt-2" *ngIf="sqltraceIteratorData && !showSqlTraceConfig">
              <div *ngIf="sqltraceIteratorData.length == 0" class="alert alert-info mt-2">
                No Execution plan found for this query.
              </div>
              <div *ngIf="sqltraceIteratorData.length > 0 && showSqlTraceState" class="p-0">
                <app-sqltrace-query-tree [sqltraceIterators]="sqltraceIteratorData"
                  [maxHeight]="tabsDataHeight"></app-sqltrace-query-tree>
              </div>
            </div>
            <div *ngIf="terminationState && showSqlTraceState" class="alert alert-warning mt-2">
              <i class="icon icon-warning text-warning"></i> Execution plan fetch terminated by user. To try again
              please <a href="javascript:;" (click)="getStatementIterators(); $event.stopPropagation()">click here</a>.
            </div>
          </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger mb-2">
          {{errorMessage}}
          <div *ngIf="sqlException">{{sqlException}}</div>
          <div *ngIf="informixError">{{informixError}}</div>
          <div *ngIf="statementOffset">{{statementOffset}}</div>
        </div>

        <button *ngIf="showRerunButton" class="btn btn-secondary" (click)="onRunAgainClick()">
          <i class="icon icon-refresh"></i> Run again
        </button>

        <div *ngIf="resultMessage" class="alert alert-info mb-2 p-4">
          {{resultMessage}}
          <div class="align-items-center float-right">
            Run time: {{selectedQueryResult?.runTime < 1000 ? selectedQueryResult?.runTime + 'ms' : (selectedQueryResult?.runTime / 1000).toFixed(3) + 's' }} 
            <button class="btn btn-secondary ml-2 " [style.marginTop.px]="-6" (click)="isExcution = !isExcution; onExecutionPlanTabSelect()" *ngIf="!isMultiLine">
              Execution Plan
              </button>
          </div>
        </div>

        <div *ngIf="resultTable && !isExcution" class="mt-2 overflow-x">
          <app-query-result-table [resultPage]="resultTable"></app-query-result-table>
        </div>

        <div class="alert alert-info mb-2" *ngIf="showHint && !isMultiLine">
          <i class="icon icon-info icon-info-circle text-info pr-1"></i>
          Besides SQL statements, there are special commands you can run as well, such as <code>onstat</code>. Type
          <code>help</code> to learn more.
        </div>
      </div>
    </div>
  </div>

  <div bsModal #excutionPlanModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">Fetching Execution Plan</h5>
          <button *ngIf="takingMoreTime" type="button" class="close" (click)="terminateAndClose()">
            <i class="icon icon-times"></i>
          </button>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
          <div>
            <div *ngIf="!takingMoreTime">
              <div class="pb-3"><i class="icon icon-info icon-info-circle text-info pr-1"></i> Fetching SQL execution
                plan
                for given query</div>
              <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuenow="count*10" aria-valuemin="0"
                  aria-valuemax="100" [style.width.%]="count*10">
                </div>
              </div>
            </div>
            <div *ngIf="takingMoreTime">
              <div class="pb-3"><i class="icon icon-warning text-warning pr-1"></i> Fetching
                SQL execution plan taking more time than usual. Do you want to try again ?</div>
              <div class="pb-3"><i class="icon icon-info icon-info-circle text-info pr-1"></i> Alternate
                solution : Re-run the same query from SQL editor.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <div *ngIf="!takingMoreTime">
              <button type="button" class="btn btn-primary  mr-1"
                (click)="terminationState = true;terminatePlanExcution()">
                Terminate
              </button>
            </div>
            <div *ngIf="takingMoreTime">
              <button type="button" class="btn btn-secondary  mr-1" (click)="terminateAndClose()">
                Cancel
              </button>
              <button type="button" class="btn btn-primary" (click)="getStatementIterators(true)">
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #runAgainQuertModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">Fetching Execution Plan</h5>
        </div>
        <div class="modal-body p-3 pt-4 pb-4">
          <div>
            <div class="pb-3">
              <i class="icon icon-warning text-warning"></i> To view execution plan, query must be re-executed. Do you
              want to run the query?
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button type="button" class="btn btn-secondary btn-fixed-width mr-1" (click)="closeRunAgainModal();">
              No
            </button>
            <button type="button" class="btn btn-primary btn-fixed-width mr-1" (click)="viewQueryTree()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #importQueryModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">Import</h5>
          <div class="outline-none">
            <button type="button" class="close" (click)="importQueryModal.hide()">
              <i class="icon icon-times"></i>
            </button>
          </div>
        </div>
        <div class="modal-body pl-3 pr-3" style="padding-top: 10px;">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="">Select SQL file</label>
                <input #file type="file" class="form-control-sm" name="name" id="name" accept=".sql">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="d-inline-block">
                <div class="d-flex">
                  <label class="toggle-button">
                    <input type="checkbox" (change)="toggleBatchMode()" [checked]="isBatchMode">
                    <span class="toggle-slider round"></span>
                  </label>
                  <span class="d-flex justify-content-center" style="padding-left: 12px;">Run as Batch Mode</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-secondary" (click)="importQueryModal.hide()">Close</button>
              <button type="button" class="btn btn-primary btn-fixed-width float-right" (click)="importSqlFile(file)">
                Load
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #confirmationModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">Uncommitted Transaction Found</h5>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-center">
              <div class="p-2">
                  <i class="icon icon-2x icon-warning text-warning"></i>
              </div>
              Save with "Commit" or discard with "Rollback".
          </div>
      </div>
        <div class="modal-footer">
          <div>
            <button type="button" class="btn btn-secondary btn-fixed-width mr-1" (click)="rollback();">
              Rollback
            </button>
            <button type="button" class="btn btn-primary btn-fixed-width mr-1" (click)="commit();">
              Commit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
