/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { AlertingModule } from '../alerting/alerting.module';
import { IncidentsModule } from '../incidents/incidents.module';
import { MonitoringModule } from '../monitoring/monitoring.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { ServersModule } from '../servers/servers.module';
import { ErrorForbiddenComponent } from './forbidden.component';
import { GroupAlertingComponent } from './group-alerting.component';
import { GroupCardComponent } from './group-card.component';
import { GroupResolver } from './group-resolver.service';
import { GroupComponent } from './group.component';
import { GroupMonitoringComponent } from './groupMonitoring.component';
import { GroupPermissionsComponent } from './groupPermissions.component';
import { GroupsComponent } from './groups.component';
import { InformixServerGroupService } from './informixServerGroup.service';
import { MoveItemsComponent } from './move-items.component';
import { NotFoundComponent } from './not-found.component';
import { ServerCardComponent } from './server-card.component';
import { DashboardModule } from '../monitoring/dashboard/dashboard.module';
import { GroupIncidentsComponent } from './groupIncidents.component';

@NgModule({
  imports: [
    AlertModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ServersModule,
    SharedModule,
    TooltipModule,
    BsDropdownModule,
    MonitoringModule,
    AlertingModule,
    PermissionsModule,
    DashboardModule,
    IncidentsModule
  ],
  exports: [],
  declarations: [
    GroupsComponent,
    GroupComponent,
    GroupCardComponent,
    ServerCardComponent,
    MoveItemsComponent,
    GroupMonitoringComponent,
    GroupAlertingComponent,
    GroupPermissionsComponent,
    ErrorForbiddenComponent,
    NotFoundComponent,
    GroupIncidentsComponent
  ],
  providers: [
    InformixServerGroupService,
    GroupResolver
  ],
})
export class GroupsModule { }
