<form role="form" [formGroup]="formGroup">
  <h5 class="mb-4"><strong>Create Space</strong></h5>
  <div class="form-group mb-2">
    <label><strong>Name</strong></label>
    <input class="form-control col-sm-4" formControlName="spaceName" type="text" tooltip="" appExtendedFormControl appFocusOnCreate>
  </div>
  <div class="form-group mb-2">
    <label><strong>Type</strong></label>
    <select formControlName="type" class="form-control col-sm-2">
      <option *ngFor="let typeOption of typeOptions" value="{{typeOption.type}}">{{typeOption.label}}</option>
    </select>
  </div>
  <div class="form-group mb-2">
    <label><strong>Size</strong></label>
    <div class="col-sm-3 pl-0">
      <app-input-size formControlName="size" min="1" tooltip="" appExtendedFormControl></app-input-size>
    </div>
  </div>
  <div class="form-group mb-2">
    <label><strong>Page Size</strong></label>
    <select formControlName="pageSize" class="form-control col-sm-1" tooltip="" appExtendedFormControl>
        <option *ngFor="let size of pageSizeOptions">{{size}}</option>
    </select>
  </div>
  <div class="form-group mb-2">
    <label><strong>Offset</strong></label>
    <div class="col-sm-3 pl-0">
      <app-input-size formControlName="offset" min="0" tooltip="" appExtendedFormControl></app-input-size>
    </div>
  </div>
  <div class="form-group mb-2">
    <label><strong>Location</strong></label>
    <app-radio-group class="col-10" formControlName="locationOption">
      <div class="row align-items-center">
        <div class="col-auto">
          <app-radio value="path" class="ml-4">
            Specify the path
          </app-radio>
        </div>
        <div class="col">
          <input formControlName="path" class="form-control col-sm-6" type="text" tooltip="" appExtendedFormControl>
        </div>
      </div>
      <app-radio class="mt-2 ml-4" value="pool" [tooltip]="hasStoragePoolEntries ? '' : 'There are no active storage pool entries on the server.'"
                 [disabled]="!hasStoragePoolEntries">
        Create the space from the storage pool
      </app-radio>
    </app-radio-group>
  </div>
  <div class="form-group mb-2">
    <app-checkbox formControlName="expandable">
      <strong>Make the space extendable</strong>
    </app-checkbox>
  </div>
  <div class="form-group ml-4">
    <label><strong>Create Size</strong></label>
    <div class="col-sm-3 pl-0">
      <app-input-size-percent-or-kb formControlName="createSize" type="number" minKB="1000" tooltip="" appExtendedFormControl></app-input-size-percent-or-kb>
    </div>
  </div>
  <div class="form-group ml-4">
    <label><strong>Extend Size</strong></label>
    <div class="col-sm-3 pl-0">
      <app-input-size-percent-or-kb formControlName="extendSize" type="number" minKB="1000" tooltip="" appExtendedFormControl></app-input-size-percent-or-kb>
    </div>
  </div>
  <div class="form-group">
    <div class="mt-5">
      <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="doCreateSpace()" [disabled]="!formGroup.valid">Create</button>
    </div>
  </div>
</form>
