/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalDirective, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BuildInfoService } from './buildInfo.service';
import { CanDeactivateGuard } from './canDeactivateGuard.service';
import { ChartjsComponent } from './chartjs/chartjs/chartjs.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { DataSourceToggleComponent } from './controls/dataSourceToggle.component';
import { SizeInputComponent } from './controls/sizeInput.component';
import { TimeInputComponent } from './controls/timeInput.component';
import { DataTableModule } from './data-table/data-table.module';
import { FocusOnCreateDirective } from './focusOnCreate.directive';
import { ExtendedFormControlDirective } from './forms/extendedFormControl.directive';
import { CopyClipboardDirective } from './copyClipboard.directive';
import { SharedFormsModule } from './forms/shared-forms.module';
import { GroupBrowserComponent } from './group-browser/group-browser.component';
import { ConfirmationDialogComponent } from './modal/confirmation-dialog.component';
import { ConfirmationDialogService } from './modal/confirmation-dialog.service';
import { NotificationsService } from './notifications/notifications.service';
import { CheckPermissionsComponent, PermissionDisableDirective } from './permissions/check-permissions.component';
import { CheckServerAdminPermissionsComponent } from './permissions/check-server-admin-permissions.component';
import { BytePipe } from './pipes/byte.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { IntervalDSPipe } from './pipes/intervalDS.pipe';
import { SecondsToIntervalPipe } from './pipes/secondsToInterval.pipe';
import { IntervalHSPipe } from './pipes/intervalHS.pipe';
import { PathPipe } from './pipes/path.pipe';
import { RelativeTimePipe } from './pipes/relativeTime.pipe';
import { TimeSincePipe } from './pipes/timeSince.pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TabGroupComponent } from './tabs/tab-group/tab-group.component';
import { TabComponent } from './tabs/tab/tab.component';
import { TimeSliderComponent } from './time-slider/time-slider.component';
import { TreeViewModule } from './tree-view/tree-view.module';
import { PermissionGuard } from './user/permissionGuard.service';
import { UserService } from './user/user.service';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { StepperComponent } from './stepper/stepper.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { HDRPermissionService } from './hdr-permission/hdr-permission.service';
import { HDRPermissionDirective } from './hdr-permission/hdr-permission.directive';
import { HDRPermissionComponent } from './hdr-permission/hdr.permission.component';
import { HDRBannerComponent } from './hdr-banner.component';
import { AgentListComponent } from './agent-list/agent-list.component';
import { ServerInfoFormComponent } from '../dashboard/servers/server-info-form.component';

const exportedDeclarations: any[] = [
  BytePipe,
  DecimalPipe,
  DataSourceToggleComponent,
  IntervalHSPipe,
  PathPipe,
  TimeSincePipe,
  RelativeTimePipe,
  BreadcrumbComponent,
  SizeInputComponent,
  TimeInputComponent,
  FocusOnCreateDirective,
  HDRPermissionDirective,
  PermissionDisableDirective,
  CheckPermissionsComponent,
  CheckServerAdminPermissionsComponent,
  HDRPermissionComponent,
  TimeSliderComponent,
  SidebarComponent,
  TabGroupComponent,
  TabComponent,
  GroupBrowserComponent,
  IntervalDSPipe,
  SecondsToIntervalPipe,
  ChartjsComponent,
  ColorPickerComponent,
  ExtendedFormControlDirective,
  StatusIconComponent,
  StepperComponent,
  DateTimePickerComponent,
  HDRBannerComponent,
  CopyClipboardDirective,
  AgentListComponent,
  ServerInfoFormComponent
];

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

@NgModule({
    imports: [
        AlertModule,
        BsDropdownModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DataTableModule,
        SharedFormsModule,
        ModalModule,
        PopoverModule,
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateHttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        ConfirmationDialogComponent,
        ...exportedDeclarations
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        DataTableModule,
        SharedFormsModule,
        ModalDirective,
        ModalModule,
        TreeViewModule,
        TranslateModule,
        ...exportedDeclarations
    ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        BsModalService,
        BuildInfoService,
        UserService,
        CanDeactivateGuard,
        PermissionGuard,
        NotificationsService,
        ConfirmationDialogService,
        TranslateService,
        HDRPermissionService
      ]
    };
  }
}

export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
