<div *ngIf="server">
  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger mt-2">
      Unable to retrieve task scheduler information.
      <br> Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div *ngIf="!dataLoadErrorMessage" class="card-block">
    <div class="card-title row mt-3 mb-0">
      <div class="col-xs-4 col-lg-6">
      </div>
      <div class="col-xs-8 col-lg-6 text-right">
        <app-check-server-admin-permissions [server]="server">
          <div dropdown container="body" placement="bottom right">
            <button class="btn btn-primary" dropdownToggle appPermissionDisable>
              <i class="icon icon-plus"></i> Add New
            </button>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-0">
              <li class="li-background" (click)="addTaskSensorModal.openAddTaskModal(false, 'Task', 'addNewPopUp')">Task
              </li>
              <li class="li-background" (click)="addTaskSensorModal.openAddTaskModal(true, 'Task', 'addNewPopUp')">Startup
                Task</li>
              <li class="li-background" (click)="addTaskSensorModal.openAddTaskModal(false, 'Sensor', 'addNewPopUp')">Sensor
              </li>
              <li class="li-background" (click)="addTaskSensorModal.openAddTaskModal(true, 'Sensor', 'addNewPopUp')">Startup
                Sensor</li>
            </div>
          </div>
        </app-check-server-admin-permissions>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <app-data-table [data]="tableData" *ngIf="tableData; else tableDataLoading" [sortDescending]="true"
          [rowsPerPage]="20" searchLabel="Search for task or group ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
          <app-data-table-column title="Name" property="tk_name" [sortable]="true" [searchable]="true">
            <ng-template appTableCell let-row="row" let-item="item">
              <a href="javascript:void(0)" (click)="addTaskSensorModal.openAddTaskModal(false, '', row)">{{item}}</a>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Group" property="tk_group" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="Start Time" property="tk_start_time" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item === null)? "" : item.$date | date:'HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Run Frequency" property="tk_frequency" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-frequency="item">
              {{frequency.frequency | intervalDS}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="M" property="tk_monday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="T" property="tk_tuesday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="W" property="tk_wednesday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="T" property="tk_thursday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="F" property="tk_friday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="S" property="tk_saturday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="S" property="tk_sunday" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Enabled" property="tk_enable" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              <i *ngIf="item" class="icon icon-check text-success"></i>
              <i *ngIf="!item" class="icon icon-times-circle text-danger"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Next Execution" property="tk_next_execution" [sortable]="true" [searchable]="false">
            <ng-template appTableCell let-item="item">
              {{(item === null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column class="text-right">
            <ng-template appTableCell let-row="row">
              <app-check-server-admin-permissions [server]="server">
                <button class="btn btn-sm btn-icon" tooltip="Parameter" (click)="addParamModal.openAddParamModal(server, row)"
                  appPermissionDisable>
                  <i class="icon icon-parameter"></i>
                </button>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <div dropdown container="body" placement="bottom right">
                  <button class="btn btn-sm btn-icon" dropdownToggle appPermissionDisable>
                    <i class="icon icon-play"></i>
                  </button>
                  <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-0">
                    <li class="li-background" (click)="openRunModal($event,row.tk_id,'Synchronous')">Synchronous</li>
                    <li class="li-background" (click)="openRunModal($event,row.tk_id,'Asynchronous')">Asynchronous</li>
                  </div>
                </div>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <button class="btn btn-sm btn-icon" (click)="confirmDelete(row.tk_id)" tooltip="Delete"
                  appPermissionDisable>
                  <i class="icon icon-trash"></i>
                </button>
              </app-check-server-admin-permissions>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
        <ng-template #tableDataLoading>
          <i class="icon icon-2x icon-circle-notch icon-spin"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <app-task-modal #addTaskSensorModal [server]="server" (saveDone)="saveDoneEvent($event)">
  </app-task-modal>
  <app-parameter #addParamModal></app-parameter>
