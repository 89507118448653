/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformixServer } from '../../informixServer';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CheckpointsService {

  constructor(private httpClient: HttpClient) { }

  public getCheckpoints(server: InformixServer, fromTimestamp?: number, toTimestamp?: number): Observable<any[]> {
    let url = 'informix/' + server.id + '/checkpoints';
    if (fromTimestamp) {
      url += '?from=' + fromTimestamp;
      if (toTimestamp) {
        url += '&to=' + toTimestamp;
      }
    }

    return this.httpClient.get<any[]>(url);
  }

  public runCheckpoint(server: InformixServer, type: string): Promise<any> {
    const url = 'informix/' + server.id + '/checkpoints';
    return this.httpClient.post<any>(url, { type }).toPromise<any>();
  }
}
