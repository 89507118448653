/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
export class DashboardTimeInterval {

  private startDate: Date;
  private endDate: Date;

  static relative(from: number, milliseconds: number) {
    return new DashboardTimeInterval(from + milliseconds, from);
  }

  static relativeFromNow(milliseconds: number) {
    const now = new Date().getTime();
    return DashboardTimeInterval.relative(now, milliseconds);
  }

  constructor(
    private startTimestamp: number,
    private endTimestamp: number
  ) {
    if (this.endTimestamp < this.startTimestamp) {
      const end = this.startTimestamp;
      this.startTimestamp = this.endTimestamp;
      this.endTimestamp = end;
    }

    this.startDate = new Date(this.startTimestamp);
    this.endDate = new Date(this.endTimestamp);
  }

  getStartTimestamp(): number {
    return this.startTimestamp;
  }

  getEndTimestamp(): number {
    return this.endTimestamp;
  }

  isSameDate(): boolean {
    return this.startDate.getFullYear() === this.endDate.getFullYear() &&
      this.startDate.getMonth() === this.endDate.getMonth() &&
      this.startDate.getDate() === this.endDate.getDate();
  }
}
