/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { InformixServerType } from '../../dashboard/servers/informixServer';
import { HDRPermission } from './hdr-permission';
import { Permissions } from './hdr-permission.model';

@Injectable({
    providedIn: 'root'
})

export class HDRPermissionService {
    static instance: HDRPermissionService;
    HDRPermissions: HDRPermission[] = [];

    constructor() {}

    /**
     * Initializing HDR permission to be used in the component.
     *
     * @param informixServerType : type of the informix server
     */
    initPermissions(informixServerType: InformixServerType) {
        this.HDRPermissions = [];
        Permissions.forEach(permission => {
            const hdrPermission: HDRPermission = new HDRPermission(permission, informixServerType);
            this.HDRPermissions.push(hdrPermission);
        });
    }

    /**
     * Finds & Returns HDRPermission instnace by given permission id.
     *
     * @param permissionId : permission id
     * @returns : HDRPermission instance or undefined if permission not found.
     */
    getByPermissionId(permissionId: string): HDRPermission | undefined {
        return this.HDRPermissions.find(hdrPermission => hdrPermission.getPermissionId() === permissionId);
    }

}
