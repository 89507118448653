<ng-container *ngIf="chartData then dataSource else loading"></ng-container>

<ng-template #dataSource>
  <ng-container
    *ngIf="config.chartType === 'bar' || config.chartType === 'line' || config.chartType === 'pie' then showdataSourceChart else showDataSourceTable">
  </ng-container>
</ng-template>

<ng-template #showdataSourceChart>
  <ng-container
    *ngIf="config.chartType === 'pie' && (sensorTypes.length > 1 || isMultiserver()) then onlyOneSensorError else chart">
  </ng-container>
</ng-template>

<ng-template #showDataSourceTable>
  <ng-container
    *ngIf="config.chartType === 'table' && sensorTypes.length < 2 && !isMultiserver() then dataSourceTable else onlyOneSensorError">
  </ng-container>
</ng-template>

<ng-template #dataSourceTable>
  <table class="table table-striped border-right-0 mb-0 text-monospace" *ngIf="config.chartType === 'table'">
    <thead>
      <tr>
        <th *ngIf="timeStampIndex > -1">timestamp</th>
        <th *ngFor="let data of chartData.dataSeries">{{data.label}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let value of chartData?.dataSeries[0]?.values; let i=index">
        <td *ngIf="timeStampIndex > -1">{{chartData?.timestamps[i]}}</td>
        <td *ngFor="let data of chartData.dataSeries">
          <ng-container *ngIf="isNumber(data.values[i]) then formatNumber else value"></ng-container>
          <ng-template #formatNumber>
            <ng-container *ngIf="chartYAxes[0].type === 'bytes' else percentFormat">
              {{data.values[i] | decimal | byte}}
            </ng-container>
            <ng-template #percentFormat>
              {{chartYAxes[0].type === 'percent' ?  ((data.values[i] | decimal) + '%') : (data.values[i] | decimal)}}
            </ng-template>
          </ng-template>
          <ng-template #value>
            {{data.values[i] !== data.values[i] ? '-' : data.values[i]}}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #onlyOneSensorError>
  <div class="d-flex align-items-center overlay">
    <div class="flex-grow-1 text-center">
      <ng-container *ngIf="isMultiserver() else oneSensor">
        Please select only one server for pie/table visualization.
      </ng-container>
      <ng-template #oneSensor>Please select only one sensor for pie/table visualization.</ng-template>
    </div>
  </div>
</ng-template>

<ng-template #chart>
  <app-chartjs [zoomOnInputOnly]="true" [data]="chartData" [yAxes]="chartYAxes"
    [startTimestamp]="timeInterval?.getStartTimestamp()" [endTimestamp]="timeInterval?.getEndTimestamp()"
    [chartType]="config.chartType" (zoom)="onZoom($event)" [isCustomQuery]="config.isCustomQuery"
    (pushWarning)="onWarning($event)">
  </app-chartjs>
</ng-template>

<ng-template #loading>
  <div class="d-flex align-items-center overlay bg-white border-gray-20 border">
    <div class="flex-grow-1 text-center">
      <i *ngIf="isLoading; else finishedLoading" class="icon icon-circle-notch icon-spin"></i>
      <ng-template #finishedLoading>
        {{statusMessage}}
      </ng-template>
    </div>
  </div>
</ng-template>
