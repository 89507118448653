/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved
 *******************************************************************************/

import { InformixSQLSession } from './informix-sql-session';

export class InformixTableColumn {
  id: number;
  name: string;
  type: string;
  notNull: boolean;
  hidden: boolean;
  length: number;
  indexes: InformixIndex[] = [];
  constraints: InformixConstraint[] = [];
  isChecked?: boolean;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.type = json.type;
    this.notNull = json.notNull;
    this.hidden = json.hidden;
    this.length = json.length;
    this.isChecked = false;
  }
}

class InformixSysObject {
  enabled: boolean;
  filtering: boolean;
  filterErrorsEnabled: boolean;

  constructor(json: any) {
    this.enabled = json.enabled;
    this.filtering = json.filtering;
    this.filterErrorsEnabled = json.filterErrorsEnabled;
  }
}

class InformixIndexColumn {
  constructor(
    public column: InformixTableColumn,
    public descending = false
  ) { }
}

export class InformixIndex extends InformixSysObject {
  name: string;
  owner: string;
  unique: boolean;
  clustered: boolean;
  levels: number;
  leaves: number;
  uniqueKeys: number;
  clusters: number;
  columns: InformixIndexColumn[];
  isDisabled: boolean;
  isChecked: boolean;

  type: string;
  columnNames = '-';

  constructor(json: any, columns: InformixIndexColumn[]) {
    super(json);
    this.name = json.name;
    this.owner = json.owner;
    this.unique = json.unique;
    this.clustered = json.clustered;
    this.levels = json.levels;
    this.leaves = json.leaves;
    this.uniqueKeys = json.uniqueKeys;
    this.clusters = json.clusters;
    this.columns = columns;
    this.isChecked = false;
    this.isDisabled = false;

    this.type = this.unique ? 'Unique' : 'Non-unique';
    if (this.clustered) {
      this.type += ', clustered';
    }

    if (this.columns.length > 0) {
      this.columnNames = this.columns.map(c => c.column.name).join(', ');
    }
  }
}

export class InformixReferences {
  constrname: string;
  owner: string;
  idxname: string;
  collation: string;
  primarytab: string;
  delrule: string;
  state: string;
  referenced_primary_key: string[];
  primaryKeys = '-';

  constructor(json: any) {
    this.constrname = json.constrname;
    this.owner = json.owner;
    this.idxname = json.idxname;
    this.collation = json.collation;
    this.primarytab = json.primarytab;
    this.delrule = json.delrule;
    this.state = json.state;
    this.referenced_primary_key = json.referenced_primary_key;

    if (this.referenced_primary_key.length > 0) {
      this.primaryKeys = this.referenced_primary_key.join(', ');
    }

    (this.delrule === 'C') ? this.delrule = 'Cascading delete' : this.delrule = 'Restrict';
    (this.state === 'D' || this.state === null) ? this.state = 'disabled' : this.state = 'enabled';
  }
}

export class InformixPrivileges {
  grantor: string;
  grantee: string;
  tabauth: string;
  tabauthlist: any;

  constructor(json: any) {
    this.grantor = json.grantor;
    this.grantee = json.grantee;
    this.tabauth = json.tabauth;
    this.tabauthlist = json.tabauth;
  }
}

export class InformixTableStatistics {
  name: string;
  type: string;
  build_date: any;
  sample: number;
  nrows: number;
  mode: string;
  resolution: number;
  confidence: number;
  build_duration: any;
  udi_counter: number;
  change: number;
  fragname?: string;
  fragtype?: string;

  constructor(json: any) {
    this.name = json.name;
    this.type = json.type;
    this.build_date = json.build_date;
    this.sample = json.sample;
    this.nrows = json.nrows;
    this.mode = json.mode;
    this.resolution = json.resolution;
    this.confidence = json.confidence;
    this.build_duration = json.build_duration;
    this.udi_counter = json.udi_counter;
    this.change = json.change;
    this.fragname = json.fragname;
    this.fragtype = json.fragtype;
  }
}

export class InformixStatistics {
  is_fragmented: boolean;
  statLevel: string;
  statChange: string;
  tableStatistics: InformixTableStatistics[];
  fragmentStatistics: InformixTableStatistics[];

  constructor(json: any, tableStatistics: InformixTableStatistics[], fragmentStatistics: InformixTableStatistics[]) {
    this.is_fragmented = json.is_fragmented;
    this.statLevel = json.statLevel;
    this.statChange = json.statChange;
    this.tableStatistics = tableStatistics;
    this.fragmentStatistics = fragmentStatistics;
  }
}

export class InformixFragments {
  fragtype: string;
  type: string;
  indexname: string;
  partn: string;
  dbspace: string;
  partition: string;
  exprtext: string;
  nrows: number | string;

  constructor(json: any) {
    this.indexname = json.indexname;
    this.partn = json.partn;
    this.dbspace = json.dbspace;
    this.partition = json.partition;
    this.nrows = json.nrows;

    (json.fragtype === 'Index fragment') ? this.fragtype = 'Index' : this.fragtype = 'Table';

    if (json.type === 'Round-robin') {
      this.type = 'Round Robin';
    } else if (json.type === 'Expression-based') {
      this.type = 'Expression Based';
    } else if (json.type === 'IN DBSPACE') {
      this.type = 'Index';
      // rows not applicable to Index - idsdb00111718
      this.nrows = 'N/A';
    } else if (json.type === 'raNge-iNterval') {
      this.type = 'Range Interval';
    } else if (json.type === 'List distribution') {
      this.type = 'List Distribution';
    } else if (json.type === 'Table-based') {
      this.type = 'Table Based';
    } else if (json.type === 'Subtable within a table hierarchy') {
      this.type = json.type;
    } else {
      this.type = null;
    }

    (json.exprtext === null) ? this.exprtext = '-' : this.exprtext = json.exprtext;
  }
}

export class InformixTriggers {
  trigname: string;
  owner: string;
  event: string;
  action: string;
  status: string;

  constructor(json: any) {
    this.trigname = json.trigname;
    this.owner = json.owner;
    this.event = json.event;
    this.action = json.action;
    this.status = json.status;
  }
}

export class InformixConstraint extends InformixSysObject {
  id: number;
  name: string;
  owner: string;
  type: string;
  collation: string;
  referencedTableName: string;
  referencedColumnNames: string[];
  cascadingDelete: boolean;
  check: string;

  index: InformixIndex;
  columns: InformixIndexColumn[];

  displayName: string = null;
  columnNames = '-';

  constructor(json: any, index: InformixIndex, columns: InformixIndexColumn[]) {
    super(json);
    this.id = json.id;
    this.name = json.name;
    this.owner = json.owner;
    this.type = json.type;
    this.collation = json.collation;

    if (typeof json.referencedTableName === 'string') {
      this.referencedTableName = json.referencedTableName;
      this.referencedColumnNames = json.referencedColumnNames;
      this.cascadingDelete = json.cascadingDelete;
    }

    if (typeof json.check === 'string') {
      this.check = json.check;
    }

    this.index = index;
    this.columns = columns;

    if (this.type === 'C') { // Check constraint
      this.displayName = 'CHECK';
      if (this.check) {
        this.displayName += ' ' + this.check;
      }
    } else if (this.type === 'N') { // Not null
      this.displayName = 'NOT NULL';
    } else if (this.type === 'P') { // Primary key
      this.displayName = 'PRIMARY KEY';
    } else if (this.type === 'R') { // Referential constraint
      this.displayName = 'FOREIGN KEY (' + this.columns.map(c => c.column.name).join(', ') + ')';
      if (this.referencedTableName && this.referencedColumnNames) {
        this.displayName += ' REFERENCES ' + this.referencedTableName + '(' + this.referencedColumnNames.join(', ') + ')';
      }
      if (this.cascadingDelete) {
        this.displayName += ' ON DELETE CASCADE';
      }
    } else if (this.type === 'T') { // Table constraint
      this.displayName = 'TABLE';
    } else if (this.type === 'U') { // Unique
      this.displayName = 'UNIQUE';
    } else { // Unknown, won't be displayed
      this.displayName = null;
    }

    if (this.columns.length > 0) {
      this.columnNames = this.columns.map(c => c.column.name).join(', ');
    }
  }

  getDisplayName(columnId: number) {
    if (this.type === 'R') {
      const index = this.columns.findIndex(c => c.column.id === columnId);
      const referencedColumnName = this.referencedColumnNames[index];
      if (referencedColumnName) {
        return 'REFERENCES ' + this.referencedTableName + '(' + referencedColumnName + ')';
      } else {
        return 'FOREIGN KEY';
      }
    }
    return this.displayName;
  }
}

export class InformixTableDetails {
  type: string;
  columns: InformixTableColumn[] = [];
  columnMap = new Map<number, InformixTableColumn>();
  indexes: InformixIndex[] = null;
  indexMap: Map<string, InformixIndex> = null;
  constraints: InformixConstraint[] = null;
  view_sql: string = null;
  ext_files: string[] = null;
  references: InformixReferences[] = null;
  statistics: InformixStatistics = null;
  privileges: InformixPrivileges[] = null;
  fragments: InformixFragments[] = null;
  triggers: InformixTriggers[] = null;

  constructor(json: any) {
    this.type = json.type;
    json.columns.forEach((v: any) => {
      const column = new InformixTableColumn(v);
      this.columns.push(column);
      this.columnMap.set(column.id, column);
    });

    if (json.type === 'V') {
      this.view_sql = json.view_sql;
    } else if (json.type === 'E') {
      this.ext_files = json.ext_files;
    } else {
      this.indexes = [];
      this.indexMap = new Map<string, InformixIndex>();
      json.indexes.forEach((v: any) => {
        const columns: InformixIndexColumn[] = [];
        v.columns.forEach((c: any) => {
          const column = this.columnMap.get(c.id);
          if (column) {
            columns.push(new InformixIndexColumn(column, v.descending));
          }
        });
        const index = new InformixIndex(v, columns);
        this.indexes.push(index);
        this.indexMap.set(index.name, index);
        columns.forEach(column => column.column.indexes.push(index));
      });

      this.constraints = [];
      json.constraints.forEach((v: any) => {
        const index = v.indexName ? this.indexMap.get(v.indexName) : null;
        const columns = index ? index.columns : this.getColumns(v.columns).map(c => new InformixIndexColumn(c));
        const constraint = new InformixConstraint(v, index, columns);
        this.constraints.push(constraint);
        columns.forEach(column => column.column.constraints.push(constraint));
      });

      this.references = [];
      json.references.forEach((v: any) => {
        const reference = new InformixReferences(v);
        this.references.push(reference);
      });

      this.privileges = [];
      json.privileges.forEach((v: any) => {
        const privilege = new InformixPrivileges(v);
        this.privileges.push(privilege);
      });

      this.statistics = json.statistics;

      this.fragments = [];
      json.fragments.forEach((v: any) => {
        const fragment = new InformixFragments(v);
        this.fragments.push(fragment);
      });

      this.triggers = [];
      json.triggers.forEach((v: any) => {
        const trigger = new InformixTriggers(v);
        this.triggers.push(trigger);
      });
    }
  }

  getColumns(ids: number[]): InformixTableColumn[] {
    return ids.map(id => this.columnMap.get(id)).filter(c => !!c);
  }
}

class InformixTableSynonym {
  id: number;
  name: string;
  public: boolean;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.public = json.public;
  }
}

export class InformixTable {
  session: InformixSQLSession;

  id: number;
  name: string;
  owner: string;
  lastModified: number;
  type: string;
  lockLevel: string;
  firstExtentSize: number;
  nextExtentSize: number;
  pageSize: number;
  lastStatisticsUpdate: number;
  statisticsLevel: string;

  hasRowId: boolean;
  isSubTable: boolean;
  isRemote: boolean;
  isCdr: boolean;
  isRaw: boolean;
  isExternal: boolean;
  isAuditEnabled: boolean;
  isAqt: boolean;
  isVirtualAqt: boolean;
  isCollection: boolean;

  columns: InformixTableColumn[] = null;
  synonyms: InformixTableSynonym[] = null;

  typeName: string;
  lockLevelName: string;
  statisticsLevelName: string;

  isSystemTable: boolean;

  /* Table names that should be considered as system tables/catalogs Despite the fact that their tabids are greater than 99*/
  SYSTEM_CATALOGS: string[] = ['sysblderrorlog', 'sysbldobjects', 'sysbldobjindex', 'sysbldobjdepends',
                               'sysbldobjkinds', 'sysbldregistered', 'sysbldirequired', 'sysbldiprovided'];

  constructor(session: InformixSQLSession, json: any) {
    this.session = session;
    this.id = json.id;
    this.name = json.name;
    this.owner = json.owner;
    this.lastModified = json.lastModified;
    this.type = json.type;
    this.lockLevel = json.lockLevel;
    this.firstExtentSize = json.firstExtentSize;
    this.nextExtentSize = json.nextExtentSize;
    this.pageSize = json.pageSize;
    this.lastStatisticsUpdate = json.lastStatisticsUpdate;
    this.statisticsLevel = json.statisticsLevel;

    this.hasRowId = json.hasRowId;
    this.isSubTable = json.isSubTable;
    this.isRemote = json.isRemote;
    this.isCdr = json.isCdr;
    this.isRaw = json.isRaw;
    this.isExternal = json.isExternal;
    this.isAuditEnabled = json.isAuditEnabled;
    this.isAqt = json.isAqt;
    this.isVirtualAqt = json.isVirtualAqt;
    this.isCollection = json.isCollection;

    this.synonyms = json.synonyms.map(v => new InformixTableSynonym(v));

    if (this.type === 'E') {
      this.typeName = 'External table';
    } else if (this.type === 'V') {
      this.typeName = 'View';
    } else {
      this.typeName = 'Table';
    }

    if (this.lockLevel === 'R') {
      this.lockLevelName = 'Row';
    } else if (this.lockLevel === 'P') {
      this.lockLevelName = 'Page';
    } else if (this.lockLevel === 'B') {
      this.lockLevelName = 'Row and page';
    } else {
      this.lockLevelName = '-';
    }

    if (this.statisticsLevel) {
      if (this.statisticsLevel === 'T') {
        this.statisticsLevelName = 'Table';
      } else if (this.statisticsLevel === 'F') {
        this.statisticsLevelName = 'Fragment';
      } else {
        this.statisticsLevelName = 'Automatic';
      }
    }

    if(this.id < 100 || this.SYSTEM_CATALOGS.includes(this.name)){
      this.isSystemTable = true;
    }else {
      this.isSystemTable = false;
    }
  }

  isTable() {
    return this.type === 'T';
  }

  isView() {
    return this.type === 'V';
  }

  isExternalTable() {
    return this.type === 'E';
  }

  getUrl() {
    return this.session.getTablesUrl() + '/' + this.name;
  }

  getDetailsUrl() {
    return this.getUrl() + '/details';
  }
}
