<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-8">
      <h4><strong>{{ 'systemReports.tableExtents' | translate }}</strong>
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-3 mb-2 d-flex">
      <label class="pr-2 col-form-label">{{ 'database.database' | translate }}</label>
      <select class="form-control" (change)="changeDatabase($event)">
        <option *ngFor="let database of databases" value="{{database}}">{{database}}
        </option>
      </select>
    </div>
  </div>
  <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" sortBy="nextents" [rowsPerPage]="20" [searchLabel]="'systemReports.searchTableExtents' | translate">
    <app-data-table-column [title]="'database.database' | translate" property="dbsname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.object' | translate" property="tabname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.size' | translate" property="size" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.numberOfExtents' | translate" property="nextents" [sortable]="true" [searchable]="true">
    </app-data-table-column>
  </app-data-table>
</div>
