/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarElement } from '../../shared/sidebar/sidebar';
import { InformixServer } from './informixServer';
import { ServerSidebar } from './server-sidebar';
import { InformixServerService } from './informixServer.service';

@Component({
  selector: 'app-servers-component',
  templateUrl: 'servers.html'
})
export class ServersComponent implements OnInit {
  showMenu: { [key: string]: boolean } = {};

  server: InformixServer = null;
  serverSidebar: SidebarElement[] = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serverService: InformixServerService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.server = data.server;
      this.serverSidebar = ServerSidebar.getSidebar(this.server);
      this.serverService.setConnectionStatus(this.server.isWorkOffline);
    });
  }

  toggleExpandClass(element: string) {
    if (this.showMenu[element]) {
      delete this.showMenu[element];
    } else {
      this.showMenu[element] = true;
    }
  }

  isExpanded(element: string): boolean {
    return this.showMenu[element] === true;
  }

  routeIsStorage(exact = true) {
    if (this.server) {
      return this.router.isActive(this.router.createUrlTree(['dashboard', 'servers', this.server.id, 'storage']), exact);
    } else {
      return false;
    }
  }

  goToStorage() {
    if (this.server) {
      this.router.navigate(['dashboard', 'servers', this.server.id, 'storage']);
    }
  }
}
