<ng-container *ngIf="internalUsersInfo; else internalUserInfoLoading">
  <div class="row mt-3 align-items-center">
    <div class="col-12 col-xl-2 col-lg-3 pt-1">
      <h5>Internal Users</h5>
    </div>
    <div class="col-12 col-xl-8 col-lg-7">
      <span class="pr-3">Specify the level of privileges for internal users (USERMAPPING)</span>
      <span *ngIf="!noUserMapping">
        <app-check-server-admin-permissions [server]="server" placement="top" class="col-3 p-0">
          <select class="form-control mt-2 mb-2" [(ngModel)]="selectedUserMapping" appPermissionDisable
            (change)="modifyUserMapping()">
            <option *ngFor="let userMapping of userMappings" [ngValue]="userMapping?.mappingName">
              {{userMapping?.mappingName}}
            </option>
          </select>
        </app-check-server-admin-permissions>
      </span>
    </div>
    <div class="col-12 col-xl-2 col-lg-2 d-flex justify-content-start justify-content-xl-end justify-content-lg-end">
      <app-check-server-admin-permissions [server]="server" placement="top">
        <button type="button" class="btn btn-primary" appPermissionDisable (click)="addInternalUser()">Grant</button>
      </app-check-server-admin-permissions>
    </div>
  </div>
  <app-data-table *ngIf="internalUsersInfo; else userInfoLoading" [data]="internalUsersInfo"
    noDataAlertMessage="No Internal Users privileges defined." [rowsPerPage]="10" searchLabel="Search here ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
    <app-data-table-column title="Internal User" property="userName" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Authentication Type" property="authenticationType" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Account Status">
      <ng-template appTableCell let-internalUser="row">
        <app-check-server-admin-permissions [server]="server" placement="top">
          <button type="button" class="btn btn-icon btn-sm" *ngIf="internalUser?.isLocked === 1" tooltip="Locked"
            appPermissionDisable (click)="toggleLock(internalUser)">
            <i class="icon icon-lock"></i>
          </button>
          <button type="button" class="btn btn-icon btn-sm" *ngIf="internalUser?.isLocked === 0" tooltip="Unlocked"
            appPermissionDisable (click)="toggleLock(internalUser)">
            <i class="icon icon-unlock"></i>
          </button>
        </app-check-server-admin-permissions>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Surrogate OS User" property="osUserName" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="UID" property="uid" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-internalUser="row">
        <div *ngIf="internalUser.uid !== 0">{{internalUser?.uid}}</div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="GID" property="gid" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-internalUser="row">
        <div *ngIf="internalUser.gid !== 0">{{internalUser?.gid}}</div>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Home Directory" property="homeDirectory" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column title="Administrative Privileges">
      <ng-template appTableCell let-internalUser="row">
        {{getPrivilegeName(internalUser?.privilegeTypes)}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column>
      <ng-template appTableCell let-internalUser="row">
        <app-check-server-admin-permissions [server]="server" placement="top">
          <button type="button" class="btn btn-icon btn-sm" tooltip="Modify" appPermissionDisable
            (click)="modifyInternalUser(internalUser)">
            <i class="icon icon-edit"></i>
          </button>
        </app-check-server-admin-permissions>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column>
      <ng-template appTableCell let-internalUser="row">
        <app-check-server-admin-permissions [server]="server" placement="top">
          <button type="button" class="btn btn-icon btn-sm" appPermissionDisable
            (click)="deleteInternalUser(internalUser.userName)">
            <i class="icon icon-trash"></i>
          </button>
        </app-check-server-admin-permissions>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
  <ng-template #userInfoLoading>
    <div class="d-flex justify-content-center">
      <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>
  </ng-template>
</ng-container>

<ng-template #internalUserInfoLoading>
  <div *ngIf="isDataLoading else serverError;" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-4"></i>
  </div>
  <ng-template #serverError>
    <div class="alert alert-danger mt-2" *ngIf="apiError"> Unable to retrieve internal users information. <br>
      Error: {{apiError}}
    </div>
  </ng-template>  
</ng-template>

<div bsModal #internalUserModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}"
(onHidden)="onHidden()">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-11">
          <h5 class="modal-title heading-padding-top pull-left" *ngIf="!isEdit">Add Internal User</h5>
          <h5 class="modal-title heading-padding-top pull-left" *ngIf="isEdit">Internal User Details</h5>
        </div>
        <div>
          <button type="button" class="close" (click)="internalUserModal.hide()">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body pl-5 pr-5 pt-2 pb-2">
        <form [formGroup]="internalUserForm">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-12  ">
              <div class="form-group font-weight-bold">
                <label>Internal User Name</label>
                <input type="text" class="form-control" placeholder="Enter User Name..." formControlName="userName"
                  tooltip="" [customErrors]="userNameErrorHandlers" appExtendedFormControl>
              </div>
              <div>
                <app-checkbox [checked]="isDefaultUser" (click)="defaultUserToggle()" [disabled]="isEdit || isDefaultUserExists">
                  Define as default user
                </app-checkbox>
              </div>
              <app-radio-group formControlName="authenticationType" class="row align-items-center mt-2 pb-2">
                <div class="font-weight-bold col-4">Authentication:</div>
                <div class="col-4">
                  <app-radio value="os">OS</app-radio>
                </div>
                <div class="col-4">
                  <app-radio value="database">Database</app-radio>
                </div>
              </app-radio-group>
              <app-radio-group formControlName="accountStatus" class="row align-items-center pb-2">
                <div class="font-weight-bold col-4">Account access:</div>
                <div class="col-4">
                  <app-radio value="lock">Locked</app-radio>
                </div>
                <div class="col-4">
                  <app-radio value="unlock">Unlocked</app-radio>
                </div>
              </app-radio-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-12 pb-3 pr-0">
              <label class="font-weight-bold">Administrative Privileges</label>
              <div *ngFor="let privilege of internalUserPrivileges; let i=index" class="mt-1">
                <app-checkbox [checked]="internalUserPrivileges[i].checked" (change)="internalUserPrivileges[i].checked = $event"
                  [disabled]="mapUserBy?.value === 'inherited'">
                  {{internalUserPrivileges[i].typeDesc}} ({{internalUserPrivileges[i].typeName}})
                </app-checkbox>
              </div>
            </div>
          </div>
          <app-radio-group formControlName="mapUserBy" class="row">
            <label class="col-12">Map the user name to an operating system user name or user ID</label>
            <div class="col-12 form-inline">
              <app-radio value="byUserName">
                <span class="font-weight-bold">By User Name</span>
              </app-radio>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 os-user-padding form-group">
              <label>User Name</label>
              <input type="text" class="form-control" formControlName="osUserName" placeholder="Enter user name..."
                tooltip="" [customErrors]="userNameErrorHandlers" appExtendedFormControl>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-2 os-user-padding">
              <label>Group ID/GID (Optional)</label>
              <input type="number" class="form-control" placeholder="Enter group ID..." formControlName="gid" min="0">
            </div>
            <div class="col-12 form-inline">
              <app-radio value="byUserID">
                <span class="font-weight-bold">By User ID (UID)</span>
              </app-radio>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 os-user-padding form-group">
              <label>User ID</label>
              <input type="number" class="form-control" formControlName="osUserID" placeholder="Enter user ID..."
                min="0" tooltip="" appExtendedFormControl>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 os-user-padding mb-2 col-12">
              <label>Group ID/GID</label>
              <input type="number" class="form-control" placeholder="Enter group ID..." formControlName="groupID"
                min="0" tooltip="" appExtendedFormControl>
            </div>
            <div class="col-12 form-inline">
              <app-radio value="inherited" [disabled]="isDefaultUser">
                <span class="font-weight-bold">Inherited Properties</span>
              </app-radio>
            </div>
          </app-radio-group>
          <div class="mt-3">
            <label class="font-weight-bold">Server Home Directory (Optional)</label>
            <input type="text" class="form-control mb-2" placeholder="Enter here..." formControlName="homeDirectory">
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 form-group">
              <label class="font-weight-bold">Password</label>
              <div class="input-group password-hide-show inner-addon right-addon">
                <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="Enter password..."
                  formControlName="password" [customErrors]="userNameErrorHandlers" (keyup)="toggleShow(true)" appExtendedFormControl tooltip="">
                <i class="icon icon-eye" (click)="toggleShow()"
                  *ngIf="showPassword && (internalUserForm.controls['password'].dirty && internalUserForm.value.password !== '')"></i>
                <i class="icon icon-eye-blocked" (click)="toggleShow()"
                  *ngIf="!showPassword && (internalUserForm.controls['password'].dirty && internalUserForm.value.password !== '')"></i>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 pb-2">
              <label class="font-weight-bold">Confirm Password</label>
              <div class="input-group password-hide-show inner-addon right-addon">
                <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control" placeholder="Confirm password..."
                  tooltip="" [customErrors]="userNameErrorHandlers" appExtendedFormControl formControlName="confirmPassword" (keyup)="toggleConfirmPassShow(true)">
                <i class="icon icon-eye" *ngIf="showConfirmPassword && (internalUserForm.controls['confirmPassword'].dirty && internalUserForm.value.confirmPassword !== '')" (click)="toggleConfirmPassShow()"></i>
                <i class="icon icon-eye-blocked" *ngIf="!showConfirmPassword && (internalUserForm.controls['confirmPassword'].dirty && internalUserForm.value.confirmPassword !== '')" (click)="toggleConfirmPassShow()"></i>
              </div>
            </div>
          </div>
        </form>
        <div align="right">
          <div class="pr-0">
            <button type="button" class="btn btn-secondary btn-fixed-width" (click)="internalUserModal.hide()">Cancel</button>
            <button type="button" *ngIf="!isEdit" class="btn btn-primary btn-fixed-width" (click)="saveInternalUser()"
              [disabled]="internalUserForm.invalid">
              Add User
            </button>
            <button type="button" *ngIf="isEdit" class="btn btn-primary btn-fixed-width" (click)="saveInternalUser()"
              [disabled]="internalUserForm.invalid">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
