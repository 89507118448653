<div class="card h-100" (mousedown)="onMouseDown($event)" (click)="onClick($event)"
  [ngClass]="{ selected: isSelected, 'card-warning-border': group.aggregate.unreadIncidents > 0, locked: !group.permissions.read }">
  <div class="card-header py-2 px-3 d-flex align-items-center">
    <ng-container *ngIf="isEditing; else notEditing">
      <form class="d-flex flex-1 align-items-center" (ngSubmit)="onSave()">
        <input type="text" class="form-control form-control-sm flex-1 mr-1" appFocusOnCreate [focusAndSelect]="true"
          [formControl]="groupNameControl">
        <button type="submit" class="btn btn-sm btn-primary" [disabled]="!groupNameControl.valid || isSaving">
          <i class="icon icon-check"></i>
        </button>
        <button class="btn btn-sm btn-danger" [disabled]="savingGroup" (click)="ignoreCardClick = true; closeEdit()">
          <i class="icon icon-times"></i>
        </button>
      </form>
    </ng-container>
    <ng-template #notEditing>
      <div class="flex-1 overflow-hidden pl-1">
        <h5 class="font-weight-bold m-0 text-truncate">{{group.name}}</h5>
      </div>
      <div *ngIf="group.permissions.admin" dropdown container="body" placement="bottom right"
        (click)="ignoreCardClick = true">
        <button class="close" dropdownToggle>
          <i class="icon icon-menu-overflow-vertical"></i>
        </button>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" (click)="onEdit()">
            <i class="icon icon-edit"></i> Rename
          </button>
          <button class="dropdown-item" type="button" (click)="onMove()">
            <i class="icon icon-folder"></i> Move to...
          </button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item text-danger" type="button" (click)="onDelete()">
            <i class="icon icon-trash"></i> Delete
          </button>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="card-body p-1" *ngIf="group.permissions.read; else groupLocked">
    <div class="item-stats">
      <div class="flex-1 text-center item-stat border-right border-gray-20">
        <i *ngIf="group.aggregate.unreadIncidents > 0" class="icon icon-warning text-warning mr-1"></i>
        <span class="item-stat-number">{{group.aggregate.unreadIncidents}}</span>
        {{group.aggregate.unreadIncidents === 1 ? 'INCIDENT' : 'INCIDENTS'}}
      </div>
      <div class="flex-1 text-center item-stat">
        <span class="item-stat-number">{{group.aggregate.serverCountTotal}}</span>
        {{group.aggregate.serverCountTotal === 1 ? 'SERVER' : 'SERVERS'}}
      </div>
    </div>
  </div>
  <ng-template #groupLocked>
    <div class="card-body d-flex align-items-center color-light-gray-40 p-2">
      <div class="flex-1 text-center">
        <i class="icon icon-2x icon-lock"></i>
      </div>
    </div>
  </ng-template>
</div>
