/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { InformixServer } from '../informixServer';
import { InformixServerService } from '../informixServer.service';
import { ServerInfo, ClusterStatusInfo, ClusterInfo, serverStatusList } from './high-availability.model';
import { HighAvailabilityService } from './high-availability.service';
import { Router } from '@angular/router';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cluster-topology',
  templateUrl: 'cluster-topology.html',
  styleUrls: ['cluster-topology.scss']
})
export class ClusterTopologyComponent implements OnChanges {

  constructor(private informixServerService: InformixServerService,
    private highAvailabilityService: HighAvailabilityService,
    private router: Router,
    private notificatinServer: NotificationsService) { }

  @Input() server: InformixServer;
  dataLoading = false;
  clusterInfo: ClusterInfo;
  currentServer: ServerInfo;
  primaryServerInfo: ServerInfo;
  hdrServerInfo: ServerInfo = null;
  sdsServersInfo: ServerInfo[] = [];
  rssServersInfo: ServerInfo[] = [];
  serverNotInCluster: string;
  clusterServerError: string;

  clusterStatusLoading = false;
  clusterStatusInfo: ClusterStatusInfo = null;
  clusterStatusServerError: string;

  serverStatusList = serverStatusList;
  productName: string = environment.productNameNoSpace;

  ngOnChanges(changes: SimpleChanges) {
    if(changes.server) {
      this.getServerHighAvailabilityInfo();
    }
  }

  refreshData() {
    this.clusterInfo = null;
    this.getServerHighAvailabilityInfo();
  }

  getServerHighAvailabilityInfo() {
    this.dataLoading = true;
    this.informixServerService.getServerHighAvailabilityStatus(this.server.id, true).subscribe(clusterInfo => {
      this.dataLoading = false;
      this.clusterInfo = clusterInfo;
      if (this.clusterInfo.clusterNodeList) {

        // Overriding HDR string to HDR SECONDARY for IDS
        this.clusterInfo.clusterNodeList.map(server => {
          if(server.type === 'HDR') {
            server.type = 'HDR SECONDARY';
          }
          return server;
        });

        // using includes() as haName can be postfixed with thread listeners.
        // example: servername-4. It is defect in IDS version lower than XC8.

        this.currentServer = this.clusterInfo.clusterNodeList.filter(server => clusterInfo.haName.includes(server.name)
        || server.id === this.server.id)[0];

        if(this.currentServer) {
          this.showStatusInformation();
          this.primaryServerInfo = this.clusterInfo.clusterNodeList.filter(server => server.type === 'PRIMARY')[0];
          this.hdrServerInfo = this.clusterInfo.clusterNodeList.filter(server => server.type === 'HDR SECONDARY')[0];
          this.sdsServersInfo = this.clusterInfo.clusterNodeList.filter(server => server.type === 'SDS');
          this.rssServersInfo = this.clusterInfo.clusterNodeList.filter(server => server.type === 'RSS');
        }else {
          this.serverNotInCluster = `Could not display cluster topology: Server '${clusterInfo.haName}' is not present in the cluster.`;
        }

      }
    }, err => {
      this.dataLoading = false;
      if (err.status === 404) {
        this.serverNotInCluster = err.error ? err.error.err : err;
      } else {
        this.clusterServerError = err.error ? err.error.err : err;
      }
    });
  }

  /**
   * Method to redirect to ha server in the HQ.
   *
   * @param selectedServerInfo
   * @returns
   */
  redirect(selectedServerInfo: ServerInfo) {
    if(selectedServerInfo.id) {
      this.router.navigateByUrl('/dashboard/servers/' + selectedServerInfo.id + '/ha');
      return;
    }
    this.notificatinServer.pushErrorNotification(`Server '${selectedServerInfo.name}' is not present in ${this.productName}.
    [Note: If HA is configured via DBSERVERALIASES, It is recommended to add those same instances in the ${this.productName}.]`);
  }

  showStatusInformation() {
    if (this.clusterStatusLoading) {
      return;
    }
    this.clusterStatusServerError = null;
    this.clusterInfo.clusterNodeList.map(server => server.selected = false);
    this.currentServer.selected = true;
    this.clusterStatusInfo = null;
    if (this.currentServer.idException) {
      return;
    }
    if (!this.currentServer.id) {
      return;
    }
    this.clusterStatusLoading = true;
    this.highAvailabilityService.getServerStatusInfo(this.currentServer.id, this.currentServer.name, this.currentServer.type)
      .subscribe(clusterStatusInfo => {
        this.clusterStatusLoading = false;
        this.clusterStatusInfo = clusterStatusInfo;
        this.clusterStatusInfo.applyRate = (this.clusterStatusInfo.sumOfWorkReplay + clusterStatusInfo.sumOfWorkReplay) / 5;
      }, err => {
        this.clusterStatusLoading = false;
        this.clusterStatusServerError = err.error ? err.error.err : err;
      });
  }
}
