/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { ConnectionManagerComponent } from './connection-manager.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ConnectionManagerService } from './connection-manager.service';
import { ConnectionUnitComponent } from './connection-unit.component';
import { SlaFormComponent } from './sla-form.component';
import { SlaDBServersComponent } from './sla-db-servers.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule,
    ReactiveFormsModule
  ],
  declarations: [
    ConnectionManagerComponent,
    ConnectionUnitComponent,
    SlaFormComponent,
    SlaDBServersComponent
  ],
  providers: [ConnectionManagerService]
})
export class ConnectionManagerModule { }
