<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col">
      <h4>
        <strong>Transactions</strong>
      </h4>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Data is not available. {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <ng-template [ngIf]="txnData">
    <div class="row">
      <div class="col-12 text-right">
        <button id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()" title="Refresh"><i class="icon icon-refresh"></i></button>
      </div>
    </div>

    <div class="table-responsive mt-1" *ngIf="txnData">
      <app-data-table [data]="txnData" [rowsPerPage]="20" noDataAlertMessage="No transactions are available.">
        <app-data-table-column property="sql_id">
          <ng-template appTableCell let-sqlid="item">
            <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['..','statements',sqlid,'transaction']">
              <i class="icon icon-search"></i>
            </button>
          </ng-template>
        </app-data-table-column>
        <app-data-table-column title="Transaction Stamp" property="sql_begintxtime" [sortable]="true">
        </app-data-table-column>
        <app-data-table-column title="Session ID" property="sql_sid" [sortable]="true">
        </app-data-table-column>
        <app-data-table-column title="Count" property="count" [sortable]="true">
        </app-data-table-column>
        <app-data-table-column title="Avg Run Time" property="avg_runtime" [sortable]="true">
        </app-data-table-column>
        <app-data-table-column title="Max Run Time" property="max_runtime" [sortable]="true">
        </app-data-table-column>
        <app-data-table-column title="Avg Memory" property="avg_mem" [sortable]="true">
          <ng-template appTableCell let-mem="item">
            {{mem | byte}}
          </ng-template>
        </app-data-table-column>
        <app-data-table-column title="Rows Processed" property="rows" [sortable]="true">
        </app-data-table-column>
      </app-data-table>
    </div>
  </ng-template>


</div>

