/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


import { AbstractSystemReport } from './abstract-system-report';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemReportsService } from './system-reports.service';

@Component({
  selector: 'app-lock-waiter-report',
  templateUrl: './lock-waiter-report.html'
})

export class LocksWithWaitersReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId = 'lock_with_waiter';
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };
  filteredReportData: any;
  filteringReportData: any;
  constructor(
    private route: ActivatedRoute,
    systemReportsService: SystemReportsService,
    notificationsService: NotificationsService,
    translate: TranslateService
  ) {
    super(systemReportsService, notificationsService, translate);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.getSystemReport(this.reportId);
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get('systemReports.locksWithWaiters').subscribe((text: string) => {
      this.setBreadcrumb(text);
    });
  }
  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.filterReportData();
  }
  filterReportData() {
    this.filteredReportData = [];
    this.filteringReportData = this.actualReportData;
    if (this.timeStamps.fromTimeStamp !== null || this.timeStamps.toTimeStamp !== null) {
      if (this.timeStamps.fromTimeStamp !== null && this.timeStamps.toTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.lock_establish && item.lock_establish.$date) {
            return item.lock_establish.$date > this.timeStamps.fromTimeStamp &&
              item.lock_establish.$date < this.timeStamps.toTimeStamp;
          }
        });
      } else if (this.timeStamps.fromTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.lock_establish && item.lock_establish.$date) {
            return item.lock_establish.$date > this.timeStamps.fromTimeStamp;
          }
        });
      } else if (this.timeStamps.toTimeStamp !== null) {
        this.filteredReportData = this.filteringReportData.filter(item => {
          if (item.lock_establish && item.lock_establish.$date) {
            return item.lock_establish.$date < this.timeStamps.toTimeStamp;
          }
        });
      }
      this.reportData = this.filteredReportData;
    } else {
      this.getSystemReport(this.reportId);
    }
  }
}
