/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InformixServer } from '../informixServer';
import { PrivilegeType, SQLAdminUserInfo } from './privileges.model';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { PrivilegesService } from './privileges.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ErrorMessageHandlers } from '../../../shared/forms/extendedFormControl.directive';
import { IACValidators } from '../../../shared/forms/validators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-sql-admin-privileges',
  templateUrl: './sql-admin-privileges.html',
  styleUrls: ['./sql-admin-privileges.scss']
})
export class SqlAdminPrivilegesComponent implements OnInit {
  @ViewChild('adminPrivilegesModal') adminPrivilegesModal: ModalDirective;
  @Input() server: InformixServer = null;
  adminPrivilegeForm: UntypedFormGroup;
  adminUsersInfo: Array<SQLAdminUserInfo>;
  isEdit = false;
  selectedPrivilegeControl: UntypedFormControl;
  selectedCustomPrivileges: Array<PrivilegeType> = [];
  isDisable = true;
  currentUserInfo: SQLAdminUserInfo = new SQLAdminUserInfo();
  serverType = environment.serverType;
  customPrivileges: Array<PrivilegeType> = [
    { typeName: 'Backup', typeDesc: 'Backup and restore commands', type: 'bar', checked: false },
    { typeName: 'Files', typeDesc: 'General operating system file commands', type: 'file', checked: false },
    { typeName: 'High Availability', typeDesc: 'High availability replication commands', type: 'ha', checked: false },
    { typeName: 'onstat', typeDesc: 'onstat commands', type: 'onstat', checked: false },
    {
      typeName: 'Replication',
      typeDesc: 'cdr commands for Enterprise Replication', type: 'replication', checked: false
    },
    { typeName: 'Storage', typeDesc: 'Storage and space commands', type: 'storage', checked: false },
    { typeName: 'SQL', typeDesc: 'SQL commands', type: 'sql', checked: false },
    { typeName: 'SQL Tracing', typeDesc: 'SQL tracing commands', type: 'sqltrace', checked: false },
    { typeName: 'IWA', typeDesc: `${this.serverType} Warehouse Accelerator stored procedures`, type: 'warehouse', checked: false },
    { typeName: 'Miscellaneous', typeDesc: 'General commands for a variety of tasks', type: 'misc', checked: false },
    {
      typeName: 'Grant',
      typeDesc: 'Grant and revoke privileges to run the SQL administration API commands for other users',
      type: 'grant', checked: false
    },
    { typeName: 'Read Only', typeDesc: 'All read-only commands', type: 'monitor', checked: false }];

  adminPrivileges: Array<PrivilegeType> = [
    { typeName: 'Admin', typeDesc: 'All the SQL administration API commands including grant and revoke', type: 'admin' },
    {
      typeName: 'Operator',
      typeDesc: 'All the SQL administration API commands except grant and revoke', type: 'operator'
    },
    { typeName: 'Monitor', typeDesc: 'All read-only commands', type: 'monitor' },
    { typeName: 'Custom', typeDesc: 'Select a custom set of privileges', type: '' }];
  priviligesHDR: HDRPermission;
  isDataLoading: Boolean = false;
  apiError: string;
  constructor(
    private privilegesService: PrivilegesService,
    private notificationsService: NotificationsService,
    private formBuilder: UntypedFormBuilder,
    private confirmationDialogService: ConfirmationDialogService,
    private hdrPermissionService: HDRPermissionService ) {
  }

  userNameErrorHandlers: ErrorMessageHandlers = {
    duplicateName: error => 'This user name already exists.',
    whitespace: error => 'Input field cannot be empty'
  };

  ngOnInit() {
    this.getAdminPrivilegeInfo();
    this.selectedPrivilegeControl = new UntypedFormControl(null, [Validators.required]);
    this.adminPrivilegeForm = this.formBuilder.group({
      userName: new UntypedFormControl(null, [this.duplicateNameValidator(), IACValidators.noWhitespaceValidator(), Validators.required]),
      selectedPrivilege: this.selectedPrivilegeControl,
    });
    this.adminPrivilegeForm.valueChanges.subscribe(() => {
      if (this.adminPrivilegeForm && this.adminPrivilegeForm.valid) {
        if (this.isEdit && this.currentUserInfo.privilegeTypes[0].toLowerCase() ===
          this.selectedPrivilegeControl.value.toLowerCase()) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
      } else {
        this.isDisable = true;
      }
    });
    this.priviligesHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  getSelectedPrivileges(event: Array<PrivilegeType>) {
    this.selectedCustomPrivileges = event;
    if (this.adminPrivilegeForm && this.adminPrivilegeForm.valid) {
      if (this.selectedPrivilegeControl.value === 'Custom') {
        if (!this.selectedCustomPrivileges.length) {
          this.isDisable = true;
        } else if (this.isEdit && JSON.stringify(this.selectedCustomPrivileges
          .map((privilege: PrivilegeType) => privilege.type).sort()) ===
          JSON.stringify(this.currentUserInfo.privilegeTypes.sort())) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
      }
    }
  }

  duplicateNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isValid: boolean;
      if (this.adminUsersInfo && this.adminUsersInfo.some(user => user.userName === control.value)) {
        isValid = false;
      } else {
        isValid = true;
      }
      return isValid ? null : { duplicateName: true };
    };
  }

  public getAdminPrivilegeInfo() {
    this.isDataLoading = true;
    this.privilegesService.getAdminPrivilegeInfo(this.server)
      .subscribe((usersInfo: SQLAdminUserInfo[]) => {
        if (usersInfo) {
          this.adminUsersInfo = usersInfo;
        }
        this.isDataLoading = false;
      }, err => {
        this.isDataLoading = false;
        if(err.error && err.error.err) {
          this.apiError = err.error.err;
        }
      });
  }

  getAdminPrivilegeNames(privilegeTypes: string[]) {
    const privileges = [];
    const allAdminPrivileges = this.adminPrivileges.concat(this.customPrivileges);
    if (privilegeTypes) {
      privilegeTypes.forEach(type => {
        privileges.push(' ' + allAdminPrivileges.filter(privilege => privilege.type === type)[0].typeName);
      });
    }
    return privileges.sort();
  }

  addUsers() {
    const adminUserInfo = new SQLAdminUserInfo();
    adminUserInfo.removedPrivileges = [];
    adminUserInfo.privilegeTypes = [];
    let privileges = [];
    let successMsg: string;
    let errorType: string;
    const selectedPrivilege = this.adminPrivilegeForm.value.selectedPrivilege;
    if (selectedPrivilege !== 'Custom') {
      privileges.push(this.adminPrivileges.filter(privilege => privilege.typeName === selectedPrivilege)[0].type);
    } else {
      privileges = this.selectedCustomPrivileges.map(privilege => privilege.type);
    }
    if (this.isEdit) {
      successMsg = 'The privileges for user ' + this.currentUserInfo.userName + ' were saved successfully.';
      errorType = 'modify';
      adminUserInfo.userName = this.currentUserInfo.userName;
      privileges.forEach(privilege => {
        if (this.currentUserInfo.privilegeTypes.filter(currentPrivilege => currentPrivilege === privilege).length === 0) {
          adminUserInfo.privilegeTypes.push(privilege);
        }
      });
      this.currentUserInfo.privilegeTypes.forEach(currentPrivilege => {
        if (privileges.filter(privilege => privilege === currentPrivilege).length === 0) {
          adminUserInfo.removedPrivileges.push(currentPrivilege);
        }
      });
      this.isEdit = false;
    } else {
      adminUserInfo.privilegeTypes = privileges;
      adminUserInfo.userName = this.adminPrivilegeForm.value.userName.trim();
      successMsg = 'Privilege to run the SQL administration API commands were granted to user '
        + adminUserInfo.userName + ' successfully.';
      errorType = 'grant';
    }
    this.privilegesService.addAdminPrivilegedUsers(this.server, adminUserInfo)
      .subscribe((res: any) => {
        this.notificationsService.pushGenericNotification(res.return_code, res.result_message, (type) => {
          if (type.isSuccess || type.isInfo) {
            this.getAdminPrivilegeInfo();
          }
        });
      }, err => {
        this.notificationsService.pushErrorNotification('Could not ' + errorType + ' SQL administration privilege. '
          + (err.error ? err.error.err : err));
      });
      this.adminPrivilegesModal.hide();
  }

  modifyAdminPrivilege(adminUserInfo: SQLAdminUserInfo) {
    if (this.priviligesHDR && !this.priviligesHDR.isAllow()) {
      return;
    }
    this.currentUserInfo.privilegeTypes = adminUserInfo.privilegeTypes;
    this.currentUserInfo.userName = adminUserInfo.userName;
    this.adminPrivilegeForm.controls['userName'].setValue(adminUserInfo.userName);
    if (adminUserInfo.privilegeTypes.length === 1 && this.adminPrivileges.some(privilege =>
      privilege.type === adminUserInfo.privilegeTypes[0])) {
      this.adminPrivilegeForm.controls['selectedPrivilege'].setValue(this.adminPrivileges.filter(privilege =>
        privilege.type === adminUserInfo.privilegeTypes[0])[0].typeName);
    } else {
      this.adminPrivilegeForm.controls['selectedPrivilege'].setValue('Custom');
      adminUserInfo.privilegeTypes.forEach(privilege => {
        this.customPrivileges.filter(customPrivilege => customPrivilege.type === privilege)[0].checked = true;
      });
    }
    this.adminPrivilegeForm.controls['userName'].disable();
    this.isEdit = true;
    this.isDisable = true;
    this.adminPrivilegesModal.show();
  }

  revokePrivileges(adminUserInfo: SQLAdminUserInfo) {
    if (this.priviligesHDR && !this.priviligesHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('revoke all privileges for user ' + adminUserInfo.userName + '?',
      () => this.onRevokeConfirmed(adminUserInfo));
  }

  onRevokeConfirmed(adminUserInfo: SQLAdminUserInfo) {
    this.privilegesService.revokeAdminPrivileges(this.server, adminUserInfo)
      .subscribe((result: any) => {
        this.notificationsService.pushGenericNotification(result.return_code, result.result_message, (type) => {
          if (type.isSuccess || type.isInfo) {
 this.getAdminPrivilegeInfo();
}
        });
        // this.getAdminPrivilegeInfo();
        // this.notificationsService.pushSuccessNotification('Privilege revoked for user ' + adminUserInfo.userName + '.');
      }, err => {
        this.notificationsService.pushErrorNotification('Could not revoke privilege. ' + (err.error ? err.error.err : err));
      });
  }

  onHidden() {
    this.adminPrivilegeForm.controls['userName'].enable();
    this.customPrivileges.map(privilege => privilege.checked = false);
    this.adminPrivilegeForm.reset();
    this.adminPrivilegeForm.clearValidators();
    this.isEdit = false;
  }
}
