/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { InformixServer } from '../informixServer';
import { HighAvailabilityService } from './high-availability.service';
import { ClusterSMXInfo } from './high-availability.model';

@Component({
  selector: 'app-smx-info',
  templateUrl: 'smx-info.html'
})
export class SMXInfoComponent implements OnInit {

  constructor(private highAvailabilityService: HighAvailabilityService) { }

  @Input() server: InformixServer;
  dataLoading = false;
  clusterSMXInfo: ClusterSMXInfo;
  serverNotInCluster: string;
  serverError: string;

  ngOnInit() {
    this.getSMXInfo();
  }

  refreshData() {
    this.clusterSMXInfo = null;
    this.getSMXInfo();
  }

  getSMXInfo() {
    this.dataLoading = true;
    this.highAvailabilityService.getSMXInfo(this.server).subscribe(clusterSMXInfo => {
      this.clusterSMXInfo = clusterSMXInfo;
      /*
       * Overriding HDR string to HDR SECONDARY for IDS
       */
      this.clusterSMXInfo.smxInfoList = this.clusterSMXInfo.smxInfoList.map(server => {
        if(server.type === 'HDR') {
          server.type = 'HDR SECONDARY';
        }
        return server;
      });

      this.dataLoading = false;
    },
      err => {
        this.dataLoading = false;
        if (err.status === 404) {
          this.serverNotInCluster = err.error ? err.error.err : err;
        } else {
          this.serverError = err.error ? err.error.err : err;
        }
      });
  }
}
