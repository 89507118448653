/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { Component, Input, OnChanges, OnDestroy, DoCheck, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from '../../../../node_modules/rxjs';
import { InformixTreeItem } from '../informixTreeItem';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { IncidentsService } from './incidents.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import * as moment from 'moment';
import { ConfirmationDialogService } from '../../shared/modal/confirmation-dialog.service';

@Component({
  selector: 'app-incidents',
  templateUrl: 'incidents.html',
  styleUrls: ['incidents.scss']
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class IncidentsComponent implements OnChanges, OnDestroy, DoCheck {

  @Input() isWidget = false;
  @Input() owner: InformixTreeItem;
  @Input() defaultHeight: any;
  serverNameCol: boolean;
  serversList: any[] = null;
  serversData: Array<{ label: any; selected: boolean }> = [];
  incidents: any[] = null;
  filteredIncidents: any[] = null;
  filteredServer: any[] = null;
  filteredServerName: any[] = null;
  filteredTimeStamp: any[] = null;
  incidentsSub: Subscription = null;
  dataLoadErrorMessage: string = null;
  selectedIncidents = 0;
  selectedServer = 'all';
  showSelected = false;
  isFiltered = false;
  constructor(
    private incidentsService: IncidentsService,
    private notifications: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService) {

  }

  @ViewChild('incidentsTable') incidentsTable: DataTableComponent;
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnDestroy() {
    if (this.incidentsSub) {
      this.incidentsSub.unsubscribe();
    }
  }
  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnChanges(changes: SimpleChanges) {
    if (changes.owner) {
      this.serverNameCol = changes.owner ? (changes.owner.currentValue instanceof InformixServerGroup ? true : false)
        : null;
      if (this.owner) {
        if ((!changes.owner.previousValue || this.owner.id !== changes.owner.previousValue.id)) {
          if (this.incidentsSub) {
            this.incidentsSub.unsubscribe();
          }
          this.incidentsSub = this.incidentsService.pollIncidents(this.owner).subscribe(incidents => {
            if (incidents) {
              this.incidents = incidents;
              this.incidents = this.incidents.map(incident => {
                incident.time = moment(incident.when_created).format('MMM DD, y, hh:mm:ss a');
                return incident;
              });
              for (const incidentItem of this.incidents) {
                incidentItem.msg = incidentItem.meta.msg;
              }
              if (this.serverNameCol) {
                if (this.incidents[0] && this.incidents[0].server_name) {
                  this.serversList = Array.from(new Set(this.incidents.map((item: any) => item.server_name)));
                }
                if (this.serversList.length > 0 && this.serversData.length === 0) {
                  this.setServers();
                }
              }
              this.filterServers(this.selectedServer);
            }
            this.dataLoadErrorMessage = null;
          }, err => {
            if (err.status === 403) {
              this.dataLoadErrorMessage = 'You do not have permissions to view all alerting incidents for this group.';
            } else {
              this.dataLoadErrorMessage = (err.error && err.error.err) ? err.error.err : err;
            }
          });
        }
      } else if (this.incidentsSub) {
        this.incidentsSub.unsubscribe();
      }
    }
  }


  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngDoCheck() {
    if (this.timeStamps.fromTimeStamp !== null || this.timeStamps.toTimeStamp !== null ||
      ((this.serversData.findIndex((server) => server.selected === true) > -1) && this.serverNameCol)) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }
    if (this.incidentsTable && this.showSelected) {
      this.selectedIncidents = this.incidentsTable.getSelectedRows().length;
      if (this.selectedIncidents < 1) {
        this.showSelected = false;
        this.filterServers('reset');
      }
    }
  }

  setServers() {
    this.serversList.forEach(server => {
      this.serversData.push({ label: server, selected: false });
    });
  }

  showOnlySelected() {
    this.showSelected = !this.showSelected;
    if (this.showSelected) {
      this.filteredIncidents = this.incidentsTable.getSelectedRows();
    } else {
      this.filterServers('reset');
    }
  }

  filterServers(filter: any) {
    if (!this.showSelected) {
      this.filteredTimeStamp = [];
      this.filteredIncidents = [];
      if (this.serverNameCol) {
        if (filter !== 'all' || (filter === 'all' && this.filteredServer === null)) {
          this.filteredServer = [];
          this.filteredServerName = [];
          this.serversData.forEach(column => {
            if (column.selected) {
              this.filteredServerName.push(column.label);
            }
          });
          if (this.filteredServerName.length === 0) {
            this.filteredServer = this.incidents;
          } else {
            this.filteredServer = this.incidents.filter(incident => this.filteredServerName.includes(incident.server_name));
          }
          this.selectedServer = 'refresh';
        }
      } else {
        this.filteredServer = this.incidents;
      }
      if (this.timeStamps.fromTimeStamp !== null || this.timeStamps.toTimeStamp !== null) {
        if (this.timeStamps.fromTimeStamp !== null && this.timeStamps.toTimeStamp !== null) {
          this.filteredTimeStamp = this.filteredServer.filter(incident => incident.when_created > this.timeStamps.fromTimeStamp &&
            incident.when_created < this.timeStamps.toTimeStamp);
        } else if (this.timeStamps.fromTimeStamp !== null) {
          this.filteredTimeStamp = this.filteredServer.filter(incident => incident.when_created > this.timeStamps.fromTimeStamp);
        } else if (this.timeStamps.toTimeStamp !== null) {
          this.filteredTimeStamp = this.filteredServer.filter(incident => incident.when_created < this.timeStamps.toTimeStamp);
        }
        this.filteredIncidents = this.filteredTimeStamp;
      } else {
        this.filteredIncidents = this.filteredServer;
      }
    }
  }

  markSelectedAsRead() {
    const unreadRows = this.incidentsTable.getSelectedRows().filter(row => row.state <= 2);
    this.incidentsTable.deselectSelectedRows();

    if (unreadRows.length < 1) {
      return;
    }

    const updateData: any[] = [];
    unreadRows.forEach(row => {
      row.state = 3;
      updateData.push({
        id: row.id,
        state: row.state,
        server_id: row.server_id
      });
    });

    this.incidentsService.updateIncidents(this.owner, updateData).then(() => {
      this.showSelected = false;
      this.notifications.pushSuccessNotification('Incident(s) acknowledged successfully');
    }).catch(err => {
      console.error('Error updating incidents', err);
    });
  }
  deleteSelected() {
    this.confirmationDialogService.show('delete selected Incident(s)?',
      () => this.onDeleteSelectedConfirm());
  }

  onDeleteSelectedConfirm() {
    const ids: any[] = this.incidentsTable.getSelectedRows().map(row => ({ id: row.id, server_id: row.server_id }));
    const deletedIds = [];
    for (const id of ids) {
      deletedIds.push(id.id);
    }
    const item = this.owner;
    this.incidentsService.deleteIncidents(item, ids).then(() => {
      this.notifications.pushSuccessNotification('Incident(s) deleted successfully');
      this.showSelected = false;
      this.incidents = this.incidents.filter(incident => !deletedIds.includes(incident.id));
      this.filterServers('deleteReset');
      this.incidentsTable.deselectSelectedRows();
    }).catch(err => {
      this.notifications.pushErrorNotification('Error occured while deleting incidents');
      console.error('Error deleting incidents', err);
    });
  }

  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
    this.filterServers(this.selectedServer);
  }
}
