<div class="container-fluid" [ngClass]="{'px-0': isERNode}">
  <form role="form" [formGroup]="formGroup">
    <h4 *ngIf="!isERNode">Add {{serverType}} Server Information</h4>
    <div class="form-group" *ngIf="editServer">
      <label class="col-sm-2 col-form-label font-weight-bold">Server ID</label>
      <div class="col-sm-6">
        {{editServer.id}}
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 col-form-label font-weight-bold"><span class="text-danger">* </span>Server
        Name</label>
      <div class="col-sm-6">
        <input class="form-control" formControlName="alias" placeholder="Insert server name..." tooltip=""
          appExtendedFormControl [customErrors]="aliasValidationErrors" appFocusOnCreate>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-6">
        <label class="font-weight-bold"><span class="text-danger">* </span>{{serverType}} Hostname</label>
        <input class="form-control" formControlName="hostname" placeholder="Insert {{serverType}} host name..." tooltip=""
          appExtendedFormControl>
      </div>
    </div>
    <div class="form-group">
      <div class="row ml-3 font-weight-bold">
        <app-radio-group formControlName="portOrSvcname" (change)="changePortOrServiceName()">
          <app-radio value="port">
            {{serverType}} Port Number
          </app-radio>
          <app-radio class="ml-4" value="serviceName">
            Service Name
          </app-radio>
        </app-radio-group>
      </div>
      <div class="{{columnSize}}" [ngSwitch]="formGroup.controls.portOrSvcname.value">
        <input *ngSwitchCase="'serviceName'" placeholder="Insert service name..." class="form-control"
          formControlName="serviceName" tooltip="" appExtendedFormControl>
        <input *ngSwitchCase="'port'" class="form-control" formControlName="port" tooltip="" appExtendedFormControl
          type="number" min="1" max="65535" placeholder="Insert {{serverType}} port number...">
      </div>
    </div>
    <h5 class="mt-1 {{columnSize}}">
      Monitoring Credentials
      <button *ngIf="editServer && !editingMonitorCredentials" type="button" class="btn btn-sm btn-icon"
        (click)="setEditingMonitorCredentials(true)" title="Edit monitoring credentials"><i
          class="icon icon-edit"></i></button>
      <button *ngIf="editServer && editingMonitorCredentials" type="button" class="btn btn-sm btn-icon"
        (click)="setEditingMonitorCredentials(false)" title="Undo edits to monitoring credentials"><i
          class="icon icon-reset"></i></button>
    </h5>
    <div class="form-group" *ngIf="!isERNode">
      <div class="col-sm-8 text-muted">
        <ng-container *ngIf="expandMonitorHelpText; else abbreviatedMonitorHelpText">
          {{ 'serverInfo.monitoringCredentialsHelp' | translate }}
        </ng-container>
        <ng-template #abbreviatedMonitorHelpText>
          {{ 'serverInfo.monitoringCredentialsHelpShort' | translate }}
          <a href="javascript:;" (click)="expandMonitorHelpText = true">See more...</a>
        </ng-template>
      </div>
    </div>
    <div class="form-group">
      <div class="{{columnSize}}">
        <label class="font-weight-bold"> <span class="text-danger">* </span> Username</label>
        <input class="form-control" formControlName="monitorUser" tooltip="" appExtendedFormControl>
      </div>
    </div>
    <div class="form-group">
      <div class="{{columnSize}}">
        <label class="font-weight-bold"><span class="text-danger">* </span> Password</label>
        <div class="input-group password-hide-show inner-addon right-addon">
          <input [type]="showPassword ? 'text' : 'password'" formControlName="monitorPassword" class="form-control"
            tooltip="" appExtendedFormControl (keyup)="toggleShow(true)">
          <ng-container *ngIf="editServer; else newMonitorPassword">
            <i class="icon icon-eye" (click)="toggleShow()"
              *ngIf="showPassword && editServer && editingMonitorCredentials && (formGroup.controls['monitorPassword'].dirty && formGroup.value.monitorPassword !== '')"></i>
            <i class="icon icon-eye-blocked" (click)="toggleShow()"
              *ngIf="!showPassword && editServer && editingMonitorCredentials && (formGroup.controls['monitorPassword'].dirty && formGroup.value.monitorPassword !== '')"></i>
          </ng-container>
          <ng-template #newMonitorPassword>
            <i class="icon icon-eye" (click)="toggleShow()"
              *ngIf="showPassword && (formGroup.controls['monitorPassword'].dirty && formGroup.value.monitorPassword !== '')"></i>
            <i class="icon icon-eye-blocked" (click)="toggleShow()"
              *ngIf="!showPassword && (formGroup.controls['monitorPassword'].dirty && formGroup.value.monitorPassword !== '')"></i>
          </ng-template>
        </div>
      </div>
    </div>
    <h5 class="mt-1 {{columnSize}}">
      Admin Credentials
      <button *ngIf="editServer && !editingAdminCredentials" type="button" class="btn btn-sm btn-icon"
        (click)="editAdminCredentialsClick(true)" title="Edit administration credentials"><i
          class="icon icon-edit"></i></button>
      <button *ngIf="editServer && editingAdminCredentials" type="button" class="btn btn-sm btn-icon"
        (click)="editAdminCredentialsClick(false)" title="Undo edits to administration credentials"><i
          class="icon icon-reset"></i></button>
    </h5>
    <div class="form-group" *ngIf="!isERNode">
      <div class="col-sm-8 text-muted">
        <ng-container *ngIf="expandAdminHelpText; else abbreviatedAdminHelpText">
          {{ 'serverInfo.adminCredentialsHelp' | translate }}
        </ng-container>
        <ng-template #abbreviatedAdminHelpText>
          {{ 'serverInfo.adminCredentialsHelpShort' | translate }}
          <a href="javascript:;" (click)="expandAdminHelpText = true">See more...</a>
        </ng-template>
      </div>
    </div>
    <div class="form-group">
      <div class="{{columnSize}}">
        <label class="font-weight-bold">Username</label>
        <input *ngIf="!editServer || editingAdminCredentials" class="form-control" formControlName="adminUser"
          tooltip="" appExtendedFormControl>
        <input disabled *ngIf="editServer && !editingAdminCredentials" class="form-control"
          [value]="editServer.adminUser">
      </div>
      <div class="form-group">
        <div class="{{columnSize}}">
          <label class="font-weight-bold">Password</label>
          <div class="input-group password-hide-show inner-addon right-addon">
            <input [type]="showAdminPassword ? 'text' : 'password'"
              class="form-control" formControlName="adminPassword" tooltip="" (keyup)="toggleAdminPassShow(true)" appExtendedFormControl>
            <ng-container *ngIf="editServer && editingAdminCredentials; else newAdminPassword">
              <i class="icon icon-eye" (click)="toggleAdminPassShow()"
                *ngIf="showAdminPassword && editServer && editingAdminCredentials && (formGroup.controls['adminPassword'].dirty && formGroup.value.adminPassword !== '')"></i>
              <i class="icon icon-eye-blocked" (click)="toggleAdminPassShow()"
                *ngIf="!showAdminPassword && editServer && editingAdminCredentials && (formGroup.controls['adminPassword'].dirty && formGroup.value.adminPassword !== '')"></i>
            </ng-container>
            <ng-template #newAdminPassword>
              <i class="icon icon-eye" (click)="toggleAdminPassShow()"
                *ngIf="showAdminPassword && (formGroup.controls['adminPassword'].dirty && formGroup.value.adminPassword !== '')"></i>
              <i class="icon icon-eye-blocked" (click)="toggleAdminPassShow()"
                *ngIf="!showAdminPassword && (formGroup.controls['adminPassword'].dirty && formGroup.value.adminPassword !== '')"></i>
            </ng-template>
          </div>
        </div>
      </div>
  </div>
    <h5 class="pt-2 pb-2 {{columnSize}}">
      Connection Properties
    </h5>
    <div formArrayName="connectionProperties">
      <div class="row ml-0 form-group"
        *ngFor="let connectionProperty of connectionPropertiesForms.controls; let i = index" [formGroupName]="i">
        <div class="col-sm-2">
          <input type="text" #nameInputCon class="form-control" placeholder="Name" (keyup)="getConName($event, i)"
            formControlName="name" tooltip="" placement="right" appExtendedFormControl>
        </div>
        <div class="col-sm-6">
          <div class="input-group password-hide-show inner-addon right-addon">
            <input [type]="connectionProperty.controls['hideShowIcon'].value ? 'text' : 'password' " #conValue
              class="form-control" placeholder="Value" formControlName="value" tooltip="" placement="right"
              appExtendedFormControl (keyup)="togglePassShow(i, true)">
            <i class="icon icon-eye" (click)="togglePassShow(i)"
              *ngIf="connectionProperty.controls['showPasswordIcon'].value && connectionProperty.controls['hideShowIcon'].value && (connectionProperty.controls['value'].value !== '' || (connectionProperty.controls['value'].dirty && connectionProperty.controls['value'].value !== ''))"></i>
            <i class="icon icon-eye-blocked" (click)="togglePassShow(i)"
              *ngIf="connectionProperty.controls['showPasswordIcon'].value && !connectionProperty.controls['hideShowIcon'].value && (connectionProperty.controls['value'].value !== '' || (connectionProperty.controls['value'].dirty && connectionProperty.controls['value'].value !== ''))"></i>
          </div>
        </div>
        <div class="col-sm-1">
          <button type="button" class="btn btn-danger" (click)="removeConnectionProperty(i)">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-secondary ml-3" (click)="addConnectionProperty()"
      [disabled]="!shouldAdd()">
      <i class="icon icon-plus"></i>
      Add Connection Property
    </button>
    <div class="mt-4">
      <ng-containaer [tooltip]="isWorkOffline ? 'You are working in offline mode' : null">
      <button type="button" class="btn btn-sm btn-secondary ml-3" [disabled]="!formGroup.valid || isWorkOffline"
        (click)="testConnection()">{{'Test Connection'}}</button>
      </ng-containaer>
    </div>
    <div class="{{alertClass}}" *ngIf="alertClass && iconClass && !isServerStatusLoading">
      <i class="{{iconClass}}"></i> Current Server Status: <strong>{{serverStatus?.description}}</strong>
    </div>
    <div class="alert alert-danger mt-4" *ngIf="serverStatusErrorMessage && !isServerStatusLoading">
      <i class="icon icon-times-circle text-danger"></i> {{serverStatusErrorMessage}}
    </div>

    <div *ngIf="isServerStatusLoading" class="d-flex justify-content-center">
      <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
    </div>

    <div class="mt-4" *ngIf="!isERNode">
      <button type="submit" class="btn btn-primary ml-3" [disabled]="!canSubmit()" (click)="onSubmit()">{{(editServer)?
        'Save': 'Submit'}}</button>
    </div>
  </form>
</div>
