<div class="container-fluid page-container">
  <h3>Server Configuration</h3>
  <p>Configure server-wide system settings</p>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-xl-auto col-lg-auto col-sm-12">
        <h5>Background Job Threads</h5>
      </div>
      <div class="col-xl-auto col-lg-auto col-sm-12 text-muted">
        Configure the maximum number of threads used for background jobs
      </div>
    </div>
    <div class="form-group row form-inline">
      <label class="col-sm-2 col-form-label">Number of Threads</label>
      <input type="number" class="form-control ml-3" formControlName="nThreads" min="1" tooltip="" appExtendedFormControl>
    </div>
    <div class="row">
      <div class="col-xl-auto col-lg-auto col-sm-12">
        <h5>SQL Session Timeout</h5>
      </div>
      <div class="col-xl-auto col-lg-auto col-sm-12 text-muted">
        Specifiy the SQL session timeout in seconds. SQL sessions are used to run ad hoc
        SQL queries on the Schema Manager page.
      </div>
    </div>
    <div class="form-group row form-inline">
      <label class="col-xs-12 col-xs-4 col-sm-2 col-form-label">SQL Session Timeout</label>
      <input type="number" class="form-control ml-3" formControlName="restSqlSessionTimeout" min="10" tooltip=""
        appExtendedFormControl>
    </div>
  </form>
  <button class="btn btn-primary mt-2" [disabled]="!formGroup.valid || isBtnDisable" (click)="saveConfig()">Save</button>
</div>
