/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InformixServer } from '../informixServer';

@Component({
  selector: 'app-add-pool-entry',
  template: `
  <app-storage-pool-entry-form [server]="server" (success)="success.emit($event)" (error)="error.emit($event)" (close)="close.emit($event)">
  </app-storage-pool-entry-form>
    `
})

export class AddPoolEntryComponent {
  @Input() server: InformixServer = null;

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

}

