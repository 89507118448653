/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractAlertConfigComponent } from './alertConfig';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twilio-config',
  templateUrl: 'twilioConfig.html'
})
export class TwilioConfigComponent extends AbstractAlertConfigComponent implements OnInit {

  private config: any;
  formGroup: UntypedFormGroup = null;

  ngOnInit() {
  }

  public getType() {
    return 'twilio';
  }

  public setConfig(config: any) {
    if (!config) {
      config = {};
    }
    this.config = config;

    if (!this.formGroup) {
      this.formGroup = new UntypedFormGroup({
        sid: new UntypedFormControl(config.sid, [Validators.required]),
        authToken: new UntypedFormControl(config.authToken, [Validators.required]),
        fromPhone: new UntypedFormControl(config.fromPhone, [Validators.required])
      });
    }
  }

  public getConfig() {
    return this.formGroup.value;
  }

  public isValid(): boolean {
    return this.formGroup && this.formGroup.valid;
  }

  public isModified(): boolean {
    const form = this.formGroup.value;
    return (this.config.sid !== form.sid
      || this.config.authToken !== form.authToken
      || this.config.fromPhone !== form.fromPhone);
  }
}
