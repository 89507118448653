/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/

/**
 * This component file will display the Privileges present in database selected by the user in Schema Manager.
 */
import { Component, OnInit, Input } from '@angular/core';
import { SchemaService } from '../schema.service';
import { InformixServer } from '../../informixServer';

@Component({
  selector: 'app-schema-privileges',
  templateUrl: './schema-privileges.component.html',
  styleUrls: ['../database/database.component.scss']
})
export class SchemaPrivilegesComponent implements OnInit {

  @Input() currentDB: string;
  @Input() server: InformixServer;
  dbPrivileges: any;
  dataLoading = false;
  serverError: string;

  constructor(private schemaService: SchemaService) { }

  ngOnInit() {
    this.getDbPrivilege();
  }

  private getDbPrivilege() {
    this.dataLoading = true;
    this.schemaService.getDatabasePrivileges(this.server, this.currentDB).subscribe(data => {
      data.forEach((element: any) => {
        switch (element.privilegeType) {
          case 'R':
            element.privilegeType = 'RESOURCE';
            break;
          case 'C':
            element.privilegeType = 'CONNECT';
            break;
          case 'D':
            element.privilegeType = 'DBA';
            break;
        }
      });
      this.dbPrivileges = data;
      this.dataLoading = false;
    },
      err => {
        this.dataLoading = false;
        this.serverError = err.error ? err.error.err : err;
      });
  }
}
