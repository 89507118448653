<ng-template [ngIf]="incidents">
  <div *ngIf="!isWidget" class="row no-gutters">
    <div class="col-2">
      <h4>
        <strong>Alerting Incidents</strong>
      </h4>
    </div>
    <div *ngIf="serverNameCol" class="col-1 drop-down-col">
      <app-dropdown-multi-select #multiselect maxHeight="300px" minWidth="14rem" [data]="serversData"
        [showSearch]="true" label="Servers" (valueChanged)="filterServers($event)">
      </app-dropdown-multi-select>
    </div>
    <div class="col-5 pr-0">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
    <div class="col text-right">
      <ng-template [ngIf]="incidents.length > 0">
        <span class="font-weight-bold pr-2" [hidden]="!(incidentsTable.selectedCount > 0)">
          {{incidentsTable.selectedCount}} of {{filteredIncidents.length}} incident(s) selected
        </span>
        <span class="font-weight-bold pr-2" [hidden]="!(incidentsTable.selectedCount < 1)">
          <span *ngIf="!isFiltered">Total </span><span *ngIf="isFiltered">Filtered </span> Incidents:
          {{filteredIncidents.length}}
        </span>
        <app-checkbox class="selected-checkbox" [checked]="showSelected" (click)="showOnlySelected()"
          [hidden]="incidentsTable.selectedCount < 1">
          Show selected only
        </app-checkbox>
        <button class="btn btn-sm btn-icon" tooltip="Remove Selected" [disabled]="incidentsTable.selectedCount < 1"
          (click)="deleteSelected()">
          <i class="icon icon-trash"></i>
        </button>
        <button class="btn btn-primary" [disabled]="incidentsTable.selectedCount < 1"
          (click)="markSelectedAsRead()">Acknowledge</button>
      </ng-template>
    </div>
  </div>
  <app-data-table #incidentsTable [data]="filteredIncidents" [enableStickyHeader]="true" [enableFixedPagination]="false"
    [sortDescending]="false" searchLabel="Search for incidents ( Use ' , ' OR ' + ' operator for multiple keyword search )." [rowsPerPage]="10" sortBy="time"
    [enableSelect]="!isWidget" idProperty="id" [enableSearch]="!isWidget"
    noDataAlertMessage="No incidents have occurred yet." [minHeight]="defaultHeight">
    <app-data-table-column *ngIf="serverNameCol" title="Server Name" property="server_name" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-incident="row">
        <span [ngStyle]="{'font-weight': incident.state <= 2 ? 'bold' : 'normal'}">
          {{incident.server_name}}
        </span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Message" property="msg" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-incident="row">
        <span [ngStyle]="{'font-weight': incident.state <= 2 ? 'bold' : 'normal'}">
          {{incident.msg}}
        </span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Time" property="time" sortProperty="when_created" [sortable]="true">
    </app-data-table-column>
  </app-data-table>
</ng-template>
<ng-template [ngIf]="dataLoadErrorMessage">
  <div class="alert alert-warning">
    Cannot load alerting incidents. {{dataLoadErrorMessage}}
  </div>
</ng-template>
