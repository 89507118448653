<div class="container-fluid page-container">
  <h3>{{isNewUser ? 'New' : 'Edit'}} User</h3>
  <form [formGroup]="formGroup">
    <div class="row mr-0">
      <div class="col-12 col-xl-4">
        <h5 class="mb-2">User Info</h5>
        <label><strong>Username</strong></label>
        <input type="text" class="form-control mb-2" formControlName="name" tooltip="" autocomplete="off"
          appExtendedFormControl>

        <app-change-password-form *ngIf="isNewUser" formControlName="password"></app-change-password-form>
        <label *ngIf="!serverPermissionsDisabled" class="mt-3">Enter expiration date or expiration days</label>
        <div *ngIf="!serverPermissionsDisabled" class="row align-items-center">
          <div class="col-12 col-lg-6 col-xl-7">
            <label class="col-form-label"><strong>Expiration Date</strong></label>
            <div class="input-group">
              <input type="text" class="form-control w-auto pl-2" placeholder="Expiration Date" bsDatepicker
                formControlName="expirationDate" #fromDatePicker="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                [minDate]="tomorrowsDate" />
              <div class="input-group-append">
                <span class="input-group-text rounded-0" (click)="fromDatePicker.toggle()" [attr.aria-expanded]="fromDatePicker.isOpen">
                  <i class="icon icon-calendar icon-padding"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-5">
            <label class="col-form-label"><strong>Expiration Days</strong></label>
            <input type="number" class="form-control expiration-days" formControlName="expirationDays" autocomplete="off">
          </div>
        </div>

        <div class="mt-2" formArrayName="permissions">
          <div *ngFor="let perm of getPermissionControls(); let i = index">
            <app-checkbox [tooltip]="getPermissionTooltip(i)" [formControlName]="i" [disabled]="isLastAdminPermission(i)">
              {{permissions[i].name}}
            </app-checkbox>
          </div>
        </div>
        <button class="btn btn-primary mt-2" [disabled]="!formGroup.valid" (click)="onSaveClick()">Save</button>
      </div>
      <div *ngIf="rootPermissionsRow" class="col-12 col-xl-8 mt-4 mt-xl-0">
        <div class="row mb-2">
          <div class="col">
            <h5>Select Permissions</h5>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-secondary" (click)="clearAllServerPermissions()" [disabled]="serverPermissionsDisabled">
              Clear All
            </button>
          </div>
        </div>

        <div class="position-relative">
          <div class="table-responsive" style="height: 500px">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th class="w-100">Groups & Servers</th>
                  <th class="permission-column" *ngFor="let permission of serverPermissions">
                    {{permission.name}}
                  </th>
                </tr>
              </thead>

              <tbody>
                <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: rootPermissionsRow, level: 0 }">
                </ng-template>
              </tbody>
            </table>
          </div>

          <div class="permissions-disabled-overlay" *ngIf="serverPermissionsDisabled">
            <div class="alert alert-info m-2">
              As a System Administrator, this user will have complete access to all groups and servers.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #nodeTemplateRef let-node="node" let-level="level">
  <tr>
    <td [ngClass]="{ 'cursor-pointer': node.isGroup && node.children.length > 0 }" (click)="node.expanded = !node.expanded">
      <div class="d-flex align-items-center" [ngStyle]="{'padding-left.px': level * 15}">
        <div class="expand-button">
          <i *ngIf="node.children.length > 0" class="icon pr-2" [ngClass]="node.expanded ? 'icon-chevron-down' : 'icon-chevron-right'">
          </i>
        </div>
        <div class="text-nowrap">
          <i class="icon pr-2" [ngClass]="node.isGroup ? 'icon-folder' : 'icon-server'"></i>
          {{node.item.name}}
        </div>
      </div>
    </td>

    <ng-container [formGroup]="node.formGroup">
      <ng-container formArrayName="permissions">
        <td class="permission-column" *ngFor="let permission of serverPermissions; let i = index">
          <app-checkbox [formControlName]="i"></app-checkbox>
        </td>
      </ng-container>
    </ng-container>
  </tr>

  <ng-container *ngIf="node.isGroup && node.expanded && node.children.length > 0">
    <ng-container *ngFor="let child of node.children">
      <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: child, level: level + 1 }">
      </ng-template>
    </ng-container>
  </ng-container>
</ng-template>
