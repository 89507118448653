/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2018, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent {

  @Input() label: string;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor() { }

  getTemplate(): TemplateRef<any> {
    return this.templateRef;
  }
}
