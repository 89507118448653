<div class="card card-body" *ngIf="serverInfo || fgWritesSensorRunning || seqScansSensorRunning" >
  <h4 class="card-title">Storage Performance</h4>

  <div class="row">
    <div *ngIf="serverInfo && (!viewMonitoredData || !checkpointSensorRunning)" class="col mt-2">
      <div class="card text-center">
        <div class="card-header"><a [routerLink]="['checkpoints']">Last Checkpoint</a></div>
        <div class="card-body">
          <h6 *ngIf="storagePerfInfo && storagePerfInfo.last_checkpoint">
            <strong>{{storagePerfInfo.last_checkpoint.cp_time.toFixed(3)}} seconds</strong>
            <br/>
            {{storagePerfInfo.last_checkpoint.timestamp | timeSince}}
            <br/>
          </h6>
        </div>
      </div>
    </div>

    <div *ngIf="viewMonitoredData && checkpointSensorRunning" class="col mt-2">
      <div class="card text-center">
        <div class="card-header"><a [routerLink]="['checkpoints']">Checkpoint Time</a> (in seconds)</div>
        <div class="card-body">
          <app-sensor-chart #checkpointSensorGraph [server]="server"
            [config]="{title: ' ', height:150, sensorId: 'checkpoint', metricList: ['cp_time'], colors: [checkpointChartColor], showControls: false, showLegend: false}">
          </app-sensor-chart>
        </div>
      </div>
    </div>

    <div *ngIf="(!viewMonitoredData || !diskioSensorRunning) && serverInfo" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Reads from Cache</div>
        <div class="card-body">
          <h4 *ngIf="readCachePercentage">{{readCachePercentage}}%</h4>
        </div>
      </div>
    </div>

    <div *ngIf="(!viewMonitoredData || !diskioSensorRunning) && serverInfo" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Writes from Cache</div>
        <div class="card-body">
          <h4 *ngIf="writeCachePercentage">{{writeCachePercentage}}%</h4>
        </div>
      </div>
    </div>

    <div *ngIf="viewMonitoredData && diskioSensorRunning" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Cache Hit Rates</div>
        <div class="card-body">
          <app-sensor-chart #cacheHitRateSensorGraph [server]="server"
            [config]="{showTitle: false, height:150, sensorId: 'diskio', metricList: ['cached_read_percent', 'cached_write_percent'], showControls: false}">
          </app-sensor-chart>
        </div>
      </div>
    </div>

    <div *ngIf="serverInfo || fgWritesSensorRunning" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Foreground Writes {{(viewMonitoredData && fgWritesSensorRunning)? '(per second)': ''}}</div>
        <div class="card-body">
          <h4 *ngIf="(!viewMonitoredData || !fgWritesSensorRunning) && storagePerfInfo">{{storagePerfInfo.pf_fgwrites}}</h4>
          <app-sensor-chart #fgWritesSensorGraph *ngIf="viewMonitoredData && fgWritesSensorRunning" [server]="server"
            [config]="{title: ' ', height:150, sensorId: 'fgwrites', metricList: ['fgwrites_total'], colors: [fgWritesChartColor], showControls: false, showLegend: false}">
          </app-sensor-chart>
        </div>
      </div>
    </div>

    <div *ngIf="serverInfo || seqScansSensorRunning" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Sequential Scans {{(viewMonitoredData && seqScansSensorRunning)? '(per second)': ''}}</div>
        <div class="card-body">
          <h4 *ngIf="(!viewMonitoredData || !seqScansSensorRunning) && storagePerfInfo">{{storagePerfInfo.pf_seqscans}}</h4>
          <app-sensor-chart #seqScansSensorGraph *ngIf="viewMonitoredData && seqScansSensorRunning" [server]="server"
            [config]="{title: ' ', height: 150, sensorId: 'seqscans', metricList: ['seqscans'], colors: [seqScansChartColor], showControls: false, showLegend: false}">
          </app-sensor-chart>
        </div>
      </div>
    </div>
  </div>
</div>
