/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';

interface TimeUnit {
  seconds: number;
  name: string;
}

export const TIME_UNITS_ARRAY: TimeUnit[] = [
  { seconds: 1, name: 'second' },
  { seconds: 60, name: 'minute' },
  { seconds: 3600, name: 'hour' },
  { seconds: 86400, name: 'day' },
  { seconds: 604800, name: 'week' },
  { seconds: 2592000, name: 'month' }
];

@Component({
  selector: 'app-input-time',
  template: `
	<form class="form-inline" [formGroup]="formGroup">
		<input type="number" class="form-control form-control-sm mr-sm-1" [min]="min" style="width:50px" formControlName="value">
		<select class="form-control form-control-sm" formControlName="unit">
			<option *ngFor="let unit of timeUnits" [ngValue]="unit">{{unit.name + (isPlural ? 's' : '')}}</option>
		</select>
	</form>
	`,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: TimeInputComponent, multi: true }
  ]
})
export class TimeInputComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() formControl: UntypedFormControl;
  @Input() usePlural = true;
  @Input() min = 1;
  @Input() timeUnits = TIME_UNITS_ARRAY;

  private onChangeFunction: any = null;

  formGroup: UntypedFormGroup;
  isPlural = false;

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      value: new UntypedFormControl('1', [Validators.required, Validators.pattern('[0-9][0-9]*')]),
      unit: new UntypedFormControl(this.timeUnits[0], Validators.required)
    });

    this.formGroup.valueChanges.subscribe(() => {
      const controls = this.formGroup.controls;
      let value = parseInt(controls['value'].value, 10);
      if (!isNaN(value)) {
        this.isPlural = (this.usePlural && value !== 1);
        value *= controls['unit'].value.seconds * 1000;
      } else {
        value = null;
      }

      if (this.onChangeFunction) {
        this.onChangeFunction(value);
      }
    });

    if (this.formControl) {
      this.writeValue(this.formControl.value);
    }
  }

  writeValue(value: any) {
    if (typeof value === 'number') {
      if (value >= 1000) {
        const seconds = Math.floor(value / 1000);
        for (let i = this.timeUnits.length - 1; i >= 0; i--) {
          if (seconds % this.timeUnits[i].seconds === 0) {
            this.formGroup.controls['value'].setValue(seconds / this.timeUnits[i].seconds);
            this.formGroup.controls['unit'].setValue(this.timeUnits[i]);
            break;
          }
        }
      } else {
        this.formGroup.controls['value'].setValue(0);
        this.formGroup.controls['unit'].setValue(this.timeUnits[0]);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any) {

  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (c.value === null) {
      return { required: true };
    } else if (c.value < this.min || !this.formGroup.valid) {
      return { err: true };
    }
    return null;
  }
}
