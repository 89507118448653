/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { RecoveryLogsComponent } from './recovery-logs.component';
import { ServerResolver } from '../server-resolver.service';
import { SpacesListComponent } from './spaces-list.component';
import { StorageDashboardComponent } from './storage-dashboard.component';
import { StoragePoolListComponent } from './storage-pool-list.component';
import { Route } from '@angular/router';
import { BackupRoutes } from './backup/backup.routes';
import { TablesIndexesPageComponent } from './tables-indexes/tables-indexes-page.component';

export const StorageRoutes: Route[] = [
  {
    path: 'storage',
    children: [
      {
        path: '',
        component: StorageDashboardComponent,
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: 'spaces',
        component: SpacesListComponent,
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: 'pool',
        component: StoragePoolListComponent,
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: 'rlogs',
        component: RecoveryLogsComponent,
        resolve: {
          server: ServerResolver
        }
      },
    {
      path: 'tablesIndexes',
        component: TablesIndexesPageComponent,
        resolve: {
          server: ServerResolver
        }
    },

      ...BackupRoutes
    ]
  }

];
