/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractAlertConfigComponent } from './alertConfig';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pager-duty-config',
  template: `
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <div class="row form-group">
      <label class="col-sm-2 col-form-label">Service Key</label>
      <div class="col-sm-6">
        <input type="text" class="form-control" formControlName="key" tooltip="" appExtendedFormControl>
      </div>
    </div>
  </form>
  `
})
export class PagerDutyConfigComponent extends AbstractAlertConfigComponent implements OnInit {

  private config: any;
  formGroup: UntypedFormGroup = null;

  ngOnInit() {
  }

  public getType() {
    return 'pagerduty';
  }

  public setConfig(config: any) {
    if (!config) {
      config = {};
    }
    this.config = config;

    if (!this.formGroup) {
      this.formGroup = new UntypedFormGroup({
        key: new UntypedFormControl(config.key, [Validators.required])
      });
    }
  }

  public getConfig() {
    return this.formGroup.value;
  }

  public isValid(): boolean {
    return this.formGroup && this.formGroup.valid;
  }

  public isModified(): boolean {
    const form = this.formGroup.value;
    return this.config.key !== form.key;
  }
}
