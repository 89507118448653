/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerChunk } from './serverChunk';

@Component({
  selector: 'app-modify-chunk',
  templateUrl: 'modify-chunk.html'
})
export class ModifyChunkComponent {

  @Input() server: InformixServer = null;
  @Input() selectedChunk: ServerChunk = null;

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  public doClose() {
    this.close.emit();
  }

  public doModifyChunk() {
    this.informixServerStorageService.modifyChunk(this.server.id, this.selectedChunk.chknum,
      (this.selectedChunk.extendable === 1) ? 0 : 1).then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

}
