/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

// Convert interval data into an hour to second interval string
@Pipe({ name: 'intervalHS' })
export class IntervalHSPipe implements PipeTransform {
  transform(interval: any): string {
    if (interval === null || interval === undefined) {
      return interval;
    }
    let seconds = 0;
    if (typeof (interval) === 'number') {
      seconds = interval;
    } else if (interval.IntervalDF && interval.IntervalDF.seconds) {
      seconds = interval.IntervalDF.seconds;
    } else {
      throw new Error('unexpected data for intervalDF: ' + interval);
    }

    let intervalStr = '';
    if (seconds < 0) {
      intervalStr += '-';
      seconds *= -1;
    }
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    if (hours < 10) {
      intervalStr += '0';
    }
    intervalStr += hours;
    intervalStr += ':';
    if (minutes < 10) {
      intervalStr += '0';
    }
    intervalStr += minutes;
    intervalStr += ':';
    if (seconds < 10) {
      intervalStr += '0';
    }
    intervalStr += seconds;
    return intervalStr;
  }
}
