/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AbstractSystemReport } from './abstract-system-report';
import { InformixServer } from '../informixServer';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemReportsService } from './system-reports.service';
import { SqltraceService } from '../sqltrace/sqltrace.service';

@Component({
  selector: 'app-sqltrae-report',
  templateUrl: './sqltrace-report.html'
})

export class SqltraceReportComponent extends AbstractSystemReport implements OnInit {

  server: InformixServer = null;
  reportId: string = null;
  reportTitle: string = null;
  timeStamps = { fromTimeStamp: null, toTimeStamp: null };
  sqltraceEnabled = true;

  constructor(
    private route: ActivatedRoute,
    systemReportsService: SystemReportsService,
    notificationsService: NotificationsService,
    translate: TranslateService,
    private sqltraceService: SqltraceService,
  ) {
    super(systemReportsService, notificationsService, translate);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.reportId = data.reportId;
      this.loadServer(data.server);
      this.getSqltraceStatus();
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    let messageKey;
    if (this.reportId === 'slowest_sql') {
      messageKey = 'slowestSQLStatements';
    } else if (this.reportId === 'sql_highest_estimated_cost') {
      messageKey = 'sqlWithHighestEstimatedCost';
    } else if (this.reportId === 'sql_most_buffer_activity') {
      messageKey = 'sqlWithMostBufferActivity';
    } else if (this.reportId === 'sql_most_iotime') {
      messageKey = 'sqlWithMostIOTime';
    } else if (this.reportId === 'sql_most_memory') {
      messageKey = 'sqlWithMostMemory';
    } else {
      messageKey = 'slowestSQLStatements';
    }

    this.translate.get('systemReports.' + messageKey).subscribe((text: string) => {
      this.reportTitle = text;
      this.setBreadcrumb(text);
    });
  }

  public getSqltraceStatus() {
    this.sqltraceService.getSqltraceSummary(this.server).subscribe(sqltrace => {
      this.sqltraceEnabled = sqltrace.config.state === 'On';
      if (this.sqltraceEnabled) {
        this.getSqltraceReport(this.reportId, null, null);
      }
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  getSqltraceReport(id: string, fromTimeStamp: any, toTimeStamp: any) {
    this.reportData = null;
    this.systemReportsService.runSystemReport(this.server, id, null, fromTimeStamp, toTimeStamp).subscribe((res: any) => {
      this.reportData = [];
      // convert each row in the result to an array of one element,
      // because we use data tables to display stats about each sql statement
      res.forEach(element => {
        const arrayelement = [];
        arrayelement.push(element);
        this.reportData.push(arrayelement);
      });
    }, err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
    });
  }

  updateReport() {
    this.getSqltraceReport(this.reportId, this.timeStamps.fromTimeStamp / 1000, this.timeStamps.toTimeStamp / 1000);
  }

  updateTimeStampValues(timeStampValues: any) {
    if ((!timeStampValues.isTimeValid || !isFinite(timeStampValues.timeStamp.fromDate) ||
      !isFinite(timeStampValues.timeStamp.toDate) ||
      (!timeStampValues.timeStamp.fromDate && !timeStampValues.timeStamp.toDate))) {
      this.timeStamps.fromTimeStamp = null;
      this.timeStamps.toTimeStamp = null;
    } else {
      this.timeStamps.fromTimeStamp = timeStampValues.timeStamp.fromTimeStamp;
      this.timeStamps.toTimeStamp = timeStampValues.timeStamp.toTimeStamp;
    }
  }

}
