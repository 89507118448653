/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterContentInit, ContentChild, Directive, Input, TemplateRef, Component } from '@angular/core';

@Directive({
  selector: '[appTableExpand]'
})
export class TableExpandDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'app-data-table-expand',
  template: ''
})
export class DataTableExpandComponent implements AfterContentInit {

  @Input() expandCondition: (data: any) => boolean = null;

  @ContentChild(TableExpandDirective) tableExpand: TableExpandDirective;
  public expandTemplate: TemplateRef<any> = null;

  ngAfterContentInit() {
    if (this.tableExpand) {
      this.expandTemplate = this.tableExpand.templateRef;
    }
  }
}
