/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';
import { PermissionGuard } from '../../shared/user/permissionGuard.service';
import { SystemAlertsComponent } from './alerts/systemAlerts.component';
import { SystemDataCleanupConfigComponent } from './data-cleanup/system-data-cleanup-config.component';
import { CreateSensorComponent } from './sensor-types/create-sensor/create-sensor.component';
import { CustomSystemReportsRoutes } from './custom-system-reports/custom-system-reports.routes';
import { SensorTypesComponent } from './sensor-types/sensor-types/sensor-types.component';
import { SystemServerConfigComponent } from './server/system-server-config.component';
import { SystemSettingsComponent } from './system-settings.component';
import { UserResolver } from './users/user-resolver.service';
import { UserFormComponent } from './users/userForm.component';
import { UsersComponent } from './users/users.component';

export const SystemSettingsRoutes: Route[] = [
  {
    path: 'system-settings',
    component: SystemSettingsComponent,
    canActivate: [PermissionGuard],
    canActivateChild: [PermissionGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'alerts'
      },
      {
        path: 'alerts',
        component: SystemAlertsComponent
      },
      {
        path: 'data-cleanup',
        component: SystemDataCleanupConfigComponent
      },
      {
        path: 'server',
        component: SystemServerConfigComponent
      },
      {
        path: 'sensors',
        component: SensorTypesComponent
      },
      {
        path: 'sensors/edit',
        component: CreateSensorComponent
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UsersComponent,
            pathMatch: 'full'
          },
          {
            path: 'new',
            component: UserFormComponent
          },
          {
            path: 'edit/:userId',
            component: UserFormComponent,
            resolve: {
              user: UserResolver
            }
          },
          {
            path: '**',
            redirectTo: ''
          }
        ]
      },

      ...CustomSystemReportsRoutes
    ]
  }
];
