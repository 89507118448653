/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';

import { CanActivateService } from './can-activate.service';
import { LoginComponent } from './login.component';

export const LoginRoutes: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [CanActivateService]
  }
];
