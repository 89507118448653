<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-md-8">
      <h4 class="page-header">
        <strong>Virtual Processors</strong>
        <app-data-source-toggle class="ml-2" [viewMonitoredData]="false"
          (viewMonitoredDataStateChange)="handleDataSourceToggle($event)"></app-data-source-toggle>
        <i *ngIf="server.hasMonitorPassword && !vps && !dataLoadErrorMessage"
          class="icon icon-circle-notch icon-spin ml-2"></i>
      </h4>
      <ng-container *ngIf="!dataLoadErrorMessage">
        <small *ngIf="viewLiveData">You are currently viewing live data.</small>
        <small *ngIf="!viewLiveData">You are currently viewing monitored data from the sensor.</small>
      </ng-container>
    </div>
    <div class="col-md-4 text-right">
      <button id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()" title="Refresh">
        <i class="icon icon-refresh"></i>
      </button>
      <app-sensors-required-control *ngIf="!viewLiveData" [server]="server" [monitoringProfile]="monitoringProfile"
        [sensorList]="['vps']" (monitoringProfileUpdated)="getMonitoringProfile()"></app-sensors-required-control>
    </div>
  </div>

  <div *ngIf="viewLiveData" class="mt-2">


      <ng-template [ngIf]="dataLoadErrorMessage">
        <div class="alert alert-danger">
          Unable to retrieve virtual processor information.
          <br> Error: {{dataLoadErrorMessage}}
        </div>
      </ng-template>

      <ng-template [ngIf]="vps">
        <div class="row mt-4">
          <div class="col-xs-12 col-lg-8">
            <div class="row mb-1">
              <div class="col-6">
                <h5>
                  Virtual Processor Classes{{(selectedVPClass) ? ': ' + selectedVPClass : ''}}
                  <button *ngIf="selectedVPClass" class="btn btn-sm btn-secondary" (click)="removeClassFilter()"
                    tooltip="Remove filter" appPermissionDisable>
                    <i class="icon icon-times"></i>
                  </button>
                </h5>
              </div>
              <div class="col-6" align="right">
                <app-check-server-admin-permissions [server]="server" placement="top">
                  <app-check-hdr-permissions [server]="server" [hdrPermission]="vpHDR" placement="top">
                    <button type="button" class="btn btn-primary" (click)="openAddVPForm()" [disabled]="isAddingVP"
                    appHDRPermission [hdrPermission]="vpHDR"appPermissionDisable>Add VPs</button>
                  </app-check-hdr-permissions>
                </app-check-server-admin-permissions>
                <app-check-server-admin-permissions [server]="server" placement="top">
                  <app-check-hdr-permissions [server]="server" [hdrPermission]="vpHDR" placement="top">
                    <button type="button" class="btn btn-primary" (click)="openDropVPForm()"
                      [disabled]="isDroppingVP || isDisableDropVPs || (selectedVPClass && dropVPClasses.indexOf(selectedVPClass) < 0)"
                      appHDRPermission [hdrPermission]="vpHDR" appPermissionDisable>Drop VPs</button>
                  </app-check-hdr-permissions>
                </app-check-server-admin-permissions>
              </div>
            </div>
            <div class="row">
              <div class="col-4 col-lg-6">
              </div>
              <div class="col-8 col-lg-6" align="right">
                <div *ngIf="isAddingVP || isDroppingVP" class="card mt-2 mb-2">
                  <div class="card-header">
                    <h5>
                      {{(isAddingVP)? 'Add' : 'Drop'}} Virtual Processors
                    </h5>
                  </div>
                  <div class="card-body">
                    <form [formGroup]="vpFormGroup" class="mb-2">
                      <div class="row">
                        <div class="col-2">Class:</div>
                        <div class="col-10">
                          <select class="form-control form-control-sm mr-3" formControlName="vpClass">
                            <option *ngFor="let vpclass of actionClassOptions" [ngValue]="vpclass">{{vpclass}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-2">Count:</div>
                        <div class="col-10">
                          <input type="number" class="form-control form-control-sm" min="1" formControlName="vpCount"
                            tooltip="" appExtendedFormControl>
                        </div>
                      </div>
                    </form>
                    <button type="button" class="btn btn-primary" [disabled]="!vpFormGroup.valid"
                      (click)="doVPAction()">
                      <i class="icon" [ngClass]="'icon-' + ( isAddingVP ? 'plus' : 'minus' )"></i>
                      {{(isAddingVP)? 'Add' : 'Drop'}}
                    </button>
                    <button type="button" class="btn btn-secondary" (click)="isAddingVP = false; isDroppingVP = false">
                      <i class="icon icon-times"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-responsive mb-2">
                  <app-data-table [data]="(selectedVPClass)? classVps : vps" sortBy="class" [rowsPerPage]="20">
                    <app-data-table-column *ngIf="selectedVPClass" title="VP ID" property="vpid" [sortable]="true">
                    </app-data-table-column>
                    <app-data-table-column title="Class" property="class" [sortable]="true">
                      <ng-template appTableCell let-item="item">
                        <a *ngIf="!selectedVPClass" [routerLink]="[]" [queryParams]="{class: item}">{{item}}</a>
                        <label *ngIf="selectedVPClass">{{item}}</label>
                      </ng-template>
                    </app-data-table-column>
                    <app-data-table-column *ngIf="!selectedVPClass" title="VPs" property="count" [sortable]="true">
                    </app-data-table-column>
                    <app-data-table-column title="User CPU Time" property="usercpu" [sortable]="true">
                    </app-data-table-column>
                    <app-data-table-column title="System CPU Time" property="syscpu" [sortable]="true">
                    </app-data-table-column>
                    <app-data-table-column title="Total CPU Time" property="totalcpu" [sortable]="true">
                    </app-data-table-column>
                  </app-data-table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-lg-4">
            <div class="row">
              <div class="col">
                <h5>Virtual Processor Total CPU Usage</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <canvas #vpChartCanvas width="500" height="400"></canvas>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
  </div>

  <div *ngIf="!viewLiveData" class="mt-2">
    <app-sensor-chart [server]="server"
      [config]="{sensorId: 'vps', metricSelect: {label: 'Metric', default: 'totalcpu'}, metricList: ['usercpu', 'syscpu', 'totalcpu']}">
    </app-sensor-chart>
  </div>
</div>