<div bsModal #addTaskSensorModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left" *ngIf="!checkRunFlag">{{title}} Setup</h5>
        <h5 class="modal-title pull-left" *ngIf="checkRunFlag">Startup {{title}} Setup</h5>
        <button type="button" class="close" (click)="closeModal()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <form *ngIf="taskSchedulerForm" [formGroup]="taskSchedulerForm">
          <div class="pl-3 pr-3">
            <div class="mb-2">
              <label><strong>{{title|titlecase}} Name & Group</strong></label>
            </div>
            <div class="mb-2 row">
              <div class="col-6">
                <label><strong>{{title|titlecase}} Name</strong></label>
                <input type="text" placeholder="{{title}} Name" class="form-control form-control-sm" id="tk_name"
                  tooltip="" appExtendedFormControl formControlName="tk_name">
              </div>
              <div class="col-6">
                <label><strong>Select Group</strong></label>
                <select class="form-control form-control-sm" tooltip="" appExtendedFormControl
                  formControlName="tk_group" (change)="selectGroup($event)">
                  <option [ngValue]="null" disabled>Select Group</option>
                  <option *ngFor="let option of groupNames">{{option}}</option>
                </select>
              </div>
            </div>
            <div class="mb-2 row">
              <div class="col-12  mt-2">
                <label><strong>Description</strong></label>
                <textarea class="form-control expandable" rows="10" id="tk_description" tooltip=""
                  appExtendedFormControl placeholder="Description" type="text"
                  formControlName="tk_description"></textarea>
              </div>
            </div>
            <div class="mb-2 pt-2">
              <label><strong>Specify schedule for {{title|titlecase}}</strong></label>
            </div>
            <div class="mb-2 row" *ngIf="!checkRunFlag">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <label><strong>Start Time (24 hrs.)</strong></label>
                <div class="row col-12">
                  <input type="number" placeholder="HH"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1" tooltip=""
                    appExtendedFormControl id="tk_start_hh" formControlName="tk_start_hh" min="0" max="23">:
                  <input type="number" placeholder="MM"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1 ml-1" tooltip=""
                    appExtendedFormControl id="tk_start_mm" formControlName="tk_start_mm" min="0" max="59">:
                  <input type="number" placeholder="SS"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 ml-1" tooltip=""
                    appExtendedFormControl id="tk_start_ss" formControlName="tk_start_ss" min="0" max="59">
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div class="row ml-0 align-items-center">
                  <span class="font-weight-bold">
                    Stop Time (24 hrs.)
                  </span>
                  <app-checkbox class="ml-2" (change)="disableInputTime('stoptime')" formControlName="tk_never_stoptime">
                    Never
                  </app-checkbox>
                </div>
                <div class="row col-12">
                  <input type="number" placeholder="HH"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1" tooltip=""
                    appExtendedFormControl formControlName="tk_stoptime_hh" min="0" max="23">:
                  <input type="number" placeholder="MM"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1 ml-1" tooltip=""
                    appExtendedFormControl formControlName="tk_stoptime_mm" min="0" max="59">:
                  <input type="number" placeholder="SS"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 ml-1" tooltip=""
                    appExtendedFormControl formControlName="tk_stoptime_ss" min="0" max="59">
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 pt-2">
                <div class="row ml-0 align-items-center">
                  <span class="font-weight-bold">
                    Frequency
                  </span>
                  <app-checkbox *ngIf="!checkRunFlag" class="ml-2"
                    (change)="disableInputTime('frequency')" formControlName="tk_never_frequency">
                    Never
                  </app-checkbox>
                </div>
                <div class="row col-12">
                  <input type="number" placeholder="DAY"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-3 mr-1" tooltip=""
                    appExtendedFormControl id="tk_frequency_day" formControlName="tk_frequency_day" min="0" max="99">:
                  <input type="number" placeholder="HH"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1 ml-1"
                    id="tk_frequency_hh" tooltip="" appExtendedFormControl formControlName="tk_frequency_hh" min="0"
                    max="23">:
                  <input type="number" placeholder="MM"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1 ml-1"
                    id="tk_frequency_mm" tooltip="" appExtendedFormControl formControlName="tk_frequency_mm" min="0"
                    max="59">:
                  <input type="number" placeholder="SS"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 ml-1" id="tk_frequency_ss"
                    tooltip="" appExtendedFormControl formControlName="tk_frequency_ss" min="0" max="59">
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 pt-2" *ngIf="sensorFlag">
                <label><strong>Data Delete Interval</strong></label>
                <div class="row col-12">
                  <input type="number" placeholder="DAY"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-3 mr-1" id="tk_delete_day"
                    tooltip="" appExtendedFormControl formControlName="tk_delete_day" min="0" max="99">:
                  <input type="number" placeholder="HH"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1 ml-1" id="tk_delete_hh"
                    tooltip="" appExtendedFormControl formControlName="tk_delete_hh" min="0" max="23">:
                  <input type="number" placeholder="MM"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 mr-1 ml-1" id="tk_delete_mm"
                    tooltip="" appExtendedFormControl formControlName="tk_delete_mm" min="0" max="59">:
                  <input type="number" placeholder="SS"
                    class="form-control form-control-sm col-sm-3 col-md-2 col-lg-2 col-xl-2 ml-1" id="tk_delete_ss"
                    tooltip="" appExtendedFormControl formControlName="tk_delete_ss" min="0" max="59">
                </div>
              </div>
            </div>
            <div class="mb-2 row" *ngIf="!checkRunFlag">
              <div class="col-12 pt-2">
                <label><strong>Days</strong></label>
                <div class="row ml-0">
                  <div class="btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-primary btn-days btn-checkbox"
                      *ngFor="let day of selectedTaskDays; let i=index"
                      [ngClass]="{ 'active': selectedTaskDays[i].checked }">
                      <input type="checkbox" [disabled]="isDisableDays"
                        (change)="selectedTaskDays[i].checked=!$event.target.checked">
                      {{day.tk_day}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 pt-2" *ngIf="sensorFlag">
              <label><strong>Specify the result table to store sensor data</strong></label>
            </div>
            <div class="mb-2 row" *ngIf="sensorFlag">
              <div class="col-12  mt-2">
                <label><strong>Result Table Name</strong></label>
                <textarea class="form-control expandable" tooltip="" appExtendedFormControl rows="6"
                  id="tk_result_table" placeholder="Enter Table Name" type="text"
                  formControlName="tk_result_table"></textarea>
              </div>
            </div>
            <div class="mb-2 pt-2" *ngIf="sensorFlag">
              <label><strong>Complete SQL statement to create the result table</strong></label>
            </div>
            <div class="mb-2 row" *ngIf="sensorFlag">
              <div class="col-12  mt-2">
                <label><strong>SQL Statement</strong></label>
                <textarea class="form-control expandable" tooltip="" appExtendedFormControl rows="10" id="tk_create"
                  placeholder="Enter Your SQL Statement Here" type="text" formControlName="tk_create"></textarea>
              </div>
            </div>
            <div class="mb-2 pt-2">
              <label><strong>Specify the command for {{title|titlecase}}</strong></label>
            </div>
            <div class="mb-2 row">
              <div class="col-12  mt-2">
                <label><strong>Command</strong></label>
                <textarea class="form-control expandable" tooltip="" appExtendedFormControl rows="10" id="tk_execute"
                  placeholder="Enter Command" type="text" formControlName="tk_execute"></textarea>
              </div>
            </div>
            <div class="mb-2 row">
              <app-checkbox class="mt-3 ml-3" formControlName="tk_enable">
                <span class="font-weight-bold">Enable Task</span>
              </app-checkbox>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <app-check-server-admin-permissions [server]="server">
                  <button *ngIf="(popuptype !== 'updatepopup')" [disabled]="!taskSchedulerForm.valid" type="button"
                    class="btn btn-secondary" (click)="addParams()" appPermissionDisable>
                    Add Parameters</button>
                </app-check-server-admin-permissions>
                <app-check-server-admin-permissions [server]="server">
                  <button *ngIf="(popuptype === 'updatepopup')" type="button" class="btn btn-secondary"
                    (click)="viewParams()" appPermissionDisable>
                    View Parameters</button>
                </app-check-server-admin-permissions>
              </div>
              <div class="col-6 text-right">
                <app-check-server-admin-permissions [server]="server">
                  <button type="button" class="btn btn-secondary" (click)="closeModal()" appPermissionDisable>
                    Cancel</button>
                </app-check-server-admin-permissions>
                <app-check-server-admin-permissions [server]="server">
                  <button *ngIf="!isEditTask" [disabled]="!taskSchedulerForm.valid" type="button"
                    class="btn btn-primary px-3" (click)="addNew()" appPermissionDisable>
                    Save</button>
                </app-check-server-admin-permissions>
                <app-check-server-admin-permissions [server]="server">
                  <button *ngIf="isEditTask" type="button" class="btn btn-primary px-3"
                    (click)="EnableAllFields('editbtn')" appPermissionDisable>
                    Edit</button>
                </app-check-server-admin-permissions>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-parameter #addParamModal (saveParamDone)="saveParam($event)"></app-parameter>
