/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';
import { BytePipe } from '../../../shared/pipes/byte.pipe';

@Pipe( {name: 'storageExtendSize'} )
export class StorageExtendSizePipe implements PipeTransform {
  transform( value: number ): string {
    // values between 0 and 100 interpreted as percentages
    if (value <= 100) {
        return value + '%';
    }
    // otherwise value is in KB, so convert to bytes then use the BytePipe to transform
    value *= 1024;
    return (new BytePipe()).transform(value);
  }
}
