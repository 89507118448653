/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved.
 *******************************************************************************/

/**
 * This component file will display the Data-Blades present in database selected by the user in Schema Manager.
 */
import { Component, OnInit, Input } from '@angular/core';
import { InformixSQLSession } from '../informix-sql-session';
import { SchemaService } from '../schema.service';
import { InformixServer } from '../../informixServer';

@Component({
  selector: 'app-database-datablade',
  templateUrl: './database-datablade.component.html',
  styleUrls: ['../database/database.component.scss']
})
export class DatabaseDatabladeComponent implements OnInit {

  @Input() session: InformixSQLSession;
  @Input() server: InformixServer;
  dbDatablades: any;
  dataLoading = false;
  serverError: string;

  constructor(private schemaService: SchemaService) { }

  ngOnInit() {
    this.getDbDataBlade('datablades');
  }

  /**
   * This function will call the Data-Blade API and store the response in dbDatablades.
   */
  private getDbDataBlade(tabName: string): void {
    this.dataLoading = true;
    this.schemaService.getDatabaseTabData(this.session, this.server, tabName)
      .subscribe(res => {
        this.dbDatablades = res;
        this.dataLoading = false;
      },
        err => {
          this.dataLoading = false;
          this.serverError = err.error ? err.error.err : err;
        });
  }
}
