<div style="display: flex">
  <div class="color-value" (mousedown)="colorValueMouseDown($event)" [ngStyle]="{'background-color': valueBgColor}">
    <div class="white-gradient">
      <div class="black-gradient">
      </div>
    </div>
    <div class="value-picker" [ngStyle]="{'left.px': value.x / 2, 'top.px': value.y / 2}">
    </div>
  </div>
  <div class="color-hue" (mousedown)="colorHueMouseDown($event)">
    <div class="hue-picker" [ngStyle]="{'margin-top.px': (hue * 127 / 360) - 1}">
    </div>
  </div>

  <div class="color-inputs">
    <div class="color-input">
      <span class="ml-2 mr-1">#</span>
      <input type="text" class="form-control form-control-sm text-center" maxlength="6" [formControl]="hexFormControl">
    </div>
    <ng-container [formGroup]="rgbFormGroup">
      <div class="color-input">
        <span class="ml-2 mr-1">R</span>
        <input type="number" class="form-control form-control-sm text-center" formControlName="r" min="0" max="255">
      </div>
      <div class="color-input">
        <span class="ml-2 mr-1">G</span>
        <input type="number" class="form-control form-control-sm text-center" formControlName="g" min="0" max="255">
      </div>
      <div class="color-input">
        <span class="ml-2 mr-1">B</span>
        <input type="number" class="form-control form-control-sm text-center" formControlName="b" min="0" max="255">
      </div>
    </ng-container>
  </div>
</div>
