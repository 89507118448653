/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SqlhostsGroupValidationStatus, SqlhostsServerInternal, AdvancedOptions, NodeConfiguration } from '../../../enterprise-replication-types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ERAdvancedOptionsComponent } from '../er-advanced-options/er-advanced-options.component';
import { ERNode } from '../../../er-domain';
import { ServerSpace } from '../../../../../servers/storage/serverSpace';
@Component({
    selector: 'app-er-define-sidebar',
    templateUrl: './er-define.component.html',
    styleUrls: ['../er-sidebars.component.scss','./er-define.component.scss']
})

export class ERDefineNodeComponent implements OnInit, OnChanges {

    @ViewChild(ERAdvancedOptionsComponent) advancedOptionsComp: ERAdvancedOptionsComponent;

    @Input() selectedGroup: SqlhostsServerInternal;
    @Input() open: boolean;
    @Input() nodes: ERNode[] = [];
    @Input() isSingleGroup: boolean;

    @Output() openChange = new EventEmitter<boolean>();
    @Output() validateGroup = new EventEmitter<SqlhostsServerInternal>();
    @Output() saveNode = new EventEmitter();

    syncServerList: ERNode[] = [];
    formGroup: FormGroup = null;
    isFirstServerInDomain: boolean;
    sqlhostsGroupValidationStatus = SqlhostsGroupValidationStatus;
    sbSpacesList: ServerSpace[] = [];
    dbSpacesList: ServerSpace[] = [];
    selectedSBSpace: ServerSpace = null;
    selectedDBSpace: ServerSpace = null;

    formSelections: any = {
        nodeType: null,
        sbSpaces: [],
        dbSpace: null,
        syncServer: null,
        options: null
    };

    advancedOptions: AdvancedOptions;

    constructor() { }


    ngOnInit() {
        this.formGroup = new FormGroup({
            nodeType: new FormControl('root', [Validators.required])
        });

        this.formGroup.controls.nodeType.valueChanges.subscribe(value => {
            if(this.nodes && this.nodes.length) {
                if(value === 'root'){
                    /*
                     * Showing only root servers in the sync server list since root servers can only be sync with root servers
                     */
                    this.syncServerList = this.nodes.filter((node: any) => node.typeName === 'Root' &&
                    node.name !== this.selectedGroup.groupName);
                }else{
                    /*
                     * Showing only non leaf and no current server in the sync server.
                     */
                    this.syncServerList = this.nodes.filter((node: any) => !node.isLeaf && node.name !== this.selectedGroup.groupName);
                }
                this.selectSyncServer();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selectedGroup && this.selectedGroup) {
            this.formSelections.sbSpaces = [];
            this.formSelections.syncServer = null;
            this.advancedOptions = null;
            if(this.selectedGroup.spaces) {
                this.initSpacesList();
                this.setSpaces();
            }
            let nodeType = 'root';
            if(this.selectedGroup.configuration) {

                if(!this.selectedGroup.configuration.isFirstServerInDomain) {
                    nodeType = this.selectedGroup.configuration.nodeType;
                }
                this.advancedOptions = this.selectedGroup.configuration.optional;
            }
            this.formGroup.patchValue({
                nodeType
            });
            this.isFirstServerInDomain =
                this.selectedGroup.configuration && this.selectedGroup.configuration.isFirstServerInDomain ||
                !(this.nodes && this.nodes.length);
        }
    }

    initSpacesList(){
        this.sbSpacesList = this.selectedGroup.spaces.filter(space => space.type.indexOf('sbspace') !== -1);
        this.dbSpacesList = this.selectedGroup.spaces.filter(space => space.type.indexOf('dbspace') !== -1);
    }

    close() {
        this.open = false;
        this.openChange.emit(false);
    }

    /**
     * Method to get all the node selections configuration
     *
     * @returns
     */
    getSelections() {
        const configuration: NodeConfiguration = {
            nodeType: this.formGroup.controls.nodeType.value,
            isFirstServerInDomain: this.isFirstServerInDomain,
            groupName: this.selectedGroup.groupName,
            optional: {
                ...this.advancedOptionsComp.getSelections()
            },
            sbSpace: this.formSelections.sbSpaces.map(space => space.name),
            syncServer: (!this.isFirstServerInDomain)?
            { id: this.formSelections.syncServer.id, name: this.formSelections.syncServer.name }:null,
            existingSbSpace: this.selectedGroup.onConfigParams[0].effective,
            existingDbSpace: this.selectedGroup.onConfigParams[1].effective
        };

        if(this.selectedDBSpace) {
            configuration.optional.dbSpace = this.selectedDBSpace.name;
        }

        if(!configuration.isFirstServerInDomain && this.formSelections.syncServer) {
            configuration.syncServerGroupName = this.formSelections.syncServer.name;
        }
        return configuration;
    };

    save(goNext: boolean = false ){
        this.saveNode.emit({configuration: this.getSelections(), goNext});
    }

    isSaveValid() {
        let isValid = true;
        if(!this.formSelections.sbSpaces.length || (!this.formSelections.syncServer && !this.isFirstServerInDomain)
        || (this.advancedOptionsComp && this.advancedOptionsComp.advancedOptionsForm.controls.ats.value === 3 &&
            this.advancedOptionsComp.advancedOptionsForm.controls.atsFilePath.value === '')
        || (this.advancedOptionsComp && this.advancedOptionsComp.advancedOptionsForm.controls.ris.value === 3 &&
            this.advancedOptionsComp.advancedOptionsForm.controls.risFilePath.value === '')
        || (this.advancedOptionsComp && this.advancedOptionsComp.advancedOptionsForm.controls.idleTimeOut.value &&
            !this.advancedOptionsComp.advancedOptionsForm.controls.timeOut.value)) {
            isValid = false;
        }
        return isValid;
    }

    triggerValidation() {
        this.validateGroup.emit(this.selectedGroup);
    }

    setSpaces() {
        if(!this.selectedGroup.onConfigParams) {
            return;
        }

        let sbSpaces = this.selectedGroup.onConfigParams[0].effective.split(',');
        let dbSpaces = this.selectedGroup.onConfigParams[1].effective.split(',');

        if(this.selectedGroup.configuration && this.selectedGroup.configuration.sbSpace) {
            sbSpaces =  this.selectedGroup.configuration.sbSpace;
        }
        if(this.selectedGroup.configuration && this.selectedGroup.configuration.optional.dbSpace) {
            dbSpaces =  [this.selectedGroup.configuration.optional.dbSpace];
        }

        this.sbSpacesList = this.sbSpacesList.filter(space => {
            if(sbSpaces.includes(space.name)) {
                this.formSelections.sbSpaces.push(space);
                return false;
            }else {
                return true;
            }
        });
        this.selectedDBSpace = null;
        this.selectedDBSpace = this.dbSpacesList.find(space => dbSpaces.includes(space.name)) || null;
    }

    getSimpleSyncList(list) {
        return list.map(item => ({name: item.name, type: 'root'}));
    }

    onSBSpaceSelected(sbSpace) {
        if(!sbSpace) {
            return;
        }
        const sbSpaceIndex = this.sbSpacesList.findIndex(space => space.name === sbSpace.name);
        this.formSelections.sbSpaces.push(this.sbSpacesList[sbSpaceIndex]);
        this.sbSpacesList = this.sbSpacesList.filter((space, index) => index !== sbSpaceIndex);
        this.selectedSBSpace = null;
    }

    removeSbSpace(index) {
        const foundSpace = this.sbSpacesList.find(space => space.name === this.formSelections.sbSpaces[index].name);
        if(!foundSpace) {
            this.sbSpacesList = [this.formSelections.sbSpaces[index], ...this.sbSpacesList ];
            this.formSelections.sbSpaces.splice(index, 1);
        }
    }

    private selectSyncServer() {
        this.formSelections.syncServer = null;
        if(this.selectedGroup.configuration && this.selectedGroup.configuration.syncServer){
            this.formSelections.syncServer =
             this.syncServerList.find(server => server.name === this.selectedGroup.configuration.syncServer.name);
        }
    }
}
