<div *ngIf="!dataLoading; else loading" class="container-fluid page-container">
  <form [formGroup]="userPreferenceForm">
  <h3>User Preferences</h3>
  <div class="row">
    <div class="col-xl-auto col-lg-auto col-sm-12">
      <h5>Dashboard Preferences</h5>
    </div>
  </div>
  <div class="form-group row form-inline">
    <label class="col-sm-3 col-form-label">Default time slice for dashboard graphs</label>
    <select formControlName="selectedTimeSlice" (change)="changeSelectedTimeSlice($event)" class="form-control form-control-sm mx-2 d-inline-block"
      style="width: auto">
      <option *ngFor="let timeSlice of timeSlices" value="{{timeSlice.name}}">{{timeSlice.name}}</option>
    </select>
  </div>
 
  <div class="row mt-4">
    <div class="col-xl-auto col-lg-auto col-sm-12">
      <h5>Query history auto delete settings</h5>
    </div>
  </div>
  <div class="form-group row form-inline ml-4">
      <app-radio-group formControlName="historySettingType" class="justify-content-start ml-2"
        (change)="changeQueryHistory()">
        <div class="form-inline justify-content-between">
          <app-radio value="day" class="d-block">Auto delete after {{userPreferenceForm.controls.days.value == null ?
            MaxDaysLimit : userPreferenceForm.controls.days.value}} days</app-radio>
          <input type="number" class="form-control m-2" tooltip="days"
            title="Specifies that after reaching the days limit, older records above the limit will be deleted"
            placeholder="30" formControlName="days" appExtendedFormControl />
        </div>
        <div class="form-inline justify-content-between">
          <app-radio class="mt-2" value="entry" class="d-block">Auto delete after
            {{userPreferenceForm.controls.entries.value == null ? MaxEntriesLimit :
            userPreferenceForm.controls.entries.value}} entries</app-radio>
          <input type="number" class="form-control m-2" tooltip="entries"
            title="Specifies that after reaching the entires limit, older records above the limit will be deleted"
            placeholder="1000" formControlName="entries" appExtendedFormControl />
        </div>
      </app-radio-group>
  </div>

  <button class="btn btn-primary ml-2" [disabled]="userPreferenceForm.invalid" (click)="saveConfig()">Save</button>
  </form>
</div>
<ng-template #loading>
  <div *ngIf="dataLoading" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
  </div>
  <div *ngIf="serverError" class="mt-4 alert alert-danger">
    <i class="icon icon-danger text-danger"></i> {{serverError}}
  </div>
</ng-template>
