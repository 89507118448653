<ng-template [ngIf]="permissionsData">
  <div class="row">
    <div class="col">
      <h4>User Permissions</h4>
    </div>
    <div class="col col-md-auto">
      <button class="btn btn-sm btn-primary" (click)="openAddUsersModal()"><i class="icon icon-plus"></i> Add Users</button>
    </div>
  </div>
  <app-data-table [data]="permissionsData" [sortBy]="userName" noDataAlertMessage="No user permissions available." [minHeight]="dynamicHeight">
    <app-data-table-column title="User Name" property="userName" [searchable]="true" [sortable]="true" width="30%">
    </app-data-table-column>
    <app-data-table-column title="Permissions" property="permissionsStr">
      <ng-template appTableCell let-row="row">
        <ng-template [ngIf]="editingRow !== row">
          {{row.permissionsStr}}
          <span class="badge badge-info" *ngIf="row.parentPermissions">Override</span>
          <button class="btn btn-sm btn-secondary" (click)="editPermissions(row)" tooltip="Edit Permissions"><i class="icon icon-edit"></i></button>
          <button class="btn btn-sm btn-secondary" (click)="removePermissions(row)"
            [tooltip]="'Remove Permissions ' + (row.parentPermissions ? 'Override' : '')"><i class="icon icon-times"></i></button>
        </ng-template>
        <ng-template [ngIf]="editingRow === row">
          <app-permissions-control [parentPermissions]="row.parentPermissions?.permissions" [formControl]="editPermissionsControl"> </app-permissions-control>
          <button class="btn btn-sm btn-primary" [disabled]="row.permissions === editPermissionsControl.value || isSavingPermissions" (click)="savePermissions(row)">
            Save
          </button>
          <button class="btn btn-sm btn-secondary" [disabled]="isSavingPermissions" (click)="editPermissions(null)">Cancel</button>
        </ng-template>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</ng-template>

<ng-template [ngIf]="parentPermissionsData">
  <hr>
  <div class="row my-1">
    <div class="col">
      <h4 style="display: inline-block">Inherited User Permissions</h4>
      <a style="margin-left: 10px" [routerLink]="parentPermissionsLink">(View parent permissions)</a>
    </div>
  </div>
  <app-data-table [data]="parentPermissionsData" [sortBy]="userName" noDataAlertMessage="No permissions are inherited." [minHeight]="dynamicHeight">
    <app-data-table-column title="User Name" property="userName" [searchable]="true" [sortable]="true" width="30%">
    </app-data-table-column>
    <app-data-table-column title="Permissions" property="permissionsStr">
      <ng-template appTableCell let-row="row">
        {{row.permissionsStr}}
        <span class="badge badge-danger" *ngIf="row.isOverridden">Overridden</span>
        <button *ngIf="!row.isOverridden" class="btn btn-sm btn-secondary" tooltip="Override" (click)="overridePermissions(row)"><i class="icon icon-arrow-up"></i></button>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</ng-template>

<div class="alert alert-danger" *ngIf="child && !child.permissions.admin">
  <i class="icon icon-lock"></i> You do not have the required permissions to view or modify permissions on this object
</div>

<div bsModal #addUsersModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Add User Permissions</h5>
        <button type="button" class="close" [disabled]="isAddingUsers" (click)="addUsersModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body" *ngIf="users">
        <div [hidden]="selectedUsers">
          <div *ngIf="users.length < 1" class="alert alert-info">
            No more users available to grant permissions to. To create new users, go to the
            <a routerLink="/dashboard/system-settings/users">User Management </a> page.
          </div>
          <ng-template [ngIf]="users.length > 0">
            <app-data-table #addUsersTable [data]="users" [sortBy]="name" [enableSelect]="true" [minHeight]="450">
              <app-data-table-column title="User Name" property="name" [sortable]="true" [searchable]="true">
              </app-data-table-column>
            </app-data-table>
            <div class="text-right">
              <button class="btn btn-primary" [disabled]="addUsersTable.selectedCount < 1" (click)="addUsersSelect(addUsersTable.getSelectedRows())">Next <i class="icon icon-chevron-right"></i></button>
            </div>
          </ng-template>
        </div>
        <ng-template [ngIf]="selectedUsers">
          <h5>Select permissions for {{selectedUsers.length > 1 ? 'users' : 'user'}}: {{selectedUsersStr}}</h5>
          <app-permissions-control [formControl]="addUsersPermissionsControl"> </app-permissions-control>
          <div class="text-right">
            <button class="btn btn-secondary" [disabled]="isAddingUsers" (click)="selectedUsers = null"><i class="icon icon-chevron-left"></i> Back</button>
            <button class="btn btn-primary" [disabled]="isAddingUsers" (click)="addSelectedUsers()">Add Users</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
