<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-md-8">
      <h4 class="page-header">
        <strong>Sessions</strong>
        <app-data-source-toggle class="ml-2" [viewMonitoredData]="false" (viewMonitoredDataStateChange)="viewLiveData = !$event"></app-data-source-toggle>
        <i *ngIf="server.hasMonitorPassword && !sessions && !dataLoadErrorMessage" class="icon icon-circle-notch icon-spin ml-2"></i>
      </h4>
      <ng-container *ngIf="!dataLoadErrorMessage">
        <small *ngIf="viewLiveData">You are currently viewing live data.</small>
        <small *ngIf="!viewLiveData">You are currently viewing monitored data from the sensor.</small>
      </ng-container>
    </div>
    <div class="col-md-4 text-right">
      <button *ngIf="viewLiveData" id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()" title="Refresh"><i class="icon icon-refresh"></i></button>
    </div>
  </div>

  <div *ngIf="viewLiveData" class="mt-2">
    <ng-template [ngIf]="dataLoadErrorMessage">
      <div class="alert alert-danger">
        Unable to retrieve session information. <br> Error: {{dataLoadErrorMessage}}
      </div>
    </ng-template>

    <ng-template [ngIf]="sessions">
      <div class="table-responsive">
        <app-data-table [data]="sessions" sortBy="sid" [sortDescending]="false" [rowsPerPage]="20" searchLabel="Search user or hostname ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
          <app-data-table-column title="ID" property="sid" [sortable]="true">
            <ng-template appTableCell let-sid="item" let-session="row">
              <button id="expand-button" type="button" class="btn btn-sm btn-secondary mr-1" title="Click to show session details" [routerLink]="[sid]">
                <i class="icon icon-search"></i>
              </button>{{sid}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="User Name" property="username" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="PID" property="pid" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Hostname" property="hostname" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="Connected" property="connected" [sortable]="true">
            <ng-template appTableCell let-connected="item">
              {{connected * 1000 | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Memory" property="mem_total" [sortable]="true">
            <ng-template appTableCell let-memtotal="item">
              {{memtotal | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="I/O Wait Time" property="io_wait_time" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="CPU Time" property="cpu_time" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Actions" property="is_killable">
            <ng-template appTableCell let-isKillable="item" let-session="row">
              <button type="button" class="btn btn-sm btn-secondary" disabled *ngIf="!isKillable" tooltip="System sessions cannot be terminated">
                <i class="icon icon-bolt"></i>
              </button>

              <app-check-server-admin-permissions [server]="server" *ngIf="isKillable">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="sessionHDR" placement="top">
                  <button type="button" class="btn btn-sm btn-primary" appHDRPermission [hdrPermission]="sessionHDR" appPermissionDisable tooltip="Terminate Session" (click)="confirmKillSession(session)">
                    <i class="icon icon-bolt"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
    </ng-template>
  </div>

  <div *ngIf="!viewLiveData && server" class="mt-2">
     <app-dashboard [server]="server" [dashboard]="sessionDashboard"></app-dashboard>
  </div>
</div>
