/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { AbstractDashboardPlugin } from '../abstract-dashboard-plugin';
import { DashboardBuiltinPlugins } from '../dashboard-builtin-plugins';
import { DashboardGrid } from '../dashboard-grid';
import { DashboardPanel } from '../dashboard-panel';
import { DashboardService } from '../dashboard.service';

@Directive({
  selector: '[appDashboardPanelContent]'
})
export class DashboardPanelContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss']
})
export class DashboardPanelComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  private componentRef: ComponentRef<AbstractDashboardPlugin> = null;

  @Input() grid: DashboardGrid;
  @Input() panel: DashboardPanel;
  @Input() config: any = null;
  @Input() editable = false;
  @Input() movable = false;
  @Input() resizable = false;

  @Output() changed = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() duplicate = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  @ViewChild(DashboardPanelContentDirective, { static: true }) panelContentDirective: DashboardPanelContentDirective;
  @ViewChild('panelRenamePopover') panelRenamePopover: PopoverDirective;

  titleFormControl: UntypedFormControl = null;

  ignoreHeaderMouseDown = false;

  errorMessages: string[] = [];
  warningMessages: string[] = [];

  errorsChangedSub: Subscription = null;
  warningsChangedSub: Subscription = null;

  serversSub: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.serversSub = this.dashboardService.serversChanged.subscribe(() => {
      this.createComponent();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.panel) {
      this.createComponent();
    } else if (changes.config) {
      this.updateComponentConfig();
    }
  }

  ngAfterViewInit() {
    if (!this.componentRef) {
      window.setTimeout(() => this.createComponent(), 0);
    }
  }

  ngOnDestroy() {
    this.serversSub.unsubscribe();
  }

  private createComponent() {
    this.destroyComponent();
    this.errorMessages = [];
    this.warningMessages = [];
    const plugin = DashboardBuiltinPlugins.getPlugin(this.panel.contentType);
    if (!plugin) {
      return this.errorMessages.push('Dashboard plugin not recognized: ' + this.panel.contentType);
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(plugin.component);
    const viewContainerRef = this.panelContentDirective.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    const c = this.componentRef.instance;
    this.warningsChangedSub = c.warningsChanged.subscribe(warningMessages => this.warningMessages = warningMessages);
    this.errorsChangedSub = c.errorsChanged.subscribe(errorMessages => this.errorMessages = errorMessages);
    this.updateComponentConfig();
  }

  private updateComponentConfig() {
    if (!this.componentRef) {
      return;
    }

    const config = this.config || this.panel.contentConfig;
    this.componentRef.instance.setConfig(config);
  }

  private destroyComponent() {
    if (this.warningsChangedSub) {
      this.warningsChangedSub.unsubscribe();
    }
    if (this.errorsChangedSub) {
      this.errorsChangedSub.unsubscribe();
    }
    this.componentRef = null;
  }

  onHeaderMouseDown(event: any) {
    if (!this.movable) {
      return;
    }

    if (this.ignoreHeaderMouseDown) {
      this.ignoreHeaderMouseDown = false;
      return;
    }

    this.grid.startMoving(this.panel, event.clientX, event.clientY);
  }

  onResizeMouseDown(event: any) {
    if (!this.resizable) {
      return;
    }

    this.grid.startResizing(this.panel, event.clientX, event.clientY);
  }

  onTitleEditPopoverShown() {
    this.titleFormControl = new UntypedFormControl(this.panel.title);
  }

  onTitleKeyDown(event: any) {
    if (event.keyCode === 27 && this.panelRenamePopover) { // Escape
      this.panelRenamePopover.hide();
    }
  }

  saveTitle() {
    if (!this.titleFormControl) {
      return;
    }

    this.panel.title = this.titleFormControl.value.trim();
    this.panelRenamePopover.hide();
    this.changed.emit();
  }

  editPanel() {
    this.edit.emit();
  }

  duplicatePanel() {
    this.duplicate.emit();
  }

  deletePanel() {
    this.delete.emit();
  }
}
