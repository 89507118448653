/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Dashboard } from '../../../dashboard/monitoring/dashboard';

export const primaryServerMetrics: Dashboard = {
  title: '',
  rows: [
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'ha_workload',
            title: 'CPU Workload(%)',
            metricList: ['cpu_workload'],
            showControls: false
          }
        }
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'ha_lagtime',
            title: 'Lagtime',
            metricList: ['lag_time'],
            showControls: false
          }
        }
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'ha_logical_log_rate',
            title: 'Logical Log Rate',
            metricList: ['llgrecs'],
            showControls: false
          }
        }
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'ha_approximate_log_backlog',
            title: 'Approximate Log Backlog',
            metricList: ['approxLogBacklog'],
            showControls: false
          }
        }
      ]
    }
  ]
};

export const secondaryServersMetrics: Dashboard = {
  title: '',
  rows: [
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'ha_transaction_latency',
            title: 'Transaction Latency',
            metricList: ['transactionLatency'],
            showControls: false
          }
        }
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'ha_apply_rate',
            title: 'Apply Rate',
            metricList: ['applyRate'],
            showControls: false
          }
        }
      ]
    }
  ]
};
