/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimal' })
export class DecimalPipe implements PipeTransform {
    transform(num: number, precision: number = 3): string {
        const value = Number(num);
        const strValue = value.toFixed(precision);
        let i = strValue.length - 1;
        while (i > 0 && strValue[i] === '0') {
            i--;
        }
        if (strValue[i] === '.') {
            i--;
        }
        return i === strValue.length - 1 ? strValue : strValue.substring(0, i + 1);
    }
}
