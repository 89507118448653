/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractSettingsComponent {
  @Input() settings: any;
  @Output() eventChange = new EventEmitter<any>();

  public abstract getSettings(): any;
  public abstract requiresSettings(): boolean;
  public abstract isValid(): boolean;
}
