/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/

export class AlertingProfile {
  public alerts: AlertingCriteria[];
  public inherited: AlertingCriteria[] = null;

  constructor(json: any) {
    this.alerts = json.alerts;
    if (json.inherited) {
      this.inherited = json.inherited;
    }
  }
}

export interface AlertingCriteria {
  id: number;
  name: string;
  trigger: AlertTrigger;
  condition: AlertOperation;
  disabled: boolean;
}

export interface AlertTrigger {
  type: string;
  interval?: number;
}

export interface AlertOperand {
  type?: string;
  [key: string]: any;
}

export interface AlertOperation {
  a: AlertOperand;
  b: AlertOperand;
  op: string;
}
