<div *ngIf="sensor" class="row" style="padding: 0rem 1rem;">
  <div class="form-inline" *ngIf="showControls">
    <app-time-slice [disabled]="isLoading" [isPaused]="isPaused" [timeSlice]="selectedTimeSlice"
      (timeSliceChanged)="setTimeSlice($event)" (dataPaused)="pauseData()" (dataResumed)="resumeData()">
    </app-time-slice>
  </div>
  <div  class="form-inline" style="padding: 0rem 2rem 0rem 1rem;">
    <ng-container *ngIf="showMetricSelect && availableMetrics">
      <label for="metricSelect">{{config.metricSelect.label}}:&nbsp;</label>
      <select id="metricSelect" [disabled]="isLoading" [ngModel]="selectedMetric"
        (ngModelChange)="changeSelectedMetric($event)" class="form-control form-control-sm">
        <option *ngFor="let metric of availableMetrics" value="{{metric.id}}">{{metric.name}}</option>
      </select>
    </ng-container>
      <ng-container *ngIf="config.primaryKeySelect && primaryKeys" >
        <label class="ml-3" for="primaryKeySelect" *ngIf="!allPrimaryKeys">{{config.primaryKeySelect.label}}:&nbsp;</label>
        <ng-select *ngIf="!allPrimaryKeys" [multiple]="true" (change)="changeSelectedPrimaryKey($event)" [(ngModel)]="selectedPrimaryKeys" [searchable]="false"
         [items]="primaryKeys" bindLabel="value" bindValue="value" [placeholder]="'Select ' + config.primaryKeySelect.label + '(s)'">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
                <span class="ng-value-label">{{item}}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
        </ng-template>
       </ng-select>
       <div class="float-right ml-2">
        <app-checkbox class="ml-0" (change)="onAllPrimaryKeysCheckBoxChange($event)">
          All {{config.primaryKeySelect.label}}(s)
        </app-checkbox>
       </div>
      </ng-container>
  </div>
</div>
<div style="width: 100%;" [ngStyle]="{height: chartHeight + 'px'}">
  <canvas #chartContainer></canvas>
</div>
<ng-template [ngIf]="sensor">
  <div [hidden]="!isLoading" class="sensor-chart-graph-overlay"
    style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(255,255,255,0.5); display: flex; align-items: center; justify-content: center">
    <i class="icon icon-circle-notch icon-spin"></i>
  </div>
  <div *ngIf="!repositoryOnline && !serverError"
    style="position:absolute; top: 50%; right: 0; left: 0; text-align: center;">
    Cannot connect to repository database.
  </div>
  <div *ngIf="serverError"
    style="position:absolute; top: 50%; right: 0; left: 0; text-align: center;">
    <i class="icon icon-2x icon-warning text-danger mr-2"> </i> {{serverError}}
  </div>
  <div *ngIf="sensorError"
    style="position:absolute; top: 50%; right: 0; left: 0; text-align: center;">
     <i class="icon icon-2x icon-warning text-danger mr-2"> </i> {{sensorError}}
  </div>
  <div *ngIf="!sensorError && selectedTimeSlice && !hasData && !isLoading && repositoryOnline"
    style="position:absolute; top: 50%; right: 0; left: 0; text-align: center;">
    No data available in the last {{selectedTimeSlice.value / 1000 | relativeTime}}
    <ng-template [ngIf]="server && server.agent && server.agent.isMonitoring && !sensor.disabled">
      <br> The sensor is running. New data will be available in about {{sensor.runInterval | relativeTime}} to
      {{sensor.runInterval * 2 | relativeTime}}
    </ng-template>
  </div>
  <div class="row" *ngIf="dataLastTimestamp">
    <div class="col-md-12 text-right">
      <small class="text-muted text-right">Latest data timestamp: {{dataLastTimestamp | date:'hh:mm:ss a'}}</small>
    </div>
  </div>
</ng-template>
