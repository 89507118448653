/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SqltraceComponent } from './sqltrace.component';
import { SqltraceConfigComponent } from './sqltrace-config.component';
import { SqltraceInfoComponent } from './sqltrace-info.component';
import { SqltraceQueryTreeComponent } from './sqltrace-query-tree.component';
import { SqltraceStatementExecutionsComponent } from './sqltrace-stmt-executions.component';
import { SqltraceStatementProfileComponent } from './sqltrace-stmt-profile.component';
import { SqltraceStatementsComponent } from './sqltrace-stmts.component';
import { SqltraceStatementTypesComponent } from './sqltrace-stmt-types.component';
import { SqltraceTransactionInfoComponent } from './sqltrace-txn-info.component';
import { SqltraceTransactionsComponent } from './sqltrace-txns.component';
import { SqltraceService } from './sqltrace.service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    SqltraceComponent,
    SqltraceConfigComponent,
    SqltraceInfoComponent,
    SqltraceQueryTreeComponent,
    SqltraceStatementExecutionsComponent,
    SqltraceStatementProfileComponent,
    SqltraceStatementsComponent,
    SqltraceStatementTypesComponent,
    SqltraceTransactionInfoComponent,
    SqltraceTransactionsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TooltipModule,
    NgSelectModule
  ],
  exports: [
    SqltraceQueryTreeComponent,
    SqltraceConfigComponent
  ],
  providers: [
    SqltraceService
  ]
})
export class SqltraceModule { }
