/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/
import { InformixServerType } from '../../dashboard/servers/informixServer';

export interface Permission {
    id: string;
    name: string;
    components: string;
    allow: Array<InformixServerType>;
}

export enum ServerTypes {
    STANDARD = 'Standard',
    PRIMARY = 'Primary',
    SECONDARY = 'Secondary',
    RSS = 'RSS',
    SDS = 'SDS',
    NA = 'N/A'
}

export const SERVER_TYPES_COLORS: {[key: string]: string} = {
    [ServerTypes.STANDARD]: '#55555',
    [ServerTypes.PRIMARY]: '#0066b3',
    [ServerTypes.SECONDARY]: '#fab914',
    [ServerTypes.RSS]: '#589d25',
    [ServerTypes.SDS]: '#ee3f64',
    [ServerTypes.NA]: '#6c6c6c'
};

export const Permissions: Permission[] = [
    {
        id: 'p1',
        name: 'Run Checkout Command, Add Log Command, Drop Log Command, Move Log Command, Add Chunk Space Command, Create Space Command, \
        Drop Chunk Command, Drop Space Command',
        components: 'CheckpointsComponents, RecoveryLogsComponent, SpaceListComponent',
        allow: [
            { serverType: ServerTypes.STANDARD },
            { serverType: ServerTypes.PRIMARY },
        ]
    },
    {
        id: 'p3',
        name: 'Terminate Session, Virtual Processor, SQL Tracing Command',
        components: 'SessionListComponent, SqltraceConfigComponent, VirtualProcessorsComponent',
        allow: [
            { serverType: ServerTypes.STANDARD },
            { serverType: ServerTypes.PRIMARY },
            { serverType: ServerTypes.SECONDARY },
            { serverType: ServerTypes.SDS },
            { serverType: ServerTypes.RSS }
        ]
    },
    {
        id: 'p4',
        name: 'Grant Privileges (SQL Admin API) Command, Revoke Privileges Command, Modify Privileges Command, \
        Add Entry Storage Pool Command, Delete Storage Pool Command, Create Database, Create Demo Database, Drop Database, \
        Create Table Command, Memory Management Command, On config Command, edit, add, save Configuration',
        components: 'StoragePoolListComponent, SchemaManagerComponent, MemoryManagerComponent, OnconfigComponent, \
        SqlAdminPrivilegesComponent',
        allow: [
            { serverType: ServerTypes.STANDARD },
            { serverType: ServerTypes.PRIMARY },
            { serverType: ServerTypes.SECONDARY, allowOnReadOnly: false },
            { serverType: ServerTypes.SDS, allowOnReadOnly: false },
            { serverType: ServerTypes.RSS, allowOnReadOnly: false }
        ]
    },
    {
        id: 'p5',
        name: 'Backup Command',
        components: 'BackupStatusComponent',
        allow: [
            { serverType: ServerTypes.STANDARD },
            { serverType: ServerTypes.PRIMARY },
            { serverType: ServerTypes.RSS },
        ]
    }
];
