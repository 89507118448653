/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { User } from '../../shared/user/user';
import { InformixServerGroup } from '../groups/informixServerGroup';
import { informixServerPermissions, InformixTreeItem } from '../informixTreeItem';
import { UsersService } from '../system-settings/users/users.service';
import { PermissionsService } from './permissions.service';
import { ConfirmationDialogService } from '../../shared/modal/confirmation-dialog.service';
import { DataTableComponent } from '../../shared/data-table/data-table.component';

interface PermissionRowBase {
  userId: number;
  userName: string;
  permissions: number;
  permissionsStr: string;
}

interface PermissionRow extends PermissionRowBase {
  parentPermissions: InheritedPermissionRow;
}

interface InheritedPermissionRow extends PermissionRowBase {
  isOverridden: boolean;
}

@Component({
  selector: 'app-user-permissions',
  templateUrl: 'permissions.html'
})
export class PermissionsComponent implements OnChanges {

  @Input() child: InformixTreeItem = null;

  permissionsData: PermissionRow[] = null;
  parentPermissionsData: InheritedPermissionRow[] = null;
  editingRow: PermissionRow = null;
  editPermissionsControl: UntypedFormControl = null;
  isSavingPermissions = false;
  dynamicHeight: any;

  @ViewChildren(DataTableComponent) myDataTable: QueryList<DataTableComponent>;
  @ViewChild('addUsersModal') addUsersModal: ModalDirective;
  users: User[] = null;
  selectedUsers: User[] = null;
  selectedUsersStr: string = null;
  addUsersPermissionsControl: UntypedFormControl = null;
  isAddingUsers = false;

  parentPermissionsLink: any[] = null;

  constructor(
    private permissionsService: PermissionsService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private usersService: UsersService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.child && this.child) {
      if (this.child.permissions.admin) {
        this.init();
      } else {
        this.permissionsData = this.parentPermissionsData = null;
      }
    }
  }
  private init() {
    this.permissionsService.getAllPermissions(this.child).then(perms => {
      if (this.child instanceof InformixServerGroup) {
        this.parentPermissionsLink = ['../../', this.child.parentGroupId, 'permissions'];
      } else {
        this.parentPermissionsLink = ['../../../groups', this.child.parentGroupId, 'permissions'];
      }

      const parentPermissionsMap: { [key: number]: InheritedPermissionRow } = {};
      if (perms.inherited) {
        this.parentPermissionsData = [];
        perms.inherited.forEach(perm => {
          const data: InheritedPermissionRow = {
            userId: perm.user.id,
            userName: perm.user.name,
            permissions: perm.permissions,
            permissionsStr: this.permissionsToString(perm.permissions),
            isOverridden: false
          };

          parentPermissionsMap[perm.user.id] = data;
          this.parentPermissionsData.push(data);
        });
      } else {
        this.parentPermissionsData = null;
      }

      this.permissionsData = perms.own.map(perm => {
        let parentPermissions: InheritedPermissionRow = null;
        if (this.parentPermissionsData) {
          parentPermissions = parentPermissionsMap[perm.user.id];
        }

        if (parentPermissions) {
          parentPermissions.isOverridden = true;
        }

        return {
          userId: perm.user.id,
          userName: perm.user.name,
          permissions: perm.permissions,
          permissionsStr: this.permissionsToString(perm.permissions, parentPermissions),
          parentPermissions
        };
      });
      setTimeout(() => {
        if (this.myDataTable.length === 2 && this.myDataTable.first.dataTableRef) {
          const topOffSet = this.myDataTable.first.dataTableRef.nativeElement.getBoundingClientRect().top;
          this.dynamicHeight = ((window.innerHeight - topOffSet) / 2) - 90;
        }
      });
    }).catch(err => {
      console.error(err);
    });
  }

  private permissionsToString(permissions: number, parent?: InheritedPermissionRow): string {
    if (parent) {
      permissions |= parent.permissions;
    }

    if (permissions > 0) {
      const strings: string[] = [];
      informixServerPermissions.forEach(perm => {
        if ((permissions & perm.value) === perm.value) {
          strings.push(perm.name);
        }
      });
      return strings.join(', ');
    } else {
      return 'None';
    }
  }

  editPermissions(row: PermissionRow) {
    if (row === null) {
      this.editingRow = null;
      this.editPermissionsControl = null;
    } else if (this.editingRow !== row) {
      this.editingRow = row;
      this.editPermissionsControl = new UntypedFormControl(row.permissions);
    }
  }

  savePermissions(row: PermissionRow) {
    this.isSavingPermissions = true;
    this.permissionsService.updatePermissions(this.child, row.userId, this.editPermissionsControl.value).then(() => {
      row.permissions = this.editPermissionsControl.value;
      row.permissionsStr = this.permissionsToString(row.permissions, row.parentPermissions);
      this.editPermissions(null);
      this.notificationsService.pushNotification({
        type: 'success',
        message: 'Permissions updated'
      });
    }).catch(err => {
      this.notificationsService.pushNotification({
        type: 'danger',
        message: 'Error updating permissions'
      });
      console.error(err);
    }).then(() => {
      this.isSavingPermissions = false;
    });
  }

  removePermissions(row: PermissionRow) {
    this.confirmationDialogService.show('remove permissions for ' + row.userName + '?',
      () => this.onRemoveConfirmed(row));
  }

  onRemoveConfirmed(row: PermissionRow) {
    this.permissionsService.removePermissions(this.child, row.userId).then(() => {
      let index = this.permissionsData.indexOf(row);
      if (index > -1) {
        this.permissionsData = this.permissionsData.slice();
        this.permissionsData.splice(index, 1);
      }

      if (this.parentPermissionsData) {
        index = this.parentPermissionsData.findIndex(parentRow => parentRow.userId === row.userId);
        if (index > -1) {
          this.parentPermissionsData[index].isOverridden = false;
        }
      }

      this.notificationsService.pushNotification({
        type: 'success',
        message: 'Permissions updated'
      });
    }).catch(err => {
      this.notificationsService.pushNotification({
        type: 'danger',
        message: 'Error updating permissions'
      });
      console.error(err);
    });
  }

  overridePermissions(row: InheritedPermissionRow) {
    this.permissionsService.updatePermissions(this.child, row.userId, row.permissions).then(() => {
      row.isOverridden = true;
      const newRow: PermissionRow = {
        userId: row.userId,
        userName: row.userName,
        permissions: row.permissions,
        permissionsStr: row.permissionsStr,
        parentPermissions: row
      };
      this.permissionsData = [newRow].concat(this.permissionsData);
      this.editPermissions(newRow);
      this.notificationsService.pushNotification({
        type: 'success',
        message: 'Permissions updated'
      });
    }).catch(err => {
      this.notificationsService.pushNotification({
        type: 'danger',
        message: 'Error updating permissions'
      });
      console.error(err);
    });
  }

  openAddUsersModal() {
    const existingUsers: { [key: string]: boolean } = {};
    this.permissionsData.forEach(row => existingUsers[row.userName] = true);

    this.users = null;
    this.selectedUsers = null;
    this.addUsersModal.show();
    this.usersService.getUsers().then(users => {
      this.users = users.filter(user => !existingUsers[user.name]);
    });
  }

  addUsersSelect(users: User[]) {
    this.selectedUsers = users;
    this.selectedUsersStr = users.map(user => user.name).join(', ');
    this.addUsersPermissionsControl = new UntypedFormControl(0);
  }

  addSelectedUsers() {
    this.isAddingUsers = true;
    this.addSelectedUser(0);
  }

  addSelectedUser(index: number) {
    if (index >= this.selectedUsers.length) {
      this.notificationsService.pushNotification({
        type: 'success',
        message: 'Permissions updated'
      });
      this.addUsersModal.hide();
      this.isAddingUsers = false;
      this.init();
      return Promise.resolve(true);
    }

    const user = this.selectedUsers[index];
    return this.permissionsService.updatePermissions(this.child, user.id, this.addUsersPermissionsControl.value).then(() => {
    }).catch(err => {
      this.notificationsService.pushNotification({
        type: 'error',
        message: 'Error updating permissions for user: ' + user.name
      });
      console.error(err);
    }).then(() => {
      this.addSelectedUser(index + 1);
    });
  }

}
