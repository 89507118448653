/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2020. All Rights Reserved.
 *******************************************************************************/
import { MonitoringProfile } from './monitoringProfile';
import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, Type, ViewContainerRef } from '@angular/core';
import { InformixServer } from '../servers/informixServer';
import { AbstractDashboardComponent } from './abstract-dashboard-component';

@Component({
  selector: 'app-dashboard-component',
  template: ''
})
export class DashboardDynamicComponent implements OnInit {
  @Input() component: Type<AbstractDashboardComponent>;
  @Input() server: InformixServer;
  @Input() config: any;
  @Input() monitoringProfile: MonitoringProfile;

  private componentRef: ComponentRef<AbstractDashboardComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.componentRef = factory.create(this.viewContainerRef.injector);
    this.viewContainerRef.insert(this.componentRef.hostView);

    if (this.componentRef) {
      const c = this.componentRef.instance;
      c.server = this.server;
      c.config = this.config;
      c.monitoringProfile = this.monitoringProfile;
    }
  }

  getComponent(): AbstractDashboardComponent {
    return this.componentRef ? this.componentRef.instance : null;
  }
}
