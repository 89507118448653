<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-4">
      <h4><strong>{{ 'systemReports.locksPerSession' | translate }}</strong>
      </h4>
      <i *ngIf="!reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
    </div>
    <div class="col-8">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
  </div>
  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" [enableSearch]="true" sortBy="ses_num_locks" [rowsPerPage]="20">
    <app-data-table-column [title]="'systemReports.userSessionId' | translate" property="sid" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', item]">{{item}}</a>
      </ng-template>
    </app-data-table-column>

    <app-data-table-column [title]="'common.user' | translate" property="userDisplay" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        {{item}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lock' | translate" property="ses_num_locks" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <a [routerLink]="['../lock-list']" [queryParams]="{ sid: row.sid}">
          {{item}}
        </a>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockRequest' | translate" property="ses_req_locks" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockWait' | translate" property="ses_wai_locks" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.deadLock' | translate" property="ses_dead_locks" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockTimeout' | translate" property="ses_lock_tout" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'database.database' | translate" property="odb_dbname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.isolationLevel' | translate" property="txt" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.connected' | translate" property="ses_connected" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
