<ng-container [formGroup]="formGroup">
  <ng-container *ngIf="requireOldPassword">
    <label><strong>Old Password</strong></label>
    <div class="input-group password-hide-show inner-addon right-addon">
      <input [type]="showOldPassword ? 'text' : 'password'" class="form-control mb-2" formControlName="old"
        (keyup)="toggleOldPassShow(true)" tooltip="" appExtendedFormControl [useFormControlName]="true">
      <i class="icon icon-eye" (click)="toggleOldPassShow()"
        *ngIf="showOldPassword && (formGroup.controls['old'].dirty && formGroup.value.old !== '')"></i>
      <i class="icon icon-eye-blocked" (click)="toggleOldPassShow()"
        *ngIf="!showOldPassword && (formGroup.controls['old'].dirty && formGroup.value.old !== '')"></i>
    </div>
  </ng-container>
  <label><strong>New Password</strong></label>
  <div class="input-group password-hide-show inner-addon right-addon">
    <input [type]="showNewPassword ? 'text' : 'password'" class="form-control mb-2" formControlName="new" tooltip=""
      appExtendedFormControl [useFormControlName]="true" (keyup)="toggleNewPassShow(true)">
    <i class="icon icon-eye" (click)="toggleNewPassShow()"
      *ngIf="showNewPassword && (formGroup.controls['new'].dirty && formGroup.value.new !== '')"></i>
    <i class="icon icon-eye-blocked" (click)="toggleNewPassShow()"
      *ngIf="!showNewPassword && (formGroup.controls['new'].dirty && formGroup.value.new !== '')"></i>
  </div>
  <label><strong>Confirm New Password</strong></label>
  <div class="input-group password-hide-show inner-addon right-addon">
    <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control mb-2" formControlName="newAgain"
      tooltip="" appExtendedFormControl [useFormControlName]="true" (keyup)="toggleConfirmPassShow(true)">
    <i class="icon icon-eye" (click)="toggleConfirmPassShow()"
      *ngIf="showConfirmPassword && (formGroup.controls['newAgain'].dirty && formGroup.value.newAgain !== '')"></i>
    <i class="icon icon-eye-blocked" (click)="toggleConfirmPassShow()"
      *ngIf="!showConfirmPassword && (formGroup.controls['newAgain'].dirty && formGroup.value.newAgain !== '')"></i>
  </div>
</ng-container>
