/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/

export interface AlertOperatorType {
  name: string;
  value: string;
  inputs: { types: string[] }[];
  outputType: string;
  genericTypes?: string[];
}

export const alertOperatorTypes: AlertOperatorType[] = [
  {
    name: 'is less than',
    value: '<',
    inputs: [
      {
        types: ['number']
      },
      {
        types: ['number']
      }
    ],
    outputType: 'boolean'
  },
  {
    name: 'is greater than',
    value: '>',
    inputs: [
      {
        types: ['number']
      },
      {
        types: ['number']
      }
    ],
    outputType: 'boolean'
  },
  {
    name: 'is',
    value: '=',
    inputs: [
      {
        types: ['T']
      },
      {
        types: ['T']
      }
    ],
    outputType: 'boolean',
    genericTypes: ['T']
  },
  {
    name: 'is one of',
    value: 'elem-in-array',
    inputs: [
      {
        types: ['T']
      },
      {
        types: ['T[]']
      }
    ],
    outputType: 'boolean',
    genericTypes: ['T']
  },
  {
    name: 'contains',
    value: 'online-log-contains',
    inputs: [
      {
        types: ['online-log']
      },
      {
        types: ['online-log-regex']
      }
    ],
    outputType: 'boolean'
  }
];
