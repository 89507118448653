/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { InformixServer } from '../../informixServer';
import { TranslateService } from '@ngx-translate/core';
import { TablesIndexesService } from './tables-indexes-page.service';
import { TaskStatusList } from './tables-indexes-page.model';
import { DatePipe } from '@angular/common';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-task-status',
  templateUrl: 'task-status.html',
  styleUrls: ['tables-indexes-page.scss']
})
export class TaskStatusComponent implements OnInit, OnDestroy {

  @Input() server: InformixServer = null;
  taskStatusList: TaskStatusList[];
  private pollTimeoutHandle: number;
  private pollInterval = 10000; // TODO: make this a configurable setting
  private taskStatusSub: Subscription = null;
  cleanupSpinner: boolean;
  apiError: string;
  isLoading: Boolean = false;
  @ViewChild('confirmCleanupModal') confirmCleanupModal: ModalDirective;

  constructor(private translate: TranslateService,
    private tablesIndexesService: TablesIndexesService,
    private datePipe: DatePipe,
    private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.getTaskStatus();
  }

  private getTaskStatus() {
    this.isLoading = true;
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }
    this.taskStatusSub = this.tablesIndexesService.getTaskStatus(this.server).subscribe((data: TaskStatusList[]) => {
      this.taskStatusList = data;
      this.isLoading = false;
      for (let i = 0; i < data.length; i++) {
        this.taskStatusList[i].start = (this.taskStatusList[i].start === null) ? '' :
          this.datePipe.transform(this.taskStatusList[i].start.$date, 'y-MM-dd HH:mm:ss');
        this.taskStatusList[i].end = (data[i].end === null) ? '' :
          this.datePipe.transform(this.taskStatusList[i].end.$date, 'y-MM-dd HH:mm:ss');
      }
    }, err => {
      this.isLoading = false;
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
    }).add(() => {
      this.pollTimeoutHandle = window.setTimeout(() => this.getTaskStatus(), this.pollInterval);
    });
  }

  ngOnDestroy() {
    if (this.taskStatusSub) {
      this.taskStatusSub.unsubscribe();
    }
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }
  }

  refreshData() {
    this.taskStatusList = null;
    this.getTaskStatus();
  }

  cleanupJobs() {
    this.cleanupSpinner = true;
    this.tablesIndexesService.cleanupJobs(this.server).subscribe(resp => {
       this.cleanupSpinner = false;
       this.confirmCleanupModal.hide();
       this.refreshData();
       this.notificationsService.pushSuccessNotification('Cleanup is succussfully done.');
       this.apiError = null;
     }, err => {
       this.cleanupSpinner = false;
       this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
     });
   }

   confirmCleanup() {
     if(this.confirmCleanupModal) {
       this.confirmCleanupModal.show();
     }
   }
}
