<ng-template [ngIf]="sqltraceInfo">
  <div class="card card-body">
    <h4 class="card-title font-weight-bold">Tracing Info</h4>

    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col text-right">
            Tracing Start Time:
          </div>
          <div class="col">
            {{sqltraceInfo.starttime.$date | date:'y-MM-dd HH:mm:ss'}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col text-right">
            Earliest Trace in Buffer:
          </div>
          <div class="col">
            {{sqltraceInfo.starttrace.$date | date:'y-MM-dd HH:mm:ss'}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col text-right">
            Duration of Trace Buffer:
          </div>
          <div class="col">
            {{sqltraceInfo.duration | relativeTime}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col text-right">
            Total SQL seen:
          </div>
          <div class="col">
            {{sqltraceInfo.sqlseen}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col text-right">
            SQL per second:
          </div>
          <div class="col">
            {{sqltraceInfo.sqlpersec}}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row mt-1">
          <div class="col text-right">
            Memory Used:
          </div>
          <div class="col">
            {{sqltraceInfo.memoryused | byte}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col text-right">
            Trace Options:
          </div>
          <div class="col">
            <i class="icon mr-1" [ngClass]="'icon-check' + ( sqltraceInfo.trc_dbsname > 0 ? '' : '-off' )"></i><span tooltip="Captured in medium and high tracing levels">Database name</span><br>
            <i class="icon mr-1" [ngClass]="'icon-check' + ( sqltraceInfo.trc_tabnames > 0 ? '' : '-off' )"></i><span tooltip="Captured in medium and high tracing levels">Table names</span><br>
            <i class="icon mr-1" [ngClass]="'icon-check' + ( sqltraceInfo.trc_procedures > 0 ? '' : '-off' )"></i><span tooltip="Captured in medium and high tracing levels">Procedures</span><br>
            <i class="icon mr-1" [ngClass]="'icon-check' + ( sqltraceInfo.trc_hostvars > 0 ? '' : '-off' )"></i><span tooltip="Captured in high tracing level">Host variables</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
