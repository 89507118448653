/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Route } from '@angular/router';
import { ServerResolver } from '../server-resolver.service';
import { OnconfigPageComponent } from './onconfig-page/onconfig-page.component';

export const OnconfigRoutes: Route[] = [
  {
    path: 'onconfig',
    children: [
      {
        path: '',
        component: OnconfigPageComponent,
        resolve: {
          server: ServerResolver
        }
      }
    ]
  }
];
