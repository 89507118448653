<ng-container *ngIf="graphNodes">
  <div class="overflow-auto wrapper domain-graph-wrapper" [ngClass]="{'fullscreen': isFullscreen}">
    <div class="mx-auto" [ngStyle]="{'width.px': graphSize.x * zoom, 'height.px': graphSize.y * zoom}">
      <div class="graph-container"
        [ngStyle]="{'width.px': graphSize.x, 'height.px': graphSize.y, transform: 'scale(' + zoom + ')'}">
        <svg [attr.width]="graphSize.x" [attr.height]="graphSize.y">
          <ng-container *ngIf="graphRootLines">
            <line *ngFor="let line of graphRootLines" class="graph-line graph-line-dashed up-line graphRootLine" [attr.x1]="line.start.x"
              [attr.y1]="line.start.y" [attr.x2]="line.end.x" [attr.y2]="line.end.y"></line>
          </ng-container>

          <ng-container *ngFor="let node of graphNodes">
            <line *ngFor="let line of node.getLines()" class="graph-line down-line graphNodeLine" [attr.x1]="line.start.x"
              [attr.y1]="line.start.y" [attr.x2]="line.end.x" [attr.y2]="line.end.y"></line>
          </ng-container>
        </svg>

        <div *ngFor="let node of graphNodes" class="graph-node" [ngStyle]="node.cssStyle" (click)="selectNode(node)">
          <div class="flex-1 overflow-hidden">
            <div class="text-center {{node.node.typeName}}" style="padding-top: 6px" [popover]="nodeInfo" placement="top" triggers="hover" 
            containerClass="er-graph-popover popover-arrow-hidden popover-color" [ngClass]="{'local': node?.node?.group, 'add-mode': domainMode === domainModes.ADD}">
              <div class="d-inline-block position-relative">
                <i class="icon icon-2x icon-bare-metal-server-filled" *ngIf="selectedNode !== node.node">
                </i>
                <i class="icon icon-2x"
                  [ngClass]="[node.node.members.length > 1 || false ? 'icon-sds-server' : 'icon-hdr-server', selectedNode === node.node ? 'icon-bare-metal-server-filled selected' : 'icon-bare-metal-server']">
                </i>
                <!-- <div class="graph-node-status" [ngClass]="'bg-' + node.node.serverState.color" [tooltip]="node.node.serverState.name"></div> -->
              </div>
              <i class="icon icon-info-circle info position-absolute text-danger error-icon" *ngIf="node?.node?.group?.validation?.errorMsg"></i>
              <i class="icon icon-times-circle delete-node-icon" (click)="removeNode(node.node); $event.stopPropagation()" *ngIf="node?.node?.group"></i>
              <div class="text-center small" [title]="node.node.name">
                <span class="group-name text-truncate ">{{node?.node?.name}}</span>
              </div>
            </div>
          </div>
          <ng-template #nodeInfo>
            <div class="popover-body node-info btn-group-toggle p-0">
                <div>{{ node?.node?.name}}</div>
                <div><strong class="bold">Group Member(s):</strong></div>
                <div>
                  <ul class="list-unstyled m-0 p-0" *ngFor="let member of node.node.members">
                    <li>{{member?.dbServerName}}</li>
                  </ul>
                </div>
                <ng-container *ngIf="domainMode !== domainModes.ADD">
                  <div class="mt-1"><strong class="bold">Server State:</strong> {{node?.node?.serverState?.name}}</div>
                  <div><strong class="bold">Connection State:</strong> {{node?.node?.serverStatus?.name}}</div>
                </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="zoom-overlay">
    <button type="button" class="d-block btn btn-sm btn-secondary" (click)="zoomIn()">
      <i class="icon icon-plus"></i>
    </button>
    <button type="button" class="d-block btn btn-sm btn-secondary" (click)="zoomOut()">
      <i class="icon icon-minus"></i>
    </button>
  </div>
</ng-container>
