/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../../servers/informixServer';
import { ServerBreadcrumb } from '../../servers/serverBreadcrumb';

@Component({
  selector: 'app-scheduler',
  templateUrl: 'scheduler.html'
})
export class SchedulerComponent implements OnInit {

  @Output() server: InformixServer = null;
  title: string = null;
  breadcrumb: BreadcrumbElement[] = null;
  selectedTabIndex: Number = 0;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  setSelectedTabIndex(index) {
    this.selectedTabIndex = index;
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [{ name: 'DB Server Administration' }, { name: 'Task Scheduler' }]);
  }
}
