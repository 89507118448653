/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomSystemReport } from './system-report';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomSystemReportsService {

  constructor(private httpClient: HttpClient) { }

  public getReports(includePackage = true): Promise<CustomSystemReport[]> {
    return this.httpClient.get<CustomSystemReport[]>('reports?includePackage=' + includePackage)
      .pipe(map(response => response.map(v => new CustomSystemReport(v)))).toPromise<CustomSystemReport[]>();
  }

  public getReport(reportId: string): Promise<CustomSystemReport> {
    return this.httpClient.get<CustomSystemReport>('reports/' + reportId)
      .pipe(map(response => new CustomSystemReport(response))).toPromise<CustomSystemReport>();
  }

  public createReport(report: CustomSystemReport): Observable<CustomSystemReport> {
    return this.httpClient.post<any>('reports', report).pipe(map(response => new CustomSystemReport(response)));
  }

  public updateReport(reportId: string, report: CustomSystemReport): Observable<CustomSystemReport> {
    return this.httpClient.put<any>('reports/' + reportId, report).pipe(map(response => new CustomSystemReport(response)));
  }

  public deleteReport(report: CustomSystemReport): Promise<any> {
    return this.httpClient.delete<any>('reports/' + report.id).toPromise<any>();
  }

}
