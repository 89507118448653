<div class="mt-4" *ngIf="!serverNotInCluster && !clusterServerError && serverType else loading">
  <div *ngIf="serverType === 'PRIMARY' else secondaryGraphs">
    <app-dashboard [server]="server" [dashboard]="primaryServerMetrics">
    </app-dashboard>
  </div>
  <ng-template #secondaryGraphs>
    <app-dashboard [server]="server" [dashboard]="secondaryServersMetrics">
    </app-dashboard>
  </ng-template>
</div>
<ng-template #loading>
  <div *ngIf="serverNotInCluster" class="mt-4 mb-0 alert alert-warning">
    <i class="icon icon-warning text-warning"></i> {{serverNotInCluster}}
  </div>
  <div *ngIf="clusterServerError" class="mt-2 alert alert-danger">
    <i class="icon icon-danger text-danger"></i>Unable to retrieve cluster metrics information. <br> Error: {{clusterServerError}}
  </div>
  <div *ngIf="dataLoading" class="d-flex justify-content-center">
    <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
  </div>
</ng-template>
