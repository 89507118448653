<div *ngIf="!isCreateQuery">
  <div class="row bottomBorder">
    <div class="col-12">
      <h5 class="font-weight-bold">{{ 'schemaManager.createIndex.indexOptions.title' | translate }}</h5>
    </div>
  </div>
  <form *ngIf="indexOptionsForm" [formGroup]="indexOptionsForm">
    <div class="row mt-2" *ngIf="dataObj.indexType === 'fot'">
      <div class="col-6 col-sm-2" [title]="'schemaManager.createIndex.indexOptions.subtreesDesc' | translate">
        <label>{{ 'schemaManager.createIndex.indexOptions.noOfSubtrees' | translate }}</label>
        <input class="form-control" type="number" formControlName="subtrees" tooltip="" appExtendedFormControl>
      </div>
    </div>
    <div class="row mt-2" *ngIf="dataObj.indexType === 'xml' || dataObj.indexType === 'bts'">
      <div class="col-12">
        <label class="font-weight-bold mt-2" *ngIf="dataObj.indexType === 'xml'">{{ 'schemaManager.createIndex.indexOptions.xmlTags' | translate }}</label>
        <label class="font-weight-bold mt-2" *ngIf="dataObj.indexType === 'bts'">{{ 'schemaManager.createIndex.indexOptions.stopWords' | translate }}</label>
        <br />
        <app-radio-group formControlName="listValue">
          <app-radio value="default">{{dataObj.indexType === 'xml'? 'Use all XML tags' : 'Use the default list'}}
          </app-radio>
          <app-radio class="ml-2" value="customize">{{ 'schemaManager.createIndex.indexOptions.useCustomizedList' | translate }}</app-radio>
        </app-radio-group><br />
      </div>
    </div>
    <div class="row mt-2"
      *ngIf="(dataObj.indexType === 'xml' ||  dataObj.indexType === 'bts') && listValue.value === 'customize'">
      <div class="col-3">
        <select class="form-control" formControlName="loadList">
          <option value="loadFromFile">{{ 'schemaManager.createIndex.indexOptions.loadFromFile' | translate }}</option>
          <option value="enterList">{{ 'schemaManager.createIndex.indexOptions.enterList' | translate }}</option>
          <option value="loadFromColumn">{{ 'schemaManager.createIndex.indexOptions.loadFromColumn' | translate }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-2"
      *ngIf="(dataObj.indexType === 'xml' ||  dataObj.indexType === 'bts') && listValue.value === 'customize'">
      <div class="col-3" *ngIf="loadList.value === 'loadFromFile'" [title]="'schemaManager.createIndex.indexOptions.enterFullPath' | translate">
        <label class="font-weight-bold"><span class="text-danger">* </span>
          {{ 'schemaManager.createIndex.indexOptions.file' | translate }}</label>
        <input class="form-control" type="text" formControlName="filePath">
      </div>
      <div class="col-3" *ngIf="loadList.value === 'enterList'"
        [title]="'schemaManager.createIndex.indexOptions.enterStopWords' | translate">
        <label class="font-weight-bold"><span class="text-danger">* </span>
          {{ 'schemaManager.createIndex.indexOptions.enterList' | translate }}</label>
        <textarea class="form-control" rows="4" formControlName="customWords"></textarea>
      </div>
      <div class="col-2" *ngIf="loadList.value === 'loadFromColumn'">
        <label class="font-weight-bold"><span class="text-danger">* </span>
          {{ 'table.table' | translate }}</label>
        <input class="form-control" type="text" formControlName="tableName">
      </div>
      <div class="col-2" *ngIf="loadList.value === 'loadFromColumn'">
        <label class="font-weight-bold"><span class="text-danger">* </span>
          {{ 'table.column' | translate }}</label>
        <input class="form-control" type="text" formControlName="columnName">
      </div>
    </div>
    <div class="row mt-2" *ngIf="dataObj.indexType === 'xml'">
      <div class="col-12">
        <label class="font-weight-bold mt-2">{{ 'schemaManager.createIndex.indexOptions.xmlOptions' | translate }}</label><br />
        <app-checkbox [checked]="isXMLPathProcessing" (click)="isXMLPathProcessing = !isXMLPathProcessing"
          [title]="'schemaManager.createIndex.indexOptions.enableXMLPathsDesc' | translate">
          {{ 'schemaManager.createIndex.indexOptions.enableXMLPath' | translate }}
        </app-checkbox><br />
        <app-checkbox class="mt-2" [checked]="isIncludeNameSpaces" (click)="isIncludeNameSpaces = !isIncludeNameSpaces"
          [title]="'schemaManager.createIndex.indexOptions.includeNamespacesDesc' | translate">
          {{ 'schemaManager.createIndex.indexOptions.includeNamespaces' | translate }}
        </app-checkbox><br />
        <app-checkbox class="mt-2" [checked]="isIncludeSubtagText" (click)="isIncludeSubtagText = !isIncludeSubtagText"
          [title]="'schemaManager.createIndex.indexOptions.xmlTagsDesc' | translate">
          {{ 'schemaManager.createIndex.indexOptions.includeSubtag' | translate }}
        </app-checkbox>
      </div>
    </div>
    <div class="row"
      *ngIf="dataObj.indexType === 'unique' || dataObj.indexType === 'duplicate' || dataObj.indexType === 'fot'">
      <div class="col-12">
        <span *ngIf="dataObj.indexType !== 'fot'">
          <label class="font-weight-bold mt-2">
            {{ 'schemaManager.createIndex.indexOptions.indexCreationOption' | translate }}</label><br />
          <app-radio-group formControlName="creationType">
            <app-radio value="online">{{ 'schemaManager.createIndex.indexOptions.online' | translate }}</app-radio>
            <app-radio class="ml-2" value="offline">{{ 'schemaManager.createIndex.indexOptions.offline' | translate }}
            </app-radio>
          </app-radio-group><br />
        </span>
        <app-checkbox class="mt-3" [checked]="isCompress" (click)="isCompress = !isCompress">
          {{ 'schemaManager.createIndex.indexOptions.compressIndex' | translate }}
        </app-checkbox>
      </div>
    </div>
    <div class="row" *ngIf="dataObj.indexType !== 'xml' && dataObj.indexType !== 'bts'">
      <div class="col-12">
        <label class="mt-4">{{ 'schemaManager.createIndex.indexOptions.enterExtentSize' | translate }}</label><br />
        <label class="font-weight-bold  mt-1">
          {{ 'schemaManager.createIndex.indexOptions.extentSizeEstimator' | translate }}</label><br />
      </div>
    </div>
    <div class="row" *ngIf="dataObj.indexType !== 'xml' && dataObj.indexType !== 'bts'">
      <div class="col-12 col-lg-2 mb-0 mb-sm-2">
        <label>{{ 'schemaManager.createIndex.indexOptions.numberOfRows' | translate }}</label>
        <input class="form-control" type="number" formControlName="exptRows" placeholder="0000" tooltip=""
          appExtendedFormControl>
      </div>
      <div class="col-6 col-lg-2">
        <label>{{ 'schemaManager.createIndex.indexOptions.growthRate' | translate }}</label>
        <select class="form-control" formControlName="growthRate"
          [title]="'schemaManager.createIndex.indexOptions.growthRateDesc' | translate">
          <option value="static">{{ 'schemaManager.createIndex.indexOptions.static' | translate }}</option>
          <option value="medium">{{ 'schemaManager.createIndex.indexOptions.medium' | translate }}</option>
          <option value="aggressive">{{ 'schemaManager.createIndex.indexOptions.aggressive' | translate }}</option>
        </select>
      </div>
      <div class="col-6 col-lg-2">
        <button type="button" class="btn btn-primary mt-4" (click)="doEstimate()"
          [disabled]="!exptRows.valid">{{ 'schemaManager.createIndex.indexOptions.estimate' | translate }}</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <label class="font-weight-bold  mt-1">
          {{ 'schemaManager.createIndex.indexOptions.extentSize' | translate }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-sm-2">
        <label>{{ 'schemaManager.createIndex.indexOptions.firstExtent' | translate }}</label>
        <input class="form-control" type="number" formControlName="firstExtent" placeholder="0000" tooltip=""
          appExtendedFormControl>
      </div>
      <div class="col-6 col-sm-2">
        <label>{{ 'schemaManager.createIndex.indexOptions.nextExtent' | translate }}</label>
        <input class="form-control" type="number" formControlName="nextExtent" placeholder="0000" tooltip=""
          appExtendedFormControl>
      </div>
    </div>
    <div class="row mt-2" *ngIf="dataObj.indexType !== 'fot'">
      <div class="col-12">
        <label class="font-weight-bold mt-2">
          {{ 'schemaManager.createIndex.indexOptions.indexStorageOptions' | translate }}</label><br />
        <app-radio-group formControlName="storageScheme">
          <app-radio value="tableStorage"
            [title]="'schemaManager.createIndex.indexOptions.tableStorageDesc' | translate">
            {{ 'schemaManager.createIndex.indexOptions.followTableStorageScheme' | translate }}
          </app-radio>
          <app-radio class="ml-0 ml-sm-2" value="specifyStorage"
            [title]="'schemaManager.createIndex.indexOptions.specifyStorageDesc' | translate">
            {{ 'schemaManager.createIndex.indexOptions.specifyStorageScheme' | translate }}</app-radio>
        </app-radio-group>
      </div>
    </div>
    <div *ngIf="dataObj.indexType !== 'fot' && storageScheme.value === 'specifyStorage'">
      <app-storage-scheme [server]="server" [dataObj]="dataObj" (isSchemeValid)="isSchemeValid = $event"
        [isBackToIndex]="isBackToIndex" [createQueryClicked]="createQueryClicked" [isCompress]="isCompress"
        (schemeDataObj)="getSchemeData($event)">
      </app-storage-scheme>
    </div>
    <div class="row mt-5 topBorder">
      <div class="col-12 pt-3">
        <button type="button" class="btn btn-secondary mr-2" (click)="backCreateIndex()">
          {{ 'button.back' | translate }}</button>
        <button type="button" class="btn btn-primary mr-2" (click)="viewQuery()" [disabled]="storageScheme.value === 'specifyStorage' && !isSchemeValid ||
           ((!firstExtent.valid && firstExtent.value !== null) || (!nextExtent.valid && nextExtent.value !== null)) ||
            (dataObj.indexType === 'fot' && !subtrees.valid)">
          {{ 'schemaManager.createIndex.createQuery.viewSQL' | translate }}</button>
      </div>
    </div>
  </form>
</div>
<app-create-query *ngIf="isCreateQuery" [server]="server" [table]="table" [dataObj]="dataObj"
  (backFromQuery)="isCreateQuery = false" (closeCreateIndex)="closeCreateIndex.emit()"></app-create-query>
