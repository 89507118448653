/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/

import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { InformixServerGroup } from './informixServerGroup';

export class GroupBreadcrumb {

  public static build(group: InformixServerGroup, extraElements: BreadcrumbElement[] = []): BreadcrumbElement[] {
    const breadcrumbElements: BreadcrumbElement[] = [];

    group.ancestors.forEach(parentGroup => {
      breadcrumbElements.push({
        name: parentGroup.name,
        link: '/dashboard/groups/' + parentGroup.id,
      });
    });

    breadcrumbElements.push({
      name: group.name,
      link: extraElements.length < 1 ? null : '/dashboard/groups/' + group.id,
    });

    extraElements.forEach(elem => {
      breadcrumbElements.push(elem);
    });

    return breadcrumbElements;
  }
}
