<div class="er-sidebar left border border-gray-20 bg-white" [ngClass]="{'open': show}">

        <div class="header py-2 px-3 border-bottom border-gray-20 d-flex ">
          <div class="flex-1">
            Groups to add in the domain
          </div>
        </div>

        <div class="loaderContainer  d-flex align-items-center justify-content-center flex-column"
            *ngIf="!groups; else groupList;">
            <i class="icon icon-circle-notch icon-spin"></i>
            <span class="mt-2">Fetching List... </span>
        </div>
        <ng-template #groupList>
            <div class="flex-1 overflow-auto">
                <div *ngIf="!groups?.length" class="p-2">
                    <div class="alert alert-info mt-2 mb-0 w-100">
                        <i class="icon icon-info icon-info-circle text-info pr-1"></i> Please add group in the list
                    </div>
                </div>
              <ul class="server-lists pl-0">
                  <li *ngFor="let group of groups; let i = index;" class="p-2"
                      [ngClass]="{'selected': (group?.serverName === selectedGroup?.serverName && group?.groupName === selectedGroup?.groupName), 'current-group': group?.isCurrentGroup}"
                      (click)="selectGroup(group); showSqlhostsGroupsModal = !showSqlhostsGroupsModal">
                      <div class="d-flex align-items-center pl-2">
                        <span class="flex-grow-1">
                          {{ group?.groupName }}</span>
                        <span class="icons">
                            <i *ngIf="group?.validation?.status === sqlhostsGroupValidationStatus.PROGRESS" class="icon icon-circle-notch icon-spin staus-icon text-primary"></i>
                            <i *ngIf="group?.validation?.status === sqlhostsGroupValidationStatus.ERROR" class="icon icon-info-circle info text-danger staus-icon"></i>
                            <i *ngIf="group?.validation?.status === sqlhostsGroupValidationStatus.SUCCESS" class="icon icon-check text-success staus-icon" [style.visibility]="group?.configuration?'visible':'hidden'"></i>
                            <i *ngIf="!group?.isCurrentGroup" class="icon icon-times delete-icon" (click)="deleteGroup(group,i)"></i>
                        </span>
                      </div>
                  </li>
              </ul>
            </div>
            <div class="p-2">
              <button class="btn btn-primary btn-block" (click)="showSqlHostsModal($event)">Add new node</button>
            </div>
        </ng-template>
    </div>
