/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { ConstantEnum } from './constant-operands';

export const agentStatusEnum: ConstantEnum = {
  name: 'Agent status',
  type: 'agent-status',
  elements: [
    {
      name: 'Offline',
      value: 0
    },
    {
      name: 'Online',
      value: 1
    }
  ]
};
