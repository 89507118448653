/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021, 2022. All Rights Reserved.
 *******************************************************************************/

import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';
import { DataTableRow } from '../../../../../shared/data-table/data-table.component';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { SchemaService } from '../../schema.service';
import { ForeignKeyModalComponent } from './foreign-key-modal.component';
import { UniqueKeyModalComponent } from './unique-key-modal.component';
import { Table } from '../create-table.model';

@Component({
  selector: 'app-add-constraint',
  templateUrl: './add-constraint.component.html'
})
export class AddConstraintComponent implements OnChanges {
  @Input() dataObj: Table;
  @Input() session;
  @Output() advTableObj = new EventEmitter<any>();
  @Output() backToCreateTable = new EventEmitter<string>();
  @ViewChild(ForeignKeyModalComponent) foreignKeyModalComponent: ForeignKeyModalComponent;
  @ViewChild(UniqueKeyModalComponent) uniqueKeyModalComponent: UniqueKeyModalComponent;

  primaryKeyName;
  foreignKeys = [];
  uniqueKeys = [];
  constraints;

  isAdvOptions = false;
  isViewQuery = false;
  backToAdvOptions = false;

  selectedColumns = [];
  primaryColumns = [];
  allColumns = [];
  isAddDisable = true;
  isRemoveDisable = true;
  isUpwardDisable = true;
  isDownwardDisable = true;
  queryError;

  advObj;
  finalObj;
  viewQueryObj;

  constructor(
    private notificationsService: NotificationsService,
    private schemaService: SchemaService,
    private confirmationDialogService: ConfirmationDialogService) { }

  ngOnChanges() {
    if (this.dataObj && this.dataObj['tableColumnsList']) {
      this.allColumns = this.dataObj['tableColumnsList'].filter(col => {
        if (col.defaultNull === true) {
          return;
        } else if (col.columnDataType === 'blob' || col.columnDataType === 'clob' || col.columnDataType === 'text' ||
          col.columnDataType === 'byte' || col.columnDataType === 'set' || col.columnDataType === 'multiset' ||
          col.columnDataType === 'list' || col.columnDataType === 'namedRow') {
          return;
        } else if (col.dataTypeAttributes && col.dataTypeAttributes.length > 6144) {
            return;
          } else {
            return col;
          }
      }).map(column => ({ name: column.tableColumnName, checked: false })).slice();
    }

    if (this.dataObj && this.dataObj['primaryKey']) {
      const pk = this.dataObj['primaryKey'];
      this.primaryKeyName = pk['primaryKeyName'];
      this.primaryColumns = pk['primaryKeyColumnName'].map(col => ({ name: col, checked: false }));
      this.primaryColumns.forEach(col => {
        const index = this.allColumns.map((e) => e.name).indexOf(col.name);
        if (index > -1) {
          this.allColumns.splice(index, 1);
        }
      });
    }

    if (this.dataObj && this.dataObj['uniqueKey']) {
      this.uniqueKeys = this.dataObj['uniqueKey'];
    }

    if (this.dataObj && this.dataObj['foreignKey']) {
      this.foreignKeys = this.dataObj['foreignKey'];
    }
  }

  viewTableAdvOptions() {
    this.isAdvOptions = true;
    this.getFinalObj();
  }

  getAdvTableObj(event) {
    this.advObj = event;
  }

  getFinalObj() {
    this.finalObj = {};
    this.constraints = {};
    if (this.primaryColumns.length > 0 && this.primaryKeyName && this.primaryKeyName.trim()) {
      this.constraints['primaryKey'] = {
        primaryKeyName: this.primaryKeyName,
        primaryKeyColumnName: this.primaryColumns.map(col => col.name)
      };
    } else if (this.dataObj['primaryKey']) {
      delete this.dataObj['primaryKey'];
    }

    if (this.uniqueKeys.length > 0) {
      this.constraints['uniqueKey'] = this.uniqueKeys;
    } else if (this.dataObj['uniqueKey']) {
      delete this.dataObj['uniqueKey'];
    }

    if (this.foreignKeys.length > 0) {
      this.constraints['foreignKey'] = this.foreignKeys;
    } else if (this.dataObj['foreignKey']) {
      delete this.dataObj['foreignKey'];
    }

    for (const key in this.constraints) {
      if (this.constraints.hasOwnProperty(key)) {
        this.dataObj[key] = this.constraints[key];
        this.finalObj[key] = this.constraints[key];
      }
    }

    if (this.advObj) {
      for (const key in this.advObj) {
        if (this.advObj.hasOwnProperty(key) && key !== 'columnsList') {
          if (this.advObj['tableColumnsList']) {
            this.advObj['tableColumnsList'].forEach(col => {
              if (col['indexedColumnName']) {
                delete col['indexedColumnName'];
              }
              if (col['type']) {
                delete col['type'];
              }
            });
          }
          this.dataObj[key] = this.advObj[key];
          this.finalObj[key] = this.advObj[key];
        }
      }
    }
    this.dataObj['isNewLine'] = true;

    if (this.dataObj['columnsList']) {
      delete this.dataObj['columnsList'];
    }
  }

  viewQuery() {
    this.getFinalObj();
    this.schemaService.createTable(this.session, this.dataObj).subscribe((res) => {
      this.viewQueryObj = res;
      this.isViewQuery = true;
      this.isAdvOptions = false;
    }, err => {
      this.queryError = true;
      this.isViewQuery = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  getSelectedColumns(event) {
    this.selectedColumns = event;
    this.primaryColumns.map(col => col.checked = false);
    setTimeout(() => {
      this.isRemoveDisable = true;
      if (event.length) {
        this.isAddDisable = false;
      }
    }, 0);
  }

  getPrimaryColumns(event) {
    this.isUpwardDisable = true;
    this.isDownwardDisable = true;
    this.selectedColumns = event;
    this.allColumns.map(col => col.checked = false);
    setTimeout(() => {
      this.isAddDisable = true;
      if (event.length) {
        this.isRemoveDisable = false;
      }
    }, 0);
    if (this.selectedColumns.length === 1 && this.primaryColumns.length > 1) {
      const index = this.primaryColumns.map(col => col.name).indexOf(this.selectedColumns[0].name);
      if (index === this.primaryColumns.length - 1) {
        this.isDownwardDisable = true;
        this.isUpwardDisable = false;
      } else if (index === 0) {
        this.isUpwardDisable = true;
        this.isDownwardDisable = false;
      } else {
        this.isDownwardDisable = false;
        this.isUpwardDisable = false;
      }
    } else {
      this.isDownwardDisable = true;
      this.isUpwardDisable = true;
    }
  }

  addPrimaryKey() {
    this.selectedColumns.forEach(col => {
      const index = this.allColumns.map((e) => e.name).indexOf(col.name);
      this.allColumns.splice(index, 1);
      this.primaryColumns.push(col);
    });
    this.selectedColumns = [];
    this.allColumns.map(col => col.checked = false);
    this.primaryColumns.map(col => col.checked = false);
    setTimeout(() => {
      this.allColumns = this.allColumns.slice();
      this.primaryColumns = this.primaryColumns.slice();
    }, 0);
    this.isAddDisable = true;
    this.isRemoveDisable = true;
  }

  removePrimaryKey() {
    this.selectedColumns.forEach(col => {
      const index = this.primaryColumns.map((e) => e.name).indexOf(col.name);
      this.primaryColumns.splice(index, 1);
      this.allColumns.push(col);
    });
    this.selectedColumns = [];
    this.allColumns.map(col => col.checked = false);
    this.primaryColumns.map(col => col.checked = false);
    setTimeout(() => {
      this.allColumns = this.allColumns.slice();
      this.primaryColumns = this.primaryColumns.slice();
    }, 0);
    this.isRemoveDisable = true;
    this.isAddDisable = true;
  }

  allColsDataMap(column: any): DataTableRow {
    return {
      data: column,
      selected: column.checked,
      disabled: false,
      expanded: false
    };
  }

  allPrimaryColsDataMap(column: any): DataTableRow {
    return {
      data: column,
      selected: column.checked,
      disabled: false,
      expanded: false
    };
  }

  backToConstraint(event: string) {
    if (this.dataObj['isExecute']) {
      this.dataObj['isExecute'] = false;
    }
    this.backToAdvOptions = false;
    if (event === 'cancel') {
      this.backToCreateTable.emit('cancel');
    } else if (event === 'viewQuery') {
      this.queryError = false;
      this.viewQuery();
      this.backToAdvOptions = true;
    } else if (event === 'backToAdv') {
      this.isViewQuery = false;
      this.isAdvOptions = true;
    } else {
      this.isViewQuery = false;
      this.isAdvOptions = false;
    }
  }

  backToTable(type: string) {
    if (type === 'back') {
      this.getFinalObj();
      this.advTableObj.emit(this.finalObj);
      this.backToCreateTable.emit('back');
    } else {
      this.backToCreateTable.emit('cancel');
    }
  }

  addForeignKey() {
    const columnList = this.dataObj['tableColumnsList'].map(
        column => ({ name: column.tableColumnName, type: column.columnDataType.toUpperCase() })
      );
    this.foreignKeyModalComponent.openModal(this.session, this.dataObj['tableOwner'], columnList);
  }

  addUniqueKey() {
    const columnList = this.dataObj['tableColumnsList'].filter(col => {
      if (col.defaultNull === true) {
        return;
      } else if (col.columnDataType === 'blob' || col.columnDataType === 'clob' || col.columnDataType === 'text' ||
        col.columnDataType === 'byte' || col.columnDataType === 'set' || col.columnDataType === 'multiset' ||
        col.columnDataType === 'list' || col.columnDataType === 'namedRow') {
        return;
      } else if (col.dataTypeAttributes && col.dataTypeAttributes.length > 6144) {
 return;
} else {
 return col;
}
    }).map(column => ({ name: column.tableColumnName, checked: false })).slice();
    this.uniqueKeyModalComponent.openModal(columnList, this.primaryColumns);
  }

  getUniqueKeyObj(event) {
    if (typeof event.id === 'number') {
      this.uniqueKeys[event.id].uniqueKeyName = event.uniqueKeyName;
      this.uniqueKeys[event.id].uniquekeyColumnName = event.uniquekeyColumnName;
    } else {
      this.uniqueKeys.push(event);
    }
    setTimeout(() => {
      this.uniqueKeys = this.uniqueKeys.slice();
    }, 0);
  }

  getForeignKeyObj(event) {
    if (typeof event.id === 'number') {
      this.foreignKeys[event.id].foreignKeyName = event.foreignKeyName;
      this.foreignKeys[event.id].disableIndex = event.disableIndex;
      this.foreignKeys[event.id].referencedTableName = event.referencedTableName;
      this.foreignKeys[event.id].foreignKeyReferences = event.foreignKeyReferences;
    } else {
      this.foreignKeys.push(event);
    }
    setTimeout(() => {
      this.foreignKeys = this.foreignKeys.slice();
    }, 0);
  }

  editUniqueKey(row) {
    const columnList = this.dataObj['tableColumnsList'].filter(col => {
      if (col.defaultNull === true) {
        return;
      } else if (col.columnDataType === 'blob' || col.columnDataType === 'clob' || col.columnDataType === 'text' ||
        col.columnDataType === 'byte' || col.columnDataType === 'set' || col.columnDataType === 'multiset' ||
        col.columnDataType === 'list' || col.columnDataType === 'namedRow') {
        return;
      } else if (col.dataTypeAttributes && col.dataTypeAttributes.length > 6144) {
 return;
} else {
 return col;
}
    }).map(column => ({ name: column.tableColumnName, checked: false })).slice();
    const index = this.uniqueKeys.map(key => key.uniqueKeyName).indexOf(row.uniqueKeyName);
    this.uniqueKeyModalComponent.openModal(columnList, this.primaryColumns, { row, index });
  }

  editForeignKey(row) {
    const columnList = this.dataObj['tableColumnsList'].map(
        column => ({ name: column.tableColumnName, type: column.columnDataType.toUpperCase() })
      );
    const index = this.foreignKeys.map(key => key.foreignKeyName).indexOf(row.foreignKeyName);
    this.foreignKeyModalComponent.openModal(this.session, this.dataObj['tableOwner'], columnList, { row, index });
  }

  deleteUniqueKey(row) {
    this.confirmationDialogService.show('Delete Unique Key',
      'continue?', () => {
        const index = this.uniqueKeys.map(key => key.uniqueKeyName).indexOf(row.uniqueKeyName);
        if (index > -1) {
          this.uniqueKeys.splice(index, 1);
        }
        setTimeout(() => {
          this.uniqueKeys = this.uniqueKeys.slice();
        }, 0);
      });
  }

  deleteForeignKey(row) {
    this.confirmationDialogService.show('Delete Foreign Key', 'continue?', () => {
      const index = this.foreignKeys.map(key => key.foreignKeyName).indexOf(row.foreignKeyName);
      if (index > -1) {
        this.foreignKeys.splice(index, 1);
      }
      setTimeout(() => {
        this.foreignKeys = this.foreignKeys.slice();
      }, 0);
    });
  }

  moveRows(type: string) {
    const colName = this.selectedColumns[0].name;
    const fromIndex: number = this.primaryColumns.map(col => col.name).indexOf(colName);
    const toIndex: number = (type === 'upward') ? fromIndex - 1 : fromIndex + 1;
    this.primaryColumns.splice(fromIndex, 1);
    this.primaryColumns.splice(toIndex, 0, this.selectedColumns[0]);
    setTimeout(() => {
      this.primaryColumns.map(col => {
        if (col.name === colName) {
          col.checked = true;
        } else {
          col.checked = false;
        }
      });
      this.primaryColumns = this.primaryColumns.slice();
    }, 0);
  }
}

