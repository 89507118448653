/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { EnterpriseReplicationService } from '../../enterprise-replication.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { InformixServer } from '../../../informixServer';

interface Server {
  name: string;
}

export class ServerData {
  targetServers: Array<Server> = [];
  serverList: Array<Server>;
  serverName: string;

  constructor(isAddServer, serverName, serverData) {
    this.serverName = serverName;
    this.serverList = serverData.serverList;
    this.targetServers = serverData.targetServers;

    if (isAddServer) {
      this.addServerName();
    } else {
      this.removeServerName();
    }
  }

  addServerName() {
    if (this.serverName) {
      this.targetServers.push({ name: this.serverName });
    }
    this.serverList = this.serverList.filter(server => server.name !== this.serverName);
    setTimeout(() => {
      this.targetServers = this.targetServers.slice();
      this.serverList = this.serverList.slice();
    });
  }

  removeServerName() {
    if (this.serverName) {
      this.targetServers = this.targetServers.filter(server => server.name !== this.serverName);
      this.serverList.push({ name: this.serverName });
      setTimeout(() => {
        this.targetServers = this.targetServers.slice();
        this.serverList = this.serverList.slice();
      });
    }
  }
}

@Component({
  selector: 'app-check-replicate-modal',
  templateUrl: './check-replicate-modal.component.html'
})
export class CheckReplicateModalComponent implements OnInit {

  @ViewChild('checkReplicateModal') checkReplicateModal: ModalDirective;
  @Output() closeModal = new EventEmitter<any>();
  @Input() serverNames;
  @Input() replicate;

  modalRef: BsModalRef;
  server: InformixServer = null;
  formGroup: FormGroup;
  serverData: ServerData;
  isLoading: Boolean = false;
  firstPage: Boolean = true;
  isRepair: Boolean = false;
  isRepairRowsOnTimestamp: Boolean = false;
  isDelWinConflictResolution: Boolean = false;
  isAllTargetServers: Boolean = false;
  isSkipLob: Boolean = false;
  isPreventCheckTimeSeries: Boolean = false;
  isAvoidTimeseriesElements: Boolean = false;
  isProceed: Boolean = false;
  currentDate: Date;
  tomorrowsDate: Date;
  error: string = null;
  tasks = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.server = data.server;
    });

    this.currentDate = new Date();
    this.tomorrowsDate = new Date();
    this.tomorrowsDate.setDate(this.currentDate.getDate() + 1);

    this.formGroup = this.fb.group({
      taskName: new FormControl(null, Validators.required),
      extraTargetRows: new FormControl({ value: 'delete', disabled: false }, Validators.required),
      serverName: new FormControl(null, Validators.required),
      targetServerName: new FormControl(null),
      isFireTriggers: new FormControl('off'),
      inProgress: new FormControl(null),
      since: new FormControl(null, this.timeFormatValidator),
      timeFormat: new FormControl('M'),
      query: new FormControl(null),
      nameChecksumFunction: new FormControl(null)
    });
    this.getJobsData();
  }

  // Custom validator function for time format
  timeFormatValidator(control: FormControl) {
    const timeRegex = /^\d+$/;
    if (control.value && !timeRegex.test(control.value)) {
      const errors = { customError: 'Invalid time format' };
      control.setErrors(errors);
      return errors;
    }
    return null;
  }

  getJobsData() {
    this.isLoading = true;
    this.erService.getJobs(this.server.id).subscribe(res => {
      this.tasks = res;
      this.isLoading = false;
      this.setCheckJobDetails();
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  setCheckJobDetails() {
    this.serverData = new ServerData(true, null, { serverList: this.serverNames, targetServers: [] });
    this.replicate = this.replicate;
    this.formGroup.controls.taskName.setValue('HQ_' + this.replicate.replicateName);
    this.checkJobNameExist();
  }

  getFormData() {
    const form = this.formGroup.value;
    delete form.tagetServerName;
    const requestBody = {
      action: 'check',
      replicateName: this.replicate.replicateName,
      jobName: form.taskName,
      object: 'replicate',
      isRepair: this.isRepair,
      isRepairRowsOnTimestamp: this.isRepairRowsOnTimestamp,
      masterDataSource: form.serverName,
      fireTrigger: form.isFireTriggers,
      isSkipLob: this.isSkipLob,
      isExcludeTimeSeries: this.isPreventCheckTimeSeries,
      isIgnoreHiddenTSElements: this.isAvoidTimeseriesElements,
      isExecute: true
    };

    if (form.timeFormat === 'date_time') {
      requestBody['since'] = form.since;
    } else {
      if (form.since !== null) {
        requestBody['since'] = form.since + form.timeFormat;
      }
    }

    if (form.nameChecksumFunction) {
      requestBody['checksum'] = form.nameChecksumFunction;
    }

    if (form.query) {
      requestBody['whereClause'] = form.query;
    }

    if (form.inProgress) {
      requestBody['inProgress'] = parseInt(form.inProgress, 10);
    }

    if (this.isAllTargetServers) {
      if (this.serverNames && this.serverNames.length) {
        // let targetServers = this.serverNames.map(server => server.name);
        // requestBody['targetServersList'] = targetServers;
        requestBody['targetServersList'] = [];
      } else {
        return;
      }
    } else {
      if (this.serverData.targetServers && this.serverData.targetServers.length) {
        requestBody['targetServersList'] = this.serverData.targetServers.map(server => server.name);
      } else {
        return;
      }
    }

    if (!this.isRepair) {
      requestBody['extraTargetRows'] = form.extraTargetRows;
    }
    return requestBody;
  }

  repairRowsOnTimeStamp() {
    this.isRepairRowsOnTimestamp = !this.isRepairRowsOnTimestamp;
    if (this.isRepairRowsOnTimestamp) {
      this.formGroup.controls.extraTargetRows.disable();
    } else {
      this.formGroup.controls.extraTargetRows.enable();
    }
  }

  hideModal() {
    this.closeModal.emit();
  }

  addTargetServers() {
    const form = this.formGroup.value;
    if (form.targetServerName !== null) {
      this.serverData = new ServerData(true, form.targetServerName, this.serverData);
      this.error = null;
    }
  }

  removeTargetServer(index: number) {
    let serverName = this.serverData.targetServers[index].name;
    if (serverName) {
      this.serverData = new ServerData(false, serverName, this.serverData);
    }
  }

  checkJobNameExist() {
    const form = this.formGroup.value;
    if (form.taskName) {
      let taskDetails = this.tasks.find(task => task.name === form.taskName);
      if (taskDetails) {
        this.error = `This task name already exists. If you use this task name, 
        the new task will replace the existing task and its results.`;
        return true;
      } else {
        this.error = null;
        return false;
      }
    }
  }

  checkValidations() {
    if (this.formGroup.invalid) {
      if (this.formGroup.invalid && this.formGroup.controls.taskName.invalid) {
        this.error = 'Task Name should not be empty';
      }

      if (this.formGroup.invalid && this.formGroup.controls.serverName.invalid) {
        this.error = 'Server Name should not be empty, Please select server name.';
      }
      return true;
    }
    if (this.isAllTargetServers) {
      if (this.serverNames && this.serverNames.length === 0) {
        this.error = 'Target Server should not be empty, Please select and add target server name.';
        return true;
      }
    } else {
      if (this.serverData.targetServers && this.serverData.targetServers.length === 0) {
        this.error = 'Target Server should not be empty, Please select and add target server name.';
        return true;
      }
    }
    // allow duplicate jobname to override on double save
    if (this.checkJobNameExist() && !this.isProceed) {
      this.isProceed = true;
      return true;
    }

    return false;
  }

  save() {
    if (this.checkValidations()) {
      return;
    }
    this.isLoading = true;
    const requestData = this.getFormData();
    this.erService.checkOrSyncReplicate(this.server.id, requestData).subscribe((res: any) => {
      this.isLoading = false;
      const msg = 'Replicate check job created successfully';
      this.notificationsService.pushSuccessNotification(msg);
      this.hideModal();
      const queryParams = { replicate: this.replicate.replicateName };
      this.router.navigate(['dashboard/servers/' + this.server.id + '/replicate/check-sync'], { queryParams });
    }, err => {
      this.isLoading = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  allTargetServers() {
    this.isAllTargetServers = !this.isAllTargetServers;
    if (this.isAllTargetServers) {
      this.error = null;
    }
  }
}
