/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import {environment} from '../../../../environments/environment';

const productNameNoSpace = environment.productNameNoSpace;

export const alertConfigHelp: { [key: string]: string } = {
  email: `<p>
  Email notifications in ${productNameNoSpace} must be enabled <strong>for each individual user</strong>.
</p>
<p>
  As the system administrator, use this page to enable and configure email notifications at the system level.
  Then each ${productNameNoSpace} user who wants email notifications must enable it for their email address
  on their <strong>My Settings > Alerting Configuration</strong> page.
</p>`,

  twilio: `<p>
  Twilio notifications in ${productNameNoSpace} must be enabled <strong>for each individual user</strong>.
</p>
<p>
  As the system administrator, use this page to enable and configure Twilio notifications at the system level.
  Then each ${productNameNoSpace} user who wants Twilio notifications must enable it for their phone number
  on their <strong>My Settings > Alerting Configuration</strong> page.
</p>`,

  pagerduty: `<p>
  Pager Duty notifications in ${productNameNoSpace} is enabled <strong>globally</strong> by the system administrator.
</p>
<p>
  Use this page to turn on Pager Duty notifications globally. There are no individual user settings for Pager Duty.
  When enabled, all alerting incidents for all servers and groups in ${productNameNoSpace} will be sent to the Pager Duty URL
  for the given service key.
</p>`,

alertscript: `<p>
Script notifications in ${productNameNoSpace} provide an extensible way to integrate ${productNameNoSpace}'s alerting with any alerting
mechanism used by your organization.
</p>
<p>
Script notification is enabled <strong>globally</strong> by the system administrator.
</p>
<p>
Use this page to have ${productNameNoSpace} run a script whenever an alerting incident occurs on any server or 
group managed by ${productNameNoSpace}.
</p>`

};
