/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from './../serverBreadcrumb';
import { SqltraceService } from './sqltrace.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sqltrace-txn-info',
  templateUrl: 'sqltrace-txn-info.html'
})
export class SqltraceTransactionInfoComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private sqltraceBreadCrumb: BreadcrumbElement = {
    name: 'SQL Tracing',
    link: 'sqltrace'
  };
  private txnsBreadCrumb: BreadcrumbElement = {
    name: 'Transactions',
    link: 'sqltrace/transactions'
  };
  private txnInfoBreadCrumb: BreadcrumbElement = {
    name: 'Info',
  };

  server: InformixServer = null;

  sqlid: number = null;

  txnInfo: any = null;

  dataLoadErrorMessage: string = null;

  constructor(
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);

      this.route.params.subscribe(params => {
        if (params.sqlid) {
          this.sqlid = params.sqlid;
          this.getTransactionInfo();
        } else {
          console.error('missing sqlid');
        }
      });
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.sqltraceBreadCrumb, this.txnsBreadCrumb, this.txnInfoBreadCrumb]);
  }

  private getTransactionInfo() {
    this.sqltraceService.getTransactionInfo(this.server, this.sqlid).then(data => {
      this.txnInfo = data;
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace transaction info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }
}
