<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-4">
      <h4 *ngIf="reportMeta"><strong>{{reportMeta.name}}
        <i *ngIf="!reportMeta || !reportData" class="icon icon-circle-notch icon-spin ml-2"></i>
      </strong>
      </h4>
    </div>
    <div class="col-8" *ngIf="reportMeta && getDateUnit(reportMeta)">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
    <div class="col-12 mb-3" *ngIf="reportMeta?.id === 'table_activity'">
      <app-checkbox [checked]="isCatalog" (change)="includeCatalog($event)">
        {{ 'tablesIndexes.systemCatalogLabel' | translate }}
      </app-checkbox>
    </div>
  </div>
  
  <app-data-table *ngIf="reportMeta && reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
    <app-data-table-column *ngFor="let col of reportMeta.columns" [title]="col.name" [property]="col.id"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{this.getDataText(col, item)}}
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
