/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { UserPermission } from './userPermission';

export class User {
  public id: number;
  public name: string;
  public settings: any;
  public permissions: number;
  public password: string = null;
  public csrfToken: string;
  public locked: boolean;
  public isExpired: boolean;
  public accessPermissions: any;
  public daysLeftForPasswordToExpire: number;
  public roleExpiry: any;
  public rest_session_timeout_seconds: number;
  isFirstSignIn: boolean;

  constructor(json: any);
  constructor(name: string, password: string, locked: boolean, permissions: number, access_permissions: any, roleExpiry: any);
  constructor(nameOrJson: any, password?: string, locked?: boolean, permissions?: number, access_permissions?: any,
    roleExpiry?: any, isFirstSignIn?: boolean) {
    if (typeof nameOrJson === 'string') {
      this.name = nameOrJson;
      this.password = password;
      this.permissions = permissions;
      this.locked = locked;
      this.accessPermissions = access_permissions;
      this.roleExpiry = roleExpiry;
      this.isFirstSignIn = isFirstSignIn;
    } else {
      const json = nameOrJson as any;
      this.id = json.id;
      this.name = json.name;
      this.settings = json.settings;
      this.permissions = json.permissions;
      this.csrfToken = json.token || null;
      this.locked = json.locked;
      this.isExpired = json.isExpired;
      this.accessPermissions = json.access_permissions;
      this.roleExpiry = json.roleExpiry;
      this.rest_session_timeout_seconds = json.rest_session_timeout_seconds;
      this.isFirstSignIn = json.isFirstSignIn;
    }
  }

  public isSuperAdmin() {
    return this.hasPermissions(UserPermission.SUPERADMIN);
  }

  public hasPermissions(byte: number): boolean;
  public hasPermissions(permissions: number[]): boolean;
  public hasPermissions(arg: number | number[]): boolean {
    let byte: number;
    if (typeof arg === 'number') {
      byte = arg;
    } else {
      byte = arg.reduce((prev, current) => prev | current, 0);
    }

    return ((byte & this.permissions) === byte);
  }
}
