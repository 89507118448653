<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col">
      <h4>
        <strong>Transaction Info</strong>
      </h4>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Data is not available. {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div class="row mt-2" *ngIf="txnInfo">
    <div class="col">

      <h5 class="card-title font-weight-bold">
        Statistics
      </h5>
      <div class="row">

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">Transaction</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  Session ID
                </div>
                <div class="col">
                  {{txnInfo.sql_sid}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Run Time
                </div>
                <div class="col">
                  {{txnInfo.sql_runtime}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Statement Count
                </div>
                <div class="col">
                  {{txnInfo.sql_numstmt}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">Reads</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  Page
                </div>
                <div class="col">
                  {{txnInfo.sql_pgreads}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Buffer
                </div>
                <div class="col">
                  {{txnInfo.sql_bfreads}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Cache
                </div>
                <div class="col">
                  {{txnInfo.sql_rdcache}} %
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">Writes</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  Page
                </div>
                <div class="col">
                  {{txnInfo.sql_pgwrites}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Buffer
                </div>
                <div class="col">
                  {{txnInfo.sql_bfwrites}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Cache
                </div>
                <div class="col">
                  {{txnInfo.sql_wrcache}} %
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">I/O</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  IO Waits
                </div>
                <div class="col">
                  {{txnInfo.sql_numiowaits}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Wait Time
                </div>
                <div class="col">
                  {{txnInfo.sql_totaliowaits}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Avg Wait
                </div>
                <div class="col">
                  {{txnInfo.sql_avgiowaits}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">Optimizer</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  Estimated Cost
                </div>
                <div class="col">
                  {{txnInfo.sql_estcost}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Estimated Rows
                </div>
                <div class="col">
                  {{txnInfo.sql_estrows}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Actual Rows
                </div>
                <div class="col">
                  {{txnInfo.sql_actualrows}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">Locks</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  Requests
                </div>
                <div class="col">
                  {{txnInfo.sql_lockreq}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Waits
                </div>
                <div class="col">
                  {{txnInfo.sql_lockwaits}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Wait Time
                </div>
                <div class="col">
                  {{txnInfo.sql_lockwttime}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3 mb-2">
          <div class="card">
            <div class="card-header">Sorts</div>
            <div class="card-body">
              <div class="row">
                <div class="col text-right">
                  Disk
                </div>
                <div class="col">
                  {{txnInfo.sql_sortdisk}}
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  Memory
                </div>
                <div class="col">
                  {{txnInfo.sql_sortmem}}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="txnInfo && txnInfo.statements">
    <div class="col">
      <h5 class="card-title font-weight-bold">
        Statements
      </h5>
      <div class="table-responsive">
        <app-data-table [data]="txnInfo.statements" [rowsPerPage]="20" noDataAlertMessage="Transaction statements are not available.">
          <app-data-table-column property="sql_id">
            <ng-template appTableCell let-id="item">
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['../..',id,'profile']" [queryParams]="{path:'txn'}">
                <i class="icon icon-search"></i>
              </button>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="SQL ID" property="sql_id" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Completion Time" property="sql_finishtime" [sortable]="true">
            <ng-template appTableCell let-time="item">
              {{time * 1000 | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Run Time" property="sql_runtime" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Rows/Second" property="sql_rowspersec" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Rows Processed" property="sql_actualrows" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Lock Wait Time" property="sql_lockwttime" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="I/O Wait Time" property="sql_totaliowaits" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="SQL Statement" property="sql_statement" [sortable]="true">
          </app-data-table-column>
        </app-data-table>
      </div>

    </div>
  </div>
</div>
