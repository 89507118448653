<h4 class="card-title">Operating System Information</h4>

<ng-template [ngIf]="!server.hasMonitorPassword">
  <div class="alert alert-warning">
    Operating system information is not available. Monitoring credentials have not been supplied.
  </div>
</ng-template>

<ng-template [ngIf]="dataLoadErrorMessage">
  <div class="alert alert-danger">
    Unable to retrieve operating system information. <br>
    Error: {{dataLoadErrorMessage}}
  </div>
</ng-template>

<ng-template [ngIf]="server.hasMonitorPassword && !dataLoadErrorMessage">
  <div *ngIf="osInfo | async as osInfo; else loading">
    <div class="row">
      <div class="col-4 text-xl-right">OS Name:</div>
      <div class="col-8">{{osInfo.os_name}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">OS Release:</div>
      <div class="col-8">{{osInfo.os_release}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">OS Version:</div>
      <div class="col-8">{{osInfo.os_version}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">Host Name:</div>
      <div class="col-8">{{osInfo.os_nodename}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">Computer Type:</div>
      <div class="col-8">{{osInfo.os_machine}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">OS Total Memory:</div>
      <div class="col-8">{{osInfo.os_mem_total | byte}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">OS Free Memory:</div>
      <div class="col-8">{{osInfo.os_mem_free | byte}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">Total Processors:</div>
      <div class="col-8">{{osInfo.os_num_procs}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">Online Processors:</div>
      <div class="col-8">{{osInfo.os_num_olprocs}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">Page Size:</div>
      <div class="col-8">{{osInfo.os_pagesize}}</div>
    </div>
    <div class="row">
      <div class="col-4 text-xl-right">Maximum Open Files per Process:</div>
      <div class="col-8">{{osInfo.os_open_file_lim}}</div>
    </div>
  </div>
  <ng-template #loading>
    <div style="text-align: center"><i class="icon icon-circle-notch icon-spin"></i></div>
    <div *ngIf="osInfo">
    {{osInfo.errorMessage}}
  </div>
  </ng-template>
</ng-template>
