/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2022. All Rights Reserved
 *******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { InformixServer } from '../../../informixServer';
import { InformixTable } from '../../informix-table';
import { BasicIndexDetails, StorageScheme, XMLTags } from './../create-index.model';
import { ServerSpace } from '../../../storage/serverSpace';

@Component({
  selector: 'app-index-options',
  templateUrl: './index-options.component.html',
  styleUrls: ['../create-index.component.scss']
})
export class IndexOptionsComponent implements OnInit {
  @Input() server: InformixServer;
  @Input() dataObj: BasicIndexDetails;
  @Input() table: InformixTable;

  @Output() closeCreateIndex = new EventEmitter();
  @Output() backToCreateIndex = new EventEmitter();

  indexOptionsForm: UntypedFormGroup;
  creationType: UntypedFormControl;
  firstExtent: UntypedFormControl;
  nextExtent: UntypedFormControl;
  exptRows: UntypedFormControl;
  growthRate: UntypedFormControl;
  storageScheme: UntypedFormControl;
  subtrees: UntypedFormControl;
  listValue: UntypedFormControl;
  loadList: UntypedFormControl;
  filePath: UntypedFormControl;
  customWords: UntypedFormControl;
  tableName: UntypedFormControl;
  columnName: UntypedFormControl;

  isCompress = false;
  isCreateQuery = false;
  dbSpacesList: ServerSpace[];
  schemeDataObj: StorageScheme;
  isBackToIndex = false;
  createQueryClicked = false;
  isSchemeValid: boolean;
  isXMLPathProcessing = false;
  isIncludeNameSpaces = false;
  isIncludeSubtagText = false;
  xmlTags: XMLTags;

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.creationType = new UntypedFormControl('offline');
    this.firstExtent = new UntypedFormControl(null, [Validators.min(8), Validators.max(33554430)]);
    this.nextExtent = new UntypedFormControl(null, [Validators.min(8), Validators.max(33554430)]);
    this.exptRows = new UntypedFormControl(null, [Validators.min(0)]);
    this.growthRate = new UntypedFormControl('static');
    this.storageScheme = new UntypedFormControl('tableStorage');
    this.subtrees = new UntypedFormControl(2, [Validators.min(2), Validators.max(2147483647), Validators.required]);
    this.listValue = new UntypedFormControl('default');
    this.loadList = new UntypedFormControl('loadFromFile');
    this.filePath = new UntypedFormControl(null);
    this.customWords = new UntypedFormControl(null);
    this.tableName = new UntypedFormControl(null);
    this.columnName = new UntypedFormControl(null);

    this.indexOptionsForm = this.fb.group({
      creationType: this.creationType,
      firstExtent: this.firstExtent,
      nextExtent: this.nextExtent,
      exptRows: this.exptRows,
      growthRate: this.growthRate,
      storageScheme: this.storageScheme,
      subtrees: this.subtrees,
      listValue: this.listValue,
      loadList: this.loadList,
      filePath: this.filePath,
      customWords: this.customWords,
      tableName: this.tableName,
      columnName: this.columnName
    });
    this.setData();
  }

  setData() {
    let loadList = null;
    let listValue = null;
    let filePath = null;
    let customWords = null;
    let tableName = null;
    let columnName = null;
    if (this.dataObj.xmlTags || this.dataObj.stopWords) {
      const type = (this.dataObj.indexType === 'xml') ? this.dataObj.xmlTags : this.dataObj.stopWords;
      if (type) {
        if (type.useCustomized === 0) {
          listValue = 'default';
        } else {
          listValue = 'customize';
          if (type.useCustomized === 1) {
            loadList = 'loadFromFile';
            filePath = type.filePath;
          } else if (type.useCustomized === 2) {
            loadList = 'enterList';
            customWords = type.customWords;
          } else if (type.useCustomized === 3) {
            loadList = 'loadFromColumn';
            tableName = type.tableName;
            columnName = type.columnName;
          }
        }
      }
    }
    if (this.dataObj.indexType === 'fot') {
        this.dataObj.storageScheme = null;
      }
    this.indexOptionsForm.patchValue({
      creationType: !this.dataObj.isOnline ? 'offline' : 'online',
      firstExtent: !this.dataObj.firstExtent ? null : this.dataObj.firstExtent,
      nextExtent: !this.dataObj.nextExtent ? null : this.dataObj.nextExtent,
      exptRows: !this.dataObj.exptRows ? null : this.dataObj.exptRows,
      growthRate: !this.dataObj.growthRate ? 'static' : this.dataObj.growthRate,
      storageScheme: !this.dataObj.storageScheme ? 'tableStorage' : 'specifyStorage',
      schemeType: !this.dataObj.storageScheme ? null : this.dataObj.schemeType,
      subtrees: !this.dataObj.subtrees ? 2 : this.dataObj.subtrees,
      listValue: !listValue ? 'default' : listValue,
      loadList: !loadList ? 'loadFromFile' : loadList,
      tableName,
      columnName,
      filePath,
      customWords
    });
    this.isCompress = !this.dataObj.isCompressed ? false : true;
    this.isXMLPathProcessing = !this.dataObj.isXMLPathProcessing ? false : true;
    this.isIncludeNameSpaces = !this.dataObj.isIncludeNameSpaces ? false : true;
    this.isIncludeSubtagText = !this.dataObj.isIncludeSubtagText ? false : true;
  }

  viewQuery() {
    if (this.storageScheme.value === 'tableStorage') {
      this.schemeDataObj = null;
      this.setDataObj();
      this.isCreateQuery = true;
    } else {
      setTimeout(() => this.createQueryClicked = true);
    }
  }

  setDataObj() {
    this.dataObj.isOnline = this.creationType.value === 'online';
    this.dataObj.isCompressed = this.isCompress;
    this.dataObj.firstExtent = this.firstExtent.value;
    this.dataObj.nextExtent = this.nextExtent.value;
    this.dataObj.exptRows = this.exptRows.value;
    this.dataObj.growthRate = this.growthRate.value;
    this.dataObj.subtrees = this.subtrees.value;
    this.dataObj.storageScheme = this.schemeDataObj ? { ...this.schemeDataObj } : null;
    const isTypeBTS = (this.dataObj.indexType === 'bts');
    if (this.listValue.value === 'default') {
      isTypeBTS ? this.dataObj.stopWords = { useCustomized: 0 } : this.dataObj.xmlTags = { useCustomized: 0 };
    } else {
      if (this.loadList.value === 'loadFromFile') {
        isTypeBTS ? this.dataObj.stopWords = { useCustomized: 1, filePath: this.filePath.value } :
          this.dataObj.xmlTags = { useCustomized: 1, filePath: this.filePath.value };
      } else if (this.loadList.value === 'enterList') {
        isTypeBTS ? this.dataObj.stopWords = { useCustomized: 2, customWords: this.customWords.value } :
          this.dataObj.xmlTags = { useCustomized: 2, customWords: this.customWords.value };
      } else if (this.loadList.value === 'loadFromColumn') {
        isTypeBTS ? this.dataObj.stopWords = { useCustomized: 3, tableName: this.tableName.value, columnName: this.columnName.value } :
          this.dataObj.xmlTags = { useCustomized: 3, tableName: this.tableName.value, columnName: this.columnName.value };
      }
    }
    this.dataObj.isXMLPathProcessing = this.isXMLPathProcessing;
    this.dataObj.isIncludeNameSpaces = this.isIncludeNameSpaces;
    this.dataObj.isIncludeSubtagText = this.isIncludeSubtagText;
  }

  backCreateIndex() {
    this.isBackToIndex = true;
    this.setDataObj();
    if (this.storageScheme.value === 'tableStorage') {
      this.dataObj.storageScheme = null;
      this.backToCreateIndex.emit(this.dataObj);
    }
  }

  getSchemeData(schemeDataObj) {
    if (schemeDataObj.storageScheme) {
      this.schemeDataObj = { ...schemeDataObj.storageScheme };
      if (!this.isSchemeValid) {
        this.schemeDataObj = null;
      }
    } else {
      this.schemeDataObj = null;
    }
    this.setDataObj();
    if (schemeDataObj.pageType === 'createIndex') {
      this.backToCreateIndex.emit(this.dataObj);
    } else {
      setTimeout(() => {
        this.isCreateQuery = true;
        this.createQueryClicked = false;
      });
    }
  }

  doEstimate() {
    const rowSize = this.getRowSize();
    const nrows = Number(this.exptRows.value);
    const nextExtent = this.growthRateFunc(nrows, rowSize, this.growthRate.value, 1);
    const firstExtent = this.growthRateFunc(nrows, rowSize, this.growthRate.value, 0);
    this.firstExtent.setValue(firstExtent);
    this.nextExtent.setValue(nextExtent);
  }

  getRowSize() {
    let rowSize = this.dataObj.columnsList.length * 8; // 8 bytes
    this.dataObj.columnsList.forEach(column => {
      rowSize += Number(column.length);
    });
    return rowSize;
  }

  growthRateFunc(nrows: number, rowSize: number, growth, type: number) {
    let gr = 1; // growth rate.
    const min = 16; // minimum extent size : 16kb appears to be the default.
    let ngrowth = 1; // the growth of the next extent.
    let ret; // The return value.
    switch (growth) {
      case 'static':
        gr = 1;
        ngrowth = 0;
        break;
      case 'medium':
        ngrowth = 4;
        if (nrows >= 100000) {
          gr = 1.25;
        } else {
          gr = 1.125;
        }
        break;
      case 'aggressive':
        ngrowth = 2;
        if (nrows >= 100000) {
          gr = 1.5;
        } else {
          gr = 1.25;
        }
        break;
    }
    if (type === 0) {
      ret = Math.max(Math.ceil(Math.max((rowSize * (nrows * gr)) / 1010, min)), min);
    } else {
      if (ngrowth === 0) {
        ret = min;
      } else {
        ret = Math.max(Math.ceil(Math.max((rowSize * (nrows * gr)) / 1010, min) / ngrowth), min);
      }
    }
    if (ret % 2 !== 0) {
      ret++;
    }
    if (ret > 33554430) {
      ret = 33554430;
    }
    return Number(ret);
  }
}
