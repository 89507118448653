/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: 'date-time-picker.html',
  styleUrls: ['date-time-picker.scss']
})

export class DateTimePickerComponent implements OnInit {
  timeStampForm: UntypedFormGroup;
  fromDate: UntypedFormControl;
  fromTimeHH: UntypedFormControl;
  fromTimeMM: UntypedFormControl;
  toDate: UntypedFormControl;
  toTimeHH: UntypedFormControl;
  toTimeMM: UntypedFormControl;
  timeStamp = {
    fromDate: null, fromTimeHH: null, fromTimeMM: null, fromTimeStamp: null,
    toDate: null, toTimeHH: null, toTimeMM: null, toTimeStamp: null
  };
  @Input() previousTimeStamps: { 'fromTimeStamp': number; 'toTimeStamp': number } = null;
  @Output() updateTimeStampValues = new EventEmitter<any>();
  constructor(
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.fromDate = new UntypedFormControl(null);
    this.fromTimeHH = new UntypedFormControl({ value: '00', disabled: true });
    this.fromTimeMM = new UntypedFormControl({ value: '00', disabled: true });
    this.toDate = new UntypedFormControl(null);
    this.toTimeHH = new UntypedFormControl({ value: '00', disabled: true });
    this.toTimeMM = new UntypedFormControl({ value: '00', disabled: true });
    if (this.previousTimeStamps) {
      if (this.previousTimeStamps.fromTimeStamp) {
        const fromDateTime = new Date(this.previousTimeStamps.fromTimeStamp);
        this.fromDate.setValue(fromDateTime);
        this.fromTimeHH.setValue(fromDateTime.getHours());
        this.fromTimeMM.setValue(fromDateTime.getMinutes());
        this.fromTimeHH.enable();
        this.fromTimeMM.enable();
      }
      if (this.previousTimeStamps.toTimeStamp) {
        const toDateTime = new Date(this.previousTimeStamps.toTimeStamp);
        this.toDate.setValue(toDateTime);
        this.toTimeHH.setValue(toDateTime.getHours());
        this.toTimeMM.setValue(toDateTime.getMinutes());
        this.toTimeHH.enable();
        this.toTimeMM.enable();
      }
    }
    this.timeStampForm = this.formBuilder.group({
      fromDate: this.fromDate,
      fromTimeHH: this.fromTimeHH,
      fromTimeMM: this.fromTimeMM,
      toDate: this.toDate,
      toTimeHH: this.toTimeHH,
      toTimeMM: this.toTimeMM,
    });
    this.timeStampForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.fromDate.value !== null && isFinite(this.fromDate.value)) {
        this.timeStamp.fromTimeStamp = new Date(this.fromDate.value.toDateString() + ' ' +
          (this.fromTimeHH.value ? this.fromTimeHH.value : '00') + ':' + (this.fromTimeMM.value ? this.fromTimeMM.value : '00')).getTime();
        this.fromTimeHH.enable({ emitEvent: false });
        this.fromTimeMM.enable({ emitEvent: false });
      } else {
        this.timeStamp.fromTimeStamp = null;
        this.fromTimeHH.setValue('00', { emitEvent: false });
        this.fromTimeMM.setValue('00', { emitEvent: false });
        this.fromTimeHH.disable({ emitEvent: false });
        this.fromTimeMM.disable({ emitEvent: false });
      }
      if (this.toDate.value !== null && isFinite(this.toDate.value)) {
        this.timeStamp.toTimeStamp = new Date(this.toDate.value.toDateString() + ' ' +
          (this.toTimeHH.value ? this.toTimeHH.value : '00') + ':' + (this.toTimeMM.value ? this.toTimeMM.value : '00')).getTime();
        this.toTimeHH.enable({ emitEvent: false });
        this.toTimeMM.enable({ emitEvent: false });
      } else {
        this.timeStamp.toTimeStamp = null;
        this.toTimeHH.setValue('00', { emitEvent: false });
        this.toTimeMM.setValue('00', { emitEvent: false });
        this.toTimeHH.disable({ emitEvent: false });
        this.toTimeMM.disable({ emitEvent: false });
      }
      this.setTimeStamps();
      this.updateTimeStampValues.emit({ timeStamp: this.timeStamp, isTimeValid: this.timeStampForm.valid });
    });
  }

  setTimeStamps() {
    this.timeStamp.fromDate = this.fromDate.value;
    this.timeStamp.fromTimeHH = this.fromTimeHH.value;
    this.timeStamp.fromTimeMM = this.fromTimeMM.value;
    this.timeStamp.toDate = this.toDate.value;
    this.timeStamp.toTimeHH = this.toTimeHH.value;
    this.timeStamp.toTimeMM = this.toTimeMM.value;
  }

  reset() {
    this.timeStampForm.reset();
    this.setTimeStamps();
    this.updateTimeStampValues.emit({ timeStamp: this.timeStamp, isTimeValid: this.timeStampForm.valid });
  }
}
