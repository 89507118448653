/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceCache } from '../../../shared/resourceCache';
import { ServerHostInfo } from './server-host-info';

@Injectable()
export class InformixServerSystemService {

  private systemInfoCache = new ResourceCache<any>();

  constructor(private httpClient: HttpClient) { }

  public getServerSystemInfo(serverId: number): Promise<ServerHostInfo> {
    const systemInfoPromise = this.systemInfoCache.get(serverId.toString());
    if (systemInfoPromise) {
      return systemInfoPromise;
    }

    const promise = this.httpClient.get('informix/' + serverId + '/host').toPromise().then(json => {
      const osInfo = new ServerHostInfo(json);
      osInfo.id = serverId;
      return osInfo;
    });
    this.systemInfoCache.putPromise(serverId.toString(), promise);
    return promise;
  }
}
