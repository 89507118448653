<form role="form" [formGroup]="formGroup">
  <div class="ml-4">
    <h6>This action extends the selected chunk.</h6>
    <div class="form-group ml-4">
      <label><strong>Extend Size</strong></label>
      <div class="form-inline">
        <input formControlName="extendSize" class="form-control" type="number" min="1" tooltip="" appExtendedFormControl>
        <select formControlName="extendSizeUnit" class="form-control ml-2" appExtendedFormControl>
          <option *ngFor="let unit of unitOptions">{{unit}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row d-flex flex-row-reverse">
      <div class="col-sm-3 d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-2" (click)="doClose()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="doExtendChunk()" [disabled]="!formGroup.valid">Apply</button>
      </div>
    </div>
  </div>
</form>
