/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { ServerResolver } from '../../server-resolver.service';
import { SessionInfoComponent } from './session-info.component';
import { SessionsListComponent } from './sessions-list.component';
import { Route } from '@angular/router';

export const SessionsRoutes: Route[] = [
  {
    path: 'sessions',
    children: [
      {
        path: '',
        component: SessionsListComponent,
        resolve: {
          server: ServerResolver
        }
      },
      {
        path: ':sid',
        component: SessionInfoComponent,
        resolve: {
          server: ServerResolver
        }
      },
    ]
  }
];
