/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'path' })
export class PathPipe implements PipeTransform {
  transform(value: string, characters: number = 20): string {
    if (value.length <= characters) {
      return value;
    } else {
      return '...' + value.substr(characters * -1);
    }
  }
}
