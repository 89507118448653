/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnChanges, OnDestroy, Optional, SimpleChanges, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { ChartJSUtils } from '../../shared/chartjs.utils';
import { BytePipe } from '../../shared/pipes/byte.pipe';
import { InformixServer } from '../servers/informixServer';
import { InformixSensorService } from './informixSensor.service';
import { Sensor } from './sensor';
import { TimeSliceService } from './time-slice.service';
import { getTimeSlice, TimeSlice } from './timeSlices';
import { SensorDataSource } from './sensor-data-source';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { UserService } from '../../shared/user/user.service';
import { User } from '../../shared/user/user';

const downsampleChartWidthFactor = 0.5;

export interface ChartConfig {
  sensorId: string;
  title?: string;
  height?: number;
  type?: string;
  primaryKeyList?: string[];
  primaryKeySelect?: {
    label?: string;
    default?: string;
  };
  metricList?: string[];
  metricSelect?: {
    label?: string;
    default?: string;
  };
  stacked?: boolean;
  showTitle?: boolean;
  showControls?: boolean;
  showLegend?: boolean;
  colors?: string[];
}

interface ChartMetric {
  id: string;
  primaryKey?: string;
  metric: string;
  unit: string;
}

@Component({
  selector: 'app-sensor-chart-graph',
  templateUrl: 'sensor-chart-graph.html'
})
export class SensorChartGraphComponent implements OnChanges, OnDestroy {

  @Input() server: InformixServer;
  @Input() sensor: Sensor;
  @Input() config: ChartConfig;

  @ViewChild('chartContainer') chartContainer: ElementRef;

  chartHeight = 300;
  isLoading = true;
  selectedMetric: string = null;
  showMetricSelect = false;

  primaryKeys: string[] = null;
  selectedPrimaryKey: string = null;
  selectedPrimaryKeys: string[] = [];
  usePrimaryKeys = false;
  allPrimaryKeys: Boolean = false;

  private chartMetrics: ChartMetric[] = null;
  private chartDataX: Date[] = [];
  private chartDataY: { [key: string]: number[] } = {};
  private chart: Chart = null;
  private chartType = 'line';
  private chartStacked: boolean = null;
  private chartMinY: number = null;
  private chartMaxY: number = null;
  private chartInsertGap = false;
  private showLegend = true;
  private wasDestroyed = false;

  showControls = true;
  selectedTimeSlice: TimeSlice;
  isPaused = true;
  hasData = false;
  dataLastTimestamp: Date = null;
  repositoryOnline = true;
  serverError: string = null;
  sensorError: string = null;
  availableMetrics: any[] = null;
  sensorDataSource: SensorDataSource = null;

  private liveDataSubscription: Subscription = null;
  private timeSliceChangedSubscription: Subscription = null;
  private pauseDataSubscription: Subscription = null;
  private resumeDataSubscription: Subscription = null;

  constructor(
    private sensorService: InformixSensorService,
    @Optional() private timeSliceService: TimeSliceService,
    private notifications: NotificationsService,
    private userService: UserService
  ) {
    if (this.timeSliceService) {
      this.timeSliceChangedSubscription = this.timeSliceService.getTimeSliceChangedEventEmitter().subscribe((timeSlice: TimeSlice) => {
        this.setTimeSlice(timeSlice);
      });
      this.pauseDataSubscription = this.timeSliceService.getPauseEventEmitter().subscribe(() => {
        this.pauseData();
      });
      this.resumeDataSubscription = this.timeSliceService.getResumeEventEmitter().subscribe(() => {
        this.resumeData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && this.config) {
      if (this.config.metricSelect) {
        this.showMetricSelect = true;
        this.selectedMetric = this.config.metricSelect.default || null;
      } else {
        this.showMetricSelect = false;
      }

      if (this.config.showControls != null) {
        this.showControls = this.config.showControls;
      }

      if (this.config.showLegend != null) {
        this.showLegend = this.config.showLegend;
      }

      if (this.config.height != null) {
        this.chartHeight = this.config.height;
      }
    }

    if (changes.sensor && this.sensor) {
      this.sensorDataSource = this.sensorService.getSensorDataSource(this.server, this.sensor);

      this.usePrimaryKeys = !!(this.sensor.type.meta && this.sensor.type.meta.default && this.sensor.type.meta.default.primaryKey);

      this.userService.getCurrentUser().then((user: User) => {
        this.selectedTimeSlice = getTimeSlice(user.settings.timeSliceName);
        if (this.usePrimaryKeys) {
          this.getSensorDataMetaData();
        } else {
          this.processSensorMetadata();
        }
      }).catch(err => {
        this.notifications.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
      });
    }
  }

  private getSensorDataMetaData() {
    return this.sensorDataSource.getMetaData().then(metadata => {
      this.sensorError = null;
      // Grab the primary key list from the metadata
      if (metadata.primaryKeys) {
        this.primaryKeys = metadata.primaryKeys;

        // If a primary key list is given through the config
        // calculate its intersection with the primary key list from the metadata
        if (this.config.primaryKeyList) {
          const filteredKeys: string[] = [];
          this.config.primaryKeyList.forEach(pkey => {
            if (this.primaryKeys.indexOf(pkey) > -1) {
              filteredKeys.push(pkey);
            }
          });

          // If the intersection has elements, use it
          // Otherwise the primary key list from the metadata will be used
          if (filteredKeys.length > 0) {
            this.primaryKeys = filteredKeys;
          }
        }
        if (this.config.primaryKeySelect && !this.selectedPrimaryKeys.length) {
          this.selectedPrimaryKeys = [this.primaryKeys[0]];
        }
      }
      this.processSensorMetadata();
    }).catch(err => {
      // It is possible that the sensor was just created and no metadata is available
      // Subscribe to live data from the sensor anyway, hoping to get something when
      // the sensor captures its first data...
      // If, eventually, some data is received, make another request for metadata

      // TODO: Replace this mess with a proper system that allows charts to subscribe to metadata
      this.isLoading = false;
      let errorMsg = err;

      // Hanlding { error: { err: 'SOME_ERROR' } } error in the http error response.
      if(typeof err === 'object') {
        errorMsg = err.error.err;
      }
      this.sensorError = errorMsg;

      this.liveDataSubscription = this.sensorDataSource.getLiveData(null, null).subscribe(data => {
        this.liveDataSubscription.unsubscribe();
        this.getSensorDataMetaData().then(() => {
          this.addData(data);
        });
      });
    });
  }

  private processSensorMetadata() {
    const meta = this.sensor.type.meta;
    this.chartMetrics = [];

    if (meta.chart) {
      if (this.config.type) {
        this.chartType = this.config.type;
      } else if (meta.chart.type) {
        this.chartType = meta.chart.type;
      }

      if (this.config.stacked != null) {
        this.chartStacked = this.config.stacked;
      } else if (meta.chart.stacked) {
        this.chartStacked = meta.chart.stacked;
      }

      if (typeof meta.chart.minY === 'number') {
        this.chartMinY = meta.chart.minY;
      }
      if (typeof meta.chart.maxY === 'number') {
        this.chartMaxY = meta.chart.maxY;
      }
    }

    this.availableMetrics = [];
    if (meta.metrics) {
      let metricIds = this.config.metricList;
      if (!metricIds) {
        metricIds = [];
        for (const metricId in meta.metrics) {
          if (meta.metrics.hasOwnProperty(metricId)) {
            metricIds.push(metricId);
          }
        }
      }

      for (let i = 0; i < metricIds.length; i++) {
        const metricId = metricIds[i];
        const metricMeta = meta.metrics[metricId];

        if (!metricMeta || metricMeta.showChart === false) {
          continue;
        }

        this.availableMetrics.push({ id: metricId, name: metricMeta.name });

        if (!this.usePrimaryKeys) {
          this.chartMetrics.push({
            id: metricId,
            metric: metricMeta.name,
            unit: metricMeta.unit
          });
        } else {
          for (let k = 0; k < this.primaryKeys.length; k++) {
            this.chartMetrics.push({
              id: this.primaryKeys[k] + '_' + metricId,
              primaryKey: this.primaryKeys[k],
              metric: metricMeta.name,
              unit: metricMeta.unit
            });
          }
        }
      }

      if (this.chartType === 'line') {
        this.chartInsertGap = true;
      }
    }

    const now = (new Date()).getTime();
    this.getHistoricalData(now - this.selectedTimeSlice.value, now).then(() => {
      if (!this.timeSliceService || !this.timeSliceService.isDataPaused()) {
        this.resumeData();
      }
    });
  }

  private initChart() {
    if (this.chart) {
      return;
    }

    this.chartDataX = [];
    this.chartDataY = {};
    const units: string[] = [];
    this.chartMetrics.forEach(series => {
      this.chartDataY[series.id] = [];

      if (series.unit) {
        units.push(series.unit);
      }
    });

    // Determine if there is a common unit for all metrics
    let unit: string = null;
    if (units.length > 0 && units.length === this.chartMetrics.length) {
      unit = units[0];
      units.forEach(value => {
        if (value !== unit) {
          unit = null;
        }
      });
    }

    const chartConfiguration: any = {
      type: this.chartType,
      data: {
        labels: this.chartDataX,
        datasets: this.buildChartDataSets()
      },
      options: {
        animation: {
          duration: 0
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              ticks: {
                source: 'labels',
                autoSkipPadding: 10,
                maxRotation: 0,
                autoSkip: true,
              }
            }
          ],
          yAxes: [
            {
              distribution: 'series',
              stacked: this.chartStacked,
              ticks: {
                autoSkipPadding: 10,
                autoSkip: true,
                maxRotation: 0
              }
            }
          ]
        },
        legend: {
          display: this.showLegend
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              let value;
              if (label) {
                label += ': ';
                if (unit === 'bytes') {
                  value = new BytePipe().transform(tooltipItem.yLabel);
                } else {
                  value = Number.parseFloat(tooltipItem.yLabel.toFixed(3));
                }
                label += value;
              }
              return label;
            }
          }
        }
      }
    };

    if (unit === 'bytes') {
      const bytePipe: BytePipe = new BytePipe();
      chartConfiguration.options.scales.yAxes[0].beforeBuildTicks = ChartJSUtils.byteAxisBeforeBuildTicks;
      chartConfiguration.options.scales.yAxes[0].ticks.callback = (value: any) => bytePipe.transform(value);
    }

    if (this.chartMinY) {
      chartConfiguration.options.scales.yAxes[0].ticks.min = this.chartMinY;
    }
    if (this.chartMaxY) {
      chartConfiguration.options.scales.yAxes[0].ticks.max = this.chartMaxY;
    }

    if(unit === 'percentage') {
      chartConfiguration.options.scales.yAxes[0].ticks.min = 0;
      chartConfiguration.options.scales.yAxes[0].ticks.max = 100;
    }

    this.chart = new Chart(this.chartContainer.nativeElement, chartConfiguration);
  }

  private buildChartDataSets(): Chart.ChartDataSets[] {
    const chartMetricMap: { [key: string]: ChartMetric } = {};
    this.chartMetrics.forEach(metric => {
      chartMetricMap[metric.id] = metric;
    });

    const metrics = this.selectedMetric ? [this.selectedMetric] : this.availableMetrics.map(am => am.id);
    let metricIds: string[] = [];
    if (this.usePrimaryKeys) {
      const primaryKeys = this.selectedPrimaryKeys && this.selectedPrimaryKeys.length
                            && !this.allPrimaryKeys ? this.selectedPrimaryKeys : this.primaryKeys;
      primaryKeys.forEach(pkey => {
        metrics.forEach(metric => {
          metricIds.push(pkey + '_' + metric);
        });
      });
    } else {
      metricIds = metrics;
    }

    return metricIds.map((metricId, index) => {
      const series = chartMetricMap[metricId];
      const color =
        (this.config.colors && index < this.config.colors.length) ? this.config.colors[index] : ChartJSUtils.getDefaultColor(index);

      let label = '';
      if (this.usePrimaryKeys && (this.primaryKeys.length > 1 || this.availableMetrics.length === 1)) {
        label = series.primaryKey;
      }

      if (!this.showMetricSelect && (!this.usePrimaryKeys || this.availableMetrics.length > 1)) {
        label += ' ' + series.metric;
      }

      if (label.length === 0) {
        label = series.metric;
      }

      return {
        label: label.trim(),
        data: this.chartDataY[series.id],
        borderColor: color,
        backgroundColor: color,
        fill: this.chartStacked,
        pointRadius: 2,
        lineTension: 0
      };
    });
  }

  private buildChartMetricsFromDatapoint(dataPoint: { [key: string]: number }) {
    this.chartMetrics = [];
    for (const key in dataPoint) {
      if (!this.usePrimaryKeys) {
        this.chartMetrics.push({
          id: key,
          metric: key,
          unit: null
        });
      } else {
        for (let i = 0; i < this.primaryKeys.length; i++) {
          this.chartMetrics.push({
            id: this.primaryKeys[i] + '_' + key,
            primaryKey: this.primaryKeys[i],
            metric: key,
            unit: null
          });
        }
      }
    }
  }

  private destroyChart() {
    if (!this.chart) {
      return;
    }

    this.chart.destroy();
    this.chart = null;
    this.chartDataX = [];
    this.chartDataY = {};
  }

  private trimData() {
    if (this.chartDataX.length < 1) {
      return;
    }

    const timestamp = this.chartDataX[this.chartDataX.length - 1].getTime() - this.selectedTimeSlice.value;

    const index = this.chartDataX.findIndex(value => value.getTime() > timestamp);
    if (index > 1) {
      this.chartDataX.splice(0, index);
      for (const key in this.chartDataY) {
        if (this.chartDataY.hasOwnProperty(key)) {
          this.chartDataY[key].splice(0, index);
        }
      }
    }
  }

  private getMaxDatapoints(): number {
    if (this.chartContainer) {
      return ((this.chartContainer.nativeElement as HTMLElement).parentElement.clientWidth) * downsampleChartWidthFactor;
    } else {
      return 100;
    }
  }

  private getHistoricalData(fromTimestamp: number, toTimestamp: number): Promise<any> {
    this.isLoading = true;

    return this.sensorDataSource.getData(fromTimestamp, toTimestamp, this.getMaxDatapoints(), this.getPrimaryKeyFilter()).then(data => {
      this.repositoryOnline = true;

      if (this.chart && !this.wasDestroyed) {
        this.chartDataX.splice(0, this.chartDataX.length);
        for (const key in this.chartDataY) {
          if (this.chartDataY.hasOwnProperty(key)) {
            this.chartDataY[key].splice(0, this.chartDataY[key].length);
          }
        }
      }

      this.addData(data, false);
      if (this.chart && !this.wasDestroyed) {
        this.chart.update();
      }
    }).catch((err: HttpErrorResponse) => {
      const error = err.error ? err.error.err : err;
      if (error && error.indexOf('Cannot connect to repository database') !== -1) {
        this.serverError = null;
        this.repositoryOnline = false;
      } else if (error && !(error.indexOf('Sensor data not found') !== -1 || error.status === 404)) {
        this.serverError = error;
        this.repositoryOnline = false;
      }
    }).then(() => {
      this.isLoading = false;
    });
  }

  private addData(data: any[], updateChart = true) {
    if (!data || data.length < 1) {
      return;
    }

    if (!this.chartMetrics) {
      for (const key in data[0].data) {
        if (data[0].data.hasOwnProperty(key)) {
          this.buildChartMetricsFromDatapoint(data[0].data[key]);
          break;
        }
      }
    }

    this.hasData = true;
    if (!this.chart) {
      this.initChart();
    }

    const firstTimestamp = this.chartDataX.length > 0 ? this.chartDataX[0].getTime() : Infinity;

    // If there's data that is older than what we have...
    if (data[0].timestamp < firstTimestamp) {

      for (let i = 0; i < data.length; i++) {
        if (data[i].timestamp >= firstTimestamp) {
          break;
        }

        if (this.chartInsertGap && data.length > 1 && i > 0) {
          let prevDelta: number; let currentDelta: number;
          if (i === 1 && data.length > 2) {
            prevDelta = data[1].timestamp - data[2].timestamp;
            currentDelta = data[0].timestamp - data[1].timestamp;
          } else if (i - 2 >= 0) {
            prevDelta = data[i - 2].timestamp - data[i - 1].timestamp;
            currentDelta = data[i - 1].timestamp - data[i].timestamp;
          }

          if (currentDelta > prevDelta * 1.5) {
            this.chartDataX.unshift(new Date(data[i].timestamp + 1));
            for (const key in this.chartDataY) {
              if (this.chartDataY.hasOwnProperty(key)) {
                this.chartDataY[key].unshift(null);
              }
            }
          }
        }

        this.chartDataX.unshift(new Date(data[i].timestamp));

        for (const key in this.chartDataY) {
          if (this.chartDataY.hasOwnProperty(key)) {
            this.chartDataY[key].unshift(null);
          }
        }

        for (const pkey in data[i].data) {
          if (data[i].data.hasOwnProperty(pkey)) {
            const metrics = data[i].data[pkey];

            const pkeyPrefix = this.usePrimaryKeys ? pkey + '_' : '';
            for (const metricId in metrics) {
              if (metrics.hasOwnProperty(metricId)) {
                const seriesId = pkeyPrefix + metricId;
                if (!this.chartDataY[seriesId]) {
                  continue;
                }

                this.chartDataY[seriesId][0] = metrics[metricId];
              }
            }
          }
        }
      }
    }

    const lastTimestamp = this.chartDataX.length > 0 ? this.chartDataX[this.chartDataX.length - 1].getTime() : -Infinity;

    // If there's data that is newer than what we have...
    if (data[data.length - 1].timestamp > lastTimestamp) {

      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].timestamp <= lastTimestamp) {
          break;
        }

        // If there is a time gamp, determine if we need to add a null data point
        if (this.chartInsertGap && this.chartDataX.length > 0 && data[i].timestamp - this.chartDataX[this.chartDataX.length - 1].getTime()
          > this.sensor.runInterval * 1500) {
          this.chartDataX.push(new Date(data[i].timestamp - 1));
          for (const key in this.chartDataY) {
            if (this.chartDataY.hasOwnProperty(key)) {
              this.chartDataY[key].push(null);
            }
          }
        }

        this.chartDataX.push(new Date(data[i].timestamp));

        for (const key in this.chartDataY) {
          if (this.chartDataY.hasOwnProperty(key)) {
            this.chartDataY[key].push(null);
          }
        }

        for (const pkey in data[i].data) {
          if (data[i].data.hasOwnProperty(pkey)) {
            const metrics = data[i].data[pkey];

            const pkeyPrefix = this.usePrimaryKeys ? pkey + '_' : '';
            for (const metricId in metrics) {
              if (metrics.hasOwnProperty(metricId)) {
                const seriesId = pkeyPrefix + metricId;
                if (!this.chartDataY[seriesId]) {
                  continue;
                }

                this.chartDataY[seriesId][this.chartDataY[seriesId].length - 1] = metrics[metricId];
              }
            }
          }
        }
      }
    }

    this.trimData();
    this.dataLastTimestamp = this.chartDataX[this.chartDataX.length - 1];

    if (!this.wasDestroyed && updateChart) {
      this.chart.update();
    }
  }

  public pauseData() {
    if (this.isPaused) {
      return;
    }

    this.isPaused = true;
    if (this.liveDataSubscription) {
      this.liveDataSubscription.unsubscribe();
      this.liveDataSubscription = null;
    }
  }

  public resumeData() {
    if (!this.isPaused) {
      return;
    }

    this.isPaused = false;

    let fromTimestamp: number = null;
    if (this.dataLastTimestamp) {
      fromTimestamp = this.dataLastTimestamp.getTime();
    }

    this.liveDataSubscription = this.sensorDataSource.getLiveData(this.getPrimaryKeyFilter(), fromTimestamp).subscribe(data => {
      this.serverError = null;
      this.repositoryOnline = true;
      this.addData(data);
    }, (error: HttpErrorResponse) => {
      const err = error.error ? error.error.err : error;
      if (err && err.indexOf('Cannot connect to repository database') !== -1) {
        this.serverError = null;
        this.repositoryOnline = false;
      } else if (err && !(err.indexOf('Sensor data not found') !== -1 || err.status === 404)) {
        this.serverError = err;
        this.repositoryOnline = false;
      }
    });
  }

  public setTimeSlice(newValue: TimeSlice) {
    if (this.selectedTimeSlice === newValue) {
      return;
    }
    this.selectedTimeSlice = newValue;

    const now = new Date().getTime();
    this.getHistoricalData(now - this.selectedTimeSlice.value, now);
  }

  public changeSelectedMetric(newValue: string) {
    this.selectedMetric = newValue;
    if (this.chart) {
      this.chart.config.data.datasets = this.buildChartDataSets();
      if (!this.wasDestroyed) {
        this.chart.update();
      }
    }
  }

  public onAllPrimaryKeysCheckBoxChange(value: boolean) {
    this.allPrimaryKeys = value;
    this.updateChart();
  }

  public changeSelectedPrimaryKey(selectedPrimaryKeys: any) {
    this.selectedPrimaryKeys = selectedPrimaryKeys;
    this.updateChart();
  }

  public updateChart() {
    const wasPaused = this.isPaused;
    this.pauseData();
    this.destroyChart();

    const now = new Date().getTime();
    this.getHistoricalData(now - this.selectedTimeSlice.value, now).then(() => {
      if (!wasPaused) {
        this.resumeData();
      }
    });
  }

  private getPrimaryKeyFilter(): string[] {
    return this.selectedPrimaryKeys && this.selectedPrimaryKeys.length
            && !this.allPrimaryKeys ? this.selectedPrimaryKeys : this.primaryKeys;
  }

  ngOnDestroy() {
    this.wasDestroyed = true;
    if (this.liveDataSubscription) {
      this.liveDataSubscription.unsubscribe();
      this.liveDataSubscription = null;
    }
    if (this.timeSliceChangedSubscription) {
      this.timeSliceChangedSubscription.unsubscribe();
      this.timeSliceChangedSubscription = null;
    }
    if (this.pauseDataSubscription) {
      this.pauseDataSubscription.unsubscribe();
      this.pauseDataSubscription = null;
    }
    if (this.resumeDataSubscription) {
      this.resumeDataSubscription.unsubscribe();
      this.resumeDataSubscription = null;
    }
  }
}
