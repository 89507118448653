<div #queryTreeContainer class="main-container ml-0 overflow-auto d-flex" [style.height.px]="maxHeight">
<app-tree-view *ngIf="sqltraceIterators && iteratorTreeRootNode" [node]="iteratorTreeRootNode" class="m-auto">
  <ng-template appTreeNodeTemplate let-node="node">
    <div class="card" style="width: 250px; margin: 0 auto">
      <div class="card-header">
        {{node.sql_itr_id}}. {{node.sql_itr_info}}
      </div>
      <div class="card-body m-0">
        <div *ngIf="node.sql_itr_table" class="row">
          <div class="col">
            {{(node.sql_itr_info.indexOf('Index') >= 0)? "Index" : "Table"}}
          </div>
          <div class="col text-right">
            {{node.sql_itr_table}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Cost
          </div>
          <div class="col text-right">
            {{node.sql_itr_cost}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Estimated rows
          </div>
          <div class="col text-right">
            {{node.sql_itr_estrows}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Actual rows
          </div>
          <div class="col text-right">
            {{node.sql_itr_numrows}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Elapsed Time
          </div>
          <div class="col text-right">
            {{node.sql_itr_time.toFixed(4)}}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-tree-view>
</div>
