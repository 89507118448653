<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="serverGroup">
  <ng-container *ngIf="server.permissions.admin; else noPermissions">
    <h3>Setup</h3>
    <tabset>
      <tab heading="Server" [active]="!isAgentTabActive">
        <div class="mt-4">
          <app-server-info-form [editServer]="server" (serverUpdate)="onServerUpdate($event)"></app-server-info-form>
        </div>
      </tab>
      <tab heading="Agent" (selectTab)="onAgentTabSelect()" (deselect)="onAgentTabDeselect()"
        [active]="isAgentTabActive">
        <div class="mt-4" *ngIf="showAgentInfo">
          <app-agent-info [server]="server"></app-agent-info>
        </div>
      </tab>
    </tabset>
  </ng-container>
  <ng-template #noPermissions>
    <div class="alert alert-danger">
      <i class="icon icon-lock"></i> You do not have the required permissions to view this page
    </div>
  </ng-template>
</div>
