<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
    <div class="row mb-2">
        <div class="col-6">
            <h4>
                <strong>Check or Sync Tasks</strong>
                <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
            </h4>
        </div>
        <div class="col-6 form-inline justify-content-end">
            <a routerLink="/dashboard/servers/{{server.id}}/replicate" class="btn btn-sm btn-secondary ml-2">
                Back to replicate
            </a>
            <button id="refresh-button" type="button" class="btn btn-sm btn-primary ml-2" (click)="getReplicateName()">
                <i class="icon icon-refresh" title="Refresh"></i>
            </button>
        </div>
    </div>

    <!-- <div class="row" *ngIf="tasks && tasks.length">
        <div class="col-8">
            <div class="alert alert-info">
                <i class="icon icon-info icon-info-circle text-info mr-2"></i>
                Data synchronization and repair success, progress report task created.
            </div>
        </div>
    </div> -->

    <div>
        <app-data-table *ngIf="tasks && !isLoading" class="table-responsive" [enableFilter]="false" [data]="tasks"
            [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
            <app-data-table-column title="Task name" property="name">
                <ng-template appTableCell let-task="row">
                    <a href="javascript:;" (click)="showTaskDetails(task)" class="">
                        {{task.name}}
                    </a>
                </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Type" property="type">
                <ng-template appTableCell let-task="row">
                    {{task.type === 'C' ? 'Check' : 'Sync'}}
                </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Replicate" property="replicate">
            </app-data-table-column>
            <app-data-table-column title="Start time" property="time_started">
                <ng-template appTableCell let-task="row">
                    {{task.time_started?.$date  | date:'short'}}
                </ng-template>
            </app-data-table-column>
            <app-data-table-column title="End time" property="time_completed">
                <ng-template appTableCell let-task="row">
                    {{task.time_completed?.$date  | date:'short'}}
                </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Task status" property="status">
                <ng-template appTableCell let-task="row">
                    <div class=" font-weight-bold" [ngClass]="'color-' + statusMap[task.status].color" >
                        {{statusMap[task.status].name}}
                    </div>
                </ng-template>
            </app-data-table-column>
            <app-data-table-column title="" property="">
                <ng-template appTableCell let-task="row">
                    <button id="refresh-button" type="button" class="btn btn-sm btn-icon" tooltip="delete"
                        (click)="confirmDelete(task)">
                        <i class="icon icon-trash" title="delete"></i>
                    </button>
                    <!-- <button class="btn btn-sm btn-secondary" *ngIf="task.status === ''" (click)="run(task.name)">
                        Run
                    </button> -->
                </ng-template>
            </app-data-table-column>
        </app-data-table>
    </div>
</div>

<!-- Define Replicate modal -->
<div bsModal #taskModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">{{taskName}}</h5>
                <button type="button" class="close" (click)="taskModal.hide()">
                    <i class="icon icon-times"></i>
                </button>
            </div>

            <div class="modal-body">
                <form [formGroup]="taskModalForm">
                    <div class="row mt-4">
                        <div class="col">
                            <div class="font-weight-bold mb-2"><span class="text-danger">* </span>Delete a named task</div>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="" formControlName="deleteNamedTask"
                                    title="Delete Named task" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col">
                            <div class="font-weight-bold mb-2"><span class="text-danger">* </span>Repeat(Seconds)</div>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="" formControlName="repeat"
                                    title="Repeat(Seconds)" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-4">
                        <div class="col pt-1">
                            <app-checkbox [checked]="showIncosistentValues"
                                (change)="showIncosistentValues = !showIncosistentValues;">
                                Show specific inconsistent values in the consistency report.
                            </app-checkbox>
                        </div>

                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <div class="float-left">
                    <button type="button" class="btn btn-secondary" (click)="taskModal.hide()">Close</button>
                </div>
                <div class="float-right">
                    <button type="button" class="btn btn-primary ml-3" (click)="runTask()">Run</button>
                    <!-- <button type="button" class="btn btn-primary ml-3" (click)="runTask()" [disabled]="taskModalForm.invalid">Run</button> -->
                </div>
            </div>
        </div>
    </div>
</div>
