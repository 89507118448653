<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-md-8">
      <h4 class="page-header">
        <strong>Threads</strong>
        <app-data-source-toggle class="ml-2" [viewMonitoredData]="false" (viewMonitoredDataStateChange)="viewLiveData = !$event"></app-data-source-toggle>
        <i *ngIf="server.hasMonitorPassword && !threads && !dataLoadErrorMessage" class="icon icon-circle-notch icon-spin ml-2"></i>
      </h4>
      <ng-container *ngIf="!dataLoadErrorMessage">
        <small *ngIf="viewLiveData">You are currently viewing live data.</small>
        <small *ngIf="!viewLiveData">You are currently viewing monitored data from the sensor.</small>
      </ng-container>
    </div>
    <div class="col-md-4 text-right">
      <button *ngIf="viewLiveData" id="refresh-button" type="button" class="btn btn-primary" (click)="refreshData()" title="Refresh">
        <i class="icon icon-refresh"></i>
      </button>
      <app-sensors-required-control *ngIf="!viewLiveData" [server]="server" [monitoringProfile]="monitoringProfile" [sensorList]="['thread_counts']"
        (monitoringProfileUpdated)="getMonitoringProfile()"></app-sensors-required-control>
    </div>
  </div>

  <div *ngIf="viewLiveData" class="mt-2">

      <h4 class="page-header">
        Threads List
      </h4>

      <ng-template [ngIf]="dataLoadErrorMessage">
        <div class="alert alert-danger">
          Unable to retrieve thread information. <br> Error: {{dataLoadErrorMessage}}
        </div>
      </ng-template>

        <div class="table-responsive" *ngIf="threads">
        <app-data-table [data]="threads" sortBy="tid" [sortDescending]="false" [rowsPerPage]="20" searchLabel="Search name or state ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
          <app-data-table-column title="ID" property="tid" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Address" property="address" [sortable]="true">
            <ng-template appTableCell let-address="item">
              {{(address).toString(16)}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="State" property="statedesc" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="State Detail" property="statedetail" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="Last Run Time" property="last_run_time" [sortable]="true">
            <ng-template appTableCell let-time="item">
              {{time * 1000 | date:'y-MM-dd HH:mm:ss'}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column class="text-right" title="CPU Time" property="cpu_time" [sortable]="true">
            <ng-template appTableCell let-cputime="item">
              {{cputime.toFixed(3)}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column class="text-right" title="Number of Schedules" property="num_sched" [sortable]="true">
          </app-data-table-column>
        </app-data-table>
      </div>
    
  </div>

  <div *ngIf="!viewLiveData" class="mt-2">
    <app-sensor-chart [server]="server" [config]="{sensorId: 'thread_counts'}">
    </app-sensor-chart>
  </div>

</div>
