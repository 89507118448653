/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/
import { AfterViewInit, Component, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataTableComponent } from '../../shared/data-table/data-table.component';
import { CanComponentDeactivate } from '../../shared/canDeactivateGuard.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { UserService } from '../../shared/user/user.service';
import { InformixTreeItem } from '../informixTreeItem';
import { InformixServer } from '../servers/informixServer';
import { MonitoringService } from './monitoring.service';
import { MonitoringProfile, SensorList } from './monitoringProfile';
import { Sensor, SensorType } from './sensor';
import { ActivatedRoute } from '@angular/router';

export class SensorListItem extends Sensor {

  public isNew: boolean;
  public isOverriding: boolean;
  public isModified: boolean;

  constructor(sensorOrType: Sensor | SensorType) {
    if (sensorOrType instanceof Sensor) {
      super(sensorOrType);
    } else {
      const type = sensorOrType;
      let runInterval: number = type.meta.default.sleepBetweenExecution;
      const scanDuration = type.meta.default.scanDuration;
      if (typeof scanDuration === 'number') {
        runInterval += scanDuration;
      }
      const dataRetentionInterval = type.meta.default.dataRetentionInterval;

      super({
        type,
        runInterval,
        dataRetentionInterval,
        disabled: false
      });
    }

    this.isNew = false;
    this.isOverriding = false;
    this.isModified = false;
  }
}

class InheritedSensorListItem extends Sensor {
  public isOverridden = false;
}

@Component({
  selector: 'app-monitoring-profile',
  templateUrl: 'monitoringProfile.html'
})
export class MonitoringProfileComponent implements OnChanges, AfterViewInit, CanComponentDeactivate {

  @Input() owner: InformixTreeItem;
  selectedSensors: SensorListItem[] = [];
  selectedInheritedSensors: InheritedSensorListItem[] = [];
  parentProfile: MonitoringProfile;
  profile: MonitoringProfile;
  server: InformixServer = null;
  isSelectAll = false;
  dynamicHeight: any;
  @ViewChild('addSensorsModal') addSensorsModal: ModalDirective;
  @ViewChild('discardChangesModal') discardChangesModal: ModalDirective;
  @ViewChildren(DataTableComponent) myDataTable: QueryList<DataTableComponent>;


  sensorsList: SensorList<SensorListItem> = null;
  editSensorId: string = null;
  changeCount = 0;
  isWorkOffline: Boolean;

  inheritedSensorsList: SensorList<InheritedSensorListItem> = null;

  sensorTypes: SensorType[] = null;

  resolveDiscardChanges: (value: boolean) => void = null;

  constructor(
    private monitoringService: MonitoringService,
    private notifications: NotificationsService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.owner) {
      this.server = this.owner instanceof InformixServer ? this.owner : null;
      if (this.owner) {
        this.monitoringService.getMonitoringProfile(this.owner).then(profiles => {
          this.profile = profiles.own;
          this.parentProfile = profiles.inherited;
          this.buildSensorsList();
          this.buildInheritedSensorsList();
          this.getDataTableElm();
        }).catch(err => {
          console.error(err);
        });
      }
    }
  }

  ngAfterViewInit() {
    this.discardChangesModal.onHide.subscribe(() => {
      if (this.resolveDiscardChanges) {
        this.resolveDiscardChanges(false);
        this.resolveDiscardChanges = null;
      }
    });
    // update isWorkOffline flag
    this.route.data.subscribe(data => {
      this.isWorkOffline = data.server.isWorkOffline;
    });
  }
  getDataTableElm() {
    setTimeout(() => {
      if (this.myDataTable.length === 2 && this.myDataTable.first.dataTableRef) {
        const topOffSet = this.myDataTable.first.dataTableRef.nativeElement.getBoundingClientRect().top;
        this.dynamicHeight = ((window.innerHeight - topOffSet) / 2) - 90;
      }
    });
  }
  canDeactivate(): Promise<boolean> | boolean {
    if (this.changeCount > 0) {
      return new Promise<boolean>(resolve => {
        this.resolveDiscardChanges = resolve;
        this.discardChangesModal.show();
      });
    } else {
      return true;
    }
  }

  private buildSensorsList() {
    if (!this.profile) {
      return;
    }

    this.changeCount = 0;
    this.sensorsList = new SensorList<SensorListItem>(this.profile.sensors.map(sensor => {
      const item = new SensorListItem(sensor);
      return item;
    }));

    if (this.inheritedSensorsList) {
      this.updateOverrideItems();
    }
  }

  private buildInheritedSensorsList() {
    if (this.parentProfile) {
      this.inheritedSensorsList = new SensorList<InheritedSensorListItem>(this.parentProfile.sensors.map(sensor => {
        const item = new InheritedSensorListItem(sensor);
        return item;
      }));
    } else {
      this.inheritedSensorsList = null;
    }

    if (this.sensorsList) {
      this.updateOverrideItems();
    }
  }

  private updateOverrideItems() {
    if (!this.sensorsList) {
      return;
    }

    this.sensorsList.sensors.forEach(sensor => {
      if (!this.inheritedSensorsList) {
        sensor.isOverriding = false;
      } else {
        const overriddenSensor = this.inheritedSensorsList.getSensor(sensor.type.id);
        if (overriddenSensor) {
          sensor.isOverriding = true;
          overriddenSensor.isOverridden = true;
        }
      }
    });
  }

  private sensorListItemChanged(item: SensorListItem) {
    if (item.isNew) {
      return;
    }

    const sensor = this.profile.getSensor(item.type.id);
    const isModified = !item.equals(sensor);
    if (item.isModified !== isModified) {
      this.changeCount += isModified ? 1 : -1;
    }
    item.isModified = isModified;
  }

  openAddSensorsDialog() {
    this.addSensorsModal.show();
    this.sensorTypes = null;
    this.monitoringService.getSensorTypes().then(types => {
      this.sensorTypes = [];
      types.forEach(type => {
        if (!this.sensorsList.getSensor(type.id)) {
          this.sensorTypes.push(type);
        }
      });
    }).catch(err => {
      console.error('Unable to get sensor types', err);
    });
  }

  addSensors(types: SensorType[]) {
    types.forEach(type => this.addSensor(new SensorListItem(type)));
    this.addSensorsModal.hide();
  }

  addSensor(sensor: SensorListItem) {
    sensor.isNew = true;

    if (this.inheritedSensorsList) {
      const overriddenSensor = this.inheritedSensorsList.getSensor(sensor.type.id);
      if (overriddenSensor) {
        sensor.isOverriding = true;
        overriddenSensor.isOverridden = true;
      }
    }

    this.sensorsList.addSensor(sensor);
    this.changeCount++;
  }

  removeSensor(item: SensorListItem) {
    this.sensorsList.removeSensor(item);
    this.changeCount += item.isNew ? -1 : 1;
    if (item.isOverriding) {
      const overriddenSensor = this.inheritedSensorsList.getSensor(item.type.id);
      if (overriddenSensor) {
        overriddenSensor.isOverridden = false;
      }
    }
    if (this.inheritedSensorsList) {
      this.inheritedSensorsList.sensors = this.inheritedSensorsList.sensors.slice();
    }
  }

  editSensor(item: SensorListItem) {
    if (this.editSensorId === item.type.id) {
      this.closeEdit();
    } else {
      this.editSensorId = item.type.id;
    }
  }

  editSave(sensor: SensorListItem) {
    this.sensorListItemChanged(sensor);
    if (sensor.disabled) {
      this.enableSensor(sensor);
    }
  }

  undoChanges(item: SensorListItem) {
    const sensor = this.profile.getSensor(item.type.id);
    if (sensor) {
      item.runInterval = sensor.runInterval;
      item.dataRetentionInterval = sensor.dataRetentionInterval;
      item.disabled = sensor.disabled;
      item.isModified = false;
      this.changeCount--;
    }
    this.closeEdit();
  }

  closeEdit() {
    this.editSensorId = null;
  }

  disableSensor(item: SensorListItem) {
    item.disabled = true;
    this.sensorListItemChanged(item);
    if (this.editSensorId === item.type.id) {
      this.closeEdit();
    }
  }

  enableSensor(item: SensorListItem) {
    item.disabled = false;
    this.sensorListItemChanged(item);
  }

  overrideSensor(sensor: InheritedSensorListItem, disable = false) {
    const newSensor = new SensorListItem(sensor);
    newSensor.disabled = disable;
    this.addSensor(newSensor);
    sensor.isOverridden = true;
    if (this.inheritedSensorsList) {
      this.inheritedSensorsList.sensors = this.inheritedSensorsList.sensors.slice();
    }
  }

  saveChanges() {
    if (this.changeCount < 1) {
      return;
    }

    this.closeEdit();

    let newMonitoringProfile: MonitoringProfile = null;

    if (this.changeCount > 0) {
      newMonitoringProfile = new MonitoringProfile();
      newMonitoringProfile.addSensors(this.sensorsList.sensors);
      this.monitoringService.updateMonitoringProfile(this.owner, newMonitoringProfile).then(() => {
        this.profile = newMonitoringProfile;
        this.buildSensorsList();
        this.buildInheritedSensorsList();
        this.notifications.pushSuccessNotification('Changes saved');
      }).catch(err => {
        console.error(err);
        this.notifications.pushErrorNotification('There was a problem saving changes');
      });
    }
  }

  discardChanges() {
    this.changeCount = 0;
    this.closeEdit();
    this.buildSensorsList();
    this.buildInheritedSensorsList();
  }

  editSensorIsOpen(item: SensorListItem) {
    return item.type.id === this.editSensorId;
  }

  removeMultipleSensors() {
    this.selectedSensors.forEach(row => {
      this.removeSensor(row);
    });
  }

  enableMultipleSensors() {
    this.selectedSensors.forEach(row => {
      this.enableSensor(row);
    });
  }

  disableMultipleSensors() {
    this.selectedSensors.forEach(row => {
      this.disableSensor(row);
    });
  }

  getUpdatedValue(sensorData: SensorListItem) {
    this.sensorsList.sensors.map(sensor => {
      if (this.selectedSensors.some(row => sensor.type === row.type)) {
        sensor.disabled = false;
        sensor.dataRetentionInterval = sensorData.dataRetentionInterval;
        sensor.runInterval = sensorData.runInterval;
      }
      this.sensorListItemChanged(sensor);
      return sensor;
    });
  }

  overrideMultipleSensors() {
    this.selectedInheritedSensors.forEach(sensor => this.overrideSensor(sensor));
  }

  inheritedSensorsData(event: { data: any; selected: boolean; disabled?: boolean }[]) {
    event.forEach(inheritedRow => {
      if (inheritedRow.data.isOverridden) {
        inheritedRow.disabled = true;
        inheritedRow.selected = false;
      } else {
        inheritedRow.disabled = false;
      }
    });
  }

  sensorsData(event: { data: any; selected: boolean; disabled?: boolean }[]) {
    event.forEach(sensorRow => {
      if (sensorRow.data.isNew && sensorRow.data.isOverriding) {
        sensorRow.selected = true;
      } else {
        sensorRow.selected = false;
      }
    });
  }

  getSelectedSensors(event: SensorListItem[]) {
    this.selectedSensors = event;
  }

  getSelectedInheritedSensors(event: InheritedSensorListItem[]) {
    this.selectedInheritedSensors = event;
  }

  canCreateSensorType() {
    return this.owner instanceof InformixServer && this.userService.getCurrentUserDirty().isSuperAdmin();
  }
}
