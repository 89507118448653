/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { EventEmitter, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

export interface Notification {
  type: string;
  message: string;
  lifespan?: number;
}

export const TYPE_ERROR = 'danger';
export const TYPE_INFO = 'info';
export const TYPE_SUCESS = 'success';
export const TYPE_WARN = 'warning';

@Injectable()
export class NotificationsService {

  private eventNotify = new EventEmitter<Notification>();

  public getEventNotifyEmitter(): EventEmitter<Notification> {
    return this.eventNotify;
  }

  public pushNotification(notification: Notification) {
    this.eventNotify.emit(notification);
  }

  public pushErrorNotification(err: string | any) {
    let message: string;
    if (typeof err === 'string') {
      message = err;
    } else if (err instanceof HttpErrorResponse) {
      message = err.error ? err.error.err : 'An error occurred';
    } else {
      message = err;
    }
    this.pushNotification({
      type: TYPE_ERROR,
      message,
      lifespan: 0
    });
  }

  public pushInfoNotification(message: string, lifespan?: number) {
    this.pushNotification({
      type: TYPE_INFO,
      message,
      lifespan
    });
  }

  public pushWarnNotification(message: string) {
    this.pushNotification({
      type: TYPE_WARN,
      message,
      lifespan: 0
    });
  }

  public pushSuccessNotification(message: string, lifespan?: number) {
    this.pushNotification({
      type: TYPE_SUCESS,
      message,
      lifespan: lifespan ? lifespan : null
    });
  }

  /**
   * Helper to show generic push notification message for task() command result.
   * Success, Error, or Info notification type will shown based on returnCode.
   */
  public pushGenericNotification(returnCode, resultMessage, callBack?) {
    const type: any = {};
    if (returnCode === 0) {
      this.pushSuccessNotification(`Admin response from server: <br> ${resultMessage} <br> For more details, Please check online log.`);
      type.isSuccess = true;
    } else if (returnCode === undefined || returnCode === null) {
      this.pushInfoNotification(`Task response from server: <br> ${resultMessage} <br> For more details, Please check online log.`);
      type.isInfo = true;
    } else if (returnCode === -1) {
      this.pushErrorNotification(resultMessage);
      type.isError = true;
    } else if (returnCode === 100) { // Speacial case for createTask() Sync/Async tasks. Which returns (expression) : 0
      this.pushInfoNotification(`${resultMessage} For more details, Please check online log.`);
      type.isInfo = true;
    }
    if (callBack) {
 callBack(type);
}
  }

  /**
   * Method to sanitize message before sending to the notification method to prevent xss attack
   *
   * @param message Can be unsafe HTML or simple message
   * @returns safe message
   */
  public sanitizeMessage(message: string): string {
    return message.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
  }
}
