/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2018. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TreeViewComponent, TreeViewNodeTemplateDirective } from './tree-view.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TreeViewComponent,
    TreeViewNodeTemplateDirective
  ],
  exports: [
    TreeViewComponent,
    TreeViewNodeTemplateDirective
  ]
})
export class TreeViewModule { }
