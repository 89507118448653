<div bsModal #addValuesModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{ 'schemaManager.createIndex.addValuesModal.title' | translate }}</h5>
        <div>
          <button type="button" class="close" (click)="hideModal()"><i class="icon icon-times"></i></button>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">{{ 'schemaManager.createIndex.addValuesModal.specifyValues' | translate }}</div>
        </div>
        <form [formGroup]="addValuesForm">
          <div class="row pt-2">
            <div class="col-12 d-inline-flex">
              <div><label class="mt-2 mr-2">{{ 'schemaManager.createIndex.addValuesModal.value' | translate }}</label>
              </div>
              <div>
                <input class="form-control" type="text" formControlName="value" tooltip="" appExtendedFormControl>
              </div>
              <div>
                <button type="button" class="btn btn-primary btn-fixed-width ml-1" (click)="addValues()"
                  [disabled]="isSameValue">{{ 'button.add' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="overflow-auto selected-table-modal mt-3" *ngIf="columnValues">
            <app-data-table [data]="columnValues">
              <app-data-table-column [title]="'schemaManager.createIndex.addValuesModal.value' | translate"
                property="value"></app-data-table-column>
              <app-data-table-column>
                <ng-template appTableCell let-row="row">
                  <button type="button" class="btn btn-icon btn-sm" (click)="deleteValues(row)">
                    <i class="icon icon-trash"></i>
                  </button>
                </ng-template>
              </app-data-table-column>
            </app-data-table>
          </div>
        </form>
        <div align="right" class="mt-3">
          <div class="pr-0">
            <button type="button" class="btn btn-secondary btn-fixed-width mr-2" (click)="hideModal()">
              {{ 'button.cancel' | translate }}</button>
            <button type="button" class="btn btn-primary btn-fixed-width" (click)="saveValues()">
              {{ 'button.save' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
