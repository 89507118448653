<h5 class="card-title">Storage Summary</h5>
<ng-template [ngIf]="dataLoading">
  <div style="text-align: center">
    <h4><i class="icon icon-circle-notch icon-spin"></i></h4>
  </div>
</ng-template>

<ng-template [ngIf]="dataLoadErrorMessage">
  <div class="row ml-1 mr-1">
    <div class="alert alert-danger">
      Unable to retrieve storage summary information. <br>
      Error: {{dataLoadErrorMessage}}
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="!dataLoading && !dataLoadErrorMessage">
  <div class="row">
    <div class="col-3 text-right"><a [routerLink]="['spaces']">Database spaces:</a></div>
    <div *ngIf="numSpacesBelowFreeThreshold != null" class="col-9">
      <i class="icon icon-warning text-warning" *ngIf="numSpacesBelowFreeThreshold > 0"></i>
      {{numSpacesBelowFreeThreshold}} spaces &lt; {{storageFreeSpaceThreshold}}% free
    </div>
  </div>
  <div *ngIf="!storagePoolInfoForbidden" class="row mt-2">
    <div class="col-3 text-right"><a [routerLink]="['pool']">Storage Pool:</a></div>
    <div *ngIf="storagePoolTotalEntries != null" class="col-9">
      <div *ngIf="storagePoolActiveEntries > 0">
        <i class="icon icon-circle text-success" title="Active"></i>
        {{storagePoolActiveEntries}} active {{(storagePoolActiveEntries == 1)? 'entry':'entries'}}
      </div>
      <div *ngIf="storagePoolFullEntries > 0">
        <i class="icon icon-circle text-warning" title="Full"></i>
        {{storagePoolFullEntries}} full {{(storagePoolFullEntries == 1)? 'entry':'entries'}}
      </div>
      <div *ngIf="storagePoolErrorEntries > 0">
        <i class="icon icon-circle text-danger" title="Error"></i>
        {{storagePoolErrorEntries}} error {{(storagePoolErrorEntries == 1)? 'entry':'entries'}}
      </div>
      <div *ngIf="storagePoolTotalEntries == 0">
         No entries
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3 text-right">Last Backup:</div>
    <div *ngIf="oldestLevel0Backup != null" class="col-9">
      <div *ngIf="oldestLevel0Backup <= 0">
        <i class="icon icon-warning text-warning"></i> NEVER
      </div>
      <div *ngIf="oldestLevel0Backup > 0">
        {{oldestLevel0Backup * 1000 | timeSince}} at <br>
        <i class="icon icon-calendar" style="padding-top: 5px"></i> {{(oldestLevel0Backup * 1000) | date:'y-MM-dd HH:mm:ss'}}
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3 text-right">Spaces not backed up:</div>
    <div *ngIf="numSpacesNotBackedUp != null" class="col-9">
      <i class="icon icon-warning text-warning" *ngIf="numSpacesNotBackedUp > 0"></i> {{numSpacesNotBackedUp}}
    </div>
  </div>
</ng-template>
