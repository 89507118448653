<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container" [ngClass]="{showLoader: isActionInProgress}">
    <div class="row mb-2">
        <div class="col-6">
            <h4>
                <strong>Replicate</strong>
                <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="isLoading"></i>
            </h4>
        </div>
        <div class="col-6 form-inline justify-content-end" *ngIf="replicates">
            <div class="input-group show-inner-icon inner-addon left-addon">
                    <i class="icon icon-search pr-2"></i>
                    <input type="text" class="form-control form-control-sm pl-4" [formControl]="searchFormControl" placeholder="Search Replicate" />
            </div>
            <app-check-server-admin-permissions [server]="server">
                <a routerLink="/dashboard/servers/{{server.id}}/replicate/new" class="btn btn-sm btn-primary ml-2"appPermissionDisable>
                    Add New Replicate
                </a>
            </app-check-server-admin-permissions>
        </div>
    </div>

    <div *ngIf="replicates && !isLoading && !isDomainLoading else serverError;">
        <app-data-table class="table-responsive"
            [enableFilter]="false" [data]="replicates" [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20">
            <app-data-table-column title="Replicate" property="replicateName">
            </app-data-table-column>
            <app-data-table-column title="Conflict" property="resolutionRule">
            </app-data-table-column>
            <app-data-table-column title="Created" property="createdTime">
            </app-data-table-column>
            <app-data-table-column title="Modified" property="modifiedTime">
            </app-data-table-column>
            <!-- <app-data-table-column title="Queue Size" property="">
            </app-data-table-column> -->
            <app-data-table-column title="Frequency" property="frequency">
                <ng-template appTableCell let-replicate="row">
                    <!--  {{replicate.frequency ? replicate.frequency : 'Immediately'}} -->
                    {{getFrequency(replicate)}}
                </ng-template>
            </app-data-table-column>
            <app-data-table-column title="Actions" property="action">
                <ng-template appTableCell let-replicate="row">
                    <div class="justify-content-end form-inline">
                        <app-check-server-admin-permissions [server]="server">
                            <button type="button" class="btn btn-sm btn-secondary border-0" (click)="updateReplicate('suspend', replicate)"
                                *ngIf="checkValidAction({name:'Suspend'}, replicate)" appPermissionDisable>
                                <i class="icon icon-suspend" tooltip="Suspend"></i>
                            </button>
                        </app-check-server-admin-permissions>
                        <app-check-server-admin-permissions [server]="server">
                            <button type="button" class="btn btn-sm btn-secondary border-0" (click)="updateReplicate('resume', replicate)"
                            *ngIf="checkValidAction({name: 'Resume'}, replicate)" appPermissionDisable>
                                <i class="icon icon-resume" tooltip="Resume"></i>
                            </button>
                        </app-check-server-admin-permissions>
                        <app-check-server-admin-permissions [server]="server">
                            <button type="button" class="btn btn-sm btn-secondary border-0" (click)="showParticipants('stop', replicate)"
                            *ngIf="checkValidAction({name: 'Stop'}, replicate)" appPermissionDisable>
                                <i class="icon icon-stop-square" tooltip="Stop"></i>
                            </button>
                        </app-check-server-admin-permissions>
                        <app-check-server-admin-permissions [server]="server">
                            <button type="button" class="btn btn-sm btn-secondary border-0" (click)="showParticipants('start', replicate)"
                             *ngIf="checkValidAction({name:'Start'}, replicate)" appPermissionDisable>
                                <i class="icon icon-start" tooltip="Start"></i>
                            </button>
                        </app-check-server-admin-permissions>
                        <app-check-server-admin-permissions [server]="server">
                            <button class="btn more-btn" tooltip="More" appPermissionDisable>
                                <div class="btn-group" dropdown container="body" placement="bottom right">
                                    <button class="btn-secondary border-0" dropdownToggle>
                                        <i class="icon icon-menu-overflow-vertical"></i>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="viewas-button">
                                        <li role="menuitem" *ngFor="let action of replicate.actions" [tooltip]="action.value === 'sync_replicate' ? 'This Feature will be available in upcoming release' : null">
                                            <a class="dropdown-item" (click)="onActionChange(action.value, replicate)" [ngClass]="{'disabled': action.value === 'sync_replicate'}">{{action.name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </button>
                        </app-check-server-admin-permissions>
                    </div>
                </ng-template>
            </app-data-table-column>
        </app-data-table>
    </div>
    <ng-template #serverError>
        <div class="alert alert-danger" *ngIf="apiError">
            Unable to retrieve replicates information. <br> Error: {{apiError}}
          </div>
    </ng-template>
</div>

<!-- action loader -->
<div *ngIf="isActionInProgress" class="align-items-center d-flex flex-column vh-100 position-absolute w-100 loading-wrapper">
    <div class="backdrop h-100 position-absolute w-100" [ngClass]="{'active': true}"></div>
    <div class="alert alert-info">
        <i class="icon icon-circle-notch icon-spin"></i> <span class="pl-2">Please wait. Replicate is being deleted.. </span>
    </div>
</div>

<!-- start replicate modal -->
<div bsModal #startReplicateModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">{{selectedAction === 'start' ? 'Start' : 'Stop'}} Participants
                    <i class="icon icon-circle-notch icon-spin" *ngIf="isLoading"></i>
                </h5>
                <button type="button" class="close" (click)="startReplicateModal.hide()">
                    <i class="icon icon-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formGroup">
                    <ng-container *ngIf="firstPage; else startReplicateNextPage">
                        <app-data-table *ngIf="participants" class="table-responsive min-width" [enableFilter]="false"
                            [data]="participants" [sortDescending]="true" [enableSearch]="true" [rowsPerPage]="20"
                            [enableSingleSelect]="false" [enableSelect]="true" (rowSelected)="onIndexSelected($event)"
                            [dataMapFn]="columnsDataMap">
                            <app-data-table-column title="Participant Server" property="groupName">
                                <ng-template appTableCell let-participant="row">
                                    {{participant.groupName}} <span *ngIf="participant.groupName === replicate.masterServer" class="master-server">Master</span>
                                </ng-template>
                            </app-data-table-column>
                            <app-data-table-column title="Participant Type" property="partMode">
                            </app-data-table-column>
                            <app-data-table-column title="State" property="partStatus">
                            </app-data-table-column>
                            <app-data-table-column title="Database" property="databaseName">
                            </app-data-table-column>
                            <app-data-table-column title="Table" property="tabName">
                            </app-data-table-column>
                            <app-data-table-column title="Modifier" property="modifiedTime">
                            </app-data-table-column>
                            <app-data-table-column title="" property="">
                            </app-data-table-column>
                        </app-data-table>
                    </ng-container>

                    <ng-template #startReplicateNextPage>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <label class="font-weight-bold">Extra target rows</label>
                                    <app-radio-group class="justify-content-start ml-2"
                                        formControlName="extraTargetRows">
                                        <app-radio value="delete">Delete</app-radio>
                                        <app-radio class="ml-3" value="keep">Keep</app-radio>
                                        <app-radio class="ml-3" value="merge">Merge</app-radio>
                                    </app-radio-group>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <div class="font-weight-bold">Size of the send queue</div>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="20"
                                        title="Size of the send queue" formControlName="queueSize" />
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <app-checkbox formControlName="isPerformSyncOp">
                                    Performed synchronization operation as foreground process
                                </app-checkbox>
                            </div>
                        </div>

                        <div class="row mt-2 mb-5">
                            <div class="col">
                                <div class="font-weight-bold mb-2"><span class="text-danger">* </span>Sync data source</div>
                                <div class="input-group">
                                    <select class="form-control" formControlName="serverName">
                                        <option *ngFor="let server of dbServerNames" value="{{server.name}}">
                                            {{server.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </form>
            </div>
            <div class="modal-footer">
                <div class="float-left" *ngIf="selectedAction === 'start'">
                    <button type="button" class="btn btn-secondary" (click)="startReplicateModal.hide()">Close</button>
                </div>
                <div class="float-right">
                    <button type="button" *ngIf="selectedAction === 'start'" class="btn  mr-3"[ngClass]="firstPage ? 'btn-primary' : 'btn-secondary'" 
                        (click)="toggle()">{{firstPage ? 'Next' : 'Back' }}</button>
                    <button type="button" *ngIf="selectedAction === 'start' && !firstPage" class="btn btn-primary justify-content-end"
                        (click)="startReplication()" [disabled]="isParticipantLoader" [disabled]="isLoading || formGroup.invalid">Start Replicate</button>
                    <button type="button" *ngIf="selectedAction === 'stop'" class="btn btn-primary justify-content-end"
                        (click)="stopReplication()" [disabled]="isParticipantLoader || participants.length === 0">Stop Replicate</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Confirm Modal -->
<div bsModal #confirmReplicateModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">Delete replicate</h5>
                <button type="button" class="close" (click)="confirmReplicateModal.hide()">
                    <i class="icon icon-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex align-items-center">
                    <div class="p-2">
                        <i class="icon icon-2x icon-warning text-warning"></i>
                    </div>
                    <span>
                        Are you sure you want to delete <b>{{replicate?.replicateName}}</b> replicate?
                    </span>
                </div>
            </div>

            <div class="modal-footer">
                <div class="float-left">
                    <button type="button" class="btn btn-secondary" (click)="confirmReplicateModal.hide()">Close</button>
                </div>
                <div class="float-right">
                    <button type="button" class="btn btn-primary ml-3" (click)="removeReplicate()">Proceed</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #checkReplicateModal>
    <app-check-replicate-modal [serverNames]="dbServerNames" [replicate]="replicate"(closeModal)="closeModal()"></app-check-replicate-modal>
</ng-template>
<ng-template #syncReplicateModal>
    <app-sync-replicate-modal [serverNames]="dbServerNames" [replicate]="replicate"(closeModal)="closeModal()"></app-sync-replicate-modal>
</ng-template>
