/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { SystemConfigService } from '../system-config.service';
import { HttpErrorResponse } from '@angular/common/http';

const BACKGROUND_JOBS_CONFIG_NAME = 'background_jobs';
const REST_CONFIG_NAME = 'rest';

@Component({
  selector: 'app-system-server-config',
  templateUrl: 'system-server-config.html'
})
export class SystemServerConfigComponent implements OnInit {

  formGroup: UntypedFormGroup = null;

  threadConfig: any;
  restConfig: any;
  isBtnDisable = true;

  constructor(private configService: SystemConfigService,
    private notifications: NotificationsService) { }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      nThreads: new UntypedFormControl(10, [Validators.required]),
      restSqlSessionTimeout: new UntypedFormControl(120, [Validators.required])
    });
    this.getConfigData(BACKGROUND_JOBS_CONFIG_NAME);
    this.getConfigData(REST_CONFIG_NAME);
  }

  getConfigData(configName: string) {
    this.configService.getConfig(configName).then(config => {
      if (configName === BACKGROUND_JOBS_CONFIG_NAME) {
        this.threadConfig = config;
        this.formGroup.controls.nThreads.setValue(config.nThreads);
      } else if (configName === REST_CONFIG_NAME) {
        this.restConfig = config;
        this.formGroup.controls.restSqlSessionTimeout.setValue(config.restSqlSessionTimeout);
      }
      this.valueCheck();
    }).catch(err => {
      console.error('Unable to get system config', err);
    });
  }

  saveConfig() {
    const newThreadConfig: any = {};
    const newRestConfig: any = {};
    newThreadConfig.nThreads = this.formGroup.value.nThreads;
    newRestConfig.restSqlSessionTimeout = this.formGroup.value.restSqlSessionTimeout;

    if (newThreadConfig.nThreads !== this.threadConfig.nThreads) {
      this.configService.saveConfig(BACKGROUND_JOBS_CONFIG_NAME, newThreadConfig).then(() => {
        this.getConfigData(BACKGROUND_JOBS_CONFIG_NAME);
        this.notifications.pushSuccessNotification('Server thread configuration saved.');
      }).catch((err: HttpErrorResponse) => {
        console.error('Unable to save server config', err);
        this.notifications.pushErrorNotification('Server thread configuration not saved. ' + err.error ? err.error.err : err);
      });
    }

    if (newRestConfig.restSqlSessionTimeout !== this.restConfig.restSqlSessionTimeout) {
      this.configService.saveConfig(REST_CONFIG_NAME, newRestConfig).then(() => {
        this.getConfigData(REST_CONFIG_NAME);
        this.notifications.pushSuccessNotification('SQL session timeout configuration saved.');
      }).catch((err: HttpErrorResponse) => {
        console.error('Unable to save server config', err);
        this.notifications.pushErrorNotification('SQL session timeout configuration not saved. ' + err.error ? err.error.err : err);
      });
    }
  }

  valueCheck() {
    this.formGroup.valueChanges.subscribe(changes => {
      if (this.threadConfig && this.restConfig && this.formGroup.value.nThreads === this.threadConfig.nThreads
        && this.formGroup.value.restSqlSessionTimeout === this.restConfig.restSqlSessionTimeout) {
        this.isBtnDisable = true;
      } else {
        this.isBtnDisable = false;
      }
    });
  }
}
