<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-4">
      <h4><strong>{{ 'systemReports.lockList' | translate }} {{sid ? 'for Session '+sid : ''}}
          {{lockedObject? 'for '+lockedObject : ''}}</strong>
          <i *ngIf="!lockListData" class="icon icon-circle-notch icon-spin ml-2"></i>
      </h4>
    </div>
    <div class="col-8">
      <app-date-time-picker (updateTimeStampValues)="updateTimeStampValues($event)" [previousTimeStamps]="timeStamps">
      </app-date-time-picker>
    </div>
  </div>
  <app-data-table *ngIf="lockListData" class="table-responsive" [enableFilter]="false" [data]="lockListData"
    [sortDescending]="true" [rowsPerPage]="20" [searchLabel]="'systemReports.searchLockList' | translate">
    <app-data-table-column [title]="'common.owner' | translate" property="owner_user" [searchable]="true"
      [sortable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.ownerSessionId' | translate" property="owner_sid" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-item="item">
        <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', item]">{{item}}</a>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waiter' | translate" property="wait_user" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.waiterSessionId' | translate" property="wait_sid" [sortable]="true"
      [searchable]="true">
      <ng-template appTableCell let-row="row" let-item="item">
        <ng-template [ngIf]="item">
          <a [routerLink]="['/dashboard', 'servers', server.id, 'sessions', item]">{{item}}</a>
        </ng-template>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockType' | translate" property="lock_type" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockedObject' | translate" property="locked_object" [sortable]="true"
      [searchable]="true"></app-data-table-column>
    <app-data-table-column [title]="'systemReports.rowId' | translate" property="lk_rowid" [sortable]="true"
      [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'table.partNum' | translate" property="lk_partnum" [sortable]="true"
      [searchable]="true"></app-data-table-column>
    <app-data-table-column [title]="'systemReports.lockStartTime' | translate" property="lk_start_time"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{(item == null)? "" : item.$date | date:'y-MM-dd HH:mm:ss'}}
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
