<div class="card card-body" *ngIf="serverInfo || memorySensorRunning || cpuSensorRunning">
  <h4 class="card-title"><a [routerLink]="['system']">Host</a></h4>

  <div *ngIf="serverInfo && (!viewMonitoredData || !memorySensorRunning)" class="row">
    <div class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Operating System Memory</div>
        <div class="card-body text-center">
          <canvas #osMemoryBarChart width="500" height="125"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="viewMonitoredData && (memorySensorRunning || cpuSensorRunning)">
    <div *ngIf="memorySensorRunning" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Operating System Memory</div>
        <div class="card-body text-center">
          <app-sensor-chart #osMemorySensorGraph [config]="{sensorId: 'os_memory', metricList: ['mem_used', 'swap_used', 'mem_total'], showControls: false, showTitle:false}" [server]="server"></app-sensor-chart>
        </div>
      </div>
    </div>
    <div *ngIf="cpuSensorRunning" class="col mt-2">
      <div class="card text-center">
        <div class="card-header">Operating System CPU</div>
        <div class="card-body text-center">
          <app-sensor-chart #osCPUSensorGraph [config]="{title: ' ', sensorId: 'os_cpu', showControls: false, showTitle:false}" [server]="server"></app-sensor-chart>
        </div>
      </div>
    </div>
  </div>
</div>
