/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2022. All Rights Reserved.
 *******************************************************************************/

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';
import { concatMap, startWith } from 'rxjs/operators';
import { InformixServerGroup } from '../../dashboard/groups/informixServerGroup';
import { InformixServerGroupService } from '../../dashboard/groups/informixServerGroup.service';
import { InformixServer } from '../../dashboard/servers/informixServer';
import { InformixServerService } from '../../dashboard/servers/informixServer.service';
import { DataTableComponent } from '../data-table/data-table.component';
import { NotificationsService } from '../notifications/notifications.service';
import { User } from '../user/user';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit, OnChanges {
  @Output() changeEvent = new EventEmitter<string>();
  @Input() enableAgentView: Boolean;
  modalRef: BsModalRef;
  user: User = null;
  server: InformixServer;
  selectedView: String = 'all';
  selectedCount = 0;
  selectedRowsCount = 0;
  filterAgentList: any[];
  previouseStateList: any[];
  selectedAgentStatus: Boolean = false;
  loader: Boolean = false;
  agentsList: any = [];
  shutdownErrorResponse: Boolean = false;
  shutdownErrorCount = 0;
  isShuttingDown: Boolean = false;
  getAgentsSubscription: Subscription = null;
  preload: Boolean = true;
  @ViewChild('shutdownAgentModal') shutdownAgentModal: TemplateRef<any>;
  @ViewChild('viewAgentsDataTable') viewAgentsDataTable: DataTableComponent;
  constructor(private router: Router,
    private userService: UserService, private bsModalService: BsModalService,
    private notificationsService: NotificationsService, private groupService: InformixServerGroupService,
    private serverService: InformixServerService) { }
  ngOnChanges() {
    if (this.enableAgentView && !this.preload) {
      this.pollingAgentsSubscriber();
    }
  }
  ngOnInit() {
    this.userService.getCurrentUser().then(user => {
      this.user = user;
    }).catch(err => {
      console.error('Could not get user info', err);
    });
    this.groupService.getAgentsData().subscribe(group => {
      this.agentsList = [];
      this.computeAgents(group);
      this.refreshAgentsData();
      this.preload = false;
      this.ngOnChanges();
    }, err => {
      console.error(err);
    });
  }
  confirmShutdown() {
    this.modalRef = this.bsModalService.show(this.shutdownAgentModal,
      { backdrop: true, ignoreBackdropClick: true, class: 'modal-dialog-centered' });
  }
  /**
   * Calling this method after user confirmation to shutdown Agents
   */
  shutdownAgents() {
    this.loader = true;
    const selectedRows = this.getSelectedRows();
    this.selectedRowsCount = selectedRows.length;
    this.isShuttingDown = true;
    const reqBody = selectedRows.map(item => ({ serverId: item.id, online: false }));
    this.serverService.shutdownAgents(reqBody).subscribe(agents => {
      // Mapping Errors to the Agents List.
      this.filterAgentList.forEach(item => {
        Object.keys(agents).forEach(key => {
          if (agents[key].serverId === item.id && agents[key].err) {
            this.shutdownErrorCount++;
            this.selectedCount++;
            item['err'] = agents[key].err;
          }
        });
      });
      this.loader = false;
      if (this.modalRef) {
        this.modalRef.hide();
      }
      if (this.shutdownErrorCount === 0) {
        this.selectedAgentStatus = false;
      } else {
        this.selectedAgentStatus = false;
        this.shutdownErrorResponse = true;
        this.resetAgentSelection();
      }
      this.selectedCount = 0;
    }, err => {
      this.notificationsService.pushErrorNotification(err.error.err);
      this.loader = false;
      if (this.modalRef) {
        this.modalRef.hide();
      }
      this.previouseStateList = [];
      this.selectedCount = 0;
      this.selectedAgentStatus = false;
      console.error(err);
    });
  }

  /**
   * This getAgents Method is for get the agents and calling every 2sec.
   */
  pollingAgentsSubscriber() {
    this.getAgentsSubscription = interval(2000).pipe(startWith(0), concatMap(() =>
      this.groupService.getAgentsData())).subscribe(group => {
        this.agentsList = [];
        this.computeAgents(group);
        this.refreshAgentsData();
      }, err => {
        console.error(err);
      });
  }
  /**
   * computeAgents method is for getting the agents from the groups by looping through.
   *
   * @param agentsData
   */
  computeAgents(agentsData: InformixServerGroup) {
    if (agentsData.servers.length > 0) {
      agentsData.servers.forEach(item => {
        // checking user has permission to read Agent or not.
        if (item.permissions.read) {
          item['groupName'] = agentsData.name;
          item['online'] = (item.agent.online && !item.agent.isConfigured) ? 'Not configured' : item.agent.online ? 'Online' : 'Offline';
          item['disabled'] = (item.permissions.admin && item.agent.online) ? false : true;
          item['groupName'].length > 20 ? item['groupNameOverflow'] = true : item['groupNameOverflow'] = false;
          item.name.length > 20 ? item['nameOverflow'] = true : item['nameOverflow'] = false;
          if (this.previouseStateList && this.previouseStateList.length > 0) {
            // comparing the current agentList with previuse list and retaining selected and err msg until we close the Agents View.
            this.previouseStateList.forEach(prevItem => {
              if (prevItem.id === item.id) {
                if (prevItem.isChecked && item.agent.online) {
                  item['isChecked'] = prevItem.isChecked;
                }
                if (prevItem.err) {
 item['err'] = prevItem.err;
}
              }
            });
          }
          this.agentsList.push(item);
        }
      });
    }
    agentsData.groups.forEach(item => {
      item.name = agentsData.name + ' > ' + item.name;
      this.computeAgents(item);
    });
  }
  /**
   * onAgentSelected method for checkBox selection to count the selected agents.
   *
   * @param row is selected record from the list
   */
  onAgentSelected(row: any) {
    this.resetAgentError();
    const table = row.data;
    table.isChecked = row.selected;
    this.selectedCount += row.selected ? 1 : -1;
    this.selectedCount > 0 ? this.selectedAgentStatus = true : this.selectedAgentStatus = false;
    this.shutdownErrorResponse = false;
  }
  resetAgentSelection() {
    this.previouseStateList.forEach(table => {
 table.isChecked ? table.isChecked = false : table.isChecked = table.isChecked;
});
  }
  resetAgentError() {
    this.previouseStateList.forEach((item) => {
 if (item.err) {
 delete item.err;
}
});
  }
  getSelectedRows(): any[] {
    const rowsSelected = [];
    this.filterAgentList.forEach(table => {
      if (table.isChecked) {
        rowsSelected.push(table);
      }
    });
    return rowsSelected;
  }
  /**
   * changeView method will filter the agents Based on user selected agent status
   *
   * @param event have the view type
   */
  changeView(event: string) {
    this.selectedView = event;
    this.refreshAgentsData();
  }
  refreshAgentsData() {
    this.filterAgentList = this.previouseStateList = this.agentsList;
    if (this.selectedView !== 'all') {
      this.filterAgentList = this.filterAgentList.filter((item) => {
        if (item.online === this.selectedView) {
          return true;
        }
      });
    }
  }
  closeAgentView() {
    this.changeEvent.emit('hide');
    this.resetAgentSelection();
    this.resetAgentError();
    this.selectedCount = 0;
    this.selectedAgentStatus = false;
    this.shutdownErrorResponse = false;
    this.selectedView = 'all';
    if (this.getAgentsSubscription) {
      this.getAgentsSubscription.unsubscribe();
    }
  }
  /**
   * navigating to agent tab based on the selected serverID
   *
   * @param serverId
   */
  navigateToAgent(serverId: Number) {
    this.closeAgentView();
    this.router.navigate([`dashboard/servers/${serverId}/setup`], { queryParams: { tab: 'agent' }, replaceUrl: true });
  }

}
