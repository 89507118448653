/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BreadcrumbElement } from '../../../../../shared/breadcrumb.component';
import { ServerBreadcrumb } from '../../../serverBreadcrumb';
import { InformixServer } from '../../../informixServer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnterpriseReplicationService } from '../../enterprise-replication.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { ConfirmationDialogService } from '../../../../../shared/modal/confirmation-dialog.service';
import { statusMap } from '../../replicate';

@Component({
  selector: 'app-check-sync-task',
  templateUrl: './check-sync-task.component.html',
  styleUrls: ['./check-sync-task.component.scss']
})
export class CheckSyncTaskComponent implements OnInit {

  @ViewChild('taskModal') taskModal: ModalDirective;
  @Input() groupNames = [];

  statusMap = statusMap;
  taskModalForm: FormGroup;
  breadcrumb: BreadcrumbElement[] = [];
  server: InformixServer = null;
  showIncosistentValues: Boolean = false;
  showTaskDetailsPage: Boolean = false;
  isLoading: Boolean = false;
  task: any;
  tasks = [];
  taskName;
  replicateName;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private erService: EnterpriseReplicationService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService
    ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.setServer(data.server);
    });

    this.taskModalForm = this.fb.group({
      deleteNamedTask: new FormControl(null, Validators.required),
      repeat: new FormControl(null, Validators.required),
    });

    this.getReplicateName();
  }

  setServer(server: InformixServer) {
    this.server = server;

    this.breadcrumb = ServerBreadcrumb.build(server, [
      { name: 'Replication' },
      { name: 'Check or Sync Replicate' }
    ]);
  }

  getReplicateName() {
     //  Get replicate from query-param
     this.route.queryParamMap.subscribe(queryParam => {
      if (queryParam.has('replicate')) {
        this.replicateName = queryParam.get('replicate').toString();
      }
    });
    this.getJobsData();
  }

  getJobsData() {
    this.isLoading = true;
    this.erService.getJobs(this.server.id).subscribe(res => {
      if (res && res.length > 0 && this.replicateName) {
        this.tasks = res.filter(task => task.replicate === this.replicateName);
      } else {
        this.tasks = res;
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.notificationsService.pushErrorNotification(err);
    });
  }

  showtaskModal(task) {
    this.taskName = task;
    this.taskModal.show();
  }

  run(taskname) {
    this.taskName = taskname;
    this.taskModal.show();
   }

   confirmDelete(task) {
    let msg = `delete <b>${task.name}</b> task`;
    this.confirmationDialogService.show('Delete Task', msg, () => this.deleteTask(task));
  }

  deleteTask(task) {
    let taskId;
    if (task && task.replcheck_id) {
      taskId = task.replcheck_id;
    }else {
      taskId = task.jsId;
    }
    const requestBody = {
      id: taskId,
      taskStatus: task.status
    };
    this.isLoading = true;
    this.erService.deleteTask(this.server.id, requestBody).subscribe((res: any)=> {
      if (res && res.length > 0) {
        this.tasks = res.filter(t => t.replicate === this.replicateName);
      } else {
        this.tasks = res;
      }
      this.isLoading = false;
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

   runTask() {
    const requestBody = this.taskModalForm.value;
    requestBody['showIncosistentValues'] = this.showIncosistentValues;
     if (this.taskModalForm.invalid) {
       return;
     }
    this.erService.runTask(requestBody).subscribe((res: any) => {
      this.taskModal.hide();
      this.notificationsService.pushSuccessNotification(res.result_message);
    }, err => {
      this.notificationsService.pushErrorNotification(err);
    });
   }

  showTaskDetails(task) {
    let queryParams;
    this.task = task;
    if (this.task && this.task.replcheck_id) {
      queryParams = { replcheck_id: this.task.replcheck_id };
    } else {
      queryParams = { taskId: this.task.jsTask };
    }
    this.router.navigate(['/dashboard', 'servers', this.server.id, 'replicate', 'task-details'], { queryParams });
  }
}
