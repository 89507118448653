/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { AbstractQuery } from '../../abstract-query';
import { Observable, from } from 'rxjs';
import { QueryResultPages } from '../../query-results';

const helpInfo: any[][] = [
  ['help', 'Show this help'],
  ['onstat <arguments...>', 'Run onstat. Requires access to the sysadmin database'],
  ['explain <query>', 'Generates explain output for the given query']
];

export class HelpQuery extends AbstractQuery {

  hasNext(): boolean {
    return false;
  }

  public next(): Observable<QueryResultPages> {
    const mapData = helpInfo.map(v => ({ command: { value: v[0] }, description: { value: v[1] } }));
    const queryData = {
      data: mapData,
      hasMore: false,
      isSuccess: true,
    };
    const result = new QueryResultPages([queryData]);
    return from([result]);
  }

  public setParams(params: any[]): void {
    // Empty implementation
  }

  public setHasNext(hasMore: boolean, resultSetId: number): void {
    // Empty implementation
  }
}
