/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved
 *******************************************************************************/
import { Component, Input } from '@angular/core';
import { InformixTableDetails } from '../../informix-table';
import { PrivilegeType } from '../table-info-page.model';

@Component({
  selector: 'app-table-privileges-info',
  templateUrl: './table-privileges-info.component.html'
})
export class TablePrivilegesInfoComponent {

  @Input() tableDetails: InformixTableDetails;

  privilegeTypes: Array<PrivilegeType> = [{ typeName: 'Select', class: 'bg-teal-50 color-white-primary', type: 'S' },
  { typeName: 'Update', class: 'bg-ruby-50 color-white-primary', type: 'U' },
  { typeName: 'Column-level', class: 'bg-ruby-50 color-white-primary', type: 'C' },
  { typeName: 'Insert', class: 'bg-aqua-60 color-white-primary', type: 'I' },
  { typeName: 'Delete', class: 'bg-lime-60 color-white-primary', type: 'D' },
  { typeName: 'Index', class: 'bg-violet-60 color-white-primary', type: 'X' },
  { typeName: 'Alter', class: 'bg-gold-60 color-white-primary', type: 'A' },
  { typeName: 'Reference', class: 'bg-blue-60 color-white-primary', type: 'R' },
  { typeName: 'Under', class: 'bg-ruby-80 color-white-primary', type: 'N' }];

  constructor() { }

  getColor(type: string) {
    return this.privilegeTypes.filter(privilegeType => privilegeType.type === type)[0].class;
  }

  getTooltip(type: string) {
    return this.privilegeTypes.filter(privilegeType => privilegeType.type === type)[0].typeName;
  }

}
