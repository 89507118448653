<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component *ngIf="breadcrumb" [elements]="breadcrumb"> </app-breadcrumb-component>
<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-md-8">
      <h4 *ngIf="maximized" class="page-header">
        <strong>{{viewAs === 'spaces' ? 'Spaces' : 'Chunks'}}</strong>
        <i class="icon icon-circle-notch icon-spin ml-2" *ngIf="dataLoading"></i>
      </h4>
    </div>
    <div class="col-md-4 text-right"
      *ngIf="action !== 'createSpace'  && (action !== 'addChunk' || (action === 'addChunk' && viewAs === 'spaces')) && !dataLoadErrorMessage">
      <div class="btn-group" dropdown container="body" placement="bottom right">
        <button id="viewas-button" type="button" class="btn btn-secondary dropdown-toggle mr-2" dropdownToggle>
          View as
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="viewas-button">
          <li role="menuitem">
            <a class="dropdown-item" (click)="setViewAs('spaces')">Spaces</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" (click)="setViewAs('chunks')">Chunks</a>
          </li>
        </ul>
      </div>
      <ng-container *ngIf="maximized">
        <app-check-server-admin-permissions [server]="server" placement="top" *ngIf="viewAs === 'spaces'">
          <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
            <button id="create-space-button" type="button" class="btn btn-primary" (click)="createSpaceClicked()"
              appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable>
              <i class="icon icon-plus"></i>
              Create Space
            </button>
          </app-check-hdr-permissions>
        </app-check-server-admin-permissions>
        <app-check-server-admin-permissions [server]="server" placement="top" *ngIf="viewAs === 'chunks'">
          <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
            <button id="add-chunk-button" type="button" class="btn btn-primary" (click)="addChunkClicked(null)"
              appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable>
              <i class="icon icon-plus"></i>
              Add Chunk
            </button>
          </app-check-hdr-permissions>
        </app-check-server-admin-permissions>
      </ng-container>
      <button id="refresh-button" type="button" class="btn btn-icon" (click)="refreshData()">
        <i class="icon icon-refresh" title="Refresh"></i>
      </button>
    </div>
  </div>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-danger">
      Unable to retrieve spaces information. <br>
      Error: {{dataLoadErrorMessage}}
    </div>
  </ng-template>

    <div *ngIf="spaces && viewAs === 'spaces'" [hidden]="action === 'createSpace'">
      <div class="table-responsive">
        <app-data-table [data]="spaces" sortBy="percent_used" sortDescending="true" [rowsPerPage]="10"
          searchLabel="Search name or type ( Use ' , ' OR ' + ' operator for multiple keyword search ).">
          <app-data-table-column title="Number" property="dbsnum" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="Status" property="status" [sortable]="false">
            <ng-template appTableCell let-item="item">
              <i class="icon icon-circle"
                [ngClass]=" ((item === 'operational') ? 'text-success' : (item === 'disabled') ? 'text-danger' : 'text-warning')"
                title="{{item}}"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Encrypted" property="encrypted" [sortable]="false">
            <ng-template appTableCell let-encrypted="item">
              <app-status-icon [status]="encrypted === 1" title="{{(encrypted === 1) ? 'Encrypted' : 'Not Encrypted'}}"></app-status-icon>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Type" property="type" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="% Used" property="percent_used" [sortable]="true">
            <ng-template appTableCell let-item="item">
              <div class="progress" [tooltip]="item + '%'">
                <div class="progress-bar"
                  [ngClass]="'bg-' + ( item < SPACE_USAGE_RED_THRESHOLD ? 'success' : 'danger' )"
                  [ngStyle]="{width: item + '%'}">
                </div>
              </div>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Size" property="size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Page Size" property="page_size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Expandable" property="expandable" [sortable]="false">
            <ng-template appTableCell let-expandable="item">
              <app-status-icon [status]="expandable === 1"></app-status-icon>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Create Size" property="create_size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | storageExtendSize}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Extend Size" property="extend_size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | storageExtendSize}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Last Backup" property="last_backup" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column *ngIf="maximized" class="text-nowrap" [sortable]="false">
            <ng-template appTableCell let-space="row">
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <button class="btn btn-sm btn-icon" tooltip="Modify Space" (click)="modifySpaceClicked(space)"
                    appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable>
                    <i class="icon icon-edit"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <div
                    [tooltip]="space.expandable !== 1 && server.permissions.admin && server.hasAdminPassword ? 'Space is not expandable' : null">
                    <button class="btn btn-sm btn-icon" (click)="expandSpaceClicked(space)" tooltip="Expand Space"
                      [disabled]="space.expandable !== 1" appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable>
                      <i class="icon icon-extend"></i>
                    </button>
                  </div>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <button class="btn btn-sm btn-icon" tooltip="Add Chunk" (click)="addChunkClicked(space)"
                    appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable>
                    <i class="icon icon-chunk"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server" *ngIf="space.dbsnum !== 1">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <button tooltip="Drop Space" class="btn btn-sm btn-icon" (click)="dropSpaceClicked(space)"
                   appHDRPermission [hdrPermission]="spacesHDR"appPermissionDisable>
                    <i class="icon icon-trash"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
    </div>

    <div *ngIf="chunks && viewAs === 'chunks'" [hidden]="action === 'addChunk'">
      <div class="table-responsive">
        <app-data-table [data]="chunks" sortBy="percent_used" [sortDescending]="true" [rowsPerPage]="10"
          searchLabel="Search space or path ( Use ' , ' OR ' + ' operator for multiple keyword search )." class="chunks-table">
          <app-data-table-column title="Number" property="chknum" [sortable]="true">
            <ng-template appTableCell let-chunk="row">
              {{chunk.chknum}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Space" property="space_name" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column title="Status" property="status" [sortable]="false">
            <ng-template appTableCell let-item="item">
              <i class="icon icon-circle"
                [ngClass]="((item === 'online') ? 'text-success' : (item === 'offline') ? 'text-danger' : 'text-warning')"
                title="{{item}}"></i>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Encrypted" property="encrypted" [sortable]="false">
            <ng-template appTableCell let-encrypted="item">
              <app-status-icon [status]="encrypted === 1" title="{{(encrypted === 1) ? 'Encrypted' : 'Not Encrypted'}}"></app-status-icon>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="% Used" property="percent_used" [sortable]="true">
            <ng-template appTableCell let-item="item">
              <div class="progress" [tooltip]="item + '%'">
                <div class="progress-bar"
                  [ngClass]="'bg-' + ( item < SPACE_USAGE_RED_THRESHOLD ? 'success' : 'danger' )"
                  [ngStyle]="{width: item + '%'}">
                </div>
              </div>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Size" property="size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Extendable" property="extendable" [sortable]="false">
            <ng-template appTableCell let-item="item">
              <app-status-icon [status]="item === 1"></app-status-icon>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column title="Read Time" property="read_time" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Write Time" property="write_time" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Reads" property="reads" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Writes" property="writes" [sortable]="true">
          </app-data-table-column>
          <app-data-table-column title="Path" property="path" [sortable]="true" [searchable]="true">
            <ng-template appTableCell let-item="item">
              <div class="d-flex ustify-content path-container">
                <span [tooltip]="item" class="d-flex align-items-center"><span>{{item | path}}</span></span>
                <span class="ml-1 copy-clipboard-btn" appCopyClipboard [text]="item" title="Copy path" (copied)="pathCopied($event)">
                  <button class="btn btn-sm btn-icon">
                    <i class="icon icon-clipboard"></i>
                  </button>
                </span>
              </div>
            </ng-template>
          </app-data-table-column>
          <app-data-table-column *ngIf="maximized" class="text-nowrap" [sortable]="false">
            <ng-template appTableCell let-chunk="row">
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <div
                    [tooltip]="server.permissions.admin && server.hasAdminPassword && !isChunkExtendable(chunk) ? 'Chunks in mirrored dbspaces, blobspaces, or sbspaces cannot be modified' : null">
                    <button class="btn btn-sm btn-icon" tooltip="Modify Chunk" (click)="modifyChunkClicked(chunk)"
                      appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable [disabled]="!isChunkExtendable(chunk)">
                      <i class="icon icon-edit"></i>
                    </button>
                  </div>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <div
                    [tooltip]="chunk.extendable !== 1 && server.permissions.admin && server.hasAdminPassword ? 'Chunk is not extendable' : null">
                    <button class="btn btn-sm btn-icon" (click)="extendChunkClicked(chunk)" appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable
                      tooltip="Extend Chunk" [disabled]="chunk.extendable !== 1">
                      <i class="icon icon-extend"></i>
                    </button>
                  </div>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
              <app-check-server-admin-permissions [server]="server">
                <app-check-hdr-permissions [server]="server" [hdrPermission]="spacesHDR" placement="top">
                  <button tooltip="Drop Chunk" class="btn btn-sm btn-icon" (click)="dropChunkClicked(chunk)"
                    appHDRPermission [hdrPermission]="spacesHDR" appPermissionDisable>
                    <i class="icon icon-trash"></i>
                  </button>
                </app-check-hdr-permissions>
              </app-check-server-admin-permissions>
            </ng-template>
          </app-data-table-column>
        </app-data-table>
      </div>
    </div>

  <br />

  <div [hidden]="action !== 'createSpace'">
    <app-create-space [server]="server" [hasStoragePoolEntries]="hasStoragePoolEntries"
      (success)="handleActionSuccess($event)" (error)="handleActionError($event)" (close)="closeAction()">
    </app-create-space>
  </div>

  <div [hidden]="action !== 'addChunk' || (action === 'addChunk' && viewAs === 'spaces')">
    <app-add-chunk [server]="server" [spacesList]="spaces" [selectedSpace]="selectedSpace"
      [hasStoragePoolEntries]="hasStoragePoolEntries" (success)="handleActionSuccess($event)"
      (error)="handleActionError($event)" (close)="closeAction()"></app-add-chunk>
  </div>

</div>

<div bsModal #storageModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div *ngIf="action === 'modifySpace' || action === 'expandSpace'">
          <h5 class="modal-title pull-left">{{title}}: {{selectedSpace?.name}}</h5>
        </div>
        <div *ngIf="action === 'modifyChunk' || action === 'extendChunk'">
          <h5 class="modal-title pull-left">{{title}}: Chunk {{selectedChunk?.chknum}} ({{selectedChunk?.space_name}})
          </h5>
        </div>
        <div *ngIf="action === 'dropSpace'">
          <h5 class="modal-title pull-left"><strong>Drop Space</strong></h5>
        </div>
        <div *ngIf="action === 'dropChunk'">
          <h5 class="modal-title pull-left"><strong>Drop Chunk</strong></h5>
        </div>
        <div *ngIf="action === 'addChunk'">
          <h5 class="modal-title pull-left">Add Chunk to {{selectedSpace?.name}}</h5>
        </div>
        <button type="button" class="close" (click)="closeAction();">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div [hidden]="action !== 'modifySpace'">
          <app-modify-space [server]="server" [selectedSpace]="selectedSpace" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()">
          </app-modify-space>
        </div>
        <div [hidden]="action !== 'expandSpace'">
          <app-expand-space [server]="server" [selectedSpace]="selectedSpace" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()">
          </app-expand-space>
        </div>
        <div [hidden]="action !== 'dropSpace'">
          <app-drop-space [server]="server" [selectedSpace]="selectedSpace" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()"></app-drop-space>
        </div>
        <div [hidden]="action !== 'extendChunk'">
          <app-extend-chunk [server]="server" [selectedChunk]="selectedChunk" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()">
          </app-extend-chunk>
        </div>
        <div [hidden]="action !== 'modifyChunk'">
          <app-modify-chunk [server]="server" [selectedChunk]="selectedChunk" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()">
          </app-modify-chunk>
        </div>
        <div [hidden]="action !== 'dropChunk'">
          <app-drop-chunk [server]="server" [selectedChunk]="selectedChunk" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()"></app-drop-chunk>
        </div>
        <div [hidden]="action !== 'addChunk'">
          <app-add-chunk [server]="server" [spacesList]="spaces" [selectedSpace]="selectedSpace"
            [hasStoragePoolEntries]="hasStoragePoolEntries" (success)="handleActionSuccess($event)"
            (error)="handleActionError($event)" (close)="closeAction()"></app-add-chunk>
        </div>
      </div>
    </div>
  </div>
</div>
