<div class="slider-container" #sliderContainer>
  <div class="slider-track">
    <div class="slider-body" [ngStyle]="sliderStyle" (mousedown)="onBodyMouseDown($event)" (touchstart)="onBodyTouchStart($event)">
      <div style="overflow: hidden">
        <i class="icon icon-bars-vertical"></i>
      </div>
      <div class="edge-handle edge-left" (mousedown)="onLeftHandleMouseDown($event)" (touchstart)="onLeftHandleTouchStart($event)"></div>
      <div class="edge-handle edge-right" (mousedown)="onRightHandleMouseDown($event)" (touchstart)="onRightHandleTouchStart($event)"></div>
    </div>
  </div>
</div>

<div class="row mt-1">
  <div class="col-12 col-md text-right">
    {{selectedMin | date:'medium'}} - {{selectedMax | date:'medium'}}
  </div>
  <div class="col-12 col-md-auto text-right">
    <div class="btn-group" dropdown container="body" placement="bottom right">
      <button type="button" class="btn btn-sm btn-secondary dropdown-toggle" dropdownToggle>
        View last {{selectedTimeSlice ? selectedTimeSlice.name : '...'}}
        <span class="caret"></span>
      </button>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <button type="button" class="dropdown-item" *ngFor="let slice of timeSlices"
          [ngClass]="{active: slice == selectedTimeSlice}" (click)="selectTimeSlice(slice)">
          {{slice.name}}
        </button>
      </div>
    </div>
  </div>
</div>

<div>

</div>
