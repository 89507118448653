/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/

import { Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { InformixSQLSession } from '../informix-sql-session';
import { InformixTable } from '../informix-table';
import { SchemaService } from '../schema.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

interface TableFilter {
  id: string;
  name: string;
  count?: number;
  filter: (table: InformixTable) => boolean;
}

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnInit, OnChanges {

  @Input() session: InformixSQLSession;
  @Input() selectedTableName: string;

  @Output() tableSelected = new EventEmitter<InformixTable>();
  @Output() createIndexData = new EventEmitter<any>();

  tables: InformixTable[] = null;
  filteredTables: InformixTable[] = null;
  selectedTable: InformixTable = null;

  searchFormControl = new UntypedFormControl('');
  filtersFormGroup: UntypedFormGroup;

  loadError: string = null;
  showDropdown = false;
  isDropdownOpen = false;

  readonly tableFilters: TableFilter[] = [
    { id: 'tables', name: 'Tables', filter: table => table.isTable() && !table.isSystemTable },
    { id: 'views', name: 'Views', filter: table => table.isView() && !table.isSystemTable },
    { id: 'externalTables', name: 'External Tables', filter: table => table.isExternalTable() && !table.isSystemTable },
    { id: 'collections', name: 'Collections', filter: table => table.isCollection },
    { id: 'systemTables', name: 'System Tables', filter: table => table.isSystemTable }
  ];

  constructor(
    private schemaService: SchemaService,
    private confirmationDialogService: ConfirmationDialogService,
    private notificationService: NotificationsService,
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.session && this.session) {
      this.selectedTable = null;
      this.createFilterForm();
      this.loadTables();
    } else if (changes.selectedTableName && this.tables) {
      this.selectTable(this.selectedTable ? this.tables.find(table => table.name === this.selectedTableName) : null);
    }
  }

  ngOnInit() {
    this.searchFormControl.valueChanges.pipe(debounceTime(200)).subscribe(() => this.filterTables());
  }

  private createFilterForm() {
    this.searchFormControl.setValue('', { emitEvent: false });

    this.filtersFormGroup = new UntypedFormGroup({});
    this.tableFilters.forEach(filter => {
      this.filtersFormGroup.addControl(filter.id, new UntypedFormControl(filter.id !== 'systemTables'));
    });
    this.filtersFormGroup.valueChanges.subscribe(() => this.filterTables());
  }

  loadTables() {
    this.tables = null;
    this.filteredTables = null;
    this.loadError = null;
    this.schemaService.getTables(this.session).subscribe(tables => {
      this.tables = tables;
      this.tableFilters.forEach(filter => {
        filter.count = this.tables.reduce((prev, current) => filter.filter(current) ? prev + 1 : prev, 0);
        const control = this.filtersFormGroup.controls[filter.id];
        if (filter.count < 1) {
          control.setValue(false, { emitEvent: false });
          control.disable({ emitEvent: false });
        } else {
          control.enable({ emitEvent: false });
        }
      });

      this.filterTables();

      if (this.selectedTable) {
        this.selectTable(this.tables.find(table => table.id === this.selectedTable.id));
      } else if (this.selectedTableName) {
        this.selectTable(this.tables.find(table => table.name === this.selectedTableName));
      }
    }, err => {
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.loadError = err.error.err;
      } else {
        this.loadError = 'An unknown error occurred';
        console.error(err);
      }
    });
  }

  selectTable(table: InformixTable) {
    if (this.selectedTable === table) {
      return;
    }
    this.selectedTable = table;
    this.tableSelected.emit(table);
  }

  onClosingDropdown() {
    if (!this.isDropdownOpen) {
      this.showDropdown = false;
    }
  }

  dropdownStateChange(e: any) {
    this.isDropdownOpen = e;
    if (!this.isDropdownOpen) {
      this.showDropdown = e;
    }
  }

  filterTables() {
    if (!this.tables) {
      return;
    }
    const searchString = (this.searchFormControl.value || '').trim();
    if (searchString) {
      this.filteredTables = this.tables.filter(v => v.name.indexOf(searchString) > -1);
    } else {
      this.filteredTables = this.tables;
    }

    const filterValues = this.filtersFormGroup.value;
    const enabledFilters = this.tableFilters.filter(filter => filterValues[filter.id]);
    if (enabledFilters.length) {
      const compositeFilter = (table: InformixTable) => !!enabledFilters.find(filter => filter.filter(table));

      this.filteredTables = this.filteredTables.filter(compositeFilter);
    }
  }

  openCreateIndex(table: InformixTable) {
    this.createIndexData.emit(table);
  }

  dropTable(tableName: string) {
    this.confirmationDialogService.show('drop the table ' + tableName + ' ?', () => {
      this.schemaService.dropTable(this.session, tableName).subscribe(() => {
        this.loadTables();
        this.translateService.get('schemaManager.createTable.successMsg.dropTable', { tableName })
          .subscribe((text: string) => {
            this.notificationService.pushSuccessNotification(text);
          });
      }, err => this.notificationService.pushErrorNotification(err.error ? err.error.err : err));
    });
  }
}
