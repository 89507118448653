<div bsModal #demoDatabaseModal="bs-modal" class="modal fade" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-11">
          <h5 class="modal-title pull-left">{{ 'schemaManager.createDemoDatabase.modalTitle' | translate }}
          </h5>
        </div>
        <div>
          <button type="button" class="close" (click)="closeModal()">
            <i class="icon icon-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body pt-3 px-4 pb-4">
        <form *ngIf="createDemoDatabaseForm" [formGroup]="createDemoDatabaseForm">
          <div class="pr-3">
            <label>{{ 'schemaManager.createDemoDatabase.modalDesc' | translate }}
            </label>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="font-weight-bold">{{ 'schemaManager.createDatabase.databaseNameLabel' | translate }}</label>
              <input class="form-control" type="text"
                [placeholder]="'schemaManager.createDatabase.databaseNamePlaceHolder' | translate"
                formControlName="databaseName" tooltip="" appExtendedFormControl>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <label class="font-weight-bold">{{ 'common.dbspace' | translate }}</label>
              <select class="form-control" formControlName="selectedDbspace">
                <option *ngFor="let dbspace of dbspaceNames" [value]="dbspace">{{dbspace}}</option>
              </select>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <label class="font-weight-bold mb-0">{{'common.options' | translate}}</label>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-6">
              <app-checkbox [checked]="transactionLogging" (click)="transactionLogging = !transactionLogging">
                {{ 'schemaManager.createDemoDatabase.transactionLogging' | translate }}
              </app-checkbox>
            </div>
            <div class="col-6">
              <app-checkbox [checked]="timeSeries" (click)="timeSeries = !timeSeries">
                {{ 'schemaManager.createDemoDatabase.createTimeSeries' | translate }}
              </app-checkbox>
            </div>
          </div>
          <div align="right">
            <div class="pt-4">
              <button type="button" class="btn btn-secondary btn-fixed-width" (click)="closeModal()">
                {{ 'button.cancel' | translate }}</button>
              <button type="button" class="btn btn-primary btn-fixed-width ml-2" (click)="createDemoDatabase()"
                [disabled]="!createDemoDatabaseForm.valid || !isDbspacesLoaded">{{ 'button.create' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
