/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2023. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { InformixServer } from '../../servers/informixServer';
import { primaryServerMetrics, secondaryServersMetrics } from './cluster-metrics-dashboard';
import { InformixServerService } from '../informixServer.service';

@Component({
  selector: 'app-cluster-metrics',
  templateUrl: 'cluster-metrics.html'
})
export class ClusterMetricsComponent implements OnInit {

  constructor(private informixServerService: InformixServerService) { }
  @Input() server: InformixServer = null;

  dataLoading = false;
  serverType: string;
  serverNotInCluster: string = null;
  clusterServerError: string = null;
  primaryServerMetrics = primaryServerMetrics;
  secondaryServersMetrics = secondaryServersMetrics;

  ngOnInit() {
    this.dataLoading = true;
    this.informixServerService.getServerHighAvailabilityStatus(this.server.id, false).subscribe(clusterInfo => {
      this.dataLoading = false;
      if (clusterInfo.clusterNodeList) {
        // using includes() as haName can be postfixed with thread listeners.
        // example: servername-4. It is defect in IDS version lower than XC8.
        const currentNode = clusterInfo.clusterNodeList.filter(server => clusterInfo.haName.includes(server.name)
        || server.id === this.server.id)[0];

        if(currentNode) {
          this.serverType = currentNode.type;
        }else {
          this.serverNotInCluster = `Could not display cluster matrics: Server '${clusterInfo.haName}' is not present in the cluster.`;
        }
      }
    }, err => {
      this.dataLoading = false;
      if (err.status === 404) {
        this.serverNotInCluster = err.error ? err.error.err : err;
      } else {
        this.clusterServerError = err.error ? err.error.err : err;
      }
    });
  }
}
