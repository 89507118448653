/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved
 *******************************************************************************/
import { Component, Input } from '@angular/core';
import { InformixTableDetails } from '../../informix-table';

@Component({
  selector: 'app-table-constraints-info',
  templateUrl: './table-constraints-info.component.html'
})
export class TableConstraintsInfoComponent {

  @Input() tableDetails: InformixTableDetails;

  constructor() { }

}
