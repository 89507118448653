/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { InformixServer } from '../../informixServer';
import { ServerBreadcrumb } from '../../serverBreadcrumb';
import { InformixDatabase } from '../informix-database';
import { InformixSQLSession } from '../informix-sql-session';
import { SchemaService } from '../schema.service';
import { InformixTable } from '../informix-table';
import { ConfirmationDialogService } from '../../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { CreateDemoDatabaseModalComponent } from '../create-database/create-demo-database-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HDRPermissionService } from '../../../../shared/hdr-permission/hdr-permission.service';
import { HDRPermission } from '../../../../shared/hdr-permission/hdr-permission';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CanComponentDeactivate } from '../../../../shared/canDeactivateGuard.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserSettingsService } from '../../../user-settings/user-settings.service';
import { UserService } from '../../../../shared/user/user.service';

@Component({
  selector: 'app-schema-manager',
  templateUrl: './schema-manager.component.html',
  styleUrls: ['./schema-manager.component.scss']
})
export class SchemaManagerComponent implements OnInit, CanComponentDeactivate, OnDestroy {

  queryHistory: Array<any> = [];
  breadcrumb: BreadcrumbElement[] = null;
  server: InformixServer = null;
  heading = 'Info';

  databases: InformixDatabase[] = null;
  databaseFormControl = new UntypedFormControl(null);
  databasesLoadError: string = null;
  session: InformixSQLSession = null;
  sessionLoading = false;
  sessionError: string = null;
  isChangingUser = false;
  userFormGroup: UntypedFormGroup;
  historyFormGroup: FormGroup;
  changeUserError: string = null;
  isCreateIndex: boolean;
  table: InformixTable;
  createIndexData: any;
  isCreateDatabase: Boolean = false;
  isCreateTable: Boolean = false;
  selectedDatabase: any;
  schemaManagerHDR: HDRPermission;
  showPassword: Boolean = false;
  editbatch: Boolean = false;
  selectedQueryHistory: any;
  isLastQueryExecuted: Boolean= false;
  isAutoCommit: Boolean = false;
  isPageChange: Boolean = false;
  showQueryHistory: Boolean = false;
  dataBaseSubscription: Subscription = null;
  private subscriptions: Subscription[] = [];
  isLoadingHistory: Boolean = false;
  batchName = '';
  batchData: any;
  downloadJsonHref: SafeUrl;

  previousHistorySettings = '';
  historySetting = '';
  prevHistoryValue = null;
  days: number = null;
  entries: number = null;
  isBtnDisable = true;
  payload = {};
  MaxDaysLimit = 30;
  MaxEntriesLimit = 1000;

  @ViewChild('createDemoDatabaseModal') createDemoDatabaseModal: CreateDemoDatabaseModalComponent;
  @ViewChild('confirmationModal') confirmationModal: ModalDirective;
  @ViewChild('batchModal') batchModal: ModalDirective;
  @ViewChild('queryHistorySettingsModal') queryHistorySettings: ModalDirective;
  @ViewChild('deleteConfirmModal') deleteConfirmModal: ModalDirective;

  resolveQueryChanges: (value: boolean) => void = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private schemaService: SchemaService,
    private confirmationDialogService: ConfirmationDialogService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private hdrPermissionService: HDRPermissionService,
    private sanitizer: DomSanitizer,
    private userSettingsService: UserSettingsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.schemaService.queryAutoCommit$.subscribe(value => {
        this.isAutoCommit = value;
      }),
      this.schemaService.lastQueryExecuted$.subscribe(value => {
        this.isLastQueryExecuted = value;
      }),
      // hide show query history page on event - recieve events from sql-console component
      this.schemaService.onShowQueryHistory$.subscribe(value => {
        this.showQueryHistory = value;
        if(value) {
          this.getSqlHistory();
        }
      })
    );

    this.userFormGroup = new UntypedFormGroup({
      type: new UntypedFormControl(null),
      username: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required)
    });

    this.historyFormGroup = new FormGroup({
      historySettingType: new FormControl('', Validators.required),
      days: new FormControl(null, this.daysValidator),
      entries: new FormControl(null, this.entriesValidator)
    });

    this.userFormGroup.controls.type.valueChanges.subscribe(type => {
      const controls = this.userFormGroup.controls;
      if (type === 'other') {
        controls.username.enable();
        controls.password.enable();
      } else {
        controls.username.disable();
        controls.password.disable();
      }
    });

    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      if (!this.userFormGroup.controls.type.value) {
        this.userFormGroup.controls.type.setValue(this.server.permissions.admin && this.server.hasAdminPassword ? 'admin' : 'monitor');
      }
      this.refreshDatabases();
    });

    this.dataBaseSubscription = this.databaseFormControl.valueChanges.subscribe(async (database) => {
      // on database change check for uncommitted transaction if yes then open popup for rollback or commit
      if (!this.isAutoCommit && this.isLastQueryExecuted) {
        await this.isChangeInLastQuery();
      }
      // reset SQL Editor on change DB
      this.schemaService.resetSqlEditor(true);
      this.updateDatabaseState(database);
    });

    this.schemaManagerHDR = this.hdrPermissionService.getByPermissionId('p4');
  }

  // Custom validator function for query history days
  daysValidator(control: FormControl) {
    if (control.value && control.value > 30) {
      const errors = { customError: 'Invalid Days, Maximum 30 days allowed' };
      control.setErrors(errors);
      return errors;
    } else if(control.value < 1){
        const errors = { customError: 'Invalid Days, Minimum 1 day required' };
        control.setErrors(errors);
        return errors;
    }
    return null;
  }

  // Custom validator function for query history entries
  entriesValidator(control: FormControl) {
    if (control.value && control.value > 1000) {
      const errors = { customError: 'Invalid entries, Maximum 1000 entires allowed' };
      control.setErrors(errors);
      return errors;
    } else if(control.value < 1){
      const errors = { customError: 'Invalid entries, Minimum 1 entry required' };
      control.setErrors(errors);
      return errors;
    }
    return null;
  }

  private updateDatabaseState(database: any): void {
    this.router.navigate([], { queryParams: { database: database?.name }, replaceUrl: true });
    this.createSession();
    this.queryHistory = JSON.parse(localStorage.getItem(database?.name) ?? '[]');
  }

  isChangeInLastQuery(): Promise<boolean> | boolean {
    if (!this.isAutoCommit && this.isLastQueryExecuted) {
      return new Promise<boolean>(resolve => {
        this.resolveQueryChanges = resolve;
        this.confirmationModal.show();
      });
    } else {
      return true;
    }
  }

  canDeactivate(): Promise<boolean> | boolean {
    this.isPageChange = true;
    // hide query history before page change.
    this.schemaService.setShowQueryHistroy(false);
    if (!this.isAutoCommit && this.isLastQueryExecuted) {
      return new Promise<boolean>(resolve => {
        this.resolveQueryChanges = resolve;
        this.confirmationModal.show();
      });
    } else {
      return true;
    }
  }

  getCreateIndexData(createIndexData: any) {
    this.isCreateIndex = true;
    this.createIndexData = createIndexData;
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.translate.get('schemaManager.title').subscribe((text: string) => {
      this.breadcrumb = ServerBreadcrumb.build(this.server, [{ name: text }]);
    });
  }

  refreshDatabases() {
    this.schemaService.getDatabases(this.server).subscribe(databases => {
      this.databases = databases;
      const selectedDatabase: string = this.databaseFormControl.value
        ? this.databaseFormControl.value.name
        : this.route.snapshot.queryParams.database;
      let database: InformixDatabase = null;
      if (selectedDatabase) {
        database = databases.find(v => v.name === selectedDatabase);
      }
      if (!database) {
        database = databases.find(v => v.name === 'sysmaster');
      }
      if (!database) {
        database = databases[0] || null;
      }
      this.databaseFormControl.setValue(database, { emitEvent: false });
      if (!this.session) {
        this.createSession();
      }
    }, err => {
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.databasesLoadError = err.error.err;
      } else {
        this.databasesLoadError = 'An unknown error occurred';
      }
      console.error(err);
    });
  }

  createSession() {
    const database = this.databaseFormControl.value as InformixDatabase;
    if (!database) {
      return;
    }

    if (this.session) {
      if (this.session.database.name === database.name) {
        return;
      }

      this.schemaService.closeSession(this.session).subscribe(null);
      this.session = null;
    }

    const f = this.userFormGroup.value;
    this.sessionLoading = true;
    this.schemaService.createSession(database, f.type, f.username, f.password).subscribe(session => {
      this.session = session;
      this.sessionError = null;
      this.getSqlHistory();
    }, err => {
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.sessionError = err.error.err;
      } else {
        this.sessionError = 'An unknown error occurred';
        console.error(err);
      }
    }).add(() => {
      this.sessionLoading = false;
    });
  }

  async changeUser() {
    if (!this.isAutoCommit && this.isLastQueryExecuted) {
      await this.isChangeInLastQuery();
    }

    this.isChangingUser = true;
    this.changeUserError = null;
  }

  cancelChangeUser() {
    this.isChangingUser = false;
    this.userFormGroup.patchValue({
      type: this.session.type,
      username: this.session.type === 'other' ? this.session.username : null,
      password: this.session.password
    });
  }

  onChangeUserSubmit() {
    const database = this.databaseFormControl.value as InformixDatabase;
    if (!database) {
      return;
    }

    const f = this.userFormGroup.value;
    this.sessionLoading = true;
    this.schemaService.createSession(database, f.type, f.username, f.password).subscribe(session => {
      this.session = session;
      this.sessionError = null;
      this.isChangingUser = false;
      setTimeout(() => {
        // reset SQL Editor on user change
        this.schemaService.resetSqlEditor(true);
      }, 100);
    }, err => {
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.changeUserError = err.error.err;
      } else {
        this.changeUserError = 'An unknown error occurred';
        console.error(err);
      }
    }).add(() => {
      this.sessionLoading = false;
    });
  }

  closeCreateIndexModal() {
    this.session = null;
    this.refreshDatabases();
    this.isCreateIndex = false;
  }

  dropDatabaseConfirm() {
    if (this.schemaManagerHDR && !this.schemaManagerHDR.isAllow()) {
      return;
    }
    this.confirmationDialogService.show('remove the database ' + this.databaseFormControl.value.name +
      ', and delete the tables and data stored in it?',
      () => this.closeSession());
  }

  closeSession() {
    if (this.session) {
      this.schemaService.closeSession(this.session).subscribe(() => {
        this.dropDatabase();
      });
    } else {
      this.dropDatabase();
    }
  }

  dropDatabase() {
    this.schemaService.dropDatabase(this.server, this.databaseFormControl.value.name)
      .subscribe(res => {

        this.notificationsService.pushGenericNotification(res.return_code, res.result_message, (type) => {
          if (type.isSuccess || type.isInfo) {
            this.session = null;
            this.refreshDatabases();
          }
        });
      }, err => {
        this.notificationsService.pushErrorNotification('Could not drop the database. ' + (err.error ? err.error.err : err));
      });
  }

  createDemoDatabase() {
    if (this.schemaManagerHDR && !this.schemaManagerHDR.isAllow()) {
      return;
    }
    this.createDemoDatabaseModal.show(this.server);
  }

  closeCreateDatabaseModal() {
    this.session = null;
    this.refreshDatabases();
    this.isCreateDatabase = false;
  }

  createTable() {
    this.isCreateTable = true;
    this.selectedDatabase = this.databaseFormControl.value.name;
  }

  closeCreateTable() {
    this.session = null;
    this.refreshDatabases();
    this.isCreateTable = false;
  }

  toggleShow(flag: boolean = false) {
    if(flag) {
      if(this.userFormGroup.controls.password.value === '') {
        this.showPassword = false;
      }
      return;
    }
    this.showPassword = !this.showPassword;
  }

  /**
   * export queries into sql file
   *
   * @param query
   */
  exportQuery(query, isTrimStements: boolean = false) {
    let concatenatedQuery;
    if (isTrimStements) {
      const statement_list = query.statement_list.filter(item => item !== '');
      concatenatedQuery = statement_list.map(item => item.endsWith(';') ? item.trim() + '\n' : item.trim() + ';\n').join('');
    } else {
      concatenatedQuery = query.query;
    }
    const blob = new Blob([concatenatedQuery], { type: 'text/sql' });
    const url = URL.createObjectURL(blob);
    this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(url);
    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      window.navigator.msSaveBlob(blob, 'sql_queries.sql');
    }
  }

   /**
    * load queries into the sql editor
    *
    * @param query
    */
  loadQuery(query, isTrimStements: boolean = false) {
    let concatenatedQuery;
    if (query && query.statement_list.length) {
      if (isTrimStements) {
        const statement_list = query.statement_list.filter(item => item !== '');
        const lastIndex = statement_list.length - 1;
        concatenatedQuery = statement_list.map((item, index) => index === lastIndex ?
          (item.endsWith(';') ? item.trim() : item.trim() + ';') :
          (item.endsWith(';') ? item.trim() + '\n' : item + ';\n')).join('');
      } else {
        concatenatedQuery = query.query;
      }
      this.showQueryHistory = !this.showQueryHistory;
      this.schemaService.setSelectedQueryHistory(concatenatedQuery);
    }
  }

   /**
    * API to get SQL history
    */
  getSqlHistory() {
    this.getConfigData();
    const database = this.databaseFormControl.value as InformixDatabase;
    if (!database) {
      return;
    }
    this.isLoadingHistory = true;
    this.schemaService.getSqlHistory(this.session, this.server).subscribe(history => {
      this.queryHistory = history;
      this.queryHistory.reverse();
      this.isLoadingHistory = false;
    }, err=>{
      this.isLoadingHistory = false;
    });
  }

   /**
    * update query history setting configurations
    */
  getConfigData() {
    this.userService.requestCurrentUser().then(user => {
      if (user.settings.statementHistoryConfiguration.hasOwnProperty('maxDays')) {
        this.previousHistorySettings = 'day';
        this.prevHistoryValue = user.settings.statementHistoryConfiguration.maxDays;
        this.historyFormGroup.controls.historySettingType.setValue(this.previousHistorySettings);
        this.historyFormGroup.controls.days.setValue(this.prevHistoryValue);
        this.historyFormGroup.get('entries').disable();
      }
      if (user.settings.statementHistoryConfiguration.hasOwnProperty('maxCount')) {
        this.previousHistorySettings = 'entry';
        this.entries = this.prevHistoryValue = user.settings.statementHistoryConfiguration.maxCount;
        this.historyFormGroup.controls.historySettingType.setValue(this.previousHistorySettings);
        this.historyFormGroup.controls.entries.setValue(this.prevHistoryValue);
        this.historyFormGroup.get('days').disable();
      }
    }).catch(err => {
      this.notificationsService.pushErrorNotification('Unable to get user preferences' + err.error ? err.error.err : err);
    });
  }

  /**
   * API to update SQL history
   */
  updateSqlHistory(query){
    query.edit = false;
    const payload = {
      querySetName: this.batchName,
      querySetId: query.id.toString()
    };
    this.schemaService.updateSqlHistory(this.session, this.server, payload).subscribe(res => {
      if(res.ok) {
        this.notificationsService.pushSuccessNotification('Successfully updated query history');
        this.getSqlHistory();
      }
      this.batchName = '';
    }, err=>{
      this.notificationsService.pushErrorNotification('Could not update the query history. '+ err);
    });
  }

  // open confirmation modal to delete query history
  removeQuery(query, isHideBatchModal: boolean = false){
    if(isHideBatchModal){
      this.batchModal.hide();
    }
    // wait for previously opened query-history modal to close
    setTimeout(()=>{
      this.selectedQueryHistory = query;
      this.deleteConfirmModal.show();
    }, 100);
  }

  /**
   * API to remove specific or all SQL history
   */
  removeQueryHistory(isRemoveAll: boolean = false, query: any = null) {
    let deleteSetIds;
    if (isRemoveAll) {
      deleteSetIds = this.queryHistory.map(item => item.id);
    } else {
      deleteSetIds = [query.id];
    }
    const payload = {
      deleteSetId: deleteSetIds,
    };
    this.schemaService.removeSqlHistory(this.session, this.server, payload).subscribe((res: any) => {
      if (res.ok) {
        const msg = isRemoveAll ? 'all' : '';
        this.notificationsService.pushSuccessNotification(`Successfully deleted ${msg} query history`);
        this.getSqlHistory();
        this.batchModal.hide();
      }
    }, err=>{
      this.notificationsService.pushErrorNotification('error removing the query history. '+ err);
    });
  }

  /**
   * Open popup to show batch query details
   * add semicolon(;) at end of each statement to separate
   */
  showBatchModal(query: any) {
    const statement_list = query.statement_list.filter(item => item !== '');
    const concatenatedQuery = statement_list.map(item => item.endsWith(';') ? item.trim() + '\n': item.trim() + ';\n').join('');
    const statementList = statement_list.map(item => item.endsWith(';') ? item.trim() + '\n': item.trim() + ';\n');
    this.batchData = {
      name: query.name,
      query: concatenatedQuery,
      statement_list: statementList,
      id: query.id
    };
    this.batchModal.show();
  }

  // rollback last executed statements
  rollback() {
    this.schemaService.setAutoCommit(true);
    this.schemaService.setLastQueryExecuted(false);
    this.schemaService.setRollackSubject(true);
    this.resolveQueryChanges(true);
    this.isPageChange = false;
    this.confirmationModal.hide();
  }

  // commit last executed statements
  commit() {
    this.schemaService.setAutoCommit(true);
    this.schemaService.setLastQueryExecuted(false);
    this.schemaService.setCommitSubject(true);
    this.resolveQueryChanges(true);
    this.isPageChange = false;
    this.confirmationModal.hide();
  }

  cancel() {
    this.resolveQueryChanges(false);
    this.isPageChange = false;
    this.confirmationModal.hide();
  }

  ngOnDestroy() {
    if (this.dataBaseSubscription) {
      this.dataBaseSubscription.unsubscribe();
    }
    // Unsubscribe from all subscriptions to avoid memory leaks
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // toggle(Enable, Disable) query history delete options(Days, Entries)
  changeQueryHistory() {
    if (this.historyFormGroup.controls.historySettingType.value === 'day') {
      this.historyFormGroup.get('days').enable();
      this.historyFormGroup.get('entries').disable();
      if(this.historyFormGroup.controls.days.value === null) {
        this.historyFormGroup.controls.days.setValue(this.MaxDaysLimit);
      }
    } else {
      this.historyFormGroup.get('days').disable();
      this.historyFormGroup.get('entries').enable();
      if(this.historyFormGroup.controls.entries.value === null) {
        this.historyFormGroup.controls.entries.setValue(this.MaxEntriesLimit);
      }
    }
  }

 /*
  * prepare the payload by adding configuration fields that have changed
  */
  preparePayload() {
    if (this.historyFormGroup.controls.historySettingType.value === 'day') {
      this.payload['statementHistoryConfiguration'] = { maxDays: this.historyFormGroup.controls.days.value };
    } else if (this.historyFormGroup.controls.historySettingType.value === 'entry') {
      this.payload['statementHistoryConfiguration'] = { maxCount: this.historyFormGroup.controls.entries.value };
    }
  }

 /*
  * save query history configuration
  */
  saveConfig() {
    this.preparePayload();
    this.userSettingsService.changeUserPreferences(this.payload)
      .subscribe(response => {
        this.notificationsService.pushSuccessNotification('Query history settings successfully updated');
        this.getConfigData();
        this.isBtnDisable = true;
        this.queryHistorySettings.hide();
      }, err => {
        this.notificationsService.pushErrorNotification('The default dashboard time slice could not be saved. '
          + err.error ? err.error.err : err);
      });
  }
}
