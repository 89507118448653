/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AlertPreferencesSettingsComponent } from './alerts/alertPreferencesSettings.component';
import { AlertSettingsComponent } from './alerts/alertSettings.component';
import { AlertSettingsService } from './alerts/alertSettings.service';
import { ChangePasswordComponent } from './changePassword.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { UserEmailSettingsComponent } from './alerts/emailSettings.component';
import { UserSettingsComponent } from './user-settings.component';
import { UserSettingsService } from './user-settings.service';
import { UserTwilioSettingsComponent } from './alerts/twilioSettings.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    AlertModule,
    TooltipModule
  ],
  exports: [
    UserEmailSettingsComponent,
    UserTwilioSettingsComponent,
    ChangePasswordFormComponent
  ],
  declarations: [
    UserSettingsComponent,
    AlertPreferencesSettingsComponent,
    AlertSettingsComponent,
    UserEmailSettingsComponent,
    UserTwilioSettingsComponent,
    ChangePasswordComponent,
    ChangePasswordFormComponent
  ],
  providers: [
    UserSettingsService,
    AlertSettingsService
  ],
})
export class UserSettingsModule { }
