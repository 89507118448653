<section class="main-container" style="margin-left: 0">
  <button class="btn btn-primary" style="margin: 10px 0" (click)="hideControl = !hideControl">Toggle Control</button>

  <form [formGroup]="formGroup">
    <div class="form-group">
      <input type="text" class="form-control" formControlName="good" tooltip="" appExtendedFormControl>
      <input type="text" class="form-control" formControlName="test" tooltip="" appExtendedFormControl [hidden]="hideControl">
    </div>
    <div class="form-group">
      <label>Multi-select dropdown:</label>
      <app-dropdown-multi-select style="width: 100px" [data]="dropdownData" label="Select metric..." (valueChanged)="handleDropdownValueChange($event)"></app-dropdown-multi-select>
    </div>
  </form>

  <button class="btn btn-primary" style="margin: 10px 0" [disabled]="!formGroup.valid">Submit?</button>
</section>
