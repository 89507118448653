<div class="row">
  <div class="col-10">
    <h5>
      <strong>{{'schemaManager.createTable.createQuery.viewQuery' | translate}}<span
          class="h6">{{'schemaManager.createTable.createQuery.viewQueryDesc' | translate}}</span></strong>
    </h5>
  </div>
  <div class="col-2">
    <button type="button" class="close">
      <i class="icon icon-times font-weight-bold" (click)="backFromQuery.emit('cancel')"></i></button>
  </div>
</div>
<div class="row bottomBorder topBorder queryContainer">
  <div class="col-12 py-2">
    <ng-container *ngIf="oldQuery">
      <ng-container *ngFor="let item of viewQueryObj | keyvalue: noOrder">
        <div class="pl-3 pt-2 new-line"> {{item.value}} </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="newQuery">
      <ng-container *ngFor="let item of newQueryObj | keyvalue: noOrder">
        <ng-container *ngFor="let query of item.value | keyvalue">
          <div class="pt-2 align-items-start d-flex new-line" [ngClass]="query.value === 'failed' ? 'text-danger' : ''">
            <div class="pr-3"><i class="icon"
                [ngClass]="query.value === 'success' ? 'icon-check-circle text-success' : 'icon-times-circle text-danger'"></i>
            </div>
            <div>{{query.key}}</div>
          </div>
        </ng-container>
      </ng-container>
      <div class="alert alert-info mt-4 col-9">
        <i class="icon icon-info-circle text-info"></i> {{'schemaManager.createTable.createQuery.errorMsg' | translate}}
      </div>
    </ng-container>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <button type="button" class="btn btn-secondary mr-2"
      (click)="backFromQuery.emit(backToAdvOptions ? 'backToAdv' : 'back')"> {{ 'button.back' | translate }}</button>
    <button type="button" class="btn btn-primary mr-2" (click)="createTable()" *ngIf="!newQuery">{{ 'button.create' |
      translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="backFromQuery.emit('cancel')"> {{ 'button.cancel' |
      translate }}</button>
  </div>
</div>
