/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2022. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TaskSchedulerService } from './task-scheduler.service';
import { TaskDetails } from './task-scheduler.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { DatePipe } from '@angular/common';
import { ParameterComponent } from './parameter.component';
import { InformixServer } from '../../servers/informixServer';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-scheduler.component.scss'],
  providers: [TaskSchedulerService]
})
export class TaskModalComponent implements OnInit {

  taskSchedulerForm: UntypedFormGroup;
  isEditTask: Boolean = false;
  popuptype: string;
  title: string;
  groupNames = [];
  rowId: number;
  rowData: any = null;
  checkRunFlag = false;
  isDisableDays: Boolean = false;
  sensorFlag: boolean;
  currentTask: any;
  tk_details: TaskDetails = new TaskDetails();
  frequency_checked: Boolean = false;
  stoptime_checked: Boolean = false;
  allDisabled: Boolean = false;
  submitted: Boolean = false;
  openParameterModal: Boolean = false;
  selectedGroup: string;
  currentTaskId: number;
  selectedTaskDays: Array<{ tk_day: string; checked: boolean }> = [{ tk_day: 'Monday', checked: true },
  { tk_day: 'Tuesday', checked: true },
  { tk_day: 'Wednesday', checked: true }, { tk_day: 'Thursday', checked: true },
  { tk_day: 'Friday', checked: true }, { tk_day: 'Saturday', checked: true }, { tk_day: 'Sunday', checked: true }];
  @ViewChild('addTaskSensorModal') addTaskSensorModal: ModalDirective;
  @ViewChild(ParameterComponent) parameterComponent: ParameterComponent;
  @Input() server: InformixServer = null;
  @Output() saveDone: EventEmitter<any> = new EventEmitter();

  constructor(
    private taskSchedulerService: TaskSchedulerService,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initTaskSchedulerForm();
    this.getAllGroups();
  }

  get formControls() {
    return this.taskSchedulerForm.controls;
  }

  initTaskSchedulerForm() {
    this.taskSchedulerForm = this.fb.group({
      tk_name: new UntypedFormControl(null, [Validators.required]),
      tk_group: new UntypedFormControl(null, [Validators.required]),
      tk_description: new UntypedFormControl(null, [Validators.required]),
      tk_start_hh: new UntypedFormControl(null),
      tk_start_mm: new UntypedFormControl(null),
      tk_start_ss: new UntypedFormControl(null),
      tk_stoptime_hh: new UntypedFormControl(null),
      tk_stoptime_mm: new UntypedFormControl(null),
      tk_stoptime_ss: new UntypedFormControl(null),
      tk_frequency_day: new UntypedFormControl(null),
      tk_frequency_hh: new UntypedFormControl(null),
      tk_frequency_mm: new UntypedFormControl(null),
      tk_frequency_ss: new UntypedFormControl(null),
      tk_delete_day: new UntypedFormControl(null),
      tk_delete_hh: new UntypedFormControl(null),
      tk_delete_mm: new UntypedFormControl(null),
      tk_delete_ss: new UntypedFormControl(null),
      tk_create: new UntypedFormControl(null),
      tk_result_table: new UntypedFormControl(null),
      tk_execute: new UntypedFormControl(null, [Validators.required]),
      tk_enable: new UntypedFormControl(true),
      tk_never_stoptime: new UntypedFormControl(false),
      tk_never_frequency: new UntypedFormControl(false)
    });
  }

  disableInputTime(type: string) {
    if (this.taskSchedulerForm.controls['tk_never_' + type].value) {
      if (this.taskSchedulerForm.get('tk_' + type + '_day')) {
        this.taskSchedulerForm.get('tk_' + type + '_day').disable();
      }
      this.taskSchedulerForm.get('tk_' + type + '_hh').disable();
      this.taskSchedulerForm.get('tk_' + type + '_mm').disable();
      this.taskSchedulerForm.get('tk_' + type + '_ss').disable();
    } else {
      if (this.taskSchedulerForm.get('tk_' + type + '_day')) {
        this.taskSchedulerForm.get('tk_' + type + '_day').enable();
      }
      this.taskSchedulerForm.get('tk_' + type + '_hh').enable();
      this.taskSchedulerForm.get('tk_' + type + '_mm').enable();
      this.taskSchedulerForm.get('tk_' + type + '_ss').enable();
    }
  }

  getAllGroups() {
    this.taskSchedulerService.getAllGroups(this.server).subscribe(res => {
      res.forEach(e => {
        this.groupNames.push(e.group_name);
      });
    });
  }

  makeFieldsDisable() {
    this.isDisableDays = true;
    for (const field in this.taskSchedulerForm.controls) {
      if (field) {
        const control = this.taskSchedulerForm.get(field);
        control.disable();
      }
    }
  }

  openAddTaskModal(flag: boolean, title: string, item: any) {
    this.rowData = item;
    if (item !== 'addNewPopUp') {
      this.openDetailsModal(flag, title, item);
    } else {
      this.showtaskSensorModal(flag, title, item);
      this.taskSchedulerForm.get('tk_enable').setValue(true);
    }
  }

  private showtaskSensorModal(flag: boolean, title: string, item: any) {
    if (item !== 'addNewPopUp') {
      flag = this.checkRunFlag;
      title = this.title;
      this.isEditTask = true;
      this.popuptype = 'updatepopup';
      this.rowId = item.tk_id;
      this.makeFieldsDisable();
    }
    if (flag === true) {
      this.checkRunFlag = true;
    } else {
      this.checkRunFlag = false;
    }
    if (title === 'Task') {
      this.sensorFlag = false;
    } else if (title === 'Sensor') {
      this.sensorFlag = true;
    }
    this.title = title;
    this.addTaskSensorModal.show();
    this.setValidations();
  }

  setValidations() {
    const tk_start_hh_control = this.taskSchedulerForm.get('tk_start_hh');
    const tk_start_mm_control = this.taskSchedulerForm.get('tk_start_mm');
    const tk_start_ss_control = this.taskSchedulerForm.get('tk_start_ss');
    const tk_delete_day_control = this.taskSchedulerForm.get('tk_delete_day');
    const tk_delete_hh_control = this.taskSchedulerForm.get('tk_delete_hh');
    const tk_delete_mm_control = this.taskSchedulerForm.get('tk_delete_mm');
    const tk_delete_ss_control = this.taskSchedulerForm.get('tk_delete_ss');
    const tk_create_control = this.taskSchedulerForm.get('tk_create');
    const tk_result_table_control = this.taskSchedulerForm.get('tk_result_table');
    const tk_stop_hh_control = this.taskSchedulerForm.get('tk_stoptime_hh');
    const tk_stop_mm_control = this.taskSchedulerForm.get('tk_stoptime_mm');
    const tk_stop_ss_control = this.taskSchedulerForm.get('tk_stoptime_ss');
    const tk_frequency_day_control = this.taskSchedulerForm.get('tk_frequency_day');
    const tk_frequency_hh_control = this.taskSchedulerForm.get('tk_frequency_hh');
    const tk_frequency_mm_control = this.taskSchedulerForm.get('tk_frequency_mm');
    const tk_frequency_ss_control = this.taskSchedulerForm.get('tk_frequency_ss');

    if (this.title === 'Sensor') {
      tk_create_control.setValidators([Validators.required]);
      tk_result_table_control.setValidators([Validators.required]);
      tk_delete_day_control.setValidators([Validators.required, Validators.min(0), Validators.max(99)]);
      tk_delete_hh_control.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
      tk_delete_mm_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      tk_delete_ss_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      if (this.checkRunFlag === false) {
        tk_start_hh_control.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
        tk_start_mm_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
        tk_start_ss_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      }
    } else if (this.title === 'Task' && this.checkRunFlag === false) {
      tk_start_hh_control.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
      tk_start_mm_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      tk_start_ss_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
    }
    if (!this.taskSchedulerForm.controls['tk_never_stoptime'].value && this.checkRunFlag === false) {
      tk_stop_hh_control.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
      tk_stop_mm_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      tk_stop_ss_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
    }
    if ((!this.taskSchedulerForm.controls['tk_never_frequency'].value || this.checkRunFlag === true)) {
      tk_frequency_day_control.setValidators([Validators.required, Validators.min(0), Validators.max(99)]);
      tk_frequency_hh_control.setValidators([Validators.required, Validators.min(0), Validators.max(23)]);
      tk_frequency_mm_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
      tk_frequency_ss_control.setValidators([Validators.required, Validators.min(0), Validators.max(59)]);
    }
    for (const field in this.taskSchedulerForm.controls) {
      if (field) {
        const control = this.taskSchedulerForm.get(field);
        control.updateValueAndValidity();
      }
    }
  }

  transformTime(interval: any): string {
    if (interval === null || interval === undefined) {
      return interval;
    }
    let seconds = 0;
    if (typeof (interval) === 'number') {
      seconds = interval;
    } else if (interval.IntervalDF && interval.IntervalDF.seconds) {
      seconds = interval.IntervalDF.seconds;
    } else {
      throw new Error('unexpected data for intervalDF: ' + interval);
    }
    let intervalStr = '';
    if (seconds < 0) {
      intervalStr += '-';
      seconds *= -1;
    }
    const days = Math.floor(seconds / 86400);
    seconds = seconds % 86400;
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    if (days < 10) {
      intervalStr += '0';
    }
    intervalStr += days;
    intervalStr += ':';
    if (hours < 10) {
      intervalStr += '0';
    }
    intervalStr += hours;
    intervalStr += ':';
    if (minutes < 10) {
      intervalStr += '0';
    }
    intervalStr += minutes;
    intervalStr += ':';
    if (seconds < 10) {
      intervalStr += '0';
    }
    intervalStr += seconds;
    return intervalStr;
  }

  private openDetailsModal(flag: boolean, title: string, item: any) {
    return this.taskSchedulerService.getTaskByID(this.server, item.tk_id).subscribe((res: any) => {
      this.currentTask = res;
      this.tk_details.tk_type = res.tk_type;
      if (this.tk_details.tk_type === 'STARTUP TASK' || this.tk_details.tk_type === 'STARTUP SENSOR') {
        this.checkRunFlag = true;
      } else {
        this.checkRunFlag = false;
      }
      if (this.tk_details.tk_type === 'STARTUP TASK' || this.tk_details.tk_type === 'TASK') {
        this.title = 'Task';
      } else if (this.tk_details.tk_type === 'STARTUP SENSOR' || this.tk_details.tk_type === 'SENSOR') {
        this.title = 'Sensor';
      }

      if (res.tk_frequency === null) {
        this.taskSchedulerForm.get('tk_frequency_day').disable();
        this.taskSchedulerForm.get('tk_frequency_hh').disable();
        this.taskSchedulerForm.get('tk_frequency_mm').disable();
        this.taskSchedulerForm.get('tk_frequency_ss').disable();
        this.frequency_checked = true;
      }
      if (res.tk_stop_time === null) {
        this.taskSchedulerForm.get('tk_stoptime_hh').disable();
        this.taskSchedulerForm.get('tk_stoptime_mm').disable();
        this.taskSchedulerForm.get('tk_stoptime_ss').disable();
        this.stoptime_checked = true;
      }

      this.selectedTaskDays.filter(day => day.tk_day.includes('Monday'))[0].checked = res.tk_monday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Tuesday'))[0].checked = res.tk_tuesday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Wednesday'))[0].checked = res.tk_wednesday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Thursday'))[0].checked = res.tk_thursday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Friday'))[0].checked = res.tk_friday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Saturday'))[0].checked = res.tk_saturday;
      this.selectedTaskDays.filter(day => day.tk_day.includes('Sunday'))[0].checked = res.tk_sunday;

      const frequencyUpdated: any = (res.tk_frequency !== null) ? this.transformTime(res.tk_frequency).split(':') : null;
      const datadeleteUpdated: any = (res.tk_delete !== null) ? this.transformTime(res.tk_delete).split(':') : null;
      const starttimeUpdated: any =
          (res.tk_start_time !== null) ? this.datePipe.transform(res.tk_start_time.$date, 'HH:mm:ss').split(':') : null;
      const stoptimeUpdated: any =
          (res.tk_stop_time !== null) ? this.datePipe.transform(res.tk_stop_time.$date, 'HH:mm:ss').split(':') : null;

      this.taskSchedulerForm.setValue({
        tk_name: res.tk_name,
        tk_group: res.tk_group,
        tk_description: res.tk_description,
        tk_start_hh: (starttimeUpdated === null) ? null : starttimeUpdated[0],
        tk_start_mm: (starttimeUpdated === null) ? null : starttimeUpdated[1],
        tk_start_ss: (starttimeUpdated === null) ? null : starttimeUpdated[2],
        tk_stoptime_hh: (stoptimeUpdated === null) ? null : stoptimeUpdated[0],
        tk_stoptime_mm: (stoptimeUpdated === null) ? null : stoptimeUpdated[1],
        tk_stoptime_ss: (stoptimeUpdated === null) ? null : stoptimeUpdated[2],
        tk_frequency_day: (frequencyUpdated === null) ? null : frequencyUpdated[0],
        tk_frequency_hh: (frequencyUpdated === null) ? null : frequencyUpdated[1],
        tk_frequency_mm: (frequencyUpdated === null) ? null : frequencyUpdated[2],
        tk_frequency_ss: (frequencyUpdated === null) ? null : frequencyUpdated[3],
        tk_delete_day: (datadeleteUpdated === null) ? null : datadeleteUpdated[0],
        tk_delete_hh: (datadeleteUpdated === null) ? null : datadeleteUpdated[1],
        tk_delete_mm: (datadeleteUpdated === null) ? null : datadeleteUpdated[2],
        tk_delete_ss: (datadeleteUpdated === null) ? null : datadeleteUpdated[3],
        tk_create: res.tk_create,
        tk_result_table: res.tk_result_table,
        tk_execute: res.tk_execute,
        tk_enable: res.tk_enable,
        tk_never_stoptime: (res.tk_stop_time === null) ? true : false,
        tk_never_frequency: (res.tk_frequency === null) ? true : false
      });
      this.showtaskSensorModal(flag, title, item);
    });
  }

  selectGroup(event: any) {
    this.selectedGroup = event.target.options[event.target.selectedIndex].text;
  }

  addNew() {
    let title_name: string;

    if (this.taskSchedulerForm.invalid) {
      this.submitted = true;
      return;
    }
    const start_time = this.taskSchedulerForm.controls['tk_start_hh'].value + ':' +
      this.taskSchedulerForm.controls['tk_start_mm'].value + ':' + this.taskSchedulerForm.controls['tk_start_ss'].value;
    const data_delete = this.taskSchedulerForm.controls['tk_delete_day'].value + ' ' +
      this.taskSchedulerForm.controls['tk_delete_hh'].value + ':' +
      this.taskSchedulerForm.controls['tk_delete_mm'].value + ':' + this.taskSchedulerForm.controls['tk_delete_ss'].value;
    const stop_time = this.taskSchedulerForm.controls['tk_stoptime_hh'].value + ':' +
      this.taskSchedulerForm.controls['tk_stoptime_mm'].value + ':' + this.taskSchedulerForm.controls['tk_stoptime_ss'].value;
    const frequency = this.taskSchedulerForm.controls['tk_frequency_day'].value + ' ' +
      this.taskSchedulerForm.controls['tk_frequency_hh'].value + ':' +
      this.taskSchedulerForm.controls['tk_frequency_mm'].value + ':' + this.taskSchedulerForm.controls['tk_frequency_ss'].value;

    if (this.checkRunFlag === true) {
      title_name = 'STARTUP ' + this.title;
    } else {
      title_name = this.title;
    }
    const dataObj = {
      tk_name: this.taskSchedulerForm.controls['tk_name'].value,
      tk_description: this.taskSchedulerForm.controls['tk_description'].value,
      tk_type: title_name.toUpperCase(),
      tk_result_table: this.taskSchedulerForm.controls['tk_result_table'].value,
      tk_create: this.taskSchedulerForm.controls['tk_create'].value,
      tk_delete: this.title === 'Task' ? null : data_delete,
      tk_execute: this.taskSchedulerForm.controls['tk_execute'].value,
      tk_start_time: this.checkRunFlag ? null : start_time,
      tk_stop_time: this.taskSchedulerForm.controls['tk_never_stoptime'].value || this.checkRunFlag ? null : stop_time,
      tk_frequency: this.taskSchedulerForm.controls['tk_never_frequency'].value && !this.checkRunFlag ? null : frequency,
      tk_group: this.selectedGroup,
      tk_enable: this.taskSchedulerForm.controls['tk_enable'].value ? this.taskSchedulerForm.controls['tk_enable'].value : false,
      tk_monday: this.selectedTaskDays.filter(day => day.tk_day.includes('Monday'))[0].checked,
      tk_tuesday: this.selectedTaskDays.filter(day => day.tk_day.includes('Tuesday'))[0].checked,
      tk_wednesday: this.selectedTaskDays.filter(day => day.tk_day.includes('Wednesday'))[0].checked,
      tk_thursday: this.selectedTaskDays.filter(day => day.tk_day.includes('Thursday'))[0].checked,
      tk_friday: this.selectedTaskDays.filter(day => day.tk_day.includes('Friday'))[0].checked,
      tk_saturday: this.selectedTaskDays.filter(day => day.tk_day.includes('Saturday'))[0].checked,
      tk_sunday: this.selectedTaskDays.filter(day => day.tk_day.includes('Sunday'))[0].checked,
    };

    if (this.popuptype === 'updatepopup') {
      dataObj.tk_group = this.taskSchedulerForm.controls['tk_group'].value;
      this.taskSchedulerService.updateTask(this.server, dataObj, this.rowId).subscribe(res => {
        this.notificationsService.pushSuccessNotification('Task updated.');
        this.currentTaskId = this.rowId;
        this.saveDone.emit({ saveDone: true, openParameterModal: this.openParameterModal, currentTaskId: this.currentTaskId });
        this.openParameterModal = false;
        this.closeModal();
      }, err => this.notificationsService.pushErrorNotification(err.error ? err.error.err : err));
    } else {
      this.taskSchedulerService.createNewTask(this.server, dataObj).subscribe(res => {
        this.notificationsService.pushSuccessNotification('Task saved.');
        this.currentTaskId = res.tk_id;
        this.saveDone.emit({ saveDone: true, openParameterModal: this.openParameterModal, currentTaskId: this.currentTaskId });
        this.openParameterModal = false;
        this.closeModal();
      }, (err) =>
          this.notificationsService.pushErrorNotification((err.error && err.error.err) ? err.error.err : err));
    }
  }

  closeModal() {
    this.taskSchedulerForm.get('tk_start_hh').setValidators(null);
    this.taskSchedulerForm.get('tk_start_mm').setValidators(null);
    this.taskSchedulerForm.get('tk_start_ss').setValidators(null);
    this.taskSchedulerForm.get('tk_delete_day').setValidators(null);
    this.taskSchedulerForm.get('tk_delete_hh').setValidators(null);
    this.taskSchedulerForm.get('tk_delete_mm').setValidators(null);
    this.taskSchedulerForm.get('tk_delete_ss').setValidators(null);
    this.taskSchedulerForm.get('tk_create').setValidators(null);
    this.taskSchedulerForm.get('tk_result_table').setValidators(null);
    this.taskSchedulerForm.get('tk_stoptime_hh').setValidators(null);
    this.taskSchedulerForm.get('tk_stoptime_mm').setValidators(null);
    this.taskSchedulerForm.get('tk_stoptime_ss').setValidators(null);
    this.taskSchedulerForm.get('tk_frequency_day').setValidators(null);
    this.taskSchedulerForm.get('tk_frequency_hh').setValidators(null);
    this.taskSchedulerForm.get('tk_frequency_mm').setValidators(null);
    this.taskSchedulerForm.get('tk_frequency_ss').setValidators(null);
    this.EnableAllFields('');
    this.submitted = false;
    this.addTaskSensorModal.hide();
    this.taskSchedulerForm.reset();
    this.selectedTaskDays.map(day => day.checked = true);
    this.frequency_checked = false;
    this.stoptime_checked = false;
    this.popuptype = '';
    this.isEditTask = false;
  }
  EnableAllFields(type: any) {
    this.isDisableDays = false;
    for (const field in this.taskSchedulerForm.controls) {
      if (field) {
        const control = this.taskSchedulerForm.get(field);
        control.enable();
      }
    }

    if (type === 'editbtn') {
      this.taskSchedulerForm.get('tk_name').disable();
      this.taskSchedulerForm.get('tk_group').disable();
      if (this.taskSchedulerForm.get('tk_never_frequency').value === true) {
        this.taskSchedulerForm.get('tk_frequency_day').disable();
        this.taskSchedulerForm.get('tk_frequency_hh').disable();
        this.taskSchedulerForm.get('tk_frequency_mm').disable();
        this.taskSchedulerForm.get('tk_frequency_ss').disable();
      }
      if (this.taskSchedulerForm.get('tk_never_stoptime').value === true) {
        this.taskSchedulerForm.get('tk_stoptime_hh').disable();
        this.taskSchedulerForm.get('tk_stoptime_mm').disable();
        this.taskSchedulerForm.get('tk_stoptime_ss').disable();
      }
      this.isEditTask = false;
    }
  }

  addParams() {
    this.confirmationDialogService.show('Save Task',
      'continue? Task needs to be saved before adding a parameter.', () => this.openParametersModal());
  }

  viewParams() {
    if (!this.isEditTask) {
      this.confirmationDialogService.show('Save Task',
        'continue? Task needs to be saved before adding a parameter.', () => this.openParametersModal());
    } else {
      this.closeModal();
      this.parameterComponent.openAddParamModal(this.server, this.rowData);
    }
  }

  openParametersModal() {
    this.openParameterModal = true;
    this.addNew();
  }

  saveParam(event: any) {
    if (event === true) {
      this.saveDone.emit(true);
    }
  }
}
