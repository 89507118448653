/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';

/**
 * Note: the VALUE of this component is expressed without the unit.
 *
 * The '%' / 'KB' drop down is only used to control the min/max values
 * on the validation for the input value. The '%' / 'KB' unit is not
 * appended to the values as reported by this form component.
 */

@Component({
  selector: 'app-input-size-percent-or-kb',
  template: `
	<form class="form-inline" [formGroup]="formGroup">
		<input type="number" class="form-control" min="0" formControlName="value">
		<select class="form-control ml-2" formControlName="unit">
			<option *ngFor="let unit of unitOptions" value="{{unit}}">{{unit}}</option>
		</select>
	</form>
	`,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SizeInputPercentOrKBComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: SizeInputPercentOrKBComponent, multi: true }
  ]
})
export class SizeInputPercentOrKBComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() minKB: number;
  @Input() maxKB: number;

  private onChangeFunction: any = null;

  formGroup: UntypedFormGroup;
  disabled = false;

  unitOptions: string[] = ['%', 'KB'];

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      value: new UntypedFormControl('0', [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      unit: new UntypedFormControl(this.unitOptions[0], Validators.required)
    });

    this.formGroup.valueChanges.subscribe(() => {
      const controls = this.formGroup.controls;
      const value = parseInt(controls['value'].value, 10);
      if (this.onChangeFunction) {
        this.onChangeFunction(value);
      }
    });
  }

  writeValue(value: any) {
    if (typeof value === 'number') {
      this.formGroup.controls['value'].setValue(value);
      if (value <= 100) {
        this.formGroup.controls['unit'].setValue(this.unitOptions[0]);
      } else {
        this.formGroup.controls['unit'].setValue(this.unitOptions[1]);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any) {

  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (c.value === null) {
      return { required: true };
    }
    if (this.formGroup.value['unit'] === '%') {
      if (c.value >= 0 && c.value <= 100) {
        return null;
      } else {
        return { customError: 'Percentage values must be between 0 and 100' };
      }
    } else {
      if (isNaN(c.value) || (this.minKB && c.value < this.minKB)) {
        return { min: this.minKB };
      } else if (this.maxKB && c.value > this.maxKB) {
        return { max: this.maxKB };
      } else {
        return null;
      }
    }
  }
}
