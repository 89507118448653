/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022, 2023. All Rights Reserved.
 *******************************************************************************/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { AlertingModule } from '../alerting/alerting.module';
import { MonitoringModule } from '../monitoring/monitoring.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { AddServerComponent } from './add-server.component';
import { DashboardServerAvailabilityComponent } from './dashboard-server-availability.component';
import { DashboardServerBufferPoolsComponent } from './dashboard-server-buffer-pools.component';
import { DashboardServerDeviceComponent } from './dashboard-server-device.component';
import { DashboardServerHostComponent } from './dashboard-server-host.component';
import { DashboardServerInfoComponent } from './dashboard-server-info.component';
import { DashboardServerSessionsComponent } from './dashboard-server-sessions.component';
import { DashboardServerStatusComponent } from './dashboard-server-status.component';
import { DashboardServerStoragePerformanceComponent } from './dashboard-server-storage-performance.component';
import { DashboardServerThreadsComponent } from './dashboard-server-threads.component';
import { EditServerComponent } from './edit-server.component';
import { ErrorForbiddenComponent } from './forbidden.component';
import { InformixServerService } from './informixServer.service';
import { LogsModule } from './logs/logs.module';
import { OnconfigModule } from './configuration/onconfig.module';
import { NotFoundComponent } from './not-found.component';
import { PerformanceModule } from './performance/performance.module';
import { ServerAlertingComponent } from './server-alerting.component';
import { ServerInfoFormComponent } from './server-info-form.component';
import { ServerResolver } from './server-resolver.service';
import { ServerDashboardComponent } from './serverDashboard.component';
import { ServerMonitoringComponent } from './serverMonitoring.component';
import { ServerPermissionsComponent } from './serverPermissions.component';
import { ServersComponent } from './servers.component';
import { SchemaModule } from './schema/schema.module';
import { SqltraceModule } from './sqltrace/sqltrace.module';
import { StorageModule } from './storage/storage.module';
import { SystemModule } from './system/system.module';
import { AgentInfoComponent } from './agent/agent-info/agent-info.component';
import { AgentDeployComponent } from './agent/agent-deploy/agent-deploy.component';
import { PrivilegesModule } from './privileges/privileges.module';
import { AutoUpdateStatisticsModule } from './autoUpdateStatistics/autoUpdateStatistics.module';
import { DashboardModule } from '../monitoring/dashboard/dashboard.module';
import { TaskSchedulerModule } from './task-scheduler/task-scheduler.module';
import { MemoryManagementModule } from './memory-manager/memory-manager.module';
import { HighAvailabilityModule } from './high-availability/high-availability.module';
import { ConnectionManagerModule } from './connection-manager/connection-manager.module';
import { IncidentsModule } from '../incidents/incidents.module';
import { ServerIncidentsComponent } from './serverIncidents.component';
import { EnterpriseReplicationModule } from './enterprise-replication/enterprise-replication.module';
import { SystemReportsModule } from './system-reports/system-reports.module';
import { SocketService } from './socket.service';
import { ReplicatesComponent } from './enterprise-replication/replicates/replicates.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    OnconfigModule,
    LogsModule,
    PerformanceModule,
    StorageModule,
    SystemModule,
    ReactiveFormsModule,
    AlertModule,
    MonitoringModule,
    PermissionsModule,
    BsDropdownModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    SchemaModule,
    SqltraceModule,
    AlertingModule,
    PrivilegesModule,
    HighAvailabilityModule,
    ConnectionManagerModule,
    AutoUpdateStatisticsModule,
    TaskSchedulerModule,
    DashboardModule,
    MemoryManagementModule,
    IncidentsModule,
    EnterpriseReplicationModule,
    SystemReportsModule
  ],
  declarations: [
    AddServerComponent,
    DashboardServerAvailabilityComponent,
    DashboardServerDeviceComponent,
    DashboardServerInfoComponent,
    DashboardServerBufferPoolsComponent,
    DashboardServerHostComponent,
    DashboardServerSessionsComponent,
    DashboardServerStatusComponent,
    DashboardServerStoragePerformanceComponent,
    DashboardServerThreadsComponent,
    EditServerComponent,
    ServersComponent,
    ServerDashboardComponent,
    AgentInfoComponent,
    ServerMonitoringComponent,
    ServerAlertingComponent,
    ServerPermissionsComponent,
    ServerIncidentsComponent,
    NotFoundComponent,
    ErrorForbiddenComponent,
    AgentDeployComponent,
    ReplicatesComponent
  ],
  exports: [
  ],
  providers: [
    InformixServerService,
    SocketService,
    ServerResolver  ],
})
export class ServersModule { }
