/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AbstractQuery } from './abstract-query';
import { QueryResultPages } from './query-results';

export class Query extends AbstractQuery {
  private wasRun = false;
  private hasMore = false;
  private params: any[] = null;
  private resultSetId: number;


  public setParams(params: any[]) {
    this.params = params;
  }

  public hasNext(): boolean {
    return (!this.wasRun || this.hasMore);
  }

  public next(): Observable<QueryResultPages> {
    if (!this.hasNext()) {
      throwError('No more data available.');
      return;
    }

    let observable: Observable<any>;
    if (!this.wasRun) {
      this.wasRun = true;
      observable = this.restdb.runQuery(this.session, this.query, this.params);
    } else {
      observable = this.restdb.getMoreData(this.session, this.resultSetId);
    }

    return observable.pipe(map(json => {
      if (json.output !== undefined) {
        if (typeof json.runTime === 'number') {
          this.runTime = json.runTime;
        }
        return new QueryResultPages(json.output);
      } else {
        return new QueryResultPages([json]);
      }
    }), catchError(err => {
      if (err.error && err.error?.output) {
        /*
          * when isContinueOnFailure=true and failed one of the query, then API return error with query result.
          * below functionts will map other query results
          */
        const QueryResult = new QueryResultPages(err.error.output);
        err.error.output = QueryResult.output;
      }
      this.hasMore = false;
      throw err;
    }));
  }

  setHasNext(hasMore: boolean, resultSetId: number): void {
    this.hasMore = hasMore;
    if(resultSetId !== undefined){
      this.resultSetId = resultSetId;
    }
  }
}
