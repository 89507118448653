<app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
<app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
<div class="container-fluid page-container">
  <div class="row">
    <div class="col-8">
      <h4><strong>
        {{ 'systemReports.tableBufferPoolActivity' | translate }}
        <i *ngIf="!reportData && isLoading" class="icon icon-circle-notch icon-spin ml-2"></i>
      </strong>
      </h4>
    </div>
  </div>
  <div class="row">    
    <div class="col-3 mb-2 d-flex">
      <label class="pr-2 col-form-label">{{ 'database.database' | translate }}</label>
      <select class="form-control" (change)="changeDatabase($event)">
        <option value="" [disabled]="isLoading">-- {{ 'database.selectDatabase' | translate }} --</option>
        <option *ngFor="let database of databases" value="{{database}}">{{database}}
        </option>
      </select>
    </div>
    <div class="col-9 col-lg-9 pt-1" [ngStyle]="{'margin-bottom': (!reportData.length) ? '24px' : '16px'}" *ngIf="reportData">
      <app-checkbox [checked]="isCatalog" (change)="includeCatalog($event)">
        {{ 'tablesIndexes.systemCatalogLabel' | translate }} 
      </app-checkbox>
    </div>
  </div>

  <app-data-table *ngIf="reportData" class="table-responsive" [enableFilter]="false" [data]="reportData"
    [sortDescending]="true" sortBy="cache_percent" [rowsPerPage]="20" [searchLabel]="'systemReports.searchTableBufferPoolActivity' | translate">
    <app-data-table-column [title]="'database.database' | translate" property="dbsname" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.object' | translate" property="tabname" [sortable]="true"[searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.totalRows' | translate" property="total_rows" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.totalPages' | translate" property="total_pages" [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.bufferedPages' | translate" property="buffered_pages"
      [sortable]="true" [searchable]="true">
    </app-data-table-column>
    <app-data-table-column [title]="'systemReports.percentCached' | translate" property="cache_percent"
      [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-item="item">
        {{item}}%
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>
