<div class="card card-body">
  <h4 class="card-title">Server Info</h4>
  <div class="row">
    <div class="col-2 text-xl-right">
      VPs:
    </div>
    <div class="col-10" *ngIf="totalVPs && cpuVPs">
      {{totalVPs}} total, {{cpuVPs}} cpu
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-2 text-xl-right">
      Version:
    </div>
    <div class="col-10">
      {{serverInfo.version}}
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-2 text-xl-right">
      Uptime:
    </div>
    <div class="col-10">
      {{serverUptime}}
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-2 text-xl-right">
      Host:
    </div>
    <div class="col-10">
      {{server.hostname}}
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-2 text-xl-right">
      OS:
    </div>
    <div class="col-10" *ngIf="serverHostInfo">
      {{serverHostInfo.os_name}} {{serverHostInfo.os_machine}} {{serverHostInfo.os_release}}
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-2 text-xl-right">
      IWA configured:
    </div>
    <div class="col-10" *ngIf="serverIWAInfo">
      {{(serverIWAInfo.hasIWA) ? 'Yes' : 'No'}}
    </div>
  </div>
</div>
