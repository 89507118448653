/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2021. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SchemaService } from '../../schema.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from '../create-table.model';
import { InformixSQLSession } from '../../informix-sql-session';

@Component({
  selector: 'app-create-table-query',
  templateUrl: './create-table-query.component.html',
  styleUrls: ['../create-table.component.scss']
})
export class CreateTableQueryComponent {
  @Input() viewQueryObj;
  @Input() session: InformixSQLSession;
  @Input() dataObj: Table;
  @Input() backToAdvOptions = false;
  @Output() backFromQuery = new EventEmitter<any>();

  newQuery = false;
  oldQuery = true;
  newQueryObj: any;

  constructor(private schemaService: SchemaService,
    private notificationsService: NotificationsService,
    private translate: TranslateService) { }

  createTable() {
    this.dataObj.isExecute = true;
    if (this.dataObj['columnsList']) {
      delete this.dataObj['columnsList'];
    }
    this.schemaService.createTable(this.session, this.dataObj).subscribe(res => {
      this.translate.get('schemaManager.createTable.successMsg.createTableSuccess', { tableName: this.dataObj.tableName })
        .subscribe((text: string) => {
          this.notificationsService.pushSuccessNotification(text);
        });
      this.backFromQuery.emit('cancel');
    }, err => {
      console.log('create table err', err);
      this.newQuery = true;
      this.oldQuery = false;
      this.newQueryObj = err.error;
      this.notificationsService.pushErrorNotification(err.error.err);
    });
  }

  noOrder(a, b) {
    return 0;
  }
}
