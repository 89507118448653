/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Dashboard } from '../../monitoring/dashboard';
import { StorageDashboardSummaryStatsComponent } from './storage-dashboard-summary-stats.component';
import { StorageDashboardSummaryUsageComponent } from './storage-dashboard-summary-usage.component';

export const storageDashboard: Dashboard = {
  title: 'Storage',
  components: {
    storageSummaryStats: StorageDashboardSummaryStatsComponent,
    storageSummaryUsage: StorageDashboardSummaryUsageComponent
  },
  rows: [
    {
      charts: [
        {
          component: 'storageSummaryStats',
          config: {}
        },
        {
          component: 'storageSummaryUsage',
          config: {}
        },
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'os_disk_usage',
            title: 'OS Device Utilization',
            metricList: ['percent_used', 'used_size', 'free_size'],
            metricSelect: {
              label: 'Metric',
              default: 'percent_used'
            }
          }
        }
      ]
    },
    {
      charts: [
        {
          component: 'chart',
          config: {
            sensorId: 'dbspace_usage',
            metricList: ['percent_used', 'percent_free'],
            metricSelect: {
              label: 'Metric',
              default: 'percent_used'
            },
            primaryKeySelect: {
              label: 'DBSpace'
            }
          }
        },
      ]
    }
  ]
};
