/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

interface TimeSlice {
  name: string;
  value: number;
}

@Component({
  selector: 'app-time-slider',
  templateUrl: 'time-slider.html',
  styleUrls: ['time-slider.scss']
})
export class TimeSliderComponent implements OnInit, OnChanges {

  @Input() minTimestamp: number = null;
  @Input() maxTimestamp: number = null;

  @Output() eventChanged = new EventEmitter<any>();

  selectedMin: number = null;
  selectedMax: number = null;

  sliderStyle: any = null;

  timeSlices: TimeSlice[] = [
    { name: '5 minutes', value: 300000 },
    { name: '1 hour', value: 3600000 },
    { name: '4 hours', value: 14400000 },
    { name: '1 day', value: 24 * 3600000 },
    { name: '7 days', value: 7 * 24 * 3600000 },
    { name: '30 days', value: 30 * 24 * 3600000 }
  ];

  selectedTimeSlice: TimeSlice = null;

  @ViewChild('sliderContainer') sliderContainer: ElementRef;
  private sliderContainerClientRect: ClientRect = null;
  private grabbedLeftHandle = false;
  private grabbedRightHandle = false;
  private grabbedBody = false;
  private grabbedBodyOffset: number = null;

  ngOnInit() {
    if (!this.maxTimestamp) {
      this.maxTimestamp = new Date().getTime();
    }

    if (!this.minTimestamp) {
      this.minTimestamp = this.maxTimestamp - 60000 * 60 * 24 * 30;
    }

    this.selectTimeSlice(this.timeSlices[2]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minTimestamp || changes.maxTimestamp) {
      if (changes.maxTimestamp) {
        if (!this.maxTimestamp) {
          this.maxTimestamp = new Date().getTime();
        }

        if (this.selectedMax > this.maxTimestamp) {
          this.selectedMax = this.maxTimestamp;
        }
      }

      if (changes.minTimestamp) {
        if (!this.minTimestamp) {
          this.minTimestamp = this.maxTimestamp - 60000 * 60 * 24;
        }

        if (this.selectedMin < this.minTimestamp) {
          this.selectedMin = this.minTimestamp;
        }
      }

      this.calculateSliderPosition();
    }
  }

  private calculateSliderPosition() {
    if (this.selectedMin && this.selectedMax) {
      this.sliderStyle = {
        left: this.getSliderPercentPosition(this.selectedMin) + '%',
        right: (100 - this.getSliderPercentPosition(this.selectedMax)) + '%'
      };
    } else {
      this.sliderStyle = null;
    }
  }

  private getSliderPercentPosition(v: number): number {
    return ((v - this.minTimestamp) * 100) / (this.maxTimestamp - this.minTimestamp);
  }

  private calculateSliderContainerX(): boolean {
    this.sliderContainerClientRect = null;
    if (this.sliderContainer) {
      try {
        this.sliderContainerClientRect = (this.sliderContainer.nativeElement as HTMLElement).getBoundingClientRect();
      } catch (e) { }
    }

    return (this.sliderContainerClientRect != null);
  }

  private projectToSlider(clientX: number): number {
    const p = (clientX - this.sliderContainerClientRect.left) / this.sliderContainerClientRect.width;
    return Math.floor(this.minTimestamp + p * (this.maxTimestamp - this.minTimestamp));
  }

  private clampValue(v: number, min: number, max: number): number {
    return v < min ? min : v > max ? max : v;
  }

  onLeftHandleMouseDown(event: MouseEvent) {
    if (event.button === 0) {
      this.grabLeftHandle();
    }
  }

  onRightHandleMouseDown(event: MouseEvent) {
    if (event.button === 0) {
      this.grabRightHandle();
    }
  }

  onBodyMouseDown(event: MouseEvent) {
    if (event.button === 0) {
      this.grabBody(event.clientX);
    }
  }

  onLeftHandleTouchStart(event: any) {
    if (event.touches.length === 1) {
      this.grabLeftHandle();
    }
  }

  onRightHandleTouchStart(event: any) {
    if (event.touches.length === 1) {
      this.grabRightHandle();
    }
  }

  onBodyTouchStart(event: any) {
    if (event.touches.length === 1) {
      this.grabBody(event.touches[0].clientX);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.grabbedBody || this.grabbedLeftHandle || this.grabbedRightHandle) {
      event.preventDefault();
      this.moveGrabbedItem(event.clientX);
    }
  }


  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (event.button === 0) {
      this.releaseGrabbedItem();
    }
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event: any) {
    if (event.touches.length < 1) {
      this.releaseGrabbedItem();
    }
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: any) {
    if (event.touches.length > 0) {
      this.moveGrabbedItem(event.touches[0].clientX);
    }
  }

  private grabLeftHandle() {
    if (this.calculateSliderContainerX()) {
      this.grabbedLeftHandle = true;
    }
  }

  private grabRightHandle() {
    if (this.calculateSliderContainerX()) {
      this.grabbedRightHandle = true;
    }
  }

  private grabBody(clientX: number) {
    if (!this.grabbedLeftHandle && !this.grabbedRightHandle && this.calculateSliderContainerX()) {
      this.grabbedBodyOffset = this.projectToSlider(clientX) - this.selectedMin;
      this.grabbedBody = true;
    }
  }

  private moveGrabbedItem(clientX: number) {
    if (this.grabbedBody) {
      const diff = this.selectedMax - this.selectedMin;
      const mouseTimestamp = this.projectToSlider(clientX);
      this.selectedMin = Math.max(mouseTimestamp - this.grabbedBodyOffset, this.minTimestamp);
      this.selectedMax = this.selectedMin + diff;
      if (this.selectedMax > this.maxTimestamp) {
        this.selectedMax = this.maxTimestamp;
        this.selectedMin = this.selectedMax - diff;
      }
      this.selectedTimeSlice = null;
      this.calculateSliderPosition();
    } else if (this.grabbedLeftHandle) {
      this.selectedMin = this.clampValue(this.projectToSlider(clientX), this.minTimestamp, this.selectedMax - 300000);
      this.selectedTimeSlice = null;
      this.calculateSliderPosition();
    } else if (this.grabbedRightHandle) {
      this.selectedMax = this.clampValue(this.projectToSlider(clientX), this.selectedMin + 300000, this.maxTimestamp);
      this.selectedTimeSlice = null;
      this.calculateSliderPosition();
    }
  }

  private releaseGrabbedItem() {
    if (this.grabbedLeftHandle || this.grabbedRightHandle || this.grabbedBody) {
      this.grabbedLeftHandle = this.grabbedRightHandle = this.grabbedBody = false;
      this.emitEventChanged();
    }
  }

  private emitEventChanged() {
    this.eventChanged.emit({
      min: this.selectedMin,
      max: this.selectedMax
    });
  }

  selectTimeSlice(slice: TimeSlice) {
    if (this.selectedTimeSlice === slice) {
      return;
    }

    this.selectedTimeSlice = slice;
    this.selectedMax = this.maxTimestamp;
    this.selectedMin = Math.max(this.minTimestamp, this.selectedMax - this.selectedTimeSlice.value);
    this.calculateSliderPosition();
    this.emitEventChanged();
  }
}
