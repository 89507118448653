/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
export const environment = {
  production: true,
  productId: 1,
  productType: 'Informix HQ',
  productNameNoSpace: 'InformixHQ',
  serverType: 'Informix',
  productName: 'HQ',
  faviconIcon: 'favicon.ico',
  loginUpper: '#003d6b',
  loginBottom: '#f5f5f5',
  loginPage: 'login-page',
  loginLogo: 'assets/img/logo_big.png',
  topnavDiv: 'topnav',
  topnavLinks: 'navbar-dark',
  topnavText: 'text-white',
  logo: 'informix-hq',
  logoExtention: '.png'
};
