/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2022. All Rights Reserved.
 *******************************************************************************/
import { CanComponentDeactivate } from '../../shared/canDeactivateGuard.service';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { JSONUtils } from '../../shared/json-utils';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { InformixTreeItem } from '../informixTreeItem';
import { AlertingCriteria, AlertingProfile, AlertOperation, AlertTrigger } from './alerting-profile';
import { AlertingService } from './alerting.service';
import { DataTableComponent } from '../../shared/data-table/data-table.component';

class AlertUI implements AlertingCriteria {
  public id: number;
  public name: string;
  public trigger: AlertTrigger;
  public condition: AlertOperation;
  private _disabled: boolean;

  public get disabled() {
    return this._disabled;
  }

  public set disabled(value: boolean) {
    this._disabled = value;
    if (!this.isNew && this.originalAlert) {
      this.isChanged = (this._disabled !== this.originalAlert.disabled);
    }
  }

  public isValid = true;
  public isChanged = false;
  public get isNew() {
    return this.id === null;
  }

  constructor(private originalAlert: AlertingCriteria) {
    this.update(originalAlert);
  }

  public update(alert: AlertingCriteria) {
    this.id = alert.id;
    this.name = alert.name;
    this.trigger = alert.trigger;
    this.condition = alert.condition;
    this.disabled = alert.disabled;

    if (!this.isNew) {
      this.isChanged = !JSONUtils.deepEqual(this.originalAlert, alert);
    }
  }

  public undoChanges() {
    this.isValid = true;
    this.update(this.originalAlert);
  }

  public toJSON(): AlertingCriteria {
    return {
      id: this.id,
      name: this.name,
      trigger: this.trigger,
      condition: this.condition,
      disabled: this.disabled
    };
  }
}

@Component({
  selector: 'app-alerting-profile',
  templateUrl: 'alerting-profile.html'
})
export class AlertingProfileComponent implements OnChanges, OnDestroy, AfterViewInit, CanComponentDeactivate {

  @Input() owner: InformixTreeItem;
  profile: AlertingProfile;

  uiAlerts: AlertUI[] = null;
  uiInheritedAlerts: AlertUI[] = null;

  newAlert: AlertingCriteria = null;
  editingAlert: AlertUI = null;
  isSaving = false;

  getProfilesSub: Subscription;

  changeCount = 0;
  private errorCount = 0;
  dynamicHeight: any;

  @ViewChildren(DataTableComponent) myDataTable: QueryList<DataTableComponent>;
  @ViewChild('discardChangesModal') discardChangesModal: ModalDirective;
  resolveDiscardChanges: (value: boolean) => void = null;

  constructor(
    private alertingService: AlertingService,
    private notifications: NotificationsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.owner && this.owner) {
      this.getProfile();
    }
  }

  ngOnDestroy() {
    this.cancelGetProfiles();
  }

  private getProfile() {
    this.cancelGetProfiles();
    this.getProfilesSub = this.alertingService.getAlerts(this.owner).subscribe(profile => {
      this.setProfile(profile);
    }, err => {
      console.error(err);
    });
  }

  private setProfile(profile: AlertingProfile) {
    this.profile = profile;

    this.uiAlerts = this.profile.alerts.map(alert => {
      const uiAlert = new AlertUI(alert);
      return uiAlert;
    });

    if (this.profile.inherited) {
      this.uiInheritedAlerts = this.profile.inherited.map(alert => {
        const uiAlert = new AlertUI(alert);
        return uiAlert;
      });
    } else {
      this.uiInheritedAlerts = null;
    }

    this.changeCount = 0;
    this.errorCount = 0;
    setTimeout(() => {
      if (this.myDataTable.length === 2 && this.myDataTable.first.dataTableRef) {
        const topOffSet = this.myDataTable.first.dataTableRef.nativeElement.getBoundingClientRect().top;
        this.dynamicHeight = ((window.innerHeight - topOffSet) / 2) - 90;
      }
    });
  }

  private cancelGetProfiles() {
    if (this.getProfilesSub) {
      this.getProfilesSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.discardChangesModal.onHide.subscribe(() => {
      if (this.resolveDiscardChanges) {
        this.resolveDiscardChanges(false);
        this.resolveDiscardChanges = null;
      }
    });
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (this.changeCount > 0) {
      return new Promise<boolean>(resolve => {
        this.resolveDiscardChanges = resolve;
        this.discardChangesModal.show();
      });
    } else {
      return true;
    }
  }

  openAddNewAlert() {
    if (this.newAlert) {
      return;
    }
    this.newAlert = {
      id: null,
      name: 'New Alert',
      trigger: {
        type: 'interval',
        interval: 5000
      },
      condition: {
        a: null,
        b: { type: 'const' },
        op: null
      },
      disabled: false
    };
  }

  addNewAlert(alert: AlertingCriteria) {
    this.newAlert = null;
    this.uiAlerts = this.uiAlerts.concat(new AlertUI(alert));
    this.changeCount++;
  }

  toggleEditAlert(alert: AlertUI) {
    this.editingAlert = (alert === this.editingAlert ? null : alert);
  }

  removeAlert(alert: AlertUI) {
    this.uiAlerts = this.uiAlerts.filter(uiAlert => uiAlert !== alert);
    this.changeCount += alert.isNew ? -1 : 1;
    if (!alert.isValid) {
      this.errorCount--;
    }
  }

  toggleEnabled(alert: AlertUI) {
    const originalStatus = alert.isChanged;
    alert.disabled = !alert.disabled;
    if (originalStatus !== alert.isChanged) {
      this.changeCount += alert.isChanged ? 1 : -1;
    }
  }

  cloneAlert(alert: AlertUI) {
    const alertClone: AlertingCriteria = {
      id: null,
      name: 'Copy of ' + alert.name,
      trigger: JSONUtils.deepClone(alert.trigger),
      condition: JSONUtils.deepClone(alert.condition),
      disabled: false
    };

    this.addNewAlert(new AlertUI(alertClone));
  }

  undoAlertChanges(alert: AlertUI) {
    this.changeCount--;
    if (!alert.isValid) {
      this.errorCount--;
    }

    if (this.isEditingAlert(alert)) {
      this.toggleEditAlert(alert);
    }
    alert.undoChanges();
  }

  isEditingAlert(alert: AlertingCriteria) {
    return alert === this.editingAlert;
  }

  onEditedAlertUpdate(event: any) {
    const oldIsChanged = this.editingAlert.isChanged;
    const oldIsValid = this.editingAlert.isValid;

    this.editingAlert.update(event.alert);
    this.editingAlert.isValid = event.valid;

    if (oldIsChanged !== this.editingAlert.isChanged) {
      this.changeCount += this.editingAlert.isChanged ? 1 : -1;
    }

    if (oldIsValid !== this.editingAlert.isValid) {
      this.errorCount += this.editingAlert.isValid ? -1 : 1;
    }
  }

  isSaveButtonDisabled() {
    return this.isSaving || this.changeCount < 1 || this.errorCount > 0;
  }

  saveProfile() {
    this.isSaving = true;

    const alerts: AlertingCriteria[] = this.uiAlerts.map(v => v.toJSON());
    let inherited: any[] = null;
    if (this.uiInheritedAlerts) {
      inherited = this.uiInheritedAlerts.map(v => ({
        id: v.id,
        disabled: v.disabled
      }));
    }

    this.alertingService.updateAlerts(this.owner, alerts, inherited).then(profile => {
      this.setProfile(profile);
      this.notifications.pushSuccessNotification('Changes saved');
    }).catch(err => {
      this.notifications.pushErrorNotification('There was a problem saving changes');
      console.error(err);
    }).then(() => {
      this.isSaving = false;
    });
  }

  discardChanges() {
    this.setProfile(this.profile);
  }
}
