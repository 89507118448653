/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020. All Rights Reserved.
 *******************************************************************************/

export class SLAInfo {
  cmName: string;
  cmUnitName: string;
  type: string;
  slaName: string;
  dbServers?: string;
  port?: string;
  policy?: string;
  mode?: string;
  workers?: number;
  useAliases?: string;
  constructor(slaInfo: any, cmName: string, cmUnitName: string, type: string) {
    this.cmName = cmName;
    this.cmUnitName = cmUnitName;
    this.type = type;
    this.slaName = slaInfo.slaName;
    this.dbServers = slaInfo.dbServers;
    this.port = slaInfo.port;
    this.policy = slaInfo.policy;
    this.mode = slaInfo.mode;
    this.workers = slaInfo.workers;
    this.useAliases = slaInfo.useAliases;
  }
}

export class ConnectionUnit {
  cmUnitName: string;
  type: string;
  foc?: string;
  servers?: string;
  slaInfoList?: Array<SLAInfo>;
  constructor(cmUnit: any, cmName, cmUnitName?) {
    if (cmUnit) {
      this.foc = cmUnit.foc;
      if (cmUnit.servers) {
        this.servers = cmUnit.servers.split(',');
      }
      this.cmUnitName = cmUnit.cmUnitName ? cmUnit.cmUnitName : cmUnitName;
      this.type = cmUnit.type.toUpperCase();
      if (cmUnit.slaInfoList) {
        this.slaInfoList = cmUnit.slaInfoList.map(slaInfo => new SLAInfo(slaInfo, cmName, cmUnitName, cmUnit.type));
      }
    }
  }
}

export class ConnectionManagerInfo {
  cmName: string;
  cmArbitrator: number;
  isExpand?: boolean;
  cmUnitList: Array<ConnectionUnit>;
  constructor(cmInfo: any) {
    this.cmName = cmInfo.cmName;
    this.cmArbitrator = cmInfo.cmArbitrator;
    this.isExpand = false;
    if (cmInfo.cmUnitList) {
      this.cmUnitList = cmInfo.cmUnitList.map(unit => new ConnectionUnit(unit, this.cmName));
    }
  }
}

export class FOCInfo {
  cmName: string;
  type: string;
  cmUnitName: string;
  timeout?: string;
  order?: string;
}
