<div class="container-fluid" style="padding: 5px">
  <div class="my-2" [innerHTML]="descriptionSafe"></div>
  <div class="form-inline mb-3">
    <ng-template [ngIf]="enableAlert">
      <span class="badge badge-success">Enabled</span>
      <button class="btn btn-sm btn-secondary ml-2" tooltip="Disable" (click)="enableAlert = false">
        <i class="icon icon-ban"></i>
      </button>
    </ng-template>
    <ng-template [ngIf]="!enableAlert">
      <span class="badge badge-danger">Disabled</span>
      <button class="btn btn-sm btn-secondary ml-2" tooltip="Enable" (click)="enableAlert = true">
        <i class="icon icon-check"></i>
      </button>
    </ng-template>
  </div>
  <hr>
  <h5>Configuration</h5>
  <ng-content></ng-content>
  <button class="btn btn-primary mt-2" [disabled]="!isValid() || !isModified()" (click)="saveConfig()">Save</button>
  <hr>
  <h5>Send test {{isEmail?'email':'message'}}</h5>
  <ng-content select="[test-form]"></ng-content>
  <div style="display: inline-block" [tooltip]="(!enableAlert || !isValid() || isModified())
          ? 'You must enable and save the alert configuration before it can be tested' : null">
    <button class="btn btn-primary" [disabled]="!enableAlert || !isValid() || !isTestValid() || isModified()" (click)="testConfig()">Test</button>
  </div>
</div>
