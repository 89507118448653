<div *ngIf="server">

  <label class="btn-container mt-3">
    <button class="btn font-weight-bold" [ngClass]="{'btn-style': viewAdminAdminAPILog}" (click)="handleLogChange(true)">
      Admin API Commands
    </button>
    <button class="btn font-weight-bold" [ngClass]="{'btn-style': !viewAdminAdminAPILog}" (click)="handleLogChange(false)">
      ON-Bar Activity Log
    </button>
  </label>

  <ng-template [ngIf]="dataLoadErrorMessage">
    <div class="alert alert-warning">
      Server backup information is not available. <br> {{dataLoadErrorMessage}}
    </div>
  </ng-template>

  <div *ngIf="viewAdminAdminAPILog && adminAPICommands" class="table-responsive mt-4">
    <app-admin-api-data-table [adminAPICommands]="adminAPICommands" [showSearch]="false">
    </app-admin-api-data-table>
  </div>

  <div *ngIf="!viewAdminAdminAPILog && barActLog !== null" class="mt-4">
    <app-log-window class="card-body" style="max-height: 500px; overflow: auto">
      <div class="alert alert-info" *ngIf="barActLog.length === 0">
        No ON-Bar activity log messages.
      </div>
      <div class="text-monospace" style="white-space: pre">
        {{barActLog}}
      </div>
      <div class="alert alert-info" *ngIf="barActLog.length > 4000">
        Click to see the full <a [routerLink]="['/dashboard/servers/' + server.id + '/logs/onbarlog']">ON-Bar
          activity log</a>.
      </div>
    </app-log-window>
  </div>
</div>
