/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { OSInfoComponent } from './os-info.component';
import { Dashboard } from '../../monitoring/dashboard';

export function getSystemInfoDashboardConfig(osName?: string): Dashboard {

  let selectedNetworkReceiveMetric = 'receive_packets';
  let selectedNetworkTransmitMetric = 'transmit_packets';
  if (osName && osName === 'Linux') {
    selectedNetworkReceiveMetric = 'receive_bytes';
    selectedNetworkTransmitMetric = 'transmit_bytes';
  }

  const systemInfoDashboard: Dashboard = {
    title: 'System Resources',
    components: {
      osInfo: OSInfoComponent
    },
    rows: [
      {
        charts: [
          {
            component: 'osInfo',
            config: {}
          },
          {
            component: 'chart',
            config: {
              sensorId: 'os_cpu',
              title: 'Overall CPU Usage'
            }
          }
        ]
      },
      {
        charts: [
          {
            component: 'chart',
            config: {
              sensorId: 'os_memory',
              title: 'OS Memory',
              metricList: ['mem_used', 'swap_used', 'mem_total']
            }
          },
          {
            component: 'chart',
            config: {
              sensorId: 'os_diskio',
              title: 'Disk I/O Activity',
              metricSelect: {
                label: 'Disk Metric',
                default: 'dk_busy'
              }
            }
          }
        ]
      },
      {
        charts: [
          {
            component: 'chart',
            config: {
              sensorId: 'os_networkio',
              title: 'Network I/O - Receive',
              metricList: ['receive_bytes', 'receive_packets'],
              metricSelect: {
                label: 'Metric',
                default: selectedNetworkReceiveMetric
              }
            }
          },
          {
            component: 'chart',
            config: {
              sensorId: 'os_networkio',
              title: 'Network I/O - Transmit',
              metricList: ['transmit_bytes', 'transmit_packets'],
              metricSelect: {
                label: 'Metric',
                default: selectedNetworkTransmitMetric
              }
            }
          }
        ]
      }
    ]
  };
  return systemInfoDashboard;
}
