<div class="btn-group">
  <button type="button" class="btn btn-sm border-gray-20" (click)="shiftBackward()">
    <i class="icon icon-chevron-left text-muted"></i>
  </button>
  <button type="button" class="btn btn-sm border-gray-20 text-muted" [popover]="popoverTemplate" container="body" placement="bottom"
    [outsideClick]="true" #popover="bs-popover" containerClass="popover-color">
    <ng-container *ngIf="selectedOption; else dateRangeTemplate">
      {{selectedOption.name}}
    </ng-container>
    <ng-template #dateRangeTemplate>
      {{interval.getStartTimestamp() | date:dateFormat}} - {{interval.getEndTimestamp() | date:dateFormat}}
    </ng-template>
  </button>
  <button type="button" class="btn btn-sm border-gray-20" (click)="shiftForward()">
    <i class="icon icon-chevron-right text-muted"></i>
  </button>
  <app-check-permissions [permissions]="checkPermissions" placement="bottom">
  <button class="btn btn-sm btn-icon ml-2" [disabled] = "isBtnDisabled" (click)="saveTime();" tooltip="Save Time"
  appPermissionDisable>
    <i class="icon icon-save"></i>
  </button>
</app-check-permissions>       
</div>

<ng-template #popoverTemplate>
  <div class="text-nowrap">
    <button type="button" class="btn btn-sm btn-link d-block" *ngFor="let option of intervalOptions"
      (click)="selectIntervalOption(option); popover.hide(); buttonDisabled();">
      {{option.name}}
    </button>
  </div>
</ng-template>
