/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { BreadcrumbElement } from '../../../../shared/breadcrumb.component';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { MonitoringProfile } from '../../../monitoring/monitoringProfile';
import { InformixServer } from '../../informixServer';
import { InformixServerService } from '../../informixServer.service';
import { ServerBreadcrumb } from './../../serverBreadcrumb';
import { ServerThread } from './serverThread';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-threads',
  templateUrl: 'threads.html'
})
export class ThreadsComponent implements OnInit, OnDestroy {

  @Input() viewLiveData = true;

  breadcrumb: BreadcrumbElement[] = null;
  performanceBreadCrumb: BreadcrumbElement = { name: 'Performance' };
  threadsBreadCrumb: BreadcrumbElement = { name: 'Threads' };

  server: InformixServer = null;
  monitoringProfile: MonitoringProfile = null;
  threads: ServerThread[] = null;

  dataLoadErrorMessage: string = null;

  private refreshThreads = new Subject<any>();
  private threadsSub: Subscription = null;

  constructor(
    private informixServerService: InformixServerService,
    private monitoringService: MonitoringService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.threadsSub = this.refreshThreads.pipe(
      switchMap(() => this.informixServerService.getServerThreads(this.server.id)))
      .subscribe(threads => {
        this.threads = threads;
        this.dataLoadErrorMessage = null;
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
      });

    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
  }

  ngOnDestroy() {
    if (this.threadsSub) {
      this.threadsSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.breadcrumb = ServerBreadcrumb.build(this.server, [this.performanceBreadCrumb, this.threadsBreadCrumb]);
    this.getMonitoringProfile();
    if (this.server.hasMonitorPassword) {
      this.refreshData();
    } else if (this.server.agent != null) {
      this.viewLiveData = false;
    }
  }

  private refreshData() {
    this.refreshThreads.next(null);
  }

  public getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;
    }).catch(err => {
      console.error('Error getting monitoring profile info', err);
    });
  }
}
