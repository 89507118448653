/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from './../serverBreadcrumb';
import { SqltraceService } from './sqltrace.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sqltrace-stmt-profile',
  templateUrl: 'sqltrace-stmt-profile.html'
})
export class SqltraceStatementProfileComponent implements OnInit {

  breadcrumb: BreadcrumbElement[] = null;
  private sqltraceBreadCrumb: BreadcrumbElement = {
    name: 'SQL Tracing',
    link: 'sqltrace'
  };
  private stmtsBreadCrumb: BreadcrumbElement = {
    name: 'Statements',
    link: 'sqltrace/statements'
  };
  private stmtExecutionsBreadCrumb: BreadcrumbElement = {
    name: 'Executions',
    link: 'sqltrace/statements/'
  };
  private txnsBreadCrumb: BreadcrumbElement = {
    name: 'Transactions',
    link: 'sqltrace/transactions'
  };
  private txnInfoBreadCrumb: BreadcrumbElement = {
    name: 'Info',
    link: 'sqltrace/statements/'
  };
  private stmtProfileBreadCrumb: BreadcrumbElement = {
    name: 'Profile',
  };
  breadcrumbPath = 'stmt';

  server: InformixServer = null;
  sqlid: string = null;
  sql: string = null;
  stmtInfo: any = null;
  sqltraceIteratorData: any[] = null;

  dataLoadErrorMessage: string = null;

  private isoLevelMap: { [level: number]: string } = {
    0: 'No transaction',
    1: 'Dirty read, read only',
    2: 'Committed read',
    3: 'Cursor record locked',
    5: 'Repeatable read',
    6: 'Dirty read, warning',
    7: 'Dirty read, read only, retain update locks',
    8: 'Committed read, retain update locks',
    9: 'Cursor record locked, retain update locks',
    10: 'Dirty read, warning, retain update locks',
    16: 'Last committed'
  };
  isoLevelText: string = null;


  constructor(
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
      this.route.params.subscribe(params => {
        if (params.sqlid) {
          this.sqlid = params.sqlid;
          this.stmtExecutionsBreadCrumb.link += this.sqlid;
          this.getStatementProfile();
          this.getStatementIterators();
        } else {
          console.error('missing sqlid');
        }
      });

      this.route.queryParams.subscribe(params => {
        if (params.path === 'txn') {
          this.breadcrumbPath = 'txn';
        }
        this.setupBreadcrumbs();
      });
    });
  }

  private loadServer(server: InformixServer) {
    this.server = server;
  }

  private setupBreadcrumbs() {
    if (this.breadcrumbPath === 'txn') {
      this.txnInfoBreadCrumb.link += this.sqlid + '/transaction';
      this.breadcrumb = ServerBreadcrumb.build(this.server,
        [this.sqltraceBreadCrumb, this.txnsBreadCrumb, this.txnInfoBreadCrumb, this.stmtProfileBreadCrumb]);
    } else {
      this.breadcrumb = ServerBreadcrumb.build(this.server,
        [this.sqltraceBreadCrumb, this.stmtsBreadCrumb, this.stmtExecutionsBreadCrumb, this.stmtProfileBreadCrumb]);
    }
  }

  private getStatementProfile() {
    this.sqltraceService.getStatementProfile(this.server, this.sqlid).then(profile => {
      this.sql = profile.sql_statement;
      this.stmtInfo = profile;
      this.setIsolationLevelText();
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace statement profile info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private getStatementIterators() {
    this.sqltraceService.getStatementIterators(this.server, this.sqlid).then(iterators => {
      this.sqltraceIteratorData = iterators;
    }).catch((err: HttpErrorResponse) => {
      console.error('Error getting sqltrace statement iterators', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private setIsolationLevelText() {
    let text = '';
    if (this.stmtInfo.sql_isollevel > 16) {
      text = this.isoLevelMap[16];
    } else {
      text = this.isoLevelMap[this.stmtInfo.sql_isollevel];
    }
    if (text && text.length > 0) {
      text = '(' + text + ')';
    }
    this.isoLevelText = text;
  }

}
