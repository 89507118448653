<div class="container-fluid page-container" *ngIf="server">
  <div class="row">
    <div class="col-6">
      <h5 class="font-weight-bold">{{ 'schemaManager.createDatabase.pageTitle' | translate }}</h5>
    </div>
    <div class="col-6">
      <button type="button" class="float-right btn btn-sm btn-icon" (click)="actionComplete.emit()">
        <i class="icon icon-times font-weight-bold"></i></button>
    </div>
  </div>
  <form *ngIf="createDatabaseForm" [formGroup]="createDatabaseForm">
    <div class="row pt-3">
      <div class="col-12 col-sm-4">
        <label class="font-weight-bold"><span class="text-danger">* </span>{{ 'schemaManager.createDatabase.databaseNameLabel' | translate }}</label>
        <input class="form-control" type="text"
          [placeholder]="'schemaManager.createDatabase.databaseNamePlaceHolder' | translate"
          formControlName="databaseName" tooltip="" appExtendedFormControl>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12 col-lg-6">
        <label class="font-weight-bold mb-0"><span class="text-danger">* </span>{{ 'schemaManager.createDatabase.selectDBSpaceLabel' | translate }}</label>
        <app-data-table *ngIf="dbSpacesList; else dbSpaceTableLoading" [data]="dbSpacesList" [enableSingleSelect]="true"
          (rowSelected)="onRowSelect($event, 'dbspace')" [minHeight]="380">
          <app-data-table-column [title]="'schemaManager.createDatabase.table.columnTitle.dbSpaceName' | translate"
            property="name" [sortable]="true" [searchable]="true">
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createDatabase.table.columnTitle.pageSize' | translate"
            property="page_size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | byte}}
            </ng-template>
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createDatabase.table.columnTitle.freeSpace' | translate"
            property="free_size" [sortable]="true">
            <ng-template appTableCell let-item="item">
              {{item | byte}}
            </ng-template>
          </app-data-table-column>
        </app-data-table>
        <ng-template #dbSpaceTableLoading>
          <div class="d-flex justify-content-center">
            <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
          </div>
        </ng-template>
      </div>
      <div class="col-12 col-lg-6">
        <label class="font-weight-bold mb-0">{{ 'schemaManager.createDatabase.selectLangLabel' | translate }}</label>
        <app-data-table *ngIf="dbLocaleList; else langTableLoading" [data]="dbLocaleList" [enableSingleSelect]="true"
          (rowSelected)="onRowSelect($event, 'language')" [minHeight]="380">
          <app-data-table-column [title]="'common.language' | translate" property="language" [sortable]="true"
            [searchable]="true">
          </app-data-table-column>
          <app-data-table-column [title]="'schemaManager.createDatabase.table.columnTitle.locale' | translate"
            property="name" [sortable]="true" [searchable]="true">
          </app-data-table-column>
        </app-data-table>
        <ng-template #langTableLoading>
          <div class="d-flex justify-content-center">
            <i class="icon icon-circle-notch icon-2x icon-spin mt-5"></i>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <label
          class="font-weight-bold mb-0 pb-2">{{ 'schemaManager.createDatabase.loggingModeLabel' | translate }}</label><br />
        <app-radio-group formControlName="loggingMode" class="pt-2">
          <app-radio value="unbuffered">{{ 'schemaManager.createDatabase.unbufferedLogging' | translate }}</app-radio>
          <app-radio class="ml-3" value="buffered">{{ 'schemaManager.createDatabase.bufferedLogging' | translate }}
          </app-radio>
          <app-radio class="ml-3" value="ansi">{{ 'schemaManager.createDatabase.ansiLogging' | translate }}
          </app-radio>
          <app-radio class="ml-3" value="no-logging">{{ 'schemaManager.createDatabase.noLogging' | translate }}
          </app-radio>
        </app-radio-group>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <label class="font-weight-bold mb-0 pb-2">{{ 'schemaManager.createDatabase.characterCaseLabel' | translate }}
        </label><br />
        <app-radio-group formControlName="characterCase" class="pt-2">
          <app-radio [value]=true>{{ 'schemaManager.createDatabase.caseSensitive' | translate }}</app-radio>
          <app-radio class="ml-3" [value]=false>{{ 'schemaManager.createDatabase.notCaseSensitive' | translate }}
          </app-radio>
        </app-radio-group>
      </div>
    </div>
  </form>
  <div class="row pt-3">
    <div class="col-12" align="right">
      <button type="button" class="btn btn-primary btn-fixed-width" (click)="createDatabase()"
        [disabled]="!createDatabaseForm.valid || !rowSelected">
        {{ 'button.finish' | translate }}</button>
      <button type="button" class="btn btn-secondary btn-fixed-width mr-2" (click)="actionComplete.emit()">
        {{ 'button.cancel' | translate }}</button>
    </div>
  </div>
</div>
