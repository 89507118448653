/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnChanges, QueryList, SimpleChanges, Type, ViewChildren } from '@angular/core';
import { InformixServer } from '../servers/informixServer';
import { AbstractDashboardComponent } from './abstract-dashboard-component';
import { Dashboard, DashboardChart } from './dashboard';
import { DashboardDynamicComponent } from './dashboard-dynamic.component';
import { MonitoringService } from './monitoring.service';
import { MonitoringProfile } from './monitoringProfile';
import { SensorChartComponent } from './sensor-chart.component';
import { TimeSlice } from './timeSlices';
import { TimeSliceService } from './time-slice.service';

interface UIChart {
  component: Type<AbstractDashboardComponent>;
  chart: DashboardChart;
  cssClass: string;
}

const dashboardComponents: { [key: string]: Type<AbstractDashboardComponent> } = {
  chart: SensorChartComponent
};

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.html',
  providers: [TimeSliceService]
})
export class DashboardComponent implements OnChanges {

  @Input() server: InformixServer;
  @Input() dashboard: Dashboard;

  uiCharts: UIChart[][] = null;
  monitoringProfile: MonitoringProfile = null;

  requiredSensorList: string[] = [];

  isPaused = false;

  @ViewChildren(DashboardDynamicComponent) dashboardComponents: QueryList<DashboardDynamicComponent>;

  constructor(private monitoringService: MonitoringService,
    private timeSliceService: TimeSliceService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.server) {
      this.getMonitoringProfile();
    } else if (this.dashboard) {
      this.initDashboard();
    }
  }

  getMonitoringProfile() {
    this.monitoringService.getEffectiveMonitoringProfile(this.server).then(profile => {
      this.monitoringProfile = profile;
      if (this.dashboard) {
        this.initDashboard();
      }
    }).catch(err => {
      console.error(err);
    });
  }

  private initDashboard() {
    const components = this.dashboard.components ? Object.assign({}, dashboardComponents, this.dashboard.components) : dashboardComponents;

    this.requiredSensorList = [];
    this.uiCharts = [];
    const cssClasses = ['', 'col-lg-6 col-sm-12', 'col-xl-4'];
    this.dashboard.rows.forEach(row => {
      const uiChartRow: UIChart[] = [];
      const cssClass = cssClasses[row.charts.length - 1];
      row.charts.forEach(chart => {
        uiChartRow.push({
          component: components[chart.component],
          chart,
          cssClass
        });
        if (chart.config.sensorId) {
          if (this.requiredSensorList.filter(sensorId => sensorId === chart.config.sensorId).length === 0) {
            this.requiredSensorList.push(chart.config.sensorId);
          }
        }
      });
      this.uiCharts.push(uiChartRow);
    });

    if (this.dashboard.options && this.dashboard.options.isPaused) {
      this.isPaused = this.dashboard.options.isPaused;
    }
  }

  pauseData() {
    this.isPaused = true;
    this.timeSliceService.pauseData();
  }

  resumeData() {
    this.isPaused = false;
    this.timeSliceService.resumeData();
  }

  changeTimeSlice(slice: TimeSlice) {
    this.timeSliceService.changeTimeSlice(slice);
  }
}
