/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/
import { environment } from './../../../environments/environment';


const productType = environment.productType;
export interface GuideConfiguration {
    id: number;
    title: string;
    totalSteps?: number;
    steps: Array<{
        text: string;
        image?: string;
        number?: number;
        headingOnly?: boolean;
    }>;
}
const guideConfig: GuideConfiguration[] = [
    {
        id: 1,
        title: 'How to define ER Domain?',
        steps : [
            {
                text: 'How to define ER Domain?'
            },
            {
                text: 'Click on the "Define ER Domain" button on the landing page. This will open a popup window.',
                image: 'assets/img/guide/1/step1.png'
            },
            {
                text: 'Select groups that you want to include in your domain, and click on the "Add" button.',
                image: 'assets/img/guide/1/step2.png'
            },
            {
                text: 'A new page will open and validation will begin for each selected groups',
            },
            {
                text: 'From the list of groups on the left side of the screen, select the group you want to add as the first node.',
                image: 'assets/img/guide/1/step3.png'
            },
            {
                text: 'Fill in the required information, such as selecting the "Node Type" and "Group for sync", and "CDR_QDATA_SBSPACE". \
                 <br> <br> If you\'re adding the first node you just need to select “CDR_QDATA_SBSPACE”',
                image: 'assets/img/guide/1/step4.png'
            },
            {
                text: 'If you want to include any additional options, click on the "more options" button.',
                image: 'assets/img/guide/1/step5.png'
            },
            {
                text: 'Once you have entered all the necessary information, click on the "Save & Next" button.',
                image: 'assets/img/guide/1/step6.png'
            },
            {
                text: 'Repeat these steps for all other groups that you want to include in your domain.',
            },
            {
                text: 'After adding all nodes and necessary information, click on the "Define ER Domain button.',
                image: 'assets/img/guide/1/step7.png'
            },
            {
                text: 'Confirm the creation of the ER domain and wait for HQ to validate all servers and complete the process.',
                image: 'assets/img/guide/1/step8.png'
            },
            {
                text: 'Once the ER domain is created successfully, a confirmation message will appear. \
                        Click on the "Close" button to exit.',
                image: 'assets/img/guide/1/step9.png'
            },
        ]
    },
    {
        id: 6,
        title: 'How to modify ER domain?',
        steps : [
            {
                text: 'How to modify ER domain?'
            },
            {
                text: 'Click on the "Add New Node" button on the domain page. This will open a popup window.',
                image: 'assets/img/guide/6/step1.png'
            },
            {
                text: 'Select groups that you want to include in your domain, and click on the "Add" button.',
                image: 'assets/img/guide/1/step2.png'
            },
            {
                text: 'A new page will open and validation will begin for each selected groups.',
            },
            {
                text: 'From the list of groups on the left side of the screen, select the group you want to add.',
                image: 'assets/img/guide/1/step3.png'
            },
            {
                text: 'Fill in the required information, such as selecting the "Node Type" and "Group for sync", and "CDR_QDATA_SBSPACE".',
                image: 'assets/img/guide/1/step4.png'
            },
            {
                text: 'If you want to include any additional options, click on the "more options" button.',
                image: 'assets/img/guide/1/step5.png'
            },
            {
                text: 'Once you have entered all the necessary information, click on the "Save & Next" button.',
                image: 'assets/img/guide/1/step6.png'
            },
            {
                text: 'Repeat these steps for all other groups that you want to include in your domain.',
            },
            {
                text: 'After adding all nodes and necessary information, click on the "Update ER Domain" button.',
                image: 'assets/img/guide/6/step7.png'
            },
            {
                text: 'Confirm the update of the ER domain and wait for HQ to validate all servers and complete the process.',
                image: 'assets/img/guide/6/step8.png'
            },
            {
                text: `Once the ER domain is updated successfully, a confirmation message will appear. \
                 Click on the "Close" button to exit. <br><br> For more information, <br> \
                 Please read the ${productType} documentation.`,
                image: 'assets/img/guide/6/step9.png'
            }
        ]
    },
    {
        id: 2,
        title: 'How to define replicate?',
        steps: [
            {
                text: 'How to define replicate?'
            },
            {
                text: 'Click “Add New Replicate” button, which will open a new page.',
                image: 'assets/img/guide/2/step-1.png',
            },
            {
                text: 'Enter Replicate name and Click on “Add participant” button.',
                image: 'assets/img/guide/2/step-2.png'
            },
            {
                text: 'Fill all the details such as Participant server, Database, Table, Participant type and Privileges.',
                image: 'assets/img/guide/2/step-3.png'
            },
            {
                text: 'And click on “Save & Next” button to add another participant.',
                image: 'assets/img/guide/2/step-3.png'
            },
            {
                text: 'Repeat same step to add all other participants.'
            },
            {
                text: 'Once you added all participants, click on “Next” button on the right side of the screen',
                image: 'assets/img/guide/2/step-5.png'
            },
            {
                text: 'Select Conflict resolution rule, Conflict resolution scope, and Frequency Options',
                image: 'assets/img/guide/2/step-6.png'
            },
            {
                text: 'Fill all the required Special Options',
                image: 'assets/img/guide/2/step-6.png'
            },
            {
                text: 'Check “Activate replicate after creation” if you want to start replicate after defining replicate',
                image: 'assets/img/guide/2/step-8.png'
            },
            {
                text: 'Once you filled all the details click on “Define Replicate” button.',
                image: 'assets/img/guide/2/step-9.png'
            },
            {
                text: 'Now give your consent to define replicate and wait for validation.',
                image: 'assets/img/guide/2/step-10.png'
            },
            {
                text: `Once the Replicate defined successfully, a confirmation message will appear. \
                Click on the "Close" button to exit.<br><br> For more information, <br> \
                Please read the ${productType} documentation.`,
                image: 'assets/img/guide/2/step-11.png'
            }
        ]
    },
    {
        id: 3,
        title: 'How to start replicate?',
        steps : [
            {
                text: 'How to start replicate?'
            },
            {
                text: 'Navigate to the replicate page and click on the "Start" icon button',
                image: 'assets/img/guide/3/start-step-01.png'
            },
            {
                text: 'A popup window will appear, allowing you to select the participants.',
                image: 'assets/img/guide/3/step-02.png'
            },
            {
                text: 'Click on “Next” Button and fill required fields.',
                image: 'assets/img/guide/3/step-03.png'
            },
            {
                text: `Once you've chosen the participants and filled the necessary fields, click on the "Start Replicate" button. \
                <br><br> For more information, <br> \ 
                Please read the ${productType} documentation.`,
                image: 'assets/img/guide/3/step-04.png'
            }
    ]
    },
    {
        id: 4,
        title: 'How to stop replicate?',
        steps : [
            {
                text: 'How to stop replicate?'
            },
            {
                text: 'Navigate to the replicate page and click on the "Stop" icon button.',
                image: 'assets/img/guide/3/step-01.png'
            },
            {
                text: 'A popup window will appear, allowing you to select the participants.',
                image: 'assets/img/guide/3/step-02.png'
            },
            {
                text: `Once you've chosen the participants, click on the "Stop Replicate" button.\
                <br><br> For more information, <br> \
                Please read the ${productType} documentation.`,
                image: 'assets/img/guide/3/stop-step-03.png'
            }
        ]
    },
    // {
    //     id: 5,
    //     title: 'How to check or sync replicate?',
    //     steps : []
    // }
    ];


export default guideConfig;
