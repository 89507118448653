/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2019. All Rights Reserved.
 *******************************************************************************/
import { InformixServer } from '../servers/informixServer';
import { MonitoringProfile } from './monitoringProfile';

export class AbstractDashboardComponent {
  server: InformixServer;
  monitoringProfile: MonitoringProfile;
  config: any;
}
