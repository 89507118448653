/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges, OnInit, ViewChild, OnDestroy, ElementRef, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AbstractQuery } from '../abstract-query';
import { InformixSQLSession } from '../informix-sql-session';
import { QueryResultPage } from '../query-results';
import { SchemaService } from '../schema.service';
import { SuperSQLService } from './supersql/supersql.service';
import { Subject, Subscription, interval, timer } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { SqltraceService } from '../../sqltrace/sqltrace.service';
import { InformixServer } from '../../informixServer';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-sql-console',
  templateUrl: 'sql-console.component.html',
  styleUrls: ['sql-console.component.scss']
})
export class SQLConsoleComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() session: InformixSQLSession;
  @Input() inputQuery: string;
  @ViewChild('excutionPlanModal') excutionPlanModal: ModalDirective;
  @ViewChild('runAgainQuertModal') runAgainQuertModal: ModalDirective;
  @ViewChild('importQueryModal') importQueryModal: ModalDirective;
  @ViewChild('confirmationModal') confirmationModal: ModalDirective;
  @ViewChild('sqlEditor') sqlEditor: ElementRef;
  @Output() showHistory = new EventEmitter<any>();
  @Output() showResultEvent = new EventEmitter<Boolean>();

  queryFormControl = new UntypedFormControl('');
  query: AbstractQuery = null;
  selectedQuery: any = null;
  prevSelectedQuery = '';
  resultMessage: string = null;
  resultTable: QueryResultPage = null;
  resultTablePages: QueryResultPage[] = [];
  resultCurrentPage = -1;
  isLoading = false;
  isRunning = false;
  ngUnsubscribe: Subject<void>;
  sqlException = '';
  informixError = '';
  statementOffset = '';
  errorMessage: string = null;
  showRerunButton = false;
  sqltraceIteratorData: any[] = null;
  dataLoadErrorMessage: string = null;
  server: InformixServer = null;
  sqltraceConfig: any;
  showSqlTraceConfig: Boolean = false;
  showSqlTraceState: Boolean = false;
  showSqlTraceConfigState: Boolean = false;
  database: string = null;
  showHint = true;
  count: number;
  resultTabActive: Boolean = true;
  counterInterval: any;
  takingMoreTime: Boolean = false;
  terminationState: Boolean = false;
  tabsDataHeight: any;
  sqlTreeStatusToRun: Boolean = false;
  queryRunStatus: Boolean = false;
  fetchingTreeStatus: Boolean = false;
  terminateActionStatus: Boolean = false;
  sqltraceServiceLoop: any;
  sqlTraceAPI$: any;
  pendingAPICall: Boolean = false;
  sqlTracingPrevState: Boolean = false;
  firstRun: boolean;
  reRunAknowleged: Boolean = true;
  isContinueOnFailure: Boolean;
  isAutoCommit: Boolean;
  isMultiLine: Boolean = true;
  isBatchMode: Boolean;
  isExcution: Boolean = false;
  downloadJsonHref: SafeUrl;
  queryResult: any = [];
  multiQueryResults: any = [];
  selectedQueryResult: any;
  slicedQueries: Array<any> =[];
  resultSetId: number;
  selectedQueryIndex = 0;
  isLastQueryExecuted: Boolean = false;
  isShowResultTab: Boolean = false;
  private subscriptions: Subscription[] = [];

  resolveQueryChanges: (value: boolean) => void = null;

  constructor(
    private schemaService: SchemaService,
    private superSql: SuperSQLService,
    private sqltraceService: SqltraceService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.database = this.session.database.name;
    this.firstRun = true;


    this.getSqlTraceConfiguration();
    this.emitShowResultTabValue();
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.schemaService.queryAutoCommit$.subscribe(value => {
        this.isAutoCommit = value;
      }),

      this.schemaService.lastQueryExecuted$.subscribe(value => {
        this.isLastQueryExecuted = value;
      }),
      // on load selected query set query to the sql editor on event
      this.schemaService.onSelectedQueryHistory$.subscribe(query => {
        this.queryFormControl.setValue(query);
      }),

      // commit or rollback uncommitted query on route change
      this.schemaService.rollback$.subscribe(value => {
        if (value) {
          this.queryFormControl.setValue('rollback;');
          this.onRunClick(false, false);
        }
      }),

      // commit uncommitted transaction
      this.schemaService.commit$.subscribe(value => {
        if (value) {
          this.queryFormControl.setValue('commit;');
          this.onRunClick(false, false);
        }
      }),

      // reset SQL Editor
      this.schemaService.onResetSqlEditor$.subscribe(value => {
        if(value) {
          this.queryFormControl.setValue('');
        }
      })
    );

    // set value to isAutoCommit from session, if not available then set default value
    if (this.session && typeof this.session.isAutoCommit === 'boolean') {
      this.isAutoCommit = this.session.isAutoCommit;
    } else {
      this.isAutoCommit = true;
    }
    this.schemaService.setAutoCommit(this.isAutoCommit);
    // set value to isContinueOnFailure from session, if not available then set default value
    if(this.session && typeof this.session.isContinueOnFailure === 'boolean') {
      this.isContinueOnFailure = this.session.isContinueOnFailure;
    } else {
      this.isContinueOnFailure = true;
    }

    // set value to isBatchMode from session, if not available then set default value
    if(this.session && typeof this.session.isBatchMode === 'boolean') {
      this.isBatchMode = this.session.isBatchMode;
    } else {
      this.isBatchMode = false;
    }
  }

  isChangeInLastQuery(): Promise<boolean> | boolean {
    if (!this.isAutoCommit && this.isLastQueryExecuted) {
      return new Promise<boolean>(resolve => {
        this.resolveQueryChanges = resolve;
        this.confirmationModal.show();
      });
    } else {
      return true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.inputQuery && this.inputQuery) {
      this.queryFormControl.setValue(this.inputQuery);
      this.onRunClick();
    }
  }

  onQueryKeyDown(event: any) {
    if (event.ctrlKey && event.keyCode === 13) { // Ctrl+Enter
      this.onRunClick();
    }
  }

  onRunClick(isLastQueryExecuted: boolean = true, isAutoCommitPrevState: boolean = true) {
    this.isRunning = true;
    this.queryRunStatus = true;
    this.isExcution = false;
    this.resultTablePages = [];
    this.resultTable = null;
    this.queryResult = [];
    this.multiQueryResults = [];
    this.selectedQuery = null;
    let queryString: string = this.queryFormControl.value.trim();
    if (!queryString) {
      return;
    }

    /*
      - if sql query is 'rollback' or 'commit' then on execution of the query set isLastQueryExecuted = false
      - update auto-commit and prevQueryExecution-status
     */
    if (!this.isAutoCommit) {
      if (!isLastQueryExecuted) {
        this.schemaService.setLastQueryExecuted(false);
        this.schemaService.setRollackSubject(false);
        this.schemaService.setCommitSubject(false);
      } else {
        this.schemaService.setLastQueryExecuted(true);
      }
    } else {
      this.schemaService.setLastQueryExecuted(false);
      this.schemaService.setRollackSubject(false);
      this.schemaService.setCommitSubject(false);
    }

    if (!isAutoCommitPrevState) {
      this.runQuery(this.superSql.getSuperQuery(this.schemaService, this.session, queryString, this.isMultiLine), isAutoCommitPrevState);
    } else {
      this.runQuery(this.superSql.getSuperQuery(this.schemaService, this.session, queryString, this.isMultiLine));
    }
  }

  onCancelClick() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.isRunning = false;
    this.schemaService.cancelQuery(this.session).subscribe(result => {
      this.showHint = true;
    }, err => {
      // ingore 404 (NOT_FOUND) errors as that means the statement is already gone
      if (err.status !== 404) {
        this.errorMessage = 'SQL query could not be cancelled: ' +
          (err.error.err || 'An unknown error occurred.');
      }
    });
  }

  // assign previous page results
  cursorPrev() {
    this.resultCurrentPage--;
    this.resultTable = this.resultTablePages[this.resultCurrentPage];
  }

  /**
   * Funtion call's API to get sql query results or more results for specific query
   */
  cursorNext() {
    this.queryRunStatus = false;
    if (this.resultCurrentPage < this.resultTablePages.length - 1) {
      this.resultCurrentPage++;
      // If the API is already have more records than cureent-page then it will assign
      this.resultTable = this.resultTablePages[this.resultCurrentPage];
    } else {
      this.ngUnsubscribe = new Subject();
      this.isLoading = true;
      this.resultCurrentPage++;
      this.subscriptions.push(
        this.query.next().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          this.queryResult = result.output;
          // console.log('-----queryResult-----', this.queryResult);
          if (this.queryResult && this.queryResult[0].isSuccess !== undefined) {
            // copy query result to another array to switch in between results of multiple queries
            this.queryResult.forEach(item => {
              const { query, resultSetId, runTime } = item;
              const hasMore = item.hasOwnProperty('hasMore') ? item.hasMore : undefined;

              this.multiQueryResults.push({
                query,
                results: [item],
                resultSetId,
                runTime,
                hasMore
              });
            });
          }

          this.isRunning = false;
          this.updateResultQuery();
          this.mapQueryResults(null);
        }, err => {
          this.isRunning = false;
          this.sqlException = '';
          this.informixError = '';
          this.statementOffset = '';
          if (err instanceof HttpErrorResponse) {
            if (err.error && err.error?.output) {
              this.queryResult = err.error.output;

              /*
              * when isContinueOnFailure=true and failed one of the query, then API return error with query result.
              * below functionts will map other query success results
              */
              if (this.queryResult && this.queryResult[0].isSuccess !== undefined) {
                // copy query result to another array to switch in between multiple query results.
                this.queryResult.forEach(item => {
                  const { query, resultSetId, runTime } = item;
                  const hasMore = item.hasOwnProperty('hasMore') ? item.hasMore : undefined;
                  this.multiQueryResults.push({
                    query,
                    results: [item],
                    resultSetId,
                    runTime,
                    hasMore
                  });
                });
              }

              this.updateResultQuery();
              this.mapQueryResults(null);
            } else if (err.status === 404) {
              this.errorMessage = 'Your session has expired. You will need to run the query again.';
              this.showRerunButton = true;
              this.resultMessage = null;
              this.resultTable = null;
              this.isShowResultTab = false;
            } else {
              this.clearResults();
              this.errorMessage = err.error.err || 'An unknown error occurred.';
              this.sqlException = err.error.sqlException;
              this.informixError = err.error.informixError;
              this.statementOffset = err.error.statementOffset;
              this.resultMessage = null;
              this.resultTable = null;
              this.isShowResultTab = false;
            }
          } else if (err instanceof Error) {
            this.errorMessage = err.message;
            this.resultMessage = null;
            this.resultTable = null;
            this.isShowResultTab = false;
          } else if (typeof err === 'string') {
            this.errorMessage = err;
            this.resultMessage = null;
            this.resultTable = null;
            this.isShowResultTab = false;
          }
          this.emitShowResultTabValue();
        }).add(() => {
          this.isLoading = false;
        })
      );
    }
  }

  /**
   * Map result queries array
   */
  updateResultQuery() {
    let newQuery = '';

    // in case of fectching next records skip update local storage
    if (this.queryResult.length === 1 && this.queryResult[0].query === undefined) {
      return;
    }
    if (this.isMultiLine) {
      // add all queries to the slicedQueries Array
      this.slicedQueries = this.queryResult.map(item => ({ query: item.query + ';' }));

      // add new line to each query
      this.queryResult.map(item => {
        newQuery += item.query + ';\r\n';
      });

      // select first query by-default from multiple queries and show results.
      this.selectedQuery = this.slicedQueries.length > 0 ? this.slicedQueries[0] : '';
    } else {
      this.slicedQueries = [];
      newQuery += this.queryFormControl.value.trim();
      this.slicedQueries.push({query: newQuery});
    }

    // select first query by-default from multiple queries and show results.
    this.selectedQuery = this.slicedQueries.length > 0 ? this.slicedQueries[0] : '';
  }

  /**
   * Map multiple query results:
   * - Check if the selected query from history is available in queryResult.
   * - If found, load the specific result; otherwise, retrieve the query response from the API.
   *
   * @param query
   */
  mapQueryResults(query: any) {
    /*
     * Check if there are any requests for additional records from the previous query.
     * If yes, update those records in the query history.
     * Else Proceed with the execution of the new query result.
   */
    if (query !== null) {
      let indexOfPrevQuery = this.multiQueryResults.findIndex(item => item.query === this.prevSelectedQuery);
      if (indexOfPrevQuery !== -1) {
        if (this.multiQueryResults && this.multiQueryResults.length &&
          this.multiQueryResults[indexOfPrevQuery].results.length < this.resultTablePages.length) {
          this.multiQueryResults[indexOfPrevQuery].results = this.resultTablePages;
        }
      }
      // clear records of previous query
      this.resetResults(0);
    }

    if (this.multiQueryResults && this.multiQueryResults.length > 0) {
      this.getSelectedQueryResults(query);
    }

    this.queryRunStatus = true;
  }

  /**
   * If user selected new query then show specific query results
   * else it is request for more records of current query
   *
   * @param query
   */
  getSelectedQueryResults(query) {
    /*
     * If query is not null then show previously feteched record
     * else it is request for more records of current query
     */
    if (query !== null) {
      // show the response of the query
      const sqlQuery = query.query.replace(/\\/g, '').toLowerCase();
      this.selectedQueryIndex = this.multiQueryResults.findIndex(item => item.query.replace(/\\/g, '').toLowerCase() + ';' === sqlQuery);
      const data = this.multiQueryResults.filter(item => item.query.replace(/\\/g, '').toLowerCase() + ';' === sqlQuery)[0];
      this.selectedQueryResult = data.results[this.resultCurrentPage];
      /*
       - Enhance the selectedQuery by incorporating runTime and hasMore status
       - Extract these values from the first result within the set of all results for that selected query.
       */
      this.selectedQueryResult['hasMore'] = data.results[0].hasMore;
      this.selectedQueryResult['runTime'] = data.results[0].runTime;

      this.prevSelectedQuery = query.query.split(';')[0];
      this.query.setHasNext(data.hasMore, data.resultSetId);
      if (data.results.length > 1) {
        this.queryResultMapping(this.selectedQueryIndex);
        return;
      }
      this.queryResultMapping();
    } else {
      // show more records for current query
      // this.selectedQueryIndex = 0;
      const firstIndex = 0;
      this.selectedQueryResult = this.queryResult[firstIndex];
      // add the hasMore status from selected query results
      this.selectedQueryResult['hasMore'] = this.queryResult[firstIndex].hasMore;
      // update the hasMore status in multiQueryResults from selected query results.
      this.multiQueryResults[firstIndex].hasMore = this.queryResult[firstIndex].hasMore;
      // add the runTime of query from first record of selected query from multiQueryResults
      this.selectedQueryResult['runTime'] = this.multiQueryResults[firstIndex].runTime;

      this.query.setHasNext(this.queryResult[firstIndex].hasMore, this.queryResult[firstIndex].resultSetId);
      // update previous query.
      if (this.queryResult[firstIndex].query !== undefined) {
        this.prevSelectedQuery = this.queryResult[firstIndex].query;
      }
      this.queryResultMapping();
    }
  }

  /**
   * To map the result of the selected query based on specified conditions or given index
   *
   * @param index
   */
  queryResultMapping(index: number = -1) {
    // set the response of selected query accordingly
    if (this.selectedQueryResult !== undefined) {
      // show the query response info
      if (this.selectedQueryResult.response !== undefined) {
        this.showHint = false;
        this.errorMessage = null;
        this.resetResults();
        this.showQueryResultTab();
        this.resultMessage = this.selectedQueryResult.response;
      } else if (this.selectedQueryResult.rows !== undefined) {
         // show the query table results
        if (!this.resultTable && this.selectedQueryResult.rows?.length < 1) {
          this.errorMessage = null;
          this.isShowResultTab = true;
          this.resultMessage = 'No rows available.';
        } else {
          this.resultMessage = null;
          this.showHint = false;
          this.errorMessage = null;

          if (index !== -1) {
            this.resetResults(0);
            this.isShowResultTab = true;
            this.resultTable = this.selectedQueryResult;
            this.resultTablePages = this.multiQueryResults[index].results;
          } else {
            this.isShowResultTab = true;
            this.resultTable = this.selectedQueryResult;
            this.resultTablePages.push(this.selectedQueryResult);
          }

          // set the current query has any more records
          if (this.selectedQueryResult.hasMore !== undefined) {
            this.resultSetId = this.selectedQueryResult.resultSetId;
            this.query.setHasNext(this.selectedQueryResult.hasMore, this.resultSetId);
          }
        }

        if (this.isMultiLine) {
          this.emitShowResultTabValue();
        }
      } else if (this.selectedQueryResult.err !== undefined) {
        // show the error response of the query
        this.showHint = false;
        this.resultMessage = null;
        this.resetResults();
        this.showQueryResultTab();
        this.errorMessage = this.selectedQueryResult.err;
      } else if(!this.selectedQueryResult.isExecute && !this.selectedQueryResult.isSuccess) {
        this.showHint = false;
        this.resultMessage = null;
        this.resetResults();
        this.showQueryResultTab();
        this.errorMessage = 'The statement is not executed in batch mode';
      }
    }
  }

  /**
   * Function to reset the current page values to their initial state or default values
   *
   * @param currentPage
   */
  resetResults(currentPage: number = -1) {
    this.resultCurrentPage = currentPage;
    this.resultTable = null;
    this.resultTablePages = [];
    this.isExcution = false;
    this.fetchingTreeStatus = false;
  }

  /**
   * Clear query results
   */
  clearResults(){
    this.resultCurrentPage = 0;
    this.resultTable = null;
    this.resultTablePages = [];
    this.slicedQueries = [];
    this.resultMessage = null;
    this.errorMessage = null;
    this.isExcution = false;
    this.fetchingTreeStatus = false;
    this.isShowResultTab =false;
    this.emitShowResultTabValue();
  }

  emitShowResultTabValue() {
    this.showResultEvent.emit(this.isShowResultTab);
  }

  // hide show query runtime details
  showQueryResultTab() {
    if(this.multiQueryResults.length > 1) {
      this.isShowResultTab = true;
      this.emitShowResultTabValue();
    } else {
      this.isShowResultTab = false;
      this.emitShowResultTabValue();
    }
  }

  /**
   * find the specific query from query results
   *
   * @param query
   */
  findQueryResult(query) {
    return this.multiQueryResults.find(item => item.query === query);
  }

  /**
   * Function to enable or disable next and previous buttons based on the current page and total number of page
   */
  hasNextQueryResult() {
    const selectedQueryResult = this.findQueryResult(this.selectedQueryResult.query);
    if (selectedQueryResult && selectedQueryResult.results && selectedQueryResult.results.length) {
      return !selectedQueryResult.results[this.resultCurrentPage]?.hasMore &&
        this.resultCurrentPage >= selectedQueryResult.results.length - 1;
    }
    return (!this.selectedQueryResult?.hasMore && this.resultCurrentPage >= this.resultTablePages.length - 1);
  }

  /**
   * Check if all records are fetched for the selected query to determine the page count
   */
  isAllRecordsFetched() {
    const selectedQueryResult = this.multiQueryResults.find(item => item.query === this.selectedQueryResult.query);
    if (selectedQueryResult?.results && this.resultTablePages.length === selectedQueryResult.results.length) {
      let len = selectedQueryResult.results.length;
      return selectedQueryResult ? !selectedQueryResult.results[len - 1].hasMore : false;
    } else {
      return this.selectedQueryResult ? !this.selectedQueryResult.hasMore : false;
    }
  }

  onRunAgainClick() {
    this.runQuery(this.superSql.getSuperQuery(this.query.restdb, this.query.session, this.query.query, this.isMultiLine));
  }

  private runQuery(query: AbstractQuery, isAutoCommitPrevState: Boolean = true) {
    let isAutoCommit;
    if (!isAutoCommitPrevState) {
      isAutoCommit = isAutoCommitPrevState;
    } else {
      isAutoCommit = this.isAutoCommit;
    }

    this.showHint = false;
    this.showRerunButton = false;
    this.resultMessage = null;
    this.errorMessage = null;
    this.resultTable = null;
    this.resultTablePages = [];
    this.multiQueryResults = [];
    this.resultCurrentPage = -1;
    this.query = query;
    // set query params to the SQL API
    let params = {
      isMultiLine: this.isMultiLine
    };

    if (this.isMultiLine) {
      params['isContinueOnFailure'] = this.isContinueOnFailure;
      params['isAutoCommit'] = isAutoCommit;
      params['isBatchMode'] = this.isBatchMode;
    }

    this.query.setParams(params);
    this.cursorNext();
  }

  /**
   * save auto-commit, continue On Failure and batch mode configuration.
   */
  saveConfigurations(){
    const payload = {
      isAutoCommit: this.isAutoCommit,
      isContinueOnFailure: this.isContinueOnFailure,
      isSetBatchMode: this.isBatchMode
    };

    this.schemaService.saveConfiguration(this.session, this.server, payload).subscribe(result => {
      this.isAutoCommit = result.isAutoCommit;
      this.isContinueOnFailure = result.isContinueOnFailure;
      this.isBatchMode = result.isSetBatchMode;
    }, err => {
      if (err.status !== 404 && err.error?.err) {
        this.notificationsService.pushErrorNotification(err.error.err);
      }
    });
  }

  onExecutionPlanTabSelect() {
    // const topOffSet = this.tabset.tabs[0].elementRef.nativeElement.offsetTop;
    // this.tabsDataHeight = (window.innerHeight - topOffSet) - 360;
    this.sqlTreeStatusToRun = false;
    this.showSqlTraceConfigState = false;
    this.resultMessage = null;
    // Execution plan process starts.
    this.executionProcess();
  }

  executionProcess() {
    // checking sqlTrace Config state is ON for selected database. based on that calling fetching query tree.
    // if it's in the OFF state giving option to enable.
    if (this.sqltraceConfig.state === 'On' && this.sqltraceConfig.databases.tracedDatabases.indexOf(this.database) > -1) {
      this.sqlTracingPrevState = true;
      this.showSqlTraceConfig = false;
      this.showSqlTraceState = true;
      if (!this.sqlTreeStatusToRun && this.queryRunStatus) {
        this.getStatementIterators();
      } else {
        this.sqlTreeStatusToRun = true;
        this.reRunAknowleged = false;
        this.runAgainQuertModal.show();
      }
    } else {
      this.showSqlTraceConfig = true;
      this.showSqlTraceState = false;
    }
  }

  terminateAndClose() {
    // closing the fetching query tree progress modal.
    this.count = 0;
    this.terminationState = false;
    this.excutionPlanModal.hide();
    this.sqlEditor.nativeElement.focus();
    this.fetchingTreeStatus = false;
  }

  closeRunAgainModal() {
    this.runAgainQuertModal.hide();
    this.sqlEditor.nativeElement.focus();
    // this.tabset.tabs[0].active = true;
    this.reRunAknowleged = true;
  }

  private getStatementIterators(reRun?: boolean) {
    this.takingMoreTime = false;
    this.terminationState = false;
    this.terminateActionStatus = false;
    this.sqltraceIteratorData = null;
    this.fetchingTreeStatus = true;
    this.count = 0;

    if (reRun) {
      this.sqlTraceIntervalSubscription();
    } else {
      this.getQueryTreeValues(true);
    }
  }

  /**
   * Subscription method to fetch query tree api every 1s for 10s.
   */
  sqlTraceIntervalSubscription() {
    this.count = 0;

    if (this.sqltraceServiceLoop && this.sqltraceServiceLoop.unsubscribe) {
      this.sqltraceServiceLoop.unsubscribe();
    }

    // subscription to fetch query tree every 1s and stop it after 10s
    this.sqltraceServiceLoop = interval(1000)
      .pipe(
        takeUntil(timer(11000, 0)),
        map(() => this.getQueryTreeValues())
      ).subscribe();
  }

  /**
   * Method to fetch query tree and show/hide progress modal based on different cases.
   *
   * @returns void
   */
  getQueryTreeValues(firstTimeCall?: boolean): void {

    if (!firstTimeCall) {
      this.count++;
    } // Counter to show progress in the execution modal on UI

    // Manually un-subscribing to sqlTraceLoop and sqlTraceAPI subscriber after 10s.
    if (this.count === 10) {
      this.takingMoreTime = true;
      // use setTimeout() to prevent extra last api call after timer finished.
      setTimeout(() => {
        this.pendingAPICall = false;
      });
      if (this.sqltraceServiceLoop && this.sqltraceServiceLoop.unsubscribe) {
        this.sqltraceServiceLoop.unsubscribe();
      }
      if (this.sqlTraceAPI$ && this.sqlTraceAPI$.unsubscribe) {
        this.sqlTraceAPI$.unsubscribe();
      }
    }

    // Not calling new API call when API is already in pending state.
    if (this.pendingAPICall) {
      return;
    }

    this.pendingAPICall = true;
    this.sqlTraceAPI$ = this.sqltraceService.getQueryTreeIdByStatement(this.server, { statement: this.selectedQuery.query })
      .subscribe((response: any) => {
        this.sqltraceIteratorData = response;
        // Closing execution plan modal if query tree generated by server.
        if (this.sqltraceIteratorData) {
          this.terminatePlanExcution();
        } else if (firstTimeCall && !this.terminateActionStatus) {
          // showing execution modal only after first api response.
          this.excutionPlanModal.show();
          this.sqlTraceIntervalSubscription();
        }
        this.pendingAPICall = false; // setting off pending state
      }, err => {
        this.pendingAPICall = false;  // setting off pending state
        this.dataLoadErrorMessage = err.error ? err.error.err : err;
        this.fetchingTreeStatus = false;
      }
      );
  }

  terminatePlanExcution() {
    this.excutionPlanModal.hide();
    // terminating Execution plan tree API call.
    this.pendingAPICall = false;
    if (this.sqltraceServiceLoop && this.sqltraceServiceLoop.unsubscribe) {
      this.sqltraceServiceLoop.unsubscribe();
    }
    if (this.sqlTraceAPI$ && this.sqlTraceAPI$.unsubscribe) {
      this.sqlTraceAPI$.unsubscribe();
    }

    // Unsubscribe from all subscriptions to avoid memory leaks
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.takingMoreTime = false;
    this.terminateActionStatus = true;
    this.fetchingTreeStatus = false;
  }
  viewQueryTree() {
    this.resultTabActive = false;
    this.showSqlTraceConfig = false;
    this.runAgainQuertModal.hide();
    this.onRunClick();
    this.getStatementIterators();
    this.reRunAknowleged = true;
  }
  ngOnDestroy() {
    this.terminatePlanExcution();
  }
  private loadServer(server: InformixServer) {
    this.server = server;
  }
  private getSqlTraceConfiguration() {
    this.sqltraceService.getSqltraceSummary(this.server).subscribe(sqltrace => {
      this.showSqlTraceConfigState = false;
      this.sqltraceConfig = sqltrace.config;
      const sqlTracingCurrentState =
        this.sqltraceConfig.databases.tracedDatabases.indexOf(this.database) > -1 && this.sqltraceConfig.state === 'On';

      // Logic to prevent runAgain to be shown even for same state.
      if (
      !this.firstRun &&
      (sqlTracingCurrentState !== this.sqlTracingPrevState) &&
      sqlTracingCurrentState
      ) {
        this.sqlTreeStatusToRun = true;
        this.runAgainQuertModal.show();
      } else {
        this.firstRun = false;
      }

      this.sqlTracingPrevState = sqlTracingCurrentState;

      if (sqlTracingCurrentState) {
        this.showSqlTraceState = this.sqltraceConfig.state === 'On';
      } else {
        this.showSqlTraceState = false;
      }

    }, err => {
      console.error('Error getting sqltrace summary info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }
  handleActionSuccess(message: string) {
    this.getSqlTraceConfiguration();
  }

  handleActionError(error: any) {
    this.notificationsService.pushErrorNotification(error);
  }

  /**
   * export queries into sql file
   *
   * @param type
   */
  exportQueryAs(type: String) {
    if (type === 'sql') {
      const blob = new Blob([this.queryFormControl.value], { type: 'text/sql' });
      const url = URL.createObjectURL(blob);
      this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(url);
      if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
        window.navigator.msSaveBlob(blob, 'sql_queries.sql');
      }
    }
  }

  importFile() {
    const inputElement = document.getElementById('name') as HTMLInputElement;
    inputElement.value = '';
    this.importQueryModal.show();
  }

  /**
   * check imported file is valid file
   * read file and load queries in sql editor
   *
   * @param file
   */
  importSqlFile(file: any) {
    if (file.files.length === 0) {
      return;
    }
    if (file.files[0].name.match(/\.sql$/) === null) {
      this.notificationsService.pushErrorNotification('Only SQL files are supported.');
      return;
    }

    const SqlFile: File = file.files[0];
    if (SqlFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.queryFormControl.setValue(e.target.result);
      };
      reader.readAsText(SqlFile);
      this.importQueryModal.hide();
    }
  }

  /**
   * toggle isAutocommit
   * if any uncommitted transaction found then show popup to rollback or commit the transaction
   */
  toggleAutoCommit() {
    if (!this.isAutoCommit && this.isLastQueryExecuted) {
      this.isChangeInLastQuery();
    }

    this.isAutoCommit = !this.isAutoCommit;
    this.schemaService.setAutoCommit(this.isAutoCommit);
    this.schemaService.setLastQueryExecuted(false);
    this.saveConfigurations();
  }

  toggleBatchMode(){
    this.isBatchMode = !this.isBatchMode;
    if(this.isBatchMode) {
      this.isContinueOnFailure = false;
    }
    this.saveConfigurations();
  }

  toggleContinueOnFailure(){
    this.isContinueOnFailure = !this.isContinueOnFailure;
    this.saveConfigurations();
  }

  toggleMultiLine() {
    this.isMultiLine = !this.isMultiLine;
    if(!this.isMultiLine) {
      this.showHint = true;
      this.resultMessage = null;
    }
    this.resetResults();
    this.slicedQueries = [];
    this.errorMessage = null;
    this.isShowResultTab = false;
    this.emitShowResultTabValue();
  }

  // rollback the uncommitted transaction
  rollback() {
    this.schemaService.setAutoCommit(true);
    this.schemaService.setLastQueryExecuted(false);
    this.schemaService.setRollackSubject(true);
    this.resolveQueryChanges(true);
    this.confirmationModal.hide();
  }

  // commit the uncommitted trasaction
  commit() {
    this.schemaService.setAutoCommit(true);
    this.schemaService.setLastQueryExecuted(false);

    // commit the uncommitted trasaction
    this.schemaService.setCommitSubject(true);
    this.resolveQueryChanges(true);
    this.confirmationModal.hide();
  }

  showQueryHistory(isShow: boolean) {
    this.schemaService.setShowQueryHistroy(isShow);
  }
}
